<clr-modal #modal [clrModalOpen]="isOpen()"
  (clrModalOpenChange)="isOpen.set($event)"
  [clrModalSize]="'xl'">
  <div class="modal-title">
    <h3>{{ "Mengeneinheiten bearbeiten" | mrTranslate }}</h3>
  </div>
  <div class="modal-body grid" id="grid-container">
    <div class="clr-row nxm">
      <form clrForm #f="ngForm" (ngSubmit)="saveIfValid()"
        class="clr-col-11 clr-row nxm width100" [clrLabelSize]="4">
        <clr-input-container class="clr-col-md-4">
          <label>{{ "Code" | mrTranslate }}</label>
          <input clrInput type="text" ngModel name="Code" required />
          <clr-control-error *clrIfError="'required'">
            {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
          </clr-control-error>
        </clr-input-container>
        <clr-input-container class="clr-col">
          <label>{{ "Bezeichnung" | mrTranslate }}</label>
          <input clrInput type="text" ngModel name="Bezeichnung" />
        </clr-input-container>
        <button type="submit" class="btn btn-primary clr-col-auto" [title]="'Speichern' | mrTranslate">
          <clr-icon shape="floppy"></clr-icon>
        </button>
      </form>
    </div>
    <ng-container *ngIf="meh() as table">
      <ng-template #loading>
        <clr-spinner class="centred"></clr-spinner>
      </ng-template>
      <ng-container *ngIf="table.columns; else loading">
        <clr-datagrid #grid
          class="datagrid-compact h-100 w-100-p"
          [clrDgSingleSelected]="selectedRow()"
          [clrDgRowSelection]="true"
          (clrDgSingleSelectedChange)="selectedRow.set($event)">
          <clr-dg-column *ngFor="let column of table.columns" [clrDgField]="column.id">
            <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns}">
              {{ column.id | mrTranslate }}
            </ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of table.rows; trackBy: trackByID" [clrDgItem]="row">
            <clr-dg-cell *ngFor="let cell of table.columns" class="name-cell" 
              [innerHTML]="row[cell.id] | tablePrettyPrint">
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>{{table.rows.length}} {{"Zeilen" | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
        <div class="btn-group btn-primary btn-icon">
          <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
            (click)="grid.selection.clearSelection()">
            <clr-icon shape="file"></clr-icon>
          </button>
          <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
            (click)="deleteRow()" [disabled]="!selectedRow()">
            <clr-icon shape="times"></clr-icon>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="modal.close()">
      {{ "Beenden" | mrTranslate }}
    </button>
  </div>
</clr-modal>