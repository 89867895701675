<div class="overlay-inner-wrapper">
  <header class="header-2">
    <div class="branding">
      <span class="title">{{"Auftrag erstellen" | mrTranslate}}</span>
    </div>
    <div class="header-actions">
      <a href="javascript:void(0);" class="nav-link nav-icon">
        <clr-icon shape="times" (click)="close()"></clr-icon>
      </a>
    </div>
  </header>

  <div class="clr-row nxm" style="padding-left: 10px; padding-right: 10px;">
    <clr-tabs class="w-100-p">
      <clr-tab *ngIf="mangel?.rows.length">
        <button clrTabLink (click)="tabChange('mangel')" id="link1">
          {{ "Mängel" | mrTranslate }}
        </button>
        <clr-tab-content id="content1" *clrIfActive="openTab == 'mangel'">
          <div id="assignmentWrapper">
            <form class="login" id="auftragform" clrForm [formGroup]="auftragGroup"
              (ngSubmit)="auftragGroup.valid && onSubmit()">
              <div class="clr-row" id="auftragsFormRow1">
                <div class="col clr-col-md-2 nopaddingleft">
                  <div>
                    <label for="dateInput">{{'Startdatum' | mrTranslate}}</label>
                    <clr-date-container>
                      <input id="dateInput" type="date" clrDate
                        [ngModel]="auftragGroup.get('startdate').value | localeDate"
                        (ngModelChange)="auftragGroup.get('startdate').setValue(localeDate.tosql($event))"
                        [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                        [disabled]="auftragGroup.disabled" required />
                      <clr-control-error *clrIfError="'required'">
                        {{ "Das Datum muss eingegeben werden" | mrTranslate }}
                      </clr-control-error>
                    </clr-date-container>
                  </div>
                </div>
                <div class="col clr-col-md-2">
                  <div>
                    <label for="dateInputEnd">{{'Enddatum' | mrTranslate}}</label>
                    <clr-date-container>
                      <input id="dateInputEnd" type="date" clrDate
                        [ngModel]="auftragGroup.get('enddate').value | localeDate"
                        (ngModelChange)="auftragGroup.get('enddate').setValue(localeDate.tosql($event))"
                        [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                        [disabled]="auftragGroup.disabled" required />
                      <clr-control-error *clrIfError="'required'">
                        {{ "Das Datum muss eingegeben werden" | mrTranslate }}
                      </clr-control-error>
                    </clr-date-container>
                  </div>
                </div>
                <div class="col clr-col-md-3">
                  <div class="w100">
                    <label for="auftragnehmer">{{'Auftragnehmner' | mrTranslate}}</label>
                    <div class="select">
                      <clr-select-container id="auftragnehmerWrapper">
                        <select id="auftragnehmer" clrSelect formControlName="auftragnehmer">
                          <option *ngFor="let firm of firmen" [value]="firm.ID">{{ firm.mFirma }}</option>
                        </select>
                        <clr-control-error *clrIfError="'required'">
                          {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                        </clr-control-error>
                      </clr-select-container>
                    </div>
                  </div>
                </div>
                <div class="col clr-col-md-3">
                  <div class="comboboxcontainer clr-form-control clr-row">
                    <label for="auftragsart">{{'Auftragsart' | mrTranslate}}</label>
                    <div class="clr-control-container">
                      <input id="auftragsart" class="clr-input combobox" placeholder="" type="search" name="aufgehobenDurch"
                        formControlName="auftragsart" list="auftragsartList" />
                    </div>
                    <datalist id="auftragsartList">
                      <option *ngFor="let c of auftragsarten" [value]="c">{{ c }}</option>
                    </datalist>
                  </div>
                </div>
                <div class="col clr-col-md-2 nopaddingright">
                  <div>
                    <label for="auftragsnummerInput">{{'Auftragsnummer' | mrTranslate}}</label>
                    <clr-input-container>
                      <input clrInput type="text" id="auftragsnummerInput" formControlName="auftragsnr" />
                    </clr-input-container>
                  </div>
                </div>
              </div>
              <div class="clr-row" id="auftragsFormRow1" style="margin-top: 5px;">
                <div class="col clr-col-md-3 nopaddingright">
                  <div class="w100" style="margin-left: -12px;">
                    <label for="auftragsgruppe">{{'Auftragsgruppe' | mrTranslate}}</label>
                    <div class="select">
                      <clr-select-container id="auftragnehmerWrapper">
                        <select clrSelect id="auftragsgruppe" formControlName="gruppenid" style="width: 100%;">
                          <option *ngFor="let groups of auftragsgroups" [value]="groups.id">
                            {{ groups.mBezeichnung }}
                          </option>
                        </select>
                        <clr-control-error *clrIfError="'required'">
                          {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                        </clr-control-error>
                      </clr-select-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clr-row">
                <div class="clr-col-12 nopaddingleft nopaddingright" style="padding-top:10px;">
                  <label for="auftragtextarea">{{'Bemerkung' | mrTranslate}}</label>
                  <clr-textarea-container style="flex-direction:column !important;max-width:100%;">
                    <textarea clrTextarea rows="3" id="auftragtextarea" formControlName="bemerkung"
                      style="width:100%;"></textarea>
                  </clr-textarea-container>
                </div>
              </div>
              <div class="clr-row" *ngIf="mangel && mangel['rows']">
                <div class="clr-col-12 nopaddingright nopaddingleft">
                  <clr-datagrid [(clrDgSelected)]="selected" class="datagrid-compact">
                    <clr-dg-column *ngFor="let column of mangel['columns']" [clrDgField]="column.id"
                      [hidden]="column.id | hideIDColumns: ['bildpfad']">{{
                      column.id | titlecase | mrTranslate
                      }}</clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of mangel['rows']" [clrDgItem]="row">
                      <clr-dg-cell *ngFor="let cell of mangel['columns']" [innerHTML]="row[cell.id] | tablePrettyPrint"
                        [hidden]="cell.id | hideIDColumns: ['bildpfad']"></clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="6">
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                        {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
                <div class="clr-col-12" style="margin-top:10px;">
                  <button class="btn btn-primary" [disabled]="trial || !selected?.length" type="submit">{{'Auftrag erstellen' |
                    mrTranslate}}</button>
                </div>
              </div>

            </form>
          </div>
        </clr-tab-content>
      </clr-tab>
      <ng-container *ngIf="(typ_Line_Point | objtype) == 'point' && Opt_F_Drawing == 1 && isload == true">
        <clr-tab class="w-100-p">
          <button clrTabLink (click)="tabChange('paint')"
            [title]="'Mangelskizzen' | mrTranslate">{{'Mangelskizzen' | mrTranslate}}</button>
          <clr-tab-content>
            <app-painting [setostammid]="ostammid" [setinittyp]="2"></app-painting>
          </clr-tab-content>
        </clr-tab>
      </ng-container>

    </clr-tabs>
  </div>

</div>
