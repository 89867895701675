<div class="overlay-inner-wrapper">
  <ng-container *ngIf="!isInspektion">
    <header class="header-2">
      <div class="branding">
        <span class="title">{{titel | mrTranslate}}</span>
      </div>
      <div class="header-actions">
        <a href="javascript:void(0);" class="nav-link nav-icon">
          <clr-icon shape="times" (click)="close()"></clr-icon>
        </a>
      </div>
    </header>
  </ng-container>

  <div id="stammdatenWrapper" *ngIf="stammdaten?.length"
    class="w-100-p padding-10-lr padding-12-b scroll-y"
    [ngClass]="isInspektion ? 'h-100' : 'h-60'">
    <div class="clr-row nxm" [class]="isInspektion ? 'auto-height-lg-100' : 'auto-height-lg-60'">
      <form #stammDatenForm clrForm #f="ngForm" clrLabelSize="12" (ngSubmit)="saveIfValid(f)"
        class="width100 clr-col-12 clr-col-lg-6 auto-height-lg scroll-y">
        <table class="table margin-0-t table-compact nowrap">
          <thead style="position: sticky; top: -7px; z-index: 3;">
            <tr>
              <th class="left">{{'Objekt' | mrTranslate}}</th>
              <th class="left">{{'Bezeichnung' | mrTranslate}}</th>
              <th>{{'Pflicht' | mrTranslate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let stamm of stammdaten">
              <ng-container *ngIf="!stamm.isHeader; else header">
                <td class="left" *ngIf="!drehung.isOn; else drehTmpl">
                  {{ stamm['Translate'] ? (stamm.Objekt | mrTranslate) : stamm.Objekt }}
                </td>
                <ng-template #drehTmpl>
                  <td class="left" [ngSwitch]="stamm.DBField">
                    <ng-container *ngSwitchCase="'Startpunkt'">
                      {{ 'Endpunkt Bezeichnung' | mrTranslate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'Endpunkt'">
                      {{ 'Startpunkt Bezeichnung' | mrTranslate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'StartMeter'">
                      {{ 'Endmeter' | mrTranslate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'EndMeter'">
                      {{ 'Startmeter' | mrTranslate }}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ stamm['Translate'] ? (stamm.Objekt | mrTranslate) : stamm.Objekt }}
                    </ng-container>
                  </td>
                </ng-template>
                <td *ngIf="(
                    stamm.DBField.startsWith('Start') ||
                    stamm.DBField.startsWith('End')
                  ) && drehung.isOn; else nichtDrehen">
                  <clr-input-container>
                    <input clrInput type="text" name="{{stamm.Objekt}}"
                      [ngModel]="stamm.Bezeichnung" disabled />
                  </clr-input-container>
                </td>
                <ng-template #nichtDrehen>
                  <td [ngSwitch]="stamm.Fieldtyp">
                    <!-- Texteingabefeld ohne Auswahl -->
                    <ng-container *ngSwitchCase="0">
                      <clr-input-container>
                        <input clrInput type="text" name="{{stamm.Objekt}}" [(ngModel)]="stamm.Bezeichnung"
                          [required]="stamm.Pflicht" />
                        <clr-control-error *clrIfError="'required'">
                          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                        </clr-control-error>
                      </clr-input-container>
                    </ng-container>
                    <!-- Texteingabefeld mit Auswahl -->
                    <ng-container *ngSwitchCase="1">
                      <clr-datalist-container>
                        <input class="clr-col-12" clrDatalistInput type="search" name="{{stamm.Objekt}}"
                          [(ngModel)]="stamm.Bezeichnung" [required]="stamm.Pflicht" />
                        <datalist>
                          <option *ngFor="let wert of stamm.Selection" [value]="wert"></option>
                        </datalist>
                        <clr-control-error *clrIfError="'required'">
                          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                        </clr-control-error>
                      </clr-datalist-container>
                    </ng-container>
                    <!-- STRIKT Texteingabefeld nur vom Auswahl -->
                    <ng-container *ngSwitchCase="6">
                      <ng-container *ngIf="stamm.Selection | keyvalue as selection">
                        <clr-select-container *ngIf="selection | array:'map':'getProperty':'key' as wListe">
                          <select clrSelect [name]="stamm.Objekt" [(ngModel)]="stamm.Bezeichnung"
                            [required]="!!stamm.Pflicht" [inTableValidator]="wListe">
                            <option *ngIf="!stamm.Pflicht" value=""></option>
                            <option *ngIf="stamm.Bezeichnung && !wListe.includes(stamm.Bezeichnung)"
                              [value]="stamm.Bezeichnung" class="invalidRed">{{ stamm.Bezeichnung }}</option>
                            <option *ngFor="let wert of selection" [value]="wert.key">{{ wert.value }}</option>
                          </select>
                          <clr-control-error *clrIfError="'not-in-Table'; error as err">
                            {{err | mrTranslate}}
                          </clr-control-error>
                          <clr-control-error *clrIfError="'required'">
                            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                          </clr-control-error>
                        </clr-select-container>
                      </ng-container>
                    </ng-container>
                    <!-- Zahleneingabefeld float -->
                    <ng-container *ngSwitchCase="2">
                      <clr-input-container>
                        <input clrInput name="{{stamm.Objekt}}" [(ngModel)]="stamm.Bezeichnung" type="number" step="0.1"
                          min="0" [required]="stamm.Pflicht"
                          (change)="(stamm.DBField == 'StartMeter' || stamm.DBField == 'EndMeter') && calcLaenge()"
                          [disabled]="stamm.DBField == 'Laenge' && (typ_Line_Point | objtype) == 'line'" />
                        <clr-control-error *clrIfError="'required'">
                          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                        </clr-control-error>
                      </clr-input-container>
                    </ng-container>
                    <!-- Zahleneingabefeld int -->
                    <ng-container *ngSwitchCase="3">
                      <clr-input-container>
                        <input clrInput name="{{stamm.Objekt}}" [(ngModel)]="stamm.Bezeichnung" type="number" step="1"
                          min="0" [required]="stamm.Pflicht" />
                        <clr-control-error *clrIfError="'required'">
                          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                        </clr-control-error>
                      </clr-input-container>
                    </ng-container>
                    <!-- Ja/Nein Einegabe -->
                    <ng-container *ngSwitchCase="4">
                      <clr-toggle-container>
                        <clr-toggle-wrapper>
                          <input clrToggle name="{{stamm.Objekt}}" [(ngModel)]="stamm.Bezeichnung" type="checkbox"
                            [required]="stamm.Pflicht" />
                        </clr-toggle-wrapper>
                        <clr-control-error *clrIfError="'required'">
                          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                        </clr-control-error>
                      </clr-toggle-container>
                    </ng-container>
                    <!-- Datum -->
                    <ng-container *ngSwitchCase="5">
                      <clr-date-container>
                        <input clrDate name="{{stamm.Objekt}}" autocomplete="off" type="date" [required]="stamm.Pflicht"
                          [ngModel]="stamm.Bezeichnung | localeDate"
                          (ngModelChange)="stamm.Bezeichnung = localeDate.tosql($event)"
                          [ngModelOptions]="{updateOn: 'blur'}" />
                        <clr-control-error *clrIfError="'required'">
                          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                        </clr-control-error>
                      </clr-date-container>
                    </ng-container>
                  </td>
                </ng-template>
                <td>
                  <clr-icon *ngIf="stamm.Pflicht" shape="success-standard" class="is-solid"
                    style="fill: #2EC0FF;"></clr-icon>
                </td>
              </ng-container>
              <ng-template #header>
                <th class="headerRow left">
                  {{ stamm['Translate'] ? (stamm.Objekt | mrTranslate) : stamm.Objekt }}
                </th>
                <th class="headerRow"></th>
                <th class="headerRow"></th>
              </ng-template>
            </tr>
          </tbody>
        </table>
      </form>
      <div class="clr-col-12 clr-col-lg-6 auto-height-lg">
        <app-preview-thumbnails style="margin-top: 0px;"
          [readonly]="!isInspektion"
          [maxAmount]="6" [acceptTypes]="'image/*'"
          (deleteFile)="deleteFile($event)"
        ></app-preview-thumbnails>
        <form clrForm class="width100" clrLabelSize="12">
          <ng-container *ngIf="(typ_Line_Point | objtype) == 'point'">
            <div class="clr-row margin-0-r" style="align-items: baseline;">
              <div class="clr-col-10">
                <clr-select-container>
                  <label class="clr-col-4">{{ 'Messpunktskizze' | mrTranslate }}</label>
                  <select clrSelect name="skizzenname" [(ngModel)]="selectedSkizze" class="clr-col-8">
                    <option value="">{{ "Keine" | mrTranslate }}</option>
                    <option *ngFor="let item of skizzenListe" [value]="item">{{ item }}</option>
                  </select>
                </clr-select-container>
              </div>
              <div class="clr-col-2">
                <button class="btn" (click)="fInput.click()">
                  <clr-icon shape="image"></clr-icon>
                </button>
                <input #fInput accept=".png" (change)="onBildChanged($event.target.files[0])" type="file" id="fileUpload"
                  name="fileUpload" style="display:none;" (click)="$event.target.value = null" />
              </div>
            </div>
            <br />
            <div class="preview mw-100-p w-100-p" *ngIf="skizzeBase64 !== undefined">
              <img [src]="skizzeBase64" class="invertWhenDark" (click)="openSkizze(skizzeBase64)"/>
            </div>
          </ng-container>
          <clr-textarea-container>
            <label>{{'Bemerkung' | mrTranslate}}</label>
            <textarea name="bemerkung" clrTextarea [(ngModel)]="bemerkung"></textarea>
          </clr-textarea-container>
        </form>
      </div>
    </div>
    <div *ngIf="!isInspektion" class="margin-12-t padding-10-l">
      <button type="submit" class="btn btn-primary"
        (click)="f.ngSubmit.emit()">{{'Speichern' | mrTranslate}}</button>
    </div>
  </div>
</div>

<app-mediaviewmodal *ngIf="imageMedia"
  (onClose)="imageMedia = undefined"
  [media]="imageMedia">
</app-mediaviewmodal>
