function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClarityModule } from "@clr/angular";
import dayjs from "dayjs";
import JSZip from 'jszip';
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { LeftPadPipe } from "src/app/pipes/left-pad.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { MangelMediaApiFuncService } from "src/app/services/_abstract/mangel-media-api-func.service";
import { MediaViewModalComponent, TitleAndFileNames } from "../../_shared/mediaviewmodal/mediaviewmodal.component";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { previewMap } from "../../detailview/previewMap";
import { KostenerfassungComponent } from "./kostenerfassung/kostenerfassung.component";


@Component({
  selector: "app-auftrag-detail",
  templateUrl: "./auftrag-detail.component.html",
  styleUrls: ["./auftrag-detail.component.scss"],
  imports: [CommonModule, ClarityModule, PreviewThumbnailsComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, LeftPadPipe, DotDotDotPipe,
    HideIDColumnsPipe, TablePrettyPrintPipe, BackendLocaleDatePipe, KostenerfassungComponent, MediaViewModalComponent],
  providers: [BackendLocaleDatePipe],
  standalone: true
})
export class AuftragDetailComponent {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  kostenChanged = false;
  auftragId: number;
  auftrag: any;
  firmen = [];
  firmenId: number;
  confirmDeletMangelAuftragVisible = false;
  rowDeletMangelAuftrag: any;
  map:any;
  showGIS:boolean = false;

  titel: Array<any>;
  auftragsarten: Array<any>;
  kostenart: Array<any>;
  result: Array<any>;
  auftragsgroups: Array<any>;
  verantwortlicher: Array<any>;
  kostenartmangel: Array<any>;

  auftragFormGroup = new UntypedFormGroup({
    titel: new UntypedFormControl("", [Validators.required]),
    firma: new UntypedFormControl({ value: "", disabled: true }, [Validators.required]),
    termin: new UntypedFormControl(""),
    terminEnde: new UntypedFormControl(""),
    auftragsart: new UntypedFormControl(""),
    auftragsnummer: new UntypedFormControl(""),
    kostenart: new UntypedFormControl(""),
    bemerkung: new UntypedFormControl(""),
    gruppenid: new UntypedFormControl({ value: "", disabled: true }, [Validators.required]),
  });

  rueckmeldungForm = new UntypedFormGroup({
    datum: new UntypedFormControl(BackendLocaleDatePipe.now, [Validators.required]),
    rueckmeldetext: new UntypedFormControl(""),
    teilerneuerung: new UntypedFormControl(""),
    verantwortlich: new UntypedFormControl(""),
    kostenart: new UntypedFormControl(""),
    bemerkung: new UntypedFormControl(""),
  });

  @ViewChild(PreviewThumbnailsComponent, { static: false }) preview: PreviewThumbnailsComponent;

  objTable = {
    rows: null,
    columns: [],
  };

  mangelTable = {
    rows: null,
    columns: [],
  };

  mangelTablrueck = {
    rows: null,
    columns: [],
  };

  abnahmeTable = {
    rows: null,
    columns: [],
  };

  activeTab: any = {
    auftrag: true,
    rueckmeldung: false,
    abnahme: false,
    kosten: false,
  };

  mangelMedia: TitleAndFileNames[];
  showRueckTab = true;

  dataRefresh: EventEmitter<any>;
  refreshSubscription: Subscription;
  refreshAbnahmen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tabRefresh: EventEmitter<void> = new EventEmitter();

  set data({ data: { id, auftrag, maengel, objekte }, recalledData: vorData, dataRefresh }: any) {
    if (id) {
      this.auftragId = id;
      this.loadListen();
      this.loadFirmen();
    }

    if (auftrag) {
      this.auftrag = auftrag;
      if (!vorData) this.setAuftrag(auftrag);
    }

    if (maengel?.rows?.length) {
      if (this.mangelTable.columns?.length == 0) {
        this.mangelTable.columns = maengel.columns;
        this.mangelTablrueck.columns = maengel.columns;
      }
      this.mangelTable.rows = maengel.rows;
      this.mangelTablrueck.rows = maengel.rows;

    }

    if (objekte?.rows?.length) {
      if (!this.objTable.columns?.length)
        this.objTable.columns = objekte.columns;
      this.objTable.rows = objekte.rows;
    }

    if (vorData) {
      this.auftragFormGroup.setValue(vorData.auftragForm);
      this.activeTab = vorData.activeTab;
      this.auftrag.Auftragsstatus ||= vorData.status;
      setTimeout(() => {
        if (this.preview) this.preview.rawPreviewThumbnails = vorData.preview;
      }, 10);
    }

    if (!vorData || vorData.refreshAbnahmen.value)
      this.loadAuftrag();
    else
      this.abnahmeTable = vorData.abnahmeTable;

    if (auftrag?.Auftragsstatus && !vorData?.preview?.length) {
      this.showRueckTab = false;
    }

    if (dataRefresh) {
      this.dataRefresh = dataRefresh;
    }
  }

  set dateforall(newdate: Date) {
    for (let i = 0; i < this.mangelTable.rows.length; i++) {
      this.mangelTable.rows[i].termin = newdate;
    }
  }

  trial = false;

  constructor(
    private apiService: APIService,
    private changeDet: ChangeDetectorRef,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private spinner: SpinnerService,
    private authService: AuthService,
    private overlayService: OverlayService,
    protected localeDate: BackendLocaleDatePipe,
    protected media: MangelMediaApiFuncService,
  ) {
    this.trial = this.authService.getToken().trial;
  }

  loadListen() {
    this.apiService
      .getAuftraege()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
          jsZip.loadAsync(res).then((zip) => {
            return zip.file("tmp.txt").async("string");
          }).then((zipf) => {
            zipf = JSON.parse(zipf);
            this.result = [...new Set(zipf.rows.map((item) => item.titel))];
            this.result = this.result.filter(notEmpty);
            this.titel = this.result.sort();

            this.result = [...new Set(zipf.rows.map((item) => item.kostenart))];
            this.result = this.result.filter(notEmpty);
            this.kostenart = this.result.sort();

            this.result = [...new Set(zipf.rows.map((item) => item.auftragart))];
            this.result = this.result.filter(notEmpty);
            this.auftragsarten = this.result.sort();
          });

        }
      });

    this.apiService
      .getfeldliste("omazs", "Verantwortlich")
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          this.verantwortlicher = [
            ...new Set(res.rows.map((item) => item.Verantwortlich)),
          ];
        }
      });

    this.apiService
      .getfeldliste("omazs", "Kostenart")
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          this.kostenartmangel = [
            ...new Set(res.rows.map((item) => item.Kostenart)),
          ];
        }
      });

    this.apiService
      .getAuftragsGroups()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          this.auftragsgroups = res.map((item) => {
            return {
              id: item.id,
              mBezeichnung: `${item.Bezeichnung}`,
            };
          });
        }
      });
  }

  loadFirmen() {
    this.apiService
      .getFirmen()
      .pipe(first())
      .subscribe((res: any) => {
        this.firmen = res.rows;
        this.firmenId = this.firmen.find((firma) => firma.Firma == this.auftrag.Firma)?.ID ?? undefined;
      });
  }

  deleteMangelAuftrag(row: any) {
    if (this.mangelTablrueck.rows.length === 1) {
      this.toastr.warning(
        this.mrTranslate.transform(
          "Löschen ist nicht möglich, es muss mindestes 1 Mangel im Auftrag enthalten sein."
        )
      );
    } else {
      this.rowDeletMangelAuftrag = row;
      this.confirmDeletMangelAuftragVisible = true;
    }
  }

  dodeleteMangelAuftrag(): void {
    this.mangelTablrueck.rows = this.mangelTablrueck.rows.filter(
      (obj) => obj !== this.rowDeletMangelAuftrag
    );
    this.confirmDeletMangelAuftragVisible = false;
    this.mangelTable = this.mangelTablrueck;

    this.apiService
      .deleteMangelAuftrag(this.rowDeletMangelAuftrag.OMAZSID)
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.success) {
          const message = this.mrTranslate.transform(
            "Mangel aus Auftrag gelöscht"
          );
          this.toastr.success(message);
        } else {
          const message = this.mrTranslate.transform("Fehler beim Speichern");
          this.toastr.error(res?.error || null, message);
        }
        this.dataRefresh.emit();
      });
  }

  loadAuftrag() {
    this.apiService
      .getAuftrag(this.auftragId)
      .pipe(first())
      .subscribe(({ abnahme }: any) => {

        if (abnahme?.rows?.length) {
          if (!this.abnahmeTable.columns?.length)
            this.abnahmeTable.columns = abnahme.columns;
          this.abnahmeTable.rows = abnahme.rows;
        }
        this.resizeGrids();
      });
  }

  setAuftrag(auftrag: any) {
    this.auftragFormGroup.controls.titel.setValue(auftrag.Titel || "");
    this.auftragFormGroup.controls.firma.setValue(auftrag.Firma || "");
    this.auftragFormGroup.controls.termin.setValue(auftrag["Termin Anfang"] || "");
    this.auftragFormGroup.controls.terminEnde.setValue(auftrag["Termin Ende"] || "");
    this.auftragFormGroup.controls.auftragsart.setValue(auftrag.Auftragsart || "");
    this.auftragFormGroup.controls.auftragsnummer.setValue(auftrag.AuftragNr || "");
    this.auftragFormGroup.controls.kostenart.setValue(auftrag.Kostenart || "");
    this.auftragFormGroup.controls.bemerkung.setValue(auftrag.Bemerkung || "");
    this.auftragFormGroup.controls.gruppenid.setValue(auftrag.Gruppe || "");
  }

  getAuftragsgruppeName(id: number) {
    let gruppe;
    if (this.auftragsgroups)
      gruppe = this.auftragsgroups.filter((item) => item.id == id)[0]
        ?.mBezeichnung;
    return gruppe || undefined;
  }

  saveAuftrag() {
    const formValue = this.auftragFormGroup.value;

    if (dayjs(formValue.terminEnde).isBefore(formValue.termin, 'days'))
      return this.toastr.warning(
        this.mrTranslate.transform("Das Startdatum muss vor dem Enddatum liegen.")
      );

    if (!formValue.titel) {
      return this.toastr.warning(
        this.mrTranslate.transform("Bitte geben Sie eine Titel an.")
      );
    }

    this.apiService
      .updateAuftrag(this.auftragId, formValue)
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.success) {
          const message = this.mrTranslate.transform("Auftrag gespeichert");
          this.toastr.success(message);
        } else {
          const message = this.mrTranslate.transform("Fehler beim Speichern");
          this.toastr.error(res?.error || null, message);
        }
        this.dataRefresh.emit();
      });
  }

  openAbnahmeProtokoll(row: any = undefined) {
    this.overlayService.setOverlay({
      overlay: "abnahmeProtokoll",
      zIndex: 8,
      data: {
        abnahmeId: row?.abnahmeId ?? undefined,
        id: this.auftragId,
        titel: this.auftragFormGroup.value.titel,
        auftragnehmer: this.auftragFormGroup.value.firma,
        objTable: this.objTable,
        firmenId: this.firmenId,
      },
      recallData: {
        auftragForm: this.auftragFormGroup.getRawValue(),
        activeTab: this.activeTab,
        status: this.auftrag.Auftragsstatus,
        refreshAbnahmen: this.refreshAbnahmen,
        abnahmeTable: this.abnahmeTable,
        preview: this.preview?.rawPreviewThumbnails,
      },
      dataRefresh: this.dataRefresh,
      refreshAbnahmen: this.refreshAbnahmen,
    });
  }
  async sendRueckmeldung() {
    this.spinner.enable();

    let ihbilder = await this.preview.packUnsavedFiles();
    const formValues = JSON.parse(JSON.stringify(this.rueckmeldungForm.value));
    const date = formValues.datum;
    delete formValues.datum;
    const maengel = this.mangelTablrueck.rows.map((row) => {
      return {
        ...formValues,
        kauftrid: this.auftragId,
        omazsid: row.omazsid,
        date: date,
        instandsetzer: this.firmenId,
      };
    });
    this.apiService
      .sendMultiRueckmeldung({ maengel: maengel, bilder: ihbilder })
      .pipe(first())
      .subscribe(({ success, message }: any) => {
        if (success) {
          message = this.mrTranslate.transform("Rückmeldung gespeichert");
          this.auftrag = { ...this.auftrag, Auftragsstatus: 1 };
          this.rueckmeldungForm.disable();
          this.dataRefresh.emit();
          this.toastr.success(message);
        }
        else this.toastr.error(message);
        this.spinner.disable();
      });
  }

  public close() {
    if (this.kostenChanged) this.dataRefresh.emit();
    setTimeout(() => {
      this.closedialog.next(true);
    });
  }

  previewMap(row: any) {
    let geometry = this.getObjectGeometry(row);
    if (geometry !== null) {
      this.showGIS = true;
      setTimeout(() => {
        previewMap(row, geometry,this.map);
      });
    }
  }

  getObjectGeometry(row: any) {
    let geometry = this.objTable.rows?.find(
      (el) => el.ostammid == row.ostammid
    ).geometry;
    return geometry?.type ? geometry : null;
  }


  resizeGrids() {
    this.changeDet.detectChanges();
  }

  setAllFirmen(event: any) {
    for (let i = 0; i < this.mangelTable.rows.length; i++) {
      this.mangelTable.rows[i].auftragnehmer = event.target.value;
    }
  }

  tabChange() {
    this.tabRefresh.emit();
  }

  deleteAuftrag() {
    const text = this.mrTranslate.transform("Auftrag wirklich löschen?");
    if (confirm(text)) {
      this.spinner.enable();
      this.apiService
        .deleteAuftrag(this.auftragId)
        .pipe(first())
        .subscribe((res: unknown) => {
          if (res["status"] === "success") {
            const message = this.mrTranslate.transform("Auftrag gelöscht");
            this.toastr.success(message);
            this.close();
            this.dataRefresh.emit();
          } else {
            const message = this.mrTranslate.transform("Fehler beim Löschen");
            this.toastr.error(res["error"] || null, message);
          }
        });
    }
  }
}

export interface UpdateAuftrag {
  titel: string;
  firma: string;
  termin: string;
  terminEnde: string;
  auftragsart: string;
  auftragsnummer: string;
  kostenart: string;
  bemerkung: string;
}
