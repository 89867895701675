import { Inject, Injectable } from '@angular/core';
import { APIService } from '../APIService/api.service';
import jwtDecode from 'jwt-decode';
import { BehaviorSubject, firstValueFrom, Observable, throwError } from 'rxjs';
import { map, catchError, first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthNewService } from './auth.new.service';
import OktaAuth, { UserClaims } from "@okta/okta-auth-js";
import { OKTA_AUTH } from '@okta/okta-angular';
import dayjs from 'dayjs';

import { WebSocketService } from '../Websocket/websocket.service';
import * as CryptoJS from 'crypto-js';
import { EncryptionService } from '../encryption.service';
import { MsalConfigService } from '../msal-config.service';
// import { MsalService } from '@azure/msal-angular';
// import { EncryptionService } from '../encryption.service';


export enum LoginMethod {
  Email,
  Okta,
  Microsoft
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedin: BehaviorSubject<Boolean>;
  token;
  $token: BehaviorSubject<any>;
  redirectURL: string;
  twofa;
  tempdata;

  private _loginMethod: LoginMethod | undefined;
  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    // private msalService: MsalService,
    private apiservice: APIService,
    private websocket: WebSocketService,
    private msalConfigService: MsalConfigService,
    private router: Router,
    private authNewService: AuthNewService,
    private encryptaes: EncryptionService,
  ) {
    this.loggedin = new BehaviorSubject(false);
    this.$token = new BehaviorSubject({});
    this.loggedin.next(this.isLoggedIn());
  }

  public getRight(mode: string, key: string, otypid?: number) {
    if (this.token && this.token.rights && this.token.rights[mode]) {
      let searchArr = this.token.rights[mode];
      if (mode == ' type' && otypid != undefined) {
        for (let i = 0; i < searchArr.length; i++) {
          const typeRights = searchArr[i];
          if (typeRights.OTYPID == otypid) {
            searchArr = typeRights.rights;
            break;
          }
        }
      }

      for (let e = 0; e < searchArr.length; e++) {
        const right = searchArr[e];
        if (right.Key == key) {
          return right.Value === 'true';
        }
      }
      return undefined;
    }
  }

  public get loginMethod() {
    return this._loginMethod;
  }

  public set loginMethod(loginMethod: LoginMethod) {
    this._loginMethod = loginMethod;
  }

  public checkemail(email: String) {
    return this.apiservice.checkmail(email).pipe(first(), map((data: any) => {
      return data;
    }), catchError((error: any) => new BehaviorSubject(false)));
  }

  public updatepassword(email: String, userid: String, pw: String) {
    return this.apiservice.updatepassword(email, userid, pw).pipe(first(), map((data: any) => {
      return data;
    }), catchError((error: any) => new BehaviorSubject(false)));
  }


  public getOktaMRproToken(token: String) {
    return this.apiservice.getOktaMRproToken(token).pipe(first(), map((data: any) => {
      return data;
    }), catchError((error: any) => new BehaviorSubject(false)));
  }



  public sendforgotemail(email: String, code: String) {
    return this.apiservice.sendforgotemail(email, code).pipe(first(), map((data: any) => {
      return data;
    }), catchError((error: any) => new BehaviorSubject(false)));
  }

  public loginGetDatabases(email: string, pwhash: string) {
    const res = this.apiservice.loginGetDatabases(email, pwhash).pipe(
      first(),
      map((data: any) => {
        this._loginMethod = LoginMethod.Email;
        return data;
      }),
      // Statt new BehaviorSubject(error) besser:
      catchError((error: any) => throwError(() => error))
    );

    return res;
  }

  public login(email: String, pwhash: String, db: any, karteFileID: String) {
    return this.apiservice.login(email, pwhash, db, karteFileID).pipe(first(), map((data: any) => {
      this._loginMethod = LoginMethod.Email;
      return this.handleLogin(data);
    }), catchError((error: any) => new BehaviorSubject(error)));
  }

  public internlogin(email: String, pwhash: String, domian: String, karteFileID: String) {
    return this.apiservice.internlogin(email, pwhash, domian, karteFileID).pipe(first(), map((data: any) => {
      this._loginMethod = LoginMethod.Email;
      return data;
    }), catchError((error: any) => new BehaviorSubject(error)));
  }

  public send2FASMS(mobil: string, code: string) {
    return this.apiservice.send2FASMS(mobil, code).pipe(first(), map((data: any) => {
      return data;
    }), catchError((error: any) => new BehaviorSubject(error)));
  }

  public async oktaLogin(claims: UserClaims) {
    if (this.isLoggedIn()) {
      const data = await firstValueFrom(this.authNewService.oktaLogin({
        Email: claims.email,
        Token: undefined,
        Forename: claims.name,
        Lastname: claims.family_name,
        Username: claims.preferred_username
      }));

      return this.handleLogin(data);
    } else {
      const data = await firstValueFrom(this.authNewService.oktaLogin({
        Email: claims.email,
        Token: undefined,
        Forename: claims.name,
        Lastname: claims.family_name,
        Username: claims.preferred_username
      }));

      this._loginMethod = LoginMethod.Okta;
      return this.handleLogin(data);

    }
  }

  public msalLogin(email: string, token: string): Observable<any> {
    let db = "";
    if (localStorage.getItem("msal") != null) {
      let msal = JSON.parse(this.encryptaes.decryptionAES(localStorage.getItem("msal")));
      db = msal.db;
    }

    return this.apiservice.msalLogin(email, token, db).pipe(
      tap((data) => {

        this._loginMethod = LoginMethod.Microsoft;
        this.handleLogin(data);

      }),
      catchError((error) => {
        console.error("[MSAL] API Login failed:", error);
        return throwError(() => error); // Ensure error propagates to the caller
      })
    );
  }

  public refreshLogin(email: String, refreshToken: String) {
    return this.apiservice.refreshLogin(email, refreshToken).pipe(first(), map((data: any) => {
      this.handleLogin(data);
    }), catchError((error: any) => new BehaviorSubject(false)));
  }


  public handle2FALogin() {
    let data = this.tempdata;

    localStorage.setItem('jwt', data.token);
    localStorage.setItem('rights', this.encrypt(JSON.stringify(data.rights)));
    localStorage.setItem('hasMap', this.encrypt(data.hasMap.toString()));
    localStorage.setItem('auftraglaenge', this.encrypt(data.auftraglaenge));
    localStorage.setItem('jwt_exp', data.expiresAt);

    this.token = jwtDecode(data.token);
    this.$token.next(this.token);
    this.loggedin.next(true);


    if (data.dbintern)
      localStorage.setItem('intdb', data.dbintern);
    else
      localStorage.setItem('intdb', '');

    if (data.image)
      localStorage.setItem('userimg', data.image);

  }


  public handleLogin(data) {
    if (data.refresh) {
      localStorage.setItem('refresh', data.refresh.refreshToken);
      localStorage.setItem('refresh_exp', data.refresh.expiresAt);
    }
    if (data.token) {

      this.twofa = data.twofa;
      if (this.twofa == 1 || this.twofa == 2) {
        this.tempdata = data;
        return true;
      }


      localStorage.setItem('jwt', data.token);
      localStorage.setItem('rights', this.encrypt(JSON.stringify(data.rights)));
      localStorage.setItem('hasMap', this.encrypt(data.hasMap.toString()));
      localStorage.setItem('auftraglaenge', this.encrypt(data.auftraglaenge));
      localStorage.setItem('jwt_exp', data.expiresAt);

      this.token = jwtDecode(data.token);
      this.$token.next(this.token);
      this.loggedin.next(true);


      if (data.dbintern)
        localStorage.setItem('intdb', data.dbintern);
      else
        localStorage.setItem('intdb', '');

      if (data.image)
        localStorage.setItem('userimg', data.image);

      if (localStorage.getItem("msal") == null && data.usemsal == true) {
        this.apiservice.getMSALTenantId().subscribe((data: any) => {
          if (data) {
            let msal = {
              appid: data.ADAppid,
              tenid: data.ADTenant,
            }
            localStorage.setItem('msal', (this.encryptaes.decryptionAES(JSON.stringify(msal))));
          }
        }), catchError((error: any) => new BehaviorSubject(error));
      }

      return true;
    }

    if (data.error) {
      return data;
    }
    if (data.info) {
      return data;
    }

    //this.apiservice.refreshJWT();
  }

  public async logout() {

    this.apiservice
      .logout()
      .pipe(first())
      .subscribe((val: any) => {
        if (val.success) {

        }
      });

    window.onbeforeunload = function () { };
    localStorage.removeItem('jwt');
    localStorage.removeItem('jwt_exp');
    localStorage.removeItem('refresh');
    localStorage.removeItem('refresh_exp');
    localStorage.removeItem('userimg');
    localStorage.removeItem('intdb');

    // Clear cookies after successful login
    document.cookie = 'Token-Data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'Rights-Data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'Group-Data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'HasMap-Data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'DT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'idx=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'okta-oauth-nonce=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'okta-oauth-redirect-params=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'okta-oauth-state=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'okta_user_lang=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'srefresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    this.token = undefined;
    this.loggedin.next(false);

    if (localStorage.getItem("loginMethod") != null) {
      if (localStorage.getItem("loginMethod") == "Microsoft") {
        localStorage.removeItem('loginMethod');
      }
    }

    if (this._loginMethod == LoginMethod.Okta || await this.oktaAuth.isAuthenticated()) {
      await this.oktaLogoutFromAppOnly();
    } else if (this._loginMethod == LoginMethod.Microsoft) {
      await this.msalLogoutFromApp();
    } else {
      this.router.navigateByUrl('/');
      setTimeout(() => {
        // why? - weil token & user & rights nur einmal wegen pipe(firsrt()) in app.component geladen werden;
        // filter() passt nicht, weil es wird user redirect, wenn refresh ohne logout ausgeführt wurde
        // TODO: unterschiedliche Observer für refreshToken u. neu Einloggen
        location.reload();
      });
    }
  }

  public isLoggedIn() {
    const exp = this.getExpiration();
    let test = exp && dayjs().isBefore(exp);
    if (test && !this.token) {
      let tokenFromLocalStorage = localStorage.getItem('jwt');
      if (tokenFromLocalStorage) {
        try {
          this.token = jwtDecode(tokenFromLocalStorage);
        } catch (error) {
          console.error('jwtDecodeError:', error);
        }
        if (this.token) {
          this.$token.next(this.token);
        } else {
          this.$token.next({})
        }
      }


    }
    return test;
  }


  async oktaLogoutFromAppOnly(): Promise<void> {
    try {
      this.oktaAuth.tokenManager.clear();
      const cookiesToRemove = [
        "okta-token-storage_idToken",
        "okta-token-storage_accessToken",
        "okta-oauth-nonce",
        "okta-session-expiry"
      ];

      cookiesToRemove.forEach((cookieName) => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      });

      await this.router.navigateByUrl('/login');
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }

  async msalLogoutFromApp(): Promise<void> {
    document.cookie.split(";").forEach((cookie) => {
      const cookieName = cookie.split("=")[0].trim();

      if (cookieName.includes("msal.") || cookieName.includes("login.windows.net")) {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${window.location.hostname}`;
      }
    });

    const msalInstace = await this.msalConfigService.getInstance()
    await msalInstace.clearCache();
    localStorage.removeItem("msal");
    await msalInstace.logoutRedirect({
      postLogoutRedirectUri: `${window.origin}/login`,
    });
  }

  public getRefreshToken() {
    return {
      passphrase: localStorage.getItem('refresh'),
      expiresAt: parseInt(localStorage.getItem('refresh_exp'))
    };
  }

  public get$token() {
    var token = this.$token;
    try {
      token.value.rights = JSON.parse(this.decrypt(localStorage.getItem('rights')));
    } catch (error) { }
    return token;
  }

  public getToken() {
    if (!this.token) {
      try {
        this.token = jwtDecode(localStorage.getItem('jwt'));
      } catch (error) {
        console.error('jwtDecodeError:', error);
      }
    }
    if (!this.token?.rights) {
      this.token.rights = JSON.parse(this.decrypt(localStorage.getItem('rights')));
    }
    return this.token;
  }

  public getLoggedIn() {
    return this.loggedin;
  }

  public getExpiration() {
    let exp = localStorage.getItem('jwt_exp');
    if (exp) return dayjs.unix(parseInt(exp));
    else return undefined;
  }

  public encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, localStorage.getItem("jwt")).toString();
  }

  public decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, localStorage.getItem("jwt")).toString(CryptoJS.enc.Utf8);
  }
}
