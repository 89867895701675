function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined && value != '';
}

import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClrCheckboxModule, ClrCommonFormsModule, ClrDatagrid, ClrDatagridModule, ClrDatalistModule, ClrDatepickerModule, ClrIconModule, ClrInputModule, ClrPopoverHostDirective, ClrSelectModule, ClrSpinnerModule, ClrStopEscapePropagationDirective, ClrTextareaModule, ClrWizardModule } from "@clr/angular";
import { forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { APIService } from "../../../services/APIService/api.service";

import { NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, TitleCasePipe } from "@angular/common";
import dayjs from "dayjs";
import { ToastrService } from "ngx-toastr";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { DotDotDotPipe } from "../../../pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "../../../pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "../../../pipes/tablePrettyPrint.pipe";

@Component({
    selector: "app-newauftragmodal",
    templateUrl: "./newauftragmodal.component.html",
    styleUrls: ["./newauftragmodal.component.scss"],
    providers: [BackendLocaleDatePipe],
    imports: [
        NgIf,
        ClrSpinnerModule,
        ClrWizardModule,
        ClrCheckboxModule,
        FormsModule,
        ClrCommonFormsModule,
        ReactiveFormsModule,
        ClrDatalistModule,
        NgFor,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrDatepickerModule,
        ClrSelectModule,
        ClrTextareaModule,
        ClrDatagridModule,
        NgSwitch,
        NgSwitchCase,
        ClrIconModule,
        NgStyle,
        ClrInputModule,
        TitleCasePipe,
        TablePrettyPrintPipe,
        HideIDColumnsPipe,
        DotDotDotPipe,
        MrTranslatePipe,
        BackendLocaleDatePipe,
    ],
    standalone: true
})
export class NewauftragmodalComponent implements AfterViewInit {
  _openModal: boolean = false;
  set openModal(newVal: boolean) {
    if (!newVal) {
      this.modalOpen.emit(false);
    }
    this._openModal = newVal;
  }
  get openModal() {
    return this._openModal;
  }
  isLoading: boolean = false;

  firmen: Array<any>;
  titel: Array<any>;
  auftragsart: Array<any>;
  kostenart: Array<any>;
  result: Array<any>;

  auftragsgroups: Array<any>;

  maengelOrig: any = undefined;
  maengel: any = undefined;
  selectedMaengel: any = [];
  _selectedObjekte: any = [];

  set selectedObjekte(newObjekte: any) {
    if (newObjekte.length > 0) {
      let ostamms = newObjekte.map((obj) => obj.OSTAMMID);
      this.maengel.rows = JSON.parse(this.maengelOrig).filter(
        (maeng) => ostamms.indexOf(maeng.ostammid) != -1
      );
    }
    this._selectedObjekte = newObjekte;
  }

  get selectedObjekte() {
    return this._selectedObjekte;
  }

  objekte: any = undefined;
  iconColumns: string[] = ["typ", "sperrstatus", "lfstatus", "rep", "icon"];

  _objectSelectMode = true;
  set objectSelectMode(newVal: boolean) {
    if (!newVal) {
      this.maengel.rows = JSON.parse(this.maengelOrig);
    }
    this.selectedObjekte = [];
    this.createNewMaengel = false;
    this.planResize(this.maengelDatagrid);
    this.planResize(this.objekteDatagrid);
    this._objectSelectMode = newVal;
  }
  get objectSelectMode() {
    return this._objectSelectMode;
  }

  @ViewChild("objekteDatagrid", { read: ClrDatagrid }) objekteDatagrid;
  @ViewChild("maengelDatagrid", { read: ClrDatagrid }) maengelDatagrid;
  @ViewChild("newMangelTable", { read: ClrDatagrid }) newMangelTable;

  @Output() modalOpen = new EventEmitter<boolean>();
  @Output() reload = new EventEmitter<void>();

  auftragGroup = new UntypedFormGroup({
    startdate: new UntypedFormControl("", [Validators.required]),
    enddate: new UntypedFormControl("", [Validators.required]),
    auftragnehmer: new UntypedFormControl("", [Validators.required]),
    auftragsgruppe: new UntypedFormControl("", [Validators.required]),
    auftragsart: new UntypedFormControl(""),
    auftragsnr: new UntypedFormControl(""),
    titel: new UntypedFormControl("", [Validators.required]),
    kostenart: new UntypedFormControl(""),
    bemerkung: new UntypedFormControl(""),
    type: new UntypedFormControl("Auftagsliste"),
  });

  newMangelGroup = new UntypedFormGroup({
    titel: new UntypedFormControl(""),
    beschreibung: new UntypedFormControl(""),
    umfang: new UntypedFormControl(""),
    mengeneinheit: new UntypedFormControl(""),
    bemerkung: new UntypedFormControl(""),
  });

  mengeneinheiten = [];

  newMaengel: any = {
    columns: [],
    rows: [],
  };

  createNewMaengel = false;
  rownr = 17;

  trial;

  constructor(
    private apiservice: APIService,
    private basicStore: BasicStoreService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private overlay: OverlayService,
    private authservice: AuthService,
    private mrTranslate: MrTranslatePipe,
    private authService: AuthService,
    protected localeDate: BackendLocaleDatePipe
  ) {
    this.trial = this.authService.getToken().trial;
  }

  ngAfterViewInit(): void {}

  refreshDatagrid(dg: ClrDatagrid) {
    this.planResize(dg);
  }

  deleteNewMangel(row) {
    this.newMaengel.rows.splice(this.newMaengel.rows.indexOf(row), 1);
    if (this.newMangelTable) this.planResize(this.newMangelTable);
  }

  createMangel() {
    let values = this.newMangelGroup.value;
    let keys = Object.keys(values);
    let colsEmpty = this.newMaengel.columns.length > 0 ? false : true;

    for (const key of keys) {
      if (colsEmpty) this.newMaengel.columns.push({ id: key });
    }
    this.newMaengel.rows.push(values);
    this.newMangelGroup.reset();

    this.planResize(this.newMangelTable);
  }

  onSubmit() {
    let objMangel = {};
    if (this.createNewMaengel) {
      this.selectedObjekte.forEach((objekt) => {
        if (!objMangel[objekt.OSTAMMID]) objMangel[objekt.OSTAMMID] = [];
        this.newMaengel.rows.forEach((mangel) => {
          objMangel[objekt.OSTAMMID].push(mangel);
        });
      });
    } else {
      this.selectedMaengel.forEach((mangel) => {
        if (!objMangel[mangel.ostammid]) {
          objMangel[mangel.ostammid] = [];
        }
        objMangel[mangel.ostammid].push(mangel.omazsid);
      });
    }

    let data = this.auftragGroup.value;
    data.omazsids = objMangel;
    data.language = localStorage.getItem("language");

    if (data.startdate && data.enddate) {
      data.typ="Auftagsliste";
      if (!dayjs(data.enddate).isBefore(data.startdate)) {
        let valid = true;
        if (
          data.titel == "" ||
          data.auftragnehmer == "" ||
          data.startdate == "" ||
          data.enddate == "" ||
          (data.omazsid && Object.keys(data.omazsid).length == 0)
        ) {
          valid = false;
          this.toastr.warning(
            this.mrTranslate.transform(
              "Bitte füllen Sie Start- & Enddatum, Auftragsnehmer & -nummer, sowie mindestens einen Mangel aus."
            )
          );
        }
        if (data.auftragsgruppe == "") {
          valid = false;
          this.toastr.warning(
            this.mrTranslate.transform(
              "Bitte füllen Sie Auftragsgruppe aus."
            )
          );
        }

        if (valid) {
          this.spinner.enable();
          this.apiservice
            .sendAuftrag(data)
            .pipe(first())
            .subscribe((val) => {
              if (val) {
                this.auftragGroup.reset();
                this.selectedObjekte = [];
                this.selectedMaengel = [];
                this.newMaengel.rows = [];
                this.newMaengel.columns = [];
                this.createNewMaengel = false;
                this.reload.emit();
                this.openModal = false;

                this.toastr.success(
                  this.mrTranslate.transform("Daten gespeichert")
                );
                this.spinner.disable();
                this.overlay.setOverlay({
                  overlay: "viewAuftrag",
                  data: val,
                  auftragsnr: data.auftragsnr,
                  zIndex: 5,
                });
              }
            });
        }
      } else {
        this.toastr.warning(
          this.mrTranslate.transform(
            "Das Startdatum muss vor dem Enddatum liegen."
          )
        );
      }
    }
  }

  getFirmenName(id: number) {
    let firm;
    if (this.firmen)
      firm = this.firmen.filter((firm) => firm.ID == id)[0]?.mFirma;
    return firm || undefined;
  }

  getAuftragsgruppeName(id:number){
    let gruppe;
    if (this.auftragsgroups)
    gruppe = this.auftragsgroups.filter((item) => item.id == id)[0]?.mBezeichnung;
    return gruppe || undefined;
  }


  changePage(event) {
    if (this.objekteDatagrid) this.planResize(this.objekteDatagrid);
    if (this.maengelDatagrid) this.planResize(this.maengelDatagrid);
  }

  planResize(grid: ClrDatagrid) {
    if (grid) {
      setTimeout(() => {
        grid.dataChanged();
        grid.resize();
      });
    }
  }

  getSelectedObjekte() {
    return this.objekte?.rows?.filter(
      (obj) => this.selectedObjekte.indexOf(obj.OSTAMMID) != -1
    );
  }

  getSelectedMaengel() {
    return this.maengel?.rows?.filter(
      (obj) => this.selectedMaengel.indexOf(obj) != -1
    );
  }

  openTheModal() {
    this.isLoading = true;
    forkJoin({
      anlagen: this.basicStore.getComponentStoreAsync("anlagen").pipe(
        first((val) => val && JSON.parse(val).anlagen.rows.length),
        map((res: any) => JSON.parse(res)?.anlagen)
      ),
      maengel: this.basicStore.getComponentStoreAsync("maengel").pipe(
        first((val) => val && JSON.parse(val).maengel.open != undefined),
        map((res: any) => JSON.parse(res)?.maengel?.open)
      ),
      firmen: this.apiservice.getFirmen().pipe(first()),
      // auftraege: this.apiservice.getAuftraege().pipe(first()),
      auftraege: this.basicStore.getComponentStoreAsync("auftraege").pipe(
        first((val) => val),
        map((res: any) => JSON.parse(res)?.auftraege)
      ),
      auftragsgruppen: this.apiservice.getAuftragsGroups().pipe(first()),
      mengeneinheiten: this.apiservice
        .getNewMangelValues(1, { category: "mengeneinheit", parameter: [] })
        .pipe(first()),
      token: this.authservice.get$token().pipe(first()),
    }).subscribe((res: any) => {
      this.modalOpen.emit(true);
      setTimeout(() => {
        this.openModal = true;
        this.isLoading = false;
      }, 100);
      this.objekte = res.anlagen;
      this.maengel = res.maengel;
      this.maengelOrig = JSON.stringify(res.maengel.rows);
      this.mengeneinheiten = res.mengeneinheiten;
      if (res.firmen) {
        this.firmen = res.firmen.rows.map((firma) => {
          return {
            ID: firma.ID,
            mFirma: `${firma.Firma}, ${firma.Abteilung}, ${firma.Ort}`,
          };
        });
      }

      if (res.auftraege && res.auftraege.rows.length) {
        this.result = [...new Set(res.auftraege.rows.map(item => item.titel))];
        this.result = this.result.filter(notEmpty);
        this.titel = this.result.sort();

        this.result = [...new Set(res.auftraege.rows.map(item => item.auftragart))];
        this.result = this.result.filter(notEmpty);
        this.auftragsart = this.result.sort();

        this.result = [...new Set(res.auftraege.rows.map(item => item.kostenart))];
        this.result = this.result.filter(notEmpty);
        this.kostenart = this.result.sort();
      }

      if (res.auftragsgruppen) {
        this.auftragsgroups = res.auftragsgruppen.map((item) => {
          return {
            id: item.id,
            mBezeichnung: `${item.Bezeichnung}`,
          };
        });
      }

      if (res.token) {
        let laenge =
          res.token.auftragslaenge ||
          parseInt(this.authService.decrypt(localStorage.getItem("auftraglaenge"))) ||
          3;
        this.auftragGroup.controls.startdate.setValue(
          BackendLocaleDatePipe.now
        );
        this.auftragGroup.controls.enddate.setValue(
          dayjs().add(laenge, "d").format(BackendLocaleDatePipe.format)
        );
      }
    });
  }

  reset(event) {
    if (event) {
      this.auftragGroup.reset();
      this.openModal = false;
    }
  }
}
