import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { APIService } from 'src/app/services/APIService/api.service';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { ClrAccordionModule, ClrDatagrid, ClrForm, ClrTabsModule } from '@clr/angular';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { BasicStoreService } from "../../../../services/BasicStore/basic-store.service";
import { DomSanitizer } from '@angular/platform-browser';
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { HilfeBeschreibungModalComponent } from '../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';


export interface FormulareRow {
  ID: number;
  Typ: string;
  FeldName: string;
  AuswahlOption: string;
  Bezeichnung: string;
}

@Component({
    selector: 'app-formularerstellungsdialog',
    templateUrl: './formularerstellungsdialog.component.html',
    styleUrls: ['./formularerstellungsdialog.component.scss'],
    imports: [CommonModule, ClarityModule, PreviewThumbnailsComponent, HilfeBeschreibungModalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule,
        HideIDColumnsPipe, TablePrettyPrintPipe, ClarityModule,
        ClrAccordionModule,
        ClrTabsModule
    ],
    standalone: true
})
export class FormularerstellungsdialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();
  set data(dataIn: any) {
    this.dataRefresh = dataIn.dataRefresh;
  }

  @ViewChild(HilfeBeschreibungModalComponent, { static: false })
  protected hilfeModal: HilfeBeschreibungModalComponent
    ;
  protected openHelper() {
    this.hilfeModal.item = { ...this.tab };
  }

  protected async saveHelper(sendObj: any) {

    this.tab["HelpBildBase64"] = sendObj.HelpBildBase64;
    this.tab["HelpBeschreibung"] = sendObj.HelpBeschreibung;
    this.tab["FileTyp"] = sendObj.FileTyp;

    let send = {
      status: "updatehilfe",
      HelpBildBase64: sendObj.HelpBildBase64,
      HelpBeschreibung: sendObj.HelpBeschreibung,
      FileTyp: sendObj.FileTyp,
      szaehnid: this.selectedTabelleID,
      images: [],
    }

    this.apiservice
      .editTabellenFAT(send)
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.hilfeModal.item = null;
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      })
  }

  public close() {
    this.closedialog.next(true);
  }

  optionMessTable: UntypedFormGroup = new UntypedFormGroup({
    messart: new UntypedFormControl('0'),
  });

  @ViewChild(PreviewThumbnailsComponent, { static: false })
  private preview: PreviewThumbnailsComponent

  modaltitel: string = '';
  copyformular: number = 0;
  copyvorlageID: number = 0;
  dorename: number = 0;
  copytabelle: number = 0;
  selectedformulare: any;
  selectedtabellen: any;
  cansortup: boolean = false;
  cansortdown: boolean = false;
  cansortuptabelle: boolean = false;
  cansortdowntabelle: boolean = false;
  selectionFormulareIndex: number = 0;
  selectionvorlageID: number = 0;
  isZaehlerAddVisible: boolean = false;
  structures: any;
  formulare: any;
  formulareOriginal: any;
  tabellen: any;
  tabellenOriginal: any;
  stammdaten = { rows: [] };
  spezifikationsdaten = { columns: [], mapping: {} };
  herstellerdaten = { columns: [] };
  canEdit: boolean = true;
  locked: boolean = true;
  typOptions = ['Normales Feld', 'Überschrift', 'Titel'];
  objektTypOptions = [1, 2, 3];
  auswahlOptions = ['', 'Stammdaten', 'Spezifikationsdaten', 'Herstellerdaten'];
  firstColumnId = 'Typ';
  secondColumnId = 'FeldName';
  formReflectData: {
    mainTitle: string | null;
    title: string | null;
    subheading: string | null;
    fieldType: string | null;
  }[] = [];
  vorlageNames: Array<{ ID: number, VorlageName: string }>;
  tabellenNames: Array<{ ID: number, Bezeichnung: string }>;
  tabellenNameAll: any;

  selectedVorlageName: string;
  // selectedTabelleName: string;
  selectedTabelleID: number | null = null;
  selected: any = [];
  selcode: any = '';
  selmesstyp: number = 0;
  tab: any;
  skizzen: any;
  curSkizzen: any = [];
  bemerkungmessung: string = '';

  delItems: any = [];
  trackById = (index, item) => item.ID;

  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild(ClrForm) form: ClrForm;

  @Output() modalOpen = new EventEmitter<boolean>();

  _currentPage: number = 1;
  set currentPage(newPage: number) {
    this._currentPage = newPage;
  }
  get currentPage() {
    return this._currentPage;
  }

  set _modalOpen(newVal: boolean) {
    this.modalOpen.emit(newVal);
  }

  tabellenName: any;

  loading: boolean = true;
  relaod: boolean = false;

  activeTab: any = {
    formulare: false,
    tabellen: false
  };


  isVorlageAddVisible: boolean = false;
  @ViewChild('_vorlage_new') _vorlage_new: ElementRef;
  @ViewChild('_zaehlercode') _zaehlercode: ElementRef
  @ViewChild('_zaehlerbezeichnung') _zaehlerbezeichnung: ElementRef

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private basicStore: BasicStoreService,
    private domSanitizer: DomSanitizer,

  ) {
    this.reloadVorlageNamesAndFormData();
    //this.reloadTabellen();
    this.getStructures();
  }

  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getStructures() {
    this.apiservice
      .getFieldStructures()
      .pipe(first())
      .subscribe((val: any) => {
        this.structures = val.rows;
      });
  }

  selectVorlageName(selectedVorlageName: string) {
    this.selectedVorlageName = selectedVorlageName;
    this.selectionvorlageID = this.getVorlageIDByName(selectedVorlageName);
    this.reloadTabellen();
    this.fetchDataForVorlage(this.selectionvorlageID);
  }

  selectTabelleID(selectedTabelleID: number, saved: boolean) {

    this.selectedTabelleID = selectedTabelleID;
    this.fetchDataForTabelleName(selectedTabelleID);

    this.tab = this.tabellenNames?.find((tabelle) => tabelle.ID === parseInt(selectedTabelleID.toString())) ?? {};
    if (saved) {
      this.tab['Bemerkung'] = this.bemerkungmessung;
    }

    this.selcode = this.tab['Code'];
    this.selmesstyp = this.tab['SourceType'];
    this.bemerkungmessung = this.tab['Bemerkung'];

    this.curSkizzen = [];


    Object.keys(this.skizzen).forEach((typid) => {
      if (typid == selectedTabelleID.toString()) {
        this.curSkizzen = this.skizzen[typid];

        setTimeout(() => {
          if (this.preview != undefined) {
            this.preview.clearPreview();
            if (this.curSkizzen.length > 0) {
              this.preview.unpackFiles(this.curSkizzen, true);
              //this.preview.rawPreviewThumbnails = this.curSkizzen;
            }
          }
        }, 200);
      }
    });
  }

  reloadTabellen() {
    this.apiservice.getTabellenNamesFAT(this.selectionvorlageID).subscribe(
      namesAndIDs => {

        this.skizzen = namesAndIDs.skizzen;
        this.tabellenNames = namesAndIDs.data.rows;
        this.tabellenNameAll = namesAndIDs.data.rows;
        this.tabellenName = [...new Set(namesAndIDs.data.rows.map(item => item.Code))];

        if (this.tabellenNames.length > 0) {
          this.selectedTabelleID = this.tabellenNames[0].ID;
          this.selectTabelleID(this.selectedTabelleID, false);
        }
      }
    );
    this.apiservice.getTabellenFAT().pipe(first()).subscribe(val => {
      this.tabellen = val;
      this.tabellenOriginal = JSON.parse(JSON.stringify(val['rows']));
      this.selectTabelleID(this.selectedTabelleID, false);
    });
  }

  reloadVorlageNamesAndFormData() {
    this.apiservice.getVorlageNames().subscribe(
      namesAndIDs => {
        this.vorlageNames = namesAndIDs;
        if (this.relaod == false) {
          this.selectedVorlageName = namesAndIDs[0]?.VorlageName || '';
          this.selectVorlageName(this.selectedVorlageName);
        }
      }
    );
    this.apiservice.getFormulare().pipe(first()).subscribe(val => {
      this.formulare = val;
      this.formulare['rows'].forEach(row => {
        if (row.Tabelle === 'Spezifikationsdaten') {
          row.Bezeichnung = this.spezifikationsdaten.mapping[row.Bezeichnung] || row.Bezeichnung;
        }
      });
      this.formulareOriginal = JSON.parse(JSON.stringify(val['rows']));
    });
  }

  getVorlageIDByName(name: string): number | null {
    const vorlage = this.vorlageNames.find((vorlage: {
      ID: number,
      VorlageName: string
    }) => vorlage.VorlageName === name);
    return vorlage ? vorlage.ID : null;
  }

  addZaehler() {
    if (this._zaehlercode.nativeElement.value.code == "" || this._zaehlerbezeichnung.nativeElement.value == "") {
      this.toastr.warning(this.mrTranslate.transform("Bitte füllen Sie Bereich und Bezeichnung aus."));
      return;
    }

    const tab = this.tabellenNameAll.find((tab) => tab.Bezeichnung.toLowerCase() == this._zaehlerbezeichnung.nativeElement.value.toLowerCase() && tab.Code.toLowerCase() == this._zaehlercode.nativeElement.value.toLowerCase());

    if (tab !== undefined) {
      this.toastr.warning(this.mrTranslate.transform("Tabelle existiert bereits, ändern Sie die Einstellungen"));
      return;
    }

    let typ = 0;

    let vals = this.optionMessTable.value;
    typ = vals.messart;


    let data = {
      FATVOLID: this.copyvorlageID.toString(),
      code: this._zaehlercode.nativeElement.value,
      bezeichnung: this._zaehlerbezeichnung.nativeElement.value,
      typ: typ.toString(),
      copyid: this.copytabelle.toString(),
      rename: this.dorename.toString()
    }

    this.apiservice.addZaehler(data)
      .pipe(first())
      .subscribe((val) => {
        if (!val.toString().includes('error')) {
          this.relaod = true;
          this.isZaehlerAddVisible = false;
          this._zaehlercode.nativeElement.value = '';
          this._zaehlerbezeichnung.nativeElement.value = '';
          //this.reloadVorlageNamesAndFormData();
          this.reloadTabellen();
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          this.copytabelle = 0;
          this.copyvorlageID = 0;
          this.relaod = false;
        }
        else {
          this.toastr.error(this.mrTranslate.transform("Daten wurden nicht gespeichert"));
        }
      });
  }

  addVorlage() {
    const isvorlage = this.vorlageNames.find((vorlage) => vorlage.VorlageName.toLowerCase() === this._vorlage_new.nativeElement.value.toLowerCase());

    if (isvorlage !== undefined) {
      this.toastr.warning(this.mrTranslate.transform("Vorlage existiert bereits"));
      return;
    }

    let data = {
      data: this._vorlage_new.nativeElement.value,
      copyid: this.copyformular
    }

    this.apiservice.addVorlage(data)
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.reloadVorlageNamesAndFormData();
          this.isVorlageAddVisible = false;
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          this.copyformular = 0;
          this.selectedVorlageName = this._vorlage_new.nativeElement.value,
            this.selectVorlageName(this.selectedVorlageName);
          this._vorlage_new.nativeElement.value = '';
        }
        else {
          this.toastr.error(this.mrTranslate.transform("Daten wurden nicht gespeichert"));
        }
      });

  }

  selectionTabellenChanged(value: any) {

    this.cansortuptabelle = false;
    this.cansortdowntabelle = false;

    var index = this.tabellen.rows.indexOf(value);
    if (index > 0)
      this.cansortuptabelle = true;

    if (index < this.tabellen.rows.length - 1)
      this.cansortdowntabelle = true;

  }

  sortUptable() {
    var index = this.tabellen.rows.indexOf(this.selectedtabellen);
    this.tabellen.rows = this.swapArrayTabellen(this.tabellen.rows, index, index - 1);
    this.selectionTabellenChanged(this.selectedtabellen);
  }

  sortDowntable() {
    var index = this.tabellen.rows.indexOf(this.selectedtabellen);
    this.tabellen.rows = this.swapArrayTabellen(this.tabellen.rows, index, index + 1);
    this.selectionTabellenChanged(this.selectedtabellen);
  }

  swapArrayTabellen(Array: any, Swap1: number, Swap2: number): any {
    let sort1 = Array[Swap1].I_SORT;
    let sort2 = Array[Swap2].I_SORT;
    let id1 = Array[Swap1].ID;
    let id2 = Array[Swap2].ID;

    Array[Swap2].I_Sort = sort1;
    Array[Swap1].I_Sort = sort2;

    var temp = Array[Swap1];
    Array[Swap1] = Array[Swap2]
    Array[Swap2] = temp


    let resort = {
      id1: id1,
      id2: id2,
      sort1: sort2,
      sort2: sort1,
    }

    let send = {
      rows: resort,
      images: [],
      szaehnid: 0,
    }

    this.apiservice
      .editTabellenFAT({ send })
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      })

    return Array;
  }

  selectionFormulareChanged(value: any) {
    this.cansortup = false;
    this.cansortdown = false;

    var index = this.formulare.rows.indexOf(value);
    if (index > 1)
      this.cansortup = true;

    if (index < this.formulare.rows.length - 1)
      this.cansortdown = true;

  }

  sortUp() {
    var index = this.formulare.rows.indexOf(this.selectedformulare);
    this.formulare.rows = this.swapArray(this.formulare.rows, index, index - 1);
    this.selectionFormulareChanged(this.selectedformulare);
  }

  sortDown() {
    var index = this.formulare.rows.indexOf(this.selectedformulare);
    this.formulare.rows = this.swapArray(this.formulare.rows, index, index + 1);
    this.selectionFormulareChanged(this.selectedformulare);
  }

  swapArray(Array: any, Swap1: number, Swap2: number): any {
    let sort1 = Array[Swap1].Sorting;
    let sort2 = Array[Swap2].Sorting;
    let id1 = Array[Swap1].id;
    let id2 = Array[Swap2].id;

    Array[Swap2].Sorting = sort1;
    Array[Swap1].Sorting = sort2;

    var temp = Array[Swap1];
    Array[Swap1] = Array[Swap2]
    Array[Swap2] = temp


    let resort = {
      id1: id1,
      id2: id2,
      sort1: sort2,
      sort2: sort1,
    }

    this.apiservice
      .editSortTabellen({ resort })
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      })

    return Array;
  }

  getvalue(row) {
    switch (row['Tabelle']) {
      case 'Stammdaten':
        if (row['Typ'] == 'Überschrift' || row['Typ'] == 'Titel') {
          return '';
        }

        let v = this.stammdaten.rows.find(x => x.FeldName === row['Bezeichnung']);
        if (v != undefined) {
          return v['Bezeichnung'];
        }

        break;
      case 'Spezifikationsdaten':

        if (row['Typ'] == 'Überschrift' || row['Typ'] == 'Titel') {
          return '';
        }

        return this.spezifikationsdaten.mapping[row.Bezeichnung];
        break;
      case 'Herstellerdaten':

        if (row['Typ'] == 'Überschrift' || row['Typ'] == 'Titel') {
          return '';
        }

        return row['Bezeichnung'];
        break;
      default:
        return '';
    }
  }
  getvalueTab(row) {
    switch (row['Typ']) {
      case 'Überschrift':
      case 'Titel':
        return '';
        break;
      default:
        return row['Tabelle'];
    }
  }

  fetchDataForVorlage(vorlageID: number) {
    this.apiservice.getFormDataForVorlage(vorlageID).subscribe(
      data => {
        if (Array.isArray(data)) {
          this.formulare['rows'] = data;
          this.formReflectData = data.map((row: FormulareRow) => {
            const reflectData = {
              mainTitle: null,
              title: null,
              subheading: null,
              fieldType: row['Bezeichnung']
            };
            this.updateFormReflectData(row, reflectData);
            return reflectData;
          });

        }
      }
    );
  }

  fetchDataForTabelleName(szaehnID: number) {
    if (!szaehnID) return;
    if (this.tabellen != undefined)
      this.tabellen.rows = undefined;

    this.apiservice.getTabellenDataFATBySZAEHNID(szaehnID).subscribe(
      data => {
        if (Array.isArray(data.rows)) {
          this.tabellen['rows'] = data.rows;
        }
      }
    );

    this.curSkizzen = [];

    Object.keys(this.skizzen).forEach((typid) => {
      if (typid == szaehnID.toString()) {
        this.curSkizzen = this.skizzen[typid];

        setTimeout(() => {
          if (this.preview != undefined) {
            this.preview.clearPreview();
            if (this.curSkizzen.length > 0) {
              //this.preview.rawPreviewThumbnails = this.curSkizzen;
              this.preview.unpackFiles(this.curSkizzen, true);
            }
          }

        }, 200);
      }
    });

  }

  tabChange() {
    this.selected = [];
    this.currentPage = 1;
  }

  sendForFormulare() {
    let a = this.formulare['rows'];
    let b = this.formulareOriginal;
    var c = _.filter(a, function (obj) { return !_.findWhere(b, obj); });
    let test = true;
    const vorlageID = this.getVorlageIDByName(this.selectedVorlageName);
    c.forEach((changedFormulare: any) => {
      if (!changedFormulare || !changedFormulare.Typ) {
        this.toastr.warning(this.mrTranslate.transform("Bitte bei der Zeile den Typ angeben."));
        test = false;
        return;
      }

      if (!changedFormulare || !changedFormulare.FeldName) {
        this.toastr.warning(this.mrTranslate.transform("Bitte bei der Zeile den Namen angeben."));
        test = false;
        return;
      }

      changedFormulare.VorlageID = this.getVorlageIDByName(this.selectedVorlageName);
      if (changedFormulare.Tabelle === 'Spezifikationsdaten' && changedFormulare.Bezeichnung) {
        const key = Object.keys(this.spezifikationsdaten.mapping).find(k => this.spezifikationsdaten.mapping[k] === changedFormulare.Bezeichnung);
        changedFormulare.Bezeichnung = key || changedFormulare.Bezeichnung;
      }
    });
    if (test) {
      // const tabelleIDUpdates = Object.entries(this.tabellenCheckboxStates[this.selectedVorlageName])
      //   .filter(([id, checked]) => checked)
      //   .map(([id]) => parseInt(id));

      if (vorlageID !== undefined) {
        this.apiservice.editFormulare(c, vorlageID, null).pipe(first()).subscribe(val => {
          if (val) {
            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
            this.selectVorlageName(this.selectedVorlageName);
            this.formulare = val;

            /*
            this.apiservice.getAnlagen().pipe(first()).subscribe((data: any) => {
              this.basicStore.setComponentStore('anlagen', {
                anlagen: data
              });
            });
            this.dataRefresh.emit(true);
            */
          }

        });
      } else {
        console.error("Missing vorlageID or no checkboxes selected");
      }
      this.locked = true;
    }
  }

  async sendForTabellen() {

    const index = this.tabellenNames.findIndex(x => x.ID === this.selectedTabelleID);
    if (index > 0) {
      this.tabellenNames[index]["Bemerkung"] = this.bemerkungmessung;

      let t = this.tabellenNames[index];

    }

    let a = this.tabellen['rows'];
    let b = this.tabellenOriginal;
    var c = _.filter(a, function (obj) { return !_.findWhere(b, obj); });
    let test = true;
    c.forEach((changedTabellen: any) => {
      if (!changedTabellen || !changedTabellen.Code) {
        this.toastr.warning(this.mrTranslate.transform("Bitte bei der Zeile den Code angeben."));
        test = false;
        return;
      }
      changedTabellen.SZAEHNID = this.selectedTabelleID;
    });
    if (test) {

      /*
          let r = this.tabellenNames.find((row: any) => row.ID === this.selectedTabelleID);
          r["Bemerkung"] = this.bemerkungmessung;
      */

      this.tabellen.rows = [];

      let images;

      try {
        images = await this.preview.packUnsavedFiles();

      } catch (_) {
        this.toastr.error(this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten"));
      }

      let send = {
        rows: c,
        images: images,
        savedFiles: this.preview.saved?.map((file) => file.name).join("|"),
        szaehnid: this.selectedTabelleID,
        bemerkung: this.bemerkungmessung,
      }
      //this.preview.setSaved();


      this.apiservice
        .editTabellenFAT(send)
        .pipe(first())
        .subscribe((val: any) => {

          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          this.tabellen.rows = val.data.rows;

          if (send.images.length > 0) {
            Object.keys(this.skizzen).forEach((typid) => {
              if (typid == this.selectedTabelleID.toString()) {
                this.skizzen[typid] = val.skizzen;
                this.curSkizzen = val.skizzen;
              }
            });

            setTimeout(() => {
              this.preview.clearPreview();
              if (this.curSkizzen.length > 0) {
                this.preview.unpackFiles(this.curSkizzen, true);
                //this.preview.rawPreviewThumbnails = this.curSkizzen;
              }

            }, 200);
          }
          this.bemerkungmessung = send.bemerkung;

          //this.preview.setSaved();
          this.selectTabelleID(this.selectedTabelleID, true);
        });
    }
  }

  private updateFormReflectData(row: any, reflectData: any) {
    if (row[this.firstColumnId] === 'Überschrift') {
      reflectData.mainTitle = null;
      reflectData.title = row['FeldName'];
      reflectData.subheading = null;
    } else if (row[this.firstColumnId] === 'Normales Feld') {
      reflectData.mainTitle = null;
      reflectData.title = null;
      reflectData.subheading = row['FeldName'];
    } else if (row[this.firstColumnId] === 'Titel') {
      reflectData.mainTitle = row['FeldName'];
      reflectData.title = null;
      reflectData.subheading = null;
    }
    let columnName = row['Bezeichnung'];

    if (columnName != null) {
      let stable = "ostamm";

      if (row['Tabelle'] === 'Spezifikationsdaten')
        stable = "ospez";

      if (row['Tabelle'] === 'Herstellerdaten')
        stable = "oherst";

      const rowToDelete = this.structures.find((row: any) => row.TABLE_NAME === stable && row.COLUMN_NAME === columnName);

      if (rowToDelete != undefined) {
        let formFieldType;
        switch (rowToDelete.DATA_TYPE.toUpperCase()) {
          case 'VARCHAR':
          case 'TEXT':
            formFieldType = 'text';
            break;
          case 'DATETIME':
            formFieldType = 'date';
            break;
          case 'DECIMAL':
          case 'INT':
          case 'FLOAT':
            formFieldType = 'dropdown';
            break;
          case 'BIT':
            formFieldType = 'checkbox';
            break;
        }
        reflectData.fieldType = formFieldType;
      }
    }
  }

  deleteForFormulare() {

    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Zeile(n) löschen wollen?"))) {

      for (const _id in this.delItems) {

        let id = this.delItems[_id];
        this.apiservice.deleteFormular(id).pipe(first()).subscribe(val => {
          if (val) {
          }
        });

        const indexF = this.formulare.rows.findIndex(x => x.id === id);
        if (indexF > 0) {
          this.formulare.rows.splice(indexF, 1);
        }
      }

      this.toastr.success(this.mrTranslate.transform("Zeile(n) gelöscht"));
      this.delItems = [];

      //this.locked = true;
    }
  }

  onItemChanged(event, row, i) {

    const indexF = this.formulare.rows.findIndex(x => x.id === row["id"]);

    if (indexF > 0) {
      let marked = event.target.checked;
      if (marked) {
        this.delItems.push(row["id"])
      }
      else {
        const _index = this.delItems.findIndex(x => x === row["id"]);
        this.delItems.splice(_index, 1);
      }
    }
    else {
      if (event.target.checked)
        event.target.checked = false;
    }
  }


  deleteForTabellen(id: any) {
    let index = this.tabellen.rows.findIndex(x => x.ID === id);
    if (id.toString().indexOf('INSERT') > -1) {
      this.tabellen.rows.splice(index, 1);
      return;
    }

    if (index > 0) {
      if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Zeile löschen wollen?"))) {
        this.apiservice.deleteTabelle(id).pipe(first()).subscribe(val => {
          if (val) {
            this.toastr.success(this.mrTranslate.transform("Zeile gelöscht"));

            if (index > -1) {
              this.tabellen.rows.splice(index, 1);
            }
          }
        });
        //this.locked = true;
      }
    }
  }

  addFormulare() {
    this.modaltitel = this.mrTranslate.transform("Neue Vorlage erstellen");
    this.isVorlageAddVisible = true;
  }

  saveAsFormulare() {
    this.copyformular = this.getVorlageIDByName(this.selectedVorlageName);
    this._vorlage_new.nativeElement.value = this.selectedVorlageName + ' - ' + this.mrTranslate.transform('Kopie');
    this.modaltitel = this.mrTranslate.transform("Speichern unter");
    this.isVorlageAddVisible = true;
  }

  deleteFormulare() {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Vorlage löschen wollen?"))) {

      let id = this.getVorlageIDByName(this.selectedVorlageName);

      this.apiservice.deleteFormularName(id).pipe(first()).subscribe(val => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Vorlage gelöscht"));

          this.reloadVorlageNamesAndFormData();
          this.selectVorlageName(this.selectedVorlageName)
        }
      });
    }
  }


  addForFormulare() {
    let blank = JSON.parse(JSON.stringify(this.formulare['rows'][0]));
    for (let key in blank) {
      blank[key] = undefined;
    }
    blank['id'] = 'INSERT' + this.randomIntFromInterval(1000000000, 9999999999);
    blank['Sorting'] = this.formulare['rows'].length + 1;


    this.formulare['rows'].push(blank);
    setTimeout(() => {
      this.grid.resize();
      document.getElementById('saveButton').scrollIntoView(true);
    });
  }

  addTabelle() {
    this.dorename = 0;
    this.copyvorlageID = this.selectionvorlageID;
    this.copytabelle = -1; //this.selectedTabelleID;

    this._zaehlercode.nativeElement.value = '';
    this._zaehlerbezeichnung.nativeElement.value = '';
    this.optionMessTable.controls.messart.setValue(0);

    this.modaltitel = this.mrTranslate.transform("Neue Messwerttabelle erstellen");
    this.isZaehlerAddVisible = true;
  }

  renameTabelle() {
    this.dorename = 1;
    this.copyvorlageID = this.selectionvorlageID;
    this.copytabelle = this.selectedTabelleID; //this.getVorlageIDByName(this.selectedVorlageName);

    const tab = this.tabellenNames.find((tabelle) => tabelle.ID === parseInt(this.copytabelle.toString()));

    this._zaehlercode.nativeElement.value = tab['Code'];
    this._zaehlerbezeichnung.nativeElement.value = tab['Bezeichnung'];

    this.optionMessTable.controls.messart.setValue(tab['SourceType']);

    this.modaltitel = this.mrTranslate.transform("Umbenennen");
    this.isZaehlerAddVisible = true;
  }


  saveAsTabelle() {
    this.dorename = 0;
    this.copyvorlageID = this.selectionvorlageID;
    this.copytabelle = this.selectedTabelleID; //this.getVorlageIDByName(this.selectedVorlageName);

    const tab = this.tabellenNames.find((tabelle) => tabelle.ID === parseInt(this.copytabelle.toString()));

    this._zaehlercode.nativeElement.value = tab['Code'];
    this._zaehlerbezeichnung.nativeElement.value = tab['Bezeichnung'] + ' - copy';

    this.optionMessTable.controls.messart.setValue(tab['SourceType']);

    this.modaltitel = this.mrTranslate.transform("Speichern unter");
    this.isZaehlerAddVisible = true;
  }

  deleteTabelle() {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Tabelle löschen wollen?"))) {

      let id = this.selectedTabelleID;

      this.apiservice.deleteTabelleName(id).pipe(first()).subscribe(val => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Tabelle gelöscht"));

          this.reloadTabellen();

        }
      });
      this.locked = true;
    }


  }


  addForTabellen() {
    let blank = JSON.parse(JSON.stringify(this.tabellen['rows'][0]));
    for (let key in blank) {
      if (key != 'ZArt') {
        blank[key] = undefined;
      }
    }

    blank['ID'] = 'INSERT' + this.randomIntFromInterval(1000000000, 9999999999);
    blank['I_SORT'] = this.tabellen.rows.length + 1;
    this.tabellen['rows'].push(blank);
    setTimeout(() => {
      this.grid.resize();
      document.getElementById('saveButton').scrollIntoView(true);
    });
  }

  triggerLock() {
    this.locked = !this.locked;
    this.delItems = [];
    this.grid.resize;
    this.grid.refresh;
  }

  handleAuswahlOptionChange(row: any, value: string) {
    row['Tabelle'] = value;
  }

  handleModalReload() {
    this.reloadVorlageNamesAndFormData();
    this.reloadTabellen();
  }

  ngOnInit() {
    this.copyformular = 0;
    this.apiservice.getStammdatenColumns().pipe(first()).subscribe(data => {
      let r = data.rows;
      r.forEach((row) => {
        row['Bezeichnung'] = this.mrTranslate.transform(row['Bezeichnung']);
      });
      r.sort((a, b) => a.Bezeichnung.localeCompare(b.Bezeichnung));

      this.stammdaten.rows = r;

    });
    this.apiservice.getSpezifikationsdaten().pipe(first()).subscribe(data => {
      this.spezifikationsdaten.columns = data.columns.map(col => {
        return {
          display: col,
          value: Object.keys(data.mapping).find(key => data.mapping[key] === col)
        };
      });

      this.spezifikationsdaten.columns = this.spezifikationsdaten.columns.sort((a, b) => a.display.localeCompare(b.display));
      this.spezifikationsdaten.mapping = data.mapping;
    });
    this.apiservice.getHerstellerdatenColumns().pipe(first()).subscribe(columns => {
      this.herstellerdaten.columns = columns;
    });
  }
}
