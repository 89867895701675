import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export abstract class CustomValidators {
  /**
   * @argument direction: 1 or -1 `required`
   * @argument path: die Namen von der Controls im FormGroup
   * @argument allowZero: wenn der Anfang gleich dem Ende sein darf
   * @returns ValidatorError { 'not-in-Direction': upwards } or null if valid
   * @upwards true, if Startmeter -> Endmeter
   * @upwards false, if Endmeter -> Startmeter
   * @example this.form.setValidators(
   *  CustomValidators.DirectionValidator(direction, { start: 'von', end: 'bis' })
   * );
   */
  public static DirectionValidator(
    direction: number,
    path: { start: string, end: string } = { start: 'Startmeter', end: 'Endmeter' },
    allowZero: boolean = false
  ): ValidatorFn {
    return (form) => {
      const start = form.get(path.start);
      const end = form.get(path.end);
      const s = start.value ?? '';
      const e = end.value ?? '';
      const diff = e - s;
      if (
        start.invalid || end.invalid
        || s == '' || e == ''
        || diff * direction > 0 
        || allowZero && diff === 0
      )
        return null;
      else {
        form.markAsTouched();
        return { 'not-in-Direction': { upwards: direction == 1 }}
      }
    }
  }
  /**
   * @description `invalid` - wenn alle controls aus paths sind leer ('' || undefined || null)
   * @argument paths: die Namen von der Controls im FormGroup `required`
   * @returns ValidatorError { 'mindestens-ein-wert': paths } or null if valid
   * @example this.form.setValidators(
   *  CustomValidators.MindestensEinWertValidator(['von', 'bis'])
   * );
   */
  public static MindestensEinWertValidator(paths: string[]): ValidatorFn {
    return (form) => {
      const valid = paths?.some(path => {
        const value = form.get(path).value;
        return !!value || value === 0
      });
      if (valid) return null;
      else {
        form.markAsTouched();
        return { 'mindestens-ein-wert': paths };
      }
    }
  }

  /**
   * @description 
   * Der Validator prüft, ob der Wert des Formularfeldes in einer vorgegebenen Liste von Werten enthalten ist.
   * Optional kann der Validator auch prüfen, ob der Wert nicht in der Liste enthalten ist und einen Initialwert berücksichtigen.
   * @argument list: string[] - Liste der vorhandenen Werte, mit denen der Eingabewert verglichen wird.
   * @argument doesNotInclude: boolean - Wenn true, wird geprüft, dass der Wert nicht in der Liste enthalten ist
   * @argument initValue: string - Der ursprüngliche Wert, der berücksichtigt wird, wenn `doesNotInclude` true ist
   * @returns ValidatorError { 'not-in-Table': message } || { 'in-Table': message } || null 
   * @example this.form.setValidators(
   *  CustomValidators.InTableValidator(this.listOfTypes)
   * );
   * @example this.form.setValidators(
   *  CustomValidators.InTableValidator(this.listOfNames, true, this.model.name)
   * );
   */
  public static InTableValidator(
    list: string[],
    doesNotInclude: boolean = false,
    initValue: string = ''
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) return null;
      let isValid = list.includes(control.value);
      if (doesNotInclude) isValid = control.value == initValue || !isValid;
      if (!isValid) control.markAsTouched();
      return isValid
        ? null
        : doesNotInclude
        ? { 'in-Table': 'Der Wert ist bereits verwendet' }
        : { 'not-in-Table': 'Der Wert muss in der Liste eingetragen sein' };
    }
  }
}