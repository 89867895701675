import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import dayjs from 'dayjs';

import 'dayjs/locale/de';
import customParseFormat from "dayjs/plugin/customParseFormat";

import { APIService } from 'src/app/services/APIService/api.service';
import { first } from 'rxjs/operators';
import { StatisticsDiagramComponent } from './statistics-diagram/statistics-diagram.component';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-statisticsdialog',
    templateUrl: './statisticsdialog.component.html',
    styleUrls: ['./statisticsdialog.component.scss'],
    imports: [CommonModule, ClarityModule, StatisticsDiagramComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe],
    providers: [BackendLocaleDatePipe],
    standalone: true
})
export class StatisticsdialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedMode: Array<any> = [];

  filterDateStart: any;
  filterDateEnd: any;
  filterInspektion: number = 1;

  Object = Object;

  statisticsModes = [];




  constructor(
    private apiservice: APIService,
    protected localeDate: BackendLocaleDatePipe
  ) {
    dayjs.locale('de');
    dayjs.extend(customParseFormat);
    this.filterDateStart = dayjs().subtract(1, 'y').startOf('y').format(BackendLocaleDatePipe.format);
    this.filterDateEnd = BackendLocaleDatePipe.now;
  }

  ngOnInit(): void {
    this.apiservice.getStatistiken().pipe(first()).subscribe((res: any) => {
      this.statisticsModes = res || undefined;
    });
  }

  public close() {
    this.closedialog.next(true);
  }

  selectStatMode(objType: string, statType: string, stat: any) {
    let sta = stat;
    //if (typeof stat != 'string') sta = stat.column;
    this.selectedMode = [objType, statType, sta];
  }

  isString(val) {
    return typeof val == 'string';
  }

}
