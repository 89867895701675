import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as Plotly from 'plotly.js-basic-dist-min';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { AuthService } from "src/app/services/Auth/auth.service";
import * as XLSX from 'xlsx';
import { APIService } from '../../../../services/APIService/api.service';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { PlotlyPlotComponent } from '../../plotly-plot/plotly-plot.component';

var holdconfig = {
  isDown: 0,
  delay: 50,
  nextTime: 0
};

@Component({
  selector: 'app-railmonitor',
  templateUrl: './railmonitor.component.html',
  styleUrls: ['./railmonitor.component.scss'],
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe,PlotlyPlotComponent, TablePrettyPrintPipe],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})


export class RailmonitorComponent implements AfterViewInit, OnDestroy {
  private stammid: number;
  public profiles: any = [];
  public sollProfile: any = {};
  public curSoll = "";
  public curSollProfL:any;
  public curSollProfR:any;
  public curIstProfL:any;
  public curIstProfR:any;
  public showImportModal : boolean = false;
  public moveStepL: number = 5;
  public moveStepR: number = 5;

  public xy = {x:0.0, y:0.0, x2:0.0, y2:0.0} ;

  modalImportGroup = new UntypedFormGroup({
    sollprofil: new UntypedFormControl(""),
    spurweite: new UntypedFormControl(1435),
    messdate: new UntypedFormControl(""),
  });

  _selected: any = [];
  set selected(newSelect: any) {
    this._selected = newSelect;
  }

  get selected() {
    return this._selected;
  }

  ori :"h" | "v" = "h";
  graphL = {
    show: false,
    id:"",
    layout: {
      autosize: true,
      showlegend: true,
      legend: { orientation: this.ori },
      //height: "25vh",
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        //autorange: true,
        range: [-40,100],//[Math.min(...measurements) - 10, Math.max(...measurements) + 10],
        //type: "linear",
      },
      xaxis:  {
        //autorange: true,
        range: [-90,50],
      },
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d"],
    },
    data: [],
    style: {
      width: "47vw",
      height: "67vh",
    },
  };

  graphR = {
    id:"",
    layout: {
      autosize: true,
      showlegend: true,
      legend: { orientation: this.ori },
      //height: "25vh",
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        //autorange: true,
        range: [-40,100],//[Math.min(...measurements) - 10, Math.max(...measurements) + 10],
        //type: "linear",
      },
      xaxis:  {
        //autorange: true,
        range: [-50,90],
      },
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d"],
    },
    data: [],
    style: {
      width: "47vw",
      height: "67vh",
    },
  };

  @Input() accept = "application/vnd.ms-excel,.txt,.brml,.csv";
  private leftBoundL: number = undefined;
  private leftBoundR: number = undefined
  public indexScrub: BehaviorSubject<number> = new BehaviorSubject(0);
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);

  set data(dataIn: any) {
    this.stammid = dataIn.id;
    this.loadRM();
  };



  constructor(
    private apiservice: APIService,
    private mrTranslate: MrTranslatePipe,
    public authService: AuthService,
    private toastr: ToastrService,
    protected localeDate: BackendLocaleDatePipe
  ) {
  }

  getPlotID(event:any, side:any) {
    var plotID = event;
    var myPlot :any = document.getElementById(plotID);
    side == 'l' ? this.graphL.id = plotID : this.graphR.id = plotID;
    myPlot.on('plotly_click',  data => {
      var graph:any;
      side == 'l' ? graph = this.graphL : graph = this.graphR;

      graph.data = graph.data.filter(item => item.name != "Distanzmessung");
      if(data.points.length == 1) {
        this.graphL.show = false;

        if (!this.xy.x || (this.xy.x && this.xy.x2)) {
          this.xy.x = data.points[0].x;
          this.xy.y = data.points[0].y;
          this.xy.x2 = undefined;
          this.xy.y2 = undefined;
          var dataPoint = { x: [this.xy.x], y: [this.xy.y], name: "Distanzmessung", type: "lines+marker" }
          var txt = "x=0.00 mm y=0.00 mm dist=0.00 mm"
          //Plotly.addTraces(graph.id,{ x: [this.xy.x], y: [this.xy.y], name: "Distanzmessung"})
        }
        else {
          this.xy.x2 = data.points[0].x;
          this.xy.y2 = data.points[0].y;
          var dist = this.getDistanceNum(this.xy.x, this.xy.x2, this.xy.y, this.xy.y2);
          var txt = "x=" + (this.xy.x - this.xy.x2).toFixed(2) + " mm y=" + (this.xy.y - this.xy.y2).toFixed(2) + " mm dist=" + dist + " mm"
          var dataPoint = { x: [this.xy.x, this.xy.x2], y: [this.xy.y, this.xy.y2], name: "Distanzmessung", type: "lines+marker" }
          //Plotly.addTraces(graph.id,{ x: [this.xy.x, this.xy.x2], y: [this.xy.y, this.xy.y2], name: "Distanzmessung"})
        }

        graph.layout.annotations  = [{
          text: txt,
          x: NaN, //Garantiert dass Annotation immer oben links zu sehen ist.
          y: NaN,
          showarrow:false,
        }];

        graph.data.push(dataPoint);
        // Plotly.react(this.graphL.id,this.graphL.data, this.graphL.layout);
        // Plotly.react(this.graphR.id,this.graphR.data, this.graphR.layout);
        setTimeout(() => {
          this.graphL.show = true;
        });
      }

    });
  }
  public getDistanceNum(p1x: number,p2x: number, p1y:number, p2y:number) {
    var a = p1x - p2x;
    var b = p1y - p2y;

    return Math.sqrt( a*a + b*b ).toFixed(2);
  }

  doMove(val, side,dir) {
    var graph:any;
    side == 'L' ? graph = this.graphL : graph = this.graphR;
    if(dir == 'X') {
      graph.data[1].x = graph.data[1].x.map(xVal => xVal + val);
    } else {
      graph.data[1].y = graph.data[1].y.map(
        xVal => xVal - val);
    }
    Plotly.redraw(graph.id);
  }

  setMoveStep(i: any, mode: string) {
    var val = +i.currentTarget.value;
    if(mode == 'L')
      this.moveStepL = val
    else
      this.moveStepR = val;
  }

  public loadRM(){

    this.apiservice.getRailmonitor(this.stammid).pipe(first()).subscribe((res: any) => {
      if (res) {
        if (res && res.rows?.length > 0) {
          this.profiles = res.rows.map((pro) => {
            if (!this.leftBoundL || !this.leftBoundR) {
              this.leftBoundL = pro.SollProfil.SchHMax * 2;//this.profiles[0].ProfilLinks[0][0];
              this.leftBoundR = this.leftBoundL;
            }
            return pro;
          });

        }
      }
    });
  }

  selectionChanged(event) {
    if(event) {
      this.graphL.show = false;
      this.graphL.data = [];
      this.graphR.data = [];
      event.forEach(element => {
        this.graphL.data.push(element.SollProfil.ProfilL);
        this.graphR.data.push(element.SollProfil.ProfilR);
        this.graphL.data.push(element.ProfilLinks);
        this.graphR.data.push(element.ProfilRechts);
      });

      // Plotly.react(this.graphL.id,this.graphL.data, this.graphL.layout);
      // Plotly.react(this.graphR.id,this.graphR.data, this.graphR.layout);
      setTimeout(() => {
        this.graphL.show = true;
      });
    }

  }

  setImportModal() {
    this.apiservice.getRailmonitorSollprofile().pipe().subscribe((v: any) => {
    this.showImportModal = true;
    this.selected = [];
    this.graphL.show = false;
    this.graphL.data = [];
    this.graphR.data = [];
    this.graphL.style.width = "450px";
    this.graphL.style.height = "450px";
    this.graphR.style.width = "450px";
    this.graphR.style.height = "450px";
    this.sollProfile = v;
    setTimeout(() => {
      this.graphL.show = true;
    },100);
  });
  }

  closeImportModal() {
    this.showImportModal = false;
    this.graphL.show = false;
    this.graphL.data = [];
    this.graphR.data = [];
    this.graphL.style.width = "47vw";
    this.graphL.style.height = "67vh";
    this.graphR.style.width = "47vw";
    this.graphR.style.height = "67vh";
    setTimeout(() => {
      this.graphL.show = true;
    });
  }

  loadSoll(event:any) {
    this.graphL.show = false;
    this.curSoll = (this.sollProfile[event.target.value].ID);
    this.curSollProfL = (this.sollProfile[event.target.value].ProfilL);
    this.curSollProfR = (this.sollProfile[event.target.value].ProfilR);
    this.graphL.data = [this.curSollProfL];
    if(this.curIstProfL) this.graphL.data.push(this.curIstProfL);
    this.graphR.data = [this.curSollProfR];
    if(this.curIstProfR) this.graphR.data.push(this.curIstProfR);
    setTimeout(() => {
      this.graphL.show = true;
    });
    // Plotly.react(this.graphL.id,this.graphL.data, this.graphL.layout);
    // Plotly.react(this.graphR.id,this.graphR.data, this.graphR.layout);
  }

  importRailmonitor() {
    const fileUpload = document.getElementById("fileUpload") as HTMLInputElement;
    fileUpload.value = '';
    fileUpload.click();
  }
  importRailmonitorRechts() {
    const fileUpload2 = document.getElementById("fileUpload2") as HTMLInputElement;
    fileUpload2.value = '';
    fileUpload2.click();
  }
  startImport(dir: string) {
    const files = dir == 'links' ? document.getElementById("fileUpload") as HTMLInputElement :  document.getElementById("fileUpload2") as HTMLInputElement ;
    var fileext = files.files[0].name.substring(files.files[0].name.indexOf("."));
    const formData = new FormData();
    formData.append('file', files.files[0]);
    if (fileext.toLowerCase() == ".xls")  formData.append('retFile','true');
    this.apiservice.uploadFiles(formData).pipe().subscribe((v: any) => {
      if (v.type == 4) {
        let f: any = {};
        var file = v.body.url;
        var fileContent = v.body.fileContent;
        let data = "";

        f.files = file;
        f.Direction = dir;
        if (fileext.toLowerCase() == ".xls") {
          //const wb = new Excel.Workbook();

          var fileXL = Buffer.from(fileContent, 'base64')

          const wb: XLSX.WorkBook = XLSX.read(fileXL);
          const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
          for (let i = 2; i < 3000; i++) {
            if(ws['E' + i.toString()] == undefined) break;
            data += ws['D' + i.toString()].v + ";" + ws['E' + i.toString()].v + "|";
          }
          f.Data = data;
          this.graphL.show = false;
          this.apiservice.getRailmonitorImport(f).pipe().subscribe((val: any) => {
            if (val) {
              if(dir == 'links') {
                this.graphL.data.push(val.dtL);
              } else {
                this.graphR.data.push(val.dtR);
              }
              this.graphL.show = true;
            }

          })
        } else {
          this.graphL.show = false;
          this.apiservice.getRailmonitorImport(f).pipe().subscribe((val: any) => {
            if (val) {
              if(dir == 'links') {
                this.graphL.data = [this.curSollProfL,val.dtL];
              } else {
                this.graphR.data = [this.curSollProfR,val.dtR];
              }
              this.graphL.show = true;
            }
          })
        }

      }
    })
  };


  saveImport(){
    let test = true;

    if (!this.modalImportGroup.controls.messdate.value) {
      this.toastr.warning(this.mrTranslate.transform("Bitte das Messdatum angeben."));
      test = false;
    }
    if (!this.modalImportGroup.controls.spurweite.value) {
      this.toastr.warning(this.mrTranslate.transform("Bitte die Spurweite angeben."));
      test = false;
    }
    if (!this.graphL.data[1] || !this.graphR.data[1]) {
      this.toastr.warning(this.mrTranslate.transform("Es muss für beide Seiten ein Import durchgeführt werden!"));
      test = false;
    }


    if (test) {
      let exp: any = {};
      let dataL = "";
      let dataR = "";
      exp.curSollID = this.curSoll;
      exp.messdate = this.modalImportGroup.controls.messdate.value;
      exp.spurweite = this.modalImportGroup.controls.spurweite.value;
      for (let i = 0; i < this.graphL.data[1].x.length; i++) {
        dataL = dataL + this.graphL.data[1].x[i].toFixed(4)+ ";" + this.graphL.data[1].y[i].toFixed(4) + "\r\n";
      }
      dataL = dataL.substring(0,dataL.length-2);
      for (let i = 0; i < this.graphR.data[1].x.length; i++) {
        dataR = dataR + this.graphR.data[1].x[i].toFixed(4) + ";" + this.graphR.data[1].y[i].toFixed(4) + "\r\n";
      }
      dataR = dataR.substring(0,dataR.length-2);
      exp.dataL = dataL;
      exp.dataR = dataR;
      this.apiservice.saveRailmonitorImport(this.stammid, exp).pipe().subscribe((val: any) => {
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        this.loadRM();
        this.closeImportModal();
      });
    }
  }

  deleteRailMonitor(id:any) {
    const text = this.mrTranslate.transform("Daten wirklich löschen?");
    if (confirm(text) && id) {
      this.apiservice.deleteRailmonitor(id).pipe().subscribe((val: any) => {
        this.toastr.success(this.mrTranslate.transform("Daten gelöscht"));
        this.loadRM();
      });
    }
  }
  ngOnDestroy(): void {
    this.graphL = undefined;
    this.graphR = undefined;
  }

  close() {
    this.closedialog.next(true);
    this.indexScrub.next(-1);
  }


  ngAfterViewInit() {
  }
}
