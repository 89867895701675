<clr-side-panel [(clrSidePanelOpen)]="opened" [clrSidePanelStaticBackdrop]="true">
  <h3 class="side-panel-title">{{"Dashboard Einstellungen" | mrTranslate}}</h3>
  <div class="side-panel-body">
    <clr-stack-view [formGroup]="optGroup" style="margin-top:5px">
      <clr-stack-block [clrSbExpanded]="true">
        <clr-stack-label>{{ "Zustand" | mrTranslate }}</clr-stack-label>

        <clr-stack-block>
          <clr-stack-label>{{ "Betriebsgefahr" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_BG" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Störungen" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Stoer" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Sperrungen" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Sperr" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Langsamfahrstellen" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_LF"/>
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Sonderinspektion" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Sonder" />
          </clr-stack-content>
        </clr-stack-block>
      </clr-stack-block>

      <clr-stack-block [clrSbExpanded]="true">
        <clr-stack-label>{{ "Termine überschritten" | mrTranslate }}</clr-stack-label>

        <clr-stack-block>
          <clr-stack-label>{{ "Inspektion" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Anz_Insp" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Auftrag" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Anz_Auf" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Begehung" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Anz_Begeh" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Wartung" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Anz_Wart"/>
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block *ngIf="optChecked_EBL == 1">
          <clr-stack-label>{{ "durch ALV bestätigen" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Anz_ALV"/>
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block *ngIf="optChecked_EBL == 1">
          <clr-stack-label>{{ "durch EBL bestätigen" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Anz_EBL"/>
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Sonderinspektion" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Anz_Sonder" />
          </clr-stack-content>
        </clr-stack-block>
      </clr-stack-block>

      <clr-stack-block [clrSbExpanded]="true">
        <clr-stack-label>{{ "Diagramme" | mrTranslate }}</clr-stack-label>

        <clr-stack-block>
          <clr-stack-label>{{ "Fehlerkassen" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Dia_FK" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "KAV" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Dia_KAV" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Kosten" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Dia_Kost" />
          </clr-stack-content>
        </clr-stack-block>

        <clr-stack-block>
          <clr-stack-label>{{ "Programmnutzung" | mrTranslate }}</clr-stack-label>
          <clr-stack-content>
            <input type="checkbox" clrCheckbox formControlName="show_Dia_Prog"/>
          </clr-stack-content>
        </clr-stack-block>
      </clr-stack-block>
    </clr-stack-view>
  </div>
  <div class="side-panel-footer">
    <button type="button" class="btn btn-outline" (click)="opened = false">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveOpt()">Ok</button>
  </div>
</clr-side-panel>



<div class="clr-row" style="margin-top: 12px;">
  <div class="clr-col-12" style="display: flex; justify-content: flex-end;">
    <div class="btn-group btn-icon" style="margin-right: 25px;">
      <button class="btn" (click)="opened = true">
        <clr-icon shape="cog" [title]="'Einstellungen' | mrTranslate"></clr-icon>
      </button>
    </div>

  </div>
</div>
<div class="clr-row" style="margin-left: 25px;margin-right: 10px; margin-top: -15px;">
  <strong>{{"Zustand" | mrTranslate}}</strong>
</div>
<div class="clr-row" style="margin-left: 10px;margin-right: 10px; margin-top: -15px;">
  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_BG">
    <a (click)="routing('/maengel','betriebsgefahr')" class="card clickable">
        <div class="card-block">
          <div class="card-title"><clr-icon [ngStyle]="{ color: 'red' }" shape="error-standard" size="28"></clr-icon> {{"Betriebsgefahr" | mrTranslate}}</div>
          <div class="card-text"style="font-size: 25px;">
            {{anzBG}}
          </div>
        </div>
    </a>
  </div>

  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Stoer">
    <a (click)="routing('/anlagen','st')" class="card clickable">
        <div class="card-block">
          <div class="card-title"><clr-icon [ngStyle]="{ color: 'red' }"  shape="exclamation-triangle" size="28"></clr-icon> {{"Störungen" | mrTranslate}}</div>
          <div class="card-text"style="font-size: 25px;">
            {{anzStoer}}
          </div>
        </div>
    </a>
  </div>
  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Sperr">
    <a (click)="routing('/anlagen','sp')" class="card clickable">
        <div class="card-block">
          <div class="card-title"><clr-icon shape="gesperrt" size="28"></clr-icon> {{"Sperrungen" | mrTranslate}}</div>
          <div class="card-text" style="font-size: 25px;">
            {{anzSperr}}
          </div>
        </div>
    </a>
  </div>

  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_LF">
    <a (click)="routing('/anlagen','lf')" class="card clickable">
        <div class="card-block">
          <div class="card-title"><clr-icon [ngStyle]="{ color: 'orange' }"  shape="lastelle" size="28"></clr-icon> {{"Langsamfahrstellen" | mrTranslate}}</div>
          <p class="card-text"style="font-size: 25px;">
            {{anzLF}}
          </p>
        </div>
    </a>
  </div>
  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Sonder">
    <a (click)="routing('/anlagen','si')" class="card clickable">
        <div class="card-block">
          <div class="card-title"><clr-icon [ngStyle]="{ color: 'orangered' }" shape="warning-standard" size="28"></clr-icon> {{"Sonderinspektion" | mrTranslate}}</div>
          <p class="card-text"style="font-size: 25px;">
            {{anzSonder}}
          </p>
        </div>
    </a>
  </div>

</div>
<br/>
<div class="clr-row" style="margin-left: 25px;margin-right: 10px;">
  <strong>{{"Termine überschritten" | mrTranslate}}</strong>
</div>
<div class="clr-row" style="margin-left: 10px;margin-right: 10px; margin-top: -15px;">
  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Anz_Insp">
    <a (click)="routing('/anlagen','term')" class="card clickable">
        <div class="card-block">
          <div class="card-title">{{"Inspektion" | mrTranslate}}</div>
          <p class="card-text"style="font-size: 25px;">
            {{anzInsp}}
          </p>
        </div>
    </a>
  </div>

  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Anz_Auf">
    <a (click)="routing('/anlagen','term')" class="card clickable">
        <div class="card-block">
          <div class="card-title">{{"Auftrag" | mrTranslate}}</div>
          <p class="card-text"style="font-size: 25px;">
            {{anzAuf}}
          </p>
        </div>
    </a>
  </div>

  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Anz_Begeh">
    <a (click)="routing('/anlagen','term')" class="card clickable">
        <div class="card-block">
          <div class="card-title">{{"Begehung" | mrTranslate}}</div>
          <p class="card-text"style="font-size: 25px;">
            {{anzBegeh}}
          </p>
        </div>
    </a>
  </div>
  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Anz_Wart">
    <a (click)="routing('/anlagen','term')" class="card clickable">
        <div class="card-block">
          <div class="card-title">{{"Wartung" | mrTranslate}}</div>
          <p class="card-text"style="font-size: 25px;">
            {{anzWart}}
          </p>
        </div>
    </a>
  </div>
  <ng-container *ngIf="optChecked_EBL == 1">
    <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Anz_ALV">
      <a (click)="routing('/anlagen','term')" class="card clickable">
          <div class="card-block">
            <div class="card-title">{{"durch ALV bestätigen" | mrTranslate}}</div>
            <p class="card-text"style="font-size: 25px;">
              {{anzALV}}
            </p>
          </div>
      </a>
    </div>
    <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Anz_EBL">
      <a (click)="routing('/anlagen','term')" class="card clickable">
          <div class="card-block">
            <div class="card-title">{{"durch EBL bestätigen" | mrTranslate}}</div>
            <p class="card-text"style="font-size: 25px;">
              {{anzEBL}}
            </p>
          </div>
      </a>
    </div>
  </ng-container>

  <div class="clr-col-lg-2 clr-col-md-4 clr-col-12" *ngIf="show_Anz_Sonder && anzSonder !== 0">
    <a (click)="routing('/anlagen','term')" class="card clickable">
        <div class="card-block">
          <div class="card-title">{{"Sonderinspektion" | mrTranslate}}</div>
          <p class="card-text"style="font-size: 25px;">
            {{anzSonderTermin}}
          </p>
        </div>
    </a>
  </div>

</div>


<br/>

<div class="clr-row" style="margin-left: 10px;margin-right: 10px; margin-top: -15px; min-height: 250px;">
  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12" *ngIf="show_Dia_FK">

    <div *ngIf="fkstatistic.length > 0;else other_content">
      <div class="card">
        <h3 class="card-header">{{"Fehlerklassen" | mrTranslate}} </h3>
        <div class="card-block">
          <app-plotly-plot   [className]=""
            [data]="fkstatistic" [layout]="graph.layout" [config]="graph.config">
          </app-plotly-plot>
        </div>
      </div>
    </div>
    <ng-template #other_content>
      <div class="card">
        <h3 class="card-header">{{"Fehlerklassen" | mrTranslate}} </h3>
        <div class="card-block">
          <div class="center" style="min-height: 500px;">
            <span class="spinner spinner-md">Loading...</span>
          </div>
        </div>
      </div>
    </ng-template>

  </div>
  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12" *ngIf="show_Dia_KAV">
    <div class="card">
      <h3 class="card-header d-flex justify-content-between align-items-center">{{kavTitel}}
        <button type="button" class="btn btn-icon btn-primary btn-sm float-right" style="margin-top: -2px;" aria-label="settings" (click)="changeKAVStatus()">
          <cds-icon shape="angle" direction="right"></cds-icon>
        </button>

      </h3>
      <div class="card-block">
        <ng-container *ngIf="kavansicht == 0">
          <app-plotly-plot *ngIf="kavstatistic.length > 0"  [className]=""
            [data]="kavstatistic" [layout]="graphkav0.layout" [config]="graphkav0.config">
          </app-plotly-plot>
        </ng-container>
        <ng-container *ngIf="kavansicht == 1">
          <app-plotly-plot *ngIf="kavstatistic1.length > 0"  [className]=""
            [data]="kavstatistic1" [layout]="graphkav1.layout" [config]="graphkav1.config">
          </app-plotly-plot>
        </ng-container>
        <ng-container *ngIf="kavansicht == 2">
          <app-plotly-plot *ngIf="kav_erneu_weiche.length > 0"  [className]=""
            [data]="kav_erneu_weiche" [layout]="graphkav2.layout" [config]="graphkav2.config">
          </app-plotly-plot>
        </ng-container>
        <ng-container *ngIf="kavansicht == 3">
          <app-plotly-plot *ngIf="kav_erneu_gleis.length > 0"  [className]=""
            [data]="kav_erneu_gleis" [layout]="graphkav3.layout" [config]="graphkav3.config">
          </app-plotly-plot>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<br/>
<div class="clr-row" style="margin-left: 10px;margin-right: 10px; margin-top: -15px; min-height: 250px;">
  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12" *ngIf="show_Dia_Kost">
    <div class="card">
      <h3 class="card-header d-flex justify-content-between align-items-center">{{kostenTitel}}
        <button type="button" class="btn btn-icon btn-primary btn-sm float-right" style="margin-top: -2px;" aria-label="settings" (click)="changeKostenStatus()">
          <cds-icon shape="angle" direction="right"></cds-icon>
        </button>
      </h3>
      <div class="card-block">
        <ng-container *ngIf="kostenansicht == 0">
          <app-plotly-plot *ngIf="kostenweek.length > 0"  [className]=""
            [data]="kostenweek" [layout]="graphkostenweek.layout" [config]="graphkostenweek.config">
          </app-plotly-plot>
        </ng-container>
        <ng-container *ngIf="kostenansicht == 1">
          <app-plotly-plot *ngIf="kostenmonth.length > 0"  [className]=""
            [data]="kostenmonth" [layout]="graphkostenmonth.layout" [config]="graphkostenmonth.config">
          </app-plotly-plot>
        </ng-container>
        <ng-container *ngIf="kostenansicht == 2">
          <app-plotly-plot *ngIf="kostenyear.length > 0"  [className]=""
            [data]="kostenyear" [layout]="graphkostenyear.layout" [config]="graphkostenyear.config">
          </app-plotly-plot>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="clr-col-lg-6 clr-col-md-8 clr-col-12" *ngIf="show_Dia_Prog">
    <div class="card">
      <h3 class="card-header d-flex justify-content-between align-items-center">{{userTitel}}
        <button type="button" class="btn btn-icon btn-primary btn-sm float-right" style="margin-top: -2px;" aria-label="settings" (click)="changeUserStatus()">
          <cds-icon shape="angle" direction="right"></cds-icon>
        </button>
      </h3>
      <div class="card-block">
        <ng-container *ngIf="useransicht == 0">
          <app-plotly-plot *ngIf="userweek.length > 0"  [className]=""
            [data]="userweek" [layout]="graphusernweek.layout" [config]="graphusernweek.config">
          </app-plotly-plot>
        </ng-container>
        <ng-container *ngIf="useransicht == 1">
          <app-plotly-plot *ngIf="usermonth.length > 0"  [className]=""
            [data]="usermonth" [layout]="graphusermonth.layout" [config]="graphusermonth.config">
          </app-plotly-plot>
        </ng-container>
        <ng-container *ngIf="useransicht == 2">
          <app-plotly-plot *ngIf="useryear.length > 0"  [className]=""
            [data]="useryear" [layout]="graphuseryear.layout" [config]="graphuseryear.config">
          </app-plotly-plot>
        </ng-container>
        <ng-template #other_content3>
          <div class="card">
            <h3 class="card-header">{{"Fehlerklassen" | mrTranslate}} </h3>
            <div class="card-block">
              <div class="center" style="min-height: 500px;">
                <span class="spinner spinner-md">Loading...</span>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<br/>


