import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrForm } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";

import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { MrUnitTextPipe } from "src/app/pipes/mrUnitText.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";

@Component({
  selector: 'app-begehungsdialog',
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe,HideIDColumnsPipe,TablePrettyPrintPipe,DotDotDotPipe,MrUnitTextPipe],
  templateUrl: './begehungsdialog.component.html',
  styleUrls: ['./begehungsdialog.component.scss'],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})
export class BegehungsdialogComponent {

  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    protected localeDate: BackendLocaleDatePipe,
    private basicStore: BasicStoreService,
    private overlayService: OverlayService,
  ) {
    this.formGroup.controls.inspektiondate.setValue(BackendLocaleDatePipe.now);
  }

  @ViewChild(ClrForm, { static: false })
    private clrForm: ClrForm
  ;
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  openModal = false;

  protected heightPx: number = 400;
  @ViewChild('heightRef', { static: false, read: ElementRef })
    private heightRef: ElementRef<HTMLDivElement>
  ;

  dataRefresh = new EventEmitter();
  set data(dataIn: any) {

    this.obegeid = dataIn.obegeid;
    this.obegpid = dataIn.obegpid || dataIn.recalledData?.obegpid || 0;
    this.titel = dataIn.titel;
    let inobjekttabelle = {columns: [...dataIn.objekttabelle.columns], rows: [...dataIn.objekttabelle.rows]};

    let col = {
      id: 'abschlussdatum',
      bezeichnung: 'Abschlussdatum',
      type: "DateTime",
      optionalData: null,
      pflichtFeld: null,
      wListe: null,
    }


    inobjekttabelle.columns.push(col);

    col = {
      id: 'mangelids',
      bezeichnung: 'MangelIDs',
      type: "string",
      optionalData: null,
      pflichtFeld: null,
      wListe: null,
    }

    inobjekttabelle.columns.push(col);

    col = {
      id: 'abgeschlossen',
      bezeichnung: 'Abgeschlossen',
      type: "UInt64",
      optionalData: null,
      pflichtFeld: null,
      wListe: null,
    }

    inobjekttabelle.columns.push(col);

    inobjekttabelle.rows.forEach(function(e){
      if (typeof e === "object" ){
        e["Abgeschlossen"] = false
      }
    });

    inobjekttabelle.rows.forEach(function(e){
      if (typeof e === "object" ){
        e["MangelIDs"] = null
      }
    });

    inobjekttabelle.rows.forEach(function(e){
      if (typeof e === "object" ){
        e["Abschlussdatum"] = null
      }
    });


    this.objekttabelle = inobjekttabelle;

    let zuTable = {
      columns: inobjekttabelle.columns,
      rows: [],
    }

    this.completedobjekttabelle = zuTable;

    this.dataRefresh = dataIn.dataRefresh;
    if (dataIn.recalledData) {
      this.basicStore.reloadTabs({ emitter: this.reloadTabsIsReady, reloadAnlagen: false});
      this.reloadTabsIsReady.subscribe(() => {
        this.selectedObjekt = dataIn.recalledData.objekt;
        this.getMangelTabelle(true);
      });
    }
    else this.getMangelTabelle(false);

    if (this.obegpid > 0)
    {
      this.getsxbepTable();
    }

  }

  protected formGroup = new UntypedFormGroup({
    bemerkung: new UntypedFormControl(''),
    inspektiondate: new UntypedFormControl("", [Validators.required]),
  });

  protected iconColumns: string[] = ["typ", "sperrstatus", "lfstatus", "rep", "icon"];
  protected obegeid: number = 0;
  protected obegpid: number = 0;
  protected titel: string = '';
  protected objekttabelle: any;
  protected completedobjekttabelle: any;
  protected selectedMaengel: any = [];
  protected maengel: any;
  protected maengelorg: any;
  protected ostammid: number = 0;
  protected lpid: number = 0;
  protected sxbepTable: any = [];
  protected ListOSTAMMIDSXBEP: number[] = [];
  protected selectedFinishedObjekt: any;
  protected ostammidFinished: number = 0;
  protected selectedObjekt: any;

  reloadTabsIsReady = new EventEmitter();

  addMangel(){
    const objekt = this.selectedObjekt;
    this.overlayService.setOverlay({
      overlay: "mangelerfassung",
      isTopOverlay: true,
      id: {
        ostammid: objekt["OSTAMMID"],
        otypid: objekt["OTYPID"],
        typ_Line_Point: objekt["typ_id"],
        startmeter: objekt["startmeter"],
        endmeter: objekt["endmeter"],
        mangel: this.selectedMaengel,
        opruefid: this.lpid
      },
      recallData: {
        obegpid: this.obegpid,
        objekt: this.selectedObjekt
      },
      dataRefresh:this.dataRefresh,
      zIndex: 8,
    });

  }


  getMangelTabelle(reloadBege:boolean){
    forkJoin({
      maengel: this.basicStore.getComponentStoreAsync("maengel").pipe(
        first((val) => val && JSON.parse(val).maengel.all != undefined),
        map((res: any) => JSON.parse(res)?.maengel?.all)
      ),
    }).subscribe((res: any) => {
      this.maengel = res.maengel;
      this.maengelorg = res.maengel;

      let zuTable = {
        columns: res.maengel.columns,
        rows: [],
      }

      this.selectedMaengel = zuTable;

      if (reloadBege) {
        this.selectionGridChanged(this.selectedObjekt);
      }

    });
  }

  getsxbepTable(){
    this.ListOSTAMMIDSXBEP = [];
    let obegp = {
      status: 'getsxbep',
      obegeid: this.obegeid,
      obegpid: this.obegpid,
    };

    this.apiService
    .setBegehung(obegp)
    .pipe(first())
    .subscribe((res: any) => {

      if (res?.success){
        this.sxbepTable = res.result.sxbep;
        if (res.result.sxbep.rows.length > 0){
          this.ListOSTAMMIDSXBEP = res.result.sxbep.rows.map(items => items.OSTAMMID);

          for(let sid of this.ListOSTAMMIDSXBEP){
            let index = this.objekttabelle.rows.findIndex((element) => element["OSTAMMID"] == sid);
            let row = this.objekttabelle.rows[index];
            row.Abgeschlossen = true;
            row.Abschlussdatum = BackendLocaleDatePipe.now;
            this.completedobjekttabelle.rows.push(row);
            this.objekttabelle.rows.splice(index, 1);
          }
        }
      }
      else
        this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    });
  }

  selectionGridChanged(value: any){
    this.lpid = 0;

    if (value != undefined){
      this.selectedObjekt = value;
      this.ostammid = value.OSTAMMID;
      this.selectedMaengel.rows = [];
      let rows:[] = this.maengelorg.rows.filter((cells) => cells.ostammid == this.ostammid);
      this.selectedMaengel.rows = [...rows];

      try {
        this.lpid = value.LPID;
      } catch(e) {
        console.log(e);
      }

    }
  }

  selectionFinishedObjektChanged(value: any){
    if (value != undefined){
      this.ostammidFinished = value.OSTAMMID;
    }
  }


  saveBegehung(){

    this.clrForm.markAsTouched();
    if (this.formGroup.valid) {

      let obegp = {
        status: 'update',
        obegeid: this.obegeid,
        obegpid: this.obegpid,
        completed: (this.objekttabelle.rows.length == 0),
        vals: this.formGroup.value,
      };

      this.apiService
      .setBegehung(obegp)
      .pipe(first())
      .subscribe((res: any) => {

        if (res?.success){
          this.obegpid = res.result.obegpid;
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        else
          this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });

    }

  }

  reaktivBegehungObjekt(){

    let obegp = {
      status: 'delsxbep',
      obegeid: this.obegeid,
      obegpid: this.obegpid,
      ostammid: this.ostammidFinished,
      lpid: this.lpid,
      completed: (this.objekttabelle.rows.length == 0),
      vals: this.formGroup.value,
    };

    this.apiService
    .setBegehung(obegp)
    .pipe(first())
    .subscribe((res: any) => {
      if (res?.success){

        let index = this.completedobjekttabelle.rows.findIndex((element) => element["OSTAMMID"] == this.ostammidFinished);
        let row = this.completedobjekttabelle.rows[index];
        row.Abgeschlossen = false;
        row.Abschlussdatum = null;

        this.objekttabelle.rows.push(row);
        this.completedobjekttabelle.rows.splice(index, 1);
        this.ostammidFinished =0;

        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
      }
      else
        this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    });

  }


  finishedBegehungObjekt(){

    let obegp = {
      status: 'setsxbep',
      obegeid: this.obegeid,
      obegpid: this.obegpid,
      ostammid: this.ostammid,
      lpid: this.lpid,
      completed: (this.objekttabelle.rows.length == 0),
      vals: this.formGroup.value,
    };

    this.apiService
    .setBegehung(obegp)
    .pipe(first())
    .subscribe((res: any) => {
      if (res?.success){

        let index = this.objekttabelle.rows.findIndex((element) => element["OSTAMMID"] == this.ostammid);
        let row = this.objekttabelle.rows[index];
        row.Abgeschlossen = true;
        row.Abschlussdatum = BackendLocaleDatePipe.now;

        this.completedobjekttabelle.rows.push(row);
        this.objekttabelle.rows.splice(index, 1);
        this.selectedMaengel.rows = [];

        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));

        if (this.objekttabelle.rows.length == 0){

          let obegp = {
            status: 'setsxbepcompleted',
            obegeid: this.obegeid,
            obegpid: this.obegpid,
            completed: true,
          };

          this.apiService
          .setBegehung(obegp)
          .pipe(first())
          .subscribe((res: any) => {
          });
        }
      }
      else
        this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    });
  }

  public close() {

    if (this.dataRefresh != undefined){
      this.dataRefresh.emit();
    }

    this.closedialog.next(true);
  }

  protected get dateControl(): string {
    return this.formGroup.get("inspektiondate").value;
  }
  protected set dateControl(value: string) {
    this.formGroup.get("inspektiondate").setValue(value);
  }

}
