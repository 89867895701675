<clr-main-container class="main-container" [class.dark-theme]="theme == 'dark'">
  <div id="overlay" [hidden]="!showoverlay">
    <ng-container #overlay></ng-container>
  </div>
  <div id="main">
    <div class="alert alert-app-level"></div>

    <ng-container *ngIf="isImageUrl; else baseTemplate">
      <app-image-slide-show *ngIf="images?.length" [data]="images" height="100svh"></app-image-slide-show>

      <ng-container *ngIf="showpdf">
        <div class="padding-15-t padding-15-lr h-100">
          <div class="clr-row h-100">
            <div class="clr-col-12">
              <div class="text-left inline-block">
                <h4 class="mt-0">{{'Inspektionsbericht' | mrTranslate }}</h4>
              </div>
              <div class="inline-block float-right">
                <a [attr.href]="pdf_url" [attr.download]="filename">
                  <button class="btn btn-primary btn-sm">
                    <clr-icon shape="download"></clr-icon> {{'Download' | mrTranslate}}
                  </button>
                </a>
              </div>
            </div>
            <div class="clr-col-12 nxp" style="height: calc(100vh - 51px)">
              <iframe [attr.src]="pdf_url" style="border:none; width:100%; height: 100%;"></iframe>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #baseTemplate>
    <clr-header class="header-6" *ngIf="authservice.token">
      <div class="branding">
          <img src='{{logo}}' class="mw-100-p light" />
          <img src='{{logoDark}}' class="mw-100-p dark" />
      </div>
      <div class="header-nav" [clr-nav-level]="1">
          <a *ngIf="allowComponents.map && !isRep" [routerLink]="['/dashboard']" routerLinkActive="active" class="nav-link nav-text">
            {{"Dashboard" | mrTranslate }}
          </a>
          <a *ngIf="allowComponents.map && !isRep" [routerLink]="['/karte']" routerLinkActive="active" class="nav-link nav-text">
            {{"GIS Map" | mrTranslate }}
          </a>
          <a [routerLink]="['/anlagen']" routerLinkActive="active" class="nav-link nav-text">
            {{ "Anlagen" | mrTranslate}}
          </a>
          <a *ngIf="!isRep" [routerLink]="['/maengel']" routerLinkActive="active" class="nav-link nav-text">
            {{ "Mängel" | mrTranslate}}
          </a>
          <a *ngIf="!isRep" [routerLink]="['/pruefkalender']" routerLinkActive="active" class="nav-link nav-text">
            {{ "Prüfkalender" | mrTranslate }}
          </a>
          <a *ngIf="!isRep" [routerLink]="['/auftraege']" routerLinkActive="active" class="nav-link nav-text">
            {{ "Aufträge" | mrTranslate}}
          </a>

          <a *ngIf="!production" [routerLink]="['/begehung']" routerLinkActive="active" class="nav-link nav-text">
            {{ "Begehung" | mrTranslate}}
          </a>

          <a *ngIf="!production" [routerLink]="['/wartung']" routerLinkActive="active" class="nav-link nav-text">
            {{ "Wartung" | mrTranslate}}
          </a>


          <!-- Menue mit nur ICONS -->
<!--

          <a *ngIf="allowComponents.map && !isRep" [routerLink]="['/karte']" role="tooltip" routerLinkActive="active" class="nav-link nav-text tooltip tooltip-md tooltip-bottom-right" style="width: 50px;">
            <clr-icon [ngStyle]="{ color: activeTheme == 1 ? '#FFFFFF' : '#FFFFFF'}" size="35" shape="world"></clr-icon>
            <span class="tooltip-content">{{"GIS Map" | mrTranslate }}</span>
          </a>
          <a [routerLink]="['/anlagen']" routerLinkActive="active" role="tooltip" class="nav-link nav-text tooltip tooltip-md tooltip-bottom-right" style="width: 50px;">
            <clr-icon [ngStyle]="{ color: activeTheme == 1 ? '#FFFFFF' : '#FFFFFF'}" size="30" shape="anlagenliste"></clr-icon>
            <span class="tooltip-content">{{"Anlagen" | mrTranslate }}</span>
            </a>
          <a *ngIf="!isRep" [routerLink]="['/maengel']" routerLinkActive="active" role="tooltip" class="nav-link nav-text tooltip tooltip-md tooltip-bottom-right" style="width: 50px;">
            <clr-icon [ngStyle]="{ color: activeTheme == 1 ? '#FFFFFF' : '#FFFFFF'}" size="40" shape="exclamation-triangle"></clr-icon>
            <span class="tooltip-content">{{"Mängel" | mrTranslate }}</span>
          </a>
          <a *ngIf="!isRep" [routerLink]="['/pruefkalender']" routerLinkActive="active" role="tooltip" class="nav-link nav-text tooltip tooltip-md tooltip-bottom-right" style="width: 50px;">
            <clr-icon [ngStyle]="{ color: activeTheme == 1 ? '#FFFFFF' : '#FFFFFF'}" size="35" shape="calendar"></clr-icon>
            <span class="tooltip-content">{{"Prüfkalender" | mrTranslate }}</span>
          </a>
          <a *ngIf="!isRep" [routerLink]="['/auftraege']" routerLinkActive="active" role="tooltip" class="nav-link nav-text tooltip tooltip-md tooltip-bottom-right" style="width: 50px;">
            <clr-icon [ngStyle]="{ color: activeTheme == 1 ? '#FFFFFF' : '#FFFFFF'}" size="30" shape="orders"></clr-icon>
            <span class="tooltip-content">{{"Aufträge" | mrTranslate }}</span>
          </a>
          <a *ngIf="!production" [routerLink]="['/begehung']" routerLinkActive="active" role="tooltip" class="nav-link nav-text tooltip tooltip-md tooltip-bottom-right" style="width: 50px;">
            <clr-icon [ngStyle]="{ color: activeTheme == 1 ? '#FFFFFF' : '#FFFFFF'}" size="32" shape="begehung"></clr-icon>
            <span class="tooltip-content">{{"Begehung" | mrTranslate }}</span>
          </a>
          <a *ngIf="!production" [routerLink]="['/wartung']" routerLinkActive="active" role="tooltip" class="nav-link nav-text tooltip tooltip-md tooltip-bottom-right" style="width: 50px;">
            <clr-icon [ngStyle]="{ color: activeTheme == 1 ? '#FFFFFF' : '#FFFFFF'}" size="30" shape="wartung"></clr-icon>
            <span class="tooltip-content">{{"Wartung" | mrTranslate }}</span>
          </a>
 -->

      </div>

      <div class="header-actions">
        <span [hidden]="!spinneractive" class="spinner spinner-inverse spinner-md" style="margin-top: 10px"></span>
        <div *ngIf="trialMode" id="trialInfoWrapper">
          <span>Trial aktiv</span>
          <clr-tooltip *ngIf="trialDate" style="
              padding-top: 1.3em;
              margin-right: 1em;
              margin-left: 0.3em;
            ">
            <cds-icon clrTooltipTrigger role="img" shape="info-circle" size="24" aria-label="Uniq tooltip 3"></cds-icon>
            <clr-tooltip-content clrPosition="bottom-right" clrSize="md" *clrIfOpen>
              <span>
                {{ "Der Testmodus ist gültig bis" | mrTranslate }}: <br />
                {{ trialDate | date : "medium" }}</span>
              <br />
              <span>
                {{
                "Im Trial Modus sind alle Daten manipulierenden Aktion gesperrt."
                | mrTranslate
                }}</span>
            </clr-tooltip-content>
          </clr-tooltip>
        </div>
        <clr-dropdown id="PropertiesDropdown" *ngIf="!isRep" >
          <button class="nav-link nav-icon cursor-pointer" clrDropdownTrigger
            title="{{ 'Einstellungen' | mrTranslate }}">
            <clr-icon size="22" shape="ellipsis-horizontal" [ngClass]="{ 'is-solid': activeTheme == 0 }"
              style="transform: translate(-60%, -46%)"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <a *ngIf="allowComponents.statistic" (click)="openStatistik()" clrDropdownItem><clr-icon
                [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22" shape="bar-chart"></clr-icon>
                {{ "Statistik" | mrTranslate }}</a>

            <a (click)="openAddressbook()" *ngIf="authservice.token.rights.status >= 3" clrDropdownItem><clr-icon
                [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22" shape="book"></clr-icon> {{
                "Adressen verwalten" | mrTranslate }}</a>

            <!-- <div class="openUserManagement" *ngIf="authservice.token.rights.status == 6  || authservice.token.rights.admin == true || authservice.token.rights.dev == true"> -->

            <a (click)="openUserManagement()"
              *ngIf="!production && (authservice.token.rights.showusers == true)"
              clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                  shape="users"></clr-icon> {{ "Benutzer verwalten" | mrTranslate }}</a>

            <a (click)="isBerichteModalOpen = true"
              *ngIf="!production && (authservice.token.rights.status == 6 || authservice.token.rights.admin == true || authservice.token.rights.dev == true)"
              clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                  shape="envelope"></clr-icon> {{ "Berichte verwalten" | mrTranslate }}</a>

            <clr-dropdown
              *ngIf="!production && (authservice.token.rights.status == 6 || authservice.token.rights.admin == true || authservice.token.rights.dev == true)">

              <a clrDropdownTrigger><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                  shape="container-volume"></clr-icon> {{ "Datenbank" | mrTranslate | titlecase }}</a>

              <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>

                <a (click)="openObjekttypManagement()" clrDropdownItem><clr-icon size="22"
                    shape="objecttypen"></clr-icon> {{ "Objektverwaltung" | mrTranslate | titlecase }}</a>

                <a (click)="openObjektSpezifikationenDialogComponent()" clrDropdownItem><clr-icon
                    [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                    shape="grid-view"></clr-icon> {{ "Bauformverwaltung" | mrTranslate | titlecase }}</a>

                <a (click)="openLinienverwaltungDialogComponent()" clrDropdownItem><clr-icon
                    [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22" shape="switch"></clr-icon>
                  {{ "Linienverwaltung" | mrTranslate | titlecase }}</a>

                <a (click)="openWartungsVerwaltung()" clrDropdownItem><clr-icon
                    style="color: currentColor;" size="22" shape="wartung"></clr-icon>
                  {{ "Wartungsverwaltung" | mrTranslate | titlecase }}</a>

                <a (click)="openBegehungsVerwaltung()" clrDropdownItem><clr-icon
                    style="color: currentColor;" size="22" shape="clock"></clr-icon>
                  {{ "Begehungsverwaltung" | mrTranslate | titlecase }}</a>

                  <a (click)="openPruefungenverwaltenDialog()" clrDropdownItem><clr-icon
                    style="color: currentColor;" size="22" shape="check-circle"></clr-icon>
                    {{ "Prüfungsverwaltung" | mrTranslate | titlecase }}</a>


              </clr-dropdown-menu>

            </clr-dropdown>


            <div class="showSettings"
              *ngIf="!production && (authservice.token.rights.status == 6 || authservice.token.rights.admin == true  || authservice.token.rights.dev == true)">
              <div class="dropdown-divider" role="separator"></div>
              <a (click)="settingsModalOpen = true" clrDropdownItem><clr-icon
                  [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22" shape="cog"></clr-icon> {{
                "Einstellungen" | mrTranslate | titlecase }}</a>

                <a (click)="openSystemManagement()" clrDropdownItem><clr-icon
                  [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22" shape="tools"></clr-icon> {{
                "Systemverwaltung" | mrTranslate | titlecase }}</a>
            </div>

            <clr-dropdown>
              <a clrDropdownTrigger><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                  shape="help"></clr-icon> {{ "Hilfe" | mrTranslate | titlecase }}</a>

              <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                <ng-container *ngIf="localLang == 'de'; else templateHilfe">
                  <a href="https://www.bahnwege-seminare.de/mrpro/Cloud/DE/Hilfe.html" target="_blank"
                  clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                    shape="help"></clr-icon> {{ "Hilfe" | mrTranslate | titlecase }}</a>
                </ng-container>

                <ng-template #templateHilfe>
                  <a href="https://www.bahnwege-seminare.de/mrpro/Cloud/EN/Manual.html" target="_blank"
                  clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                    shape="help"></clr-icon> {{ "Hilfe" | mrTranslate | titlecase }}</a>
                </ng-template>

                  <a href="https://mr-pro.de/de/datenschutz.html" target="_blank"
                  clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                    shape="lock"></clr-icon> {{ "Datenschutz" | mrTranslate | titlecase }}</a>

                  <a href="https://mr-pro.de/de/impressum.html" target="_blank"
                  clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                    shape="help-info"></clr-icon> {{ "Impressum" | mrTranslate | titlecase }}</a>

                  <a href="javascript:;" (click)="showInfoBox = true;"
                    clrDropdownItem><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                      shape="info-standard"></clr-icon> {{ "Info" | mrTranslate | titlecase }}</a>
              </clr-dropdown-menu>
            </clr-dropdown>


            <ng-container *ngIf="!production && UseFAT">
              <div class="dropdown-divider" role="separator"></div>
              <a (click)="openFormcreation()" clrDropdownItem><clr-icon
                  [ngStyle]="{'color': activeTheme == 0 ? '#1D3A53' : '#AEB8BC'}" size="22" shape="form"></clr-icon>
                {{'Vorlagen für Werksabnahme erstellen' | mrTranslate | titlecase }}</a>
            </ng-container>

            <ng-container *ngIf="!production">
              <clr-dropdown
                *ngIf="authservice.token.rights.status >= 3  || authservice.token.rights.admin == true || authservice.token.rights.dev == true">
                <div class="dropdown-divider" role="separator"></div>
                <a clrDropdownTrigger><clr-icon [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }"
                    size="22" shape="upload-cloud"></clr-icon> {{ "Messdaten hochladen" | mrTranslate | titlecase }}</a>

                <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                  <a (click)="openMessRegModal()" clrDropdownItem><clr-icon
                      [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                      shape="chartswitch"></clr-icon> {{ "Weichenmessung" | mrTranslate | titlecase }}</a>
                  <a (click)="openMessungModal()" clrDropdownItem><clr-icon
                      [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                      shape="line-chart"></clr-icon> {{ "Streckenmessdaten" | mrTranslate | titlecase }}</a>
                </clr-dropdown-menu>
              </clr-dropdown>

              <div class="dropdown-divider" role="separator"></div>
              <a (click)="openMessregAuswertungModal()" clrDropdownItem><clr-icon
                  [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                    shape="align-bottom"></clr-icon> {{ "Messdaten auswerten" | mrTranslate | titlecase }}</a>

              <a (click)="openSonderinspektionsListedialog()" clrDropdownItem><clr-icon
                [ngStyle]="{ color: activeTheme == 0 ? '#1D3A53' : '#AEB8BC' }" size="22"
                shape="warning-standard"></clr-icon> {{ "Sonderinspektion anzeigen" | mrTranslate | titlecase }}</a>

            </ng-container>

          </clr-dropdown-menu>
        </clr-dropdown>

        <clr-dropdown>
          <ng-container class="usernull" *ngIf="user != null">
            <button *ngIf="user.image; else baseavatar" class="nav-link nav-icon cursor-pointer" clrDropdownTrigger
              title="{{ user.vorname + ' ' + user.nachname }}">
              <img class="useravatar" [src]="getImageSaveUrl(user.image)" alt="avatar" id="avatar"
                style="display: block;" />
            </button>
          </ng-container>

          <ng-template #baseavatar>
            <button class="nav-link nav-icon cursor-pointer" clrDropdownTrigger
              title="{{ user.vorname + ' ' + user.nachname }}">
              <img class="useravatar" [src]="getSVGImageUrl(user.svg)" alt="avatar" id="avatar" />
            </button>
          </ng-template>

          <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
            <a (click)="toggleTheme()" clrDropdownItem>{{
              "Farbschema wechseln" | mrTranslate | titlecase
              }}</a>
              <a (click)="changePasswordClick()" clrDropdownItem>{{
              "Passwort ändern" | mrTranslate | titlecase
              }}</a>
            <clr-dropdown>
              <button clrDropdownTrigger>
                {{ "Sprache wechseln" | mrTranslate | titlecase }}
              </button>
              <clr-dropdown-menu clrPosition="bottom-right" *clrIfOpen>
                <clr-dropdown>
                  <a href="javascript:void(0);" (click)="changeLanguage('de')" clrDropdownItem>
                    <img src="assets/flags/de.svg" class="flag" /> deutsch
                  </a>
                  <a href="javascript:void(0);" (click)="changeLanguage('en')" clrDropdownItem>
                    <img src="assets/flags/en.svg" class="flag" /> english
                  </a>
                  <a href="javascript:void(0);" (click)="changeLanguage('fr')" clrDropdownItem>
                    <img src="assets/flags/fr.svg" class="flag" /> français
                  </a>
                  <a href="javascript:void(0);" (click)="changeLanguage('it')" clrDropdownItem>
                    <img src="assets/flags/it.svg" class="flag" /> italiano
                  </a>
                  <a href="javascript:void(0);" (click)="changeLanguage('nl')" clrDropdownItem>
                    <img src="assets/flags/nl.svg" class="flag" /> nederlands
                  </a>
                  <a href="javascript:void(0);" (click)="changeLanguage('pt')" clrDropdownItem>
                    <img src="assets/flags/pt.svg" class="flag" /> português
                  </a>
                  <a href="javascript:void(0);" (click)="changeLanguage('ru')" clrDropdownItem>
                    <img src="assets/flags/ru.svg" class="flag" /> русский
                  </a>
                  <a href="javascript:void(0);" (click)="changeLanguage('tr')" clrDropdownItem>
                    <img src="assets/flags/tr.svg" class="flag" /> türkçe
                  </a>

                </clr-dropdown>
              </clr-dropdown-menu>
            </clr-dropdown>
            <div class="dropdown-divider" role="separator"></div>
            <a (click)="logout()" clrDropdownItem>{{ "Abmelden" | mrTranslate | titlecase }}</a>
          </clr-dropdown-menu>
        </clr-dropdown>
      </div>
    </clr-header>
    <div class="content-container" [ngStyle]="{'height': authservice.token ? 'calc(100% - 3rem)' : '100%'}">
      <div id="content-area" class="content-area">
        <app-einstellungsmodal *ngIf="user" [(modalOpen)]="settingsModalOpen"></app-einstellungsmodal>
        <app-messregsmodal *ngIf="isMessRegModalOpen"></app-messregsmodal>
        <app-messungmodal *ngIf="isMessungModalOpen"></app-messungmodal>
        <app-messregauswertungmodal *ngIf="isMessregAuswertungModalOpen"></app-messregauswertungmodal>
        <app-berichte-config-modal *ngIf="isBerichteModalOpen"
          [(isOpen)]="isBerichteModalOpen"></app-berichte-config-modal>
        <router-outlet></router-outlet>
        <router-outlet name="modal"></router-outlet>
      </div>
    </div>
    </ng-template>
  </div>
</clr-main-container>

<!-- MODAL PASSWORT ÄNDERN -->
<clr-modal *ngIf="changePassword" [(clrModalOpen)]="changePassword" [clrModalSize]="'sm'" [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Passwort ändern" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm [formGroup]="passwordGroup" clrLayout="vertical">
      <div class="clr-row">
        <div class="clr-col-11">
          <div class="clr-control-container" style="width: 200px">
            <clr-password-container>
              <input
                clrPassword
                placeholder="{{ 'aktuelles Passwort' | mrTranslate }}"
                name="password"
                formControlName="opw"
                style="width: 200px"
              />
              <clr-control-error *clrIfError="'required'">{{ 'Das Feld muss ausgefüllt werden' | mrTranslate }}</clr-control-error>
            </clr-password-container>
            </div>

          <div class="clr-control-container" style="width: 200px">
            <clr-password-container>
              <input
                clrPassword
                autocomplete="off"
                placeholder="{{ 'neues Passwort' | mrTranslate }}"
                name="password"
                formControlName="npw"
                style="width: 200px"
                />
                <clr-control-error *clrIfError="'required'">{{ 'Das Feld muss ausgefüllt werden' | mrTranslate }}</clr-control-error>
            </clr-password-container>
          </div>

          <div class="clr-control-container" style="width: 200px">
            <clr-password-container>
              <input
                clrPassword
                autocomplete="off"
                placeholder="{{ 'Passwort bestätigen' | mrTranslate }}"
                name="password"
                formControlName="npw1"
                style="width: 200px"
              />
              <clr-control-error *clrIfError="'required'">{{ 'Das Feld muss ausgefüllt werden' | mrTranslate }}</clr-control-error>
            </clr-password-container>
            </div>
          <br />
            </div>
          </div>
      <div>
        <br />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="setNewPassword($event)">{{ "OK" | mrTranslate }}</button>
    <button type="button" class="btn" (click)="changePassword = false">{{ "Abbrechen" | mrTranslate }}</button>
  </div>
</clr-modal>

<!-- MODAL INFOBOX -->
<clr-modal *ngIf="showInfoBox" [(clrModalOpen)]="showInfoBox" [clrModalSize]="'xl'" [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Info" | mrTranslate }}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical">
      <div class="clr-row">
        <div class="clr-col-4">
            <clr-textarea-container style="height: 60vh;">
              <label>Info</label>
              <textarea clrTextarea style="height: 58vh; width: 20vw;" readonly>{{InfoBox}}</textarea>
            </clr-textarea-container>
        </div>
        <div class="clr-col-8">
            <clr-textarea-container>
              <label>EULA</label>
              <textarea clrTextarea style="height: 58vh; width: 38vw;" readonly>{{EULAdescription}}</textarea>
            </clr-textarea-container>

          <br />

        </div>
      </div>
      <div>
        <br />
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="showInfoBox = false">{{ "Schließen" | mrTranslate }}</button>
  </div>
</clr-modal>
