import { CommonModule, DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, first } from "rxjs";

import { getUniqueValues } from "src/app/models/ui/table";
import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from 'src/app/services/APIService/api.service';
import { AuthService } from "src/app/services/Auth/auth.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { FormService } from "src/app/services/Shared/form.service";
import { TableStateService } from "src/app/services/Shared/table-state.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { db } from "src/app/services/dexieDB";
import { FilterService } from "../../filter/filter.service";
import { ColumnFilterAction, ColumnFilterChange, ColumnFilterComponent } from "../../filter/column-filter/column-filter.component";

@Component({
  selector: "app-sammelexportdialog",
  templateUrl: "./sammelexportdialog.component.html",
  styleUrls: ["./sammelexportdialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ CommonModule, ClarityModule, MrTranslatePipe, HideIDColumnsPipe,
    TablePrettyPrintPipe, DotDotDotPipe, ColumnFilterComponent
   ],
  standalone: true
})

export class SammelexportsDialogComponent implements OnInit,OnDestroy {
  currentIndex: number = 0;
  hasRepID: boolean = localStorage.getItem("repID") != null && localStorage.getItem("repDB") == localStorage.getItem("intdb")
  _ostammid: number = undefined;
  formService: FormService;
  selected: any = [];
  set ostammid(val: number) {
    if (val === null) return;
    this._ostammid = val;
  }
  get ostammid() {
    return this._ostammid;
  }

  iconColumns: string[] = ["typ", "sp", "st", "si", "lf", "rep"];

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChildren(ColumnFilterComponent) columnFilters: QueryList<ColumnFilterComponent>;
  os:any;
  previewThumbnails: any[] = [];
  table:any;
  openModal = false;
  deleteFiltersModalOpened = false;
  tableUniqueColumnValues: any;
  objectFilter = [];
  filter_invert = false;
  tableRowsOriginal = [];
  dataRefresh = new EventEmitter();
  rownr: number = 50;
  progVersion: number = 50;
  rights: any;
  PRDEL: boolean = false;
  STDEL: boolean = false;
  promptEvent:any;
  filterService: FilterService;

  set data(dataIn: any) {
    if (dataIn) {
        this.table = dataIn.table;

        if(dataIn.dataRefresh) {
          this.dataRefresh = dataIn.dataRefresh;
        }
    }
  }


  constructor(
    private apiservice: APIService,
    private mrTranslate: MrTranslatePipe,
    private datePipe: DatePipe,
    private overlayService: OverlayService,
    private basicStore: BasicStoreService,
    private spinner: SpinnerService,
    private tableStateService: TableStateService,
    protected authService: AuthService,

    private toastr: ToastrService,

  ) {

    this.rights = JSON.parse(this.authService.decrypt(localStorage.getItem('rights')));


    let token = this.authService.getToken();
    if (token && token.rights) {
      this.progVersion = parseInt(token.rights.progVersion);
    }
  }

  close() {
    this.closedialog.next(true);
  }

  ngOnInit(): void {
    this.getAnlagenStore();
    const tableFilters = this.tableStateService.getByTableName("anlagen");
    this.hasRepID = this.apiservice.isRep

    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });

    if (tableFilters && this.grid) {
      const stateProvider = (this.grid as any).stateProvider;
      if (stateProvider?.filters?._all)
        stateProvider.filters._all = tableFilters;

      tableFilters.forEach((element) => {
        const columnId = element.filter.column.id;
        this.filterVisibility[columnId] = true;
        setTimeout(() => {
          this.filterVisibility[columnId] = false;
        }, 10);
      });
    }

    var filters = JSON.parse(sessionStorage.getItem('filter'));
    if (filters && Object.keys(filters).length > 0) {
      this.table.rows = this.filterService.setChanges(filters)
    }
  }

  ngOnDestroy(){
    sessionStorage.setItem("filter", JSON.stringify(this.filterService.getChanges()));
  }

  getAnlagenStore() {
    this.basicStore
      .getComponentStoreAsync("anlagen")
      .pipe(first((val) => val != undefined))
      .subscribe((componentStore) => {
        componentStore = JSON.parse(componentStore);
        this.table = componentStore["anlagen"];
        this.tableUniqueColumnValues = getUniqueValues(this.table);
        this.tableRowsOriginal = componentStore["anlagen"]["rows"];
        const filterApplied = this.filterService?.getChanges();
        this.filterService = new FilterService(this.tableRowsOriginal);
        this.table.rows = this.filterService.setChanges(filterApplied);
      });
  }
  installPwa() {
    this.promptEvent.prompt();
  }

  getUniqueValuesForColumn(tableValues: Set<any>, columnId: string): Set<any> {
    return tableValues[columnId] || new Set();
  }
  onFilterChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.table.rows = this.filterService.filter(columnFilterChange);
    }
  }


  reloadAnlagen(reloadTabsWaitReady: EventEmitter<any> = undefined) {
    if (reloadTabsWaitReady) {
      reloadTabsWaitReady.pipe(first((isReady: any) => isReady?.refreshAnlagen)).subscribe(() => {
        this.getAnlagenStore();
      });
    } else {
      this.apiservice.getAnlagen().pipe().subscribe((data: any) => {
        let store = this.basicStore.getComponentStore('anlagen');
        this.basicStore.setComponentStore('anlagen', { ...store, anlagen: data, ostammid: this.ostammid ?? store.ostammid });
        setTimeout(() => {
          this.getAnlagenStore();
        }, 10);
      });
    }
  }

  stammdel(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie die ausgewählten Objekte löschen wollen?"))) {
      let stammids = [];
      this.selected.forEach((row) =>{
        stammids.push(row["OSTAMMID"]);
      });

      let toSend = {};
      toSend["stammids"] = stammids;

      this.apiservice
        .deleteStammDaten(toSend)
        .pipe(first())
        .subscribe((res) => {
          if (res.success == true) {
            this.reloadAnlagen();
          }
      });
    }
  }

  stammarchiv(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie die ausgewählten Objekte archivieren wollen?"))) {
      let stammids = [];
      this.selected.forEach((row) =>{
        stammids.push(row["OSTAMMID"]);
      });

      let toSend = {};
      toSend["stammids"] = stammids;

      this.apiservice
        .archivStammDaten(toSend)
        .pipe(first())
        .subscribe((res) => {
          if (res.success == true) {
            this.reloadAnlagen();
          }
      });
    }
  }

  delpruef(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie die ausgewählte Prüfung löschen wollen?"))) {
      let toSend = {};
      toSend["OSTAMMID"] = this.selected[0]["OSTAMMID"];
      toSend["LPID"] = this.selected[0]["LPID"];

      this.apiservice
        .deletepruefungStammDaten(toSend)
        .pipe(first())
        .subscribe((res) => {
          if (res.success == true) {
            this.reloadAnlagen();
          }
      });
    }
  }


  selectionChanged(selection) {
    if (this.grid.allSelected)
      this.selected = this.table.rows;

    this.PRDEL = false;
    this.STDEL = false;

    selection.forEach((row) =>{
      let otypid = row["OTYPID"];

        Object.keys(this.rights.type).forEach((typid) => {
          if(typid == otypid.toString()){
            this.PRDEL = getTokenRight(this.rights.type[typid], "PRDEL");
            this.STDEL = getTokenRight(this.rights.type[typid], "STDEL");
          }
        });
    });

    if (selection.length > 1)
      this.PRDEL = false;

  }

  createSammel(event:any) {
    var pruefid = "";
    this.selected.forEach(row => {
      if(row.LPID != 0) pruefid = pruefid + row.LPID + ",";
    });
    pruefid = pruefid.slice(0,-1);
    this.spinner.enable();
    this.apiservice
        .getPDFFile(pruefid)
        .pipe(first())
        .subscribe((res) => {
          if (res) {
            this.spinner.disable();
            // let filename = "test.pdf";
            // this.apiservice.downloadBlobAsFile(res, filename);
            this.overlayService.setOverlay({
              overlay: "viewAuftrag",
              data: res,
              zIndex: 10,
              isTopOverlay:true
            });
          }
        });
  }


  onModalClose() {
    this.openModal = false;

    let val = localStorage.getItem('foto');

    if (val){

      let r = val.toString() as String;
      var splitted = r.split(',');

      let img = {
        src: val,
        file: null,
        date: this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS'),
        base64: splitted[1],
      };
      this.previewThumbnails.push(img);

      localStorage.removeItem('foto');

    }
  }


  filterVisibility: { [columnId: string]: boolean } = {};
  filterHidden: { [columnId: string]: boolean } = {};
  isFilterVisible(columnId: string): boolean {
    return this.filterVisibility[columnId] === true;
  }

  tabFilters: any = {};
  onFilterOpenChange(isOpen: boolean, column: any) {
    this.filterVisibility[column.id] = isOpen;
    if (!isOpen) {
      const all = _.cloneDeep((this.grid as any).stateProvider.filters._all);
      this.tableStateService.upsertTableByName("anlagen", all);
    }
  }

  applyFilter(event?: any) {
    if (event) {
      this.objectFilter = event;
    }
    if (this.objectFilter.length == 0) {
      this.table.rows = this.tableRowsOriginal;
    } else {
      if (!this.filter_invert) {
        this.table.rows = [
          ...this.tableRowsOriginal.filter(
            (row) => this.objectFilter.indexOf(row.OSTAMMID) != -1
          ),
        ];
      } else {
        this.table.rows = [
          ...this.tableRowsOriginal.filter(
            (row) => this.objectFilter.indexOf(row.OSTAMMID) == -1
          ),
        ];
      }
    }

    setTimeout(() => {
      this.grid.resize();
    });
  }

  invertFilter(event) {
    this.filter_invert = event;
    this.applyFilter();
  }

  deleteAllFilters() {
    this.filterService.clearFilters();
    if (this.columnFilters) {
      this.columnFilters.forEach(filterColumn => filterColumn.forceResetSubmit());
    }
    this.grid = this.table['rows'];
  }

  forceResetSubmit() {
    // this.isResettingFilters = true;
    this.deleteFiltersModalOpened = false;
    this.formService.resetAll("anlagen");
    (this.grid as any).stateProvider.filters.resetPageAndEmitFilterChange();
  }

  async replicate() {
    this.spinner.enable();
    let stammids = [];
    this.selected.forEach((row) =>{
      stammids.push(row["OSTAMMID"]);
    });
    let c = {};
    c["id"] = stammids;
    this.apiservice
    .setupReplicate(c)
    .pipe(first())
    .subscribe(async ({repID, list}:any) => {
      await db.delete({disableAutoOpen:false});
      await Object.entries(list).forEach(async dt => {
          await db.populate(dt);
      });
      setTimeout(() => {
        localStorage.setItem("repID",repID);
        localStorage.setItem("repDB",localStorage.getItem("intdb"));
        this.toastr.success(this.mrTranslate.transform("Replikat erstellt. App wird neu geladen..."));
        this.spinner.disable();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }, 5000); //TODO: Genug Zeit, damit die DB vollständig geladen wird. Aber es lädt dennoch irgendwie im Hintergrund weiter, kann man abfangen wann es wirklich geladen ist?
    });


  }

  async stopReplicate() {
    this.spinner.enable();
    //db.delete({disableAutoOpen:false});
    //Transferiere die Tables wieder in ein Array mit Tables, die einen Namen und Key-Value-Reihen haben, so wie wir es lieben.
    var table = await db.transaction('r', db.tables, () => {
      return Promise.all(
        db.tables.map(table => table.toArray()
          .then(rows => ({ table: table.name, rows: rows }))));
    });
    var tableMap = table.reduce((a,b) =>  {
      a[b.table] = b.rows;
      return a;
    }, {})

    var sendObj = {
      table: tableMap,
      repID: localStorage.getItem("repID")
    }

    try {
      await this.apiservice
      .syncbackReplicate(sendObj)
      .pipe(first())
      .subscribe(async (res) => {
        if(res.toString() == "OK") {
          localStorage.removeItem("repID");
          localStorage.removeItem("DB");
          localStorage.removeItem("repDB");
          this.toastr.success(this.mrTranslate.transform("Replikat zurückgespielt. App wird neu geladen..."));
          await db.delete({disableAutoOpen:false});
          this.spinner.disable();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          this.toastr.error(this.mrTranslate.transform("Fehler beim zurückspielen des Replikats"));
        }

        this.spinner.disable();
        this.dataRefresh.emit(true);
      });
    } catch (error) {
      console.log("ERROR stopReplicate: ", error);
      this.toastr.error(this.mrTranslate.transform("Replikat konnte nicht zurückgespielt werden. Sie müssen Online sein um das Replikat zurückspielen zu können."));
    }

  }


}

export function getTokenRight(rights: any[], key: string) {
  let res = undefined;
  for (const right of rights) {
    if (right.key == key) {
      res = right.value;
      break;
    }
  }
  return res;
}
