import { enableProdMode, LOCALE_ID, importProvidersFrom, InjectionToken } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MsalModule, MsalGuardConfiguration, MsalInterceptorConfiguration, MSAL_INSTANCE } from '@azure/msal-angular';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CalendarCommonModule, DateAdapter } from 'angular-calendar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { WartungComponent } from './app/content/Wartung/wartung.component';
import { BegehungComponent } from './app/content/begehung/begehung.component';
import { AuftraegeComponent } from './app/content/Auftraege/auftraege.component';
import { MaengelComponent } from './app/content/Maengel/maengel.component';
import { KalenderComponent } from './app/content/Kalender/kalender.component';
import { AnlagenComponent } from './app/content/Anlagen/anlagen.component';
import { AuthGuard } from './app/guards/auth.guard';
import { GISMapComponent } from './app/content/GISMap/gismap.component';
import { LoginComponent } from './app/content/Login/login.component';
import { HomeComponent } from './app/content/home/home.component';
import { provideRouter, Routes } from '@angular/router';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { Configuration, LogLevel, PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import { OKTA_CONFIG, OktaCallbackComponent, OktaAuthModule } from '@okta/okta-angular';
import { Logoutinterceptor } from './app/services/LogoutInterceptor/logoutinterceptor';
import OktaAuth from '@okta/okta-auth-js';
import {
  TranslateDirective
} from "@ngx-translate/core";


class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super();
  }

  override toString() {
    return this.service.currentLang;
  }
}
const oktaAuth = new OktaAuth({
  issuer: `https://login.vossloh-digital.com/oauth2/default`,
  clientId: `${environment.okta.clientId}`,
  redirectUri: window.location.origin + '/login/callback',//environment.okta.redirectUri,
  pkce: true,
  scopes: ['openid', 'profile', 'email', 'groups-mrpro'],
  responseType: ['code', 'id_token', 'token', 'refresh_token'],
  responseMode: 'query',
  storageManager: {
    token: {
      storageTypes: ['cookie'],
    }
  },
  tokenManager: {
    autoRenew: true
  },
  cookies: {
    secure: true,
    sameSite: 'none'
  }
});

export const MSAL_CONFIG_TOKEN = new InjectionToken<Configuration>('MSAL_CONFIG');

export function msalInstanceFactory(config: Configuration): PublicClientApplication {
  return new PublicClientApplication(config);
}

export function loadMsalConfig(): Promise<Configuration> {
  return new Promise((resolve) => {
    // ?? You could fetch this via HTTP or localStorage
    const rawConfig = localStorage.getItem('msal'); // or fetch('/config.json')...
    const decrypted = rawConfig ? JSON.parse(rawConfig) : null;

    const appId = decrypted?.appid ?? '';
    const tenantId = decrypted?.tenid ?? '';

    const config: Configuration = {
      auth: {
        clientId: appId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: environment.msalConfig.redirectUri,
        postLogoutRedirectUri: environment.msalConfig.redirectUri,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: false
      },
      system: {
        loggerOptions: {
          loggerCallback: () => { },
          piiLoggingEnabled: false
        }
      }
    };

    resolve(config);
  });
}

const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect, // MSAL Guard configuration
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', ['user.read']], // Protect Microsoft Graph API
  ]),
};

const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect, // Can be 'Popup' or 'Redirect'
  authRequest: {
    scopes: ['user.read'], // Define the required scopes
  },
};

import dayjs from 'dayjs';

import { registerLocaleData } from '@angular/common';


import * as localeDJDe from 'dayjs/locale/de';
import * as localeDJEn from 'dayjs/locale/en';
import * as localeDJFr from 'dayjs/locale/fr';
import * as localeDJIt from 'dayjs/locale/it';
import * as localeDJNl from 'dayjs/locale/nl';
import * as localeDJRu from 'dayjs/locale/ru';
import * as localeDJPt from 'dayjs/locale/pt';
import * as localeDJTr from 'dayjs/locale/tr';

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localeRu from '@angular/common/locales/ru';
import localePt from '@angular/common/locales/pt';
import localeTr from '@angular/common/locales/tr';
import { MrTranslatePipe } from './app/pipes/mr-translate.pipe';
import { urlEnDecodePipe } from './app/pipes/urlEncode.pipe';
import { TablePrettyPrintPipe } from './app/pipes/tablePrettyPrint.pipe';
import { HideIDColumnsPipe } from './app/pipes/hide-idcolumns.pipe';
import { WerteRowValuesPipe } from './app/content/_components/_overlays/zaehlerdialog/zaehlerverwaltung/werte-row-values.pipe';
import { MrUnitTextPipe } from './app/pipes/mrUnitText.pipe';
import { AngularDeviceInformationService } from 'angular-device-information';
import { ObjTypePipe } from './app/pipes/objtype.pipe';
import { DashboardComponent } from './app/content/Dashboard/dashboard.component';
import { MsalConfigService } from './app/services/msal-config.service';

registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localeRu);
registerLocaleData(localePt);
registerLocaleData(localeTr);

dayjs.locale(localeDJDe);
dayjs.locale(localeDJEn);
dayjs.locale(localeDJFr);
dayjs.locale(localeDJIt);
dayjs.locale(localeDJNl);
dayjs.locale(localeDJRu);
dayjs.locale(localeDJPt);
dayjs.locale(localeDJTr);


function dayjsAdapterFactory() {
  return adapterFactory(dayjs);
}


const appRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: 'home', redirectTo: 'karte' },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
  {
    path: 'karte',
    component: GISMapComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
  {
    path: 'anlagen',
    component: AnlagenComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
  {
    path: 'pruefkalender',
    component: KalenderComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
  {
    path: 'maengel',
    component: MaengelComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
  {
    path: 'auftraege',
    component: AuftraegeComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
  {
    path: 'begehung',
    component: BegehungComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
  {
    path: 'wartung',
    component: WartungComponent,
    canActivate: [AuthGuard /*, AppDataGuard*/],
  },
];
function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;



if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {

  providers: [
    importProvidersFrom(BrowserModule, ClarityModule, FormsModule, ReactiveFormsModule, TranslatePipe, TranslateDirective,
      ToastrModule.forRoot({ positionClass: 'toast-bottom-right' }),
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
      }),
      TranslateModule.forRoot({
        defaultLanguage: 'de',
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      CalendarCommonModule.forRoot({
        provide: DateAdapter,
        useFactory: dayjsAdapterFactory,
      }),
      OktaAuthModule.forRoot({ oktaAuth })),
      // MsalConfigDynamicModule.forRoot()),
    // {
    //   provide: MSAL_INSTANCE,
    //   useFactory: (configService: MsalConfigService) => {
    //     configService.setConfig('', '');
    //     configService.getInstance();
    //   },
    //   deps: [MsalConfigService],
    // },
    // MsalConfigService,

    // * if Servise has providedIn: "root", there is no need to declare here
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: HTTP_INTERCEPTORS, useClass: Logoutinterceptor, multi: true },
    { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    { provide: OktaAuth, useValue: oktaAuth },
    { provide: APP_BASE_HREF, useValue: '/' },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(appRoutes),
    provideAnimations(),
    MrTranslatePipe,
    urlEnDecodePipe,
    TablePrettyPrintPipe,
    HideIDColumnsPipe,
    WerteRowValuesPipe,
    DatePipe,
    ObjTypePipe,
    MrUnitTextPipe,
    {
      provide: AngularDeviceInformationService,
      useFactory: (platformId: Object) => new AngularDeviceInformationService(platformId),
      deps: [PLATFORM_ID]
    }
  ]
})
  .catch(err => console.error(err));
