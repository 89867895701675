import { Attribute, Component, Input } from '@angular/core';
import { slideAnimation } from '../../detailview/slide.animation';
import { DomSanitizer } from '@angular/platform-browser';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { MediaViewModalComponent } from '../mediaviewmodal/mediaviewmodal.component';

@Component({
    selector: 'app-image-slide-show',
    templateUrl: './image-slide-show.component.html',
    styleUrls: ['./image-slide-show.component.scss'],
    imports: [CommonModule, ClarityModule, MediaViewModalComponent],
    animations: [slideAnimation],
    providers: [MrTranslatePipe],
    standalone: true
})
export class ImageSlideShowComponent {
  protected currentIndex: number = 0;
  protected currentThumb: any;
  protected previewThumbnails: any[];
  protected files: any[];

  protected imageMedia: any;

  @Input() set data(files: any[]) {
    this.files = files;
    this.previewThumbnails = files?.map(f => {
      const extension = f.extension ?? f.name?.split(".").pop();
      const type = 'data:image/' + (extension == 'svg' ? 'svg+xml' : extension || 'png');
      return this.domSanitizer.bypassSecurityTrustUrl(type + ';base64,' + f.base64);
    });
    this.changeIndexTo(0);
  }

  constructor(
    private domSanitizer: DomSanitizer,
    private mrTranslate: MrTranslatePipe,
    @Attribute('height') protected readonly height: string,
    @Attribute('invert') protected readonly invert: boolean,
  ) {
    this.height ||= '45vh';
    this.invert = invert !== null;
  }

  imagezoom(img) {
    this.imageMedia = [];

    if (!this.files[this.currentIndex].name) {

      let skizze = {
        name: 'skizze.png',
        base64: this.files[this.currentIndex].base64,
      }

      this.imageMedia.push(
        {
          title: this.mrTranslate.transform("Messpunktskizze"),
          fileNames: [skizze],
        }
      )
      return;
    }

    this.imageMedia.push(
      {
        title: this.mrTranslate.transform("Passbild"),
        fileNames: [this.files[this.currentIndex]],
      }
    )
  }


  protected moveGallery(mode: 'right' | 'left') {
    let nextIndex = this.currentIndex;
    if (mode == 'right') nextIndex++;
    else if (mode == 'left') nextIndex--;

    if (nextIndex >= this.previewThumbnails.length) nextIndex = 0;
    if (nextIndex < 0) nextIndex = this.previewThumbnails.length - 1;

    this.changeIndexTo(nextIndex);
  }

  protected changeIndexTo(index: number) {
    this.currentIndex = index;
    this.currentThumb = this.previewThumbnails?.[index];
  }
}
