<div class="overlay-inner-wrapper">
  <header class="header-2 scroll-x">
    <div class="branding">
      <span class="title" *ngIf="openView == 'home'; else viewTitle">
        {{ "Wartungsverwaltung" | mrTranslate }}
      </span>
      <ng-template #viewTitle>
        <span class="title">
          {{ "Wartungs" + openView | mrTranslate }}
        </span>        
      </ng-template>
    </div>
    <div class="header-actions">
      <div class="clr-form-horizontal">
        <clr-select-container *ngIf="objekte$ | async as objekte">
          <label style="width: auto; padding: 0 2px;" for="objekt">
            <clr-icon *ngIf="objekte[otypid]?.symbol as symbol" style="color: #000;" class="customIcon"
              [attr.shape]="'objekttyp' + symbol" size="22"></clr-icon>
          </label>
          <select clrSelect name="objekt" class="cur-color"
            [(ngModel)]="otypid" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let typ of objekte | keyvalue" [value]="typ.key">
              {{ typ.value.bezeichnung }}
            </option>
          </select>
        </clr-select-container>
      </div>
      <div class="header-nav">
        <a *ngIf="openView == 'home'" class="nav-link nav-icon close" href="javascript:void(0);" (click)="close()">
          <clr-icon shape="times" size="22" [title]="'Schliessen' | mrTranslate"></clr-icon>
        </a>
        <a *ngIf="openView != 'home'" class="nav-link nav-icon" href="javascript:void(0);" (click)="openView = 'home'">
          <clr-icon shape="view-cards" size="22" [title]="'Zurück' | mrTranslate"></clr-icon>
        </a>
      </div>
    </div>
  </header>
  <ng-container [ngSwitch]="openView">
    <div *ngSwitchCase="'home'" class="w-100-p padding-10-lr padding-12-b scroll-y h-60">
      <div class="clr-row nxm w-100-p cards">
        <div class="clr-col-md-6">
          <a (click)="openView = 'baum'" class="card clickable">
            <div class="card-header">{{ "Wartungsbaum" | mrTranslate }}</div>
            <div class="card-block clr-row nxm clr-align-items-center">
              <clr-icon class="clr-col-auto" shape="tree-view"></clr-icon>
              <p class="card-text clr-col-lg clr-col-md-12 clr-col-sm">
                {{ "Bearbeiten, Erstellen und Löschen von der Wartungsarten und -arbeiten, sowie ihren Verknüpfungen" | mrTranslate }}
              </p>
            </div>
            <div class="card-footer">
              <button class="btn btn-sm btn-link"> {{ "Öffnen" | mrTranslate }} </button>
            </div>
          </a>
        </div>
        <div class="clr-col-md-6">
          <a (click)="openView = 'personal'" class="card clickable">
            <div class="card-header">{{ "Personal" | mrTranslate }}</div>
            <div class="card-block clr-row nxm clr-align-items-center">
              <clr-icon class="clr-col-auto" shape="assign-user"></clr-icon>
              <p class="card-text clr-col-lg clr-col-md-12 clr-col-sm">
                {{ "Bearbeiten, Erstellen und Löschen von der Personal für die Wartungstätigkeiten" | mrTranslate }}
              </p>
            </div>
            <div class="card-footer">
              <button class="btn btn-sm btn-link"> {{ "Öffnen" | mrTranslate }} </button>
            </div>
          </a>
        </div>

        <!-- <div class="clr-break-row"></div> -->
        <div class="clr-col-md-6">
          <a (click)="openView = 'masken'" class="card clickable">
            <div class="card-header">
              {{ "Wartungsmasken" | mrTranslate }}
            </div>
            <div class="card-block clr-row nxm clr-align-items-center">
              <clr-icon class="clr-col-auto" shape="nvme"></clr-icon>
              <p class="card-text clr-col-lg clr-col-md-12 clr-col-sm">
                {{ "Bearbeiten, Erstellen und Löschen von der benannten Masken mit Wartungsarten-Checkliste" | mrTranslate }}
              </p>
            </div>
            <div class="card-footer">
              <button class="btn btn-sm btn-link"> {{ "Öffnen" | mrTranslate }} </button>
            </div>
          </a>
        </div>
        <div class="clr-col-md-6">
          <a (click)="openView = 'zuordnung'" class="card clickable">
            <div class="card-header">
              {{ "Objektzuordnung" | mrTranslate }}
            </div>
            <div class="card-block clr-row nxm clr-align-items-center">
              <clr-icon class="clr-col-auto" shape="file-settings"></clr-icon>
              <p class="card-text clr-col-lg clr-col-md-12 clr-col-sm">
                {{ "Bearbeiten, Erstellen und Löschen von der Verknüpfungen 'Objekt-Maske' mit der Eingabe vom Intervall der Wartungen" | mrTranslate }}
              </p>
            </div>
            <div class="card-footer">
              <button class="btn btn-sm btn-link"> {{ "Öffnen" | mrTranslate }} </button>
            </div>
          </a>
        </div>
      </div>
    </div>
    <app-wartungsbaum *ngSwitchCase="'baum'" [otypid]="otypid"></app-wartungsbaum>
    <app-wartungsmasken *ngSwitchCase="'masken'" [otypid]="otypid" [refresh]="dataRefresh" (view)="openView = $event"></app-wartungsmasken>
    <app-wartungsobjektzuordnung *ngSwitchCase="'zuordnung'" [otypid]="otypid" [refresh]="dataRefresh" (view)="openView = $event"></app-wartungsobjektzuordnung>      
    <div *ngSwitchCase="'personal'" class="w-100-p padding-10-lr padding-12-b scroll-y h-60 clr-row nxm">
      <div class="clr-col-lg-8 clr-col-12 h-100 centred">
        <app-wartungsitem [mode]="'personal'" [otypid]="otypid" [refresh]="dataRefresh"></app-wartungsitem>
      </div>
    </div>
  </ng-container>
</div>