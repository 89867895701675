import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { APIService } from '../APIService/api.service';
import { AuthService } from '../Auth/auth.service';
import JSZip from 'jszip';
import { db } from '../dexieDB';

@Injectable({
  providedIn: 'root'
})
export class BasicStoreService {

  asyncStore: { [key: string]: BehaviorSubject<any> } = {
    anlagen: new BehaviorSubject(undefined),
    maengel: new BehaviorSubject(undefined),
    einstellung: new BehaviorSubject(undefined),
  };

  detailViewStore: Record<string, any> = {};
  loggedStatus: boolean;

  constructor(
    private apiservice: APIService,
    private authservice: AuthService
  ) {
    this.authservice.getLoggedIn().pipe(first(data0 => data0 === true )).subscribe(async _ => {
      this.apiservice.refreshJWT();
      await this.initialize();
    });
  }

  private async initialize() {
    try {
      firstValueFrom(this.apiservice.getAnlagen()).then((anlagenData) => {
        this.setComponentStore('anlagen', { anlagen: anlagenData });
      });
      
      if (!this.apiservice.isRep) {
        firstValueFrom(this.apiservice.getMaengel(false)).then((maengelData) => {
          var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
          jsZip.loadAsync(maengelData).then((zip) => {
            return zip.file("tmp.txt").async("string");
          }).then((zipf) => {
            this.setComponentStore('maengel', { maengel: JSON.parse(zipf) })
          }
          );
        });
      }
      await this.initSettings();
    } catch (error) {
      console.error('Initialization error:', error);
    }
  }

  private async initSettings() {
    try {
      const data: any = await firstValueFrom(this.apiservice.getINIOptions());
      if (data) {
        this.setComponentStore('einstellung', { einstellung: data.rows[0] });
      }
    } catch (error) {
      console.error('Settings initialization error:', error);
    }
  }

  public getComponentStoreAsync(name: string) {
    return this.asyncStore[name] || undefined;
  }

  public getComponentStore(componentName: string) {
    let val = this.asyncStore[componentName]?.getValue();
    if (typeof val === 'string') val = JSON.parse(val);
    return val || undefined;
  }

  public setComponentStore(componentName: string, componentConfig: any, parseJSON: boolean = true) {
    if (!this.asyncStore[componentName]) {
      this.asyncStore[componentName] = new BehaviorSubject(undefined);
    }
    if (componentConfig) {
      const value = parseJSON ? JSON.stringify(componentConfig) : componentConfig;
      this.asyncStore[componentName].next(value);
    }
  }

  public resetComponentStore(componentName: string, componentConfig: any, parseJSON: boolean = true) {
    const before = this.getComponentStore(componentName);
    this.setComponentStore(componentName, { ...before, ... componentConfig }, parseJSON);
  }

  public deleteComponentStoreAsync(componentName: string) {
    if (this.asyncStore[componentName]) {
      this.asyncStore[componentName].next(undefined);
    }
  }

  public getDetailViewStore() {
    return this.detailViewStore;
  }

  public setDetailViewStore(dvconf: Record<string, any>) {
    this.detailViewStore = dvconf;
  }

  public reloadTabs({ emitter: reloadTabsIsReady = undefined, reloadAnlagen = true }: { emitter?: EventEmitter<any>, reloadAnlagen?: boolean } = {}) {
    if (reloadAnlagen) {
      this.apiservice.getAnlagen().pipe().subscribe((data: any) => {
        this.resetComponentStore('anlagen', { anlagen: data });
        reloadTabsIsReady?.emit({ refreshAnlagen: true });
      });
    }

    this.apiservice.getMaengel(false).pipe(first()).subscribe((data: any) => {

      if (!this.apiservice.isRep) {
        var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
        jsZip.loadAsync(data).then((zip) => {
          return zip.file("tmp.txt").async("string");
        }).then((zipf) => {
          this.resetComponentStore('maengel', { maengel: JSON.parse(zipf) });
          reloadTabsIsReady?.emit({ refreshMaengel: true });
        });
      }

    });

    this.resetComponentStore('auftraege', { auftraege: null });
  }
}
