import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { PreviewThumbnailsComponent } from '../../_shared/preview-thumbnails/preview-thumbnails.component';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';


export type HilfeBeschreibung = {
  HelpBeschreibung?: string;
  HelpBildBase64?: string;
  FileTyp?: string;
}

@Component({
    selector: 'app-hilfe-beschreibung-modal',
    templateUrl: './hilfebeschreibungmodal.component.html',
    styleUrls: ['./hilfebeschreibungmodal.component.scss'],
    imports: [
        CommonModule, ClarityModule, FormsModule,
        PreviewThumbnailsComponent, AutofocusDirective, MrTranslatePipe
    ],
    standalone: true
})
export class HilfeBeschreibungModalComponent {
  @ViewChild(PreviewThumbnailsComponent, { static: false })
    private preview: PreviewThumbnailsComponent
  ;
  @ViewChild(NgForm, { static: false })
    protected ngForm: NgForm
  ;
  @Input('readonly') isReadOnly = false;

  @Output() onSave = new EventEmitter<HilfeBeschreibung>();

  @Input() public set item (value: HilfeBeschreibung) {
    if (value) {
      this.model = {
        helperText: value.HelpBeschreibung,
        base64: value.HelpBildBase64,
        extension: value.FileTyp,
        pdfUrl: null
      };
      this.isHelperModalOpen = true;
      const name = this.mrTranslate.transform("Hilfe");
      setTimeout(() => {
        this.preview.clearPreview();
        if (this.model.base64) {
          this.preview.unpackFiles([{
            name: name + '.' + this.model.extension,
            base64: this.model.base64
          }]);
          this.checkPDF(this.model.extension);
        }
      });
    } else this.isHelperModalOpen = false;
  }

  protected model: {
    helperText: string;
    base64: string;
    extension: string;
    pdfUrl: SafeUrl;
  };

  protected isHelperModalOpen = false;
  protected ReadOnly = false;

  constructor(
    private mrTranslate: MrTranslatePipe,
    private domSanitizer: DomSanitizer,
  ) {}


  protected async saveHelper() {
    const { helperText } = this.ngForm.value;
    const [{
      base64: newBase64 = null,
      extension: newExt = null
    } = {}] = await this.preview.packUnsavedFiles();


    // * newBase64 = null und base64 = "{base64}", wenn nicht geändert; kein Speichern ist nötig
    // ! newBase64 = null und base64 = "", wenn Media ist gelöscht, Speichern ist nötig
    if (
      helperText == this.model.helperText &&
      (newBase64 == this.model.base64 || this.model.base64)
    ) {
      this.isHelperModalOpen = false;
      return;
    }

    const sendObj = {
      HelpBeschreibung: helperText || null, // get rid of ""
      HelpBildBase64: (newBase64 ?? this.model.base64) || null,
      FileTyp: (newExt ?? this.model.extension) || null
    };

    this.onSave.emit(sendObj);
  }

  protected clearHelper() {
    this.model.base64 = this.model.extension = "";
  }

  protected checkPDF(name: string) {
    if (name.split('.').pop().toLowerCase() == 'pdf')
      this.preview.forceOpenPdf(0);
  }

  protected showPDF(file: File) {
    this.model.pdfUrl ??= this.domSanitizer.bypassSecurityTrustResourceUrl(
      URL.createObjectURL(file)
    );
  }
  //#endregion
}
