<div class="clr-row nxm mh-100 mw-100-p">
  <ng-container *ngIf="!disabled && previewThumbnails.length < maxAmount">

    <ng-container *ngIf="drag_drop; else noDragDrop">
      <div class="clr-row clr-col nxm" (uploadDropzone)="onFileChange($event)">

        <label class="clr-col-12 clr-control-label" #infoLabel>
          {{ ("Medien hochladen" | mrTranslate) + " (" + ("bis 50MB" | mrTranslate) + ")" }}
        </label>

        <div class="innerWrapper clr-row">
          <clr-icon [class]="isWindows ? 'clr-col-auto' : 'clr-col-12'" style="height: 50px;" shape="upload-cloud"></clr-icon>

          <ng-container *ngIf="isWindows && showCamera">
            <app-camera *ngIf="openCameraModal" [(showWebcam)]="openCameraModal"
              (onTakenFoto)="onTakenFoto($event)"></app-camera>
            <button type="button" class="btn clr-col-auto nxm" style="min-width: 43px;"
              (click)="openCameraModal = true">
              <clr-icon shape="camera"></clr-icon>
            </button>
          </ng-container>

          <span class="clr-col-12" style="font-size: smaller;">
            {{ 'oder' | mrTranslate }}</span>
          <div class="clr-col-12 d-f center">
            <button type="button" class="nxm btn btn-sm btn-primary" type="button"
              (click)="fileInput.click()">{{ "upload" | mrTranslate }}
            </button>
            <input type="file" #fileInput id="fileInput" name="fileInput"
              [accept]="acceptTypes" hidden multiple
              (change)="onFileChange($event.target.files)"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #noDragDrop>
      <div id="control-row" class="clr-row clr-col-auto nxm nxp margin-15-t">
        <div class="btn-group">
          <button type="button" class="btn" style="min-width: 43px;" (click)="fileInput.click()"
            [title]="'Bild auswählen' | mrTranslate">
            <clr-icon shape="upload-cloud"></clr-icon>
          </button>
          <button type="button" *ngIf="isWindows && showCamera" class="btn" style="min-width: 43px;" (click)="openCameraModal = true"
            [title]="'Kamera öffnen' | mrTranslate">
            <clr-icon shape="camera"></clr-icon>
          </button>
        </div>
        <input type="file" #fileInput id="fileInput" name="fileInput"
          [accept]="acceptTypes" hidden multiple
          (change)="onFileChange($event.target.files)"
        />
        <app-camera *ngIf="openCameraModal" [(showWebcam)]="openCameraModal" (onTakenFoto)="onTakenFoto($event)"></app-camera>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="previewThumbnails.length > 0">

    <ng-container *ngIf="drag_move; else noDragMove">
      <div class="clr-row clr-col nxm previewThumbnailsContainer" [class.line]="galleryMode"
        cdkDropList #dragList="cdkDropList"
        cdkDropListOrientation="horizontal" [cdkDropListData]="previewThumbnails" [cdkDropListConnectedTo]="[dropList]" (cdkDropListDropped)="drop($event)">

        <div *ngFor="let thumb of previewThumbnails; let i = index" class="uploadImagePreviewContainer"
          [style.max-width]="thumb.plays ? '150px' : 'auto' " [class.highlight]="thumb.highlight" cdkDrag>
          <ng-container *ngTemplateOutlet="previewMedia; context: { $implicit: thumb, index: i }"></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-template #noDragMove>
      <div class="clr-row clr-col nxm previewThumbnailsContainer" [class.line]="galleryMode">
        <div *ngFor="let thumb of previewThumbnails; let i = index" class="uploadImagePreviewContainer"
          [style.max-width]="thumb.plays ? '150px' : 'auto' " [class.highlight]="thumb.highlight">
          <ng-container *ngTemplateOutlet="previewMedia; context: { $implicit: thumb, index: i }"></ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template #previewMedia let-thumb let-i="index">
      <ng-container *ngIf="!disabled">
        <a class="deletePreview" (click)="deleteDoc(i)">
          <clr-icon shape="times-circle" class="is-solid"></clr-icon>
        </a>
      </ng-container>
      <a (click)="openDoc(i)" style="margin: 0 auto;">
        <img #preview [src]="thumb.src" alt="" class="uploadImagePreview"
          [class.invertWhenDark]="isSkizze" draggable="false"/>
      </a>
      <ng-container *ngIf="thumb.opens"> <!-- PDF Files -->
        <a class="titleFile" (click)="openPdfDoc(i)">
          <br>{{ thumb.file?.name ?? ("Öffnen" | mrTranslate) }}
        </a>
      </ng-container>
      <ng-container *ngIf="thumb.plays"> <!-- Video Files -->
        <a class="titleFile" (click)="
          (showPlay.checked = !showPlay.checked ? true : false) && openVideo(i);
          preview.style.display = showPlay.checked ? 'none' : 'block';
        ">
          <br>{{ thumb.file.name ?? ("Öffnen" | mrTranslate) }}
        </a>
        <input hidden type="checkbox" name="showPlay" id="showPlay" #showPlay [ngModelOptions]="{standalone: true}">
        <ng-container *ngIf="showPlay.checked">
          <clr-spinner *ngIf="!thumb.url; else video" [clrMedium]="true">
          </clr-spinner>
          <ng-template #video>
            <video [src]="thumb.url" width="150" height="auto" autoplay [muted]="true" loop controls>
              {{ "Your browser does not support the video tag." | mrTranslate }}
            </video>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-template>
  </ng-container>
</div>

<!--
<clr-modal [(clrModalOpen)]="openimage" [clrModalSize]="'xl'" [clrModalClosable]="true">
  <div class="modal-body d-f" style="height: min(70svh, 49vw)">
    <img [src]="imgSrc" class="zoomImagePreview" [class.invertWhenDark]="isSkizze"
      draggable="false"/>
  </div>
</clr-modal>
 -->

<app-mediaviewmodal *ngIf="imageMedia"
  (onClose)="imageMedia = undefined"
  [media]="imageMedia">
</app-mediaviewmodal>
