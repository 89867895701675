<div class="overlay-inner-wrapper">
  <div id="sperrungWrapper">
    <div class="clr-row" style="max-height: 26.391px">
      <div class="clr-col-md-12 nopaddingleft nopaddingright">
        <div class="text-left inline-block">
          <h3 class="mt-0" style="display: inline-block">Sperrung {{ mode == 'new' ? 'anlegen' : 'bearbeiten'}}</h3>
          <button *ngIf="mode == 'edit'" class="btn btn-sm margin-15-l margin-6-b" style="display: inline-block"
            (click)="switchMode('new')">
            <clr-icon shape="plus"></clr-icon> Neue Sperrung anlegen
          </button>
        </div>
        <div class="inline-block float-right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
    </div>

    <form class="login" id="sperrungform" clrForm [formGroup]="sperrungGroup" (ngSubmit)="onSubmit()">
      <div class="clr-row">
        <div class="clr-col-md-6 clr-col-12 nopaddingleft">
          <clr-date-container>
            <label class="clr-col-md-4">{{'Beginn der Sperrung' | mrTranslate}}</label>
            <input class="clr-col-md-8" type="date" name="date" clrDate
              [ngModel]="sperrungGroup.get('date').value | localeDate"
              (ngModelChange)="sperrungGroup.get('date').setValue(localeDate.tosql($event))"
              [ngModelOptions]="{updateOn: 'blur', standalone: true}"
              [disabled]="sperrungGroup.disabled">
          </clr-date-container>
          <clr-input-container>
            <label class="clr-col-md-4">{{'Zeit Beginn' | mrTranslate }}</label>
            <input class="clr-col-md-8" clrInput placeholder="00:00" type="time" name="time" formControlName="time" />
          </clr-input-container>
          <ng-container *ngIf="mode == 'edit'">
            <clr-date-container>
              <label class="clr-col-md-4">{{'Ende der Sperrung' | mrTranslate }}</label>
              <input class="clr-col-md-8" type="date" name="edate" clrDate
                [ngModel]="sperrungGroup.get('edate').value | localeDate"
                (ngModelChange)="sperrungGroup.get('edate').setValue(localeDate.tosql($event))"
                [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                [disabled]="sperrungGroup.disabled">
            </clr-date-container>
            <clr-input-container>
              <label class="clr-col-md-4">{{'Zeit Ende' | mrTranslate }}</label>
              <input class="clr-col-md-8" clrInput placeholder="00:00" type="time" name="etime"
                formControlName="etime" />
            </clr-input-container>
          </ng-container>



          <div class="comboboxcontainer clr-form-control clr-row">
            <label class="clr-col-md-4 clr-control-label">{{'gemeldet durch' | mrTranslate}}</label>
            <div class="clr-control-container clr-col-md-8">
              <input class="clr-input combobox" placeholder="Name" type="search" name="gemeldetDurch"
                formControlName="gemeldetDurch" list="gemeldetDurchList" />
            </div>
            <datalist id="gemeldetDurchList">
              <ng-container *ngIf="gemeldetDurch">
                <option *ngFor="let name of gemeldetDurch" [value]="name">
              </ng-container>
            </datalist>
          </div>

          <div class="comboboxcontainer clr-form-control clr-row" *ngIf="mode == 'edit'">
            <label class="clr-col-md-4 clr-control-label">{{'aufgehoben durch' | mrTranslate}}</label>
            <div class="clr-control-container clr-col-md-8">
              <input class="clr-input combobox" placeholder="Name" type="search" name="aufgehobenDurch"
                formControlName="aufgehobenDurch" list="aufgehobenDurchList" />
            </div>
            <datalist id="aufgehobenDurchList">
              <ng-container *ngIf="aufgehobenDurch">
                <option *ngFor="let name of aufgehobenDurch" [value]="name">
              </ng-container>
            </datalist>
          </div>


          <div id="lineWrapper" *ngIf="(typ_Line_Point | objtype) == 'line'">
            <div class="clr-row">
              <div class="clr-col">
                <clr-checkbox-wrapper class="clr-row">
                  <input type="checkbox" clrCheckbox name="gesamtesGleis" formControlName="gl_g" />
                  <label>{{'Gesamtes Gleis' | mrTranslate}}</label>
                </clr-checkbox-wrapper>
                <div *ngIf="sperrungGroup.errors?.['not-in-Direction'] as err"
                  class="clr-row nxm clr-col-auto">
                  <clr-alert class="w-100-p" clrAlertType="danger" [clrAlertClosable]="false">
                    <clr-alert-item>
                      <span class="alert-text">
                        {{ "Der Startmeter muss " + (err.upwards ? "kleiner" : "größer") + " als der Endmeter sein" | mrTranslate }}
                      </span>
                    </clr-alert-item>
                  </clr-alert>
                </div>
                <div class="clr-row" [hidden]="sperrungGroup.value.gl_g">
                  <clr-input-container class="clr-col-6" clrInline>
                    <label class="clr-col-md-4">{{'von' | mrUnitText }}</label>
                    <input class="" clrInput [placeholder]="startmeter !== undefined ? startmeter+'...' :'0'"
                      name="gleisVon" formControlName="gl_v" />
                    <clr-control-error *clrIfError="'required'">
                      {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'min'; error as err">
                      {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'max'; error as err">
                      {{ "Der Wert ist größer als" | mrTranslate }} {{err.max}}
                    </clr-control-error>
                  </clr-input-container>
                  <clr-input-container class="clr-col-6" clrInline>
                    <label class="clr-col-md-4">{{'bis' | mrUnitText}}</label>
                    <input class="" clrInput [placeholder]="endmeter !== undefined ? '...'+endmeter : '987'"
                      name="gleisBis" formControlName="gl_b" />
                    <clr-control-error *clrIfError="'required'">
                      {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'min'; error as err">
                      {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
                    </clr-control-error>
                    <clr-control-error *clrIfError="'max'; error as err">
                      {{ "Der Wert ist größer als" | mrTranslate }} {{err.max}}
                    </clr-control-error>
                  </clr-input-container>
                </div>
              </div>
            </div>
          </div>



          <div id="pointWrapper" *ngIf="(typ_Line_Point | objtype) == 'point' && pointtype && pointtype == 1">
            <div class="clr-row">
              <div class="clr-col blockPoint cursor-pointer" (click)="selectRadio('2')"
                [ngClass]="{'active': sperrungGroup.value.ew == 2}">
                <img class="vector" src="assets/icons/weichen/EW_blockStraight.svg">
                <div class="radio">
                  <input type="radio" clrRadio name="ew" value="2" formControlName="ew" />
                  <label class="radiolabel">{{'Gerade' | mrTranslate}}</label>
                </div>
              </div>
              <div class="clr-col blockPoint cursor-pointer" (click)="selectRadio('3')"
                [ngClass]="{'active': sperrungGroup.value.ew == 3}">
                <img class="vector" src="assets/icons/weichen/EW_blockBranch.svg">
                <div class="radio">
                  <input type="radio" clrRadio name="ew" value="3" formControlName="ew" />
                  <label class="radiolabel">{{'Abzweig' | mrTranslate}}</label>
                </div>
              </div>
              <div class="clr-col blockPoint cursor-pointer" (click)="selectRadio('1')"
                [ngClass]="{'active': sperrungGroup.value.ew == 1}">
                <img class="vector" src="assets/icons/weichen/EW_blockBoth.svg">
                <div class="radio">
                  <input type="radio" clrRadio name="ew" value="1" formControlName="ew" />
                  <label class="radiolabel">{{'Gerade und Abzweig' | mrTranslate}}</label>
                </div>
              </div>
            </div>
          </div>



          <div id="pointDoubleWrapper" *ngIf="(typ_Line_Point | objtype) == 'point' && pointtype && pointtype == 2">
            <div class="clr-row">
              <div class="clr-col padding-20-lr">
                <strong>A</strong>&nbsp;&nbsp;<input type="checkbox" clrCheckbox name="a" formControlName="dw_a" />
              </div>
              <div class="clr-col">
                <strong>B</strong>&nbsp;&nbsp;<input type="checkbox" clrCheckbox name="b" formControlName="dw_b" />
              </div>
              <div class="clr-col">
                <strong>C</strong>&nbsp;&nbsp;<input type="checkbox" clrCheckbox name="c" formControlName="dw_c" />
              </div>
            </div>
            <div class="clr-row">
              <div class="clr-col ">
                <img class="vector mw-50p" src="assets/icons/weichen/DW1.svg" />
              </div>
              <div class="clr-col">
                <img class="vector mw-50p" src="assets/icons/weichen/DW2.svg" />
              </div>
            </div>
          </div>



          <div id="pointCrossWrapper" class="clr-row"
            *ngIf="(typ_Line_Point | objtype) == 'point' && pointtype && pointtype == 3">
            <div class="checkboxes clr-col">
              <div>
                <strong>A</strong>&nbsp;&nbsp;<input type="checkbox" clrCheckbox name="a" formControlName="kr_a" />
              </div>
              <div>
                <strong>B</strong>&nbsp;&nbsp;<input type="checkbox" clrCheckbox name="b" formControlName="kr_b" />
              </div>
            </div>
            <img src="assets/icons/weichen/KR.svg" class="clr-col vector">
            <div class="checkboxes right clr-col">
              <div>
                <input type="checkbox" clrCheckbox name="c" formControlName="kr_c" />&nbsp;<strong>C</strong>
              </div>
              <div>
                <input type="checkbox" clrCheckbox name="d" formControlName="kr_d" />&nbsp;<strong>D</strong>
              </div>
            </div>
          </div>


          <div id="sperrForm">
            <clr-checkbox-wrapper class="clr-row">
              <input type="checkbox" clrCheckbox name="gesperrt" formControlName="gesperrt" />
              <label>{{'gesperrt' | mrTranslate}}</label>
            </clr-checkbox-wrapper>
            <clr-textarea-container class="clr-row">
              <label class="clr-col-md-4">{{'Grund' | mrTranslate}}</label>
              <textarea class="clr-col-md-8" clrTextarea name="grund" formControlName="grund"></textarea>
            </clr-textarea-container>
            <div class="clr-row">
              <button class="btn btn-primary" [disabled]="trial" type="submit">
                {{ (mode == 'new' ? 'Sperrung anlegen' : 'Sperrung speichern') | mrTranslate }}</button>
            </div>
          </div>

        </div>
        <div id="history" class="clr-col-md-6 clr-col-12 nopaddingright">
          <h3>{{'Historie' | mrTranslate}}</h3>
          <clr-datagrid *ngIf="history && history['columns']" class="datagrid-compact">

            <clr-dg-column *ngFor="let column of history['columns']" [clrDgField]="column.id"
              [style.min-width.px]="column.id == 'status' ? 150 : column.id.length * 7.5 + 45">
              <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns}">{{
                camelcase(column.id) | mrTranslate }}
              </ng-container>
            </clr-dg-column>

            <clr-dg-row *clrDgItems="let row of history['rows']" [ngClass]="{'selectedrow': row.id == editId}">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="editRow(row)">{{'Bearbeiten' | mrTranslate}}</button>
              </clr-dg-action-overflow>
              <ng-container *ngFor="let cell of history['columns']">
                <ng-container *ngIf="(cell.id != 'richtung'); else elseTemplate">
                  <clr-dg-cell class="name-cell" [hidden]="cell.id | hideIDColumns" [innerHTML]="row[cell.id] | tablePrettyPrint"
                    [style.min-width.px]="cell.id.length * 7.5 + 45">
                  </clr-dg-cell>
                </ng-container>
                <ng-template #elseTemplate>
                  <clr-dg-cell style="color:#000"
                    [style.min-width.px]="cell.id.length * 7.5 + 45">
                    <clr-icon class="customIcon"
                      [attr.shape]="row['richtung'] && pointtype ? 'richtung'+row['richtung'] : 'blank'" size="28">
                    </clr-icon>
                  </clr-dg-cell>
                </ng-template>
              </ng-container>

            </clr-dg-row>

            <clr-dg-footer>{{1}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>
    </form>
  </div>
</div>