import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrForm } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { InTableValidatorDirective } from 'src/app/directives/validators/in-table-validator.directive';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { ObjTypePipe } from 'src/app/pipes/objtype.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { LinieDrehungService } from 'src/app/services/Shared/linie-drehung.service';
import { Base64Service } from 'src/app/services/base64/base64.service';
import { db } from '../../../../services/dexieDB';
import { PreviewThumbnailsComponent } from '../../_shared/preview-thumbnails/preview-thumbnails.component';
import { ArrayMethodsCallbackPipe } from 'src/app/pipes/array-methods-callback.pipe';
import { MediaViewModalComponent } from '../../_shared/mediaviewmodal/mediaviewmodal.component';

@Component({
  selector: 'app-stammdatendialog',
  templateUrl: './stammdatendialog.component.html',
  styleUrls: ['./stammdatendialog.component.scss'],
  imports: [CommonModule, ClarityModule, PreviewThumbnailsComponent, InTableValidatorDirective, MrTranslatePipe, BackendLocaleDatePipe,
    FormsModule, ReactiveFormsModule, ObjTypePipe,ArrayMethodsCallbackPipe,
    MediaViewModalComponent
  ],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})
export class StammdatenDialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private dataRefresh: EventEmitter<any>;

  @ViewChild('stammDatenForm', { read: ClrForm, static: false })
    private clrForm: ClrForm
  ;
  @ViewChild('stammDatenForm', { read: NgForm, static: false })
    private ngForm: NgForm
  ;
  @ViewChild(PreviewThumbnailsComponent, { static: false })
    private preview: PreviewThumbnailsComponent
  ;

  protected typ_Line_Point: number = 1;
  @Input() set settypeid(intypeid: number) {
    this.typ_Line_Point = intypeid;
  }

  private otypid: number;
  @Input() set setotypid(inotypid: number) {
    this.otypid = inotypid;
  }

  private opruefid: number;
  @Input() set setopruefid(inopruefid: number) {
    this.opruefid = inopruefid;
  }

  private ostammid: number;
  @Input() set setostammid(inostammid: number) {
    this.ostammid = inostammid;
    this.isInspektion = true;
  }

  public set data(inData: any) {
    this.ostammid = inData.id;
    this.typ_Line_Point = inData.type;
    this.otypid = inData.otypid;
    this.dataRefresh = inData.dataRefresh;

    if (this.ostammid == -1)
      this.titel =
        this.mrTranslate.transform('neue Stammdaten erstellen') +
        ' - ' +
        inData.bezeichnung;
  }

  protected stammdaten: any[];
  protected isInspektion: boolean = false;

  protected bemerkung: string;
  protected titel: string = 'Stamdaten bearbeiten';

  protected skizzenListe: string[];
  protected skizzeBase64: any;
  protected skizze64: any;
  private _selectedSkizze: string = '';
  protected get selectedSkizze(): string {
    return this._selectedSkizze;
  }
  protected set selectedSkizze(value: string) {
    this._selectedSkizze = value;
    this.skizzeBase64 = ''
    this.skizze64 = undefined;
    if (!value) return;
    this.apiService
      .getStammSkizze(value)
      .pipe(first())
      .subscribe(({success, error, skizzeBase64}: any) => {
        if (success) {
          this.skizze64 = skizzeBase64;
          this.skizzeBase64 = this.domSanitizer.bypassSecurityTrustUrl(
            'data:image/png;base64,' + skizzeBase64
          );
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }

  protected Opt_I_PassBild: boolean = false;
  private deletedFiles: string[] = [];


  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    private base64service: Base64Service,
    protected localeDate: BackendLocaleDatePipe,
    protected drehung: LinieDrehungService
  ) {}

  ngOnInit(): void {

    if(this.apiService.isRep) {
      db.getStammInsp(this.ostammid, this.typ_Line_Point).then(async ({success, stamm, bemerkung}) => {
        if (success) {
          this.stammdaten = stamm;
          this.bemerkung = bemerkung;
        } else this.toastr.error(this.mrTranslate.transform('Keine Stammdaten gefunden'), this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
    } else {
      this.apiService
      .getStammDaten(this.ostammid, this.otypid, this.typ_Line_Point, this.opruefid ?? -1)
      .pipe(first())
      .subscribe(({
        success, error, list: stamm, bemerkung,
        skizzenNames, selectedSkizze, skizzeBase64,
        passbilder, istVomHeute, opt_I_PassBild
      }: any) => {
        if (success) {
          this.stammdaten = stamm;
          this.bemerkung = bemerkung;
          this.skizzenListe = skizzenNames;
          this.Opt_I_PassBild = opt_I_PassBild;

          if (skizzeBase64) {
            this.skizze64 = skizzeBase64;
            this._selectedSkizze = selectedSkizze;
            this.skizzeBase64 = this.domSanitizer.bypassSecurityTrustUrl(
              'data:image/png;base64,' + skizzeBase64
            );
          }
          if (passbilder) setTimeout(() => {
            this.preview.unpackFiles(passbilder, istVomHeute || !this.isInspektion);
          });
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
    }
  }

  imageMedia: any;
  openSkizze(base64){
    this.imageMedia = [];
    let skizze = {
      name: 'skizze.png',
      base64: this.skizze64,
    }

    this.imageMedia.push(
      {
        title: this.mrTranslate.transform("Messpunktskizze"),
        fileNames:[skizze],
      }
    )
  }

  //* Ändert gespeicherte Skizze sofort nach Hochladen;
  //? Aber selectedSkizze speichert nur nach "Speichern" button Click
  protected async onBildChanged(file: File) {
    if (!file) return;

    const extension = file.name.split('.').pop();
    if (extension.toLocaleLowerCase() != 'png') {
      this.toastr.warning(
        this.mrTranslate.transform("Die Skizze soll im Format '.png' sein.")
      );
      return;
    }
    const confirmText =
      this.mrTranslate.transform(
        'Es ist bereits eine Skizze mit diesem Namen vorhanden'
      ) +
      this.mrTranslate.transform(
        'Möchten Sie die Skizze ersetzen?'
      );
    const bildname = file.name.slice(0, -4); /* ".png".length => 4 */
    const isExisting = this.skizzenListe.includes(bildname);
    if (!isExisting || confirm(confirmText)) {
      try {
        const src = await this.base64service.getUrlStringFromFile(file);
        this.apiService
          .setStammSkizze(this.ostammid, file.name, src.split(',').pop())
          .pipe(first())
          .subscribe(({success, error}: any) => {
            if (success) {
              this.skizze64 = "";
              this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
              this.skizzeBase64 = this.domSanitizer.bypassSecurityTrustUrl(src);
              if (!isExisting) this.skizzenListe.push(bildname);
              this._selectedSkizze = bildname;
              this.dataRefresh?.emit(true);
            } else
              this.toastr.error(
                this.mrTranslate.transform(error),
                this.mrTranslate.transform(
                  'Hochladen der Skizze fehlgeschlagen'
                )
              );
          });
      } catch (er) {
        this.toastr.error(
          this.mrTranslate.transform((<ErrorEvent>er).message),
          this.mrTranslate.transform('Hochladen der Skizze fehlgeschlagen')
        );
      }
    }
  }

  public saveIfValid() {
    return this.isFormValid && this.isPassbilderValid && this.saveStammDetails();
  }

  private get isFormValid(): boolean {
    this.clrForm.markAsTouched();
    return this.ngForm.valid;
  }

  private get isPassbilderValid(): boolean {
    const valid = (
      !this.isInspektion ||
      !this.Opt_I_PassBild ||
      !!this.preview.rawPreviewThumbnails.length
    );
    if (!valid) this.toastr.error(
      this.mrTranslate.transform('Mindestend ein Passbild'),
      this.mrTranslate.transform('Stammdaten')
    );
    return valid;
  }

  protected async saveStammDetails(): Promise<boolean> {
    const sendObj = {
      data: this.stammdaten,
      selectedSkizze: this.selectedSkizze,
      bemerkung: this.bemerkung,
      passbilder: this.isInspektion ? await this.preview.packUnsavedFiles() : null,
      savedFileNames: this.preview?.saved.map((file) => file.name).join("|") ?? "",
      delFile: this.deletedFiles,
      opruefid: this.opruefid
    }
    if(this.apiService.isRep) {
      var { success, error, ostammid, mediaStr } = await db.setStammDaten(this.ostammid, this.otypid, sendObj);
    }else  {
      var { success, error, ostammid, mediaStr } = await firstValueFrom(
        this.apiService.setStammDaten(this.ostammid, this.otypid, sendObj)
      );
    }
    if (success) {
      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert'),
        this.mrTranslate.transform('Stammdaten')
      );
      this.ostammid = ostammid;
      this.updatePreview(mediaStr);
      this.dataRefresh?.emit(true);
      return true;
    } else {
      console.log(error);
      this.toastr.error(
        this.mrTranslate.transform('Etwas ist schief gelaufen'),
        this.mrTranslate.transform('Stammdaten')
      );
      return false;
    }
  }

  private async updatePreview(mediaStr: string) {
    this.deletedFiles = [];
    if (!this.preview.unsaved.length) return;
    const files = await firstValueFrom(this.apiService.getPassBilder(mediaStr));
    this.preview.clearPreview();
    this.preview.unpackFiles(files);
  }

  public get linieValues() {
    const startmeter = this.stammdaten.find(
      (row) => row.DBField == 'StartMeter'
    )?.Bezeichnung;
    const endmeter = this.stammdaten.find(
      (row) => row.DBField == 'EndMeter'
    )?.Bezeichnung;
    return { startmeter, endmeter }
  }

  protected calcLaenge() {
    const { startmeter, endmeter } = this.linieValues;
    const laengeRow = this.stammdaten.find((row) => row.DBField == 'Laenge');
    const laenge = startmeter === null || endmeter === null
      ? null
      : Math.abs((endmeter * 10 - startmeter * 10) / 10)
    ;

    if (laengeRow) laengeRow.Bezeichnung = laenge;
  }

  protected deleteFile(name: string) {
    this.deletedFiles.push(name);
  }

  protected close() {
    this.closedialog.next(true);
  }
}
