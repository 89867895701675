const map = {
    'dw': [
        [true,false,false],
        [true,true,false],
        [true,false,true],
        [true,true,true],
        [false,true,false],
        [false,true,true],
        [false,false,true],
    ],
    'kr': [
        [true,false,false,false],
        [true,true,false,false],
        [true,true,true,false],
        [true,true,true,true],
        [false,true,false,false],
        [false,false,true,false],
        [false,false,false,true],
        [false,true,true,true],
        [false,false,true,true],
        [true,false,false,true],
        [true,false,true,false],
        [false,true,false,true],
        [false,false,true,true],
        [true,true,false,true],
        [true,false,true,true],
        [false,true,false,true]
    ]
}

export function getDirection(formvals:any[]){
    let vals = formvals.map(val => val ? true : false);
    let key = '';
    let offset = 0;
    if (formvals.length == 3){
        key = 'dw';
        offset = 11;
    } else if (formvals.length == 4){
        key = 'kr';
        offset = 21;
    } 
    if (key != ''){

        let jsonvals = JSON.stringify(vals);

        let ind = map[key].findIndex((arr) => {
            return JSON.stringify(arr) == jsonvals;
        });

        if (ind != -1){
            return offset+ind;
        }
        return 99;
    }
}

export function getFormVals(direction:number){
    if (direction < 10){
        return direction;
    } else if (direction > 10 && direction <= 20){
        return map['dw'][direction-11];
    } else if (direction > 20){
        return map['kr'][direction-21];
    }
}