import { Component, OnInit, Inject, ViewChild,ElementRef, AfterViewInit } from "@angular/core";
import { APIService } from "../../../../services/APIService/api.service";
import { first } from "rxjs/operators";
import {UntypedFormGroup,UntypedFormControl,Validators,FormBuilder,} from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import * as _ from "underscore";
import { AuthService } from "src/app/services/Auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { ClrDatagrid } from "@clr/angular";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { BrowserModule } from "@angular/platform-browser";
import { BasedatamodalComponent } from "../../../_components/_modals/basedatamodal/basedatamodal.component";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";

@Component({
    selector: "app-checklistendialog",
    templateUrl: "./checklistendialog.component.html",
    styleUrls: ["./checklistendialog.component.scss"],
    imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, HideIDColumnsPipe, TablePrettyPrintPipe, DotDotDotPipe],
    standalone: true
})
export class ChecklistenDialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public minSize;

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
    private overlayService: OverlayService
  ) {

  }
  @ViewChild('_checklistename') _checklistename: ElementRef;

  otypid: number = 0;
  stammobjekte: any;
  stammobjekteorginal: any;
  selchecklist: any;
  selchecklistid: number;
  checklistennamen: any;
  checklisten: any;
  checklistenall: any;
  groupObjects: any;

  selectedStammList: any[] = [];
  selectedPOList: any[] = [];
  cansortup: boolean = false;
  cansortdown: boolean = false;
  isChecklisteAddVisible: boolean = false;
  modaltitel: string = "";
  modaltyp: number = 0;
  selectedOption;

  addChecklist(){
    this.modaltyp = 1;
    this.modaltitel = 'Neue Checkliste erstellen';

    if (this._checklistename!= undefined)
      this._checklistename.nativeElement.value = '';

    this.isChecklisteAddVisible = true;
  }

  saveAsChecklist(){
    this.modaltyp = 2;
    this.modaltitel = 'Checkliste speichern unter...';

    if (this._checklistename!= undefined)
      this._checklistename.nativeElement.value = '';

    this.isChecklisteAddVisible = true;
  }

  editCheckliste(){
    if (this._checklistename.nativeElement.value != ''){

      if(this.checklistennamen.some((usr) => usr.Titel.toLowerCase() == this._checklistename.nativeElement.value.toLowerCase())){
        this.toastr.error(this.mrTranslate.transform("Checkliste wird bereits verwendet"));
        return;
      }

      if (this.modaltyp == 1){
        let newchecklist = {
          Titel: this._checklistename.nativeElement.value,
        }
        this.checklistennamen.push(newchecklist);

        setTimeout(() => {
          if (this.checklistennamen.length > 0){
            this.selchecklist = this.checklistennamen[this.checklistennamen.length-1];
            this.selectedOption = newchecklist.Titel;
            this.selectedNamechanged(this.selchecklist.Titel);
          }
        }, 50);

        this.isChecklisteAddVisible = false;
      }
      if (this.modaltyp == 2){

        let newchecklist = {
          Titel: this._checklistename.nativeElement.value,
        }

        let items = {
          status: 'copyto',
          OTYPID: this.otypid,
          name: this.selchecklist,
          newname: this._checklistename.nativeElement.value,
        }

        this.apiservice
          .getChecklistenItems(items)
          .pipe(first())
          .subscribe((val: any) => {
          if (val){
            this.stammobjekte = val.po;
            this.stammobjekteorginal = val.po.rows;
            this.checklistennamen = val.name.rows;
            this.groupObjects = val.liste;
            this.checklistenall =  val.liste.rows;

            setTimeout(() => {
                this.selchecklist = newchecklist;
                this.selectedOption = newchecklist.Titel;
                this.selectedNamechanged(newchecklist.Titel);
            }, 50);
          }

        });

      }

    }
  }
  deleteChecklist(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Checkliste löschen wollen?"))) {
      let items = {
        status: 'deleteChecklist',
        OTYPID: this.otypid,
        name: this.selchecklist,
      }

      this.apiservice
        .getChecklistenItems(items)
        .pipe(first())
        .subscribe((val: any) => {
        if (val){
          this.stammobjekte = val.po;
          this.stammobjekteorginal = val.po.rows;
          this.checklistennamen = val.name.rows;
          this.groupObjects = val.liste;
          this.checklistenall =  val.liste.rows;

          setTimeout(() => {
            if (this.checklistennamen.length > 0){
              this.selchecklist = this.checklistennamen[0];
              this.selectedOption = this.selchecklist.Titel;
              this.selectedNamechanged(this.selchecklist.Titel);
            }

          }, 50);
        }

      });
    }
  }

  executeToAdd(){

    let IDs = this.selectedStammList.map(item => {
      return item.ID;
    });

    let items = {
      status: 'addPoToChecklist',
      ids: IDs,
      OTYPID: this.otypid,
      name: this.selchecklist,
    }

    this.apiservice
      .getChecklistenItems(items)
      .pipe(first())
      .subscribe((val: any) => {
      if (val){
        this.stammobjekte = val.po;
        this.stammobjekteorginal = val.po.rows;
        this.groupObjects = val.liste;
        this.checklistenall =  val.liste.rows;

        setTimeout(() => {
            this.selectedNamechanged(this.selchecklist);
        }, 50);
      }

    });

  }

  executeToRemove(){
    let IDs = this.selectedPOList.map(item => {
      return item.ID;
    });

    let items = {
      status: 'deletePoFromChecklist',
      ids: IDs,
      OTYPID: this.otypid,
      name: this.selchecklist,
    }

    this.apiservice
      .getChecklistenItems(items)
      .pipe(first())
      .subscribe((val: any) => {
      if (val){
        this.stammobjekte = val.po;
        this.stammobjekteorginal = val.po.rows;
        this.groupObjects = val.liste;
        this.checklistenall =  val.liste.rows;

        setTimeout(() => {
            this.selectedNamechanged(this.selchecklist);
        }, 50);
      }

    });

  }

  selectionPOChanged($event){
    this.cansortup = false;
    this.cansortdown = false;

    if (this.selectedPOList.length==1){
      var index = this.groupObjects.rows.indexOf($event[0]);
      if (index > 0)
        this.cansortup = true;

      if (index < this.groupObjects.rows.length - 1)
        this.cansortdown = true;
    }

  }

  selectionStammChanged($event){

  }

  sortUp() {
    var index = this.groupObjects.rows.indexOf(this.selectedPOList[0]);
    this.groupObjects.rows = this.swapArrayTabellen(this.groupObjects.rows, index, index - 1);
    this.selectionPOChanged(this.selectedPOList[0]);
  }

  sortDown() {
    var index = this.groupObjects.rows.indexOf(this.selectedPOList[0]);
    this.groupObjects.rows = this.swapArrayTabellen(this.groupObjects.rows, index, index + 1);
    this.selectionPOChanged(this.selectedPOList[0]);
  }

  swapArrayTabellen(Array: any, Swap1: number, Swap2: number): any {
    let sort1 = Array[Swap1].SortID;
    let sort2 = Array[Swap2].SortID;
    let id1 = Array[Swap1].ID;
    let id2 = Array[Swap2].ID;

    Array[Swap2].SortID = sort1;
    Array[Swap1].SortID = sort2;

    var temp = Array[Swap1];
    Array[Swap1] = Array[Swap2]
    Array[Swap2] = temp

    let items = {
      status: 'resort',
      id1: id1,
      id2: id2,
      sort1: sort2,
      sort2: sort1,
    }

    this.apiservice
      .getChecklistenItems(items)
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      })

    return Array;
  }



  selectedNamechanged(value: any) {
    this.selchecklist = value;
    this.groupObjects.rows = this.checklistenall.filter((obj) => {return obj.Titel === value;});
    this.stammobjekte.rows = this.stammobjekteorginal.filter(stamm=> !this.groupObjects.rows.some(item=> item.Bezeichnung == stamm.Bezeichnung));
    this.selectedStammList = [];
    this.selectedPOList = [];
  }

  set data(dataIn: any) {
    if (dataIn && dataIn.id) {
      if (dataIn.id.otypid) {
        this.otypid = dataIn.id.otypid;

        let items = {
          status: 'get',
          OTYPID: this.otypid,
        }

        this.apiservice
          .getChecklistenItems(items)
          .pipe(first())
          .subscribe((val: any) => {
          if (val){
            this.stammobjekte = val.po;
            this.stammobjekteorginal = val.po.rows;
            this.checklistennamen = val.name.rows;
            this.groupObjects = val.liste;
            this.checklistenall =  val.liste.rows;

            setTimeout(() => {
              if (this.checklistennamen.length > 0){
                this.selchecklist = this.checklistennamen[0];
                this.selectedOption = this.selchecklist.Titel;
                this.selectedNamechanged(this.selchecklist.Titel);
              }

            }, 50);
          }

        });


      }
    }
  }

  ngOnInit() {
  }

  public close() {
    this.closedialog.next(true);
  }


}
