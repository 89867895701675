import { Component, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { SpinnerService } from 'src/app/services/Spinner/spinner.service';
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';


@Component({
    selector: 'app-viewauftragdialog',
    templateUrl: './viewauftragdialog.component.html',
    styleUrls: ['./viewauftragdialog.component.scss'],
    imports: [CommonModule, ClarityModule, MrTranslatePipe],
    standalone: true
})

export class ViewauftragdialogComponent implements AfterViewInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public filename: string = 'Auftrag';
  titel: string = 'Auftrag anzeigen';
  blobdata: any;

  set data(dataIn: any) {
    this.titel = this.mrTranslate.transform('Auftrag anzeigen');
    this.filename = this.mrTranslate.transform('Auftrag');

    if (dataIn.auftragsnr) {
      this.filename = this.mrTranslate.transform('Auftrag') + ' ' + dataIn.auftragsnr;
    }
    this.blobdata = dataIn.data;

    if (dataIn.data.titel) {
      this.titel = dataIn.data.titel;
      this.filename = dataIn.data.file;
      this.blobdata = dataIn.data.data;
    }
    if (dataIn.titel)
      this.titel = dataIn.titel;

    let url = this.domsanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.blobdata));
    this.pdf_url = url;
    this.spinner.disable();
  };

  pdf_url: SafeUrl;
  constructor(
    private domsanitizer: DomSanitizer,
    private spinner: SpinnerService,
    private mrTranslate: MrTranslatePipe
  ) {
  }

  public close() {
    this.closedialog.next(true);
  }

  ngAfterViewInit() { }

}
