<div class="clr-row">
    <div class="clr-col">
        <clr-button-group class="btn-primary btn-sm" style="margin-top: 0.5em;">
            <clr-button (click)="doAction('add')" [disabled]="!selected">{{"Hinzufügen" | mrTranslate}}</clr-button>
            <clr-button (click)="doAction(titleSelected() ? 'editLV' : 'edit')" [disabled]=" !selected">
                {{"Bearbeiten" | mrTranslate}}</clr-button>
            <clr-button (click)="doAction('delete')"
                [disabled]="trial || !selected || !!selected.children?.length || titleSelected()">{{"Löschen" |
                mrTranslate}}</clr-button>
        </clr-button-group>
    </div>
    <div class="clr-col-3 text-right">
        <clr-button-group class="btn-primary btn-sm" style="margin-top: 0.5em; margin-right: 0px;">
            <clr-button (click)="doAction('addLV')"><clr-icon shape="add"></clr-icon> {{"Leistungsverzeichnis" | mrTranslate}}</clr-button>
        </clr-button-group>
    </div>
</div>
<clr-tree id="fremdleistungTree" class="datagrid datagrid-body">
    <div class="tree-header">
        <span class="treeSpan">{{"Nr"| mrTranslate}}</span>
        <span class="treeSpan">{{"Kurztext"| mrTranslate}}</span>
        <span class="treeSpan">{{"Einheit"| mrTranslate}}</span>
        <span class="treeSpan">{{"EP"| mrTranslate}}</span>
    </div>
    <clr-tree-node *clrRecursiveFor="let leistung of root; getChildren: getChildren"
        [(clrExpanded)]="leistung.expanded">
        <div class="tree-item" (click)="select(leistung)" [class.active]="leistung === selected"
            [class.parent]="!!leistung.children" [title]="leistung.Kurztext">
            <span class="treeSpan">{{leistung.OZ}}</span>
            <span class="treeSpan" [innerHTML]="leistung.Kurztext"></span>
            <span class="treeSpan" [innerHTML]="leistung.ME ?? ''"></span>
            <span class="treeSpan" [innerHTML]="leistung.EP ? (leistung.EP | currency:'EUR') : ''"></span>
        </div>
    </clr-tree-node>
</clr-tree>
