import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PreviewThumbnail } from 'src/app/content/_components/_shared/preview-thumbnails/preview-thumbnail.type';

export type FileObject = {
  base64: string;
  /**
   * @description Kann im Back-End für Namen-Erstellung benutzt werden
   * @pattern DD.MM.YYYY HH.mm.ss.SSS
   */
  addedDate: string;
  name: string;
  lastModified: number;
  size: number;
  /**
   * @description Kann im Back-End für die Validierung benutzt werden
   * @pattern `{type}/{extension}`
   * @example "image/png", "video/mp4", "application/pdf"
   */
  type: string;
  extension: string;
};

@Injectable({
  providedIn: 'root'
})
export class Base64Service {
  constructor(private toastr: ToastrService){}

  public async getUrlStringFromFile(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(<string>reader.result);
      reader.onerror = (_) => reject(reader.error.message);
    });
  }

  public async packFileObject({ file, date, base64, extension }: PreviewThumbnail) {
    return new Promise<FileObject>(async (resolve, reject) => {
      try {
        const f = <File>file;
        // get base64: für Bilder gibt es schon seit Preview; für andere erstellen
        const fileObject: FileObject = {
          'base64'       : base64 || (await this.getUrlStringFromFile(f)).split(',').pop(),
          'addedDate'    : date,
          'name'         : f?.name,
          'lastModified' : f?.lastModified,
          'size'         : f?.size,
          'type'         : f?.type,
          'extension'    : extension
        };
        resolve(fileObject);
      } catch (er) {
        reject({ filename: file?.name, error: (<ErrorEvent>er).message });
      }
    })
  }

  public async packFiles(previewArray: PreviewThumbnail[]) {
    let filesArray: FileObject[] = [];
    const promisesArray = previewArray.map(
      thumbnail => this.packFileObject(thumbnail)
    );

    // * Warten, bis es für alle Files ein Objekt erstellt wurde (oder Error).  
    (await Promise.allSettled(promisesArray)).forEach(({status, value, reason}: any) => {
      if (status == "fulfilled") 
        filesArray.push(value);
      else 
        this.toastr.error(reason.filename + "has error: " + reason.error);
    });

    return filesArray;
  }

  public convertFileAsBlob(b64Data: string, contentType = "", sliceSize?: number, filename?: string) {
    sliceSize = sliceSize || 512;

    let byteCharacters = window.atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new File(byteArrays, filename || "pot", { type: contentType });
  }
}
