<div class="overlay-inner-wrapper">
  <!-- <div id="rueckmeldungsWrapper"> -->

    <header class="header-2">
      <div class="branding">
        <span class="title">{{"Rückmeldung" | mrTranslate}}</span>
      </div>
      <div class="header-actions">

        <clr-dropdown>
          <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
            <clr-icon shape="cog"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">

            <a (click)="createMangel($event)" clrDropdownItem><clr-icon
              [style.color]="!isDarkThemeAktiv ? '#1D3A53' : '#AEB8BC'" size="22"
              shape="plus-circle"></clr-icon> {{ "Mangel anlegen" | mrTranslate | titlecase }}</a>

            <ng-container *ngIf="candoteilinst">
              <a (click)="showTeilinstandsetzung($event)" clrDropdownItem><clr-icon
                [style.color]="!isDarkThemeAktiv ? '#1D3A53' : '#AEB8BC'" size="22"
                shape="share"></clr-icon> {{ "Teilinstandsetzung" | mrTranslate | titlecase }}</a>
            </ng-container>

            <ng-container *ngIf="typ_Line_Point == 2 || typ_Line_Point == 4">
              <div class="dropdown-divider" role="separator"></div>
              <a (click)="showErneuerung($event)" clrDropdownItem><clr-icon
                [style.color]="!isDarkThemeAktiv ? '#1D3A53' : '#AEB8BC'" size="22"
                shape="repeat"></clr-icon> {{ "Erneuerung" | mrTranslate | titlecase }}</a>
            </ng-container>

            <!-- <div *ngIf="UseKomponenten === true" class="dropdown-divider" role="separator"></div>
            <a *ngIf="UseKomponenten === true" (click)="addKomponenten()" clrDropdownItem>{{'Neue Komponente erstellen' | mrTranslate}}</a>
            <a *ngIf="selnodeid !== 0" (click)="editKomponenten()" clrDropdownItem>{{'Komponente bearbeiten' | mrTranslate}}</a>
            <a *ngIf="selnodeid !== 0" (click)="delKomponenten()" clrDropdownItem>{{'Komponente löschen' | mrTranslate}}</a> -->
          </clr-dropdown-menu>


        </clr-dropdown>


        <a href="javascript:void(0);" class="nav-link nav-icon">
          <clr-icon shape="times" (click)="close()"></clr-icon>
        </a>
      </div>
    </header>

    <ng-container *ngIf="this.openTab != 'paint'">
      <form clrForm [formGroup]="formGroup" [clrLabelSize]="4" class="width100">
        <div class="clr-row nxm" #heightRef>
          <div class="clr-col-sm" style="margin-left: 10px;">
            <clr-date-container>
              <!--  style="margin-top: 0.6em" -->
              <label>{{ "Datum" | mrTranslate }}</label>
              <input type="date" clrDate name="datum"
                [ngModel]="formGroup.get('instandsetzungsdatum').value | localeDate"
                (ngModelChange)="formGroup.get('instandsetzungsdatum').setValue(localeDate.tosql($event))"
                [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                [disabled]="formGroup.disabled" required/>
            </clr-date-container>

            <clr-select-container>
              <label>{{ "Firma" | mrTranslate }}</label>
              <select clrSelect formControlName="firma">
                <option *ngFor="let c of firmen" [value]="c.ID">{{c.mFirma}}</option>
              </select>
            </clr-select-container>
            <clr-textarea-container *ngIf="this.openTab == 'mangel'">
              <label>{{ "Rückmeldetext" | mrTranslate }}</label>
              <textarea rows="2" clrTextarea formControlName="rueckmeldetext" name="description"></textarea>
            </clr-textarea-container>
          </div>
          <div class="clr-col-sm-1"></div>
          <div class="clr-col-sm">
            <clr-textarea-container *ngIf="selectedmangel.length == 1 && this.openTab == 'mangel'">
              <label>{{ "Teilerneuerung" | mrTranslate }}</label>
              <textarea rows="2" clrTextarea formControlName="teilerneuerung" name="description"></textarea>
            </clr-textarea-container>
            <clr-textarea-container *ngIf="this.openTab == 'mangel'">
              <label>{{ "Bemerkung" | mrTranslate }}</label>
              <textarea rows="2" clrTextarea formControlName="bemerkung" name="description"></textarea>
            </clr-textarea-container>
          </div>
        </div>
  <!-- this.openTab == 'mangel'-->
      </form>
    </ng-container>

    <div class="clr-row nxm" style="padding-left: 10px; padding-right: 10px;">
      <clr-tabs class="w-100-p">
        <clr-tab *ngIf="mangel?.rows.length || mangelErl?.rows.length">
          <button clrTabLink (click)="tabChange('mangel')" id="link1">
            {{ "Mängel" | mrTranslate }}
          </button>
          <clr-tab-content id="content1" *clrIfActive="openTab == 'mangel'">
            <form id="rueckmeldungsMangelForm">
              <div class="clr-row nxm">
                <div class="clr-col-12">
                  <clr-datagrid #mangelDg [(clrDgSelected)]="selectedmangel" class="datagrid-compact phSmall" [clrDgRowSelection]="true" (clrDgSelectedChange)="selectedmangelChanged($event)">
                    <!-- <clr-dg-column style="min-width: 10rem">Datum</clr-dg-column>
                    <clr-dg-column style="min-width: 13rem">Instandsetzer</clr-dg-column> -->
                    <clr-dg-column *ngFor="let column of mangel?.columns ?? []" [clrDgField]="column.id"
                      [hidden]="column.id | hideIDColumns">{{ column.id | titlecase | mrTranslate }}</clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of mangel?.rows ?? []" [clrDgItem]="row">
                      <!-- <clr-dg-cell class="name-cell" style="min-width: 10rem">
                        <clr-date-container>
                          <input type="date" clrDate
                            [ngModel]="row.termin | localeDate"
                            (ngModelChange)="row.termin = localeDate.tosql($event)"
                            [ngModelOptions]="{standalone: true, updateOn: 'blur'}"/>
                        </clr-date-container>
                      </clr-dg-cell>
                      <clr-dg-cell class="name-cell" style="min-width: 13rem">
                        <select name="auftragnehmer" [(ngModel)]="row.auftragnehmer" [ngModelOptions]="{ standalone: true }" style="background-color: inherit; color: currentColor;">
                          <option *ngFor="let firm of firmen" [value]="firm.mFirma">
                            {{ firm.mFirma }}
                          </option>
                        </select>
                      </clr-dg-cell> -->
                      <clr-dg-cell *ngFor="let cell of mangel['columns']" [innerHTML]="row[cell.id] | tablePrettyPrint"
                        [hidden]="cell.id | hideIDColumns" class="name-cell"></clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" style="right: 60px" #pagination [clrDgPageSize]="5">
                        <clr-dg-page-size [clrPageSizeOptions]="[5, 10, 20]"></clr-dg-page-size>
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
                <div class="clr-col-12 margin-10-t" *ngIf="mangel?.rows.length && selectedmangel.length == 1">
                  <app-preview-thumbnails [maxAmount]="6"></app-preview-thumbnails>
                </div>

                <div class="clr-col-12" style="padding-top: 10px">
                  <button class="btn btn-primary" [disabled]="trial || !selectedmangel.length" (click)="onMangelSubmit()">
                    {{
                    selectedmangel.length +
                    " " +
                    (selectedmangel.length == 1
                    ? "Mangel"
                    : "Mängel") | mrTranslate
                    }}
                    {{ "rückmelden" | mrTranslate }}
                  </button>
                </div>
                <div class="clr-col-12" *ngIf="mangelErl?.rows.length">
                  <clr-datagrid #mangelErlDg [(clrDgSingleSelected)]="selectedInstMangel" [clrDgRowSelection]="true">
                    <clr-dg-column *ngFor="let column of mangelErl['columns']" [clrDgField]="column.id"
                      [hidden]="column.id | hideIDColumns">{{ column.id | titlecase | mrTranslate }}</clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of mangelErl['rows']" [clrDgItem]="row">
                      <clr-dg-cell *ngFor="let cell of mangelErl['columns']"
                        [innerHTML]="row[cell.id] | tablePrettyPrint" [hidden]="cell.id | hideIDColumns"
                        class="name-cell"></clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" style="right: 60px" #pagination [clrDgPageSize]="5">
                        <clr-dg-page-size [clrPageSizeOptions]="[5, 10, 20]"></clr-dg-page-size>
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>

                <div class="clr-col-12" style="padding-top: 10px" *ngIf="mangelErl?.rows.length">
                  <div class="btn-group btn-primary btn-icon">
                    <button class="btn btn-icon btn btn-danger" [title]="'Rückmeldung löschen' | mrTranslate" [disabled]="trial || !selectedInstMangel"
                      (click)="onMangelDelRueckmeldung()">
                      <clr-icon shape="times"></clr-icon>
                    </button>
                  </div>
                </div>

              </div>
            </form>
          </clr-tab-content>
        </clr-tab>
        <clr-tab *ngIf="messungen?.rows.length || messungenErl?.rows.length">
          <button clrTabLink (click)="tabChange('messung')" id="link2">
            {{ "Messungen" | mrTranslate }}
          </button>
          <clr-tab-content *clrIfActive="openTab == 'messung'">
            <form id="rueckmeldungsMessungForm" clrForm>
              <div class="clr-row nxm">
                <div class="clr-col-12">
                  <clr-datagrid #messungDg [(clrDgSelected)]="selectedmessung" class="datagrid-compact phSmall">
                    <clr-dg-column *ngFor="let column of messungen?.columns ?? []" [clrDgField]="column.id" [hidden]="
                          column.id
                            | hideIDColumns
                              : [
                                  'iausfall',
                                  'instand',
                                  'status',
                                  'username',
                                  'bemerkung',
                                  'ex_infralife',
                                  'mposition',
                                  'ztyp',
                                  'info',
                                  'messender',
                                  'instandsetzer',
                                  'instand_datum',
                                  'ist_neu',
                                ]
                        ">{{ column.id | titlecase }}</clr-dg-column>

                        <clr-dg-column>{{ "Ist-Neu" | mrTranslate }}</clr-dg-column>
                        <clr-dg-column>{{ "Ausfall-Neu" | mrTranslate }}</clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of messungen?.rows ?? []" [clrDgItem]="row">
                      <clr-dg-cell *ngFor="let cell of messungen['columns']"
                        [innerHTML]="row[cell.id] | tablePrettyPrint" [hidden]="
                            cell.id
                              | hideIDColumns
                                : [
                                    'iausfall',
                                    'instand',
                                    'status',
                                    'username',
                                    'bemerkung',
                                    'ex_infralife',
                                    'mposition',
                                    'ztyp',
                                    'info',
                                    'messender',
                                    'instandsetzer',
                                    'instand_datum',
                                    'ist_neu',
                                  ]
                          ">
                      </clr-dg-cell>
                      <clr-dg-cell>
                        <input clrInput style="margin-left: -60px; margin-top: -10px;" type="text" [(ngModel)]="row.IstNeu" [ngModelOptions]="{ standalone: true }" (blur)="onBlurMethod(row, $event.target.value)"/>
                      </clr-dg-cell>
                      <clr-dg-cell>
                        {{ row.IAusfall }}
                      </clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" style="right: 60px" #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[5, 10, 20, 50, 100]"></clr-dg-page-size>
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
                <div class="clr-col-12" style="padding-top: 10px">
                  <button class="btn btn-primary" [disabled]="trial || !selectedmessung.length" (click)="onMessungSubmit()">
                    {{
                    selectedmessung.length +
                    " " +
                    (selectedmessung.length == 1
                    ? "Messung"
                    : ("Messungen" | mrTranslate))
                    }}
                    {{ "rückmelden" | mrTranslate }}
                  </button>
                </div>

                <div class="clr-col-12" *ngIf="messungenErl?.rows.length">
                  <clr-datagrid #messungenErlDg class="datagrid-compact" [(clrDgSingleSelected)]="selectedInstMessung" [clrDgRowSelection]="true">
                    <clr-dg-column *ngFor="let column of messungenErl['columns']" [clrDgField]="column.id"
                      [hidden]="column.id | hideIDColumns">{{ column.id | titlecase | mrTranslate }}</clr-dg-column>
                    <clr-dg-row *clrDgItems="let row of messungenErl['rows']" [clrDgItem]="row">
                      <clr-dg-cell *ngFor="let cell of messungenErl['columns']"
                        [innerHTML]="row[cell.id] | tablePrettyPrint" [hidden]="cell.id | hideIDColumns"
                        class="name-cell"></clr-dg-cell>
                    </clr-dg-row>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" style="right: 60px" #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[5, 10, 20,50,100]"></clr-dg-page-size>
                        {{ pagination.firstItem + 1 }} -
                        {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                        {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                      </clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>

                <div class="clr-col-12" style="padding-top: 10px" *ngIf="messungenErl?.rows.length">
                  <div class="btn-group btn-primary btn-icon">
                    <button class="btn btn-icon btn btn-danger" [title]="'Rückmeldung löschen' | mrTranslate" [disabled]="trial || !selectedInstMessung"
                      (click)="onMessungDelRueckmeldung()">
                      <clr-icon shape="times"></clr-icon>
                    </button>
                  </div>
                </div>

              </div>
            </form>
          </clr-tab-content>
        </clr-tab>

        <ng-container *ngIf="(typ_Line_Point | objtype) == 'point' && Opt_F_Drawing == 1 && isload == true">
          <clr-tab class="w-100-p">
            <button clrTabLink (click)="tabChange('paint')"
              [title]="'Mangelskizzen' | mrTranslate">{{'Mangelskizzen' | mrTranslate}}</button>
            <clr-tab-content>
              <app-painting [setostammid]="ostammid" [setinittyp]="3"></app-painting>
            </clr-tab-content>
          </clr-tab>
        </ng-container>


      </clr-tabs>
    </div>
  <!-- </div> -->
</div>


<clr-modal [(clrModalOpen)]="ismodteilinst" [clrModalClosable]="true" [clrModalSize]="modalsize">
  <h3 class="modal-title">{{'Teilinstandsetzung' | mrTranslate}}</h3>
  <div class="modal-body">
    <div class="clr-row">
      <ng-container *ngIf="typ_Line_Point == 2 || typ_Line_Point == 4; else templatepunkt">
        <div class="clr-col-3">
          <clr-input-container>
            <label>{{'von' | mrTranslate}}</label>
            <input clrInput type="number" #_modalvon />
          </clr-input-container>
        </div>

        <div class="clr-col-3">
          <clr-input-container>
            <label>{{'bis' | mrTranslate}}</label>
            <input clrInput type="number" #_modalbis />
          </clr-input-container>
        </div>

        <div class="clr-col-3">
          <clr-input-container>
            <label>{{'Umfang' | mrTranslate}}</label>
            <input clrInput type="number" #_modalumfang [disabled]="!caneditumfang" />
          </clr-input-container>
        </div>

        <div class="clr-col-3">
          <clr-input-container>
            <label>{{'Mengeneinheit' | mrTranslate}}</label>
            <input clrInput #_modaleinheit [disabled]="true"/>
          </clr-input-container>
        </div>
      </ng-container>

      <ng-template #templatepunkt>
        <div class="clr-col-6">
          <clr-input-container>
            <label>{{'Umfang' | mrTranslate}}</label>
            <input clrInput type="number" #_modalumfang [disabled]="!caneditumfang" />
          </clr-input-container>
        </div>

        <div class="clr-col-6">
          <clr-input-container>
            <label>{{'Mengeneinheit' | mrTranslate}}</label>
            <input clrInput #_modaleinheit [disabled]="true"/>
          </clr-input-container>
        </div>
      </ng-template>



    </div>

    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="createTeilinstandsetzung()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="ismodteilinst = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>



<clr-modal [(clrModalOpen)]="ismoderneuerung" [clrModalClosable]="true" [clrModalSize]="'xl'">
  <h3 class="modal-title">{{'Erneuerung' | mrTranslate}}</h3>
  <div class="modal-body">
    <div class="clr-row" style="margin-top: -25px;">
      <div class="clr-col-4">
          <clr-input-container>
            <label>{{'von' | mrTranslate}}</label>
            <input clrInput type="number" #_neumodalvon />
          </clr-input-container>
        </div>
        <div class="clr-col-4">
          <clr-input-container>
            <label>{{'bis' | mrTranslate}}</label>
            <input clrInput type="number" #_neumodalbis />
          </clr-input-container>
        </div>
        <div class="clr-col-4">

        </div>
    </div>

    <div class="clr-row">
      <div class="clr-col-5">
        <h4>{{'Spezifikation' | mrTranslate}}</h4>

        <form #spezFormPoint clrForm ngForm class="width100 margin-12-t" clrLabelSize="5">
          <table class="table margin-0-t table-compact nowrap">
            <thead style="position: sticky; top: 0; z-index: 3;">
              <tr>
                <th class="left">{{'Objekt' | mrTranslate}}</th>
                <th class="left" style="width: 60%;">{{'Bezeichnung' | mrTranslate}}</th>
                <th>{{'Pflicht' | mrTranslate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sp of spez">
                <td class="left">
                  {{ sp.objekt | mrTranslate }}
                </td>
                <td [ngSwitch]="sp.fieldType">
                  <ng-container *ngSwitchCase="'str'">
                    <ng-container *ngIf="sp.sField; else textinput">
                      <clr-select-container>
                        <select class="clr-col-12" clrSelect [name]="sp.objekt" [(ngModel)]="sp.bezeichnung"
                          [required]="!!sp.pflichtFeld" [inTableValidator]="sp.wListe" #sel (change)="changed(sp.objekt, sel.value)">
                          <option *ngIf="!sp.pflichtFeld" value=""></option>
                          <option *ngIf="sp.bezeichnung && !sp.wListe.includes(sp.bezeichnung)"
                            [value]="sp.bezeichnung" class="invalidRed">{{ sp.bezeichnung }}</option>
                          <option *ngFor="let wert of sp.wListe" [value]="wert">{{ wert }}</option>
                        </select>
                        <clr-control-error *clrIfError="'not-in-Table'; error as err">
                          {{err | mrTranslate}}
                        </clr-control-error>
                        <clr-control-error *clrIfError="'required'">
                          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                        </clr-control-error>
                      </clr-select-container>
                    </ng-container>
                  </ng-container>

                  <!-- * FREI Texteingabefeld mit den bisherigen Werten -->
                  <ng-template #textinput>
                    <clr-datalist-container>
                      <input class="clr-col-12" clrDatalistInput [name]="sp.objekt"
                        [(ngModel)]="sp.bezeichnung" [required]="!!sp.pflichtFeld" />
                      <clr-control-error *clrIfError="'required'">
                        {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                      </clr-control-error>
                    </clr-datalist-container>
                  </ng-template>

                  <!-- Zahleneingabefeld number -->
                  <ng-container *ngSwitchCase="'dbl'">
                    <ng-container *ngTemplateOutlet='number; context: {float: true}'></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'int'">
                    <ng-container *ngTemplateOutlet='number; context: {float: false}'></ng-container>
                  </ng-container>
                  <ng-template #number let-float="float">
                    <clr-input-container>
                      <input class="clr-col-12" clrInput [name]="sp.objekt" [(ngModel)]="sp.bezeichnung" type="number"
                        [step]="float ? 0.1 : 1" [required]="!!sp.pflichtFeld" />
                      <clr-control-error *clrIfError="'required'">
                        {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                      </clr-control-error>
                    </clr-input-container>
                  </ng-template>
                  <!-- Ja/Nein Einegabe -->
                  <ng-container *ngSwitchCase="'chk'">
                    <clr-toggle-container>
                      <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle [name]="sp.objekt" [(ngModel)]="spez.bezeichnung"/>
                      </clr-toggle-wrapper>
                    </clr-toggle-container>
                  </ng-container>
                  <!-- Datum -->
                  <ng-container *ngSwitchCase="'dat'">
                    <clr-date-container>
                      <input clrDate [name]="sp.objekt" autocomplete="off" type="date"
                        [required]="!!sp.pflichtFeld" [ngModel]="spe.bezeichnung | localeDate"
                        (ngModelChange)="sp.bezeichnung = localeDate.tosql($event)"
                        [ngModelOptions]="{updateOn: 'blur'}"/>
                      <clr-control-error *clrIfError="'required'">
                        {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                      </clr-control-error>
                    </clr-date-container>
                  </ng-container>
                </td>
                <td>
                  <clr-icon *ngIf="sp.pflichtFeld" shape="success-standard" class="is-solid"
                    style="fill: #2EC0FF;"></clr-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>

      <div class="clr-col-7">
        <h4>{{'Herstelldaten' | mrTranslate}}</h4>
        <br>
        <form [formGroup]="herstellDatenForm" #f="ngForm"
        clrForm clrLayout="horizontal" class="width100 padding-24-r" clrLabelSize="6">


          <clr-select-container>
            <label>{{ "Einbaufirma" | mrTranslate }}</label>
            <select clrSelect formControlName="Einbaufirma">
              <option *ngFor="let c of firmen" [value]="c.mFirma">{{c.mFirma}}</option>
            </select>
          </clr-select-container>


<!--
        <clr-input-container class="mt-0">
          <label>{{ "Hersteller" | mrTranslate }}</label>
          <input clrInput formControlName="Hersteller" name="Hersteller" [autofocus]="ismoderneuerung" type="text" />
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
        </clr-input-container>
        <clr-date-container>
          <label> {{ "Herstelldatum" | mrTranslate }} </label>
          <input clrDate name="Herstelldatum" autocomplete="off" type="date"
            [ngModel]="herstellDatenForm.controls['Herstelldatum'].value | localeDate"
            (ngModelChange)="herstellDatenForm.controls['Herstelldatum'].setValue(localeDate.tosql($event))"
            [ngModelOptions]="{updateOn: 'blur', standalone: true}"
            required />
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
        </clr-date-container>
        <clr-input-container>
          <label>{{ "Einbaufirma" | mrTranslate }}</label>
          <input clrInput formControlName="Einbaufirma" name="Einbaufirma" type="text" />
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
        </clr-input-container>

         -->
        <clr-date-container>
          <label> {{ "Einbaudatum" | mrTranslate }} </label>
          <input clrDate name="Einbaudatum" autocomplete="off" type="date"
            [ngModel]="herstellDatenForm.controls['Einbaudatum'].value | localeDate"
            (ngModelChange)="herstellDatenForm.controls['Einbaudatum'].setValue(localeDate.tosql($event))"
            [ngModelOptions]="{updateOn: 'blur', standalone: true}"
            required />
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
        </clr-date-container>
        <clr-input-container>
          <label>{{ "Erneuerungsjahr" | mrTranslate }}</label>
          <input clrInput formControlName="Erneuerungsjahr" name="Erneuerungsjahr"
            type="number" [step]="1" min="1800"/>
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
          <clr-control-error *clrIfError="'min'; error as err">
            {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
          </clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label>{{ "Nutzungsdauer" | mrTranslate }}</label>
          <input clrInput formControlName="Nutzungsdauer" name="Nutzungsdauer"
            type="number" [step]="1" min="1"/>
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
          <clr-control-error *clrIfError="'min'; error as err">
            {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
          </clr-control-error>
        </clr-input-container>
        <clr-input-container>
          <label>{{ "Dauer der Gewährleistung" | mrTranslate }}</label>
          <input clrInput formControlName="DauerGewaehrleistung" name="DauerGewaehrleistung"
            type="number" [step]="1" min="0"/>
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
          <clr-control-error *clrIfError="'min'; error as err">
            {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
          </clr-control-error>
        </clr-input-container>
        <clr-select-container>
          <label > {{ "Einheit der Gewährleistung" | mrTranslate }} </label>
          <select clrSelect formControlName="EinheitGewaehrleistung" name="EinheitGewaehrleistung">
            <option value="Tag">{{ "Tag" | mrTranslate }}</option>
            <option value="Monat">{{ "Monat" | mrTranslate }}</option>
            <option value="Jahr">{{ "Jahr" | mrTranslate }}</option>
          </select>
        </clr-select-container>
        <clr-date-container>
          <label>{{ "Ende der Gewährleistung" | mrTranslate }}</label>
          <input clrDate name="EndeGewaehrleistung" autocomplete="off" type="date"
            [ngModel]="herstellDatenForm.controls['EndeGewaehrleistung'].value | localeDate"
            (ngModelChange)="herstellDatenForm.controls['EndeGewaehrleistung'].setValue(localeDate.tosql($event))"
            [ngModelOptions]="{updateOn: 'blur', standalone: true}"
            required />
          <clr-control-error *clrIfError="'required'">
            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
          </clr-control-error>
        </clr-date-container>
      </form>
      </div>

    </div>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="creatErneuerung()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="ismoderneuerung = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
