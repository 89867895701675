
import OlXYZ from 'ol/source/XYZ';
import OlTileWMS from 'ol/source/TileWMS';
import OlVectorTileSource from 'ol/source/VectorTile';
import OlBingMaps from 'ol/source/BingMaps';
import OlOSM from 'ol/source/OSM';

import OlVectorTileLayer from 'ol/layer/VectorTile';
import OlTileLayer from 'ol/layer/Tile';

import OlMVT from 'ol/format/MVT';

import OlStyle from 'ol/style/Style';
import OlStroke from 'ol/style/Stroke';
import OlFill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';

const bingkey = "$$apikey$$";

const mapboxkey = 'pk.eyJ1IjoiZHdpZWRhdmUiLCJhIjoiY2psbTJmbmF3MTMyejNxbngxOGxnYThvdSJ9.PHcI3LwNfv-9oeIO0r94lg';
const retina = window.devicePixelRatio > 1 ? (window.devicePixelRatio > 2 ? 3 : 2) : 1;

let TileLayerObj = {
    google: {
        googleMaps: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© Google',
                url: 'https://mt{0-3}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=de',
                crossOrigin: 'anonymous'
            })
        }),
        googleHybrid: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© Google',
                url: 'https://mt{0-3}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&hl=de',
                crossOrigin: 'anonymous'
            })
        }),
        googleTerrain: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© Google',
                url: 'https://mt{0-3}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}&hl=de',
                crossOrigin: 'anonymous'
            })
        }),
        googleSatellite: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© Google',
                url: 'https://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=de',
                crossOrigin: 'anonymous'
            })
        })
    },
    bing: {
        bingRoad: {
            preload: Infinity,
            source: {
                key: bingkey,
                imagerySet: 'Road',
                hidpi: retina > 1,
                culture: 'de-de',
                maxZoom: 19
            }
        },
        bingRoadOnDemand: {
            preload: Infinity,
            source: {
                key: bingkey,
                imagerySet: 'RoadOnDemand',
                hidpi: retina > 1,
                culture: 'de-de',
                maxZoom: 19
            }
        },
        bingAerial: {
            preload: Infinity,
            source: {
                key: bingkey,
                imagerySet: 'Aerial',
                hidpi: retina > 1,
                culture: 'de-de',
                maxZoom: 19
            }
        },
        bingAerialWithLabels: {
            preload: Infinity,
            source: {
                key: bingkey,
                imagerySet: 'AerialWithLabels',
                hidpi: retina > 1,
                culture: 'de-de',
                maxZoom: 19
            }
        }
    },
    mapbox: {
        mapboxDark: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=$$apikey$$',
                tilePixelRatio: retina,
                crossOrigin: 'anonymous'
            })
        }),
        mapboxLight: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                url: 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=$$apikey$$',
                tilePixelRatio: retina,
                crossOrigin: 'anonymous'
            })
        }),
        mapboxStreet: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=$$apikey$$',
                tilePixelRatio: retina,
                crossOrigin: 'anonymous'
            })
        }),
        mapboxSatellite: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=$$apikey$$',
                tilePixelRatio: retina,
                crossOrigin: 'anonymous'
            })
        }),
        mapboxSatelliteStreet: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=$$apikey$$',
                tilePixelRatio: retina,
                crossOrigin: 'anonymous'
            })
        }),
        mapboxOutdoors: new OlTileLayer({
            source: new OlXYZ({
                attributions: '© <a href="https://www.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                url: 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=$$apikey$$',
                tilePixelRatio: retina,
                crossOrigin: 'anonymous'
            })
        })
    },
    cartoDB: {
        cartoDbDark: new OlTileLayer({
            source: new OlXYZ({
                url: `https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}${retina == 1 ? '' : '@' + retina + 'x'}.png`,
                opaque: false,
                tilePixelRatio: retina,
                attributions: "© CartoDB",
                crossOrigin: 'anonymous'
            })
        }),
        cartoDbDarkNoLabels: new OlTileLayer({
            source: new OlXYZ({
                url: `https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/dark_nolabels/{z}/{x}/{y}${retina == 1 ? '' : '@' + retina + 'x'}.png`,
                tilePixelRatio: retina,
                opaque: false,
                attributions: "© CartoDB",
                crossOrigin: 'anonymous'
            })
        }),
        cartoDbLight: new OlTileLayer({
            source: new OlXYZ({
                url: `https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/light_all/{z}/{x}/{y}${retina == 1 ? '' : '@' + retina + 'x'}.png`,
                tilePixelRatio: retina,
                opaque: false,
                attributions: "© CartoDB",
                crossOrigin: 'anonymous'
            })
        }),
        cartoDbLightNoLabels: new OlTileLayer({
            source: new OlXYZ({
                url: `https://cartodb-basemaps-{a-d}.global.ssl.fastly.net/light_nolabels/{z}/{x}/{y}${retina == 1 ? '' : '@' + retina + 'x'}.png`,
                tilePixelRatio: retina,
                opaque: false,
                attributions: "© CartoDB",
                crossOrigin: 'anonymous'
            })
        })
    },
    sonstige: {
      leer: new OlTileLayer(),
      nrwOrtho: new OlTileLayer({
        source: new OlTileWMS({
            attributions: '© <a href="https://www.govdata.de/dl-de/zero-2-0">Datenlizenz Deutschland – Zero</a>',
            url: 'https://www.wms.nrw.de/geobasis/wms_nw_dop?REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'nw_dop_rgb', 'TILED': true },
            crossOrigin: 'anonymous'
        })
      }),
      belgiumOrtho: new OlTileLayer({
          source: new OlTileWMS({
              attributions: '© <a href="https://ngi.be">ngi.be</a>',
              url: 'http://wms.ngi.be/inspire/ortho/service',
              params: { 'LAYERS': 'orthoimage_coverage', 'TILED': true },
              crossOrigin: 'anonymous'
          })
      }),
      austriaOrtho: new OlTileLayer({
          source: new OlXYZ({
              url: 'https://maps{1-4}.wien.gv.at/basemap/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg',
              attributions: 'Datenquelle: <a href="https://www.basemap.at">basemap.at</a>',
              crossOrigin: 'anonymous'
          })
      }),
        wikimedia: new OlTileLayer({
            source: new OlXYZ({
                url: `https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}${retina == 1 ? '' : '@' + retina + 'x'}.png`,
                tilePixelRatio: retina,
                attributions: '<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
                maxZoom: 19,
                crossOrigin: 'anonymous',
            })
        }),
        osm: new OlTileLayer({
            source: new OlOSM()
        }),

        osmde: new OlTileLayer({
            source: new OlXYZ({
                url: 'https://{a-d}.tile.openstreetmap.de/{z}/{x}/{y}.png',
                attributions: '<a target="_blank" href="http://www.openstreetmap.org/">Karte hergestellt aus OpenStreetMap-Daten</a> | Lizenz: <a rel="license" target="_blank" href="http://opendatacommons.org/licenses/odbl/">Open Database License (ODbL)</a>',
                crossOrigin: 'anonymous'
            })
        }),
        esriVectorWorldBasemap: new OlVectorTileLayer({
            source: new OlVectorTileSource({
                format: new OlMVT(),
                url: 'https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf'
            })//,
            //style: createMapboxStreetsV6Style(OlStyle, OlFill, OlStroke, OlIcon, OlText)
        }),
        osmVectorWorldBasemap: new OlVectorTileLayer({
            source: new OlVectorTileSource({
                format: new OlMVT(),
                url: 'https://basemaps.arcgis.com/arcgis/rest/services/OpenStreetMap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf'
            })//,
            //style: createMapboxStreetsV6Style(OlStyle, OlFill, OlStroke, OlIcon, OlText)
        }),
        vectorWorldBasemapDark: new OlVectorTileLayer({
            source: new OlVectorTileSource({
                format: new OlMVT(),
                url: 'https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf'
            })//,
            //style: createMapboxStreetsV6Style(OlStyle, OlFill, OlStroke, OlIcon, OlText)
        }),
        esri: new OlTileLayer({
            source: new OlXYZ({
                attributions: '&copy; <a href="http://www.esri.com/">Esri</a> i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                crossOrigin: 'anonymous'
            })
        }),
        nrw_house: new OlTileLayer({
          source: new OlTileWMS({
              attributions: '© <a href="https://ngi.be">ngi.be</a>',
              url: 'https://www.wms.nrw.de/geobasis/wms_nw_alkis?REQUEST=GetMap&VERSION=1.3.0',
              params: { 'LAYERS': 'adv_alkis_gebaeude', 'TILED': true },
              crossOrigin: 'anonymous'
          })
        }),
        nrw_lands: new OlTileLayer({
          source: new OlTileWMS({
              attributions: '© <a href="https://ngi.be">ngi.be</a>',
              url: 'https://www.wms.nrw.de/geobasis/wms_nw_inspire-flurstuecke_alkis?REQUEST=GetMap&VERSION=1.3.0',
              params: { 'LAYERS': 'CP.CadastralParcel', 'TILED': true },
              crossOrigin: 'anonymous'
          })
        }),

        bb_lands: new OlTileLayer({
          source: new OlTileWMS({
              attributions: '© <a>GeoBasis-DE/LGB, dl-de/by-2-0</a>',
              url: 'https://isk.geobasis-bb.de/ows/alkis_wms?REQUEST=GetMap&VERSION=1.3.0',
              params: { 'LAYERS': 'adv_alkis_flurstuecke'},
              crossOrigin: 'anonymous'
          })
        }),

        bb_house:new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>GeoBasis-DE/LGB, dl-de/by-2-0</a>',
            url: 'https://isk.geobasis-bb.de/ows/alkis_wms?REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'adv_alkis_gebaeude'},
            crossOrigin: 'anonymous'
        })
        }),

        bb_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>GeoBasis-DE/LGB, dl-de/by-2-0; © Geoportal Berlin, dl-de/by-2-0</a>',
            url: 'https://isk.geobasis-bb.de/mapproxy/dop20c/service/wms?request=GetMap&SERVICE=WMS&VERSION=1.3.0',
            params: { 'LAYERS': 'bebb_dop20c'},
            crossOrigin: 'anonymous'
          })
        }),

        bw_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>LGL-BW Datenlizenz Deutschland - Version 2.0, www.lgl-bw.de</a>',
            url: 'https://owsproxy.lgl-bw.de/owsproxy/ows/WMS_INSP_BW_Orthofoto_DOP20?request=GetMap&service=WMS&version=1.3.0',
            params: { 'LAYERS': 'OI.OrthoimageCoverage'},
            crossOrigin: 'anonymous'
          })
        }),

        by_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Landesamt für Digitalisierung, Breitband und Vermessung (BY)</a>',
            url: 'https://geoservices.bayern.de/od/wms/dop/v1/dop40?&version=1.3.0&FORMAT=image%2Fpng&SERVICE=WMS',
            params: { 'LAYERS': 'by_dop40c'},
            crossOrigin: 'anonymous'
          })
        }),

        hb_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Landesamt GeoInformation Bremen</a>',
            url: 'https://geodienste.bremen.de/wms_dop20_2023?language=ger&version=1.3.0&FORMAT=image%2Fpng&SERVICE=WMS',
            params: { 'LAYERS': 'DOP20_2023_HB'},
            crossOrigin: 'anonymous'
          })
        }),

        hh_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Freie und Hansestadt Hamburg, Landesbetrieb Geoinformation und Vermessung (LGV)</a>',
            url: 'https://geodienste.hamburg.de/HH_WMS_DOP?language=ger&version=1.3.0&FORMAT=image%2Fpng&SERVICE=WMS',
            params: { 'LAYERS': 'HH_WMS_DOP'},
            crossOrigin: 'anonymous'
          })
        }),

        ni_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Landesamt für Geoinformation und Landesvermessung Niedersachsen (LGLN) - Landesbetrieb Landesvermessung und Geobasisinformation</a>',
            url: 'https://www.geobasisdaten.niedersachsen.de/doorman/noauth/wms_ni_dop?SERVICE=WMS&VERSION=1.3.0',
            params: { 'LAYERS': 'WMS_NI_DOP'},
            crossOrigin: 'anonymous'
          })
        }),

        he_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Hessische Verwaltung für Bodenmanagement und Geoinformation: Digitale Orthophotos</a>',
            url: 'https://gds-srv.hessen.de/cgi-bin/lika-services/ogc-free-images.ows?VERSION=1.3.0&SERVICE=WMS&REQUEST=GetMap',
            params: { 'LAYERS': 'WMS_HE_DOP'},
            crossOrigin: 'anonymous'
          })
        }),

        mv_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Landesamt für innere Verwaltung M-V, Amt für Geoinformation, Vermessung und Katasterwesen</a>',
            url: 'https://www.geodaten-mv.de/dienste/adv_dop?language=ger&REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'WMS_MV_DOP'},
            crossOrigin: 'anonymous'
          })
        }),

        mv_lands: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Landesamt für innere Verwaltung M-V, Amt für Geoinformation, Vermessung und Katasterwesen/by-2-0</a>',
            url: 'https://www.geodaten-mv.de/dienste/alkis_wms?language=ger&REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'adv_alkis_flurstuecke'},
            crossOrigin: 'anonymous'
          })
        }),

        mv_house: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Landesamt für innere Verwaltung M-V, Amt für Geoinformation, Vermessung und Katasterwesen/by-2-0</a>',
            url: 'https://www.geodaten-mv.de/dienste/alkis_wms?language=ger&REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'adv_alkis_gebaeude'},
            crossOrigin: 'anonymous'
          })
        }),

        sa_ortho: new OlTileLayer({
            source: new OlTileWMS({
              attributions: '© <a>GeoBasis-DE / LVermGeo LSA</a>',
              url: 'https://www.geodatenportal.sachsen-anhalt.de/wss/service/ST_LVermGeo_DOP_WMS_OpenData/guest?&REQUEST=GetMap&VERSION=1.3.0',
              params: { 'LAYERS': 'lsa_lvermgeo_dop20_2'},
              crossOrigin: 'anonymous'
          })
        }),
        sn_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Staatsbetrieb Geobasisinformation und Vermessung Sachsen(GeoSN)</a>',
            url: 'https://geodienste.sachsen.de/wms_geosn_dop-rgb/guest?REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'sn_dop_020'},
            crossOrigin: 'anonymous'
          })
        }),

        sn_lands: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Staatsbetrieb Geobasisinformation und Vermessung Sachsen(GeoSN)</a>',
            url: 'https://geodienste.sachsen.de/wms_geosn_alkis-adv/guest?&REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'adv_alkis_flurstuecke'},
            crossOrigin: 'anonymous'
          })
        }),
        sn_house: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Staatsbetrieb Geobasisinformation und Vermessung Sachsen(GeoSN)</a>',
            url: 'https://geodienste.sachsen.de/wms_geosn_alkis-adv/guest?&REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'adv_alkis_gebaeude'},
            crossOrigin: 'anonymous'
          })
        }),

        th_ortho: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Geodateninfrastruktur Thüringen - GDI-Th"</a>',
            url: 'https://www.geoproxy.geoportal-th.de/geoproxy/services?REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'th_dop'},
            crossOrigin: 'anonymous'
          })
        }),

        de_farbe: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>SGeoBasis-DE / BKG (2024) CC BY 4.0</a>',
            url: 'https://sgx.geodatenzentrum.de/wms_basemapde?REQUEST=GetMap&version=1.3.0',
            params: { 'LAYERS': 'de_basemapde_web_raster_farbe'},
            crossOrigin: 'anonymous'
          })
        }),

        de_grau: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>SGeoBasis-DE / BKG (2024) CC BY 4.0</a>',
            url: 'https://sgx.geodatenzentrum.de/wms_basemapde?REQUEST=GetMap&version=1.3.0',
            params: { 'LAYERS': 'de_basemapde_web_raster_grau'},
            crossOrigin: 'anonymous'
          })
        }),

        ch_ortho:new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Öffentliche Daten des Geoportal.ch</a>',
            url: 'https://wms.geo.admin.ch/?REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'ch.swisstopo.swissimage'},
            crossOrigin: 'anonymous'
        })
        }),

        ch_base: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Öffentliche Daten des Geoportal.ch</a>',
            url: 'https://wms.geo.admin.ch/?REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'ch.swisstopo.pixelkarte-farbe'},
            crossOrigin: 'anonymous'
          })
        }),


        ch_base_grau: new OlTileLayer({
          source: new OlTileWMS({
            attributions: '© <a>Öffentliche Daten des Geoportal.ch</a>',
            url: 'https://wms.geo.admin.ch/?REQUEST=GetMap&VERSION=1.3.0',
            params: { 'LAYERS': 'ch.swisstopo.pixelkarte-grau'},
            crossOrigin: 'anonymous'
          })
        }),

        belgiumCarto: new OlTileLayer({
          extent: [ 279569.630, 6352288.757, 731563.107, 6711221.861 ],
          source: new OlXYZ({
              attributions: '© Be',
              url: 'https://cartoweb.wmts.ngi.be/1.0.0/topo/default/3857/{z}/{y}/{x}.png',
              crossOrigin: 'anonymous'
          })
        }),
        luxOrtho:new OlTileLayer({
          extent: [636757.681558, 6351042.69913, 728716.389785, 6478379.8966],
          source: new OlTileWMS({
              url: 'https://wmts3.geoportail.lu/opendata/service?',
              params: {
                  'LAYERS': 'ortho_latest',
                  'FORMAT': 'image/jpeg',
                  'TRANSPARENT':false
              },
              attributions: [
                  '<a href="https://data.public.lu" target="_blank">data.public.lu</a> Hybrid @ ACT Luxembourg'
              ],
              serverType: 'geoserver'
          })
        }),
        luxBaseMap:new OlTileLayer({
          extent: [636757.681558, 6351042.69913, 728716.389785, 6478379.8966],
          source: new OlTileWMS({
              url: 'https://wmts3.geoportail.lu/opendata/service?',
              params: {
                  'LAYERS': 'Basemap',
                  'FORMAT': 'image/jpeg',
                  'TRANSPARENT':false
              },
              attributions: [
                  '<a href="https://data.public.lu" target="_blank">data.public.lu</a> Hybrid @ ACT Luxembourg'
              ],
              serverType: 'geoserver'
          })
        }),

    }
};

let ElementStylesObj = {
    line: new OlStyle({
        stroke: new OlStroke({
            color: 'black',
            width: 4
        }),
        zIndex: 1
    }),
    point: new OlStyle({
        fill: new OlFill({ color: 'transparent' }),
        stroke: new OlStroke({
            color: 'rgba(0,0,0,0.6)',
            width: 2
        }),
        zIndex: 1
    }),
    circleImage: {
        light: new CircleStyle({
            radius: 4,
            stroke: new OlStroke({
                color: 'rgba(0,0,0,0.6)',
                width: 2
            }),
            fill: new OlFill({ color: 'transparent' })
        }),
        dark: new CircleStyle({
            radius: 4,
            stroke: new OlStroke({
                color: 'rgba(170,170,170,0.6)',
                width: 2
            }),
            fill: new OlFill({ color: 'transparent' })
        })
    },
    hoverCircleImage: {
        light: new CircleStyle({
            radius: 4,
            stroke: new OlStroke({
                color: 'rgba(0,0,0,0.6)',
                width: 2
            }),
            fill: new OlFill({ color: '#fe5d00' })
        }),
        dark: new CircleStyle({
            radius: 4,
            stroke: new OlStroke({
                color: 'rgba(170,170,170,0.6)',
                width: 2
            }),
            fill: new OlFill({ color: '#fe5d00' })
        })
    },

}


export { TileLayerObj, ElementStylesObj }
