<clr-modal #signature [clrModalSize]="'lg'">
  <h3 class="modal-title">{{ "Unterschrift" | mrTranslate }}</h3>
  <div class="modal-body">
    <canvas #unterschriftCanvas style="
      width: 100%;
      height: auto;
      max-height: 65vh;
      border: 1px solid #000000;
      background-color: #fafafa;
    "></canvas>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="signaturePad?.isEmpty()" class="btn btn-primary"
      (click)="modalAntwort.next('signed')">{{"Übernehmen" | mrTranslate}}</button>
    <button type="button" class="btn" (click)="signaturePad.clear()">{{"Löschen" | mrTranslate}}</button>
    <button type="button" class="btn" (click)="signature.close()">{{"Abbrechen" | mrTranslate}}</button>
  </div>
</clr-modal>