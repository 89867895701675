<clr-modal [(clrModalOpen)]="modalOpen" [clrModalSize]="'lg'" [clrModalClosable]="true">
  <h3 class="modal-title">{{titel}}</h3>
  <div class="modal-body">

    <h4>{{"Mangel" | mrTranslate}}: {{mangel}}</h4>
    <br/>
    <hr/>
    <form clrForm ngForm [formGroup]="sonderinspformGroup" #f="ngForm" clrLayout="horizontal" clrLabelSize="8">

      <clr-date-container>
        <label class="clr-col-md-2"> {{ "Beginn" | mrTranslate }} </label>
        <input clrDate name="Datum" autocomplete="off" type="date"
          [ngModel]="sonderinspformGroup.controls['beginn'].value | localeDate"
          (ngModelChange)="sonderinspformGroup.controls['beginn'].setValue(localeDate.tosql($event))"
          [ngModelOptions]="{updateOn: 'blur', standalone: true}"
          [disabled]="isdisabled" />
      </clr-date-container>

      <clr-input-container>
        <label class="clr-col-md-2">{{'Intervall in Tagen' | mrTranslate}}</label>
        <input clrInput type="number" min="1" max="100" name="intervall" formControlName="intervall" [disabled]="isdisabled" />
      </clr-input-container>

      <div class="clr-row">
        <div class="clr-col-md-2">
          <div style="height: 20px;"></div>
          <span>{{"aktiv" | mrTranslate}}</span>
        </div>
        <div class="clr-col-8">
          <div style="height: 20px;"></div>
            <input type="checkbox" clrCheckbox value="true" name="aktiv" formControlName="aktiv" [disabled]="!isdisabled"/>
        </div>
      </div>
      <clr-textarea-container>
        <label class="clr-col-md-2">{{"Bemerkung" | mrTranslate}}</label>
        <textarea id="aa" class="clr-col-md-10"
          clrTextarea formControlName="bemerkung"
          style=" width:100%;height:150px"></textarea>
      </clr-textarea-container>
      <br/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveSonderinspektion()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="modalOpen = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
