import { NgFor, NgIf, TitleCasePipe, UpperCasePipe } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewChildren,} from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClrButtonGroupModule, ClrCommonFormsModule, ClrConditionalModule, ClrDatagrid, ClrDatagridModule, ClrDatepickerModule, ClrInputModule, ClrPopoverHostDirective, ClrSelectModule, ClrStopEscapePropagationDirective, ClrTabsModule, ClrTextareaModule } from "@clr/angular";
import dayjs from "dayjs";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { DotDotDotPipe } from "../../../../../pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "../../../../../pipes/hide-idcolumns.pipe";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../../../../pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "../../../../../pipes/tablePrettyPrint.pipe";
import { ActionEvent, FremdleistungStackViewComponent } from "./fremdleistung-stack-view/fremdleistung-stack-view.component";
import { KostenEditModalComponent, MaschineEditDataWithImage, MaterialEditDataWithImage, PersonalEditDataWithImage } from "./kosten-edit-modal/kosten-edit-modal.component";
import { KostenFremdleistungModalComponent } from "./kosten-fremdleistung-modal/kosten-fremdleistung-modal.component";

export enum KostenTyp {
  PERSONAL = 1,
  MATERIAL = 2,
  MASCHINE = 3,
  FREMDLEISTUNG = 4,
}

export interface ActionEventInternal {
  action: "add" | "edit" | "delete";
  data: any;
}


interface FremdleistungAddData {
  ID: number;
  Titel_ID: number;
  Hauptgruppe: number;
}

export interface FremdleistungEditData extends FremdleistungAddData {
  ID: number;
  OZ?: string;
  Kurztext: string;
  Langtext: string;
  ME: string;
  EP: number;
}

@Component({
    selector: "app-kostenerfassung",
    templateUrl: "./kostenerfassung.component.html",
    styleUrls: ["./kostenerfassung.component.scss"],
    providers: [BackendLocaleDatePipe],
    imports: [
        NgIf,
        ClrDatagridModule,
        NgFor,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrTabsModule,
        ClrConditionalModule,
        KostenEditModalComponent,
        FormsModule,
        ClrCommonFormsModule,
        ReactiveFormsModule,
        ClrDatepickerModule,
        ClrInputModule,
        ClrSelectModule,
        ClrTextareaModule,
        ClrButtonGroupModule,
        KostenFremdleistungModalComponent,
        FremdleistungStackViewComponent,
        UpperCasePipe,
        TitleCasePipe,
        TablePrettyPrintPipe,
        HideIDColumnsPipe,
        DotDotDotPipe,
        MrTranslatePipe_1,
        BackendLocaleDatePipe,
    ],
    standalone: true
})
export class KostenerfassungComponent implements OnInit, OnDestroy {

  doAction(action: "add" | "edit" | "delete") {
    this.action.emit({ action, data: this.selected });
  }

  @Input() kauftrid: string;
  @Input() set auftrag(newAuftrag: any) {
    if (!!newAuftrag?.Auftragsstatus) {
      this.personalForm.disable();
      this.materialForm.disable();
      this.maschinenForm.disable();
      this.fremdleistungForm.disable();
    }
    this._auftrag = newAuftrag;
  }

  @Output() action: EventEmitter<ActionEventInternal> = new EventEmitter();

  get auftrag() {
    return this._auftrag;
  }
  _auftrag: any;

  mengeneinheiten: string[] = [];
  @Input() refresh: EventEmitter<void>;
  @Output() refreshGesamtkosten = new EventEmitter<void>();

  kostenTable = {
    rows: {
      personal: [],
      material: [],
      maschine: [],
      fremdleistung: [],
    },
    columns: null,
    summary: {
      kosten: "0.00",
      plankosten: "0.00",
    },
  };

  // --- PERSONAL ---

  personalForm = new UntypedFormGroup({
    datum: new UntypedFormControl("", [Validators.required]),
    arbeitszeitStart: new UntypedFormControl("", [Validators.required]),
    arbeitszeitEnde: new UntypedFormControl("", [Validators.required]),
    taetigkeit: new UntypedFormControl("", [Validators.required]),
    bemerkung: new UntypedFormControl(""),
  });

  @ViewChild("kostenDg") kostenDg: ClrDatagrid;
  @ViewChildren("datagrid") datagrids: ClrDatagrid[];

  personalTable = {
    rows: null,
    columns: [],
  };

  // --- MATERIAL ---

  materialForm = new UntypedFormGroup({
    datum: new UntypedFormControl("", [Validators.required]),
    anzahl: new UntypedFormControl("", [Validators.required]),
    bemerkung: new UntypedFormControl(""),
  });

  materialTable = {
    rows: null,
    columns: [],
  };

  // --- MASCHINEN ---

  maschinenForm = new UntypedFormGroup({
    datum: new UntypedFormControl("", [Validators.required]),
    arbeitszeitStart: new UntypedFormControl("", [Validators.required]),
    arbeitszeitEnde: new UntypedFormControl("", [Validators.required]),
    taetigkeit: new UntypedFormControl("", [Validators.required]),
    bemerkung: new UntypedFormControl(""),
  });

  maschinenTable = {
    rows: null,
    columns: [],
  };

  // --- FREMDLEISTUNG ---

  fremdleistungForm = new UntypedFormGroup({
    datum: new UntypedFormControl("", [Validators.required]),
    kurztext: new UntypedFormControl(""),
    anzahl: new UntypedFormControl("", [Validators.required]),
    einheit: new UntypedFormControl(""),
    bemerkung: new UntypedFormControl(""),
  });

  fremdLeistungTitles: any[] = [];
  fremdleistungen: any[] = [];

  selected = {
    personal: null,
    material: null,
    maschine: null,
    fremdleistung: null,
  };

  $deleteSelection: BehaviorSubject<void> = new BehaviorSubject(null);

  modals: {
    personal: boolean;
    material: boolean;
    maschine: boolean;
    fremdleistung: boolean | string;
  } = {
    personal: false,
    material: false,
    maschine: false,
    fremdleistung: false,
  };

  modalData: {
    personal: PersonalEditDataWithImage | null;
    material: MaterialEditDataWithImage | null;
    maschine: MaschineEditDataWithImage | null;
    fremdleistung: FremdleistungAddData | FremdleistungEditData | null;
  } = {
    personal: null,
    material: null,
    maschine: null,
    fremdleistung: null,
  };

  zeiten = {
    personal: null,
    maschine: null,
  };

  taetigkeiten = { personal: [], maschine: [] };

  subscriptions: Subscription[] = [];

  trial = false;

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private changeDet: ChangeDetectorRef,
    private authService: AuthService,
    protected localeDate: BackendLocaleDatePipe
  ) {
    this.trial = this.authService.getToken().trial;
  }

  ngOnInit(): void {
    if (this.kostenTable.columns === null) {
      this.loadAuftragKosten();
    }

    if (this.refresh) {
      this.subscriptions.push(
        this.refresh.subscribe(() => this.refreshGrids())
      );
    }

    this.apiservice
      .getKostenTaetigkeiten()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) this.taetigkeiten = res;
      });

    this.loadPersonal();

    this.loadMaterial();

    this.loadMaschine();

    this.loadFremdleistung();

    this.apiservice
      .getKostenFremdleistungEinheiten()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) this.mengeneinheiten = res;
      });

    this.subscriptions.push(
      this.personalForm.controls.arbeitszeitStart.valueChanges.subscribe((_) =>
        this.calculateZeiten("personal")
      )
    );
    this.subscriptions.push(
      this.personalForm.controls.arbeitszeitEnde.valueChanges.subscribe((_) =>
        this.calculateZeiten("personal")
      )
    );

    this.subscriptions.push(
      this.maschinenForm.controls.arbeitszeitStart.valueChanges.subscribe((_) =>
        this.calculateZeiten("maschine")
      )
    );
    this.subscriptions.push(
      this.maschinenForm.controls.arbeitszeitEnde.valueChanges.subscribe((_) =>
        this.calculateZeiten("maschine")
      )
    );
  }

  private loadMaschine() {
    this.maschinenTable = {
      rows: null,
      columns: [],
    };
    this.changeDet.detectChanges();
    this.apiservice
      .getKostenMaschinen()
      .pipe(first())
      .subscribe((res: any) => {
        if (res)
          this.maschinenTable = {
            columns: res.columns,
            rows: res.rows && res.rows.length > 0 ? [...res.rows] : []
          };
        this.modals.maschine = false;
      });
  }

  private loadMaterial() {
    this.materialTable = {
      rows: null,
      columns: [],
    };
    this.changeDet.detectChanges();
    this.apiservice
      .getKostenMaterial()
      .pipe(first())
      .subscribe((res: any) => {
        if (res)
          this.materialTable = {
            columns: res.columns,
            rows: res.rows && res.rows.length > 0 ? [...res.rows] : []
          };
        this.modals.material = false;
      });
  }

  private loadPersonal(initial = true) {
    this.personalTable = {
      rows: null,
      columns: [],
    };
    this.changeDet.detectChanges();
    this.apiservice
      .getKostenPersonal()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          this.personalTable = {
            columns: res.columns,
            rows: res.rows && res.rows.length > 0 ? [...res.rows] : []
          };
          this.modals.personal = false;
        }
      });
  }

  private loadFremdleistung() {
    this.saveFremdleistungExpanded();

    this.fremdleistungen = [];
    this.fremdLeistungTitles = [];
    this.changeDet.detectChanges();
    this.apiservice
      .getKostenFremdleistung()
      .pipe(first())
      .subscribe((res: any) => {
        const expanded = JSON.parse(
          localStorage.getItem("fremdleistungenExpanded")
        );
        if (res && !res.error) {
          const titel =
            res.titel?.map((t) => {
              return {
                ...t,
                Kurztext: t.Bezeichnung,
                OZ: "",
                children: [],
                expanded: expanded?.includes(t.ID) ?? false,
                Titel_ID: 0,
              };
            }) ?? [];
          const input =
            res.leistungen?.map((l) => {
              return { ...l, selected: 0, expanded: false };
            }) ?? [];
          let leistungen = [];
          leistungen = leistungen.concat(
            input
              .filter((l) => l.Hauptgruppe === "-" || l.Hauptgruppe === "")
              .map((l) => {
                l.expanded = true;
                return l;
              })
          );
          let childLeistungen = input.filter(
            (l) => l.Hauptgruppe !== "-" && l.Hauptgruppe !== ""
          );
          // add childLeistungen to leistungen matching on parseInt(Hauptgruppe) == ID properties
          // add children recursively since there can be multiple levels
          const addChildren = (leistung) => {
            const isCurrentLeistung = (l) =>
              parseInt(l.Hauptgruppe) === leistung.ID;
            const notIsCurrentLeistung = (l) =>
              parseInt(l.Hauptgruppe) !== leistung.ID;
            const children = childLeistungen.filter(isCurrentLeistung);
            // remove children from childLeistungen
            childLeistungen = childLeistungen.filter(notIsCurrentLeistung);
            if (children.length > 0) {
              leistung.children = children;
              children.forEach((child) => addChildren(child));
            }
          };

          const addToTitel = (leistung) => {
            const t = titel.find((t) => t.ID === leistung.Titel_ID);
            if (t) {
              t.children.push(leistung);
            }
          };
          leistungen.forEach((leistung) => addChildren(leistung));
          leistungen.forEach((l) => {
            addToTitel(l);
          });

          this.fremdleistungen = titel;
          this.modals.fremdleistung = false;
        }
      });
  }

  private saveFremdleistungExpanded() {
    if (this.fremdleistungen.length > 0) {
      // save expanded state of fremdleistungen
      const expanded = this.fremdleistungen
        .filter((t) => t.expanded)
        .map((t) => t.ID);
      localStorage.setItem("fremdleistungenExpanded", JSON.stringify(expanded));
    }
  }

  private loadAuftragKosten() {
    this.apiservice
      .getKostenAuftrag(Number(this.kauftrid))
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          this.kostenTable = {
            rows: {
              personal: [],
              material: [],
              maschine: [],
              fremdleistung: [],
            },
            columns: null,
            summary: {
              kosten: "0.00",
              plankosten: "0.00",
            },
          };
          this.changeDet.detectChanges();
          this.refreshGrids();
          const table = res;
          const rows = [...table.rows];
          table.rows = {};
          let kosten = 0,
            plankosten = 0;
          if (rows.length > 0) {
            kosten =
              rows
                ?.map((r) => r.Kosten || 0)
                ?.reduce((a, b) => Number(a) + Number(b)) || 0;
            plankosten =
              rows
                ?.map((r) => r.PlanKosten || 0)
                ?.reduce((a, b) => Number(a) + Number(b)) || 0;
          }

          table.rows.personal = rows?.filter(
            (r) => r.KostenTyp === KostenTyp.PERSONAL
          );

          table.rows.material = rows?.filter(
            (r) => r.KostenTyp === KostenTyp.MATERIAL
          );

          table.rows.maschine = rows?.filter(
            (r) => r.KostenTyp === KostenTyp.MASCHINE
          );

          table.rows.fremdleistung = rows?.filter(
            (r) => r.KostenTyp === KostenTyp.FREMDLEISTUNG
          );

          table.summary = {
            kosten: Number(kosten).toFixed(2),
            plankosten: Number(plankosten).toFixed(2),
          };
          this.kostenTable = table;
          this.refreshGrids(100);
        }
      });
  }

  calculateZeiten(type: "personal" | "maschine") {
    const form = this.getForm(type);
    const start = form.controls.arbeitszeitStart.value;
    const ende = form.controls.arbeitszeitEnde.value;
    if (start && ende) {
      let diff = dayjs(`2000-01-01 ${ende}`).diff(
        dayjs(`2000-01-01 ${start}`),
        "hours",
        true
      );
      if (diff < 0) diff = 0;
      const isFloat = diff % 1 !== 0;
      this.zeiten = {
        ...this.zeiten,
        [type]: isFloat ? diff.toFixed(2).replace(".", ",") : diff.toString(),
      };
    }
  }

  private getForm(type: string) {
    let form = null;
    switch (type) {
      case "personal":
        form = this.personalForm;
        break;
      case "material":
        form = this.materialForm;
        break;
      case "maschine":
        form = this.maschinenForm;
        break;
      case "fremdleistung":
        form = this.fremdleistungForm;
        break;
    }
    return form;
  }

  openModal(type: string, data: any) {
    const mdData = { ...this.modalData };
    if (data) {
      switch (type) {
        case "personal":
          mdData.personal = {
            id: data.ID,
            personalnummer: data.PersonalNummer,
            kuerzel: data.Kuerzel,
            vorname: data.Vorname,
            nachname: data.Nachname,
            email: data.Email,
            qualifikation: data.Qualifikation,
            wochenarbeitszeit: data.Wochenstunden,
            verrechnungssatz: data.Lohn,
            bild: data.Bild,
          };
          break;
        case "material":
          mdData.material = {
            id: data.ID,
            materialnummer: data.MaterialNummer,
            titel: data.Titel,
            beschreibung: data.Beschreibung,
            einheit: data.Einheit,
            einzelpreis: data.Preis,
            bild: data.Bild,
          };
          break;
        case "maschine":
          mdData.maschine = {
            id: data.ID,
            maschinennummer: data.MaschinenNummer,
            titel: data.Titel,
            beschreibung: data.Beschreibung,
            verrechnungssatz: data.Preis,
            bild: data.Bild,
          };
          break;
        case "fremdleistung":
          mdData.fremdleistung = null;
          break;
      }
      this.modalData = { ...mdData };
    }else {
      switch (type) {
        case "personal":
          mdData.personal = {
            id: -99,
            personalnummer: "",
            kuerzel:  "",
            vorname: "",
            nachname: "",
            email: "",
            qualifikation: "",
            wochenarbeitszeit: 0,
            verrechnungssatz: 0,
            bild: ""
          };
          break;
        case "material":
          mdData.material = {
            id: -99,
            materialnummer: "",
            titel: "",
            beschreibung: "",
            einheit: "",
            einzelpreis: 0,
            bild: "",
          };
          break;
        case "maschine":
          mdData.maschine = {
            id: -99,
            maschinennummer:  "",
            titel:  "",
            beschreibung:  "",
            verrechnungssatz:  0,
            bild:  "",
          };
          break;
        case "fremdleistung":
          mdData.fremdleistung = null;
          break;
      }
    }
    this.modals[type] = true;
  }

  closeModal(type: string, reload: any) {
    if (reload) {
      setTimeout(() => {
        switch (type) {
          case "personal":
            this.loadPersonal();
            break;
          case "material":
            this.loadMaterial();
            break;
          case "maschine":
            this.loadMaschine();
            break;
          case "fremdleistung":
            this.loadFremdleistung();
            break;
        }
      });
    } else {
      this.modals = { ...this.modals, [type]: false };
    }
  }

  refreshGrids(time = 1) {
    setTimeout(() => {
      for (const dg of this.datagrids) {
        dg?.dataChanged();
        dg?.resize();
      }
    }, time);
  }

  saveKosten(type: "personal" | "material" | "maschine" | "fremdleistung") {
    let form: UntypedFormGroup = this.getForm(type);
    const selected = this.selected[type];
    if (selected === null || (type === "personal" && selected.length === 0)) {
      const typeName = type[0].toUpperCase() + type.slice(1);
      const text = this.mrTranslate.transform(
        `Kein${type === "fremdleistung" ? "e" : ""} ${typeName} ausgewählt`
      );
      this.toastr.warning(text);
      return;
    }

    if (!form.valid) {
      const text = this.mrTranslate.transform(
        "Bitte alle Pflichtfelder(*) ausfüllen"
      );
      this.toastr.warning(text);
      return;
    }

    let toSend;
    // if (type === "personal") {
    //   toSend = selected.map((s) => ({
    //     ...form.value,
    //     kostenID: s.ID,
    //     auftragID: this.kauftrid,
    //   }));
    // } else {
      toSend = [
        {
          ...form.value,
          kostenID: selected.ID,
          auftragID: this.kauftrid,
        },
      ];
    // }

    this.apiservice
      .setKostenAuftrag(type, toSend)
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status == "success") {
          const text = this.mrTranslate.transform(
            "Kosten erfolgreich gespeichert"
          );
          this.toastr.success(text);
          form.reset();
          const newSelect = { ...this.selected };
          newSelect[type] = type === "personal" ? [] : null;
          this.selected = newSelect;
          this.$deleteSelection.next();
          this.refreshGesamtkosten.emit();
          this.loadAuftragKosten();
        } else {
          const text = this.mrTranslate.transform(
            "Kosten konnten nicht gespeichert werden"
          );
          this.toastr.error(text);
        }
      });
  }

  fremdleistungSelected(leistung: any) {
    if (leistung.EP !== "") {
      this.fremdleistungForm.controls.kurztext.setValue(
        leistung.Kurztext ?? ""
      );
      this.fremdleistungForm.controls.einheit.setValue(leistung.ME ?? "");
      this.fremdleistungForm.controls.bemerkung.setValue(
        leistung.Langtext ?? ""
      );
    }

    this.selected.fremdleistung = leistung;
  }

  fremdleistungAction(actionEvent: ActionEvent) {
    const action = actionEvent.action;
    const leistung = actionEvent.data;

    switch (action) {
      case "add":
      case "edit":
      case "addLV":
      case "editLV":
        this.modalData = {
          ...this.modalData,
          fremdleistung: leistung
            ? {
                ...leistung,
                Hauptgruppe: parseInt(leistung.Hauptgruppe),
              }
            : null,
        };
        this.modals = { ...this.modals, fremdleistung: action };
        break;
      case "delete":
        this.deleteFremdleistung(leistung,"fremdleistungen");
        break;
    }
  }

  deleteFremdleistung(leistung: any , type: string) {
    const message = this.mrTranslate.transform("Sind Sie sicher?");
    if (confirm(message)) {
      this.apiservice
        .deleteKostenFremdleistung(leistung.ID,type)
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status == "success") {
            const text = this.mrTranslate.transform(
              type + " erfolgreich gelöscht"
            );
            this.toastr.success(text);
            switch (type) {
              case "personal":
                this.loadPersonal();
                break;
              case "material":
                this.loadMaterial();
                break;
              case "maschine":
                this.loadMaschine();
                break;
              case "fremdleistungen":
                this.loadFremdleistung();
                break;
            }
          } else {
            const text = this.mrTranslate.transform(
              "Fremdleistung konnte nicht gelöscht werden"
            );
            this.toastr.error(text, res.message ?? "");
          }
        });
    }
  }

  tabChange() {
    this.refreshGrids();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.saveFremdleistungExpanded();
  }
}
