import {
  throwError as observableThrowError,
  Observable,
  BehaviorSubject,
  ObservableInput,
  throwError,
  of,
  from,
} from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { map, catchError, tap } from "rxjs/operators";
import { UpdateAuftrag } from "src/app/content/_components/_overlays/auftrag-detail/auftrag-detail.component";
import {
  FremdleistungInput,
  FremdleistungUpdateInput,
} from "src/app/types/api";
import JSZip from 'jszip';
import { UserClaims } from "@okta/okta-auth-js";
import { ItemsMode as WartungsItemMode } from "src/app/content/_components/_overlays/wartungsverwaltung/wartung.types";
import { db } from "../dexieDB";
import { SOTYP_Map } from "src/app/models/api/SOTYP";
import { ZählerSkizze } from "src/app/content/_components/_overlays/zaehlerdialog/zaehlerverwaltung/zaehler.types";

const API_URL = environment.apiUrl;

export interface ErrorPayload {
  message: string;
  stack?: string;
  timestamp: string;
}


@Injectable({
  providedIn: "root",
})
export class APIService {
  public AUTH_HEADER;
  public AUTH_HEADER_LOGIN_NO_CACHE;
  public AUTH_HEADER_PRIV_CACHE;
  public AUTH_HEADER_NO_CACHE;
  public AUTH_HEADER_BIN;
  public AUTH_HEADER_ARR;
  public AUTH_HEADER_OBSERVE;
  public isRep;

  public aud: string = '';
  public tid: string = '';


  private img_placeholder = new BehaviorSubject(
    "https://via.placeholder.com/400x300"
  );
  //#region all
  constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {
    this.refreshJWT();
  }

  public getfeldliste(_table: String, _field: String) {
    return this.http.post(API_URL + "/auftrag/getfeldliste", {
      table: _table,
      field: _field,
      key: 'fe0ef3c0-f5a5-4d4e-b61a-03ea117f9cc8',
    },this.AUTH_HEADER);
  }

  public updatepassword(email: String, userid: String, pw: String) {
    return this.http.post(API_URL + "/login/resetpassword", {
      email: email,
      id: userid,
      pw: pw,
      key: '9eec9eba-cb65-42c0-bff8-4dee0072ea4c',
    },);
  }

  public getOktaMRproToken(token: String) {
    return this.http.post(API_URL + "/login/testokta", {
      token: token,
    },);
  }

  public sendforgotemail(email: String, codes: String) {
    return this.http.post(API_URL + "/login/sendforgetemail", {
      email: email,
      codes: codes,
      type: "password_forgot",
      key: 'fed27d99-3cb0-4296-bfc4-b96a4e5767f8',
    },);
  }

  public checkmail(email: String) {
    return this.http.post(API_URL + "/login/checkmail", {
      email: email,
      key: 'bdaf624c-0fba-44e7-b001-5025493440f6',
    },);
  }

  public getApiImages(id: String, typ: String, images: string) {
    return this.http.post(API_URL + "/api/getimages", {
      id: id,
      typ: typ,
      images: images,
      key: '541b122c-341c-448b-b3c7-43847172a576',
    }, this.AUTH_HEADER_LOGIN_NO_CACHE);
  }

  public getApiPdfDok(id: String, ostammid: String, fremdid: String) {

    let customheader: any = {
      headers: new HttpHeaders({
        Authorization: "Bearer ",
      }),
      responseType: "blob",
    };

    return this.http.post(API_URL + "/api/getpdf", {
      id: id,
      fremdid: fremdid,
      ostammid: ostammid,
      language: localStorage.getItem("language"),
      key: '541b122c-341c-448b-b3c7-43847172a589',
    }, customheader);
  }

  public login(email: String, pwhash: String, db: String, karteFileID: String) {
    return this.http.post(API_URL + "/login", {
      email: email,
      pwhash: pwhash,
      db: db,
      karteFileID: karteFileID,
    }, this.AUTH_HEADER_LOGIN_NO_CACHE);
  }

  public loginGetDatabases(email: String, pwhash: String) {
    return this.http.post(API_URL + "/login/getdatabase", {
      email: email,
      pwhash: pwhash,
    }, this.AUTH_HEADER_LOGIN_NO_CACHE);
  }

  public logout(): Observable<any> {
    return this.http.post(API_URL + "/login/logout", this.AUTH_HEADER);
  }


  public oktalogin(claims: UserClaims) {

    return this.http.post(API_URL + "/login", {
      okta: claims,
      email: claims.email,
      // forename: claims.given_name,
      // lastname: claims.family_name,
      // iat: claims.iat,
      // exp: claims.exp
    }, this.AUTH_HEADER_LOGIN_NO_CACHE);
  }

  public msalLogin(email: string, token: string, initdb: string) {
    return this.http.post(API_URL + "/login", {
      email: email,
      token: token,
      db: initdb,
    }, this.AUTH_HEADER_LOGIN_NO_CACHE)
      .pipe(
        tap(response => console.log("[MSAL] API Response:", response)),
        catchError(error => {
          console.error("[MSAL] API Error:", error);
          return throwError(error);
        })
      );
  }


  public internlogin(email: String, pwhash: String, domain: String, karteFileID: String) {
    return this.http.post(API_URL + "/login/internlogin", {
      email: email,
      pwhash: pwhash,
      domain: domain,
      karteFileID: karteFileID,
      key: '448c81ac-c1e1-4a3e-8b8b-3484a262a8e0',
    }, this.AUTH_HEADER_LOGIN_NO_CACHE);
  }



  public send2FASMS(mobil: string, code: string) {
    return this.http.post(API_URL + "/login/sendsms", {
      mobil: mobil,
      code: code,
      key: '448c81ac-c1e1-4a3e-8b8b-3484a262a8e0',
    }, this.AUTH_HEADER_LOGIN_NO_CACHE);
  }

  public getdb() {
    return this.http.post(API_URL + "/login/getdb", {
      key: '9e55218f-f038-4834-919b-78aa2cbb4b7c',
    },);
  }

  public refreshLogin(email: String, refreshToken: String) {
    return this.http.post(API_URL + "/login", {
      email: email,
      refreshToken: refreshToken,
      db: localStorage.getItem('intdb'),
    }, this.AUTH_HEADER_LOGIN_NO_CACHE);
  }

  public getURL() {
    return API_URL;
  }

  public getHEADER() {
    return this.AUTH_HEADER;
  }

  public verifyGoogleCode(items: any) {
    return this.http.post(API_URL + "/login/verifygooglecode", {
      items: items,
    },);
  }

  public setupGoogleCode(items: any) {
    return this.http.post(API_URL + "/login/getgoogleauthkey", {
      items: items,
    },);
  }

  public getnewTenantId(email: string) {
    return this.http.post(API_URL + "/login/getnewtenantid", {
      key: 'b018604a-fc94-4472-9520-1e41a84862c6',
      mail: email,
    },);
  }

  public getMSALTenantId() {
    return this.http.get(API_URL + "/login/getmsaltenantId", this.AUTH_HEADER);
  }

  public addZaehler(data: any): Observable<any> {
    return this.http.post(API_URL + "/tabelle-name/add", data, this.AUTH_HEADER);
  }


  public addVorlage(data: any): Observable<any> {
    return this.http.post(API_URL + "/vorlage/add", data, this.AUTH_HEADER);
  }

  // public addTabelleName(data: any): Observable<any> {
  //   return this.http.post(API_URL + "/tabelle-name/add", data, this.AUTH_HEADER);
  // }

  public testMethod() { }

  public getKarte() {
    return this.http.get(API_URL + "/karte", this.AUTH_HEADER);
  }

  public getKalender(): Observable<any> {
    return this.http.get(API_URL + "/kalender", this.AUTH_HEADER);
  }

  public getTopoJson() {
    return this.http.get(API_URL + "/karte/topojson", this.AUTH_HEADER);
  }

  public refreshTopoJson(fileID: string) {
    return this.http.get(API_URL + "/karte/refreshTopoJson/" + fileID, this.AUTH_HEADER);
  }


  public getObjekttypen() {
    return this.http.get(API_URL + "/karte/objtypes", this.AUTH_HEADER);
  }

  public getTOEdit(value: any) {
    return this.http.get(API_URL + "/karte/getTOEdit/" + value, this.AUTH_HEADER);
  }


  public getTOEditSchiene(value: string, bezeichnung: string) {
    return this.http.get(API_URL + "/karte/getTOEdit/" + value + "/" + bezeichnung, this.AUTH_HEADER);
  }


  public setNewFeature(values: any) {
    return this.http.post(API_URL + "/karte/newFeature", values, this.AUTH_HEADER);
  }

  public setNewSymbol(values: any) {
    return this.http.post(API_URL + "/karte/setnewSymbol", values, this.AUTH_HEADER);
  }

  public setNewTOSymbol(values: any) {
    return this.http.post(API_URL + "/karte/setnewTOSymbol", values, this.AUTH_HEADER);
  }

  public editFeature(values: any) {
    return this.http.post(API_URL + "/karte/editFeature", values, this.AUTH_HEADER);
  }

  public deleteFeature(values: any) {
    return this.http.delete(API_URL + "/karte/feature/" + values, this.AUTH_HEADER);
  }

  public applyRotation(values: any) {
    return this.http.post(API_URL + "/karte/applyRotation", values, this.AUTH_HEADER);
  }

  public saveFeatures(values: any) {
    return this.http.post(API_URL + "/karte/saveFeatures", values, this.AUTH_HEADER);
  }

  public getLayerFeatures(fileID: string, key: string) {
    return this.http.get(API_URL + "/karte/getFeatures/" + fileID + "/" + key, this.AUTH_HEADER);
  }

  public getStammDatenBez() {
    return this.http.get(API_URL + "/karte/stammDaten", this.AUTH_HEADER);
  }
  public getKarteAnlagen(typ: number) {
    return this.http.get(API_URL + "/karte/anlagen/" + typ, this.AUTH_HEADER);
  }

  public saveMapSet(values: any) {
    return this.http.post(API_URL + "/karte/MapSet", values, this.AUTH_HEADER);
  }
  public deleteMapSet(values: any) {
    return this.http.delete(API_URL + "/karte/MapSet/" + values, this.AUTH_HEADER);
  }
  public getMapSettings() {
    return this.http.get(API_URL + "/karte/settings", this.AUTH_HEADER);
  }

  public newTOSymbol(values: any) {
    return this.http.post(API_URL + "/karte/getTOEdit/Add/", values, this.AUTH_HEADER);
  }

  public editTOSymbol(values: any) {
    return this.http.post(API_URL + "/karte/getTOEdit/Edit/", values, this.AUTH_HEADER);
  }

  public deleteTOSymbol(values: any) {
    return this.http.post(API_URL + "/karte/getTOEdit/Delete/", values, this.AUTH_HEADER);
  }

  public getSysManWindow(action: string) {
    return this.http.get(API_URL + "/sysMan/" + action, this.AUTH_HEADER);
  }

  public SysManStartAction(action: string, v: any) {
    return this.http.post(API_URL + "/sysMan/start/" + action, v, this.AUTH_HEADER);
  }

  public SysManGetObjList() {
    return this.http.get(API_URL + "/sysMan/objList/", this.AUTH_HEADER);
  }

  public setupReplicate(values: any) {
    return this.http.post(API_URL + "/replikat/setup", values, this.AUTH_HEADER);
  }
  public syncbackReplicate(values: any) {
    return this.http.post(API_URL + "/replikat/syncback", values, this.AUTH_HEADER);
  }

  public getMaengel(alleInspktionen: boolean) {
    return this.http.post(
      API_URL + "/maengel",
      { alleInspektionen: alleInspktionen },
      this.AUTH_HEADER_ARR
    );
  }

  public getMaengelLog(omazsid: number): Observable<any> {
    return this.http.get(API_URL + "/maengel/log/" + omazsid, this.AUTH_HEADER);
  }

  public getMangelMedia(filename: string) {
    return this.http.get(
      API_URL + "/maengel/media/" + encodeURIComponent(filename),
      this.AUTH_HEADER
    );
  }
  public getMangelMediaAll(names: string[]) {
    return this.http.post(
      API_URL + "/maengel/media/",
      names,
      this.AUTH_HEADER
    );
  }

  public getAnlagenMedia(filename: string) {
    return this.http.get(
      API_URL + "/anlagen/media/" + encodeURIComponent(filename),
      this.AUTH_HEADER
    );
  }
  public getAnlagenMediaAll(names: string[]) {
    return this.http.post(
      API_URL + "/anlagen/media/",
      names,
      this.AUTH_HEADER
    );
  }

  public getNewMangelValues(otypid: number, values: any) {
    return this.http.post(
      API_URL + "/maengel/newMangel/values/" + otypid,
      values,
      this.AUTH_HEADER
    );
  }

  public getNewMangelGeoDistance(ostammid: number, coords: any) {
    return this.http.post(
      API_URL + "/maengel/newMangel/findGeoDistance/" + ostammid,
      coords,
      this.AUTH_HEADER
    );
  }

  public getVectorStyle(mapname: string) {
    return this.http.get(
      "https://basemaps.arcgis.com/arcgis/rest/services/" +
      mapname +
      "/VectorTileServer/resources/styles"
    );
  }

  public getKarteStrValues() {
    return this.http.get(API_URL + "/karte/strvalues", this.AUTH_HEADER);
  }

  public getDocumentFile(id: any) {
    return this.http.get(API_URL + "/anlagen/getDocumentFile/" + id, this.AUTH_HEADER);
  }

  public getAnlagenQR(id: any) {
    return this.http.get(API_URL + "/anlagen/getQRLabel/" + id, this.AUTH_HEADER_BIN);
  }

  public getQRLables(otypid: any) {
    let customheader: any = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      }),
      responseType: "blob",
    };
    //return this.http.get(API_URL + "/anlagen/getPDFFile/" + opruefid, customheader);
    return this.http.post(API_URL + "/sysMan/getQRLables/", { otypid: otypid, language: localStorage.getItem("language") }, customheader);
  }


  public getPDFFile(opruefid: any) {
    let customheader: any = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      }),
      responseType: "blob",
    };
    //return this.http.get(API_URL + "/anlagen/getPDFFile/" + opruefid, customheader);
    return this.http.post(API_URL + "/anlagen/getPDFFile/", { opruefid: opruefid, language: localStorage.getItem("language") }, customheader);
  }

  public downloadExcelFile(excelPath: string) {
    let customheader: any = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      }),
      responseType: "blob",
    };
    return this.http.post(
      API_URL + "/anlagen/downloadExcelFile/",
      { path: excelPath },
      customheader
    );
  }

  public uploadFiles(formData: any) {
    return this.http.post(API_URL + "/files/uploadfiles", formData, this.AUTH_HEADER_OBSERVE);
  }

  public getRailmonitor(ostammid: number) {
    return this.http.get(API_URL + "/anlagen/railmonitor/" + ostammid, this.AUTH_HEADER);
  }
  public getRailmonitorSollprofile() {
    return this.http.get(API_URL + "/anlagen/railmonitor/sollprofile", this.AUTH_HEADER);
  }
  public deleteRailmonitor(id: number): Observable<any> {
    return this.http.delete(API_URL + "/anlagen/railmonitor/" + id, this.AUTH_HEADER);
  }
  public getRailmonitorImport(formData: any) {
    return this.http.post(API_URL + "/anlagen/railmonitor/data", formData, this.AUTH_HEADER);
  }
  public saveRailmonitorImport(ostammid: number, formData: any) {
    return this.http.post(API_URL + "/anlagen/railmonitor/import/" + ostammid, formData, this.AUTH_HEADER);
  }


  public getFirmen() {
    return this.http.get(API_URL + "/anlagen/firmen", this.AUTH_HEADER);
  }

  public getKundenBezeichnung() {
    return this.http.get(API_URL + "/anlagen/kundenbezeichnung", this.AUTH_HEADER);
  }

  public getINIOptions() {
    return this.http.get(API_URL + "/anlagen/inioptions", this.AUTH_HEADER);
  }

  public getFirmenAll() {
    return this.http.get(API_URL + "/anlagen/firmenall", this.AUTH_HEADER);
  }

  public getFirmenUserGruppen(groupid: number) {
    return this.http.get(API_URL + "/anlagen/firmen/groups/" + groupid, this.AUTH_HEADER);
  }

  public setFirmenUserGruppen(editarr: any) {
    return this.http.post(API_URL + "/anlagen/firmen/groups/edit", editarr, this.AUTH_HEADER);
  }

  public getFormulare(): Observable<any> {
    return this.http.get(API_URL + "/formulare", this.AUTH_HEADER);
  }

  public getGroupsForFat(): Observable<any> {
    return this.http.get(API_URL + "/groups/fat", this.AUTH_HEADER);
  }

  public getTabellenFAT(): Observable<any> {
    return this.http.get(API_URL + "/tabellenFAT", this.AUTH_HEADER);
  }

  public getTabellen(): Observable<any> {
    return this.http.get(API_URL + "/tabellen", this.AUTH_HEADER);
  }

  public getStammdatenColumns(): Observable<any> {
    return this.http.get(API_URL + "/ostamm/columns", this.AUTH_HEADER);
  }

  public getSpezifikationsdaten(): Observable<any> {
    return this.http.get(API_URL + "/kspezv/data", this.AUTH_HEADER);
  }

  public initMangelbaum(id: string): Observable<any> {
    return this.http.get(API_URL + "/anlagen/mangelbaum/init/" + id, this.AUTH_HEADER);
  }

  public getMangelHilfe(id: number): Observable<any> {
    return this.http.get(API_URL + "/mangel/helper/" + id, this.AUTH_HEADER);
  }

  public getHerstellerdatenColumns(): Observable<any> {
    return this.http.get(API_URL + "/oherst/columns", this.AUTH_HEADER);
  }

  public getBUE(id: number): Observable<any> {
    return this.http.get(API_URL + "/anlagen/bue/" + id, this.AUTH_HEADER);
  }
  public getBUEExcelTemplate(): Observable<any> {
    return this.http.get(API_URL + "/anlagen/bue/excel", this.AUTH_HEADER);
  }
  public setBUE(id: number, editArray): Observable<any> {
    return this.http.post(API_URL + "/anlagen/bue/" + id, editArray, this.AUTH_HEADER);
  }
  public setBUEImage(id: number, editArray): Observable<any> {
    return this.http.post(API_URL + "/anlagen/bue/image/" + id, editArray, this.AUTH_HEADER);
  }


  public deleteBUE(id: number, num: number): Observable<any> {
    return this.http.delete(API_URL + "/anlagen/bue/" + id + "/" + num, this.AUTH_HEADER);
  }

  public getFieldStructures() {
    return this.http.get(API_URL + "/get-field-type", this.AUTH_HEADER);
  }

  public getFatFieldValueByID(ostammid: number, lpid: number) {
    return this.http.get(API_URL + "/anlagen/getfatfieldvalue/" + ostammid + "/" + lpid, this.AUTH_HEADER);
  }

  public getFieldType(columnName: string | null, rowName: string | null): Observable<any> {
    let params = new HttpParams().set('columnName', columnName);
    params = params.set('rowName', rowName);
    return this.http.get(API_URL + "/get-field-type", { headers: this.AUTH_HEADER, params: params });
  }

  public getVorlageNames(): Observable<any> {
    return this.http.get(API_URL + "/vorlage-names", this.AUTH_HEADER);
  }

  public getTabellenNamesFAT(vorlageID: number): Observable<any> {
    return this.http.get(API_URL + "/tabellen-names-fat/" + vorlageID, this.AUTH_HEADER);
  }
  public getTabellenNames(vorlageID: number, type: number): Observable<any> {
    return this.http.get(API_URL + "/tabellen-names/" + vorlageID + "/" + type, this.AUTH_HEADER);
  }
  public getFormDataForVorlage(vorlageID: number): Observable<any> {
    return this.http.get(API_URL + "/form-data/" + vorlageID, this.AUTH_HEADER);
  }

  public getTabellenDataBySZAEHNID(szaehnID: number): Observable<any> {
    return this.http.get(API_URL + "/tabellen-data/" + szaehnID, this.AUTH_HEADER);
  }

  public getTabellenDataFATBySZAEHNID(szaehnID: number): Observable<any> {
    return this.http.get(API_URL + "/tabellen-data-FAT/" + szaehnID, this.AUTH_HEADER);
  }

  public getFilteredTablesAndTitlesForAig(): Observable<any> {
    return this.http.get(API_URL + "/filtered-tables-and-titles-for-aig", this.AUTH_HEADER);
  }

  public getFilteredTablesAndTitlesForPI(): Observable<any> {
    return this.http.get(API_URL + "/filtered-tables-and-titles-for-pi", this.AUTH_HEADER);
  }

  public getCheckboxState(tabelleID: number): Observable<any> {

    return undefined
    //return this.http.get(API_URL + "/checkbox/" + tabelleID, this.AUTH_HEADER);
  }

  public getTabelleIDForVorlage(vorlageID: number): Observable<any> {
    return this.http.get(API_URL + "/tabellen/gettabelleIDforvorlage/" + vorlageID, this.AUTH_HEADER);
  }


  public getZaehlerEinheiten(): Observable<any> {
    return this.http.get(API_URL + "/tabellen/GetEinheiten", this.AUTH_HEADER);
  }
  public getZaehlerObjekttypen(): Observable<any> {
    return this.http.get(API_URL + "/tabellen/GetObjekttypen", this.AUTH_HEADER);
  }

  public getAllZaehlerForObject(ostammid: number): Observable<any> {
    return this.http.get(API_URL + "/anlagen/getallzaehlerforobject/" + ostammid, this.AUTH_HEADER);
  }

  public getGroupObjects(groupid: number) {
    return this.http.get(
      API_URL + "/groups/objects/" + groupid,
      this.AUTH_HEADER
    );
  }

  public getAuftragGroupObjects(groupid: number) {
    return this.http.get(
      API_URL + "/auftraggroups/objects/" + groupid,
      this.AUTH_HEADER
    );
  }

  public setAuftragGroupObjects(groupDiff: any) {
    return this.http.post(
      API_URL + "/auftraggroups/objects/",
      groupDiff,
      this.AUTH_HEADER
    );
  }

  public deleteAuftragGroup(groupid: number) {
    return this.http.post(
      API_URL + "/auftraggroups/delete",
      groupid,
      this.AUTH_HEADER
    );
  }

  public getUserFromGroup(groupid: number) {
    return this.http.get(API_URL + "/groups/" + groupid, this.AUTH_HEADER);
  }

  public getUserFromAuftragGroup(groupid: number) {
    return this.http.get(
      API_URL + "/auftraggroups/" + groupid,
      this.AUTH_HEADER
    );
  }

  public getPWini(): Observable<any> {
    return this.http.get(API_URL + "/users/pwini", this.AUTH_HEADER);
  }

  public getGroups() {
    return this.http.get(API_URL + "/groups", this.AUTH_HEADER);
  }

  public getMessTol() {
    return this.http.get(API_URL + "/anlagen/toleranzen", this.AUTH_HEADER);
  }

  public getChecklistenItems(items: any) {
    return this.http.post(API_URL + "/anlagen/getchecklistenitems", items, this.AUTH_HEADER);
  }

  public getBaseDataItems(items: any) {
    return this.http.post(API_URL + "/anlagen/getbaseitems", items, this.AUTH_HEADER);
  }

  public getMessungTol(items: any) {
    return this.http.post(API_URL + "/anlagen/getmessungtol", items, this.AUTH_HEADER);
  }

  public getMessungAuswertung(items: any) {
    return this.isRep ? from(db.getMessungAuswertung(items)) : this.http.post(API_URL + "/anlagen/getmessungauswertung", items, this.AUTH_HEADER);
  }

  public getSOTYP(): Observable<SOTYP_Map> {
    return this.http.get<SOTYP_Map>(
      API_URL + "/anlagen/sotyp",
      { headers: this.AUTH_HEADER.headers }
    );
  }

  public getSOTYPAll() {
    return this.http.get(API_URL + "/anlagen/sotypall", this.AUTH_HEADER);
  }

  public getPruefungsVerwaltung() {
    return this.http.get(API_URL + "/anlagen/getpruefungsverwaltung", this.AUTH_HEADER);
  }

  public setPruefungsVerwaltung(items: any) {
    return this.http.post(API_URL + "/anlagen/setpruefungsverwaltung", items, this.AUTH_HEADER);
  }


  public getVorlagenFiles(id: number) {
    return this.http.get(API_URL + "/anlagen/getpaintvorlagen/" + id, this.AUTH_HEADER);
  }

  public getObjektPruefungTypen() {
    return this.http.get(API_URL + "/anlagen/objektpruefungtypen", this.AUTH_HEADER);
  }

  public getCurrentMessung(ostammid: number, opruefid: number): Observable<any> {
    return this.http.get(
      API_URL + `/anlagen/messung/${ostammid}/${opruefid}`, this.AUTH_HEADER
    );
  }

  public getKomponenten(id: number) {
    return this.http.get(API_URL + "/anlagen/komponenten/" + id, this.AUTH_HEADER);
  }

  public getObjectKomponenten(stammid: string) {
    return this.http.get(API_URL + "/anlagen/komponenten/object/" + stammid, this.AUTH_HEADER);
  }

  public setObjectKomponenten(komp: any) {
    return this.http.post(API_URL + "/anlagen/setkomponenten", komp, this.AUTH_HEADER);
  }

  public setPaintItems(items: any, option: string) {

    if (option == '') {
      return this.http.post(API_URL + "/anlagen/setpaintitems", items, this.AUTH_HEADER);
    }
    else {

      let customheader: any = {
        headers: new HttpHeaders({
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        }),
        responseType: "blob",
      };
      return this.http.post(API_URL + "/anlagen/getpaintpdf", { items: items, language: localStorage.getItem("language") }, customheader);
    }

  }

  public getPaintItemList(stammid: string) {
    return this.http.get(API_URL + "/anlagen/getpaintitemslist/" + stammid, this.AUTH_HEADER);
  }

  public getPaintItem(stammid: string, id: string) {
    return this.http.get(API_URL + "/anlagen/getpaintitemslist/" + stammid + "/" + id, this.AUTH_HEADER);
  }

  public setSonderinspektion(items: any) {
    return this.http.post(API_URL + "/maengel/setsonderinspektion", items, this.AUTH_HEADER);
  }

  public getSonderinspektion(stammid: string) {
    return this.http.get(API_URL + "/maengel/getsonderinspektion/" + stammid, this.AUTH_HEADER);
  }

  public getSonderinspektionListe() {
    return this.http.get(API_URL + "/maengel/getsonderinspektionliste", this.AUTH_HEADER);
  }

  public getObjectKomponentenChildren(id: any) {
    return this.http.get(API_URL + "/anlagen/komponentenchildren/" + id, this.AUTH_HEADER);
  }

  public getKspezErneuerung(id: number) {
    return this.http.get(API_URL + "/anlagen/getkspezerneuerung/" + id, this.AUTH_HEADER);
  }

  public getKSpezvAll() {
    return this.http.get(API_URL + "/anlagen/getkspevall", this.AUTH_HEADER);
  }

  public getSysObjectOptions() {
    return this.http.get(API_URL + "/anlagen/sysoopt", this.AUTH_HEADER);
  }

  public getSysOptionsForObject(otypid: number) {
    return this.http.get(API_URL + `/anlagen/sysoopt/${otypid}`, this.AUTH_HEADER);
  }

  public getAuftragsGroups() {
    return this.http.get(API_URL + "/auftraggroups", this.AUTH_HEADER);
  }
  public getSettingsKav() {
    return this.http.get(API_URL + "/settings/kav", this.AUTH_HEADER);
  }

  public getSettingsApiKeys() {
    return this.http.get(API_URL + "/settings/apikeys", this.AUTH_HEADER);
  }

  public getSettingsKunde() {
    return this.http.get(API_URL + "/settings/kunde", this.AUTH_HEADER);
  }

  public setAnlagenALVStatus(status: any) {
    return this.http.post(
      API_URL + "/anlagen/setalv",
      status,
      this.AUTH_HEADER
    );
  }

  public setSettingsBezeichnung(bez: any) {
    return this.http.post(
      API_URL + "/anlagen/settings/kundenbezeichnung",
      bez,
      this.AUTH_HEADER
    );
  }

  public setSettingsApiKeys(apiKeys: any) {
    return this.http.post(
      API_URL + "/settings/apikeys",
      apiKeys,
      this.AUTH_HEADER
    );
  }

  public setGroupObjects(groubObjects: any) {
    return this.http.post(
      API_URL + "/groups/objects",
      groubObjects,
      this.AUTH_HEADER
    );
  }

  public setGroups(group: any) {
    return this.http.post(API_URL + "/groups/edit", group, this.AUTH_HEADER);
  }

  public setAuftragGroups(group: any) {
    return this.http.post(
      API_URL + "/auftraggroups/edit",
      group,
      this.AUTH_HEADER
    );
  }

  public getUser(userid: number) {
    return this.http.get(API_URL + "/users/" + userid, this.AUTH_HEADER);
  }

  public getUsers() {
    return this.http.get(API_URL + "/users", this.AUTH_HEADER);
  }

  public getBerichte() {
    return this.http.get(API_URL + "/berichte", this.AUTH_HEADER);
  }
  public setBerichte(berichte: any) {
    return this.http.post(
      API_URL + "/berichte",
      berichte,
      this.AUTH_HEADER
    );
  }

  public getAnlagen() {
    return this.isRep ? from(db.getAnlagen()) : this.http.get(API_URL + "/anlagen", this.AUTH_HEADER);
  }

  public getKostenAuftrag(auftragId: number) {
    return this.http.get(
      API_URL + "/anlagen/kosten/auftrag/" + auftragId,
      this.AUTH_HEADER
    );
  }

  public setKostenAuftrag(typ: string, newKosten: any) {
    return this.http.post(
      API_URL + "/anlagen/kosten/auftrag/" + typ,
      newKosten,
      this.AUTH_HEADER
    );
  }

  public getKostenTaetigkeiten() {
    return this.http.get(
      API_URL + "/anlagen/kosten/taetigkeiten",
      this.AUTH_HEADER
    );
  }

  public getKostenPersonal() {
    return this.http.get(
      API_URL + "/anlagen/kosten/personal",
      this.AUTH_HEADER
    );
  }

  public updateKostenPersonal(id: number, kostenPersonal: any) {
    return this.http.post(
      API_URL + "/anlagen/kosten/personal/" + id,
      kostenPersonal,
      this.AUTH_HEADER
    );
  }

  public getKostenMaterial() {
    return this.http.get(
      API_URL + "/anlagen/kosten/material",
      this.AUTH_HEADER
    );
  }

  public updateKostenMaterial(id: number, kostenPersonal: any) {
    return this.http.post(
      API_URL + "/anlagen/kosten/material/" + id,
      kostenPersonal,
      this.AUTH_HEADER
    );
  }

  public getKostenMaschinen() {
    return this.http.get(
      API_URL + "/anlagen/kosten/maschinen",
      this.AUTH_HEADER
    );
  }

  public updateKostenMaschinen(id: number, kostenPersonal: any) {
    return this.http.post(
      API_URL + "/anlagen/kosten/maschinen/" + id,
      kostenPersonal,
      this.AUTH_HEADER
    );
  }

  public getKostenFremdleistung() {
    return this.http.get(
      API_URL + "/anlagen/kosten/fremdleistungen",
      this.AUTH_HEADER
    );
  }

  public getKostenFremdleistungEinheiten() {
    return this.http.get(
      API_URL + "/anlagen/kosten/fremdleistungen/einheiten",
      this.AUTH_HEADER
    );
  }

  public insertKostenFremdleistungTitel(titel: string) {
    return this.http.post(
      API_URL + "/anlagen/kosten/fremdleistungen/titel",
      { Kurztext: titel },
      this.AUTH_HEADER
    );
  }

  public updateKostenFremdleistungTitel(id: number, titel: string) {
    return this.http.put(
      API_URL + "/anlagen/kosten/fremdleistungen/titel/" + id,
      { Kurztext: titel },
      this.AUTH_HEADER
    );
  }

  public insertKostenFremdleistung(kostenFremdleistung: FremdleistungInput) {
    return this.http.post(
      API_URL + "/anlagen/kosten/fremdleistungen/",
      kostenFremdleistung,
      this.AUTH_HEADER
    );
  }

  public updateKostenFremdleistung(
    id: number,
    kostenFremdleistung: FremdleistungUpdateInput
  ) {
    return this.http.put(
      API_URL + "/anlagen/kosten/fremdleistungen/" + id,
      kostenFremdleistung,
      this.AUTH_HEADER
    );
  }

  public deleteKostenFremdleistung(id: number, type: string) {
    return this.http.delete(
      API_URL + "/anlagen/kosten/" + type + "/" + id,
      this.AUTH_HEADER
    );
  }

  public getAuftraege() {
    return this.http.get(API_URL + "/auftraege", this.AUTH_HEADER_ARR);
  }

  public getAuftraegeMaengelAll() {
    return this.http.get(API_URL + "/auftraege/mangel/all", this.AUTH_HEADER_ARR);
  }

  public getAuftraegeObjekteAll() {
    return this.http.get(API_URL + "/auftraege/objekte/all", this.AUTH_HEADER_ARR);
  }


  public getAuftraegeListe() {
    return this.http.get(API_URL + "/auftraege/liste", this.AUTH_HEADER);
  }
  public getAuftrag(kauftrid: number) {
    return this.http.get(API_URL + "/auftraege/" + kauftrid, this.AUTH_HEADER);
  }
  public getAuftragPDF(kauftrid: number) {
    let language = localStorage.getItem("language");
    return this.http.get(API_URL + '/auftraege/setPDF/' + kauftrid + '/' + language, this.AUTH_HEADER_BIN);
  }

  public getAuftraegeObjekte(kauftrid: number) {
    return this.http.get(
      API_URL + "/auftraege/objekte/" + kauftrid,
      this.AUTH_HEADER
    );
  }

  public getAuftraegeInhalt(kauftsid: number) {
    return this.http.get(
      API_URL + "/auftraege/inhalt/" + kauftsid,
      this.AUTH_HEADER
    );
  }

  public getCoorinates(id: number) {
    return this.http.get(
      API_URL + "/anlagen/getCoordinate/" + id,
      this.AUTH_HEADER
    );
  }

  public getPruefid(id: number) {
    return this.http.get(
      API_URL + "/anlagen/getPruefid/" + id,
      this.AUTH_HEADER
    );
  }

  public getMessungen(pruefid: string, id: number) {
    return this.http.get(
      API_URL + "/anlagen/messungen/" + id + "/" + pruefid,
      this.AUTH_HEADER
    );
  }

  public getMessungGeo(id: number) {
    return this.http.get(
      API_URL + "/anlagen/geo/" + id,
      this.AUTH_HEADER
    );
  }

  public getMessungLaengswelligkeit(id: number) {
    return this.http.get(
      API_URL + "/anlagen/messungenlaengswelligkeit/" + id,
      this.AUTH_HEADER
    );
  }

  public getStellkraftMessungen(ostammid: number) {
    return this.http.get(
      API_URL + "/anlagen/stellkraftmessung/" + ostammid,
      this.AUTH_HEADER
    );
  }

  public setStellkraftMessung(body: any) {
    return this.http.post(API_URL + "/anlagen/stellkraftmessung/", body, this.AUTH_HEADER);
  }

  public setMangelbaum(body: any) {
    return this.isRep ? from(db.updateMangelbaum(body)) : this.http.post(API_URL + "/anlagen/mangelbaum/update", body, this.AUTH_HEADER);
  }

  public setMangelsHelper(id: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/mangel/helper/${id}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public setUpdatePruefung(body: any) {
    return this.isRep ? from(db.setUpdatePruefung(body)) : this.http.post(API_URL + "/maengel/updatepruefung", body, this.AUTH_HEADER);
  }

  public setNewPruefung(body: any): Observable<any> {
    return this.isRep ? from(db.SetMangelPruefung(body.ostammid, body.oldpruefid, body.otypid, body.typeid)) : this.http.post(API_URL + "/maengel/newpruefung", body, this.AUTH_HEADER);
  }

  public getMaengelFromInspektion(otypid: number, opruefid: number): Observable<any> {
    return this.isRep ? from(db.GetMaengelFromPrüfung(otypid, opruefid)) : this.http.get(
      API_URL + `/maengel/inspektion/${otypid}/${opruefid}`,
      this.AUTH_HEADER_NO_CACHE
    );
  }

  public getAnlagenHistorie(id: number): Observable<any> {
    return this.http.get(API_URL + "/anlagen/historie/" + id, this.AUTH_HEADER_NO_CACHE);
  }
  public sendAnlagenMessage(id: number, type: string) {
    return this.http.get(API_URL + "/anlagen/SendAnlagenMessage/" + id + "/" + type, this.AUTH_HEADER_NO_CACHE);
  }

  public getAnlage(id: number) {
    return this.isRep ? from(db.getAnlageByID(id)) : this.http.get(API_URL + "/anlagen/" + id + "/" + localStorage.getItem("repID"), this.AUTH_HEADER_NO_CACHE);
  }

  public getStatistiken() {
    return this.http.get(API_URL + "/statistiken", this.AUTH_HEADER);
  }

  public getStatistik(body: any) {
    return this.http.post(API_URL + "/statistik", body, this.AUTH_HEADER);
  }

  public getHistoryItem(id: number, historyKey: any, searchVal: any): Observable<any> {
    return this.http.post(
      API_URL + "/anlagen/history/" + id,
      { historyKey, searchVal },
      this.AUTH_HEADER
    );
  }

  public getAnlagenAuftrag(stammid: number, typ: number) {
    return this.http.get(
      API_URL + "/anlagen/auftrag/" + stammid + "/" + typ,
      this.AUTH_HEADER
    );
  }

  public getAnlageProfilCount(id: number) {
    return this.http.get(API_URL + '/anlagen/profile/count/' + id, this.AUTH_HEADER);
  }
  public setAnlageProfilRecalc(profil: any) {
    return this.http.post(API_URL + '/anlagen/profile/recalc/', profil, this.AUTH_HEADER);
  }
  public getAnlageProfil(id: number, iteration: number) {
    return this.http.get(API_URL + '/anlagen/profile/' + id + "/" + iteration, this.AUTH_HEADER);
  }

  public setAnlageProfil(profile: any) {
    return this.http.post(API_URL + '/anlagen/profile/', profile, this.AUTH_HEADER);
  }

  public getKarteFilteredObjects(filter: any) {
    return this.http.post(API_URL + "/karte/filter/", filter, this.AUTH_HEADER);
  }

  public getKarteFilterVal(category: string) {
    return this.http.get(
      API_URL + "/karte/filterDropdown/" + category,
      this.AUTH_HEADER
    );
  }

  public getStoerung(ostammid: number) {
    return this.http.get(
      API_URL + "/anlagen/stoerung/" + ostammid,
      this.AUTH_HEADER
    );
  }

  public getStoerungMassnahmen(omazsid: number) {
    return this.http.get(
      API_URL + "/anlagen/stoerung/massnahmen/" + omazsid,
      this.AUTH_HEADER
    );
  }

  public getStoerungMangel(pruefartid: number) {
    return this.http.get(
      API_URL + "/anlagen/stoerung/mangel/" + pruefartid,
      this.AUTH_HEADER
    );
  }
  public getSperrung(ostammid: number) {
    return this.http.get(
      API_URL + "/anlagen/sperrung/" + ostammid,
      this.AUTH_HEADER
    );
  }

  public getLastelle(ostammid: number) {
    return this.http.get(
      API_URL + "/anlagen/langsamfahrstelle/" + ostammid,
      this.AUTH_HEADER
    );
  }

  public sendKAVsettings(kav: any) {
    return this.http.post(API_URL + "/settings/kav", kav, this.AUTH_HEADER);
  }

  public sendMessTol(mess: any) {
    return this.http.post(API_URL + "/anlagen/toleranzen", mess, this.AUTH_HEADER);
  }

  public sendSysObjectOptions(sysoopt: any) {
    return this.http.post(API_URL + "/anlagen/sysoopt", sysoopt, this.AUTH_HEADER);
  }

  public sendINIsettings(ini: any) {
    return this.http.post(API_URL + "/settings/ini", ini, this.AUTH_HEADER);
  }

  public sendKLINIEN(linie: any) {
    return this.http.post(API_URL + "/anlagen/klinie", linie, this.AUTH_HEADER);
  }

  public sendSBFEK(codes: any) {
    return this.http.post(API_URL + "/anlagen/sbfek", codes, this.AUTH_HEADER);
  }

  public sendOSTEDT(trass: any) {
    return this.http.post(API_URL + "/anlagen/ostedt", trass, this.AUTH_HEADER);
  }

  public sendKSPEZV(kspezv: any) {
    return this.http.post(API_URL + "/anlagen/kspezv", kspezv, this.AUTH_HEADER);
  }

  public sendSOTYP(sotyp: any) {
    return this.http.post(API_URL + "/anlagen/sotyp", sotyp, this.AUTH_HEADER);
  }

  public sendObjektKAV(ostammid: number, kav: boolean) {
    return this.http.post(
      API_URL + "/anlagen/kav/" + ostammid,
      { kav: kav },
      this.AUTH_HEADER
    );
  }

  public sendLastelle(ostammid: number, lastelle: any) {
    return this.http.post(
      API_URL + "/anlagen/langsamfahrstelle/" + ostammid,
      lastelle,
      this.AUTH_HEADER
    );
  }
  public sendAbnahme(items: any) {
    return this.http.post(
      API_URL + "/auftrag/abnahme",
      items,
      this.AUTH_HEADER
    );
  }
  public getAbnahme(id: any) {
    return this.http.get(
      API_URL + "/auftrag/abnahme/" + id,
      this.AUTH_HEADER
    );
  }
  public getAbnahmeLargeFile(id: any) {
    return this.http.get(
      API_URL + "/auftrag/abnahme/file/" + id,
      this.AUTH_HEADER
    );
  }

  public sendStoerung(ostammid: number, stoerung: any) {
    return this.http.post(
      API_URL + "/anlagen/stoerung/" + ostammid,
      stoerung,
      this.AUTH_HEADER
    );
  }

  public sendSperrung(ostammid: number, sperrung: any) {
    return this.http.post(
      API_URL + "/anlagen/sperrung/" + ostammid,
      sperrung,
      this.AUTH_HEADER
    );
  }

  public deleteAuftrag(aufragId: number) {
    return this.http.delete(
      API_URL + "/auftrag/delete/" + aufragId,
      this.AUTH_HEADER
    );
  }
  public deleteMangel(omazsid: number) {
    return this.http.delete(
      API_URL + "/mangel/delete/" + omazsid,
      this.AUTH_HEADER
    );
  }

  public sendAuftrag(auftrag: any) {
    let customheader: any = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      }),
      responseType: "blob",
    };
    return this.http.post(
      API_URL + "/auftrag/add",
      auftrag,
      customheader
    );
  }

  public updateAuftrag(kauftrid: number, auftrag: UpdateAuftrag) {
    return this.http.post(
      API_URL + "/auftraege/" + String(kauftrid),
      auftrag,
      this.AUTH_HEADER
    );
  }

  public deleteMangelAuftrag(maengelid: number) {
    return this.http.post(
      API_URL + "/auftrag/deletemangelauftrag",
      maengelid,
      this.AUTH_HEADER
    );
  }
  public sendNewMessdaten(formData: any) {
    return this.http.post(
      API_URL + "/anlagen/add/messdatenfiles",
      formData,
      this.AUTH_HEADER
    );
  }

  public updateOMAZS(OMAZS: any, omazsid: number): Observable<any> {
    return this.isRep ? from(db.UpdateMangel(omazsid, OMAZS.status, OMAZS)) : this.http.post(
      API_URL + "/maengel/updateOMAZS/" + omazsid,
      OMAZS,
      this.AUTH_HEADER
    );
  }



  public sendNewMangel(otypid: number, typ_Line_Point: number, formData: any): Observable<any> {
    return this.http.post(
      API_URL + `/maengel/newMangel/${otypid}/${typ_Line_Point}`,
      formData,
      this.AUTH_HEADER
    );
  }
  public saveMangelBemerkung(req: any, id: number) {
    return this.http.post(
      API_URL + "/maengel/bemerkung/" + id,
      req,
      this.AUTH_HEADER
    );
  }

  public getMaengelEinheiten(): Observable<any> {
    return this.http.get(API_URL + '/maengel/mengeneinheiten', this.AUTH_HEADER);
  }

  public sendMultiRueckmeldung(maengel: any) {
    return this.http.post(
      API_URL + "/maengel/multirueckmeldung",
      maengel,
      this.AUTH_HEADER
    );
  }

  public sendRueckmeldungMangel(deltamangel: any) {
    return this.http.post(
      API_URL + "/anlagen/rueckmeldung/mangel",
      deltamangel,
      this.AUTH_HEADER
    );
  }

  public sendRueckmeldungMessung(deltamessung: any) {
    return this.http.post(
      API_URL + "/anlagen/rueckmeldung/messung",
      deltamessung,
      this.AUTH_HEADER
    );
  }

  public deleteFirma(id: number) {
    return this.http.delete(
      API_URL + "/anlagen/firmen/delete/" + id,
      this.AUTH_HEADER
    );
  }

  public deleteFormularName(id: number) {
    return this.http.delete(API_URL + "/formularname/delete/" + id, this.AUTH_HEADER);
  }

  public deleteFormular(id: number) {
    return this.http.delete(API_URL + "/formulare/delete/" + id, this.AUTH_HEADER);
  }

  public deleteTabelleName(id: number) {
    return this.http.delete(API_URL + "/tabelle/delete/" + id, this.AUTH_HEADER);
  }

  public deleteTabelle(id: number) {
    return this.http.delete(API_URL + "/tabellen/delete/" + id, this.AUTH_HEADER);
  }

  public deleteUser(id: number) {
    return this.http.delete(API_URL + "/users/delete/" + id, this.AUTH_HEADER);
  }

  public getmaxUser() {
    return this.http.get(API_URL + "/users/getmaxuser", this.AUTH_HEADER);
  }

  public deleteGroup(id: number) {
    return this.http.delete(API_URL + "/groups/delete/" + id, this.AUTH_HEADER);
  }

  public editFirmen(editarr: any) {
    return this.http.post(
      API_URL + "/anlagen/firmen/edit",
      editarr,
      this.AUTH_HEADER
    );
  }

  public editFormulare(editarr: any, vorlageID: number, tabelleIDUpdates: number[]): Observable<any> {
    const payload = { c: editarr, vorlageID, tabelleIDUpdates };
    return this.http.post(API_URL + '/formulare/edit', payload, this.AUTH_HEADER);
  }

  public addAnlageByFAT(data: any, vorlageID: any, gruppeid: number, ostammid: number, tabelleIDUpdates: any): Observable<any> {
    const payload = { data, vorlageID, gruppeid, ostammid, tabelleIDUpdates };
    return this.http.post(API_URL + "/anlagen/add/fat", payload, this.AUTH_HEADER);
  }

  public addPruefungFAT(stamm: any, mess: any, bilder: any, info: any, ostammid: number, opruefid: number, vorlageid: any): Observable<any> {
    const payload = { stamm, mess, bilder, info, ostammid, opruefid, vorlageid };
    return this.http.post(API_URL + "/anlagen/add/fatpruefung", payload, this.AUTH_HEADER);
  }

  public addAnlageByFormular(data: any): Observable<any> {
    return this.http.post(API_URL + "/anlagen/add/form", data, this.AUTH_HEADER);
  }

  public editAnlagenDokumentKategorie(data: any): Observable<any> {
    return this.http.post(API_URL + "/anlagen/document/category", data, this.AUTH_HEADER);
  }

  public addAnlageDocument(data: any): Observable<any> {
    return this.http.post(API_URL + "/anlagen/documents", data, this.AUTH_HEADER);
  }

  public editSortTabellen(editarr: any) {
    return this.http.post(API_URL + "/formulare/edit", editarr, this.AUTH_HEADER);
  }

  public editTabellen(editarr: any) {
    return this.http.post(API_URL + "/tabellen/edit", editarr, this.AUTH_HEADER);
  }

  public editTabellenFAT(editarr: any) {
    return this.http.post(API_URL + "/tabellen/editFAT", editarr, this.AUTH_HEADER);
  }

  public setZaehlerImage(data: any): Observable<any> {
    return this.http.post(API_URL + "/tabellen/Image", data, this.AUTH_HEADER);
  }

  public updateUser(editarr: any) {
    return this.http.post(API_URL + "/users/update", editarr, this.AUTH_HEADER);
  }

  public editUser(editarr: any) {
    return this.http.post(API_URL + "/users/edit", editarr, this.AUTH_HEADER);
  }

  public setPWini(items: any) {
    return this.http.post(API_URL + "/users/setpwini", items, this.AUTH_HEADER);
  }


  //#endregion
  //#region Anlage Basisdaten
  //#region stammdaten
  public getStammDaten(ostammid: number, otypid: number, typ_Line_Point: number, opruefid: number): Observable<any> {
    return this.http.get(API_URL + `/stamm/${ostammid}/${otypid}/${typ_Line_Point}/${opruefid}`, this.AUTH_HEADER);
  }

  public deletepruefungStammDaten(sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/stamm/deletepruefung`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public archivStammDaten(sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/stamm/archiv`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public deleteStammDaten(sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/stamm/delete`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public copyStammDaten(ostammid: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/stamm/copy/${ostammid}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public setStammDaten(ostammid: number, otypid: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/stamm/${ostammid}/${otypid}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public getStammSkizze(filename: string): Observable<any> {
    return this.http.get(API_URL + `/stamm/skizze/${filename}`, this.AUTH_HEADER);
  }

  public setStammSkizze(ostammid: number, filename: string, base64: string): Observable<any> {
    return this.http.post(
      API_URL + `/stamm/skizze/${ostammid}/${filename}`,
      { base64 },
      this.AUTH_HEADER
    );
  }

  public getPassBilder(bildNames: string): Observable<any> {
    return this.http.get(API_URL + `/stamm/passbilder/${bildNames}`, this.AUTH_HEADER);
  }
  //#endregion
  //#region spezifikation
  public getSpezTemplates(): Observable<any> {
    return this.http.get(API_URL + "/spez/templates", this.AUTH_HEADER);
  }

  public getSpez(ostammid: number, otypid: number, typid: number): Observable<any> {
    return this.http.get(
      API_URL + "/spez/" + ostammid + "/" + otypid + "/" + typid,
      this.AUTH_HEADER
    );
  }

  public setSpez(id: number, editArray): Observable<any> {
    return this.http.post(API_URL + "/spez/" + id, editArray, this.AUTH_HEADER);
  }

  public deleteSpez(id: number): Observable<any> {
    return this.http.delete(API_URL + "/spez/" + id, this.AUTH_HEADER);
  }
  //#endregion
  //#region herstelldaten
  public getHerstelldaten(ospezid: number): Observable<any> {
    return this.http.get(API_URL + `/hersteller/${ospezid}`, this.AUTH_HEADER);
  }

  public setHerstelldaten(ospezid: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/hersteller/${ospezid}`,
      sendObj,
      this.AUTH_HEADER
    );
  }
  //#endregion
  //#region bauwerke
  public getBauwerksArten(otypid: number): Observable<any> {
    return this.isRep ? from(db.getBauwArten(otypid)) : this.http.get(API_URL + "/bauwerke/arten/" + otypid, this.AUTH_HEADER);
  }

  public getBauw(ostammid: number): Observable<any> {
    return this.isRep ? from(db.getBauwerke(ostammid)) : this.http.get(API_URL + "/bauwerke/" + ostammid, this.AUTH_HEADER);
  }

  public setBauw(id: number, editArray): Observable<any> {
    return this.isRep ? from(db.SetBauwerke(editArray, id)) : this.http.post(API_URL + "/bauwerke/" + id, editArray, this.AUTH_HEADER);
  }

  public deleteBauw(id: number): Observable<any> {
    return this.isRep ? from(db.DeleteObject(id, "obauw")) : this.http.delete(API_URL + "/bauwerke/" + id, this.AUTH_HEADER);
  }
  //#endregion
  //#endregion

  //#region Begehung
  //#region verwaltung
  public getBegehungItem(): Observable<any> {
    return this.http.get(API_URL + `/begehung/getsetting`, this.AUTH_HEADER);
  }

  public setBegehungItem(sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/begehung/setsetting`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  //#endregion


  //#region plan
  public getBegehungsplan(): Observable<any> {
    return this.isRep ? from(db.getBegehungsplan()) :
      this.http.get(API_URL + `/begehung/begehungsplan`, this.AUTH_HEADER);
  }

  public getBegehungsplanHistory(obegeid: number): Observable<any> {
    return this.isRep ? from(db.getBegehungsplanHistory(obegeid)) : this.http.get(API_URL + `/begehung/begehungsplan/history/${obegeid}`, this.AUTH_HEADER);
  }
  //#endregion

  //#region kalender
  public getBegehungsCalendarList(): Observable<any> {
    return this.http.get(API_URL + `/begehung/calendar`, this.AUTH_HEADER);
  }
  //#endregion

  //#region begehung
  public setBegehung(sendObj: any): Observable<any> {
    return this.isRep ? from(db.setBegehung(sendObj)) :
      this.http.post(
        API_URL + `/begehung/setbegehung`,
        sendObj,
        this.AUTH_HEADER
      );
  }
  //#endregion

  //#endregion


  //#region Zähler
  //#region verwaltung
  public getZaehlerNames(otypid: number, sourceTyp: 1 | 2): Observable<any> {
    return this.http.get(API_URL + `/zaehler/names/${otypid}/${sourceTyp}`, this.AUTH_HEADER);
  }

  public setZaehlerName(otypid: number, sourceTyp: 1 | 2, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/name/${otypid}/${sourceTyp}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public copyZaehlerName(id: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/name/copy/${id}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public copyZaehlerWert(szaehnid: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/wert/copy/${szaehnid}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public setZaehlerBild(id: number, bild: ZählerSkizze): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/name/bild/${id}`,
      bild,
      this.AUTH_HEADER
    );
  }

  public getZaehlerWerte(szaehnid: number): Observable<any> {
    return this.http.get(API_URL + `/zaehler/werte/${szaehnid}`, this.AUTH_HEADER);
  }

  public setZaehlerWert(szaehnid: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/wert/${szaehnid}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public setZaehlerSorting(sendObj: Array<{ ID: number, I_SORT: number }>): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/wert/sort`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public deleteZahlerItem(mode: 'name' | 'wert' | 'meh', id: number): Observable<any> {
    return this.http.delete(API_URL + `/zaehler/delstatus/zaehler-${mode}/${id}`, this.AUTH_HEADER);
  }

  public setZaehlerHelper(id: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/helper/${id}`,
      sendObj,
      this.AUTH_HEADER
    );
  }

  public getZaehlerWerteEinheiten(otypid: number): Observable<any> {
    return this.http.get(API_URL + `/zaehler/einheiten/${otypid}`, this.AUTH_HEADER);
  }

  public setZaehlerWerteEinheit(otypid: number, sendObj: any): Observable<any> {
    return this.http.post(
      API_URL + `/zaehler/einheit/${otypid}`,
      sendObj,
      this.AUTH_HEADER
    );
  }
  //#endregion
  //#endregion

  //#region Wartung
  //#region verwaltung
  public getWartungsItem(mode: WartungsItemMode, otypid: number): Observable<any> {
    return this.isRep ? from(db.GetWartungsItem(mode, otypid)) : this.http.get(API_URL + `/wartung/settings/${mode}/${otypid}`, this.AUTH_HEADER);
  }

  public setWartungsItem(mode: WartungsItemMode, otypid: number, sendObj: any): Observable<any> {
    return this.isRep ? from(db.SetWartungsItem(mode, otypid, sendObj)) : this.http.post(API_URL + `/wartung/settings/${mode}/${otypid}`, sendObj, this.AUTH_HEADER);
  }

  public setWartungsHelper(id: number, sendObj: any): Observable<any> {
    return this.isRep ? from(db.SetWartungsHelper(id, sendObj)) : this.http.post(API_URL + `/wartung/helper/${id}`, sendObj, this.AUTH_HEADER);
  }

  public deleteWartungsItem(mode: WartungsItemMode, id: number): Observable<any> {
    return this.isRep ? from(db.DeleteWartungsItem(mode, id)) :
      this.http.delete(API_URL + `/wartung/settings/${mode}/${id}`, this.AUTH_HEADER);
  }

  public getWartungsbaum(otypid: number): Observable<any> {
    return this.isRep ? from(db.GetWartungsBaum(otypid)) :
      this.http.get(API_URL + `/wartung/baum/${otypid}`, this.AUTH_HEADER);
  }

  public setWartungsbaumNode(otypid: number, sendObj: any): Observable<any> {
    return this.isRep ? from(db.SetWartungsbaumNode(otypid, sendObj)) :
      this.http.post(API_URL + `/wartung/baum/${otypid}`, sendObj, this.AUTH_HEADER);
  }

  public getWartungsMaskeList(id: number, otypid: number): Observable<any> {
    return this.isRep ? from(db.GetWartungsMaskeList(id, otypid)) :
      this.http.get(API_URL + `/wartung/masken/${id}/${otypid}`, this.AUTH_HEADER);
  }

  public setWartungsMaskeList(id: number, otypid: number, sendObj: any): Observable<any> {
    return this.isRep ? from(db.SetWartungsMaskeList(id, otypid, sendObj)) : this.http.post(
      API_URL + `/wartung/masken/${id}/${otypid}`, sendObj, this.AUTH_HEADER);
  }

  public deleteWartungsMaske(id: number): Observable<any> {
    return this.isRep ? from(db.DeleteWartungsMaske(id)) :
      this.http.delete(API_URL + `/wartung/masken/${id}`, this.AUTH_HEADER);
  }

  public getWartungsObjekte(otypid: number): Observable<any> {
    return this.isRep ? from(db.GetObjekteNachOtypid(otypid)) :
      this.http.get(API_URL + `/wartung/objekte/${otypid}`, this.AUTH_HEADER);
  }

  public getWartungsZuordnungen(ostammid: number): Observable<any> {
    return this.isRep ? from(db.GetWartungsZuordnungen(ostammid)) :
      this.http.get(API_URL + `/wartung/objekte-masken/${ostammid}`, this.AUTH_HEADER);
  }

  public setWartungsZuordnungen(sendObj: any): Observable<any> {
    return this.isRep ? from(db.SetWartungsZuordnung(sendObj)) :
      this.http.post(API_URL + `/wartung/objekte-masken`, sendObj, this.AUTH_HEADER);
  }
  //#endregion
  //#region übersicht
  public getWartungsCalendarList(): Observable<any> {
    return this.http.get(API_URL + `/wartung/calendar`, this.AUTH_HEADER); //UNUSED
  }

  public getWartungsBemerkungenList(): Observable<any> {
    return this.isRep ? from(db.GetWartungenBemerkungen()) : this.http.get(API_URL + `/wartung/bemerkungen`, this.AUTH_HEADER);
  }

  public getWartungsPlan(type: 'list' | 'table'): Observable<any> {
    return this.isRep ? from(db.GetWartungenInPlan(type)) : this.http.get(API_URL + `/wartung/plan/${type}`, this.AUTH_HEADER);
  }

  public getWartungNachMaske(mID: number, wID: number, readonly: boolean): Observable<any> {
    return this.isRep ? from(db.GetWartungFromMaske(mID, wID, readonly)) :
      this.http.get(API_URL + `/wartung/${mID}/${wID}/${readonly}`, this.AUTH_HEADER);
  }
  //#endregion
  //#region create, edit
  public setWartung(sendObj: any): Observable<any> {
    return this.isRep ? from(db.SetWartung(sendObj)) :
      this.http.post(API_URL + '/wartung/protokoll', sendObj, this.AUTH_HEADER);
  }
  //#endregion
  //#endregion
  //#region generic GET & POST
  public requestGET<T = any>(url: string): Observable<T> {
    if (!url.startsWith('/')) url = '/' + url;
    return this.http.get<T>(API_URL + url, { headers: this.AUTH_HEADER.headers });
  }

  public requestPOST(url: string, req: any): Observable<any> {
    if (!url.startsWith('/')) url = '/' + url;
    return this.http.post(API_URL + url, req, this.AUTH_HEADER);
  }
  //#endregion
  //#region other helpers
  public getImage(path: string) {
    if (path !== undefined && path !== null) {
      if (path.indexOf("undefined") == -1) {
        return this.http
          .get(API_URL + path, {
            headers: new HttpHeaders().set(
              "Authorization",
              "Bearer " + localStorage.getItem("jwt")
            ),
            responseType: "blob",
          })
          .pipe(
            catchError(
              (err: any, caught: Observable<any>): ObservableInput<any> => {
                if (err.status == 404) return this.img_placeholder;
                return throwError(err);
              }
            )
          )
          .pipe(
            map((e) => {
              if (typeof e == "string" && e.indexOf("placeholder") != -1)
                return e;
              return this.domSanitizer.bypassSecurityTrustUrl(
                URL.createObjectURL(e)
              );
            })
          );
      }
    }
    return this.img_placeholder;
  }

  private handleError(error: Response | any) {
    console.error("ApiService::handleError", error);
    return observableThrowError(error);
  }

  public refreshJWT() {
    this.isRep = localStorage.getItem("repID") != null && localStorage.getItem("repDB") == localStorage.getItem("DB");
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      this.AUTH_HEADER = {
        headers: new HttpHeaders().set(
          "Authorization",
          "Bearer " + jwt.toString(),
        ).set(
          "Cache-Control",
          "no-cache, no-store, private"
        ),
        responseType: "json",
      };
      this.AUTH_HEADER_NO_CACHE = {
        headers: new HttpHeaders().set(
          "Authorization",
          "Bearer " + jwt.toString(),
        ).set(
          "Cache-Control",
          "no-store must-revalidate"
        ),
        responseType: "json",
      };
      this.AUTH_HEADER_PRIV_CACHE = {
        headers: new HttpHeaders().set(
          "Authorization",
          "Bearer " + jwt.toString(),
        ).set(
          "Cache-Control",
          "private"
        ),
        responseType: "json",
      };
      this.AUTH_HEADER_BIN = {
        headers: new HttpHeaders().set(
          "Authorization",
          "Bearer " + jwt.toString(),
        ),
        responseType: "blob",
      };
      this.AUTH_HEADER_ARR = {
        headers: new HttpHeaders().set(
          "Authorization",
          "Bearer " + jwt.toString(),
        ),
        responseType: "arraybuffer",
      };
    }
    this.AUTH_HEADER_LOGIN_NO_CACHE = {
      headers: new HttpHeaders().set(
        "Cache-Control",
        "no-store must-revalidate"
      ),
      responseType: "json",
    };
    this.AUTH_HEADER_OBSERVE = {
      headers: new HttpHeaders().set(
        "Authorization",
        "Bearer " + jwt,
      ),
      responseType: "json",
      reportProgress: true,
      observe: 'events',
    };
  }

  public convertFileAsBlob = function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    //console.log(byteArrays);

    return new File(byteArrays, "pot", { type: contentType });
  }


  public downloadBlobAsFile = function (data, filename) {
    const contentType = data.type || "application/octet-stream";
    if (!data) {
      console.error(" No data");
      return;
    }

    var blob = new Blob([data], { type: contentType }),
      e = document.createEvent("MouseEvents"),
      a = document.createElement("a");

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset.downloadurl = [contentType, a.download, a.href].join(":");
    e.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  };

  public logError(errorPayload: ErrorPayload): Observable<any> {
    const headers = new HttpHeaders({
      "Authorization": "Bearer " + localStorage.getItem("jwt"),
      "Content-Type": "application/json"
    });
    return this.http.post(API_URL + '/log/error', errorPayload, { headers }).pipe(
      catchError((error: any) => {
        console.error("Logging error failed:", error);
        return throwError(() => error);
      })
    );
  }
  //#endregion
}
