import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CombineLabelsFunction, CustomStepDefinition, LabelType, Options, NgxSliderModule } from '@angular-slider/ngx-slider';
import { MrTranslatePipe } from '../../../pipes/mr-translate.pipe';
import { TablePrettyPrintPipe } from '../../../pipes/tablePrettyPrint.pipe';
import { NgFor, NgIf } from '@angular/common';
import { ClrCommonFormsModule, ClrAccordionModule, ClrCheckboxModule, ClrDatagridModule, ClrConditionalModule, ClrIconModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-gleismessdatenmodal',
    templateUrl: './gleismessdatenmodal.component.html',
    styleUrls: ['./gleismessdatenmodal.component.scss'],
    imports: [FormsModule, ClrCommonFormsModule, ClrAccordionModule, NgFor, ClrCheckboxModule, ClrDatagridModule, ClrConditionalModule, NgIf, NgxSliderModule, ClrIconModule, TablePrettyPrintPipe, MrTranslatePipe],
    standalone: true
})
export class GleismessdatenmodalComponent {
  @Output() mess_filter = new EventEmitter<any>();
  protected _mess_filters: messFilter[] = [];

  @Input() set resultReady(isReady: any) {
    setTimeout(() => {
      this.showProgress = !isReady;
    }, 500);
  }
  protected showProgress = false;

  protected readonly mess_keys = ["Spur", "Radius", "Überhöhung", "Tiefpunkt", "Verwindung", "Rillentiefe"]; // auß, drin, drin, drin, auß, auß
  protected readonly mess_filter_Arr: {value: boolean}[] = this.mess_keys.map(_ => ({value: false}));

  private readonly comp_außer: messComp = {type: 'außer', open: "<", close: ">"};
  private readonly comp_inner: messComp = {type: 'inner', open: ">", close: "<"};
  private calcComp(): messComp[] {
    let _mess_comp_Arr: messComp[] = [];
    this.mess_keys.forEach((key) => {
      switch (key) {
        case "Spur":
        case "Verwindung":
        case "Rillentiefe":
          _mess_comp_Arr.push(this.comp_außer);
          break;

        case "Radius":
        case "Überhöhung":
        case "Tiefpunkt":
        default:
          _mess_comp_Arr.push(this.comp_inner);
          break;
      }
    });
    return _mess_comp_Arr;
  }
  protected readonly mess_comp: messComp[] = this.calcComp();

  protected lowValues = [1430, 290, 0, 0, 0, 20];
  protected highValues = [1450, 1_000_000, 150, 4, 25, 60];

  private calcRadiusSteps(): CustomStepDefinition[] {

    let steps: CustomStepDefinition[] = [];
    let i = 0;

    /* // ! Bogen
    //* 10 to 300 (step 10)
    for (i = 1; i <= 30; i++) {
      let val = 312.5/i;
      steps.push({value: val});
    }
     //* 400 to 5_000 (step 100)
    for (i = 4; i <= 50; i++) {
      let val = 31.25/i;
      steps.push({value: val});
    }

    //* 6_000 to 9_000 (step 1_000)
    for (i = 6; i < 10; i++) {
      let val = 3.125/i;
      steps.push({value: val});
    }

    //* 10_000 to 1E10 (step *10)
    for (i = 0.3125; i >= 0.0000003125; i/=10) {
      steps.push({value: i});
    } */

    // ! Radius
    //* 10 to 300 (step 10)
    for (i = 1; i <= 30; i++) {
      steps.push({ value: 10 * i });
    }

    //* 400 to 5_000 (step 100)
    for (i = 4; i <= 50; i++) {
      steps.push({ value: 100 * i });
    }

    //* 6_000 to 9_000 (step 1_000)
    for (i = 6; i < 10; i++) {
      steps.push({ value: 1_000 * i });
    }

    //* 10_000 to 1E10 (step *10)
    for (i = 10_000; i <= 10_000_000_000; i *= 10) {
      steps.push({ value: i });
    }

    return steps;
  }
  private readonly radius_steps_Array: CustomStepDefinition[] = this.calcRadiusSteps();

  private readonly combineLabelsAußer: CombineLabelsFunction = (minLabel, maxLabel) => {
    return minLabel + " || " + maxLabel;
  }
  protected readonly options: Options[] = [
    // Spur
    {
      floor: 1410,
      ceil: 1480,
      animate: false,
      combineLabels: this.combineLabelsAußer,
    },
    // Radius
    {
      stepsArray: this.radius_steps_Array,
      animate: false,
      translate: (value: number, label: LabelType) => {
        switch (label) {
          case LabelType.Ceil:
            return '∞';
          default:
            return value.toString();
        }
      },
      /* translate: (value: number, label: LabelType) => {
        switch (label) {
          case LabelType.Floor:
            return '10';
          case LabelType.Ceil:
            return '∞';
          case LabelType.Low:
          case LabelType.High:
            return (Math.round((2500*2500)/(2*1000*value))).toString();
        }
      } */
    },
    // Ueberhoehung
    {
      floor: 0,
      ceil: 200,
      animate: false
    },
    // Tiefpunkt
    {
      floor: 0,
      ceil: 10,
      animate: false
    },
    // Verwindung
    {
      floor: 0,
      ceil: 25,
      animate: false,
      combineLabels: this.combineLabelsAußer,
    },
    // Rillentiefe
    {
      floor: 0,
      ceil: 60,
      animate: false,
      combineLabels: this.combineLabelsAußer,
    },
  ]

  constructor() { }

  protected setFilters() {
    this.showProgress = true;
    this._mess_filters = [];
    let first = true;
    this.mess_filter_Arr.forEach(({value: check}, index) => {
      if (check) {
        this._mess_filters.push({
          concat: first ? null : 'AND',
          key: this.mess_keys[index],
          comp: this.mess_comp[index].open,
          // value: this.mess_keys[index] == 'Radius' ? this.transformRadius(this.lowValues[index]) : this.lowValues[index],
          value: this.lowValues[index],
          openBracket: true
        });
        this._mess_filters.push({
          concat: this.mess_comp[index].type == 'außer' ? 'OR' : 'AND',
          key: this.mess_keys[index],
          comp: this.mess_comp[index].close,
          // value: this.mess_keys[index] == 'Radius' ? this.transformRadius(this.highValues[index]) : this.highValues[index],
          value: this.highValues[index],
          closeBracket: true
        });
        first = false;
      }
    });
    this.mess_filter.emit(this._mess_filters);
  }

  // private transformRadius(radius: number): number {
  //   let bogen = 3125 / radius;
  //   return bogen;
  // }

  protected deleteMessFilter() {
    this._mess_filters = [];
    this.mess_filter.emit({});
  }
}
interface messFilter {
  concat: string,
  key: string,
  comp: string,
  value: number,
  openBracket?: boolean,
  closeBracket?: boolean,
}
interface messComp {
  type: string,
  open: string,
  close: string
}
