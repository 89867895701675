import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";

import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from 'src/app/services/APIService/api.service';
import { BasedatamodalComponent } from "../../../_components/_modals/basedatamodal/basedatamodal.component";
import { MangelbaumitemsComponent } from "./mangelbaumitems/mangelbaumitems.component";

interface TreeDataNode {
  id: string,
  name: string,
  typ: string,
  selected: boolean,
  expanded: boolean,
  parent: TreeDataNode | null,
  children: TreeDataNode[]
}

@Component({
  selector: "app-mangelbaumdialog",
  templateUrl: "./mangelbaumdialog.component.html",
  styleUrls: ["./mangelbaumdialog.component.scss"],
  imports: [ CommonModule, ClarityModule, BasedatamodalComponent, MangelbaumitemsComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, TablePrettyPrintPipe ],
  standalone: true
})

export class MangelbaumDialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dgPO') dgPO: ClrDatagrid;
  @ViewChild('dgMG') dgMG: ClrDatagrid;
  @ViewChild('dgLG') dgLG: ClrDatagrid;
  @ViewChild('dgIH') dgIH: ClrDatagrid;
  @ViewChild(BasedatamodalComponent) modal: BasedatamodalComponent;


  title:string = "Mangelbaum";
  otypid: number = 0;
  typid: number = 1;
  symbol: number = 1;
  activeTheme = 0;
  objekte: any;
  titlebutton: string ="Weiche";
  selectedTree: any;
  colNums: string = "";
  mengeneinheit: any;
  mengeneinheitid: number = 0;
  fehlerklassen: any;
  gattung: any;
  stamm: any;
  selstamm: any = [];
  bedarray: any = ["", ">", "<", ">=", "<="];
  bedund: any = ["", "UND"];
  bedeinh: any = ["", "%", "mm","Stk."];
  tree: any;
  selid: number;
  stammtablename: string = 'sstamm';
  stammttitel: string = 'Stammdaten';
  seltree: TreeDataNode = null;
  grid: any;
  usefk: number = 1;

  selectedIds = {
    sprue: { id: 0, control: "pruefart" },
    slage: { id: 0, control: "lage" },
    smang: { id: 0, control: "mangel" },
    sinst: { id: 0, control: "instandsetzung" },
  }

  gattungid: number = 0;
  stammid: number = 0;
  szumzid: number = 0;

  root: TreeDataNode;
  selTreeNote: any;
  listTreeNote: TreeDataNode[] = [];
  selnodeid: any;
  object: any;
  viewliste: boolean = false;
  seledlistitem: any;
  rownr: number = 50;

  tabelleNameGroup = new UntypedFormGroup({
    txt0s: new UntypedFormControl('', [Validators.required]),
  });

  formSpezTop = new UntypedFormGroup({
    gattung: new UntypedFormControl(""),
    stamm: new UntypedFormControl(""),
    pruefart: new UntypedFormControl(""),
    lage: new UntypedFormControl(""),
    mangel: new UntypedFormControl(""),
    fehlerklasse: new UntypedFormControl(""),
    instandsetzung: new UntypedFormControl(""),
    mengeneinheit: new UntypedFormControl(""),
    beding1: new UntypedFormControl(""),
    bedingval1: new UntypedFormControl(""),
    bedingund: new UntypedFormControl(""),
    beding2: new UntypedFormControl(""),
    bedingval2: new UntypedFormControl(""),
    bedingeinheit: new UntypedFormControl(""),
    preis: new UntypedFormControl(""),
    lv: new UntypedFormControl(""),
    abt: new UntypedFormControl(""),
    equi: new UntypedFormControl(""),
  });

  set data(dataIn: any) {
    if (dataIn) {
        //this.selSpez = dataIn.selSpez;
        this.otypid = dataIn.id.otypid;
        this.objekte = dataIn.id.objecte;

        let srow = this.objekte.rows.filter(s => s.id == this.otypid);
        this.object = srow[0];
        this.openSymbol(this.object);
    }
  }

  isLoading = true;
  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
  ) {

  }
  symbols: any =  [
    { id: 1, name: "Weiche" },
    { id: 2, name: "Gleis" },
    { id: 3, name: "Gleisabschluss" },
    { id: 4, name: "Bahnübergang" },
    { id: 5, name: "Mast" },
    { id: 6, name: "Signal" },
    { id: 7, name: "Oberleitung" },
    { id: 8, name: "Brandschutz" },
    { id: 9, name: "Brücke" },
    { id: 10, name: "Tunnel" },
    { id: 11, name: "Videoanlagen" },
    { id: 12, name: "Weichensteuerung" },
    { id: 13, name: "Haltestelle" },
    { id: 14, name: "Unterwerk" },
    { id: 15, name: "Stellwerk" },
    { id: 16, name: "Weichenantrieb" },
    { id: 17, name: "Weichenheizung" },
    { id: 18, name: "Solaranlagen" },
    { id: 19, name: "Beleuchtungsanlagen" },
    { id: 20, name: "Fahrgastinformationssystem" },
    { id: 21, name: "Fangschienen" },
    { id: 22, name: "Bü-Sicherungsanlagen" },
    { id: 23, name: "Gleissperre elektrisch" },
    { id: 24, name: "Gleissperre mechanisch" },
    { id: 25, name: "Schienenauszugsvorrichtung" },
    { id: 26, name: "Radsensor" },
    { id: 27, name: "Schaltschrank" },
    { id: 28, name: "Kabel" },
    { id: 29, name: "Lichtsignal" },
    { id: 30, name: "Schienenkontakt" },
    { id: 31, name: "Schienenkontakt isoliert" },
    { id: 32, name: "Schienenprofil" },
    { id: 33, name: "Stützmauer" },
    { id: 34, name: "Durchlass" },
    { id: 35, name: "Bahnsteig" },
    { id: 36, name: "Damm" },
    { id: 37, name: "Schmieranlage" },
    { id: 38, name: "Flurstück" },
    { id: 39, name: "Gebäude" },
    { id: 40, name: "Anschlussbahn" },
    { id: 41, name: "Anlage ortsfest" },
    { id: 42, name: "Anlage mobil" },
    { id: 43, name: "Geräte" },
    { id: 44, name: "Dienstweg" },
    { id: 45, name: "Stromschiene" },


    { "id": 99, "name": "undefiniert" },
  ];

  addTree(){
    this.szumzid = 0;
    this.selectedTree = null;
    this.gattungid = 0;
    this.stammid = 0;
    this.selectedIds.sprue.id = 0;
    this.selectedIds.slage.id = 0;
    this.selectedIds.smang.id = 0;
    this.selectedIds.sinst.id = 0;

    this.formSpezTop.controls.gattung.setValue('');
    this.formSpezTop.controls.stamm.setValue('');
    this.formSpezTop.controls.pruefart.setValue('');
    this.formSpezTop.controls.lage.setValue('');
    this.formSpezTop.controls.mangel.setValue('');
    this.formSpezTop.controls.fehlerklasse.setValue('');
    this.formSpezTop.controls.instandsetzung.setValue('');
    this.formSpezTop.controls.mengeneinheit.setValue('');
    this.formSpezTop.controls.beding1.setValue('');
    this.formSpezTop.controls.bedingval1.setValue('');
    this.formSpezTop.controls.bedingund.setValue('');
    this.formSpezTop.controls.beding2.setValue('');
    this.formSpezTop.controls.bedingval2.setValue('');
    this.formSpezTop.controls.bedingeinheit.setValue('');
    this.formSpezTop.controls.preis.setValue('');
    this.formSpezTop.controls.lv.setValue('');
    this.formSpezTop.controls.abt.setValue('');
    this.formSpezTop.controls.equi.setValue('');

  }

  deleteTree(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Mangelsverknüpfung löschen wollen?"))) {
      let treeid = '';

      if (this.seltree != undefined)
        if (this.seltree.id != undefined)
          treeid = this.seltree.id;

      let items ={
        status: 'delete',
        szumzid: this.szumzid,
        id: treeid,
        typ: this.typid,
        otypid: this.otypid,
      }
      this.apiservice
      .setMangelbaum(items)
      .subscribe((res: any) => {
        if (res){
          if (res.status == "OK"){
            this.toastr.success(this.mrTranslate.transform("Daten gelöscht"));
          }
          else{
            this.toastr.error(this.mrTranslate.transform(res.status));
          }
          return res;
        }
      });
    }
  }

  editTree(){
    let ids = this.seltree.id.split("|");
    this.szumzid = parseInt(ids[ids.length-1]);
    let items = this.grid.rows.filter((row) => row.ID == this.szumzid);
    let szumzrow = items[0];

    this.gattungid = szumzrow.GattungID;

    this.changedgattung(szumzrow.GattungID.toString());

    this.stammid = szumzrow.StammID;
    this.mengeneinheitid = szumzrow.MengeneinheitID;
    this.selectedIds.sprue.id = szumzrow.PruefartID;

    if (szumzrow.LageID)
      this.selectedIds.slage.id = szumzrow.LageID;

    this.selectedIds.smang.id = szumzrow.MangelID;
    this.selectedIds.sinst.id = szumzrow.InstandsetzungID;

    setTimeout(() =>{
      this.formSpezTop.controls.gattung.setValue(szumzrow.GattungID);
      this.formSpezTop.controls.stamm.setValue(szumzrow.StammID);
      this.formSpezTop.controls.pruefart.setValue(szumzrow.Pruefart);

      if (szumzrow.Lage)
        this.formSpezTop.controls.lage.setValue(szumzrow.Lage);


      this.formSpezTop.controls.mangel.setValue(szumzrow.Mangel);
      this.formSpezTop.controls.fehlerklasse.setValue(szumzrow.Fehlerklasse);
      this.formSpezTop.controls.instandsetzung.setValue(szumzrow.Instandsetzung);
      this.formSpezTop.controls.mengeneinheit.setValue(szumzrow.MengeneinheitID);
      this.formSpezTop.controls.beding1.setValue(szumzrow.Bedingung);
      this.formSpezTop.controls.bedingval1.setValue(szumzrow.Min);
      this.formSpezTop.controls.bedingund.setValue(szumzrow.UND);
      this.formSpezTop.controls.beding2.setValue(szumzrow.Bedingung2);
      this.formSpezTop.controls.bedingval2.setValue(szumzrow.Max);
      this.formSpezTop.controls.bedingeinheit.setValue(szumzrow.Bedingungseinheit);
      this.formSpezTop.controls.preis.setValue(szumzrow.EP);
      this.formSpezTop.controls.lv.setValue(szumzrow.LV);
      this.formSpezTop.controls.abt.setValue(szumzrow.Abteilung);
      this.formSpezTop.controls.equi.setValue(szumzrow.Equipment);

      this.selectedTree = true;
    });

  }

  reloadTree(){
    this.openSymbol(this.object);
  }

  saveTree(){

    let vals = this.formSpezTop.value;

    if (this.usefk == 1){
      if(vals.fehlerklasse == undefined || vals.fehlerklasse == ''){
        this.toastr.error(this.mrTranslate.transform(this.mrTranslate.transform("Bitte 'Fehlerklasse' auswählen...!")));
        return;
      }
    }

    if (this.typid == 2 || this.typid == 4)
        this.selectedIds.slage.id = 0;

    let items ={
      status: 'update',
      szumzid:this.szumzid,
      selid: this.selid,
      typ: this.typid,
      otypid: this.otypid,
      gattungid: this.gattungid,
      stammid: this.stammid,
      fk: vals.fehlerklasse,
      meihid: this.mengeneinheitid,
      sprueid: this.selectedIds.sprue.id,
      slageid: this.selectedIds.slage.id,
      smangid: this.selectedIds.smang.id,
      sinstid: this.selectedIds.sinst.id,
      beding1: vals.beding1,
      bedingval1: vals.bedingval1,
      bedingund: vals.bedingund,
      beding2: vals.beding2,
      bedingval2: vals.bedingval2,
      bedingeinheit: vals.bedingeinheit,
      preis: vals.preis,
      lv: vals.lv,
      abt: vals.abt,
      equi: vals.equi,
    }

    this.apiservice
    .setMangelbaum(items)
    .subscribe((res: any) => {
      if (res){
        if (res.status == "OK"){
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        else{
          this.toastr.error(this.mrTranslate.transform("Eintrag existiert bereits !!"));
        }
      }
    });


  }

  addGattung(){
    this.openWerteliste("sgatt",this.mrTranslate.transform("Gattung bearbeiten"))
  }
  addStamm(){
    this.openWerteliste(this.stammtablename,this.mrTranslate.transform(this.stammttitel))
  }
  changedMEH(id: number){
    this.mengeneinheitid = id;
  }

  openWerteliste(tabelle, titel){
    let inData={
      titel: titel,
      tabelle: tabelle,
      OTYPID: this.otypid,
      gattungid: this.gattungid,
      tname: '',
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true){

      }
      this.modal.close();
    });

  }
  changeansicht(val){
    this.viewliste = val;
  }

  changedstamm(id: number){
    this.stammid = id;
    let vals = this.formSpezTop.value;
    if (this.typid != 4){
      if (vals.pruefart != '' && vals.lage != ''  && vals.mangel != '' && vals.instandsetzung != ''){
        this.selectedTree = true;
      }
    }
    else{
      if (this.gattungid != 0 && this.stammid != 0 && vals.pruefart != '' && vals.mangel != '' && vals.instandsetzung != ''){
        this.selectedTree = true;
      }
    }
  }


  changedgattung(id: any){

    this.gattungid = id;

    if (this.typid == 4){
      switch (id) {
        case "1":
          this.selstamm =  this.stamm.schiene.rows;
          this.stammtablename = '';
          break;
        case "2":
          this.selstamm =  this.stamm.befestigung.rows;
          this.stammtablename = 'sbeft';
          this.stammttitel= 'Befestigung';
          break;
        case "3":
          this.selstamm =  this.stamm.schwellenart.rows;
          this.stammtablename = 'sschw';
          this.stammttitel= 'Schwellenart';
          break;
        case "4":
          this.selstamm =  this.stamm.bettung.rows;
          this.stammtablename = '';
          break;
        case "5":
          this.selstamm =  this.stamm.eindeckung.rows;
          this.stammtablename = 'seind';
          this.stammttitel= 'Eindeckung';
          break;
        case "6":
          this.selstamm =  this.stamm.regellichtraumprofil.rows;
          this.stammtablename = '';
          break;
        case "7":
          this.selstamm =  this.stamm.bauwerke.rows;
          this.stammtablename = 'sbauw';
          this.stammttitel= 'Bauwerke';
          break;
        case "8":
          this.selstamm =  this.stamm.schienenverbindung.rows;
          this.stammtablename = '';
          break;
        case "9":
          this.selstamm =  this.stamm.grundinstandsetzung.rows;
          this.stammtablename = '';
          break;
        case "10":
          this.selstamm =  this.stamm.messwerte.rows;
          this.stammtablename = '';
          break;
        default:
          this.selstamm =  this.stamm.stammitems.rows;
          this.stammtablename = 'sstamm';
          this.stammttitel= 'Stammdaten';
          break;
      }
    }
    if (this.typid == 2){
      this.selstamm =  this.stamm.stammitems.rows;
      this.stammtablename = 'sstamm';
      this.stammttitel= 'Stammdaten';
    }

    let vals = this.formSpezTop.value;
    if (this.typid != 4){
      if (vals.pruefart != '' && vals.lage != ''  && vals.mangel != '' && vals.instandsetzung != ''){
        this.selectedTree = true;
      }
    }
    else{
      if (this.gattungid != 0 && this.stammid != 0 && vals.pruefart != '' && vals.mangel != '' && vals.instandsetzung != ''){
        this.selectedTree = true;
      }
    }

  }

  openSymbol(row: any){
    this.isLoading = true;
    this.symbol = row.symbol;
    this.titlebutton= row.bezeichnung;
    this.typid = row.typ;

    this.colNums = "150px 150px 200px 80px 250px 80px";

    if (this.typid == 4)
      this.colNums = "150px 150px 150px 200px 80px 250px 80px";

    this.stamm = undefined;
    this.selstamm =  [];
    this.listTreeNote = [];
    this.tree = [];


    this.otypid = row.id;
    this.apiservice
    .initMangelbaum(row.id)
    .subscribe((res: any) => {
      if (res){

        this.fehlerklassen = res.fehlerklassen;
        this.mengeneinheit = res.mengeneinheit;
        this.gattung = res.gattung;

        if(res.stamm){
          this.stamm = res.stamm;
        }

        this.usefk = res.useFK;
        this.tree = res.tree;
        this.grid  = res.grid;

        setTimeout(() =>{
          this.listTreeNote = [];

          for (let i = 0; i < this.tree.rows.length; i++) {
            let komp = this.tree.rows[i];
            if (komp.ParentID == ""){

              this.root = {
                id: komp.ID,
                name: komp.Bezeichnung,
                typ: this.geticon(komp.Typ),
                selected: true,
                expanded: false,
                parent: null,
                children: []
              }
              this.fillChildren(this.root, komp.ID);

              this.listTreeNote.push(this.root);

            }
          }

          let node = this.listTreeNote[0];
          this.selnodeid = node.id;
          this.selTreeNote = node;
          this.selTreeNote = this.root;
          this.isLoading = false;

          this.addTree();


        });

      }
    });

  }

  public getChildren = (node) => node.children;

  geticon(typ: number){

    switch(typ) {
      case 1: {
        return 'eye';
      }
      case 2: {
        return 'tasks';
      }
      case 3: {
        return 'search';
      }
      case 4: {
        return 'map-marker';
      }
      case 5: {
        return 'exclamation-triangle';
      }
      case 6:{
        return 'cog';
      }
      case 7:{
        return 'star';
      }
      default: {
         //statements;
         break;
      }
    }
  }


  treeClick(val){
    this.seltree = val;

    if (val.children.length==0){
      let ids = val.id.split("|");
      this.szumzid = parseInt(ids[ids.length-1]);
    }
    else
    this.szumzid =0;

  }


  fillChildren(parentNode:TreeDataNode, iid:string){
    const filtered = this.tree.rows.filter((obj) => {
      return obj.ParentID === iid;
    });

    for (let i = 0; i < filtered.length; i++) {
      let komp = filtered[i];
      let node: TreeDataNode = {
        id: komp.ID,
        name: komp.Bezeichnung,
        typ: this.geticon(komp.Typ),
        selected: false,
        expanded: false,
        parent: parentNode,
        children: []
      };
      parentNode.children.push(node);

      this.fillChildren(node,komp.ID);
    }

  }

  selectionChanged(event) {
    const obj = this.selectedIds[event.tablename];
    obj.id = event.id;
    this.formSpezTop.get(obj.control).setValue(event.bezeichnung);

    let vals = this.formSpezTop.value;
    this.selectedTree == null;

    if (this.typid != 4){
      if (vals.pruefart != '' && vals.lage != ''  && vals.mangel != '' && vals.instandsetzung != ''){
        this.selectedTree = true;
      }
    }
    else{
      if (this.gattungid != 0 && this.stammid != 0 && vals.pruefart != '' && vals.mangel != '' && vals.instandsetzung != ''){
        this.selectedTree = true;
      }
    }

  }

  close() {
    this.closedialog.next(true);
  }

  ngOnInit() {}

  selectionListChanged(val:any){

  }

  changedListgattung(v, item){

  }

  changedListFK(v, item){

    this.szumzid = item.ID;

    let items ={
      status: 'updatefk',
      szumzid:this.szumzid,
      fk: v,
      typ: this.typid,
      otypid: this.otypid,
    }

    this.apiservice
    .setMangelbaum(items)
    .subscribe((res: any) => {
      if (res){
        if (res.status == "OK"){
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      }
    });

  }

  dataChanged(newObj, item) {
    item.dochanges = true;
  }

  updateField(field, item, itemfieldname){

    this.szumzid = item.ID;

    if (itemfieldname == 'GattungID'){
      item['GattungID'] = parseInt(field);
      field = 'GattungID';
    }

    if (itemfieldname == 'GattungID' || itemfieldname == 'GattungID'){
      item.dochanges = true;
    }

    if(item.dochanges == undefined)
      return;

    let items ={
      status: 'updatefield',
      szumzid:this.szumzid,
      field: field,
      value: item[itemfieldname],
      typ: this.typid,
      otypid: this.otypid,
    }

    this.apiservice
    .setMangelbaum(items)
    .subscribe((res: any) => {
      if (res){
        if (res.status == "OK"){
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      }
    });
  }

  deleteRow(item){
    this.szumzid = item.ID;


    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Mangelsverknüpfung löschen wollen?"))) {

      let treeid = '';

      if (this.seltree != undefined)
        if (this.seltree.id != undefined)
          treeid = this.seltree.id;

      let items ={
        status: 'delete',
        szumzid: this.szumzid,
        id: treeid,
        typ: this.typid,
        otypid: this.otypid,
      }
      this.apiservice
      .setMangelbaum(items)
      .subscribe((res: any) => {
        if (res){
          if (res.status == "OK"){
            this.toastr.success(this.mrTranslate.transform("Daten gelöscht"));

            var index = this.grid.rows.indexOf(item);
            if (index !== -1) {
              this.grid.rows.splice(index, 1);
            }


          }
          else{
            this.toastr.error(this.mrTranslate.transform(res.status));
          }

        }
      });
    }
  }

}
