<div class="overlay-inner-wrapper">
  <div id="abnahmeProtokollWrapper">
    <div class="clr-row nxm">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h3 class="mt-0">
            {{ "Abnahme nach Umbau" | mrTranslate }} - {{ titel }}
          </h3>
        </div>
        <div class="inline-block" style="float: right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
    </div>
    <div class="clr-col-12 clr-row nxm" style="height: calc(100% - 1.2rem);">
      <div class="clr-col-12 clr-col-lg-7">
        <!-- style="width: 100%;" -->
        <form clrLabelSize="4" clrForm [formGroup]="abnahmeProtokollFormGroup" class="width100"
          (ngSubmit)="abnahmeProtokollFormGroup.valid && saveAbnahmeProtokoll()">
          <div class="clr-row margin-24-t">
            <label for="objekte" class="clr-col-12 clr-col-md-4 clr-control-label">
              {{ "Objekte" | mrTranslate }}
            </label>
            <div class="clr-col-12 clr-col-md-8 clr-row nxm wrapdiv">
              <div *ngFor="let objekt of objekteBez; let i = index">
                <clr-icon style="fill: black;" class="margin-18-l margin-6-r customIcon"
                  [attr.shape]="objekteIcons[i]" size="28"></clr-icon>
                <span>{{ objekt }}</span>
              </div>
            </div>
          </div>
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-4" for="auftragId">
              {{ "Auftragsnummer" | mrTranslate }}</label>
            <input class="clr-col-12 clr-col-md-8" clrInput name="auftragId"
              [value]="abnahmeProtokollFormGroup.get('auftragId').value | leftPadd:'5'"
              formControlName="auftragId" readonly />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-4" for="auftragnehmer">
              {{ "Auftragnehmer" | mrTranslate }}</label>
            <input class="clr-col-12 clr-col-md-8" clrInput name="auftragnehmer"
              formControlName="auftragnehmer" readonly />
          </clr-input-container>
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-4" for="pruefer">
              {{ "Prüfer" | mrTranslate }}</label>
            <input class="clr-col-12 clr-col-md-8" clrInput name="pruefer"
              formControlName="pruefer" readonly />
          </clr-input-container>
          <ng-container *ngIf="mode == 'new'; else readonlyTmpl">
            <clr-date-container>
              <label class="clr-col-12 clr-col-md-4" for="datum">
                {{ "Datum" | mrTranslate }}
              </label>
              <input class="clr-col-12 clr-col-md-8" type="date" clrDate name="datum"
                [ngModel]="dateControl | localeDate"
                (ngModelChange)="dateControl = localeDate.tosql($event)"
                [ngModelOptions]="{updateOn: 'blur', standalone: true}" required/>
              <clr-control-error *clrIfError="'required'">
                {{ "Das Datum muss eingegeben werden" | mrTranslate }}
              </clr-control-error>
            </clr-date-container>
          </ng-container>
          <ng-template #readonlyTmpl>
            <clr-input-container>
              <label class="clr-col-12 clr-col-md-4" for="datum">
                {{ "Datum" | mrTranslate }}</label>
              <input class="clr-col-12 clr-col-md-8" clrInput name="datum"
                [ngModel]="dateControl | localeDate" 
                [ngModelOptions]="{standalone: true}" readonly />
            </clr-input-container>
          </ng-template>
          <div class="padding-30-tb">
            <hr />
            <label class="clr-col-12 clr-control-label">
              {{ "geprüft wurden" | mrTranslate }}:
            </label>
            <div class="clr-row padding-10-t" formArrayName="geprueftArr">
              <clr-checkbox-wrapper *ngFor="let check of geprueftArr.controls; let i = index"
                class="clr-col-12 clr-col-md-6">
                <label>{{ geprueftChecks[i] | mrTranslate }}</label>
                <input type="checkbox" clrCheckbox [formControlName]="i" />
              </clr-checkbox-wrapper>
            </div>
          </div>
          <div class="padding-30-tb">
            <hr />
            <label class="clr-col-12 clr-control-label">
              {{ "Die Anlage(n) wurde sachgemäß geprüft und ggf. nach einer Belastungsprobe (Probefahrt)" | mrTranslate }}: 
            </label>
            <div class="clr-row padding-10-t" formArrayName="genommenArr">
              <div class="clr-col-12">
                <clr-checkbox-wrapper *ngFor="let check of genommenArr.controls; let i = index">
                  <label>{{ genommenChecks[i] | mrTranslate }}</label>
                  <input type="checkbox" clrCheckbox [formControlName]="i" />
                </clr-checkbox-wrapper>
              </div>
            </div>
          </div>
          <div class="padding-0-tb">
            <clr-textarea-container class="margin-1-r">
              <label class="clr-col-12">
                {{ "Bemerkung" | mrTranslate }}
              </label>
              <textarea class="clr-col-12" style="width: 100%;" clrTextarea formControlName="bemerkung"
                name="bemerkung"></textarea>
            </clr-textarea-container>
          </div>
          <div class="padding-0-tb">
            <clr-textarea-container class="margin-1-r">
              <label class="clr-col-12 clr-col-md-4 clr-align-self-center nowrap">
                {{ "Freigegeben an" | mrTranslate }}
              </label>
              <textarea rows="1" class="clr-col-12 clr-col-md-8" style="width: 100%;" clrTextarea
                formControlName="freigegebenAn" name="freigegebenAn"></textarea>
              <clr-control-error *clrIfError="'required'">
                {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
              </clr-control-error>
            </clr-textarea-container>
          </div>
          <!-- ? Toggle ja / nein ? -->
          <div class="padding-30-tb margin-1-r">
            <hr />
            <div class="clr-row">
              <label class="clr-col-12 clr-col-md-4 clr-control-label">
                {{ "Mängel festgestellt" | mrTranslate }}: 
              </label>
              <div class="clr-col clr-control-inline" style="justify-content: flex-end">
                <clr-radio-wrapper>
                  <input type="radio" clrRadio value="ja" formControlName="isNeuMangel" />
                  <label>{{ "ja" | mrTranslate }}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                  <input type="radio" clrRadio value="nein" formControlName="isNeuMangel" />
                  <label>{{ "nein" | mrTranslate }}</label>
                </clr-radio-wrapper>
              </div>
            </div>
            <!-- opens if Neue Mängel = ja -->
            <div class="padding-10-t" *ngIf="
                abnahmeProtokollFormGroup.controls.isNeuMangel.value === 'ja'
              ">
              <label class="clr-col-12">
                {{ "geringfügige Mängel die vom Auftragnehmer umgehend zu beseitigen sind" | mrTranslate }}:
              </label>
              <div class="clr-row padding-24-l" style="align-items: baseline;">
                <div class="clr-col-9 clr-row nxm" style="align-items: baseline;">
                  <div class="clr-col-2">
                    <clr-icon style="fill: black;" class="margin-6-tb customIcon"
                      [attr.shape]="getSelectedObjektIcon()" size="36"></clr-icon>
                  </div>
                  <div class="clr-col-10">
                    <clr-select-container>
                      <label class="clr-col-4">{{"Objekt" | mrTranslate}}</label>
                      <select class="clr-col-8" clrSelect [(ngModel)]="selectedObjekt"
                        [ngModelOptions]="{standalone: true}">
                        <option *ngFor="let option of objekteBez; let i = index" [value]="option">{{option}}
                        </option>
                      </select>
                    </clr-select-container>
                  </div>
                </div>
                <div class="clr-col-3" style="text-align: center;">
                  <button type="button" class="btn btn-icon btn-info-outline" aria-label="add"
                    (click)="addNeuMangel()">
                    <clr-icon shape="plus-circle"></clr-icon>
                  </button>
                </div>
              </div>
              <div class="clr-row">
                <div class="clr-col mw-100-p">
                  <ng-container *ngFor="let mangelTable of neueMaengelTables | keyvalue">
                    <clr-datagrid
                      *ngIf="getSelectedObjekt().ostammid == mangelTable.key && mangelTable.value?.rows?.length"
                      class="datagrid-compact">
                      <clr-dg-column *ngFor="let column of mangelTable.value['columns']">
                        <ng-container
                          *clrDgHideableColumn="{hidden:column.id | hideIDColumns : ['kauftmid', 'bezirk', 'stellwerk', 'bild']}">
                          {{ column.id | titlecase | mrTranslate }}
                        </ng-container>
                      </clr-dg-column>
                      <clr-dg-row *clrDgItems="let row of mangelTable.value['rows']; trackBy: trackByOmazsID">
                        <clr-dg-action-overflow>
                          <button class="action-item" (click)="deleteMangel(row)">
                            {{ "Mangel löschen" | mrTranslate }}
                          </button>
                        </clr-dg-action-overflow>
                        <clr-dg-cell *ngFor="let cell of mangelTable.value['columns']"
                          [innerHTML]="row[cell.id] | dotDotDot : 30 | tablePrettyPrint"
                          [title]="row[cell.id] | tablePrettyPrint" class="name-cell">
                        </clr-dg-cell>
                      </clr-dg-row>
                      <clr-dg-footer>
                        <clr-dg-column-toggle></clr-dg-column-toggle>
                        <clr-dg-pagination class="dg-pagination" #pagination>
                          {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                        </clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <!-- ^ opens if Neue Mängel = ja ^ -->
          <div class="padding-30-tb margin-1-r">
            <hr />
            <clr-radio-container>
              <label class="clr-col-12 clr-col-md-12 margin-36-b">
                {{ "Die Abnahme nach Umbau gilt als" | mrTranslate }}:
              </label>
              <clr-radio-wrapper style="justify-self: flex-end">
                <label>{{ "erteilt" | mrTranslate }}</label>
                <input type="radio" clrRadio value="erteilt" formControlName="isErteilt" />
              </clr-radio-wrapper>
              <clr-radio-wrapper style="justify-self: flex-end">
                <input type="radio" clrRadio value="nicht erteilt" formControlName="isErteilt" />
                <label>{{ "nicht erteilt" | mrTranslate }}</label>
              </clr-radio-wrapper>
              <clr-control-error *clrIfError="'required'">
                {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
              </clr-control-error>
            </clr-radio-container>
          </div>
          <div class="padding-30-tb margin-1-r signatures">
            <hr />
            <div class="clr-row padding-30-tb">
              <div class="clr-col-12 clr-col-md-12 padding-24-l hideIfSmall">
                <button [disabled]="isZoomedIn" type="button" class="btn btn-icon btn-info-outline"
                  aria-label="resize" (click)="isZoomedIn = true;">
                  <clr-icon shape="zoom-in"></clr-icon>
                </button>
                <button [disabled]="!isZoomedIn" type="button" class="btn btn-icon btn-info-outline"
                  aria-label="resize" (click)="isZoomedIn = false;">
                  <clr-icon shape="zoom-out"></clr-icon>
                </button>
              </div>
              <div class="margin-50-b" [class.clr-col-md-6]="!isZoomedIn" [class.clr-col-12]="isZoomedIn">
                <div class="clr-row">
                  <!-- * Name Prüfer -->
                  <div class="clr-col-12 clr-col-md-12">
                    <clr-input-container>
                      <label class="clr-col-12 clr-col-md-5 padding-20-l" for="unterschriftPrueferName">
                        {{ ("Unterschrift" | mrTranslate) + ' ' + ("Prüfer" | mrTranslate) }}
                      </label>
                      <input class="clr-col-12 clr-col-md-7 padding-20-l w-100-p" clrInput
                        name="unterschriftPrueferName" formControlName="unterschriftPrueferName" />
                      <clr-control-error *clrIfError="'required'">
                        {{ "Der Prüfer muss eingegeben werden" | mrTranslate }}
                      </clr-control-error>
                    </clr-input-container>
                  </div>
                  <!-- * Unterschrift Prüfer -->
                  <div class="clr-col-12 clr-col-md-12 unterschrift" [class.isZoomed]="isZoomedIn">
                    <div class="clr-col-lg" [class.clr-col-lg-12]="isZoomedIn">
                      <div class="clr-row">
                        <!-- * Wenn Unterschrift ist required:
                        <div class="clr-col">
                          <clr-checkbox-container class="margin-1-t">
                            <clr-checkbox-wrapper hidden style="justify-content: flex-end">
                              <label class="clr-col-2" for="unterschrift1"></label>
                              <input type="checkbox" clrCheckbox class="clr-col-10" name="unterschrift1"
                                formControlName="unterschriftPruefer" [ngModel]="isUnterschriebenPruefer" />
                            </clr-checkbox-wrapper>
                            <clr-control-error *clrIfError="'isNotUnterschrieben'">{{
                              "Unterschreiben Sie" | mrTranslate
                              }}</clr-control-error>
                          </clr-checkbox-container>
                        </div> -->
                        <div class="clr-col margin-6-t" style="display: flex;">
                          <button [disabled]="!isUnterschriebenPruefer" type="button"
                            class="btn btn-icon btn-warning-outline" aria-label="clear"
                            (click)="unterschriftPruefer = ''; isUnterschriebenPruefer = false; isChanged = true;">
                            <clr-icon shape="trash"></clr-icon>
                          </button>
                          <button [disabled]="isUnterschriebenPruefer" type="button"
                            class="btn btn-icon btn-info-outline" aria-label="clear" (click)="sign('pruefer')">
                            <clr-icon shape="pencil"></clr-icon>
                            <span class="margin-5-l" *ngIf="isZoomedIn">{{ "Unterschrift" | mrTranslate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="clr-col-lg padding-10-t h-100" [class.clr-col-lg-12]="isZoomedIn">
                      <img [src]="unterschriftPruefer" class="w-100-p" alt="" width="auto" height="auto"
                        style="max-height: 100%; object-fit: scale-down;" />
                    </div>
                  </div>
                </div>
              </div>
              <div [class.clr-col-md-6]="!isZoomedIn" [class.clr-col-12]="isZoomedIn">
                <div class="clr-row">
                  <!-- * Name Kunde -->
                  <div class="clr-col-12 clr-col-md-12">
                    <clr-input-container>
                      <label class="clr-col-12 clr-col-md-5 padding-20-l" for="unterschriftKundeName">
                        {{ ("Unterschrift" | mrTranslate) + ' ' + ("Kunde" | mrTranslate) }}
                      </label>
                      <input class="clr-col-12 clr-col-md-7 padding-20-l w-100-p" clrInput
                        name="unterschriftKundeName" formControlName="unterschriftKundeName" />
                      <clr-control-error *clrIfError="'required'">
                        {{ "Der Kundenname muss eingegeben werden" | mrTranslate }}
                      </clr-control-error>
                    </clr-input-container>
                  </div>
                  <!-- * Unterschrift Kunde -->
                  <div class="clr-col-12 clr-col-md-12 unterschrift" [class.isZoomed]="isZoomedIn">
                    <div class="clr-col-lg" [class.clr-col-lg-12]="isZoomedIn">
                      <div class="clr-row">
                        <!-- * Wenn Unterschrift ist required:
                        <div class="clr-col">
                          <clr-checkbox-container class="margin-1-t">
                            <clr-checkbox-wrapper hidden style="justify-content: flex-end">
                              <label class="clr-col-2" for="unterschrift1"></label>
                              <input type="checkbox" clrCheckbox class="clr-col-10" name="unterschrift2"
                                formControlName="unterschriftKunde" [ngModel]="isUnterschriebenKunde" />
                            </clr-checkbox-wrapper>
                            <clr-control-error *clrIfError="'isNotUnterschrieben'">{{
                              "Unterschreiben Sie" | mrTranslate
                              }}</clr-control-error>
                          </clr-checkbox-container>
                        </div> -->
                        <div class="clr-col margin-6-t" style="display: flex;">
                          <button [disabled]="!isUnterschriebenKunde" type="button"
                            class="btn btn-icon btn-warning-outline" aria-label="clear"
                            (click)="unterschriftKunde = ''; isUnterschriebenKunde = false; isChanged = true;">
                            <clr-icon shape="trash"></clr-icon>
                          </button>
                          <button [disabled]="isUnterschriebenKunde" type="button"
                            class="btn btn-icon btn-info-outline" aria-label="sign" (click)="sign('kunde')">
                            <clr-icon shape="pencil"></clr-icon>
                            <span class="margin-5-l" *ngIf="isZoomedIn">{{ "Unterschrift" | mrTranslate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="clr-col-lg padding-10-t h-100" [class.clr-col-lg-12]="isZoomedIn">
                      <img [src]="unterschriftKunde" class="w-100-p" alt="" width="auto" height="auto"
                        style="max-height: 100%; object-fit: scale-down;" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clr-row padding-48-tb padding-24-lr margin-0-lr">
            <div class="clr-col" style="flex-grow: 0; align-self: end">
              <button class="btn btn-primary" type="submit">
                <!-- [disabled]="trial" -->
                {{ "Abnahmeprotokoll speichern" | mrTranslate }}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="clr-col-12 clr-col-lg-5 clr-row nxm padding-24-b">
        <div class="clr-col-12">
          <app-preview-thumbnails
            [drag_drop]="true"
            [acceptTypes]="'image/*, video/*, .pdf'"
            (deleteFile)="deleteDoc($event)"
            (openPdfOverlay)="openPdf($event)"
            [getBigFileFnc]="getLargeFile"
            [highlightFnc]="protokollHighlight">
          </app-preview-thumbnails>
        </div>
      </div>
    </div>
  </div>
</div>

<clr-modal #saveFileChanges [clrModalSize]="'md'">
  <h3 class="modal-title">{{"Speichern bestätigen?" | mrTranslate}}</h3>
  <div class="modal-body">
    <p>
      {{ "Sie haben ein Dokument hochgeladen oder gelöscht. Möchten Sie speichern?" | mrTranslate }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalAntwort.next('save')">
      {{ "Speichern" | mrTranslate }}
    </button>
    <button type="button" class="btn" (click)="modalAntwort.next('not save')">
      {{ "Rückgängig machen" | mrTranslate }}
    </button>
  </div>
</clr-modal>

<app-signature-pad-modal *ngIf="openSignPad" [modalOpen]="openSignPad"></app-signature-pad-modal>