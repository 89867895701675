import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { APIService } from '../../../../services/APIService/api.service';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from 'src/app/services/Spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { DndModule } from 'ngx-drag-drop';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlotlyPlotComponent } from '../../plotly-plot/plotly-plot.component';

@Component({
  selector: 'app-messungsdialog',
  templateUrl: './messunglwmdialog.component.html',
  styleUrls: ['./messunglwmdialog.component.scss'],
  imports: [CommonModule, ClarityModule, DndModule, MrTranslatePipe, FormsModule, ReactiveFormsModule,PlotlyPlotComponent],
  standalone: true
})
export class MessungLaengswelligkkeitdialogComponent implements OnInit {
  ostammid: number;
  Object = Object;
  placeHolderHeight = 50;
  draganddropactive = false;
  layout:any;

  graph: any = {
    layout: {
      autosize: true,
      hovermode: 'closest',
      showlegend: false,
      legend: { "orientation": "h" },
      height: 250,
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0
      },
      plot_bgcolor: 'transparent',
      paper_bgcolor: 'transparent'
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: 'de',
      modeBarButtonsToRemove: ['sendDataToCloud', 'lasso2d', 'zoomOut2d', 'zoomIn2d', 'autoScale2d', 'toggleSpikelines', 'hoverCompareCartesian', 'hoverClosestCartesian']
    },
    m: [],
    show: {},
    y: [],
    data: [
      {
        name: 'Frequenz [10-30] links',
        key: 'l0100030',
        yData: []
      },
      {
        name: 'Frequenz [30-100] links',
        key: 'l0300100',
        yData: []
      },
      {
        name: 'Frequenz [100-300] links',
        key: 'l1000300',
        yData: []
      },
      {
        name: 'Frequenz [300-1000] links',
        key: 'l3001000',
        yData: []
      },
      {
        name: 'Frequenz [10-30] rechts',
        key: 'r0100030',
        yData: []
      },
      {
        name: 'Frequenz [30-100] rechts',
        key: 'r0300100',
        yData: []
      },
      {
        name: 'Frequenz [100-300] rechts',
        key: 'r1000300',
        yData: []
      },
      {
        name: 'Frequenz [300-1000] rechts',
        key: 'r3001000',
        yData: []
      }

    ]
  };
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);

  set data(dataIn: any) {
    this.ostammid = dataIn.id;

    this.apiservice.getMessungLaengswelligkeit(dataIn.id).pipe(first()).subscribe((res: any) => {

      let measurements = res;
      if (!measurements || !measurements?.rows?.length) {
        this.spinner.disable();
        this.toastr.warning(this.mrTranslate.transform("Keine Daten vorhanden"));
        this.close();
        return;
      }

      let firstm = measurements.rows[0].m;
      let lastm = measurements.rows[measurements.rows.length-1].m;

      let m = []; //...new Set(res.rows.map(item => item.m))
      let y = {};
      measurements.columns.forEach(val => {
        if (val.id != "m") {
          y[val.id] = []
        }
      });

      measurements.rows.forEach(row => {
        m.push(row["m"]);
        Object.keys(y).forEach((col) => {
          if (row[col] != null)
            y[col].push(row[col])
          else
            y[col].push(0)
        });
      });

      measurements.columns.forEach(val => {
        let valName = val.id;
        let curlayout = {
            autosize: true,
            hovermode: 'closest',
            showlegend: false,
            legend: { "orientation": "h" },
            height: 250,
            margin: {
              l: 35,
              r: 20,
              b: 25,
              t: 20,
              pad: 0
            },
            plot_bgcolor: 'transparent',
            paper_bgcolor: 'transparent',
            yaxis: {
              range: [-0.2, 0.2],
              dtick: 0.05,
            },
            xaxis: {
              range: [-100000, 100000],
              tick0: 0,
            },
          }


        if (valName != 'm'){
          let ind = this.getGraphIndex(valName);

          // let vals = [...new Set(res.rows(item => item[valName]))];

          if (ind !== undefined && this.graph.data[ind]) {

              let newPlot: any = {
                name: valName,
                visible: true,
                type: "scatter",
                marker: {
                  size: Number.MIN_VALUE
                },
                mode: "line",
                x: m,
                y: y[valName]
              };
              newPlot.line = {
                color: 'rgb(60, 133, 0)'
              };
              this.graph.data[ind].yData.push(newPlot);
              this.graph.data[ind].yData[0].x.push(m);
              this.graph.data[ind].yData[0].y.push(y[valName]);
              this.graph.data[ind].show = true;

              curlayout.yaxis = {
                range: [-0.25, 0.25],
                dtick: 0.05,
              };

              if (valName.indexOf("0300100")>-1){
                curlayout.yaxis = {
                  range: [-0.35, 0.35],
                  dtick: 0.07,
                };
              }

              if (valName.indexOf("1000300")>-1){
                curlayout.yaxis = {
                  range: [-1.5, 1.5],
                  dtick: 0.3,
                };
              }

              if (valName.indexOf("3001000")>-1){
                curlayout.yaxis = {
                  range: [-2, 2],
                  dtick: 0.4,
                };
              }

              curlayout.xaxis= {
                //range: [firstm, firstm + 400],
                range: [firstm, lastm],
                tick0: firstm,
              };

              this.graph.data[ind].layout = curlayout;
          }
        }
      });
      this.spinner.disable();
    });
  }


  constructor(
    private apiservice: APIService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private changeDet: ChangeDetectorRef,
    private mrTranslate: MrTranslatePipe
  ) {
    this.graph.m = [];
    this.graph.y = [];
  }

  getGraphIndex(key: string): number {
    for (var i = 0; i < this.graph.data.length; i++) {
      if (this.graph.data[i].key == key) {
        return i;
      }
    }
    return undefined;
  }

  applyYaxis(name: string) {
    let ind = this.getGraphIndex(name);
    this.graph.data[ind].show = false;
    this.changeDet.detectChanges();
    this.graph.data[ind].show = true;
  }


  public close() {
    this.graph = [];
    this.closedialog.next(true);
  }

  ngOnInit() {

  }
}
