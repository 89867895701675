<div *ngIf="historie$ | async as historieTree"
  class="h-100 w-100-p d-f row">
  <div class="clr-col-auto h-100 scroll-y no-scroll-x padding-0-l">
    <clr-tree>
      <clr-tree-node *ngFor="let historie of historieTree"
        [clrExpanded]="false">
        <clr-icon [attr.shape]="icons[historie.Key]"></clr-icon>
        {{ historie.Key | titlecase | mrTranslate }}
        <clr-tree-node *ngFor="let node of historie.Value">
          <div *ngIf="node.Key; else button">
            {{ node.Key }}
          </div>
          <ng-template #button>
            <button class="clr-treenode-link"
              (click)="historySelect(historie.Key, node.id ?? node.date)">
              {{ node.date | date }}
            </button>
          </ng-template>
          <clr-tree-node *ngFor="let r of node.Value"
            [clrExpanded]="false">
            <button class="clr-treenode-link"
              (click)="historySelect(historie.Key, r.id)">
              {{ r.datum | date }}
            </button>
          </clr-tree-node>
        </clr-tree-node>
      </clr-tree-node>
    </clr-tree>
  </div>
  <div *ngIf="table$ | async as table" class="clr-col h-100 scroll-y">
    <div #heightRef>
      <app-image-slide-show [data]="files$ | async"></app-image-slide-show>
    </div>

    <clr-datagrid *ngIf="table?.rows?.length else noDaten"
      [dymanicStyle]="heightRef" addPx="10" styleMode="max-height"
      class="datagrid-compact dg-history">
      <clr-dg-column *ngIf="mode == 'inspektionen'" class="mw-0">
        {{ "Log" | mrTranslate }}</clr-dg-column>
      <clr-dg-column *ngIf="mode | in: [
        'stoerungen', 'instandsetzungen', 'inspektionen'
        ]" class="mw-0">
        {{ "Medien" | mrTranslate }}</clr-dg-column>
      <clr-dg-column *ngFor="let column of table.columns" [clrDgField]="column.id">
        <ng-container *clrDgHideableColumn="{
          hidden: column.id | hideIDColumns : ['bilder', 'rueckbilder']
        }">{{ column.id | titlecase | mrUnitText }}</ng-container>
      </clr-dg-column>

      <clr-dg-row *clrDgItems="let row of table.rows">
        <clr-dg-cell *ngIf="mode == 'inspektionen'" class="mw-0">
          <a href="javascript:void(0)" (click)="mangelLog = row.omazsid">
            <clr-icon shape="view-list" size="20"></clr-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="mode | in: [
          'stoerungen', 'instandsetzungen', 'inspektionen'
          ]" class="mw-0">
          <a href="javascript:void(0)"
            (click)="mangelMedia = media.openMangelMedia(row.bilder, row.rueckbilder)"
            [class.deactivate]="!row.bilder && !row.rueckbilder">
            <clr-icon shape="image-gallery" size="20"></clr-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let cell of table.columns" class="name-cell"
          [innerHTML]="row[cell.id] | tablePrettyPrint ">
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer> {{table.rows.length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
    </clr-datagrid>
    <ng-template #noDaten>
      <div class="margin-3-t">{{'keine Daten vorhanden' | mrTranslate}}</div>
    </ng-template>
  </div>
</div>

<app-mangel-log-modal *ngIf="mangelLog" [(open)]="mangelLog"></app-mangel-log-modal>

<app-mediaviewmodal *ngIf="mangelMedia"
  (onClose)="mangelMedia = undefined"
  [srcs]="mangelMedia"
  [getMediaFromServerAll]="media.getApiFunctionMedia"
  [getBigFileFnc]="media.getApiFunctionMediaBig">
</app-mediaviewmodal>
