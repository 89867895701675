import { Component } from '@angular/core';
import { VerwaltungsView } from './wartung.types';
import { SOTYP_Map } from "../../../../models/api/SOTYP";
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { APIService } from 'src/app/services/APIService/api.service';
import { WartungsBaumComponent } from './wartungsbaum/wartungsbaum.component';
import { WartungsItemComponent } from './wartungsbaum/wartungsitem/wartungsitem.component';
import { WartungsMaskenComponent } from "./wartungsmasken/wartungsmasken.component";
import { WartungsObjektZuordnungComponent } from './wartungsobjektzuordnung/wartungsobjektzuordnung.component';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { FormsModule } from '@angular/forms';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';

@Component({
    selector: 'app-wartungsverwaltung',
    templateUrl: './wartungsverwaltung.component.html',
    styleUrls: ['./wartungsverwaltung.component.scss'],
    imports: [
        CommonModule, ClarityModule,
        WartungsBaumComponent,
        WartungsItemComponent,
        WartungsMaskenComponent,
        WartungsObjektZuordnungComponent,
        MrTranslatePipe, FormsModule
    ],
    standalone: true
})
export class WartungsVerwaltungComponent {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected dataRefresh: Subject<void>;

  protected openView: 'home' | VerwaltungsView = 'home';

  protected readonly objekte$: Observable<SOTYP_Map>;
  protected symbol: number;
  protected otypid: number;

  public set data(dataIn: any) {
    this.dataRefresh = dataIn.dataRefresh;
  }

  constructor (private _: APIService) {
    this.objekte$ = _.getSOTYP().pipe(
      tap(objekte => {
        for (const id in objekte) {
          this.otypid = +id;
          break;
        }
      })
    );
  }

  protected close() {
    this.closedialog.next(true);
  }
}
