import { pipe, startWith, switchMap, of, delay, Observable, repeat, merge, tap, MonoTypeOperatorFunction, filter, concat, shareReplay } from 'rxjs';

/**
 * @description z.B., für Datagrid Refresh 
 * @param precedingValue - ein Wert, der vorangehend emittet wird
 * @param delayTime - Zeit in ms, die abwartet wird vor dem wirklichen Wert
 * @returns new Observable that emits [ precedingValue -->>--- value ] with delayTime Pause
 */
export const precedeWith = <T>(precedingValue: T, delayTime = 10): MonoTypeOperatorFunction<T> =>
  pipe(
    switchMap((value: T) =>
      of(value).pipe(startWith(precedingValue), delay(delayTime))
    )
  );

/**
 * @description shareReplay with refCount, which is used almost everytime  
 * @param bufferSize - how many last values to cache
 * @returns hot Observable that emits cached (latest) value to all subscribers 
 */
export const cached = <T>(bufferSize = 1): MonoTypeOperatorFunction<T> =>
  shareReplay<T>({ bufferSize, refCount: true });

// same as startWith(null), emits void immediately after subscription
export const concatVOID = <T>(source$: Observable<T>) => concat(
  of(null) as Observable<void>,
  source$
);

// Mostly doesn't work with http$, which completes after 1 value;
// Use instead merge(...triggers$).pipe(switchMap(() => http$))
export const refreshOn = <T>(...triggers$: Observable<any>[]): MonoTypeOperatorFunction<T> => {
  const mergedTrigger$ = merge(...triggers$);
  return pipe(repeat({ delay: () => mergedTrigger$}));
}

export const log = <T>(...messages: any[]): MonoTypeOperatorFunction<T> => pipe(
  tap(() => console.log(...messages))
);

export const logDefer = <T>(cb: () => any): MonoTypeOperatorFunction<T> => pipe(
  tap(() => typeof cb == 'function' && console.log(cb()))
);

export const logState = <T>(label: string): MonoTypeOperatorFunction<T> => pipe(
  tap({ 
    subscribe: () => console.log(label, ' - subscribe'),
    next: val => console.log(label, ' - next: ', val),
    complete: () => console.log(label, ' - complete'),
    unsubscribe: () => console.log(label, ' - unsubscribe'),
    error: err => console.log(label, ' - error: ', err),
    finalize: () => console.log(label, ' - finalize'),
  })
);

export const logRefCount = <T>(label: string, labelRef: string): MonoTypeOperatorFunction<T> => {
  let count = 0;
  return pipe(
    tap({
      subscribe: () => console.log(`${label} - subscribe; refCount of ${labelRef} = ${++count}`),
      next: val => console.log(label, ' - next: ', val),
      unsubscribe: () => console.log(label, ' - unsubscribe'),
      error: err => console.log(label, ' - error: ', err),
      complete: () => console.log(label, ' - complete'),
      finalize: () => console.log(`${label} - finalize; refCount of ${labelRef} = ${--count}`),
    })
  );
} 

export const notFalsy = <T>(): MonoTypeOperatorFunction<T> => filter<T>((value: T) => !!value);

export const VOID = new Observable<void>(observer => {
  observer.next();
  observer.complete();
});