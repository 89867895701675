import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ClrDatagrid, ClrDatagridModule, ClrStopEscapePropagationDirective, ClrPopoverHostDirective } from "@clr/angular";
import { BehaviorSubject, Subscription } from "rxjs";
import { delay, first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { KostenTyp } from "../../_components/_overlays/auftrag-detail/kostenerfassung/kostenerfassung.component";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../../pipes/mr-translate.pipe";
import { HideIDColumnsPipe } from "../../../pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "../../../pipes/tablePrettyPrint.pipe";
import { NgIf, NgFor, AsyncPipe, DecimalPipe, TitleCasePipe, CurrencyPipe } from "@angular/common";

type Summen = {
  kosten: number;
  plankosten: number;
  personalstunden: number;
  personalstundenPlanung: number;
  typKosten: {
    "PERSONAL": number;
    "MATERIAL": number;
    "MASCHINE": number;
    "FREMDLEISTUNG": number;
  };
  typPlankosten: {
    "PERSONAL": number;
    "MATERIAL": number;
    "MASCHINE": number;
    "FREMDLEISTUNG": number;
  };
};

@Component({
    selector: "app-kostenliste",
    templateUrl: "./kostenliste.component.html",
    styleUrls: ["./kostenliste.component.scss"],
    imports: [
        NgIf,
        ClrDatagridModule,
        NgFor,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        AsyncPipe,
        DecimalPipe,
        TitleCasePipe,
        CurrencyPipe,
        TablePrettyPrintPipe,
        HideIDColumnsPipe,
        MrTranslatePipe_1,
    ],
    standalone: true
})
export class KostenListeComponent implements OnInit, OnDestroy {
  // auftraege = new BehaviorSubject<any>({ columns: [], rows: null });
  auftraege: any = {
    rows: [],
    columns: [],
  };
  summen = new BehaviorSubject<Summen>({
    kosten: 0,
    plankosten: 0,
    personalstunden: 0,
    personalstundenPlanung: 0,
    typKosten: {
      "PERSONAL": 0,
      "MATERIAL": 0,
      "MASCHINE": 0,
      "FREMDLEISTUNG": 0
    },
    typPlankosten: {
      "PERSONAL": 0,
      "MATERIAL": 0,
      "MASCHINE": 0,
      "FREMDLEISTUNG": 0,
    },
  });
  trackByAuftrNr = (index, row) => row['AuftragNr']

  // ! Wird nicht benutzt, da es ein anderes Component ist, das durch onInit u. onDestroy immer geht.
  // @Input("tabChange") tabChange: BehaviorSubject<boolean>;
  // @Input("reload") reload: EventEmitter<void>;
  // subscriptions: Subscription[] = [];

  //@ViewChild("auftraegeDatagridWrapper", { static: false }) auftraegeDatagridWrapper: ElementRef;
  @ViewChild("auftraegeDatagrid", { static: false }) auftraegeDatagrid: ClrDatagrid;

  pageSize = 20;

  @Output() dataRefresh = new EventEmitter();
  refreshSubscription: Subscription;
  componentStore: any;

  constructor(
    private apiservice: APIService,
    private spinnerservice: SpinnerService,
    private overlayService: OverlayService,
    private basicStore: BasicStoreService,
  ) {}

  private window = window;
  ngOnInit(): void {
    this.window.onresize = (_) => { this.resizeHeight(); }
    this.spinnerservice.enable();
    this.componentStore = this.basicStore.getComponentStore("auftragsliste");

    if (!this.componentStore) {
      this.loadAuftraege();
    } else {
      setTimeout(() => {
        if (this.componentStore.auftraege)
          this.auftraege = this.componentStore.auftraege;
        if (this.componentStore.summen)
          this.summen.next(this.componentStore.summen);

        setTimeout(() => {
          this.resizeHeight();
          this.spinnerservice.disable();
        });
      });
    }
  }

  private loadAuftraege() {
    this.apiservice
      .getAuftraegeListe()
      .pipe(first())
      .subscribe((data: any) => {
        const { auftraege, summen } = data;
        if (auftraege?.rows?.length > 0) {
          if (this.auftraege.columns?.length == 0) {
            this.auftraege.columns = auftraege.columns;
          }
          this.auftraege.rows = auftraege.rows;
        }
        if (summen) this.summen.next(summen);

        setTimeout(() => {
          this.resizeHeight();
          this.spinnerservice.disable();
        });
      });
  }

  private resizeHeight() {
    let wrapperHeight = this.auftraegeDatagrid?.datagridTable.nativeElement.parentElement.parentElement.offsetHeight - 15;
    //       this.auftraegeDatagridWrapper.nativeElement.offsetHeight - 250;
    if (wrapperHeight) this.pageSize = Math.floor((wrapperHeight / 26) - 1);
  }

  ngOnDestroy(): void {
    this.basicStore.setComponentStore("auftragsliste", {
      auftraege: this.auftraege,
      summen: this.summen.getValue(),
    });
    this.refreshSubscription?.unsubscribe();
    this.window.onresize = undefined;
  }

  openAuftrag(auftragRow) {
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe((val) => {
      this.loadAuftraege();
    });
    this.overlayService.setOverlay({
      overlay: "auftragDetail",
      data: {
        id: Number(auftragRow.AuftragNr),
        auftrag: auftragRow,
      },
      zIndex: 8,
      dataRefresh: this.dataRefresh,
    });
  }
}
