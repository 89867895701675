<div class="w-100-p padding-10-lr padding-12-b scroll-y h-60">
  <div class="clr-row nxm h-100">
    <div class="clr-col-lg-4 h-100 padding-1-tb">
      <div class="card item-table">
        <div class="card-header np clr-row nxm clr-align-items-baseline">
          <div class="clr-col-auto margin-12-t margin-12-l nxp">
            {{ "Zuordnung" | mrTranslate }}
          </div>
          <div class="clr-col clr-form-horizontal width100 clr-row nxm">
            <clr-select-container class="widthAuto margin-0-t clr-col-12">
              <label class="clr-col-auto">{{"Fehlerklasse" | mrTranslate}}</label>
              <select class="clr-col" clrSelect name="fk" [disabled]="!selectedWartung"
                [(ngModel)]="selectedFK" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let fk of rangeFK" [value]="fk">
                  {{ fk }}
                </option>
              </select>
            </clr-select-container>
          </div>
        </div>
        <div class="card-block scroll-y" #treeContainer>
          <clr-tree>
            <clr-tree-node class="basealign" *ngFor="let art of wTree | keyvalue" [clrExpanded]="true">
              <div class="clr-row nxm">
                <!-- <clr-icon shape="eye" size="22" class="clr-col-auto nxp margin-6-t"></clr-icon> -->
                <span class="margin-8-t badge badge-light-blue"> {{ art.value.length }}</span>
                <span class="clr-col nxp">{{ art.value[0].artName }}</span>
              </div>
              <clr-tree-node *ngFor="let wartung of art.value" class="basealign" id="w-{{wartung.ID}}"
                [ngClass]="{'datagrid-row datagrid-selected': selectedWartung == wartung}">
                <div class="clr-row nxm">
                  <clr-icon shape="wrench" size="18" class="clr-col-auto nxp margin-6-t"></clr-icon>
                  <a href="javascript:void(0);" class="clr-col nxp cur-color"
                    (click)="selectedWartung = wartung">{{ wartung.arbeitName }}
                  </a>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
        <div class="card-footer d-f clr-justify-content-between">
          <div class="btn-group btn-primary btn-icon">
            <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
              (click)="addWartung()" [disabled]="setInsert">
              <clr-icon shape="file"></clr-icon>
            </button>
            <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
              (click)="deleteWartung()" [disabled]="!selectedWartung || setInsert">
              <clr-icon shape="times"></clr-icon>
            </button>
            <button class="btn btn-icon margin-12-l" [title]="'Speichern' | mrTranslate"
              (click)="saveWartung()" [disabled]="!selectedWartung">
              <clr-icon shape="floppy"></clr-icon>
            </button>
          </div>

          {{ wTree.size }} {{ "Verknüpfungen" | mrTranslate }}
        </div>
      </div>
    </div>
    <div class="clr-col-lg-8 h-100 half-cards">
      <div>
        <app-wartungsitem #art [otypid]="otypid" [mode]="'art'" [withHelper]="true"
          (editedItem)="editTree('art', $event)"
          (deletedItem)="cleanTree('art', $event)"></app-wartungsitem>
      </div>
      <div>
        <app-wartungsitem #arbeit [otypid]="otypid" [mode]="'arbeit'"
          (editedItem)="editTree('arbeit', $event)"
          (deletedItem)="cleanTree('arbeit', $event)"></app-wartungsitem>
      </div>
    </div>
  </div>
</div>