import {
  Component,
  AfterViewInit,
  ViewChild,
  ViewContainerRef,
  ElementRef,
  OnDestroy,
  EventEmitter,
  QueryList,
  ViewChildren,
  HostListener,
} from "@angular/core";
import { ClrDatagrid, ClrDatagridFilterInterface, ClrDatagridPagination, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrButtonGroupModule, ClrIconModule, ClrSpinnerModule, ClrTabsModule, ClrConditionalModule, ClrDatagridModule, ClrModalModule, ClrCommonFormsModule, ClrTextareaModule } from "@clr/angular";
import { APIService } from "src/app/services/APIService/api.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { first } from "rxjs/operators";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";

import { AuthService } from "src/app/services/Auth/auth.service";
import { isNumber } from "src/app/misc/functions";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { Observable, Subject } from "rxjs";
import { getUniqueValues } from "src/app/models/ui/table";
import * as _ from "lodash";
import { TableStateService } from "src/app/services/Shared/table-state.service";
import { MangelMediaApiFuncService } from "src/app/services/_abstract/mangel-media-api-func.service";
import { TitleAndFileNames, MediaViewModalComponent } from "../_components/_shared/mediaviewmodal/mediaviewmodal.component";
import { FilterService } from "../_components/filter/filter.service";
import { ColumnFilterAction, ColumnFilterChange, ColumnFilterComponent } from "../_components/filter/column-filter/column-filter.component";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { SonderinspektionmodalComponent } from "../_components/_modals/sonderinspektionmodal/sonderinspektionmodal.component";
import JSZip from 'jszip';
import { MrTranslatePipe } from "../../pipes/mr-translate.pipe";
import { HideIDColumnsPipe as HideIDColumnsPipe_1 } from "../../pipes/hide-idcolumns.pipe";
import { MrUnitTextPipe } from "../../pipes/mrUnitText.pipe";
import { TablePrettyPrintPipe as TablePrettyPrintPipe_1 } from "../../pipes/tablePrettyPrint.pipe";
import { ProgressbarComponent } from "../_components/progressbar/progressbar.component";
import { MultiRueckmeldungModalComponent } from "./multi-rueckmeldung-modal/multi-rueckmeldung-modal.component";
import { MultiAuftragModalComponent } from "./multi-auftrag-modal/multi-auftrag-modal.component";
import { FormsModule } from "@angular/forms";
import { DetailviewComponent } from "../_components/detailview/detailview.component";
import { NgStyle, NgIf, NgFor, NgTemplateOutlet, NgClass, TitleCasePipe } from "@angular/common";


export class TextFilter implements ClrDatagridFilterInterface<any> {
  state?: any;
  changes: Observable<any>;
  private filterValue: string = "";

  accepts(item: any): boolean {
    return item != null;
  }

  isActive(): boolean {
    return this.filterValue !== "";
  }
}

export class ColorFilter implements ClrDatagridFilterInterface<any> {
  public selectedColors: string[] = [];
  public text: string = "";

  public changes = new Subject<any>();

  public isActive(): boolean {
    return this.text != "" || this.selectedColors.length > 0;
  }

  public accepts(user: any): boolean {
    return this.selectedColors.indexOf(user.Ort.toLocaleLowerCase()) > -1;
  }
}

@Component({
    selector: "app-maengel",
    templateUrl: "./maengel.component.html",
    styleUrls: ["./maengel.component.scss"],
    providers: [BackendLocaleDatePipe],
    imports: [
        NgStyle,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrButtonGroupModule,
        ClrIconModule,
        ClrSpinnerModule,
        NgIf,
        ClrTabsModule,
        ClrConditionalModule,
        ClrDatagridModule,
        NgFor,
        ColumnFilterComponent,
        NgTemplateOutlet,
        NgClass,
        DetailviewComponent,
        ClrModalModule,
        FormsModule,
        ClrCommonFormsModule,
        ClrTextareaModule,
        MultiAuftragModalComponent,
        MultiRueckmeldungModalComponent,
        SonderinspektionmodalComponent,
        ProgressbarComponent,
        MediaViewModalComponent,
        TitleCasePipe,
        TablePrettyPrintPipe_1,
        MrUnitTextPipe,
        HideIDColumnsPipe_1,
        MrTranslatePipe,
    ],
    standalone: true
})
export class MaengelComponent implements AfterViewInit, OnDestroy {
  textfilter: TextFilter = new TextFilter();
  customColorFilter: ColorFilter = new ColorFilter();
  toggleSelection(event: any) {
    if (event.target.checked) {
      this.customColorFilter.selectedColors.push(event.target.value);
    } else {
      const colorName = event.target.value;
      const index = this.customColorFilter.selectedColors.indexOf(colorName);
      if (index > -1) {
        this.customColorFilter.selectedColors.splice(index, 1);
      }
    }
    this.customColorFilter.changes.next(true);
  }
  searchInput = "";
  updateSearchInput(event: any) {
    this.customColorFilter.text = this.searchInput;
    this.customColorFilter.changes.next(true);
  }

  showFilters = false;

  tableAllUniqueColumnValues: any;
  tableAll: any;
  tableAuftragUniqueColumnValues: any;
  tableAuftrag: any;
  tableDoneUniqueColumnValues: any;
  tableDone: any;
  tableOpenUniqueColumnValues: any;
  tableOpen: any;
  tableWartungUniqueColumnValues: any;
  tableWartung: any;
  tableStoerungUniqueColumnValues: any;
  tableStoerung: any;

  dataAll = [];
  dataAuftrag = [];
  dataDone = [];
  dataOpen = [];
  dataWartung = [];
  dataStoerung = [];

  filterServiceAll: FilterService;
  filterServiceAuftrag: FilterService;
  filterServiceDone: FilterService;
  filterServiceOpen: FilterService;
  filterServiceWartung: FilterService;
  filterServiceStoerung: FilterService;

  _currentPage: number = 1;
  dataRefresh: any;
  reloadTabsIsReady = new EventEmitter();

  set currentPage(newPage: number) {
    this._currentPage = newPage;
  }
  get currentPage() {
    return this._currentPage;
  }
  _ostammid: number = undefined;
  set ostammid(val: number) {
    this._ostammid = val;
    if (val !== undefined && !this.showdetailview) {
      this.showdetailview = true;
    }
  }
  get ostammid() {
    return this._ostammid;
  }

  selected: any = [];

  showoverlay: boolean = false;
  showdetailview: boolean = false;
  hidedetailview: boolean = false;
  showbemerkung: boolean = false;
  showprogressbar: boolean = false;

  mangelbemerkung: string = "";
  curID: number = 0;
  curostammid: number = 0;
  rownr = 14;

  split50 = false;
  sidenavenlarged = false;
  progressbarenlarged = false;
  progressbars: any;
  componentStore = {};

  @ViewChild(SonderinspektionmodalComponent) modal: SonderinspektionmodalComponent;

  indexScrub: number = -1;
  overlayref;
  overlayZ = 3;
  @ViewChild("overlay", { read: ViewContainerRef, static: true })
  overlay: ViewContainerRef;
  @ViewChild("datagridwrapper", { static: true }) datagridwrapper: ElementRef;

  @ViewChild("pagination") pagination: ClrDatagridPagination;
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChildren(ColumnFilterComponent) columnFilters: QueryList<ColumnFilterComponent>;
  trackByOmazsID = (_: number, row: any) => row["omazsid"];

  subscriptions: any = [];

  activeTab: any = {
    all: false,
    auftrag: false,
    done: false,
    open: false,
    wartung: false,
    stoerung: false,
  };

  protected isMultiRueckModalOpen = false;
  protected isMultiAuftragModalOpen = false;

  allInspections: boolean = false;
  loading: boolean = true;

  isLoadingMaengel = false;
  isResettingFilters = false;

  mangelMedia: TitleAndFileNames[];

  constructor(
    private apiservice: APIService,
    private basicStore: BasicStoreService,
    private hideIdColumns: HideIDColumnsPipe,
    private tablePrettyPrint: TablePrettyPrintPipe,
    private spinner: SpinnerService,
    private tableStateService: TableStateService,
    protected authService: AuthService,
    protected media: MangelMediaApiFuncService,
  ) {}


  private window = window;
  ngAfterViewInit() {
    this.resizeGridHeight();
    this.window.onresize = (_) => { this.resizeGridHeight(); }
  }
  private resizeGridHeight() {
    let rowHeight = 40;
    this.rownr = Math.floor((this.window.innerHeight - 250) / rowHeight);
  }

  reloadMangel(toggleInspektion: boolean = false, reloadTabsWaitReady: EventEmitter<any> = undefined) {
    this.loading = true;
    this.spinner.enable();
    if (reloadTabsWaitReady) {
      reloadTabsWaitReady.pipe(first((isReady: any) => isReady?.refreshMaengel)).subscribe(() => {
        this.getMaengelStore();
      });
    } else {
      if (toggleInspektion) this.allInspections = !this.allInspections;
      this.apiservice.getMaengel(this.allInspections)
        .pipe(first()).subscribe((data: any) => {
          if (!this.apiservice.isRep) {
            var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
            jsZip.loadAsync(data).then((zip) => {
              return zip.file("tmp.txt").async("string");
            }).then((zipf) => {
              let store = this.basicStore.getComponentStore('maengel');
              this.basicStore.setComponentStore('maengel', {
                ...store,
                maengel: JSON.parse(zipf),
                ostammid: this.ostammid ?? store.ostammid,
                allInspections: this.allInspections,
                activeTab: this.activeTab,
              });
              setTimeout(() => {
                this.getMaengelStore();
              }, 10);
            }
            );
          }

        });
    }
  }
  downloadFile(
    data: any,
    charset: string,
    name: string,
    extension: string,
    cb: any
  ) {
    let blob = new Blob(["\ufeff" + data], { type: charset });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", name + "." + extension);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    cb();
  }

  exportCSV() {
    const trennzeichen = ";";
    this.spinner.enable();
    setTimeout(() => {
      if (this.selected.length > 0) {
        let table = this.activeTab.auftrag
          ? this.tableAuftrag
          : this.activeTab.done
            ? this.tableDone
            : this.tableOpen;
        let columns = table.columns.filter((elem) => {
          return !this.hideIdColumns.transform(elem.id);
        });
        let csvContent = "";
        columns.forEach((col: any) => {
          //let type = isNumber(this.selected[0][col.id]) ? '|NUMBER|DECIMAL' : '|STRING';
          csvContent +=
            this.tablePrettyPrint.transform(
              col.id[0].toUpperCase() + col.id.substr(1),
              { icons: false }
            ) + trennzeichen;
        });
        csvContent = csvContent.slice(0, -1) + "\r\n";
        this.selected.forEach((sel: any) => {
          columns.forEach((col: any) => {
            let escapeChar = isNumber(sel[col.id]) ? "" : '"';
            let insVal =
              sel[col.id] == undefined || sel[col.id] == null
                ? ""
                : isNumber(sel[col.id])
                  ? sel[col.id].toString().replace(".", ",")
                  : sel[col.id];
            csvContent +=
              escapeChar +
              this.tablePrettyPrint.transform(
                sel[col.id] != undefined && sel[col.id] != null
                  ? sel[col.id]
                  : "",
                { icons: false }
              ) +
              escapeChar +
              trennzeichen;
          });
          csvContent = csvContent.slice(0, -1) + "\r\n";
        });
        this.downloadFile(
          csvContent,
          "text/csv;charset=utf-8;",
          "Mängelexport",
          "csv",
          () => {
            this.spinner.disable();
          }
        );
      }
    });
  }

  select(mode: string) {
    let rows = this.activeTab.all
      ? this.tableAll["rows"]
      : this.activeTab.auftrag
        ? this.tableAuftrag["rows"]
        : this.activeTab.done
          ? this.tableDone["rows"]
          : this.activeTab.done
          ? this.tableOpen["rows"]
          : this.activeTab.done
          ? this.tableWartung["rows"]
          : this.tableStoerung["rows"];

    switch (mode) {
      case "all_not":
        this.selected = [];
        break;
      case "all":
        this.selected = rows;
        break;
      case "page":
        this.selected = _.union(this.selected, this.grid.items.displayed);
        break;
      case "page_not":
        this.selected = _.difference(this.selected, this.grid.items.displayed);
        break;
      case "invert":
        this.selected = _.difference(rows, this.selected);
        break;
      case "clear_filters":
        this.forceResetSubmit();
    }
  }

  forceResetSubmit() {
    if (this.columnFilters) {
      this.columnFilters.forEach(filterComponent => filterComponent.forceResetSubmit());
      this.filterServiceAll.clearFilters();
      this.filterServiceAuftrag.clearFilters();
      this.filterServiceDone.clearFilters();
      this.filterServiceOpen.clearFilters();
      this.filterServiceWartung.clearFilters();
      this.filterServiceStoerung.clearFilters();

      this.dataAll = this.tableAll['rows'];
      this.dataAuftrag = this.tableAuftrag['rows'];
      this.dataDone = this.tableDone['rows'];
      this.dataOpen = this.tableOpen['rows'];
      this.dataWartung = this.tableWartung['rows'];
      this.dataStoerung= this.tableStoerung['rows'];
    }
  }

  ngOnInit(): void {
    this.selected = [];

    this.spinner.enable();
    this.getMaengelStore();

    this.presetFilters();

    var filters = JSON.parse(sessionStorage.getItem('filtermangelAll'));
    if (filters && Object.keys(filters).length > 0) {
      this.dataAll = this.filterServiceAll.setChanges(filters)
    }
    filters = JSON.parse(sessionStorage.getItem('filtermangelAuftrag'));
    if (filters && Object.keys(filters).length > 0) {
      this.dataAuftrag = this.filterServiceAuftrag.setChanges(filters)
    }
    filters = JSON.parse(sessionStorage.getItem('filtermangelDone'));
    if (filters && Object.keys(filters).length > 0) {
      this.dataDone = this.filterServiceDone.setChanges(filters)
    }
    filters = JSON.parse(sessionStorage.getItem('filtermangelOpen'));
    if (filters && Object.keys(filters).length > 0) {
      this.dataOpen = this.filterServiceOpen.setChanges(filters)
    }

    //debugger;
    var filters = JSON.parse(sessionStorage.getItem('filter'));
    if (filters && Object.keys(filters).length > 0) {
      sessionStorage.removeItem('filter');
      sessionStorage.setItem('filtermangelAll', JSON.stringify(filters));
      this.dataAll = this.filterServiceAll.setChanges(filters);
    }
  }

  getMaengelStore() {
    this.basicStore
      .getComponentStoreAsync("maengel")
      .pipe(first((val: any) => val !== undefined && val !== null))
      .subscribe((componentStore) => {
        let cmpStore = JSON.parse(componentStore);
        const activeTabName = Object.keys(this.activeTab).find(
          (key) => this.activeTab[key] === true
        );
        if (cmpStore["activeTab"] && !activeTabName) {
          this.activeTab = cmpStore["activeTab"];
        }

        this.showdetailview = cmpStore["showdetailview"] ?? false;
        this.hidedetailview = cmpStore["hidedetailview"] ?? false;
        this.split50 = cmpStore.split50 ?? false;
        this.sidenavenlarged = cmpStore.sidenavenlarged ?? false;

        if (cmpStore["ostammid"]) {
          if (this.showdetailview) {
            this.ostammid = cmpStore["ostammid"];
          } else {
            this.ostammid = undefined;
          }
        }

        if (cmpStore["maengel"]) {
          this.loading = true;
          this.tableAll = undefined;
          this.tableAuftrag = undefined;
          this.tableDone = undefined;
          this.tableOpen = undefined;
          this.tableWartung = undefined;
          this.tableStoerung = undefined;

          this.tableAll = cmpStore["maengel"].all;

          this.tableAllUniqueColumnValues = getUniqueValues(this.tableAll);

          this.tableAuftrag = cmpStore["maengel"].auftrag;
          this.tableAuftragUniqueColumnValues = getUniqueValues(
            this.tableAuftrag
          );

          this.tableWartung = cmpStore["maengel"].wartung;
          this.tableWartungUniqueColumnValues = getUniqueValues(this.tableWartung);

          this.tableStoerung = cmpStore["maengel"].stoerung;
          this.tableStoerungUniqueColumnValues = getUniqueValues(this.tableStoerung);

          this.tableDone = cmpStore["maengel"].done;
          this.tableDoneUniqueColumnValues = getUniqueValues(this.tableDone);

          this.tableOpen = cmpStore["maengel"].open;
          this.tableOpenUniqueColumnValues = getUniqueValues(this.tableOpen);

          this.dataAll = this.tableAll['rows'];
          this.dataAuftrag = this.tableAuftrag['rows'];
          this.dataDone = this.tableDone['rows'];
          this.dataOpen = this.tableOpen['rows'];
          this.dataWartung = this.tableWartung['rows'];
          this.dataStoerung = this.tableStoerung['rows'];

          this.filterServiceAll = new FilterService(this.dataAll);
          this.filterServiceAuftrag = new FilterService(this.dataAuftrag);
          this.filterServiceDone = new FilterService(this.dataDone);
          this.filterServiceOpen = new FilterService(this.dataOpen);

          this.filterServiceWartung = new FilterService(this.dataWartung);
          this.filterServiceStoerung = new FilterService(this.dataStoerung);

          this.allInspections = cmpStore["allInspections"] ?? false;

          this.loading = false;
          this.spinner.disable();
        }
      });
  }

  tabChange() {
    this.selected = [];
    this.currentPage = 1;

    this.presetFilters();
  }

  // Method to get unique values for a specific column
  getUniqueValuesForColumn(tableValues: Set<any>, columnId: string): Set<any> {
    return tableValues[columnId] || new Set();
  }

  private presetFilters() {
    if (this.grid) {
      const activeTabName = Object.keys(this.activeTab).find(
        (key) => this.activeTab[key] === true
      );
      const tableFilters = this.tableStateService.getByTableName(activeTabName);

      if (tableFilters) {
        const stateProvider = (this.grid as any).stateProvider;
        if (stateProvider?.filters?._all)
          stateProvider.filters._all = tableFilters;

        tableFilters.forEach((element) => {
          const columnId = element.filter.column.id;
          this.filterVisibility[columnId] = true;
          setTimeout(() => {
            this.filterVisibility[columnId] = false;
          }, 50);
        });
      }
    }
  }
  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.basicStore.setComponentStore("maengel", {
      maengel: {
        all: this.tableAll,
        auftrag: this.tableAuftrag,
        done: this.tableDone,
        open: this.tableOpen,
        wartung: this.tableWartung,
        stoerung: this.tableStoerung,
      },

      allInspections: this.allInspections,
      activeTab: this.activeTab,
      // currentPage: this.currentPage,
      // selected: this.selected,
      // rownr: this.rownr,
      ostammid: this.ostammid,
      showdetailview: this.showdetailview,
      hidedetailview: this.hidedetailview,
      split50: this.split50,
      sidenavenlarged: this.sidenavenlarged,
      // progressbarenlarged: this.progressbarenlarged,
    });
    sessionStorage.setItem("filtermangelAll", JSON.stringify(this.filterServiceAll.getChanges()));
    sessionStorage.setItem("filtermangelAuftrag", JSON.stringify(this.filterServiceAuftrag.getChanges()));
    sessionStorage.setItem("filtermangelDone", JSON.stringify(this.filterServiceDone.getChanges()));
    sessionStorage.setItem("filtermangelOpen", JSON.stringify(this.filterServiceOpen.getChanges()));
    sessionStorage.setItem("filtermangelWartung", JSON.stringify(this.filterServiceWartung.getChanges()));
    this.window.onresize = undefined;
  }

  openObject(ost: number) {
    this.ostammid = ost;
  }
  openBemerkung(bemerkung: string, id: number) {
    this.mangelbemerkung = bemerkung;
    this.curID = id;
    this.showbemerkung = true;
  }

  openSonderinspektion(row: any) {
    this.curID = row.omazsid;
    this.curostammid = row.ostammid;

    let inData = {
      Status: 1,
      OSTAMMID: row.ostammid,
      OMAZSID: row.omazsid,
      OTYPID: row.h1,
      OSISPKID: 0,
      Mangel: row.mangel,
      aktive: true,
      beginn: BackendLocaleDatePipe.now,
      intervall: 10
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true){
        this.modal.close();
        this.dataRefresh?.emit(true);
        this.basicStore.reloadTabs({ emitter: this.reloadTabsIsReady});
      }
    });
  }

  saveMangelBemerkung() {
    this.showbemerkung = false;
    var req = {
      bemerkung: this.mangelbemerkung,
    };
    this.apiservice
      .saveMangelBemerkung(req, this.curID)
      .pipe(first()).subscribe(() => {
        setTimeout(() => {
          this.reloadMangel();
          this.tabChange();
        });
      });
    this.curID = 0;
    this.mangelbemerkung = "";
  }

  enlargeSidenav(event) {
    if (event.mode == "full") {
      this.sidenavenlarged = event.val;
    } else if (event.mode == "half") {
      this.split50 = event.val;
    } else if (event.mode == "progress") {
      this.showprogressbar = event.val;
      this.progressbars = {};
    } else if (event.mode == "hidden") {
      this.hidedetailview = event.val;
    } else if (event.mode == "close") {
      this.showdetailview = false;
      this.showprogressbar = false;
    }
  }

  protected closeMultiRueckModal(toReload: boolean) {
    setTimeout(() => {
      this.isMultiRueckModalOpen = false;
    });
    if (toReload) this.callReload();
  }

  protected closeMultiAuftragModal(toReload: boolean) {
    setTimeout(() => {
      this.isMultiAuftragModalOpen = false;
    });
    if (toReload) this.callReload();
  }

  private callReload() {
    this.basicStore.reloadTabs({ emitter: this.reloadTabsIsReady, reloadAnlagen: false });
    this.reloadMangel(false, this.reloadTabsIsReady);
  }

  handleScrub(event) {
    this.indexScrub = event;
  }

  showProgressData(data) {
    if (data) {
      this.progressbars = data;
      this.showprogressbar = true;
    }
  }

  enlargeProgressbar(val?) {
    if (val) {
      this.progressbarenlarged = true;
    } else {
      this.progressbarenlarged = !this.progressbarenlarged;
    }
  }

  filterVisibility: { [columnId: string]: boolean } = {};
  filterHidden: { [columnId: string]: boolean } = {};
  isFilterVisible(columnId: string): boolean {
    return this.filterVisibility[columnId] === true;
  }

  tabFilters: any = {};
  onFilterOpenChange(isOpen: boolean, column: any) {

    this.filterVisibility[column.id] = isOpen;
    if (!isOpen) {
      const activeTabName = Object.keys(this.activeTab).find(
        (key) => this.activeTab[key] === true
      );

      const all = _.cloneDeep((this.grid as any).stateProvider.filters._all);
      this.tableStateService.upsertTableByName(activeTabName, all);
    }
  }

  public getColumnWidth(columnId: string): number {
    const widthMapping: { [key: string]: number } = {
      status: 100,
      instandsetzung: 350,
      auftragnehmer: 350,
      erledigt_durch: 350,
      mangelfoto: 350,
      stellwerk: 250,
      Ort: 250,
      mangel: 250,
      bemerkung: 250,
      lage: 250,
      Bezirk: 170,
      messreg_bezirk: 170
    };

    return widthMapping[columnId] || (columnId.length * 7.5) + 45;
  }

  onAllFilterChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.dataAll = this.filterServiceAll.filter(columnFilterChange);
    }
  }

  onAuftragFilterChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.dataAuftrag = this.filterServiceAuftrag.filter(columnFilterChange);
    }
  }

  onDoneFilterChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.dataDone = this.filterServiceDone.filter(columnFilterChange);
    }
  }

  onOpenFilterChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.dataOpen = this.filterServiceOpen.filter(columnFilterChange);
    }
  }

  onWartungFilterChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.dataWartung = this.filterServiceWartung.filter(columnFilterChange);
    }
  }

  onFilterStoerungChange(columnFilterChange: ColumnFilterChange) {
    if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
      this.dataStoerung = this.filterServiceStoerung.filter(columnFilterChange);
    }
  }


  normalizeId(id: string): string {
    return id.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
  }
}
