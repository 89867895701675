import { NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClrCommonFormsModule, ClrDatagridModule, ClrDatepickerModule, ClrModalModule, ClrPopoverHostDirective, ClrSelectModule, ClrStopEscapePropagationDirective } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { SpinnerService } from 'src/app/services/Spinner/spinner.service';
import { HideIDColumnsPipe } from '../../../pipes/hide-idcolumns.pipe';
import { MrUnitTextPipe } from '../../../pipes/mrUnitText.pipe';
import { TablePrettyPrintPipe } from '../../../pipes/tablePrettyPrint.pipe';
import { PreviewThumbnailsComponent } from '../../_components/_shared/preview-thumbnails/preview-thumbnails.component';

@Component({
    selector: 'app-multi-rueckmeldung-modal',
    templateUrl: './multi-rueckmeldung-modal.component.html',
    styleUrls: ['./multi-rueckmeldung-modal.component.scss'],
    providers: [BackendLocaleDatePipe],
    imports: [
        ClrModalModule,
        NgIf,
        ClrDatagridModule,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrDatepickerModule,
        ClrCommonFormsModule,
        FormsModule,
        ClrSelectModule,
        NgFor,
        PreviewThumbnailsComponent,
        TitleCasePipe,
        TablePrettyPrintPipe,
        MrUnitTextPipe,
        HideIDColumnsPipe,
        MrTranslatePipe,
        BackendLocaleDatePipe,
    ],
    standalone: true
})
export class MultiRueckmeldungModalComponent implements OnInit {

  @Input() maengel: any[];

  /** @emits true when saved, for reload */
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();


  @ViewChild(PreviewThumbnailsComponent, { static: true })
    private preview: PreviewThumbnailsComponent
  ;
  private toReload = false;
  protected set isMultiRueckModalOpen(isOpen: boolean) {
    this._isMultiRueckModalOpen = isOpen;
    if (!isOpen) this.onClose.emit(this.toReload);
  }
  protected get isMultiRueckModalOpen() {
    return this._isMultiRueckModalOpen;
  }
  private _isMultiRueckModalOpen = false;

  protected set dateforall(newdate: string) {
    this.selectedValid.forEach( el => el.erledigt_am = newdate);
  }
  protected get dateforall() {
    return null;
  }
  protected isReady = false;
  protected selectedValid: any[] = [];
  protected firmen: any[];
  protected trial = false;

  constructor(
    private apiService: APIService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
    protected localeDate: BackendLocaleDatePipe
  ) {
    this.apiService
      .getFirmen()
      .pipe(first())
      .subscribe((firm: any) => {
        this.firmen = firm.rows;
      });
    this.trial = this.authService.getToken().trial;
  }

  ngOnInit() {
    if (this.maengel?.length) this.openModalIfValid();
    else this.isMultiRueckModalOpen = false;
  }

  private openModalIfValid() {
    for (const el of this.maengel) {
      if (!el.erledigt)
        this.selectedValid.push({...el, erledigt_am: BackendLocaleDatePipe.now });
    }

    if (this.selectedValid.length) {
      this.isMultiRueckModalOpen = true;
      setTimeout(() => {
        this.isReady = true;
      });
    }
    else {
      this.toastr.error(
        this.mrTranslate.transform(
          "Mängel dürfen nicht im Status Erledigt sein"
        ),
        this.mrTranslate.transform(
          "Keine gültigen Mängel ausgewählt",
        )
      );
      this.isMultiRueckModalOpen = false;
    }
  }

  protected setAllFirmen(event: Event) {
    const firma = (event.target as HTMLSelectElement).value;
    this.selectedValid.forEach( el => el.auftragnehmer = firma);
  }

  protected async sendMultiRueckmeldung() {
    this.spinner.enable();
    const isValid = this.selectedValid.every(
      mngl => !!mngl.erledigt_am && !!this.findFirmIdbyName(mngl.auftragnehmer)
    );

    if (isValid) {
      let files = [];
      try {
        files = await this.preview.packUnsavedFiles();
      } catch (er) {
        this.toastr.error(this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten"));
      }
      const rueckmeldungsItems = this.selectedValid.map( mngl => ({
        omazsid: mngl.omazsid,
        date: mngl.erledigt_am,
        instandsetzer: this.findFirmIdbyName(mngl.auftragnehmer),
      }));

      this.apiService
        .sendMultiRueckmeldung({ maengel: rueckmeldungsItems, bilder: files })
        .pipe(first())
        .subscribe(({success, message}: any) => {
          this.spinner.disable();

          if (success) {
            this.toastr.success(this.mrTranslate.transform("Eintrag erfolgreich gespeichert"));
            this.toReload = true;
            this._isMultiRueckModalOpen = false;
          } else this.toastr.error(message);
        });
    }
    else {
      this.spinner.disable();
      this.toastr.warning(
        this.mrTranslate.transform(
          "Bitte bei allen ausgewählten Mängeln Datum und Instandsetzer angeben."
        ));
    }
  }

  private findFirmIdbyName(name: string) {
    const id = this.firmen?.find(
      firm => name == [firm.Firma, firm.Abteilung, firm.Ort].join(", ")
    )?.ID;
    return id;
  }

  protected rueckmeldungColumns = [
    "pruefart",
    "mangel",
    "lage",
    "umfang",
    "mengeneinheit",
    "fehlerklasse",
    "instandsetzung",
    "lage",
    "betriebsgefahr",
    "auftrag",
    "termin",
    "auftrag_nr",
    "auftragsart",
    "auftragnehmer",
  ];
}
