import { Injectable } from '@angular/core';
import { APIService } from '../APIService/api.service';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { first } from 'rxjs';
import { TitleAndFileNames } from 'src/app/content/_components/_shared/mediaviewmodal/mediaviewmodal.component';

@Injectable({
  providedIn: 'root'
})
export class AnlagenMediaApiFuncService {

  constructor(
    private mrTranslate: MrTranslatePipe,
    private apiService: APIService,
  ) { }

  openAnlagenMedia(anlagen: string): TitleAndFileNames [] {
    let fileNamesWithTitle: TitleAndFileNames [] = [];

    if (anlagen) fileNamesWithTitle.push(
      {
        title: "Anlagen",
        fileNames: anlagen.split("|").filter((bild) => bild != ""),
      }
    );
    return fileNamesWithTitle;
  }

  getApiFunctionMedia = async (fileNames: string[] = []) => new Promise<Array<object>>(
    (resolve, reject) => {
      this.apiService.getAnlagenMediaAll(fileNames)
        .pipe(first())
        .subscribe(({success, files, error}: any) => {
          if (success) resolve(files);
          else reject(this.mrTranslate.transform("Die Dateien konnten nicht geladen werden!\n") + error);
        });
    }
  );

  getApiFunctionMediaBig = async (name: string = "") => new Promise<string>(
    (resolve, reject) => {
      this.apiService.getAnlagenMedia(name)
        .pipe(first())
        .subscribe((base64: any) => {
          if (base64) resolve(<string>base64);
          else reject(this.mrTranslate.transform("Die Datei konnte nicht geladen werden!"));
        });
    }
  );
}
