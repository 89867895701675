<clr-modal [(clrModalOpen)]="modalOpen" [clrModalSize]="'xl'" [clrModalClosable]="false" id="settingsmodal">

  <h3 class="modal-title" style="display:flex">{{'Einstellungen' | mrTranslate}}
    <a href="javascript:void(0)" (click)="modalOpen = false; modalOpenChange.emit(false);"
      style="margin-left:auto; color: inherit;">
      <clr-icon shape="times"></clr-icon>
    </a>
  </h3>

  <div class="modal-body" id="settingsModalBody">
    <div class="content-container">
      <clr-vertical-nav>
        <a clrVerticalNavLink href="javascript:void(0)" (click)="contentmode = 'allgemein'"
          [ngClass]="{'active': contentmode == 'allgemein'}">{{'Allgemein' | mrTranslate}}</a>
        <a clrVerticalNavLink href="javascript:void(0)" (click)="contentmode = 'bezeichnungen'"
          [ngClass]="{'active': contentmode == 'bezeichnungen'}">{{'Bezeichnungen' | mrTranslate}}</a>
        <a clrVerticalNavLink href="javascript:void(0)" (click)="contentmode = 'gismap'"
          [ngClass]="{'active': contentmode == 'gismap'}">{{'Gismap-API-Keys' | mrTranslate}}</a>
        <a clrVerticalNavLink href="javascript:void(0)" (click)="contentmode = 'kav'"
          [ngClass]="{'active': contentmode == 'kav'}">{{'KAV®' | mrTranslate}}</a>
      </clr-vertical-nav>

      <div [ngSwitch]="contentmode" class="content-area">
        <div *ngSwitchCase="'allgemein'">
          <form clrForm [formGroup]="optionGroup">
            <strong>{{'Firma' | mrTranslate}}</strong>
            <br />
            <div class="clr-row" *ngIf="logo; else loggedOut">
              <div class="clr-col-6">
                <div>{{'Logo' | mrTranslate}}</div>
                <div>
                  <img [src]="logo" (click)="fInput.click()">
                </div>
              </div>
            </div>
            <ng-template #loggedOut>
              <div class="clr-col-6">
                <div>{{'Logo' | mrTranslate}}</div>
                <div>
                  <clr-icon class="icon" shape="plus-circle" (click)="fInput.click()"></clr-icon>
                </div>
              </div>
            </ng-template>
            <br />

            <div class="clr-col-6">
              <clr-input-container>
                <label>{{'Name' | mrTranslate}}</label>
                <input clrInput placeholder="Kunde-Name" name="kunde" formControlName="kunde" />
              </clr-input-container>
              <clr-input-container>
                <label>{{'Ort' | mrTranslate}}</label>
                <input clrInput placeholder="Kunde-Ort" name="ort" formControlName="ort" />
              </clr-input-container>
              <br />
            </div>
            <div class="clr-col-12">
              <clr-checkbox-container class="margin-0-t">
                <clr-checkbox-wrapper>
                  <label>{{'Logo in Dokumentation übernehmen' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="OpLogoDok" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
              <br />

              <clr-radio-container clrInline class="margin-0-t">
                <label>{{'Logoposition' | mrTranslate}}</label>
                <clr-radio-wrapper>
                  <input type="radio" name="OpLogoPosition" clrRadio value="0" class="clr-radio"
                    formControlName="OpLogoPosition" />
                  <label>{{'links' | mrTranslate}}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                  <input type="radio" name="OpLogoPosition" clrRadio value="1" class="clr-radio"
                    formControlName="OpLogoPosition" />
                  <label>{{'rechts' | mrTranslate}}</label>
                </clr-radio-wrapper>
              </clr-radio-container>
            </div>
            <br />

            <input #fInput type="file" id="fileUpload" name="fileUpload" accept="image/png" style="display:none;"
              (change)="onLogoChanged($event)" />
          </form>
          <hr>
          <form clrForm [formGroup]="optionGroup">
            <strong> {{'Anlagenverantwortlicher (ALV)' | mrTranslate}} </strong>
            <div class="clr-col-4">

              <clr-checkbox-container class="margin-0-t">
                <clr-checkbox-wrapper>
                  <label>{{'ALV muss Prüfung bestätigen' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="optChecked_EBL" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
              <br />
              <clr-checkbox-container class="margin-0-t">
                <clr-checkbox-wrapper>
                  <label>{{'automatische Meldung' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="optMeldung_EBL" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
              <clr-input-container>
                <label>{{'Frist' | mrTranslate}}</label>
                <input clrInput type="number" formControlName="optMeldungTage_EBL" />
              </clr-input-container>


              <br />
            </div>
          </form>
          <hr>
          <form clrForm [formGroup]="optionGroup">
            <strong> {{'Fehlerklassen' | mrTranslate}} </strong>
            <div class="clr-col-4">
              <clr-input-container>
                <label>{{'min' | mrTranslate}}</label>
                <input clrInput type="number" formControlName="optMINNumFK" />
              </clr-input-container>
              <clr-input-container>
                <label>{{'max' | mrTranslate}}</label>
                <input clrInput type="number" formControlName="optMAXNumFK" />
              </clr-input-container>
              <clr-checkbox-container class="margin-0-t">
                <clr-checkbox-wrapper>
                  <label>{{('min' | mrTranslate) + ' = ' + ('Betriebsgefahr' | mrTranslate)}}</label>
                  <input type="checkbox" clrCheckbox formControlName="optFk1_IstGleich_BG" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
              <clr-checkbox-container class="margin-0-t">
                <clr-checkbox-wrapper>
                  <label>{{'nach unter änderbar' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="optDownChangeFK" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
            </div>
          </form>
          <hr>
          <form clrForm [formGroup]="optionGroup">
            <strong> {{'Allgemein' | mrTranslate}} </strong>
            <div class="clr-col-4">
              <clr-checkbox-container class="margin-0-t">
                <clr-checkbox-wrapper>
                  <label>{{'Export Auftrag Spezifikation' | mrTranslate}}</label>
                  <input type="checkbox" clrCheckbox formControlName="optAuftragSpezExport" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
              <div class="clr-form-control">
                <label for="select-length" class="clr-control-label">Select length</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <select id="select-length" class="clr-select" formControlName="optUnitLength">
                      <option *ngFor="let item of lengthUnitItems; index as i;" [value]="i">{{lengthUnitItems[i]}}
                      </option>
                    </select>
                    <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                  </div>
                </div>
              </div>
              <div class="clr-form-control">
                <label for="select-width" class="clr-control-label">Select width</label>
                <div class="clr-control-container">
                  <div class="clr-select-wrapper">
                    <select id="select-width" class="clr-select" formControlName="optUnitWidth">
                      <option *ngFor="let item of measurementUnitItems; index as i;" [value]="i">
                        {{measurementUnitItems[i]}}</option>

                    </select>
                    <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                  </div>
                </div>
              </div>

              <!-- <clr-datalist-container id="settings-measurement-unit-selection">
                <label>Messwerteinheit</label>
                <input minlength="4" name="MeasurementOption" required formControlName="optUnitWidth"
                       clrDatalistInput [disabled]="disabled"/>
                <datalist>
                    <option *ngFor="let item of measurementUnitItems; index as i;" [value]="i">{{measurementUnitItems[i]}}</option>
                </datalist>
                <clr-control-error>There was an error</clr-control-error>
            </clr-datalist-container> -->
            </div>
          </form>
          <hr />
          <form clrForm [formGroup]="optionGroup">


            <strong> {{'Anmeldung' | mrTranslate}} </strong>

            <div class="clr-col-8">
              <clr-radio-container clrInline>
                <label style="display: table;">{{'Authentifizierung' | mrTranslate}}</label>
                <clr-radio-wrapper>
                  <input type="radio" clrRadio formControlName="optAuthentType" [value]="0"
                    (change)="onAnmeldChanged($event,'mrpro')" />
                  <label>{{'MR.pro®'}}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                  <input type="radio" clrRadio formControlName="optAuthentType" [value]="2"
                    (change)="onAnmeldChanged($event,'msal')" />
                  <label>{{'AzureAd'}}</label>
                </clr-radio-wrapper>
              </clr-radio-container>

              <ng-container *ngIf="optAuthentType == 0;">
                <br />
                <clr-checkbox-container class="margin-0-t">
                  <clr-checkbox-wrapper>
                    <label>{{'2‑Faktor-Authentifizierung mit Google Authenticator' | mrTranslate}}</label>
                    <input clrCheckbox type="checkbox" formControlName="tfa" (change)="changeStatus($event)" />
                  </clr-checkbox-wrapper>
                </clr-checkbox-container>
              </ng-container>
              <ng-container *ngIf="optAuthentType == 2;">
                <clr-input-container>
                  <label>{{'App Id' | mrTranslate}}</label>
                  <input clrInput placeholder="App Id" name="ADAppid" formControlName="ADAppid" />
                </clr-input-container>
                <clr-input-container>
                  <label>{{'Tenant' | mrTranslate}}</label>
                  <input clrInput placeholder="Tenant" name="ADTenant" formControlName="ADTenant" />
                </clr-input-container>
              </ng-container>

              <br />
            </div>
          </form>

          <hr />

          <form clrForm [formGroup]="optionGroup">
            <strong> {{'Werksabnahme' | mrTranslate}} </strong>
            <div class="clr-col-6">
              <clr-toggle-wrapper>
                <input type="checkbox" clrToggle value="false" formControlName="optUseFAT" />
                <label>{{'Werksabnahme aktivieren' | mrTranslate}}</label>
              </clr-toggle-wrapper>
            </div>
          </form>


          <hr>
          <form clrForm [formGroup]="optionGroup">
            <div class="clr-col-4">
              <button class="btn btn-primary btn-sm margin-20-t" type="submit" [disabled]="trial"
                (click)="saveINI()">{{'Speichern' | mrTranslate}}</button>
            </div>
          </form>

        </div>
        <div *ngSwitchCase="'bezeichnungen'">
          <form clrForm>
            <clr-datagrid class="datagrid-compact">
              <clr-dg-column>{{'Feld' | mrTranslate}}</clr-dg-column>
              <clr-dg-column>{{'Bezeichnung' | mrTranslate}}</clr-dg-column>
              <clr-dg-column>{{'Anzeigen' | mrTranslate}}</clr-dg-column>
              <clr-dg-column>{{'Pflichtfeld' | mrTranslate}}</clr-dg-column>
              <clr-dg-row *clrDgItems="let item of kundenbezeichnung.rows;" [clrDgItem]="item">
                <ng-container *ngIf="item.FeldName.indexOf('KNField') == -1; else clientcell">
                  <ng-container *ngIf="item.FeldName.indexOf('Eigentuemer') == -1; else eigentuemer">
                    <clr-dg-cell> {{ item.FeldName | mrTranslate}} </clr-dg-cell>
                  </ng-container>
                  <ng-template #eigentuemer>
                    <clr-dg-cell> {{ 'Eigentümer' | mrTranslate }} </clr-dg-cell>
                  </ng-template>
                </ng-container>
                <ng-template #clientcell>
                  <clr-dg-cell> {{ replaceKundenfeld(item.FeldName) }} </clr-dg-cell>
                </ng-template>

                <clr-dg-cell> {{ item.KBezeichnung | tablePrettyPrint | dotDotDot:30}} </clr-dg-cell>
                <clr-dg-cell [innerHTML]="item.KShow | tablePrettyPrint"
                  [title]="item.KShow | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                <clr-dg-cell [innerHTML]="item.KPflicht | tablePrettyPrint"
                  [title]="item.KPflicht | tablePrettyPrint | dotDotDot:30">
                </clr-dg-cell>
              </clr-dg-row>
              <clr-dg-detail *clrIfDetail="let detail">
                <clr-dg-detail-header>{{ detail.FeldName | mrTranslate }}</clr-dg-detail-header>
                <clr-dg-detail-body>
                  <clr-input-container style="margin-left: 3px">
                    <label>{{'Bezeichnung' | mrTranslate}}</label>
                    <input clrInput type="text" value="{{detail.KBezeichnung}}" #bez />
                  </clr-input-container>
                  <br />
                  <clr-checkbox-container class="margin-0-t">
                    <clr-checkbox-wrapper>
                      <label>{{'Anzeigen' | mrTranslate}}</label>
                      <input type="checkbox" clrCheckbox [checked]="detail.KShow == true" #show />
                    </clr-checkbox-wrapper>
                  </clr-checkbox-container>
                  <br />
                  <clr-checkbox-container class="margin-0-t">
                    <clr-checkbox-wrapper>
                      <label>{{'Pflichtfeld' | mrTranslate}}</label>
                      <input type="checkbox" clrCheckbox [checked]="detail.KPflicht == true" #must />
                    </clr-checkbox-wrapper>
                  </clr-checkbox-container>
                  <br />
                  <br />
                  <div class="clr-row">
                    <div class="clr-col">
                      <button class="btn btn-primary" type="submit" (click)="saveBezeichnung(detail.ID)">{{'Speichern' |
                        mrTranslate}}</button>
                    </div>
                  </div>
                </clr-dg-detail-body>
              </clr-dg-detail>
            </clr-datagrid>

          </form>
        </div>
        <div *ngSwitchCase="'gismap'">
          <form clrForm>
            <clr-input-container>
              <label>{{'Bing'}}</label>
              <input clrInput placeholder="Bing-API-Key" name="bing" [(ngModel)]="apiKeys.bing" />
            </clr-input-container>
            <clr-input-container>
              <label>{{'Mapbox'}}</label>
              <input clrInput placeholder="Mapbox-API-Key" name="mapbox" [(ngModel)]="apiKeys.mapbox" />
            </clr-input-container>
            <button class="btn btn-primary btn-sm margin-20-t" type="submit" [disabled]="trial"
              (click)="saveApiKeys()">{{'Speichern' |
              mrTranslate}}</button>
          </form>
        </div>
        <div *ngSwitchCase="'kav'">
          <div class="clr-row">
            <div class="clr-col">
              <form clrForm id="kavForm" [formGroup]="kavGroup">
                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{('Gleis' | mrTranslate) + ': ' + ('Wichtung offener Oberbau' | mrTranslate)}}</h5>
                </div>
                <div class="d-f w-100-p">
                  <div style="min-width:45%;">
                    <clr-input-container class="margin-0-t">
                      <label>{{'Schiene' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisOffenerOberbauSchiene" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Schwellen' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisOffenerOberbauSchwellen" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Befestigung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisOffenerOberbauBefestigung" />
                    </clr-input-container>
                  </div>
                  <div style="min-width:45%;">
                    <clr-input-container class="margin-0-t">
                      <label>{{'Bettung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisOffenerOberbauBettung" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Unterbau' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisOffenerOberbauUnterbau" />
                    </clr-input-container>
                  </div>
                  <div style="max-width:10%;">
                    <p class="text-center">{{'Summe' | mrTranslate}} <br><span class="badge"
                        [ngClass]="getSum('gleisOffenerOberbau') > 1 ? 'badge-danger' : 'badge-blue'">{{getSum('gleisOffenerOberbau')}}</span>
                    </p>
                  </div>

                </div>
                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{('Gleis' | mrTranslate) + ': ' + ('Wichtung geschlossener Oberbau' | mrTranslate)}}</h5>
                </div>
                <div class="d-f w-100-p">
                  <div style="min-width: 45%;">
                    <clr-input-container class="margin-0-t">
                      <label>{{'Schiene' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisGeschlossenerOberbauSchiene" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Schwellen' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisGeschlossenerOberbauSchwellen" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Befestigung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisGeschlossenerOberbauBefestigung" />
                    </clr-input-container>
                  </div>

                  <div style="min-width: 45%;">
                    <clr-input-container class="margin-0-t">
                      <label>{{'Bettung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisGeschlossenerOberbauBettung" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Unterbau' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisGeschlossenerOberbauUnterbau" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Eindeckung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="gleisGeschlossenerOberbauEindeckung" />
                    </clr-input-container>
                  </div>

                  <div style="max-width:10%;">
                    <p class="text-center">{{'Summe' | mrTranslate}} <br><span class="badge"
                        [ngClass]="getSum('gleisGeschlossenerOberbau') > 1 ? 'badge-danger' : 'badge-blue'">{{getSum('gleisGeschlossenerOberbau')}}</span>
                    </p>
                  </div>

                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{'Weichen: Wichtung' | mrTranslate}}</h5>
                </div>
                <div class="d-f w-100-p">
                  <div style="min-width: 45%;">
                    <clr-input-container class="margin-0-t">
                      <label>{{'Schiene' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenSchiene" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Schwellen' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenSchwellen" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Befestigung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenBefestigung" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Bettung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenBettung" />
                    </clr-input-container>
                  </div>

                  <div style="min-width: 45%;">

                    <clr-input-container class="margin-0-t">
                      <label>{{'Unterbau' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenUnterbau" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Zungenvorrichtung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenZungenvorrichtung" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Herzstück' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenHerzstück" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Eindeckung' | mrTranslate}}</label>
                      <input clrInput type="number" formControlName="weichenEindeckung" />
                    </clr-input-container>
                  </div>

                  <div style="max-width:10%;">
                    <p class="text-center">{{'Summe' | mrTranslate}} <br><span class="badge"
                        [ngClass]="getSum('weichen') > 1 ? 'badge-danger' : 'badge-blue'">{{getSum('weichen')}}</span>
                    </p>
                  </div>

                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{'Schwellenbewertung' | mrTranslate}}</h5>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b" *ngIf="kavSettings != {} && kavSettings.schwellen">
                  <table class="table table-compact margin-0-t editableTable" style="max-width: 50%;">
                    <thead>
                      <tr>
                        <th class="left">{{'Schwellenart' | mrTranslate}}</th>
                        <th class="left">{{'max Liegedauer' | mrTranslate}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let schwelle of kavSettings.schwellen">
                        <td class="left">{{schwelle.Schwellenart}}</td>
                        <td class="left">
                          <input clrInput type="number" [(ngModel)]="schwelle['max. Liegedauer']"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{'Schwellenhöhenbewertung' | mrTranslate}}</h5>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <table class="table table-compact margin-0-t editableTable" style="max-width: 60%;">
                    <thead>
                      <tr>
                        <th class="left">{{'Schienenform' | mrTranslate}}</th>
                        <th class="left">{{'Schienenhöhe' | mrTranslate}}</th>
                        <th class="left">{{'max Höhenverschleiß' | mrTranslate}}</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="kavSettings.schienenhoehe">
                      <tr *ngFor="let schiene of kavSettings.schienenhoehe">
                        <td class="left">{{schiene.Schienenform}}</td>
                        <td class="left">
                          <input clrInput type="number" [(ngModel)]="schiene['Schienenhöhe']"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td class="left">
                          <input clrInput type="number" [(ngModel)]="schiene['max. Höhenverschleiß']"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{'Grenzwert für Anlagenklassen' | mrTranslate}}</h5>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <table class="table table-compact margin-0-t editableTable" style="max-width: 70%;">
                    <thead>
                      <tr>
                        <th class="left">{{'Anlagenklasse' | mrTranslate}}</th>
                        <th class="left">{{'Kategorie 3' | mrTranslate}}</th>
                        <th class="left">{{'Kategorie 2' | mrTranslate}}</th>
                        <th class="left">{{'Kategorie 1' | mrTranslate}}</th>
                        <th class="left">{{'geplante Nutzung' | mrTranslate}}</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr *ngFor="let anlage of kavSettings.anlagenklasse">
                        <td class="left">{{anlage.ak}}</td>
                        <td class="left">
                          <input clrInput type="number" [(ngModel)]="anlage['Kat3']"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td class="left">
                          <input clrInput type="number" [(ngModel)]="anlage['Kat2']"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td class="left">
                          <input clrInput type="number" [(ngModel)]="anlage['Kat1']"
                            [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td class="left">
                          <input clrInput type="number" [(ngModel)]="anlage['DNutzung']"
                            [ngModelOptions]="{standalone: true}" />
                        </td>

                      </tr>

                    </tbody>
                  </table>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{'Farbverwaltung Anlagenklassen' | mrTranslate}}</h5>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b" style="flex-direction: column;">
                  <div class="d-f">
                    <input type="color" style="display:none;" formControlName="kavKat1Color" #colorIn>
                    <clr-icon shape="circle" class="is-solid cursor-pointer"
                      [ngStyle]="{'color': kavGroup.value.kavKat1Color}" (click)="colorFocusInput(colorIn)">
                    </clr-icon>
                    <span class="margin--3-t margin-3-l">{{('Kategorie' | mrTranslate) + ' 1'}}</span>
                  </div>
                  <div class="d-f margin-10-t">
                    <input type="color" style="display:none;" formControlName="kavKat2Color" #colorIn2>
                    <clr-icon shape="circle" class="is-solid cursor-pointer"
                      [ngStyle]="{'color': kavGroup.value.kavKat2Color}" (click)="colorFocusInput(colorIn2)">
                    </clr-icon>
                    <span class="margin--3-t margin-3-l">{{('Kategorie' | mrTranslate) + ' 2'}}</span>
                  </div>
                  <div class="d-f margin-10-t">
                    <input type="color" style="display:none;" formControlName="kavKat3Color" #colorIn3>
                    <clr-icon shape="circle" class="is-solid cursor-pointer"
                      [ngStyle]="{'color': kavGroup.value.kavKat3Color}" (click)="colorFocusInput(colorIn3)">
                    </clr-icon>
                    <span class="margin--3-t margin-3-l">{{('Kategorie' | mrTranslate) + ' 3'}}</span>
                  </div>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <h5>{{'Anzeigeoptionen' | mrTranslate}}</h5>
                </div>

                <div class="d-f w-100-p margin--12-l margin-7-b">
                  <div style="min-width: 30%;">
                    <clr-checkbox-container class="margin-0-t">
                      <clr-checkbox-wrapper>
                        <label>{{'KAV anzeigen' | mrTranslate}}</label>
                        <input type="checkbox" clrCheckbox formControlName="anzeigeKAV" />
                      </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                  </div>
                  <div style="min-width: 30%;">
                    <clr-checkbox-container class="margin-0-t">
                      <clr-checkbox-wrapper>
                        <label>{{'KAV in Dok schreiben' | mrTranslate}}</label>
                        <input type="checkbox" clrCheckbox formControlName="anzeigeKAVDok" />
                      </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                  </div>

                  <div style="min-width: 40%;">
                    <clr-radio-container clrInline class="margin-0-t">
                      <label style="display: table;">Anzeigetyp</label>
                      <clr-radio-wrapper>
                        <input type="radio" clrRadio formControlName="anzeigeTyp" [value]="0" />
                        <label>{{'KAV' | mrTranslate}}</label>
                      </clr-radio-wrapper>
                      <clr-radio-wrapper>
                        <input type="radio" clrRadio formControlName="anzeigeTyp" [value]="1" />
                        <label>{{'SAX'}}</label>
                      </clr-radio-wrapper>
                    </clr-radio-container>
                  </div>

                </div>
              </form>
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col">
              <button class="btn btn-primary" type="submit" [disabled]="trial" (click)="saveKAVSettings()">
                {{'Speichern' | mrTranslate}}
              </button>
            </div>
          </div>
        </div>
        <div *ngSwitchDefault>
          Einstellung folgt.
        </div>
      </div>
    </div>
  </div>

</clr-modal>