<div class="overlay-inner-wrapper">
  <div id="stoerungWrapper">
    <div class="clr-row nxm h-100">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h3 class="mt-0">
            {{(operation == 'new' ? 'Störung anlegen' : 'Störung bearbeiten') | mrTranslate}}</h3>
          <button *ngIf="operation == 'edit'" class="btn btn-sm margin-15-l margin-6-b" style="display: inline-block"
            (click)="switchMode('new')">
            <clr-icon shape="plus"></clr-icon> {{'Störung anlegen' | mrTranslate}}
          </button>
        </div>
        <div class="inline-block float-right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
      <div class="clr-col-12 clr-row nxm" style="height: calc(100% - 1.2rem);">
        <div class="clr-col-12 clr-col-lg-6">
          <!-- id="stoerungform" -->
          <form class="width100" clrForm [formGroup]="stoerungGroup" (ngSubmit)="onSubmit()">
            <clr-date-container>
              <label class="clr-col-md-4">{{'Datum der Störung' | mrTranslate}}</label>
              <input #dateModel class="clr-col-md-8" type="date" name="date" clrDate
                [ngModel]="dateControl | localeDate"
                (ngModelChange)="dateControl = localeDate.tosql($event)"
                [ngModelOptions]="{standalone: true, updateOn: 'blur'}"
                [disabled]="stoerungGroup.disabled">
            </clr-date-container>
            <clr-input-container>
              <label class="clr-col-md-4">{{'Zeit der Störung' | mrTranslate}}</label>
              <input class="clr-col-md-8" clrInput placeholder="00:00" type="time" name="time" formControlName="time" />
            </clr-input-container>

            <clr-datalist-container>
              <label class="clr-col-md-4 clr-control-label">{{'gemeldet durch' | mrTranslate}}</label>
              <input clrDatalistInput formControlName="gemeldetDurch" list="gemeldetDurchList" placeholder="{{'Name' | mrTranslate}}" name="gemeldetDurch" type="search"
                class="clr-col-md-8" />
              <datalist id="gemeldetDurchList">
                <option *ngFor="let name of gemeldetDurch" [value]="name">
              </datalist>
            </clr-datalist-container>

            <clr-datalist-container>
              <label class="clr-col-md-4 clr-control-label">{{'Art der Störung' | mrTranslate}}</label>
              <input clrDatalistInput formControlName="artDerStoerung" list="artDerStoerungList" placeholder="{{'Störung' | mrTranslate}}" name="artDerStoerung" type="search"
                class="clr-col-md-8" />
              <datalist id="artDerStoerungList">
                <option *ngFor="let name of stoerart" [value]="name">
              </datalist>
            </clr-datalist-container>

            <clr-radio-container clrInline class="parent" id="schaeden">
              <label class="clr-col-md-2">{{'Schäden' | mrTranslate}}?</label>
              <clr-radio-wrapper>
                <input type="radio" clrRadio name="schaeden" required [value]="true" formControlName="schaeden" />
                <label>{{'Ja' | mrTranslate}}</label>
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                <input type="radio" clrRadio name="schaeden" required [value]="false" formControlName="schaeden" />
                <label>{{'Nein' | mrTranslate}}</label>
              </clr-radio-wrapper>
            </clr-radio-container>
            <div class="indent" *ngIf="stoerungGroup.value.schaeden">
              <div *ngIf="stoerungGroup.errors?.['not-in-Direction'] as err"
                class="clr-row nxm clr-col-auto">
                <clr-alert class="w-100-p" clrAlertType="danger" [clrAlertClosable]="false">
                  <clr-alert-item>
                    <span class="alert-text">
                      {{ "Der Startmeter muss " + (err.upwards ? "kleiner" : "größer") + " als der Endmeter sein" | mrTranslate }}
                    </span>
                  </clr-alert-item>
                </clr-alert>
              </div>
              <div class="comboboxcontainer clr-form-control clr-row" *ngIf="(typ_Line_Point | objtype) == 'line'">
                <label class="clr-col-md-4 clr-control-label">{{'Start- und Endmeter' | mrTranslate}}</label>
                <div class="clr-col-md-8 clr-row nxm nxp">
                  <div class="clr-control-container clr-col-6"
                    [ngClass]="{'clr-error': startmeter.errors && (startmeter.errors.min || startmeter.errors.max)}">
                    <div class="clr-input-wrapper">
                      <input class="clr-input clr-col-md-6" type="number" [placeholder]="'von ' + (startm !== undefined ? startm : '0') + ' [m]'" name="startmeter"
                        formControlName="startmeter" />
                      <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="clr-subtext" *ngIf="startmeter.errors && (startmeter.errors.min || startmeter.errors.max)">
                      Bitte einen Wert von {{ startm }} - {{ endm - 1 }} eingeben
                    </span>
                  </div>
                  <div class="clr-control-container clr-col-6"
                    [ngClass]="{'clr-error': endmeter.errors && (endmeter.errors.min || endmeter.errors.max)}">
                    <div class="clr-input-wrapper">
                      <input class="clr-input clr-col-md-6" type="number" [placeholder]="'bis ' + (endm !== undefined ? endm : '987') + ' [m]'" name="endmeter"
                        formControlName="endmeter" />
                      <clr-icon class="clr-validate-icon" shape="exclamation-circle"></clr-icon>
                    </div>
                    <span class="clr-subtext" *ngIf="endmeter.errors && (endmeter.errors.min || endmeter.errors.max)">
                      Bitte einen Wert von {{ startm+ 1 }} - {{ endm }} eingeben
                    </span>
                  </div>
                </div>
              </div>

              <clr-datalist-container>
                <label class="clr-col-md-4 clr-control-label">{{'Prüfart' | mrTranslate}}</label>
                <input clrDatalistInput formControlName="pruefart" list="pruefartList" placeholder="{{'Prüfart' | mrTranslate}}" name="pruefart" type="search"
                  class="clr-col-md-8" />
                <datalist id="pruefartList">
                  <option *ngFor="let obj of pruefart" [value]="obj.val">
                </datalist>
              </clr-datalist-container>

              <clr-datalist-container>
                <label class="clr-col-md-4 clr-control-label">{{'Mangel' | mrTranslate}}</label>
                <input clrDatalistInput formControlName="mangel" list="mangelList" placeholder="{{'Mangel' | mrTranslate}}" name="mangel" type="search"
                  class="clr-col-md-8" />
                <datalist id="mangelList">
                  <option *ngFor="let obj of mangel" [value]="obj.val">
                </datalist>
              </clr-datalist-container>

              <clr-input-container clrInline>
                <label class="clr-col-md-4">{{'Umfang' | mrTranslate}}</label>
                <input class="clr-col-md-8" clrInput placeholder="4.1268..." name="umfang" formControlName="umfang" />
              </clr-input-container>

              <clr-datalist-container>
                <label class="clr-col-md-4 clr-control-label">{{'Einheit' | mrTranslate}}</label>
                <input clrDatalistInput formControlName="einheit" list="einheitList" placeholder="{{'Einheit' | mrTranslate}}" name="einheit" type="search"
                  class="clr-col-md-8" />
                  <datalist id="einheitList">
                    <option *ngFor="let name of einheit" [value]="name">
                  </datalist>
              </clr-datalist-container>

              <clr-checkbox-container>
                <label class="clr-col-md-4">{{'Betriebsgefahr' | mrTranslate}}</label>
                <clr-checkbox-wrapper class="clr-col-md-8">
                  <input type="checkbox" clrCheckbox [value]="true" name="betriebsgefahr"
                    formControlName="betriebsgefahr" />
                </clr-checkbox-wrapper>
              </clr-checkbox-container>
            </div>

            <clr-radio-container clrInline class="parent">
              <label class="clr-col-md-2">{{'Ursache bekannt?' | mrTranslate}}</label>
              <clr-radio-wrapper>
                <input type="radio" clrRadio name="ursachebekannt" required [value]="true"
                  formControlName="ursachebekannt" />
                <label>{{'Ja' | mrTranslate}}</label>
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                <input type="radio" clrRadio name="ursachebekannt" required [value]="false"
                  formControlName="ursachebekannt" />
                <label>{{'Nein' | mrTranslate}}</label>
              </clr-radio-wrapper>
            </clr-radio-container>
            <div class="indent" *ngIf="stoerungGroup.value.ursachebekannt">
              <div class="comboboxcontainer clr-form-control clr-row">
                <label class="clr-col-md-4 clr-control-label">{{'Ursache' | mrTranslate}}</label>
                <div class="clr-control-container clr-col-md-8">
                  <input class="clr-input combobox" placeholder="{{'Ursache' | mrTranslate}}" type="search" name="ursache"
                    formControlName="ursache" list="ursacheList" />
                </div>
                <datalist id="ursacheList">
                  <option *ngFor="let name of ursache" [value]="name">
                </datalist>
              </div>
            </div>
            <div id="massnahmen">
              <div id="addMassnahmeForm">
                <div class="clr-col-3">
                  <div class="comboboxcontainer clr-form-control clr-row">
                    <div class="clr-control-container clr-col">
                      <input class="clr-input combobox" [attr.placeholder]="'Was' | mrTranslate" type="search" name="Was?"
                        [(ngModel)]="newMassnahme.was" [ngModelOptions]="{standalone: true}" list="wasList" />
                    </div>
                    <datalist id="wasList">
                      <option *ngFor="let name of was" [value]="name">
                    </datalist>
                  </div>
                </div>
                <div class="clr-col-3">
                  <div class="comboboxcontainer clr-form-control clr-row">
                    <div class="clr-control-container clr-col">
                      <input class="clr-input combobox" [attr.placeholder]="'Wer' | mrTranslate" type="search" name="Wer?"
                        [(ngModel)]="newMassnahme.wer" [ngModelOptions]="{standalone: true}" list="werList" />
                    </div>
                    <datalist id="werList">
                      <option *ngFor="let name of wer" [value]="name">
                    </datalist>
                  </div>
                </div>
                <div class="clr-col-3">
                  <input class="" type="date" name="date" clrDate
                    [ngModel]="newMassnahme.bisWann | localeDate"
                    (ngModelChange)="newMassnahme.bisWann = localeDate.tosql($event)"
                    [ngModelOptions]="{standalone: true, updateOn: 'blur'}">
                </div>
                <div class="clr-col-2">
                  <input class="clr-col-12" clrInput placeholder="00:00" name="time" [(ngModel)]="newMassnahme.zeit"
                    [ngModelOptions]="{standalone: true}" type="time" />
                </div>
                <div class="clr-col-1">
                  <button type="button" class="btn"
                    (click)="saveMassnahme(newMassnahme.type == 'insert' ? 'new' : 'edit')">
                    <clr-icon [attr.shape]="newMassnahme.type == 'insert' ? 'plus' : 'check'"></clr-icon>
                  </button>
                </div>
              </div>
              <clr-datagrid class="datagrid-compact">
                <clr-dg-column style="width: 40px; min-width: 40px;" *ngIf="operation == 'new'">
                  <clr-icon shape="times"></clr-icon>
                </clr-dg-column>
                <clr-dg-column>{{'Was' | mrTranslate}}</clr-dg-column>
                <clr-dg-column>{{'Wer' | mrTranslate}}</clr-dg-column>
                <clr-dg-column>{{'bis Wann' | mrTranslate}}</clr-dg-column>
                <clr-dg-column style="width: 100px; min-width: 100px;">{{'Zeit' | mrTranslate}}</clr-dg-column>

                <clr-dg-row *ngFor="let mass of massnahmen; let i = index;"
                  [ngClass]="{'selectedrow': newMassnahme.id && mass.id && mass.id == newMassnahme.id}">
                  <clr-dg-cell style="width: 40px; min-width: 40px;" *ngIf="operation == 'new'">
                    <clr-icon shape="times" class="cursor-pointer" (click)="deleteMassnahme(i)"></clr-icon>
                  </clr-dg-cell>
                  <clr-dg-action-overflow *ngIf="operation == 'edit'">
                    <button type="button" class="action-item" (click)="editMassRow(mass)">{{'Bearbeiten' |
                      mrTranslate}}</button>
                  </clr-dg-action-overflow>
                  <clr-dg-cell>
                    {{mass.was}}
                  </clr-dg-cell>
                  <clr-dg-cell>
                    {{mass.wer}}
                  </clr-dg-cell>
                  <clr-dg-cell>
                    {{ mass.bisWann | tablePrettyPrint }}
                  </clr-dg-cell>
                  <clr-dg-cell style="width: 100px; min-width: 100px;">
                    {{mass.zeit}}
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>{{massnahmen?.length ?? 0}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
              </clr-datagrid>

              <button class="btn btn-primary" [disabled]="trial" type="submit">{{(operation == 'new' ? 'Störung anlegen' :
                'Störung speichern') | mrTranslate}}</button>
            </div>
          </form>
        </div>
        <div class="clr-col-12 clr-col-lg-6 clr-row nxm" [class.h-100]="history?.rows?.length > 0">
          <div *ngIf="stoerungGroup.value.schaeden"
            class="clr-col-12 margin-24-b" style="max-height: 50%;">
            <app-preview-thumbnails
              [drag_drop]="true"
              [getBigFileFnc]="media.getApiFunctionMediaBig"
              (deleteFile)="deleteDoc($event)">
            </app-preview-thumbnails>
          </div>
          <div *ngIf="history?.rows?.length" class="clr-col-12" style="height: 50%;">
            <h3>{{'Historie' | mrTranslate}}</h3>
            <clr-datagrid *ngIf="history && history['columns']" class="datagrid-compact" style="height: calc(100% - 48px);">
              <clr-dg-column *ngFor="let column of history['columns']" [clrDgField]="column.id"
                [ngStyle]="{'min-width': column.id == 'username' ? '130px' : '4rem'}">
                <ng-container
                  *clrDgHideableColumn="{hidden: column.id | hideIDColumns: ['startmeter','endmeter', 'bild', 'rueckbilder']}">
                  {{ camelcase(column.id) | mrTranslate }}
                </ng-container>
              </clr-dg-column>
              <clr-dg-row *clrDgItems="let row of history['rows']" [ngClass]="{'selectedrow': row.omazsid == editId}">
                <clr-dg-action-overflow>
                  <button class="action-item" (click)="editRow(row)">{{'Bearbeiten' | mrTranslate}}</button>
                  <button type="button" class="action-item" (click)="emailStoerung(row)">{{'Email' |
                    mrTranslate}}</button>
                </clr-dg-action-overflow>
                <clr-dg-cell *ngFor="let cell of history['columns']" [hidden]="cell.id | hideIDColumns"
                  [innerHTML]="row[cell.id] | tablePrettyPrint"
                  class="name-cell"></clr-dg-cell>
              </clr-dg-row>
              <clr-dg-footer>{{ history?.rows?.length ?? 0 }} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
            </clr-datagrid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
