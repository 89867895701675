<div class="main-container" style="padding: 10px;">
  <div class="btn-group btn-icon">

    <button *ngIf="userCanEdit" class="btn" (click)="neu()">
      <clr-icon [attr.title]="'Neu' | mrTranslate" size="20" shape="file"></clr-icon>
    </button>
    <button *ngIf="userCanEdit" class="btn" (click)="save()" [disabled]="this.stage.getLayers().length == 0">
      <clr-icon [attr.title]="'Speichern' | mrTranslate" size="20" shape="floppy"></clr-icon>
    </button>
    <button class="btn" (click)="saveAsPDF()" [disabled]="this.stage.getLayers().length == 0">
      <clr-icon [attr.title]="'PDF erstellen' | mrTranslate" size="20" shape="pdffile"></clr-icon>
    </button>
    <button class="btn" (click)="saveAsImage()" [disabled]="this.stage.getLayers().length == 0">
      <clr-icon [attr.title]="'als Bild speichern...' | mrTranslate" size="20" shape="image"></clr-icon>
    </button>

    <button class="btn" (click)="showHistory()">
      <clr-icon [attr.title]="'Historie öffnen' | mrTranslate" size="20" shape="history"></clr-icon>
    </button>

    <button *ngIf="userCanEdit" class="btn" (click)="undo()" [disabled]="!undo_flag">
      <clr-icon [attr.title]="'Zurück' | mrTranslate" size="20" shape="undo"></clr-icon>
    </button>
    <button *ngIf="userCanEdit" class="btn" (click)="redo()" [disabled]="!redo_flag">
      <clr-icon [attr.title]="'Wiederholen' | mrTranslate" size="20" shape="redo" ></clr-icon>
    </button>

    <!-- <button class="btn" (click)="switch()">
      <clr-icon [attr.title]="'Ansicht drehen' | mrTranslate" size="20" shape="switch"></clr-icon>
    </button> -->

    <button *ngIf="userCanEdit" class="btn" (click)="showTemplates()" [disabled]="hasTemplate">
      <clr-icon [attr.title]="'Vorlage öffnen' | mrTranslate" size="20" shape="library"></clr-icon>
    </button>

    <button *ngIf="userCanEdit" class="btn" (click)="line()">
      <clr-icon [attr.title]="'Linie' | mrTranslate" size="20" shape="drawline"></clr-icon>
    </button>

    <button *ngIf="userCanEdit" class="btn" (click)="freeline()">
      <clr-icon [attr.title]="'Stift' | mrTranslate" size="20" shape="drawfree"></clr-icon>
    </button>

    <button *ngIf="userCanEdit" class="btn" (click)="rectangle()">
      <clr-icon [attr.title]="'Rechteck' | mrTranslate" size="20" shape="drawrect"></clr-icon>
    </button>
    <button *ngIf="userCanEdit" class="btn" (click)="arc()">
      <clr-icon [attr.title]="'Kurve' | mrTranslate" size="20" shape="drawarc"></clr-icon>
    </button>
    <button *ngIf="userCanEdit" class="btn" (click)="point()">
      <clr-icon [attr.title]="'Punkt' | mrTranslate" size="20" shape="drawpoint"></clr-icon>
    </button>
    <button *ngIf="userCanEdit" class="btn" (click)="circle()">
      <clr-icon [attr.title]="'Kreis' | mrTranslate" size="20" shape="circle"></clr-icon>
    </button>

    <button *ngIf="userCanEdit" class="btn">
      <input type="color" id="colorPicker" name="color" [(ngModel)]="curcolor" (input)="onColorChange($event.target.value)" (blur)="colorChanged($event.target.value)"/>
    </button>

    <button *ngIf="userCanEdit" class="btn" style="min-width: 70px;">
      <div class="clr-input-wrapper"  style="margin-top: -35px;">
        <input clrInput type="number" name="strokeWidth" [(ngModel)]="curstrokeWidth" min="3" max="10" style="min-width: 40px;" (input)="onSizeChange($event.target.value)" (blur)="strokeChanged($event.target.value)"/>
      </div>
    </button>

  </div>

  <div class="sidebar-slider" *ngIf="sidebarShow">
    <div class="sidebar-close" (click)="sidebarShow = false"> X </div>
	  <div class="sidebar-content">
        <h2 style="color: white;">{{'Vorlagen' | mrTranslate}}</h2>
        <div *ngFor="let icon of sideBarIcons">
          <img [src]="getImageSaveUrl(icon.base64String)" (click)="addTemplate(icon.name, icon.base64String)" draggable="false" class="cursor-pointer skizze invert" />
        </div>
        <button class="btn btn-outline" (click)="fileInput.click()">
          <clr-icon size="20" shape="plus"></clr-icon>
          {{'Vorlage hinzufügen' | mrTranslate}}
        </button>
        <input type="file" #fileInput id="fileInput" name="fileInput"
        accept=".png" hidden
        (change)="onFileChange($event.target.files)"
      />
	  </div>
  </div>

  <div class="sidebar-slider" *ngIf="historieShow">
    <div class="sidebar-close" (click)="historieShow = false"> X </div>

      <h2 style="color: white; margin-left: 20px;">{{'Historie' | mrTranslate}}</h2>

      <clr-tree>
        <clr-tree-node [clrExpanded]="true">
          <clr-icon size="20" shape="folder"></clr-icon>
          {{'Allgemein' | mrTranslate}}
          <clr-tree-node *ngFor="let row of historyAllgemein">
            <button (click)="openHistory(row)" class="clr-treenode-link" [class.active]="row.active">
              {{ row.Status }}
            </button>

          </clr-tree-node>
        </clr-tree-node>
        <clr-tree-node>
          <clr-icon size="20" shape="folder"></clr-icon>
          {{'Auftrag' | mrTranslate}}
          <clr-tree-node *ngFor="let rows of historyAuftrag">
            <button (click)="openHistory(row)" class="clr-treenode-link" [class.active]="row.active">
              {{ row.Status }}
            </button>
          </clr-tree-node>
        </clr-tree-node>
        <clr-tree-node>
          <clr-icon size="20" shape="folder"></clr-icon>
          {{'Rückmeldung' | mrTranslate}}
          <clr-tree-node *ngFor="let rows of historyRueckmeldung">
            <button (click)="openHistory(row)" class="clr-treenode-link" [class.active]="row.active">
              {{ row.Status }}
            </button>
          </clr-tree-node>
        </clr-tree-node>
      </clr-tree>



  </div>

  <div class="clr-row nxm">
    <div [ngClass]="stage.getLayers().length != 0 ? 'clr-col-9' : 'clr-col-12'" class="margin-10-t">
      <div class="paint">
        <div style="background-color: white" id="drawing-field" (click)="draw_shape()"></div>
      </div>
    </div>


    <div class="clr-col-3 margin-10-t" *ngIf="stage.getLayers().length != 0">

      <clr-stack-view>
        <clr-stack-block [clrStackViewLevel]="1" [clrSbExpanded]="true">
          <clr-stack-label>{{'Objekte' | mrTranslate}}</clr-stack-label>
          <clr-stack-block *ngFor="let layer of stage.getLayers() | paintfilterlayer:findNonTempLayer; let j = index" [clrStackViewLevel]="2">

            <clr-stack-label>

              <div *ngIf="!layer.attrs.isTemplate && userCanEdit; else template">
                <div class="clr-input-wrapper"  style="margin-top: -25px;">
                  <input clrInput placeholder="{{layer.attrs.name}}" name="name" [(ngModel)]="layer.attrs.name" style="min-width: 150px;" (focusout)="focusOut(layer)" (focus)="focusIn(layer)"/>
                </div>
              </div>

              <ng-template #template>
                {{layer.attrs.name | mrTranslate}}
              </ng-template>

            </clr-stack-label>

            <div *ngIf="!layer.attrs.isTemplate && userCanEdit">
              <clr-stack-content>

                <div class="btn-group btn-icon btn-sm">
                  <button class="btn" (click)="doSelect(layer)">
                    <clr-icon [attr.title]="'Bearbeiten' | mrTranslate" size="20" shape="pencil"></clr-icon>
                  </button>
                  <button class="btn" (click)="doDelete(layer)">
                    <clr-icon [attr.title]="'Löschen' | mrTranslate" size="20" shape="trash"></clr-icon>
                  </button>
                  <button class="btn" (click)="doEndEdit(layer)" [disabled]="!layer.attrs.edit">
                    <clr-icon [attr.title]="'Bearbeiten beenden' | mrTranslate" size="20" shape="success-standard"></clr-icon>
                  </button>
                  <button class="btn" (click)="doCopy(layer)" [disabled]="!layer.attrs.edit">
                    <clr-icon [attr.title]="'Kopieren' | mrTranslate" size="20" shape="copy"></clr-icon>
                  </button>
                </div>

              </clr-stack-content>

            </div>
          </clr-stack-block>

        </clr-stack-block>
      </clr-stack-view>

    </div>
  </div>
</div>


