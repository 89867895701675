import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostListener, OnDestroy, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ClarityModule } from "@clr/angular";
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import * as Excel from 'exceljs';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, first } from "rxjs";

import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { environment } from "src/environments/environment";
import { DetailviewComponent } from "../../detailview/detailview.component";

@Component({
  selector: "app-systemmanagementdialog",
  templateUrl: "./systemmanagementdialog.component.html",
  styleUrls: ["./systemmanagementdialog.component.scss"],
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule,HideIDColumnsPipe,TablePrettyPrintPipe,DotDotDotPipe, DetailviewComponent],
  standalone: true
})

export class SystemManagementComponent implements OnDestroy {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _connection: HubConnection;

  action: "inspRestore" | "inspFullDelete" | "masterRestore" | "masterDelete" | "masterDeleteMultiple" | "masterArchive" | "masterCopy" | "moveStart" |
    "lineConnect" | "lineCut" | "lineTurn" | "assignInsp" | "assignMess" | "assignError" | "sortOrder" | "xlSpez" | "xlBauw" | "xlHerst" | "xlStamm" | "xlStammImp" |
    "xlStammExp" | "xlStammEditImp" | "xlSpezExp" | "xlSpezImp" | "QRCode" | "kav_calculate" | "createTestDatabase" | "deleteTestDatabase" | "refreshDBConDictonary" | "masterUnlock" | ""
  tblLeft: any;
  tblRight: any;
  lstObjects: any;
  objSelected: any = [];
  selectedtabellenL: any;
  selectedtabellenLM: any = [];
  selectedtabellenR: any;
  selectionTabelleTitelChanged: any;
  selTreeNote: any;
  tableColSize: "clr-col-12" | "clr-col-6";
  tableheight: string = "85vh";
  titel: string = '';
  trTitel: string = '';
  info: string = '';
  pcnt: string = '';
  isLoading = false;
  rN: number = 0;
  showdetailview = false;
  hidedetailview: boolean = false;
  _ostammid = 0;
  split50 = false;
  sidenavenlarged = false;
  progressbarenlarged = false;
  indexScrub: number = -1;

  cutVals: any = {
    TrennPos: 0,
    NeuStartPos: 0,
    NeuStartPunkt: "",
    NeuBez: "",
    NeuMess: ""
  }

  movePos: any = {
    OldStart: 0,
    NewStart: 0,
    Move: 0,
    Descending: false
  }
  cutL: any = {
    Startpunkt: "",
    Endpunkt: "",
    Bezeichnung: "",
    Startposition: 0,
    Endposition: 0,
    Messfile: "",
    Gleislaenge: 0
  }
  cutR: any = {
    Startpunkt: "",
    Endpunkt: "",
    Bezeichnung: "",
    Startposition: 0,
    Endposition: 0,
    Messfile: "",
    Gleislaenge: 0
  }

  dataRefresh = new EventEmitter();

  showCreateTestDatabase: boolean = false;
  users: any;
  selectedUser: any = [];
  text: string = '';
  doCreateTestDatabase: boolean = false;

  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    protected authservice: AuthService,
  ) { }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    if (this._connection) this._connection.stop();
  }

  set data(inData: any) {
    this.titel = this.mrTranslate.transform("Systemverwaltung");
  }

  valuesChange() {
    if (this.action == "lineCut") {
      // var tPos = document.getElementById("TrennPos") as HTMLInputElement;
      // var nPos = document.getElementById("NeuStartPos") as HTMLInputElement;
      // tPos.min = this.selectedtabellenL.startmeter;
      // nPos.min = tPos.min;
      // nPos.max = tPos.max = this.selectedtabellenL.endmeter;
      // if(this.cutVals.TrennPos < parseInt(tPos.min)) this.cutVals.TrennPos = tPos.min
      // if(this.cutVals.TrennPos > parseInt(tPos.max)) this.cutVals.TrennPos = tPos.max
      // if(this.cutVals.NeuStartPos < this.cutVals.TrennPos) this.cutVals.NeuStartPos = this.cutVals.TrennPos
      this.selectionChanged();
    }
  }
  selectionChanged() {
    if (!this.selectedtabellenL) return;

    if (this.action == "lineCut") {
      var tPos = document.getElementById("TrennPos") as HTMLInputElement;
      var nPos = document.getElementById("NeuStartPos") as HTMLInputElement;
      tPos.min = this.selectedtabellenL.startmeter;
      nPos.max = tPos.max = this.selectedtabellenL.endmeter;


      this.cutL.Startpunkt = this.selectedtabellenL.startpunkt;
      this.cutL.Endpunkt = this.cutVals.NeuStartPunkt != "" ? this.cutVals.NeuStartPunkt : this.selectedtabellenL.endpunkt;
      this.cutL.Bezeichnung = this.selectedtabellenL.bezeichnung;
      this.cutL.Startposition = this.selectedtabellenL.startmeter;
      this.cutL.Endposition = this.cutVals.TrennPos != "" ? this.cutVals.TrennPos : this.selectedtabellenL.endmeter;
      this.cutL.Messfile = this.selectedtabellenL.Messpunktskizze;
      this.cutL.Gleislaenge = this.cutVals.TrennPos != "" ? this.cutVals.TrennPos - this.cutL.Startposition : this.selectedtabellenL.Länge;

      this.cutR.Startpunkt = this.cutVals.NeuStartPunkt != "" ? this.cutVals.NeuStartPunkt : this.selectedtabellenL.startpunkt;
      this.cutR.Endpunkt = this.selectedtabellenL.endpunkt;
      this.cutR.Bezeichnung = this.cutVals.NeuBez != "" ? this.cutVals.NeuBez : this.selectedtabellenL.bezeichnung;
      this.cutR.Startposition = this.cutVals.NeuStartPos != "" ? this.cutVals.NeuStartPos : this.selectedtabellenL.startmeter;
      this.cutR.Endposition = parseInt(this.selectedtabellenL.endmeter);
      this.cutR.Messfile = this.cutVals.NeuMess;
      this.cutR.Gleislaenge = this.cutR.Endposition - this.cutVals.NeuStartPos;
    }

    if (this.action == "moveStart") {
      this.movePos.OldStart = this.selectedtabellenL.startmeter;
    }
    if (this.action == "masterArchive") {
      this.showdetailview = true;
      this._ostammid = this.selectedtabellenL.OSTAMMID;
    }
  }

  createTestDatabase() {
    this.doCreateTestDatabase = true;
    let v: any = {};
    v.vals = this.selectedUser;
    v.pruefID = 0;
    v.stammID = 0;

    this.startHubConnection();
    this.action = "createTestDatabase";
    this.spinner.enable();

    this.apiService
      .SysManStartAction(this.action, v)
      .pipe()
      .subscribe((res: any) => {

        if (res.success == "OK") {
          this.toastr.success(
            this.mrTranslate.transform("Testdatenbank wurde erfolgreich erstellt")
          );
        }
        this.info = "";
        this.pcnt = "";
        this.text = "";
        this.spinner.disable();
        this.doCreateTestDatabase = false;

        setTimeout(() => {
          this.showCreateTestDatabase = false;
        }, 1500);

      });
  }


  setView(action: "inspRestore" | "inspFullDelete" | "masterRestore" | "masterDelete" | "masterDeleteMultiple" | "masterArchive" | "masterCopy" | "moveStart" |
    "lineConnect" | "lineCut" | "lineTurn" | "assignInsp" | "assignMess" | "assignError" | "sortOrder" | "xlSpez" | "xlBauw" | "xlHerst" | "xlStamm" | "xlStammImp" |
    "xlStammExp" | "xlStammEditImp" | "xlSpezExp" | "xlSpezImp" | "QRCode" | "kav_calculate" | "createTestDatabase" | "deleteTestDatabase" | "refreshDBConDictonary" | "masterUnlock" | "") {
    this.tblLeft = undefined;
    this.tblRight = undefined;
    setTimeout(() => {
    }, 100);


    if (action == "createTestDatabase") {

      this.apiService
        .getUsers()
        .pipe(first())
        .subscribe((users: any) => {
          this.users = users;
          this.selectedUser = users.rows;
          this.text = "Action: ";
          this.showCreateTestDatabase = true;
        });

      return;
    }

    this.tableColSize = "clr-col-12"
    this.action = action;
    this.info = "";
    this.pcnt = "";
    this.showdetailview = false;

    this.tableheight = "85vh";
    this.apiService
      .getSysManWindow(action)
      .pipe()
      .subscribe((res: any) => {
        switch (action) {
          case "inspRestore":
          case "inspFullDelete":
          case "masterRestore":
          case "masterDelete":
          case "masterDeleteMultiple":
          case "masterArchive":
          case "masterCopy":
          case "masterUnlock":
            this.tblLeft = res;
            break;
          case "moveStart":
            this.tblLeft = res;
            this.tableheight = "70vh";
            break;
          case "lineConnect":
            this.tableColSize = "clr-col-6"
            this.tblLeft = res;
            this.tblRight = res;
            this.tableheight = "85vh";
            break;
          case "lineCut":
            this.tblLeft = res;
            this.tableheight = "40vh";
            break;
          case "lineTurn":
            this.tblLeft = res;
            break;
          case "sortOrder":
            this.trTitel = 'Auftrag umsortieren';
            this.tblLeft = res;
            break;
          case "assignError":
            this.trTitel = 'Fehlerklassenzuordnung wiederherstellen';
            break;
          case "assignInsp":
            this.trTitel = 'Prüfzuordnungen wieder herstellen';
            break;
          case "assignMess":
            this.trTitel = 'Zuordnungen letzte Messung wieder herstellen';
            break;
          case "kav_calculate":
            this.trTitel = 'KAV für alle Objekte berechnen';
            break;
          case "refreshDBConDictonary":
            this.trTitel = 'Connectionstring-Dictionary erneut aufbauen';
            break;
          case "deleteTestDatabase":
            this.trTitel = 'Testdatenbank löschen';
            break;
          case "xlSpez":
            this.trTitel = 'Export Spezifikationen';
            this.lstObjects = res;
            break;
          case "QRCode":
            this.trTitel = 'QR-Codes erstellen';
            this.lstObjects = res;
            break;
          case "xlStamm":
            this.trTitel = 'Vorgabe für Stammdatenimport erstellen';
            this.lstObjects = res;
            break;
          case "xlStammExp":
            this.trTitel = 'Stammdaten zum Bearbeiten nach Excel exportieren';
            this.lstObjects = res;
            break;
          case "xlSpezExp":
            this.trTitel = 'Spezifikationen zum Bearbeiten nach Excel exportieren';
            this.lstObjects = res;
            break;
          case "xlBauw":
            this.trTitel = 'Bauwerke';
            break;
          case "xlHerst":
            this.trTitel = 'Herstellungsdaten';
            break;
          case "xlStammImp":
            this.trTitel = 'Stammdatenliste aus Excel importieren';
            break;
          case "xlStammEditImp":
            this.trTitel = 'Bearbeitete Stammdatenliste aus Excel einlesen';
            break;
          case "xlSpezImp":
            this.trTitel = 'Bearbeitete Liste mit Spezifikationen aus Excel einlesen';
            break;
          default:
            break;
        }
      });

    let bem = document.getElementById("bemerkung") as HTMLInputElement;
    if (bem) bem.value = "";
  }

  startAction(event: any) {

    if (this.action == 'QRCode') {

      let r = this.lstObjects.rows.filter((r) => r.selected == 1);

      this.apiService
        .getQRLables(r)
        .pipe(first())
        .subscribe((res) => {
          if (res) {

            var blob = new Blob([res], { type: 'application/pdf' });
            const anchor = document.createElement('a');
            anchor.download = "qr-codes.pdf";
            anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
            anchor.click();

          } else {
            this.toastr.warning(this.mrTranslate.transform("Kein PDF erstellt, Fehler im Log."));
          }
        });


      return;
    }
    if (this.action == "deleteTestDatabase") {
      const text = this.mrTranslate.transform("Testdatenbank wirklich löschen?");
      if (!confirm(text)) return;
    }

    let v: any = {};
    let error = false;
    this.startHubConnection();
    switch (this.action) {
      case "inspRestore":
      case "inspFullDelete":
        v.pruefID = this.selectedtabellenL.opruefID;
        if (v.pruefID <= 0) {
          this.toastr.warning("Dieses Stammdokument besitzt keine Prüfungsdaten.");
          return;
        }
        break;
      case "masterRestore":
      case "masterDelete":
        v.stammID = this.selectedtabellenL.ostammID;
        break;
      case "masterDeleteMultiple":
      case "masterUnlock":
        v.SelectedRows = this.selectedtabellenLM;
        break;
      case "masterArchive":

        break;
      case "masterCopy":
        v.bez = (document.getElementById("CopyBezeichnung") as HTMLInputElement).value
        v.stammID = this.selectedtabellenL.OSTAMMID;
        if (v.bez == '') {
          this.toastr.warning("Bitte Bezeichnung eingeben");
          return;
        }
        break;
      case "moveStart":
        v.stammID = this.selectedtabellenL.OSTAMMID;
        v.alt = this.movePos.OldStart;
        v.neu = this.movePos.NewStart;
        //v.ver = this.movePos.Move;
        v.dec = (document.getElementById("Descending") as HTMLInputElement).checked;
        break;
      case "lineConnect":
        v.stammIDL = this.selectedtabellenL.OSTAMMID;
        v.stammIDR = this.selectedtabellenR.OSTAMMID;
        if (v.stammIDL == v.stammIDR) {
          this.toastr.warning("Es dürfen nicht die gleichen Stammobjekte verbunden werden.");
          return;
        }
        break;
      case "lineCut":
        v.stammID = this.selectedtabellenL.OSTAMMID;
        v.OldEP = this.cutL.Startposition;
        v.OldEM = this.cutL.Endposition;
        v.OldL = this.cutL.Gleislaenge;
        v.NewSP = this.cutR.Startpunkt;
        v.NewEP = this.cutR.Endpunkt;
        v.NewMF = this.cutR.Messfile;
        v.NewBez = this.cutVals.NeuBez
        v.NewSM = this.cutR.Startposition;
        v.NewEM = this.cutR.Endposition;
        v.NewL = this.cutR.Gleislaenge;

        if (v.NewBez == '') {
          this.toastr.warning("Bitte neue Bezeichnung eingeben");
          error = true;
        }
        if (v.NewMF == '') {
          this.toastr.warning("Bitte neue Messfile eingeben");
          error = true;
        }
        if (v.NewSM == '') {
          this.toastr.warning("Bitte neuen Startmeter eingeben");
          error = true;
        }
        if (error) return;
        break;
      case "lineTurn":
        v.stammID = this.selectedtabellenL.OSTAMMID;
        v.offset = 0;
        break;
      case "assignInsp":
      case "assignMess":
      case "assignError":
      case "sortOrder":

        break;
      case "xlSpez":
        v.rows = this.lstObjects;
        break;
      case "xlStamm":
      case "xlStammExp":
      case "xlSpezExp":
        v.id = event.ID;
        v.bez = event.BEZEICHNUNG;
        break;
      case "xlSpezImp":
      case "xlStammImp":
      case "xlStammEditImp":
        const workbook = new Excel.Workbook();
        const reader: FileReader = new FileReader();

        reader.onload = async (e: any) => {
          /* read workbook */
          event.target.value = "";
          let lstRows = [];
          await workbook.xlsx.load(e.target.result);
          var worksheet = workbook.worksheets[0];
          worksheet.eachRow({ includeEmpty: false }, function (row, rowNumber) {
            lstRows.push(row.values);
            //console.log("Row " + rowNumber + " = " + JSON.stringify(row.values));
          });
          v.val = lstRows;

          this.apiService
            .SysManStartAction(this.action, v)
            .pipe()
            .subscribe((res: any) => {
              let bem = document.getElementById("bemerkung") as HTMLInputElement;
              bem.value = res.join("\n");

              this.spinner.disable();
              this.toastr.success(
                this.mrTranslate.transform("Aktion ausgeführt")
              );
            });

        };
        reader.readAsArrayBuffer(event.target.files[0]);
        return;
      default:
        break;
    }
    this.spinner.enable();
    this.apiService
      .SysManStartAction(this.action, v)
      .pipe()
      .subscribe((res: any) => {
        let ws;
        let wb;
        switch (this.action) {
          case "xlSpez":
            wb = new Excel.Workbook();
            ws = wb.addWorksheet("Spezifikationen");
            var resO: any = Object.entries(res);
            resO.forEach(obj => {
              this.rN += 1;
              obj = obj[1];
              this.ExcelCreateList(obj, ws);
            });
            this.AdjustColumnWidth(ws);

            wb.xlsx.writeBuffer().then((buffer) => {
              this.apiService.downloadBlobAsFile(buffer, "Spezifikation.xlsx");
            });
            break;
          case "xlBauw":
            this.SetUpExcelDoc(res, "Bauwerke");
            break;
          case "xlHerst":
            this.SetUpExcelDoc(res, "Hersteller");
            break;
          case "xlStamm":
            wb = new Excel.Workbook();
            this.rN = 5;
            ws = wb.addWorksheet("Stammdaten");
            ws.eachRow((row) => {
              row.eachCell((cell) => {
                cell.protection = {
                  locked: false
                };
              });
            });

            ws.getCell("A1").value = "Stammdatenimport";
            ws.getCell('A1').font = { size: 24, bold: true };
            ws.getCell("A2").value = "ID";
            ws.getCell("B2").value = event.ID;
            ws.getCell("A3").value = "Name";
            ws.getCell("B3").value = event.BEZEICHNUNG;
            ws.getCell("A4").value = "Stammdaten";
            ws.getCell('A4').font = { size: 18, bold: true };
            if (res[1].length > 0) {
              ws.getRow(4).getCell(res[0].length).value = "Spezifikationen";
              ws.getRow(4).getCell(res[0].length).font = { size: 18, bold: true };
            }
            if (res[2].length > 0) {
              ws.getRow(4).getCell(res[0].length + res[1].length).value = "Herstelldaten";
              ws.getRow(4).getCell(res[0].length + res[1].length).font = { size: 18, bold: true };
            }

            let startCell = 1;
            for (let r = 0; r < 3; r++) {
              if (res[r].length > 0) {
                startCell = r == 1 ? res[0].length - 1 : r == 2 ? res[0].length + res[1].length - 1 : 1;
                let color = r == 0 ? 'B4C6E7' : r == 1 ? 'E2EFDA' : 'FCE4D6';
                for (let i = startCell; i < startCell + res[r].length; i++) {
                  ws.getCell(1, i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: r == 2 ? 'FFF2CC' : r == 1 ? 'FFF2CC' : 'FFF2CC' }
                  }
                  ws.getCell(5, i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: color }
                  }
                  ws.getCell(6, i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: color }
                  }
                  ws.getCell(7, i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: color }
                  }
                }
                for (let i = 1; i < res[r].length; i++) {
                  ws.getRow(5).getCell(startCell - 1 + i).value = res[r][i - 1].DBField;
                  ws.getRow(6).getCell(startCell - 1 + i).value = res[r][i - 1].Pflicht ? "✔" : "";
                  ws.getRow(7).getCell(startCell - 1 + i).value = res[r][i - 1].Objekt;
                }
                for (let i = 4; i <= 7; i++) {
                  ws.getRow(i).eachCell({ includeEmpty: false }, function (cell) {
                    ws.getCell(cell.address).protection = {
                      locked: true,
                      hidden: false,
                    };
                  })
                }
              }

            }

            this.AdjustColumnWidth(ws);
            ws.autoFilter = { from: 'A7', to: { row: 7, column: res[0].length + res[1].length + res[2].length - 2 } };
            //ws.protect('pprotectedd', {selectLockedCells: true});
            wb.xlsx.writeBuffer().then((buffer) => {
              this.apiService.downloadBlobAsFile(buffer, "Stammdaten-Vorlage.xlsx");
            });
            break;
          case "xlStammExp":
          case "xlSpezExp":
            wb = new Excel.Workbook();
            this.rN = 5;
            let name: string = this.action == "xlSpezExp" ? "Spezifikationen" : "Stammdaten";
            ws = wb.addWorksheet(name);
            ws.eachRow((row) => {
              row.eachCell((cell) => {
                cell.protection = {
                  locked: false
                };
              });
            });

            ws.getCell("A1").value = name + "import";
            ws.getCell('A1').font = { size: 24, bold: true };
            ws.getCell("A2").value = "ID";
            ws.getCell("B2").value = event.ID;
            ws.getCell("A3").value = "Name";
            ws.getCell("B3").value = event.BEZEICHNUNG;
            ws.getCell("A4").value = name;
            ws.getCell('A4').font = { size: 18, bold: true };
            for (let i = 1; i < res.dtStamm.length; i++) {
              ws.getCell(1, i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFF2CC' }
              }
              ws.getCell(5, i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'B4C6E7' }
              }
            }
            for (let i = 0; i < res.dtStamm.length; i++) {
              ws.getRow(5).getCell(i + 1).value = res.dtStamm[i].DBField;
              ws.getRow(6).getCell(i + 1).value = res.dtStamm[i].Pflicht ? "✔" : "";
              ws.getRow(7).getCell(i + 1).value = res.dtStamm[i].Objekt;
            }
            for (let i = 4; i <= 7; i++) {
              ws.getRow(i).eachCell({ includeEmpty: false }, function (cell) {
                ws.getCell(cell.address).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'B4C6E7' }
                }
                ws.getCell(cell.address).protection = {
                  locked: true,
                  hidden: false,
                };
              })
            }

            this.rN += 3;
            for (let i = 0; i < res.dt.length; i++) {
              for (let j = 0; j < res.dtStamm.length; j++) {
                ws.getRow(this.rN + i).getCell(j + 1).value = res.dt[i][res.dtStamm[j].DBField];
              }
            }

            //ws.protect('pprotectedd', {selectLockedCells: true});
            ws.autoFilter = { from: 'A7', to: { row: 7, column: res.dtStamm.length } };
            this.AdjustColumnWidth(ws);
            wb.xlsx.writeBuffer().then((buffer) => {
              this.apiService.downloadBlobAsFile(buffer, name + "-Vorlage.xlsx");
            });
            break;
          case "assignInsp":
          case "assignMess":
          case "assignError":
          case "sortOrder":
          case "kav_calculate":
            let bem = document.getElementById("bemerkung") as HTMLInputElement;
            bem.value = res.join("\n");
            this.info = "";
            this.pcnt = "";
            this.spinner.disable();
            this.toastr.success(
              this.mrTranslate.transform("Aktion ausgeführt")
            );
            return;
          case "deleteTestDatabase":
            this.setView(this.action);
            this.spinner.disable();
            if (res.success)
              this.toastr.success(
                this.mrTranslate.transform(res.success)
              );
            else if (res.failure)
              this.toastr.error(
                this.mrTranslate.transform(res.failure)
              );
            return;
        }
        this.tblLeft = undefined;
        this.tblRight = undefined;
        this.setView(this.action);
        this.info = "";
        this.pcnt = "";
        this.spinner.disable();
        this.toastr.success(
          this.mrTranslate.transform("Aktion ausgeführt")
        );
      });
  }

  private SetUpExcelDoc(res: any, name: string) {
    const wb = new Excel.Workbook();
    this.rN = 1;
    const ws = wb.addWorksheet(name);
    this.ExcelCreateList(res, ws);
    this.AdjustColumnWidth(ws);
    wb.xlsx.writeBuffer().then((buffer) => {
      this.apiService.downloadBlobAsFile(buffer, name + ".xlsx");
    });
  }

  private ExcelCreateList(obj: any, ws: Excel.Worksheet) {
    for (var i = 0; i < obj.columns.length; i++) {
      ws.getRow(this.rN).getCell(i + 1).value = obj.columns[i].id;
    }
    ws.getRow(this.rN).eachCell({ includeEmpty: false }, function (cell) {
      ws.getCell(cell.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'BFBFBF' }
      }
      ws.getCell(cell.address).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      }
    })
    this.rN += 1;
    for (var i = 0; i < obj.rows.length; i++) {
      for (var j = 0; j < obj.columns.length; j++) {
        ws.getRow(i + this.rN).getCell(j + 1).value = obj.rows[i][obj.columns[j].id];
      }
      ws.getCell(i + this.rN, 1).border = { left: { style: 'thin' } }
      ws.getCell(i + this.rN, obj.columns.length).border = { right: { style: 'thin' } }
    }
    this.rN += obj.rows.length;
    ws.getRow(this.rN - 1).eachCell({ includeEmpty: true }, (cell) => {
      ws.getCell(cell.address).border = { bottom: { style: 'thin' } }
    });
    ws.getCell(this.rN - 1, obj.columns.length).border = { right: { style: 'thin' }, bottom: { style: 'thin' } };
    this.rN += 4;
  }

  private AdjustColumnWidth(worksheet) {
    worksheet.columns.forEach(column => {
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength > 10 ? maxLength : 10;
    });
  }

  async startHubConnection() {
    try {

      if (this._connection == undefined)
        this.initHubConnectionBuilder();


      await this._connection.start();
      console.log("SignalR Connected.");

    } catch (err) {
      console.log(err);
      setTimeout(this.startHubConnection, 2000);
    }
  };

  enlargeSidenav(event) {
    if (event.mode == "full") {
      this.sidenavenlarged = event.val;
    } else if (event.mode == "half") {
      this.split50 = event.val;
    } else if (event.mode == "hidden") {
      this.hidedetailview = event.val;
    }
    else if (event.mode == "close") {
      this._ostammid = undefined;
      setTimeout(() => {
        this.showdetailview = false;
      });
    }
  }
  handleScrub(event) {
    this.indexScrub = event;
  }

  initHubConnectionBuilder() {
    this._connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.None)
      .withUrl(environment.apiUrl + '/progress', { withCredentials: false, transport: HttpTransportType.LongPolling })
      .withAutomaticReconnect()
      .build();

    this._connection.on('taskStarted', data => {
    });

    this._connection.on('taskProgressChanged', data => {
      setTimeout(() => {
      }, 1);
      //this.messages.push(data);
      console.log(data)
      this.pcnt = data[1];
      this.info = "Fortschritt: " + data[0];

      if (this.showCreateTestDatabase) {
        this.text = "Action: " + data[0];
      }
    });

    this._connection.on('taskEnded', data => {
      setTimeout(() => {
      }, 500);
    });
  }
  close() {
    this.closedialog.next(true);
  }

}
