/**
 * @license
 * Copyright 2017 Google LLC
 * SPDX-License-Identifier: BSD-3-Clause
 */
export const legacyPrototypeMethod = (descriptor, proto, name) => {
  Object.defineProperty(proto, name, descriptor);
};
export const standardPrototypeMethod = (descriptor, element) => ({
  kind: 'method',
  placement: 'prototype',
  key: element.key,
  descriptor
});
/**
 * Helper for decorating a property that is compatible with both TypeScript
 * and Babel decorators. The optional `finisher` can be used to perform work on
 * the class. The optional `descriptor` should return a PropertyDescriptor
 * to install for the given property.
 *
 * @param finisher {function} Optional finisher method; receives the element
 * constructor and property key as arguments and has no return value.
 * @param descriptor {function} Optional descriptor method; receives the
 * property key as an argument and returns a property descriptor to define for
 * the given property.
 * @returns {ClassElement|void}
 */
export const decorateProperty = ({
  finisher,
  descriptor
}) => (protoOrDescriptor, name
// Note TypeScript requires the return type to be `void|any`
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) => {
  var _a;
  // TypeScript / Babel legacy mode
  if (name !== undefined) {
    const ctor = protoOrDescriptor.constructor;
    if (descriptor !== undefined) {
      Object.defineProperty(protoOrDescriptor, name, descriptor(name));
    }
    finisher === null || finisher === void 0 ? void 0 : finisher(ctor, name);
    // Babel standard mode
  } else {
    // Note, the @property decorator saves `key` as `originalKey`
    // so try to use it here.
    const key =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_a = protoOrDescriptor.originalKey) !== null && _a !== void 0 ? _a : protoOrDescriptor.key;
    const info = descriptor != undefined ? {
      kind: 'method',
      placement: 'prototype',
      key,
      descriptor: descriptor(protoOrDescriptor.key)
    } : {
      ...protoOrDescriptor,
      key
    };
    if (finisher != undefined) {
      info.finisher = function (ctor) {
        finisher(ctor, key);
      };
    }
    return info;
  }
};
