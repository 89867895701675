<div class="overlay-inner-wrapper">
  <div id="viewauftragWrapper">
    <div class="clr-row">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h4 class="mt-0">{{titel}}</h4>
        </div>
        <div class="inline-block float-right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
    </div>
    <div class="clr-row">
      <div class="clr-col-12">
        <a [attr.href]="pdf_url" [attr.download]="filename">
          <button class="btn btn-primary btn-sm">
            <clr-icon shape="download"></clr-icon> {{'Download' | mrTranslate}}
          </button>
        </a>
      </div>
    </div>

    <div class="clr-row pt-content" style="height: calc(100vh - 155px)">
      <iframe [attr.src]="pdf_url" style="border:none; width:100%;"></iframe>
    </div>

  </div>
</div>
