export const enum ZählerMode {
  ZAEHLER = 1,
  KRAFTMESSUNG
}

export const enum ZType {
  NotHeader = 0,
  Header = 6
}

export const enum ZArt {
  Numerisch = 1,
  SollEqLess = 2,
  SollLess = 21,
  SollEqGreat = 3,
  SollGreat = 31,
  Mindestwert = 4,
  Maximalwert = 5,
  ZwischenMinMax = 9,
  JaNein = 6
}

export type SollMinusPlusOptions = {
  soll: 'Num' | 'Ja' | '<' | '>' | '>...<';
  plus?: 'Num' | '<=' | '<' | '>' | '>=';
  minus?: 'Num' | '<=' | '<' | '>' | '>=';
};

export const ZArtOptions: Record<ZArt, SollMinusPlusOptions> = {
  1: { soll: 'Num', plus: 'Num', minus: 'Num' },
  2: { soll: 'Num', plus: '>=' },
  21: { soll: 'Num', plus: '>' },
  3: { soll: 'Num', minus: '<=' },
  31: { soll: 'Num', minus: '<' },
  4: { soll: '>', minus: 'Num' },
  5: { soll: '<', plus: 'Num' },
  9: { soll: '>...<', plus: 'Num', minus: 'Num' },
  6: { soll: 'Ja' },
};

export const ZArtLongNames: Record<ZArt, string> = {
  1: 'Numerische Toleranzen',
  2: 'Ist-Wert <= Soll, dann Ausfall',
  21: 'Ist-Wert < Soll, dann Ausfall',
  3: 'Ist-Wert >= Soll, dann Ausfall',
  31: 'Ist-Wert > Soll, dann Ausfall',
  4: 'Ist-Wert < Mindestwert, dann Ausfall',
  5: 'Ist-Wert > Maximalwert, dann Ausfall',
  9: 'Ist-Wert liegt zwischen Min und Max',
  6: 'Ja/Nein-Prüfung',
};