// import { ChangeDetectorRef, Pipe, PipeTransform } from "@angular/core";
// import { TranslatePipe, TranslateService } from "@ngx-translate/core";
// import { environment } from "src/environments/environment";
// import { urlEnDecodePipe } from "./urlEncode.pipe";
// @Pipe({
//   name: "mrTranslate",
//   pure: false,
//   standalone: true
// })
// export class MrTranslatePipe implements PipeTransform {
//   constructor(
//     private urlencodepipe: urlEnDecodePipe,
//     private translatepipe: TranslatePipe
//   ) { }

//   transform(value: string, ...args: any[]): string {
//     if (value && value !== "" && typeof (value) === 'string') {

//       let result = this.encodeTranslateDecode(value, args);
//       if (!result || (result === "" && value[0] === value[0].toUpperCase()))
//         result = this.encodeTranslateDecode(value.toLowerCase(), args);
//       if (!result || (result === "" && value[0] === value[0].toLowerCase()))
//         result = this.encodeTranslateDecode(
//           value[0].toUpperCase() + value.slice(1),
//           args
//         );

//       if (environment.debugTranslation && !(result && result !== ""))
//         console.debug("Translation not found:", "'" + value + "'");

//       if (result?.indexOf("%20") !== -1)
//         result = this.urlencodepipe.transform(result, [false]);
//       result = result && result !== "" ? result : value;
//       return result;
//     }
//     return value;
//   }

//   encodeTranslateDecode(value, args) {
//     const encoded = this.urlencodepipe.transform(value, [true]);
//     const formattedByTranslatePipe = this.translatepipe.transform(
//       encoded,
//       args
//     );
//     const decoded = this.urlencodepipe.transform(formattedByTranslatePipe, [
//       false,
//     ]);
//     return decoded;
//   }
// }

import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { urlEnDecodePipe } from "./urlEncode.pipe";

@Pipe({
  name: "mrTranslate",
  pure: false,
  standalone: true
})
export class MrTranslatePipe implements PipeTransform {
  constructor(
    private urlencodepipe: urlEnDecodePipe,
    private translateService: TranslateService
  ) {}

  transform(value: string, ...args: any[]): string {
    if (value && value !== "" && typeof value === "string") {
      let result = this.encodeTranslateDecode(value, args);
      if (!result || (result === "" && value[0] === value[0].toUpperCase()))
        result = this.encodeTranslateDecode(value.toLowerCase(), args);
      if (!result || (result === "" && value[0] === value[0].toLowerCase()))
        result = this.encodeTranslateDecode(
          value[0].toUpperCase() + value.slice(1),
          args
        );

      if (environment.debugTranslation && !(result && result !== ""))
        console.debug("Translation not found:", "'" + value + "'");

      if (result?.indexOf("%20") !== -1)
        result = this.urlencodepipe.transform(result, [false]);
      result = result && result !== "" ? result : value;
      return result;
    }
    return value;
  }

  encodeTranslateDecode(value: string, args: any[]): string {
    const encoded = this.urlencodepipe.transform(value, [true]);
    // Use TranslateService.instant to translate the encoded value.
    const formatted = this.translateService.instant(encoded, ...args);
    const decoded = this.urlencodepipe.transform(formatted, [false]);
    return decoded;
  }
}
