import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[uploadDropzone]',
  standalone: true

})
export class DropZoneDirective {

  @Output('uploadDropzone') fileDrop = new EventEmitter<Array<File>>();
  @Input() preventBodyDrop = true;

  constructor(private el: ElementRef) {
    el.nativeElement.classList.add("dropzoneBox");
  }

  // The `drop-zone-active` class is applied to the host
  // element when a drag is currently over the target.

  @HostBinding('class.drop-zone-active')
  active = false;
  // set active(isActive) {
  //   if (isActive) this.el.nativeElement.classList.add("drop-zone-active");
  //   else this.el.nativeElement.classList.remove("drop-zone-active");
  // }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    this.active = false;
    const { dataTransfer } = event;
    if (dataTransfer.items) {
      const files = Array.from(dataTransfer.items).flatMap(
        (item) => item.kind == 'file' ? [item.getAsFile()] : []
      );
      dataTransfer.items.clear();
      this.fileDrop.emit(files);
    } else {
      const files = Array.from(dataTransfer.files);
      dataTransfer.clearData();
      this.fileDrop.emit(files);
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    this.active = true;
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('dragleave')
  onDragLeave() {
    this.active = false;
  }

  @HostListener('body:dragover', ['$event'])
  onBodyDragOver(event: DragEvent) {
    if (this.preventBodyDrop) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  @HostListener('body:drop', ['$event'])
  onBodyDrop(event: DragEvent) {
    if (this.preventBodyDrop) {
      event.preventDefault();
    }
  }
}
