<clr-modal [(clrModalOpen)]="modalOpen"  [clrModalClosable]="false" id="messungmodal" [clrModalStaticBackdrop]="true">  <!-- [clrModalSize]="'xl'" -->
  <h3 class="modal-title" style="display:flex">{{'Messdatenauswertung' | mrTranslate}}</h3>
  <div class="modal-body" id="settingsModalBody">
    <form clrForm class="clr-form clr-form-horizontal width100" [formGroup]="inGroup" style="margin-top: 10px;">
      <div class="clr-row" style="margin-top: 10px;">
        <div class="clr-col-4" style="padding-left: 5px;">
          <span>{{'Auswahl' | mrTranslate}}:</span>
        </div>
        <div class="clr-col-4">
          <clr-radio-wrapper>
            <input type="radio" clrRadio formControlName="anzeigeTyp" [value]="0" (change)="getCounts()"/>
            <label>{{'letzte Inspektion' | mrTranslate}}</label>
          </clr-radio-wrapper>
        </div>
        <div class="clr-col-4">
          <clr-radio-wrapper>
            <input type="radio" clrRadio formControlName="anzeigeTyp" [value]="1" (change)="getCounts()"/>
            <label>{{'alle Inspektionen' | mrTranslate}}</label>
          </clr-radio-wrapper>
        </div>
      </div>

      <div class="clr-row" style="margin-top: 15px;">
        <div class="clr-col-4" style="padding-left: 5px;">
          <span>{{'Auswahl Zeitraum' | mrTranslate}}:</span>
        </div>

        <ng-container *ngIf="isopened == true">
          <div class="clr-col-4">
            <input type="date" name="date1" clrDate
            [ngModel]="von | localeDate"
            (ngModelChange)="von = localeDate.tosql($event)"
            [ngModelOptions]="{standalone: true, updateOn: 'blur'}" aria-hidden="true"/>
          </div>
          <div class="clr-col-4">
            <input type="date" name="date1" clrDate
            [ngModel]="bis | localeDate"
            (ngModelChange)="bis = localeDate.tosql($event)"
            [ngModelOptions]="{standalone: true, updateOn: 'blur'}" aria-hidden="true"/>
          </div>
      </ng-container>

      </div>
      <div class="clr-row" style="margin-top: 15px;">
        <div class="clr-col-12" style="padding-left: 5px;">
          <span>{{'Objekte' | mrTranslate}}: {{countobjekte}} / {{'Messungen' | mrTranslate}}: {{countmess}}</span>
        </div>
      </div>
    </form>

  </div>
  <div class="modal-footer">

    <ng-container *ngIf="countmess != 0">
      <button type="button" class="btn" (click)="onExport();">{{'Excel' | mrTranslate}}</button>
    </ng-container>

    <button type="button" class="btn" (click)="onOK.emit(false);">{{'Beenden' | mrTranslate}}</button>

  </div>
</clr-modal>

