import { FilterOperator } from "src/app/models/ui/filter";
import { IFilter } from ".";
import { emptyValueSign } from "../../models/ui/datatype";

export class NumberFilter implements IFilter {
  private data: any[];

  constructor(data: any[]) {
    this.ensureNumbers(data);
    this.data = data;
  }

  private ensureNumbers(data: any[]): void {
    const d = data.map((m) => this.normalizeValue(m.name));
    if (!d.every((item) => typeof item === "number")) {
      throw new Error("All elements in the array must be numbers.");
    }
  }

  filter(data1: any, data2: any, operator: FilterOperator) {
    switch (operator.value) {
      case "isGreaterThan":
        return this.isGreaterThan(data1);
      case "isGreaterThanOrEqual":
        return this.isGreaterThanOrEqual(data1);
      case "isLessThan":
        return this.isLessThan(data1);
      case "isLessThanOrEqual":
        return this.isLessThanOrEqual(data1);
      case "isEqual":
        return this.isEqual(data1);
      case "isNotEqual":
        return this, this.isNotEqual(data1);
      case "isEmpty":
        return this.isEmpty();
      case "isNotEmpty":
        return this.isNotEmpty();
      case "between":
        return this.between(data1, data2);
      case "none":
        return this.data;
    }
  }

  isGreaterThan(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => item.name > v);
  }

  isGreaterThanOrEqual(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => item.name >= v);
  }

  isLessThan(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => item.name < v);
  }

  isLessThanOrEqual(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => item.name <= v);
  }

  isEqual(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => item.name == v);
  }

  isNotEqual(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => item.name != v);
  }

  isEmpty(): string[] {
    return this.data.filter((item) => item.name === emptyValueSign);
  }

  isNotEmpty(): string[] {
    return this.data.filter((item) => item.name !== emptyValueSign);
  }

  between(start: any, end: any): any[] {
    const v = this.normalizeValue(start);
    const v2 = this.normalizeValue(end);
    return this.data.filter((item) => item.name >= v && item.name <= v2);
  }

  normalizeValue(data: any) {

    return Number(data);
  }
}
