export type DataType = "string" | "uint18" | "int8" | "int16" | "uint16" | "int32" | "uint32" | "int64" | "uint64" | "single" | "decimal" | "datetime" | "boolean";

export const emptyValueSign = '-';

export function isNumberType(dataType: DataType | string): boolean {
  return ["int8", "uint8", "int16", "uint16", "int32", "uint32", "int64", "single", "decimal", "double", "float", "number"].includes(dataType);
}

export function isTextType(dataType: DataType | string): boolean {
  return dataType === "string";
}

export function isDateType(dataType: DataType | string): boolean {
  return dataType === "datetime";
}

export function isBooleanType(dataType: DataType | string): boolean {
  return dataType === "boolean" || dataType === "uint64";
}
