import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { CustomValidators } from "./custom-validators";

/**
 * @constructor argument path to controls
 * @example
 * class ... extends LinieValidationManager ...
 * constructor(...) {
 *  super({ start: 'von', end: 'bis' });
 *  this.form = this.formGroup; // Das Reference vom formGroup zu LinieValidators Class übertragen
 * }
 * this.direction = this.initLinieValidators(this.startmeter, this.endmeter);
 */
export class LinieValidationManager {
  constructor(
    private path?: { start: string, end: string },
    private allowZero = false
  ) {
    this.path ??= { start: 'Startmeter', end: 'Endmeter' };
  }
  public form: UntypedFormGroup;
  private initialisiert = false;
  /**
   * @argument required - adds Validators.required to Start&End oder MindestensEinWertValidator to form
   * @see CustomValidators.MindestensEinWertValidator
   * @see CustomValidators.DirectionValidator
   * @argument f - falls `this.form = this.%formGroupName%` nicht gemacht wurde
   * @returns direction = start < end ? 1 : -1
   */
  initLinieValidators(start: number, end: number, required: boolean = true, f?: UntypedFormGroup): 1 | -1 {
    this.form ??= f;
    const validators = [
      Validators.min(Math.min(start, end)),
      Validators.max(Math.max(start, end))
    ];
    if (required) validators.push(Validators.required);

    if (!this.initialisiert) {
      if (this.form.disabled) setTimeout(() => {
        this.form.disable();
      });
      this.form.setControl(this.path.start, new UntypedFormControl('', {
        validators,
        updateOn: 'blur'
      }));
  
      this.form.setControl(this.path.end, new UntypedFormControl('', {
        validators,
        updateOn: 'blur'
      }));
      this.initialisiert = true;
    }
    else {
      this.form.get(this.path.start).setValidators(validators);
      this.form.get(this.path.end).setValidators(validators);
    }

    const direction = start < end ? 1 : -1;
    this.form.setValidators(
      CustomValidators.DirectionValidator(direction, this.path, this.allowZero)
    );
    if (!required) this.form.addValidators(
      CustomValidators.MindestensEinWertValidator([ this.path.start, this.path.end ])
    );
    return direction;
  }

  clearLinieValidators(form?: UntypedFormGroup) {
    this.form ??= form;
    this.form.get(this.path.start).clearValidators();
    this.form.get(this.path.end).clearValidators();
    this.form.clearValidators();
    this.form.get(this.path.start).updateValueAndValidity({ onlySelf: true });
    this.form.get(this.path.end).updateValueAndValidity();
  }
}
