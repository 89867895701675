import { CommonModule } from "@angular/common";
import { Component, EventEmitter } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ClarityModule } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "../../../../services/APIService/api.service";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";

@Component({
  selector: 'app-pruefungenverwaltendialog',
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule,HideIDColumnsPipe,TablePrettyPrintPipe,DotDotDotPipe],
  templateUrl: './pruefungenverwaltendialog.component.html',
  styleUrls: ['./pruefungenverwaltendialog.component.scss'],
  standalone: true
})
export class PruefungenverwaltendialogComponent {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
  ) {
  }
  set data(dataIn: any) {
    this.loadItems();
  }

  protected formGroupKP = new UntypedFormGroup({
    titel: new UntypedFormControl(''),
    intervallpr: new UntypedFormControl(12,[Validators.required, Validators.pattern('^\\d+$')]),
    intervallme: new UntypedFormControl(12, [Validators.required, Validators.pattern('^\\d+$')]),
  });


  selPruefID: number = 0;
  lstObjects:any;
  sotyp: any;
  pruefungen: any;
  pruefungenOriginal: any;
  selTreeNote: any;
  selpruefung: any;
  kpruvZuordnung: any;
  anzpruef: number = 0;
  showPruefModal: boolean = false;
  showPruefModalTitel: string = "Prüfungsart bearbeiten";
  showPruefModalStatus: number = 0;
  deletdisabled: boolean = true;

  private loadItems() {
    this.apiservice
      .getPruefungsVerwaltung()
      .pipe(first())
      .subscribe((val: any) => {
        this.sotyp = val.sotyp;
        this.pruefungen = val.kpruv;
        this.pruefungenOriginal = JSON.parse(JSON.stringify(val.kpruv.rows));
        this.kpruvZuordnung = val.sxkpo.rows;

        for(let row of this.sotyp){
          row.selected = false;
          row.disabled = false;
        }

        this.selpruefung = this.pruefungen.rows[0];
        this.selectionPruefungChanged(this.selpruefung);
      });
  }


  selectionPruefungChanged(value: any) {
    for(let row of this.sotyp){
      row.selected = false;
      row.disabled = false;
    }

    this.selPruefID = value.ID
    this.anzpruef = value['Anzahl Prüfungen'];
    this.deletdisabled = (this.selPruefID == 0 || this.selPruefID == 1) && this.anzpruef != 0;

    let kpruvlist  = this.kpruvZuordnung.filter(items => items.KPRUVID == this.selPruefID);

    // let result = kpruvlist
    //   .filter((item1) => {
    //     return this.sotyp.rows.some((item2) => item1.OTYPID === item2.id);
    //   });

    for(let row of this.sotyp){
      row.selected = kpruvlist.some(x => x.OTYPID === row.id);
      row.disabled = (this.selPruefID == 1);
    }
  }

  newitem(){
    this.formGroupKP.controls.titel.setValue('');
    this.formGroupKP.controls.intervallpr.setValue(12);
    this.formGroupKP.controls.intervallme.setValue(12);

    this.selPruefID = 0;
    this.showPruefModalStatus = 1;
    this.showPruefModal = true;
    this.showPruefModalTitel = "neue Prüfungsart erstellen";
  }

  showedititem(){

    this.formGroupKP.controls.titel.setValue(this.selpruefung.Bezeichnung);
    this.formGroupKP.controls.intervallpr.setValue(this.selpruefung.Prüfintervall);
    this.formGroupKP.controls.intervallme.setValue(this.selpruefung.Messintervall);

    this.showPruefModalStatus = 0;
    this.showPruefModal = true;
    this.showPruefModalTitel = "Prüfungsart bearbeiten";
  }

  savePR(){
    if (this.formGroupKP.valid){
      let vals =  this.formGroupKP.value;

      if(this.pruefungenOriginal.some((item) => item.Bezeichnung.toLowerCase() == vals.titel.toLowerCase().trimEnd().trimStart() && item.ID != this.selPruefID)){
        this.toastr.error(this.mrTranslate.transform("Eintrag existiert bereits !!"));
        return;
      }

      let items = {
        status: "savekpruv",
        kpruv: vals,
        pruefid: this.selPruefID,
      }
      this.apiservice
        .setPruefungsVerwaltung(items)
        .pipe(first())
        .subscribe((val: any) => {
          try {
            this.pruefungen.rows = val.kpruv.rows;
            this.pruefungenOriginal = JSON.parse(JSON.stringify(val.kpruv.rows));
            this.selpruefung = this.pruefungen.rows[0];
            this.showPruefModal = false;
            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          } catch (_) {
            this.toastr.error(this.mrTranslate.transform("Beim Speichern ist ein Fehler aufgetreten"));
          }
      });
    }
  }

  saveitem(){
    let sxkpo  = this.sotyp.filter(items => items.selected == true);
    let items = {
      status: "sxkpo",
      sxkpo: sxkpo,
      pruefid: this.selPruefID,
    }
    this.apiservice
      .setPruefungsVerwaltung(items)
      .pipe(first())
      .subscribe((val: any) => {
        this.kpruvZuordnung = val.sxkpo.rows;
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
    });
  }


  deletitem(){

    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Prüfungsart löschen wollen?"))) {
      let items = {
        status: "deletekpruv",
        pruefid: this.selPruefID,
      }
      this.apiservice
        .setPruefungsVerwaltung(items)
        .pipe(first())
        .subscribe((val: any) => {
          this.pruefungen.rows = val.kpruv.rows;
          this.pruefungenOriginal = JSON.parse(JSON.stringify(val.kpruv.rows));
          this.kpruvZuordnung = val.sxkpo.rows;
        });
    }
  }

  public close() {
    this.closedialog.next(true);
  }
}
