
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

import { CommonModule } from "@angular/common";
import { Component, EventEmitter, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import dayjs from "dayjs";
import JSZip from 'jszip';
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { APIService } from "../../../../services/APIService/api.service";
import { PaintingComponent } from "../../_shared/paint/painting.component";

@Component({
  selector: "app-auftragdialog",
  templateUrl: "./auftragdialog.component.html",
  styleUrls: ["./auftragdialog.component.scss"],
  imports: [CommonModule, ClarityModule, PaintingComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, ObjTypePipe, HideIDColumnsPipe,
    TablePrettyPrintPipe, BackendLocaleDatePipe],
  providers: [ BackendLocaleDatePipe ],
  standalone: true

})
export class AuftragdialogComponent {
  ostammid: number;
  typ_Line_Point: number;
  latlng: string;
  imgSrc: any;
  firmen: Array<any>;
  mangel: any;
  output: any = {};
  date: any;
  selected: any = [];
  auftragnehmer: number;
  auftragsart: number;
  auftragsnr: number = undefined;
  bemerkung: string = undefined;

  trial = false;

  auftragsarten: Array<any>;
  result: Array<any>;
  openTab = "mangel";
  isload: boolean = false;
  Opt_F_Drawing:any;
  auftragsgroups: Array<any>;

  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  mapOptions = {
    center: {
      lat: 49.007252,
      lng: 8.414331,
    },
    zoom: 10,
    marker: [
      {
        lat: 49.008189,
        lng: 8.424115,
      },
    ],
  };
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();

  // public showAssignment: BehaviorSubject<Object> = new BehaviorSubject({});

  auftragGroup = new UntypedFormGroup({
    startdate: new UntypedFormControl("", [Validators.required]),
    enddate: new UntypedFormControl("", [Validators.required]),
    auftragnehmer: new UntypedFormControl("", [Validators.required]),
    auftragsart: new UntypedFormControl(""),
    auftragsgruppe: new UntypedFormControl(""),
    auftragsnr: new UntypedFormControl(""),
    bemerkung: new UntypedFormControl(""),
    kostenart: new UntypedFormControl(""),
    titel: new UntypedFormControl(""),
    type: new UntypedFormControl("Einzelauftrag"),
    gruppenid: new UntypedFormControl(1, [Validators.required]),
  });

  set data(dataIn: any) {
    this.ostammid = dataIn.id;
    this.typ_Line_Point = dataIn.typ_Line_Point;
    this.latlng = dataIn.latlng;
    this.Opt_F_Drawing = dataIn.Opt_F_Drawing;
    let url =
      "https://maps.googleapis.com/maps/api/staticmap?center=" +
      this.latlng +
      "&zoom=18&scale=2&size=600x300&maptype=satellite&key=AIzaSyBywoqwb3PQ6iY732xWLUwfQGyt45syB-0&format=png&signature=OY-3lAeiIBBnJkOmxxGgW7rH4A8=&visual_refresh=true&markers=size:small%7Ccolor:0xff0000%7Clabel:%7C" +
      this.latlng;
    this.imgSrc = url;
    if(dataIn.dataRefresh) {
      this.dataRefresh = dataIn.dataRefresh;
    }

    this.apiservice
    .getAuftragsGroups()
    .pipe(first())
    .subscribe((res: any) => {
      if (res) {
        this.auftragsgroups = res.map((item) => {
          return {
            id: item.id,
            mBezeichnung: `${item.Bezeichnung}`,
          };
        });
      }
    });

    this.apiservice
      .getAuftraege()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
          jsZip.loadAsync(res).then((zip) => {
            return zip.file("tmp.txt").async("string");
          }).then((zipf) => {
            this.result = [...new Set(JSON.parse(zipf).rows.map(item => item.auftragart))];
            this.result = this.result.filter(notEmpty);
            this.auftragsarten = this.result.sort();
          });

        }
    });

    this.apiservice
      .getAnlagenAuftrag(this.ostammid, 0)
      .pipe(first())
      .subscribe((val: any) => {
        this.firmen = val.firmen;
        this.mangel = val.mangel;

        this.isload = true;
      });


  }

  constructor(
    //@Inject(MAT_DIALOG_DATA) public data,
    private apiservice: APIService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private authservice: AuthService,
    private mrTranslate: MrTranslatePipe,
    private overlayService: OverlayService,
    protected localeDate: BackendLocaleDatePipe,
  ) {
    const { auftragslaenge: laenge = 3, trial = false } = {} = this.authservice.getToken();
    this.auftragGroup.controls.startdate.setValue(BackendLocaleDatePipe.now);
    this.auftragGroup.controls.enddate.setValue(
      dayjs().add(laenge, 'days').format(BackendLocaleDatePipe.format)
    );
    this.trial = trial === true;
  }

  tabChange(tab) {
    this.openTab = tab;
  }

  getAuftragsgruppeName(id: any) {
    let gruppe;
    if (this.auftragsgroups)
      gruppe = this.auftragsgroups.filter((item) => item.id == id)[0]
        ?.mBezeichnung;
    return gruppe || undefined;
  }

  onSubmit() {
    let data = this.auftragGroup.value;
    data.strOMAZSID = this.selected.map(row => row.OMAZSID).join(" ");
    data.ostammid = this.ostammid;
    data.otypid = this.typ_Line_Point;

    data.auftragsgruppe = 1;
    data.kostenart = "";
    data.titel = "Instandsetzung";
    data.type = "Einzelauftrag";
    data.language = localStorage.getItem("language");

    if (data.startdate && data.enddate) {
      const now = dayjs();
      const start = dayjs(data.startdate);
      const end = dayjs(data.enddate);
      let bigger = !end.isBefore(start, 'days');
      let nowCS = !start.isBefore(now, 'days');
      let nowCE = !end.isBefore(now, 'days');

      if (bigger && nowCS && nowCE) {
        if (
          data.auftragnehmer == "" ||
          data.startdate == "" ||
          data.enddate == "" ||
          data.strOMAZSID == ""
        ) {
          this.toastr.warning(
            this.mrTranslate.transform(
              "Bitte füllen Sie Start- & Enddatum, Auftragsnehmer & -nummer, sowie mindestens einen Mangel aus."
            )
          );
        } else {
          this.spinner.enable();
          this.apiservice
            .sendAuftrag(data)
            .pipe(first())
            .subscribe((val) => {
              this.toastr.success(
                this.mrTranslate.transform("Eintrag erfolgreich gespeichert")
              );
              this.spinner.disable();
              this.auftragGroup.reset();
              if (val) {
                this.dataRefresh?.emit(true);
                this.overlayService.setOverlay({
                  isTopOverlay: true,
                  overlay: "viewAuftrag",
                  data: val,
                  auftragsnr: data.auftragsnr,
                  zIndex: 5,
                });
              }
            });
        }
      } else {
        this.toastr.warning(
          this.mrTranslate.transform(
            "Beide Daten müssen in der Zukunft und das Startdatum muss vor dem Enddatum liegen."
          )
        );
      }
    }
  }

  public close() {
    this.closedialog.next(true);
  }

}
