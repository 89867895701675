export const environment = {
  mode: 'd',
  production: true,
  hmr: false,
  apiUrl: 'https://devserver.mrpro.cloud',
  debugTranslation: false,
  okta: {
    redirectUri: 'https://dev.mrpro.cloud/login/callback',
    logoutUri: 'https://dev.mrpro.cloud/',
    clientId: '0oacbgbmmwKUoXtwt0i7',
    domain: 'login.vossloh-digital.com',
    showLogin: true,
  },
  msalConfig: {
    redirectUri: 'https://dev.mrpro.cloud',
    showLogin: true,
  },
  apiConfig: {
    scopes: ['ENTER_SCOPE'],
    uri: 'ENTER_URI',
  },
};
