import { Component, Input, Output, ElementRef, EventEmitter, ViewChild, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
//import { ClarityModule } from "@clr/angular";

export enum Rotation {
  TOP,
  LEFT,
  BOTTOM,
  RIGHT
}

@Component({
    selector: "app-cameramodal",
    templateUrl: "./cameramodal.component.html",
    styleUrls: ["./cameramodal.component.scss"],
    imports: [CommonModule, ClarityModule, MrTranslatePipe],
    standalone: true
})
export class CameramodalComponent {
  isOpen: boolean = false;

  get parentChildConnection(): boolean {
    return this.isOpen;
  }

  @Input() set parentChildConnection(setting: boolean) {
    this.isOpen = setting;
    if (setting === false) {
      this.saveSettings();
      this.cleanUp();
      this.modalClosed.emit();
    }
    else{
      this.video = this.createInMemoryVideoElement(this.document);
      this.loadSettings();
      if (this.videoDeviceId && !this.isSafari()) {
        this.setupStream(this.videoDeviceId);
      } else {
        this.setupStream();
      }
    }
  }

  @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    //private mrTranslate: MrTranslatePipe,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @ViewChild('canvas')

  public canvas: ElementRef;

  public canTorch: boolean;

  public torchOn: boolean;

  public canZoom: boolean;

  public zoomMax: number;

  public zoomMin: number;

  public zoomStep: number;

  public zoomValue: number;

  public canSwapCamera: boolean;

  public pictureData: string;

  private video: HTMLVideoElement;

  private stream: MediaStream;

  private videoDevices: any[];

  private videoDeviceId: string;

  private nextVideoDevice: number = 0;

  private animationFrameId: number;

  private rotation: number = 0;


  public async swapCamera(): Promise<void> {
    if (navigator.mediaDevices && typeof navigator.mediaDevices.enumerateDevices === 'function' && !this.isSafari()) {
      if (!this.videoDevices) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.videoDevices = devices.filter(device => device.kind == 'videoinput');
      }
      const index = this.nextVideoDevice % this.videoDevices.length;
      this.videoDeviceId = this.videoDevices[index].deviceId;
      this.cleanUp();
      this.setupStream(this.videoDeviceId);
      this.nextVideoDevice++;
    }
  }

  public takePicture(): void {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    this.pictureData = canvas.toDataURL('image/jpeg');
//console.log("bild", this.pictureData)
    localStorage.setItem('foto', this.pictureData);

    this.saveSettings();
    this.cleanUp();
    this.parentChildConnection = false;

  }


  private async setupStream(deviceId?: string): Promise<void> {
    let constraints: MediaStreamConstraints;
    if (deviceId) {
      constraints = { video: { deviceId } };
    } else {
      constraints = { video: { facingMode: 'environment' } };
    }
    try {
      this.stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error) {
      if (deviceId) {
        // if failed with device id try again without
        this.setupStream();
      }
    }
    if (this.stream) {
      // start video
      this.addStreamToVideo(this.stream, this.video);
      this.stopCanvas();
      const canvas: HTMLCanvasElement = this.canvas.nativeElement;
      this.startCanvas(canvas, this.video);
      this.setupControls(this.stream.getVideoTracks()[0])
    }
  }

  private addStreamToVideo(stream: MediaStream, video: HTMLVideoElement): void {
    if ('srcObject' in video) {
      video.srcObject = stream;
    }
    video.load();
  }

  private setupControls(track: MediaStreamTrack): void {
    if (track && typeof track.getCapabilities === 'function') {
      // capabilities not imediatly avalible and no events fire reliably after avalible
      setTimeout(() => {
        const capabilities = track.getCapabilities();
        this.canTorch = (capabilities as any).torch;
        this.canZoom = (capabilities as any).zoom;
        if (this.canZoom) {
          this.zoomMax = (capabilities as any).zoom.max;
          this.zoomMin = (capabilities as any).zoom.min;
          this.zoomStep = (capabilities as any).zoom.step;
        }
        const settings = track.getSettings();
        this.torchOn = (settings as any).torch;
        this.zoomValue = (settings as any).zoom;
      }, 1000);
    }
    if (navigator.mediaDevices && typeof navigator.mediaDevices.enumerateDevices === 'function' && !this.isSafari()) {
      this.canSwapCamera = true;
    }
  }

  private updateCanvas(canvas: HTMLCanvasElement, video: HTMLVideoElement): void {
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    switch (this.rotation) {
      case Rotation.TOP:
      default:
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        break;
      case Rotation.LEFT:
        canvas.width = video.videoHeight;
        canvas.height = video.videoWidth;
        context.transform(0, -1, 1, 0, 0, video.videoWidth);
        break;
      case Rotation.BOTTOM:
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.transform(-1, 0, 0, -1, video.videoWidth, video.videoHeight);
        break;
      case Rotation.RIGHT:
        canvas.width = video.videoHeight;
        canvas.height = video.videoWidth;
        context.transform(0, 1, -1, 0, video.videoHeight, 0);
        break;
    }
    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  }

  private startCanvas(canvas: HTMLCanvasElement, video: HTMLVideoElement): void {
    const frame = () => {
      this.updateCanvas(canvas, video);
      this.animationFrameId = requestAnimationFrame(frame);
    }
    this.animationFrameId = requestAnimationFrame(frame);
  }

  private stopCanvas(): void {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  private stopStream(stream: MediaStream) {
    stream.getTracks().forEach(track => {
      track.stop();
    })
  }

  private cleanUp(): void {
    this.stopCanvas();
    // revoke if fallback to createObjectURL was used
    if (this.video)
      if (this.video.src) {
        URL.revokeObjectURL(this.video.src);
      }

    if (this.stream) {
      this.stopStream(this.stream);
    }
  }

  private createInMemoryVideoElement(document: Document): HTMLVideoElement {
    const video: HTMLVideoElement = document.createElement('video');
    video.setAttributeNode(document.createAttribute('playsinline'));
    video.autoplay = true;
    return video;
  }

  private saveSettings(): void {
    if (this.rotation != null) {
      localStorage.setItem('camera-rotation', JSON.stringify(this.rotation));
    }
    if (this.videoDeviceId != null && !this.isSafari()) {
      localStorage.setItem('camera-device-id', JSON.stringify(this.videoDeviceId));
    }
  }

  private loadSettings(): void {
    const rotation = JSON.parse(localStorage.getItem('camera-rotation'));
    const videoDeviceId = JSON.parse(localStorage.getItem('camera-device-id'));
    if (rotation != null) {
      this.rotation = rotation;
    }
    if (videoDeviceId != null && !this.isSafari()) {
      this.videoDeviceId = videoDeviceId;
    }
  }

  private isSafari(): boolean {
    return new RegExp('^((?!chrome|android).)*safari', 'i').test(navigator.userAgent);
  }

  public use(): void {
    //this.cameraRef.close(this.pictureData);
  }
}
