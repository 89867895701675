import { isDevMode } from "@angular/core";

export class PerformanceTrackerService {
  private static componentLoadTimes: Map<string, number> = new Map();

  static startComponentLoad(componentName: string): void {
    PerformanceTrackerService.componentLoadTimes.set(componentName, performance.now());
  }

  static endComponentLoad(componentName: string): void {
    const startTime = PerformanceTrackerService.componentLoadTimes.get(componentName);
    if (startTime) {
      const endTime = performance.now();
      const loadTime = endTime - startTime;
      if (isDevMode())
        console.debug(`${componentName} loaded in ${loadTime} milliseconds`);
    }
  }
}
