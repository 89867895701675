import { Component,  EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { Base64Service, FileObject } from 'src/app/services/base64/base64.service';
import { DropZoneDirective } from 'src/app/directives/drop-zone.directive';
import { AngularDeviceInformationService } from 'angular-device-information';
import { CameraComponent } from 'src/app/content/_components/_shared/camera/camera.component';
import { PreviewThumbnail } from './preview-thumbnail.type';
import dayjs from 'dayjs';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { MediaViewModalComponent } from '../mediaviewmodal/mediaviewmodal.component';

/** For saving the files to server use await packUnsavedFiles() method. */
@Component({
    selector: 'app-preview-thumbnails',
    templateUrl: './preview-thumbnails.component.html',
    styleUrls: ['./preview-thumbnails.component.scss'],
    imports: [CommonModule, ClarityModule, DropZoneDirective, CameraComponent, DragDropModule, MrTranslatePipe, MediaViewModalComponent],
    standalone: true
})
export class PreviewThumbnailsComponent {
  //#region public Properties
  /**
   * @description für Verwaltung im Front-End
   * @warning `für Speichern im Back-End nicht geeignet`
   */
  public get rawPreviewThumbnails(): PreviewThumbnail[] {
    return this.previewThumbnails;
  }
  public set rawPreviewThumbnails(value: PreviewThumbnail[]) {
    this.previewThumbnails = value;
  }

  public get unsaved(): any[] {
    return this.previewThumbnails.filter((file) => !file.saved)
  }
  public get saved(): any[] {
    return this.previewThumbnails.filter((file) => file.saved)
  }
    //#region @Input
  /**
   * @example [acceptTypes]="'image/*, video/*, .pdf'"
   * @example [acceptTypes]="'.png, .svg'"
   */
  @Input() acceptTypes = "image/*, video/*";
  /**
   * @example [rejectTypes]="'.svg'"
   */
  @Input() rejectTypes = "";
  @Input() maxAmount: number = 100;
  /**
   * @property sperrt Hochladen, Löschen
   * @example <app-preview-thumbnails [readonly]="condition | true">
   */
  @Input('readonly') disabled = false;
  @Input() galleryMode = true;

  @Input() drag_drop: boolean = false;
  @Input() drag_move: boolean = false;

  @Input() showCamera: boolean = true;
  @Input() isSkizze: boolean = false;
  @Input() set data (files: any[]) {
    files?.length ? this.unpackFiles(files) : this.clearPreview();
  };

  /**
   * @alias getBigFileFnc
   * @function base64 beim Server anfordern (mit Dateigröße ab 9,5 MB)
   * @async
   * @returns Promise - string
   */
  @Input('getBigFileFnc') fileBase64FromServer: (idOrName: number | string) => Promise<string>;
  /**
   * @function condition für highlight class Anwendung
   * @returns true/false - class active/inactive
   */
  @Input() highlightFnc = (_: string) => false;
    //#endregion
    //#region @Output
  /** @emits name beim Hochladen eines Preview-Thumbnails */
  @Output() lastUpload = new EventEmitter<string>;
  /**
   * @emits beim Erreichen maxAmount der Preview-Thumbnail Anzahl
   * @see maxAmount
   */
  @Output() reachedMax = new EventEmitter<void>;
  /** @emits immer beim Löschen einen Preview-Thumbnail */
  @Output() clearedThumb = new EventEmitter<void>;
  /**
   * @emits `idOrName`  - Beim Löschen eines Preview-Thumbnails
   * @condition file.saved && file.name
   * @description für Löschen auch auf dem Server/Datenbank
   */
  @Output() deleteFile = new EventEmitter<number|string>;
  /**
   * @required if allowTypes includes PDF
   * @emits `File` - für Öffnen mit viewauftragdialog oder custom iframe
   * @example pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL($event))
   * @template <iframe *ngIf="pdfUrl" [attr.src]="pdfUrl" style="border: none; width: 100%; height: 50svh;"></iframe>
   */
  @Output() openPdfOverlay = new EventEmitter<File>;
    //#endregion
  //#endregion
  //#region hidden Properties
  protected previewThumbnails: PreviewThumbnail[] = [];

  protected openCameraModal: boolean = false;
  protected isWindows: boolean;

  private sperrSort: boolean = false;

  private get allowImage() {
    return this.acceptTypes.includes("image");
  }
  private get allowVideo() {
    return this.acceptTypes.includes("video");
  }
  private get allowPdf() {
    return this.acceptTypes.includes("pdf");
  }
  //#endregion

  constructor(
    private domSanitizer: DomSanitizer,
    private mrTranslate: MrTranslatePipe,
    private toastr: ToastrService,
    private base64service: Base64Service,
    private deviceInformationService: AngularDeviceInformationService,
  ) {
    this.isWindows = this.deviceInformationService.getDeviceInfo().os === "Windows";
  }
  //#region public Methods
  public clearPreview() {
    this.previewThumbnails = [];
  }

  /**
   * @method Erzeugt PreviewThumbnail vom base64 aus dem Back-End
   * @param files - {name: string, base64; string, id?: number}[]
   * @pattern name - `{name}.{extension}`
   * @param saved - if false, wird auch zum Speichern mit packUnsavedFiles() zusammengefasst
   */
  public unpackFiles(files: any[], saved: boolean = true) {
    files?.forEach(({ id, name, base64 }: any) => {
      const extension = name?.split(".").pop().toLowerCase() || 'png';
      let opens = false;
      let plays = false;
      let type = 'image/';
      let src = "";
      switch (extension) {
        case "svg":
          src = "data:image/" + extension + "+xml;base64," + base64;
          break;
        case "mp4":
        case "mov":
          src = "/assets/icons/videoicon.png";
          plays = true;
          type = 'video/' + extension;
          break;
        case "pdf":
          src = "/assets/icons/pdf-file-icon.png";
          opens = true;
          type = 'application/pdf';
          break;

        default:
          src = "data:image/" + extension + ";base64," + base64;
          type += extension;
          break;
      }
      this.previewThumbnails.push({
        src: this.domSanitizer.bypassSecurityTrustUrl(src),
        base64,
        file: { name: name?.split("$").pop(), type },
        extension,
        saved,
        date: saved ? null : dayjs().format('DD.MM.YYYY HH.mm.ss.SSS'),
        id,
        name, // for id im Server
        plays,
        opens,
        highlight: this.highlightFnc(name)
      });
    });

    this.sortFiles();
  }

  /**
   * @method Konvertiert File[] zu Back-End geeignete Objekte mit base64, type, addedDate
   * @async
   * @example await this.preview.packUnsavedFiles();
   */
  public packUnsavedFiles(): Promise<FileObject[]> {
    return this.base64service.packFiles(this.unsaved);
  }

  /** @method Sets saved = true; für Benutzen nach Speichern im Back-End*/
  public setSaved() {
    this.previewThumbnails.forEach(
      (thumbnail) => thumbnail.saved = true
    );
  }

  public forceOpenPdf(index: number) {
    this.openPdfDoc(index);
  }
  //#endregion
  //#region hidden Methods
  /**
   * @method Konvertiert base64 zu File, if kommt vom Server
   * @emits openPdfOverlay(File)
   */
  protected async openPdfDoc(index: number) {
    const fileObj = this.previewThumbnails[index];
    try {
      //! File
      //* - wenn neu hochgeladen;
      if (!(fileObj.file instanceof File)) {
        //* - aus base64:
        //   - fileObj
        //   - beim Server anfordern, wenn Size mehr als 9,5 MB
        fileObj.base64 ??= await this.fileBase64FromServer(fileObj.id || fileObj.name);
        fileObj.file = this.base64service.convertFileAsBlob(
          fileObj.base64, "application/pdf", 512, fileObj.file.name
        );
      }
      this.openPdfOverlay.emit(<File>fileObj.file);
    } catch (er) {
      this.toastr.error((<ErrorEvent>er).message);
    }
  }
  /** @method Konvertiert base64 oder File zu URL */
  protected async openVideo(index: number) {
    const fileObj = this.previewThumbnails[index];
    if (!fileObj.url) {
      try {
        //! url
        //* - vom File, wenn neu hochgeladen;
        if (fileObj.file instanceof File) {
          fileObj.url = URL.createObjectURL(<File>fileObj.file);
          fileObj.base64 = fileObj.url.split(",").pop();
        } else {
          //* - aus base64:
          //   - fileObj
          //   - beim Server anfordern, wenn Size mehr als 9,5 MB
          fileObj.base64 ??= await this.fileBase64FromServer(fileObj.id || fileObj.name);
          fileObj.url = "data:video/mp4;base64," + fileObj.base64;
        }
      } catch (er) {
        this.toastr.error((<ErrorEvent>er).message);
      }
    }
  }
  /**
   * @method Erzeugt PreviewThumbnail aus der User-Input;
   * `Image` - sofort und async zu base64 konvertiert
   * @param files - aus der `FileInput` oder `Dropzone` Directive
   * @emits lastUpload(name)
   * @throws maximal erlaubte Anzahl von Files überschritten
   * @throws nicht zugelassene Typ vom File wurde hochgeladen
   * @throws Dateigröße 50 MB wurde überschritten
   */
  protected async onFileChange(files: File[]) {
    if (!files.length) return;

    const promisesArray: Promise<void>[] = [];
    let showSizeWarning = false;
    let showTypeWarning = false;

    for (const file of files) {
      if (!file) continue;
      if (!this.validAmount(promisesArray.length)) break;
      // ! Wenn Size ist größer als 50 MB
      if ((file.size / 1024 / 1024) > 50) {
        this.toastr.error(file.name + this.mrTranslate.transform(" kann nicht geladen werden!"));
        showSizeWarning = true;
        continue;
      }

      const extension = file.name.split(".").pop().toLowerCase();
      const date = dayjs().format('DD.MM.YYYY HH.mm.ss.SSS');

      if (file.type.includes("image") && (this.allowImage || this.acceptTypes.includes(extension)) && !this.rejectTypes.includes(extension)) {
        promisesArray.push(new Promise<void>(async (resolve, reject) => {
          try {
            const src = await this.base64service.getUrlStringFromFile(file);
            this.previewThumbnails.unshift({
              src: this.domSanitizer.bypassSecurityTrustUrl(src),
              base64: src.split(",").pop(), // get rid of data:image/{type};base64,
              file,
              extension,
              date,
            });
            this.lastUpload.emit(file.name);
            resolve();
          } catch (er) {
            reject((<ErrorEvent>er).message);
          }
        }));
      } else if (file.type.includes("video") && (this.allowVideo || this.acceptTypes.includes(extension)) && !this.rejectTypes.includes(extension)) {
        this.previewThumbnails.push({
          src: this.domSanitizer.bypassSecurityTrustUrl("/assets/icons/videoicon.png"),
          base64: null,
          file,
          extension,
          date,
          plays: true
        });
        this.lastUpload.emit(file.name);
      } else if (file.type == "application/pdf" && (this.allowPdf || this.acceptTypes.includes(extension)) && !this.rejectTypes.includes(extension)) {
        this.previewThumbnails.push({
          src: this.domSanitizer.bypassSecurityTrustUrl("/assets/icons/pdf-file-icon.png"),
          base64: null,
          file,
          extension,
          date,
          opens: true,
        });
        this.lastUpload.emit(file.name);
      } else {
        console.log(file.type);
        this.toastr.error(file.name + this.mrTranslate.transform(" kann nicht geladen werden!"));
        showTypeWarning = true;
      }
    }

    if (showSizeWarning) this.toastr.warning(
      this.mrTranslate.transform("Sie können Dateien nur bis zu 50 MB hochladen!")
    );
    if (showTypeWarning) {
      const text = [this.allowImage ? 'Bilder' : [],  this.allowVideo ? 'Video' : [],  this.allowPdf ? 'PDF Dokumente' : []].flat().join(" oder ");
      if (text) this.toastr.warning(
        this.mrTranslate.transform(`Sie können nur ${ text } hochladen!`)
      );
    }

    await Promise.allSettled(promisesArray);
    this.sortFiles();
  }

  openimage: boolean = false;
  imgSrc: any;
  protected imageMedia: any;

  openDoc(index: number) {
    //this.imgSrc = this.previewThumbnails[index].src;
    //this.openimage = true;

    this.imageMedia = [];
    this.imageMedia.push(
      {
        title: this.mrTranslate.transform("Passbild"),
        fileNames:[this.previewThumbnails[index]],
      }
    )
  }



  /**
   * @method Löscht previewThumbnail aus dem Array
   * @emits deletedFiles(idOrName) - wenn saved && name
   * @emits clearedThumb()
   */
  protected deleteDoc(index: number) {
    const thumb = this.previewThumbnails[index];
    if (thumb.saved) {
      if (thumb.name) {
        this.deleteFile.emit(thumb.id || thumb.name);
        // ? Wofüf hier?? Wird neu hinzugefügte Dateien nie speichern
        // this.setSaved();
      } else {
        this.toastr.error(this.mrTranslate.transform("Dateien können nach dem erneuten Öffnen gelöscht werden!"))
        return;
      }
    }
    this.previewThumbnails.splice(index, 1);
    this.clearedThumb.emit();
  }

  /**
   * @method Erzeugt PreviewThumbnail aus der Camera-Input;
   * @param urlString - aus der Camera Modal
   * @pattern urlString = `data:{image | video}/{type};base64,{base64}`
   * @throws maximal erlaubte Anzahl von Files überschritten
   */
  protected onTakenFoto(urlString: string) {
    if (!this.validAmount()) return;
    const date = dayjs().format('DD.MM.YYYY HH.mm.ss.SSS');
    const name = date + ".jpeg";
    this.previewThumbnails.unshift({
      src: this.domSanitizer.bypassSecurityTrustUrl(urlString),
      base64: urlString.split(",").pop(), // get rid of data:image/{type};base64,
      file: {
        name,
        type: "image/jpeg",
      },
      extension: "jpeg",
      date,
    });
    this.lastUpload.emit(name);
    this.toastr.info(this.mrTranslate.transform("Foto wurde hinzugefügt!"))
  }

  /** @sort Files Bilder -> Video -> PDF -> Highlight*/
  private sortFiles() {
    if (this.sperrSort) return;

    this.previewThumbnails.sort(
      ({ opens: opensA = false, plays: playsA = false, highlight: highlightA = false },
        { opens: opensB = false, plays: playsB = false, highlight: highlightB = false }) => {
        // * Beide sind gleich Typ
        if (opensA == opensB && playsA == playsB && highlightA == highlightB) return 0;

        // * Highlight am Ende
        if (highlightA) return 1;

        // * PDF nach Bilder und nach Video, aber vor Highlight
        if (opensA && !highlightB) return 1;

        // * Video nach Bilder, aber vor PDF
        if (playsA && !opensB) return 1;

        return -1;
      }
    );
  }

  /**
   * @method Checks if maxAmount noch nicht erreicht wurde
   * @param pendingLength - aus OnFileChange (es gibt Promise für `Image`, aber Array hat noch kein Objekt)
   * @returns true - if Hochladen noch erlaubt
   * @emits reachedMax - if Hochladen nicht mehr erlaubt
   */
  private validAmount(pendingLength: number = 0) {
    const valid = (pendingLength + this.previewThumbnails.length) < this.maxAmount;
    if (!valid) {
      this.toastr.error(
        this.mrTranslate.transform("Sie können kein Foto mehr hochladen"),
        this.mrTranslate.transform("Maximale Anzahl") + ": " + this.maxAmount
      );
      this.reachedMax.emit();
    }
    return valid;
  }
  //#endregion


  dropOld(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      const imagen = { ...(event.container.data[event.currentIndex] as any) };
      const previousImagen = {
        ...(event.previousContainer.data[event.previousIndex] as any)
      };
      event.container.data.splice(event.currentIndex, 1, previousImagen);
      event.previousContainer.data.splice(event.previousIndex, 1, imagen);
    }
    this.sperrSort = true;
  }
}
