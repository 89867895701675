import { Injectable } from '@angular/core';
import config from '../../assets/msal.configuration.json';
import { EncryptionService } from './encryption.service';
import { IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';

@Injectable({ providedIn: 'root' })
export class MsalConfigService {
  private msalInstance: IPublicClientApplication | null = null;
  private clientId: string | null = null;
  private tenantId: string | null = null;

  constructor(private encryptAes: EncryptionService) {}

  async setConfig(tenantId: string, clientId: string): Promise<void> {
    const needsNewInstance =
      this.clientId !== clientId || this.tenantId !== tenantId;

    if (!needsNewInstance && this.msalInstance) {
      return; // reuse existing instance
    }

    this.clientId = clientId;
    this.tenantId = tenantId;

    this.msalInstance = new PublicClientApplication({
      auth: {
        clientId: this.clientId,
        authority: `https://login.microsoftonline.com/${this.tenantId}`,
        redirectUri: `${window.location.protocol}://${window.location.host}/login`
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (_, message) => console.log(message),
          logLevel: LogLevel.Info,
          piiLoggingEnabled: false,
        },
      },
    });

    await this.msalInstance.initialize();
  }

  async getInstance(): Promise<IPublicClientApplication> {
    if (!this.msalInstance) {
      await this.restoreFromLocalStorage(); // Try to auto-restore
      if (!this.msalInstance) {
        throw new Error('MSAL instance not initialized. Call setConfig() first or ensure msal config is stored in localStorage.');
      }
    }
    return this.msalInstance;
  }

  private async restoreFromLocalStorage(): Promise<void> {
    const encryptedMsal = localStorage.getItem("msal");
    if (!encryptedMsal) return;

    try {
      const decrypted = this.encryptAes.decryptionAES(encryptedMsal);
      const msal = JSON.parse(decrypted);

      const appid = msal.appid;
      const tenid = msal.tenid;

      if (!appid || !tenid) return;

      this.clientId = appid;
      this.tenantId = tenid;

      this.msalInstance = new PublicClientApplication({
        auth: {
          clientId: this.clientId,
          authority: `https://login.microsoftonline.com/${this.tenantId}`,
          redirectUri: `${window.location.protocol}//${window.location.host}/login`
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
        system: {
          loggerOptions: {
            loggerCallback: (_, message) => console.log(message),
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
          },
        },
      });

      await this.msalInstance.initialize(); // you could make this async too if needed
    } catch (error) {
      console.error("Failed to parse or decrypt msal localStorage data", error);
    }
  }

  async isInitialized(): Promise<boolean> {
    return !!(await this.getInstance());
  }

  getCurrentClientId(): string | null {
    return this.clientId;
  }

  getCurrentTenantId(): string | null {
    return this.tenantId;
  }
}
