import { Pipe, PipeTransform } from '@angular/core';

const HIDE_COL = [
  // ID
  'id', 'ostammid', 'bparentid', 'b_parentid', 'omazsid', 'omartid', 'ospezid', 'typ_id',
  'lpid', 'opruefid', 'sperrid', 'osperrid', 'spid', 'otypid', 'tid', 'sysiconid',
  // Bilder + icons
  'bild64', 'bild', 'helpbildbase64', 'messpunktskizze', 'typ_icon', 'kavcolor',
  // other
  'rights', 'rechte', 'h1', 'h5', 'h6', 'h9', 'h10', 'ident', 'osperrstatus', 'delstatus', 'typ_Line_Point',
  'hkav', 'anzrep', 'nomination_cts',
  // ? warum hier ?
  'nutzlaenge', 'laenge', 'startpunkt', 'endpunkt', 'minfk',
];
@Pipe({
  name: 'hideIDColumns',
  standalone: true

})
export class HideIDColumnsPipe implements PipeTransform {

  transform(value: any, hideCol?: any): any {
    // Wenn zusätzliche Logik vor hideIDColumnsPipe verwendet wurde
    if(!value) return false;
    if (typeof value == 'boolean') return value;

    hideCol = Array.isArray(hideCol)
      ? hideCol.concat(HIDE_COL)
      : HIDE_COL;
    if (hideCol.indexOf(value.toString().toLowerCase()) != -1) {
      return true;
    } else {
      return false;
    }
  }

}
