import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'urlEncode',
  standalone: true
})
export class urlEnDecodePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && typeof value == "string") {
      if (args[0] === true) {
        return encodeURIComponent(value);
      } else {
        return decodeURIComponent(value);
      }
    }
    return value;
  }

}
