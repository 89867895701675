<clr-modal [(clrModalOpen)]="editDataGridVisible" [clrModalClosable]="true">
  <h3 class="modal-title">Datentabelle bearbeiten</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical">
      <div class="clr-row">
        <div class="clr-row" style="margin-bottom: 12px;">
          <div style="border-style: solid; height: 200px;">
            <img [src]="zaehlerbild" alt="" style="max-height: 196px" class="uploadImagePreview" />
          </div>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-col-12">
          <label>{{ 'SR100- ' | mrTranslate }} {{SRValues.SR100M}}</label>
          <label style="position: absolute; text-align: center; left:38%; width:100px; font-size: 18px;">{{bezeichnung}}</label>
          <label style="position: absolute; right:20px;">{{ 'SR100+ ' | mrTranslate }} {{SRValues.SR100P}}</label>
          <br><br>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-col-12">
            <label>{{ 'SRLim- ' | mrTranslate }} {{SRValues.SRLimM}}</label>
            <input type="number" value = {{ist}} class="clr-input" style="position: absolute; text-align: center; left:40%; width:100px" (change)="changeIst($event)" />
            <label style="position: absolute; right:20px;" for="objektName">{{ 'SRLim+ ' | mrTranslate }} {{SRValues.SRLimP}}</label>
            <br><br>
        </div>
      </div>
      <div class="clr-row">
        <div class="clr-col-12">
          <label>{{ 'SRG- ' | mrTranslate }} {{SRValues.SRGM}}</label>
          <label name="tolverlStr" class="clr-input" style="position: absolute; text-align: center; left:40%; width:100px">{{tolverlStr}}</label>
          <label style="position: absolute; right:20px;">{{ 'SRG+ ' | mrTranslate }} {{SRValues.SRGP}}</label>
        </div>
      </div>
    </form>
    <br>
    <div class="clr-row">
      <button type="button" style="position: absolute; right:35%;" class="btn btn-primary" (click)="changeRow(1)"><clr-icon shape="arrow"></clr-icon></button>
      <button type="button" style="position: absolute; right:50%;" class="btn btn-primary" (click)="changeRow(-1)"><clr-icon shape="arrow down"></clr-icon></button>
    </div>
    <br>
  </div>
  <div class="modal-footer">
    <div class="clr-row">
      <button type="button" class="btn btn-primary" (click)="changeRow(0)">{{'OK' | mrTranslate}}</button>
      <button type="button" class="btn" (click)="editDataGridVisible = false; reloadOnlyMessTable();">{{'Abbrechen' | mrTranslate}}</button>
    </div>
   
  </div>
</clr-modal>


<div class="overlay-inner-wrapper">
  <div id="stellkraftmessungWrapper">
    <header class="header-2">
      <div class="branding">
        <span class="title">{{'Stellkraftmessung' | mrTranslate}}</span>
      </div>
      <div class="header-actions">
        <!-- <div class="nav-link nav-icon">
          <clr-icon shape="edit" (click)="editDataGrid()"></clr-icon>
        </div> -->
        <a href="javascript://" class="nav-link nav-icon">
          <clr-icon shape="times" (click)="close()"></clr-icon>
        </a>
      </div>
    </header>


    <ng-container *ngIf="!isLoading; else spinnerTemplate">
      <div class="clr-row" style="height: calc((100vh - 60px - 56px - 30px) / 4.13)">
        <div class="clr-col-12" style="max-height: 100%;">
          <clr-datagrid *ngIf="messTable?.columns.length > 0" class="datagrid-compact" style="height: 100%;">
            <clr-dg-column *ngFor="let column of messTable['columns']" [clrDgField]="column.id"
              [hidden]="column.id | hideIDColumns:['bezeichnung']">
              <ng-container *clrDgHideableColumn="{hidden: column.id | lowercase | hideIDColumns}">
                {{ (column.id.includes("SR") || column.id.includes("SRG")) ? (column.id | mrTranslate) : (column.id | titlecase | mrTranslate) }}
              </ng-container>
            </clr-dg-column>

            <clr-dg-row *clrDgItems="let row of messTable['rows']">
              <clr-dg-action-overflow>
                <button class="action-item" (click)="editDataGrid(row)">{{'Bearbeiten' | mrTranslate}}</button>
              </clr-dg-action-overflow>
              <clr-dg-cell class="name-cell" *ngFor="let cell of messTable['columns']"
                [hidden]="cell.id | hideIDColumns:['bezeichnung']" [innerHTML]="row[cell.id] | tablePrettyPrint"></clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-column-toggle></clr-dg-column-toggle>
              <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="30">
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>

      <div class="clr-row" style="height: calc((100vh - 60px - 56px - 30px) / 5)">
        <div class="clr-col-2" style="max-height: 100%;">
          <clr-datagrid *ngIf="stellDateTable?.rows.length > 0" class="datagrid-compact  dg-overflow-fix" style="height: 100%;" [(clrDgSelected)]="selectedStellDates">
            <clr-dg-column *ngFor="let column of stellDateTable['columns']" [clrDgField]="column.id"></clr-dg-column>
          
            <clr-dg-row *clrDgItems="let row of stellDateTable['rows']" [clrDgItem]="row">
              <clr-dg-cell class="name-cell" *ngFor="let cell of stellDateTable['columns']" [hidden]="cell.id | hideIDColumns" [innerHTML]="row[cell.id] | tablePrettyPrint"></clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              {{stellDateTable['rows'].length}} {{'Zeilen' | mrTranslate}}
            </clr-dg-footer>
          </clr-datagrid>
        </div>
        <div class="clr-col-10" style="max-height: 100%;">
          <clr-datagrid *ngIf="stellTable.show && stellTable?.columns.length > 0" class="datagrid-compact"
            style="height: 100%;">
            <clr-dg-column *ngFor="let column of stellTable['columns']" [clrDgField]="column.id"
              [hidden]="column.id | hideIDColumns">
              <ng-container *clrDgHideableColumn="{hidden: column.id | lowercase | hideIDColumns}">
                {{ column.id | titlecase | mrTranslate }}
              </ng-container>
            </clr-dg-column>

            <clr-dg-row *clrDgItems="let row of stellTable['rows']">
              <clr-dg-cell class="name-cell" *ngFor="let cell of stellTable['columns']"
                [hidden]="cell.id | hideIDColumns" [innerHTML]="row[cell.id] | tablePrettyPrint"></clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>
              {{stellTable['rows'].length}} {{'Zeilen' | mrTranslate}}
            </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>

      <div class="clr-row plotlyrow">
        <div class="clr-col-12" style="max-height: 100%;">

          <div class="card">
            <h4 class="mt-0 card-header">{{"Fahrtrichtung nach links Umstellen" | mrTranslate}}</h4>
            <div class="card-block" style="height: 100%;">
              <app-plotly-plot *ngIf="graph.show" [className]="" [data]="graph.dataLeft" [layout]="graph.layout"
                [config]="graph.config" [useResizeHandler]="true" [style]="graph.style">
              </app-plotly-plot>
            </div>
          </div>
        </div>
      </div>

      <div class="clr-row plotlyrow" style="margin-top: 1em;">
        <div class="clr-col-12" style="max-height: 100%;">
          <div class="card">
            <h4 class="mt-0 card-header">{{"Fahrtrichtung nach rechts Umstellen" | mrTranslate}}</h4>
            <div class="card-block" style="height: 100%;">
              <app-plotly-plot *ngIf="graph.show" [className]="" [data]="graph.dataRight" [layout]="graph.layout"
                [config]="graph.config" [useResizeHandler]="true" [style]="graph.style">
              </app-plotly-plot>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #spinnerTemplate>
      <div class="flexCenterWrapper">
        <clr-spinner>Lädt ...</clr-spinner>
      </div>
    </ng-template>



  </div>
</div>