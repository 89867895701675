import { Component, OnInit, ViewChild,ElementRef } from "@angular/core";
import { APIService } from "../../../../services/APIService/api.service";
import { first } from "rxjs/operators";
import {UntypedFormGroup,UntypedFormControl, ReactiveFormsModule} from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import * as _ from "underscore";
import { AuthService } from "src/app/services/Auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { ClrDatagrid } from "@clr/angular";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { BasedatamodalComponent } from "../../../_components/_modals/basedatamodal/basedatamodal.component";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { ZählerMode } from "../zaehlerdialog/zaehlerverwaltung/zaehler.enums";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { FormsModule } from "@angular/forms";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";

interface TreeDataNode {
  id: number,
  name: string,
  selected: boolean,
  expanded: boolean,
  parent: TreeDataNode | null,
  children: TreeDataNode[]
}



@Component({
    selector: "app-objekttypdialog",
    templateUrl: "./objekttypdialog.component.html",
    styleUrls: ["./objekttypdialog.component.scss"],
    imports: [CommonModule, ClarityModule, BasedatamodalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, TablePrettyPrintPipe, DotDotDotPipe, HideIDColumnsPipe],
    standalone: true
})
export class ObekttypDialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public minSize;

  trial = false;

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
    private overlayService: OverlayService
  ) {

    this.trial = this.authService.getToken().trial;
  }

  @ViewChild('_name') _name: ElementRef;
  @ViewChild('_bezeichnung') _bezeichnung: ElementRef;
  @ViewChild('_typ') _typ: ElementRef;
  @ViewChild('_icon') _icon: ElementRef;
  @ViewChild('_komptext') _komptext: ElementRef;

  @ViewChild('objekteDg', { static: false }) objekteDg: ClrDatagrid;
  @ViewChild('pruefungDg', { static: false }) pruefungDg: ClrDatagrid;
  @ViewChild(BasedatamodalComponent) modal: BasedatamodalComponent;
  openWerteliste(){
    let inData={
      titel: this.mrTranslate.transform('Mengeneinheit'),
      tabelle: "SMEH",
      OTYPID: this.selobjekt.id,
      tname: '',
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true){

      }
      this.modal.close();
    });

  }

  openWertelisteBauwerk(){
    let inData={
      titel: this.mrTranslate.transform('Bauwerke'),
      tabelle: "SBAUW",
      OTYPID: this.selobjekt.id,
      tname: '',
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true){

      }
      this.modal.close();
    });
  }


  treeclicktimer: any;


  root: TreeDataNode;

  iconColumns: string[] = ["typ", "symbol"];
  seltyp: number = 1;
  otypid: number = 1;
  kprtypid: number = 1;
  isinit: boolean=false;
  selobjekt: any = undefined;
  selpruefung: any = undefined;
  objekte: any;
  pruefungen: any;
  pruefungenOriginal: any;
  properties: any;
  sysobjektoptions: any;
  sysobjektoptionsOriginal: any;
  UseKomponenten: boolean=false;
  komponenten: any;

  cursysobjektoptions: any;
  lastsysooptGroup: any;

  editObjektModal: boolean = false;
  modalObjekttitel: string='';

  editKomponentenModal: boolean = false;
  modalKomponententtitel: string='';

  selsymbol: any = undefined;
  selected: any;
  selsotypid: number;
  selTreeNote: any;
  selnodeid: number = 0;
  editnodeid: number = 0;

  form = new UntypedFormGroup({
    clientId: new UntypedFormControl()
  });

  items = ["Closeout", "Construction", "Engineering", "Site Acquisition"];

  symbols: any =  [
    { id: 1, name: "Weiche" },
    { id: 2, name: "Gleis" },
    { id: 3, name: "Gleisabschluss" },
    { id: 4, name: "Bahnübergang" },
    { id: 5, name: "Mast" },
    { id: 6, name: "Signal" },
    { id: 7, name: "Oberleitung" },
    { id: 8, name: "Brandschutz" },
    { id: 9, name: "Brücke" },
    { id: 10, name: "Tunnel" },
    { id: 11, name: "Videoanlagen" },
    { id: 12, name: "Weichensteuerung" },
    { id: 13, name: "Haltestelle" },
    { id: 14, name: "Unterwerk" },
    { id: 15, name: "Stellwerk" },
    { id: 16, name: "Weichenantrieb" },
    { id: 17, name: "Weichenheizung" },
    { id: 18, name: "Solaranlagen" },
    { id: 19, name: "Beleuchtungsanlagen" },
    { id: 20, name: "Fahrgastinformationssystem" },
    { id: 21, name: "Fangschienen" },
    { id: 22, name: "Bü-Sicherungsanlagen" },
    { id: 23, name: "Gleissperre elektrisch" },
    { id: 24, name: "Gleissperre mechanisch" },
    { id: 25, name: "Schienenauszugsvorrichtung" },
    { id: 26, name: "Radsensor" },
    { id: 27, name: "Schaltschrank" },
    { id: 28, name: "Kabel" },
    { id: 29, name: "Lichtsignal" },
    { id: 30, name: "Schienenkontakt" },
    { id: 31, name: "Schienenkontakt isoliert" },
    { id: 32, name: "Schienenprofil" },
    { id: 33, name: "Stützmauer" },
    { id: 34, name: "Durchlass" },
    { id: 35, name: "Bahnsteig" },
    { id: 36, name: "Damm" },
    { id: 37, name: "Schmieranlage" },
    { id: 38, name: "Flurstück" },
    { id: 39, name: "Gebäude" },
    { id: 40, name: "Anschlussbahn" },
    { id: 41, name: "Anlage ortsfest" },
    { id: 42, name: "Anlage mobil" },
    { id: 43, name: "Geräte" },
    { id: 44, name: "Dienstweg" },
    { id: 45, name: "Stromschiene" },


    { "id": 99, "name": "undefiniert" },
];

  sysooptGroup: UntypedFormGroup = new UntypedFormGroup({
    Opt_F_Einbauhistory: new UntypedFormControl(false),
    Opt_F_Zaehler: new UntypedFormControl(false),
    Opt_F_Linienbelegung: new UntypedFormControl(false),
    Opt_F_Logbuch: new UntypedFormControl(false),
    Opt_F_Bahnsteig: new UntypedFormControl(false),
    Opt_F_BUESicht: new UntypedFormControl(false),
    Opt_F_Trassierung: new UntypedFormControl(false),
    Opt_O_UseRailProfil: new UntypedFormControl(false),
    Opt_O_UseDIN1076: new UntypedFormControl(false),
    Opt_O_UseMessReg: new UntypedFormControl(false),
    Opt_F_Drawing: new UntypedFormControl(false),
    Opt_O_UseKomponenten: new UntypedFormControl(false),
    Opt_I_Zaehler: new UntypedFormControl(false),
    Opt_I_Del_Old_Image: new UntypedFormControl(false),
    Opt_I_Fehlerklasse: new UntypedFormControl(false),
    Opt_I_Zustaend_Abt: new UntypedFormControl(false),
    Opt_I_Gundinst: new UntypedFormControl(false),
    Opt_I_PassBild: new UntypedFormControl(false),
    Opt_I_Track_BauwZustand: new UntypedFormControl(false),
    Opt_I_Track_Spez_SchwellenNote: new UntypedFormControl(false),
    Opt_I_Track_Spez_Schwellenjahr: new UntypedFormControl(false),
    Opt_I_Track_Spez_Schienenhoehe: new UntypedFormControl(false),
    Opt_I_Track_Spez_Option_ZusatzI: new UntypedFormControl(false),
    Opt_I_Track_Spez_Option_ZusatzII: new UntypedFormControl(false),
    Opt_I_Track_Spez_Option_Unterbau: new UntypedFormControl(false),
    Opt_I_Track_Spez_Option_Einbaujahr: new UntypedFormControl(false),
    Opt_I_Track_Spez_Option_Daemmung: new UntypedFormControl(false),
    Opt_E_OrdZustand: new UntypedFormControl(false),
    Opt_E_OrdZustand_TXT_IO: new UntypedFormControl(''),
    Opt_E_OrdZustand_TXT_NIO: new UntypedFormControl(''),
    Opt_E_FK_ImageToDok: new UntypedFormControl(false),
    Opt_E_Sperr_ImageToDok: new UntypedFormControl(false),
    Opt_E_Spez_ListToDok: new UntypedFormControl(false),
    Opt_I_Anlagenklasse: new UntypedFormControl(false),
    Opt_I_Track_Spez_Schienenjahr: new UntypedFormControl(false),
    Opt_E_Track_Spez_Schienenhoehe: new UntypedFormControl(false),
    Opt_E_Fehlerklasse: new UntypedFormControl(false),
    Opt_E_Bezirk: new UntypedFormControl(false),
    Opt_E_Stellwerk: new UntypedFormControl(false),
    Opt_E_MessReg: new UntypedFormControl(false),
    Opt_E_Track_Spez_Schwellenjahr: new UntypedFormControl(0),
    Opt_F_Stellkraftmessung: new UntypedFormControl(false),
    Opt_F_Naturschutz: new UntypedFormControl(false),
    Opt_I_PruefIntIsMessInt: new UntypedFormControl(false),
    Opt_I_CheckList: new UntypedFormControl(false),
    Opt_K_Kalender: new UntypedFormControl(30),
  });

  openZaehler()
  {
    this.overlayService.setOverlay({
      overlay: 'zaehlerverwaltung',
      isTopOverlay: true,
      mode: ZählerMode.ZAEHLER,
      otypid: this.otypid
    });
    // this.overlayService.setOverlay({
    //   overlay: "counterMenu",
    //   type: 0,
    //   zIndex: 7,
    // });
  }

  openStellkraft()
  {
    this.overlayService.setOverlay({
      overlay: "counterMenu",
      type: 1,
      zIndex: 9,
    });
  }

  selectionSymbolChanged(value: any) {}

  selectionObjektChanged(value: any) {
    if (!value) return;
    const filtered = this.pruefungenOriginal?.filter((obj) => {
      return obj.otypid === value.id;
    });

    this.otypid = value.id;
    this.seltyp = value.typ;
    if (this.pruefungen) {
      this.pruefungen.rows = filtered;
      setTimeout(() => {
        this.pruefungDg.singleSelected = this.pruefungen.rows[0];
      },100);
    }

  }

  addObjekt(){
    this.selsotypid = -1;
    this.selsymbol = this.symbols[0];
    this.editObjektModal = true;
    this.modalObjekttitel = this.mrTranslate.transform('Neues Objekt erstellen');
  }

  updateObjekt(){

    if (this._name.nativeElement.value.toString().length == 0 ){
      this.toastr.error(this.mrTranslate.transform("Geben Sie einen Namen ein"));
      return;
    }
    if (this._bezeichnung.nativeElement.value.toString().length == 0){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Bezeichnung ein"));
      return;
    }

    if (this.selsotypid == -1){
      if(this.objekte.rows.some((item) => item.name.toLowerCase() == this._name.nativeElement.value.toLowerCase())){
        this.toastr.error(this.mrTranslate.transform("Namen wird bereits verwendet"));
        return;
      }

      if(this.objekte.rows.some((item) => item.bezeichnung.toLowerCase() == this._bezeichnung.nativeElement.value.toLowerCase())){
        this.toastr.error(this.mrTranslate.transform("Bezeichnung wird bereits verwendet"));
        return;
      }
    }

    let n = this._name.nativeElement.value.toString();
    let b = this._bezeichnung.nativeElement.value.toString();
    let t = parseFloat(this._typ.nativeElement.value);
    let i = this.selsymbol.id;

    let sotyp = {
      Status: 'update',
      ID: this.selsotypid,
      Name: n,
      Bezeichnung: b,
      Typ: t,
      SysIcon: i,
    };

    this.apiservice
      .sendSOTYP({
        sotyp
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status){

          this.seltyp = 1;
          this.loadSOTYP();
          this.loadObjektPruefungTypen();
          this.loadSysObjectOptions();
          this.editObjektModal = false;

          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        else
        {
          this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
        }
      });
  }

  treeClick(node: any): void {
    this.treeclicktimer = setTimeout( () => {this.selectTreeNode(node);}, 300);
  }

  treeDoubleClick(node: any): void {
    clearTimeout(this.treeclicktimer);
    this.treeclicktimer = undefined;

    if (node.parent == null){
      return;
    }
    else{
      this.selnodeid = node.id;
    }
    this.selTreeNote = node;
    this.editKomponenten();
  }

  addKomponenten(){
    this._komptext.nativeElement.value='';
    this.editnodeid = -1;
    this.modalKomponententtitel = 'Neue Komponente erstellen'
    this.editKomponentenModal = true;
  }

  editKomponenten(){
    this._komptext.nativeElement.value = this.selTreeNote.name;
    this.editnodeid = this.selnodeid;
    this.modalKomponententtitel ='Komponente bearbeiten'
    this.editKomponentenModal = true;
  }

  delKomponenten(){

    let text="Sind Sie sicher, dass Sie dieses Komponente löschen wollen?";
    if (this.selTreeNote.children.length>0){
      text="Sind Sie sicher, dass Sie dieses Komponente mit alle Unterkomponente [" + this.selTreeNote.children.length .toString() + "] löschen wollen?";
    }

    if (confirm(text)) {
      let ids = [this.selnodeid];

      for (let i = 0; i < this.selTreeNote.children.length; i++) {
        let c = this.selTreeNote.children[i];
        ids.push(c.id);
      }

      let sotyp = {
        Status: 'deleteKomponent',
        ID: ids,
      }

      this.apiservice
      .sendSOTYP({
        sotyp
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status){

          this.selnodeid = 0;
          this.loadKomponenten()

          this.toastr.success(this.mrTranslate.transform("Komponenten wurden gelöscht"));
        }
        else
        {
          this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
        }
      });

    }
  }

  updateKomponenten(){
    if (this._komptext.nativeElement.value.toString().length == 0){
      this.toastr.error(this.mrTranslate.transform("Error: Geben Sie eine Bezeichnung ein"));
      return;
    }

    let b = this._komptext.nativeElement.value.toString();
    let p = this.otypid;

    if (this.selTreeNote.parent !== null)
      p = this.selTreeNote.parent.id;

    let sotyp = {
      Status: 'updateKomponent',
      ID: this.editnodeid,
      Name: "-",
      Bezeichnung: b,
      Typ: this.selobjekt.typ,
      SysIcon: this.selobjekt.symbol,
      ParentOTypID: this.selobjekt.id,
      ParentID: p,

    };

    this.apiservice
      .sendSOTYP({
        sotyp
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status){

          this.selnodeid = 0;
          this.loadKomponenten();
          this.editKomponentenModal = false;
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        else
        {
          this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
        }
      });

  }

  selectTreeNode(node: any){
    if (!this.treeclicktimer) return;
    if (node.parent == null){
      this.selnodeid = 0;
    }
    else{
      this.selnodeid = node.id;
    }
    this.selTreeNote = node;
  }

  editShowObjekt(row: any){

    this._name.nativeElement.value = row.name;
    this._bezeichnung.nativeElement.value = row.bezeichnung;
    this._typ.nativeElement.value = row.typ.toString();

    const filtered = this.symbols.filter((obj) => {
      return obj.id === row.symbol;
    });

    this.selsymbol = filtered[0];
    this.selsotypid = row.id;
    this.modalObjekttitel='Objekt bearbeiten'
    this.editObjektModal = true;
  }

  deleteObjek(id: number){
    if (confirm("Sind Sie sicher, dass Sie dieses Objekt löschen wollen?")) {
      let sotyp = {
        Status: 'delete',
        ID: id,
      };

      this.apiservice
        .sendSOTYP({
          sotyp
        })
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status){

            this.seltyp = 1;
            this.loadSOTYP();
            this.loadObjektPruefungTypen();
            this.loadSysObjectOptions();

            this.toastr.success(this.mrTranslate.transform("Objekt wurden gelöscht"));
          }
          else
          {
            this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
          }
        });
    }
  }

  changedKomponenten = (evt) => {
    if (evt.target.checked==true){
        this.loadKomponenten();
    }
    else{
      this.UseKomponenten = evt.target.checked;
    }
  }

  loadKomponenten(){
    this.apiservice
      .getKomponenten(this.otypid)
      .pipe(first())
      .subscribe((val: any) => {
        this.komponenten = val;

        setTimeout(() => {
          for (let i = 0; i < this.komponenten.rows.length; i++) {
            let komp = this.komponenten.rows[i];
            if (komp.ParentID == 0){

              this.root = {
                id: komp.ID,
                name: komp.Bezeichnung,
                selected: true,
                expanded: true,
                parent: null,
                children: []
              }
              this.fillChildren(this.root, komp.ID);
            }
          }
          this.UseKomponenten = true;

          this.selTreeNote = this.root;

        },200);

    });
  }

  fillChildren(parentNode:TreeDataNode, iid:number){
    const filtered = this.komponenten.rows.filter((obj) => {
      return obj.ParentID === iid;
    });

    for (let i = 0; i < filtered.length; i++) {
      let komp = filtered[i];
      let node: TreeDataNode = {
        id: komp.ID,
        name: komp.Bezeichnung,
        selected: false,
        expanded: false,
        parent: parentNode,
        children: []
      };
      parentNode.children.push(node);

      this.fillChildren(node,komp.ID);
    }

  }

  public getChildren = (node) => node.children;

  selectionPruefungChanged(value: any) {
    if (!value) return;
    const filtered = this.sysobjektoptionsOriginal.filter((obj) => {
      return obj.OTYPID === value.otypid && obj.KPRTYPID == value.id;
    });

    // if (this.lastsysooptGroup!== undefined)
      //this.saveSysObjectOptions();

    this.kprtypid = value.id;

    this.cursysobjektoptions = filtered[0];

    this.sysooptGroup.controls.Opt_F_Einbauhistory.setValue(this.cursysobjektoptions.Opt_F_Einbauhistory);
    this.sysooptGroup.controls.Opt_F_Zaehler.setValue(this.cursysobjektoptions.Opt_F_Zaehler);
    this.sysooptGroup.controls.Opt_F_Linienbelegung.setValue(this.cursysobjektoptions.Opt_F_Linienbelegung);
    this.sysooptGroup.controls.Opt_F_Logbuch.setValue(this.cursysobjektoptions.Opt_F_Logbuch);
    this.sysooptGroup.controls.Opt_F_Bahnsteig.setValue(this.cursysobjektoptions.Opt_F_Bahnsteig);
    this.sysooptGroup.controls.Opt_F_BUESicht.setValue(this.cursysobjektoptions.Opt_F_BUESicht);
    this.sysooptGroup.controls.Opt_F_Trassierung.setValue(this.cursysobjektoptions.Opt_F_Trassierung);
    this.sysooptGroup.controls.Opt_F_Stellkraftmessung.setValue(this.cursysobjektoptions.Opt_F_Stellkraftmessung);
    this.sysooptGroup.controls.Opt_F_Naturschutz.setValue(this.cursysobjektoptions.Opt_F_Naturschutz);
    this.sysooptGroup.controls.Opt_O_UseRailProfil.setValue(this.cursysobjektoptions.Opt_O_UseRailProfil);
    this.sysooptGroup.controls.Opt_O_UseDIN1076.setValue(this.cursysobjektoptions.Opt_O_UseDIN1076);
    this.sysooptGroup.controls.Opt_O_UseMessReg.setValue(this.cursysobjektoptions.Opt_O_UseMessReg);
    this.sysooptGroup.controls.Opt_F_Drawing.setValue(this.cursysobjektoptions.Opt_F_Drawing);
    this.sysooptGroup.controls.Opt_O_UseKomponenten.setValue(this.cursysobjektoptions.Opt_O_UseKomponenten);
    this.sysooptGroup.controls.Opt_I_Zaehler.setValue(this.cursysobjektoptions.Opt_I_Zaehler);
    this.sysooptGroup.controls.Opt_I_Del_Old_Image.setValue(this.cursysobjektoptions.Opt_I_Del_Old_Image);
    this.sysooptGroup.controls.Opt_I_Fehlerklasse.setValue(this.cursysobjektoptions.Opt_I_Fehlerklasse);
    this.sysooptGroup.controls.Opt_I_Zustaend_Abt.setValue(this.cursysobjektoptions.Opt_I_Zustaend_Abt);
    this.sysooptGroup.controls.Opt_I_Gundinst.setValue(this.cursysobjektoptions.Opt_I_Gundinst);
    this.sysooptGroup.controls.Opt_I_PassBild.setValue(this.cursysobjektoptions.Opt_I_PassBild);
    this.sysooptGroup.controls.Opt_I_Track_BauwZustand.setValue(this.cursysobjektoptions.Opt_I_Track_BauwZustand);
    this.sysooptGroup.controls.Opt_I_Track_Spez_SchwellenNote.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_SchwellenNote);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Schwellenjahr.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Schwellenjahr);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Schienenhoehe.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Schienenhoehe);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Option_ZusatzI.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Option_ZusatzI);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Option_ZusatzII.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Option_ZusatzII);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Option_Unterbau.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Option_Unterbau);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Option_Einbaujahr.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Option_Einbaujahr);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Option_Daemmung.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Option_Daemmung);
    this.sysooptGroup.controls.Opt_I_Anlagenklasse.setValue(this.cursysobjektoptions.Opt_I_Anlagenklasse);
    this.sysooptGroup.controls.Opt_I_Track_Spez_Schienenjahr.setValue(this.cursysobjektoptions.Opt_I_Track_Spez_Schienenjahr);
    this.sysooptGroup.controls.Opt_I_PruefIntIsMessInt.setValue(this.cursysobjektoptions.Opt_I_PruefIntIsMessInt);
    this.sysooptGroup.controls.Opt_I_CheckList.setValue(this.cursysobjektoptions.Opt_I_CheckList);
    this.sysooptGroup.controls.Opt_E_OrdZustand.setValue(this.cursysobjektoptions.Opt_E_OrdZustand);
    this.sysooptGroup.controls.Opt_E_OrdZustand_TXT_IO.setValue(this.cursysobjektoptions.Opt_E_OrdZustand_TXT_IO);
    this.sysooptGroup.controls.Opt_E_OrdZustand_TXT_NIO.setValue(this.cursysobjektoptions.Opt_E_OrdZustand_TXT_NIO);
    this.sysooptGroup.controls.Opt_E_FK_ImageToDok.setValue(this.cursysobjektoptions.Opt_E_FK_ImageToDok);
    this.sysooptGroup.controls.Opt_E_Sperr_ImageToDok.setValue(this.cursysobjektoptions.Opt_E_Sperr_ImageToDok);
    this.sysooptGroup.controls.Opt_E_Spez_ListToDok.setValue(this.cursysobjektoptions.Opt_E_Spez_ListToDok);
    this.sysooptGroup.controls.Opt_E_Track_Spez_Schienenhoehe.setValue(this.cursysobjektoptions.Opt_E_Track_Spez_Schienenhoehe);
    this.sysooptGroup.controls.Opt_E_Fehlerklasse.setValue(this.cursysobjektoptions.Opt_E_Fehlerklasse);
    this.sysooptGroup.controls.Opt_E_Bezirk.setValue(this.cursysobjektoptions.Opt_E_Bezirk);
    this.sysooptGroup.controls.Opt_E_Stellwerk.setValue(this.cursysobjektoptions.Opt_E_Stellwerk);
    this.sysooptGroup.controls.Opt_E_MessReg.setValue(this.cursysobjektoptions.Opt_E_MessReg);
    this.sysooptGroup.controls.Opt_E_Track_Spez_Schwellenjahr.setValue(this.cursysobjektoptions.Opt_E_Track_Spez_Schwellenjahr);
    this.sysooptGroup.controls.Opt_K_Kalender.setValue(parseFloat(this.cursysobjektoptions.Opt_K_Kalender));

    this.lastsysooptGroup = this.sysooptGroup.value;

    if (this.cursysobjektoptions.Opt_O_UseKomponenten == 1){

      setTimeout(() => {;
        this.loadKomponenten();
      },200);
    }
    else{
      this.UseKomponenten=false;
    }
  }


  public close() {
    this.closedialog.next(true);
  }

  ngOnInit() {
    this.seltyp = 1;
    this.loadSOTYP();
    this.loadObjektPruefungTypen();
    this.loadSysObjectOptions();
  }

  private loadSOTYP() {
    this.apiservice
      .getSOTYPAll()
      .pipe(first())
      .subscribe((val: any) => {
        this.objekte = val;
      });
  }

  private loadObjektPruefungTypen() {
    this.apiservice
      .getObjektPruefungTypen()
      .pipe(first())
      .subscribe((val: any) => {
        this.pruefungen = val;
        this.pruefungenOriginal = JSON.parse(JSON.stringify(val.rows));
        setTimeout(() => {
          this.objekteDg.singleSelected = this.objekte?.rows[0];
        });

      });
  }

  private loadSysObjectOptions() {
    this.apiservice
      .getSysObjectOptions()
      .pipe(first())
      .subscribe((val: any) => {
        this.sysobjektoptions = val;
        this.sysobjektoptionsOriginal = JSON.parse(JSON.stringify(val.rows));
      });
  }

  saveSysObjectOptions() {
    let vals = this.sysooptGroup.value;

    let sysoopt={
      Opt_F_Einbauhistory: vals.Opt_F_Einbauhistory,
      Opt_F_Zaehler: vals.Opt_F_Zaehler,
      Opt_F_Linienbelegung: vals.Opt_F_Linienbelegung,
      Opt_F_Logbuch: vals.Opt_F_Logbuch,
      Opt_F_Bahnsteig: vals.Opt_F_Bahnsteig,
      Opt_F_BUESicht: vals.Opt_F_BUESicht,
      Opt_F_Trassierung: vals.Opt_F_Trassierung,
      Opt_O_UseRailProfil: vals.Opt_O_UseRailProfil,
      Opt_O_UseDIN1076: vals.Opt_O_UseDIN1076,
      Opt_O_UseMessReg: vals.Opt_O_UseMessReg,
      Opt_F_Drawing: vals.Opt_F_Drawing,
      Opt_O_UseKomponenten: vals.Opt_O_UseKomponenten,
      Opt_I_Zaehler: vals.Opt_I_Zaehler,
      Opt_I_Del_Old_Image: vals.Opt_I_Del_Old_Image,
      Opt_I_Fehlerklasse: vals.Opt_I_Fehlerklasse,
      Opt_I_Zustaend_Abt: vals.Opt_I_Zustaend_Abt,
      Opt_I_Gundinst: vals.Opt_I_Gundinst,
      Opt_I_PassBild: vals.Opt_I_PassBild,
      Opt_I_Track_BauwZustand: vals.Opt_I_Track_BauwZustand,
      Opt_I_Track_Spez_SchwellenNote: vals.Opt_I_Track_Spez_SchwellenNote,
      Opt_I_Track_Spez_Schwellenjahr: vals.Opt_I_Track_Spez_Schwellenjahr,
      Opt_I_Track_Spez_Schienenhoehe: vals.Opt_I_Track_Spez_Schienenhoehe,
      Opt_I_Track_Spez_Option_ZusatzI: vals.Opt_I_Track_Spez_Option_ZusatzI,
      Opt_I_Track_Spez_Option_ZusatzII: vals.Opt_I_Track_Spez_Option_ZusatzII,
      Opt_I_Track_Spez_Option_Unterbau: vals.Opt_I_Track_Spez_Option_Unterbau,
      Opt_I_Track_Spez_Option_Einbaujahr: vals.Opt_I_Track_Spez_Option_Einbaujahr,
      Opt_I_Track_Spez_Option_Daemmung: vals.Opt_I_Track_Spez_Option_Daemmung,
      Opt_E_OrdZustand: vals.Opt_E_OrdZustand,
      Opt_E_OrdZustand_TXT_IO: vals.Opt_E_OrdZustand_TXT_IO,
      Opt_E_OrdZustand_TXT_NIO: vals.Opt_E_OrdZustand_TXT_NIO,
      Opt_E_FK_ImageToDok: vals.Opt_E_FK_ImageToDok,
      Opt_E_Sperr_ImageToDok: vals.Opt_E_Sperr_ImageToDok,
      Opt_E_Spez_ListToDok: vals.Opt_E_Spez_ListToDok,
      Opt_I_Anlagenklasse: vals.Opt_I_Anlagenklasse,
      Opt_I_Track_Spez_Schienenjahr: vals.Opt_I_Track_Spez_Schienenjahr,
      Opt_E_Track_Spez_Schienenhoehe: vals.Opt_E_Track_Spez_Schienenhoehe,
      Opt_E_Fehlerklasse: vals.Opt_E_Fehlerklasse,
      Opt_E_Bezirk: vals.Opt_E_Bezirk,
      Opt_E_Stellwerk: vals.Opt_E_Stellwerk,
      Opt_E_MessReg: vals.Opt_E_MessReg,
      Opt_E_Track_Spez_Schwellenjahr: vals.Opt_E_Track_Spez_Schwellenjahr,
      Opt_F_Stellkraftmessung: vals.Opt_F_Stellkraftmessung,
      Opt_F_Naturschutz: vals.Opt_F_Naturschutz,
      Opt_I_PruefIntIsMessInt: vals.Opt_I_PruefIntIsMessInt,
      Opt_I_CheckList: vals.Opt_I_CheckList,
      Opt_K_Kalender: vals.Opt_K_Kalender,
      otypid: this.otypid,
      kprtypid: this.kprtypid,
    }

    this.apiservice
      .sendSysObjectOptions({sysoopt})
      .pipe(first())
      .subscribe((val: any) => {
        if (val){
          this.sysobjektoptions = val;
          this.sysobjektoptionsOriginal = JSON.parse(JSON.stringify(val.rows));
        }

      });
  }

  showMangelbaum(){
    this.otypid = this.selobjekt.id;
    this.overlayService.setOverlay({
      overlay: "openmangelbaum",
      isTopOverlay: true,
      id: {
        otypid: this.otypid,
        objecte: this.objekte,
      },
      zIndex: 10,
    });
  }

  showChecklisten(){
    this.otypid = this.selobjekt.id;
    this.overlayService.setOverlay({
      overlay: "checklistenMenu",
      isTopOverlay: true,
      id: {
        otypid: this.otypid,
      },
      zIndex: 10,
    });

  }

  showSpezifikationTemplate(){
    this.otypid = this.selobjekt.id;
    this.overlayService.setOverlay({
      overlay: "spezifikationtemplate",
      isTopOverlay: true,
      id: {
        otypid: this.otypid,
      },
      zIndex: 10,
    });

  }



  /*      id: {
        ostammid: this.objTable.rows[index]["OSTAMMID"],
        otypid: this.objTable.rows[index]["otypid"],
      },
      recallData: {
        formValue: this.abnahmeProtokollFormGroup.getRawValue(),
        selectedObjekt: this.selectedObjekt,
        previewThumbnails: this.previewThumbnails,
      },
  */


}
