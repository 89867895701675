import { CommonModule } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClarityModule } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { APIService } from "../../../../services/APIService/api.service";
import { BasedatamodalComponent } from "../../../_components/_modals/basedatamodal/basedatamodal.component";

interface TreeDataNode {
  id: number,
  typ: number,
  name: string,
  selected: boolean,
  expanded: boolean,
  parent: TreeDataNode | null,
  children: TreeDataNode[]
}


@Component({
  selector: "app-objektspezifikationendialog",
  templateUrl: "./objektspezifikationendialog.component.html",
  styleUrls: ["./objektspezifikationendialog.component.scss"],
  imports: [CommonModule, ClarityModule, BasedatamodalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, TablePrettyPrintPipe, DotDotDotPipe],
  standalone: true
})
export class ObjektSpezifikationenDialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public minSize;

  trial = false;

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe
  ) {

    this.trial = this.authService.getToken().trial;
    this.loadKomponenten()
    this.loadSpezifikation();
  }

  @ViewChild('feldname') feldname: ElementRef;
  @ViewChild('pflicht') pflicht: ElementRef;
  @ViewChild('usewert') usewert: ElementRef;
  @ViewChild('feldtyp') feldtyp: ElementRef;
  @ViewChild('exwerte') exwerte: ElementRef;
  @ViewChild('export') export: ElementRef;

  @ViewChild(BasedatamodalComponent) modal: BasedatamodalComponent;
  openWerteliste() {
    let inData = {
      titel: this.selTreeNote.name + ': ' + this.detailvalue.KFELDNA,
      tabelle: this.detailvalue.TABWERT,
      OTYPID: this.selnodeid,
      tname: this.detailvalue.SFELDNA,
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true) {

      }
      this.modal.close();
    });

  }

  treeclicktimer: any;


  root: TreeDataNode;
  selid: number = 0;
  iconColumns: string[] = ["typ", "symbol"];
  seltyp: number = 1;
  otypid: number = 1;
  kprtypid: number = 1;
  isinit: boolean = false;
  selobjekt: any = undefined;
  selpruefung: any = undefined;
  objekte: any;
  pruefungen: any;
  pruefungenOriginal: any;
  properties: any;
  sysobjektoptions: any;
  sysobjektoptionsOriginal: any;
  UseKomponenten: boolean = false;
  komponenten: any;
  spezifikation: any;
  spezifikationall: any;

  firstid: number = 1;

  cursysobjektoptions: any;
  lastsysooptGroup: any;

  editObjektModal: boolean = false;
  modalObjekttitel: string = '';

  editKomponentenModal: boolean = false;
  modalKomponententtitel: string = '';

  selsymbol: any = undefined;
  selected: any;
  selsotypid: number;
  listTreeNote: TreeDataNode[] = [];
  selTreeNote: any;
  selnodeid: number = 0;
  titel: string;
  isnew: boolean = false;
  selectedfeldtyp: string = "";
  isnewvisible: boolean = false;
  modaltitel: string = "";
  usewerteliste: boolean = false;
  wertelisteauswahl: any[] = []
  selection: any;
  selectFeldtyp: any;
  canUseWerteliste: boolean = true;
  canUsePflichtfeld: boolean = true;
  canUseExport:boolean = true;
  detailvalue; any;
  cansavedetail: boolean = true;
  canchangewerteliste: boolean = true;
  candelete: boolean = true;
  canadditem: boolean = true;
  cansort: boolean = true;
  cansortuptabelle: boolean = false;
  cansortdowntabelle: boolean = false;
  showwertelisteSelect: boolean = true;
  saveItems(id: number) {

    let p = this.pflicht.nativeElement.checked;
    let w = this.usewert.nativeElement.checked;
    let t = this.feldtyp.nativeElement.value;
    let l = this.selection;
    let n = this.feldname.nativeElement.value;
    let e = this.export.nativeElement.checked;

    if (w == false)
      l = null;

    if (id > 0) {

      let kspezv = {
        Status: 'update',
        ID: id,
        TABWERT: l,
        KFELDNA: n,
        Typ: t,
        PFLICHT: p,
        USEWERT: w,
        Export: e
      };

      this.apiservice
        .sendKSPEZV({
          kspezv
        })
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.spezifikation.rows = [];
            this.spezifikationall = res.rows;

            setTimeout(() => {
              this.setcurrentSpez(this.selnodeid);
            }, 50);

            this.selid = 0;
            this.isnewvisible = false
            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          }
          else {
            this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
          }
        });

    }
    else {
      this.toastr.error(this.mrTranslate.transform("Die maximale Anzahl an Spalten ist erreicht"));
    }
  }

  addItems() {

    let rows = this.spezifikationall.filter((obj) => {
      return obj.OTYPID == this.selTreeNote.id;
    });

    if (rows.length == 0) {
      let setnewitems = {
        id: this.otypid
      }

      this.apiservice
        .sendKSPEZV({
          setnewitems
        })
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.spezifikation.rows = [];
            this.spezifikationall = res.rows;

            setTimeout(() => {
              this.setcurrentSpez(this.selnodeid);
              this.resort();
            }, 50);

          }
          else {
            this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
          }
        });

      return;
    }

    let p = this.pflicht.nativeElement.checked;
    let w = this.usewert.nativeElement.checked;
    let t = this.feldtyp.nativeElement.value;
    let l = this.selection;
    let n = this.feldname.nativeElement.value;
    let id = this.getnextfreeid(t);

    if (w == false)
      l = null;

    let s = this.spezifikation.rows.length + 2;

    if (id > 0) {

      let kspezv = {
        Status: 'update',
        ID: id,
        TABWERT: l,
        KFELDNA: n,
        Typ: t,
        PFLICHT: p,
        USEWERT: w,
        KSORT: 1000000 + s
      };

      this.apiservice
        .sendKSPEZV({
          kspezv
        })
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.spezifikation.rows = [];
            this.spezifikationall = res.rows;

            setTimeout(() => {
              this.setcurrentSpez(this.selnodeid);
              this.resort();
            }, 50);

            this.isnewvisible = false
            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          }
          else {
            this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
          }
        });

    }
    else {
      this.toastr.error(this.mrTranslate.transform("Die maximale Anzahl an Spalten ist erreicht"));
    }

  }

  getnextfreeid(typ) {
    let searchStr = "str";

    if (typ == "Ganzzahl") searchStr = 'int';

    if (typ == "Kommazahl") searchStr = 'dbl';

    if (typ == "Ja/Nein") searchStr = 'chk';

    if (typ == "Datum") searchStr = 'date';

    let rows = this.spezifikationall.filter((obj) => {
      return obj.OTYPID == this.selTreeNote.id && obj.KFELDNA == null && obj.SFELDNA.includes(searchStr);
    });

    if (rows.length > 0) {
      rows.sort((a, b) => {
        var typeA = a.SFELDNA.toLowerCase(),
          typeB = b.SFELDNA.toLowerCase();
        if (typeA < typeB)
          return -1;
        if (typeA > typeB) return 1;
        return 0;
      });

      return rows[0].ID;
    }
    else {
      return -1
    }

  }
  onDetailOpen($event) {
    console.log($event);
    if ($event != undefined) {
      this.canUseWerteliste = true;
      this.canUsePflichtfeld = true;
      this.usewerteliste = false;
      this.cansavedetail = true;
      this.detailvalue = $event;
      this.canchangewerteliste = true;
      this.canadditem = true;
      this.showwertelisteSelect = true;
      this.canUseExport = true;

      if (!this.detailvalue.SFELDNA.includes('str')) {
        this.usewert.nativeElement.checked = false;
        this.canUseWerteliste = false;
        this.usewerteliste = false;
      }

      if (this.detailvalue.SFELDNA.includes('chk')) {
        this.pflicht.nativeElement.checked = false;
        this.canUsePflichtfeld = false;
      }

      this.usewerteliste = this.detailvalue.USEWERT;
      this.selection = this.getcurrentWerteliste(this.selnodeid, this.detailvalue.TABWERT);
      this.selid = this.detailvalue.ID;
      this.export = this.detailvalue.Export;

      if (this.detailvalue.TABWERT == 'SBAGP')
        this.showwertelisteSelect = false;


      if (this.otypid == 1) {
        if (this.detailvalue.SFELDNA == 'strValue02' || this.detailvalue.SFELDNA == 'strValue03' || this.detailvalue.SFELDNA == 'strValue06' || this.detailvalue.SFELDNA == 'strValue07' || this.detailvalue.SFELDNA == 'strValue08' || this.detailvalue.SFELDNA == 'strValue09' || this.detailvalue.SFELDNA == 'strValue10' || this.detailvalue.SFELDNA == 'strValue11' || this.detailvalue.SFELDNA == 'strValue12' || this.detailvalue.SFELDNA == 'strValue13' || this.detailvalue.SFELDNA == 'strValue14' || this.detailvalue.SFELDNA == 'strValue15' || this.detailvalue.SFELDNA == 'strValue16' || this.detailvalue.SFELDNA == 'strValue17' || this.detailvalue.SFELDNA == 'strValue18' || this.detailvalue.SFELDNA == 'strValue19' || this.detailvalue.SFELDNA == 'strValue20') {

          if (this.detailvalue.SFELDNA == 'strValue02' || this.detailvalue.SFELDNA == 'strValue08' || this.detailvalue.SFELDNA == 'strValue09' || this.detailvalue.SFELDNA == 'strValue20') {
            this.canUsePflichtfeld = false;
          }
          else
            this.canUsePflichtfeld = true;


          if (this.detailvalue.SFELDNA == 'strValue02' || this.detailvalue.SFELDNA == 'strValue03'  || this.detailvalue.SFELDNA == 'strValue14' || this.detailvalue.SFELDNA == 'strValue08' || this.detailvalue.SFELDNA == 'strValue09' || this.detailvalue.SFELDNA == 'strValue10'){
            this.canUseExport = false;
          }
          else
            this.canUseExport = true;

          this.canUseWerteliste = false;
          this.canchangewerteliste = false;
          this.selid = 0;
        }

      }

      if (this.otypid == 2) {
        if (this.detailvalue.SFELDNA == 'strValue01' || this.detailvalue.SFELDNA == 'strValue02' || this.detailvalue.SFELDNA == 'strValue03' || this.detailvalue.SFELDNA == 'strValue04' || this.detailvalue.SFELDNA == 'strValue05' || this.detailvalue.SFELDNA == 'strValue06' || this.detailvalue.SFELDNA == 'strValue07' || this.detailvalue.SFELDNA == 'strValue08' || this.detailvalue.SFELDNA == 'strValue21' || this.detailvalue.SFELDNA == 'strValue22' || this.detailvalue.SFELDNA == 'strValue23' || this.detailvalue.SFELDNA == 'strValue24' || this.detailvalue.SFELDNA == 'strValue25' || this.detailvalue.SFELDNA == 'strValue26' || this.detailvalue.SFELDNA == 'strValue27' || this.detailvalue.SFELDNA == 'intValue01' || this.detailvalue.SFELDNA == 'intValue02') {
          if (this.detailvalue.SFELDNA == 'strValue01' || this.detailvalue.SFELDNA == 'strValue02' || this.detailvalue.SFELDNA == 'strValue03' || this.detailvalue.SFELDNA == 'strValue04' || this.detailvalue.SFELDNA == 'strValue05') {
            this.canUsePflichtfeld = false;
          }
          else
            this.canUsePflichtfeld = true;


          this.canUsePflichtfeld = false;
          this.canUseWerteliste = false;
          this.canchangewerteliste = false;
          this.cansavedetail = false;

        }
        this.canadditem = false;
        this.selid = 0;
      }


    }
  }

  getcurrentWerteliste(otypid, tabwert) {
    const filtered = this.wertelisteauswahl.filter((obj) => {
      return obj.otypid === otypid && obj.TABWERT === tabwert;
    });

    if (filtered.length > 0)
      return filtered[0].bezeichnung;
    else
      return '';
  }



  selectionObjektChanged(value: any) {
    this.cansortuptabelle = false;
    this.cansortdowntabelle = false;

    if (!value) return;
    var index = this.spezifikation.rows.indexOf(value);
    if (index > 0)
      this.cansortuptabelle = true;

    if (index < this.spezifikation.rows.length - 1)
      this.cansortdowntabelle = true;

    this.selid = value.ID;

    if (this.otypid == 1) {
      if (value.SFELDNA == 'strValue02' || value.SFELDNA == 'strValue03' || value.SFELDNA == 'strValue06' || value.SFELDNA == 'strValue07' || value.SFELDNA == 'strValue08' || value.SFELDNA == 'strValue09' || value.SFELDNA == 'strValue10' || value.SFELDNA == 'strValue11' || value.SFELDNA == 'strValue12' || value.SFELDNA == 'strValue13' || value.SFELDNA == 'strValue14' || value.SFELDNA == 'strValue15' || value.SFELDNA == 'strValue16' || value.SFELDNA == 'strValue17' || value.SFELDNA == 'strValue18' || value.SFELDNA == 'strValue19' || value.SFELDNA == 'strValue20') {
        this.selid = 0;
      }

    }

    if (this.otypid == 2) {
      this.canadditem = false;
      this.selid = 0;
    }



  }
  resort() {
    for (let i = 0; i < this.spezifikation.rows.length; i++) {
      this.spezifikation.rows[i].KSORT = 1000000 + (i + 1);

      let doresort = {
        id: this.spezifikation.rows[i].ID,
        sort: this.spezifikation.rows[i].KSORT,
      }

      this.apiservice
        .sendKSPEZV({ doresort })
        .pipe(first())
        .subscribe((val) => {
          if (val) {
            //this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          }
        })
    }
  }


  sortUptable() {
    var index = this.spezifikation.rows.indexOf(this.selobjekt);
    this.spezifikation.rows = this.swapArrayTabellen(this.spezifikation.rows, index, index - 1);
    this.selectionObjektChanged(this.selobjekt);
  }

  sortDowntable() {
    var index = this.spezifikation.rows.indexOf(this.selobjekt);
    this.spezifikation.rows = this.swapArrayTabellen(this.spezifikation.rows, index, index + 1);
    this.selectionObjektChanged(this.selobjekt);
  }

  swapArrayTabellen(Array: any, Swap1: number, Swap2: number): any {
    let sort1 = Array[Swap1].KSORT;
    let sort2 = Array[Swap2].KSORT;
    let id1 = Array[Swap1].ID;
    let id2 = Array[Swap2].ID;

    Array[Swap2].KSORT = sort1;
    Array[Swap1].KSORT = sort2;

    var temp = Array[Swap1];
    Array[Swap1] = Array[Swap2]
    Array[Swap2] = temp


    let resort = {
      id1: id1,
      id2: id2,
      sort1: sort2,
      sort2: sort1,
    }

    this.apiservice
      .sendKSPEZV({ resort })
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      })

    return Array;
  }



  treeClick(node: any): void {
    this.treeclicktimer = setTimeout(() => { this.selectTreeNode(node); }, 50);
  }
  selectChanged(value) {
    this.selectFeldtyp = value;
    this.canUseWerteliste = true;
    this.canUsePflichtfeld = true;

    if (this.selectFeldtyp != "Text") {
      this.usewert.nativeElement.checked = false;
      this.canUseWerteliste = false;
      this.usewerteliste = false;
    }

    if (this.selectFeldtyp == "Ja/Nein") {
      this.pflicht.nativeElement.checked = false;
      this.canUsePflichtfeld = false;
    }

  }

  newitem() {
    this.canUseWerteliste = true;
    this.canUsePflichtfeld = true;
    this.usewerteliste = false;
    this.modaltitel = this.mrTranslate.transform("neues Feld anlegen") + ": " + this.selTreeNote.name;
    this.feldname.nativeElement.value = '';
    this.pflicht.nativeElement.checked = false;
    this.usewert.nativeElement.checked = false;
    // this.feldtyp.nativeElement.value = this.mrTranslate.transform("Text");
    this.selectedfeldtyp = "Text";
    this.selection = '**' + this.mrTranslate.transform("Neu") + '**';
    this.isnewvisible = true;
  }

  selectTreeNode(node: any) {
    if (!this.treeclicktimer) return;
    this.candelete = true;
    this.selnodeid = node.id;
    this.otypid = node.id;
    this.selTreeNote = node;
    this.titel = this.mrTranslate.transform("Spezifikation") + ": " + node.name;

    this.modaltitel = this.selTreeNote.name;

    this.spezifikation.rows = [];
    this.setcurrentSpez(this.selnodeid);
    this.cansort = true;
    this.canadditem = true;

    if (this.otypid == 2) {
      this.canadditem = false;
      this.cansort = false;
    }

  }

  loadSpezifikation() {
    this.apiservice
      .getKSpezvAll()
      .pipe(first())
      .subscribe((val: any) => {
        this.spezifikation = val;
        this.spezifikationall = val.rows;
      });
  }


  setcurrentSpez(id: number) {
    this.spezifikation.rows = this.spezifikationall.filter((obj) => {
      return obj.OTYPID === id && obj.KFELDNA != null;
    });

  }


  loadKomponenten() {
    this.apiservice
      .getKomponenten(-1)
      .pipe(first())
      .subscribe((val: any) => {
        this.komponenten = val;

        setTimeout(() => {
          for (let i = 0; i < this.komponenten.rows.length; i++) {
            let komp = this.komponenten.rows[i];
            if (komp.ParentID == 0) {

              this.root = {
                id: komp.ID,
                typ: komp.SysIcon,
                name: komp.Bezeichnung,
                selected: true,
                expanded: true,
                parent: null,
                children: []
              }
              this.fillChildren(this.root, komp.ID);

              this.listTreeNote.push(this.root);
            }
          }


          let node = this.listTreeNote[0];
          this.selnodeid = node.id;
          this.selTreeNote = node;
          this.titel = this.mrTranslate.transform("Spezifikation") + ": " + node.name;

          this.spezifikation.rows = [];
          this.setcurrentSpez(this.selnodeid);

          this.wertelisteauswahl = [];
          let item = {
            otypid: -1,
            typid: -1,
            SFELDNA: '',
            TABWERT: '',
            bezeichnung: '**' + this.mrTranslate.transform("Neu") + '**',
          }
          this.wertelisteauswahl.push(item);

          let bez = [];

          for (let i = 0; i < this.listTreeNote.length; i++) {
            let node = this.listTreeNote[i];
            let spzrows = this.spezifikationall.filter((obj) => {
              return obj.OTYPID === node.id && obj.TABWERT != null;
            });

            for (let j = 0; j < spzrows.length; j++) {

              if (spzrows[j]["TABWERT"] == 'SBAGP') continue;
              if (spzrows[j]["TABWERT"] == 'SBFEK') continue;

              if (!bez.includes(spzrows[j]["KFELDNA"])) {
                bez.push(spzrows[j]["KFELDNA"]);

                let item = {
                  otypid: node.id,
                  typid: node.typ,
                  SFELDNA: spzrows[j]["SFELDNA"],
                  TABWERT: spzrows[j]["TABWERT"],
                  bezeichnung: spzrows[j]["KFELDNA"],
                }
                this.wertelisteauswahl.push(item);

              }
            }

          }

          this.wertelisteauswahl.sort((a, b) => {
            var typeA = a.bezeichnung.toLowerCase(),
              typeB = b.bezeichnung.toLowerCase();
            if (typeA < typeB)
              return -1;
            if (typeA > typeB) return 1;
            return 0;
          });

          this.UseKomponenten = true;

        }, 150);

      });
  }

  fillChildren(parentNode: TreeDataNode, iid: number) {
    const filtered = this.komponenten.rows.filter((obj) => {
      return obj.ParentID === iid;
    });

    for (let i = 0; i < filtered.length; i++) {
      let komp = filtered[i];
      let node: TreeDataNode = {
        id: komp.ID,
        typ: komp.SysIcon,
        name: komp.Bezeichnung,
        selected: false,
        expanded: false,
        parent: parentNode,
        children: []
      };
      parentNode.children.push(node);

      this.fillChildren(node, komp.ID);
    }

  }

  public getChildren = (node) => node.children;

  fieldsChange(values: any): void {
    this.usewerteliste = values.currentTarget.checked;
    console.log(values.currentTarget.checked);
  }

  deletitem() {
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Zeile löschen wollen?"))) {

      let kspezv = {
        Status: 'delete',
        ID: this.selid,
        OTYPID: this.otypid
      };

      this.apiservice
        .sendKSPEZV({
          kspezv
        })
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.spezifikation.rows = [];
            this.spezifikationall = res.rows;

            setTimeout(() => {
              this.setcurrentSpez(this.selnodeid);
              this.resort();
            }, 50);

            this.selid = 0;
            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          }
          else {
            this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
          }
        });

    }
  }

  public close() {
    this.closedialog.next(true);
  }

  ngOnInit() {
    this.titel = this.mrTranslate.transform("Spezifikation")
    this.selectedfeldtyp = "Text";
  }



}
