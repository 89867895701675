import { Component, OnInit, ViewChild, ElementRef} from "@angular/core";
import { APIService } from "../../../../services/APIService/api.service";
import { first } from "rxjs/operators";
import {UntypedFormGroup,UntypedFormControl} from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import * as _ from "underscore";
import { ToastrService } from "ngx-toastr";
import { ClrDatagrid } from "@clr/angular";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { DomSanitizer } from '@angular/platform-browser';
import { Context} from "svgcanvas";
import { ClarityIcons } from "@clr/icons";
import { DatePipe } from '@angular/common';
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";

@Component({
  selector: "app-linienverwaltungdialog",
  templateUrl: "./linienverwaltungdialog.component.html",
  styleUrls: ["./linienverwaltungdialog.component.scss"],
  imports: [ CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule,HideIDColumnsPipe,TablePrettyPrintPipe,DotDotDotPipe ],
  standalone: true
})
export class LinienverwaltungDialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public minSize;

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private spinner: SpinnerService,
  ) {
    this.initdate = this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS')

  }
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild("leftObjectsDatagrid") leftObjectsDatagrid;
  @ViewChild("addedObjectsDatagrid") addedObjectsDatagrid;

  initdate: any;

  iconColumns: string[] = ["Typ", "typ","sp", "st", "si", "lf", "rep"];
  selid: number;
  objekte: any;
  objekteorginal: any;

  anlagenklassen: any;

  selobjekt: any;
  isModalVisible: boolean = false;
  modaltitel; string ="";
  svgFile: any;
  base64Canvas: string;
  svg: any;
  svgbase64: any;
  icontitel: string;
  @ViewChild('_nummer') _nummer: ElementRef;
  @ViewChild('_ort') _ort: ElementRef;
  @ViewChild('_weg') _weg: ElementRef;
  @ViewChild('_belastung') _belastung: ElementRef;
  @ViewChild('_forecolor') _forecolor: ElementRef;
  @ViewChild('_fillcolor') _fillcolor: ElementRef;

  @ViewChild('_akmin') _akmin: ElementRef;
  @ViewChild('_akmax') _akmax: ElementRef;
  @ViewChild('_akintervall') _akintervall: ElementRef;

  linieGroup: UntypedFormGroup = new UntypedFormGroup({
    nummer: new UntypedFormControl(''),
    ort: new UntypedFormControl(''),
    weg: new UntypedFormControl(''),
    belastung: new UntypedFormControl(0),
    forecolor: new UntypedFormControl('#FFFFFF'),
    fillcolor: new UntypedFormControl('#0091D8'),
  });

  akGroup: UntypedFormGroup = new UntypedFormGroup({
    min: new UntypedFormControl(''),
    max: new UntypedFormControl(''),
    intervall: new UntypedFormControl(0),
  });

  activeTabs = {
    user: true,
    belegung: false,
    anlagenklassen: false,
  };
  selectedLinienID: any;
  stammobjekte: any;
  stammobjekteorginal: any;
  toRemove: any[];
  toAdd: any[];
  selstammobjekte: any;
  sellinienobjekte: any;
  _selectedLinienForObject: any;
  linien: any;
  groupObjects: any;


  selectedStammList: any[] = [];
  selectedLinieList: any[] = [];
  akid: number = 0;
  detailak: any;

  public tabChange(key: string) {
    this.selectedLinienID = undefined;

    if (key == 'belegung'){
      if (this.stammobjekte == undefined){
        let linie = {
          status: 'getstammforobjekt',
        }

        this.apiservice
        .sendKLINIEN({linie})
        .pipe(first())
        .subscribe((val: any) => {
          if (val) {
            this.stammobjekte = val;
            this.stammobjekteorginal = val.rows;
          }
        });
      }

    }

    for (const act of Object.keys(this.activeTabs)) {
      if (act != key) {
        this.activeTabs[act] = false;
      } else {
        this.activeTabs[act] = true;
      }
    }

  }

  set selectedLinienForObject(newVal: any) {
    let linie = {
      status: 'getlinienforobjekt',
      id: parseInt(newVal),
    }

    this.apiservice
      .sendKLINIEN({linie})
      .pipe(first())
      .subscribe((val: any) => {
        if (val) {
          this.groupObjects = {};
          this.stammobjekte.rows = [];

          setTimeout(() => {
            this.groupObjects = val;
            this.stammobjekte.rows = this.stammobjekteorginal.filter(stamm=> !this.groupObjects.rows.some(linie=> linie.OSTAMMID==stamm.OSTAMMID));
            this.selectedLinieList = [];
            this.selectedStammList = [];
          }, 1);

        }
    });

    this._selectedLinienForObject = newVal;
  }

  get selectedLinienForObject(): any {
    return this._selectedLinienForObject;
  }

  calcAK(){
    this.spinner.enable();

    let linie = {
      status: 'calcAK',
    }
    this.apiservice
    .sendKLINIEN({linie})
    .pipe(first())
    .subscribe((val: any) => {
      if (val.success) {
        this.toastr.success(this.mrTranslate.transform("Anlagenklassen bearbeitet ") + val.done + " / " + val.all);
        this.spinner.disable();
      }
    });

  }

  selectionLinieChanged($event){

  }

  selectionStammChanged($event){

  }

  executeToAdd(){

    let OSTAMMIDs = this.selectedStammList.map(item => {
        return item.OSTAMMID;
    });
    let linieid = this._selectedLinienForObject;

    let linie = {
      status: 'addobjekttoline',
      id: parseInt(linieid),
      ostammid: OSTAMMIDs,
    }

    this.apiservice
    .sendKLINIEN({linie})
    .pipe(first())
    .subscribe((val: any) => {
      if (val) {
        this.groupObjects = {};
        this.stammobjekte.rows = [];

        setTimeout(() => {
          this.groupObjects = val;
          this.stammobjekte.rows = this.stammobjekteorginal.filter(stamm=> !this.groupObjects.rows.some(linie=> linie.OSTAMMID==stamm.OSTAMMID));
          this.selectedLinieList = [];
          this.selectedStammList = [];
        }, 1);

      }
    });

  }

  executeToRemove(){
    let OSTAMMIDs = this.selectedLinieList.map(item => {
      return item.OSTAMMID;
    });
    let linieid = this._selectedLinienForObject;

    let linie = {
      status: 'deleteobjektfromline',
      id: parseInt(linieid),
      ostammid: OSTAMMIDs,
    }

    this.apiservice
    .sendKLINIEN({linie})
    .pipe(first())
    .subscribe((val: any) => {
      if (val) {
        this.groupObjects.rows = [];
        this.stammobjekte.rows = [];

        setTimeout(() => {
          this.groupObjects = val;
          this.stammobjekte.rows = this.stammobjekteorginal.filter(stamm=> !this.groupObjects.rows.some(linie=> linie.OSTAMMID==stamm.OSTAMMID));
          this.selectedLinieList = [];
          this.selectedStammList = [];
        }, 1);
      }
    });
  }

  selectionObjektChanged(value: any) {
    this.selid = value.ID;
  }

  onDetailOpen($event){
    //console.log($event);
    if ($event != undefined){
      let detailvalue = $event;
      this.svgFile =  detailvalue.svgcode;
      this.linieGroup.value.fillcolor = '#' + detailvalue.BackColor.toString(16).padStart(6, '0');
      this.linieGroup.value.forecolor = '#' + detailvalue.ForeColor.toString(16).padStart(6, '0');
      this.linieGroup.value.nummer = detailvalue.Nummer;
    }
  }

  newitem(){
    this.modaltitel ="Neue Linie erstellen"
    this.svgFile = undefined;
    this.linieGroup.value.fillcolor = '#0091D8';
    this.linieGroup.value.forecolor = '#FFFFFF';
    this.linieGroup.value.nummer = '';
    this.linieGroup.value.ort = '';
    this.linieGroup.value.weg = '';
    this.linieGroup.value.belastung = 0;
    this.isModalVisible = true;
  }


  saveItems(id){
    this.linieGroup.value.fillcolor = this._forecolor.nativeElement.value;
    this.linieGroup.value.forecolor = this._fillcolor.nativeElement.value;
    this.linieGroup.value.nummer = this._nummer.nativeElement.value;
    this.linieGroup.value.ort = this._ort.nativeElement.value;
    this.linieGroup.value.weg = this._weg.nativeElement.value;
    this.linieGroup.value.belastung = this._belastung.nativeElement.value;
    this.doSave(id)
  }

  onAKDetailOpen($event){
    if ($event != undefined){
      let detailvalue = $event;
      this.akid = detailvalue.ID;
      this.detailak = detailvalue;
    }
  }

  saveAKItems(id){

    let linie = {
      status: 'updateak',
      id: id,
      bez: this.detailak.Bezeichnung,
      min: this._akmin.nativeElement.value,
      max: this._akmax.nativeElement.value,
      intervall: this._akintervall.nativeElement.value,
    };

    if (this.anlagenklassen != undefined){
      if (this.anlagenklassen.rows)
        this.anlagenklassen.rows = [];
    }

    this.apiservice
    .sendKLINIEN({linie})
    .pipe(first())
    .subscribe((val: any) => {
      if (val){

        this.anlagenklassen = val.kalkw;
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));

      }
      else
      {
        this.toastr.warning(this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
      }
    });


  }



  addLinie(){
    this.doSave(-1)
  }

  doSave(id){
    let vals = this.linieGroup.value;
    if (vals.nummer == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Nummer ein"));
      return;
    }

    if (this.objekteorginal != undefined){
      if (id == -1 && this.objekteorginal != undefined){
        if(this.objekteorginal.some((item) => item.Nummer.toLowerCase() == vals.nummer.toLowerCase())){
          this.toastr.error(this.mrTranslate.transform("Nummer existiert bereits"));
          return;
        }
      }
      else
      {
        if(this.objekteorginal.some((item) => item.Nummer.toLowerCase() == vals.nummer.toLowerCase() && item.ID != id)){
          this.toastr.error(this.mrTranslate.transform("Nummer existiert bereits"));
          return;
        }
      }
    }


    if (vals.ort == ''){
      this.toastr.error(this.mrTranslate.transform("Error: Geben Sie einen Ort ein"));
      return;
    }

    let linie = {
      status: 'update',
      id: id,
      fillcolor: parseInt(vals.fillcolor.slice(1), 16),
      forecolor: parseInt(vals.forecolor.slice(1), 16),
      nummer: vals.nummer,
      ort: vals.ort,
      weg: vals.weg,
      base64: this.base64Canvas,
      belastung: vals.belastung,
    };

    if (this.objekte != undefined){
      if (this.objekte.rows)
        this.objekte.rows=[];
    }

    this.initdate = this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS')

    this.apiservice
    .sendKLINIEN({linie})
    .pipe(first())
    .subscribe((val: any) => {
      if (val){

        let item = val.klinien.rows.find(
          (el) => el.Nummer == vals.nummer
        )

        let linie = {
          updateid: item.ID,
          svg:this.svgbase64
        }
        this.apiservice
        .sendKLINIEN({linie})
        .pipe(first())
        .subscribe((val: any) => {
          if (val){

            val.klinien.rows.forEach((row) => {
              let svg = atob(row.svgicon);
              let name = 'linie_' + row.ID +'_'+ this.initdate.toString().replace(" ","_").replace(":","_");

              let icons = {};
              icons[name] =  svg;
              ClarityIcons.add(icons);
              row.icon = name;
              row.svgcode = svg;
            });

            this.objekte = val.klinien;
            this.objekteorginal = val.klinien.rows;

            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
            this.linieGroup.value.fillcolor = '#0091D8';
            this.linieGroup.value.forecolor = '#FFFFFF';
            this.linieGroup.value.nummer = '';
            this.linieGroup.value.ort = '';
            this.linieGroup.value.weg = '';
            this.isModalVisible = false;
          }
        });
      }
      else
      {
        this.toastr.warning(this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
      }
    });

  }

  deletitem(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Linie löschen wollen?"))) {

      let linie = {
        status: 'delete',
        id: this.selid
      }

      this.objekte.rows=[];


      this.initdate = this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS')
      this.apiservice
        .sendKLINIEN({linie})
        .pipe(first())
        .subscribe((val: any) => {

          val.klinien.rows.forEach((row) => {
            let svg = atob(row.svgicon);
            let name = 'linie_' + row.ID +'_'+ this.initdate.toString().replace(" ","_").replace(":","_");

            this.selid = 0;

            let icons = {};
            icons[name] =  svg;
            ClarityIcons.add(icons);
            row.icon = name;
            row.svgcode = svg;
          });

          this.objekte = val.klinien;
          this.objekteorginal = val.klinien.rows;

        });


    }
  }

  onChange(v: any, t: any){

    if ( t == 'fillcolor' ){
      this.linieGroup.value.fillcolor = v;
    }

    if ( t == 'forecolor' ){
      this.linieGroup.value.forecolor = v;
    }

    if ( t == 'nummer' ){
      this.linieGroup.value.nummer = v;
    }

    this.svgFile = this.generateDefaultImage(this.linieGroup.value.nummer);

    this.svg = this.generateSVGImage(this.linieGroup.value.nummer);

  }

  generateSVGImage(text: string){
    const canvas = document.createElement("canvas");
    canvas.style.display = "none";
    const context2D = canvas.getContext("2d");

    let size = 28;
    let radius = size / 5;
    let fontsize = size / 2;

    const options = {
      height: size, // falsy values get converted to 500
      width: size, // falsy values get converted to 500
      ctx: context2D, // existing Context2D to wrap around
      enableMirroring: false, // whether canvas mirroring (get image data) is enabled (defaults to false)
      document: undefined, // overrides default document object
    };
    const ctx = new Context(options);

    ctx.fillStyle = this.linieGroup.value.fillcolor;
    ctx.beginPath();
    ctx.moveTo(size,size);
    ctx.arcTo(0,size,0,0,radius);
    ctx.arcTo(0,0,size,0,radius);
    ctx.arcTo(size,0,size,size,radius);
    ctx.arcTo(size,size,0,size,radius);
    ctx.fill()

    ctx.font = fontsize +"px arial";

    let textsize = ctx.measureText(text);
    textsize.width;
    if (textsize.width > 100){
      for (var i = 0; i <= fontsize-5; i++){
        fontsize = size / 2 - i;
        ctx.font = fontsize +"px arial";
        const text1 = ctx.measureText(text);
        text1.width;
        if (text1.width < 80){
          ctx.font = fontsize +"px arial";
          break;
        }
      }
    }
    textsize = ctx.measureText(text);
    textsize.width;
    var txtH = textsize.actualBoundingBoxAscent + textsize.actualBoundingBoxDescent;


    //console.log (textsize.width);
    ctx.fillStyle = this.linieGroup.value.forecolor;
    ctx.fillText(text, (size / 2) - (textsize.width / 2), (size / 2) + (txtH / 2));

    ctx.getSerializedSvg();
    const data = ctx.getSvg();
    let xmlSource =  data.outerHTML;

    let decoded = unescape(encodeURIComponent(xmlSource));

    let base64 = btoa(decoded);
    let str = atob(base64);


    this.svgbase64 = base64;

    this.svgFile = xmlSource;


    return data.outerHTML;
  }

  generateDefaultImage(name: string) {
    const canvas = document.createElement("canvas");
    canvas.style.display = "none";
    let size = 28;
    let radius = size / 5;
    let fontsize = size / 2;
    canvas.width = size;
    canvas.height = size;

    document.body.appendChild(canvas);
    const ctx = canvas.getContext("2d")!;
    ctx.fillStyle = this.linieGroup.value.fillcolor;
    ctx.beginPath();
    ctx.moveTo(size,size);
    ctx.arcTo(0,size,0,0,radius);
    ctx.arcTo(0,0,size,0,radius);
    ctx.arcTo(size,0,size,size,radius);
    ctx.arcTo(size,size,0,size,radius);
    ctx.fill()

    ctx.font = fontsize +"px arial";

    let textsize = ctx.measureText(name);
    textsize.width;
    if (textsize.width > 100){
      for (var i = 0; i <= fontsize-5; i++){
        fontsize = size / 2 - i;
        ctx.font = fontsize +"px arial";
        const text1 = ctx.measureText(name);
        text1.width;
        if (text1.width < 80){
          ctx.font = fontsize +"px arial";
          break;
        }
      }
    }
    textsize = ctx.measureText(name);
    textsize.width;
    var txtH = textsize.actualBoundingBoxAscent + textsize.actualBoundingBoxDescent;


    //console.log (textsize.width);
    ctx.fillStyle = this.linieGroup.value.forecolor;
    ctx.fillText(name.toUpperCase(), (size / 2) - (textsize.width / 2), (size / 2) + (txtH / 2));
    const data = canvas.toDataURL();
    this.base64Canvas = data.split(';base64,')[1];

    document.body.removeChild(canvas);
    return data;
  }

  getSVGImageUrl(image) {
    let base64string = btoa(image);
    return this._sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${base64string}`);
  }


  public close() {
    this.closedialog.next(true);
  }

  ngOnInit() {
    this.selid = 0;
    this.loadLinien();
  }

  private loadLinien() {

    let linie = {
      status: 'get',
    }

    this.apiservice
      .sendKLINIEN({linie})
      .pipe(first())
      .subscribe((val: any) => {

        val.klinien.rows.forEach((row) => {
          let svg = atob(row.svgicon);
          let name = 'linie_' + row.ID +'_'+ this.initdate.toString().replace(" ","_").replace(":","_");

          let icons = {};
          icons[name] =  svg;
          ClarityIcons.add(icons);
          row.icon = name;
          row.svgcode = svg;
        });

        if (val.klinien.rows != 0){
          this.objekte = val.klinien;
          this.objekteorginal = val.klinien.rows;

          this.anlagenklassen = val.kalkw;
        }



      });
  }

}
