<clr-modal *ngIf="isHelperModalOpen"
  [(clrModalOpen)]="isHelperModalOpen"
  [clrModalSize]="'xl'" [clrModalClosable]="true">
  <h3 class="modal-title">{{ "Beschreibung" | mrTranslate }} {{ isReadOnly ? '' : ('verwalten' | mrTranslate) }}</h3>
  <div class="modal-body clr-row nxm">
    <app-preview-thumbnails [class.openedPDF]="model.pdfUrl"
      [readonly]="isReadOnly"
      [drag_drop]="true"
      [maxAmount]="1"
      [acceptTypes]="'image/*, .pdf'"
      (clearedThumb)="model.pdfUrl = null"
      (deleteFile)="clearHelper()"
      (lastUpload)="checkPDF($event)"
      (openPdfOverlay)="showPDF($event)">
    </app-preview-thumbnails>
    <iframe *ngIf="model.pdfUrl"
      [attr.src]="model.pdfUrl"
      style="border: none; width: 100%; height: 50svh;">
    </iframe>
    <div *ngIf="isReadOnly; else aktiv" class="clr-row nxm">
      <h4 class="clr-col-12">{{ "Beschreibung" | mrTranslate }}</h4>
      <p class="clr-col-12">{{ model.helperText }}</p>
    </div>

    <ng-template #aktiv>
      <form (ngSubmit)="saveHelper()" ngForm clrForm
        class="clr-col-12 width100" clrLayout="vertical">
        <clr-textarea-container class="mt-0">
          <label>{{ "Beschreibung" | mrTranslate }}</label>
          <textarea clrTextarea name="helperText"
            [autofocus]="isHelperModalOpen" [ngModel]="model.helperText">
          </textarea>
        </clr-textarea-container>
      </form>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button *ngIf="!isReadOnly" type="button" class="btn btn-primary"
      (click)="ngForm.ngSubmit.emit()">{{'OK' | mrTranslate}}
    </button>
    <button type="button" class="btn" (click)="isHelperModalOpen = false">
      {{'Abbrechen' | mrTranslate}}
    </button>
  </div>
</clr-modal>
