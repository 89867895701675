import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, Inject, OnDestroy } from '@angular/core';
import { ClrDatagrid } from '@clr/angular';
import { Subject } from 'rxjs';
import { fromEvent, takeUntil } from 'rxjs';

/**
 * @example
 * <clr-datagrid deselectButton [(clrDgSingleSelected)]="...">
 */
@Directive({
  selector: '[deselectButton][clrDgSingleSelected]',
  standalone: true

})
export class DeselectSingleSelectedDirective implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>;

  constructor(
    private grid: ClrDatagrid,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngAfterViewInit() {
    const btn = this.doc.createElement('button');
    btn.innerHTML = '<clr-icon shape="remove" size="17"></clr-icon>';
    btn.setAttribute('class', `btn btn-link btn-icon centred padding-11-lr noover`);
    fromEvent(btn, 'click').pipe(takeUntil(this.destroy$)).subscribe(
      () => this.grid.selection.clearSelection()
    )
    const container = this.grid.el.nativeElement.querySelector(
      '.datagrid-column.datagrid-select.datagrid-fixed-column'
    );
    container.classList.add('np');
    container.appendChild(btn);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
