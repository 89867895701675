import { NgClass, NgFor, NgIf, NgStyle, TitleCasePipe } from "@angular/common";
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ClrConditionalModule, ClrDatagrid, ClrDatagridModule, ClrIconModule, ClrModalModule, ClrPopoverHostDirective, ClrStopEscapePropagationDirective, ClrTabsModule, ClrTooltipModule } from "@clr/angular";
import JSZip from 'jszip';
import { Subscription, forkJoin } from "rxjs";
import { APIService } from "src/app/services/APIService/api.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { MangelMediaApiFuncService } from "src/app/services/_abstract/mangel-media-api-func.service";
import { DeselectSingleSelectedDirective } from "../../directives/deselect-single-selected.directive";
import { DynamicStyleDirective } from "../../directives/dynamic-style.directive";
import { DotDotDotPipe } from "../../pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "../../pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "../../pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "../../pipes/tablePrettyPrint.pipe";
import { BasicStoreService } from "../../services/BasicStore/basic-store.service";
import { MediaViewModalComponent, TitleAndFileNames } from "../_components/_shared/mediaviewmodal/mediaviewmodal.component";
import { DetailviewComponent } from "../_components/detailview/detailview.component";
import { previewMap } from "../_components/detailview/previewMap";
import { ProgressbarComponent } from "../_components/progressbar/progressbar.component";
import { KostenListeComponent } from "./kostenliste/kostenliste.component";
import { NewauftragmodalComponent } from "./newauftragmodal/newauftragmodal.component";

@Component({
    selector: "app-auftraege",
    templateUrl: "./auftraege.component.html",
    styleUrls: ["./auftraege.component.scss"],
    imports: [
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrTabsModule,
        ClrConditionalModule,
        ClrModalModule,
        NgStyle,
        NgIf,
        DynamicStyleDirective,
        NewauftragmodalComponent,
        ClrDatagridModule,
        DeselectSingleSelectedDirective,
        NgFor,
        ClrIconModule,
        ClrTooltipModule,
        NgClass,
        DetailviewComponent,
        ProgressbarComponent,
        KostenListeComponent,
        MediaViewModalComponent,
        TitleCasePipe,
        TablePrettyPrintPipe,
        HideIDColumnsPipe,
        DotDotDotPipe,
        MrTranslatePipe,
    ],
    standalone: true
})
export class AuftraegeComponent implements OnDestroy, OnInit {
  protected auftraege: any = {
    rows: [],
    columns: [],
  };

  // * Alle zu filternden Objekte und Mängel
  private objekte: any = {
    rows: [],
    columns: [],
  };

  private maengel: any = {
    rows: [],
    columns: [],
  };

  // * Alle zu filternden Mängel mit ausgewähltem kauftrid
  private mnglAllAuftrag: any[];


  // * Angezeigte und gefilterte Objekte und Mängel
  protected objTable: any = {
    rows: [],
    columns: [],
  };

  protected mnglTable: any = {
    rows: [],
    columns: [],
  };
  protected trackByAuftrId = (_: number, row: any) => row['auftrag'];
  protected trackByOstammID = (_: number, row: any) => row['ostammid'];

  private _selectedAuftrag: any;
  protected get selectedAuftrag() {
    return this._selectedAuftrag;
  }
  protected set selectedAuftrag(auftrag) {
    this._selectedAuftrag = auftrag;
    const kauftrid = auftrag?.auftrag;
    this.objTable.rows = [];
    this.objDg?.selection.clearSelection();
    if (kauftrid) {
      // * In ObjektTable in Template filtern objekte mit dieser Auftragnummer
      this.objTable.rows = this.objekte.rows.filter(
        (obj: any) => obj.auftragid == kauftrid
      );
      // * In MangelTable in Template filtern mängel mit dieser Auftragnummer
      this.mnglAllAuftrag = this.maengel.rows.filter(
        (mngl: any) => mngl.auftragid == kauftrid
      );

      if (
        this.objTable.rows?.length &&
        this.mnglAllAuftrag?.length
      ) {
        setTimeout(() => {
          if (this.objDg) {
            this.objDg.selection.current = this.objTable.rows;
          }
        });
      }
    }
  }

  private _selectedObjects: number[] = [];
  protected get selectedObjects() {
    return this._selectedObjects;
  }
  protected set selectedObjects(newObjs: number[]) {
    this._selectedObjects = newObjs;
    const ostammids = newObjs.map((newObj: any) => newObj.ostammid);
    if (ostammids?.length && this.mnglAllAuftrag?.length) {
      this.mnglTable.rows = this.mnglAllAuftrag.filter(
        (mngl) => ostammids.indexOf(mngl.ostammid) != -1
      );
    } else this.mnglTable.rows = [];
    // this.objDg?.dataChanged();
  }


  private _ostammid: number = undefined;
  protected get ostammid() {
    return this._ostammid;
  }
  protected set ostammid(val: number) {
    this.showdetailview = !!val;
    this._ostammid = val;
  }

  showoverlay: boolean = false;
  showdetailview: boolean = false;
  hidedetailview: boolean = false;
  showprogressbar: boolean = false;
  map:any;
  showGIS: boolean = false;


  split50 = false;
  sidenavenlarged = false;
  progressbarenlarged = false;
  progressbars: any;
  componentStore: any = {};

  indexScrub: number = -1;

  protected auftrPage = 1;
  private scrollContainer: ElementRef<HTMLElement>;
  private auftrDg: ClrDatagrid;
  @ViewChild("auftrDg", { read: ClrDatagrid, static: false }) set auftragDatagrid (value: ClrDatagrid) {
    this.auftrDg = value;
    this.scrollContainer = value?.datagridTable;
  }
  @ViewChild("objDg", { read: ClrDatagrid, static: false }) private objDg: ClrDatagrid;

  subscriptions: any = [];
  dataRefresh = new EventEmitter();
  reloadTabsIsReady = new EventEmitter();

  mangelMedia: TitleAndFileNames[];
  newAuftragModalOpen = false;

  constructor(
    private basicStore: BasicStoreService,
    private apiservice: APIService,
    private spinnerservice: SpinnerService,
    private overlayService: OverlayService,
    protected media: MangelMediaApiFuncService
  ) {
    this.subscriptions.push(this.dataRefresh.subscribe(() => {
      this.reloadTabs();
    }));
  }

  ngOnInit(): void {
    this.spinnerservice.enable();
    this.componentStore = this.basicStore.getComponentStore("auftraege");

    if (!this.componentStore?.auftraege?.rows?.length) {
      this.loadAuftraege();
    } else {
      setTimeout(() => {
        if (this.componentStore.auftraege.rows.length)
          this.auftraege = this.componentStore.auftraege;

        if (this.componentStore.maengel.rows.length) {
          this.maengel = this.componentStore.maengel;
          this.mnglTable.columns = this.maengel.columns;
        }

        if (this.componentStore.objekte.rows.length) {
          this.objekte = this.componentStore.objekte;
          this.objTable.columns = this.objekte.columns;
        }

        this.restoreSelection();

        setTimeout(() => {
          this.spinnerservice.disable();
        });
      });
    }
  }

  restoreSelection() {
    setTimeout(() => {
      const selAuft = this.selectedAuftrag ?? this.componentStore?.selectedAuftrag;
      if (selAuft) {
        const all = this.auftrDg.items.all;
        const selected = all.findIndex(
          (item) => item.auftrag == selAuft.auftrag
        );
        this.auftrDg.singleSelected = all[selected];
        this.auftrPage = Math.ceil((selected + 1) / 50);
      }
      setTimeout(() => {
        if (this.componentStore?.selectedObjects?.length) {
          let ostammidsSelected = this.componentStore.selectedObjects.map((selObj) => selObj.ostammid);
          this.objDg.selected = this.objDg.items.all.filter(
            (item) => ostammidsSelected.indexOf(item.ostammid) != -1);
        }
      }, 10);
    }, 5);
  }

  loadAuftraege() {
    this.subscriptions.push(forkJoin({
      objekte: this.apiservice.getAuftraegeObjekteAll(),
      maengel: this.apiservice.getAuftraegeMaengelAll(),
      auftraege: this.apiservice.getAuftraege(),
    }).subscribe(({ auftraege, objekte, maengel }: any) => {
      var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
      jsZip.loadAsync(auftraege).then((zip) => {
        return zip.file("tmp.txt").async("string");
      }).then((zipf) => {
        zipf = JSON.parse(zipf);
        if (zipf.rows?.length) {
          if (this.auftraege.columns?.length == 0) {
            this.auftraege.columns = zipf.columns;
          }
          this.auftraege.rows = zipf.rows;
        }

        var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
        jsZip.loadAsync(objekte).then((zip) => {
          return zip.file("tmp.txt").async("string");
        }).then((zipf) => {
          zipf = JSON.parse(zipf);
          if (zipf.rows?.length) {
            if (this.objekte.columns?.length == 0) {
              this.objekte.columns = zipf.columns;
              this.objTable.columns = zipf.columns;
            }
            this.objekte.rows = zipf.rows;
          }

          var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
          jsZip.loadAsync(maengel).then((zip) => {
            return zip.file("tmp.txt").async("string");
          }).then((zipf) => {
            zipf = JSON.parse(zipf);
            if (zipf.rows?.length) {
              if (this.maengel.columns?.length == 0) {
                this.mnglTable.columns = zipf.columns;
                this.maengel.columns = zipf.columns;
              }
              this.maengel.rows = zipf.rows;
            }


            setTimeout(() => {
              this.spinnerservice.disable();
              this.basicStore.setComponentStore("auftraege", {
                ...this.componentStore,
                auftraege: this.auftraege,
              });
              this.restoreSelection();
            });
          });

        });
      });
    }));
  }

  reloadTabs() {
    this.basicStore.reloadTabs( { reloadAnlagen: false } );
    this.loadAuftraege();
  }

  protected scroll() {
    if (this.selectedAuftrag) {
      const kauftrid = this.selectedAuftrag.auftrag;
      this.scrollContainer.nativeElement
        .querySelector("#a-" + kauftrid)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
    }
  }

  previewMap(row: any) {
    let geometry = this.getObjectGeometry(row);
    if (geometry !== null) {
      this.showGIS = true;
      setTimeout(() => {
        previewMap(row, geometry, this.map);
      });
    }
  }

  getObjectGeometry(row: any) {
    let item = this.objTable.rows.find(
      (el) => el.ostammid == row.ostammid
    )
    if (item != undefined) {
      let geometry = item.geometry;
      return geometry?.type ? geometry : null;
    }
    return null;
  }

  openAuftrag(row: any) {
    const auftrag = {
      Titel: row.titel,
      Firma: row.firma,
      ["Termin Anfang"]: row.termins,
      ["Termin Ende"]: row.termin,
      Auftragsart: row.auftragart,
      Auftragsnummer: row.auftragnummer,
      Kostenart: row.kostenart,
      Bemerkung: row.bemerkung,
      Auftragsstatus: row.erlstatus,
      Gruppe: row.gruppenid,

    };

    this.overlayService.setOverlay({
      overlay: "auftragDetail",
      data: {
        id: Number(row.auftrag),
        maengel: {
          rows: this.maengel.rows.filter(
            (mngl) => mngl.auftragid == row.auftrag
          ),
          columns: this.maengel.columns,
        },
        objekte: {
          rows: this.objekte.rows.filter(
            (obj) => obj.auftragid == row.auftrag
          ),
          columns: this.objekte.columns,
        },
        auftrag,
      },
      dataRefresh: this.dataRefresh,
    });
  }

  openPDF(kauftrid: any) {
    this.spinnerservice.enable();
    this.apiservice.getAuftragPDF(parseInt(kauftrid.auftrag)).pipe().subscribe((val)=>{
      this.spinnerservice.disable();
      this.overlayService.setOverlay({
        overlay: "viewAuftrag",
        data: val,
        auftragsnr: kauftrid.auftrag,
        zIndex: 5,
      });
    });
  }

  ngOnDestroy() {
    this.basicStore.setComponentStore("auftraege", {
      auftraege: this.auftraege,
      objekte: this.objekte,
      maengel: this.maengel,
      selectedAuftrag: this.selectedAuftrag,
      selectedObjects: this.selectedObjects,
    });

    this.subscriptions.forEach((subs: Subscription) => {
      subs.unsubscribe();
    });
  }

  enlargeSidenav(event) {
    if (event.mode == "full") {
      this.sidenavenlarged = event.val;
    } else if (event.mode == "half") {
      this.split50 = event.val;
    } else if (event.mode == "progress") {
      this.showprogressbar = event.val;
      this.progressbars = {};
    } else if (event.mode == "hidden") {
      this.hidedetailview = event.val;
    } else if (event.mode == "close") {
      this.ostammid = undefined;
      this.showprogressbar = false;
      setTimeout(() => {
        this.showdetailview = false;
      });
    }
  }

  handleScrub(event) {
    this.indexScrub = event;
  }

  showProgressData(data) {
    if (data) {
      this.progressbars = data;
      this.showprogressbar = true;
    }
  }

  enlargeProgressbar(val?) {
    if (val) {
      this.progressbarenlarged = true;
    } else {
      this.progressbarenlarged = !this.progressbarenlarged;
    }
  }
}
