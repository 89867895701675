import { CommonModule, DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "../../../../services/APIService/api.service";
import { BasedatamodalComponent } from "../../../_components/_modals/basedatamodal/basedatamodal.component";

@Component({
  selector: "app-spezifikationtemplatedialog",
  templateUrl: "./spezifikationtemplatedialog.component.html",
  styleUrls: ["./spezifikationtemplatedialog.component.scss"],
  imports: [ CommonModule, ClarityModule, BasedatamodalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule,HideIDColumnsPipe,TablePrettyPrintPipe,DotDotDotPipe ],
  standalone: true
})
export class SpezifikationTemplatedialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public minSize;

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
    private datePipe: DatePipe,
  ) {
  }

  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild(BasedatamodalComponent) modal: BasedatamodalComponent;
  @ViewChild('code') code: ElementRef;
  @ViewChild('geschlossen') geschlossen: ElementRef;
  @ViewChild('epreis') epreis: ElementRef;


  set data(dataIn: any) {
    if (dataIn && dataIn.id) {
      if (dataIn.id.otypid) {
        this.otypid = dataIn.id.otypid;
        this.loadCodes();
      }
    }
  }

  openWerteliste(titel, TABWERT, SFELDNA){
    let inData={
      titel: this.mrTranslate.transform(titel),
      tabelle: TABWERT,
      OTYPID: this.otypid,
      tname: SFELDNA,
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true){

        if (inData.tabelle != ''){
              let code = {
                status: 'get',
                otypid: this.otypid,
                tabelle: inData.tabelle,
              }

              this.apiservice
              .sendSBFEK(code)
              .pipe(first())
              .subscribe((val: any) => {
                if (val){

                  if (inData.tabelle == 'SSCHF'){
                    this.schienen = val;
                  }

                  if (inData.tabelle == 'SSCHW'){
                    this.schwellenart = val;
                  }

                  if (inData.tabelle == 'SBEFT'){
                    this.schienenbefestigung = val;
                  }

                  if (inData.tabelle == 'SEIND'){
                    this.eindeckung = val;
                  }
                  inData.tabelle = '';
                }
            });
            }
        }
        this.modal.close();
    });

  }

  onBildChanged(event){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend =() => {
        let r = reader.result as String;
        var splitted = r.split(',');
        this.base64Image = splitted[1];
        this.bild = r;
        this.selecteditem.Bild = splitted[1];
      }
      reader.readAsDataURL(file);
    }
  }

  deleteImage(){
    this.bild = undefined;
    this.base64Image = '';
    this.selecteditem.Bild = null;
  }

  bild: any;
  base64Image: any;
  iconColumns: string[] = ["bild"];
  selid: number = 0;
  otypid: number = 2;
  stammdaten: any;
  schienen: any;
  selectedSchienen: any;
  schwellenart: any;
  selectedSchwellenart: any;
  schienenbefestigung: any;
  selectedSchienenbefestigung: any;
  eindeckung: any;
  selectedEindeckung: any;
  selecteditem: any;
  detailState: any = null;
  showBildModal: boolean = false;

  editGroup: UntypedFormGroup = new UntypedFormGroup({
    geschlossen: new UntypedFormControl(false),
    kosten: new UntypedFormControl(0),
    code: new UntypedFormControl(''),
    schiene: new UntypedFormControl(''),
    schwelle: new UntypedFormControl(''),
    befestigung: new UntypedFormControl(''),
    eindeckung: new UntypedFormControl(''),
    ep: new UntypedFormControl(''),
    nummer: new UntypedFormControl(''),
    ort: new UntypedFormControl(''),
    weg: new UntypedFormControl(''),
  });

  newitem(){

    let newitems ={
      ID: -1,
      OTYPID: this.otypid,
      Code: '',
      Schienenform: '',
      Schwellenart: '',
      Schienenbefestigung: '',
      Eindeckung: '',
      BildBase64:'',
      EPreis: 0,
      Geschlossen:false,

    }

    this.stammdaten.rows.push(newitems);
    this.selecteditem = newitems;
    this.detailState = newitems;
  }

  saveitem(){

    if(this.code.nativeElement.value == ''){
      this.toastr.warning(this.mrTranslate.transform("Bitte geben Sie einen Code an"));
    }

    if(this.selectedSchienen == ''){
      this.toastr.warning(this.mrTranslate.transform("Bitte wählen Sie eine Schienenform aus."));
      return;
    }

    if(this.selectedEindeckung == ''){
      if(this.selectedSchwellenart == ''){
        this.toastr.warning(this.mrTranslate.transform("Bitte wählen Sie eine Schwellenart aus."));
        return;
      }
      if(this.selectedSchienenbefestigung == ''){
        this.toastr.warning(this.mrTranslate.transform("Bitte wählen Sie eine Schienenbefestigung aus."));
        return;
      }
    }

    if (this.selid!=-1)
    {
      if(this.stammdaten.rows.some((item) => item.Code.toLowerCase() == this.code.nativeElement.value.toLowerCase() && item.ID != this.selid)){
        this.toastr.error(this.mrTranslate.transform("Code existiert bereits"));
        return;
      }
    }
    else
    {
      if(this.stammdaten.rows.some((item) => item.Code.toLowerCase() == this.code.nativeElement.value.toLowerCase())){
        this.toastr.error(this.mrTranslate.transform("Code existiert bereits"));
        return;
      }
    }


    let code = {
      status: 'update',
      otypid: this.otypid,
      id: this.selid,
      code: this.code.nativeElement.value,
      schiene: this.selectedSchienen,
      schwelle: this.selectedSchwellenart,
      befestigung:this.selectedSchienenbefestigung,
      eindeckung: this.selectedEindeckung,
      geschlossen: this.geschlossen.nativeElement.value,
      kosten: this.epreis.nativeElement.value,
      bild: this.base64Image,
    }

    this.selecteditem = undefined;
    this.detailState = undefined;
    this.stammdaten.rows = [];

    this.apiservice
      .sendSBFEK(code)
      .pipe(first())
      .subscribe((val: any) => {
        if (val){
          this.stammdaten.rows = val.codes.rows;
        }
    });
  }

  openBildemodal(){

    this.showBildModal = true;

  }

  deletitem(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?"))) {
      let code = {
        status: 'delete',
        otypid: this.otypid,
        id: this.selid,
      }

      this.selecteditem = undefined;
      this.detailState = undefined;
      this.stammdaten.rows = [];

      this.apiservice
        .sendSBFEK(code)
        .pipe(first())
        .subscribe((val: any) => {
          if (val){
            this.stammdaten.rows = val.codes.rows;
          }
      });
    }
  }

  onGeschlossenChange(value){

  }




  onDetailOpen($event){
    if ($event != undefined){

      this.bild = undefined;

      let detailvalue = $event;

      this.base64Image = detailvalue.Bild;

      if (detailvalue.Bild != null && detailvalue.Bild != '')
      {
        var file = this.b64toBlob(detailvalue.Bild, "image/jpeg", 512 );
        this.bild  = URL.createObjectURL(file);
      }


      // this.editGroup.controls.code.setValue(detailvalue.Code);
      // this.editGroup.controls.schiene.setValue(detailvalue.Schienenform);
      // this.editGroup.controls.schwelle.setValue(detailvalue.Schwellenart);
      // this.editGroup.controls.befestigung.setValue(detailvalue.Schienenbefestigung);
      // this.editGroup.controls.eindeckung.setValue(detailvalue.Eindeckung);
      // this.editGroup.controls.ep.setValue(detailvalue.EPreis);

      setTimeout(() => {
        this.selectedSchienen = detailvalue.Schienenform;
        this.selectedSchwellenart = detailvalue.Schwellenart;
        this.selectedSchienenbefestigung = detailvalue.Schienenbefestigung;
        this.selectedEindeckung = detailvalue.Eindeckung;
      }, 100);



    }
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    console.log(byteArrays);

    return new File(byteArrays, "pot", { type: contentType });
  }



  selectedSchienechanged(value){
    this.editGroup.controls.schiene.setValue(value);
  }

  selectionFirmaChanged($event){
    this.selid = $event.ID;
  }
  public close() {
    this.closedialog.next(true);
  }

  ngOnInit() {
  }

  private loadCodes() {

    let code = {
      status: 'get',
      otypid: this.otypid,
    }

    this.apiservice
      .sendSBFEK(code)
      .pipe(first())
      .subscribe((val: any) => {
        if (val){
          this.stammdaten = val.codes;
          this.schienen = val.schiene;
          this.schienenbefestigung = val.befestigung;
          this.schwellenart = val.schwelle;
          this.eindeckung = val.eindeckung;

        }
    });
  }

}
