import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotDotDot',
  standalone: true

})
export class DotDotDotPipe implements PipeTransform {

  transform(value: any, limit: number): any {
    if (value) {
      return value.toString().length > limit ? value.toString().substr(0, limit) + "..." : value.toString();
    }
    return value;
  }

}
