import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[autofocus]",
  standalone: true
})
export class AutofocusDirective
{
  private focusOnCondition: boolean;
  constructor(private el: ElementRef) {}

  ngOnInit()
  {
    if (!this.focusOnCondition )this.condition = true;
  }

  @Input('autofocus') set condition(condition: boolean)
  {
    this.focusOnCondition = true;
    if (condition !== false)
    {
      //Otherwise Angular throws error: Expression has changed after it was checked.
      setTimeout(() =>
      {
        this.el.nativeElement.focus(); //For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
      });
    }
  }
}
