import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'in',
  standalone: true

})
export class CheckInArrayPipe implements PipeTransform {

  transform(value: unknown, array: unknown[]): boolean {
    return Array.isArray(array)
      ? array.includes(value)
      : false;
  }

}

@Pipe({
    name: 'at',
    standalone: true
})
export class AtIndexArrayPipe implements PipeTransform {

  transform<T>(array: T[], index: number): T {
    return Array.isArray(array)
      ? array.at(index)
      : undefined;
  }

}

@Pipe({
    name: 'indexOf',
    standalone: true
})
export class IndexOfArrayPipe implements PipeTransform {

  transform<T>(array: T[], elem: T): number {
    return Array.isArray(array)
      ? array.indexOf(elem)
      : undefined;
  }
}
