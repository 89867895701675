import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'check',
  standalone: true
})
export class CheckByCallbackFunctionPipe implements PipeTransform {

  transform(value: unknown, cb: (...args: unknown[]) => unknown, ...args: unknown[]): unknown {
    return cb(value, args);
  }

}
