import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrDatagrid, ClrForm } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";

import { Table } from "src/app/models/ui/table";
import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { MrUnitTextPipe } from "src/app/pipes/mrUnitText.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";

type ZuordnungsTable = Table & {
  obegeid: number;
}

@Component({
  selector: 'app-begehungseinstellungen',
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe, MrUnitTextPipe,
    HideIDColumnsPipe,DotDotDotPipe, TablePrettyPrintPipe
  ],
  templateUrl: './begehungseinstellungen.component.html',
  styleUrls: ['./begehungseinstellungen.component.scss'],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})
export class BegehungseinstellungenComponent implements OnInit {
  dataRefresh = new EventEmitter();
  set data(dataIn: any) {
    this.dataRefresh = dataIn.dataRefresh;
  }

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild("objekteDatagrid", { read: ClrDatagrid }) objekteDatagrid;
  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    protected localeDate: BackendLocaleDatePipe,
    private basicStore: BasicStoreService,
  ) {
    this.formGroup.controls.intervallbeginn.setValue(BackendLocaleDatePipe.now);
  }

  protected formGroup = new UntypedFormGroup({
    intervall: new UntypedFormControl(2, [Validators.required, Validators.min(0)]),
    bemerkung: new UntypedFormControl(''),
    bezeichnung: new UntypedFormControl("", [Validators.required]),
    intervallbeginn: new UntypedFormControl("", [Validators.required]),
    tag: new UntypedFormControl("1", [Validators.required]),
  });

  @ViewChild(ClrForm, { static: false })
    private clrForm: ClrForm
  ;
  protected obegeid: number = 0;
  protected objekte:any;
  protected objekteTable:any;
  protected zuordnungTable:any;
  protected begehungTable:any;
  protected selectedBegehung:any = [];
  protected selectedObjekte:any = [];
  protected selectedZuObjekte:any = [];
  protected selectedZuordnungTable:any;
  protected _zuordnungTable: ZuordnungsTable[] = [];
  protected anzahlItems: number = 0;
  protected insertItem: boolean = false;
  protected selectedZuordnung:any;

  protected objekteModal: boolean = false;
  protected iconColumns: string[] = ["typ", "sperrstatus", "lfstatus", "rep", "icon"];

  ngOnInit() {

    this.apiService
    .getBegehungItem()
    .pipe(first())
    .subscribe((res: any) => {
      if (res?.success){
        this.begehungTable = res.result.obege;
        this.zuordnungTable = res.result.sxbeg;

        this.setObjektTabelle();
      }
      else
        this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    });
  }

  setObjektTabelle(){
    forkJoin({
      anlagen: this.basicStore.getComponentStoreAsync("anlagen").pipe(
        first((val) => val && JSON.parse(val).anlagen.rows.length),
        map((res: any) => JSON.parse(res)?.anlagen)
      ),
    }).subscribe((res: any) => {
      this.objekte = res.anlagen;
      this._zuordnungTable = [];

      for(let row of this.begehungTable.rows){

        let rows:[] = this.zuordnungTable.rows.filter((cells) => cells.OBEGEID == row["ID"]);
        let r = [];

        for(let zurow of rows){
          let index = this.objekte.rows.findIndex((element) => element["OSTAMMID"] == zurow["OSTAMMID"]);
          if (index > -1){
            r.push(this.objekte.rows[index]);
            this.objekte.rows.splice(index, 1);
          }
        }

        let zuTable = {
          obegeid: row["ID"],
          columns: res.anlagen.columns,
          rows: [...r],
        }
        this._zuordnungTable.push(zuTable);
      }

    });
  }

  public close() {
    if (this.dataRefresh != undefined){
      this.dataRefresh.emit();
    }

    this.closedialog.next(true);
  }

  protected saveIfValid() {
    this.clrForm.markAsTouched();
    if (this.formGroup.valid) this.saveItem();
  }

  private saveItem() {
    if (this.insertItem) this.obegeid = -1;

    let obege = {
      status: 'update',
      obegeid: this.obegeid,
      vals: this.formGroup.value,
    };

    this.apiService
    .setBegehungItem(obege)
    .pipe(first())
    .subscribe((res: any) => {

      if (res?.success){
        this.begehungTable.rows = res.result.obege.rows;
        this.zuordnungTable = res.result.sxbeg;
        this.setObjektTabelle();
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        this.dataRefresh.emit();
      }
      else
        this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    });
  }


  addBegehung(){
    this.formGroup.reset({ tag: '1', intervall: 2, intervallbeginn: BackendLocaleDatePipe.now });
    this.insertItem = true;
    this.obegeid = -1;
  }

  deleteBegehung(){

    const text = this.mrTranslate.transform('Eintrag wirklich löschen?');
    if (confirm(text)) {
      let obege = {
        status: 'delete',
        obegeid: this.obegeid,
      };

      this.apiService
      .setBegehungItem(obege)
      .pipe(first())
      .subscribe((res: any) => {

        if (res?.success){
          this.begehungTable.rows = res.result.obege.rows;
          this.zuordnungTable = res.result.sxbeg;
          this.setObjektTabelle();
          this.toastr.info(this.mrTranslate.transform("Eintrag wurde gelöscht"));
          this.dataRefresh.emit();
        }
        else
          this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
    }
  }

  selectionGridChanged(value: any) {
    if (value != undefined){
      this.obegeid = value.ID;
      this.formGroup.controls.intervall.setValue(value.Intervall);
      this.formGroup.controls.bezeichnung.setValue(value.Begehungsart);
      this.formGroup.controls.bemerkung.setValue(value.Beschreibung);
      this.formGroup.controls.tag.setValue(value.Tag.toString());
      this.formGroup.controls.intervallbeginn.setValue(value.Beginn);

      this.anzahlItems = 0;


      let t = this._zuordnungTable.find(o => o.obegeid === value.ID);
      if (this.selectedZuordnungTable == undefined){

        let zuTable = {
          columns: t.columns,
          rows: t.rows,
        }

        this.selectedZuordnungTable = zuTable;

      }
      else{
        this.selectedZuordnungTable.rows = t.rows;
      }

      this.anzahlItems = this.selectedZuordnungTable.rows.length;


    }
    else{
      this.obegeid = -1;
    }
  }

  tabChange(tab){

  }

  addObjekt(){
    this.objekteModal = true;
  }

  setobjekteModal(){
    if (this.selectedObjekte.length > 0){
      let OSTAMMIDIds = this.selectedObjekte.map(items => items.OSTAMMID);
      let t = this._zuordnungTable.find(o => o.obegeid === this.obegeid);
      let r = [];

      for(let sid of OSTAMMIDIds){
        let index = this.objekte.rows.findIndex((element) => element["OSTAMMID"] == sid);
        r.push(this.objekte.rows[index]);
        this.objekte.rows.splice(index, 1);
      }
      for(let _r of r){
        t.rows.push(_r);
      }

      this.selectedZuordnungTable.rows = t.rows;

      let obege = {
        status: 'insertxbeg',
        obegeid: this.obegeid,
        ostammids: OSTAMMIDIds,
      };

      this.apiService
      .setBegehungItem(obege)
      .pipe(first())
      .subscribe((res: any) => {

        if (res?.success){
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        else
          this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
    }
  }

  deleteObjekt(){
    if (this.selectedZuObjekte.length > 0){

      const text = this.mrTranslate.transform('Einträge wirklich löschen?');

      if (confirm(text)) {

        let OSTAMMIDIds = this.selectedZuObjekte.map(items => items.OSTAMMID);

        let obege = {
          status: 'deletesxbeg',
          obegeid: this.obegeid,
          ostammids: OSTAMMIDIds,
        };

        this.apiService
        .setBegehungItem(obege)
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.success){
            this.zuordnungTable = res.result.sxbeg;
            this.setObjektTabelle();

            for(let sid of OSTAMMIDIds){
              let index = this.selectedZuordnungTable.rows.findIndex((element) => element["OSTAMMID"] == sid);
              this.selectedZuordnungTable.rows.splice(index, 1);
            }

            this.toastr.info(this.mrTranslate.transform("Einträge wurde gelöscht"));
          }
          else
            this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
        });
      }
    }
  }



  protected get dateControl(): string {
    return this.formGroup.get("intervallbeginn").value;
  }
  protected set dateControl(value: string) {
    this.formGroup.get("intervallbeginn").setValue(value);
  }
}
