<div class="overlay-inner-wrapper" id="zaehler-verwaltung">
  <header class="header-2 scroll-x">
    <div class="branding">
      <span class="title">
        {{ "Zähler konfigurieren" | mrTranslate }}
      </span>
    </div>
    <div class="header-actions">
      <div class="clr-form-horizontal">
        <!-- Subscribes onViewInit, completes and unsubscribes after HttpResponse or onDestroy -->
        <clr-select-container *ngIf="objekte$ | async as objekte">
          <label style="width: auto; padding: 0 2px;" for="objekt">
            <clr-icon *ngIf="objekte[otypid]?.symbol as symbol" style="color: #000;" class="customIcon"
              [attr.shape]="'objekttyp' + symbol" size="22"></clr-icon>
          </label>
          <select clrSelect name="objekt" class="cur-color"
            [(ngModel)]="otypid" [ngModelOptions]="{standalone: true}"
            (ngModelChange)="otypidSubject$.next()">
            <option *ngFor="let typ of objekte | keyvalue" [value]="typ.key">
              {{ typ.value.bezeichnung }}
            </option>
          </select>
        </clr-select-container>
      </div>
      <div class="header-nav">
        <a class="nav-link nav-icon close" href="javascript:void(0);"
          (click)="close()">
          <clr-icon shape="times" size="22" [title]="'Schliessen' | mrTranslate"></clr-icon>
        </a>
      </div>
    </div>
  </header>
  <div class="w-100-p padding-12-b scroll-y h-60 clr-row nxm">
    <ng-template #loading>
      <clr-spinner class="centred"></clr-spinner>
    </ng-template>
    <!-- Subscribes on onViewInit, unsubscribes onDestroy -->
    <ng-container *ngIf="zaehler_names$ | async as table">
      <div *ngIf="otypid" [class]="selectedName ? 'clr-col-xl-6' : 'clr-col-xl-12'" class="min-h-min-c">
        <div id="namesContainer">
          <div class="card item-table zählerItemWrapper">
            <div class="card-header np clr-row nxm">
              <div class="clr-col-auto margin-12-t margin-12-l nxp">
                {{ "Zählerprogramm" | mrTranslate }}
              </div>
            </div>
            <div class="card-block nyp d-f" #zNamesContainer>
              <clr-datagrid *ngIf="table.columns; else loading"
                class="datagrid-compact h-100 w-100-p"
                [(clrDgSingleSelected)]="selectedName" [clrDgRowSelection]="true"
                (clrDgSingleSelectedChange)="selectedNameSubject$.next()">
                <clr-dg-column *ngFor="let column of table.columns" [clrDgField]="column.id">
                  <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns}">
                    {{ column.id | mrTranslate }}</ng-container>
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let row of table.rows; trackBy: trackByID" [clrDgItem]="row"
                  id="i-{{row.ID}}">
                  <clr-dg-cell class="name-cell" *ngFor="let cell of table.columns"
                  [innerHTML]="row[cell.id] | tablePrettyPrint">
                  </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>{{table.rows.length}} {{"Zeilen" | mrTranslate}}</clr-dg-footer>
              </clr-datagrid>
            </div>
            <div class="card-footer">
              <div class="btn-group btn-primary btn-icon margin-0-t">
                <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
                  (click)="addItem('name')">
                  <clr-icon shape="file"></clr-icon>
                </button>
                <button class="btn btn-icon margin-12-l" [title]="'Bearbeiten' | mrTranslate"
                  (click)="editItem('name')" [disabled]="!selectedName">
                  <clr-icon shape="edit"></clr-icon>
                </button>
                <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
                  (click)="deleteItem('name')" [disabled]="!selectedName">
                  <clr-icon shape="times"></clr-icon>
                </button>
                <button class="btn btn-icon btn-success margin-12-l" [title]="'Kopie erstellen' | mrTranslate"
                  (click)="copyItem('name')" [disabled]="!selectedName">
                  <clr-icon shape="copy"></clr-icon>
                </button>
              </div>
              <button *ngIf="true" class="btn btn-icon" style="margin-left: auto;" [title]="'Beschreibung' | mrTranslate"
                (click)="openHelper()" [disabled]="!selectedName">
                <clr-icon shape="help"></clr-icon>
              </button>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="preview; context: { readonly: true }"></ng-container>
      </div>
    </ng-container>
    <!-- Subscribes onViewInit, unsubscribes onDestroy -->
    <ng-container *ngIf="zaehler_werte$ | async as table">
      <div *ngIf="selectedName?.ID" class="clr-col-xl-6 padding-12-t auto-height-xl-100" id="werteContainer">
        <div class="card item-table zählerItemWrapper">
          <div class="card-header np clr-row nxm">
            <div class="clr-col-auto margin-12-t margin-12-l nxp">
              {{ "Zählerwerte SA" | mrTranslate }}
            </div>
          </div>
          <div class="card-block nyp d-f" #zWerteContainer>
            <div class="clr-col-11">
              <clr-datagrid #wertGrid *ngIf="table.columns; else loading"
                class="datagrid-compact h-100 w-100-p" [clrDgRowSelection]="true"
                [(clrDgSelected)]="selectedWerte" (clrDgSelectedChange)="checkEnableUpDown(table.rows)">
                <clr-dg-column *ngFor="let column of table.columns"
                  [class.disabled]="(column.id | hideSRaktiv: selectedName) === true">
                  <ng-container
                    *clrDgHideableColumn="{
                      hidden:
                        column.id
                        | hideSRaktiv : selectedName
                        | hideIDColumns : ['szaehnid', 'i_sort', 'zeinheit']
                    }"
                  >
                    {{ column.id | werteColNames | mrTranslate }}
                  </ng-container>
                </clr-dg-column>
                <clr-dg-row
                  *clrDgItems="let row of table.rows; trackBy: trackByID"
                  [clrDgItem]="row"
                  id="i-{{ row.ID }}"
                  [class.headerRow]="row.ZType == ZType_Header"
                >
                  <ng-container *ngFor="let cell of table.columns">
                    <clr-dg-cell class="name-cell"
                      [innerHTML]="row | werteRowValues: cell.id | tablePrettyPrint" 
                      [class.disabled]="(cell.id | hideSRaktiv: selectedName) === true">
                    </clr-dg-cell>
                  </ng-container>
                </clr-dg-row>
                <clr-dg-footer>{{table.rows.length}} {{"Zeilen" | mrTranslate}}</clr-dg-footer>
              </clr-datagrid>
            </div>
            <div class="clr-col-1 padding-0-r move-buttons">
              <button class="btn btn-link btn-state btn-state-custom-size" 
                [disabled]="!enableMove.UP"
                (click)="wertSortSubject$.next(SORT_DIRECTION.UP)" [clrLoading]="moveUpDownState">
                <clr-icon shape="circle-arrow up"></clr-icon>
              </button>
              <button class="btn btn-link btn-state btn-state-custom-size"
                [disabled]="!enableMove.DOWN"
                (click)="wertSortSubject$.next(SORT_DIRECTION.DOWN)" [clrLoading]="moveUpDownState">
                <clr-icon shape="circle-arrow down"></clr-icon>
              </button>
            </div>    
          </div>
          <div class="card-footer">
            <div class="btn-group btn-primary btn-icon margin-0-t">
              <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
                (click)="addItem('wert')">
                <clr-icon shape="file"></clr-icon>
              </button>
              <button class="btn btn-icon margin-12-l"
                [title]="'Bearbeiten' | mrTranslate"
                (click)="editItem('wert'); wertIndex = table.rows.indexOf(singleSelectedWert)"
                [disabled]="!singleSelectedWert || selectedWerte.length > 1">
                <clr-icon shape="edit"></clr-icon>
              </button>
              <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
                (click)="deleteItem('wert')" [disabled]="!selectedWerte.length">
                <clr-icon shape="times"></clr-icon>
              </button>
            </div>
            <div class="btn-group btn-outline margin-0-t">
              <button *ngIf="!copiedWerte.length; else clear"
                class="btn btn-icon"
                (click)="copiedWerte = selectedWerte"
                [disabled]="!selectedWerte.length">
                <clr-icon shape="copy"></clr-icon>
                {{ 'Kopieren' | mrTranslate }}
              </button>
              <ng-template #clear>
                <button class="btn btn-icon" (click)="copiedWerte = []">
                  <clr-icon shape="copy"></clr-icon>
                  {{ 'Abbrechen' | mrTranslate }}
                </button>
              </ng-template>
    
              <button class="btn btn-icon btn-success"
                (click)="copyItem('wert')" [disabled]="!copiedWerte.length">
                <clr-icon shape="copy"></clr-icon>
                {{ 'Einfügen' | mrTranslate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <clr-modal [(clrModalOpen)]="isOpenModalZaehlerName" [clrModalClosable]="true">
    <h3 class="modal-title">{{ ( !selectedName?.ID 
      ? "Einen neuen Zähler erstellen"
      : "Den Zähler bearbeiten"
    ) | mrTranslate }}</h3>
    <div class="modal-body clr-row nxm">
      <form clrForm [formGroup]="nameGroupForm"
        #f="ngForm" (ngSubmit)="saveItem('name')"
        class="clr-col-11 width100" clrLayout="horizontal" [clrLabelSize]="4">
        <clr-input-container>
          <label>{{ "Code" | mrTranslate }}</label>
          <input clrInput type="text" formControlName="Code"
            [autofocus]="isOpenModalZaehlerName"
          />
          <clr-control-error *clrIfError="'required'">
            {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
          </clr-control-error>
          <clr-control-error *clrIfError="'in-Table'; error as err">
            {{ err | mrTranslate }}
          </clr-control-error>
        </clr-input-container>
  
        <clr-input-container>
          <label>{{ "Bezeichnung" | mrTranslate }}</label>
          <input clrInput type="text" formControlName="Bezeichnung" />
        </clr-input-container>
  
        <clr-toggle-container clrInline>
          <label>{{ "Toleranzen" | mrTranslate }}</label>
          <clr-toggle-wrapper>
            <input clrToggle formControlName="SR100" type="checkbox" />
            <label>{{ "SR 100 aktiv" | mrTranslate }}</label>
          </clr-toggle-wrapper>
          <clr-toggle-wrapper>
            <input clrToggle formControlName="SRG" type="checkbox" />
            <label>{{ "SRG aktiv" | mrTranslate }}</label>
          </clr-toggle-wrapper>
        </clr-toggle-container>
        
        <clr-select-container>
          <label>{{ 'Messpunktskizze' | mrTranslate }}</label>
          <select clrSelect formControlName="Bild">
            <option [value]="null">{{ "Keine" | mrTranslate }}</option>
            <option *ngFor="let skizze of zNames_Skizzen_Map$ | async | keyvalue" [value]="skizze.key">{{ skizze.key }}</option>
          </select>
        </clr-select-container>
      </form>
        
      <!-- <ng-container *ngIf="isOpenModalZaehlerName">
      </ng-container> -->
      <ng-container *ngTemplateOutlet="preview; context: { readonly: false }">
      </ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary"
        [disabled]="nameGroupForm.invalid" (click)="f.ngSubmit.emit()">
        {{'OK' | mrTranslate}}
      </button>
      <button type="button" class="btn" (click)="isOpenModalZaehlerName = false">
        {{ "Abbrechen" | mrTranslate }}
      </button>
    </div>
  </clr-modal>
  
  <ng-container [w-media-query]="{min: 768}" #query="query">
    <!-- Subscribes onViewInit, unsubscribes onDestroy -->
    <clr-modal
      *ngIf="meh$ | async as meh"
      [(clrModalOpen)]="isOpenModalZaehlerWert"
      [clrModalClosable]="true"
      [clrModalSize]="query.matches ? 'lg' : 'full-screen'"
    >
      <div class="modal-title">
        <h3> {{ singleSelectedWert?.ID
          ? "Den Wert bearbeiten"
          : "Einen neuen Wert erstellen" | mrTranslate }}
        </h3>
      </div>
      <div class="modal-body grid" id="grid-container">
        <form id="wert-form" #wertForm clrForm #fW="ngForm" [formGroup]="wertGroupForm"
          (ngSubmit)="saveItem('wert')" [clrLabelSize]="4"
          class="width100 grid no-margin" clrLayout="horizontal">
          <div class="span-row" role="grid-row-placeholder" aria-hidden="true"></div>
          <div *ngIf="singleSelectedWert?.ID && zWerte_Rows$ | async as list"
          id="nav-row" class="grid">
            <button *ngIf="wertIndex != 0"
              type="button"
              class="btn btn-link np nm text-right" app-area="prev"
              [disabled]="wertState != LOADING_STATE.DEFAULT"
              (click)="nextWert(-1)"
              [title]="'vorherige Wert öffnen' | mrTranslate">
              {{ (list | at: (wertIndex - 1))?.Code }}
            </button>
            <button [clrLoading]="wertState"
              type="button"
              class="btn np nm btn-link btn-state btn-state-custom-size" app-area="prev-btn"
              [class.success]="wertState == LOADING_STATE.SUCCESS"
              [disabled]="wertIndex == 0"
              (click)="nextWert(-1)"
              [title]="'vorherige Wert öffnen' | mrTranslate">
              <clr-icon
                [attr.shape]="wertState == LOADING_STATE.ERROR && wertGroupForm.invalid 
                  ? 'error' 
                  : 'circle-arrow left'"
                [class.invalidRed]="wertState == LOADING_STATE.ERROR && wertGroupForm.invalid">
              </clr-icon>
            </button>
            <button [clrLoading]="wertState"
              type="button"
              class="btn np nm btn-link btn-state btn-state-custom-size" app-area="next-btn"
              [class.success]="wertState == LOADING_STATE.SUCCESS"
              [disabled]="wertIndex == list.length - 1"
              (click)="nextWert(1)"
              [title]="'nächste Wert öffnen' | mrTranslate">
              <clr-icon
                [attr.shape]="wertState == LOADING_STATE.ERROR && wertGroupForm.invalid 
                  ? 'error' 
                  : 'circle-arrow right'"
                [class.invalidRed]="wertState == LOADING_STATE.ERROR && wertGroupForm.invalid">
              </clr-icon>
            </button>
            <button *ngIf="wertIndex != list.length - 1"
              type="button"
              class="btn btn-link np nm text-left" app-area="next"
              [disabled]="wertState != LOADING_STATE.DEFAULT"
              (click)="nextWert(1)"
              [title]="'nächste Wert öffnen' | mrTranslate">
              {{ (list | at: (wertIndex + 1))?.Code }}
            </button>
          </div>
          <clr-input-container class="span-2" app-area="code">
            <label>{{ "Code" | mrTranslate }}</label>
            <input clrInput type="text" formControlName="Code"
              [autofocus]="isOpenModalZaehlerWert && singleSelectedWert" />
            <clr-control-error *clrIfError="'required'">
              {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
            </clr-control-error>
            <clr-control-error *clrIfError="'in-Table'; error as err">
              {{ err | mrTranslate }}
            </clr-control-error>
          </clr-input-container>
          <clr-input-container class="span-2" app-area="bezeichnung">
            <label>{{ "Bezeichnung" | mrTranslate }}</label>
            <input clrInput type="text" formControlName="Bezeichnung" />
          </clr-input-container>
          <clr-toggle-container class="span-2" app-area="is-header">
            <clr-toggle-wrapper>
              <input clrToggle formControlName="isHeader" type="checkbox" />
              <label>{{ "Überschrift" | mrTranslate }}</label>
            </clr-toggle-wrapper>
          </clr-toggle-container>
          <ng-container *ngIf="!wertGroupForm.get('isHeader').value">
            <clr-select-container class="span-2" app-area="z-art">
              <label>{{ "Zählerart" | mrTranslate }}</label>
              <select clrSelect formControlName="ZArt">
                <option *ngFor="let typ of ZArt_Map | keyvalue: noSort" [value]="typ.key">
                  {{ typ.value }}
                </option>
              </select>
            </clr-select-container>
    
            <ng-container *ngIf="wertGroupForm.get('ZArt').value as zArt">
              <ng-container *ngIf="ZArt_Options[+zArt] as mode">
                <ng-container *ngTemplateOutlet="input; context:
                  { label: 'Soll-Wert',
                    control: 'Soll',
                    area: 'soll',
                    text: mode.soll }">
                </ng-container>
                <ng-container *ngIf="mode.minus || mode.plus as text">
                  <!-- Toleranzen -->
                  <div class="grid span-row" id="tol-block">
                    <!-- SR 100 -->
                    <ng-container *ngIf="selectedName?.SR100">
                      <clr-toggle-container app-area="sr100-aktiv" class="span-4">
                        <clr-toggle-wrapper class="min-w-max-c">
                          <input clrToggle formControlName="aktivSR100" type="checkbox" />
                          <label>{{ "aktiv SR100 Toleranz" | mrTranslate }}</label>
                        </clr-toggle-wrapper>
                      </clr-toggle-container>
                      <ng-container *ngIf="wertGroupForm.get('aktivSR100').value">
                        <ng-container *ngIf="mode.minus">
                          <ng-container *ngTemplateOutlet="input; context:
                            {
                              label: 'SR100 -',
                              control: 'SR100minus',
                              area: 'sr100-minus',
                              text }">
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="mode.plus">
                          <ng-container *ngTemplateOutlet="input; context:
                            {
                              label: 'SR100 +',
                              control: 'SR100plus',
                              area: 'sr100-plus',
                              text }">
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <!-- SR lim -->
                    <ng-container *ngIf="true"> <!-- sonst css sieht es als ersten Block -->
                      <clr-toggle-container app-area="srlim-aktiv" class="span-4">
                        <clr-toggle-wrapper class="min-w-max-c">
                          <input clrToggle formControlName="aktivSRlimm" type="checkbox" />
                          <label>{{ "aktiv SR lim Toleranz" | mrTranslate }}</label>
                        </clr-toggle-wrapper>
                      </clr-toggle-container>
                      <ng-container *ngIf="wertGroupForm.get('aktivSRlimm').value">
                        <ng-container *ngIf="mode.minus">
                          <ng-container *ngTemplateOutlet="input; context:
                            {
                              label: 'SRlim -',
                              control: 'SRlimminus',
                              area: 'srlim-minus',
                              text }">
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="mode.plus">
                          <ng-container *ngTemplateOutlet="input; context:
                            {
                              label: 'SRlim +',
                              control: 'SRlimplus',
                              area: 'srlim-plus',
                              text }">
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <!-- SRG -->
                    <ng-container *ngIf="selectedName?.SRG">
                      <clr-toggle-container app-area="srg-aktiv" class="span-4">
                        <clr-toggle-wrapper class="min-w-max-c">
                          <input clrToggle formControlName="aktivSRG" type="checkbox" />
                          <label>{{ "aktiv SRG Toleranz" | mrTranslate }}</label>
                        </clr-toggle-wrapper>
                      </clr-toggle-container>
                      <ng-container *ngIf="wertGroupForm.get('aktivSRG').value">
                        <ng-container *ngIf="mode.minus">
                          <ng-container *ngTemplateOutlet="input; context:
                            {
                              label: 'SRG -',
                              control: 'SRGminus',
                              area: 'srg-minus',
                              text }">
                          </ng-container>
                        </ng-container>
                        <ng-container *ngIf="mode.plus">
                          <ng-container *ngTemplateOutlet="input; context:
                            {
                              label: 'SRG +',
                              control: 'SRGplus',
                              area: 'srg-plus',
                              text }">
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                  <clr-select-container class="span-2" app-area="einheit">
                    <label class="d-f row w-100-p">
                      <span class="clr-col nxp ellipsis">
                        {{ "Einheit" | mrTranslate }}</span>
                      <button class="btn btn-sm btn-icon btn-outline clr-col-auto"
                        style="margin: -7px 0; float: inline-end;" tabindex="-1"
                        type="button" [title]="'Tabelle verwalten' | mrTranslate"
                        (click)="initializeEinheiten = true; isOpenModalZaehlerEinheit = true;">
                        <clr-icon shape="pencil"></clr-icon>
                      </button>
                    </label>
                    <!-- button für Bearbeiten -->
                    <select clrSelect formControlName="ZEinheit" name="ZEinheit">
                      <option *ngFor="let einheit of meh" [value]="einheit.ID">
                        {{ einheit.Code }}
                      </option>
                    </select>
                    <clr-control-error *clrIfError="'required'">
                      {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                    </clr-control-error>  
                  </clr-select-container>
                  <ng-template #slider let-control="control">
                    <ngx-slider [formControlName]="control" [options]="{ floor: 0, ceil: 4, animate: false }"></ngx-slider>
                  </ng-template>
                  <div class="span-2" app-area="genau-sliders" id="genauSliders">
                    <ng-container *ngTemplateOutlet="query.matches ? dropdown : inline"></ng-container>
                  </div>
                  <ng-template #dropdown>
                    <clr-dropdown [clrCloseMenuOnItemClick]="false">
                      <button type="button" class="btn btn-icon btn-link" clrDropdownTrigger>
                        <clr-icon shape="bullseye" solid="true" style="color: brown;">
                        </clr-icon>
                        <clr-icon shape="angle right"></clr-icon>
                      </button>
                      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right"
                      style="min-width: 30vw;">
                        <span class="dropdown-header" aria-hidden="true">
                          {{ "Genauigkeiten" | mrTranslate }}
                        </span>
                        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                        <span class="dropdown-header" aria-hidden="true">
                          {{ "Soll" | mrTranslate }}
                        </span>
                        <button clrDropdownItem type="button">
                          <ng-container *ngTemplateOutlet="slider; context: { control: 'SGenau' }"></ng-container>
                        </button>
                        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                        <span class="dropdown-header" aria-hidden="true">
                          {{ "Toleranzen" | mrTranslate }}
                        </span>
                        <button clrDropdownItem type="button">
                          <ng-container *ngTemplateOutlet="slider; context: { control: 'TGenau' }"></ng-container>
                        </button>
                      </clr-dropdown-menu>
                    </clr-dropdown>
                  </ng-template>
                  <ng-template #inline>
                    <div class="clr-row clr-form-control">
                      <label class="clr-control-label clr-col-4 nym">
                        {{ "Genauigkeit" | mrTranslate }}
                      </label>
                      <div class="clr-col">
                        <span class="clr-control-label"> {{ "Soll" | mrTranslate }}</span>
                        <ng-container *ngTemplateOutlet="slider; context: { control: 'SGenau' }"></ng-container>
                      </div>
                      <div class="clr-col">
                        <span class="clr-control-label"> {{ "Tol" | mrTranslate }}</span>
                        <ng-container *ngTemplateOutlet="slider; context: { control: 'TGenau' }"></ng-container>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #input let-label="label" let-control="control" let-text="text" let-area="area">
              <ng-container *ngTemplateOutlet="text != 'Num' ? textInput : numberInput"></ng-container>
              <ng-template #textInput>
                <clr-input-container class="span-2" [attr.app-area]="area">
                  <label>{{ label | mrTranslate }}</label>
                  <input clrInput [value]="text" type="text" readonly />
                </clr-input-container>
              </ng-template>
    
              <ng-template #numberInput>
                <clr-input-container class="span-2" [attr.app-area]="area">
                  <label>{{ label | mrTranslate }}</label>
                  <input clrInput [formControlName]="control" type="number" />
                  <clr-control-error *clrIfError="'required'">
                    {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                  </clr-control-error>
                </clr-input-container>
              </ng-template>
            </ng-template>
          </ng-container>
        </form>
      </div>
      <div class="modal-footer">
        <!-- [disabled]="wertGroupForm.invalid" -->
        <button type="button" class="btn btn-primary"
         (click)="fW.ngSubmit.emit()">
          {{'OK' | mrTranslate}}
        </button>
        <button type="button" class="btn" (click)="isOpenModalZaehlerWert = false">
          {{ "Abbrechen" | mrTranslate }}
        </button>
      </div>
    </clr-modal>
  </ng-container>
</div>

<ng-template #preview let-readonly="readonly">
  <app-preview-thumbnails
    [acceptTypes]="'.png'"
    [maxAmount]="1"
    [readonly]="readonly"
    [showCamera]="false"
    [isSkizze]="true"
    (lastUpload)="setBildInForm($event)"
    (clearedThumb)="setBildInForm(null)">
  </app-preview-thumbnails>
</ng-template>

<app-hilfe-beschreibung-modal
  (onSave)="saveHelper($event)"
></app-hilfe-beschreibung-modal>

<app-zaehler-einheiten *ngIf="initializeEinheiten" 
  (updated)="mehRefresh$.next()"
  [otypid]="otypid" [(opened)]="isOpenModalZaehlerEinheit">
</app-zaehler-einheiten>
