import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, EventEmitter, inject, Input, Output, signal, untracked, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormsModule, NgForm } from '@angular/forms';
import { ClarityModule, ClrForm } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { filter, firstValueFrom, merge, sample, Subject, switchMap } from 'rxjs';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { logState, precedeWith } from 'src/app/misc/operators';
import { Table } from 'src/app/models/ui/table';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { ZählerEinheit } from '../zaehler.types';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';

@Component({
  selector: 'app-zaehler-einheiten',
  templateUrl: './zaehler-einheiten.component.html',
  styleUrls: ['./zaehler-einheiten.component.scss'],
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, HideIDColumnsPipe, TablePrettyPrintPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class ZaehlerEinheitenComponent {
  @Output() openedChange = new EventEmitter<boolean>();
  @Input() public set opened(value: boolean) {
    this.isOpen.set(value);
  }

  protected readonly isOpen = signal<boolean>(undefined);
  readonly setClosedEffect = effect(() => {
    if (this.isOpen() === false)
      this.openedChange.next(false);
  });

  private readonly isNowOpened$ = toObservable(this.isOpen).pipe(filter(opened => opened));

  @Input() public set otypid(value: number) {
    this._otypid = value;
    this.otypidSubject$.next();
  }
  public get otypid(): number {
    return this._otypid;
  }
  private _otypid: number;

  private readonly otypidSubject$ = new Subject<void>();

  @Output('updated') readonly mehRefresh$ = new Subject<void>();

  private readonly apiService = inject(APIService);
  private readonly toastr = inject(ToastrService);
  private readonly mrTranslate = inject(MrTranslatePipe);
  protected readonly trackByID = (_: number, row: ZählerEinheit) => row.ID;

  protected readonly meh = toSignal<Table<ZählerEinheit>>(
    merge(
      /* emit last otypidSubject at the time isNowOpened has new value */
      this.otypidSubject$.pipe(sample(this.isNowOpened$)),
      this.mehRefresh$
    ).pipe(
      switchMap(() => this.apiService.getZaehlerWerteEinheiten(this.otypid)),
      logState('meh'),
      precedeWith({} as Table<ZählerEinheit>),
      takeUntilDestroyed()
    )
  );

  protected readonly selectedRow = signal<ZählerEinheit>(undefined);

  readonly setValuesEffect = effect(() => {
    const selected = this.selectedRow();
    untracked(() => {
      console.log('selected row: ', selected);
      this.ngForm.resetForm(selected);
    });
  });

  @ViewChild(ClrForm, { static: true })
  private clrForm: ClrForm;
  @ViewChild(NgForm, { static: true })
  private ngForm: NgForm;

  protected async saveIfValid() {
    if (!this.ngForm.valid) {
      this.clrForm.markAsTouched();
      return;
    }

    const { success, error, ID } = await firstValueFrom(
      this.apiService.setZaehlerWerteEinheit(
        this.otypid,
        { ID: this.selectedRow()?.ID, ...this.ngForm.value }
      )
    );

    if (success) {
      this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
      this.mehRefresh$.next();
      this.selectedRow.set({ ID } as ZählerEinheit);
    } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
  }

  protected async deleteRow() {
    const ID = this.selectedRow()?.ID;
    if (!ID) return;
    const text = 'Sind Sie sicher, dass Sie diese Zeile löschen wollen?';
    if (!confirm(this.mrTranslate.transform(text))) return;

    const { success, error } = await firstValueFrom(
      this.apiService.deleteZahlerItem('meh', ID)
    );
    if (success) {
      this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));
      this.selectedRow.set(null);
      this.mehRefresh$.next();
    } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
  }
}
