<clr-modal [(clrModalOpen)]="open" [clrModalClosable]="true">
  <h3 class="modal-title">{{'Herstelldaten' | mrTranslate}}</h3>
  <div class="modal-body">
    <form [formGroup]="herstellDatenForm" #f="ngForm" (ngSubmit)="saveIfValid()"
      clrForm clrLayout="horizontal" class="width100 padding-24-r" clrLabelSize="5">
      <clr-input-container class="mt-0">
        <label>{{ "Hersteller" | mrTranslate }}</label>
        <input clrInput formControlName="Hersteller" name="Hersteller" [autofocus]="open" type="text" />
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-input-container>
      <clr-date-container>
        <label> {{ "Herstelldatum" | mrTranslate }} </label>
        <input clrDate name="Herstelldatum" autocomplete="off" type="date"
          [ngModel]="herstellDatenForm.controls['Herstelldatum'].value | localeDate"
          (ngModelChange)="herstellDatenForm.controls['Herstelldatum'].setValue(localeDate.tosql($event))"
          [ngModelOptions]="{updateOn: 'blur', standalone: true}"
          required />
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-date-container>
      <clr-input-container>
        <label>{{ "Einbaufirma" | mrTranslate }}</label>
        <input clrInput formControlName="Einbaufirma" name="Einbaufirma" type="text" />
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-input-container>
      <clr-date-container>
        <label> {{ "Einbaudatum" | mrTranslate }} </label>
        <input clrDate name="Einbaudatum" autocomplete="off" type="date"
          [ngModel]="herstellDatenForm.controls['Einbaudatum'].value | localeDate"
          (ngModelChange)="herstellDatenForm.controls['Einbaudatum'].setValue(localeDate.tosql($event))"
          [ngModelOptions]="{updateOn: 'blur', standalone: true}"
          required />
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-date-container>
      <clr-input-container>
        <label>{{ "Erneuerungsjahr" | mrTranslate }}</label>
        <input clrInput formControlName="Erneuerungsjahr" name="Erneuerungsjahr"
          type="number" [step]="1" min="1800"/>
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
        <clr-control-error *clrIfError="'min'; error as err">
          {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
        </clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>{{ "Nutzungsdauer" | mrTranslate }}</label>
        <input clrInput formControlName="Nutzungsdauer" name="Nutzungsdauer"
          type="number" [step]="1" min="1"/>
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
        <clr-control-error *clrIfError="'min'; error as err">
          {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
        </clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>{{ "Dauer der Gewährleistung" | mrTranslate }}</label>
        <input clrInput formControlName="DauerGewaehrleistung" name="DauerGewaehrleistung"
          type="number" [step]="1" min="0"/>
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
        <clr-control-error *clrIfError="'min'; error as err">
          {{ "Der Wert ist kleiner als" | mrTranslate }} {{err.min}}
        </clr-control-error>
      </clr-input-container>
      <clr-select-container>
        <label > {{ "Einheit der Gewährleistung" | mrTranslate }} </label>
        <select clrSelect formControlName="EinheitGewaehrleistung" name="EinheitGewaehrleistung">
          <option value="Tag">{{ "Tag" | mrTranslate }}</option>
          <option value="Monat">{{ "Monat" | mrTranslate }}</option>
          <option value="Jahr">{{ "Jahr" | mrTranslate }}</option>
        </select>
      </clr-select-container>
      <clr-date-container>
        <label>{{ "Ende der Gewährleistung" | mrTranslate }}</label>
        <input clrDate name="EndeGewaehrleistung" autocomplete="off" type="date"
          [ngModel]="herstellDatenForm.controls['EndeGewaehrleistung'].value | localeDate"
          (ngModelChange)="herstellDatenForm.controls['EndeGewaehrleistung'].setValue(localeDate.tosql($event))"
          [ngModelOptions]="{updateOn: 'blur', standalone: true}"
          required />
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-date-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
      [disabled]="disable"
      (click)="f.ngSubmit.emit()">
    {{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="open = false">
      {{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
