<div class="overlay-inner-wrapper">
  <header class="header-2">
    <div class="branding">
        <span class="title">{{'Mangel-/Bauformverteilung ' | mrTranslate}}{{sBez}}</span>
    </div>
    <div class="header-actions">
      <a href="javascript://" class="nav-link nav-icon">
        <clr-icon  shape="window-close" (click)="close()"></clr-icon >
      </a>
    </div>
  </header>
  <div style="height: 10px;"></div>
  <canvas #canvas> </canvas>

  <div class="clr-row nxm" style="padding-left:10px;">
    <h4>{{'Legende ' | mrTranslate}}:</h4>
  </div>
  <input type="color" style="display:none;" [(ngModel)]="color"
  (ngModelChange)="setColorFromPicker($event)" #colorIn>

  <div class="clr-row nxm" style="padding-left:10px; padding-top:5px;" *ngFor="let spez of (spezblocks ? spezblocks : [])">



      <div class="clr-col-md-1">
        {{spez.key}}
      </div>
      <div class="clr-col-md-8">
        <div class="content-area">
          <span class="label cursor-pointer" (click)="changeColor(spez.key, spezitems.wert, spezitems.color, colorIn)" *ngFor="let spezitems of spez.items">
            <ng-container *ngIf="spezitems.wert">
              <span class="text">{{spezitems.wert}}</span>
              <span class="badge" [style.background-color]="spezitems.color"></span>
            </ng-container>

          </span>
        </div>
      </div>

    </div>

</div>

