<div id="gleisMessDatenWrapper">
  <form clrForm>
    <clr-accordion clrAccordionMultiPanel="true">
      <ng-container *ngFor="let mess_filter_check of mess_filter_Arr; let i = index">
        <clr-accordion-panel [(clrAccordionPanelOpen)]="mess_filter_check.value" class="clr-row nxm margin-10-t">
          <clr-accordion-title class="w-100-p" style="display: flex; justify-content: space-between;">
            <clr-checkbox-container style="pointer-events: none;" class="margin-0-t padding-6-l">
              <clr-checkbox-wrapper>
                <label> {{ mess_keys[i] | tablePrettyPrint | mrTranslate }} </label>
                <input type="checkbox" [(ngModel)]="mess_filter_check.value" [ngModelOptions]="{standalone: true}" clrCheckbox />
              </clr-checkbox-wrapper>
            </clr-checkbox-container>
          </clr-accordion-title>

          <clr-accordion-content *clrIfExpanded>
            <div class="margin-36-b margin-12-t" *ngIf="mess_filter_check.value">
              <ngx-slider [class.invert-slider]="mess_comp[i].type == 'außer'" [(value)]="lowValues[i]"
                [(highValue)]="highValues[i]" [options]="options[i]"></ngx-slider>
            </div>
          </clr-accordion-content>
        </clr-accordion-panel>
      </ng-container>
    </clr-accordion>
  </form>

  <br />

  <button type="button" class="btn btn-sm btn-primary" (click)="setFilters()">{{'Filter übernehmen' |
    mrTranslate}}</button>
  <button *ngIf="_mess_filters.length" style="min-width: 1.5rem;" type="button" class="btn btn-danger btn-sm"
    (click)="deleteMessFilter()">
    <clr-icon shape="times"></clr-icon>
  </button>

  <div *ngIf=" _mess_filters.length">
    <ng-container *ngFor="let filt of _mess_filters">
      <span *ngIf="filt.key && filt.comp && filt.value != null" class="label">{{filt.concat ? (filt.concat == 'AND' ?
        'UND ' :
        'ODER ') : ''}}{{filt.key}} {{filt.comp}}
        {{filt.value}}</span>
    </ng-container>
  </div>

  <br />
  <div class="progress loop" style="margin: auto; margin-top: 11px; max-width: 95%; height: 4px;" *ngIf="showProgress">
    <progress></progress></div>
  <br />
</div>