<div *ngIf="previewThumbnails?.length" id="mediaSlideShowWrapper"
  [style.height]="height">
  <div [@slideAnimation]="currentIndex" class="slider"
    [style.max-height]="previewThumbnails?.length > 1 ? 'calc(100% - 36px)' : '100%'">
    <ng-container *ngFor="let thumb of previewThumbnails; let i = index">
      <img *ngIf="currentThumb == thumb" [src]="thumb" draggable="false"
        class="slide auto cursor-pointer" [class.invertWhenDark]="invert"
        (swiperight)="moveGallery('right')"
        (swipeleft)="moveGallery('left')"
       (click)="imagezoom(currentThumb)"
      >
    </ng-container>
    <button *ngIf="previewThumbnails?.length > 1"
      class="btn btn-icon btn-link arrow prev" (click)="moveGallery('left')">
      <clr-icon shape="angle left" size="44"></clr-icon>
    </button>
    <button *ngIf="previewThumbnails?.length > 1"
      class="btn btn-icon btn-link arrow next" (click)="moveGallery('right')">
      <clr-icon shape="angle right" size="44"></clr-icon>
    </button>
  </div>
  <div *ngIf="previewThumbnails?.length > 1"
    class="d-f row center mw-100p padding-12-tb">
    <clr-icon
      *ngFor="let thumb of previewThumbnails; let i = index"
      shape="circle" [class.is-solid]="currentThumb == thumb" size="12"
      (click)="changeIndexTo(i)" class="cursor-pointer margin-3-lr">
    </clr-icon>
  </div>
</div>

<app-mediaviewmodal *ngIf="imageMedia"
  (onClose)="imageMedia = undefined"
  [media]="imageMedia">
</app-mediaviewmodal>
