import Dexie, { Table } from 'dexie';
import dayjs from 'dayjs';

import { select } from 'underscore';


export interface OSTAMM {
  ID?: number, OSTAMMID: number, OTYPID: number, TYP?: string, OPRUEF?: ORPUEF, BILD?: OPBILD, Bezirk?: string, Stellwerk?: string, Eigentuemer?: string, Ort?: string, Kostenstelle?: string, Startpunkt?: string, Endpunkt?: string,
  StartMeter: string, EndMeter: string, Bezeichnung?: string, Laenge?: string, Nutzlaenge?: string, Anlagenklasse?: string, PlanNr?: string, FremdID?: string,
  Messdatei?: string, Gesperrt?: string, DelStatus: number, Messfile?: string, Intervall_P?: string, Intervall_B?: string, Bemerkung?: string, Skizze?: string, MessRegBezirk?: string,
  Dokumente?: string, Richtung?: string, Befahrung?: string, GPS_Lat?: string, GPS_Lon?: string, LPDatum?: string, LPID?: number, SperrID?: string, SperrStatus?: number, MinFK?: string,
  cTimeStamp?: string, Verantwortlich?: string, ParentStammID?: string, UserName: string, Status: string, KNField1?: string, KNField2?: string, KNField3?: string, KNField4?: string,
  KNField5?: string, KNField6?: string, KNField7?: string, KNField8?: string, KNField9?: string, KNField10?: string, KNField11?: string, KNField12?: string, KNField13?: string, KNField14?: string,
  KNField15?: string, KNField16?: string, KNField17?: string, KNField18?: string, KNField19?: string, KNField20?: string, ZTYP: string, KAVaktiv?: string, LFaktiv?: number, LFmin?: string, LFID?: string,
  MessIdent?: string, Intervall_M?: string, Intervall_MB?: string, LPMDatum?: string, ZstTyp?: string, LNPID?: number, LNPDatum?: string, GPS_Lat1?: string, GPS_Lon1?: string, MessRegID?: string, DBStrNr?: string,
  typ?,typ_id?,icon?,strBez?,stmid?,inspektionID?,OPRUEFID?,wID?,letzteDatum?,bemerkung?,mID?,maske?,pID?,verantwortlich?,intervall?,einheit?,intervallbeginn?,typ_icon?, diffFromNow?, nextDatum?, LF?, SP?; SPID?, Rep?
}
export interface ORPUEF {
  ID?: number, OPRUEFID?: number, OSTAMMID: number, P_Datum?: string, P_IntStatus?: string, P_DelStatus?: number, P_Bemerkung?: string, P_MUser?: string, P_MTol?: string, P_MDatum?: string,
  P_MinFK?: string, UserName, Status, BWPZN?: number, M_Mess_Start?: string, M_Device?: string, P_KeinePruefung?: string, P_KeinePrfGrund?: string, B_BUEBildPfade?: string, KPRTYPID?: number, P_ALV_Checked?: string,
  P_Kunde_U?: string, P_Pruefer_U?: string, P_Kunde?: string, P_Vorlage?: string,
  KAV00?, KAV01?, KAV02?, KAV03?, KAV04?, KAV05?, KAV06?, KAV07?, L_Mangel?, L_MangelFK1?, L_MangelFK2?, L_MangelFK3?, L_MangelFK4?, L_MangelCFK1?, L_MangelCFK2?, L_MangelCFK3?, L_MangelCFK4?, P_ZUser?,
  P_ZDatum?, M_Mess_Drehen?, P_Pruefer?, L_MangelFK5?, L_MangelFK6?, L_MangelFK7?, L_MangelFK8?, L_MangelFK9?, L_MangelCFK5?, L_MangelCFK6?, L_MangelCFK7?, L_MangelCFK8?, L_MangelCFK9?, P_ALV_CheckDate?,
  P_ALV_Name?, P_ALV_Bemerkung?, P_ALV_Status?, P_EBL_Checked?, P_EBL_CheckDate?, P_EBL_Name?, P_EBL_Bemerkung?, P_EBL_Status?
}

export interface OHERST{
  ID?: number, OSPEZID?: number, Hersteller?, Einbaudatum?, Einbaufirma?, LageplanNr?, VerlegeplanNr?, DauerGewaehrleistung?, EinheitGewaehrleistung?, EndeGewaehrleistung?, UserName:string, Status, Nutzungsdauer?, Erneuerungsjahr?, Herstelldatum?
}

export interface SOTYP {
  ID?: number, Name: string, Bezeichnung: string, Typ: number, SysIcon: number, Ersteller: string, Erstellt: string, DelStatus: number, UserName: string, Status: string, ParentOTypID: number, ParentID: number
}

export interface SXMGR {
  ID?: number, Typ: string, AnlagenobjektID: number, BenutzerGruppeID: number
}

export interface SXMREP {
  ID?: number, OSTAMMID: number, REPID: number, UserName: string, Status
}

export interface SXMKP {
  ID?: number, OSTAMMID: number, KPRUVID?: number, IVAL_HP?: number, IVAL_Beginn_HP?: string, IVAL_MS?: number, IVAL_Beginn_MS?: string, ZAEID_HP?: number, UserName: string, Status: string
}
export interface KPRUV {
  ID?: number, Bezeichnung?:string, Base_IVAL_HP?: number,Base_IVAL_MS?: number, UserName: string, Status: string
}

export interface KBNGR {
  ID?: number, Bezeichnung: string, Rechte: string, UserName: string, Status: string, DelStatus
}

export interface OSPEZ {
  ID?: number, OSPEZID: number, OSTAMMID?: number, OKOMPID?: number, s_StartMeter?: number, s_EndMeter?: number,
  strValue01?, strValue02?, strValue03?, strValue04?, strValue05?, strValue06?, strValue07?, strValue08?, strValue09?, strValue10?, strValue11?, strValue12?, strValue13?, strValue14?, strValue15?, strValue16?, strValue17?, strValue18?, strValue19?, strValue20?, strValue21?, strValue22?, strValue23?, strValue24?, strValue25?,
  strValue26?, strValue27?, strValue28?, strValue29?, strValue30?, strValue31?, strValue32?, strValue33?, strValue34?, strValue35?, strValue36?, strValue37?, strValue38?, strValue39?, strValue40?, strValue41?, strValue42?, strValue43?, strValue44?, strValue45?, strValue46?, strValue47?, strValue48?, strValue49?, strValue50?,
  intValue01?, intValue02?, intValue03?, intValue04?, intValue05?, intValue06?, intValue07?, intValue08?, intValue09?, intValue10?, intValue11?, intValue12?, intValue13?, intValue14?, intValue15?,
  dblValue01?, dblValue02?, dblValue03?, dblValue04?, dblValue05?, dblValue06?, dblValue07?, dblValue08?, dblValue09?, dblValue10?, dblValue11?, dblValue12?, dblValue13?, dblValue14?, dblValue15?,
  chkValue01?, chkValue02?, chkValue03?, chkValue04?, chkValue05?, chkValue06?, chkValue07?, chkValue08?, chkValue09?, chkValue10?, chkValue11?, chkValue12?, chkValue13?, chkValue14?, chkValue15?,
  dateValue01?, dateValue02?, dateValue03?, dateValue04?, dateValue05?, dateValue06?, dateValue07?, dateValue08?, dateValue09?, dateValue10?, dateValue11?, dateValue12?, dateValue13?, dateValue14?, dateValue15?, Bemerkung?, BWert?, UserName, Status
}

export interface OBAUW {
  ID?: number, OSTAMMID: number, b_StartMeter, b_EndMeter, b_Art, b_Bezeichnung, b_Zustand?, b_ParentID?, b_Bild?, b_GPS, UserName: string, Status: string, b_Lage?, RailDeep?, Bild64?
}

export interface SBAUW {
  ID?: number, Bezeichnung: string, Pruefung?, AnweisungBase64?, UserName: string, Status: string, VisualDiagramm?, intColDia?, DiaWert?, OTYPID?, GetRailDeep?
}

export interface OBEGE {
  ID?:number, Titel?, BeginnDatum?, Intervall?, Tag?, LPOBEGPID?, LPDatum?, LPCompleted?, Bemerkung?, UserName: string, Status: string
}

export interface OBEGP {
  ID?:number, OBEGEID?, BegehDatum?, Completed?, Bemerkung?, UserName: string, Status: string, BegehungsArt?
}
export interface SXBEP {
  ID?: number, OBEGP?, OSTAMMID?, Completed?, MangelID?, CompletedDate?, UserName: string
}
export interface SXBEG {
  ID?: number, OBEGEID, OSTAMMID
}

export interface OPBILD {
  ID?: number, OSTAMMID: number, OPRUEFID: number, P_Bild: string
}

export interface KSPEZV {
  ID?: number, OTYPID: number, SFELDNA: string, KFELDNA: string, PFLICHT: string, USEWERT: string, TABWERT: string, KSORT: string, UserName: string, Status: string
}
export interface OMAZS {
  ID?: number, OMAZSID?: number, OSTAMMID?: number, OPRUEFID?: number, OMARTID?: number, lfNr?: number, StartMeter?, EndMeter?, Strasse?, Richtung?, Haus?,
  HNummer?, LaengeInMeter?, Gattung?, Stamm?, Pruefart?, Mangel?, Lage?, Umfang?, Mengeneinheit?, FK?, Instandsetzung?,
  Betriebsgefahr?, Bild?, Erledigt?: boolean, E_Datum?, E_Firma?, ProzentFaktor?, FK_Hand?: number, Auftrag?: boolean, Termin?, AuftragNr?, Auftragnehmer?, Auftragsart?,
  Rueckmeldetext?, GattungID?: number, KAVAAE?, KAVAW?, ParentID?: number, Bemerkung?, Kosten?, Stunden?, Verantwortlich?,
  SAPExport?, InstPic1?, InstPic2?, InstPic3?, EP?, GPS_Lat?, GPS_Lon?, GPS_Image?, Kostenart?, MELDNR?, MELDSTAT?,
  AUFSTAT?, ZEDASID?, ZEDASERL?:boolean, ZuOID?, nIO?, Faktor?, Meldender?, UrsacheGrund?, SperrID?, SperrStatus?, GewaehrZeit?,
  M_Datum?, M_Zeit?, UserName: string, Status, StoerArt?, optUrsacheGrund?, IntAuftragNr?, LVPos?, zuAbt?, SAPAusfall?, SAPEckende?, SAPEckendeNeu?,
  S?: number, V?: number, D?: number, SZUMZID?: number, Teilerneuerung?, Ex_Infralife?, UpdateZEDAS?, Angebot?, Equipment?, pruefDatum?
}

export interface OMESS {
  ID?: number, OPRUEFID: number, Bezeichnung; string, Soll?, SR100plus?, SR100minus?, SRlimplus?, SRlimminus?, SRGplus?, SRGminus?, Ist?, Ausfall?, Instand?, Instand_Datum?, Instandsetzer?, Messender?,
  UserName: string, Status, INFO?, ZTYP?, IAusfall?, Bemerkung?, Ex_Infralife?, mPosition?, SZAEHNID?, Bild?,x_pruef_date?,x_bezirk?,x_stellwerk?,x_messregbezirk?

}
export interface SYSOOPT {
  ID?: number, OTYPID: number, Opt_F_Einbauhistory, Opt_F_Zaehler, Opt_F_Linienbelegung, Opt_F_Logbuch, Opt_F_Trassierung, Opt_O_UseRailProfil, Opt_O_UseDIN1076, Opt_O_UseMessReg,
  Opt_O_UseKomponenten, Opt_I_Zaehler, Opt_I_Del_Old_Image, Opt_I_Fehlerklasse, Opt_I_Anlagenklasse, Opt_I_Zustaend_Abt, Opt_I_Gundinst, Opt_I_PassBild, Opt_I_Track_BauwZustand,
  Opt_I_Track_Ber_Sichtbar, Opt_I_Track_Ber_Richtung, Opt_I_Track_Ber_Strasse, Opt_I_Track_Ber_Haus, Opt_I_Track_Ber_Nummer, Opt_I_Track_Ber_Laenge, Opt_I_Track_Spez_SchwellenNote,
  Opt_I_Track_Spez_Schwellenjahr, Opt_I_Track_Spez_Schienenhoehe, Opt_I_Track_Spez_Schienenjahr, Opt_I_Track_Spez_Option_ZusatzI, Opt_I_Track_Spez_Option_ZusatzII,
  Opt_I_Track_Spez_Option_Unterbau, Opt_I_Track_Spez_Option_Einbaujahr, Opt_I_Track_Spez_Option_Daemmung, Opt_I_Track_Spez_Option_GisID, Opt_I_Track_Spez_Option_ZustandsNote,
  Opt_E_OrdZustand, Opt_E_OrdZustand_TXT_IO, Opt_E_OrdZustand_TXT_NIO, Opt_E_FK_ImageToDok, Opt_E_Sperr_ImageToDok, Opt_E_Spez_ListToDok, Opt_E_Track_Spez_Schienenhoehe,
  Opt_E_Track_Spez_Schwellenjahr, Opt_E_Fehlerklasse, Opt_I_Bezirk, Opt_I_Stellwerk, Opt_E_Bezirk, Opt_E_Stellwerk, Opt_E_MessReg, Opt_F_Bahnsteig, Opt_F_Stellkraftmessung, Opt_F_Naturschutz,
  Opt_F_BUESicht, Opt_K_Kalender, KPRTYPID, Opt_I_PruefIntIsMessInt, Opt_F_Drawing, Opt_I_CheckList
}

export interface KBEZNG {
  ID?: number, FeldName: string, KBezeichnung: string, KShow: boolean, KPflicht: boolean, UserName: string, Status: any
}


export interface OMZLOG {
  ID?: number, OMAZSID: number, LogTyp: string, AuftragNr: string, UserName: string, Status: any
}

export interface SPRUE {
  ID?: number, OTYPID: number, Bezeichnung: string, Warranty_Time: string, UserName: string, Status: any
}

export interface KSTPVG {
  ID?: number, OTYPID: number, Titel: string, PruefartID: number, SortID: string, Gesperrt: string, UserName: string, Status: any, Bezeichnungen: any
}

export interface SBFEK {
  ID?: number, Bezeichnung, Schienenform_ID?, Schwellenart_ID?, Schienenbefestigung_ID?, Eindeckung_ID?, BildBase64?,  UserName: string, Status: any, SchienenformID?, SchwellenartID?, SchienenbefestigungID?,EindeckungID?,Sortierung?, EPreis?, OTYPID?, Geschlossen?
}
export interface SSCHF {
  ID?: number, Bezeichnung, Typ?, SchHMax?, BildBase64?, HV_Max?, Profil?, UserName: string, Status: any
}
export interface SSCHW {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SBEFT {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SEIND {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SSTAMM {
  ID?: number, OTYPID, GattungID, Bezeichnung, UserName: string, Status
}
export interface SGATT {
  ID?: number, IntID, OTYPID, Bezeichnung,  UserName: string, Status: any
}
export interface SMEH {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SLAGE {
  ID?: number, OTYPID, Bezeichnung, UserName: string, Status
}
export interface SINST {
  ID?: number, OTYPID, SINTYID, Bezeichnung, UserName: string, Status
}
export interface SMANG {
  ID?: number, OTYPID?, SMATYID?, Bezeichnung?, UserName: string, Status, HelpBildBase64?, HelpBeschreibung?, FileTyp?, InCheckListe?, PosCheckListe?
}
export interface SZUMZ {
  ID?: number, OTYPID, GattungID, StammID, PruefartID, LageID, MangelID, MengeneinheitID?, InstandsetzungID?, BedAusloeserMin?, BedMin?, BedVerbindung?, BedAusloeserMax?,
  BedMax?, BedEinheit?, FK?, Gesperrt?, AAE?, AW?, KAV_ME?, EP?, LVPos?, zuAbt?, S?, V?, D?, Equipment?, Gattung?, Stamm?, Pruefart?, Lage?, Mangel?, Mengeneinheit?, Instandsetzung?, Bezeichnung?, UserName: string, Status: any
}

export interface SZUBF {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SWTYP {
  ID?: number, Bezeichnung, UserName: string, Status: any,TypID?:number
}
export interface SVEST {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SUNTG {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SRICH {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SRADL {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SBAGP {
  ID?: number, Bezeichnung, UserName: string, Status: any
}
export interface SANTR {
  ID?: number, Bezeichnung, UserName: string, Status: any
}

export interface KTOLR {
  ID?:number, USE_0?, USE_100?, USE_L?, USE_G?, TEXT_0_m?, TEXT_0_p?, TEXT_100_m?, TEXT_100_p?, TEXT_L_m?, TEXT_L_p?, TEXT_G_m?, TEXT_G_p?, TEXT_0_A?, TEXT_100_A?, TEXT_L_A?, TEXT_G_A?, TEXT_0_S?, TEXT_100_S?, TEXT_L_S?, TEXT_Ausfall_0?, TEXT_Ausfall_I?, TEXT_G_S?, COLOR_0?, COLOR_100?, COLOR_L?, COLOR_G?, UserName:string, Status
}
export interface SYSINI {
  ID, optMINNumFK, optMAXNumFK, optFk1_IstGleich_BG, optDownChangeFK, KAV17?
}

export interface KZUWA {
  ID?: number, RevArtID?, RevArbID?, Priorität?, UserName: string, Status: any, Prioritaet?, OTYPID?, ArbBez?, ArtBez?
}
export interface KWAART {
  ID?: number, UserName: string, Status: any, Bezeichnung?, Typ?, OTYPID?, HelpBildBase64?, HelpBeschreibung?, FileTyp?
}
export interface KWAARB {
  ID?: number, UserName: string, Status: any, Bezeichnung?, OTYPID?
}
export interface KWAPRS {
  ID?: number, UserName: string, Status: any, Bezeichnung?, OTYPID?
}
export interface KWAMAN {
  ID?: number, UserName: string, Status: any, Name?, OTYPID?
}
export interface KWAMAS {
  ID?: number, UserName: string, Status: any, Bezeichnung?, OTYPID?, RevMaskenNameID?, RevArtID?, Sortierung?,artID?,artName?,artTyp?,FileTyp?,HelpBeschreibung?,
  HelpBildBase64?,zuordnungen?
}
export interface KWASTM {
  ID?: number, UserName: string, Status: any, Bezeichnung?, OSTAMMID?, RevMaskenID?, RevIntervall?, LastRevPruefungID?, Zustaedigkeit?, RevBeginn?, IntervallTyp?
}
export interface KWAPRU {
  ID?: number, UserName: string, Status: any, RevStammID?, RevMaskenNameID?, RevBemerkung?, RevDatum?, OTYPID?
}
export interface KWAMGZ {
  ID?: number, UserName: string, Status: any, RevPruefungID?, RevMaskenNameID?, RevArtID?, RevArbID?, RevGeprueft?, RevZustand_iO?, RevPriorität?, RevUmfang?, RevME?,
  RevBild?, Arbeiter?, Messwert?, OMAZSID?,artID?,arbeitID?,artName?,artTyp?,FileTyp?,HelpBeschreibung?, HelpBildBase64?,zuordnungen?,arbeitName?,FK?
}
export class AppDB extends Dexie {



  ostamm!: Table<OSTAMM, number>;
  opruef!: Table<ORPUEF, number>;
  ospez!: Table<OSPEZ, number>;
  oherst!: Table<OHERST, number>;
  obauw!: Table<OBAUW, number>;
  sbauw!: Table<SBAUW, number>;
  omazs!: Table<OMAZS, number>;
  omzlog!: Table<OMZLOG, number>;
  omess!: Table<OMESS, number>;
  sotyp!: Table<SOTYP, number>;
  sxmrep!: Table<SXMREP, number>;
  sxmgr!: Table<SXMGR, number>;
  sxmkp!: Table<SXMKP, number>;
  kpruv!: Table<KPRUV, number>;
  kbngr!: Table<KBNGR, number>;
  opbild!: Table<OPBILD, number>;
  obege!: Table<OBEGE, number>;
  obegp!: Table<OBEGP, number>;
  sxbeg!: Table<SXBEG, number>;
  sxbep!: Table<SXBEP, number>;
  kspezv!: Table<KSPEZV, number>;
  kbezng!: Table<KBEZNG, number>;
  sysoopt!: Table<SYSOOPT, number>;
  sprue!: Table<SPRUE, number>;
  kstpvg!: Table<KSTPVG, number>;
  sbfek!: Table<SBFEK, number>;
  sschf!: Table<SSCHF, number>;
  sschw!: Table<SSCHW, number>;
  sbeft!: Table<SBEFT, number>;
  seind!: Table<SEIND, number>;
  szubf!: Table<SZUBF, number>;
  swtyp!: Table<SWTYP, number>;
  svest!: Table<SVEST, number>;
  suntg!: Table<SUNTG, number>;
  sradl!: Table<SRADL, number>;
  srich!: Table<SRICH, number>;
  sbagp!: Table<SBAGP, number>;
  santr!: Table<SANTR, number>;
  sstamm!: Table<SSTAMM, number>;
  sgatt!: Table<SGATT, number>;
  smeh!: Table<SMEH, number>;
  slage!: Table<SLAGE, number>;
  smang!: Table<SMANG, number>;
  szumz!: Table<SZUMZ, number>;
  sinst!: Table<SINST, number>;
  ktolr!: Table<KTOLR, number>;
  kzuwa!: Table<KZUWA, number>;
  kwaart!: Table<KWAART, number>;
  kwaarb!: Table<KWAARB, number>;
  kwaprs!: Table<KWAPRS, number>;
  kwaman!: Table<KWAMAN, number>;
  kwamas!: Table<KWAMAS, number>;
  kwapru!: Table<KWAPRU, number>;
  kwastm!: Table<KWASTM, number>;
  kwamgz!: Table<KWAMGZ, number>;
  sysini!: Table<SYSINI, number>;
  KPflicht: boolean;
  nowFormat: string = 'YYYY-MM-DDTHH:mm:ss';

  constructor() {
    super('ngdexieliveQuery');
    this.version(1).stores({
      opruef: '++ID, OPRUEFID, OSTAMMID,P_Datum',
      ostamm: '++ID, OSTAMMID, OTYPID',
      ospez: '++ID, OSPEZID, OSTAMMID, OKOMPID, strValue01, strValue02',
      obauw: '++ID, OSTAMMID, b_StartMeter, b_EndMeter, b_ParentID',
      oherst: '++ID, OSPEZID,Einbaudatum',
      sbauw: '++ID, Bezeichnung, Pruefung, OTYPID',
      sotyp: '++ID, Name, Bezeichnung, Typ, SysIcon, ParentOTypID, ParentID',
      sxmrep: '++ID, OSTAMMID, REPID',
      sxmgr: '++ID, Typ, AnlagenobjektID, BenutzerGruppeID',
      sxmkp: '++ID, KPRUVID, OSTAMMID',
      kpruv: '++ID',
      kbngr: '++ID, Bezeichnung, Rechte, DelStatus',
      opbild: '++ID, OSTAMMID, OPRUEFID, P_Bild',
      sxbeg: '++ID, OBEGEID, OSTAMMID',
      sxbep: '++ID, OBEGP, OSTAMMID',
      obege: '++ID',
      obegp: '++ID, OBEGEID',
      kspezv: '++ID, OTYPID, SFELDNA, KFELDNA, PFLICHT, USEWERT, TABWERT, KSORT',
      kbezng: '++ID, FeldName, KBezeichnung, KShow, KPflicht',
      omazs: '++ID, OMAZSID, OSTAMMID, OPRUEFID, OMARTID, lfNr, StartMeter, EndMeter, SZUMZID,M_Datum,E_Datum',
      omzlog: '++ID, OMAZSID',
      omess: '++ID, OPRUEFID, Bezeichnung, ZTYP, SZAEHNID',
      sysoopt: '++ID, OTYPID',
      sprue: '++ID, OTYPID, Bezeichnung',
      kstpvg: '++ID, OTYPID, Titel, PruefartID',
      sbfek: '++ID, Bezeichnung, Schienenform_ID, Schwellenart_ID, Schienenbefestigung_ID, Eindeckung_ID, OTYPID',
      sschf: '++ID, Bezeichnung, Typ',
      sschw: '++ID, Bezeichnung',
      sbeft: '++ID, Bezeichnung',
      seind: '++ID, Bezeichnung',
      sgatt: '++ID, OTYPID, Bezeichnung',
      sstamm: '++ID, OTYPID, GattungID, Bezeichnung',
      sinst: '++ID, OTYPID, GattungID, Bezeichnung',
      szubf: '++ID, Bezeichnung',
      swtyp: '++ID, Bezeichnung',
      svest: '++ID, Bezeichnung',
      suntg: '++ID, Bezeichnung',
      sradl: '++ID, Bezeichnung',
      srich: '++ID, Bezeichnung',
      sbagp: '++ID, Bezeichnung',
      santr: '++ID, Bezeichnung',
      smeh: '++ID, Bezeichnung',
      slage: '++ID, OTYPID, Bezeichnung',
      smang: '++ID, OTYPID, SMATYID, Bezeichnung',
      ktolr: '++ID',
      kzuwa: '++ID,RevArtID',
      kwaarb: '++ID',
      kwaart: '++ID',
      kwaman: '++ID',
      kwaprs: '++ID',
      kwamas: '++ID',
      kwapru: '++ID,RevStammID',
      kwastm: '++ID,OSTAMMID,RevMaskenID,Zustaedigkeit',
      kwamgz: '++ID,RevPruefungID',
      sysini: '++ID',
      szumz :'++ID, OTYPID, GattungID, StammID, PruefartID, LageID, MangelID, MengeneinheitID, InstandsetzungID'

    });
    //   this.on('populate', () => this.populate());
  }

  //Aliasse - entspricht "x AS y" im SQL-Befehl. Nur eintragen wenn es wirklich ein Alias im Befehl für das Feld gibt.
  aliasStammAnl = {lpdatum: "Prüfdatum", lpmdatum: "Messdatum", laenge:"Länge", messregbezirk:'MessReg Bezirk', startpunkt:"startpunkt", endpunkt:"endpunkt", startmeter:"startmeter",endmeter:"endmeter"};
  aliasMangel = {startmeter: "von", endmeter: "bis", gattung: "gattung", lage: "lage", mangel: "mangel", instandsetzung: "instandsetzungshinweis",fk:"fehlerklasse", umfang:"umfang",
  mengeneinheit:"mengeneinheit", abteilung:"abteilung",betriebsgefahr:"betriebsgefahr",bemerkung:"bemerkung",stamm:"stamm",pruefart:"pruefart", szumzid: "szumzid", faktor: "faktor",
  erledigt: "erledigtStatus",e_datum:'Erledigt_am', e_firma:'Erledigt_durch',  prozentfaktor: "faktor", fk_hand: "fk_aenderung", gattungid: "gattung_id", kavaae: "abnutzungsaequivalent",
  kavaw: "anteilige_wichtung", ep: "gattung_id", auftrag: "auftrag", termin: "termin", AuftragNr: "auftrag_nr", auftragsart: "auftragsart", auftragnehmer: "auftragnehmer", sapexport: "sap_export",
  meldstat: "MELDSTAT", meldnr: "meld_nr", aufstat: "aufstat", zuAbt: "abteilung", sperrid: "sperrung", sperrstatus: "sperrung_status",status:"status"}

  aliasBauwerke = {b_startmeter: "von", b_endmeter: "bis", b_bezeichnung:"bezeichnung", b_zustand:"zustand", b_bild:"bild", b_lage:"lage", b_gps:"gpskoordinaten",b_art:"bauwerksart"};

  aliasMessungen = {p_datum:"pruefdatum",soll:"sollmass",sr100plus:"sr_100_plus",sr100minus:"sr_100_minus",srlimplus:"sr_lim_plus",srlimminus:"sr_lim_minus",ausfall:"tolverl",instand:"ist_neu",iausfall:"tolverl_neu",instand_datum:"instandsetzung_vom",messender:"pruefer"};
  aliasBegehung = {id:"OBEGEID",lpobegpid:"OBEGPID",titel:"Begehungsart",lpdatum:"letzte",lpcompleted:"abgeschlossen",beginndatum:"Intervallbeginn",tag:"_tag",bemerkung:"Beschreibung"}
  aliasSpezTempl = {schienenform_id:"Schienenform",schwellenart_id:"Schwellenart",schienenbefestigung_id:"Schienenbefestigung",eindeckung_id:"Eindeckung",bildbase64:"Bild64"}

  //Selects - entspricht "SELECT WHERE a,b...,n". Reihenfolge ist wichtig!
  selectStamm = ['OTYPID', 'OSTAMMID', 'Stellwerk', 'Ort', 'MessRegBezirk', 'Bezeichnung', 'Startpunkt', 'Endpunkt', 'Startmeter', 'Verantwortlich', 'FremdID', 'PlanNr', 'Kostenstelle',
  'Eigentuemer', 'Anlagenklasse','LPID'];
  selectStammAnl = [ 'OSTAMMID', 'LPID','OTYPID','MinFK', 'Rechte','Rep','typ', 'typ_id', 'typ_icon', 'SPID', 'SP', 'LF', 'Bezeichnung', 'Bezirk', 'Stellwerk','Ort',
  'LPDatum', 'LPMDatum','Laenge', 'MessRegBezirk', 'Startpunkt', 'Endpunkt', 'StartMeter', 'EndMeter','Anlagenklasse', 'PlanNr', 'FremdID', 'Skizze', 'Eigentuemer','Richtung',
  'Befahrung','Verantwortlich', 'Kostenstelle','Nutzlaenge', 'UserName', 'Status'];

  selectMangelAnl = ['OMAZSID','OSTAMMID','pruefDatum','StartMeter','EndMeter','Pruefart' ,'Lage' ,'Mangel' ,'Instandsetzung' ,'Umfang', 'nio' ,'Mengeneinheit' ,'FK' ,'Betriebsgefahr','Bild',
  'Erledigt' ,'E_Datum' ,'E_Firma' ,'InstPic1','ProzentFaktor','FK_Hand' ,'Gattung' ,'GattungID' ,'Stamm' ,'KAVAAE' ,'KAVAW' ,'EP' ,'Auftrag' ,'Termin' ,'AuftragNr' ,'Auftragsart' ,'Auftragnehmer',
  'SAPExport' ,'MELDSTAT' ,'MELDNR','AUFSTAT' ,'Faktor' ,'SperrID' ,'SperrStatus','Bemerkung','UserName' ,'Status'];

  selectMangelPoint = ['OMAZSID','OSTAMMID','pruefDatum','Pruefart' ,'Lage' ,'Mangel' ,'Instandsetzung' ,'Umfang', 'nio' ,'Mengeneinheit' ,'FK' ,'Betriebsgefahr','Bild',
  'Erledigt' ,'E_Datum' ,'E_Firma' ,'InstPic1','ProzentFaktor','FK_Hand' ,'Gattung' ,'GattungID' ,'Stamm' ,'KAVAAE' ,'KAVAW' ,'EP' ,'Auftrag' ,'Termin' ,'AuftragNr' ,'Auftragsart' ,'Auftragnehmer',
  'SAPExport' ,'MELDSTAT' ,'MELDNR','AUFSTAT' ,'Faktor' ,'SperrID' ,'SperrStatus','Bemerkung','UserName' ,'Status'];

  selectBege = ['ID','OBEGEID', 'LPOBEGEID', 'Titel', 'LPDatum', 'LPCompleted', 'BeginnDatum', 'Intervall','Tag', 'Bemerkung', 'UserName' ,'Status'];
  selectSpezTmpl = ['Bezeichnung','Schienenform_ID', 'Schwellenart_ID', 'Schienenbefestigung_ID', 'Eindeckung', 'Geschlossen', 'UserName', 'Status','SchienenformID', 'SchwellenartID', 'SchienenbefestigungID','EindeckungID'];

  async populate(dt: any) {
    await db[dt[0]].bulkAdd(dt[1].rows);
    if(dt[0] == "ostamm") await db.ostamm.delete((await db.ostamm.toArray()).reverse()[0].ID)
  }

  //Wandelt in Frontend-Tabellenformat um. Beachten: bei der replaceList muss der Key in lowercase sein!
  parseToFrontEnd(dt: any, allowNull = false, replaceList : any = {}, deleteList : any = []) {
    let dtFE = {
      columns: [],
      rows: []
    }
    if (dt.length == 0) if (!allowNull) return null; else return dtFE;
    Object.entries(dt[0]).forEach(elm => {
      if (Object.keys(replaceList).length > 0) {
        if (replaceList.hasOwnProperty(elm[0].toLowerCase())) {
          elm[0] = replaceList[elm[0].toLowerCase()];
        }
      }


      dtFE.columns.push({ id: elm[0], bezeichnung: elm[0].toUpperCase(), type: !Number.isNaN(elm[1]) ? "number" : "string" });
    });
    dtFE.rows = dt;
    dtFE.rows = this.renObj(dtFE.rows,replaceList,deleteList)

    return dtFE;
  }
  renObj(obj: any, replaceList : any = {}, deleteList : any = []) {
    if(Object.keys(replaceList).length > 0) {
      obj = obj.map(row => {
        return Object.fromEntries(Object.entries(row).map((elm) => {
          if(false) { // (deleteList && deleteList.includes(elm[0])) {
            delete row[elm[0]];
          } else {
            if(replaceList.hasOwnProperty(elm[0].toLowerCase())) {
              return [replaceList[elm[0].toLowerCase()], elm[1]];
            }
          }
          return elm;
        }));
      });
    }
    return obj;
  }

  // Entspricht einem "Select a,b,...n" in einem SQL-Query mit entsprechender Sortierung
  public Select(obj, allowed):any {
    return obj.map(x=>Object.keys(x)
      .filter(key => allowed.includes(key))
      .sort((a, b) => {
        if (!allowed.includes(a)) return 1
        if (!allowed.includes(b)) return -1
        return allowed.indexOf(a) - allowed.indexOf(b);
      })
      .reduce((obj, key) => {
        obj[key] = x[key];
        return obj;
      }, {})
      );
  }

  async getAnlagen() : Promise<any> {
    var kbez = await this.getKundenBezeichnungColumnNames();
    for (let i = 0; i <= 20; i++) {
      if (("KNField" + i.toString()) in kbez) {
        this.selectStammAnl.push("KNField" + i.toString());
      }
    }

    var groupIDs = this.GetGroupIDString([{id:1}]);
    var anlage = await db.ostamm.toArray();
    const lf = anlage.filter(x=> (x.LFaktiv > 0 && x.DelStatus == 0));
    const sp = anlage.filter(x=> (x.SperrStatus > 0 && x.DelStatus == 0));
    if (sp.length == 0)
      {this.selectStammAnl.splice(this.selectStammAnl.indexOf("SP"),1);
      this.selectStammAnl.splice(this.selectStammAnl.indexOf("SPID"),1)}
    if (sp.length == 0) this.selectStammAnl.splice(this.selectStammAnl.indexOf("LF"),1);
    var sxmrep : number = await db.sxmrep.count() || 0;
    if (sxmrep == 0) this.selectStammAnl.splice(this.selectStammAnl.indexOf("Rep"),1);

    await Promise.all(anlage.map(async anl => {
      var sotyp = await db.sotyp.get(anl.OTYPID);
      var sxmrep : number = await db.sxmrep.where("OSTAMMID").equals(anl.OSTAMMID).count() || 0;
      [anl.typ,anl.typ_icon,anl.LF, anl.SP, anl.SPID, anl.Rep] =
        [sotyp.Bezeichnung,sotyp.SysIcon, lf[0]?.LFmin ?? 0, sp[0]?.SperrStatus, sp[0]?.SperrID, sxmrep]
    }));

    anlage = this.Select(anlage,this.selectStammAnl);
    kbez = Object.assign(this.aliasStammAnl,kbez);
    return this.parseToFrontEnd(anlage,false,kbez);
  }

  async getAnlageByID(selID: number) {
    var returnval = {};
    var result = {};
    const anlage = await db.ostamm.where("OSTAMMID").equals(selID).toArray();
    var opruef = await db.opruef.where("OPRUEFID").equals(anlage[0]["LPID"]).toArray();
    var sotyp = (await db.sotyp.get(anlage[0].OTYPID));
    var pruefid = opruef.length > 0 ? opruef[0]["OPRUEFID"] : -99;
    returnval["skizze"] = anlage[0]["Skizze"];

    await Promise.all(anlage.map(async anl => {
      anl.TYP = sotyp.Bezeichnung;
    }));
    var otyptypid = sotyp["Typ"];
    var otypID = anlage[0]["OTYPID"];

    var kspez = this.parseToFrontEnd((await db.kspezv.toArray()).filter(x=> x.OTYPID == otypID && x.KFELDNA != null));
    var ospez = this.parseToFrontEnd(await db.ospez.where("OSTAMMID").equals(selID).toArray());
    if (ospez != null) result["spez"] = (otyptypid == 2 || otyptypid == 4 ? await this.getSpezLinie(ospez, kspez,otypID) : await this.getPointSpez(ospez, kspez,otypID,0));
    var mangel = this.Select(await db.omazs.where("OPRUEFID").equals(pruefid).and(x=>x.Mangel != null).toArray(),this.selectMangelAnl);
    mangel = this.parseToFrontEnd(mangel,false,this.aliasMangel);
    if (mangel != null) result["mangel"] = mangel;
    var bauwerke = this.parseToFrontEnd(await db.obauw.where("OSTAMMID").equals(selID).toArray(),false,this.aliasBauwerke);
    if (bauwerke != null) result["bauwerke"] = bauwerke;
    var messung = await this.getMessungAuswertung({status:'inspektion',pruefid:pruefid,ostammid:selID});
    if (messung != null) result["messungen"] = messung;
    var sysoopt = this.parseToFrontEnd(await db.sysoopt.where("OTYPID").equals(otypID).toArray());
    var kbezng = this.parseToFrontEnd((await db.kbezng.toArray()).filter(x=>x.KShow == true));

    result["stamm"] = await this.stammConvert(anlage[0], otyptypid, kbezng);


    returnval["ostammid"] = selID;
    returnval["bemerkung"] = otypID;
    returnval["bezeichnung"] = anlage[0]["Bezeichnung"];
    returnval["otypid"] = otypID;
    returnval["typ_Line_Point"] = otyptypid;
    returnval["opruefid"] = pruefid;
    returnval["tabs"] = result;
    returnval["objOpt"] = sysoopt;
    returnval["hasStellkraftmessung"] = false;
    returnval["isRepLocked"] = false;
    //returnval["pointtype"] = pointtype;
    // returnval["bild"] = bilderbyte.ToArray();
    // returnval["skizze"] = skizze;
    // returnval["icon"] = sysicon;

    return returnval;
  }

  async getStamm(findid: number) {
    return await db.ostamm.where("OSTAMMID").equals(findid).toArray();
  }
  async getpruef(id: number) {
    return await db.opruef.where("OPRUEFID").equals(id).toArray();
  }

  public async getStammInsp(selID: number, typ: number) {
    var stamm = await db.ostamm.where("OSTAMMID").equals(selID).toArray();
    var kbezng = this.parseToFrontEnd((await db.kbezng.toArray()).filter(x=>x.KShow == true));
    var result = await this.stammConvert(stamm[0],typ,kbezng);
    return {
      success: true,
      stamm: result.rows,
      bemerkung: stamm["bemerkung"],
    }
  }

  public async setLPID(stammid: number, krtyp: number) {
    var pruef = (await db.opruef.toArray()).filter(x=> x.OSTAMMID == stammid && x.P_DelStatus == 0 && x.KPRTYPID == krtyp && x.P_IntStatus == 'Hauptprüfung' && x.P_Datum != null);
    var f: boolean = false;
    var m_LPID = -1;
    var m_LDate;
    var id = (await db.ostamm.get({OSTAMMID:stammid}))?.ID;

    //Hauptprüfung
    pruef.forEach(row => {
      m_LPID = parseInt(row["OPRUEFID"].toString());
      m_LDate = row["P_Datum"].toString();
      f = true;
    });

    if (krtyp == 1) {
      if (f)
        await db.ostamm.update(id, { LPID: m_LPID, LPDatum: m_LDate })
      else
        await db.ostamm.update(id, { LPID: null, LPDatum: null })
    } else if (f) {
      await db.ostamm.update(id, { LPID: m_LPID, LPDatum: m_LDate })
    } else {
      await db.ostamm.update(id, { LPID: null, LPDatum: null })
    }

    //Nebenprüfung
    pruef = (await db.opruef.toArray()).filter(x=> x.OSTAMMID == stammid && x.P_DelStatus == 0 && x.KPRTYPID == krtyp && x.P_IntStatus == 'Nebenprüfung');
    f = false;

    pruef.forEach(row => {
      m_LPID = parseInt(row["OPRUEFID"].toString());
      m_LDate = row["P_Datum"].toString();
      f = true;
    });

    if (f)
      await db.ostamm.update(id, { LNPID: m_LPID, LNPDatum: m_LDate })
    else
      await db.ostamm.update(id, { LNPID: null, LNPDatum: null })

  }

  async getSpez(stammid: any, typid: number, typ: number) {
    var spez = this.parseToFrontEnd(await db.ospez.where("OSTAMMID").equals(stammid).toArray());
    var kspez = this.parseToFrontEnd((await db.kspezv.toArray()).filter(x=> x.OTYPID == typid && x.KFELDNA != null));
    var ret: any;
    if (typ == 2 || typ == 4) {
      ret = this.getSpezLinie(spez, kspez,typid);
    } else {
      ret = this.getPointSpez(spez, kspez,typid,0);
    }
    return ret;
  }
  async getSpezLinie(spez: any, kspez: any, otypID: any) {
    var spezle = [];
    var rows = {};
    var columns = [];

    columns.push({
      bezeichnung: "OSPEZID",
      id: "OSPEZID"
    })

    columns.push({
      bezeichnung: "s_StartMeter",
      id: "Startmeter",
      pflichtFeld: 1
    })

    columns.push({
      bezeichnung: "s_EndMeter",
      id: "Endmeter",
      pflichtFeld: 1
    })

    // Filtern kspez nach SysObjOpt, falls Gleis
// if (otypID == 2)
// {
//     var clsSysObjOpt = this.parseToFrontEnd(await db.sysoopt.where("OTYPID").equals(otypID).toArray()).rows[0];

//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_SchwellenNote, "strValue21");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Schwellenjahr, "strValue07");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Schienenhoehe, "strValue22");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Option_Einbaujahr, "strValue06");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Option_ZusatzI, "strValue24");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Option_ZusatzII, "strValue25");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Option_Daemmung, "strValue26");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Option_Unterbau, "strValue23");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Option_GisID, "intValue02");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Option_ZustandsNote, "intValue01");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_SchwellenNote, "strValue21");
//     kspez = this.checkGleisSpezOpt(kspez, clsSysObjOpt.Opt_I_Track_Spez_Schienenjahr, "strValue08");
//     kspez = this.checkGleisSpezOpt(kspez, 0, "strValue27");
// }

    for (const drSpez of kspez.rows) {

      var selOptions = [];
      var sqlColName : string = drSpez["SFELDNA"].toString()!;
      var tblColName : string = drSpez["KFELDNA"].toString()!;
      if (sqlColName.startsWith("str")) // Datalist für int, dbl, datum, und boolean Werte ist sinnlos, spare SQL-Befehl
      {
        var tableName = drSpez["TABWERT"]?.toString();
        var isSelect = drSpez["USEWERT"];
        if (tableName)
          if (isSelect) {
            var column = tableName == "SBAGP" ? "Name" : "Bezeichnung";
            var tbl: any[] = await db[tableName.toLowerCase()].toArray();
            var fltr = tableName == "KSPEZW" ? tbl.filter(x => x.OTYPID = otypID && x.SFELDNA == sqlColName) : tbl;
            fltr.forEach(f => selOptions.push(f[column]));
          }
          else {
            var tbl: any[] = await db[tableName.toLowerCase()].toArray();
            var fltr = tbl.filter(x => x.OTYPID = otypID);
            fltr.forEach(f => selOptions.push(f["Bezeichnung"]));
          }
      }

        columns.push({
          bezeichnung: drSpez["SFELDNA"],
          id: drSpez["KFELDNA"],
          pflichtFeld: 1,
          wListe: selOptions.length > 0 ? selOptions : undefined,
          optionalData:{
            fieldType: sqlColName.substring(0,3),
            isOptSpez: drSpez["isOptSpez"] ?? false,
            sField: tableName
          }
        })
    }

    for (const dr of spez.rows) {
      rows = {};
      rows["Startmeter"] = (
        {
          objekt: "Startmeter",
          value: dr["s_StartMeter"],
          orgName: "s_StartMeter"
        });

        rows["Endmeter"] = (
          {
            objekt: "Endmeter",
            value: dr["s_EndMeter"],
            orgName: "s_EndMeter"
          });

      rows["OSPEZID"] = (
        {
          objekt: "OSPEZID",
          value: dr["OSPEZID"],
          orgName: "OSPEZID"
        });

        for (const drSpez of kspez.rows) {
          rows[drSpez["KFELDNA"]] = ({
            objekt: drSpez["KFELDNA"],
            orgName: drSpez["SFELDNA"],
            value: dr[drSpez["SFELDNA"]],
          });
        }
      spezle.push(rows);
    }

    let dtFE = {
      columns: [],
      rows: [],
      type: "table"
    }
    const col = new Map(columns.map(x => [x.bezeichnung, x]));
    dtFE.columns = [...col.values()];
    dtFE.rows = spezle;
    return dtFE;
  }
  async getPointSpez(spez: any, kspez: any, otypid:number, okompid: number) {
    var rows = [];
    rows.push({
      objekt: "OSPEZID",
      bezeichnung: spez.rows[0]["OSPEZID"] ?? -1
    });
    var ret;
    for (const dr of Object.entries(spez.rows[0])) {
      if (true){//dr[1] != null) {
        for (const drSpez of kspez.rows) {
          if (dr[0] == drSpez["SFELDNA"] && drSpez["KFELDNA"]) {

            var selOptions = [];
            var tableName : string = drSpez["TABWERT"]? drSpez["TABWERT"].toUpperCase() : '';
            var colName :string = drSpez["SFELDNA"];
            if (colName.startsWith("str")) // Datalist für int, dbl, datum, und boolean Werte ist sinnlos, spare SQL-Befehl
            {
                if (tableName != "" && db[tableName.toLowerCase()])
                {
                    var column = (tableName == "SBAGP" ? "Name" : "Bezeichnung");
                    var dt = (tableName == "KSPEZW" ?
                      await db[tableName.toLowerCase()].where("OTYPID").equals(otypid).and((x)=> x.SFELDNA == colName).toArray() :
                      await db[tableName.toLowerCase()].toArray());
                    await Promise.all(dt.map((x) =>  {
                      selOptions.push(x[column].toString());
                    }));
                }
                else
                {
                    //string sql = @"SELECT " + drSpez["SFELDNA"].toString() + " FROM ospez INNER JOIN ostamm on ospez.OSTAMMID = ostamm.OSTAMMID WHERE OTYPID = ? GROUP BY " + colName;
                    dt = await db.ospez.toArray(); //where("OTYPID").equals(otypid).sortBy(colName);
                    if (okompid > 0) dt = await db.ospez.where("KTYPID").equals(otypid).sortBy(colName);

                        //sql = @"SELECT " + drSpez["SFELDNA"].toString() + " FROM ospez LEFT JOIN okomp ON okomp.OKOMPID = ospez.OKOMPID WHERE KTYPID= ? GROUP BY " + colName;
                        await Promise.all(dt.map(async x =>  {
                          var stm = (await db.ostamm.toArray()).filter(y=>y.OSTAMMID == x.OSTAMMID && x.OTYPID == otypid);
                          if(stm && x[colName])
                            selOptions.push(x[colName].toString());
                        }));
                }
            }

            rows.push({
              objekt: drSpez["KFELDNA"],
              bezeichnung: dr[1],
              fieldType: drSpez["SFELDNA"].toString().slice(0, 3),
              orgName: drSpez["SFELDNA"],
              sField: drSpez["USEWERT"] ? drSpez["TABWERT"] : null,
              wListe: selOptions,
              pflichtFeld: drSpez["PFLICHT"]
            });
            break;
          }
        };
      }

    }
    return rows;
  }

  async getSpezTemplate() {

    var bfek = await db.sbfek.toArray();
    await Promise.all(bfek.map(async el => {
      [el.SchienenformID, el.SchwellenartID, el.SchienenbefestigungID, el.EindeckungID] = [
        el.Schienenform_ID ? (await db.sschf.get({Bezeichnung:el.Schienenform_ID}))?.ID : undefined,
        el.Schwellenart_ID ? (await db.sschw.get({Bezeichnung:el.Schwellenart_ID}))?.ID : undefined,
        el.Schienenbefestigung_ID ? (await db.sbeft.get({Bezeichnung:el.Schienenbefestigung_ID}))?.ID : undefined,
        el.Eindeckung_ID ? (await db.seind.get({Bezeichnung:el.Eindeckung_ID}))?.ID : undefined,
      ]
    }));
    return this.parseToFrontEnd(bfek,false,this.aliasSpezTempl);
  }

  private checkGleisSpezOpt(kspez : any, opt: number, fieldname : string)
  {
    Object.keys(kspez.rows).forEach(rw => {
      if(kspez.rows[rw]) {
        if (kspez.rows[rw]["SFELDNA"] == fieldname) {
          if (opt == 0) {
            kspez.rows.splice(kspez.rows[rw],1);
          }
          else {
            kspez.rows[rw].isOptSpez = true;
          }
        }
      }

    });
    return kspez;
  }

  public getstammfield(dt, field) {
    var ret = null;
    for (const dr of dt.rows) {
      if (dr["FeldName"] == field) {
        this.KPflicht = dr["KPflicht"];
        ret = dr["KBezeichnung"];
        break;
      }
    };
    return ret;
  }

  async stammConvert(anlage: any, otyptypid: number, kbezng: any) {
    var rows = [];
    var ostammid = anlage["OSTAMMID"];
    var dr = {};

    var dtSysObjectOptions = await db.sysoopt.where("OTYPID").equals(anlage["OTYPID"]).toArray();
    var dtsxmkp = (await db.sxmkp.toArray()).filter(x=>x.OSTAMMID == ostammid && x.KPRUVID == 1) ;
    var drsxmkp = undefined;

    if(parseInt(ostammid) > 0)
    if(dtsxmkp.length == 0) {
      var rKPRUV = await db.kpruv.toArray();
      await db.sxmkp.add({
        OSTAMMID:ostammid,
        IVAL_HP: rKPRUV.length > 0 ? rKPRUV[0].Base_IVAL_HP : 12,
        IVAL_Beginn_HP: dayjs().format(this.nowFormat),
        IVAL_MS:rKPRUV.length > 0 ? rKPRUV[0].Base_IVAL_MS :12,
        IVAL_Beginn_MS: dayjs().format(this.nowFormat),
        KPRUVID:1,
        ZAEID_HP:0,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      });
      dtsxmkp = (await db.sxmkp.toArray()).filter(x=>x.OSTAMMID == ostammid && x.KPRUVID == 1) ;
      drsxmkp = dtsxmkp[0];
    }

    if(dtsxmkp.length > 0) {
      drsxmkp = dtsxmkp[0];
    } else {
      drsxmkp = {
        OSTAMMID:ostammid,
        IVAL_HP: rKPRUV.length > 0 ? rKPRUV[0].Base_IVAL_HP : 12,
        IVAL_Beginn_HP: dayjs().format(this.nowFormat),
        IVAL_MS:rKPRUV.length > 0 ? rKPRUV[0].Base_IVAL_MS :12,
        IVAL_Beginn_MS: dayjs().format(this.nowFormat),
        KPRUVID:1,
        ZAEID_HP:0,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      }
    }

    if (true) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "sotyp.Bezeichnung";
      dr["Fieldtyp"] = -1;
      dr["Translate"] = true;
      dr["Objekt"] = "Typ";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["TYP"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);
    }

    dr = {};
    dr["isHeader"] = 0;
    dr["DBField"] = "Bezeichnung";
    dr["Fieldtyp"] = 0;
    dr["Translate"] = true;
    dr["Objekt"] = "Bezeichnung";

    if (anlage != null) {
      dr["Bezeichnung"] = anlage["Bezeichnung"];
    }

    dr["Pflicht"] = true;
    rows.push(dr);

    this.KPflicht = false;
    var b = this.getstammfield(kbezng, "Bezirk");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Bezirk";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Bezirk"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }

    this.KPflicht = false;
    b = this.getstammfield(kbezng, "Stellwerk");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Stellwerk";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Stellwerk"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }

    this.KPflicht = false;
    b = this.getstammfield(kbezng, "Ort");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Ort";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Ort"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }


    if (otyptypid == 1 || otyptypid == 3) {
      // if (sysini.Table.Columns.Contains("Opt_O_UseMessReg") && sysini["Opt_O_UseMessReg"] == "1")
      // {

      //     dr = {};
      //     dr["isHeader"] = 0;
      //     dr["DBField"] = "MessRegBezirk";
      //     dr["Fieldtyp"] = 1;
      //     dr["Translate"] = true;
      //     dr["Objekt"] = "MessRegBezirk";


      //     if (anlage != null)
      //     {
      //         dr["Bezeichnung"] = anlage["MessRegBezirk"];
      //     }


      //     dr["Pflicht"] = true;
      //     rows.push(dr);


      //     dr["isHeader"] = 0;
      //     dr["DBField"] = "MessRegID";
      //     dr["Fieldtyp"] = 3;
      //     dr["Translate"] = true;
      //     dr["Objekt"] = "MessRegID";

      //     if (anlage != null)
      //     {
      //         dr["Bezeichnung"] = anlage["MessRegID"];
      //     }

      //     dr["Pflicht"] = true;
      //     rows.push(dr);



      // }


      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Laenge";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Baulänge";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Laenge"];
      }


      dr["Pflicht"] = true;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Nutzlaenge";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Leistungslänge";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Nutzlaenge"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);


    }

    if (otyptypid == 2 || otyptypid == 4) {

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Startpunkt";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = true;
      dr["Objekt"] = "Startpunkt Bezeichnung";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Startpunkt"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Endpunkt";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = true;
      dr["Objekt"] = "Endpunkt Bezeichnung";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Endpunkt"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "StartMeter";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Startmeter";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["StartMeter"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "EndMeter";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Endmeter";


      if (anlage != null) {
        dr["Bezeichnung"] = anlage["EndMeter"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Laenge";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Gleislänge";


      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Laenge"];
      }


      dr["Pflicht"] = true;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Nutzlaenge";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Leistungslänge";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Nutzlaenge"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);

    }


    this.KPflicht = false;
    b = this.getstammfield(kbezng, "Anlagenklasse");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Anlagenklasse";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Anlagenklasse"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }

    this.KPflicht = false;
    b = this.getstammfield(kbezng, "Eigentuemer");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Eigentuemer";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Eigentuemer"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }


    this.KPflicht = false;
    b = this.getstammfield(kbezng, "Kostenstelle");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Kostenstelle";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Kostenstelle"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }


    this.KPflicht = false;
    b = this.getstammfield(kbezng, "PlanNr");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "PlanNr";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["PlanNr"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }

    this.KPflicht = false;
    b = this.getstammfield(kbezng, "FremdID");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "FremdID";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["FremdID"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }

    if (otyptypid == 2 || otyptypid == 4) {
      this.KPflicht = false;
      b = this.getstammfield(kbezng, "Messfile");
      if (b != null) {
        dr = {};
        dr["isHeader"] = 0;
        dr["DBField"] = "Messfile";
        dr["Fieldtyp"] = 1;
        dr["Translate"] = false;
        dr["Objekt"] = b;

        if (anlage != null) {
          dr["Bezeichnung"] = anlage["Messfile"];
        }

        dr["Pflicht"] = this.KPflicht;
        rows.push(dr);
      }

      this.KPflicht = false;
      b = this.getstammfield(kbezng, "Befahrung");
      if (b != null) {
        dr = {};
        dr["isHeader"] = 0;
        dr["DBField"] = "Befahrung";
        dr["Fieldtyp"] = 4;
        dr["Translate"] = false;
        dr["Objekt"] = b;

        if (anlage != null) {
          dr["Bezeichnung"] = anlage["Befahrung"];
        }

        dr["Pflicht"] = this.KPflicht;
        rows.push(dr);
      }

      this.KPflicht = false;
      b = this.getstammfield(kbezng, "Richtung");
      if (b != null) {
        dr = {};
        dr["isHeader"] = 0;
        dr["DBField"] = "Richtung";
        dr["Fieldtyp"] = 1;
        dr["Translate"] = false;
        dr["Objekt"] = b;

        if (anlage != null) {
          dr["Bezeichnung"] = anlage["Richtung"];
        }

        dr["Pflicht"] = this.KPflicht;
        rows.push(dr);
      }

    }


    this.KPflicht = false;
    b = this.getstammfield(kbezng, "Verantwortlich");
    if (b != null) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Verantwortlich";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = false;
      dr["Objekt"] = b;

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Verantwortlich"];
      }

      dr["Pflicht"] = this.KPflicht;
      rows.push(dr);
    }

    if (otyptypid == 1 || otyptypid == 3) {
      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "GPS_Lat";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = true;
      dr["Objekt"] = "GPS Breitengrad";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["GPS_Lat"];
      }

      dr["Pflicht"] = false;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "GPS_Lon";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = true;
      dr["Objekt"] = "GPS Längengrad";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["GPS_Lon"];
      }

      dr["Pflicht"] = false;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "Skizze";
      dr["Fieldtyp"] = -1;
      dr["Translate"] = true;
      dr["Objekt"] = "Skizze";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["Skizze"];
      }

      dr["Pflicht"] = false;
      rows.push(dr);
    }

    dr = {};
    dr["isHeader"] = 0;
    dr["DBField"] = "opbild.P_Bild";
    dr["Fieldtyp"] = -1;
    dr["Translate"] = true;
    dr["Objekt"] = "Bild";

    if (anlage != null) {
      dr["Bezeichnung"] = anlage["Bild"];
    }

    dr["Pflicht"] = false;
    rows.push(dr);


    for (let i = 1; i <= 20; i++) {
      var fildname = "KNField" + i.toString();

      this.KPflicht = false;
      b = this.getstammfield(kbezng, fildname);
      if (b != null && b != '') {
        dr = {};
        dr["isHeader"] = 0;
        dr["DBField"] = fildname;
        dr["Fieldtyp"] = 1;
        dr["Translate"] = false;
        dr["Objekt"] = b;

        if (anlage != null) {
          dr["Bezeichnung"] = anlage[fildname];
        }

        dr["Pflicht"] = this.KPflicht;
        rows.push(dr);
      }
    }

    if (false) { //Excel wird nicht mehr verwendet
      dr = {};
      var excelpath = "";
      dr["isHeader"] = 0;
      dr["DBField"] = "EXCEL";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = true;
      dr["Objekt"] = "ExcelPath";
      dr["Bezeichnung"] = excelpath;
      dr["Pflicht"] = false;
      rows.push(dr);


      var Bezeichnung_Format = "";

      if (anlage != null) {
        Bezeichnung_Format = anlage["TYP"] + " " + anlage["Stellwerk"] + " " + anlage["Bezeichnung"];
      }

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "pfad";
      dr["Fieldtyp"] = 1;
      dr["Translate"] = true;
      dr["Objekt"] = "Bezeichnung_Format";
      dr["Bezeichnung"] = Bezeichnung_Format;
      dr["Pflicht"] = false;
      rows.push(dr);


      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "OSTAMMID";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Objekt-ID";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["OSTAMMID"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);


      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "LPID";
      dr["Fieldtyp"] = 2;
      dr["Translate"] = true;
      dr["Objekt"] = "Prüfung-ID";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["LPID"];
      }

      dr["Pflicht"] = true;
      rows.push(dr);

      dr = {};
      dr["isHeader"] = 0;
      dr["DBField"] = "LPDatum";
      dr["Fieldtyp"] = 5;
      dr["Translate"] = true;
      dr["Objekt"] = "letzte Prüfung";

      if (anlage != null) {
        dr["Bezeichnung"] = anlage["LPDatum"];
      }

      dr["Pflicht"] = false;
      rows.push(dr);

    }


    dr = {};
    dr["isHeader"] = 1;
    dr["DBField"] = "isHeader";
    dr["Fieldtyp"] = 0;
    dr["Translate"] = true;
    dr["Objekt"] = "Inspektion";
    dr["Bezeichnung"] = null;
    dr["Pflicht"] = false;
    rows.push(dr);


    dr = {};
    dr["isHeader"] = 0;
    dr["DBField"] = "IVAL_Beginn_HP";
    dr["Fieldtyp"] = 5;
    dr["Translate"] = true;
    dr["Objekt"] = "Intervallbeginn";
    dr["Bezeichnung"] = drsxmkp["IVAL_Beginn_HP"];
    dr["Pflicht"] = true;
    rows.push(dr);

    dr = {};
    dr["isHeader"] = 0;
    dr["DBField"] = "IVAL_HP";
    dr["Fieldtyp"] = 3;
    dr["Translate"] = true;
    dr["Objekt"] = "Prüfintervall";
    dr["Bezeichnung"] = drsxmkp["IVAL_HP"];
    dr["Pflicht"] = true;
    rows.push(dr);


    // if (clsSysObjOpt.Opt_I_PruefIntIsMessInt == 1)
    // {


    //     dr["isHeader"] = 0;
    //     dr["DBField"] = "IVAL_Beginn_MS";
    //     dr["Fieldtyp"] = 5;
    //     dr["Translate"] = true;
    //     dr["Objekt"] = "Intervallbeginn Messung";
    //     dr["Bezeichnung"] = drsxmkp["IVAL_Beginn_MS"];
    //     dr["Pflicht"] = true;
    //     rows.push(dr);


    //     dr["isHeader"] = 0;
    //     dr["DBField"] = "IVAL_MS";
    //     dr["Fieldtyp"] = 3;
    //     dr["Translate"] = true;
    //     dr["Objekt"] = "Intervall Messung";
    //     dr["Bezeichnung"] = drsxmkp["IVAL_MS"];
    //     dr["Pflicht"] = true;
    //     rows.push(dr);

    // }
    let dtFE = {
      columns: [],
      rows: []
    }
    Object.entries(anlage).forEach(element => {
      dtFE.columns.push({ id: element[0], bezeichnung: element[0].toUpperCase(), type: "string" });
    });
    dtFE.rows = rows;
    return dtFE;
  }

  public async setStammDaten(stammid: number, typid: number, tabelle: any): Promise<any> {
    var strid = -1;
    if (stammid == -1) {
      var id: number = 1;
      id = await db.ostamm.orderBy("OSTAMMID").last().then(x => x.ID);
      strid = parseInt(typid.toString() + id.toString().padStart(9 - typid.toString().length, "0"));


      var dbID = await db.ostamm.add({
        OSTAMMID: strid,
        OTYPID: typid,
        Startpunkt: '',
        Endpunkt: '',
        StartMeter: '0',
        EndMeter: '0',
        Bemerkung: '',
        Skizze: '',
        ZTYP: '0',
        DelStatus: 0,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      })
      db.sxmkp.add({
        OSTAMMID: strid,
        KPRUVID: 1,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      })
    } else{
      dbID = (await db.ostamm.get({OSTAMMID:stammid})).ID;
      strid = stammid;
    }
    tabelle.UserName = "";
    tabelle.data.forEach(el => {
      if(el.Bezeichnung)
        tabelle[el.DBField] = el.Bezeichnung;
    });
    delete tabelle.data;
    tabelle.Status = dayjs().format(this.nowFormat);
    delete tabelle.savedFileNames;
    delete tabelle.selectedSkizze;
    delete tabelle.delFile;
    delete tabelle.bemerkung;
    delete tabelle.passbilder;
    await db.ostamm.update(dbID, tabelle);
    // db.ostamm.update(dbID, {
    //   Bemerkung: tabelle.von.toString().replace(',', '.'),
    //   Skizze: '',
    //   UserName:  sessionStorage.getItem("User"),
    //   Status: dayjs().format(this.nowFormat)
    // })

    return {
      success: true,
      ostammid: strid,
      mediaStr: ''
    }
  }

  public async SetSpezifikationen(req, stammID: number) {
    try {
      var tabelle = req.table;
      var table = null;
      var typ = req.typ;
      if (req.smartInsert == true) {
        if (await this.InsertSpez(req.upwards, req.von, req.bis, stammID)) { }// Wenn geändert, reload table
        table = await db.getSpez(stammID, req.otypid, req.typ); // Reload without columns (wListe, sql-Befehle)
      }

      var spezid = -1;
      if (req.OSPEZID) spezid = parseInt(req.OSPEZID.toString());

      var okompid = -1;
      if (req.okompid) okompid = parseInt(req.okompid.toString());

      var keys = "";
      var values:any = {};
      if (stammID == null) {
        console.log("No Ostammid given");
      }
      if (tabelle.length > 0) {
        var ids = [spezid,spezid];
        if (spezid == -1) {
          ids = await this.Create_OSPEZ(stammID, okompid);
        } else {
          var dbID = (await db.ospez.get({OSPEZID:spezid})).ID
          ids = [dbID,spezid]
        }

        await tabelle.forEach(key => {
          var name = key.orgName?.toString();
          if (name && name != "OSPEZID") {
            var value = key.bezeichnung?.toString() ?? "";
            if (name.includes("chk"))
              values[name] = (value ? (value as boolean) : false);
            else if (name.includes("int") || name.includes("dbl"))
              values[name] = (value ? value.replace(",", ".") : 0);
            else
              values[name] = (value ? value : '');
          }
        });
      }
      if(typ== 2 || typ == 4) {
        values["s_StartMeter"] = req.von;
        values["s_EndMeter"] = req.bis;
      }
      values["OSPEZID"] = (ids[1]);
      db.ospez.update(ids[0],values);

      var id = (await db.ostamm.get({OSTAMMID:stammID}))?.ID;
      db.ostamm.update(id, {UserName:  sessionStorage.getItem("User"),Status: dayjs().format(this.nowFormat)})

      return {success: true, spezid: ids[1], table: table};
    }
    catch (ex) {
      return {success: false, error: ex.message}}
  }

  public async Create_OSPEZ(ostammid: number, okompid = -1) {
    try {
      var id: number = 1;
      id = await db.ospez.orderBy("OSPEZID").last().then(x => x.ID);
      id++;
      if (id < 100000000)
        id += (100000000);



      var newid = await db.ospez.add({
        OSPEZID: id,
        OSTAMMID: ostammid,
        OKOMPID: okompid,
        strValue01: 'New',
        UserName: sessionStorage.getItem("User"),
        BWert: 0,
        s_EndMeter: 0,
        s_StartMeter: 0,
        Status: dayjs().format(this.nowFormat)
      });

      return [newid, id];
    }
    catch (ex) {
      console.log("Fehler anlegen vom neuen Mangel (Create_OMAZS)" + ex.Message + ex.StackTrace);
      return [0,0];
    }
  }

  public async InsertSpez(upwards: boolean, von: number, bis: number, stammID: number): Promise<boolean> {

    var isChanged: boolean = false;
    try {
      var startNeu: number = von * (upwards ? 1 : -1);
      var endNeu: number = bis * (upwards ? 1 : -1);
      var dt = await db.ospez.where("OSTAMMID").equals(stammID).sortBy("s_StartMeter");
      var strSQL: string = "";
      var id: number = 0;

      await Promise.all(dt.map(async row => {
        var startRow: number = parseFloat(row["s_StartMeter"].toString()!) * (upwards ? 1 : -1);
        var endRow: number = parseFloat(row["s_EndMeter"].toString()!) * (upwards ? 1 : -1);

        if (endNeu <= startRow || startNeu >= endRow) {
          console.log("Skip: " + startRow + " : " + endRow);
        } else {
          isChanged = true;
          if (startNeu <= startRow && endNeu >= endRow) {
            console.log("Delete: " + startRow + " : " + endRow);
            //Outside

            db.ospez.where("OSPEZID").equals(row["OSPEZID"]).delete();
            db.oherst.where("OSPEZID").equals(row["OSPEZID"]).delete();
          }
          else if (startNeu > startRow && endNeu < endRow) {
            console.log("Insert & Update: " + startRow + " : " + endRow);
            //Both inside

            strSQL = "SELECT MAX(OSPEZID) FROM ospez";
            id = await db.ospez.orderBy("OSPEZID").last().then(x => x.ID);
            id++;
            if (id < 100000000)
              id += 100000000;
            var values: any =
              {
                OSPEZID:id,
                OSTAMMID: stammID,
                s_StartMeter: bis.toString().replace(",", "."),
                s_EndMeter: endRow.toString().replace(",", "."),
                UserName: sessionStorage.getItem("User"),
                Status: dayjs().format(this.nowFormat)
              };
            await Promise.all(Object.entries(row).map(item => {
              var colName: string = item[0];
              if (colName != "ID" && colName != "OSPEZID" && colName != "OSTAMMID" &&colName != "s_StartMeter" &&colName != "UserName" &&colName != "Status" && row[colName] && row[colName] != null) {
                values[colName] = row[colName].toString()!.replace(",", ".");
              }
            }));
            var newID = await db.ospez.add(values);

            await db.ospez.update(row["ID"],{
              s_EndMeter:von,
              UserName: sessionStorage.getItem("User"),
              Status: dayjs().format(this.nowFormat) });
          }
          else if (startNeu <= startRow && endNeu < endRow) {
            console.log("Update (start): " + startRow + " : " + endRow);
            //End inside

            await db.ospez.update(row["ID"],{
              s_StartMeter:bis,
              UserName: sessionStorage.getItem("User"),
              Status: dayjs().format(this.nowFormat) });
          }
          else if (startNeu > startRow && endNeu >= endRow) {
            console.log("Update (end): " + startRow + " : " + endRow);
            //Start inside

            await db.ospez.update(row["ID"],{
              s_EndMeter:von,
              UserName: sessionStorage.getItem("User"),
              Status: dayjs().format(this.nowFormat) });
          }
        }

      }));
      return true;
    }
    catch (ex) {
      console.log(ex.Message);
    }
    return isChanged;
  }

  public async getBauwerke(stammID: number) {
    var res = await db.obauw.where("OSTAMMID").equals(stammID).toArray();
    return this.parseToFrontEnd(res,true,this.aliasBauwerke);
  }
  public async getBauwArten(OTYPID: number) {
    var res = await db.sbauw.where("OTYPID").equals(OTYPID).toArray();
    var lst = Promise.all(res.map((x)=> x.Bezeichnung));
    return lst;
  }
  public async SetBauwerke(req, stammID: number): Promise<any> {
    var tabelle = req.table;
    var values = [];
    if (stammID == null || tabelle) {
      console.log("No Ostammid given");
    }
    var ID: number = req.ID;
    if (req.DoInsert != null && req.DoInsert) {
      ID = await db.obauw.add({
        b_StartMeter: tabelle.von.toString().replace(',', '.'),
        b_EndMeter: tabelle.bis.toString().replace(',', '.'),
        b_Art: tabelle.bauwerksart,
        b_Bezeichnung: tabelle.bezeichnung,
        b_GPS: tabelle.gpskoordinaten,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat),
        b_Lage: tabelle.lage,
        OSTAMMID: stammID
      })
    }
    else {
      await db.obauw.update(req.ID.toString(), {
        b_StartMeter: tabelle.von.toString().replace(',', '.'),
        b_EndMeter: tabelle.bis.toString().replace(',', '.'),
        b_Art: tabelle.bauwerksart,
        b_Bezeichnung: tabelle.bezeichnung,
        b_GPS: tabelle.gpskoordinaten,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat),
        b_Lage: tabelle.lage,
        OSTAMMID: stammID
      })
      values.push();
    }

    var ids = (await db.ostamm.get({OSTAMMID:stammID}))?.ID;
    db.ostamm.update(ids, {UserName:  sessionStorage.getItem("User"),Status: dayjs().format(this.nowFormat)})
    var neuRow = {
      ID,
      UserName: sessionStorage.getItem("User"),
      Status: dayjs().format(this.nowFormat),
      bild: null
    }
    return {neuRow, status:"OK"};
  }

  public async DeleteObject(id: number, table: string) {
    try {
      await db[table.toLowerCase()].delete(id);
      return {success: true, status: "success"};
    }
    catch (ex) {
      return {success: false, error:ex.message, status: "error"};
    }

  }
  public async DeleteObjectWhere(id: number, field: string, table: string) {
    try {
      var dt = await db[table.toLowerCase()].where(field).equals(id).delete();
      return {success: true};
    }
    catch (ex) {
      return {success: false, error:ex.message};
    }

  }
  public ArchiveObject(id: number, table: string) {
    try {
      db[table].update(id, { Delstatus: 1 });
      return {success: true};
    }
    catch (ex) {
      return {success: false, error:ex.Message};
    }

  }

  public async SetMangel(stammid: number, pruefid: number, typid: number, body: any) {
    var mangid: number = await this.Create_Mangel(stammid, pruefid, typid, 0);
    var FKHand: number = 0;
    if (body.autofk != null) {
      if (body.fehlerklasse.toString() != body.autofk.toString())
        FKHand = 1;
    }
    if(!pruefid) {
      pruefid = (await db.ostamm.get({OSTAMMID:stammid})).LPID;
    }

    var form = body.form;
    db.omazs.update(mangid, {
      StartMeter: form.von,
      EndMeter: form.bis,
      Gattung: form.gattung,
      Stamm: form.stamm,
      Pruefart: form.pruefart,
      Lage: form.lage,
      Mangel: form.mangel,
      Umfang: form.umfang,
      Mengeneinheit: form.mengeneinheit,
      Instandsetzung: form.instandsetzungshinweis,
      Betriebsgefahr: form.betriebsgefahr,
      FK: form.fehlerklasse,
      Bemerkung: form.bemerkung,
      zuAbt: form.abteilung,
      ProzentFaktor: form.faktor,
      FK_Hand: FKHand,
      SZUMZID: body.szumzid,
      UserName: sessionStorage.getItem("User"),
      Status: dayjs().format(this.nowFormat)
    })

    var mang = await db.omazs.get( {ID:mangid});
    return mang;
  }

  public async Create_Mangel(stammid: number, pruefid: number, typid: number, martid: number, pruefart: string = "NEU", lfnr: number = 0, nIO: string = 'true'): Promise<number> {
    var id: number = 1;
    id = await db.omazs.orderBy("OMAZSID").last().then(x => x.ID);
    var strid = parseInt(typid.toString() + id.toString().padStart(9 - typid.toString().length, "0"));
    var newID = -1;

      newID = await db.omazs.add({
        OMAZSID: strid,
        OSTAMMID: stammid,
        OPRUEFID: pruefid,
        OMARTID: martid,
        lfNr: lfnr,
        Betriebsgefahr: 0,
        nIO: nIO,
        optUrsacheGrund: false,
        StartMeter: 0,
        EndMeter: 0,
        Pruefart: pruefart,
        ParentID: 0,
        Mangel: '',
        Lage: '',
        Erledigt: false,
        Auftrag: false,
        M_Datum: null,
        E_Datum: null,
        Termin: null,
        SAPExport: false,
        SAPEckende: null,
        SAPAusfall: false,
        IntAuftragNr: 0,
        SAPEckendeNeu: null,
        S: 0,
        V: 0,
        D: 0,
        SZUMZID: 0,
        Ex_Infralife: false,
        UpdateZEDAS: false,
        KAVAAE: 0,
        KAVAW: 0,
        Faktor: 0,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      })
      await db.omzlog.add({
        OMAZSID: strid, LogTyp: "Neu", AuftragNr: '0', UserName: sessionStorage.getItem("User"), Status: dayjs().format(this.nowFormat)
      })


    return newID;
  }

  public async UpdateMangel(omazsid: number, status: string, req: any) {

    var id = omazsid == -1 ? -1 : (await db.omazs.get({OMAZSID:omazsid}))?.ID;
    switch (status.toLowerCase()) {
      case "updatelfnr":
        await Promise.all(req.items.map(element => {
          db.omazs.update(id, { lfNr: element["lfNr"] })
        }));
        break;
      case "deleteitems":
        var lstDelID = [];

        Promise.all(req.deleteIDs.forEach(async el => {
          var dtMazs = (await db.omazs.get({OMAZSID:el}))?.ID;
          if(dtMazs) lstDelID.push(dtMazs);
        }));
        await db.omazs.bulkDelete(lstDelID);
        break;
      case "updatetoempty":
        await Promise.all(req.clearIDs.map(element => {
          db.omazs.update(id, { lfNr: element["lfNr"] })
        }));
        break;
      case "updatetochecklist":
        var ostammid = parseInt(req.ostammid.toString());
        var name: string = req.checklistname;
        var otypid = parseInt(req.otypid.toString());
        var pruefarte: string[] = req.pruefarte;
        // var pruefartAll =
        const pruefArtAll = await db.kstpvg.where("OTYPID").equals(otypid).and(x => x.Titel == name).toArray();
        await Promise.all(pruefArtAll.map(async pr => {
          [pr.Bezeichnungen] = await Promise.all([
            db.sprue.get(pr.PruefartID)
          ]);
        }));
        await Promise.all(pruefArtAll[0].Bezeichnungen.forEach(async pr => {
          if(pruefarte.includes(pr["Bezeichnung"].toString())) {
            lfNr = lfNr + 1;
          } else {
            id = await this.Create_Mangel(ostammid, pruefid, otypid, 1, pr["Bezeichnung"].toString(),lfNr,'false')
          }

        }));
        await Promise.all(req.clearIDs.map(element => {
          db.omazs.update(id, { lfNr: element["lfNr"] })
        }));
        break;
      case "addchecklist":
        var ostammid = parseInt(req.ostammid.toString());
        var name: string = req.checklistname;
        var otypid = parseInt(req.otypid.toString());
        var pruefarte: string[] = req.pruefarte;

        var lstDelID = [];
        var dtMazs = await db.omazs.where("OPRUEFID").equals(pruefid).and(x => x.OMARTID == 1).toArray()
        dtMazs.forEach(el => {
          lstDelID.push(el.ID);
        });
        db.omazs.bulkDelete(lstDelID);
        // var pruefartAll =
        var lfNrnew = 1;
        req.clearIDs.forEach(async element => {
          await this.Create_Mangel(ostammid, pruefid, otypid, 1, element["Pruefart"], lfNrnew, 'false');
          lfNrnew = lfNrnew + 1;
        });
        break;
      case "deletemultipleitems":
        await Promise.all(req.clearIDs.map(element => {
          db.omazs.update(id, { lfNr: element["lfNr"] })
        }));
        await db.omazs.bulkDelete(req.deleteID);
        break;
      case "update":
        var typ = parseInt(req.otypid.toString());
        var pruefid = parseInt(req.pruefid.toString());
        var body = req.vals;
        var isPoint : boolean = false;
        var isPointNIO: boolean = false;

        if (id < 1) {
          if (typ == 1 || typ == 3) {
            isPoint = true;
            if (body.nio = false) isPointNIO = true;
            var lfNr = req.vals.lfNr != null ? req.vals.lfNr : -1;
            id = await this.Create_Mangel(req.ostammid, pruefid, typ, 1, 'NEU', lfNr);
          } else {
            id = await this.Create_Mangel(req.ostammid, pruefid, typ, 1, 'NEU', lfNr);
            var pruefs = this.parseToFrontEnd(await db.omazs.where("OPRUEFID").equals(pruefid).and(x => x.OMARTID == 1).toArray())
            var lfNrnew = 1;
            await Promise.all(pruefs.rows.map(element => {
              db.omazs.update(id, { lfNr: element["lfNR"] })
              lfNrnew++;
            }));
          }
        }

        var FKHand: number = 0;
        if (body.autofk != null) {
          if (body.fehlerklasse.toString() != body.autofk.toString())
            FKHand = 1;
        }

        await db.omazs.update(id, {
          StartMeter: body.von ?? null,
          EndMeter: body.bis ?? null,
          Gattung: body.gattung,
          Stamm: body.stamm ?? null,
          nIO: body.nIO ?? null,
          Pruefart: body.pruefart,
          Lage: isPointNIO ? null: body.lage,
          Mangel: isPointNIO ? null: body.mangel,
          Umfang: isPointNIO ? null: body.umfang,
          Mengeneinheit: isPointNIO ? null: body.mengeneinheit,
          Instandsetzung: isPointNIO ? null: body.instandsetzungshinweis,
          Betriebsgefahr: isPointNIO ? null: body.betriebsgefahr,
          FK: isPointNIO ? -1: body.fehlerklasse,
          Bemerkung: isPointNIO ? null: body.bemerkung,
          zuAbt: isPointNIO ? null: body.abteilung,
          ProzentFaktor: isPointNIO ? null: body.faktor,
          FK_Hand: isPointNIO ? 0 : FKHand,
          SZUMZID: isPointNIO ? 0 : body.szumzid ?? 0,
          UserName: sessionStorage.getItem("User"),
          Status: dayjs().format(this.nowFormat)
        })
        break;
      default:
        break;
    }
    var mang = (typ == 2 || typ == 4) ?
      this.parseToFrontEnd(this.Select((await db.omazs.toArray()).filter(x=>x.OPRUEFID == pruefid && x.Mangel != null && x.Erledigt == false),this.selectMangelAnl),false,this.aliasMangel)
      :
      this.parseToFrontEnd(this.Select((await db.omazs.toArray()).filter(x=>x.OPRUEFID == pruefid && x.OMARTID == 1),this.selectMangelAnl),false,this.aliasMangel);
    return { mangel: mang, omazsid: id };

  }

  public async initMangelbaum(stammid: number, otypid: number, typid : number) {
    var itemsMangelbaum = [];
    var dr = (await db.sysini.toArray())[0];
    var sysoopt = (await  db.sysoopt.where("OTYPID").equals(otypid).toArray())[0];
    var opt_fk = sysoopt.Opt_I_Fehlerklasse;
    var minFK = parseInt(dr["optMINNumFK"]);
    itemsMangelbaum["useFK"] = opt_fk;
    itemsMangelbaum["sperrFK"] = parseInt(dr["optDownChangeFK"]) == 0;
    itemsMangelbaum["gefahrFK"] = parseInt(dr["optFk1_IstGleich_BG"]) == 1;
    itemsMangelbaum["minfk"] = minFK;
    itemsMangelbaum["optzuabt"] = sysoopt.Opt_I_Zustaend_Abt;

    var fk = [];

    for (var i = minFK; i <= parseInt(dr["optMAXNumFK"]); i++)
    {
        fk.push(i.toString());
    };

    itemsMangelbaum["fehlerklassen"] = fk;


    var dtMeh = db.smeh;
    dtMeh.add({ID:0, Bezeichnung:'',UserName: sessionStorage.getItem("User"),Status: dayjs().format(this.nowFormat)});
    var dtgat = (await db.sgatt.toArray()).filter(x=>x.OTYPID == otypid);
    if (typid == 4)
    {
      var spez = await db.ospez.where("OSTAMMID").equals(stammid).toArray();
      await Promise.all( spez.map(async (x) => {
        var sschf = await db.sschf.get({ Bezeichnung: x.strValue02 });
        return x.strValue02 = sschf?.Typ;
      }));
      itemsMangelbaum["stammspez"] = spez;
    }
    itemsMangelbaum["mengeneinheit"] = this.parseToFrontEnd(await dtMeh.toArray());
    itemsMangelbaum["gattung"] = this.parseToFrontEnd(dtgat);

    //strSQL = "SELECT (szumz.PruefartID) as ID, sprue.Bezeichnung FROM sprue INNER JOIN szumz ON sprue.ID = szumz.PruefartID  WHERE szumz.OTYPID= " + otypid + " GROUP BY szumz.PruefartID, sprue.Bezeichnung ORDER BY sprue.Bezeichnung;";
    // const szumzRepo = await db.szumz.where("OTYPID").equals(otypid).toArray();
    // szumzRepo.map(man => {
    //   man.Gattung = sprue.find(x=>x.ID = man.GattungID) ? sgatt.find(x=>x.ID = man.GattungID).Bezeichnung : '';
    // });


    if(typid == 4) {
      var stammitems = [];
      stammitems["schiene"] = this.parseToFrontEnd([{ID:1,Bezeichnung:"Vignol"},{ID:2, Bezeichnung: "Rille"}]);
      stammitems["befestigung"] = this.parseToFrontEnd(await db.sbeft.orderBy("Bezeichnung").toArray());
      stammitems["schwellenart"] = this.parseToFrontEnd(await db.sschw.orderBy("Bezeichnung").toArray());
      stammitems["bettung"] = this.parseToFrontEnd([{ID:1,Bezeichnung:"Schotterbett"},{ID:2, Bezeichnung: "Unterbau"}]);
      stammitems["eindeckung"] = this.parseToFrontEnd(await db.seind.orderBy("Bezeichnung").toArray());
      stammitems["bauwerke"] = this.parseToFrontEnd(await db.sbauw.where("Pruefung").equals(1).sortBy("Bezeichnung"));
      stammitems["regellichtraumprofil"] = this.parseToFrontEnd([{ID:1,Bezeichnung:"Lichtraumprofil"}]);
      stammitems["schienenverbindung"] = this.parseToFrontEnd([{ID:1,Bezeichnung:"Schweißstöße"},{ID:2, Bezeichnung: "Laschenverbindung"},{ID:[3],Bezeichnung:"ISO-Stoß"}]);
      stammitems["grundinstandsetzung"] = this.parseToFrontEnd([{ID:1,Bezeichnung:"Grundinstandsetzung"}]);
      stammitems["messwerte"] = this.parseToFrontEnd([{ID:1,Bezeichnung:"Spurweite"},{ID:2, Bezeichnung: "Verwindung"}, {ID:3, Bezeichnung: "Richtung"},{ID:4, Bezeichnung: "Neigung"},{ID:5, Bezeichnung: "Überhöhung"}]);
      stammitems["stammitems"] = this.parseToFrontEnd(await db.sstamm.where("OTYPID").equals(otypid.toString()).sortBy("Bezeichnung"),true);

      itemsMangelbaum["stamm"] = stammitems;

      if(stammid > 0) {
        var spezL = this.getSpez(stammid, otypid,typid);
        itemsMangelbaum["spez"] = spezL;
        stammitems["sschf"] = await db.sschf.toArray();
      }

    }
    //dtTree wird hier nicht gefüllt - schließlich ist das nur für die Anzeige im Mangelbuamdialog und die Offline-Version verwendet diese nicht
    // if(typid != 4) {

    // }
    const sgatt = await db.sgatt.toArray();
    const smang = await db.smang.toArray();
    const smeh = await db.smeh.toArray();
    const sinst = await db.sinst.toArray();
    const sprue = await db.sprue.toArray();
    const slage = await db.slage.toArray();
    const szumz = (await db.szumz.where("OTYPID").equals(otypid).toArray());
      // .filter(man=> sgatt.find(x=>x.ID = man.GattungID) && smang.find(x=>x.ID = man.MangelID) && smeh.find(x=>x.ID = man.MengeneinheitID) &&
      //               sinst.find(x=>x.ID = man.InstandsetzungID) && sprue.find(x=>x.ID = man.PruefartID) && slage.find(x=>x.ID = man.LageID));
    szumz.map(man => {
      man.Gattung = sgatt.find(x=>x.ID == man.GattungID) ? sgatt.find(x=>x.ID == man.GattungID).Bezeichnung : '';
      man.Mangel = smang.find(x=>x.ID == man.MangelID) ? smang.find(x=>x.ID == man.MangelID).Bezeichnung : '';
      man.Mengeneinheit = smeh.find(x=>x.ID == man.MengeneinheitID) ? smeh.find(x=>x.ID == man.MengeneinheitID).Bezeichnung : '';
      man.Instandsetzung = sinst.find(x=>x.ID == man.InstandsetzungID) ? sinst.find(x=>x.ID == man.InstandsetzungID).Bezeichnung : '';
      man.Pruefart = sprue.find(x=>x.ID == man.PruefartID) ? sprue.find(x=>x.ID == man.PruefartID).Bezeichnung : '';
      man.Lage = slage.find(x=>x.ID == man.LageID) ? slage.find(x=>x.ID == man.LageID).Bezeichnung : '';
    });
    itemsMangelbaum["grid"] = this.parseToFrontEnd(szumz);

    var spruez = sprue.map(pru => {
      var mz = szumz.filter(x=>x.PruefartID == pru.ID && x.OTYPID == 1)[0];
      return mz ?   {ID:mz.PruefartID, Bezeichnung: pru.Bezeichnung} : null;
    });
    spruez = spruez.filter(n => n); // Entfernt die nulls
    itemsMangelbaum["pruefart"] = this.parseToFrontEnd(spruez);
    return itemsMangelbaum;
  }

  public async updateMangelbaum(req:any) : Promise<any>  {
    if (req.status == "newfrominspektion")
    {
        var otypid = parseInt(req.otypid.toString());
        var typid = parseInt(req.typ.toString());

        var _GaID =  parseInt(req.gattid.toString());
        var _StID = parseInt(req.stammid.toString());

        var items = req.form;


        var gatt : string = items.gattung ?? "";
        var stamm : string = items.stamm ?? "";
        var po : string = items.pruefart.toString();
        var lg : string = items.lage.toString();
        var mg : string = items.mangel.toString();
        var ih : string = items.instandsetzungshinweis.toString();
        var fk : string = items.fehlerklasse.toString();
        var abt : string = items.abteilung ?? "";
        var meh : string = items.mengeneinheit ?? "";

        var _MeID : number = 0;
        var _PoID = await this.MangelbaumGetIDs(otypid, "SPRUE", po);
        var _LgID = 0;

        if (typid == 1 || typid == 3)
            _LgID = await this.MangelbaumGetIDs(otypid, "SLAGE", lg);


        var _MgID = await this.MangelbaumGetIDs(otypid, "SMANG", mg);
        var _InID = await this.MangelbaumGetIDs(otypid, "SINST", ih);

        var szumzid = items.szumzid ? parseInt(items.szumzid.toString()) : 0;

        //Mengeneinheit kann doch gar nicht ausgewählt werden?
        // if (meh != "")
        //     _MeID = await this.MangelbaumGetIDs(otypid, "SMEH", meh);

        var strSQL : string = "SELECT szumz.ID, szumz.GattungID, szumz.StammID, szumz.PruefartID, szumz.LageID, szumz.MangelID, szumz.InstandsetzungID, szumz.MengeneinheitID, (sprue.Bezeichnung) as Prüfart, (slage.Bezeichnung) as Lage, (smang.Bezeichnung) as Mangel, (sinst.Bezeichnung) as Instandsetzung, (smeh.Bezeichnung) as ME, szumz.BedAusloeserMin, szumz.BedMin, szumz.BedVerbindung, szumz.BedAusloeserMax, szumz.BedMax, szumz.BedEinheit, szumz.FK, szumz.EP, szumz.LVPos, szumz.zuAbt, szumz.Equipment, szumz.UserName, szumz.Status, szumz.AAE, szumz.AW, szumz.KAV_ME, szumz.S, szumz.V, szumz.D FROM ((((szumz LEFT JOIN sprue ON szumz.PruefartID = sprue.ID) LEFT JOIN slage ON szumz.LageID = slage.ID) LEFT JOIN smang ON szumz.MangelID = smang.ID) LEFT JOIN sinst ON szumz.InstandsetzungID = sinst.ID) LEFT JOIN smeh ON szumz.MengeneinheitID = smeh.ID WHERE szumz.ID=" + szumzid.toString() + " and Not sprue.Bezeichnung is null and Not slage.Bezeichnung is null and Not smang.Bezeichnung is null and Not sinst.Bezeichnung is null ORDER BY szumz.PruefartID;";

        if (typid == 2 || typid == 4)
        {
            strSQL = "SELECT szumz.ID, szumz.GattungID, szumz.StammID, szumz.PruefartID, szumz.LageID, szumz.MangelID, szumz.InstandsetzungID, szumz.MengeneinheitID, (sprue.Bezeichnung) as Prüfart, (slage.Bezeichnung) as Lage, (smang.Bezeichnung) as Mangel, (sinst.Bezeichnung) as Instandsetzung, (smeh.Bezeichnung) as ME, szumz.BedAusloeserMin, szumz.BedMin, szumz.BedVerbindung, szumz.BedAusloeserMax, szumz.BedMax, szumz.BedEinheit, szumz.FK, szumz.EP, szumz.LVPos, szumz.zuAbt, szumz.Equipment, szumz.UserName, szumz.Status, szumz.AAE, szumz.AW, szumz.KAV_ME, szumz.S, szumz.V, szumz.D FROM ((((szumz LEFT JOIN sprue ON szumz.PruefartID = sprue.ID) LEFT JOIN slage ON szumz.LageID = slage.ID) LEFT JOIN smang ON szumz.MangelID = smang.ID) LEFT JOIN sinst ON szumz.InstandsetzungID = sinst.ID) LEFT JOIN smeh ON szumz.MengeneinheitID = smeh.ID WHERE szumz.ID=" + szumzid.toString() + " and Not sprue.Bezeichnung is null and Not smang.Bezeichnung is null and Not sinst.Bezeichnung is null ORDER BY szumz.PruefartID;";
        }

        const sgatt = await db.sgatt.toArray();
        const smang = await db.smang.toArray();
        const smeh = await db.smeh.toArray();
        const sinst = await db.sinst.toArray();
        const sprue = await db.sprue.toArray();
        const slage = await db.slage.toArray();
        const dtszumz = (await db.szumz.where("ID").equals(szumzid).toArray());
          // .filter(man=> sgatt.find(x=>x.ID = man.GattungID) && smang.find(x=>x.ID = man.MangelID) && smeh.find(x=>x.ID = man.MengeneinheitID) &&
          //               sinst.find(x=>x.ID = man.InstandsetzungID) && sprue.find(x=>x.ID = man.PruefartID) && slage. find(x=>x.ID = man.LageID));
        dtszumz.map(man => {
          man.Gattung = sgatt.find(x=>x.ID == man.GattungID) ? sgatt.find(x=>x.ID == man.GattungID).Bezeichnung : '';
          man.Mangel = smang.find(x=>x.ID == man.MangelID) ? smang.find(x=>x.ID == man.MangelID).Bezeichnung : '';
          man.Mengeneinheit = smeh.find(x=>x.ID == man.MengeneinheitID) ? smeh.find(x=>x.ID == man.MengeneinheitID).Bezeichnung : '';
          man.Instandsetzung = sinst.find(x=>x.ID == man.InstandsetzungID) ? sinst.find(x=>x.ID == man.InstandsetzungID).Bezeichnung : '';
          man.Pruefart = sprue.find(x=>x.ID == man.PruefartID) ? sprue.find(x=>x.ID == man.PruefartID).Bezeichnung : '';
          man.Lage = slage.find(x=>x.ID == man.LageID) ? slage.find(x=>x.ID == man.LageID).Bezeichnung : '';
        });

        var preis : string = "";
        var lv : string = "";
        var equi : string = "";
        if (dtszumz.length > 0)
        {
            var r = dtszumz[0];
            preis = r["EP"].toString();
            lv = r["LVPos"].toString();
            equi = r["Equipment"].toString();

        }

        //szumzid = DBCon.Create_MangelbaumItem(user, otypid);
        await db.szumz.add({
          OTYPID: otypid,
          GattungID: _GaID,
          StammID: 0,
          PruefartID: _PoID,
          LageID: _LgID,
          MangelID: _MgID,
          InstandsetzungID: _InID,
          FK: fk,
          EP: preis ? preis.replace(",", ".") : 0.00,
          LVPos: lv,
          zuAbt: abt,
          MengeneinheitID: _MeID,
          Equipment: equi.replace("'", "''"),
          UserName: sessionStorage.getItem("User"),
          Status: dayjs().format(this.nowFormat)
        })
        return { status: "OK" };

    }

  }

  public async MangelbaumGetIDs(otypid: number, table: string, value: string)
{
    try
    {
        //var strSQL = "SELECT id FROM " + table.toLowerCase() + " WHERE OTYPID=" + otypid.toString() + " AND Bezeichnung='" + value + "';";
        var id = (await (db.sgatt.get({OTYPID:otypid, Bezeichnung:value})))?.ID;

        if(!id)
        {
          //return DBCon.Create_BaseDBItem(user, otypid, table, value, "");
          switch (table.toUpperCase()) {
            case "SSCHF":
              id = await db.sschf.add({
                Bezeichnung:value,
                HV_Max:0,
                UserName: sessionStorage.getItem("User"),
                Status: dayjs().format(this.nowFormat)
              })
              break;

            case "SGATT":
            case "SSTAMM":
            case "SPRUE":
            case "SLAGE":
            case "SMANG":
            case "SINST":
              id = await db[table.toLowerCase()].add({
                Bezeichnung:value,
                OTYPID:otypid,
                UserName: sessionStorage.getItem("User"),
                Status: dayjs().format(this.nowFormat)
              })
              break;

            case "SBAUW":
              id = await db.sbauw.add({
                OTYPID:otypid,
                Bezeichnung:value,
                GetRailDeep:false,
                VisualDiagramm:false,
                DiaWert:false,
                Pruefung:false,
                UserName: sessionStorage.getItem("User"),
                Status: dayjs().format(this.nowFormat)
              })
            case "SWTYP":
              id = await db.swtyp.add({
                Bezeichnung:value,
                TypID:otypid,
                UserName: sessionStorage.getItem("User"),
                Status: dayjs().format(this.nowFormat)
              })
              break;
            }

            if(table.toUpperCase() == "SGATT") {
              var intid = await (db.sgatt.where("OTYPID").equals(otypid).limit(1).reverse().toArray())[0].intID;
              db.sgatt.update(id,{IntID:intid})
            }
            return id ?? 0;
        }
        else
        {
            return parseInt(id.toString());
        }

    }
    catch (ex)
    {
      ex = ex;
    }
    return 0;
}

  public async SetMangelPruefung(ostammid: number, oldpruefid: number, typID: number, typ: number) {

    var newpruefid: string = await this.CreatePruefung(ostammid, oldpruefid, typID, typ, 1);
    var mang = (typ == 2 || typ == 4) ?
      this.parseToFrontEnd(this.Select((await db.omazs.toArray()).filter(x=>x.OPRUEFID == parseInt(newpruefid) && x.Mangel != null && x.Erledigt == false),this.selectMangelAnl),false,this.aliasMangel)
      :
      this.parseToFrontEnd(this.Select((await db.omazs.toArray()).filter(x=>x.OPRUEFID == parseInt(newpruefid) && x.OMARTID == 1),this.selectMangelAnl),false,this.aliasMangel);
    return { mangel: mang, newpruefid};
  }
  public async CreatePruefung(ostammid: number, oldpruefid: number, typID: number, typ: number, kprtypid: number): Promise<string> {
    var id;
    if (oldpruefid > 0) {
      var dbPruef = (await db.opruef.toArray()).filter(x=>x.OPRUEFID == oldpruefid)[0];
      // var colList = [];
      // dbPruef.columns.forEach(col => {
      //   if(col[0] != "id") colList.push(col[0])
      // });
      var pruefClone = JSON.parse(JSON.stringify(dbPruef));
      delete pruefClone.ID;
      Object.entries(pruefClone).forEach(element => {
        if(element[1] == null) delete pruefClone[element[0]];
      });

      id = await db.opruef.add(pruefClone);
    } else {
      id = await db.opruef.add({
        OSTAMMID: ostammid,
        P_DelStatus: 0,
        KPRTYPID: kprtypid,
        BWPZN: 0,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      })
    }

    var pruefIDNew = typID.toString() + id.toString().padStart(7-typID.toString().length, "0");
    await db.opruef.update(id, { OPRUEFID: parseInt(pruefIDNew), P_IntStatus: 'Hauptprüfung', P_Datum: dayjs().format(this.nowFormat),UserName: sessionStorage.getItem("User"),Status: dayjs().format(this.nowFormat) }),
    await db.omazs.where("OPRUEFID").equals(pruefIDNew).modify({ S: 0,V: 0,D: 0 });

    var dtMazs;
    if (typ == 2 || typ == 4) {
      dtMazs = (await db.omazs.toArray()).filter(x=>x.OPRUEFID == oldpruefid && x.Erledigt == false);
    } else {
      dtMazs = (await db.omazs.toArray()).filter(x=>x.OPRUEFID == oldpruefid && x.OMARTID == 1);
    }

    //Lösche Duplikate
    if (typ == 1 || typ == 3) {
      var lstDelID: number[] = [];
      dtMazs.forEach(el => {
        lstDelID.push(el.ID);
      });
       await db.omazs.bulkDelete(lstDelID);
    }

    //Kopieren von alter auf neue Prüfung
    var lfNr = 1;
    await Promise.all(dtMazs.map(async el => {
        el.lfNR = lfNr;
        lfNr++;
        delete el.ID;

        var idNewMasz = await db.omazs.add(el);
        var idNewStr = typID.toString() + idNewMasz.toString().padStart(9 - typID.toString().length, "0");
        db.omazs.update(idNewMasz, { OPRUEFID: parseInt(pruefIDNew), OMAZSID: parseInt(idNewStr), UserName: sessionStorage.getItem("User"), Status: dayjs().format(this.nowFormat) });
        db.omzlog.add({
          OMAZSID: id, LogTyp: "Neu", AuftragNr: '0', UserName: sessionStorage.getItem("User"), Status: dayjs().format(this.nowFormat)
        })
        await this.setLPID(ostammid, kprtypid);
    }))
    .catch((error) => {console.log(error)});

    return pruefIDNew;
  }

  public async setUpdatePruefung(req) : Promise<any> {

    var status: string = req.status.toString();
    var opruefid = parseInt(req.pruefid.toString());
    var ostammid = parseInt(req.ostammid.toString());
    var dbID = (await db.opruef.get({OPRUEFID:opruefid})).ID
    var dbIDS = (await db.ostamm.get({OSTAMMID:ostammid})).ID

    if (status.toLowerCase() == "setinspektionsdatum") {
      var datum = req.datum.toString();
      await db.opruef.update(dbID, { P_Datum: datum });
      await db.ostamm.update(dbIDS, { LPDatum: datum });
    }

    if (status.toLowerCase() == "deleteinspektion") {
      await db.opruef.update(dbID, { P_DelStatus: 1 });
      await this.setLPID(ostammid, 1);

    }

    if (status.toLowerCase() == "deleltepruefung") {
      await db.opruef.delete(dbID);
      await this.setLPID(ostammid, 1);
    }


    return { status: "OK" };
  }

  public async Create_OPRUEF(ostammid : number, kprtypid : number, otypid : number, P_Date, P_IntStatus = "", setTime = false) : Promise<number>
  {
      var newopruefid = 0;
      try
      {
        var id = await db.opruef.add({
          OSTAMMID: ostammid,
          P_DelStatus: 0,
          P_IntStatus: P_IntStatus,
          KPRTYPID: kprtypid,
          BWPZN: 0,
          UserName: sessionStorage.getItem("User"),
          Status: dayjs().format(this.nowFormat)
        })

          var idLen = id.toString().length;
          var pruefIDNew = otypid.toString() + id.toString().padStart(7-otypid.toString().length, "0");
          await db.opruef.update(id, { OPRUEFID: parseInt(pruefIDNew), P_IntStatus: 'Hauptprüfung', P_Datum: dayjs().format(this.nowFormat)});

          return parseInt(pruefIDNew);
      }
      catch (ex)
      {
          ex = ex
      }
  }

  public async getMessungAuswertung(req: any) : Promise<any> {
    switch (req.status) {
      case "inspektion":
        var pruef :ORPUEF[] = (await db.opruef.where("OSTAMMID").equals(req.ostammid).limit(5).sortBy("P_Datum")).reverse();
        var messArr = await db.omess.toArray();
        var pruefArr = await db.opruef.toArray();
        if(pruef.length > 0) {
          var pruefid:number = pruef[0].OPRUEFID;
          var mess = messArr.filter(x=> x.ZTYP == 0 && x.OPRUEFID == pruefid);
          var messFE = this.parseToFrontEnd(mess,false,{SR100plus: 'SR 100 +',SR100minus: 'SR 100 -',SRlimplus: 'SR lim +',SRlimminus: 'SR lim -',SRGplus: 'SRG +',SRGminus: 'SRG -'})
          if(messFE == null) return null;
          var messHistoric = messArr.filter(x=> x.ZTYP == 0 && x.OPRUEFID == pruefid);
          messHistoric = await Promise.all(messHistoric.map(async (x) => {x.x_pruef_date = (pruefArr.find(y=>y.OPRUEFID == x.OPRUEFID)).P_Datum; return x;}));

          var existingDates = [];
          messFE.rows.forEach(mess => {
            messHistoric.forEach(his => {
              if(his["Bezeichnung"] == mess["Bezeichnung"] && his["OPRUEFID"] != pruefid) {
                  var date = his["x_pruef_date"];
                  var tolVerl = "Tol-Verl:" + date;
                  mess[date] = his["ist"];
                  mess[tolVerl] = his["tolverl"];
                  if(!existingDates.includes(date)) {
                    var dc = messFE.columns;
                    dc.splice(12,0,{id:date.toUpperCase(),bezeichnung:date});
                    dc.splice(13,0,{id:tolVerl.toUpperCase(),bezeichnung:tolVerl});
                    existingDates.push(date);
                  }
              }
            });
          });

          return messFE;
        }
        break;
        case "getmess":
          var pruefids : string = req.pruefid.join(",");
          var stamm = db.ostamm.get({OSTAMMID:req.ostammid});
          var tol = await db.ktolr.get({ID:1});
          var kbezng = this.parseToFrontEnd(await db.kbezng.toArray());
          var a = this.getstammfield(stamm,"Bezirk");
          var b = this.getstammfield(stamm,"Stellwerk");
          var c = this.getstammfield(stamm,"MessRegBezirk");
          var mess = (await db.omess.toArray()).filter(x=>pruefids.includes(x.OPRUEFID.toString()) && x.SZAEHNID == 0);
          mess = await Promise.all(mess.map(x=> {[x.x_bezirk,x.x_stellwerk,x.x_messregbezirk] = [a,b,c];return x}));
          var messFE = this.parseToFrontEnd(mess,false,
            {SR100plus: tol.TEXT_100_p,SR100minus: tol.TEXT_100_m,SRlimplus: tol.TEXT_L_p,SRlimminus: tol.TEXT_L_m,SRGplus: tol.TEXT_G_p,SRGminus: tol.TEXT_G_m})

        break;
        case "getcount":

        break;
      default:
        break;
    }
  }

  public async getAnlagenHistorie(id: number) {
    var historie = [];

    var opruef = (await db.opruef.where("OSTAMMID").equals(id).sortBy("OSPEZID")).map((x) => x.OPRUEFID);
    var ospez = (await db.ospez.where("OSTAMMID").equals(id).sortBy("OSPEZID")).map((x) => x.OSPEZID);
    var tbl = await Promise.all([
      this.oherst.where("OSPEZID").anyOf(ospez).toArray(),
      this.opruef.toArray(),
      this.omess.toArray(),
      this.omazs.toArray()
    ]);
    var messVal = tbl[2].filter(x => opruef.includes(x.OPRUEFID)).map(
      x => { x.x_pruef_date = (tbl[1].find(y => y.OPRUEFID == x.OPRUEFID)).P_Datum; return x; });
    messVal = [...new Map(messVal.map(x => [x.x_pruef_date, x])).values()];


    var listBegehung = [];
    var sxbep = (await db.sxbep.toArray()).filter(x=>x.OSTAMMID==id);
    await Promise.all( sxbep.map(async bep => {
      var begp = (await this.obegp.toArray()).filter(x=>x.ID == bep.OBEGP);
      await Promise.all( begp.map(async begp => {
        var bege = (await this.obege.get({ID : begp.OBEGEID}));
        listBegehung.push({Key: bege.Titel , Value:  [{id:begp.ID, datum: begp.BegehDatum}]});
      }));
    }));

    var listWartungen = [];
    var kwapru = (await db.kwapru.toArray()).filter(x=>x.RevStammID==id);
    await Promise.all( kwapru.map(async pru => {
      var kwaman = (await this.kwaman.get({ID : pru.RevMaskenNameID}));
      var maske = kwaman.Name ?? "---";
      listWartungen.push({Key:maske, Value: [{id:pru.ID, datum: pru.RevDatum}]});
    }));


    historie.push({
      Key: "herstellerdaten",
      Value: this.renObj(tbl[0], { einbaudatum: "date" })
    }),
    historie.push({
      Key: "inspektionen",
      Value: this.renObj(tbl[1].filter(x => x.OSTAMMID == id && x.P_DelStatus == 0 && x.P_IntStatus != 'messung'), { p_datum: "date", opruefid: "id" })
    }),
    historie.push({
      Key: "messungen",
      Value: this.renObj(messVal, { x_pruef_date: "date" })
    }),
    // historie["sperrungen"] = (await this.osperr.where("ostammid").equals(id).sortBy("bdatum")).reverse,
    historie.push({
      Key: "stoerungen",
      Value: this.renObj(tbl[3].filter(x => x.OSTAMMID == id && x.OMARTID == 3), { m_datum: "date", omazsid: "id" })
    }),
    // historie["langsamfahrstellen"] = (await this.oslfst.where("ostammid").equals(id).sortBy("bdatum")).reverse,
    historie.push({
      Key: "instandsetzungen",
      Value: this.renObj(tbl[3].filter(x => x.Erledigt == true && x.Mangel != null), { e_datum: "date" })
    });
    if(listBegehung.length > 0) historie.push({
      Key: "begehung",
      Value: listBegehung
    });
    if(listWartungen.length > 0) historie.push({
      Key: "wartungen",
      Value: listWartungen
    });

    for (let index = historie.length - 1; index >= 0; index--) {
      const element = historie[index];
      if (element.Value.length == 0) historie.splice(index, 1);
    }
    return historie;
  }

  public async getHistoryItem(id: number, typ_typid:number, body: any) {
    var ostammid = id;
var type = typ_typid;
var historyKey = body.mode;
var searchVal = body.idOrDate.toString();
var result;
switch (historyKey)
{
    case "wartungen":
        // strSQL = "SELECT art.Bezeichnung as Wartungsart, arb.Bezeichnung as 'Maßnahmen', k.RevZustand_iO as 'i.O.'," +
        //     " k.Messwert, if(k.RevPriorität > -1, k.RevPriorität, null) as FK, if(k.RevUmfang != -99, k.RevUmfang, null) as Umfang," +
        //     " k.RevME as Mengeneinheit, k.Arbeiter, k.UserName, k.Status" +
        //     " FROM kwamgz k INNER JOIN kwaart art ON art.ID = k.RevArtID" +
        //     " LEFT JOIN kwaarb arb ON arb.ID = k.RevArbID" +
        //     " WHERE k.RevPruefungID = ?";
        var mgz = (await db.kwamgz.where("RevPruefungID").equals(parseInt(searchVal)).sortBy("ID")); // OVER (PARTITION BY RevPruefungID, RevArtID ORDER BY ID DESC) as rn FROM kwamgz
        await Promise.all( mgz.map(async (x) => {
          var art = await db.kwaart.get({ ID: x.RevArtID });
          var arb = await db.kwaarb.get({ ID: x.RevArbID });
          return [x.artID, x.artName, x.artTyp, x.FileTyp, x.HelpBeschreibung, x.arbeitName] = [
              art?.ID, art?.Bezeichnung, art?.Typ, art?.FileTyp, art?.HelpBeschreibung, arb?.Bezeichnung]
        }));
        mgz = this.Select(mgz,['artID','artName','RevZustand_iO','Messwert','FK','RevUmfang','RevME','Arbeiter','UserName','Status'])
        return this.parseToFrontEnd(mgz,true,{artid:'Wartungsart',artname:'Maßnahmen',arttyp:'Wartungsart',revzustand_io:'i.O.',revme:'Wartungsart',revumfang:'Wartungsart'});
        break;
    case "sonderinspektion":
        //result = db.osispd.where("ID").equals(searchVal).toArray();

        break;
    case "begehung":
        var dt = (await db.sxbep.toArray()).filter(x=>x.OBEGP == searchVal && x.OSTAMMID==id);
        var strFilter = " WHERE OMAZSID =-1;";
        if (dt.length > 0)
        {
            var dr = dt[0];
            var f: string[] = dr["MangelID"].toString().split(";");

        // strSQL = "select omazs.opruefid, omazs.omazsid, (opruef.p_datum) as pruefdatum,"
        //    + (type == 4 ? "(omazs.StartMeter) as von, (omazs.EndMeter) as bis, " : "")
        //    + @"(omazs.pruefart) as pruefart, (omazs.lage) as lage, (omazs.mangel) as mangel, (omazs.instandsetzung) as instandsetzungshinweis,
        // (omazs.nio) as nicht_in_ordnung, (omazs.umfang) as umfang, (omazs.mengeneinheit) as mengeneinheit, (omazs.betriebsgefahr) as betriebsgefahr,
        // (omazs.bild) as bilder, (omazs.fk) as fehlerklasse, (omazs.auftrag) as auftrag, (omazs.termin) as termin,
        // (omazs.auftragnehmer) as auftragnehmer, (omazs.auftragsart) as auftragsart, (omazs.auftragnr) as auftragnr, (omazs.erledigt) as erledigt,
        // (omazs.e_datum) as erledigt_am, (omazs.e_firma) as erledigt_durch, (omazs.meldnr) as sapmelungnr, (omazs.meldstat) as sapmeldungstatus,
        // (omazs.aufstat) as sapauftragstatus, (omazs.sapeckende) as sapgewende, (omazs.sapeckendeneu) as sapgewendeneu,
        // (omazs.sapausfall) as ausfall_droht, (omazs.rueckmeldetext) as rueckmeldetext, (omazs.bemerkung) as bemerkung, (omazs.kosten) as kosten,
        // (omazs.stunden) as arbeitsstunden, (opbild.p_bild) as pruefbild, (omazs.verantwortlich) as verantwortlich, (omazs.username) as pruefer, (omazs.status) as status
        // from (omazs inner Join opruef on omazs.opruefid = opruef.opruefid) left join opbild on opbild.opruefid = opruef.opruefid ";
        // strSQL += strFilter;
        // strSQL += " and omazs.omartid<>3 order by opruef.p_datum desc, omazs.lfnr";

        if (f[0] != "") {
          var res = []
          await Promise.all(f.map(async mgl => {
            var man = await db.omazs.where("OMAZSID").equals(parseInt(mgl)).toArray();
            await Promise.all(man.map(async x=> {
              var opruef = await db.opruef.get({OPRUEFID : x.OPRUEFID});
              [x.OPRUEFID, x.pruefDatum] = [opruef.OPRUEFID, opruef.P_Datum];
              res.push(x);
            }))
          }));
          res = this.Select(res,this.selectMangelAnl);
          result = this.parseToFrontEnd(res,true, this.aliasMangel);
        }


        }
        break;
    case "herstellerdaten":
    //     result = DBCon.getTableFE(
    //        @"select oherst.id, ospez.ostammid, (oherst.hersteller) as hersteller, (oherst.herstelldatum) as herstelldatum,
    // (oherst.einbaufirma) as einbaufirma, (oherst.einbaudatum) as einbaudatum, (oherst.lageplannr) as lageplan_nr, (oherst.verlegeplannr) as verlegeplan_nr,
    // (oherst.dauergewaehrleistung) as dauer_gewaehrleistung, (oherst.einheitgewaehrleistung) as einheit_gewaehrleistung, (oherst.endegewaehrleistung) as ablauf_gewaehrleistung,
    // (oherst.nutzungsdauer) as geplante_nutzungsdauer, (oherst.username) as user, (oherst.status) as status
    // from ospez inner Join oherst on ospez.ospezid = oherst.ospezid where ospez.ostammid = ?",
    //         db, ostammid);
    var ospez = (await db.ospez.where("OSTAMMID").equals(id).toArray()).map((x)=> x.OSPEZID);
            result = this.parseToFrontEnd(await db.oherst.where("OSPEZID").anyOf(ospez).toArray(),true);
        break;

    case "inspektionen":
        // result = DBCon.getTableFE(
        //    "select omazs.opruefid, omazs.omazsid, (opruef.p_datum) as pruefdatum,"
        //    + (type == "line" ? "(omazs.StartMeter) as von, (omazs.EndMeter) as bis, " : "")
        //    + @"(omazs.pruefart) as pruefart, (omazs.lage) as lage, (omazs.mangel) as mangel, (omazs.instandsetzung) as instandsetzungshinweis,
        // (omazs.nio) as nicht_in_ordnung, (omazs.umfang) as umfang, (omazs.mengeneinheit) as mengeneinheit, (omazs.betriebsgefahr) as betriebsgefahr,
        // (omazs.bild) as bilder, (omazs.InstPic1) as rueckbilder, (omazs.fk) as fehlerklasse, (omazs.auftrag) as auftrag, (omazs.termin) as termin,
        // (omazs.auftragnehmer) as auftragnehmer, (omazs.auftragsart) as auftragsart, (omazs.auftragnr) as auftragnr, (omazs.erledigt) as erledigt,
        // (omazs.e_datum) as erledigt_am, (omazs.e_firma) as erledigt_durch, (omazs.meldnr) as sapmelungnr, (omazs.meldstat) as sapmeldungstatus,
        // (omazs.aufstat) as sapauftragstatus, (omazs.sapeckende) as sapgewende, (omazs.sapeckendeneu) as sapgewendeneu,
        // (omazs.sapausfall) as ausfall_droht, (omazs.rueckmeldetext) as rueckmeldetext, (omazs.bemerkung) as bemerkung, (omazs.kosten) as kosten,
        // (omazs.stunden) as arbeitsstunden, (opbild.p_bild) as pruefbild, (omazs.verantwortlich) as verantwortlich, (omazs.username) as pruefer, (omazs.status) as status
        // from (omazs inner Join opruef on omazs.opruefid = opruef.opruefid) left join opbild on opbild.opruefid = opruef.opruefid where omazs.opruefid = ? and omazs.omartid<>3
        // order by opruef.p_datum desc , omazs.lfnr",
        //     db, searchVal);
        result = this.parseToFrontEnd(await db.omazs.where("OPRUEFID").equals(parseInt(searchVal)).toArray(),true, this.aliasMangel);
        break;

    case "messungen":
    //     result = DBCon.getTableFE(
    //        @"select omess.id, omess.opruefid, (opruef.p_datum) as pruefdatum, (omess.bezeichnung) as bezeichnung,
    // (omess.soll) as sollmass, (omess.sr100plus) as sr_100_plus, (omess.sr100minus) as sr_100_minus, (omess.srlimplus) as sr_lim_plus,
    // (omess.srlimminus) as sr_lim_minus, (omess.ist) as ist, (omess.ausfall) as tolverl, (omess.instand) as ist_neu, (omess.iausfall) as tolverl_neu,
    // (omess.instand_datum) as instandsetzung_vom, (omess.instandsetzer) as instandsetzer, (omess.messender) as pruefer, (omess.username) as user,
    // (omess.status) as status
    // from opruef inner Join omess on opruef.opruefid = omess.opruefid where opruef.p_datum = ? and p_intstatus<>'messung' and opruef.ostammid = ?
    // order by omess.id",
        //         db, DBCon.SQLDatum(searchVal), ostammid);
        var pruefids = (await db.opruef.toArray()).filter(x=>x.P_Datum == searchVal).map((x)=> x.OPRUEFID);
        result = this.parseToFrontEnd((await db.omess.toArray()).filter(x=>pruefids.includes(x.OPRUEFID) && x.SZAEHNID == 0),true,this.aliasMessungen);
        break;

      case "instandsetzungen":
      //     result = DBCon.getTableFE(
      //        @"select omazs.omazsid, opruef.opruefid, (opruef.p_datum) as pruefdatum, (omazs.lfnr) as pos,
      // (omazs.Bild) as bilder, (omazs.InstPic1) as rueckbilder,
      // (omazs.pruefart) as pruefart, (omazs.lage) as lage, (omazs.mangel) as mangel, (omazs.instandsetzung) as instandsetzungshinweis,
      // (omazs.nio) as nicht_in_ordnung, (omazs.umfang) as umfang, (omazs.mengeneinheit) as mengeneinheit, (omazs.betriebsgefahr) as betriebsgefahr,
      // (omazs.fk) as fehlerklasse, (omazs.auftrag) as auftrag, (omazs.termin) as termin, (omazs.auftragnehmer) as auftragnehmer,
      // (omazs.auftragsart) as auftragsart, (omazs.auftragnr) as auftragnr, (omazs.erledigt) as erledigt, (omazs.e_datum) as erledigt_am,
      // (omazs.e_firma) as erledigt_durch, (omazs.rueckmeldetext) as rueckmeldetext, (omazs.bemerkung) as bemerkung, (omazs.kosten) as kosten,
      // (omazs.stunden) as arbeitsstunden, (omazs.verantwortlich) as verantwortlich, (omazs.username) as pruefer, (omazs.status) as status
      // from (ostamm inner Join opruef on ostamm.ostammid = opruef.ostammid)
      // inner Join omazs on opruef.opruefid = omazs.opruefid
      // where (((ostamm.ostammid)= ? ) and omazs.Mangel IS NOT NUll AND omazs.Mangel != '' and ((omazs.e_datum)= ?) and ((omazs.erledigt)=1))",
      //         db, ostammid, DBCon.SQLDatum(searchVal));
      //     break;
      result = await db.omazs.where("OSTAMMID").equals(id).toArray();
      default:
        result = "";
        break;
    }
    return result;

  }


  //#region begehung
  public async setBegehung(req:any) {
    var status: string = req.status.toString();
    var obegeid: number = parseInt(req.obegeid);
    var obegpid: number = parseInt(req.obegpid);

    var strSQL = "";
    var listItems = [];

    if (status.toLowerCase() == "delsxbep")
    {
        var ostammid = parseInt(req.ostammid);
        await db.sxbeg.delete((await db.sxbeg.get({OBEGP:obegpid,OSTAMMID:ostammid})).ID);

        var now = dayjs().format(this.nowFormat);

        await db.obege.update(obegeid, {
          LPCompleted:false,
          UserName:sessionStorage.getItem("User"),
          Status:now
        })

        await db.obegp.update(obegpid, {
          Completed:false,
          UserName:sessionStorage.getItem("User"),
          Status:now
        })

        return { success : true };

    }

    if (status.toLowerCase() == "setsxbepcompleted")
    {
      var now = dayjs().format(this.nowFormat);

      await db.obege.update(obegeid, {
          LPCompleted:req.completed,
          UserName:sessionStorage.getItem("User"),
          Status:now
        })

        await db.obegp.update(obegpid, {
          Completed:req.completed,
          UserName:sessionStorage.getItem("User"),
          Status:now
        })

        return {success : true };
    }

    if (status.toLowerCase() == "setsxbep")
    {
        var ostammid = parseInt(req.ostammid);
        var lpid = parseInt(req.lpid);
        var now = dayjs().format(this.nowFormat);

        await db.sxbep.add({
          OBEGP:obegpid,
          OSTAMMID:ostammid,
          Completed:true,
          MangelID:await this.GetMangelItems(lpid),
          CompletedDate:now,
          UserName:sessionStorage.getItem("User"),
        })

        var body = req.vals;

        await db.obege.update(obegeid, {
          LPOBEGPID:obegpid,
          LPDatum:body.inspektiondate,
          UserName:sessionStorage.getItem("User"),
          Status:now
        })


        return { success : true };

    }

    if (status.toLowerCase() == "getsxbep")
    {
        var result = {};
        result["sxbep"] = this.parseToFrontEnd(await db.sxbep.where("OBEGP").equals(obegpid).toArray(),true);

        return { success : true, result: result };
    }

    if (status.toLowerCase() == "update")
    {
      var now = dayjs().format(this.nowFormat);
        var body = (req.vals);

        if (obegpid <= 0)
        {
          obegpid = await db.obegp.add({
              OBEGEID:obegeid,
              BegehDatum:body.inspektiondate,
              Completed:req.completed,
              Bemerkung:body.bemerkung??'',
              UserName:sessionStorage.getItem("User"),
              Status:now
            })
        }
        else
        {
            listItems.push(obegeid);
            await db.obegp.update(obegeid, {
              OBEGEID:obegeid,
              BegehDatum:body.inspektiondate,
              Completed:req.completed,
              Bemerkung:body.bemerkung??'',
              UserName:sessionStorage.getItem("User"),
              Status:now
            })
        }


        var result = {};
        result["obegpid"] = obegpid;
        return { success: true , result: result};


    }

    return { success: true };
  }

  public async getBegehungsplan() : Promise<any> {
    try
    {

        //TODO:
        //var sql = "SELECT ID as OBEGEID, LPOBEGPID as OBEGPID, (Titel) as Begehungsart, (LPDatum) as letzte, (LPCompleted) as abgeschlossen, (BeginnDatum) as Intervallbeginn, Intervall, (Tag) as _tag, (Bemerkung) as Beschreibung, UserName, Status From obege ORDER BY Titel";
        var bege = this.Select(await(db.obege.toArray()),this.selectBege);
        var dtobege = this.parseToFrontEnd(bege,false,this.aliasBegehung);
        dtobege.columns.push({ id: "Termin", bezeichnung: "TERMIN", type: "string" });
        dtobege.columns.push({ id: "Wochentag", bezeichnung: "WOCHENTAG", type: "string" });

        await dtobege.rows.forEach(r=>{
            if (r["letzte"] == null)
            {
                //var d = ((GetNextWeekday(CurrD, (DayOfWeek)parseInt(r["_tag"].toString()));
                var d : Date = new Date(r["_tag"].toString());
                r["Termin"] = d;
                r["Wochentag"] = d.getDay();
                r["OBEGPID"] = 0;
            }
            else
            {
                var intervall = parseInt(r["Intervall"].toString());
                var startdate = new Date(r["Intervallbeginn"].toString());
                var endP = new Date(r["letzte"].toString());
                var CurrD = startdate;

                while (CurrD <= endP)
                {
                    CurrD.setDate(CurrD.getDate() + intervall);
                }

                var d : Date = new Date(r["_tag"].toString()); //GetNextWeekDay
                r["Termin"] = d;
                r["Wochentag"] = d.getDay();

            }
        })

        var obege = dtobege;
        var sxbeg = this.parseToFrontEnd(await db.sxbeg.toArray());

        var begehungsHistoryarray = {completed:"Abgeschlossen"}
        //"SELECT (obegp.ID) as ID, (obege.Titel) as Begehungsart, (obegp.BegehDatum) as BegehDatum, (obegp.Completed) as Abgeschlossen, (obegp.Bemerkung) as Bemerkung, (obegp.UserName) as UserName, (obegp.Status) as Status FROM obege INNER JOIN obegp ON obege.ID = obegp.OBEGEID WHERE OBEGEID=-1;";
        var obegp_history = this.parseToFrontEnd((await db.obegp.toArray()).filter(x=>x.OBEGEID == -1).map(async x=>x.BegehungsArt = (await db.obege.get({ID: x.OBEGEID})).Titel),true);


        var result = {};
        result["obege"] = obege;
        result["sxbeg"] = sxbeg;
        result["obegp_history"] = obegp_history;

        return { success: true, result:result };
    }
    catch (ex)
    {
        return { success: false, error: ex.Message };
    }
  }

  public async getBegehungsplanHistory(obegeid: number) : Promise<any> {
    var begehungsHistoryarray = {titel:"Begehungsart",completed:"Abgeschlossen"}
    //string sql = "SELECT (obegp.ID) as ID, (obege.Titel) as Begehungsart, (obegp.BegehDatum) as BegehDatum, (obegp.Completed) as Abgeschlossen, (obegp.Bemerkung) as Bemerkung, (obegp.UserName) as UserName, (obegp.Status) as Status FROM obege INNER JOIN obegp ON obege.ID = obegp.OBEGEID WHERE OBEGEID=" + obegeid.toString() + ";";
    var obegp_history =  this.parseToFrontEnd((await db.obegp.toArray()).filter(x=>x.OBEGEID == obegeid),true,begehungsHistoryarray);

    return { success: true, result:{obegp_history:obegp_history} };
  }

  private async GetMangelItems(OPRUEFID: number) : Promise<string>
  {
      var listItems = [];
      var mang = (await db.omazs.toArray()).filter(x=>x.OPRUEFID == OPRUEFID).forEach(el => {
        listItems.push(el.OMAZSID);
      });
      return listItems.join(";");
  }

  public async GetMaengelFromPrüfung(otypid: number, opruefid: number)
{
    try
    {
        var table = otypid == 2 || otypid == 4 ?
        this.parseToFrontEnd(await db.omazs.where("OPRUEFID").equals(opruefid).and(x => x.OMARTID == 1).toArray(),false,this.aliasMangel)
        :
        this.parseToFrontEnd(this.Select(await db.omazs.where("OPRUEFID").equals(opruefid).and(x => x.Erledigt == false).and(x => x.Mangel != null).toArray(),this.selectMangelPoint),false,this.aliasMangel);
        return table;
    }
    catch (Exception)
    {
        return null;
    }
}

  //#endregion

  //#region wartung
  //#region Wartungsverwaltung
  //#region Shared Settings
  public async GetWartungsItem(mode: string, otypid: number) {
    try {
      var table = this.parseToFrontEnd(await db[mode].where("OTYPID").equals(otypid));

      return { success: true, table };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async SetWartungsItem(mode: string, otypid: number, req: any) {

    try {
      var isTyp = mode == "art";
      var field: string = mode == "masken-names" ? "Name" : "Bezeichnung";
      var ID = -1;
      if (req.ID != null) parseInt(req.ID.toString());

      var Status = dayjs().format(this.nowFormat);
      var name: string = req.Bezeichnung.toString();
      var table: string = this.GetTableFromMode(mode);
      if (ID == -1) {
        db[table].add({
          OTYPID: otypid,
          [field]: name,
          UserName: sessionStorage.getItem("User"),
          Status: Status,
          Typ: req.Typ
        })
      }
      else {
        db[table].update(ID, {
          OTYPID: otypid,
          [field]: name,
          UserName: sessionStorage.getItem("User"),
          Status: Status,
          Typ: req.Typ
        })
      }

      var neuRow = {
        ID: ID,
        UserName: sessionStorage.getItem("User"),
        Status: Status.trim(),
      };

      return { success: true, neuRow };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async DeleteWartungsItem(mode: string, id: number) {

    try {
      await db[this.GetTableFromMode(mode)].delete(id);
      return { success: true };
    }
    catch (ex) {
      return { success: false, error: ex.Message.toString() };
    }
  }
  //#endregion
  //#region Wartungsbaum
  public async GetWartungsBaum(otypid: number) {

    try {
      var replaceFields :any = {revartid:"artID",artBez:"artName",arbBez:"arbeitName",revarbid:"arbeitID"}
      //  string sql = "SELECT w.ID, w.RevArtID as artID, art.Bezeichnung as artName," +
      //      " w.RevArbID as arbeitID, arb.Bezeichnung as arbeitName, w.Priorität, w.UserName, w.Status" +
      //      " FROM kzuwa w INNER JOIN kwaart art ON w.RevArtID = art.ID" +
      //      " INNER JOIN kwaarb arb ON w.RevArbID = arb.ID WHERE w.OTYPID = ? ORDER BY w.RevArtID";
      //DataTable wartungen = DBCon.getTable(sql, user.db, otypid.toString());

      var wa = (await db.kzuwa.toArray()).filter(x => x.OTYPID = otypid);
        await wa.map(async (x) => {
          var art = await db.kwaart.get({ ID: x.RevArtID });
          var arb = await db.kwaarb.get({ ID: x.RevArbID });
          [x.ArbBez,x.ArtBez] = [arb.Bezeichnung, art.Bezeichnung];
        })
        var wartungen = this.renObj(wa,replaceFields);


      return { success: true, wartungen };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async SetWartungsbaumNode(otypid: number, req) {

    try {
      var ID = -1;
      if (req.ID != null) ID = parseInt(req.ID.toString());


      if (ID == -1) {
        ID = await db.kzuwa.add({
          RevArtID: req.artID,
          RevArbID: req.arbeitID,
          Priorität: req.Priorität,
          OTYPID: otypid,
          UserName: sessionStorage.getItem("User"),
          Status: dayjs().format(this.nowFormat)
        });
      }
      else {
        await db.kzuwa.update(ID, {
          RevArtID: req.artID,
          RevArbID: req.arbeitID,
          Priorität: req.Priorität,
          OTYPID: otypid,
          UserName: sessionStorage.getItem("User"),
          Status: dayjs().format(this.nowFormat)
        });
      }

      return { success: true, ID };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }
  //#endregion
  //#region Masken
  public async GetWartungsMaskeList(id: number, otypid: number) {

    try {
      //  string sql = "SELECT kwamas.ID as mID, kwamas.Sortierung as mSort, art.* FROM kwamas INNER JOIN kwaart art ON RevArtID = art.ID" +
      //      " WHERE RevMaskenNameID = " + id + " AND kwamas.OTYPID = " + otypid +
      //      " ORDER BY Sortierung";
      var list = (await db.kwamas.toArray()).filter(x => (x.RevMaskenNameID == id && x.OTYPID == otypid));

      return { success: true, list };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async SetWartungsMaskeList(id: number, otypid: number, req) {
    try {
      var IDs = [];
      if (req.edit ?? false) {
        req.arten.forEach(async (art) => {
          await db.kwamas.update(art.mID, {
            Sortierung: req.artID,
            UserName: sessionStorage.getItem("User"),
            Status: dayjs().format(this.nowFormat)
          });
        });
      }
      else {
        req.arten.forEach(async (art) => {
          var ID = await db.kwamas.add({
            RevMaskenNameID: id,
            RevArtID: art.ID,
            Sortierung: art.mSort,
            OTYPID: otypid,
            UserName: sessionStorage.getItem("User"),
            Status: dayjs().format(this.nowFormat)
          });
          IDs.push(ID);
        });
      }
      return { success: true, IDs };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async DeleteWartungsMaske(id: number) {

    try {
      db.kwamas.delete(id);
      return { success: true };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }
  //#endregion
  //#region Objektzuordnung
  public async GetObjekteNachOtypid(otypid: number) {

    try {
      var kbez = await this.getKundenBezeichnungColumnNames();
      var groupIDs = this.GetGroupIDString({id:1});
      var isKAV = (await db.sysini.get({ ID: 1 })).KAV17 ?? "1";
      //  var sql = "SELECT DISTINCT " +
      //      "o.OSTAMMID, " +
      //      "o.Bezeichnung, " +
      //          (kbez["Bezirk"] != null ?
      //      "(o.Bezirk) as '" + kbez["Bezirk"] + "', " : "") +
      //          (kbez["Stellwerk"] != null ?
      //      "(o.Stellwerk) as '" + kbez["Stellwerk"] + "', " : "") +
      //          (kbez["Ort"] != null ?
      //      "(o.Ort) as '" + kbez["Ort"] + "', " : "") +
      //      "(o.Laenge) as Länge, " +
      //          (kbez.Anlagenklasse != null ?
      //      "(o.Anlagenklasse) as '" + kbez.Anlagenklasse + "', " : "") +
      //          (kbez.PlanNr != null ?
      //      "(o.PlanNr) as '" + kbez.PlanNr + "', " : "") +
      //          (kbez.KundenID != null ?
      //      "(o.FremdID) as '" + kbez.KundenID + "', " : "") +
      //          (kbez.Eigentuemer != null ?
      //      "(o.Eigentuemer) as '" + kbez.Eigentuemer + "', " : "") +
      //          (kbez.Verantwortlich != null ?
      //      "(o.Verantwortlich) as '" + kbez.Verantwortlich + "', " : "") +
      //          (kbez.Kostenstelle != null ?
      //      "(o.Kostenstelle) as '" + kbez.Kostenstelle + "', " : "") +
      //          (isKAV ?
      //       "(opruef.KAV00) as KAV, " : "") +
      //      "(o.MessRegBezirk) as 'MessReg Bezirk' " +
      //      "FROM ostamm o INNER JOIN sxmgr ON o.OSTAMMID = sxmgr.AnlagenobjektID " +
      //      "INNER JOIN kbngr ON sxmgr.BenutzerGruppeID = kbngr.ID " +

      //      "LEFT JOIN opruef ON o.LPID = opruef.opruefID " +

      //      "WHERE o.DelStatus = 0 AND kbngr.ID IN (" + groupIDs + ") AND o.OTYPID = " + otypid;


      var table = this.parseToFrontEnd((await db.ostamm.toArray()).filter(x => x.DelStatus == 0 && x.OTYPID == otypid), false, kbez);//DBCon.getTableFE(sql, user.db);

      return { success: true, table };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async GetWartungsZuordnungen(ostammid: number) {

    try {
      var sql = "SELECT v.ID, v.OSTAMMID, m.ID as mID, m.Name as maske," +
        " v.RevIntervall as intervall, if (v.IntervallTyp = 0, 'Tag', 'Monat') as einheit, v.RevBeginn as intervallbeginn," +
        " p.ID as pID, p.Bezeichnung as verantwortlich, v.UserName, v.Status" +
        " FROM kwastm v INNER JOIN kwaman m ON v.RevMaskenID = m.ID INNER JOIN kwaprs p ON v.Zustaedigkeit = p.ID" +
        " WHERE v.OSTAMMID = " + ostammid;
      var table = null;//DBCon.getTableFE(sql, user.db);

      return { success: true, table };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async SetWartungsZuordnung(req: any) {

    try {
      var ID: number = -1;
      if (req.ID != null) parseInt(req.ID.toString());

      var Status = dayjs().format(this.nowFormat);

      if (ID == -1) // Neue Verknüpfungen, möglich für mehrere Objekte
      {
        req.objekte.forEach(async (id) => {
          ID = await db.kwastm.add({
            OSTAMMID: id,
            RevMaskenID: req.mID,
            LastRevPruefungID: 0,
            Zustaedigkeit: req.pID,
            RevIntervall: req.intervall,
            IntervallTyp: (req.einheit == "Tag" ? 0 : 1),
            RevBeginn: req.intervallbeginn,
            UserName: sessionStorage.getItem("User"),
            Status: dayjs().format(this.nowFormat)
          });
        });
      }
      else {
        await db.kwastm.update(ID, {
          RevMaskenID: req.mID,
          Zustaedigkeit: req.pID,
          RevIntervall: req.intervall,
          IntervallTyp: (req.einheit == "Tag" ? 0 : 1),
          RevBeginn: req.intervallbeginn,
          UserName: sessionStorage.getItem("User"),
          Status: dayjs().format(this.nowFormat)
        });
      }

      var neuRow =
      {
        ID: ID,
        UserName: sessionStorage.getItem("User"),
        Status: Status.trim(),
      };

      return { success: true, neuRow };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }
  //#endregion
  //#region Helper
  public async SetWartungsHelper(id: number, req) {

    try {
      var Status = dayjs().format(this.nowFormat);
      await db.kwaart.update(id, {
        HelpBeschreibung: req.HelpBeschreibung,
        HelpBildBase64: req.HelpBildBase64,
        FileTyp: req.FileTyp,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      });

      var neuRow =
      {
        UserName: sessionStorage.getItem("User"),
        Status: Status,
      };

      return { success: true, neuRow };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }
  //#endregion
  //#endregion

  //#region Wartungsplan
  public async GetWartungenInPlan(type: string) {

    try {
      var kbez = null;
      var groupIDs = this.GetGroupIDString([{id:1}]);
      kbez = await this.getKundenBezeichnungColumnNames();

      var lstStamm = [];
      var stamm = (await db.ostamm.toArray()).filter(x => x.DelStatus == 0);
      stamm = this.Select(stamm, this.selectStamm);
      await Promise.all(stamm.map(async x => {
        var stm = (await db.kwastm.toArray()).filter(y=> y.OSTAMMID == x.OSTAMMID);
        x.diffFromNow = 0;
        x.nextDatum = '';
        await Promise.all(stm.map(async xstm => {
          var sotyp = await db.sotyp.get({ ID: x.OTYPID });
          var kwapru = (await db.kwapru.where("RevStammID").equals(xstm.OSTAMMID).and(x=>x.RevMaskenNameID == xstm.RevMaskenID).reverse().sortBy("RevDatum")) //Da kwapru kein eindeutiges ID-Merkmal hat einfach das letzte Datum nehmen.
          var pru = kwapru ? kwapru[0] : undefined;
          var opruef = await db.opruef.get({ OSTAMMID: xstm.OSTAMMID });
          if (!pru) { pru = { ID: null, RevDatum: null, RevBemerkung: null, UserName: null, Status: null } }
          if (xstm) {
            var man = await db.kwaman.get({ ID: xstm?.RevMaskenID });
            var prs = await db.kwaarb.get({ ID: xstm?.Zustaedigkeit });
            [x.OPRUEFID, x.strBez, x.stmid, x.inspektionID, x.wID, x.letzteDatum, x.bemerkung, x.mID, x.maske, x.pID, x.verantwortlich, x.intervall, x.einheit, x.intervallbeginn, x.icon] =
              [opruef?.OPRUEFID, sotyp.Bezeichnung + " - " + x.Bezirk + " - " + x.Stellwerk + " - " + x.Bezeichnung,
              xstm?.ID, x.LPID, pru?.ID, pru?.RevDatum, pru?.RevBemerkung, man?.ID, man?.Name, prs?.ID, prs?.Bezeichnung, xstm?.RevIntervall, stm ? (xstm.IntervallTyp == 0 ? 'd' : 'M') : 'd', xstm?.RevBeginn, sotyp.SysIcon]
              lstStamm.push(JSON.parse(JSON.stringify(x)));
          }
        }));
      }));
      stamm = stamm.filter(x=>x.stmid);

      var wartungsPlan = this.parseToFrontEnd(lstStamm,true,kbez);

      return wartungsPlan;
    }
    catch (Exception) {
      return null;
    }
  }


  public async GetWartungenBemerkungen() {

    try {
      var list = (await db.kwapru.toArray()).filter(x => x.RevBemerkung != null).sort(x => x.RevBemerkung);
      return list;
    }
    catch (Exception) {
      return null;
    }
  }

  public async GetWartungFromMaske(mID: number, wID: number, isReadOnly: boolean) {

    try {
      var isNew: boolean = wID == -1;
      var sql = isReadOnly ? "" : "SET SESSION group_concat_max_len = 4096;";
      if (isNew) {
        sql += `
             SELECT art.ID as artID, art.Bezeichnung as artName, art.Typ as artTyp, art.FileTyp, art.HelpBeschreibung, art.HelpBildBase64,
             group_concat(concat_ws('|.|', kzuwa.RevArbID, kwaarb.Bezeichnung, kzuwa.Priorität) SEPARATOR '&.&') as zuordnungen
             FROM kwamas INNER JOIN kwaart art ON art.ID = kwamas.RevArtID
             LEFT JOIN (kzuwa INNER JOIN kwaarb ON kwaarb.ID = kzuwa.RevArbID) ON kzuwa.RevArtID = kwamas.RevArtID
             WHERE kwamas.RevMaskenNameID = ?
             GROUP BY kwamas.ID
             ORDER BY Sortierung;`;
        var mas = (await db.kwamas.toArray()).filter(x => x.RevMaskenNameID == (isNew ? mID.toString() : wID.toString()));
        await Promise.all(mas.map(async (x) => {
          var art = await db.kwaart.get({ ID: x.RevArtID });
          var wa = await db.kzuwa.where("RevArtID").equals(x.RevArtID).toArray();

          var zuordnung: string = "";
          await Promise.all(wa.map(async wa => {
            var arbWA = await db.kwaarb.get({ ID: wa.RevArbID });
            zuordnung += wa?.RevArbID + "|.|" + arbWA?.Bezeichnung + "|.|" + wa?.Priorität + "&.&"
          }));
          zuordnung = zuordnung.substring(0,zuordnung.length-3);

          delete x.ID;
          return  [x.artID, x.artName, x.artTyp, x.FileTyp, x.HelpBeschreibung, x.zuordnungen] = [
              art?.ID, art?.Bezeichnung, art?.Typ, art?.FileTyp, art?.HelpBeschreibung, zuordnung]
        }));
        return mas;

      }
      else if (isReadOnly) {
        sql = `
             SELECT art.ID as artID, art.Bezeichnung as artName, art.Typ as artTyp, art.FileTyp, art.HelpBeschreibung, art.HelpBildBase64,
             arb.Bezeichnung as arbeitName,
             kwamgz.ID, kwamgz.RevZustand_iO as iO, kwamgz.Messwert, kwamgz.RevArbID as arbeitID, kwamgz.RevME,
             if(kwamgz.RevPriorität > -1, kwamgz.RevPriorität, null) as FK,
             if(kwamgz.RevUmfang != -99, kwamgz.RevUmfang, null) as RevUmfang
             FROM (
                 SELECT * FROM (
                     SELECT *, row_number() OVER (PARTITION BY RevPruefungID, RevArtID ORDER BY ID DESC) as rn FROM kwamgz
                 ) r WHERE rn = 1
             ) kwamgz INNER JOIN kwaart art ON art.ID = RevArtID
             LEFT JOIN kwaarb arb ON arb.ID = RevArbID
             WHERE kwamgz.RevPruefungID = ?
             GROUP BY kwamgz.ID
             ORDER BY kwamgz.ID;`;
        var mgz = (await db.kwamgz.where("RevPruefungID").equals(isNew ? mID : wID).sortBy("ID")); // OVER (PARTITION BY RevPruefungID, RevArtID ORDER BY ID DESC) as rn FROM kwamgz
        await Promise.all( mgz.map(async (x) => {
          var art = await db.kwaart.get({ ID: x.RevArtID });
          var arb = await db.kwaarb.get({ ID: x.RevArbID });
          return [x.artID, x.artName, x.artTyp, x.FileTyp, x.HelpBeschreibung, x.arbeitName] = [
              art?.ID, art?.Bezeichnung, art?.Typ, art?.FileTyp, art?.HelpBeschreibung, arb?.Bezeichnung]
        }));
        return mgz;
      }
      else {
        sql += `
             SELECT art.ID as artID, art.Bezeichnung as artName, art.Typ as artTyp, art.FileTyp, art.HelpBeschreibung, art.HelpBildBase64,
             group_concat(concat_ws('|.|', kzuwa.RevArbID, kwaarb.Bezeichnung, kzuwa.Priorität) SEPARATOR '&.&') as zuordnungen,
             arb.Bezeichnung as arbeitName,
             kwamgz.ID, kwamgz.RevZustand_iO as iO, kwamgz.Messwert, kwamgz.RevArbID as arbeitID, kwamgz.RevME,
             if(kwamgz.OMAZSID > 0, kwamgz.OMAZSID, null) as OMAZSID,
             if(kwamgz.RevPriorität > -1, kwamgz.RevPriorität, null) as FK,
             if(kwamgz.RevUmfang != -99, kwamgz.RevUmfang, null) as RevUmfang, kwamgz.OMAZSID

             FROM (
                 SELECT * FROM (
                     SELECT *, row_number() OVER (PARTITION BY RevPruefungID, RevArtID ORDER BY ID DESC) as rn FROM kwamgz
                 ) r WHERE rn = 1
             ) kwamgz INNER JOIN kwaart art ON art.ID = kwamgz.RevArtID
             LEFT JOIN (kzuwa INNER JOIN kwaarb ON kwaarb.ID = kzuwa.RevArbID) ON kzuwa.RevArtID = kwamgz.RevArtID
             LEFT JOIN kwaarb arb ON arb.ID = kwamgz.RevArbID
             WHERE kwamgz.RevPruefungID = ?
             GROUP BY kwamgz.ID
             ORDER BY kwamgz.ID;`;
        var mgz = (await db.kwamgz.where("RevPruefungID").equals(isNew ? mID : wID).sortBy("ID")); // OVER (PARTITION BY RevPruefungID, RevArtID ORDER BY ID DESC) as rn FROM kwamgz
        await Promise.all( mgz.map(async (x) => {
          var art = await db.kwaart.get({ ID: x.RevArtID });
          var arb = await db.kwaarb.get({ ID: x.RevArbID });
          var wa = await db.kzuwa.where("RevArtID").equals(x.RevArtID).toArray();

          var zuordnung: string = "";
          await Promise.all( wa.map(async wa => {
            var arbWA = await db.kwaarb.get({ ID: wa.RevArbID });
            zuordnung += (wa?.RevArbID + "|.|" + arbWA?.Bezeichnung + "|.|" + wa?.Priorität + "&.&");
          }));
          zuordnung = zuordnung.substring(0,zuordnung.length-3);

          return [x.artID, x.arbeitID, x.artName, x.artTyp, x.FileTyp, x.HelpBeschreibung, x.arbeitName, x.zuordnungen,x.FK] = [
              art?.ID, arb?.ID, art?.Bezeichnung, art?.Typ, art?.FileTyp, art?.HelpBeschreibung, arb?.Bezeichnung, zuordnung,x.RevPriorität]
        }));
        return mgz;
      }

      return null;
    }
    catch (Exception) {
      return null;
    }
  }

  public async SetWartung(req: any) {

    try {
      var username = sessionStorage.getItem("User");
      var wID = typeof(req.ID) == "object" ? await req.ID : req.ID;
      var mID = req.mID;
      var pruefID: number = req.opruefid;
      var ostammid = req.ostammid;
      var otypid: number = req.otypid;
      var wdatum = req.datum;

      var Status = dayjs().format(this.nowFormat);
      if (wID == null) {
        wID = await db.kwapru.add({
          RevStammID: ostammid,
          RevMaskenNameID: mID,
          RevDatum: wdatum!,
          RevBemerkung: req.bemerkung,
          OTYPID: otypid,
          UserName: username,
          Status: Status
        });
      }
      else {
        await db.kwapru.update(wID, {
          RevDatum: wdatum!,
          RevBemerkung: req.bemerkung,
          OTYPID: otypid,
          UserName: username,
          Status: Status
        });
        var prid = (await db.opruef.get({OPRUEFID:pruefID}))?.ID;
        await db.opruef.update(prid, {
          P_Datum: wdatum!,
          UserName: username,
          Status: Status
        });

      }

      var form = req.form;
      var lfNr = 0;

      if (form != null) {
        var options = undefined;

        var headerNode = (Object.entries(form)[0][0]) as any;
        var header: string = headerNode;
        var separatorIndex: number = headerNode.indexOf('-');
        var headerID: number = parseInt(header[separatorIndex]);
        var headerName: string = headerID != 0 ? header[(separatorIndex + 1)] : req.maske;

        var tmp = ((Object.entries(form)[0][1]) as any);
        var sysini = (await db.sysini.get({ ID: 1 }));
        await Promise.all([
        pruefID ??= await this.Create_OPRUEF(ostammid, 1, otypid, wdatum, "Wartung", true),
        options ??= { gefahrFK: sysini.optFk1_IstGleich_BG, minFK: sysini.optMINNumFK },
        ]);
        var i = 0;

        Object.values(tmp).forEach(async (werte :any) => {
          var artID = Object.keys(tmp)[i];
          var ID: number = werte.ID;
          var iO: boolean = werte["i.O."];
          var arbID: number = werte.arbeitID;
          var omazsid: number = werte.omazsid;
          var messwert: string = werte.messwert?.toString().replace(',', '.');
          var umfang: string = werte.umfang.toString().replace(',', '.');
          var fk: number = werte.fk;
          var meh: string = werte["meh"];
          i++;

          if (!iO && arbID != null) {
            lfNr++;
            omazsid ??= await this.Create_Mangel(ostammid, pruefID, otypid, 5, headerName, lfNr),
            await db.omazs.update(omazsid, {
              lfNr: lfNr,
              Mangel: werte.wartungsart,
              Instandsetzung: werte.massnahmen,
              FK: fk,
              Betriebsgefahr: false,
              Umfang: umfang ?? null,
              Mengeneinheit: meh,
              UserName: username,
              Status: Status
            });
          }
          else if (iO && omazsid != null) {
            var pid = (await db.omazs.get({ OMAZSID: omazsid }))?.ID;
            if (pid) db.omazs.delete(pid); // TODO: ID FINDEN
            omazsid = null;
          }

          if (!ID) {
            await db.kwamgz.add({
              RevPruefungID: wID,
              RevMaskenNameID: mID,
              RevArtID: artID,
              RevArbID: arbID ?? 0,
              Messwert: messwert,
              RevPriorität: fk ?? -1,
              RevUmfang: umfang ?? -99,
              RevME: meh,
              RevGeprueft: true,
              RevZustand_iO: iO ?? false,
              OMAZSID: omazsid ?? 0,
              Arbeiter: req.personal,
              UserName: username,
              Status: Status
            });
          }
          else {
            await db.kwamgz.update(ID, {
              RevArbID: arbID ?? 0,
              Messwert: messwert,
              RevPriorität: fk ?? -1,
              RevUmfang: umfang ?? -99,
              RevME: meh,
              RevZustand_iO: iO ?? false,
              OMAZSID: omazsid ?? 0,
              UserName: username,
              Status: Status
            });
          }
        });

        if (lfNr == 0 && pruefID > 0) {
          var pid = (await db.opruef.get({ OPRUEFID: pruefID }))?.ID;
          if (pid) db.omazs.delete(pid);
          pruefID = null;
        }
      }

      return { success: true, wID, pruefID };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async SetMultiWartung(req) {

    try {
      var username = sessionStorage.getItem("User");
      var wdatum = req.datum;
      var Status = dayjs().format(this.nowFormat);
      var wID;
      var objekte = req.objekte;

      objekte.forEach(async (objekt) => {
        var mID: number = objekt.mID;
        var ostammid: number = objekt.OSTAMMID;
        var otypid: number = objekt.OTYPID;

        wID = db.kwapru.add({
          RevStammID: ostammid,
          RevMaskenNameID: mID,
          RevDatum: wdatum,
          RevBemerkung: req.bemerkung,
          OTYPID: otypid,
          UserName: username,
          Status: Status
        });

        //sql = "SELECT art.ID FROM kwamas INNER JOIN kwaart art ON art.ID = RevArtID WHERE RevMaskenNameID = ? ORDER BY Sortierung;";
        var arten = await db.kwamas.where("RevMaskenNameID").equals(mID).sortBy("Sortierung");

        arten.forEach((artID) => {

          db.kwamgz.add({
            RevPruefungID: wID,
            RevMaskenNameID: mID,
            RevArtID: artID.RevArtID,
            RevArbID: 0,
            Messwert: 0,
            RevPriorität: -1,
            RevUmfang: -99,
            RevME: '',
            RevGeprueft: true,
            RevZustand_iO: true,
            OMAZSID: 0,
            Arbeiter: '',
            UserName: username,
            Status: Status
          });
        });
      });

      return { success: true };
    }
    catch (ex) {
      return { success: false, error: ex.Message };
    }
  }

  public async GetArbeitID(arbeitName: string, otypid: number) {

    try {
      //int? id = DBCon.getVal("SELECT ID FROM kwaarb WHERE OTYPID = ? AND Bezeichnung = ?", user.db, otypid.toString(), arbeitName);
      var arb = (await db.kwaarb.toArray()).filter(x => (x.OTYPID == otypid && x.Bezeichnung == arbeitName));
      var id = arb.length > 0 ? id = arb[0].ID :
        await db.kwaarb.add({
        Bezeichnung: arbeitName,
        OTYPID: otypid,
        UserName: sessionStorage.getItem("User"),
        Status: dayjs().format(this.nowFormat)
      });

      return id;
    }
    catch (ex) {
      return null;
    }
  }

  //#endregion

  private GetTableFromMode(mode: string): string {
    switch (mode) {
      case "wartung": return "kzuwa"; break;
      case "art": return "kwaart"; break;
      case "arbeit": return "kwaarb"; break;
      case "personal": return "kwaprs"; break;
      case "masken-names": return "kwaman"; break;
      case "masken-items": return "kwamas"; break;
      case "zuordnung": return "kwastm"; break;
      default: return "Ungültig!"
    };
  }

  public GetGroupIDString(userGroups): string {
    var ids = "";
    var items = userGroups;
    items.forEach((item) => {
      ids += item["id"].toString() + ",";
    });
    if (ids.length > 0) ids = ids.slice(0, ids.length - 1);
    if (ids == "") ids = "1";

    return ids;
  }
  public async getKundenBezeichnungColumnNames() {
    var kbezng = (await db.kbezng.toArray()).filter(x => x.KShow == true);

    var columns = {};
    kbezng.forEach((bezng) => {
      columns[bezng["FeldName"].toString()] = bezng["KBezeichnung"].toString();
    });

    return columns;
  }
  //#endregion
}

export const db = new AppDB();