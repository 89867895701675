import { Pipe, type PipeTransform } from '@angular/core';
import { LinieDrehungService } from '../services/Shared/linie-drehung.service';

@Pipe({
  name: 'drehung',
  standalone: true

})
export class LinieDrehungPipe implements PipeTransform {
  constructor(
    private drehung: LinieDrehungService,
  ) { }
  /**
   * @alias back - für Kürzung im Template
   * @param value Wert, das wirklich in Start- & Endmeter gespeichert ist
   * @param summe Start- + Endmeter, kommt vom linie-drehung Service
   * @see LinieDrehungService.summe
   * @returns gedrehtes Wert
   * @example
   * protected drehung: LinieDrehungService,
   * protected drehungPipe: LinieDrehungPipe,
   * @example
   * [value]="formSpezTop.get('Startmeter').value | drehung: drehung.summe"
   * (change)="formSpezTop.get('Startmeter').setValue(
      drehungPipe.back($event.target.value, drehung.summe)
    )"
   * @example
   * row[(!drehung.isOn ? 'Startmeter' : 'Endmeter')] | drehung: drehung.summe
   */
  transform(value: number, summe: number): number {
    return (value || value === 0) && this.drehung.isOn ? (summe - value) : value;
  }

  // Zurück Konvertieren benötigt dasselbe (Summe - Wert)
  back = this.transform;
}
