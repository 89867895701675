import { CommonModule } from "@angular/common";
import { Component, HostListener, ElementRef, ViewChild, OnInit} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ClarityModule } from "@clr/angular";
import { BehaviorSubject } from "rxjs";

import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";

@Component({
    selector: 'app-mangelverteilungsdialog',
    templateUrl: './mangelverteilungsdialog.component.html',
    styleUrls: ['./mangelverteilungsdialog.component.scss'],
    imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule],
    standalone: true
})
export class MangelverteilungsdialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  cx: CanvasRenderingContext2D;
  @ViewChild('canvas') canvas: ElementRef;

  color = '#ffdd2d';

  protected ostammid: number;
  protected stamm: any;
  protected bauwerk: any;
  protected mangel: any = [];
  protected spez: any;
  private window = window;

  protected mangelblock: any= [];
  protected sStart: number = 0;
  protected sEnde: number = 0;
  protected mvStreckenLaenge: number = 0;
  protected sBez: string = '';
  protected speztypen: any;

  protected mvMassstabH: number = 1;
  protected mvLinks: number = 500;
  protected mvRowHeight: number = 30;
  protected rectWidt: number = 400;
  protected foreColor: string = 'black';
  protected spezblocks: any = [];

  set data(dataIn: any) {
    this.ostammid  = dataIn.id;
    this.stamm = dataIn.stamm;
    this.mangel = dataIn.mangel;
    this.spez = dataIn.spez;
    this.bauwerk = dataIn.bauwerk;

    this.sStart =  this.getStammValue('StartMeter');
    this.sEnde = this.getStammValue('EndMeter');
    this.mvStreckenLaenge = this.getStammValue('Laenge');
    this.sBez = this.getStammValue('Bezeichnung');

    if (this.mangel?.rows){
      this.mangelblock = this.groupByKey(this.mangel.rows,'mangel');
    }


    this.speztypen = [];
    for(let i = 4 ; i < this.spez.columns.length; i++){
      this.speztypen.push(this.spez.columns[i].id);
    }

    this.speztypen.forEach((sp) => {

      let a = this.getValueArray(this.spez.rows,sp);

      let ispez = [];

      a.forEach((wert) => {
        let v = [];
        this.spez.rows.forEach((row) => {
          let von = this.getSpezValue(row,"Startmeter", sp, wert);
          let bis = this.getSpezValue(row,"Endmeter", sp, wert);

          let pos = {
            von: von,
            bis: bis,
          }
          v.push(pos);
        });

        if (wert != null){
          let _ispez = {
            color: this.getRandomColor(),
            wert: wert,
            pos: v,
          }

          ispez.push(_ispez);
        }


      });

      if (ispez != null){
        let block = {
          key: sp,
          items: ispez,
        }

        this.spezblocks.push(block);
      }
    });
    //this.mangelblock =  result;

  }


  getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  scrHeight:any;
  scrWidth:any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        console.log(this.scrHeight, this.scrWidth);
  }

  constructor() {
    this.getScreenSize();
  }

  ngAfterViewInit(){
    this.draw();
  }

/*
  ngAfterViewInit(){
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.scrWidth - 10;
    canvasEl.height = this.scrHeight - 250;

    let blockMangelHeight = 0;
    let z:number = 0;
    Object.keys(this.mangelblock).forEach((tableKey) => {
      z++
    });

    let y = 30;
    if (z != 0){
      blockMangelHeight = y + z * this.mvRowHeight + this.mvRowHeight / 2;
      if (blockMangelHeight != 0){
        y = blockMangelHeight;
      }
    }

    let anzspezblock = this.spezblocks.length;
    let blockSpezHeight = y + anzspezblock * this.mvRowHeight + this.mvRowHeight / 2;
    canvasEl.height = blockSpezHeight + 20;

    this.rectWidt = canvasEl.width - this.mvLinks;
    this.cx.fillStyle = this.foreColor;
    this.mvMassstabH = this.rectWidt / this.mvStreckenLaenge * 0.85;
    let steps = parseInt((this.mvStreckenLaenge/100).toString()) + 3;
    this.cx.font = "12px Metropolis";

    y = 30;
    if (z != 0){

      let xtxt1 = 15;
      let xtxt2 = 180;

      this.cx.beginPath();
      this.cx.moveTo(0, y);
      this.cx.lineTo(canvasEl.width, y);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();

      for(let i = 0 ; i < steps; i++){
        let x = (i * 100 * this.mvMassstabH) + this.mvLinks;

        this.cx.beginPath();
        this.cx.moveTo(x, 0);
        this.cx.lineTo(x, blockMangelHeight);
        this.cx.strokeStyle = '#485A6A';
        this.cx.stroke();

        let txt = this.sStart + (i*100);
        this.cx.fillText(txt.toString(), x + 5, y - 5);

      }

      this.cx.beginPath();
      this.cx.moveTo(0, blockMangelHeight);
      this.cx.lineTo(canvasEl.width, blockMangelHeight);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();

      y = 60;

      this.cx.font = "14px Metropolis";

      Object.keys(this.mangelblock).forEach((tableKey) => {

        let color = "black";
        this.cx.fillStyle = this.foreColor;

        this.cx.fillText(tableKey.toString(),180,y);
        let maengel = this.mangel.rows.filter((cells) => cells.mangel == tableKey);
        let r = maengel[0];
        this.cx.fillText(r['pruefart'].toString(),10,y);

        maengel.sort((a,b)=>b.fehlerklasse - a.fehlerklasse);

        maengel.forEach((row) => {
          let s = parseFloat(row.von.toString());
          let e = parseFloat(row.bis.toString());
          let fk = row.fehlerklasse.toString();

          let xs = this.mvLinks + ((s - this.sStart) * this.mvMassstabH);
          let l = e - s;
          if (l < 1) l = 1;

          let w = (l * this.mvMassstabH);


          color = "blue";

          switch(fk) {
            case "0": {
              color = "red";
              break;
            }
            case "1": {
              color = "red";
              break;
            }
            case "2": {
              color = "yellow";
              break;
            }
            case "3": {
              color = "green";
              break;
            }
            case "4": {
              color = "blue";
              break;
            }
            default: {
              color = "blue";
              break;
            }
          }

          this.cx.fillStyle = color;
          this.cx.fillRect(xs, y-20, w, 25);
        });

        y+=30;

      });

      y = 30;
      if (blockMangelHeight != 0){
        y = blockMangelHeight;
      }
    }

    if (z == 0){
      this.cx.beginPath();
      this.cx.moveTo(0, y);
      this.cx.lineTo(canvasEl.width, y);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();
    }

    this.cx.fillStyle = this.foreColor;
    for(let i = 0 ; i < steps; i++){
      let x = (i * 100 * this.mvMassstabH) + this.mvLinks;

      this.cx.beginPath();
      this.cx.moveTo(x, y);
      this.cx.lineTo(x, blockSpezHeight);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();

      if (y == 30){
        let txt = this.sStart + (i*100);
        this.cx.fillText(txt.toString(), x + 5, y - 5);
      }
    }

    this.cx.beginPath();
    this.cx.moveTo(0, blockSpezHeight);
    this.cx.lineTo(canvasEl.width, blockSpezHeight);
    this.cx.strokeStyle = '#485A6A';
    this.cx.stroke()

    y += this.mvRowHeight;

    this.spezblocks.forEach((spez) => {
      this.cx.fillStyle = this.foreColor;
      this.cx.fillText(spez.key,10,y);
      spez.items.forEach((spezitems) => {
        let c = spezitems.color;
        let wert = spezitems.wert;
        if (wert != null){
          spezitems.pos.forEach((ipos) => {
            if (ipos.von != undefined){
              let xs = this.mvLinks + ((ipos.von - this.sStart) * this.mvMassstabH);
              let l = ipos.bis - ipos.von;
              let w = (l * this.mvMassstabH);
              this.cx.fillStyle = c;
              this.cx.fillRect(xs, y-20, w, 25);
            }
          });
        }

      });
      y += this.mvRowHeight;
    });

  }
*/


  draw(){
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    canvasEl.width = this.scrWidth - 10;
    canvasEl.height = this.scrHeight - 250;

    let blockMangelHeight = 0;
    let z:number = 0;
    Object.keys(this.mangelblock).forEach((tableKey) => {
      z++
    });

    let y = 30;
    if (z != 0){
      blockMangelHeight = y + z * this.mvRowHeight + this.mvRowHeight / 2;
      if (blockMangelHeight != 0){
        y = blockMangelHeight;
      }
    }

    let anzspezblock = this.spezblocks.length;
    let blockSpezHeight = y + anzspezblock * this.mvRowHeight + this.mvRowHeight / 2;
    canvasEl.height = blockSpezHeight + 20;

    this.rectWidt = canvasEl.width - this.mvLinks;
    this.cx.fillStyle = this.foreColor;
    this.mvMassstabH = this.rectWidt / this.mvStreckenLaenge * 0.85;
    let steps = parseInt((this.mvStreckenLaenge/100).toString()) + 3;
    this.cx.font = "12px Metropolis";

    y = 30;
    if (z != 0){

      let xtxt1 = 15;
      let xtxt2 = 180;

      this.cx.beginPath();
      this.cx.moveTo(0, y);
      this.cx.lineTo(canvasEl.width, y);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();

      for(let i = 0 ; i < steps; i++){
        let x = (i * 100 * this.mvMassstabH) + this.mvLinks;

        this.cx.beginPath();
        this.cx.moveTo(x, 0);
        this.cx.lineTo(x, blockMangelHeight);
        this.cx.strokeStyle = '#485A6A';
        this.cx.stroke();

        let txt = this.sStart + (i*100);
        this.cx.fillText(txt.toString(), x + 5, y - 5);

      }

      this.cx.beginPath();
      this.cx.moveTo(0, blockMangelHeight);
      this.cx.lineTo(canvasEl.width, blockMangelHeight);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();

      y = 60;

      this.cx.font = "14px Metropolis";

      Object.keys(this.mangelblock).forEach((tableKey) => {

        let color = "black";
        this.cx.fillStyle = this.foreColor;

        this.cx.fillText(tableKey.toString(),180,y);
        let maengel = this.mangel.rows.filter((cells) => cells.mangel == tableKey);
        let r = maengel[0];
        this.cx.fillText(r['pruefart'].toString(),10,y);

        maengel.sort((a,b)=>b.fehlerklasse - a.fehlerklasse);

        maengel.forEach((row) => {
          let s = parseFloat(row.von.toString());
          let e = s;

          if  (row.bis != null){
            e = parseFloat(row.bis.toString());
          }

          let fk = row.fehlerklasse.toString();

          let xs = this.mvLinks + ((s - this.sStart) * this.mvMassstabH);
          let l = e - s;
          if (l < 1) l = 1;

          let w = (l * this.mvMassstabH);


          color = "blue";

          switch(fk) {
            case "0": {
              color = "red";
              break;
            }
            case "1": {
              color = "red";
              break;
            }
            case "2": {
              color = "yellow";
              break;
            }
            case "3": {
              color = "green";
              break;
            }
            case "4": {
              color = "blue";
              break;
            }
            default: {
              color = "blue";
              break;
            }
          }

          this.cx.fillStyle = color;
          this.cx.fillRect(xs, y-20, w, 25);
        });

        y+=30;

      });

      y = 30;
      if (blockMangelHeight != 0){
        y = blockMangelHeight;
      }
    }

    if (z == 0){
      this.cx.beginPath();
      this.cx.moveTo(0, y);
      this.cx.lineTo(canvasEl.width, y);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();
    }

    this.cx.fillStyle = this.foreColor;
    for(let i = 0 ; i < steps; i++){
      let x = (i * 100 * this.mvMassstabH) + this.mvLinks;

      this.cx.beginPath();
      this.cx.moveTo(x, y);
      this.cx.lineTo(x, blockSpezHeight);
      this.cx.strokeStyle = '#485A6A';
      this.cx.stroke();

      if (y == 30){
        let txt = this.sStart + (i*100);
        this.cx.fillText(txt.toString(), x + 5, y - 5);
      }
    }

    this.cx.beginPath();
    this.cx.moveTo(0, blockSpezHeight);
    this.cx.lineTo(canvasEl.width, blockSpezHeight);
    this.cx.strokeStyle = '#485A6A';
    this.cx.stroke()

    y += this.mvRowHeight;

    this.spezblocks.forEach((spez) => {
      this.cx.fillStyle = this.foreColor;
      this.cx.fillText(spez.key,10,y);
      spez.items.forEach((spezitems) => {
        let c = spezitems.color;
        let wert = spezitems.wert;
        if (wert != null){
          spezitems.pos.forEach((ipos) => {
            if (ipos.von != undefined){
              let xs = this.mvLinks + ((ipos.von - this.sStart) * this.mvMassstabH);
              let l = ipos.bis - ipos.von;
              let w = (l * this.mvMassstabH);
              this.cx.fillStyle = c;
              this.cx.fillRect(xs, y-20, w, 25);
            }
          });
        }

      });
      y += this.mvRowHeight;
    });

  }




  async ngOnInit() {
    if (localStorage.getItem("theme") == "dark"){
      this.foreColor ='#EEF1F2';
    }
  }

  changecolorkey: string;
  changecolorwert: string;
  changeColor(key, wert, incolor, input: HTMLInputElement){
    this.color = incolor;

    this.changecolorkey = key;
    this.changecolorwert = wert;

    input.focus();
    input.click();
  }

  setColorFromPicker(event) {
    this.color = event;
    let find = false;

    for(let i = 0 ; i < this.spezblocks.length; i++){
      let spez = this.spezblocks[i];
      if (spez.key == this.changecolorkey){
        for(let j = 0 ; j < spez.items.length; j++){
          let item = spez.items[j];
          if (item.wert == this.changecolorwert){
            item.color = this.color;
            find = true;

            this.draw();

            break;
          }
        }
      }
      if (find) break;
    }
  }

  public close() {
    this.closedialog.next(true);
  }


  getStammValue(fied: string){
    return this.stamm.rows.find((row) => row.DBField == fied)?.Bezeichnung;
  }

  getSpezValue(row: any, field: string, typ: string, wert: string){
    let ret = undefined;
    let find = false;
    Object.keys(row).forEach((tableKey) => {
      if (tableKey == typ && wert == row[tableKey].value){
        find = true;
      }
    });

    if (find){
      Object.keys(row).forEach((tableKey) => {
        if (tableKey == field){
          ret = row[tableKey].value;
        }
      });
    }

    return ret;
  }

  getValueArray(array, field){

    const groups = [];
    array.forEach((item) => {
      let v = item[field].value;
      if (!groups.includes(v)){
        groups.push(v);
      }
    });
    return groups;
  }


  groupByKey(array, key) {
    return array
      .reduce((hash, obj) => {
        if(obj[key] === undefined) return hash;
        return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
      }, {})
  }



}
