import { Injectable } from '@angular/core';
import { ColumnFilterChange, ColumnFilterAction } from './column-filter/column-filter.component';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private originalData: any[] = [];
  private changes: { [columnId: string]: ColumnFilterChange } = {};

  constructor(data: any[]) {
    this.originalData = data;
  }

  clearFilters() {
    this.changes = {};
  }

  setChanges(changes: any) {
    this.changes = changes || {};
    return this.filterData();
  }
  getChanges(): any {
    return this.changes;
  }

  // filter(columnFilterChange: ColumnFilterChange): any[] {
  //   if (columnFilterChange.reason != ColumnFilterAction.Cancel) {
  //     const filters = columnFilterChange.items;
  //     if (filters.length > 0) {
  //       this.changes[columnFilterChange.columnId] = columnFilterChange;
  //     } else {
  //       delete this.changes[columnFilterChange.columnId];
  //     }
  //     return this.filterData();
  //   }
  //   return this.originalData;
  // }
  filter(columnFilterChange: ColumnFilterChange): any[] {
    console.log(`Action: ${ColumnFilterAction[columnFilterChange.reason]}`);
    console.log(`Before filtering: ${this.originalData.length} items`);

    if (columnFilterChange.reason !== ColumnFilterAction.Cancel) {
      const filters = columnFilterChange.items;
      if (filters.length > 0) {
        this.changes[columnFilterChange.columnId] = columnFilterChange;
      } else {
        delete this.changes[columnFilterChange.columnId];
      }
      const result = this.filterData();
      console.log(`After filtering: ${result.length} items`);
      return result;
    }

    console.log(`Cancel detected – returning unfiltered original data`);
    return this.originalData;
  }


  // private filterItemsByColumn(item: any, columnId: string): boolean {
  //   const filterItems = this.changes[columnId].items;
  //   return filterItems.some(filterItem => {
  //     const itemValue = item[columnId]?.toString().toLowerCase();

  //     return itemValue && itemValue.toString().toLowerCase() === filterItem.name.toString().toLowerCase();
  //   });
  // }

  private filterItemsByColumn(item: any, columnId: string): boolean {
    const normalizedColumnId = columnId.toLowerCase();
    const filterEntry = this.changes[normalizedColumnId];
    if (!filterEntry) return true;

    const filterItems = filterEntry.items;

    return filterItems.some(filterItem => {
      const normalizedColumnId = columnId.toLowerCase();

      // Try to find the key in item with case-insensitive match
      const itemKey = Object.keys(item).find(key => key.toLowerCase() === normalizedColumnId);
      if (!itemKey) return false;

      const itemValue = item[itemKey]?.toString().toLowerCase();
      return itemValue === filterItem.name.toString().toLowerCase();
    });

  }


  private filterData(): any[] {
    console.time('filterData');
    let filteredData;
    if (Object.keys(this.changes).length > 0) {
      filteredData = this.originalData.filter(item => {
        return Object.keys(this.changes).every(columnId => this.filterItemsByColumn(item, columnId));
      });
    } else {
      filteredData = [...this.originalData];
    }
    console.timeEnd('filterData');
    return filteredData;
  }
}
