import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ClrAccordionModule, ClrCommonFormsModule, ClrConditionalModule, ClrDatagridModule, ClrDatalistModule, ClrDatepickerModule, ClrInputModule, ClrModalModule, ClrPopoverHostDirective, ClrSelectModule, ClrStopEscapePropagationDirective, ClrTextareaModule } from '@clr/angular';
import dayjs from "dayjs";
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { OverlayService } from 'src/app/services/Overlay/overlay.service';
import { SpinnerService } from 'src/app/services/Spinner/spinner.service';

@Component({
    selector: 'app-multi-auftrag-modal',
    templateUrl: './multi-auftrag-modal.component.html',
    styleUrls: ['./multi-auftrag-modal.component.scss'],
    providers: [BackendLocaleDatePipe],
    imports: [
        ClrModalModule,
        FormsModule,
        ClrCommonFormsModule,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrDatepickerModule,
        ClrSelectModule,
        NgFor,
        ClrDatalistModule,
        ClrInputModule,
        ClrTextareaModule,
        ClrAccordionModule,
        ClrDatagridModule,
        ClrConditionalModule,
        MrTranslatePipe,
        BackendLocaleDatePipe,
    ],
    standalone: true
})
export class MultiAuftragModalComponent {

  @Input() maengel: any[];

  /** @emits true when saved, for reload */
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  private toReload = false;
  protected set isMultiAuftragModalOpen(isOpen: boolean) {
    this._isMultiAuftragModalOpen = isOpen;
    if (!isOpen) this.onClose.emit(this.toReload);
  }
  protected get isMultiAuftragModalOpen() {
    return this._isMultiAuftragModalOpen;
  }
  private _isMultiAuftragModalOpen = false;
  protected selectedValid: any[] = [];
  protected firmen: any[];
  protected trial = false;
  protected multiauftrag = {
    startdate: null,
    enddate: null,
    auftragnehmer: null,
    auftragsart: null,
    auftragsnr: null,
    bemerkung: null,
  };


  constructor(
    private apiService: APIService,
    private spinner: SpinnerService,
    private overlayService: OverlayService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
    protected localeDate: BackendLocaleDatePipe
  ) {
    this.apiService
      .getFirmen()
      .pipe(first())
      .subscribe((firm: any) => {
        this.firmen = firm.rows;
      });
    const laenge = this.authService.getToken().auftragslaenge || 3;
    this.multiauftrag.startdate = BackendLocaleDatePipe.now;
    this.multiauftrag.enddate =
      dayjs().add(laenge, "d").format(BackendLocaleDatePipe.format);

    this.trial = this.authService.getToken().trial;
  }

  ngOnInit() {
    if (this.maengel?.length) this.openModalIfValid();
    else this.isMultiAuftragModalOpen = false;
  }

  private openModalIfValid() {
    for (const el of this.maengel) {
      if (!el.erledigt && !el.auftrag)
        this.selectedValid.push({...el});
    }
    if (this.selectedValid.length) {
      this.isMultiAuftragModalOpen = true;
    }
    else {
      this.toastr.error(
        this.mrTranslate.transform(
          "Mängel dürfen nicht im Status Auftrag oder Erledigt sein"
        ),
        this.mrTranslate.transform(
          "Keine gültigen Mängel ausgewählt",
        )
      );
      this.isMultiAuftragModalOpen = false;
    }
  }

  protected sendMultiAuftrag() {
    this.spinner.enable();
    let objMangel = {};
    this.selectedValid.forEach((mangel) => {
      if (!objMangel[mangel.ostammid]) {
        objMangel[mangel.ostammid] = [];
      }
      objMangel[mangel.ostammid].push(mangel.omazsid);
    });

    let data: any = this.multiauftrag;
    data.omazsids = objMangel;
    data.language = localStorage.getItem("language");

    if (data.startdate && data.enddate && data.auftragnehmer) {
      const now = dayjs().startOf('day');
      const start = dayjs(data.startdate).startOf('day');
      const end = dayjs(data.enddate).startOf('day');
      const bigger = !end.isBefore(start);
      const nowCS = !start.isBefore(now);
      const nowCE = !end.isBefore(now);
      data.kostenart = "";
      data.titel = "";
      data.auftragsgruppe = "1";

      if (bigger && nowCS && nowCE) {
        this.apiService
          .sendAuftrag(data)
          .pipe(first())
          .subscribe((val) => {
            this.spinner.disable();
            if (val) {
              this.toastr.success(
                this.mrTranslate.transform("Eintrag erfolgreich gespeichert")
              );
              this.toReload = true;
              this._isMultiAuftragModalOpen = false;

              this.overlayService.setOverlay({
                overlay: "viewAuftrag",
                data: val,
                auftragsnr: this.multiauftrag.auftragsnr,
                zIndex: 5,
              });
            }
            else this.toastr.error(this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
          });
      }
      else  {
        this.toastr.warning(this.mrTranslate.transform(
          "Beide Daten müssen in der Zukunft und das Startdatum muss vor dem Enddatum liegen."
        ));
        this.spinner.disable();
      }
    }
    else {
      this.toastr.warning(this.mrTranslate.transform(
        "Bitte füllen Sie Start- & Enddatum, Auftragsnehmer & -nummer, sowie mindestens einen Mangel aus."
      ));
      this.spinner.disable();
    }
  }

}
