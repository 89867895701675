import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from 'src/app/services/APIService/api.service';
import { ClrDatagrid } from "@clr/angular";
import { HilfeBeschreibungModalComponent } from "../../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";

@Component({
    selector: "app-mangelbaumitems",
    templateUrl: "./mangelbaumitems.component.html",
    styleUrls: ["./mangelbaumitems.component.scss"],
    imports: [CommonModule, ClarityModule, HilfeBeschreibungModalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, TablePrettyPrintPipe],
    standalone: true
})

export class MangelbaumitemsComponent implements OnInit {
  @Input() tablename: string = "";
  @Input() titel: string = "";
  //@Input() otypid: number = 1;
  @Output() selected: EventEmitter<object> = new EventEmitter();

  otypid: number;

  @Input() set setotypid(inotypid: number) {
    if (inotypid > 0) {
    }
    this.otypid = inotypid;
    this.apiServiceItem('get', '');
  }
  get setotypid() {
    return this.otypid;
  }

  @ViewChild('dgStammdaten', { read: ClrDatagrid, static: false })
    private grid: ClrDatagrid
  ;
  @ViewChild(HilfeBeschreibungModalComponent, { static: false })
    protected hilfeModal: HilfeBeschreibungModalComponent
  ;
  public selectionChanged(event) {
    this.selected.emit(event.Bezeichnung.value);
  }


  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
  ) {

  }

  selid: number = 0;
  tabelleNameGroup = new UntypedFormGroup({
    txt0s: new UntypedFormControl('', [Validators.required]),
  });

  stammdaten: any;
  stammdatenOriginal: any;
  base64data: any;
  base64datatyp: any;
  selecteditem: any;

  ngOnInit() {

    //this.apiServiceItem('get', '');

    // let data = {
    //   status: 'get',
    //   id: this.selid,
    //   tabelle: this.tablename,
    //   tname: this.tablename,
    //   OTYPID: this.otypid,
    // }
    // this.stammdaten =[];

    // this.apiservice
    // .getBaseDataItems(data)
    // .pipe(first())
    // .subscribe((val: any) => {

    //   this.stammdaten = val;

    // })

  }


  protected openHelper() {
    this.hilfeModal.item = { ...this.selecteditem };
  }

  protected async saveHelper(sendObj: any) {
    this.apiservice
      .setMangelsHelper(this.selid, sendObj)
      .pipe(first())
      .subscribe(({success, error, neuRow}: any) => {
        if (success) {
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
          this.hilfeModal.item = null;
          const neuItem = { ...this.selecteditem, ...sendObj, ...neuRow };

          this.stammdaten.rows.splice(
            this.stammdaten.rows.indexOf(this.selecteditem),
            1, neuItem
          );
          this.grid.singleSelected = neuItem;
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }

  newitem(){
    this.tabelleNameGroup.controls.txt0s.setValue('');
    this.selid = 0;
  }

  saveitem(){

    let vals = this.tabelleNameGroup.value;

    if (vals.txt0s == ''){
      this.toastr.error(this.mrTranslate.transform(this.mrTranslate.transform("Bitte geben Sie eine Bezeichnung an")));
      return;
    }

    if(this.stammdatenOriginal.some((item) => item.Bezeichnung.toLowerCase() == vals.txt0s.toLowerCase() && item.ID != this.selid)){
      this.toastr.error(this.mrTranslate.transform("Eintrag existiert bereits"));
      return;
    }
    this.apiServiceItem('update', vals.txt0s);

  }

  deletitem(){
    if (confirm("Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?")) {
      let vals = this.tabelleNameGroup.value;
      this.apiServiceItem('delete', vals.txt0s);
    }
  }

  apiServiceItem(status: string, value: string){

    let inData = {
      status: status,
      id: this.selid,
      value: value,
      titel: this.titel,
      tabelle: this.tablename,
      OTYPID: this.otypid,
      tname: '',
      typid: 0,
      gattungid: 0,
      schhmax: 0,
      bauwcheck: false,
      profiltyp: '',
      profil: '',
      base64Image: undefined,
      sbagp: undefined,
      sbagpold: '',
      base64data: this.base64data,
      base64datatyp: this.base64datatyp,
    }

    this.stammdaten = undefined;

    this.apiservice
    .getBaseDataItems(inData)
    .pipe(first())
    .subscribe((val: any) => {

      this.stammdaten = val;
      this.stammdatenOriginal = val.rows;

      setTimeout(()=>{
        if (this.stammdaten.rows.length != 0)
          this.selecteditem = this.stammdaten.rows[0];
        },10);
    });
  }

  selectionItemsChanged(value: any) {
    if (!value) return;
    this.tabelleNameGroup.controls.txt0s.setValue(value.Bezeichnung);
    setTimeout(() => {
      this.selid = value.ID;
      this.selected.emit({
        id: this.selid,
        bezeichnung: value.Bezeichnung,
        tablename: this.tablename,
      })
    });
  }
}
