import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { first, map } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";

import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { PlotlyPlotComponent } from "../../../plotly-plot/plotly-plot.component";

@Component({
  selector: "app-statistics-diagram",
  templateUrl: "./statistics-diagram.component.html",
  styleUrls: ["./statistics-diagram.component.scss"],
  imports: [CommonModule, ClarityModule, MrTranslatePipe,PlotlyPlotComponent],
  providers: [BackendLocaleDatePipe],
  standalone: true
})
export class StatisticsDiagramComponent implements AfterViewInit {
  _selectedMode: Array<any> = [];
  currentstatistic: any;
  isTable: boolean = false;
  isEmpty: boolean = true;
  isLoading: boolean = false;

  @Input() set selectedMode(newMode: Array<any>) {
    this._selectedMode = newMode;
    this._filterStartDate ||= BackendLocaleDatePipe.now;
    this._filterEndDate ||= BackendLocaleDatePipe.now;
    newMode.length && this.getSelectedStatistic();
  }
  get selectedMode() {
    return this._selectedMode;
  }

  _filterStartDate = "";
  @Input() set filterStartDate(newStartDate: string) {
    this._filterStartDate = newStartDate;
    newStartDate && this.getSelectedStatistic();
  }
  get filterStartDate() {
    return this._filterStartDate;
  }

  _filterEndDate = "";
  @Input() set filterEndDate(newEndDate: string) {
    this._filterEndDate = newEndDate;
    newEndDate && this.getSelectedStatistic();
  }
  get filterEndDate() {
    return this._filterEndDate;
  }

  _allInspections = true;
  @Input() set allInspections(newAllInspections: boolean) {
    this._allInspections = newAllInspections;
    this.getSelectedStatistic();
  }
  get allInspections() {
    return this._allInspections;
  }

  @ViewChild("statisticWrapper") statisticWrapper: ElementRef;

  graph: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: true,
      margin: {
        l: 150,
        r: 150,
        b: 150,
        t: 150,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  constructor(
    private apiservice: APIService,
    private mrTranslate: MrTranslatePipe
  ) { }

  ngAfterViewInit() { }

  setPlotlyDimension() {
    let dimension =
      this.statisticWrapper?.nativeElement?.getBoundingClientRect() ||
      undefined;
    if (dimension?.height) this.graph.layout.height = dimension.height;
  }

  getSelectedStatistic() {
    this.isTable = false;
    if (!this.filterStartDate || !this.filterEndDate || !this.selectedMode?.length) {
      return;
    }

    this.isLoading = true;
    let mode = JSON.parse(JSON.stringify(this.selectedMode));
    mode = mode.map(m => m.column || m);
    if (mode[0]?.ID) mode[0] = mode[0].ID;
    this.apiservice
      .getStatistik({
        startdate: this.filterStartDate,
        enddate: this.filterEndDate,
        mode: mode.join("."),
        allInspections: this.allInspections,
      })
      .pipe(
        first(),
        map((res: any) => {
          if (res[0]?.type == "pie") {
            res[0].textinfo = "percent";
            res[0].textposition = "outside";
            res[0].labels = res[0].labels.map((l) =>
              this.mrTranslate.transform(l)
            );
          } else if (res[0]?.type == "bar") {
            res = res.map((r) => {
              r.name = this.mrTranslate.transform(r.name);
              return r;
            });
          }
          return res;
        })
      )
      .subscribe((res: any) => {
        this.currentstatistic = res;
        this.isEmpty = false;
        if (!Array.isArray(res) && res.type == "table") {
          this.isTable = true;
        } else if (Object.keys(res).length == 0 || (Object.keys(res).length > 0 && res[0].type == null)) {
          this.isEmpty = true;
        } else {
          this.setPlotlyDimension();
        }
        this.isLoading = false;
      });
  }
}
