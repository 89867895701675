<ng-container *ngIf="selectedMode?.length > 0; else elseTemplate">
  <h4 class="mt-0">{{'Ausgewählte' | mrTranslate | titlecase }} {{ 'Statistik' | mrTranslate }}:</h4>
  <p class="mt-0">
    <span *ngFor="let sel of selectedMode; let i = index">
      {{ (sel.column ? sel.name : (sel.bezeichnung ? sel.bezeichnung : sel)) | titlecase | mrTranslate }}
      <cds-icon *ngIf="i !== selectedMode.length -1" shape="angle" direction="right" style="color: #bbbbbb"></cds-icon>
      </span>
  </p>
  <div id="statisticWrapper" #statisticWrapper>
    <app-plotly-plot *ngIf="!isLoading && !isEmpty && currentstatistic.length > 0 && !isTable" [className]=""
      [data]="currentstatistic" [layout]="graph.layout" [config]="graph.config"></app-plotly-plot>

    <clr-datagrid id="datagridWrapper" *ngIf="!isLoading && !isEmpty && isTable && currentstatistic as table"
      class="datagrid-compact">
      <clr-dg-column *ngFor="let col of table.columns" [clrDgField]="col.id">
        {{col.id | titlecase | mrTranslate}}</clr-dg-column>
      <clr-dg-row *clrDgItems="let row of table.rows">
        <clr-dg-cell *ngFor="let col of table.columns">{{row[col.id]}}</clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>{{table.rows.length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
    </clr-datagrid>

    <div *ngIf="isLoading" class="flexCenterWrapper">
      <clr-spinner>{{'Lädt' | mrTranslate}} ...</clr-spinner>
    </div>

    <div *ngIf="isEmpty && !isLoading" class="flexCenterWrapper">
      {{'Leeres Ergebnis.' | mrTranslate}}
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <p class="mt-0">
    {{'Bitte links eine Statistik auswählen.' | mrTranslate}}
  </p>
</ng-template>