import { Pipe, PipeTransform } from '@angular/core';
import { ZählerWert } from './zaehler.types';

@Pipe({
  name: 'werteColNames',
  standalone: true
})
export class WerteColNamesPipe implements PipeTransform {

  transform(wertColName: keyof ZählerWert): string {
    return matchMap[wertColName] ?? wertColName;
  }
}

const matchMap: Partial<Record<keyof ZählerWert, string>> = {
  'SR100minus': 'SR100 -',
  'SR100plus': 'SR100 +',
  'aktivSR100': 'SR100 aktiv',

  'SRGminus': 'SRG -',
  'SRGplus': 'SRG +',
  'aktivSRG': 'SRG aktiv',

  'SRlimminus': 'SRlim -',
  'SRlimplus': 'SRlim +',
  'aktivSRlimm': 'SRlim aktiv',

  'ZType': 'Header'
};