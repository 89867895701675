import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UnitLength, UnitMeasurement, addUnitAbbreviationToText, addUnitToValue, lengthUnitColumns, measurementUnitColumns } from "../misc/functions";
import { BasicStoreService } from "../services/BasicStore/basic-store.service";
import { MrTranslatePipe } from "./mr-translate.pipe";

@Pipe({
  name: "mrUnitText",
  standalone: true

})
export class MrUnitTextPipe implements PipeTransform {
  private settings: any | undefined;
  constructor(private domSanitizer: DomSanitizer, private basicStore: BasicStoreService, private translate: MrTranslatePipe) {
    this.basicStore.getComponentStoreAsync('einstellung').subscribe(settings => {
      if (settings) this.settings = JSON.parse(settings)
    }
    )

  }
  transform(value: any, force: boolean = false, isLength: boolean = false, isMeasurement: boolean = false): any {
    if (value) {
      const lowercase = value.toLowerCase();
      if (this.settings) {
        if (lengthUnitColumns.includes(lowercase)) {
          return addUnitAbbreviationToText(this.translate.transform(value), UnitLength, this.settings.einstellung?.optUnitLength ?? 0);
        } else if (measurementUnitColumns.includes(lowercase)) {
          return addUnitAbbreviationToText(this.translate.transform(value), UnitMeasurement, this.settings.einstellung?.optUnitWidth ?? 0);
        }
      }
    }

    return this.translate.transform(value);
  }
}
