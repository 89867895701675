import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    public _connection: HubConnection;
    constructor() {}

    startCon() {
        if (!this._connection)
            this._connection = new HubConnectionBuilder()
                .configureLogging(LogLevel.Trace)
                .withUrl(environment.apiUrl + '/progress', { withCredentials: false, transport: HttpTransportType.LongPolling })
                .withAutomaticReconnect()
                .build();

        this._connection.start().catch((err) => console.log(err));
        console.log("SignalR Connected.");
    }
    stopCon() {
        if (this._connection) {
            this._connection.stop().catch((err) => console.log(err));
            console.log("SignalR Disconnected.");
        }
    }
}