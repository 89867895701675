import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, } from "@angular/forms";
import { ClarityModule, ClrForm, ClrModal } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription, first } from "rxjs";

import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { LeftPadPipe } from "src/app/pipes/left-pad.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { SignaturePadModalComponent } from "../../_shared/signature-pad-modal/signature-pad-modal.component";

@Component({
  selector: "app-abnahme-protokoll",
  templateUrl: "./abnahme-protokoll.component.html",
  styleUrls: ["./abnahme-protokoll.component.scss"],
  imports: [CommonModule, ClarityModule, PreviewThumbnailsComponent, SignaturePadModalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule,
    LeftPadPipe, DotDotDotPipe, HideIDColumnsPipe, TablePrettyPrintPipe, BackendLocaleDatePipe],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})
export class AbnahmeProtokollComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected modalAntwort: BehaviorSubject<string> = new BehaviorSubject("");
  private modalSub: Subscription;
  protected isChanged: boolean = false;

  protected openSignPad: boolean = false;

  private abnahmeId: number = undefined;
  private firmenId = 0;
  protected mode = "new";
  protected titel: string;

  private objekts = [];
  protected objekteBez: string[] = [];
  protected objekteIcons: string[] = [];

  protected geprueftChecks = [
    "Spurweite",
    "Gleislage",
    "Schwellenteilung / Lage",
    "Bettung",
    "Schienenverspannung",
    "Verschlußteile",
    "Randwege",
    "Freimeldung"
  ];
  protected genommenChecks = [
    "abgenommen",
    "in Betrieb genommen",
    "per Funk freigegeben"
  ];


  protected unterschriftPruefer: string = "";
  protected unterschriftKunde: string = "";

  protected isUnterschriebenPruefer: boolean = false;
  protected isUnterschriebenKunde: boolean = false;
  protected isZoomedIn: boolean = true;

  private deletedFiles: number[] = [];

  @ViewChild(PreviewThumbnailsComponent, {static: true})
    private preview: PreviewThumbnailsComponent
  ;
  @ViewChild(SignaturePadModalComponent, {static: false})
    private signPad: SignaturePadModalComponent

  @ViewChild(ClrForm, { static: true })
    private clrForm: ClrForm
  ;
  @ViewChild('saveFileChanges', { static: true })
    private modalFileChanges: ClrModal
  ;

  protected abnahmeProtokollFormGroup = new UntypedFormGroup({
    auftragId: new UntypedFormControl(""),
    auftragnehmer: new UntypedFormControl(""),
    pruefer: new UntypedFormControl(""),
    datum: new UntypedFormControl(BackendLocaleDatePipe.now, [Validators.required]), /* get; set; as dateControl */

    geprueftArr: new UntypedFormArray(this.geprueftChecks.map(() => new UntypedFormControl(false))),
    genommenArr: new UntypedFormArray(this.genommenChecks.map(() => new UntypedFormControl(false))),

    bemerkung: new UntypedFormControl(""),
    freigegebenAn: new UntypedFormControl("", [Validators.required]),
    isNeuMangel: new UntypedFormControl("nein"),
    isErteilt: new UntypedFormControl("", [Validators.required]),
    unterschriftPrueferName: new UntypedFormControl("", [Validators.required]),
    unterschriftKundeName: new UntypedFormControl("", [Validators.required]),

    // * Wenn Unterschrift ist required:
    // unterschriftPruefer: new UntypedFormControl(false), /* [checkUnterschrift()] */
    // unterschriftKunde: new UntypedFormControl(false), /* [checkUnterschrift()] */
  });

  protected get geprueftArr(): UntypedFormArray {
    return this.abnahmeProtokollFormGroup.get("geprueftArr") as UntypedFormArray;
  }
  protected get genommenArr(): UntypedFormArray {
    return this.abnahmeProtokollFormGroup.get("genommenArr") as UntypedFormArray;
  }


  protected selectedObjekt: string = "";
  protected neueMaengelTables = {};
  protected trackByOmazsID = (index, row) => row["OMAZSID"];

  private dataRefresh: EventEmitter<any>;
  private refreshAbnahmen: BehaviorSubject<boolean>;


  constructor(
    private authService: AuthService,
    private overlayService: OverlayService,
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private toastr: ToastrService,
    protected localeDate: BackendLocaleDatePipe
  ) {}

  public set data({ data: inData, recalledData: vorData, dataRefresh, refreshAbnahmen }: any) {
    if (inData) {
      this.abnahmeProtokollFormGroup.get("auftragId").setValue(inData.id);
      this.titel = inData.titel || "Instandsetzung";
      this.abnahmeProtokollFormGroup.get("auftragnehmer").setValue(inData.auftragnehmer || "Firma");
      if (inData.objTable?.rows?.length) {
        this.objekts = inData.objTable.rows;
        this.getObjektsBez();
      }
      this.firmenId = inData.firmenId;
      this.abnahmeId = vorData?.abnahmeId || inData.abnahmeId;
      if (this.abnahmeId) {
        this.mode = "edit";
        if (!vorData) {
          this.apiService.getAbnahme(this.abnahmeId)
            .pipe(first())
            .subscribe(({ abnahme, mangel, files }: any) => {
              this.abnahmeProtokollFormGroup.get("pruefer").setValue(abnahme.pruefer);
              this.abnahmeProtokollFormGroup.get("datum").setValue(abnahme.datum);
              for (let i = 0; i < this.geprueftArr?.length; i++) {
                this.geprueftArr.controls[i].setValue(abnahme["CHK" + (i + 1)]);
              }
              for (let i = 0; i < this.genommenArr?.length; i++) {
                this.genommenArr.controls[i].setValue(abnahme["FUNC" + (i + 1)]);
              }
              this.abnahmeProtokollFormGroup.get("bemerkung").setValue(abnahme.bemerkung);
              this.abnahmeProtokollFormGroup.get("freigegebenAn").setValue(abnahme.freigegebenAn);
              this.abnahmeProtokollFormGroup.get("isNeuMangel").setValue(abnahme.isNeuMangel ? "ja" : "nein");
              this.abnahmeProtokollFormGroup.get("isErteilt").setValue(abnahme.isErteilt ? "erteilt" : "nicht erteilt");
              this.abnahmeProtokollFormGroup.get("unterschriftPrueferName").setValue(abnahme.unterschriftPrueferName);
              this.abnahmeProtokollFormGroup.get("unterschriftKundeName").setValue(abnahme.unterschriftKundeName);
              this.unterschriftPruefer = abnahme.unterschriftPruefer;
              this.unterschriftKunde = abnahme.unterschriftKunde;
              if (this.unterschriftPruefer) this.isUnterschriebenPruefer = true;
              if (this.unterschriftKunde) this.isUnterschriebenKunde = true;

              if (mangel) {
                Object.keys(this.neueMaengelTables).forEach((ostammid) => {
                  this.neueMaengelTables[ostammid].columns = mangel.columns;
                  this.neueMaengelTables[ostammid].rows = mangel.rows.filter((row) => row.ostammid == ostammid);
                })
              }

              setTimeout(() => {
                this.preview.unpackFiles(files);
              });
            });
        }
      }
    }
    if (vorData) {
      // Es gibt gespeicherte Daten für diesen Overlay vom letzten Mal
      this.abnahmeProtokollFormGroup.setValue(vorData.formValue);
      this.selectedObjekt = vorData.selectedObjekt;
      this.neueMaengelTables = vorData.neueMaengelTables;
      this.unterschriftPruefer = vorData.signatures?.pruefer;
      this.unterschriftKunde = vorData.signatures?.kunde;
      if (this.unterschriftPruefer) this.isUnterschriebenPruefer = true;
      if (this.unterschriftKunde) this.isUnterschriebenKunde = true;
      setTimeout(() => {
        this.preview.rawPreviewThumbnails = vorData.previewThumbnails;
      }, 10);

      // * Geprüft, ob neue Mängel erstellt wurden
      const objekt = this.getSelectedObjekt();
      if (vorData.mangelCount) this.isChanged = (this.neueMaengelTables[objekt.ostammid]?.rows?.length ?? 0) != vorData.mangelCount;
      this.isChanged ||= vorData.isChanged;
    }
    this.dataRefresh = dataRefresh;
    this.refreshAbnahmen = refreshAbnahmen;
  }

  ngOnInit(): void {
    const pruefer = this.authService.getToken().username;
    this.abnahmeProtokollFormGroup.get("pruefer").setValue(pruefer);
    this.abnahmeProtokollFormGroup.get("unterschriftPrueferName").setValue(pruefer);
  }

  private getObjektsBez() {
    this.objekts.forEach(objekt => {
      this.objekteIcons.push("objekttyp" + objekt["typ"])
      this.objekteBez.push(objekt["Bezeichnung"]);
      this.neueMaengelTables[objekt["ostammid"]] = {};
    });

    this.selectedObjekt = this.objekteBez[0];
  }
  protected getSelectedObjektIcon() {
    const index = this.objekteBez.indexOf(this.selectedObjekt);
    return this.objekteIcons[index];
  }
  protected getSelectedObjekt() {
    const index = this.objekteBez.indexOf(this.selectedObjekt);
    return this.objekts[index];
  }


  protected async saveAbnahmeProtokoll() {
    const isFormChanged = this.abnahmeProtokollFormGroup.dirty || this.isChanged;
    const sendObj: any = { updateInhalt: isFormChanged };
    sendObj.abnahmeId = this.abnahmeId ?? 0;

    if (isFormChanged) {
      sendObj.mode = this.mode || "new";

      sendObj.formValues = this.abnahmeProtokollFormGroup.getRawValue();
      sendObj.formValues.FirmaID = this.firmenId;
      sendObj.formValues.signatures = {
        pruefer: this.unterschriftPruefer ?? "",
        kunde: this.unterschriftKunde ?? "",
      }

      let mangelArray = [];
      let objektArray = [];
      Object.entries(this.neueMaengelTables).forEach(
        ([key, table]: any) => {
          objektArray.push(key);
          table?.rows?.forEach(
            (row) => mangelArray.push(row.OMAZSID));
        });

      sendObj.formValues.mangelIds = mangelArray;
      sendObj.formValues.objektIds = objektArray;
    }

    try {
      sendObj.files = await this.preview.packUnsavedFiles();
    } catch (er) {
      this.toastr.error(this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten"));
    }
    sendObj.deletedFiles = this.deletedFiles;
    sendObj.language = localStorage.getItem("language");

    this.apiService.sendAbnahme(sendObj)
      .pipe(first())
      .subscribe(({ success, id, pdf }: any) => {
        if (success) {
          this.mode = "edit";
          this.abnahmeId = id;
          this.dataRefresh?.emit();
          this.refreshAbnahmen?.next(true);
          this.toastr.success(this.mrTranslate.transform("Abnahme Protokoll ist gespeichert!"));
          this.isChanged = false;
          this.deletedFiles = [];
          this.preview.setSaved();
          if (pdf) {
            this.preview.rawPreviewThumbnails = this.preview.rawPreviewThumbnails.filter(
              (thumbnail) => !thumbnail.highlight
            );
            this.preview.unpackFiles([pdf]);
            this.toastr.success(this.mrTranslate.transform("PDF Dokument wurde erstellt!"));
          }
        } else {
          this.toastr.error(this.mrTranslate.transform("Beim Speichern ist ein Fehler aufgetreten..!"));
        }
      });
  }

  protected async close() {
    let saveFilesChange = false;
    if (this.abnahmeId && (this.deletedFiles.length || this.preview.unsaved?.length)) {
      saveFilesChange = await new Promise<boolean>((resolve, _) => {
        this.modalFileChanges.open();
        this.modalSub?.unsubscribe();
        this.modalSub = this.modalAntwort.subscribe((antwort) => {
          if (antwort) {
            this.modalFileChanges.close();
            this.modalAntwort.next("");
            resolve(antwort == "save" ? true : false);
          }
        });
      })
    }
    if (this.isChanged || saveFilesChange || (this.mode == "new" && this.mangelZahl())) {
      if (this.abnahmeProtokollFormGroup.valid) await this.saveAbnahmeProtokoll();
      else {
        this.clrForm.markAsTouched();
        let message = this.mrTranslate.transform("Füllen Sie bitte erforderliche Felder");
        message += (this.mode == "new") ?
          (" " + this.mrTranslate.transform("oder löschen Sie die Mängel."))
          : ".";
        this.toastr.warning(message);
        return;
      }
    }
    setTimeout(() => {
      this.closedialog.next(true);
    });
  }

  protected addNeuMangel() {
    const objekt = this.getSelectedObjekt();
    this.overlayService.setOverlay({
      overlay: "mangelerfassung",
      isTopOverlay: true,
      id: {
        ostammid: objekt["ostammid"],
        otypid: objekt["otypid"],
        typ_Line_Point: objekt["typ_id"],
        startmeter: objekt["StartMeter"],
        endmeter: objekt["EndMeter"],
        mangel: this.neueMaengelTables[objekt.ostammid],
      },
      recallData: { ...this.getRecallData(),
        mangelCount: this.neueMaengelTables[objekt.ostammid]?.rows?.length ?? 0,
      },
      zIndex: 8,
    });

  }

  protected deleteMangel(row: any) {
    console.log(row);
    this.apiService.deleteMangel(row.OMAZSID)
      .pipe(first())
      .subscribe(({ success, error }: any) => {
        if (success) {
          this.neueMaengelTables[row.ostammid].rows.splice(this.neueMaengelTables[row.ostammid].rows.indexOf(row), 1);
          this.isChanged = (this.mode == "edit");
        } else {
          this.toastr.error(
            this.mrTranslate.transform(error),
            this.mrTranslate.transform("Mangel könnte nicht gelöscht werden")
          );
        }
      });
  }

  protected deleteDoc(id: number) {
    this.deletedFiles.push(id);
  }
  protected openPdf(file: File) {
    this.overlayService.setOverlay({
      overlay: "viewAuftrag",
      isTopOverlay: true,
      data: {
        data: file,
        titel: file?.name || this.mrTranslate.transform('Datei'),
        file: file?.name || this.abnahmeId + ".pdf",
      },
      recallData: this.getRecallData(),
      zIndex: 5,
    });
  }


  protected sign(mode: string) {
    this.openSignPad = true;
    setTimeout(() => {
      const sub: Subscription = this.signPad.signImage.subscribe((imgUrl) => {
        if (imgUrl) {
          if (mode === "pruefer") {
            this.unterschriftPruefer = imgUrl;
            this.isUnterschriebenPruefer = true;
          }
          if (mode === "kunde") {
            this.unterschriftKunde = imgUrl;
            this.isUnterschriebenKunde = true;
          }
          this.isChanged = true;
        }
        sub?.unsubscribe();
        this.openSignPad = false;
      });
    });
  }

  private mangelZahl(): number {
    return Object.values(this.neueMaengelTables)
      .reduce((count, table: any) => count + (table?.rows?.length ?? 0)
        , 0) as number;
  }
  protected protokollHighlight (fileName: string = "") {
    return fileName.includes('$Abnahme-Protokoll') && fileName.endsWith('.pdf');
  }
  protected getLargeFile = async (id: number) => new Promise<string>(
    (resolve, reject) => {
      this.apiService.getAbnahmeLargeFile(id)
        .pipe(first())
        .subscribe(({success, base64, error}: any) => {
          if (success && base64) resolve(<string>base64);
          else reject(this.mrTranslate.transform("Datei konnte nicht geladen werden!") + "\n" + error);
        })
    }
  );
  private getRecallData(){
    return {
      formValue: this.abnahmeProtokollFormGroup.getRawValue(),
      selectedObjekt: this.selectedObjekt,
      previewThumbnails: this.preview.rawPreviewThumbnails,
      neueMaengelTables: this.neueMaengelTables,
      isChanged: this.isChanged,
      abnahmeId: this.abnahmeId,
      signatures: {
        pruefer: this.unterschriftPruefer,
        kunde: this.unterschriftKunde,
      }
    };
  }

  protected get dateControl(): string {
    return this.abnahmeProtokollFormGroup.get("datum").value;
  }
  protected set dateControl(value: string) {
    this.abnahmeProtokollFormGroup.get("datum").setValue(value);
  }
}


/* export function checkUnterschrift(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value !== true) {
      return { 'isNotUnterschrieben': true };
    }
    return null;
  }
} */
