import { Component, OnInit, ElementRef, ViewChild, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { APIService } from '../../../../services/APIService/api.service';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import * as Plotly from 'plotly.js-basic-dist-min';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Options } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';


var holdconfig = {
  isDown: 0,
  delay: 50,
  nextTime: 0
};

@Component({
    selector: 'app-profileviewer',
    templateUrl: './profileviewer.component.html',
    styleUrls: ['./profileviewer.component.scss'],
    imports: [CommonModule, ClarityModule, MrTranslatePipe, NgxSliderModule, MrTranslatePipe, FormsModule, ReactiveFormsModule],
    standalone: true
})

export class ProfileViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  public sizew: number = 700;
  public sizeh: number = 600;
  private stammid: number
  isLoading: boolean = false;
  public profiles: any = {};
  private sollProfiles: any = {};
  public index: number = 0;

  private startpos: number = 0;
  @ViewChild('myCanvasL', { static: true }) canvasRefL: ElementRef;
  @ViewChild('myCanvasR', { static: true }) canvasRefR: ElementRef;

  public GLOB_SCALE: number = 1;
  private ctxL: CanvasRenderingContext2D;
  private ctxR: CanvasRenderingContext2D;

  private moveXL: number = 0;
  private moveYL: number = 0;
  private moveXR: number = 0;
  private moveYR: number = 0;
  public ds = {l1:0, l2:0, lh:0, r1:0, r2:0, rh:0}
  public xy = {x:0.0, y:0.0, x2:0.0, y2:0.0} ;
  public moveStepL: number = 1;
  public moveStepR: number = 1;
  public set ind(newindex: number) {
    if (newindex != -1) {
      this.index = newindex;
      this.changeIndex(newindex);
    }
  };
  public get ind() {
    return this.index;
  }
  public indexScrub: BehaviorSubject<number> = new BehaviorSubject(0);
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  public slideroptions: Options = {
    floor: 0,
    ceil: 100
  };

  public ;

  graphL = {
    show: true,
    id:"plotly_Left",
    layout:  {
      autosize: false,
      showlegend: false,
      //legend: { orientation: "h" },
      //height: "25vh",
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        //autorange: true,
        range: [-40, 100],//[Math.min(...measurements) - 10, Math.max(...measurements) + 10],
        //type: "linear",
      },
      xaxis: {
        //autorange: true,
        range: [-90, 50],
      },
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d"],
    },
    data: [],
    style: {
      width: "47vw",
      height: "47vh",
    },
  };

  graphR = {
    id: "plotly_Right",
    layout: {
      autosize: false,
      showlegend: false,
      //legend: { orientation: "h" },
      //height: "25vh",
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        //autorange: true,
        range: [-40, 100],//[Math.min(...measurements) - 10, Math.max(...measurements) + 10],
        //type: "linear",
      },
      xaxis: {
        //autorange: true,
        range: [-50, 90],
      },
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d"],
    },
    data: [],
    style: {
      width: "47vw",
      height: "47vh",
    },
  };



  private subscribtions: any = {};
  set data(dataIn: any) {
    this.stammid = dataIn.id;
    this.startpos = dataIn.start;

    const STEP = 1000;
    this.isLoading = true;
    this.apiservice.getAnlageProfilCount(this.stammid).pipe().subscribe((valLen: any) => {
      this.apiservice.getAnlageProfil(this.stammid, 0).pipe().subscribe((res) => { // 1.Daten immer zuerst
        const obj = JSON.parse(JSON.stringify(res));
        if (obj.length == 0) {
          this.toastr.warning(this.mrTranslate.transform("Keine Daten vorhanden!"));
          this.close();
          return;
        }

        this.setData(obj, valLen);
        Plotly.newPlot('plotly_Left', this.graphL.data, this.graphL.layout,this.graphL.config as any);
        Plotly.newPlot('plotly_Right', this.graphL.data, this.graphL.layout,this.graphL.config as any);
        this.getPlotID('plotly_Left','l');
        this.getPlotID('plotly_Right','r');

        this.render(0);

        for (let index = STEP; index < valLen; index += STEP) {
          this.apiservice.getAnlageProfil(this.stammid, index).pipe().subscribe((res) => {
            this.setData(res, valLen);
          })
        }
        this.isLoading = false;
      })
    })
  };

  setData(obj, valLen) {
    this.slideroptions = {
      floor: 0,
      translate: this.getPosition,
      ceil: valLen - 1,
      animate: false
    };

    var result = obj.sollProfile;
    for (var key in result) {
      let sl = result[key]["SollL"].split("|");
      let sr = result[key]["SollR"].split("|");
      let solll = {x:[], y:[]};
      let sollr = {x:[], y:[]};
      sl = sl.map((el) => el.split(";"));
        sl.forEach(element => {
          solll.x.push(parseFloat(element[0]));
          solll.y.push(parseFloat(element[1]));
        });
      sr = sr
        .map((el) => el.split(";"));
        sr.forEach(element => {
          sollr.x.push(parseFloat(element[0]));
          sollr.y.push(parseFloat(element[1]));
        });
      result[key]["SollL"] = solll;
      result[key]["SollR"] = sollr;
    }
    this.sollProfiles = result;

    for (let index = 0; index < obj.profiles.length; index++) {
      result = obj.profiles[index];
      let sl = result["IstL"].split("|");
      let sr = result["IstR"].split("|");
      let istl = {x:[], y:[]};
      let istr = {x:[], y:[]};
      sl = sl.map((el) => el.split(";"));
      sl.forEach(element => {
          istl.x.push(parseFloat(element[0]));
          istl.y.push(parseFloat(element[1]));
        });
      sr = sr.map((el) => el.split(";"));
        sr.forEach(element => {
          istr.x.push(parseFloat(element[0]));
          istr.y.push(parseFloat(element[1]));
        });
      result["IstL"] = istl;
      result["IstR"] = istr;
      this.profiles[result.zIndex] = result;
    }
  }

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe
  ) {}

  ngOnDestroy(): void {
    this.subscribtions?.profiles?.unsubscribe();
    this.profiles = null;
    this.sollProfiles = null;
  }

  close() {
    this.closedialog.next(true);
    this.indexScrub.next(-1);
  }

  getPlotID(event:any, side:any) {
    var plotID = event;
    var myPlot :any = document.getElementById(plotID);
    side == 'l' ? this.graphL.id = plotID : this.graphR.id = plotID;
    myPlot.on('plotly_click',  data => {
      var graph:any;
      side == 'l' ? graph = this.graphL : graph = this.graphR;

      graph.data = graph.data.filter(item => item.name != "Distanzmessung");
      if(data.points.length == 1) {


        if (!this.xy.x || (this.xy.x && this.xy.x2)) {
          this.xy.x = data.points[0].x;
          this.xy.y = data.points[0].y;
          this.xy.x2 = undefined;
          this.xy.y2 = undefined;
          var dataPoint = { x: [this.xy.x], y: [this.xy.y], name: "Distanzmessung", type: "lines+marker" }
          var txt = "x=0.00 mm y=0.00 mm dist=0.00 mm"
          //Plotly.addTraces(graph.id,{ x: [this.xy.x], y: [this.xy.y], name: "Distanzmessung"})
        }
        else {
          this.xy.x2 = data.points[0].x;
          this.xy.y2 = data.points[0].y;
          var dist = this.getDistanceNum(this.xy.x, this.xy.x2, this.xy.y, this.xy.y2);
          var txt = "x=" + (this.xy.x - this.xy.x2).toFixed(2) + " mm y=" + (this.xy.y - this.xy.y2).toFixed(2) + " mm dist=" + dist + " mm"
          var dataPoint = { x: [this.xy.x, this.xy.x2], y: [this.xy.y, this.xy.y2], name: "Distanzmessung", type: "lines+marker" }
          //Plotly.addTraces(graph.id,{ x: [this.xy.x, this.xy.x2], y: [this.xy.y, this.xy.y2], name: "Distanzmessung"})
        }

        graph.layout.annotations  = [{
          text: txt,
          x: NaN, //Garantiert dass Annotation immer oben links zu sehen ist.
          y: NaN,
          showarrow:false,
        }];

        graph.data.push(dataPoint);
        //Plotly.redraw(graph.id);
        Plotly.react(this.graphL.id,this.graphL.data,this.graphL.layout);
        Plotly.react(this.graphR.id,this.graphR.data,this.graphR.layout);
      }

    });
  }

  doMove(val, side,dir) {
    var graph:any;
    var soll =
    side == 'L' ? graph = this.graphL : graph = this.graphR;
    if(dir == 'X') {
      graph.data[1].x = graph.data[1].x.map(xVal => xVal + val);
    } else {
      graph.data[1].y = graph.data[1].y.map(
        xVal => xVal - val);
    }
    var preSoll = side == 'L' ?
      this.sollProfiles[this.profiles[this.ind]['ProfilL']]:
      this.sollProfiles[this.profiles[this.ind]['ProfilR']];
    var sollArr = [];
    for (let i = 0; i < preSoll.SollL.x.length; i++) {
      sollArr.push([preSoll.SollL.x[i],preSoll.SollL.y[i]]);
    }
    preSoll.soll = sollArr;

    var preProf = side == 'L' ?
    this.profiles[this.ind]['IstL']:
    this.profiles[this.ind]['IstR'];
    var profArr = [];
    for (let i = 0; i < preProf.x.length; i++) {
      profArr.push([preProf.x[i],preProf.y[i]]);
    }
    var req = {
      soll : preSoll,
      vardef: {},
      profile : profArr,
      richtung: side == 'L' ? 0 : 1
    }
    this.apiservice.setAnlageProfilRecalc(req).pipe().subscribe((val:any)=>{
      if (side == 'L') {
      this.ds.l1 = val.S1_Verschleiss_li.toFixed(2);
      this.ds.l2 = val.S2_Verschleiss_li.toFixed(2);
      this.ds.lh = val.H_Verschleiss_li.toFixed(2);
      }else {
        this.ds.r1= val.S1_Verschleiss_re.toFixed(2);
        this.ds.r2 = val.S2_Verschleiss_re.toFixed(2);
        this.ds.rh = val.H_Verschleiss_re.toFixed(2);
      }

    });

    Plotly.redraw(graph.id);
  }

  setMoveStep(i: any, mode: string) {
    var val = +i.currentTarget.value;
    if(mode == 'L')
      this.moveStepL = val
    else
      this.moveStepR = val;
  }

  changeIndex(index) {
    if (this.profiles[index]) {

      this.ds.l1 = this.profiles[index].dS1L;
      this.ds.l2 = this.profiles[index].dS2L;
      this.ds.lh = this.profiles[index].dhL;
      this.ds.r1 = this.profiles[index].dS1R;
      this.ds.r2 = this.profiles[index].dS2R;
      this.ds.rh = this.profiles[index].dhR;

      this.indexScrub.next(parseFloat(this.profiles[index]['Position']));
      this.moveXL = this.moveXR = this.moveYL = this.moveYR = 0;
      this.render(index);
    } else {
      this.graphL.data = [];
      this.graphR.data = [];
      var loadtrace = {
        x: [-20, -20],
        y: [40, 40],
        mode: 'markers+text',
        name: 'Loading',
        text: ['Daten werden geladen...'],
        textposition: 'bottom',

        type: 'scatter'
      };
      this.graphL.data = [loadtrace];
      this.graphR.data = [loadtrace];
      if(this.graphL.id) {
        Plotly.react(this.graphL.id,this.graphL.data,this.graphL.layout);
        Plotly.react(this.graphR.id,this.graphR.data,this.graphR.layout);
      }
    }
  }

  render(index:any) {
    var lstSollL = this.sollProfiles[this.profiles[this.ind]['ProfilL']]['SollL']
    var lstSollR = this.sollProfiles[this.profiles[this.ind]['ProfilR']]['SollR'];
    //this.graphL.show = false;
    this.graphL.data = [lstSollL,this.profiles[index].IstL];
    this.graphR.data = [lstSollR,this.profiles[index].IstR];
    if(this.graphL.id) {
      Plotly.react(this.graphL.id,this.graphL.data,this.graphL.layout);
      Plotly.react(this.graphR.id,this.graphR.data,this.graphR.layout);
    }
    // setTimeout(() => {
    //  this.graphL.show=true;
    // });
  }

  download(mode) {
    let datauri;
    if (mode == 'L') {
      datauri = this.canvasRefL?.nativeElement.toDataURL("image/png");
    } else {
      datauri = this.canvasRefR?.nativeElement.toDataURL("image/png");
    }
    fetch(datauri).then(res => res.blob()).then(blob => window.URL.createObjectURL(blob)).then(url => {
      var a = document.createElement("a");
      a.href = url;
      a.download = this.stammid + '_' + this.profiles[this.index]['zIndex'] + '_' + mode + '.png';
      a.click();
      a.remove();
    });
  }

  indexMup() {
    holdconfig.isDown = 0;
  }

  getPosition = (val) => {
    if (val !== undefined && !Number.isNaN(val)) {
      if (this.profiles[val]) {
        return this.profiles[val]['Position'].toFixed(2);
      }
      return val;
    }
    return '0';
  }



  saveProfile(mode: string) {
    var i = 0;
    var prof;
    let profLine ="";
    if(mode == 'L')
      prof = this.graphL.data[1];
    else
      prof = this.graphR.data[1];

      for (let i = 0; i < prof.x.length; i++) {
        const val = prof.x[i];
        profLine = profLine + val + ";" + prof.y[i] + "|";
      }
      profLine = profLine.slice(0,-1);
    var body = {
      profiles: profLine,
      mode: mode,
      stammid: this.stammid,
      zIndex: this.ind,
      dS: JSON.stringify(this.ds)
    }
    this.apiservice.setAnlageProfil(body).pipe().subscribe((val:any)=>{
      this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
    });
    // if (mode == 'L') {
    //   this.profiles[this.ind].IstL = prof;
    //   this.profiles[this.ind].dS1L = this.ds.l1;
    //   this.profiles[this.ind].dS2L = this.ds.l2;
    //   this.profiles[this.ind].dhL = this.ds.lh;
    // } else {
    //     this.profiles[this.ind].IstR = prof;
    //     this.profiles[this.ind].dS1R = this.ds.r1;
    //     this.profiles[this.ind].dS2R = this.ds.r2;
    //     this.profiles[this.ind].dhR = this.ds.rh;
    // }
    this.moveXL = this.moveXR = this.moveYL = this.moveYR = 0;
  }

  public getDistance(p1: Point, p2:Point) {
    var a = p1.x - p2.x;
    var b = p1.y - p2.y;

    return Math.sqrt( a*a + b*b );
  }
  public getDistanceNum(p1x: number,p2x: number, p1y:number, p2y:number) {
    var a = p1x - p2x;
    var b = p1y - p2y;

    return Math.sqrt( a*a + b*b ).toFixed(2);
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.ctxL = this.canvasRefL?.nativeElement.getContext('2d');
    this.ctxL?.scale(this.GLOB_SCALE, this.GLOB_SCALE);

    this.ctxR = this.canvasRefR?.nativeElement.getContext('2d');
    this.ctxR?.scale(this.GLOB_SCALE, this.GLOB_SCALE);

    this.moveStepL = 1;
    this.moveStepR = 1;
  }

}

export class Point {
  x: number;
  y: number;
  constructor() {
    this.x = null;
    this.y = null;
  }
}