import { Component, OnInit, ViewChild } from '@angular/core';
import { first, firstValueFrom, map, Observable, of, startWith, Subject, switchMap, tap } from 'rxjs';
import { APIService } from 'src/app/services/APIService/api.service';
import * as dayjs from 'dayjs';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { DAYS_OF_WEEK } from 'angular-calendar';
import { OverlayService } from 'src/app/services/Overlay/overlay.service';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { ClarityModule, ClrForm, ClrIconModule, ClrModal } from '@clr/angular';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PlotlyPlotComponent } from "../_components/plotly-plot/plotly-plot.component";
import { CommonModule } from '@angular/common';
import { ColumnFilterAction } from '../_components/filter/column-filter/column-filter.component';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    PlotlyPlotComponent, CommonModule, ClarityModule, MrTranslatePipe, ClrIconModule, FormsModule, ReactiveFormsModule
  ],
  providers: [BackendLocaleDatePipe],
})
export class DashboardComponent implements OnInit {

  constructor(
    private apiService: APIService,
    private router: Router,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private sanitizer: DomSanitizer,
  ) { }


  graph: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: true,
      margin: {
        l: 10,
        r: 10,
        b: 10,
        t: 10,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };


  graphkav0: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: { range: [0, 1] }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };


  graphkav1: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: { range: [0, 1] }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphkav2: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      //yaxis: { range: [0, 1] }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphkav3: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      //yaxis: { range: [0, 1] }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphkostenweek: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        rangemode: 'nonnegative',
        autorange: true
      }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphkostenmonth: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        rangemode: 'nonnegative',
        autorange: true
      }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphkostenyear: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        rangemode: 'nonnegative',
        autorange: true
      }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphusernweek: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        rangemode: 'nonnegative',
        autorange: true
      }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphusermonth: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        rangemode: 'nonnegative',
        autorange: true
      }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  graphuseryear: any = {
    layout: {
      autosize: true,
      hovermode: "closest",
      showlegend: false,
      margin: {
        l: 60,
        r: 20,
        b: 20,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        rangemode: 'nonnegative',
        autorange: true
      }
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: [
        "sendDataToCloud",
        "lasso2d",
        "zoomOut2d",
        "zoomIn2d",
        "autoScale2d",
        "toggleSpikelines",
        "hoverCompareCartesian",
        "hoverClosestCartesian",
      ],
    },
    data: [],
  };

  optGroup: UntypedFormGroup = new UntypedFormGroup({
    show_BG: new UntypedFormControl(true),
    show_Sperr: new UntypedFormControl(true),
    show_Stoer: new UntypedFormControl(true),
    show_LF: new UntypedFormControl(true),
    show_Sonder: new UntypedFormControl(true),
    show_Anz_Insp: new UntypedFormControl(true),
    show_Anz_Wart: new UntypedFormControl(true),
    show_Anz_Auf: new UntypedFormControl(true),
    show_Anz_Begeh: new UntypedFormControl(true),
    show_Anz_EBL: new UntypedFormControl(true),
    show_Anz_Sonder: new UntypedFormControl(true),
    show_Anz_ALV: new UntypedFormControl(true),
    show_Dia_FK: new UntypedFormControl(true),
    show_Dia_KAV: new UntypedFormControl(true),
    show_Dia_Kost: new UntypedFormControl(true),
    show_Dia_Prog: new UntypedFormControl(true),
  });

  fkstatistic: any = [];
  kavstatistic: any = [];
  kavstatistic1: any = [];
  kav_erneu_weiche: any = [];
  kav_erneu_gleis: any = [];
  kavTitel: string = "KAV Entwicklung";
  anzBG: any;
  anzSperr: any;
  anzStoer: any;
  anzLF: any;
  anzSonder: any;
  anzInsp: any;
  InspStammIDs: any;
  anzWart: any;
  WartStammIDs: any;
  anzAuf: any;
  anzBegeh: any;
  kavansicht: number = 0;
  gesKAV: any;
  kostenweek: any = [];
  kostenmonth: any = [];
  kostenyear: any = [];
  kostenansicht: number = 0;
  kostenTitel: string = "Kosten akutelle Woche";
  userweek: any = [];
  usermonth: any = [];
  useryear: any = [];
  useransicht: number = 0;
  userTitel: string = "Programmnutzung akutelle Woche";
  opened: boolean = false;


  show_BG: boolean = true;
  show_Sperr: boolean = true;
  show_Stoer: boolean = true;
  show_LF: boolean = true;
  show_Sonder: boolean = true;
  show_Anz_Insp: boolean = true;
  show_Anz_Wart: boolean = true;
  show_Anz_Auf: boolean = true;
  show_Anz_Begeh: boolean = true;
  show_Anz_Sonder: boolean = true;
  show_Anz_ALV: boolean = true;
  show_Anz_EBL: boolean = true;

  show_Dia_FK: boolean = true;
  show_Dia_KAV: boolean = true;
  show_Dia_Kost: boolean = true;
  show_Dia_Prog: boolean = true;

  optChecked_EBL: number = 0;
  anzALV: number = 0;
  anzEBL: number = 0;
  ALVStammIDs: any = [];
  EBLStammIDs: any = [];
  anzSonderTermin: number = 0;
  SondertOMAZSID: any = [];

  changeKAVStatus(){
    if (this.kavansicht == 0){
      this.kavansicht = 1;
      this.kavTitel = "KAV Bezirk - KAV Gesamt: " + this.gesKAV;
      return;
    }
    if (this.kavansicht == 1){
      this.kavansicht = 2;
      this.kavTitel = "Erneuerungsbedarf Weichen:";
      return;
    }
    if (this.kavansicht == 2){
      this.kavansicht = 3;
      this.kavTitel = "Erneuerungsbedarf Gleis:";
      return;
    }
    if (this.kavansicht == 3){
      this.kavansicht = 0;
      this.kavTitel = "KAV Entwicklung";
      return;
    }
  }

  changeKostenStatus() {
    if (this.kostenansicht == 0) {
      this.kostenansicht = 1;
      this.kostenTitel = "Kosten akutellen Monat";
      return;
    }
    if (this.kostenansicht == 1) {
      this.kostenansicht = 2;
      this.kostenTitel = "Kosten akutelles Jahr";
      return;
    }
    if (this.kostenansicht == 2) {
      this.kostenansicht = 0;
      this.kostenTitel = "Kosten akutelle Woche";
      return;
    }
  }

  changeUserStatus() {
    if (this.useransicht == 0) {
      this.useransicht = 1;
      this.userTitel = "Programmnutzung akutellen Monat";
      return;
    }
    if (this.useransicht == 1) {
      this.useransicht = 2;
      this.userTitel = "Programmnutzung akutelles Jahr";
      return;
    }
    if (this.useransicht == 2) {
      this.useransicht = 0;
      this.userTitel = "Programmnutzung akutelle Woche";
      return;
    }
  }

  saveOpt(){
    let val = this.optGroup.value;

    this.show_BG = val.show_BG;
    this.show_Sperr = val.show_Sperr;
    this.show_Stoer = val.show_Stoer;
    this.show_LF = val.show_LF;
    this.show_Sonder = val.show_Sonder;
    this.show_Anz_Insp = val.show_Anz_Insp;
    this.show_Anz_Wart = val.show_Anz_Wart;
    this.show_Anz_Auf = val.show_Anz_Auf;
    this.show_Anz_Begeh = val.show_Anz_Begeh;
    this.show_Anz_Sonder = val.show_Anz_Sonder;
    this.show_Dia_FK = val.show_Dia_FK;
    this.show_Dia_KAV = val.show_Dia_KAV;
    this.show_Dia_Kost = val.show_Dia_Kost;
    this.show_Dia_Prog = val.show_Dia_Prog;
    this.show_Anz_ALV = val.show_Anz_ALV;
    this.show_Anz_EBL = val.show_Anz_EBL;

    let send ={
      typ:'dashboardsettings',
      value: val
    };


    this.apiService.getStatistik(send).pipe(first()).subscribe((res: any) => {
      if (res.success){
        this.toastr.success(
          this.mrTranslate.transform('Erfolgreich gespeichert'),
          this.mrTranslate.transform('Einstellungen')
        );
      }
      else{
        this.toastr.error(
          this.mrTranslate.transform('"Ein Fehler ist aufgetreten"'),
          this.mrTranslate.transform('Einstellungen')
        );
      }

      setTimeout(() => {
        this.opened = false;
      }, 500);
    });

  }

  ngOnInit() {

    this.kavansicht = 0;

    this.apiService.getStatistik({ typ: 'dashboard' }).pipe(first()).subscribe((res: any) => {
      this.fkstatistic = res.fk || undefined;
      this.fkstatistic[0].hole = 0.4;

      this.anzBG = res.anzBG || 0;
      this.anzSperr = res.anzSperr || 0;
      this.anzStoer = res.anzStoer || 0;
      this.anzLF = res.anzLF || 0;
      this.anzSonder = res.anzSonder || 0;
      this.kavstatistic = res.kav || undefined;
      this.anzInsp = res.anzInsp || 0;

      this.InspStammIDs = res.InspStammIDs || undefined;
      this.anzWart = res.anzWart || 0;
      this.WartStammIDs = res.WartStammIDs || undefined;
      this.anzAuf = res.anzAuf || 0;
      this.anzBegeh = res.anzBegeh || 0;
      this.kavstatistic1 = res.kav1 || undefined;
      this.gesKAV = res.gesKAV || undefined;

      this.kostenweek = res.kostenweek || undefined;
      this.kostenmonth = res.kostenmonth || undefined;
      this.kostenyear = res.kostenyear || undefined;

      this.userweek = res.userweek || undefined;
      this.usermonth = res.usermonth || undefined;
      this.useryear = res.useryear || undefined;

      this.kav_erneu_weiche = res.kav_erneu_weiche || undefined;

      if(this.kav_erneu_weiche != undefined){
        this.kav_erneu_weiche[1].line = {shape: 'hvh', color: 'rgb(31, 119, 180)',};
        this.kav_erneu_weiche[1].name = 'Stk.',
        this.kav_erneu_weiche[0].mode= 'lines';
        this.kav_erneu_weiche[0].line= {color: 'grey', dash: 'dot',};

      }

      this.kav_erneu_gleis = res.kav_erneu_gleis || undefined;

      if(this.kav_erneu_gleis != undefined){
        this.kav_erneu_gleis[1].line = {shape: 'hvh', color: 'rgb(31, 119, 180)',};
        this.kav_erneu_gleis[1].name = 'm',
        this.kav_erneu_gleis[0].mode= 'lines';
        this.kav_erneu_gleis[0].line= {color: 'grey', dash: 'dot',};
      }


      this.optChecked_EBL = res.optChecked_EBL || 0;
      this.anzALV = res.anzALV || 0;
      this.anzEBL = res.anzEBL || 0;
      this.ALVStammIDs = res.ALVStammIDs || undefined;
      this.EBLStammIDs = res.EBLStammIDs || undefined;


      this.anzSonderTermin = res.anzSonderTermin || 0;
      this.SondertOMAZSID = res.SondertOMAZSID || undefined;





    });



  }

  routing(url: string, typ: string): void {
    const normalizedTyp = typ.toLowerCase();
    const upperColumnId = typ.toUpperCase();

    const valueMap: Record<string, any[]> = {
      st: ['1'],
      betriebsgefahr: ["true"],
      sp: ['1'],
      lf: ['0'],
      si: ['1'],
      term: this.InspStammIDs
    };

    const filterValue = valueMap[normalizedTyp];

    if (filterValue === undefined) {
      console.warn(`No predefined filter for type: ${typ}`);
      this.router.navigateByUrl(url);
      return;
    }

    const preFilters = {
      [typ]: {
        columnId: upperColumnId,
        items: filterValue.map((value, idx) => ({
          id: idx,
          name: value,
          isVisible: true
        }))
      },
    };
//debugger
    sessionStorage.setItem('filter', JSON.stringify(preFilters));
    this.router.navigateByUrl(url);
  }
}


