import { CommonModule } from "@angular/common";
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, } from "@angular/core";
import { FormsModule, NgModel, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { MangelMediaApiFuncService } from "src/app/services/_abstract/mangel-media-api-func.service";
import { LinieValidationManager } from "src/app/shared/classes/linie-validation-manager";
import { APIService } from "../../../../services/APIService/api.service";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";

@Component({
  selector: "app-stoerungdialog",
  templateUrl: "./stoerungdialog.component.html",
  styleUrls: ["./stoerungdialog.component.scss"],
  imports: [CommonModule, ClarityModule, PreviewThumbnailsComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe,
    HideIDColumnsPipe, TablePrettyPrintPipe],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})
export class StoerungDialogComponent extends LinieValidationManager implements OnInit, OnDestroy {
  @ViewChild('dateModel', { read: NgModel, static: false })
    private dateModel: NgModel
  ;
  ostammid: number;
  typ_Line_Point: number;

  stoerart: string[];
  gemeldetDurch: string[];
  ursache: string[];
  was: string[];
  wer: string[];
  einheit: string[];
  pruefart: any;
  mangel: any;
  @ViewChild("pruefartList", { static: true }) pruefartList: ElementRef;

  editMassId: number = undefined;
  massnahmen: any = [];
  newMassnahme: any = {
    type: "insert",
    was: "",
    wer: "",
    bisWann: "",
    zeit: "",
  };

  editId: number = undefined;
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild(PreviewThumbnailsComponent, {static: false}) preview: PreviewThumbnailsComponent;
  deletedFiles: string[] = [];

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();

  stoerungGroup = new UntypedFormGroup({
    date: new UntypedFormControl(""),
    time: new UntypedFormControl(""),
    gemeldetDurch: new UntypedFormControl(""),
    artDerStoerung: new UntypedFormControl(""),
    schaeden: new UntypedFormControl(false),
    startmeter: new UntypedFormControl(""),
    endmeter: new UntypedFormControl(""),
    pruefart: new UntypedFormControl(""),
    mangel: new UntypedFormControl(""),
    umfang: new UntypedFormControl(""),
    einheit: new UntypedFormControl(""),
    betriebsgefahr: new UntypedFormControl(false),
    ursachebekannt: new UntypedFormControl(false),
    ursache: new UntypedFormControl(""),
  });

  maxlen: number = undefined;
  startm: number = undefined;
  endm: number = undefined;
  // imgURL;
  history: any;
  operation: string = "new";
  sub = new Subscription();
  set data(dataIn: any) {
    this.ostammid = dataIn.id;
    this.typ_Line_Point = dataIn.typ_Line_Point;
    if(dataIn.dataRefresh) {
      this.dataRefresh = dataIn.dataRefresh;
    }
    if (dataIn.startmeter || dataIn.endmeter) {
      this.startm = dataIn.startmeter;
      this.endm = dataIn.endmeter;
      const sub = this.stoerungGroup.get('schaeden').valueChanges.subscribe(
        value => {
          if (value != this.stoerungGroup.value.schaeden) {
            if (value) this.initLinieValidators(this.startm, this.endm);
            else this.clearLinieValidators();
          }
        }
      );
      this.sub.add(sub);
    }
    this.apiservice
      .getStoerung(this.ostammid)
      .pipe(first())
      .subscribe((val: any) => {
        if (val) {
          this.stoerart = val.stoerart;
          this.gemeldetDurch = val.gemeldetDurch;
          this.ursache = val.ursache;
          this.was = val.was;
          this.wer = val.wer;
          this.einheit = val.einheit;
          this.pruefart = val.pruefart;
          this.history = val.history;
          /* if (val.maxlen) {
            this.maxlen = val.maxlen;
            this.stoerungGroup.controls.endmeter.setValidators([
              Validators.min(0),
              Validators.max(val.maxlen),
            ]);
            this.stoerungGroup.controls.startmeter.setValidators([
              Validators.min(0),
              Validators.max(val.maxlen - 1),
            ]);
          } else {
            this.maxlen = undefined;
            this.stoerungGroup.controls.endmeter.setValidators([]);
            this.stoerungGroup.controls.startmeter.setValidators([]);
          } */
        }
      });
  }

  get endmeter() {
    return this.stoerungGroup.get("endmeter");
  }

  get startmeter() {
    return this.stoerungGroup.get("startmeter");
  }

  trial = false;

  constructor(
    //@Inject(MAT_DIALOG_DATA) public data,
    private apiservice: APIService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private mrTranslate: MrTranslatePipe,
    private authService: AuthService,
    private objtypePipe: ObjTypePipe,
    protected localeDate: BackendLocaleDatePipe,
    protected media: MangelMediaApiFuncService,
  ) {
    super({ start: 'startmeter', end: 'endmeter' });
    this.form = this.stoerungGroup;
    this.trial = this.authService.getToken().trial;
  }

  saveMassnahme(mode) {
    if (
      this.newMassnahme.was &&
      this.newMassnahme.wer &&
      this.newMassnahme.bisWann &&
      this.newMassnahme.zeit
    ) {
      if (mode == "new") {
        this.massnahmen.push(this.newMassnahme);
      } else if (mode == "edit") {
        if (this.newMassnahme.id) {
          this.massnahmen = this.massnahmen.map((mass) => {
            if (mass.id == this.newMassnahme.id) {
              return this.newMassnahme;
            }
            return mass;
          });
        }
      }

      this.newMassnahme = {
        type: "insert",
        was: "",
        wer: "",
        bisWann: "",
        zeit: "",
      };
    }
  }

  deleteMassnahme(index) {
    this.massnahmen.splice(index, 1);
  }

  async onSubmit() {
    let body = this.stoerungGroup.value;
    let err = [];

    if (this.stoerungGroup.status != "VALID" || this.stoerungGroup.errors) {
      err.push(
        this.mrTranslate.transform("Formularvalidierung (rot) beachten")
      );
    }

    if (!body.artDerStoerung) {
      err.push(this.mrTranslate.transform("Art der Störung ausfüllen"));
    }
    if (body.schaeden) {
      if (body.endmeter) {
        body.startmeter = body.startmeter ?? 0;
        if (body.startmeter > body.endmeter) {
          err.push("Startmeter < Endmeter");
        }
      } else {
        if (this.objtypePipe.transform(this.typ_Line_Point) == "line") {
          err.push(
            this.mrTranslate.transform("Startmeter und Endmeter ausfüllen")
          );
        }
      }
      if (!(body.mangel && body.pruefart)) {
        err.push(
          this.mrTranslate.transform(
            "Bei Schäden: Mangel und Prüfart ausfüllen"
          )
        );
      }
      try {
        body.files = await this.preview.packUnsavedFiles();
        const savedFileNames = this.preview.saved.map((file) => file.name);
        body.savedFiles = savedFileNames.join("|");
        body.delFiles = this.deletedFiles;
      } catch (er) {
        this.toastr.error(
          this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten"),
          (<ErrorEvent>er).message
        );
      }
    }
    else {
      body.startmeter = null;
      body.endmeter = null;
      body.mangel = null;
      body.pruefart = null;
    }
    if (body.ursachebekannt) {
      if (!body.ursache) {
        err.push(
          this.mrTranslate.transform("Bei Ursache bekannt: Ursache ausfüllen")
        );
      }
    }
    if (this.massnahmen.length == 0) {
      err.push(this.mrTranslate.transform("Mindestens eine Massnahme anlegen"));
    }

    if (err.length == 0) {
      body.massnahmen = this.massnahmen;
      body.operation = this.operation;
      if (this.operation == "edit") {
        body.omazsid = this.editId;
      }

      this.apiservice
        .sendStoerung(this.ostammid, body)
        .pipe(first())
        .subscribe(({message, history}: any) => {
          if (message == "success") {
            this.toastr.success(
              this.mrTranslate.transform("Störung erfolgreich gespeichert.")
            );
            this.editId = undefined;
            this.operation = "new";
            this.stoerungGroup.reset();
            this.dateModel?.reset();
            this.preview?.clearPreview();
            this.deletedFiles = [];
            this.massnahmen = [];
            this.newMassnahme = {
              type: "insert",
              was: "",
              wer: "",
              bisWann: "",
              zeit: "",
            };

            if (history) {
              if (this.history && this.history["rows"]) {
                this.history["rows"] = history["rows"];
                this.grid.resize();
              } else {
                this.history = history;
              }

              setTimeout(() => {
                this.grid.resize();
              });

              this.dataRefresh?.emit(true);
            }
          } else {
            this.toastr.error(
              this.mrTranslate.transform("Ein Fehler ist aufgetreten.")
            );
          }
          this.spinner.disable();
        });
    } else {
      this.toastr.warning(
        "<ul>" +
          err.map((e) => `<li>${this.mrTranslate.transform(e)}</li>`).join("") +
          "</ul>",
        this.mrTranslate.transform("Bitte folgendes beachten:"),
        {
          enableHtml: true,
        }
      );
    }
  }

  public close() {
    this.closedialog.next(true);
  }

  editMassRow(mass) {
    this.newMassnahme = {...mass};
  }

  editRow(row: any) {
    this.operation = "edit";
    this.editId = row.omazsid;

    this.apiservice
      .getStoerungMassnahmen(row.omazsid)
      .pipe(first())
      .subscribe((massnahmen: any) => {
        this.massnahmen = massnahmen;
      });

    if (row.erledigt) {
      this.stoerungGroup.disable();
    }

    this.stoerungGroup.controls.date.setValue(row.datum);
    this.stoerungGroup.controls.artDerStoerung.setValue(row.stoerart);
    this.stoerungGroup.controls.time.setValue(row.zeit);
    this.stoerungGroup.controls.gemeldetDurch.setValue(row.meldender);
    this.stoerungGroup.controls.schaeden.setValue(
      (row.mangel != "") && (row.mangel!= null)
    );
    this.stoerungGroup.controls.startmeter.setValue(row.startmeter);
    this.stoerungGroup.controls.endmeter.setValue(row.endmeter);
    this.stoerungGroup.controls.pruefart.setValue(row.pruefart);
    this.stoerungGroup.controls.mangel.setValue(row.mangel);
    this.stoerungGroup.controls.umfang.setValue(row.umfang);
    this.stoerungGroup.controls.einheit.setValue(row.mengeneinheit);
    this.stoerungGroup.controls.betriebsgefahr.setValue(row.betriebsgefahr);
    this.stoerungGroup.controls.ursachebekannt.setValue(row.ursachebekannt);
    this.stoerungGroup.controls.ursache.setValue(row.ursache);

    if (this.stoerungGroup.controls.schaeden.value) setTimeout( async() => {
      try {
        this.preview.clearPreview();

        if (row.erledigt) this.preview.disabled = true;
        const [
          { fileNames: neuNames = [] } = {},
          { fileNames: rueckNames = [] } = {},
        ] = this.media.openMangelMedia(row.bild, row.rueckbilder);
        const files = await this.media.getApiFunctionMedia([...neuNames, ...rueckNames]);
        this.preview.unpackFiles(files);
      } catch (error) {
        this.toastr.error(error);
      }
    });
  }

  switchMode(newMode) {
    if (newMode == "new") {
      this.stoerungGroup.reset();
      this.dateModel?.reset();
      this.massnahmen = [];
      this.newMassnahme = {
        type: "insert",
        was: "",
        wer: "",
        bisWann: "",
        zeit: "",
      };
      this.editId = undefined;
    }
    this.operation = newMode;
    setTimeout(() => {
      this.grid.resize();
    });
  }

  emailStoerung(row) {
    this.apiservice
      .getStoerungMassnahmen(row.omazsid)
      .pipe(first())
      .subscribe((massnahmen: any) => {
        const datum = this.localeDate.transform(row.datum);
        let emailsubject = `Störung vom ${datum} ${row.zeit}`;
        let emailbody =`
          *********************************************
          *                   Störungen               *
          *********************************************\n
          Datum der Störung: ${datum} Zeit: ${row.zeit}
          gemeldet durch: ${row.meldender}
          Art der Störung: ${row.stoerart}\n
          *********************************************\n
          Schäden: ${row.mangel && row.mangel.length > 0 ? "Ja" : "Nein"}
          Prüfart: ${row.pruefart ? row.pruefart : ""}
          Mangel: ${row.mangel ? row.mangel : ""}
          Umfang: ${row.umfang ? row.umfang : ""}
          Einheit: ${row.mengeneinheit ? row.mengeneinheit : ""}
          Betriebsgefahr: ${row.betriebsgefahr ? "Ja" : "Nein"}\n
          *********************************************\n
          Ursache bekannt: ${row.ursachebekannt ? "Ja" : "Nein"}
          Ursache: ${row.ursache ? row.ursache : ""}\n
          *********************************************\n
          Zu treffende Maßnahmen:
          Was -> Wer -> bis Wann -> Zeit\n\n`;

        massnahmen?.forEach((mass) => {
          emailbody += `
          ${mass.was} -> ${mass.wer} -> ${this.localeDate.transform(mass.bisWann)} -> ${mass.zeit}\n`;
        });
        emailbody +=`
          *********************************************\n
          Zeit: ${BackendLocaleDatePipe.now}`;

        let mailtolink =
          "mailto:?subject=" +
          encodeURIComponent(emailsubject) +
          "&body=" +
          encodeURIComponent(emailbody);
        window.open(mailtolink, "_self");
      });
  }

  ngOnInit() {
    const sub = this.stoerungGroup.controls.pruefart.valueChanges.subscribe((newVal) => {
      if (newVal == "") {
        this.mangel = [];
      } else {
        for (let i = 0; i < this.pruefart.length; i++) {
          const element = this.pruefart[i];
          if (element.val == newVal) {
            this.apiservice
              .getStoerungMangel(element.id)
              .pipe(first())
              .subscribe((mangel) => {
                this.mangel = mangel;
              });
            break;
          }
        }
      }
    });
    this.sub.add(sub);
  }

  camelcase(val: string) {
    return val.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }
  deleteDoc(name: string) {
    this.deletedFiles.push(name);
  }

  protected get dateControl(): string {
    return this.stoerungGroup.get("date").value;
  }
  protected set dateControl(value: string) {
    this.stoerungGroup.get("date").setValue(value);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
