import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { APIService } from 'src/app/services/APIService/api.service';
import { first, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { ToastrService } from 'ngx-toastr';
import { ClrDatagrid, ClrForm } from '@clr/angular';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { BasicStoreService } from "../../../../services/BasicStore/basic-store.service";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { HilfeBeschreibungModalComponent } from '../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component';
import { disable } from 'ol/rotationconstraint';
import jwtDecode from 'jwt-decode';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';

export interface FormulareRow {
  ID: number;
  Typ: string;
  FeldName: string;
  AuswahlOption: string;
  Bezeichnung: string;
}

@Component({
    selector: 'app-fatobjekterstellendialog',
    templateUrl: './fatobjekterstellendialog.component.html',
    styleUrls: ['./fatobjekterstellendialog.component.scss'],
    imports: [CommonModule, ClarityModule, HilfeBeschreibungModalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, TablePrettyPrintPipe, HideIDColumnsPipe],
    standalone: true
})
export class FatobjekterstellendialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();
  set data(dataIn: any) {
    this.dataRefresh = dataIn.dataRefresh;
  }

  @ViewChild(HilfeBeschreibungModalComponent, { static: false })
  protected hilfeModal: HilfeBeschreibungModalComponent;

  objekteGroup = new UntypedFormGroup({
    objektName: new UntypedFormControl("", [Validators.required]),
    vorlageName: new UntypedFormControl("", [Validators.required]),
    //data: null
  });

  ostammid: number = -1;

  //selectedformulare: any;
  //selectedtabellen: any;
  tabellensourcetype: number = 0;

  selectionFormulareIndex: number = 0;
  structures: any;
  formulare: any;
  formulareOriginal: any;
  tabellen: any;
  tabellenOriginal: any;
  stammdaten = { columns: [] };
  spezifikationsdaten = { columns: [], mapping: {} };
  herstellerdaten = { columns: [] };
  canEdit: boolean = true;
  locked: boolean = true;
  typOptions = ['Normales Feld', 'Überschrift', 'Titel'];
  objektTypOptions = [1, 2, 3];
  auswahlOptions = ['Stammdaten', 'Spezifikationsdaten', 'Herstellerdaten'];
  firstColumnId = 'Typ';
  secondColumnId = 'FeldName';

  formReflectData: {
    table: string | null;
    fieldname: string | null;
    fieldType: string | null;
    fieldLength: string | null;
    subheading: string | null;
    value: string | null;
  }[] = [];

  vorlageNames: Array<{ ID: number, VorlageName: string }>;
  tabellenNames: Array<{ ID: number, Bezeichnung: string }>;
  tabellenNameAll: any;

  selectedVorlageName: string;
  // selectedTabelleName: string;
  selectedTabelleID: number | null = null;
  selected: any = [];
  tabellenCheckboxStates: { [vorlageName: string]: { [tabelleID: number]: boolean } } = {};
  // tabellenCheckboxStates: { [vorlageName: string]: Set<number> } = {};

  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild(ClrForm) form: ClrForm;

  @Output() modalOpen = new EventEmitter<boolean>();

  _currentPage: number = 1;
  set currentPage(newPage: number) {
    this._currentPage = newPage;
  }
  get currentPage() {
    return this._currentPage;
  }

  set _modalOpen(newVal: boolean) {
    this.modalOpen.emit(newVal);
  }

  loading: boolean = true;

  activeTab: any = {
    formulare: false,
    tabellen: false
  };

  showmodalselectVorlage: boolean = false;
  isVorlageAddVisible: boolean = false;
  skizzen: any;
  curSkizzen: any = [];
  bemerkungmessung: string = '';

  isGroupVisible: boolean = false;
  kunde: any = [];
  kundenname: any = [];
  kundenort: any = [];
  addKunde: boolean = false;
  editable: boolean = true;

  @ViewChild('gruppeForm', { static: false, read: ClrForm })
  private clrForm: ClrForm
    ;
  @ViewChild('headerForm', { static: false, read: ClrForm })
  private clrheaderForm: ClrForm
    ;

  gruppe = new FormGroup({
    gruppeid: new FormControl({ value: '', disabled: !this.editable }, Validators.required),
  });

  gruppeedit = new UntypedFormGroup({
    kname: new UntypedFormControl("", [Validators.required]),
    kort: new UntypedFormControl("", [Validators.required]),
  });

  @ViewChild('_vorlage_new') _vorlage_new: ElementRef;
  @ViewChild('_zaehlercode') _zaehlercode: ElementRef
  @ViewChild('_zaehlerbezeichnung') _zaehlerbezeichnung: ElementRef

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private cdRef: ChangeDetectorRef,
    private basicStore: BasicStoreService,
  ) {
    // this.reloadVorlageNamesAndFormData();
    this.getStructures();
  }

  protected openHelper(tabelle) {
    this.hilfeModal.item = { ...tabelle };
  }


  createGroup() {

    this.kundenname = [];
    this.kundenort = [];

    this.kunde.forEach((row: any) => {
      let c = row['Bezeichnung'];
      let arr = c.split("-");
      this.kundenname.push(arr[0]);
      this.kundenort.push(arr[1]);
    });


    this.isGroupVisible = true;
  }

  saveIfValid() {
    this.clrForm.markAsTouched();
    if (this.gruppeedit.valid) {
      let newExists = false;
      let newKunde = this.gruppeedit.value.kname.toString().replace("-", "_") + "-" + this.gruppeedit.value.kort.toString().replace("-", "_");
      this.kunde.forEach((row: any) => {
        if (newKunde == row['Bezeichnung']) {
          newExists = true;
        }
      });

      if (newExists) {
        this.toastr.warning(this.mrTranslate.transform("Gruppe existiert bereits"));
        return;
      }

      let toSend: any = {};
      toSend.id = "INSERT";
      toSend.Bezeichnung = newKunde;
      toSend.Rechte = 1;
      toSend.Typ = 1;

      this.apiservice
        .setGroups(toSend)
        .pipe(first())
        .subscribe((res: any) => {
          if (res.rows) {
            this.toastr.success(this.mrTranslate.transform("Gruppe erfolgreich gespeichert."));
            this.updateKunde(res.rows);
          } else {
            this.toastr.warning(
              this.mrTranslate.transform(`Ein Fehler ist aufgetreten.`)
            );
          }
        });
    }
  }

  getStructures() {
    this.apiservice
      .getFieldStructures()
      .pipe(first())
      .subscribe((val: any) => {
        this.structures = val.rows;
      });

    this.apiservice.getGroupsForFat().pipe(first()).subscribe(val => {
      this.updateKunde(val.rows);
    });
  }

  updateKunde(kunden: any[]) {
    this.kunde = kunden;
  }

  selectVorlageName(selectedVorlageName: string) {
    this.selectedVorlageName = selectedVorlageName;
    const vorlageID = this.getVorlageIDByName(selectedVorlageName);
    this.tabellenCheckboxStates[selectedVorlageName] = {};

    this.fetchDataForVorlage(vorlageID);

    this.cdRef.detectChanges();
  }

  private initializeCheckboxStates(savedTabelleIDs: number[]) {
    this.tabellenCheckboxStates[this.selectedVorlageName] = {};
    this.tabellenNames.forEach(tabelle => {
      this.tabellenCheckboxStates[this.selectedVorlageName][tabelle.ID] = savedTabelleIDs.includes(tabelle.ID);
    });
  }


  reloadTabellen() {
    const vorlageID = this.getVorlageIDByName(this.selectedVorlageName);
    this.apiservice.getTabellenNamesFAT(vorlageID).subscribe(
      namesAndIDs => {
        this.tabellenNames = namesAndIDs.data.rows;
        this.tabellenNameAll = namesAndIDs.data.rows;
        this.skizzen = namesAndIDs.skizzen;

        if (this.tabellenNames.length > 0) {
          this.selectedTabelleID = this.tabellenNames[0].ID;
        }

        this.apiservice.getTabelleIDForVorlage(vorlageID).pipe(first()).subscribe(
          (response) => {
            const tabelleID = response;
            this.initializeCheckboxStates(tabelleID);
            // this.apiservice.getCheckboxState(tabelleID)?.subscribe(()=>{});
          }
        );
      }
    );
    this.apiservice.getTabellenFAT().pipe(first()).subscribe(val => {
      this.tabellen = val;
      this.tabellenOriginal = JSON.parse(JSON.stringify(val['rows']));
    });
  }

  getCode(id) {
    let c = this.tabellenNameAll.find(x => x.ID == id);
    return c["Code"];
  }

  selectVorlage() {
    this.selectedVorlageName = this._vorlage_new.nativeElement.value;
    this.selectVorlageName(this.selectedVorlageName);
    this.reloadTabellen();
    this.showmodalselectVorlage = false
  }

  reloadVorlageNamesAndFormData() {
    this.apiservice.getVorlageNames().subscribe(
      namesAndIDs => {
        this.vorlageNames = namesAndIDs;
        if (namesAndIDs.length > 1) {
          this.showmodalselectVorlage = true;
        }
        else {
          this.selectedVorlageName = namesAndIDs[0]?.VorlageName || '';
          this.selectVorlageName(this.selectedVorlageName);
          this.reloadTabellen();
        }
      }

    );
    this.apiservice.getFormulare().pipe(first()).subscribe(val => {
      this.formulare = val;
      this.formulare['rows'].forEach(row => {
        if (row.Tabelle === 'Spezifikationsdaten') {
          row.Bezeichnung = this.spezifikationsdaten.mapping[row.Bezeichnung] || row.Bezeichnung;
        }
      });
      this.formulareOriginal = JSON.parse(JSON.stringify(val['rows']));
    });
  }

  onCheckboxChange(id: number, isChecked: boolean) {
    if (!this.tabellenCheckboxStates[this.selectedVorlageName]) {
      this.tabellenCheckboxStates[this.selectedVorlageName] = {};
    }
    this.tabellenCheckboxStates[this.selectedVorlageName][id] = isChecked;
  }

  getVorlageIDByName(name: string): number | null {
    const vorlage = this.vorlageNames.find((vorlage: {
      ID: number,
      VorlageName: string
    }) => vorlage.VorlageName === name);
    return vorlage ? vorlage.ID : null;
  }

  panelChange(event, tabelle) {
    if (event) {

      let curname = this.tabellenNames.find(x => x.ID == tabelle.ID);
      this.tabellensourcetype = curname["SourceType"];


      this.tabellen.rows = this.tabellenOriginal.filter(x => x.SZAEHNID == tabelle.ID);
      console.log('panel is opening');
    }
  }

  fetchDataForVorlage(vorlageID: number) {
    this.apiservice.getFormDataForVorlage(vorlageID).subscribe(
      data => {
        if (Array.isArray(data)) {
          this.formulare['rows'] = data;
          this.formReflectData = data.map((row: FormulareRow) => {
            const reflectData = {
              table: row['Tabelle'],
              fieldname: row['Bezeichnung'],
              subheading: null,
              fieldType: row['Bezeichnung'],
              fieldLength: null,
              value: null
            };
            this.updateFormReflectData(row, reflectData);
            return reflectData;
          });

          this.formReflectData.map((elem) => {
            this.objekteGroup.addControl(elem.fieldname, new UntypedFormControl(elem, Validators.maxLength(3)));
          });


        }
      }
    );
  }


  public close() {
    this.closedialog.next(true);
  }

  tabChange() {
    this.selected = [];
    this.currentPage = 1;
  }

  sendForFormulare() {

    this.clrheaderForm.markAsTouched();
    if (this.gruppe.valid) {

      let missingBez: boolean = false;
      let nameBez: string = '';
      this.formReflectData.forEach((row: any) => {
        if (row['table'] == 'Stammdaten' && row['fieldname'] == 'Bezeichnung' && row['value'] == null) {
          nameBez = row['subheading'];
          missingBez = true;
        }
      });

      if (missingBez) {
        this.toastr.warning(nameBez + ' → ' + this.mrTranslate.transform("Eintrag fehlt"));
        return;
      }

      let vorlageID = this.getVorlageIDByName(this.selectedVorlageName);
      let gruppeid = parseInt(this.gruppe.value.gruppeid);




      const tabelleIDUpdates = this.tabellenCheckboxStates[this.selectedVorlageName];

      if (vorlageID !== undefined && tabelleIDUpdates !== undefined) {
        this.apiservice.addAnlageByFAT(this.formReflectData, vorlageID, gruppeid, this.ostammid, tabelleIDUpdates).pipe(first()).subscribe(val => {
          if (val) {
            this.ostammid = parseInt(val.toString());
            this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
            this.dataRefresh.emit();
            this.editable = false;
            this.gruppe.controls['gruppeid'].disable();
          }
        });


      } else {
        console.error("Missing vorlageID or no checkboxes selected");
      }
    }

  }

  sendForTabellen() {
    let a = this.tabellen['rows'];
    let b = this.tabellenOriginal;
    var c = _.filter(a, function (obj) { return !_.findWhere(b, obj); });
    let test = true;
    c.forEach((changedTabellen: any) => {
      if (!changedTabellen || !changedTabellen.Code) {
        this.toastr.warning(this.mrTranslate.transform("Bitte bei der Zeile den Code angeben."));
        test = false;
        return;
      }
      changedTabellen.SZAEHNID = this.selectedTabelleID;
    });
    if (test) {
      this.apiservice.editTabellenFAT(c).pipe(first()).subscribe(val => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }

        this.tabellen = val;
      });
      this.locked = true;
    }
  }

  private updateFormReflectData(row: any, reflectData: any) {
    if (row[this.firstColumnId] === 'Überschrift') {
      reflectData.mainTitle = null;
      reflectData.title = row['FeldName'];
      reflectData.subheading = null;
    } else if (row[this.firstColumnId] === 'Normales Feld') {
      reflectData.mainTitle = null;
      reflectData.title = null;
      reflectData.subheading = row['FeldName'];
    } else if (row[this.firstColumnId] === 'Titel') {
      reflectData.mainTitle = row['FeldName'];
      reflectData.title = null;
      reflectData.subheading = null;
    }
    let columnName = row['Bezeichnung'];

    if (columnName != null) {
      let stable = "ostamm";

      if (row['Tabelle'] === 'Spezifikationsdaten')
        stable = "ospez";

      if (row['Tabelle'] === 'Herstellerdaten')
        stable = "oherst";

      const rowToDelete = this.structures.find((row: any) => row.TABLE_NAME === stable && row.COLUMN_NAME === columnName);

      if (rowToDelete != undefined) {
        let formFieldType;
        switch (rowToDelete.DATA_TYPE.toUpperCase()) {
          case 'VARCHAR':
          case 'TEXT':
            formFieldType = 'text';
            break;
          case 'DATETIME':
            formFieldType = 'date';
            break;
          case 'DECIMAL':
          case 'INT':
          case 'FLOAT':
            formFieldType = 'dropdown';
            break;
          case 'BIT':
            formFieldType = 'checkbox';
            break;
        }
        reflectData.fieldType = formFieldType;
      }
    }
  }


  triggerLock() {
    this.locked = !this.locked;
  }

  handleAuswahlOptionChange(row: any, value: string) {
    row['Tabelle'] = value;
  }

  handleModalReload() {
    this.reloadVorlageNamesAndFormData();
    this.reloadTabellen();
  }

  ngOnInit() {

    this.reloadVorlageNamesAndFormData();
    //this.getStructures();

    this.apiservice.getStammdatenColumns().pipe(first()).subscribe(columns => {
      this.stammdaten.columns = columns;
    });
    this.apiservice.getSpezifikationsdaten().pipe(first()).subscribe(data => {
      this.spezifikationsdaten.columns = data.columns.map(col => {
        return {
          display: col,
          value: Object.keys(data.mapping).find(key => data.mapping[key] === col)
        };
      });
      this.spezifikationsdaten.mapping = data.mapping;
    });
    this.apiservice.getHerstellerdatenColumns().pipe(first()).subscribe(columns => {
      this.herstellerdaten.columns = columns;
    });
  }
}
