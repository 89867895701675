import { CommonModule } from "@angular/common";
import { Component, EventEmitter, OnDestroy, ViewChild,} from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Subscription } from "rxjs";
import { first } from "rxjs/operators";

import { getDirection, getFormVals } from "src/app/misc/direction";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { MrUnitTextPipe } from "src/app/pipes/mrUnitText.pipe";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import { SpinnerService } from "src/app/services/Spinner/spinner.service";
import { LinieValidationManager } from "src/app/shared/classes/linie-validation-manager";
import { APIService } from "../../../../services/APIService/api.service";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";


@Component({
  selector: "app-lastelledialog",
  templateUrl: "./lastelledialog.component.html",
  styleUrls: ["./lastelledialog.component.scss"],
  imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, ObjTypePipe, BackendLocaleDatePipe,MrUnitTextPipe,
    HideIDColumnsPipe, TablePrettyPrintPipe],
  providers: [BackendLocaleDatePipe],
  standalone: true
})
export class LastelleDialogComponent extends LinieValidationManager implements OnDestroy {
  ostammid: number;
  typ_Line_Point: number = undefined;
  pointtype: number = undefined;
  startmeter: number = undefined;
  endmeter: number = undefined;
  mode: string = "new";
  editId: number = undefined;

  gemeldetDurch: any;
  aufgehobenDurch: any;
  vmax: any;
  history: any;

  columnwidth = {
    aktiv: "25px",
    vmax: "25px",
    startmeter: "110px",
    endmeter: "110px",
    richtung: "25px",
    beginn: "175px",
    ende: "175px",
    beginnBenutzername: "175px",
    endeBenutzername: "175px",
    username: "130px",
    grund: "110px",
    status: "175px",
  };

  Object = Object;
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild("historyDatagrid") historyDatagrid;

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();

  lastelleGroup = new UntypedFormGroup({
    date: new UntypedFormControl(BackendLocaleDatePipe.now),
    edate: new UntypedFormControl(""),
    time: new UntypedFormControl(""),
    etime: new UntypedFormControl(""),

    gemeldetDurch: new UntypedFormControl(""),
    aufgehobenDurch: new UntypedFormControl(""),

    ew: new UntypedFormControl(""),

    gl_g: new UntypedFormControl(""),
    gl_v: new UntypedFormControl(""),
    gl_b: new UntypedFormControl(""),

    dw_a: new UntypedFormControl(""),
    dw_b: new UntypedFormControl(""),
    dw_c: new UntypedFormControl(""),

    kr_a: new UntypedFormControl(""),
    kr_b: new UntypedFormControl(""),
    kr_c: new UntypedFormControl(""),
    kr_d: new UntypedFormControl(""),

    gesperrt: new UntypedFormControl(""),
    vmax: new UntypedFormControl(""),
    grund: new UntypedFormControl(""),
  });

  maxlen: number = undefined;
  sub = new Subscription();

  set data(dataIn: any) {
    this.ostammid = dataIn.id;
    this.typ_Line_Point = dataIn.typ_Line_Point;
    this.pointtype = dataIn.pointtype;
    if (dataIn.startmeter || dataIn.endmeter) {
      this.startmeter = dataIn.startmeter;
      this.endmeter = dataIn.endmeter;
      const sub = this.lastelleGroup.get('gl_g').valueChanges.subscribe(
        value => {
          if (value != this.lastelleGroup.value.gl_g) {
            if (!value) this.initLinieValidators(this.startmeter, this.endmeter);
            else this.clearLinieValidators();
          }
        }
      );
      this.sub.add(sub);
      this.initLinieValidators(this.startmeter, this.endmeter);
    }
    if (dataIn.dataRefresh) {
      this.dataRefresh = dataIn.dataRefresh;
    }

    this.apiservice
      .getLastelle(this.ostammid)
      .pipe(first())
      .subscribe((lsfstl: any) => {
        if (lsfstl.gemeldetDurch) {
          this.gemeldetDurch = lsfstl.gemeldetDurch.map(
            (user) => user.busername
          );
        }

        if (lsfstl.aufgehobenDurch) {
          this.aufgehobenDurch = lsfstl.aufgehobenDurch.map(
            (user) => user.eusername
          );
        }
        if (lsfstl.history) {
          this.history = lsfstl.history;
        }
        if (lsfstl.vmax) {
          this.vmax = lsfstl.vmax.map((vm) => vm.vmax);
        }

        /* if (lsfstl.maxlen) {
          this.maxlen = lsfstl.maxlen;
          this.lastelleGroup.controls.gl_b.setValidators([
            Validators.min(0),
            Validators.max(lsfstl.maxlen),
          ]);
          this.lastelleGroup.controls.gl_v.setValidators([
            Validators.min(0),
            Validators.max(lsfstl.maxlen - 1),
          ]);
        } else {
          this.maxlen = undefined;
          this.lastelleGroup.controls.gl_b.setValidators([]);
          this.lastelleGroup.controls.gl_v.setValidators([]);
        } */
      });
  }

  trial = false;

  constructor(
    //@Inject(MAT_DIALOG_DATA) public data,
    private apiservice: APIService,
    private toastr: ToastrService,
    private spinner: SpinnerService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
    private objtypePipe: ObjTypePipe,
    protected localeDate: BackendLocaleDatePipe
  ) {
    super({ start: 'gl_v', end: 'gl_b' });
    this.form = this.lastelleGroup;
    this.trial = this.authService.getToken().trial;
  }

  selectRadio(val: any) {
    this.lastelleGroup.controls.ew.setValue(val);
  }

  camelcase(val: string) {
    return val.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }

  onSubmit() {
    let data = this.lastelleGroup.value;
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == "Invalid date") {
        delete data[key];
      }
    });

    data.gesperrt = data.gesperrt ? true : false;
    data.grund = data.grund ? data.grund : "";

    let config = "";
    let gleisData;
    let direction = 0;
    if (this.objtypePipe.transform(this.typ_Line_Point) == "line") {
      if (
        data.gl_g ||
        (data.gl_v !== null &&
          data.gl_v !== undefined &&
          data.gl_v !== "" &&
          data.gl_b !== null &&
          data.gl_b !== undefined &&
          data.gl_b !== "")
      ) {
        direction = 99;
        gleisData = {
          g: data.gl_g,
          v: data.gl_v,
          b: data.gl_b,
        };
        config = "gl";
      }
    } else if (this.objtypePipe.transform(this.typ_Line_Point) == "point") {
      switch (this.pointtype) {
        case 1:
          if (data.ew) {
            direction = data.ew;
            config = "ew";
          }
          break;
        case 2:
          if (data.dw_a || data.dw_b || data.dw_c) {
            direction = getDirection([data.dw_a, data.dw_b, data.dw_c]);
            config = "dw";
          }
          break;
        case 3:
          if (data.kr_a || data.kr_b || data.kr_c || data.kr_c || data.kr_d) {
            direction = getDirection([
              data.kr_a,
              data.kr_b,
              data.kr_c,
              data.kr_d,
            ]);
            config = "kr";
          }
          break;
      }
    }

    let sendObj = {
      operation: this.mode,
      editId: this.editId ? this.editId : undefined,
      date: this.localeDate.addTimeToDate(
        data.date || BackendLocaleDatePipe.now,
        data.time
      ),
      edate: this.localeDate.addTimeToDate(data.edate, data.etime),
      gemeldetDurch: data.gemeldetDurch,
      aufgehobenDurch: data.aufgehobenDurch,
      mode: config,
      direction: direction,
      gleisData: gleisData ?? undefined,
      gesperrt: data.gesperrt,
      vmax: data.vmax,
      grund: data.grund,
    };

    let err = [];

    if (this.lastelleGroup.status != "VALID" || this.lastelleGroup.errors) {
      err.push("Formularvalidierung (rot) beachten");
    }

    if (!sendObj.vmax) {
      err.push("V-max ausfüllen");
    }

    if (this.pointtype && config == "") {
      err.push("Bitte eine Richtung / Gleisabschnitt auswählen");
    } else {
      if (this.objtypePipe.transform(this.typ_Line_Point) == "line") {
        if (!sendObj.gleisData) {
          err.push("Gleisinformationen ausfüllen");
        } else {
          if (
            parseFloat(sendObj.gleisData.v) > parseFloat(sendObj.gleisData.b)
          ) {
            err.push("von < bis");
          }
        }
      } else if (this.objtypePipe.transform(this.typ_Line_Point) == "point") {
        if (this.pointtype && !sendObj.direction) {
          err.push("Richtung ausfüllen");
        }
      }
    }

    if (err.length == 0) {
      this.spinner.enable();
      this.apiservice
        .sendLastelle(this.ostammid, sendObj)
        .pipe(first())
        .subscribe((res: any) => {
          if (res.message && res.message == "success") {
            if (this.mode == "new") {
              this.toastr.success(
                this.mrTranslate.transform("Daten gespeichert")
              );
            } else {
              this.toastr.success(
                this.mrTranslate.transform("Daten gespeichert")
              );
              this.editId = undefined;
              this.mode = "new";
            }
            this.lastelleGroup.reset({ date: BackendLocaleDatePipe.now });
            if (res.history) {
              if (!this.history.columns?.length) {
                this.history["columns"] = res.history["columns"];
              }
              this.history["rows"] = res.history["rows"];
            }
            this.dataRefresh?.emit(true);
          } else {
            this.toastr.error(
              this.mrTranslate.transform("Ein Fehler ist aufgetreten.")
            );
          }
          this.spinner.disable();
        });
    } else {
      this.toastr.warning(
        "<ul>" +
        err.map((e) => `<li>${this.mrTranslate.transform(e)}</li>`).join("") +
        "</ul>",
        this.mrTranslate.transform("Bitte folgendes beachten:"),
        {
          enableHtml: true,
        }
      );
    }
  }

  switchMode(newMode: string) {
    if (newMode == "new") {
      this.lastelleGroup.reset({ date: BackendLocaleDatePipe.now });
      this.editId = undefined;
    }
    this.mode = newMode;
  }

  editRow(row: any) {
    this.lastelleGroup.reset();
    this.lastelleGroup.controls.gesperrt.setValue(row.aktiv);
    this.lastelleGroup.controls.vmax.setValue(row.vmax);
    this.lastelleGroup.controls.date.setValue(row.beginn);
    this.lastelleGroup.controls.time.setValue(row.beginn?.slice(-8));
    this.lastelleGroup.controls.edate.setValue(row.ende);
    this.lastelleGroup.controls.etime.setValue(row.ende?.slice(-8));
    this.lastelleGroup.controls.aufgehobenDurch.setValue(row.endeBenutzername);
    this.lastelleGroup.controls.gl_v.setValue(row.startmeter);
    this.lastelleGroup.controls.gl_b.setValue(row.endmeter);
    this.lastelleGroup.controls.grund.setValue(row.grund);
    this.lastelleGroup.controls.gemeldetDurch.setValue(row.beginnBenutzername);

    if (this.objtypePipe.transform(this.typ_Line_Point) == "point") {
      let formvals = getFormVals(row.richtung);
      if (Array.isArray(formvals)) {
        if (formvals.length == 3) {
          this.lastelleGroup.controls.dw_a.setValue(formvals[0]);
          this.lastelleGroup.controls.dw_b.setValue(formvals[1]);
          this.lastelleGroup.controls.dw_c.setValue(formvals[2]);
        } else if (formvals.length == 4) {
          this.lastelleGroup.controls.kr_a.setValue(formvals[0]);
          this.lastelleGroup.controls.kr_b.setValue(formvals[1]);
          this.lastelleGroup.controls.kr_c.setValue(formvals[2]);
          this.lastelleGroup.controls.kr_d.setValue(formvals[3]);
        }
      } else {
        this.lastelleGroup.controls.ew.setValue(formvals.toString());
      }
    }

    this.editId = row.id;
    this.mode = "edit";
  }

  public close() {
    this.closedialog.next(true);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
