<div class="overlay-inner-wrapper">
  <div id="benutzerverwaltungsWrapper">
    <div class="clr-row nxm">
      <div class="clr-col-12 nxp">
        <div class="text-left inline-block">
          <h3 class="margin-0-t" style="margin-top:0px !important;">{{ "Benutzer- und Gruppenverwaltung" | mrTranslate }}
          </h3>
        </div>
        <div class="inline-block float-right">
          <clr-icon
            size="20"
            shape="times"
            (click)="close()"
            class="cursor-pointer"
          ></clr-icon>
        </div>
      </div>
    </div>

    <clr-tabs>
      <clr-tab>
        <button clrTabLink (click)="tabChange('user')">
          {{ "Benutzerverwaltung" | mrTranslate }}
        </button>

        <clr-tab-content *ngIf="activeTabs.user">
          <div class="clr-row nxm padding-10-t">
            <div *ngIf="users"
              [ngClass]="{
                'clr-col-12 nxp':!selectedUser ||(selectedUser && selectedUser.id == 'INSERT'),
                'clr-col-4': selectedUser !== undefined && selectedUser.id != 'INSERT'
              }">
              <!-- <button
                class="btn btn-sm"
                (click)="triggerLock()"
                [disabled]="selectedUser === undefined"
              >
                <clr-icon [attr.shape]="locked ? 'lock' : 'unlock'"></clr-icon>
              </button> -->

              <button class="btn btn-sm" [disabled]="trial" (click)="add()">
                + {{ "Benutzer neu anlegen" | mrTranslate }}
              </button>

              <button class="btn btn-primary btn-sm" (click)="send()" [disabled]="trial || !selectedUser">
                {{ "Benutzer speichern" | mrTranslate }}
              </button>

              <clr-datagrid style="padding-top: 0px" class="datagrid-compact" [(clrDgSingleSelected)]="selectedUser">>
                <clr-dg-column
                  *ngFor="let column of users['columns']"
                  [clrDgField]="column.id"
                  [hidden]="column.id | hideIDColumns:['userimagebase64', 'eisenbahnbetriebsleiter (ebl)', 'anlagenverantwortlicher (alv)' ,'user_alv','status','pwhash']"
                  >{{ column.id | titlecase | mrTranslate }}</clr-dg-column
                >

                <clr-dg-row *clrDgItems="let row of users['rows']; let indx = index" [clrDgItem]="row">

                <clr-dg-action-overflow>
                  <button class="action-item" (click)="editUser(row)">{{'Bearbeiten' | mrTranslate}}</button>
                  <button class="action-item" (click)="deleteUser(row.id)">{{'Löschen' | mrTranslate}}</button>
                </clr-dg-action-overflow>


                  <clr-dg-cell
                    *ngFor="let cell of users['columns']"
                    [hidden]="cell.id | hideIDColumns:['userimagebase64', 'eisenbahnbetriebsleiter (ebl)', 'anlagenverantwortlicher (alv)' , 'user_ebl' ,'user_alv','status','pwhash']"
                    >
                    <input
                      clrInput
                      type="text"
                      [(ngModel)]="row[cell.id]"
                      [readonly]="true || selectedUser != row"
                    />
                  </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination
                    class="dg-pagination"
                    style="right: 60px"
                    #pagination
                    [clrDgPageSize]="15"
                  >
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}'
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>

            <div
              *ngIf="
                selectedUserRights &&
                selectedUser &&
                selectedUser.id != 'INSERT'
              "
              class="clr-col-4"
            >
              <h3 class="margin-11-tb">{{ "Rechte" | mrTranslate }}</h3>
              <clr-accordion>
                <clr-accordion-panel>
                  <clr-accordion-title>{{
                    "Ansicht" | mrTranslate
                  }}</clr-accordion-title>
                  <clr-accordion-content *clrIfExpanded>
                    <clr-checkbox-container
                      *ngFor="let right of selectedUserRights.view"
                      class="clr-form-control"
                    >
                      <clr-checkbox-wrapper>
                        <label>{{ right.descriptor | mrTranslate }}</label>
                        <input
                          [disabled]="!selectedUser"
                          type="checkbox"
                          clrCheckbox
                          [(ngModel)]="right.value"
                        />
                      </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                  </clr-accordion-content>
                </clr-accordion-panel>

                <clr-accordion-panel>
                  <clr-accordion-title>{{
                    "Programm" | mrTranslate
                  }}</clr-accordion-title>
                  <clr-accordion-content *clrIfExpanded>
                    <clr-checkbox-container
                      *ngFor="let right of selectedUserRights.program"
                      class="clr-form-control"
                    >
                      <clr-checkbox-wrapper>
                        <label>{{ right.descriptor | mrTranslate }}</label>
                        <input
                          [disabled]="!selectedUser"
                          type="checkbox"
                          clrCheckbox
                          [(ngModel)]="right.value"
                        />
                      </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                  </clr-accordion-content>
                </clr-accordion-panel>

                <ng-container
                  *ngIf="selectedUserRights.type"
                >
                  <clr-accordion-panel
                    *ngFor="let type of selectedUserRights.type | keyvalue"
                  >
                    <clr-accordion-title>
                      {{ sotyp[type.key]?.bezeichnung ?? ('Objekttyp' + type.key) }}
                    </clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>
                      <clr-checkbox-container
                        *ngFor="let right of type.value"
                        class="clr-form-control"
                      >
                        <clr-checkbox-wrapper>
                          <label>{{ right.descriptor | mrTranslate }}</label>
                          <input
                            [disabled]="!selectedUser"
                            type="checkbox"
                            clrCheckbox
                            [(ngModel)]="right.value"
                          />
                        </clr-checkbox-wrapper>
                      </clr-checkbox-container>
                    </clr-accordion-content>
                  </clr-accordion-panel>
                </ng-container>
              </clr-accordion>
            </div>

            <div *ngIf="selectedUser !== undefined && selectedUser.id != 'INSERT'" class="clr-col-4">
              <h3 class="margin-11-tb">{{ "Gruppen" | mrTranslate }}</h3>
              <clr-datagrid style="padding-top: 0px" class="datagrid-compact margin--12-t" [(clrDgSelected)]="selectedUserGroups">
                <clr-dg-column [clrDgField]="Bezeichnung">{{"Bezeichnung" | mrTranslate}}</clr-dg-column>
                <clr-dg-column [clrDgField]="Rechte">{{"Rechte" | mrTranslate}}</clr-dg-column>

                <clr-dg-row [clrDgSelectable]="selectedUser" *clrDgItems=" let grp of groups; let indx = index" [clrDgItem]="grp.id">
                  <clr-dg-cell>{{ grp.Bezeichnung }} </clr-dg-cell>
                  <clr-dg-cell>{{ groupRights[grp.Rechte] }}</clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                  <clr-dg-pagination
                    class="dg-pagination"
                    style="right: 60px"
                    #pagination
                    [clrDgPageSize]="15"
                  >
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>

              <h3 class="margin-11-tb">
                {{ "Auftragsgruppen" | mrTranslate }}
              </h3>
              <clr-datagrid
                style="padding-top: 0px"
                class="datagrid-compact margin--12-t"
                [(clrDgSelected)]="selectedUserAuftragGroups"
              >
                <clr-dg-column [clrDgField]="Bezeichnung">{{
                  "Bezeichnung" | mrTranslate
                }}</clr-dg-column>
                <clr-dg-row
                  [clrDgSelectable]="selectedUser"
                  *clrDgItems="let grp of auftragsGroups; let indx = index"
                  [clrDgItem]="grp.id"
                >
                  <clr-dg-cell>
                    {{ grp.Bezeichnung }}
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                  <clr-dg-pagination
                    class="dg-pagination"
                    style="right: 60px"
                    #pagination1
                    [clrDgPageSize]="15"
                  >
                  {{ pagination1.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink (click)="tabChange('groups')">
          {{ "Gruppen" | mrTranslate }}
        </button>

        <clr-tab-content *ngIf="activeTabs.groups">
          <div class="clr-row nxm padding-10-t">
            <div *ngIf="groups"
              [ngClass]="{
                'clr-col-12 nxp': !selectedGroupID || selectedGroupID == 'INSERT',
                'clr-col-6': selectedGroupID != 'INSERT' && selectedGroupID !== undefined
              }">

              <button class="btn btn-sm" (click)="isGroupAddVisible=true" [disabled]="trial"> + {{ "Gruppe hinzufügen" | mrTranslate }}</button>

              <clr-datagrid style="padding-top: 0px" class="datagrid-compact" [(clrDgSingleSelected)]="selectedGroupID">

                <clr-dg-column [clrDgField]="Bezeichnung">{{"Bezeichnung" | mrTranslate}}</clr-dg-column>
                <clr-dg-column [clrDgField]="Rechte">{{"Rechte" | mrTranslate}}</clr-dg-column>

                <clr-dg-row *clrDgItems="let grp of groups; let indx = index" [clrDgItem]="grp.id">

                <clr-dg-action-overflow *ngIf="grp.id != 1">
                  <button class="action-item" [disabled]="grp.id == 1" (click)="editShowGruppe(grp.id)">{{'Bearbeiten' | mrTranslate}}</button>
                  <button class="action-item" [disabled]="grp.id == 1" (click)="deleteGruppe(grp.id)">{{'Löschen' | mrTranslate}}</button>
                </clr-dg-action-overflow>

                  <clr-dg-cell>
                    <input clrInput type="text" [(ngModel)]="grp.Bezeichnung" [readonly]="true"/>
                  </clr-dg-cell>

                  <clr-dg-cell style="align-self: center">
                    <ng-container>{{ groupRights[grp.Rechte] }}</ng-container>
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                  <clr-dg-pagination
                    class="dg-pagination"
                    style="right: 60px"
                    #pagination
                    [clrDgPageSize]="15"
                  >
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>

            <div
              *ngIf="
                selectedGroupID && groupUser && selectedGroupID != 'INSERT'
              "
              class="clr-col-6"
            >
              <h3 class="margin-11-t">{{ "Benutzer" | mrTranslate }}</h3>
              <clr-datagrid style="padding-top: 0px" class="datagrid-compact">
                <clr-dg-column [clrDgField]="delete" [hidden]="groupsLocked"
                  >{{ "Löschen" | mrTranslate }}
                </clr-dg-column>
                <clr-dg-column [clrDgField]="UserName">{{
                  "Username" | mrTranslate
                }}</clr-dg-column>
                <clr-dg-column [clrDgField]="UserMail">{{
                  "User Email" | mrTranslate
                }}</clr-dg-column>

                <clr-dg-row
                  *clrDgItems="
                    let grpUser of groupUser['rows'];
                    let indx = index
                  "
                  [clrDgItem]="grpUser.id"
                >
                  <clr-dg-cell [hidden]="groupsLocked">
                    <clr-icon
                      shape="times"
                      class="cursor-pointer"
                      (click)="!trial ? removeUserFromGroup(grpUser.id) : ''"
                    ></clr-icon>
                  </clr-dg-cell>

                  <!-- <clr-dg-action-overflow>
                    <button class="action-item" (click)="deleteAufGruppe(grpUser.id)">{{'Löschen' | mrTranslate}}</button>
                  </clr-dg-action-overflow> -->

                  <clr-dg-cell>
                    {{ grpUser.UserName }}
                  </clr-dg-cell>

                  <clr-dg-cell>
                    {{ grpUser.UserMail }}
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                  <clr-dg-pagination
                    class="dg-pagination"
                    style="right: 60px"
                    #pagination
                    [clrDgPageSize]="15"
                  >
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>

      <clr-tab>
        <button clrTabLink (click)="tabChange('groupsObj')">
          {{ "Gruppenberechtigungen" | mrTranslate }}
        </button>

        <clr-tab-content *ngIf="activeTabs.groupsObj">
          <div
            class="clr-row nxm"
            id="groupSelectionRow"
            [ngClass]="{ enlarged: !_selectedGroupNameForRights }"
          >
            <div class="clr-col-12 nxp text-center">
              <clr-select-container style="display: inline-block">
                <select clrSelect [(ngModel)]="selectedGroupNameForRights">
                  <option [value]="undefined" disabled selected>
                    Gruppe auswählen
                  </option>
                  <option *ngFor="let grp of groups" [value]="grp.id">
                    {{ grp.Bezeichnung }}
                  </option>
                </select>
              </clr-select-container>
              <button
                *ngIf="selectedGroupNameForRights"
                [disabled]="
                  trial ||
                  (groupObjectsDifference?.toAdd?.length == 0 &&
                    groupObjectsDifference?.toRemove?.length == 0)
                "
                class="btn btn-sm btn-primary margin-10-l"
                type="submit"
                (click)="saveGroupObjects()"
                style="display: inline-block"
              >
                {{ "Speichern" | mrTranslate }}
              </button>
            </div>
          </div>
          <div
            id="objectSelectionWrapper"
            class="clr-row nxm"
            *ngIf="selectedGroupNameForRights"
          >
            <div
              class="clr-col"
              style="max-width: calc(calc(100% - 8.3333333333%) / 2)"
            >
              <clr-datagrid
                #leftObjectsDatagrid
                class="datagrid-compact"
                *ngIf="
                  groupObjects?.leftObjects &&
                  groupObjects?.leftObjects?.columns
                "
                [(clrDgSelected)]="groupObjects.leftObjects.toAdd"
                [clrDgRowSelection]="true"
              >
                <clr-dg-column
                  *ngFor="let column of groupObjects.leftObjects['columns']"
                  [clrDgField]="column.id"
                  [hidden]="column.id | hideIDColumns"
                >
                  {{ column.id | titlecase | mrTranslate }}
                </clr-dg-column>

                <clr-dg-row
                  *clrDgItems="let row of groupObjects.leftObjects['rows']"
                  [clrDgItem]="row"
                >
                  <clr-dg-cell
                    class="name-cell"
                    *ngFor="let cell of groupObjects.leftObjects['columns']"
                    [hidden]="cell.id | hideIDColumns"
                    [innerHTML]="row[cell.id] | tablePrettyPrint"
                  ></clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination #pagination [clrDgPageSize]="25">
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>
            <div id="objectControl" class="clr-col-1">
              <a
                href="javascript:void(0);"
                [ngClass]="{
                  disabled: groupObjects?.addedObjects?.toRemove?.length > 0
                }"
                (click)="executeToAdd()"
              >
                <clr-icon
                  shape="circle-arrow right"
                  size="72"
                  style="color: green"
                ></clr-icon>
              </a>
              <a
                href="javascript:void(0);"
                [ngClass]="{
                  disabled: groupObjects?.leftObjects?.toAdd.length > 0 > 0
                }"
                (click)="executeToRemove()"
              >
                <clr-icon
                  shape="circle-arrow left"
                  size="72"
                  style="color: red"
                  class="is-solid"
                ></clr-icon>
              </a>
            </div>
            <div
              class="clr-col"
              style="max-width: calc(calc(100% - 8.3333333333%) / 2)"
            >
              <clr-datagrid
                #addedObjectsDatagrid
                class="datagrid-compact"
                *ngIf="
                  groupObjects?.addedObjects &&
                  groupObjects?.addedObjects?.columns
                "
                [(clrDgSelected)]="groupObjects.addedObjects.toRemove"
                [clrDgRowSelection]="true"
              >
                <clr-dg-column
                  *ngFor="let column of groupObjects?.addedObjects['columns']"
                  [clrDgField]="column.id"
                  [hidden]="column.id | hideIDColumns"
                >
                  {{ column.id | titlecase | mrTranslate }}
                </clr-dg-column>

                <clr-dg-row
                  *clrDgItems="let row of groupObjects.addedObjects['rows']"
                  [clrDgItem]="row"
                >
                  <clr-dg-cell
                    class="name-cell"
                    *ngFor="let cell of groupObjects.addedObjects['columns']"
                    [hidden]="cell.id | hideIDColumns"
                    [innerHTML]="row[cell.id] | tablePrettyPrint"
                  ></clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination #pagination [clrDgPageSize]="25">
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>

      <!-- AUFTRAGSGRUPPEN -->

      <clr-tab>
        <button clrTabLink (click)="tabChange('auftraggroups')">
          {{ "Auftragsgruppen" | mrTranslate }}
        </button>

        <clr-tab-content *ngIf="activeTabs.auftraggroups">
          <div class="clr-row nxm padding-10-t">
            <div *ngIf="groups"
              [ngClass]="{
                'clr-col-12 nxp': !selectedAuftragGroupID || selectedAuftragGroupID == 'INSERT',
                'clr-col-6': selectedAuftragGroupID != 'INSERT' && selectedAuftragGroupID !== undefined
              }"
            >
              <button class="btn btn-sm" (click)="isAufGroupAddVisible=true" [disabled]="trial"> + {{ "Auftragsgruppe hinzufügen" | mrTranslate }}</button>

              <clr-datagrid style="padding-top: 0px" class="datagrid-compact" [(clrDgSingleSelected)]="selectedAuftragGroupID">

                <clr-dg-column [clrDgField]="Bezeichnung">{{"Bezeichnung" | mrTranslate}}</clr-dg-column>
                <clr-dg-column [clrDgField]="Kostenstelle">{{"Kostenstelle" | mrTranslate}}</clr-dg-column>

                <clr-dg-row *clrDgItems="let grp of auftragsGroups; let indx = index" [clrDgItem]="grp.id">
                <clr-dg-action-overflow *ngIf="grp.id != 1">
                  <button class="action-item" [disabled]="grp.id == 1" (click)="editShowAufGruppe(grp.id)">{{'Bearbeiten' | mrTranslate}}</button>
                  <button class="action-item" [disabled]="grp.id == 1" (click)="deleteAufGruppe(grp.id)">{{'Löschen' | mrTranslate}}</button>
                </clr-dg-action-overflow>
                  <clr-dg-cell>
                    <input clrInput type="text" [(ngModel)]="grp.Bezeichnung" [readonly]="true"/>
                  </clr-dg-cell>
                  <clr-dg-cell>
                    <input clrInput type="text" [(ngModel)]="grp.Kostenstelle" [readonly]="true"/>
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                  <clr-dg-pagination
                    class="dg-pagination"
                    style="right: 60px"
                    #pagination
                    [clrDgPageSize]="15"
                  >
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>

            <div
              *ngIf="
                selectedAuftragGroupID &&
                auftragGroupUser &&
                selectedAuftragGroupID != 'INSERT'
              "
              class="clr-col-6"
            >
              <h3 class="margin-11-t">{{ "Benutzer" | mrTranslate }}</h3>
              <clr-datagrid style="padding-top: 0px" class="datagrid-compact">
                <!-- <clr-dg-column> -->

                  <!-- <clr-dg-action-overflow>
                    <button class="action-item" (click)="openObject(row.ostammid)">{{'Öffnen' | mrTranslate}}</button>
                  </clr-dg-action-overflow> -->
<!-- </clr-dg-column> -->
                <clr-dg-column
                  [clrDgField]="delete"
                  [hidden]="auftragGroupsLocked"
                  >{{ "Löschen" | mrTranslate }}
                </clr-dg-column>
                <clr-dg-column [clrDgField]="UserName">{{
                  "Username" | mrTranslate
                }}</clr-dg-column>
                <clr-dg-column [clrDgField]="UserMail">{{
                  "User Email" | mrTranslate
                }}</clr-dg-column>

                <clr-dg-row
                  *clrDgItems="
                    let grpUser of auftragGroupUser['rows'];
                    let indx = index
                  "
                  [clrDgItem]="grpUser.id"
                >

                <!-- <clr-dg-action-overflow>
                  <button class="action-item" (click)="deleteAufGruppe(grpUser.id)">{{'Löschen' | mrTranslate}}</button>
                </clr-dg-action-overflow> -->


                  <clr-dg-cell [hidden]="auftragGroupsLocked">
                    <clr-icon
                      shape="times"
                      class="cursor-pointer"
                      (click)="!trial ? removeUserFromAuftragGroup(grpUser.id) : ''"
                    ></clr-icon>
                  </clr-dg-cell>

                  <clr-dg-cell>
                    {{ grpUser.UserName }}
                  </clr-dg-cell>

                  <clr-dg-cell>
                    {{ grpUser.UserMail }}
                  </clr-dg-cell>
                </clr-dg-row>

                <clr-dg-footer>
                  <clr-dg-pagination
                    class="dg-pagination"
                    style="right: 60px"
                    #pagination
                    [clrDgPageSize]="15"
                  >
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>

      <!-- AUFTRAGSGRUPPENBERECHTIGUNGEN -->

      <clr-tab>
        <button clrTabLink (click)="tabChange('auftraggroupsObj')">
          {{ "Auftragsgruppenberechtigungen" | mrTranslate }}
        </button>

        <clr-tab-content *ngIf="activeTabs.auftraggroupsObj">
          <div
            class="clr-row nxm"
            id="groupSelectionRow"
            [ngClass]="{ enlarged: !_selectedAuftragGroupNameForRights }"
          >
            <div class="clr-col-12 nxp text-center">
              <clr-select-container style="display: inline-block">
                <select
                  clrSelect
                  [(ngModel)]="selectedAuftragGroupNameForRights"
                >
                  <option [value]="undefined" disabled selected>
                    Gruppe auswählen
                  </option>
                  <option *ngFor="let grp of auftragsGroups" [value]="grp.id">
                    {{ grp.Bezeichnung }}
                  </option>
                </select>
              </clr-select-container>
              <button
                *ngIf="selectedAuftragGroupNameForRights"
                [disabled]="
                  trial ||
                  (auftragGroupObjectsDifference?.toAdd?.length == 0 &&
                    auftragGroupObjectsDifference?.toRemove?.length == 0)
                "
                class="btn btn-sm btn-primary margin-10-l"
                type="submit"
                (click)="saveAuftragGroupObjects()"
                style="display: inline-block"
              >
                {{ "Speichern" | mrTranslate }}
              </button>
            </div>
          </div>
          <div
            id="objectSelectionWrapper"
            class="clr-row nxm"
            *ngIf="selectedAuftragGroupNameForRights"
          >
            <div
              class="clr-col"
              style="max-width: calc(calc(100% - 8.3333333333%) / 2)"
            >
              <clr-datagrid
                #leftAuftragObjectsDatagrid
                class="datagrid-compact"
                *ngIf="
                  auftragGroupObjects?.leftObjects &&
                  auftragGroupObjects?.leftObjects?.columns
                "
                [(clrDgSelected)]="auftragGroupObjects.leftObjects.toAdd"
                [clrDgRowSelection]="true"
              >
                <clr-dg-column
                  *ngFor="
                    let column of auftragGroupObjects.leftObjects['columns']
                  "
                  [clrDgField]="column.id"
                  [hidden]="column.id | hideIDColumns"
                >
                  {{ column.id | titlecase | mrTranslate }}
                </clr-dg-column>

                <clr-dg-row
                  *clrDgItems="
                    let row of auftragGroupObjects.leftObjects['rows']
                  "
                  [clrDgItem]="row"
                >
                  <clr-dg-cell
                    class="name-cell"
                    *ngFor="
                      let cell of auftragGroupObjects.leftObjects['columns']
                    "
                    [hidden]="cell.id | hideIDColumns"
                    [innerHTML]="row[cell.id] | tablePrettyPrint"
                  ></clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination #pagination [clrDgPageSize]="25">
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>
            <div id="objectControl" class="clr-col-1">
              <a
                href="javascript:void(0);"
                [ngClass]="{
                  disabled:
                    auftragGroupObjects?.addedObjects?.toRemove?.length > 0
                }"
                (click)="executeAuftraggroupToAdd()"
              >
                <clr-icon
                  shape="circle-arrow right"
                  size="72"
                  style="color: green"
                ></clr-icon>
              </a>
              <a
                href="javascript:void(0);"
                [ngClass]="{
                  disabled:
                    auftragGroupObjects?.leftObjects?.toAdd?.length > 0
                }"
                (click)="executeAuftraggroupToRemove()"
              >
                <clr-icon
                  shape="circle-arrow left"
                  size="72"
                  style="color: red"
                  class="is-solid"
                ></clr-icon>
              </a>
            </div>
            <div
              class="clr-col"
              style="max-width: calc(calc(100% - 8.3333333333%) / 2)"
            >
              <clr-datagrid
                #addedAuftragObjectsDatagrid
                class="datagrid-compact"
                *ngIf="
                  auftragGroupObjects?.addedObjects &&
                  auftragGroupObjects?.addedObjects?.columns
                "
                [(clrDgSelected)]="auftragGroupObjects.addedObjects.toRemove"
                [clrDgRowSelection]="true"
              >
                <clr-dg-column
                  *ngFor="
                    let column of auftragGroupObjects?.addedObjects['columns']
                  "
                  [clrDgField]="column.id"
                  [hidden]="column.id | hideIDColumns"
                >
                  {{ column.id | titlecase | mrTranslate }}
                </clr-dg-column>

                <clr-dg-row
                  *clrDgItems="
                    let row of auftragGroupObjects.addedObjects['rows']
                  "
                  [clrDgItem]="row"
                >

                <!-- <clr-dg-action-overflow>
                  <button class="action-item" [disabled]="grp.id == 1" (click)="deleteAufGruppe(grp.id)">{{'Löschen' | mrTranslate}}</button>
                </clr-dg-action-overflow> -->

                  <clr-dg-cell
                    class="name-cell"
                    *ngFor="
                      let cell of auftragGroupObjects.addedObjects['columns']
                    "
                    [hidden]="cell.id | hideIDColumns"
                    [innerHTML]="row[cell.id] | tablePrettyPrint"
                  ></clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                  <clr-dg-pagination #pagination [clrDgPageSize]="25">
                    {{ pagination.firstItem + 1 }} -
                    {{ pagination.lastItem + 1 }} {{ "von" | mrTranslate }}
                    {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
                  </clr-dg-pagination>
                </clr-dg-footer>
              </clr-datagrid>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>
      <!-- Passwortsicherheit -->
      <clr-tab>
        <button clrTabLink (click)="tabChange('security')">
          {{ "Passwortsicherheit" | mrTranslate }}
        </button>

        <clr-tab-content *ngIf="activeTabs.security">

              <button class="btn btn-sm" (click)="setPWSecurity()">{{ "Speichern" | mrTranslate }}</button>

              <br>
              <div class="clr-row nxm">
                <div class="clr-col-2">
                  <span>{{'Passwortoptionen' | mrTranslate}}:</span>
                </div>
              </div>
              <br>
              <div class="clr-row nxm">
                <div class="clr-col-1">
                  <span>{{'Mindestlänge' | mrTranslate}}:</span>
                </div>
                <div class="clr-col-3">
                  <clr-input-container>
                    <label for="minLengthInput">{{ 'Mindestlänge' | mrTranslate }}</label>
                    <input clrInput id="minLengthInput" type="number" min="6" max="30" step="1" name="name" required style="width: 70px;" [(ngModel)]="optPWMinL" />
                    <clr-control-error>This field is required!</clr-control-error>
                  </clr-input-container>

                </div>

              </div>
              <br>
              <div class="clr-row nxm">
                <div class="clr-col-3">
                  <div class="clr-form-control">
                    <div class="clr-control-container">
                      <div class="clr-checkbox-wrapper">
                        <input type="checkbox" [(ngModel)]="optPWkBuch" clrCheckbox />
                        <label class="clr-control-label" for="clr-form-control-1">{{'Kleinbuchstaben erforderlich [a-z]' | mrTranslate}}</label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="clr-col-3" *ngIf="optPWkBuch">
                  <clr-input-container>
                    <input type="number" min="1" max="30" step="1" clrInput name="name" required style="width: 70px;" [(ngModel)]="optAzkBuch"/>
                    <clr-control-error>This field is required!</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
              <br>
              <div class="clr-row nxm">
                <div class="clr-col-3">
                  <div class="clr-form-control">
                    <div class="clr-control-container">
                      <div class="clr-checkbox-wrapper">
                        <input type="checkbox" [(ngModel)]="optPWgBuch" clrCheckbox />
                        <label class="clr-control-label" for="clr-form-control-1">{{'Grossbuchstaben erforderlich [A-Z]' | mrTranslate}}</label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="clr-col-3" *ngIf="optPWgBuch">
                  <clr-input-container>
                    <input type="number" min="1" max="30" step="1" clrInput name="name" required style="width: 70px;" [(ngModel)]="optAzgBuch"/>
                    <clr-control-error>This field is required!</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
              <br>
              <div class="clr-row nxm">
                <div class="clr-col-3">
                  <div class="clr-form-control">
                    <div class="clr-control-container">
                      <div class="clr-checkbox-wrapper">
                        <input type="checkbox" [(ngModel)]="optPWZahl" clrCheckbox/>
                        <label class="clr-control-label" for="clr-form-control-1">{{'Zahlen erforderlich [0-9]' | mrTranslate}}</label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="clr-col-3" *ngIf="optPWZahl">
                  <clr-input-container>
                    <input type="number" min="1" max="30" step="1" clrInput name="name" required style="width: 70px;" [(ngModel)]="optAzZahl"/>
                    <clr-control-error>This field is required!</clr-control-error>
                  </clr-input-container>
                </div>
              </div>
              <br>
              <div class="clr-row nxm">
                <div class="clr-col-3">
                  <div class="clr-form-control">
                    <div class="clr-control-container">
                      <div class="clr-checkbox-wrapper">
                        <input type="checkbox" [(ngModel)]="optPWSonder" clrCheckbox/>
                        <label class="clr-control-label" for="clr-form-control-1">{{'Sonderzeichen erforderlich [!°@-#$%^?*_+=<>]' | mrTranslate}}</label>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="clr-col-3" *ngIf="optPWSonder">
                  <clr-input-container>
                    <input type="number" min="1" max="30" step="1" clrInput name="name" required style="width: 70px;" [(ngModel)]="optAzSonder"/>
                    <clr-control-error>This field is required!</clr-control-error>
                  </clr-input-container>
                </div>
              </div>




        </clr-tab-content>
      </clr-tab>


    </clr-tabs>
  </div>
</div>

<clr-modal [(clrModalOpen)]="isUserEditVisible" [clrModalClosable]="true">
  <h3 class="modal-title" id="modaltitle">Benutzer bearbeiten</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row nxm">
      <div class="clr-col" style="width: 20%;">
        <div class="avatar-container">
          <img src="/assets/icons/user-solid.svg" id="_image" alt=”avatar”/>
          <div *ngIf="file; else placeholder">
            <button type="button" class="floatAvatarBtn btn btn-link btn-icon" (click)="onFileClear('edit')">
              <clr-icon shape="times"></clr-icon>
            </button>
          </div>
          <ng-template #placeholder>
            <button type="button" class="floatAvatarBtn btn btn-link btn-icon" (click)="onFileOpen('edit')">
              <clr-icon shape="pencil"></clr-icon>
            </button>
          </ng-template>
        </div>
      </div>
      <div class="clr-col-6">
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_username" class="clr-input combobox" placeholder="Benutzername" name="_username" #_username />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_pw" type="password" class="clr-input combobox" placeholder="Passwort" name="_pw" #_pw />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_alias" class="clr-input combobox" placeholder="Alias" name="_alias" #_alias />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="_rechte" class="clr-select" style="width: 250px" #_rechte>
                <option value="1">lesen</option>
                <option value="2">bearbeiten</option>
                <option value="3">ändern</option>
                <option value="4">Störungsbearbeiter</option>
                <option value="5">Inspekteur</option>
                <option value="6">Administrator</option>
              </select>
            </div>
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_vname" class="clr-input combobox" placeholder="Vorname" name="_vname" #_vname />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_name" class="clr-input combobox" placeholder="Nachname" name="_name" #_name />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_email" class="clr-input combobox" placeholder="EMail" name="_email" #_email />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_mobil" class="clr-input combobox" placeholder="Mobilnummer" name="_mobil" #_mobil />
          </div>
        </div>
      </div>
    </div>
    <div class="clr-row nxm">
      <div class="clr-col-12 nxp">
        <div class="card">
          <h5 class="card-header">Anlagenverantwortung</h5>
          <div class="card-block">
            <div class="clr-control-container">
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio1" value="optkein" (click)="setVerantwortung('optkein')" checked="true" class="clr-radio"/>
                <label for="vertical-radio1" class="clr-control-label">kein</label>
              </div>
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio2" value="optalv" (click)="setVerantwortung('optalv')" class="clr-radio"/>
                <label for="vertical-radio2" class="clr-control-label">Anlagenverantwortlicher (ALV)</label>
              </div>
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio3" value="optebl" (click)="setVerantwortung('optebl')" class="clr-radio"/>
                <label for="vertical-radio3" class="clr-control-label">Eisenbahnbetriebsleiter (EBL)</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <br>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="updateUser($event)">OK</button>
    <button type="button" class="btn" (click)="isUserEditVisible = false">Abbrechen</button>
  </div>
</clr-modal>



<clr-modal [(clrModalOpen)]="isUserAddVisible" [clrModalClosable]="true">
  <h3 class="modal-title">Neuen Benutzer anlegen</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row nxm">
      <div class="clr-col" style="width: 20%;">
        <div class="avatar-container">
          <img src="/assets/icons/user-solid.svg" id="_image_new" alt=”avatar”/>
          <div *ngIf="file; else placeholder_new">
            <button type="button" class="floatAvatarBtn btn btn-link btn-icon" (click)="onFileClear('new')">
              <clr-icon shape="times"></clr-icon>
            </button>
          </div>
          <ng-template #placeholder_new>
            <button type="button" class="floatAvatarBtn btn btn-link btn-icon" (click)="onFileOpen('new')">
              <clr-icon shape="pencil"></clr-icon>
            </button>
          </ng-template>
        </div>
      </div>

      <div class="clr-col-6">
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_username_new" class="clr-input combobox" placeholder="Benutzername" name="_username" #_username_new />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_pw_new" type="password" class="clr-input combobox" placeholder="Passwort" name="_pw" #_pw_new />
          </div>
        </div>
        <br>

        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_alias_new" class="clr-input combobox" placeholder="Alias" name="_alias" #_alias_new />
          </div>
        </div>
        <br>

        <div class="comboboxcontainer">
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="_rechte_new" class="clr-select" style="width: 250px" #_rechte_new>
                <option value="1">lesen</option>
                <option value="2">bearbeiten</option>
                <option value="3">ändern</option>
                <option value="4">Störungsbearbeiter</option>
                <option value="5">Inspekteur</option>
                <option value="6">Administrator</option>
              </select>
            </div>
          </div>
        </div>
        <br>

        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_vname_new" class="clr-input combobox" placeholder="Vorname" name="_vname" #_vname_new />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_name_new" class="clr-input combobox" placeholder="Nachname" name="_name" #_name_new />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_email_new" class="clr-input combobox" placeholder="EMail" name="_email" #_email_new />
          </div>
        </div>
        <br>
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_mobil_new" class="clr-input combobox" placeholder="Mobilnummer" name="_mobil" #_mobil_new />
          </div>
        </div>
      </div>
    </div>

    <div class="clr-row nxm">
      <div class="clr-col-12 nxp">
        <div class="card">
          <h5 class="card-header">Anlagenverantwortung</h5>
          <div class="card-block">
            <div class="clr-control-container">
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio4" value="optkein" (click)="setVerantwortung_new('optkein')" checked="true" class="clr-radio"/>
                <label for="vertical-radio1" class="clr-control-label">kein</label>
              </div>
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio5" value="optalv" (click)="setVerantwortung_new('optalv')" class="clr-radio"/>
                <label for="vertical-radio2" class="clr-control-label">Anlagenverantwortlicher (ALV)</label>
              </div>
              <div class="clr-radio-wrapper">
                <input type="radio" id="vertical-radio6" value="optebl" (click)="setVerantwortung_new('optebl')" class="clr-radio"/>
                <label for="vertical-radio3" class="clr-control-label">Eisenbahnbetriebsleiter (EBL)</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <br>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addUser($event)">OK</button>
    <button type="button" class="btn" (click)="isUserAddVisible = false">Abbrechen</button>
  </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="isGroupAddVisible" [clrModalClosable]="true">
  <h3 class="modal-title">Neuen Gruppe anlegen</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row nxm">
      <div class="clr-col-12 nxp">
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_group_name_new" class="clr-input combobox" placeholder="Bezeichnung" name="_alias" #_group_name_new />
          </div>
        </div>
        <br>

        <div class="comboboxcontainer">
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="_group_rechte_new" class="clr-select" style="width: 250px" #_group_rechte_new>
                <option value="1">lesen</option>
                <option value="2">lesen und schreiben</option>
              </select>
            </div>
          </div>
        </div>
        <br>

      </div>
    </div>
  </form>
  <br>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addGruppe()">OK</button>
    <button type="button" class="btn" (click)="isGroupAddVisible = false">Abbrechen</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isGroupEditVisible" [clrModalClosable]="true">
  <h3 class="modal-title">Gruppe bearbeiten</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row nxm">
      <div class="clr-col-12 nxp">
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_group_name" class="clr-input combobox" placeholder="Bezeichnung"  #_group_name />
          </div>
        </div>
        <br>

        <div class="comboboxcontainer">
          <div class="clr-control-container">
            <div class="clr-select-wrapper">
              <select id="_group_rechte" class="clr-select" style="width: 250px" #_group_rechte>
                <option value="1">lesen</option>
                <option value="2">lesen und schreiben</option>
              </select>
            </div>
          </div>
        </div>
        <br>

      </div>
    </div>
  </form>
  <br>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="editGruppe()">OK</button>
    <button type="button" class="btn" (click)="isGroupEditVisible = false">Abbrechen</button>
  </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="isAufGroupAddVisible" [clrModalClosable]="true">
  <h3 class="modal-title">Neuen Auftragsgruppe anlegen</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row nxm">
      <div class="clr-col-12 nxp">
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_aufgroup_name_new" class="clr-input combobox" placeholder="Bezeichnung" #_aufgroup_name_new />
          </div>
        </div>
        <br>

        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_aufgroup_kost_new" class="clr-input combobox" placeholder="Kostenstelle" name="_alias" #_aufgroup_kost_new />
          </div>
        </div>
        <br>
      </div>
    </div>
  </form>
  <br>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addAufGruppe()">OK</button>
    <button type="button" class="btn" (click)="isAufGroupAddVisible = false">Abbrechen</button>
  </div>
</clr-modal>


<clr-modal [(clrModalOpen)]="isAufGroupEditVisible" [clrModalClosable]="true">
  <h3 class="modal-title">Auftragsgruppe bearbeiten</h3>
<div class="modal-body">
  <form clrForm clrLayout="vertical">
    <div class="clr-row nxm">
      <div class="clr-col-12 nxp">
        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_aufgroup_name" class="clr-input combobox" placeholder="Bezeichnung" #_aufgroup_name />
          </div>
        </div>
        <br>

        <div class="comboboxcontainer">
          <div class="clr-control-container" style="width: 250px">
            <input id="_aufgroup_kost" class="clr-input combobox" placeholder="Kostenstelle" name="_alias" #_aufgroup_kost />
          </div>
        </div>
        <br>
      </div>
    </div>
  </form>
  <br>
</div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="editAufGruppe()">OK</button>
    <button type="button" class="btn" (click)="isAufGroupEditVisible = false">Abbrechen</button>
  </div>
</clr-modal>
