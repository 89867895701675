import { CommonModule, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrDatagrid, ClrForm } from '@clr/angular';
import { AngularDeviceInformationService } from 'angular-device-information';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import SignaturePad from "signature_pad";
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { AuthService } from "src/app/services/Auth/auth.service";
import { CameramodalComponent } from '../../_modals/cameramodal/cameramodal.component';
import { HilfeBeschreibungModalComponent } from '../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component';
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';



export interface FormulareRow {
  ID: number;
  Typ: string;
  FeldName: string;
  AuswahlOption: string;
  Bezeichnung: string;
}

@Component({
  selector: 'app-fatpruefungsdialog',
  templateUrl: './fatpruefungsdialog.component.html',
  styleUrls: ['./fatpruefungsdialog.component.scss'],
  imports: [ CommonModule, ClarityModule, CameramodalComponent, PreviewThumbnailsComponent, HilfeBeschreibungModalComponent, MrTranslatePipe,
    FormsModule, ReactiveFormsModule, HideIDColumnsPipe,BackendLocaleDatePipe],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})

export class FatpruefungsdialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  openModal = false;

  dataRefresh = new EventEmitter();
  set data(dataIn: any) {

    this.ostammid = dataIn.id;

    if (dataIn.lpid != undefined){
      this.opruefid = dataIn.lpid;
    }

    this.getFatFieldsValueByID();

    this.dataRefresh = dataIn.dataRefresh;
  }

  onModalClose() {
    this.openModal = false;

    let val = localStorage.getItem('foto');

    if (val){

      let r = val.toString() as String;
      var splitted = r.split(',');

      let img = {
        src: val,
        file: null,
        date: this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS'),
        base64: splitted[1],
      };

      if (this.typ == undefined){
        this.previewThumbnails.push(img);
      }
      else
      {
        this.selectedmessrow = this.typ;
        this.previewMessThumbnails.push(img);
        this.selectedmessrow.bilder = this.previewMessThumbnails;
      }

      localStorage.removeItem('foto');

    }
  }

  objekteGroup = new UntypedFormGroup({
    objektName: new UntypedFormControl("", [Validators.required]),
    vorlageName: new UntypedFormControl("", [Validators.required]),
    //data: null
  });

  visualGroup = new UntypedFormGroup({
    objektName: new UntypedFormControl("", [Validators.required]),
    vorlageName: new UntypedFormControl("", [Validators.required]),
    //data: null
  });

  os: any;
  selectedmessrow:any;
  iswebcam: boolean = false;
  locked: boolean = true;
  ostammid: number = 0;
  opruefid: number = 0;

  //clrtab: any[] = [];
  clrtab: {
    titel: string | null;
    zindex: number | 0,
    openstate: boolean | false,
    items: any[] | [];
  }[] = [];


  //tabellen2: any;
  //tabellen3: any;
  //tabellen4: any;
  //combinedDataPage2: any[] = [];
  //combinedDataPage3: any[] = [];
  //combinedDataPage4: any[] = [];
  showsignaturpad: boolean = false;
  modaltitel: string;
  signaturtype: any;

  resultForm = new UntypedFormGroup({
    datum: new UntypedFormControl(BackendLocaleDatePipe.now),
    pruefername: new UntypedFormControl(""),
    ort: new UntypedFormControl(""),
    kundename: new UntypedFormControl(""),
    pruefersignatur: new UntypedFormControl(""),
    kundesignatur: new UntypedFormControl(""),
    bemerkung: new UntypedFormControl(""),
  });

  sig: SignaturePad;
  structures: any;
  formulare: any;
  formulareOriginal: any;
  tabellen: any;
  tabellenOriginal: any;
  spezifikationsdaten = { columns: [], mapping: {} };
  auswahlOptions = ['Stammdaten', 'Spezifikationsdaten', 'Herstellerdaten'];
  firstColumnId = 'Typ';
  secondColumnId = 'FeldName';
  selected: any = [];
  previewThumbnails: any[] = [];
  previewMessThumbnails: any[] = [];

  kundesignatur: any[] = [];
  pruefersignatur: any[] = [];

  showBildModal: boolean = false;
  bildData: any;
  username: string = "";
  vorlageid: number;

  formReflectData: {
    table: string | null;
    fieldname: string | null;
    fieldType: string | null;
    fieldLength: string | null;
    subheading: string | null;
    value: string | null;
  }[] = [];

  vorlagename: string = 'Vorlage';

  skizzen: any;
  curSkizzen: any = [];
  bemerkungmessung: string = '';

  opruef: any;
  omess: any;
  messbilder: any;


  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild(ClrForm) form: ClrForm;
  @ViewChild("canvas", { static: true }) canvas: ElementRef;
  @Output() modalOpen = new EventEmitter<boolean>();

  @ViewChild(HilfeBeschreibungModalComponent, { static: false })
    protected hilfeModal: HilfeBeschreibungModalComponent
  ;

  public showWebcam = true;

  triggerLock() {
    this.locked = !this.locked;
  }

  protected openHelper(tabelle) {
    this.hilfeModal.item = { ...tabelle };
  }

  onMessFileChange(event: any, row: any){
    let files: File[] = event.target.files;
    this.selectedmessrow = row;

    for (const file of files) {
      if (file.type == "image/jpeg" ) {
        let fileReader = new FileReader();
        fileReader.onload = (e: any) => {

          let r = e.target.result.toString() as String;
          var splitted = r.split(',');

          this.previewMessThumbnails.push({
            src: this._sanitizer.bypassSecurityTrustUrl(e.target.result.toString()),
            file: file,
            date: this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS'),
            base64: splitted[1],
          });


          this.selectedmessrow.bilder = this.previewMessThumbnails;

        };
        fileReader.readAsDataURL(file);
      }
    }
  }

  selectionmMessrowChanged(value: any) {

  if (value.bilder)
    this.previewMessThumbnails = value.bilder;
  else
    this.previewMessThumbnails = [];
  }

  _currentPage: number = 1;
  set currentPage(newPage: number) {
    this._currentPage = newPage;
  }
  get currentPage() {
    return this._currentPage;
  }

  set _modalOpen(newVal: boolean) {
    this.modalOpen.emit(newVal);
  }

  // loading: boolean = true;

  activeTab: any = {
    formulare: false,
    tabellen: false
  };

  isVorlageAddVisible: boolean = false;
  @ViewChild('_vorlage_new') _vorlage_new: ElementRef;
  @ViewChild('_zaehlercode') _zaehlercode: ElementRef
  @ViewChild('_zaehlerbezeichnung') _zaehlerbezeichnung: ElementRef

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private authService: AuthService,
    private deviceInformationService: AngularDeviceInformationService,
    protected localeDate: BackendLocaleDatePipe
  ) {
    /*
    this.basicStore
    .getComponentStoreAsync("anlagen")
      .pipe(first((val) => val != undefined))
      .subscribe((componentStore) => {
        componentStore = JSON.parse(componentStore);
        if (componentStore && componentStore["ostammid"]) {
          this.ostammid = componentStore["ostammid"] || undefined;

          this.getFatFieldsValueByID();

        }
      })
      */
  }

  typ: any;
  bemrow: any;
  openModalBem: boolean = false;
  @ViewChild('rowBem') rowBem: ElementRef;

  openBem(row: any){
    this.bemrow = row;
    if (this.bemrow.bemerkung)
      this.rowBem.nativeElement.value = this.bemrow.bemerkung;
    else
    this.rowBem.nativeElement.value = '';
    this.openModalBem = true;
  }
  setBem(){
    this.selectedmessrow = this.bemrow;
    this.selectedmessrow.bemerkung = this.rowBem.nativeElement.value;
    this.rowBem.nativeElement.value = '';
    this.openModalBem = false;
  }

  openCamera(typ: any) {
    this.typ = typ;
    this.openModal = true;
  }

  getFatFieldsValueByID() {
    this.apiservice
      .getFatFieldValueByID(this.ostammid, this.opruefid)
      .pipe(first())
      .subscribe((result: any) => {
        this.omess = [];
        this.opruef = [];
        this.messbilder = [];

        this.spezifikationsdaten.columns = result.spezifikationsdaten.columns.map(col => {
        return {
          display: col,
          value: Object.keys(result.spezifikationsdaten.mapping).find(key => result.spezifikationsdaten.mapping[key] === col)
          };
        });
        this.spezifikationsdaten.mapping = result.spezifikationsdaten.mapping;

        this.structures = result.structures.rows;
        this.vorlageid = result.vorlageid;
        this.formulare = result.vorlage;

        this.vorlagename = result.vorlagename;
        this.skizzen= result.skizzen;

        // this.formulare['rows'].forEach(row => {
        //   if (row.AuswahlOption === 'Spezifikationsdaten') {
        //     row.Bezeichnung = this.spezifikationsdaten.mapping[row.Bezeichnung] || row.Bezeichnung;
        //   }
        // });
        this.formulareOriginal = JSON.parse(JSON.stringify(result.vorlage['rows']));

        this.formReflectData = this.formulare.rows.map((row: FormulareRow) => {
          const reflectData = {
            table: row['Tabelle'],
            fieldname: row['Bezeichnung'],
            subheading: null,
            fieldType: row['Bezeichnung'],
            fieldLength: null,
            value: null
          };
          this.updateFormReflectData(row, reflectData);
          return reflectData;
        });

        this.formReflectData.forEach(data => {
          // ? Nirgendwo benutzt
          // this.objekteGroup.addControl(
          //   data.fieldname,
          //   new UntypedFormControl(data, Validators.maxLength(3))
          // );
          switch (data.table) {
            case 'Stammdaten':
              data.value = result.ostamm[data.fieldname];
              break;
            case 'Spezifikationsdaten':
              data.value = result.ospez[data.fieldname];
              break;
            case 'Herstellerdaten':
              data.value = result.oherst[data.fieldname];
              break;
          }
        })



        if (result.opruef != undefined){
          this.opruef = result.opruef.rows;
          this.opruef[0].P_MUser
          this.resultForm.controls.pruefername.setValue(this.opruef[0].P_MUser);
          this.resultForm.controls.datum.setValue(this.opruef[0].P_MDatum);
          this.resultForm.controls.ort.setValue(this.opruef[0].P_KeinePrfGrund);
          this.resultForm.controls.kundename.setValue(this.opruef[0].P_Kunde);
          this.resultForm.controls.bemerkung.setValue(this.opruef[0].P_Bemerkung);
          let sigP = this.opruef[0].P_Pruefer_U;
          let sigK = this.opruef[0].P_Kunde_U;
          //pruefersignatur
          //kundesignatur

        }

        if (result.omess != undefined){
          this.omess = result.omess.rows;
        }

        if (result.bilder != undefined){
          this.messbilder = result.bilder.rows;

          this.previewThumbnails = [];

          let bilderL = this.messbilder.filter(element => element.typ == 0);
          bilderL.forEach((bild) => {
            this.previewThumbnails.push({
              src: this._sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + bild.base64),
              file: bild.bildname,
              date: this.datePipe.transform(bild.datum,'dd.MM.yyyy HH.mm.ss.SSS'),
              base64: bild.base64,
            });
          });

        }

        let groupnames = result.groupnames.rows;
        let tabellennamen = result.tabellennamen;
        let zindex: number = 0;

        for(let tabs of groupnames){

          let tabelle = tabellennamen.rows.filter(element => element.Code == tabs.Code);
          let combinedDataPage: any [] = [];
          let openstate = false;

          for(let table of tabelle){

            let tb={
              columns: result.tabellen.columns,
              rows: result.tabellen.rows.filter(x => x.SZAEHNID == table.ID)
            }


            if (this.omess.length != 0){
              let rowmess = this.omess.filter(x => x.SZAEHNID == table.ID)
              rowmess.forEach((rowElement) => {
                const code = rowElement.Bezeichnung;

                for(let i = 0 ; i < tb.rows.length; i++){
                  let row = tb.rows[i];
                  if (rowElement.Bezeichnung == row.Code) {
                    row.istValue = rowElement.Ist != 'non' ? rowElement.Ist: undefined;
                    row.ausfallValue = rowElement.Ausfall != '' ? rowElement.Ausfall: undefined;
                    row.bemerkung = rowElement.Bemerkung != '' ? rowElement.Bemerkung: undefined;

                    this.previewMessThumbnails = [];

                    let bilderL = this.messbilder.filter(element => element.id == rowElement.ID);
                    bilderL.forEach((bild) => {
                      this.previewMessThumbnails.push({
                        src: this._sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + bild.base64),
                        file: bild.bildname,
                        date: this.datePipe.transform(bild.datum,'dd.MM.yyyy HH.mm.ss.SSS'),
                        base64: bild.base64,
                      });
                    });

                    row.bilder = this.previewMessThumbnails.length != 0 ? this.previewMessThumbnails: undefined;
                    break;
                  }
                }
              });

            }



            this.curSkizzen = [];
            Object.keys(this.skizzen).forEach((typid) => {
              if(typid == table.ID.toString()){
                this.curSkizzen = this.skizzen[typid];
              }
            });

            let items ={
              szaehnid: table.ID,
              iscompleted: false,
              hasfaults: false,
              title: table.Bezeichnung,
              tableData: tb,
              SourceType: table.SourceType,
              images: this.curSkizzen,
              HelpBildBase64: table.HelpBildBase64,
              HelpBeschreibung: table.HelpBeschreibung,
              FileTyp: table.FileTyp,
              bemerkung: table.Bemerkung,
            }

            combinedDataPage.push(items);

            if (table.SourceType == 1){
              openstate = true;
              tb.rows.map((elem) => {
                this.visualGroup.addControl(elem.Code, new UntypedFormControl(elem,Validators.maxLength(3)));
              });
            }


          }

          const clrtab = {
            titel: tabs.Code,
            zindex: zindex,
            openstate: openstate,
            items: combinedDataPage,
          };

          this.clrtab.push(clrtab);

          zindex++;
        }
        /*
        this.tabellen2 = tabellennamen.rows.filter(element => element.Code == 'AIGUILLAGE');
        for(let tab of this.tabellen2){

          let tb={
            columns: result.tabellen.columns,
            rows: result.tabellen.rows.filter(x => x.SZAEHNID == tab.ID)
          }
          let items ={
            szaehnid: tab.ID,
            iscompleted: false,
            hasfaults: false,
            title: tab.Bezeichnung,
            tableData: tb,
            image: tab.Bild64 ? this._sanitizer.bypassSecurityTrustUrl('data:image/png;base64,' + tab.Bild64) : null,
          }
          this.combinedDataPage2.push(items);
        }

        this.tabellen3 = tabellennamen.rows.filter(element => element.Code == 'INTERMEDIATE');
        for(let tab of this.tabellen3){

          let tb={
            columns: result.tabellen.columns,
            rows: result.tabellen.rows.filter(x => x.SZAEHNID == tab.ID)
          }
          let items ={
            szaehnid: tab.ID,
            iscompleted: false,
            hasfaults: false,
            title: tab.Bezeichnung,
            tableData: tb,
            image: tab.Bild64
          }
          this.combinedDataPage3.push(items);
        }

        this.tabellen4 = tabellennamen.rows.filter(element => element.Code == 'VISUEL');
        for(let tab of this.tabellen4){

          let tb={
            szaehnid: tab.ID,
            iscompleted: false,
            hasfaults: false,
            columns: result.tabellen.columns,
            rows: result.tabellen.rows.filter(x => x.SZAEHNID == tab.ID)
          }
          this.combinedDataPage4.push(tb);
        }

        this.combinedDataPage4[0].rows.map((elem) => {
          this.visualGroup.addControl(elem.Code, new UntypedFormControl(elem,Validators.maxLength(3)));
        });
        */
        this.tabellenOriginal = result.tabellen.rows;

      });
  }

  panelChange(event, tabelle){
    if (event) {
      // tabelle.iscompleted = true;
      // tabelle.hasfaults = true;
      // this.tabellen.rows =  this.tabellenOriginal.filter(x => x.SZAEHNID == tabelle.ID);
      this.checkCompletedFaults(tabelle);
      console.log('panel is opening');
    }
  }

  checkCompletedFaults(tabelle: any){
    tabelle.hasfaults = false;
    tabelle.iscompleted = false;

    for (let row of tabelle.tableData.rows){

      if (row.ausfallValue != undefined){
        if (row.ausfallValue = 'SR0'){
          tabelle.hasfaults = true;
        }
      }
      if (row.istValue != row.Soll){
        if (!row.istValue || isNaN(row.istValue))
        {
          row.ausfallValue = null;
          return;
        }
      }
    }

    tabelle.iscompleted = true;
  }

  onFileChange(event: any) {
    let files: File[] = event.target.files;

    for (const file of files) {
      if (file.type == "image/jpeg" ) {
        let fileReader = new FileReader();
        fileReader.onload = (e: any) => {

          let r = e.target.result.toString() as String;
          var splitted = r.split(',');

          this.previewThumbnails.push({
            src: this._sanitizer.bypassSecurityTrustUrl(e.target.result.toString()),
            file: file,
            date: this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS'),
            base64: splitted[1],
          });
        };
        fileReader.readAsDataURL(file);
      }
    }
  }



  updatesignatur(){
    let signaturpng = this.sig.toDataURL()
    if (this.signaturtype == 'pruefer' )
    {
      let r = signaturpng.toString() as String;
      var splitted = r.split(',');

      this.pruefersignatur.push({
        src: this._sanitizer.bypassSecurityTrustUrl(signaturpng),
        file: "",
        date: this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS'),
        base64: splitted[1],
      });

    }
    else
    {
      let r = signaturpng.toString() as String;
      var splitted = r.split(',');

      this.kundesignatur.push({
        src: this._sanitizer.bypassSecurityTrustUrl(signaturpng),
        file: "",
        date: this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS'),
        base64: splitted[1],
      });

    }
    this.showsignaturpad = true;
  }

  addSignatur(value: any){
    this.sig = new SignaturePad(this.canvas.nativeElement);
    this.signaturtype = value;
    this.modaltitel="Unterschrift"
    this.showsignaturpad = true;
  }

  deletesignatur(){
    this.sig.clear();
  }

  handleChange(val: number, row: any, tabelle: any) {
    if (row.bilder != undefined)
      this.previewMessThumbnails = row.bilder;
    else
      this.previewMessThumbnails = [];

    tabelle.hasfaults = false;
    tabelle.iscompleted = false;

    row.ausfallValue = (val == 0 ? null: "SR0");

    this.checkCompletedFaults(tabelle);

  }

  submitDemo(typ: any) {

  }
  showPreviewSkizze(image: any){
    this.bildData = image;
    this.showBildModal = true;
  }

  showPreviewImage(image: any){
    this.bildData = image.src;
    this.showBildModal = true;
  }

  tabChange() {
    this.selected = [];
    this.currentPage = 1;
    this.previewMessThumbnails = [];
  }

  validateRow(row: any, tabelle: any) {
    tabelle.hasfaults = false;
    tabelle.iscompleted = false;

    if (!row.istValue || isNaN(row.istValue)) {
        row.ausfallValue = null;
        tabelle.iscompleted = false;
        return;
    }

    const ist = parseFloat(row.istValue);

    let tmin = 1000000;
    let tmax = 1000000;

    if (row['Tol -'] != undefined)
      tmin=row['Tol -'];

    if (row['Tol +'] != undefined)
      tmax=row['Tol +'];

    const maxAllowed = row.Soll + tmax;
    const minAllowed = row.Soll - tmin;

    if (ist < minAllowed || ist > maxAllowed) {
        row.ausfallValue = 'SR0';
    } else {
        row.ausfallValue = null;
    }

    this.checkCompletedFaults(tabelle);

  }

  updateFormReflectData(row: any, reflectData: any) {
    if (row[this.firstColumnId] === 'Überschrift') {
      reflectData.mainTitle = null;
      reflectData.title = row['FeldName'];
      reflectData.subheading = null;
    } else if (row[this.firstColumnId] === 'Normales Feld') {
      reflectData.mainTitle = null;
      reflectData.title = null;
      reflectData.subheading = row['FeldName'];
    } else if (row[this.firstColumnId] === 'Titel') {
      reflectData.mainTitle = row['FeldName'];
      reflectData.title = null;
      reflectData.subheading = null;
    }
    let columnName = row['Bezeichnung'];

    if (columnName != null) {
      let stable = "ostamm";

      if (row['Tabelle'] === 'Spezifikationsdaten')
        stable = "ospez";

      if (row['Tabelle'] === 'Herstellerdaten')
        stable = "oherst";

      const rowToDelete = this.structures.find((row: any) => row.TABLE_NAME === stable && row.COLUMN_NAME === columnName);

      if (rowToDelete != undefined) {
        let formFieldType;
        switch (rowToDelete.DATA_TYPE.toUpperCase()) {
          case 'VARCHAR':
          case 'TEXT':
            formFieldType = 'text';
            break;
          case 'DATETIME':
            formFieldType = 'date';
            break;
          case 'DECIMAL':
          case 'INT':
          case 'FLOAT':
            formFieldType = 'dropdown';
            break;
          case 'BIT':
            formFieldType = 'checkbox';
            break;
        }
        reflectData.fieldType = formFieldType;
      }
    }
  }


  sendForFormulare() {

    let allcompleted = true;
    for (let tab of this.clrtab){
      for (let item of tab.items){
        if (item.iscompleted == false){
          allcompleted = false;
          break;
        }
      }
    }


/*
    for (let item of this.clrtab.items){
      if (item.iscompleted == false){
        allcompleted = false;
        break;
      }
    }
    if (allcompleted == true){
      for (let items of this.combinedDataPage3){
        if (items.iscompleted == false){
          allcompleted = false;
          break;
        }
      }
    }
    if (allcompleted == true){
      for (let items of this.combinedDataPage4){
        for (let row of items.rows){
          if (!row.istValue || isNaN(row.istValue)){
            allcompleted = false;
            break;
          }
        }
      }
    }
*/

    if (allcompleted == false){
      if (!confirm(this.mrTranslate.transform("Die Messungen sind unvollständig, möchten Sie sie trotzdem speichern?"))) {
        return;
      }
    }

    let allmessrows: any[] = [];

    /*
      for (let item of this.combinedDataPage2){
        for (let row of item.tableData.rows){
          allmessrows.push(row);
        }
      }

      for (let item of this.combinedDataPage3){
        for (let row of item.tableData.rows){
          allmessrows.push(row);
        }
      }

      for (let item of this.combinedDataPage4){
        for (let row of item.rows){
          allmessrows.push(row);
        }
      }
    */

    for (let tab of this.clrtab){
      for (let item of tab.items){
        for (let row of item.tableData.rows){
          allmessrows.push(row);
        }
      }
    }


    let pbild: any[] = [];
    for (let bi of this.previewThumbnails){
      let bild={
        date: bi.date,
        base64: bi.base64
        }
        pbild.push(bild);
    }

    let vals = this.resultForm.value;

    if (this.pruefersignatur.length > 0){
      let jArray = this.pruefersignatur.map( (o)=> o.base64 );
      vals.pruefersignatur = JSON.parse(JSON.stringify(jArray));
    }
    else vals.pruefersignatur='';

    if (this.kundesignatur.length > 0){
      let jArray = this.kundesignatur.map( (o)=> o.base64 );
      vals.kundesignatur = JSON.parse(JSON.stringify(jArray));
    }
    else vals.kundesignatur='';

    let info = {
      pdatum: vals.datum,
      pname: vals.pruefername,
      port: vals.ort,
      pkname: vals.kundename,
      pbem: vals.bemerkung,
      punter: vals.pruefersignatur,
      pkunter: vals.kundesignatur,
    }

    this.apiservice.addPruefungFAT(this.formReflectData, allmessrows, pbild, info, this.ostammid, this.opruefid, this.vorlageid).pipe(first()).subscribe(val => {
      this.opruefid = parseInt(val.toString());
      if (this.opruefid > 1){
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        this.dataRefresh.emit();
      }
    });
  }


  ngOnInit(){

    this.os = this.deviceInformationService.getDeviceInfo().os
    this.username = this.authService.getToken().username;
    this.resultForm.controls.pruefername.setValue(this.username);

  }

  public close() {
    this.closedialog.next(true);
  }
}
