import { ColumnTypes } from "./types.js";
const columnTypeMap = {
  0x01: ColumnTypes.Boolean,
  0x02: ColumnTypes.Byte,
  0x03: ColumnTypes.Integer,
  0x04: ColumnTypes.Long,
  0x05: ColumnTypes.Currency,
  0x06: ColumnTypes.Float,
  0x07: ColumnTypes.Double,
  0x08: ColumnTypes.DateTime,
  0x09: ColumnTypes.Binary,
  0x0a: ColumnTypes.Text,
  0x0b: ColumnTypes.Long,
  0x0c: ColumnTypes.Memo,
  0x0f: ColumnTypes.RepID,
  0x10: ColumnTypes.Numeric,
  0x12: ColumnTypes.Complex,
  0x13: ColumnTypes.BigInt,
  0x14: ColumnTypes.DateTimeExtended
};
/**
 * @see https://github.com/brianb/mdbtools/blob/d6f5745d949f37db969d5f424e69b54f0da60b9b/include/mdbtools.h#L88-L104
 * @see https://github.com/brianb/mdbtools/blob/d6f5745d949f37db969d5f424e69b54f0da60b9b/HACKING#L498-L515
 */
export function getColumnType(typeValue) {
  const type = columnTypeMap[typeValue];
  if (type === undefined) {
    throw new Error("Unsupported column type");
  }
  return type;
}
/**
 * @see https://github.com/brianb/mdbtools/blob/d6f5745d949f37db969d5f424e69b54f0da60b9b/HACKING#L481-L491
 */
export function parseColumnFlags(flags) {
  return {
    fixedLength: !!(flags & 0x01),
    nullable: !!(flags & 0x02),
    autoLong: !!(flags & 0x04),
    autoUUID: !!(flags & 0x40)
  };
}