import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { MRproResponse } from "src/app/types/api";
import { NgIf, NgFor } from "@angular/common";
import { ClrModalModule, ClrCommonFormsModule, ClrRadioModule, ClrInputModule, ClrSelectModule, ClrTextareaModule } from "@clr/angular";

type openMode = "add" | "edit" | "addLV" | "editLV";

@Component({
    selector: "app-kosten-fremdleistung-modal",
    templateUrl: "./kosten-fremdleistung-modal.component.html",
    styleUrls: ["./kosten-fremdleistung-modal.component.scss"],
    imports: [
        ClrModalModule,
        NgIf,
        FormsModule,
        ClrCommonFormsModule,
        ReactiveFormsModule,
        ClrRadioModule,
        ClrInputModule,
        ClrSelectModule,
        NgFor,
        ClrTextareaModule,
        MrTranslatePipe,
    ],
    standalone: true
})
export class KostenFremdleistungModalComponent implements OnInit {
  @Input() set open(newOpen: boolean | openMode) {
    console.log(newOpen);
    if (newOpen === "edit") {
      this.fremdleistungForm.reset();
      this.fremdleistungForm.patchValue(this.fremdleistung);
    } else if (newOpen === "add") {
      this.fremdleistungForm.reset();
      if (
        !this.titleSelected() &&
        !(this.fremdleistung?.children?.length > 0) &&
        this.fremdleistung?.EP !== null &&
        this.fremdleistung?.EP !== ""
      ) {
        this.fremdleistungForm.controls["addType"].setValue("neighbor");
        this.fremdleistungForm.controls["addType"].disable();
      } else if (this.titleSelected()) {
        this.fremdleistungForm.controls["addType"].setValue("child");
        this.fremdleistungForm.controls["addType"].disable();
      } else {
        this.fremdleistungForm.controls["addType"].setValue("child");
        this.fremdleistungForm.controls["addType"].enable();
      }
    } else if (newOpen === "addLV") {
      this.fremdleistungForm.controls.OZ.setValidators([]);
    } else if (newOpen === "editLV") {
      this.fremdleistungForm.reset();
      this.fremdleistungForm.patchValue(this.fremdleistung);
      this.fremdleistungForm.controls.OZ.setValidators([]);
    } else {
      this.fremdleistungForm.controls.OZ.setValidators([Validators.required]);
    }

    this._open = newOpen;
  }
  get open(): boolean | openMode {
    return this._open;
  }
  _open: boolean | openMode = false;

  titleSelected = () => this.fremdleistung?.Titel_ID === 0;

  @Input() fremdleistung: any;
  @Input() mengeneinheiten: string[];
  @Output() close: EventEmitter<any> = new EventEmitter();

  selectedFremdleistung = null;

  fremdleistungForm = new UntypedFormGroup({
    addType: new UntypedFormControl(null),
    OZ: new UntypedFormControl("", [Validators.required]),
    Kurztext: new UntypedFormControl("", [Validators.required]),
    EP: new UntypedFormControl(null),
    ME: new UntypedFormControl(""),
    Langtext: new UntypedFormControl(""),
  });

  trial = false;

  constructor(
    private apiservice: APIService,
    private mrTranslate: MrTranslatePipe,
    private toastr: ToastrService,
    private authService: AuthService
  ) {
    this.trial = this.authService.getToken().trial;
  }

  ngOnInit(): void {
    console.log(this.open);
  }

  save() {
    let formValue = { ...this.fremdleistungForm.getRawValue() };
    const addNeighbor = formValue.addType === "neighbor";

    formValue.addType = undefined;

    console.log("SELECTED", this.fremdleistung);

    formValue = {
      ...formValue,
      EP: formValue.EP ? Number(formValue.EP) : null,
      Titel_ID: this.titleSelected()
        ? this.fremdleistung?.ID
        : this.fremdleistung?.Titel_ID,
    };

    if (this.open === "edit") {
      this.apiservice
        .updateKostenFremdleistung(this.fremdleistung.ID, formValue)
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status === "success") {
            const text = this.mrTranslate.transform(
              "Fremdleistung aktualisiert"
            );
            this.toastr.success(text);
            this.close.emit(true);
          } else {
            const text = this.mrTranslate.transform(
              "Fremdleistung konnte nicht aktualisiert werden"
            );
            this.toastr.error(text + " " + res?.message);
          }
        });
    } else if (this.open === "add") {
      let group;

      if (this.titleSelected()) {
        group = 0;
      } else {
        group = addNeighbor
          ? this.fremdleistung.Hauptgruppe
          : this.fremdleistung.ID;
      }

      formValue = {
        ...formValue,
        Hauptgruppe: group,
      };

      this.apiservice
        .insertKostenFremdleistung(formValue)
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status === "success") {
            const text = this.mrTranslate.transform("Fremdleistung angelegt");
            this.toastr.success(text);
            this.close.emit(true);
          } else {
            const text = this.mrTranslate.transform(
              "Fremdleistung konnte nicht angelegt werden"
            );
            this.toastr.error(text + " " + res?.message);
          }
        });
    } else if (this.open === "addLV") {
      const titel = this.fremdleistungForm.controls.Kurztext.value;
      if (!titel || titel.trim() === "") {
        this.toastr.error(
          this.mrTranslate.transform("Bitte geben Sie eine Titel an.")
        );
        return;
      }
      this.apiservice
        .insertKostenFremdleistungTitel(titel)
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status === "success") {
            const text = this.mrTranslate.transform(
              "Leistungsverzeichnis angelegt"
            );
            this.toastr.success(text);
            this.close.emit(true);
          } else {
            const text = this.mrTranslate.transform(
              "Leistungsverzeichnis konnte nicht angelegt werden"
            );
            this.toastr.error(text + " " + res?.message);
          }
        });
    } else if (this.open === "editLV") {
      const titel = this.fremdleistungForm.controls.Kurztext.value;
      if (!titel || titel.trim() === "") {
        this.toastr.error(
          this.mrTranslate.transform("Bitte geben Sie eine Titel an.")
        );
        return;
      }
      this.apiservice
        .updateKostenFremdleistungTitel(this.fremdleistung.ID, titel)
        .pipe(first())
        .subscribe((res: any) => {
          if (res?.status === "success") {
            const text = this.mrTranslate.transform(
              "Leistungsverzeichnis gespeichert"
            );
            this.toastr.success(text);
            this.close.emit(true);
          } else {
            const text = this.mrTranslate.transform(
              "Leistungsverzeichnis konnte nicht gespeichert werden"
            );
            this.toastr.error(text + " " + res?.message);
          }
        });
    }
  }
}
