<clr-modal [(clrModalOpen)]="parentChildConnection" [clrModalSize]="'lg'" [clrModalClosable]="true">

  <h3 class="modal-title">{{ 'Kamera' | mrTranslate }}</h3>
  <div class="modal-body">
      <div class="video-contatiner">
          <canvas #canvas></canvas>
      </div>
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="swapCamera()">{{ 'Kamera wechseln' | mrTranslate }}</button>
      <button type="button" class="btn btn-outline" (click)="takePicture()">{{ 'Bild' | mrTranslate }}</button>
      <button type="button" class="btn btn-outline" (click)="parentChildConnection = false">{{ 'Beenden' | mrTranslate }}</button>
  </div>
</clr-modal>
