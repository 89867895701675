<clr-modal [(clrModalOpen)]="open" [clrModalSize]="'md'" (clrModalOpenChange)="close.emit()">
    <h3 class="modal-title">{{ (mode | titlecase) + " bearbeiten" | mrTranslate}}</h3>
    <div class="modal-body" id="kosten-edit-modal-body">
        <div class="clr-row">
            <div class="clr-col" style="width: 30%;">
                <img for="kostenImage" [src]="bild ? bild : 'assets/icons/image-placeholder.png'"
                    style="max-width: 100%; padding-top: 0.8em;" [ngStyle]="{'opacity': bild ? '1' : '0.6'}">
                <div class="clr-file-wrapper">
                    <label for="kostenImage" class="clr-control-label"
                        style="padding-left: 0px; margin-top:0.8em;"><span class="btn btn-sm">{{"Foto ändern" |
                            mrTranslate}}</span></label>
                    <input type="file" id="kostenImage" name="kostenImage" placeholder="" class="button-input clr-file"
                        (change)="onImageSelect($event.target)">
                </div>
            </div>
            <div class="clr-col">
                <ng-container [ngSwitch]="mode">
                    <ng-container *ngSwitchCase="'personal'">
                        <form clrForm [formGroup]="personalForm">
                            <clr-input-container>
                                <label>{{"Personalnummer" | mrTranslate}}</label>
                                <input clrInput formControlName="personalnummer" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Kürzel" | mrTranslate}}</label>
                                <input clrInput formControlName="kuerzel" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Vorname" | mrTranslate}} *</label>
                                <input clrInput formControlName="vorname" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Nachname" | mrTranslate}} *</label>
                                <input clrInput formControlName="nachname" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Email" | mrTranslate}}</label>
                                <input clrInput formControlName="email" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Qualifikation" | mrTranslate}}</label>
                                <input clrInput formControlName="qualifikation" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Wochenarbeitszeit" | mrTranslate}} *</label>
                                <input clrInput formControlName="wochenarbeitszeit" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Verrechnungssatz" | mrTranslate}} *</label>
                                <input clrInput formControlName="verrechnungssatz" />
                            </clr-input-container>
                        </form>
                    </ng-container>
                    <ng-container *ngSwitchCase="'material'">
                        <form clrForm [formGroup]="materialForm">
                            <clr-input-container>
                                <label>{{"Materialnummer" | mrTranslate}}</label>
                                <input clrInput formControlName="materialnummer" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Titel" | mrTranslate}} *</label>
                                <input clrInput formControlName="titel" />
                            </clr-input-container>
                            <clr-textarea-container>
                                <label>{{"Beschreibung" | mrTranslate}}</label>
                                <textarea clrTextarea formControlName="beschreibung" name="beschreibung"></textarea>
                            </clr-textarea-container>
                            <clr-input-container class="clr-form-control">
                                <label class="clr-col-md-4">{{'Einheit' | mrTranslate}} *</label>
                                <!-- <select class="clr-col-md-8" clrSelect formControlName="einheit">
                                    <option *ngFor="let val of mengeneinheiten" [value]="val.bezeichnung">
                                        {{val.bezeichnung}}</option>
                                </select> -->
                                <input clrInput formControlName="einheit" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Einzelpreis" | mrTranslate}} *</label>
                                <input clrInput formControlName="einzelpreis" type="number" />
                            </clr-input-container>
                        </form>
                    </ng-container>
                    <ng-container *ngSwitchCase="'maschine'">
                        <form clrForm [formGroup]="maschineForm">
                            <clr-input-container>
                                <label>{{" Maschinennummer" | mrTranslate}}</label>
                                <input clrInput formControlName="maschinennummer" />
                            </clr-input-container>
                            <clr-input-container>
                                <label>{{"Titel" | mrTranslate}} *</label>
                                <input clrInput formControlName="titel" />
                            </clr-input-container>
                            <clr-textarea-container>
                                <label>{{"Beschreibung" | mrTranslate}}</label>
                                <textarea clrTextarea formControlName="beschreibung" name="beschreibung"></textarea>
                            </clr-textarea-container>
                            <clr-input-container>
                                <label>{{"Verrechnungssatz" | mrTranslate}} *</label>
                                <input clrInput formControlName="verrechnungssatz" type="number" />
                            </clr-input-container>
                        </form>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" [disabled]="trial" class="btn btn-primary" (click)="save()">{{'Speichern' |
            mrTranslate}}</button>
    </div>

</clr-modal>