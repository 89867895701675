<clr-modal id="cameraModalWrapper" [(clrModalOpen)]="showWebcam" [clrModalSize]="'xl'" [clrModalClosable]="webcamReady">
  <h3 class="modal-title">{{ 'Kamera' | mrTranslate }}</h3>
  <div #sizeRef class="modal-body">
    <webcam *ngIf="resizeReady"
      [width]="sizePx.width" [height]="sizePx.height"
      [trigger]="triggerObservable"
      (imageCapture)="handleImage($event)"
      [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
      (cameraSwitched)="$event && webcamReady = true;"
      [imageQuality]="1"
      [videoOptions]="videoOptions"
      (initError)="handleInitError($event)">
    </webcam>
  </div>

  <div class="modal-footer">
    <button *ngIf="multipleWebcamsAvailable" type="button" class="btn btn-outline" (click)="showNextWebcam(true)">{{ 'Kamera wechseln' | mrTranslate }}</button>
    <button type="button" class="btn btn-outline" (click)="takePicture()">{{ 'Bild' | mrTranslate }}</button>
    <button type="button" [disabled]="!webcamReady" class="btn btn-outline" (click)="showWebcam = false">{{ 'Beenden' | mrTranslate }}</button>
  </div>
</clr-modal>
