import { Directive, Input, ElementRef } from '@angular/core';

// This directive controls display of checkbox button in the Clarity datagrid column header

@Directive({
  selector: '[appClrCheckboxDisplay]',
  standalone: true
})
export class ClrCheckboxDisplayDirective {

  @Input('appClrCheckboxDisplay') set display( show: boolean ) {
    const classList = this.elementRef.nativeElement.classList;
    if (!show) classList.add("clr_disabled");
    else classList.remove("clr_disabled");
  }

  constructor(private elementRef: ElementRef) {}
}