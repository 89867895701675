import { PerformanceTrackerService } from "../services/Shared/performance-tracker.service";
import { ColumnFilterComponent } from "src/app/content/_components/filter/column-filter/column-filter.component";

/**
 * It automatically records the start and end of component loading using the PerformanceTrackerService and uses this static service to print the loading time to the console in debug context.
 *
 * @example
 * ```
 * import { Lifecycled } from 'path-to-your-decorator-file';
 * import { PerformanceTrackerService } from '../services/Shared/performance-tracker.service';
 * import { TextfilterComponent } from 'src/app/content/_components/filter/textfilter/textfilter.component';
 *
 * @Lifecycled()
 * @Component({
 *   selector: 'app-example-component',
 *   templateUrl: './example-component.component.html',
 *   styleUrls: ['./example-component.component.css']
 * })
 * export class ExampleComponent implements OnInit, AfterViewInit {
 *   // ... other component properties and methods ...
 *
 *   ngOnInit(): void {
 *     // ... your existing ngOnInit logic ...
 *   }
 *
 *   ngAfterViewInit(): void {
 *     // ... your existing ngAfterViewInit logic ...
 *   }
 * }
 * ```
 *
 * In this example, the `Lifecycled` decorator is applied to an Angular component.
 * It automatically records the component loading performance using PerformanceTrackerService.
 */
export function Lifecycled() {
  return function (target: any) {
    const ngAfterViewInit = target.prototype.ngAfterViewInit ?? (() => {});

   

    target.prototype.ngAfterViewInit = function () {
      ngAfterViewInit.apply(this);

      if (this instanceof ColumnFilterComponent) {
        PerformanceTrackerService.endComponentLoad(
          `Textfilter-${this.column.id}`
        );
      }
    };
  };
}
