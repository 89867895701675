<div class="overlay-inner-wrapper">
  <div id="measurementWrapper">
    <div class="clr-row">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h3 class="mt-0" style="display: inline-block;">{{'Messungen' | mrTranslate}}</h3>
          <clr-checkbox-wrapper style="display:inline-block; margin-left: 20px;">
            <input type="checkbox" clrToggle name="options" value="false" [(ngModel)]="draganddropactive" />
            <label>{{'Diagramm-Reihenfolge ändern' | mrTranslate}}</label>
          </clr-checkbox-wrapper>
        </div>
        <div class="inline-block margin-left:50px" *ngIf="tblPruefids">
          <clr-combobox-container>
            <clr-combobox [(ngModel)]="selection" name="multiSelect" clrMulti="true" (clrOpenChange)="selChanged($event)" (clrOpenChange)="selOpenChanged($event)" (clrSelectionChange)="selChanged($event)">
              <ng-container *clrOptionSelected="let selected">{{ selected?.Datum }}
              </ng-container>
              <clr-options>
                <clr-option *clrOptionItems="let id of tblPruefids.rows; field: 'Datum'" [clrValue]="id">{{ id.Datum }}</clr-option>
              </clr-options>
            </clr-combobox>
          </clr-combobox-container>
        </div>
        <div class="inline-block float-right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
    </div>
    <div class="clr-row" dndDropzone (dndDrop)="onDrop($event)" dndEffectAllowed="move" [dndHorizontal]="false">
      <div dndPlaceholderRef class="card dndPlaceholder" [style.height.px]="placeHolderHeight">
      </div>
      <ng-container *ngFor="let gr of graph.data; let ind = index;">
        <div class="card" *ngIf="gr.yData && gr.yData.length > 0" [dndDraggable]="gr" [dndEffectAllowed]="'move'" [dndDisableIf]="!draganddropactive" (dndStart)="onDragStart($event)">
          <div class="card-header" [ngClass]="{'cursor-grab': draganddropactive}">
            <div class="clr-row">
              <div class="clr-col-6">
                <clr-icon (click)="gr.show = !gr.show" [attr.shape]="gr.show ? 'caret up' : 'caret down'" style="cursor: pointer; margin-left:-15px;  margin-right: 8px;" size="25">
                </clr-icon> {{ gr.name | mrTranslate }}
                <ng-container *ngIf="gr.key == 'Rille_L' || gr.key == 'Rille'">
                  <clr-checkbox-wrapper style="display:inline-block; margin-left: 20px;">
                    &nbsp;|&nbsp;
                    <input type="checkbox" clrToggle name="options" value="false" [(ngModel)]="combineRille" />
                    <label>{{'Rillen-Diagramme zusammenfassen' | mrTranslate}}</label>
                  </clr-checkbox-wrapper>
                </ng-container>
              </div>
              <div class="clr-col-6" style="text-align: right;">
                <button class="btn btn-icon" (click)="loadDetailView(gr)">
                  <clr-icon shape="search"></clr-icon>
                </button>
                <clr-signpost>
                  <clr-icon shape="cog" class="is-solid" clrSignpostTrigger>
                  </clr-icon>
                  <clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen>
                    <form clrForm>
                      <clr-input-container style="margin-top:10px;">
                        <label>{{'Y-Max' | mrTranslate}}</label>
                        <input clrInput placeholder="Y-Max" name="ymax" [(ngModel)]="gr.layout.yaxis.range[1]" />
                      </clr-input-container>
                      <clr-input-container>
                        <label>{{'Y-Min' | mrTranslate}}</label>
                        <input clrInput placeholder="Y-Min" name="ymin" [(ngModel)]="gr.layout.yaxis.range[0]" />
                      </clr-input-container>
                      <button class="btn btn-primary btn-sm" style="margin-top:1.2rem" (click)="applyYaxis(gr.key)">{{'übernehmen' | mrTranslate}}</button>
                    </form>
                  </clr-signpost-content>
                </clr-signpost>
              </div>
            </div>
          </div>
          <div *ngIf="gr.show" class="card-block">
            <div style="width:100%;">
              <div class="clr-col-12">
                <div [ngStyle]="{'height.px': gr.layout.height}">
                  <app-plotly-plot [className]="" [data]="gr.yData" [layout]="gr.layout ? gr.layout : graph.layout" [config]="graph.config">
                  </app-plotly-plot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>


  <!-- MODAL DETAILANSICHT -->
  <clr-modal [(clrModalOpen)]="isDetailViewVisible" [clrModalSize]="'full-screen'" [clrModalClosable]="true"  >
    <h3 class="modal-title">{{'Detailansicht'|mrTranslate}}</h3>
    <div class="modal-body" *ngIf="curGraph">
      <!-- Graph -->
      <div class="card" *ngIf="curGraph.yData && curGraph.yData.length > 0">
        <div class="card-block">
          <div style="width:100%;">
            <div class="clr-col-12">

              <div style="height:47vh; position: relative;">
              <!-- <div [ngStyle]="{'height.px': curGraph.layout.height}"> -->
                <div id="plotly_Sub"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Karte -->
      <div style="width:100%;height:29vh; position: relative;">
        <div  style="margin-top: 10px;margin-left: 5px;position: absolute; z-index: 2;">
          <clr-dropdown>
            <button type="button" class="btn btn-success dropdown-toggle" action="solid" clrDropdownTrigger>
              <clr-icon shape="world" size="26"></clr-icon>
              <clr-icon shape="caret down"></clr-icon>
            </button>

            <clr-dropdown-menu *clrIfOpen>
              <a (click)="tileSwitch('sonstige','leer')" clrDropdownItem>{{'leer' | mrTranslate}}</a>

              <clr-dropdown>
                <button clrDropdownTrigger>Bing</button>
                <clr-dropdown-menu *clrIfOpen>
                    <clr-dropdown>
                      <a (click)="tileSwitch('bing','bingRoadOnDemand')" clrDropdownItem>BingMaps</a>
                      <a (click)="tileSwitch('bing','bingAerial')" clrDropdownItem>Sattelit</a>
                      <a (click)="tileSwitch('bing','bingAerialWithLabels')" clrDropdownItem>Hybrid</a>
                    </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Carto DB</button>
                <clr-dropdown-menu *clrIfOpen>
                    <clr-dropdown>
                      <a (click)="tileSwitch('cartoDB','cartoDbDark')" clrDropdownItem>Dark</a>
                      <a (click)="tileSwitch('cartoDB','cartoDbDarkNoLabels')" clrDropdownItem>Dark no Label</a>
                      <a (click)="tileSwitch('cartoDB','cartoDbLight')" clrDropdownItem>Light</a>
                      <a (click)="tileSwitch('cartoDB','cartoDbLightNoLabels')" clrDropdownItem>Light no Label</a>
                    </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>ESRI</button>
                <clr-dropdown-menu *clrIfOpen>
                    <clr-dropdown>
                      <a (click)="tileSwitch('sonstige','esri')" clrDropdownItem>Hybrid</a>
                    </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Geoportale</button>
                <clr-dropdown-menu *clrIfOpen>

                  <clr-dropdown>
                    <button clrDropdownTrigger>Belgien</button>
                    <clr-dropdown-menu *clrIfOpen>
                      <clr-dropdown>
                        <a (click)="tileSwitch('sonstige','belgiumOrtho')" clrDropdownItem>Ortho</a>
                        <a (click)="tileSwitch('sonstige','belgiumCarto')" clrDropdownItem>Carto.be</a>
                      </clr-dropdown>
                    </clr-dropdown-menu>
                  </clr-dropdown>

                  <clr-dropdown>
                    <button clrDropdownTrigger>Deutschland</button>
                    <clr-dropdown-menu *clrIfOpen>
                      <clr-dropdown>
                        <a (click)="tileSwitch('sonstige','bb_house')" clrDropdownItem>BB ALKIS Gebäude</a>
                        <a (click)="tileSwitch('sonstige','bb_lands')" clrDropdownItem>BB ALKIS Flurstücke</a>
                        <a (click)="tileSwitch('sonstige','bb_ortho')" clrDropdownItem>BB Ortho</a>
                        <a (click)="tileSwitch('sonstige','he_ortho')" clrDropdownItem>HE Ortho</a>
                        <a (click)="tileSwitch('sonstige','mv_house')" clrDropdownItem>MV ALKIS Gebäude</a>
                        <a (click)="tileSwitch('sonstige','mv_lands')" clrDropdownItem>MV ALKIS Flurstücke</a>
                        <a (click)="tileSwitch('sonstige','mv_ortho')" clrDropdownItem>MV Ortho</a>


                        <a (click)="tileSwitch('sonstige','ni_ortho')" clrDropdownItem>NI Ortho</a>
                        <a (click)="tileSwitch('sonstige','nrw_house')" clrDropdownItem>NR ALKIS Gebäude</a>
                        <a (click)="tileSwitch('sonstige','nrw_lands')" clrDropdownItem>NR ALKIS Flurstücke</a>
                        <a (click)="tileSwitch('sonstige','nrwOrtho')" clrDropdownItem>NR Ortho</a>
                        <a (click)="tileSwitch('sonstige','sa_ortho')" clrDropdownItem>SA Ortho</a>
                        <a (click)="tileSwitch('sonstige','sn_house')" clrDropdownItem>SN ALKIS Gebäude</a>
                        <a (click)="tileSwitch('sonstige','sn_lands')" clrDropdownItem>SN ALKIS Flurstücke</a>
                        <a (click)="tileSwitch('sonstige','sn_ortho')" clrDropdownItem>SN Ortho</a>
                        <a (click)="tileSwitch('sonstige','th_ortho')" clrDropdownItem>TH Ortho</a>

                        <a (click)="tileSwitch('sonstige','de_farbe')" clrDropdownItem>BASEMAP.DE</a>
                        <a (click)="tileSwitch('sonstige','de_grau')" clrDropdownItem>BASEMAP.DE grau</a>
                      </clr-dropdown>
                    </clr-dropdown-menu>
                  </clr-dropdown>

                  <clr-dropdown>
                    <button clrDropdownTrigger>Luxemburg</button>
                    <clr-dropdown-menu *clrIfOpen>
                      <clr-dropdown>
                        <a (click)="tileSwitch('sonstige','luxBaseMap')" clrDropdownItem>Basemap</a>
                        <a (click)="tileSwitch('sonstige','luxOrtho')" clrDropdownItem>Ortho</a>
                      </clr-dropdown>
                    </clr-dropdown-menu>
                  </clr-dropdown>

                  <clr-dropdown>
                    <button clrDropdownTrigger>Österreich</button>
                    <clr-dropdown-menu *clrIfOpen>
                      <clr-dropdown>
                        <a (click)="tileSwitch('sonstige','austriaOrtho')" clrDropdownItem>Österreich Ortho</a>
                        <!-- <a clrDropdownItem>Voralberg Ortho</a> -->
                      </clr-dropdown>
                    </clr-dropdown-menu>
                  </clr-dropdown>

                  <clr-dropdown>
                    <button clrDropdownTrigger>Schweiz</button>
                    <clr-dropdown-menu *clrIfOpen>
                      <clr-dropdown>
                        <a (click)="tileSwitch('sonstige','ch_base')" clrDropdownItem>Swisstopo Pixelkarte farbe</a>
                        <a (click)="tileSwitch('sonstige','ch_base_grau')" clrDropdownItem>Swisstopo Pixelkarte grau</a>
                        <a (click)="tileSwitch('sonstige','ch_ortho')" clrDropdownItem>Swisstopo Ortho</a>
                      </clr-dropdown>
                    </clr-dropdown-menu>
                  </clr-dropdown>

                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Google</button>
                <clr-dropdown-menu *clrIfOpen>
                    <clr-dropdown>
                      <a (click)="tileSwitch('google','googleMaps')" clrDropdownItem>GoogleMaps</a>
                      <a (click)="tileSwitch('google','googleSatellite')" clrDropdownItem>Satelit</a>
                      <a (click)="tileSwitch('google','googleHybrid')" clrDropdownItem>Hybrid</a>
                      <a (click)="tileSwitch('google','googleTerrain')" clrDropdownItem>Terrain</a>
                    </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Openstreetmap</button>
                <clr-dropdown-menu *clrIfOpen>
                    <clr-dropdown>
                      <a (click)="tileSwitch('sonstige','osmde')" clrDropdownItem>Openstreetmap</a>
                    </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <a  (click)="tileSwitch('sonstige','wikimedia')" clrDropdownItem>WikiMaps</a>

            </clr-dropdown-menu>
          </clr-dropdown>
        </div>
        <div id="previewMap" style="width:100%; height:100%;z-index: 1;"></div>
      </div>


    </div>
    <div class="modal-footer">
      <!-- <button type="button" class="btn btn-primary" (click)="createMapSet()">{{'OK' | mrTranslate}}</button> -->
      <button type="button" class="btn" (click)="closeModal()">{{'Schließen' | mrTranslate}}</button>
    </div>
  </clr-modal>
</div>
