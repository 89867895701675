
<header class="header-2">
  <div class="branding">
      <span class="title">{{'Trassierungseditor' | mrTranslate}}</span>
  </div>
  <div class="header-actions">
<!--
    <clr-dropdown>
      <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
        <clr-icon shape="cog"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a (click)="addObjekt()" clrDropdownItem>{{'Neues Objekt erstellen' | mrTranslate}}</a>
        <div *ngIf="UseKomponenten === true" class="dropdown-divider" role="separator"></div>
        <a *ngIf="UseKomponenten === true" (click)="addKomponenten()" clrDropdownItem>{{'Neue Komponente erstellen' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="editKomponenten()" clrDropdownItem>{{'Komponente bearbeiten' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="delKomponenten()" clrDropdownItem>{{'Komponente löschen' | mrTranslate}}</a>
      </clr-dropdown-menu>
    </clr-dropdown>
     -->
    <a href="javascript://" class="nav-link nav-icon">
      <clr-icon  shape="times" (click)="close()"></clr-icon >
    </a>
  </div>
</header>


<div class="overlay-inner-wrapper">
  <div id="measurementWrapper">
    <form clrForm class="clr-form" style="width: 100%;">
      <div class="clr-row" style="margin-left: 12px;">
        <div class="clr-col-2">
          {{'Trassierungselement' | mrTranslate}}
        </div>
        <div class="clr-col-2">
          <div class="clr-select-wrapper">
            <select class="clr-select" #sel [(ngModel)]="selectedtyp" [ngModelOptions]="{standalone: true}" (change)="selecteTypChanged(sel.value)">
              <option *ngFor="let item of typen" [value]="item['Id']">
                {{ item['Bezeichnung'] | mrTranslate}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <ng-container *ngIf="selectedtyp">
        <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
          <div class="btn-group btn-primary btn-icon">
            <button class="btn btn-success" (click)="newitem()">
              <clr-icon shape="file" [attr.title]="'neu' | mrTranslate"></clr-icon>
            </button>
          <!--
            <button class="btn" (click)="saveItem()">
              <clr-icon shape="floppy" [attr.title]="'speichern' | mrTranslate"></clr-icon>
            </button>
          -->

            <button class="btn btn-danger" [disabled]="selid == 0" (click)="deletItem()" >
              <clr-icon shape="times" [attr.title]="'löschen' | mrTranslate"></clr-icon>
            </button>
          </div>
        </div>


        <div class="clr-row">
          <div class="clr-col-12">
            <div class="limit-height">
              <clr-datagrid class="datagrid-compact" *ngIf="selecteddata?.rows?.length" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selecteditem" (clrDgSingleSelectedChange)="selectionItemChanged($event)">
                <clr-dg-column *ngFor="let column of selecteddata['columns']" [clrDgField]="column.id"
                  [hidden]="column.id | hideIDColumns:['KSTEDTID','kstedtid','schhmax','bildbase64','Profil']">
                  {{ column.id | titlecase | mrTranslate }}
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let row of selecteddata['rows']" [clrDgItem]="row">
                  <clr-dg-cell class="name-cell" *ngFor="let cell of selecteddata['columns']" [hidden]="cell.id | hideIDColumns:['KSTEDTID','kstedtid','schhmax','bildbase64','Profil']"
                    [innerHTML]="row[cell.id]">
                  </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>{{selecteddata['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
                <ng-template [(clrIfDetail)]="detailState" let-detail>
                  <clr-dg-detail>
                    <!-- <clr-dg-detail-header>{{ detail['Code'] }}</clr-dg-detail-header> -->
                    <clr-dg-detail-body>
                      <form clrForm class="clr-form" clrLayout="horizontal">
                        <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
                          <div class="clr-col-3">
                            {{'von' | mrUnitText}}
                          </div>
                          <div class="clr-col-1">
                            <input type="number" id="basic" #bez class="clr-input" value="{{detail['Startposition']}}" #von />
                          </div>
                        </div>
                        <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
                          <div class="clr-col-3">
                            {{'bis' | mrUnitText}}
                          </div>
                          <div class="clr-col-1">
                            <input type="number" id="basic" #bez class="clr-input" value="{{detail['Endposition']}}" #bis />
                          </div>
                        </div>
                        <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
                          <div class="clr-col-3">
                            {{'Anfangswert' | mrTranslate}}
                          </div>
                          <div class="clr-col-1">
                            <input type="number" id="basic" #bez class="clr-input" value="{{detail['Anfangswert']}}" #wert1 />
                          </div>
                        </div>
                        <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
                          <div class="clr-col-3">
                            {{'Endwert' | mrTranslate}}
                          </div>
                          <div class="clr-col-1">
                            <input type="number" id="basic" #bez class="clr-input" value="{{detail['Endwert']}}" #wert2 />
                          </div>
                        </div>

                        <ng-container *ngIf="seltyp == 6">
                          <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
                            <div class="clr-col-3">
                              {{'Steigung in ‰' | mrTranslate}}
                            </div>
                            <div class="clr-col-1">
                              <input type="number" id="basic" #bez class="clr-input" value="{{detail['Steigung']}}" #wert3 />
                            </div>
                          </div>
                          <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
                            <div class="clr-col-3">
                              {{'Ausrunfdungsradius' | mrTranslate}}
                            </div>
                            <div class="clr-col-1">
                              <input type="number" id="basic" #bez class="clr-input" value="{{detail['Radius']}}" #wert4 />
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="seltyp != 6">
                          <div class="clr-row" style="margin-top: 10px; margin-left: 12px;">
                            <div class="clr-col-3">
                              {{'alternativ: Länge' | mrTranslate}}
                            </div>
                            <div class="clr-col">
                              <input type="number" id="basic" #bez class="clr-input" #awert />
                            </div>
                          </div>
                        </ng-container>


                        <div class="clr-row" style="margin-top: 15px; margin-left: 10px;">
                          <button type="button" class="btn btn-icon btn-primary" aria-label="Speichern" (click)="saveItem()">
                            <clr-icon shape="floppy"></clr-icon>
                          </button>
                        </div>
                      </form>
                    </clr-dg-detail-body>
                  </clr-dg-detail>
                </ng-template>
              </clr-datagrid>
            </div>
          </div>
        </div>

      </ng-container>


      <ng-container *ngIf="graphdata.length > 0">

        <div class="clr-row" style="margin-top: 10px; margin-left: 12px; width: 100%;">
          <div class="clr-col-12">
            <app-plotly-plot [className]="" [data]="graphdata" [layout]="layout" [config]="graphconfig"></app-plotly-plot>
          </div>
        </div>
      </ng-container>
  </form>
  </div>
</div>
