import { Component, Output, Input, OnInit, ViewChild, ElementRef, EventEmitter} from "@angular/core";
import { APIService } from "../../../../services/APIService/api.service";
import { first, map } from "rxjs/operators";
import { UntypedFormGroup, UntypedFormControl, Validators, NgForm } from "@angular/forms";
import { BehaviorSubject, firstValueFrom, forkJoin, Subscription } from "rxjs";
import * as _ from "underscore";
import { ToastrService } from "ngx-toastr";
import { ClrDatagrid, ClrForm, ClrModal } from "@clr/angular";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { DomSanitizer } from '@angular/platform-browser';
import { Context} from "svgcanvas";
import { ClarityIcons } from "@clr/icons";
import { DatePipe } from '@angular/common';
import { forEach } from "lodash";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: 'app-sonderinspektionmodal',
    imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe],
    templateUrl: './sonderinspektionmodal.component.html',
    styleUrls: ['./sonderinspektionmodal.component.scss'],
    providers: [BackendLocaleDatePipe],
    standalone: true
})
export class SonderinspektionmodalComponent {
  @Input() modalOpen: boolean = false;
  @Output() onOK: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private basicStore: BasicStoreService,
    protected localeDate: BackendLocaleDatePipe,
  ) {
    //this.sonderinspformGroup.controls.beginn.setValue(BackendLocaleDatePipe.now);
  }

  protected mangel: string = '';
  protected sonderinspformGroup = new UntypedFormGroup({
    bemerkung: new UntypedFormControl(''),
    beginn: new UntypedFormControl('', [Validators.required]),
    aktiv: new UntypedFormControl(true),
    intervall: new UntypedFormControl(10, [Validators.required]),
  });
  protected OSTAMMID: number;
  protected OMAZSID: number;
  protected OSISPKID: number;
  protected OTYPID: number;
  protected status: number = 0;
  protected isdisabled: boolean;
  protected titel: string = 'Sonderinspektion beenden';

  saveSonderinspektion(){
    let val = this.sonderinspformGroup.value;

    if (this.status == 1){
      if (val.beginn == ''){
        this.toastr.error(this.mrTranslate.transform("Beginn fehlt"));
        return;
      }
      if (val.intervall == '' || val.intervall == null){
        this.toastr.error(this.mrTranslate.transform("Intervall fehlt"));
        return;
      }
      if (val.aktiv == false){
        this.toastr.error(this.mrTranslate.transform("aktiv muss aktiviert sein"));
        return;
      }

      let sonder = {
        status: "add",
        OSTAMMID: this.OSTAMMID,
        OMAZSID: this.OMAZSID,
        OTYPID: this.OTYPID,
        value: val,
      }

      this.apiservice
      .setSonderinspektion(sonder)
      .pipe(first())
      .subscribe((val: any) => {
        if (val.success){
          this.toastr.success(
            this.mrTranslate.transform('Erfolgreich gespeichert'),
            this.mrTranslate.transform('Sonderinspektion')
          );
          this.onOK.emit(true);
        }
      });
    }
    else{
      if (val.aktiv == false){
        let sonder = {
          status: "closepruefung",
          OSTAMMID: this.OSTAMMID,
          OMAZSID: this.OMAZSID,
          OSISPKID: this.OSISPKID,
          bemerkung: val.bemerkung,
        }
        this.apiservice
        .setSonderinspektion(sonder)
        .pipe(first())
        .subscribe((val: any) => {
          if (val.success){
            this.toastr.success(
              this.mrTranslate.transform('Sonderinspektion wurde beenden'),
              this.mrTranslate.transform('Sonderinspektion')
            );
            this.onOK.emit(true);
          }
        });
      }
      else{
        this.modalOpen = false;
      }
    }
  }

  open(inData: any) {
    this.status = inData.Status;
    this.mangel = inData.Mangel;
    this.OSTAMMID = inData.OSTAMMID;
    this.OMAZSID = inData.OMAZSID;
    this.OSISPKID = inData.OSISPKID;
    this.OTYPID = inData.OTYPID;
    this.sonderinspformGroup.controls.aktiv.setValue(inData.aktive);
    this.sonderinspformGroup.controls.intervall.setValue(inData.intervall);
    this.sonderinspformGroup.controls.beginn.setValue(BackendLocaleDatePipe.now);
    this.titel = this.mrTranslate.transform('Sonderinspektion beenden');
    this.isdisabled = true;

    if (this.status == 1)
    {
      this.isdisabled = false;
      this.titel = this.mrTranslate.transform('Sonderinspektion anlegen');
    }


    this.modalOpen = true;
  }

  close() {
    this.modalOpen = false;
  }
}
