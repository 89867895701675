import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leftPadd',
  standalone: true

})
export class LeftPadPipe implements PipeTransform {
  transform(value: number, amount: number, fill: string = "0"): string {
    return value.toString().padStart(amount, fill);
  }
}
