<div class="textfilter" clrForm [attr.data-type]="dataType" [attr.data-test-id]="'textfilter-' + column.id">
  <h4>{{ column.id | titlecase | mrTranslate }}</h4>
  <div class="clr-row" style="align-items: flex-start; padding-bottom: 15px; flex-wrap: nowrap;">
    <div class="clr-col-2" *ngIf="isOperatorVisible">
      <select clrSelect name="operatorList" [(ngModel)]="operator" (change)="onOperatorChange($event)"
        [attr.data-type]="dataType" [attr.operator-name]="operatorName"
        [attr.data-test-id]="'textfilter-operator-list'">
        <option *ngFor="let op of operators" [ngValue]="op"
          [attr.data-test-id]="'textfilter-operator-list-' + op.value">{{ op.displayName }}</option>
      </select>
    </div>
    <div class="clr-col-8 clr-flex-column input-container" *ngIf="isInputVisible">
      <!-- TEXT -->
      <input *ngIf="dataType == 'string'" clrInput name="searchInput"
        placeholder="{{ 'Suche' | titlecase | mrTranslate }}" [(ngModel)]="input1" (input)="searchInputChanged($event)"
        [readonly]="inputsDisabled" [autofocus] [attr.data-test-id]="'textfilter-input'" />
      <!-- NUMBER -->
      <ng-container *ngIf="dataType != 'string' && dataType != 'datetime'">
        <input clrInput name="searchInput" placeholder="{{ 'Suche' | titlecase | mrTranslate }}" [(ngModel)]="input1"
          (input)="searchInputChanged($event)" type="number" [readonly]="inputsDisabled" [autofocus]
          [attr.data-test-id]="'textfilter-input'" />
        <input *ngIf="enableSecondSearchInput" clrInput name="searchInput2"
          placeholder="{{ 'Suche' | titlecase | mrTranslate }}" [(ngModel)]="input2"
          (input)="searchInput2Changed($event)" type="number" [readonly]="inputsDisabled"
          [attr.data-test-id]="'textfilter-input2'" />
      </ng-container>
      <!-- DATE -->
      <ng-container *ngIf="dataType == 'datetime'">
        <input clrInput name="searchInput" placeholder="{{ 'Suche' | titlecase | mrTranslate }}" [(ngModel)]="input1"
          (input)="searchInputChanged($event)" type="date" [readonly]="inputsDisabled" [autofocus]
          [attr.data-test-id]="'textfilter-input'" />
        <input *ngIf="enableSecondSearchInput" clrInput name="searchInput2"
          placeholder="{{ 'Suche' | titlecase | mrTranslate }}" [(ngModel)]="input2"
          (input)="searchInput2Changed($event)" type="date" [readonly]="inputsDisabled"
          [attr.data-test-id]="'textfilter-input2'" />
      </ng-container>
    </div>

  </div>
  <div class="clr-row maxheight-400" style="padding: 0 0px; overflow: auto;">
    <div class="values-grid maxheight-400">
      <clr-datagrid class="datagrid-compact" [(clrDgSelected)]="selectedItems" [clrDgRowSelection]="true"
        [attr.data-test-id]="'textfilter-grid'">
        <clr-dg-column>{{ "Werteliste" | titlecase | mrTranslate }}</clr-dg-column>
        <!-- <clr-dg-row *ngFor="let item of filteredItems; index as i" [clrDgItem]="item" [attr.data-test-id]="'textfilter-grid-row-' + i"> -->
        <clr-dg-row *ngFor="let item of filteredItems; trackBy: trackById; index as i" [clrDgItem]="item">
          <ng-container [ngTemplateOutlet]="isIconColumn(column.id) ? iconTemplate : normalTemplate"></ng-container>

          <ng-template #iconTemplate>
            <clr-dg-cell [title]="column.id | tablePrettyPrint"
              [attr.data-test-id]="'textfilter-grid-cell-' + item.name">
              <!-- <clr-icon class="customIcon iconcolor"
                  [attr.shape]="getIconShape(column.id.toLowerCase(), item.name, item.id)"
                  [ngStyle]="getIconStyle(column.id.toLowerCase(), item.name)"
                  size="28">
                </clr-icon> -->
              <clr-icon class="customIcon iconcolor"
                [attr.shape]="getIconShape(column.id.toLowerCase(), item.name, item.id)"
                [style.color]="getIconColor(column.id.toLowerCase(), item.name)"
                [style.opacity]="getIconOpacity(column.id.toLowerCase(), item.name)"
                [style.width.px]="getIconSize(column.id.toLowerCase())"
                [style.height.px]="getIconSize(column.id.toLowerCase())">
              </clr-icon>

            </clr-dg-cell>
          </ng-template>

          <ng-template #normalTemplate>
            <clr-dg-cell [title]="item.name"
              [innerHTML]="item.name | tablePrettyPrint : { icons: true, column: column }"
              [attr.data-test-id]="'textfilter-grid-cell-' + item.name" [style.min-width.px]="85" class="ellipsis">
            </clr-dg-cell>
          </ng-template>
        </clr-dg-row>


        <clr-dg-footer>{{ filteredItems.length }} {{ "Items" | mrTranslate }}</clr-dg-footer>
      </clr-datagrid>
    </div>
  </div>
  <div class="clr-row">
    <div class="buttons">
      <button name="cancel" class="btn btn-sm" (click)="closeCancel()" [attr.data-test-id]="'textfilter-btn-cancel'">
        {{ "Abbrechen" | titlecase | mrTranslate }}
      </button>
      <button type="submit" name="submit" class="btn btn-primary btn-sm" (click)="closeSubmit()"
        [attr.data-test-id]="'textfilter-btn-submit'">
        {{ "Filter anwenden" | mrTranslate }}
      </button>
    </div>
  </div>
</div>