<clr-modal [(clrModalOpen)]="open" [clrModalSize]="'md'" (clrModalOpenChange)="close.emit()"
    id="kosten-fremdleistung-modal">

    <h3 class="modal-title" *ngIf="open !== 'addLV' && open !== 'editLV';else lvTitle">
        {{"Fremdleistung" | mrTranslate}}
        {{(open == 'add' ? 'anlegen' : 'bearbeiten') | mrTranslate}}
    </h3>
    <ng-template #lvTitle>
        <h3 class="modal-title">{{"Leistungsverzeichnis" | mrTranslate}}
            {{open === 'addLV' ? 'anlegen' : 'bearbeiten' | mrTranslate}}</h3>
    </ng-template>
    <div class="modal-body" id="kosten-fremdleistung-modal-body">
        <div class="clr-row">
            <div class="clr-col">
                <form clrForm [formGroup]="fremdleistungForm">
                    <clr-radio-container *ngIf="open === 'add'" style="margin-top:0px;">
                        <label>{{"Hinzufügen als" | mrTranslate}}</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="addType" required value="child"
                                formControlName="addType" />
                            <label>{{'Unterposition' | mrTranslate}}</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio name="addType" required value="neighbor"
                                formControlName="addType" />
                            <label>{{'Nebenposition' | mrTranslate}}</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                    <clr-input-container *ngIf="open !== 'addLV' && open !== 'editLV'">
                        <label>{{"Nr" | mrTranslate}} *</label>
                        <input clrInput formControlName="OZ" />
                    </clr-input-container>
                    <clr-input-container>
                        <label>{{"Kurztext" | mrTranslate}} *</label>
                        <input clrInput formControlName="Kurztext" />
                    </clr-input-container>
                    <ng-container *ngIf="open !== 'addLV' && open !== 'editLV'">
                        <clr-input-container>
                            <label>{{"Einzelpreis" | mrTranslate}}</label>
                            <input clrInput formControlName="EP" type="number" />
                        </clr-input-container>
                        <clr-select-container class="clr-form-control">
                            <label>{{'Einheit' | mrTranslate}}</label>
                            <select clrSelect formControlName="ME">
                                <option value=""></option>
                                <option *ngFor="let val of mengeneinheiten" [value]="val">
                                    {{val}}</option>
                            </select>
                        </clr-select-container>
                        <clr-textarea-container>
                            <label>{{"Beschreibung" | mrTranslate}}</label>
                            <textarea clrTextarea formControlName="Langtext" name="beschreibung"></textarea>
                        </clr-textarea-container>
                    </ng-container>
                </form>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" [disabled]="trial" class="btn btn-primary" (click)="save()">{{open === "add" || open ===
            "addLV" ? "anlegen"
            :
            "speichern" |
            mrTranslate}}</button>
    </div>

</clr-modal>
