import { Injectable, isDevMode } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/Auth/auth.service';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';
import { navigateToLogin } from '../services/LogoutInterceptor/logoutinterceptor'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {

    constructor(private router: Router,
        private authservice: AuthService,
        private swupdate: SwUpdate,
        private toastr: ToastrService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!isDevMode()) this.swupdate.checkForUpdate();
        if (this.authservice.isLoggedIn()) {
            return true;
        }
        let refresh = this.authservice.getRefreshToken();
        if (Math.floor(Date.now() / 1000) > refresh.expiresAt && localStorage.getItem("repID") == null) navigateToLogin(this.toastr, this.router, route, this.authservice);
        else return true;
        return false;
    }
}