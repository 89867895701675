<header class="header-2">
  <div class="branding">
      <span class="title">{{'Linien verwalten' | mrTranslate}}</span>
  </div>
  <div class="header-actions">
    <a href="javascript://" class="nav-link nav-icon">
      <clr-icon  shape="times" (click)="close()"></clr-icon >
    </a>
  </div>
</header>

<div class="overlay-inner-wrapper">
  <div id="objekttpyWrapper">
    <clr-tabs>
      <clr-tab>
        <button clrTabLink (click)="tabChange('user')">
          {{ "Linien" | mrTranslate }}
        </button>
        <clr-tab-content *ngIf="activeTabs.user">
          <div class="clr-row padding-10-t">
              <div class="clr-col-12">
                <div class="btn-group btn-primary btn-icon">
                  <button class="btn btn-success"  (click)="newitem()">
                    <clr-icon shape="file"></clr-icon>
                    <span class="clr-icon-title">Add</span>
                  </button>
                  <button class="btn btn-danger" [disabled]="selid == 0" (click)="deletitem()">
                    <clr-icon shape="times"></clr-icon>
                    <span class="clr-icon-title">Delete</span>
                  </button>
                </div>

                <div class="limit-height" *ngIf="objekte?.rows?.length">
                  <clr-datagrid style="padding-top: 0px" class="datagrid-compact" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selobjekt" (clrDgSingleSelectedChange)="selectionObjektChanged($event)">
                    <clr-dg-column>{{'Symbol' | mrTranslate | titlecase}}</clr-dg-column>
                    <clr-dg-column>{{'Nummer' | mrTranslate | titlecase}}</clr-dg-column>
                    <clr-dg-column>{{'Ort' | mrTranslate | titlecase}}</clr-dg-column>
                    <clr-dg-column>{{'über' | mrTranslate | titlecase}}</clr-dg-column>
                    <clr-dg-column>{{'Belastung' | mrTranslate | titlecase}}</clr-dg-column>
                    <clr-dg-column>{{'Benutzer' | mrTranslate | titlecase}}</clr-dg-column>
                    <clr-dg-column>{{'Zeitstempel' | mrTranslate | titlecase}}</clr-dg-column>
                    <clr-dg-row *clrDgItems="let item of objekte.rows" [clrDgItem]="item">
                      <clr-dg-cell style="color:#000">
                        <clr-icon class="customIcon" [attr.shape]="item['icon']" size="28">
                      </clr-icon>
                      </clr-dg-cell>
                      <clr-dg-cell [innerHTML]="item['Nummer'] | tablePrettyPrint" [title]="item['Nummer'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                      <clr-dg-cell [innerHTML]="item['Ort'] | tablePrettyPrint" [title]="item['Ort'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                      <clr-dg-cell [innerHTML]="item['Weg'] | tablePrettyPrint" [title]="item['Weg'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                      <clr-dg-cell [innerHTML]="item['Belastung'] | tablePrettyPrint" [title]="item['Belastung'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                      <clr-dg-cell [innerHTML]="item['UserName'] | tablePrettyPrint" [title]="item['UserName'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                      <clr-dg-cell [innerHTML]="item['Status'] | tablePrettyPrint" [title]="item['Status'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                    </clr-dg-row>
                    <ng-template clrIfDetail let-detail (clrIfDetailChange)="onDetailOpen($event)">
                      <clr-dg-detail>
                        <clr-dg-detail-header>{{ detail['Nummer'] }}</clr-dg-detail-header>
                        <clr-dg-detail-body>
                          <br>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                {{'Symbol' | mrTranslate}}
                              </div>
                              <div class="clr-col-3">
                                  <div>
                                    <img [src]="getSVGImageUrl(svgFile)" style="margin-left: -1px;"/>
                                  </div>
                              </div>
                            </div>
                            <br>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                {{'Nummer' | mrTranslate}}
                              </div>
                              <div class="clr-col-9">
                                <input type="text" id="basic" #bezfield class="clr-input" value="{{detail['Nummer']}}" (change)="onChange($event.target.value, 'nummer')" #_nummer/>
                              </div>
                            </div>
                            <br>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                {{'Ort' | mrTranslate}}
                              </div>
                              <div class="clr-col-9">
                                <input type="text" id="basic" #bezfield class="clr-input" value="{{detail['Ort']}}" #_ort/>
                              </div>
                            </div>
                            <br>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                {{'über' | mrTranslate}}
                              </div>
                              <div class="clr-col-9">
                                <input type="text" id="basic" #bezfield class="clr-input" value="{{detail['Weg']}}" #_weg/>
                              </div>
                            </div>
                            <br>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                {{'Belastung' | mrTranslate}}
                              </div>
                              <div class="clr-col-9">
                                <input type="number" id="basic" #bezfield class="clr-input" value="{{detail['Belastung']}}" #_belastung/>
                              </div>
                            </div>
                            <br>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                {{'Schriftfarbe' | mrTranslate}}
                              </div>
                              <div class="clr-col-9">
                                <input id="forecolor" type="color" class="cursor-pointer"  value="{{'#' + detail['BackColor'].toString(16).padStart(6, '0')}}" (change)="onChange($event.target.value, 'forecolor')" #_forecolor>
                              </div>
                            </div>
                            <br>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                {{'Hinztergrundfarbe' | mrTranslate}}
                              </div>
                              <div class="clr-col-9">
                                <input id="fillcolor" type="color" class="cursor-pointer" value="{{'#' + detail['ForeColor'].toString(16).padStart(6, '0')}}" (change)="onChange($event.target.value, 'fillcolor')" #_fillcolor>
                              </div>
                            </div>
                            <div class="clr-row">
                              <div class="clr-col-3">
                                <button class="btn btn-primary" type="submit" (click)="saveItems(detail.ID)">{{'Speichern' | mrTranslate}} </button>
                              </div>
                            </div>
                        </clr-dg-detail-body>
                      </clr-dg-detail>
                    </ng-template>
                    <clr-dg-footer>
                      <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{objekte['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                    </clr-dg-footer>
                  </clr-datagrid>
                </div>
              </div>
            </div>
          </clr-tab-content>
      </clr-tab>

      <ng-container *ngIf="objekte != undefined">

        <clr-tab>
          <button clrTabLink (click)="tabChange('belegung')">
            {{ "Linienbelegung" | mrTranslate }}
          </button>
          <clr-tab-content *ngIf="activeTabs.belegung">
            <div class="clr-row" id="groupSelectionRow" [ngClass]="{ enlarged: !_selectedLinienForObject }">

              <div class="clr-col-12 text-center">
                <clr-select-container style="display: inline-block">
                  <select clrSelect [(ngModel)]="selectedLinienForObject" >
                                        <option [value]="undefined" disabled selected>
                      {{ "Linie auswählen" | mrTranslate }}
                    </option>
                    <option *ngFor="let line of objekteorginal" [value]="line.ID">
                      {{ line.Nummer + " - " + line.Ort}}
                    </option>
                  </select>
                </clr-select-container>
              </div>

              <div  id="objectSelectionWrapper" class="clr-row" *ngIf="_selectedLinienForObject">
                <div class="clr-col" style="max-width: calc(calc(100% - 8.3333333333%) / 2)">
                  <div class="limit-height">
                    <clr-datagrid class="datagrid-compact" *ngIf="stammobjekte" [(clrDgSelected)]="selectedStammList" [clrDgRowSelection]="true" (clrDgSelectedChange)="selectionStammChanged($event)">
                      <clr-dg-column *ngFor="let column of stammobjekte['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns">
                        {{ column.id | titlecase | mrTranslate }} </clr-dg-column>

                      <clr-dg-row *clrDgItems="let row of stammobjekte['rows']" [clrDgItem]="row">
                        <ng-container *ngFor="let cell of stammobjekte['columns']" >
                          <ng-container *ngIf="cell.id.toLowerCase() != 'typ';else iconcell">
                              <clr-dg-cell [hidden]="cell.id | hideIDColumns"
                                [innerHTML]="row[cell.id] | tablePrettyPrint"
                                [title]="row[cell.id] | tablePrettyPrint | dotDotDot : 30">
                              </clr-dg-cell>
                          </ng-container>

                          <ng-template #iconcell>
                            <clr-dg-cell style="color: #000" [style.min-width.px]="36">
                              <clr-icon class="customIcon" [attr.shape]="'objekttyp' + row['OTYPID']" size="28"></clr-icon>
                            </clr-dg-cell>
                          </ng-template>
                        </ng-container>
                      </clr-dg-row>

                      <clr-dg-footer>
                        <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="500">{{stammobjekte['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                  </div>
                </div>

                <div id="objectControl" class="clr-col-1">
                  <a href="javascript:void(0);" [ngClass]="{ disabled: selectedStammList.length == 0 }" (click)="executeToAdd()" >
                    <clr-icon shape="circle-arrow right" size="72" style="color: green" ></clr-icon>
                  </a>
                  <a href="javascript:void(0);" [ngClass]="{ disabled: selectedLinieList.length == 0 }" (click)="executeToRemove()" >
                    <clr-icon shape="circle-arrow left" size="72" style="color: red" class="is-solid"></clr-icon>
                  </a>
                </div>
                <div class="clr-col" style="max-width: calc(calc(100% - 8.3333333333%) / 2)">
                  <div class="limit-height">
                    <clr-datagrid class="datagrid-compact" *ngIf=" groupObjects" [(clrDgSelected)]="selectedLinieList" [clrDgRowSelection]="true" (clrDgSelectedChange)="selectionLinieChanged($event)">
                      <clr-dg-column
                        *ngFor="let column of groupObjects['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns">
                        {{ column.id | titlecase | mrTranslate }}
                      </clr-dg-column>
  <!--
                      <clr-dg-row *clrDgItems="let row of groupObjects['rows']" [clrDgItem]="row">
                        <clr-dg-cell class="name-cell" *ngFor="let cell of groupObjects['columns']"
                          [hidden]="cell.id | hideIDColumns" [innerHTML]="row[cell.id] | tablePrettyPrint"
                        ></clr-dg-cell>
                      </clr-dg-row>
                        -->

                        <clr-dg-row *clrDgItems="let row of groupObjects['rows']" [clrDgItem]="row">
                        <ng-container *ngFor="let cell of groupObjects['columns']" >
                          <ng-container *ngIf="cell.id.toLowerCase() != 'typ';else iconcell">
                              <clr-dg-cell [hidden]="cell.id | hideIDColumns"
                                [innerHTML]="row[cell.id] | tablePrettyPrint"
                                [title]="row[cell.id] | tablePrettyPrint | dotDotDot : 30">
                              </clr-dg-cell>
                          </ng-container>

                          <ng-template #iconcell>
                            <clr-dg-cell style="color: #000" [style.min-width.px]="36">
                              <clr-icon class="customIcon" [attr.shape]="'objekttyp' + row['OTYPID']" size="28"></clr-icon>
                            </clr-dg-cell>
                          </ng-template>
                        </ng-container>
                      </clr-dg-row>

                      <clr-dg-footer>
                        <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="500">{{groupObjects['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                  </div>
                </div>
              </div>
            </div>
          </clr-tab-content>
        </clr-tab>


        <clr-tab>
          <button clrTabLink (click)="tabChange('anlagenklassen')">
            {{ "Anlagenklassen" | mrTranslate }}
          </button>
          <clr-tab-content *ngIf="activeTabs.anlagenklassen">
            <div class="clr-row padding-10-t">
              <div class="clr-col-12">
                <div class="btn-group btn-primary btn-icon">
                  <button class="btn"  (click)="calcAK()">
                    <clr-icon shape="calculator"></clr-icon>
                    <span class="clr-icon-title">Anlagenklassen berechnen</span>
                  </button>
                </div>
              </div>
            </div>

            <clr-datagrid *ngIf="anlagenklassen?.columns" style="padding-top: 0px" class="datagrid-compact">
              <clr-dg-column>{{'Bezeichnung' | mrTranslate | titlecase}}</clr-dg-column>
              <clr-dg-column>{{'min' | mrTranslate | titlecase}}</clr-dg-column>
              <clr-dg-column>{{'max' | mrTranslate | titlecase}}</clr-dg-column>
              <clr-dg-column>{{'Intevall' | mrTranslate | titlecase}}</clr-dg-column>
              <clr-dg-column>{{'Benutzer' | mrTranslate | titlecase}}</clr-dg-column>
              <clr-dg-column>{{'Zeitstempel' | mrTranslate | titlecase}}</clr-dg-column>

              <clr-dg-row *clrDgItems="let item of anlagenklassen.rows" [clrDgItem]="item">
                <clr-dg-cell [innerHTML]="item['Bezeichnung'] | tablePrettyPrint" [title]="item['Bezeichnung'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                <clr-dg-cell [innerHTML]="item['min'] | tablePrettyPrint" [title]="item['min'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                <clr-dg-cell [innerHTML]="item['max'] | tablePrettyPrint" [title]="item['max'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                <clr-dg-cell [innerHTML]="item['Intervall'] | tablePrettyPrint" [title]="item['Intervall'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                <clr-dg-cell [innerHTML]="item['UserName'] | tablePrettyPrint" [title]="item['UserName'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
                <clr-dg-cell [innerHTML]="item['Status'] | tablePrettyPrint" [title]="item['Status'] | tablePrettyPrint | dotDotDot:30"></clr-dg-cell>
              </clr-dg-row>
              <ng-template clrIfDetail let-detail (clrIfDetailChange)="onAKDetailOpen($event)">
                <clr-dg-detail>
                  <clr-dg-detail-header>{{'Anlagenklasse' | mrTranslate | titlecase}}: {{ detail['Bezeichnung'] }}</clr-dg-detail-header>
                  <clr-dg-detail-body>
                    <div class="clr-row">
                      <div class="clr-col-1">
                      </div>
                      <div class="clr-col-3">
                        {{'Belastung' | mrTranslate | titlecase}}:
                      </div>
                    </div>
                      <div class="clr-row">
                        <div class="clr-col-1">
                          {{'min' | mrTranslate}}
                        </div>
                        <div class="clr-col-3">
                          <input type="number" id="basic" #bezfield class="clr-input" value="{{detail['min']}}" #_akmin/>
                        </div>
                      </div>
                      <br>
                      <div class="clr-row">
                        <div class="clr-col-1">
                          {{'max' | mrTranslate}}
                        </div>
                        <div class="clr-col-3">
                          <input type="number" id="basic" #bezfield class="clr-input" value="{{detail['max']}}" #_akmax/>
                        </div>
                      </div>
                      <br>
                      <div class="clr-row">
                        <div class="clr-col-1">
                        </div>
                        <div class="clr-col-3">
                          {{'Inspektion' | mrTranslate | titlecase}}:
                        </div>
                      </div>
                      <div class="clr-row">
                        <div class="clr-col-1">
                          {{'Intervall' | mrTranslate}}
                        </div>
                        <div class="clr-col-3">
                          <input type="number" id="basic" #bezfield class="clr-input" value="{{detail['Intervall']}}" #_akintervall/>
                        </div>
                      </div>
                      <br>

                      <div class="clr-row">
                        <div class="clr-col-1">
                        </div>
                        <div class="clr-col-3">
                          <button class="btn btn-primary" type="submit" (click)="saveAKItems(detail.ID)">{{'Speichern' | mrTranslate}} </button>
                        </div>
                      </div>
                  </clr-dg-detail-body>
                </clr-dg-detail>




              </ng-template>
            </clr-datagrid>


          </clr-tab-content>
        </clr-tab>
      </ng-container>
    </clr-tabs>
  </div>
</div>



<clr-modal [(clrModalOpen)]="isModalVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{modaltitel}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical" [formGroup]="linieGroup">

      <div class="clr-row">
        <div class="clr-col-3">
          {{'Symbol' | mrTranslate}}
        </div>
        <div class="clr-col-3">
          <ng-container *ngIf="svgFile !== undefined">
            <div>
              <img [src]="getSVGImageUrl(svgFile)" style="margin-left: -1px;"/>
            </div>
          </ng-container>
        </div>
      </div>
      <br>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Nummer' | mrTranslate}}
        </div>
        <div class="clr-col-9">
          <input type="text" id="basic" #bezfield class="clr-input" formControlName="nummer" (change)="onChange($event.target.value, 'nummer')"/>
        </div>
      </div>
      <br>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Ort' | mrTranslate}}
        </div>
        <div class="clr-col-9">
          <input type="text" id="basic" #bezfield class="clr-input" formControlName="ort"/>
        </div>
      </div>
      <br>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'über' | mrTranslate}}
        </div>
        <div class="clr-col-9">
          <input type="text" id="basic" #bezfield class="clr-input" formControlName="weg"/>
        </div>
      </div>
      <br>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Belastung' | mrTranslate}}
        </div>
        <div class="clr-col-9">
          <input type="number" id="basic" #bezfield class="clr-input" formControlName="belastung"/>
        </div>
      </div>
      <br>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Schriftfarbe' | mrTranslate}}
        </div>
        <div class="clr-col-9">
          <input id="forecolor" type="color" class="cursor-pointer" [ngStyle]="{'background-color': linieGroup.value.forecolor}" formControlName="forecolor" (change)="onChange($event.target.value, 'forecolor')">
        </div>
      </div>
      <br>
      <div class="clr-row">
        <div class="clr-col-3">
          {{'Hintergrundfarbe' | mrTranslate}}
        </div>
        <div class="clr-col-9">
          <input id="fillcolor" type="color" class="cursor-pointer" [ngStyle]="{'background-color': linieGroup.value.fillcolor}" formControlName="fillcolor" (change)="onChange($event.target.value, 'fillcolor')">
        </div>
      </div>
    </form>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addLinie()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isModalVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>


