import { Pipe, type PipeTransform } from '@angular/core';

const ArrayMethod = ['filter', 'map'] as const;
type ArrayMethod = (typeof ArrayMethod)[number];

const ArrayCallback = ['getProperty', 'getElement'] as const;
type ArrayCallback = (typeof ArrayCallback)[number];

type CallbackFn = (value: unknown, index: number, array: unknown[]) => any;


@Pipe({
  name: 'array',
  standalone: true

})
export class ArrayMethodsCallbackPipe implements PipeTransform {
  /**
   * @example
   * *ngFor="let col of table.columns | array:'filter':exceptID"
   * protected exceptID = (col) => col.id != 'ID' || !col.id.toLower().includes('id');
   * @param array zu filtern
   * @param method name der Aktion
   * @param cb eine von existierten callbacks names or callback function
   * @param property
   * @returns gefilterten Array
   */
  transform(
    array: unknown[],
    method: ArrayMethod,
    cb: CallbackFn | ArrayCallback,
    property?: string
  ): unknown[] {
    if (!ArrayMethod.includes(method))
      method = 'map';
    if (typeof cb !== 'function') {
      if (!ArrayCallback.includes(cb))
        cb = 'getElement';
      cb = this[cb](property);
    }

    return Array.isArray(array) ? array[method](cb) : array;
  }


  private getProperty(property: string): CallbackFn {
    return (value) => value?.[property];
  }

  private getElement(_: string): CallbackFn {
    return (value) => value;
  }
}

