import { Attribute, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, skip, startWith, Subject, Subscription, switchMap, timer } from 'rxjs';

const StyleMode = [ 'height', 'max-height', 'width', 'max-width' ] as const;
type StyleMode = (typeof StyleMode)[number];

@Directive({
  selector: '[dymanicStyle]',
  standalone: true
})
export class DynamicStyleDirective implements OnInit, OnDestroy {
  /** @reference  auf ein Element, auf dessen Höhe/Breite es dynamisch berechnet wird */
  @Input({ alias: 'dymanicStyle', required: true }) private dynamicElem: HTMLElement;
  private readonly hostElem: HTMLElement;
  @Output('resized') private resizeIsReady = new EventEmitter<void>();

  private resizer: ResizeObserver;
  private resized$ = new Subject<ResizeObserverEntry[]>();
  private sub: Subscription;
  private readonly box_size: 'blockSize' | 'inlineSize';
  private readonly elem_size: 'offsetHeight' | 'offsetWidth';

  constructor(
    { nativeElement }: ElementRef<HTMLElement>,
    @Attribute('addPx') private readonly padding: number,
    @Attribute('styleMode') private readonly style_size: StyleMode,
    @Attribute('diminutiv') private readonly substract_from: string,
    @Attribute('maxSize') private readonly max_size: string,
  ) {
    this.hostElem = nativeElement;
    this.padding ||= 0;
    this.substract_from ||= '100%';
    if (!StyleMode.includes(style_size)) this.style_size = 'height';
    this.box_size = this.style_size.includes('height') ? 'blockSize' : 'inlineSize';
    this.elem_size = this.style_size.includes('height') ? 'offsetHeight' : 'offsetWidth';
  }

  ngOnInit() {
    if (!this.dynamicElem || !(this.dynamicElem instanceof HTMLElement)) return;

    this.resizer = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => this.resized$.next(entries)
    );

    this.resizer.observe(this.dynamicElem);

    this.sub = this.resized$.pipe(
      debounceTime(200),
      map(entries => entries[0].borderBoxSize[0][this.box_size]),
      startWith(0), distinctUntilChanged(), skip(1),
      map(size => {
        const dymamicSize = size + +this.padding + 1;
        const currSize = this.max_size
          || this.hostElem.parentElement[this.elem_size] - dymamicSize + 'px';
        const style = `min(${currSize}, calc(${this.substract_from} - ${dymamicSize}px))`;
        this.hostElem.style.setProperty(this.style_size, style);
      }),
      switchMap(() => timer(10))
    ).subscribe(() => this.resizeIsReady.emit());
  }

  ngOnDestroy() {
    this.resizer?.disconnect();
    this.sub?.unsubscribe();
  }
}