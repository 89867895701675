import { FilterOperator } from "src/app/models/ui/filter";
import { DataType, isBooleanType, isDateType, isNumberType, isTextType } from "../../models/ui/datatype";

export const isEqualOperator: FilterOperator = {
  displayName: "=",
  name: "isEqual",
  value: "isEqual",
};

export const isNotEqualOperator: FilterOperator = {
  displayName: "<>",
  name: "isNotEqual",
  value: "isNotEqual",
};

export const isEmptyOperator: FilterOperator = {
  displayName: "= Ø",
  name: "isEmpty",
  value: "isEmpty",
};

export const isNotEmptyOperator: FilterOperator = {
  displayName: "<> Ø",
  name: "isNotEmpty",
  value: "isNotEmpty",
};

export const containsOperator: FilterOperator = {
  displayName: "...xxx...",
  name: "contains",
  value: "contains",
};

export const startsWithOperator: FilterOperator = {
  displayName: "xxx...",
  name: "startsWith",
  value: "startsWith",
};

export const endsWithOperator: FilterOperator = {
  displayName: "...xxx",
  name: "endsWith",
  value: "endsWith",
};

export const isLessThanOperator: FilterOperator = {
  displayName: "<",
  name: "isLessThan",
  value: "isLessThan",
};

export const isLessThanOrEqualOperator: FilterOperator = {
  displayName: "<=",
  name: "isLessThanOrEqual",
  value: "isLessThanOrEqual",
};

export const isGreaterThanOperator: FilterOperator = {
  displayName: ">",
  name: "isGreaterThan",
  value: "isGreaterThan",
};

export const isGreaterThanOrEqualOperator: FilterOperator = {
  displayName: ">=",
  name: "isGreaterThanOrEqual",
  value: "isGreaterThanOrEqual",
};

export const betweenOperator: FilterOperator = {
  displayName: "xxx...xxx",
  name: "between",
  value: "between",
};

export const noneOperator = {
  displayName: "- (None)",
  name: "none",
  value: "none",
};

export const commonOperators: FilterOperator[] = [
  isEqualOperator,
  isNotEqualOperator,
  isEmptyOperator,
  isNotEmptyOperator,
];

export const textOperators: FilterOperator[] = [
  containsOperator,
  startsWithOperator,
  endsWithOperator,
];

export const numericOperators: FilterOperator[] = [
  isLessThanOperator,
  isLessThanOrEqualOperator,
  isGreaterThanOperator,
  isGreaterThanOrEqualOperator,
  betweenOperator,
];

export class OperatorFactory {
  getOperators(dataType: DataType): FilterOperator[] {
    let operators = [];
    if (isNumberType(dataType)) {
      operators = numericOperators.concat(commonOperators);
    } else if (isTextType(dataType)) {
      operators = textOperators.concat(commonOperators);
    } else if (isDateType(dataType)) {
      operators = numericOperators.concat(commonOperators);
    } else if(isBooleanType(dataType)){
      operators = [isEqualOperator, isNotEqualOperator, isEmptyOperator, isNotEmptyOperator];
    } else {
      console.warn(`Cannot find specific operators for data type ${dataType}`);
      operators = commonOperators;
    }
    return operators;
  }
}
