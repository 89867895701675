import { NgFor, NgIf, NgSwitch, NgSwitchCase, TitleCasePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { ClrDatagrid, ClrDatagridModule, ClrIconModule, ClrPopoverHostDirective, ClrStopEscapePropagationDirective } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { Subscription, forkJoin } from "rxjs";
import { first, map } from "rxjs/operators";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { OverlayService } from "src/app/services/Overlay/overlay.service";
import { DotDotDotPipe } from "../../pipes/dot-dot-dot.pipe";
import { HideIDColumnsPipe } from "../../pipes/hide-idcolumns.pipe";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../pipes/mr-translate.pipe";
import { MrUnitTextPipe } from "../../pipes/mrUnitText.pipe";
import { TablePrettyPrintPipe } from "../../pipes/tablePrettyPrint.pipe";

@Component({
    selector: 'app-begehung',
    templateUrl: './begehung.component.html',
    styleUrls: ['./begehung.component.scss'],
    providers: [BackendLocaleDatePipe],
    imports: [
        NgIf,
        ClrIconModule,
        ClrDatagridModule,
        NgFor,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        NgSwitch,
        NgSwitchCase,
        TitleCasePipe,
        TablePrettyPrintPipe,
        MrUnitTextPipe,
        HideIDColumnsPipe,
        DotDotDotPipe,
        MrTranslatePipe_1,
    ],
    standalone: true
})
export class BegehungComponent implements OnInit{
  @ViewChild("objekteDatagrid", { read: ClrDatagrid }) objekteDatagrid;
  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    protected localeDate: BackendLocaleDatePipe,
    private basicStore: BasicStoreService,
    private overlayService: OverlayService,
  ) {
  }
  // private reload = new EventEmitter<any>();
  protected isconfiged: boolean = false;

  protected obegeid: number = 0;
  protected obegpid: number = 0;
  protected title: string = '';
  protected objekte:any;
  protected zuordnungTable:any;
  protected begehungTable:any;
  protected begehungHistoryTable:any;
  protected selectedBegehung: any;
  protected selectedHistoryBegehung: any;
  protected selectedZuordnungTable: any;
  protected selectedZuObjekte: any;
  protected selectedHistoryFinished: boolean = true;
  protected iconColumns: string[] = ["typ", "sperrstatus", "lfstatus", "rep", "icon"];
  protected canContinueBegehung: boolean = false;
  protected sxbepTable: any;

  subscriptions: any = [];
  refreshSubscription: Subscription;
  dataRefresh = new EventEmitter();

  async ngOnInit() {
    this.loadFormItem();
  }

  loadFormItem(){
    this.isconfiged = false;
    this.obegeid = 0;

    this.apiService
    .getBegehungsplan()
    .pipe(first())
    .subscribe((res: any) => {
      if (res?.success){
        if (res.result.obege.rows.length != 0){
          this.isconfiged = true;
          this.begehungTable = res.result.obege;
          this.zuordnungTable = res.result.sxbeg;
          this.begehungHistoryTable = res.result.obegp_history;
          this.setObjektTabelle();
        }
      }
      else
        this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    });
  }

  setObjektTabelle(){
    forkJoin({
      anlagen: this.basicStore.getComponentStoreAsync("anlagen").pipe(
        first((val) => val && JSON.parse(val).anlagen.rows.length),
        map((res: any) => JSON.parse(res)?.anlagen)
      ),
    }).subscribe((res: any) => {
      this.objekte = res.anlagen;
    });
  }

  openBegehungsEinstellungen(){
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.loadFormItem();
    });

    this.overlayService.setOverlay({
      overlay: "begehungsVerwaltung",
      zIndex: 8,
      dataRefresh: this.dataRefresh,
    });
  }

  addBegehung(){
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.loadFormItem();
    });
    this.obegpid = 0;

    this.overlayService.setOverlay({
      overlay: "begehungsDialog",
      obegeid: this.obegeid,
      obegpid: this.obegpid,
      titel: this.title,
      objekttabelle: this.selectedZuordnungTable,
      zIndex: 99999,
      dataRefresh: this.dataRefresh,
    });
  }

  continueBegehung(){
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
    this.refreshSubscription = this.dataRefresh?.subscribe(() => {
      this.loadFormItem();
    });
    this.overlayService.setOverlay({
      overlay: "begehungsDialog",
      obegeid: this.obegeid,
      obegpid: this.obegpid,
      titel: this.title,
      objekttabelle: this.selectedZuordnungTable,
      zIndex: 99999,
      dataRefresh: this.dataRefresh,
    });
  }

  selectionHistoryChanged(value: any) {
    this.canContinueBegehung = false;
    if (value != undefined){
      this.obegpid = value.ID;
      this.getsxbepTable();
      this.selectedHistoryFinished = value.Abgeschlossen;
      this.canContinueBegehung = (value.Abgeschlossen == false && value.ID != 0);
    }
  }

  getGridValue(row: any, type: string){
    let ostammid = row.OSTAMMID;

    if (row.bezeichnung == '103a'){
      let s: string = 'stop';
    }


    if (type == 'closed'){
      let index = this.sxbepTable.rows.findIndex((element) => element["OSTAMMID"] == row.OSTAMMID);
      return (index != -1);
    }
    if (type == 'date'){
      let index = this.sxbepTable.rows.findIndex((element) => element["OSTAMMID"] == row.OSTAMMID);
      if (index != -1){
        let r = this.sxbepTable.rows[index];
        return r.CompletedDate;
      }
    }
  }


  getsxbepTable(){
    let obegp = {
      status: 'getsxbep',
      obegeid: this.obegeid,
      obegpid: this.obegpid,
    };

    this.apiService
    .setBegehung(obegp)
    .pipe(first())
    .subscribe((res: any) => {

      if (res?.success){
        this.sxbepTable = res.result.sxbep;
      }
      else
        this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    });
  }


  selectionGridChanged(value: any) {
    this.obegpid = 0;
    if (value != undefined){
      this.obegeid = value.OBEGEID;
      this.title = value.Begehungsart;
      this.begehungHistoryTable.rows = [];

      this.apiService
      .getBegehungsplanHistory(value.OBEGEID)
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.success){
            this.begehungHistoryTable.rows = res.result.obegp_history.rows;
            if(this.apiService.isRep) this.begehungHistoryTable.columns = res.result.obegp_history.columns;
        }
        else
          this.toastr.error(res?.error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });

      let rows:[] = this.zuordnungTable.rows.filter((cells) => cells.OBEGEID == value.OBEGEID);
      let r = [];

      for(let zurow of rows){
        let index = this.objekte.rows.findIndex((element) => element["OSTAMMID"] == zurow["OSTAMMID"]);
        if (index > -1){
          r.push(this.objekte.rows[index]);
        }
      }

      if (this.selectedZuordnungTable == undefined){
        let zuTable = {
          columns: this.objekte.columns,
          rows: [...r],
        }
        this.selectedZuordnungTable = zuTable;
      }
      else{
        this.selectedZuordnungTable.rows = [...r];
      }
    }
  }
}
