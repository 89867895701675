import { Inject, Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, mergeMap, take } from "rxjs/operators";
import { AuthService } from "../Auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { concat, Observable, throwError } from "rxjs";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { SpinnerService } from "../Spinner/spinner.service";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";

@Injectable()
export class Logoutinterceptor implements HttpInterceptor {
  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private authservice: AuthService,
    private toastr: ToastrService,
    private router: Router,
    private spinner: SpinnerService
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): import("rxjs").Observable<HttpEvent<any>> {
    let refresh = this.authservice.getRefreshToken();
    let tokenExpired =
      parseInt(localStorage.getItem("jwt_exp")) < Math.floor(Date.now() / 1000);

    if (req.url.indexOf("api/okta/") != -1) {
      const accessToken = sessionStorage.getItem('okta-jwt-tmp')
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8')
        .set("Authorization", `Bearer ${accessToken}`);

      return next.handle(req.clone({
        headers
      })).pipe(
        catchError((err: HttpErrorResponse) => {
          return errorCatch(this.authservice, req, next, refresh, err);
        })
      );
    }

    if (
      tokenExpired &&
      Math.floor(Date.now() / 1000) < refresh.expiresAt &&
      req.url.indexOf("login") == -1 &&
      req.url.indexOf("i18n/") == -1 &&
      this.authservice.token != undefined
    ) {
      let authObs = this.authservice.refreshLogin(
        this.authservice.token.email,
        refresh.passphrase
      );

      return authObs.pipe(
        mergeMap(() => {
          return next.handle(
            req.clone({
              headers: req.headers.set(
                "Authorization",
                "Bearer " + localStorage.getItem("jwt")
              ),
            })
          );
        })
      );
    } else {
      return next
        .handle(
          req.clone({
            headers: req.headers.set(
              "Authorization",
              "Bearer " + localStorage.getItem("jwt")
            ),
          })
        )
        .pipe(
          catchError((err: HttpErrorResponse) => {
            return errorCatch(this.authservice, req, next, refresh, err);
          })
        );
    }
  }
}

function errorCatch(
  authservice: AuthService,
  req: HttpRequest<any>,
  next: HttpHandler,
  refresh: any,
  err: HttpErrorResponse
) {
  if (err.status == 401) {
    if (
      Math.floor(Date.now() / 1000) < refresh.expiresAt &&
      req.url.indexOf("login") == -1 &&
      req.url.indexOf("i18n/") == -1
    ) {
      let authObs = authservice.refreshLogin(
        authservice.getToken().email,
        refresh.passphrase
      );

      return authObs.pipe(
        mergeMap(() => {
          return next.handle(
            req.clone({
              headers: req.headers.set(
                "Authorization",
                "Bearer " + localStorage.getItem("jwt")
              ),
            })
          );
        })
      ) as Observable<HttpEvent<any>>;
    } else {
      if (req.url.indexOf("login") != -1)
        this.toastr.warning(
          this.mrTranslate.transform("Bitte erneut versuchen"),
          this.mrTranslate.transform("Falsche Logininformationen")
        );
      else {
        navigateToLogin(this.toastr, this.router);
      }
      return next.handle(
        req.clone({
          headers: req.headers.set(
            "Authorization",
            "Bearer " + localStorage.getItem("jwt")
          ),
        })
      );
    }
  } else {
    return throwError(err);
  }
}

export function navigateToLogin(
  toastr: any,
  router: any,
  route?: ActivatedRouteSnapshot,
  authservice?: AuthService
) {
  toastr.warning(
    this?.mrTranslate?.transform("Bitte loggen Sie sich erneut ein.") || "Bitte loggen Sie sich erneut ein.",
    this?.mrTranslate?.transform("Ihre Session ist abgelaufen") || "Ihre Session ist abgelaufen"
  );
  setTimeout(() => {
    //this.authservice.logout();
    if (route) authservice.redirectURL = route.url[0].path;
    router.navigateByUrl("login");
  }, 2000);
}
