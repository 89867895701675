import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ClrDatagrid } from '@clr/angular';
import { Subscription, first, forkJoin } from 'rxjs';
import { ClrCheckboxDisplayDirective } from 'src/app/directives/clrCheckboxDisplay.directive';
import { APIService } from 'src/app/services/APIService/api.service';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';

@Component({
    selector: 'app-berichte-config-modal',
    templateUrl: './berichte-config-modal.component.html',
    styleUrls: ['./berichte-config-modal.component.scss'],
    imports: [CommonModule, ClarityModule, ClrCheckboxDisplayDirective, MrTranslatePipe, FormsModule, ReactiveFormsModule, HideIDColumnsPipe, TablePrettyPrintPipe],
    providers: [MrTranslatePipe],
    standalone: true
})
export class BerichteConfigModalComponent implements OnDestroy {

  @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter();
  @Input("isOpen")
  public set isBerichteModalOpen(isOpen: boolean) {
    this._isBerichteModalOpen = isOpen;
    if (isOpen) this.getAlerts();
    else this.isOpenChange.emit(false);
  }
  public get isBerichteModalOpen() {
    return this._isBerichteModalOpen;
  }
  private _isBerichteModalOpen = false;

  protected saved = true;
  protected description: string;

  @ViewChild('userDg') private userDg: ClrDatagrid;


  protected users = {
    columns: undefined,
    rows: undefined
  }

  private _selectedUsers: any[] = [];
  protected set selectedUsers(users: any[]) {
    this._selectedUsers = users;
    const idArr: number[] = this.selectedAlert.users.idArr = users?.map(({ id }: any) => id);
    this.selectedAlert.users.nameStr = this.getUserNameJoinedString(idArr);
    this.selectedAlert.isChanged = true;
    this.saved = false;
  }
  protected get selectedUsers(): any[] {
    return this._selectedUsers;
  }


  private _selectedAlert: Alert;
  protected set selectedAlert(alert: Alert) {
    this._selectedAlert = alert;
    this.userDg.selected = this.users.rows?.filter(({ id }) => alert?.users?.idArr?.includes(id));
  }
  protected get selectedAlert(): Alert {
    return this._selectedAlert;
  }


  private _alerts: AlertMap = {};
  protected set alerts(alerts: any[]) {
    alerts.forEach((alert: any) => {
      const userArr: number[] = alert.UserList?.split(", ")?.map((id: string): number => +id);
      const userStr: string = this.getUserNameJoinedString(userArr);
      const alertObj: Alert = {
        id: alert.ID,
        active: !!alert.SendActive,
        users: { idArr: userArr || [], nameStr: userStr },
        termin: alert.HTMLText,
        format: alert.ExpFormat
      };
      this._alerts[alert.AlertID].items.push(alertObj);
      if (alert.SendActive) {
        this._alerts[alert.AlertID].count++;
        this._alerts[alert.AlertID].expanded = true;
      }
    })
  }
  protected get alerts(): AlertMap {
    return this._alerts;
  }
  private deletedAlerts: number[] = [];

  private sub: Subscription;

  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
  ) {
    this.initAlerts();
  }

  private initAlerts() {
    this.berichtsArten.flatMap(({ arten }) => arten).forEach(({ id }) => {
      this.alerts[id] = {
        expanded: false,
        count: 0,
        items: []
      };
    });
  }

  private getAlerts() {
    this.sub = forkJoin({
      users: this.apiService.getUsers().pipe(first()),
      alerts: this.apiService.getBerichte().pipe(first())
    }).subscribe(({ users, alerts }: any) => {
      this.users.columns = users.columns;
      this.users.rows = users.rows?.filter(({ UserMail, id, Nachname }) => this.userIdToNachname.set(id, Nachname) && !!UserMail);
      if (alerts?.length) this.alerts = alerts;
    });
  }

  private reloadAlerts() {
    this.apiService
      .getBerichte()
      .pipe(first())
      .subscribe((alerts: any) => {
        this.initAlerts();
        this.alerts = alerts;
      })
  }

  protected newAlert(berichtId: number) {
    const neuAlert: Alert = {
      id: null,
      termin: "0",
      active: true,
      format: 1,
      users: {
        idArr: [],
        nameStr: ""
      },
      isChanged: true
    };
    this.saved = false;
    this.alerts[berichtId]?.items?.push(neuAlert);
    this.alerts[berichtId].count++;
    this.selectedAlert = neuAlert;
  }

  protected deleteAlert(berichtId: number, alert: Alert) {
    this.alerts[berichtId]?.items.splice(this.alerts[berichtId].items.indexOf(alert), 1);
    if (alert.active) this.alerts[berichtId].count--;
    this.selectedAlert = undefined;
    this.description = undefined;
    if (alert.id) {
      this.deletedAlerts.push(alert.id);
      this.saved = false;
    }
  }

  protected toggleActive(isOn: boolean, berichtId: number, alert: Alert) {
    alert.active = isOn;
    alert.isChanged = true;
    this.saved = false;
    this.alerts[berichtId].count += isOn ? 1 : -1;
  }

  protected saveBerichteConfig() {
    const updatedAlerts = Object.entries(this.alerts)?.flatMap(
      ([alertId, { items }]: [string, AlertUI]) => items.flatMap(
        ({ isChanged, id, active, format, termin, users }: Alert) =>
          isChanged ? [{
            id: id,
            alertId: alertId,
            active: active,
            format: format,
            termin: termin,
            users: users?.idArr?.join(", "),
          }] : []
      )
    );
    this.apiService
      .setBerichte({ updated: updatedAlerts, deleted: this.deletedAlerts })
      .pipe(first())
      .subscribe(({ success, reload, error }: any) => {
        if (success) {
          this.toastr.success(this.mrTranslate.transform("Einstellungen erfolgreich gespeichert."))
          this.saved = true;
          if (reload) this.reloadAlerts();
          else Object.values(this.alerts).forEach(({ items }) =>
            items.forEach((alert: Alert) => delete alert.isChanged)
          );
          this.deletedAlerts = [];
        }
        else this.toastr.error(error, this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
      })
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private userIdToNachname = new Map<number, string>();
  private getUserNameJoinedString(userArr: number[]): string {
    return userArr?.flatMap((id: number) => this.userIdToNachname.has(id) ?
      [this.userIdToNachname.get(id)] : []
    )?.join(", ");
  }

  protected readonly sendRepeatTermin = {
    '0': 'täglich',
    '1': 'wöchentlich',
    '2': 'monatlich'
  }

  protected readonly berichtsArten = [
    {
      name: "Mängel",
      arten: [
        {
          id: 2,
          titel: "Fehlerklasse 1 nach Inspektion",
          description: "Erstellt eine Liste mit allen Mängel der Fehlerklasse 1, die bei der letzten Inspektion aufgenommen wurden."
        },
      ]
    },
    {
      name: "Termine",
      arten: [
        {
          id: 4,
          titel: "Prüftermin überschritten",
          description: "Erstellt eine Liste mit allen Objekte, bei denen der Prüftermin überschritten wurde."
        },
        {
          id: 5,
          titel: "Bestätigung durch den ALV erforderlich",
          description: "Erstellt eine Liste mit allen Objekte, welche vom Anlagenverantwortlichen zu bestätigen sind."
        },
      ]
    },
    {
      name: "Statistik",
      arten: [
        {
          id: 7,
          titel: "Fehleranzahl / Objekttyp",
          description: "Diese Funktion bietet eine statistische Auswertung von Fehleranzahl je Objekttyp."
        },
        {
          id: 8,
          titel: "Fehlerklassen / Objekttyp",
          description: "Diese Funktion bietet eine statistische Auswertung von Fehlerklassen je Objekttyp."
        },
        {
          id: 9,
          titel: "Fehlerhäufigkeit / Objekttyp",
          description: "Diese Funktion bietet eine statistische Auswertung von Fehlerhäufigkeit je Objekttyp."
        },
        {
          id: 10,
          titel: "minimale Dauer Instandsetzung pro Fehlerklasse",
          description: "Die kürzeste Dauer von der Feststellung eines Mangel bis zur dokumentierten Behebung."
          // Die Auflistung erfolgt pro Fehlerklasse
        },
        {
          id: 11,
          titel: "maximale Dauer Instandsetzung pro Fehlerklasse",
          description: "Die längest Dauer von der Feststellung eines Mangel bis zur dokumentierten Behebung."
          // Die Auflistung erfolgt pro Fehlerklasse
        },
        {
          id: 12,
          titel: "durchschnittliche Dauer Instandsetzung pro Fehlerklasse",
          description: "Die druchschnittliche Dauer von der Feststellung eines Mangel bis zur dokumentierten Behebung."
          // Die Auflistung erfolgt pro Fehlerklasse
        },
      ]
    },
  ];
}

interface Alert {
  id: number,
  active: boolean,
  termin: string,
  format: number,
  users: { idArr: number[], nameStr: string },
  isChanged?: boolean
};
interface AlertUI {
  count: number,
  expanded: boolean,
  items: Alert[],
}
interface AlertMap {
  [key: number]: AlertUI
}