import { Injectable } from '@angular/core';
import { APIService } from '../APIService/api.service';
import { map, first } from 'rxjs/operators';
import { FormulareRow } from 'src/app/content/_components/_overlays/formularerstellungsdialog/formularerstellungsdialog.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  
  firstColumnId = 'Typ';
  secondColumnId = 'FeldName';
  thirdColumnId = 'ObjektTyp';
  fourthColumnId = 'StandardWerte';
  public production: boolean = false;//environment.apiUrl.includes("devserver") ? false: true;

  constructor(private apiservice: APIService) {}


  getFormulareData(vorlageID: number) {
    return this.apiservice.getFormDataForVorlage(vorlageID).pipe(
      map(data => {
        if (Array.isArray(data)) {
          return data.map((row: FormulareRow) => {
            const reflectData = {
              table: row['AuswahlOption'],
              fieldname: row['Bezeichnung'],
              subheading: null,
              fieldType: row['Bezeichnung'],
              fieldLength: null,
              value: null
            };
            this.updateFormReflectData(row, reflectData);
            return reflectData;
          });
        }
        return [];
      })
    );
  }

 
  private updateFormReflectData(row: any, reflectData: any) {
    if (row[this.firstColumnId] === 'Überschrift') {
      reflectData.mainTitle = null;
      reflectData.title = row['FeldName'];
      reflectData.subheading = null;
    } else if (row[this.firstColumnId] === 'Normales Feld') {
      reflectData.mainTitle = null;
      reflectData.title = null;
      reflectData.subheading = row['FeldName'];
    } else if (row[this.firstColumnId] === 'Titel') {
      reflectData.mainTitle = row['FeldName'];
      reflectData.title = null;
      reflectData.subheading = null;
    }
    let columnName = row['Bezeichnung'];
    let rowName = row['AuswahlOption'] === 'Spezifikationsdaten' ? columnName: null;
    this.apiservice.getFieldType(columnName, rowName).pipe(first()).subscribe(response => {
      reflectData.fieldType = response.fieldType;
      reflectData.fieldLength = response.fieldLength;
    });
  }
}