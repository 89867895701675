import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UnitLength, UnitMeasurement, convertValueToUnit, lengthUnitColumns, measurementUnitColumns } from "../misc/functions";
import { BasicStoreService } from "../services/BasicStore/basic-store.service";
import { formatDate } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

const Bool: ['true', 'false'] = ['true', 'false'];

@Pipe({
  name: "tablePrettyPrint",
  standalone: true

})
export class TablePrettyPrintPipe implements PipeTransform {
  constructor(
    private domSanitizer: DomSanitizer,
    private basicStore: BasicStoreService,
    private translate: TranslateService
  ) { }
  transform(value: any, args?: any): any {

    const columnId = args?.column?.id
    if (columnId) {
      const settings = this.basicStore.getComponentStore("einstellung");
      if (settings && settings.einstellung) {
        if (lengthUnitColumns.includes(columnId)) {
          return convertValueToUnit(value as number, UnitLength, 0, settings.einstellung.optUnitLength, false);
        } else if (measurementUnitColumns.includes(columnId)) {
          return convertValueToUnit(value as number, UnitMeasurement, 0, settings.einstellung.optUnitWidth, false);
        }
      }
    }
    /*
    // NUMBER AS STRING
    if (typeof value == "string") {
      // Check if the entire string is a valid number
      if (/^[+-]?\d+(\.\d+)?$/.test(value)) {
        // Replace commas with dots
        let copy: any = value.replace(",", ".");

        // Parse as a float
        let parsedValue = parseFloat(copy);

        // Check if the parsed value is a valid number
        if (!Number.isNaN(parsedValue)) {
          value = parsedValue;
        }
      }
    }
    */

    // NUMBER
    if (typeof value == "number") {
      let str = value.toString();
      let dot = str.indexOf(".");
      if (dot != -1 && str.slice(dot).length > 3) {
        return round(value, 3).toString().replace(".", ",");
      } else if (dot != -1) {
        return value.toString().replace(".", ",");
      }
    }

    if (!args || (args && args.icons !== false)) {
      if (args?.column?.id == 'lfstatus') {
        return this.getLfstatusIcon(value);
      }
      if (args?.column?.id == 'sperrstatus' || args?.column?.id == 'osperrstatus') {
        return this.getSperrstatusIcon(value);
      }

      if (typeof value == "boolean" || Bool.includes(value)) {

        if (value !== 'false' && (value || value == 'true'))
          return this.domSanitizer.bypassSecurityTrustHtml('<clr-icon shape="success-standard" class="is-solid" style="fill: #2EC0FF;"></clr-icon>');
        else
          return this.domSanitizer.bypassSecurityTrustHtml('<clr-icon shape="circle"></clr-icon>');
      }

    } else {
      if (value == "true" || value == '"true"' || value === "true") return 1;
      if (value == "false" || value == '"false"' || value === "false") return 0;
      if (typeof value == "boolean") return value ? 1 : 0;
    }

    if (
      value &&
      typeof value == "string" &&
      (value.length == 25 || value.length == 19 || value.length == 10) &&
      Date.parse(value) && (value.includes(".") || value.includes("-"))
    ) {
      const dateFormat = value.includes('00:00:00') ? 'shortDate' : 'short';
      return formatDate(value, dateFormat, this.translate.currentLang);
    }

    if (value === "" || value === null || value === undefined) {
      return "";
    }
    return String(value);
  }

  private getLfstatusIcon(value: string) {
    // const value = row['lfstatus'];
    let iconShape = 'minus';
    if (value) {
      const num = Number(value);
      if (num && num > 0) {
        iconShape = 'lastelle' + (num / 10).toString().replace('.', '_').replace(',', '_');
      }
    }
    return this.domSanitizer.bypassSecurityTrustHtml(`<clr-icon shape="${iconShape}" class="customIcon iconcolor" size="28"></clr-icon>`)
    // return 'minus';
  }


  private getSperrstatusIcon(value: string) {
    // row['sperrstatus'] == 1 ? 'gesperrt' : 'minus'
    const iconShape = value == "1" ? 'gesperrt' : 'minus';
    // const value = row['lfstatus'];
    // let iconShape = 'minus';
    // if(value) {
    //   const num = Number(value);
    //   if(num && num > 0){
    //     iconShape = 'lastelle'+(num/10).toString().replace('.', '_').replace(',', '_');
    //   }
    // }
    return this.domSanitizer.bypassSecurityTrustHtml(`<clr-icon shape="${iconShape}" class="customIcon iconcolor" size="28"></clr-icon>`)
    // return 'minus';
  }
}

function round(value: number, precision: number) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

