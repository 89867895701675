import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import * as Plotly from "plotly.js-basic-dist-min";
import { getRandomInt } from "src/app/misc/functions";
@Component({
    selector: "app-plotly-plot",
    templateUrl: "./plotly-plot.component.html",
    styleUrls: ["./plotly-plot.component.scss"],
    standalone: true
})
export class PlotlyPlotComponent implements AfterViewInit, OnDestroy {
  _id: number;
  @Output() plotID = new EventEmitter<string>();
  @Input() data: any;
  @Input() layout: any;
  @Input() config: any;
  @Input() delay: number;
  private window: Window = window;
  constructor() {
    this._id = getRandomInt(1000000);
  }

  ngAfterViewInit(): void {
    if (Plotly !== undefined) {
      const divID = "plotly-plot-" + this._id;
      if (this.delay && this.delay > 0) {
        setTimeout(() => {
          this.createPlot(divID);
        }, this.delay);
      } else {
        this.createPlot(divID);
      }
      this.plotID.emit(divID);
      this.window.onresize = () => Plotly.Plots.resize(divID);
    }
  }

  createPlot(divID) {
    return Plotly.newPlot(divID, this.data, this.layout, this.config);
  }

  ngOnDestroy(): void {
    this.window.onresize = undefined;
  }
}
