import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundTo',
  standalone: true

})
export class RoundToPipe implements PipeTransform {

  transform(value: any, decimalAfterPoint: number): any {
    if (value && decimalAfterPoint) {

      return parseFloat(value).toFixed(decimalAfterPoint);
    }
    return value;
  }

}
