import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ClrCommonFormsModule, ClrDatepickerModule, ClrForm, ClrInputModule, ClrModalModule, ClrPopoverHostDirective, ClrSelectModule, ClrStopEscapePropagationDirective } from '@clr/angular';
import dayjs from 'dayjs';

import { ToastrService } from 'ngx-toastr';
import { Subscription, first, pairwise } from 'rxjs';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { AutofocusDirective } from '../../../../directives/autofocus.directive';

export type HerstellDaten = {
  ID: number;
  Hersteller: string;
  Herstelldatum: string; // DATE
  Einbaufirma: string;
  Einbaudatum: string; // DATE
  Erneuerungsjahr: string; // YYYY
  Nutzungsdauer: number;
  DauerGewaehrleistung: number;
  EinheitGewaehrleistung: "Tag" | "Monat" | "Jahr"; // Tag, Monat, Jahr
  EndeGewaehrleistung: string; // DATE
};

@Component({
    selector: 'app-herstell-daten-modal',
    templateUrl: './herstell-daten-modal.component.html',
    styleUrls: ['./herstell-daten-modal.component.scss'],
    providers: [BackendLocaleDatePipe],
    imports: [
        ClrModalModule,
        FormsModule,
        ClrCommonFormsModule,
        ReactiveFormsModule,
        ClrInputModule,
        AutofocusDirective,
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrDatepickerModule,
        ClrSelectModule,
        MrTranslatePipe,
        BackendLocaleDatePipe,
    ],
    standalone: true
})
export class HerstellDatenModalComponent implements OnDestroy {
  /** @emits true when saved, for reload */
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();

  @Input() protected disable: boolean;

  @Input() private set ospezid(value: number) {
    this._ospezid = value;
    this.apiService
      .getHerstelldaten(this.ospezid)
      .pipe(first())
      .subscribe((herstelldaten: HerstellDaten) => {
        this._id = herstelldaten?.ID;
        this.herstellDatenForm.patchValue(herstelldaten);
        this.catchChanges();
        this.open = true;
      });
  }
  private get ospezid(): number {
    return this._ospezid;
  }
  private _ospezid: number;
  private _id: number;

  protected set open(isOpen: boolean) {
    this._isOpen = isOpen;
    if (!isOpen) this.onClose.emit(false);
  }
  protected get open() {
    return this._isOpen;
  }
  private _isOpen = false;

  protected herstellDatenForm = new UntypedFormGroup({
    Hersteller: new UntypedFormControl("", [Validators.required]),
    Herstelldatum: new UntypedFormControl(BackendLocaleDatePipe.now, [Validators.required]),
    Einbaufirma: new UntypedFormControl("", [Validators.required]),
    Einbaudatum: new UntypedFormControl(BackendLocaleDatePipe.now, [Validators.required]),
    Erneuerungsjahr: new UntypedFormControl("", [Validators.required]),
    Nutzungsdauer: new UntypedFormControl("", [Validators.required]),
    DauerGewaehrleistung: new UntypedFormControl(2, [Validators.required]),
    EinheitGewaehrleistung: new UntypedFormControl("Jahr", [Validators.required]),
    EndeGewaehrleistung: new UntypedFormControl(
      dayjs().add(2, 'years').format(BackendLocaleDatePipe.format),
      [Validators.required]
    ),
  });

  @ViewChild(ClrForm, { static: false })
    private clrForm: ClrForm
  ;
  private sub: Subscription;

  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    protected localeDate: BackendLocaleDatePipe
  ) {}

  private catchChanges() {
    this.sub = this.herstellDatenForm.valueChanges.pipe(pairwise()).subscribe(
      ([old, {
        Einbaudatum: start,
        DauerGewaehrleistung: dauer,
        EinheitGewaehrleistung: einheit
      }]: HerstellDaten[]) => {
        if (
          old.Einbaudatum != start ||
          old.DauerGewaehrleistung != dauer ||
          old.EinheitGewaehrleistung != einheit
        )
          this.calcEndeGewährleistung(start, dauer, einheit);
      }
    );
  }

  protected saveIfValid() {
    this.clrForm.markAsTouched();
    if (this.herstellDatenForm.valid) this.saveHerst();
  }

  private saveHerst() {
    if (this.herstellDatenForm.pristine) this.onClose.emit(false);
    else this.apiService
      .setHerstelldaten(this.ospezid, {data: this.herstellDatenForm.value, ID: this._id})
      .pipe(first())
      .subscribe(({success, error}: any) => {
        if (success) {
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
          this.onClose.emit(true);
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }

  private calcEndeGewährleistung(start: string, dauer: number, einheit: string) {
    einheit = einheit  == "Tag" ? "days" : einheit == "Monat" ? "months" : "years";
    this.herstellDatenForm.get("EndeGewaehrleistung").setValue(
      dayjs(start || undefined).add(
        dauer ?? 2, <dayjs.ManipulateType> einheit
      ).format(BackendLocaleDatePipe.format)
    );
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

}