import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/services/Auth/auth.service";
import { MrTranslatePipe } from "../../../../../../pipes/mr-translate.pipe";
import { CurrencyPipe } from "@angular/common";
import { ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrButtonGroupModule, ClrIconModule, ClrTreeViewModule } from "@clr/angular";

export interface Stack {
  Kurztext: string;
  Langtext: string;
  children?: Stack[];
}

export interface ActionEvent {
  action: "add" | "edit" | "delete" | "addLV" | "editLV";
  data: any;
}

@Component({
    selector: "app-fremdleistung-stack-view",
    templateUrl: "./fremdleistung-stack-view.component.html",
    styleUrls: ["./fremdleistung-stack-view.component.scss"],
    imports: [
        ClrStopEscapePropagationDirective,
        ClrPopoverHostDirective,
        ClrButtonGroupModule,
        ClrIconModule,
        ClrTreeViewModule,
        CurrencyPipe,
        MrTranslatePipe,
    ],
    standalone: true
})

export class FremdleistungStackViewComponent implements OnInit, OnDestroy {
  @Input() root: Stack;

  @Input() deleteSelection: BehaviorSubject<void>;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() action: EventEmitter<ActionEvent> = new EventEmitter();

  selected: any = undefined;
  titleSelected = () => this.selected?.Titel_ID === 0;
  $destroy: ReplaySubject<boolean> = new ReplaySubject(1);

  trial = false;
  constructor(private authService: AuthService) {
    this.trial = this.authService.getToken().trial;
  }

  ngOnInit(): void {
    console.log(this.root);
    this.deleteSelection.pipe(takeUntil(this.$destroy)).subscribe((_) => {
      this.selected = undefined;
    });
  }

  ngOnDestroy(): void {
    this.$destroy.next(true);
    this.$destroy.complete();
  }

  doAction(action: "add" | "edit" | "delete" | "addLV" | "editLV") {
    this.action.emit({ action, data: this.selected });
  }

  getChildren = (leistung) => leistung.children;

  toFixedLength = (
    str: string,
    length: number,
    spacesInFront: boolean = false
  ) => {
    if (str.length > length) {
      return str.slice(0, length - 3) + "...";
    } else if (str.length < length && !spacesInFront) {
      return str + "&nbsp;".repeat(length - str.length);
    } else {
      return "&nbsp;".repeat(length - str.length) + str;
    }
    return str;
  };

  select = (leistung: any) => {
    // if (leistung.children) {
    //   return;
    // }
    if (this.selected?.ID === leistung.ID) {
      this.selected = undefined;
    } else {
      this.selected = leistung;
      this.selectionChange.emit(leistung);
    }
  };
}
