<div class="overlay-inner-wrapper">
  <ng-container *ngIf="!isInspektion">
    <header class="header-2">
      <div class="branding">
        <span class="title">{{titel | mrTranslate}}</span>
      </div>
      <div class="header-actions">
        <a href="javascript:void(0);" class="nav-link nav-icon">
          <clr-icon shape="times" (click)="close()"></clr-icon>
        </a>
      </div>
    </header>
  </ng-container>

  <div id="spezifikationsWrapper" class="w-100-p padding-10-lr padding-12-b scroll-y"
    [ngClass]="isInspektion ? 'h-100' :  'h-60'">
    <ng-container *ngIf="(typ_Line_Point | objtype) == 'line'; else punktobjekt">
      <div #heightRef>
        <div class="clr-row nxm" style="align-items: center;">
          <div class="clr-col-auto margin-12-tb">
            <div class="btn-group btn-primary btn-icon">
              <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
                (click)="addSpez()" [disabled]="setInsert">
                <clr-icon shape="file"></clr-icon>
              </button>
              <!-- <button class="btn btn-icon" [title]="'Einfügen' | mrTranslate"
                (click)="insertSpez()" [disabled]="!setInsert">
                <clr-icon shape="add-text"></clr-icon>
              </button> -->
              <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
                (click)="deleteSpezLine()" [disabled]="!selectedSpez">
                <clr-icon shape="times"></clr-icon>
              </button>
              <button class="btn btn-icon margin-12-l" [title]="'Speichern' | mrTranslate"
                (click)="ngForm.ngSubmit.emit()" [disabled]="!(selectedSpez || setInsert)">
                <clr-icon shape="floppy"></clr-icon>
              </button>
            </div>
          </div>
          <div class="clr-col-auto margin-12-tb">
            <clr-toggle-container clrInline class="margin-0-t">
              <clr-toggle-wrapper>
                <label> <clr-icon shape="add-text"></clr-icon>
                  {{ "Smart Einfügen" | mrTranslate }}
                </label>
                <input type="checkbox" clrToggle [name]="'insert'" [disabled]="!setInsert"
                  [(ngModel)]="isSmartInsert" [ngModelOptions]="{standalone: true}"/>
              </clr-toggle-wrapper>
            </clr-toggle-container>
          </div>
          <div class="clr-col-auto margin-12-tb" style="height: auto;">
            <span class="label" [class]="isComplete ? 'label-success' : 'label-danger'" >
              <clr-icon [attr.shape]="isComplete ? 'success-standard' : 'error-standard'"
                class="is-solid margin-6-r" style="fill: currentColor;"></clr-icon>
              <span class="text"> {{ "Vollständigkeit" | mrTranslate }} {{startmeter}} - {{endmeter}} </span>
            </span>
          </div>
        </div>
        <!-- Top-Sektion - Felder zum eintragen -->
        <form [formGroup]="formSpezTop" ngForm (ngSubmit)="saveIfValid()"
          clrForm clrLayout="vertical" class="width100 padding-12-lr" [clrLabelSize]="12">
          <div *ngIf="formSpezTop.errors?.['not-in-Direction'] as err"
            class="clr-row nxm clr-col-12 clr-col-sm-10 clr-col-md-8 clr-col-lg-6">
            <clr-alert class="w-100-p" clrAlertType="danger" [clrAlertClosable]="false">
              <clr-alert-item>
                <span class="alert-text">
                  {{ "Der Startmeter muss " + (err.upwards ? "kleiner" : "größer") + " als der Endmeter sein" | mrTranslate }}
                </span>
              </clr-alert-item>
            </clr-alert>
          </div>
          <div class="clr-row nxm">
            <ng-container *ngTemplateOutlet="!drehung.isOn ? start : end"></ng-container>
            <ng-container *ngTemplateOutlet="!drehung.isOn ? end : start"></ng-container>
            <ng-template #start>
              <div class="clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6">
                <clr-input-container>
                  <label for="Startmeter">{{ (!drehung.isOn ? "Startmeter" : "Endmeter") | mrTranslate }}</label>
                  <input clrInput formControlName="Startmeter"
                    [value]="formSpezTop.get('Startmeter').value | drehung: drehung.summe"
                    (change)="formSpezTop.get('Startmeter').setValue(
                      drehungPipe.back($event.target.value, drehung.summe)
                    )" name="Startmeter" type="number" [step]="0.1"/>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'min'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'kleiner' : 'größer') + " als" | mrTranslate }} {{err.min | drehung: drehung.summe}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'max'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'größer' : 'kleiner') + " als" | mrTranslate }} {{err.max | drehung: drehung.summe}}
                  </clr-control-error>
                </clr-input-container>
              </div>
            </ng-template>
            <ng-template #end>
              <div class="clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6">
                <clr-input-container>
                  <label for="Endmeter">{{ (!drehung.isOn ? "Endmeter" : "Startmeter") | mrTranslate }}</label>
                  <input clrInput formControlName="Endmeter" 
                    [value]="formSpezTop.get('Endmeter').value | drehung: drehung.summe"
                    (change)="formSpezTop.get('Endmeter').setValue(
                      drehungPipe.back($event.target.value, drehung.summe)
                    )" name="Endmeter" type="number" [step]="0.1"/>
                  <clr-control-error *clrIfError="'required'">
                    {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'min'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'kleiner' : 'größer') + " als" | mrTranslate }} {{err.min | drehung: drehung.summe}}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'max'; error as err">
                    {{ "Der Wert ist " + (!drehung.isOn ? 'größer' : 'kleiner') + " als" | mrTranslate }} {{err.max | drehung: drehung.summe}}
                  </clr-control-error>
                </clr-input-container>
              </div>
            </ng-template>
            <div *ngIf="typ_Line_Point == 4" class="clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6">
              <clr-input-container>
                <label for="code">{{ "Code" | mrTranslate }}</label>
                <input clrInput formControlName="Code" name="code" [tabIndex]="-1" type="text"
                  style="pointer-events: none; cursor: default;" />
                <clr-control-error *clrIfError="'required'">
                  {{"Wählen Sie eine Vorlage aus" | mrTranslate}}
                </clr-control-error>
              </clr-input-container>
            </div>
            <div *ngFor="let option of spezOpt" class="clr-col-lg-2 clr-col-md-3 clr-col-sm-4 clr-col-6">
              <ng-container [ngSwitch]="option.fieldType">
                <ng-container *ngSwitchCase="'str'">
                  <!-- ! STRIKT Texteingabefeld nur vom Tabelle -->
                  <ng-container *ngIf="option.sField; else datalist">
                    <div class="clr-row nxm" style="flex-wrap: nowrap;">
                      <div class="clr-col padding-0-l">
                        <clr-select-container>
                          <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;"
                            [for]="option.objekt"> {{ option.objekt | mrTranslate }} </label>
                          <select clrSelect [inTableValidator]="option.wListe" [required]="!!option.pflichtFeld"
                            [formControlName]="option.objekt" [name]="option.objekt">
                            <option *ngIf="!option.pflichtFeld" value=""></option>
                            <ng-container *ngTemplateOutlet="optionRed;
                              context: {$implicit: formSpezTop?.value[option.objekt]}">
                            </ng-container>
                            <ng-template #optionRed let-value>
                              <option *ngIf="value && !option.wListe.includes(value)"
                                [value]="value" class="invalidRed">{{ value }}</option>
                            </ng-template>
                            <option *ngFor="let wert of option.wListe" [value]="wert">{{ wert }}</option>
                          </select>
                          <clr-control-error *clrIfError="'not-in-Table'; error as err">
                            {{err | mrTranslate}}
                          </clr-control-error>
                          <clr-control-error *clrIfError="'required'">
                            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                          </clr-control-error>
                        </clr-select-container>
                      </div>
                      <div class="clr-col-2 margin-42-t nxp" style="text-align: right;">
                        <clr-icon [title]="'Liste bearbeiten' | mrTranslate" shape="pencil" size="20"
                          (click)="openTabelle(option)"
                          [style.color]="!(selectedSpez || setInsert) ? 'lightgrey' : 'initial'"></clr-icon>
                      </div>
                    </div>
                  </ng-container>
                  <!-- * FREI Texteingabefeld mit den bisherigen Werten -->
                  <ng-template #datalist>
                    <clr-datalist-container><!-- </clr-datalist-container> style="margin-top: 0px;"> -->
                      <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;" [for]="option.objekt"
                        class="clr-col-12 clr-col-md-12">{{ option.objekt | mrTranslate }}</label>
                      <input clrDatalistInput type="search" class="clr-col-12 clr-col-md-12"
                        [formControlName]="option.objekt" [name]="option.objekt" [required]="!!option.pflichtFeld" />
                      <datalist>
                        <option *ngFor="let wert of option.wListe" [value]="wert"></option>
                      </datalist>
                      <clr-control-error *clrIfError="'required'">
                        {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                      </clr-control-error>
                    </clr-datalist-container>
                  </ng-template>
                </ng-container>
                <!-- Zahleneingabefeld number -->
                <ng-container *ngSwitchCase="'dbl'">
                  <ng-container *ngTemplateOutlet='number; context: {float: true}'></ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'int'">
                  <ng-container *ngTemplateOutlet='number; context: {float: false}'></ng-container>
                </ng-container>
                <ng-template #number let-float="float">
                  <clr-input-container>
                    <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;"
                      [for]="option.objekt"> {{ option.objekt | mrTranslate }} </label>
                    <input clrInput [name]="option.objekt" type="number" [formControlName]="option.objekt"
                      [step]="float ? 0.1 : 1" [required]="!!option.pflichtFeld" />
                    <clr-control-error *clrIfError="'required'">
                      {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                    </clr-control-error>
                  </clr-input-container>
                </ng-template>
                <!-- Ja/Nein Einegabe -->
                <ng-container *ngSwitchCase="'chk'">
                  <clr-toggle-container>
                    <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;"
                      [for]="option.objekt"> {{ option.objekt | mrTranslate }} </label>
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [name]="option.objekt" [formControlName]="option.objekt"/>
                    </clr-toggle-wrapper>
                  </clr-toggle-container>
                </ng-container>
                <!-- Datum -->
                <ng-container *ngSwitchCase="'dat'">
                  <clr-date-container>
                    <label style="text-wrap: nowrap; text-overflow: ellipsis; overflow: hidden;"
                      [for]="option.objekt"> {{ option.objekt | mrTranslate }} </label>
                    <input clrDate [name]="option.objekt" autocomplete="off" type="date"
                      [ngModel]="formSpezTop.controls[option.objekt].value | localeDate"
                      (ngModelChange)="formSpezTop.controls[option.objekt].setValue(localeDate.tosql($event))"
                      [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                      [disabled]="formSpezTop.disabled"/>
                  </clr-date-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </form>
        <clr-datagrid #spezTableGrid *ngIf="table" class="datagrid-compact dg-overflow-fix"
          [(clrDgSingleSelected)]="selectedSpez" [clrDgRowSelection]="true"
          [style.maxHeight]="typ_Line_Point == 4 ? '25svh' : 'none'">
          <clr-dg-column [clrDgField]="(!drehung.isOn ? 'Startmeter' : 'Endmeter') + '.value'" 
            [clrDgSortOrder]="direction * (drehung.isOn ? -1 : 1)">
            {{ 'Startmeter' | titlecase | mrTranslate }}
          </clr-dg-column>
          <clr-dg-column [clrDgField]="(!drehung.isOn ? 'Endmeter' : 'Startmeter') + '.value'">
            {{ 'Endmeter' | titlecase | mrTranslate }}
          </clr-dg-column>
          <clr-dg-column *ngFor="let column of table['columns'] | array:'filter': exceptStartEnd" [clrDgField]="column.id+'.value'">
            <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns}">
              {{ column.id | titlecase | mrTranslate }}
            </ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of table['rows']" [clrDgItem]="row">
            <clr-dg-cell class="name-cell" [innerHTML]="row[(!drehung.isOn ? 'Startmeter' : 'Endmeter')]?.value | drehung: drehung.summe | tablePrettyPrint">
            </clr-dg-cell>
            <clr-dg-cell class="name-cell" [innerHTML]="row[(!drehung.isOn ? 'Endmeter' : 'Startmeter')]?.value | drehung: drehung.summe | tablePrettyPrint">
            </clr-dg-cell>
            <clr-dg-cell class="name-cell" *ngFor="let cell of table['columns'] | array:'filter': exceptStartEnd"
              [innerHTML]="row[cell.id]?.value | tablePrettyPrint">
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>{{table['rows']?.length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
      </div>

      <clr-datagrid #templateTableGrid *ngIf="table && tableTmpl && typ_Line_Point == 4"
        class="datagrid-compact dg-overflow-fix" [(clrDgSingleSelected)]="selectedTmpl"
        style="min-height: max(25svh, 120px);" [dymanicStyle]="heightRef" addPx="10"
        [clrDgRowSelection]="!!(selectedSpez || setInsert)">
        <clr-dg-column *ngFor="let column of tableTmpl['columns']" [clrDgField]="column.id"
          [hidden]="column.id | hideIDColumns:['Bild64','schienenformid','schwellenartid','schienenbefestigungid','eindeckungid']">
          {{ column.id | titlecase | mrTranslate }}
        </clr-dg-column>

        <clr-dg-row *clrDgItems="let row of tableTmpl['rows']" [clrDgItem]="row"
          [clrDgSelectable]="!!selectedSpez || setInsert" id="t-{{row.Bezeichnung}}">
          <clr-dg-cell class="name-cell" *ngFor="let cell of tableTmpl['columns']"
            [hidden]="cell.id | hideIDColumns:['Bild64','schienenformid','schwellenartid','schienenbefestigungid','eindeckungid']"
            [innerHTML]="row[cell.id] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>{{tableTmpl['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
      </clr-datagrid>

    </ng-container>
    <ng-template #punktobjekt>
      <div class="clr-row nxm">
        <div style="width: 100%; max-width: 1200px; margin: 0 auto;">
          <form #spezFormPoint clrForm ngForm (ngSubmit)="saveIfValid()"
            class="width100 margin-12-t" clrLabelSize="12">
            <table class="table margin-0-t table-compact nowrap">
              <thead style="position: sticky; top: 0; z-index: 3;">
                <tr>
                  <th class="left">{{'Objekt' | mrTranslate}}</th>
                  <th class="left" style="width: 50%;">{{'Bezeichnung' | mrTranslate}}</th>
                  <th>{{'Tabelle' | mrTranslate}}</th>
                  <th>{{'Pflicht' | mrTranslate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let spez of spezData" [hidden]="spez.objekt == 'OSPEZID'">
                  <ng-container *ngIf="!spez.isHeader; else header">
                    <td class="left">
                      {{ spez.objekt | mrTranslate }}
                    </td>
                    <td [ngSwitch]="spez.fieldType">
                      <ng-container *ngSwitchCase="'str'">
                        <!-- ! STRIKT Texteingabefeld nur vom Tabelle -->
                        <ng-container *ngIf="spez.sField; else datalist">
                          <clr-select-container>
                            <select clrSelect [name]="spez.objekt" [(ngModel)]="spez.bezeichnung"
                              [required]="!!spez.pflichtFeld" [inTableValidator]="spez.wListe">
                              <option *ngIf="!spez.pflichtFeld" value=""></option>
                              <option *ngIf="spez.bezeichnung && !spez.wListe.includes(spez.bezeichnung)"
                                [value]="spez.bezeichnung" class="invalidRed">{{ spez.bezeichnung }}</option>
                              <option *ngFor="let wert of spez.wListe" [value]="wert">{{ wert }}</option>
                            </select>
                            <clr-control-error *clrIfError="'not-in-Table'; error as err">
                              {{err | mrTranslate}}
                            </clr-control-error>
                            <clr-control-error *clrIfError="'required'">
                              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                            </clr-control-error>
                          </clr-select-container>
                        </ng-container>
                        <!-- * FREI Texteingabefeld mit den bisherigen Werten -->
                        <ng-template #datalist>
                          <clr-datalist-container>
                            <input class="clr-col-12" clrDatalistInput type="search" [name]="spez.objekt"
                              [(ngModel)]="spez.bezeichnung" [required]="!!spez.pflichtFeld" />
                            <datalist>
                              <option *ngFor="let wert of spez.wListe" [value]="wert"></option>
                            </datalist>
                            <clr-control-error *clrIfError="'required'">
                              {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                            </clr-control-error>
                          </clr-datalist-container>
                        </ng-template>
                      </ng-container>
                      <!-- Zahleneingabefeld number -->
                      <ng-container *ngSwitchCase="'dbl'">
                        <ng-container *ngTemplateOutlet='number; context: {float: true}'></ng-container>
                      </ng-container>
                      <ng-container *ngSwitchCase="'int'">
                        <ng-container *ngTemplateOutlet='number; context: {float: false}'></ng-container>
                      </ng-container>
                      <ng-template #number let-float="float">
                        <clr-input-container>
                          <input clrInput [name]="spez.objekt" [(ngModel)]="spez.bezeichnung" type="number"
                            [step]="float ? 0.1 : 1" [required]="!!spez.pflichtFeld" />
                          <clr-control-error *clrIfError="'required'">
                            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                          </clr-control-error>
                        </clr-input-container>
                      </ng-template>
                      <!-- Ja/Nein Einegabe -->
                      <ng-container *ngSwitchCase="'chk'">
                        <clr-toggle-container>
                          <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle [name]="spez.objekt" [(ngModel)]="spez.bezeichnung"/>
                          </clr-toggle-wrapper>
                        </clr-toggle-container>
                      </ng-container>
                      <!-- Datum -->
                      <ng-container *ngSwitchCase="'dat'">
                        <clr-date-container>
                          <input clrDate [name]="spez.objekt" autocomplete="off" type="date"
                            [required]="!!spez.pflichtFeld" [ngModel]="spez.bezeichnung | localeDate"
                            (ngModelChange)="spez.bezeichnung = localeDate.tosql($event)"
                            [ngModelOptions]="{updateOn: 'blur'}"/>
                          <clr-control-error *clrIfError="'required'">
                            {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
                          </clr-control-error>
                        </clr-date-container>
                      </ng-container>
                    </td>
                    <td>
                      <clr-icon *ngIf="spez.sField" [title]="'Liste bearbeiten' | mrTranslate" shape="pencil" size="20"
                        (click)="openTabelle(spez)"></clr-icon>
                    </td>
                    <td>
                      <clr-icon *ngIf="spez.pflichtFeld" shape="success-standard" class="is-solid"
                        style="fill: #2EC0FF;"></clr-icon>
                    </td>
                  </ng-container>
                  <ng-template #header>
                    <th class="headerRow left">
                      {{ spez.objekt | mrTranslate }}
                    </th>
                    <th class="headerRow"></th>
                    <th class="headerRow"></th>
                    <th class="headerRow"></th>
                  </ng-template>
                </tr>
              </tbody>
            </table>
          </form>

          <ng-container *ngIf="!isInspektion">
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="ngForm.ngSubmit.emit()">{{'Speichern' | mrTranslate}}</button>
            </div>
          </ng-container>
        </div>
      </div>

    </ng-template>

    <app-basedatamodal *ngIf="isBasedataModalOpen"></app-basedatamodal>
  </div>
</div>

<clr-modal #notCompleteModal [clrModalSize]="'md'">
  <h3 class="modal-title">{{"Spezifikationen sind unvollständig!" | mrTranslate}}</h3>
  <div class="modal-body">
    <p>
      {{ "Die Spezifikationen müssen das Objekt vollständig umfassen!" | mrTranslate }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="notCompleteModal.close()">
      {{ "Bearbeiten" | mrTranslate }}
    </button>
  </div>
</clr-modal>
