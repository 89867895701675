<div class="clr-row nxm h-100" #datagridwrapper>
  <div class="h-100 padding-10-b"
    [ngStyle]="{'width': ((!showdetailview || hidedetailview) ? '100%' : (split50 ? '50%' : '66.66%'))}">
    <div id="toolbar" class="clr-row nxm" style="justify-content: space-between">
      <clr-button-group>
        <clr-button class="btn-sm" [title]="'Alle auswählen' | mrTranslate" (click)="select('all')">
          {{'Alle' | mrTranslate}} <clr-icon shape="check-circle" size="21"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [title]="'Alle abwählen' | mrTranslate" (click)="select('all_not')">
          {{'Alle' | mrTranslate}} <clr-icon shape="times-circle" size="16"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [title]="'Seite auswählen' | mrTranslate" (click)="select('page')">
          {{'Seite' | mrTranslate}} <clr-icon shape="check-circle" size="21"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [title]="'Seite abwählen' | mrTranslate" (click)="select('page_not')">
          {{'Seite' | mrTranslate}} <clr-icon shape="times-circle" size="16"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [title]="'Auswahl invertieren' | mrTranslate" (click)="select('invert')">
          {{'Auswahl invertieren' | mrTranslate}} <clr-icon class="mobile inline" shape="switch"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [title]="'Alle Filter zurücksetzen' | mrTranslate" (click)="select('clear_filters')"
          [disabled]="isResettingFilters">
          {{'alle Filter löschen' | mrTranslate}} <clr-icon shape="undo" size="16"></clr-icon>
        </clr-button>
      </clr-button-group>
      <clr-button-group>
        <clr-button class="btn-sm" [title]="'CSV Export' | mrTranslate" (click)="exportCSV()"
          [disabled]="!selected?.length">{{'CSV Export' |
          mrTranslate}}
          <clr-icon class="mobile inline" shape="export"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [title]="'Auftrag erstellen' | mrTranslate" (click)="isMultiAuftragModalOpen = true"
          [disabled]="!selected?.length || authService.token.rights.status == 1 || activeTab.done == true || activeTab.auftrag == true">
          {{'Auftrag' | mrTranslate}}
          <clr-icon class="mobile inline" shape="pencil"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [title]="'Rückmeldung anlegen' | mrTranslate" (click)="isMultiRueckModalOpen = true"
          [disabled]="!selected?.length || authService.token.rights.status == 1 || activeTab.done == true">
          {{'Rückmeldung' | mrTranslate}}
          <clr-icon class="mobile inline" shape="redo"></clr-icon>
        </clr-button>
        <clr-button class="btn-sm" [class]="allInspections ? 'btn-success' : ''"
          [title]="'alle Inspektionen' | mrTranslate" (click)="reloadMangel(true)">
          {{'alle Inspektionen' | mrTranslate}}
          <clr-icon class="mobile inline" shape="redo"></clr-icon>
        </clr-button>
      </clr-button-group>
    </div>

    <div id="maengeltabs">
      <ng-template #loadTmp>
        <div class="flexCenterWrapper">
          <clr-spinner>Lädt ...</clr-spinner>
        </div>
      </ng-template>
      <ng-container *ngIf="!loading; else loadTmp">
        <ng-template #mediaCell let-row>
          <a href="javascript:void(0);" (click)="mangelMedia = media.openMangelMedia(row.mangelfoto, row.rueckbilder)"
            [class.deactivate]="!row.mangelfoto && !row.rueckbilder">
            <clr-icon shape="image-gallery" size="20"></clr-icon>
          </a>
        </ng-template>

        <clr-tabs>

          <!-- 1. TAB: OFFENE -->
          <clr-tab>
            <button clrTabLink (click)="tabChange()" [title]="'Offene' | mrTranslate"
              [attr.data-test-id]="'tab-maengel-open'">{{'Offene' |
              mrTranslate | titlecase}}</button>
            <ng-template [(clrIfActive)]="activeTab['open']">
              <clr-tab-content>
                <clr-datagrid *ngIf="dataOpen" [(clrDgSelected)]="selected" class="datagrid-compact"
                  [attr.data-test-id]="'datagrid-open'">
                  <clr-dg-column style="min-width: 50px; width: 60px;">{{ "Medien" | mrTranslate }}</clr-dg-column>
                  <clr-dg-column *ngFor="let column of tableOpen['columns']" [clrDgField]="column.id"
                    [attr.id]="'datagrid-open-col-' + normalizeId(column.id)"
                    [style.min-width.px]="getColumnWidth(column.id)"
                    [attr.data-test-id]="'datagrid-open-col-' + column.id">
                    <clr-dg-filter (clrDgFilterOpenChange)="onFilterOpenChange($event, column)"
                      [attr.data-test-id]="'datagrid-open-col-' + column.id">
                      <app-column-filter tableName="open" [column]="column"
                        [uniqueColumnValues]="getUniqueValuesForColumn(tableOpenUniqueColumnValues, column.id)"
                        (changes)="onOpenFilterChange($event)" />
                    </clr-dg-filter>
                    <ng-container
                      *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['mangelfoto', 'rueckbilder']}">{{
                      column.id | titlecase | mrUnitText }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of dataOpen; trackBy: trackByOmazsID" [clrDgItem]="row"
                    [attr.data-test-id]="'datagrid-open-row-' + i">
                    <clr-dg-action-overflow>
                      <button class="action-item" (click)="openObject(row.ostammid)">{{'Öffnen' | mrTranslate}}</button>
                      <button class="action-item" (click)="openBemerkung(row.bemerkung, row.omazsid)">{{'Bemerkung
                        eintragen' | mrTranslate}}</button>
                      <button class="action-item"
                        (click)="openSonderinspektion(row.mangel, row.omazsid)">{{'Sonderinspektion anlegen' |
                        mrTranslate}}</button>
                    </clr-dg-action-overflow>
                    <clr-dg-cell style="min-width: 50px; width: 60px;">

                      <ng-container *ngTemplateOutlet='mediaCell; context: {$implicit: row}'></ng-container>
                    </clr-dg-cell>

                    <ng-container *ngFor="let cell of tableOpen['columns']">
                      <ng-container [ngTemplateOutlet]="cell.id == 'icon' ? icon : normal"></ng-container>
                      <ng-template #icon>
                        <clr-dg-cell [title]="'Objekttyp' | tablePrettyPrint"
                          [attr.data-test-id]="'datagrid-open-cell-' + cell.id">
                          <clr-icon class="customIcon iconcolor" [attr.shape]="row['icon']
                            ? 'objekttyp' + row['icon']
                            : 'unknown-status'" size="28">
                          </clr-icon>
                        </clr-dg-cell>
                      </ng-template>
                      <ng-template #normal>
                        <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint"
                          [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } "
                          [attr.data-test-id]="'datagrid-open-cell-' + cell.id"
                          [style.min-width.px]="getColumnWidth(cell.id)" class="ellipsis"></clr-dg-cell>
                      </ng-template>
                    </ng-container>

                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-column-toggle>
                    </clr-dg-column-toggle>
                    <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage"
                      [clrDgPageSize]="rownr">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                      </clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </ng-template>
          </clr-tab>

          <!-- 2. TAB: BEAUFTRAGTE -->
          <clr-tab>
            <button clrTabLink (click)="tabChange()" [title]="'Beauftragte' | mrTranslate"
              [attr.data-test-id]="'tab-maengel-auftrag'">{{'Beauftragte' |
              mrTranslate | titlecase}}</button>
            <ng-template [(clrIfActive)]="activeTab['auftrag']">
              <clr-tab-content>
                <clr-datagrid *ngIf="dataAuftrag" [(clrDgSelected)]="selected" class="datagrid-compact"
                  [attr.data-test-id]="'datagrid-auftrag'">
                  <clr-dg-column style="min-width: 50px; width: 60px;">{{ "Medien" | mrTranslate }}</clr-dg-column>
                  <clr-dg-column *ngFor="let column of tableAuftrag['columns']" [clrDgField]="column.id"
                    [attr.id]="'datagrid-auftrag-col-' + normalizeId(column.id)"
                    [style.min-width.px]="getColumnWidth(column.id)"
                    [attr.data-test-id]="'datagrid-auftrag-col-' + column.id">
                    <clr-dg-filter (clrDgFilterOpenChange)="onFilterOpenChange($event, column)"
                      [attr.data-test-id]="'datagrid-auftrag-col-' + column.id">
                      <app-column-filter tableName="auftrag" [column]="column"
                        [uniqueColumnValues]="getUniqueValuesForColumn(tableAuftragUniqueColumnValues, column.id)"
                        (changes)="onAuftragFilterChange($event)" />
                    </clr-dg-filter>
                    <ng-container
                      *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['mangelfoto', 'rueckbilder']}">{{
                      column.id | titlecase | mrUnitText}}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of dataAuftrag; trackBy: trackByOmazsID" [clrDgItem]="row"
                    [attr.data-test-id]="'datagrid-auftrag-row-' + i">
                    <clr-dg-action-overflow>
                      <button class="action-item" (click)="openObject(row.ostammid)">{{'Öffnen' | mrTranslate}}</button>
                      <button class="action-item" (click)="openBemerkung(row.bemerkung, row.omazsid)">{{'Bemerkung
                        eintragen' | mrTranslate}}</button>

                    </clr-dg-action-overflow>

                    <clr-dg-cell style="min-width: 50px; width: 60px;">
                      <ng-container *ngTemplateOutlet='mediaCell; context: {$implicit: row}'></ng-container>
                    </clr-dg-cell>

                    <ng-container *ngFor="let cell of tableAuftrag['columns']">
                      <ng-container [ngTemplateOutlet]="cell.id == 'icon' ? icon : normal"></ng-container>
                      <ng-template #icon>
                        <clr-dg-cell [title]="'Objekttyp' | tablePrettyPrint"
                          [attr.data-test-id]="'datagrid-aufrrag-cell-' + cell.id">
                          <clr-icon class="customIcon iconcolor" [attr.shape]="row['icon']
                            ? 'objekttyp' + row['icon']
                            : 'unknown-status'" size="28">
                          </clr-icon>
                        </clr-dg-cell>
                      </ng-template>
                      <ng-template #normal>
                        <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint"
                          [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } "
                          [attr.data-test-id]="'datagrid-aufrrag-cell-' + cell.id"
                          [style.min-width.px]="getColumnWidth(cell.id)" class="ellipsis"></clr-dg-cell>
                      </ng-template>
                    </ng-container>

                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-column-toggle>
                    </clr-dg-column-toggle>
                    <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage"
                      [clrDgPageSize]="rownr">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                      </clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </ng-template>
          </clr-tab>

          <!-- 3. TAB: ERLEDIGTE -->
          <clr-tab>
            <button clrTabLink (click)="tabChange()" [title]="'Erledigte' | mrTranslate"
              [attr.data-test-id]="'tab-maengel-done'">{{'Erledigt' |
              mrTranslate | titlecase}}</button>
            <ng-template [(clrIfActive)]="activeTab['done']">
              <clr-tab-content>
                <clr-datagrid *ngIf="dataDone" [(clrDgSelected)]="selected" class="datagrid-compact"
                  [attr.data-test-id]="'datagrid-done'">
                  <clr-dg-column style="min-width: 50px; width: 60px;">{{ "Medien" | mrTranslate }}</clr-dg-column>
                  <clr-dg-column *ngFor="let column of tableDone['columns']" [clrDgField]="column.id"
                    [attr.id]="'datagrid-done-col-' + normalizeId(column.id)"
                    [style.min-width.px]="getColumnWidth(column.id)"
                    [attr.data-test-id]="'datagrid-done-col-' + column.id">
                    <clr-dg-filter (clrDgFilterOpenChange)="onFilterOpenChange($event, column)"
                      [attr.data-test-id]="'datagrid-done-col-' + column.id">
                      <app-column-filter tableName="done" [column]="column"
                        [uniqueColumnValues]="getUniqueValuesForColumn(tableDoneUniqueColumnValues, column.id)"
                        (changes)="onDoneFilterChange($event)" />
                    </clr-dg-filter>
                    <ng-container
                      *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['mangelfoto', 'rueckbilder']}">{{
                      column.id | titlecase | mrUnitText }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of dataDone; trackBy: trackByOmazsID" [clrDgItem]="row"
                    [attr.data-test-id]="'datagrid-done-row-' + i">
                    <clr-dg-action-overflow>
                      <button class="action-item" (click)="openObject(row.ostammid)">{{'Öffnen' | mrTranslate}}</button>
                    </clr-dg-action-overflow>
                    <clr-dg-cell style="min-width: 50px; width: 60px;">

                      <ng-container *ngTemplateOutlet='mediaCell; context: {$implicit: row}'></ng-container>
                    </clr-dg-cell>

                    <ng-container *ngFor="let cell of tableDone['columns']">
                      <ng-container [ngTemplateOutlet]="cell.id == 'icon' ? icon : normal"></ng-container>
                      <ng-template #icon>
                        <clr-dg-cell [title]="'Objekttyp' | tablePrettyPrint"
                          [attr.data-test-id]="'datagrid-done-cell-' + cell.id">
                          <clr-icon class="customIcon iconcolor" [attr.shape]="row['icon']
                            ? 'objekttyp' + row['icon']
                            : 'unknown-status'" size="28">
                          </clr-icon>
                        </clr-dg-cell>
                      </ng-template>
                      <ng-template #normal>
                        <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint"
                          [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } "
                          [attr.data-test-id]="'datagrid-done-cell-' + cell.id"
                          [style.min-width.px]="getColumnWidth(cell.id)" class="ellipsis"></clr-dg-cell>
                      </ng-template>
                    </ng-container>

                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-column-toggle>
                    </clr-dg-column-toggle>
                    <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage"
                      [clrDgPageSize]="rownr">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                      </clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </ng-template>
          </clr-tab>

          <!-- 4. TAB: ALLE -->
          <clr-tab>
            <button clrTabLink (click)="tabChange()" [title]="'Alle' | mrTranslate"
              [attr.data-test-id]="'tab-maengel-all'">{{'Alle' | mrTranslate |
              titlecase}}</button>
            <ng-template [(clrIfActive)]="activeTab['all']">
              <clr-tab-content>
                <!-- <app-grid [tableData]="tableAll" [uniqueColumnData]="tableAllUniqueColumnValues" name="maengel"></app-grid> -->
                <clr-datagrid *ngIf="dataAll" [(clrDgSelected)]="selected" class="datagrid-compact h-100"
                  [attr.data-test-id]="'datagrid-maengel'">
                  <clr-dg-column style="min-width: 50px; width: 60px;">{{ "Medien" | mrTranslate }}</clr-dg-column>
                  <clr-dg-column *ngFor="let column of tableAll['columns'];index as i" [clrDgField]="column.id"
                    [attr.id]="'datagrid-all-col-' + normalizeId(column.id)"
                    [style.min-width.px]="getColumnWidth(column.id)"
                    [attr.data-test-id]="'datagrid-maengel-col-' + column.id">
                    <clr-dg-filter (clrDgFilterOpenChange)="onFilterOpenChange($event, column)"
                      [attr.data-test-id]="'datagrid-maengel-col-filter-' + column.id">
                      <app-column-filter tableName="all" [column]="column"
                        [uniqueColumnValues]="getUniqueValuesForColumn(tableAllUniqueColumnValues, column.id)"
                        (changes)="onAllFilterChange($event)" />
                    </clr-dg-filter>
                    <ng-container
                      *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['mangelfoto', 'rueckbilder']}">{{
                      column.id | titlecase | mrUnitText }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of dataAll; let i = index; trackBy: trackByOmazsID" [clrDgItem]="row"
                    [attr.data-test-id]="'datagrid-maengel-row-' + i">
                    <clr-dg-action-overflow>
                      <button class="action-item" (click)="openObject(row.ostammid)">{{'Öffnen' | mrTranslate}}</button>
                      <button class="action-item" (click)="openBemerkung(row.bemerkung, row.omazsid)">{{'Bemerkung
                        eintragen' | mrTranslate}}</button>
                      <button class="action-item" (click)="openSonderinspektion(row)">{{'Sonderinspektion anlegen' |
                        mrTranslate}}</button>
                    </clr-dg-action-overflow>
                    <clr-dg-cell style="min-width: 50px; width: 60px;">
                      <ng-container *ngTemplateOutlet='mediaCell; context: {$implicit: row}'></ng-container>
                    </clr-dg-cell>

                    <ng-container *ngFor="let cell of tableAll['columns']">
                      <ng-container [ngTemplateOutlet]="cell.id == 'icon' ? icon : normal"></ng-container>
                      <ng-template #icon>
                        <clr-dg-cell [title]="'Objekttyp' | tablePrettyPrint"
                          [attr.data-test-id]="'datagrid-maengel-cell-' + cell.id">
                          <clr-icon class="customIcon iconcolor" [attr.shape]="row['icon']
                            ? 'objekttyp' + row['icon']
                            : 'unknown-status'" size="28">
                          </clr-icon>
                        </clr-dg-cell>
                      </ng-template>
                      <ng-template #normal>
                        <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint"
                          [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } "
                          [attr.data-test-id]="'datagrid-maengel-cell-' + cell.id"
                          [style.min-width.px]="getColumnWidth(cell.id)" class="ellipsis"></clr-dg-cell>
                      </ng-template>
                    </ng-container>

                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-column-toggle>
                    </clr-dg-column-toggle>
                    <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage"
                      [clrDgPageSize]="rownr">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                      </clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </ng-template>
          </clr-tab>

          <!-- 5. TAB: Wartung-->
          <clr-tab *ngIf="tableWartung?.rows?.length !== 0">
            <button clrTabLink (click)="tabChange()" [title]="'Wartung' | mrTranslate"
              [attr.data-test-id]="'tab-maengel-wartung'">{{'Wartung' | mrTranslate | titlecase}}</button>
            <ng-template [(clrIfActive)]="activeTab['wartung']">
              <clr-tab-content>
                <clr-datagrid [(clrDgSelected)]="selected" class="datagrid-compact"
                  [attr.data-test-id]="'datagrid-open'">
                  <clr-dg-column style="min-width: 50px; width: 60px;">{{ "Medien" | mrTranslate }}</clr-dg-column>
                  <clr-dg-column *ngFor="let column of tableWartung['columns']" [clrDgField]="column.id"
                    [attr.id]="'datagrid-open-col-' + normalizeId(column.id)"
                    [style.min-width.px]="getColumnWidth(column.id)"
                    [attr.data-test-id]="'datagrid-open-col-' + column.id">
                    <clr-dg-filter (clrDgFilterWartungChange)="onFilterWartungChange($event, column)"
                      [attr.data-test-id]="'datagrid-open-col-' + column.id">
                      <app-column-filter tableName="open" [column]="column"
                        [uniqueColumnValues]="getUniqueValuesForColumn(tableOpenUniqueColumnValues, column.id)"
                        (changes)="onFilterWartungChange($event)" />
                    </clr-dg-filter>
                    <ng-container
                      *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['mangelfoto', 'rueckbilder']}">{{
                      column.id | titlecase | mrUnitText }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of dataWartung; trackBy: trackByOmazsID" [clrDgItem]="row"
                    [attr.data-test-id]="'datagrid-open-row-' + i">
                    <clr-dg-action-overflow>
                      <!-- <button class="action-item" (click)="openObject(row.ostammid)">{{'Öffnen' | mrTranslate}}</button> -->
                      <button class="action-item" (click)="openBemerkung(row.bemerkung, row.omazsid)">{{'Bemerkung
                        eintragen' | mrTranslate}}</button>
                      <!-- <button class="action-item" (click)="openSonderinspektion(row.mangel, row.omazsid)">{{'Sonderinspektion anlegen' | mrTranslate}}</button> -->
                    </clr-dg-action-overflow>
                    <clr-dg-cell style="min-width: 50px; width: 60px;">

                      <ng-container *ngTemplateOutlet='mediaCell; context: {$implicit: row}'></ng-container>
                    </clr-dg-cell>

                    <ng-container *ngFor="let cell of tableWartung['columns']">
                      <ng-container [ngTemplateOutlet]="cell.id == 'icon' ? icon : normal"></ng-container>
                      <ng-template #icon>
                        <clr-dg-cell [title]="'Objekttyp' | tablePrettyPrint"
                          [attr.data-test-id]="'datagrid-open-cell-' + cell.id">
                          <clr-icon class="customIcon iconcolor" [attr.shape]="row['icon']
                            ? 'objekttyp' + row['icon']
                            : 'unknown-status'" size="28">
                          </clr-icon>
                        </clr-dg-cell>
                      </ng-template>
                      <ng-template #normal>
                        <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint"
                          [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } "
                          [attr.data-test-id]="'datagrid-open-cell-' + cell.id"
                          [style.min-width.px]="getColumnWidth(cell.id)" class="ellipsis"></clr-dg-cell>
                      </ng-template>
                    </ng-container>

                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-column-toggle>
                    </clr-dg-column-toggle>
                    <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage"
                      [clrDgPageSize]="rownr">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                      </clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </ng-template>
          </clr-tab>

          <!-- 6. TAB: Störung-->
          <clr-tab *ngIf="tableStoerung?.rows?.length !== 0">
            <button clrTabLink (click)="tabChange()" [title]="'Störung' | mrTranslate"
              [attr.data-test-id]="'tab-maengel-stoerung'">{{'Störung' | mrTranslate | titlecase}}</button>
            <ng-template [(clrIfActive)]="activeTab['stoerung']">
              <clr-tab-content>
                <clr-datagrid [(clrDgSelected)]="selected" class="datagrid-compact"
                  [attr.data-test-id]="'datagrid-open'">
                  <clr-dg-column style="min-width: 50px; width: 60px;">{{ "Medien" | mrTranslate }}</clr-dg-column>
                  <clr-dg-column *ngFor="let column of tableStoerung['columns']" [clrDgField]="column.id"
                    [attr.id]="'datagrid-open-col-' + normalizeId(column.id)"
                    [style.min-width.px]="getColumnWidth(column.id)"
                    [attr.data-test-id]="'datagrid-open-col-' + column.id">
                    <clr-dg-filter (clrDgFilterWartungChange)="onFilterStoerungChange($event, column)"
                      [attr.data-test-id]="'datagrid-open-col-' + column.id">
                      <app-column-filter tableName="open" [column]="column"
                        [uniqueColumnValues]="getUniqueValuesForColumn(tableOpenUniqueColumnValues, column.id)"
                        (changes)="onFilterStoerungChange($event)" />
                    </clr-dg-filter>
                    <ng-container
                      *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['mangelfoto', 'rueckbilder']}">{{
                      column.id | titlecase | mrUnitText }}
                    </ng-container>
                  </clr-dg-column>
                  <clr-dg-row *clrDgItems="let row of dataStoerung; trackBy: trackByOmazsID" [clrDgItem]="row"
                    [attr.data-test-id]="'datagrid-open-row-' + i">
                    [attr.data-test-id]="'datagrid-open-row-' + i">
                    <clr-dg-action-overflow>
                      <!-- <button class="action-item" (click)="openObject(row.ostammid)">{{'Öffnen' | mrTranslate}}</button> -->
                      <button class="action-item" (click)="openBemerkung(row.bemerkung, row.omazsid)">{{'Bemerkung
                        eintragen' | mrTranslate}}</button>
                      <!-- <button class="action-item" (click)="openSonderinspektion(row.mangel, row.omazsid)">{{'Sonderinspektion anlegen' | mrTranslate}}</button> -->
                    </clr-dg-action-overflow>
                    <clr-dg-cell style="min-width: 50px; width: 60px;">

                      <ng-container *ngTemplateOutlet='mediaCell; context: {$implicit: row}'></ng-container>
                    </clr-dg-cell>

                    <ng-container *ngFor="let cell of tableStoerung['columns']">
                      <ng-container [ngTemplateOutlet]="cell.id == 'icon' ? icon : normal"></ng-container>
                      <ng-template #icon>
                        <clr-dg-cell [title]="'Objekttyp' | tablePrettyPrint"
                          [attr.data-test-id]="'datagrid-open-cell-' + cell.id">
                          <clr-icon class="customIcon iconcolor" [attr.shape]="row['icon']
                            ? 'objekttyp' + row['icon']
                            : 'unknown-status'" size="28">
                          </clr-icon>
                        </clr-dg-cell>
                      </ng-template>
                      <ng-template #normal>
                        <clr-dg-cell [title]="row[cell.id] | tablePrettyPrint"
                          [innerHTML]="row[cell.id] | tablePrettyPrint : { icons: true, column: { id: cell.id } } "
                          [attr.data-test-id]="'datagrid-open-cell-' + cell.id"
                          [style.min-width.px]="getColumnWidth(cell.id)" class="ellipsis"></clr-dg-cell>
                      </ng-template>
                    </ng-container>

                  </clr-dg-row>
                  <clr-dg-footer>
                    <clr-dg-column-toggle>
                    </clr-dg-column-toggle>
                    <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage"
                      [clrDgPageSize]="rownr">
                      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                      </clr-dg-page-size>
                      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                      {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                    </clr-dg-pagination>
                  </clr-dg-footer>
                </clr-datagrid>
              </clr-tab-content>
            </ng-template>
          </clr-tab>


        </clr-tabs>

      </ng-container>

    </div>
  </div>

  <div #dvwrapper id="wrapperdetailview" *ngIf="showdetailview"
    [ngClass]="{ sidenavenlarged, split50, hidedetailview }">
    <app-detailview [ostammid]="_ostammid" [progressbarenlarged]="progressbarenlarged" [expanded]="sidenavenlarged"
      [hidden]="hidedetailview" [split50]="split50" (enlarge)="enlargeSidenav($event)"
      (enlargePB)="enlargeProgressbar(true)" (progressData)="showProgressData($event)"
      (indexScrub)="handleScrub($event)" (reload)="reloadMangel(false, $event)" #detailView></app-detailview>
  </div>


  <clr-modal [(clrModalOpen)]="showbemerkung">
    <h3 class="modal-title">{{'Bemerkung eintragen' | mrTranslate}}</h3>
    <div class="modal-body">
      <form clrForm>
        <clr-textarea-container>
          <textarea id="aa" class="clr-col-md-12" placeholder="Tragen Sie hier die Bemerkung für den Mangel ein"
            clrTextarea [(ngModel)]="mangelbemerkung" name="bemerkung" required
            style=" width:100%;height:150px"></textarea>
        </clr-textarea-container>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="saveMangelBemerkung()">
        {{"Bemerkung speichern" | mrTranslate}}</button>
    </div>
  </clr-modal>

  <app-multi-auftrag-modal *ngIf="isMultiAuftragModalOpen" (onClose)="closeMultiAuftragModal($event)"
    [maengel]="selected"></app-multi-auftrag-modal>

  <app-multi-rueckmeldung-modal *ngIf="isMultiRueckModalOpen" (onClose)="closeMultiRueckModal($event)"
    [maengel]="selected"></app-multi-rueckmeldung-modal>

  <app-sonderinspektionmodal></app-sonderinspektionmodal>

</div>

<div id="progresstrigger" *ngIf="showprogressbar" [ngClass]="{'bottom': !progressbarenlarged}">
  <div class="wrap" (click)="enlargeProgressbar()">
    <clr-icon [ngStyle]="{'transform': progressbarenlarged ? 'rotate(180deg)' : 'rotate(0deg)'}" shape="caret"
      class="expandProgressbarButton cursor-pointer"></clr-icon>
  </div>
</div>
<footer *ngIf="showprogressbar && progressbarenlarged" id="wrapperprogressbars"
  [ngClass]="(!progressbarenlarged && !sidenavenlarged) ? (split50 ? 'shorter' :'short') : '' "
  class="clr-row nxm clr-align-items-center">
  <app-progressbar [dataIn]="progressbars" [indexScrub]="indexScrub" (enlargeProgressbar)="enlargeProgressbar(true)"
    (openDialogAt)="openOverlay($event)" style="display:inline-block; width:calc(100% - 35px);"></app-progressbar>
</footer>

<app-mediaviewmodal *ngIf="mangelMedia" (onClose)="mangelMedia = undefined" [srcs]="mangelMedia"
  [getMediaFromServerAll]="media.getApiFunctionMedia" [getBigFileFnc]="media.getApiFunctionMediaBig">
</app-mediaviewmodal>