import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { APIService } from "src/app/services/APIService/api.service";

import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { ToastrService } from "ngx-toastr";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { PlotlyPlotComponent } from "../../plotly-plot/plotly-plot.component";


@Component({
  selector: "app-stellkraftmessungdialog",
  templateUrl: "./stellkraftmessungdialog.component.html",
  styleUrls: ["./stellkraftmessungdialog.component.scss"],
  imports: [CommonModule, ClarityModule, MrTranslatePipe,HideIDColumnsPipe,TablePrettyPrintPipe,PlotlyPlotComponent],
  standalone: true
})
export class StellkraftmessungdialogComponent implements OnInit {
  isLoading = true;
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ostammid: number;
  tolverlStr: string;
  bezeichnung: string;
  messTable;
  zaehlerbild:any;

  stellkraftmessungen: any[] = [];

  stellTable = {
    show: false,
    rows: [],
    columns: [],
  };

  stellDateTable = {
    rows: [],
    columns: [{ id: "datum" }],
  };

  set selectedStellDates(val: any[]) {
    this._selectedStellDates = val;
    this.filterStellTableRows();
  }
  get selectedStellDates() {
    return this._selectedStellDates;
  }
  _selectedStellDates = [];

  graph = {
    show: false,
    layout: {
      autosize: true,
      showlegend: true,
      legend: { orientation: "h" },
      //height: "25vh",
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0,
      },
      plot_bgcolor: "transparent",
      paper_bgcolor: "transparent",
      yaxis: {
        autorange: true,
        //range: [Math.min(...measurements) - 10, Math.max(...measurements) + 10],
        type: "linear",
      },
      xaxis:  {
        autorange: true,
        autorangeoptions : {
          minallowed: -4
        },
      },
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: "de",
      modeBarButtonsToRemove: ["sendDataToCloud", "lasso2d"],
    },
    dataLeft: [],
    dataRight: [],
    style: {
      width: "100%",
      height: "100%",
    },
  };

  editDataGridVisible : boolean = false;
  selectedRow : any = undefined;
  ist : number;
  SRValues = {SR100M:0, SR100P:0, SRLimP:0, SRLimM:0, SRGP:0,  SRGM:0,};

  set data(dataIn: any) {
    this.ostammid = dataIn.id;
  }

  constructor(
    private apiservice: APIService,
    private changeDet: ChangeDetectorRef,
    private mrTranslate: MrTranslatePipe,
    private toastr: ToastrService
  ) {
    dayjs.locale("de");
    dayjs.extend(customParseFormat);
  }

  ngOnInit(): void {
    this.graph.show = true;
    this.apiservice
      .getStellkraftMessungen(this.ostammid)
      .pipe(first())
      .subscribe((val: any) => {
        if(!val) {
          this.toastr.warning(this.mrTranslate.transform("Keine Daten vorhanden"));
          this.close();
          return;
        }
        if (val?.messungen?.rows) this.messTable = val.messungen;
        if (val?.stellkraftmessungen) {
          this.stellkraftmessungen = val.stellkraftmessungen;
          this.stellDateTable.rows = val.stellkraftmessungen
            .map((val) => dayjs(val.Datum).format("DD.MM.YYYY"))
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((val) => {
              return { datum: val };
            });

          this.filterStellTableRows();
        }
        if(val?.zaehlerbild) this.zaehlerbild = val.zaehlerbild;
      });

      setTimeout(() => {
        this.selectedRow = this.messTable?.rows[0];
      }, 200);
  }

  public reloadOnlyMessTable() {
    this.apiservice
    .getStellkraftMessungen(this.ostammid)
    .pipe(first())
    .subscribe((val: any) => {
      if (val.messungen?.rows) this.messTable = val.messungen;
    });
  }
  public changeRow(dir: number) {
    var body = {Bezeichnung:"", OPRUEFID:"", Ausfall:"",Ist:0 };
    var tolVerl = "";
    var index: number = 0;

    for (let i = 0; i < this.messTable.rows.length; i++)
      if(this.selectedRow == this.messTable.rows[i]) index = i;

    if(!this.selectedRow) this.selectedRow = this.messTable.rows[0];
    if(this.ist > this.SRValues.SR100P || this.ist < this.SRValues.SR100M) {
      tolVerl += "X";
      if(this.ist > this.SRValues.SRLimP || this.ist < this.SRValues.SRLimM) {
        tolVerl += "X";
        if(this.ist > this.SRValues.SRGP || this.ist < this.SRValues.SRGM) {
          tolVerl += "X";
        }
      }
    }

    body.Bezeichnung = this.bezeichnung = this.selectedRow.bezeichnung;
    body.Ist = this.ist;
    body.Ausfall = tolVerl;
    body.OPRUEFID = this.selectedRow.opruefid;

    this.apiservice.setStellkraftMessung(body).pipe().subscribe((val: any) => {
      if (dir == 0) {
        this.editDataGridVisible = false;
        this.reloadOnlyMessTable();
      }
      else {
        this.editDataGrid(this.messTable.rows[index + dir])
      }
    });
  }

  public changeIst(event:any) {
    if(isNaN(event)) this.ist = event.target.value;
    this.tolverlStr = "";
    if(this.ist > this.SRValues.SR100P && this.SRValues.SR100P != 0)  this.tolverlStr = "SR 100 +";
    if(this.ist < this.SRValues.SR100M && this.SRValues.SR100M != 0)  this.tolverlStr = "SR 100 -";
    if(this.ist > this.SRValues.SRLimP && this.SRValues.SRLimP != 0)  this.tolverlStr = "SR lim +";
    if(this.ist < this.SRValues.SRLimM && this.SRValues.SRLimP != 0)  this.tolverlStr = "SR lim -";
    if(this.ist > this.SRValues.SRGP && this.SRValues.SRGP != 0)  this.tolverlStr = "SRG +";
    if(this.ist < this.SRValues.SRGM && this.SRValues.SRGM != 0)  this.tolverlStr = "SRG -";
  }
  public editDataGrid(row) {
    this.selectedRow = row;
    this.ist = row.ist;
    this.bezeichnung = row.bezeichnung;
    this.SRValues = {
      SR100P: parseFloat(row.soll || 0) + parseFloat(row["SR 100 +"]) || 0,
      SR100M: parseFloat(row.soll || 0) - parseFloat(row["SR 100 -"]) || 0,
      SRLimP: parseFloat(row.soll || 0) + parseFloat(row["SR lim +"]) || 0,
      SRLimM: parseFloat(row.soll || 0) - parseFloat(row["SR lim -"]) || 0,
      SRGP: parseFloat(row.soll || 0) + parseFloat(row["SRG +"]) || 0,
      SRGM: parseFloat(row.soll || 0) - parseFloat(row["SRG -"]) || 0,
    }
    this.changeIst(this.ist);
    this.editDataGridVisible = true;
  }
  filterStellTableRows() {
    this.stellTable.show = false;
    this.stellTable.columns = [];
    this.stellTable.rows = [];

    let filteredRows = this.stellkraftmessungen.filter((row) =>
      this.selectedStellDates
        .map((row) => row.datum)
        .includes(dayjs(row.Datum).format("DD.MM.YYYY"))
    );
    this.stellTable.rows = filteredRows;
    this.stellTable.columns = Object.keys(this.stellkraftmessungen[0])
      .filter((col) => col !== "x" && col !== "y")
      .map((val) => {
        return { id: val };
      });

    setTimeout(() => {
      this.stellTable.show = true;
      this.isLoading = false;
    });

    this.setGraph(filteredRows);
  }

  setGraph(filteredRows) {
    let offSetL : number = 0;
    let offSetR : number = 0;
    let startIndex = {};
    this.graph.show = false;
    this.graph.dataLeft = [];
    this.graph.dataRight = [];

    if (filteredRows.length > 0) {
      for (let [index, row, ] of filteredRows.entries()) {
        var loop = Math.floor((index) /4) * 4 ;
        const left = index % 2 === 0;

        const graphData = {
          x: row.x.split(';'),
          y: row.y.split(';'),
          type: "scatter",
          name: `${dayjs(row.Datum).format("DD.MM.YYYY")} - ${row.Messart}`,
        };

        //Generelle Linksverschiebung damit nicht alles zu weit rechts liegt
        if(left && offSetL == 0)offSetL = this.setGraphOffset(row);
        else if(offSetR == 0) offSetR = this.setGraphOffset(row);

        //Vergleichen wo die beste Überlappung zwischen dem Linken und Rechten wert ist
        startIndex[index + 1] = this.setLineStart(graphData.y);

        if (left) {
          if (index == 2 + loop && startIndex[1 + loop] != -1 && startIndex[3 + loop] != -1) graphData.x = graphData.x.map((val) => {
            return parseFloat(val) + (startIndex[1 + loop] / 100 - startIndex[3 + loop] / 100);
          });
          graphData.x = graphData.x.map((val) => { return parseFloat(val) - offSetL; });
          this.graph.dataLeft.push(graphData);
        }
        else {
          if (index == 3 + loop && startIndex[2 + loop] != -1 && startIndex[4 + loop] != -1) graphData.x = graphData.x.map((val) => {
            return parseFloat(val) + (startIndex[2 + loop] / 100 - startIndex[4 + loop] / 100);
          });
          graphData.x = graphData.x.map((val) => { return parseFloat(val) - offSetR; });
          this.graph.dataRight.push(graphData);
        }

        if (index+1 % 4 == 0) { offSetL = 0; offSetR = 0; }
      }
    }

    this.changeDet.detectChanges();

    setTimeout(() => {
      this.graph.show = true;
    });
  }

  setGraphOffset(row) :number {
    let offSet: number;
    let maxWert = parseFloat(row.Maxwert.replace(",","."));
    let y = row.y.split(';');
    for (let i = 0; i < y.length; i++) {
      if ((i > 4 && y[i] - y[i - 4] > 2) || y[i] > maxWert - 0.8) {
        offSet = ((i-2) / 100);
        break;
      }
    }
    return offSet;
  }
  setLineStart(messwerte) : number {
    if (!messwerte || messwerte.length == 0) return -1;
    messwerte = messwerte.map((v) => parseFloat(v));
    let max : number = messwerte[0];
    let min : number = messwerte[0];

    //bestimme min und max der ersten 50 Messwerte = 0.5 Sekunde
    for (let i = 0; i <= 50; i++) {

      if (max < messwerte[i]) max = messwerte[i];
      if (min > messwerte[i]) min = messwerte[i];
    }
    min = min -0.02;
    max = max +0.02;

    //durchlaufe alle Messerte
    for (let i = 50; i <= messwerte.length - 1; i++) {
      //wenn der aktuelle Wert größer max ODER kleiner min
      if (messwerte[i] > max || messwerte[i] < min) {

        if (i >= 1 && messwerte[i] < (messwerte[i - 1] - 0.02) || messwerte[i] > (messwerte[i - 1] + 0.02)) {
          //betrachte die nächsten 5 Messwerte
          //wenn 3 der nächsten 5 Messwerte >= max ODER <= min ist der Start gefunden
          if (i < messwerte.length - 6) {
            var count = 0;
            for (let j = 1; j <= 5; j++) {
              if ((messwerte[j + i] > max || messwerte[j + i] < min)) count += 1;
            }
            if (count >= 3) return i - 1;
          }
        }
      }
    }

    return - 1
  }

  public close() {
    this.closedialog.next(true);
  }
  ngOnDestroy(): void {
    this.graph = undefined;
    this.stellTable = undefined;
    this.messTable = undefined;
    this.stellDateTable = undefined;
    this.stellkraftmessungen = undefined;
  }

}
