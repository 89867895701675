import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

import CustomParseFormat from 'dayjs/plugin/customParseFormat';

@Pipe({
  name: 'localeDate',
  standalone: true

})
export class BackendLocaleDatePipe implements PipeTransform {
  private readonly localeFormat: string;

  constructor({ currentLang }: TranslateService) {
    dayjs.extend(CustomParseFormat);
    this.localeFormat = dayjs.Ls[currentLang]?.formats?.['L'] ?? 'MM/DD/YYYY';
  }

  /** für Initialisierung von clrDate Input
   * @example new UntypedFormControl(GetLocaleDatePipe.now)
   */
  public static get now(): string {
    return dayjs().format(BackendLocaleDatePipe.format);
  }

  /** für schnelle Zugriff auf BackEnd DateFormat
   * @example dayjs().add(2, 'days').format(GetLocaleDatePipe.format)
   */
  public static get format(): string {
    return 'YYYY-MM-DDTHH:mm:ss';
  }

  /** nur für ANZEIGE
   * @param value 'YYYY-MM-DDTHH:mm:ss'
   * @returns en: 'MM/DD/YYYY'; de, ru: 'DD.MM.YYYY'; andere: 'DD/MM/YYYY'
   * @example [ngModel]="formGroup.controls['Datum'].value | localeDate"
   * @example [ngModel]="datum | localeDate"
   */
  transform(value: string): string {
    return value
      ? dayjs(value).format(this.localeFormat)
      : null;
  }

  /**
   * für Speichern in BackEnd
   * @alias tosql
   * @see tosql()
   * @param value en: 'MM/DD/YYYY'; de, ru: 'DD.MM.YYYY'; andere: 'DD/MM/YYYY'
   * @returns 'YYYY-MM-DDTHH:mm:ss'
   */
  toBackEndFormat(value: string): string {
    if (!value) return null;
    const date = dayjs(value, this.localeFormat);

    return date.isValid()
      ? date.format(BackendLocaleDatePipe.format)
      : null;
  }

  /**
   * @see toBackEndFormat()
   * @example (ngModelChange)="datum = localeDate.tosql($event)"
   * @example (ngModelChange)="formGroup.controls['Datum'].setValue(localeDate.tosql($event))"
   */
  tosql = this.toBackEndFormat;

  /**
   * @description Schreibt Zeit zu Datum string
   * @param date 'YYYY-MM-DDTHH:mm:ss'
   * @param time 'HH:mm:ss' | 'HH:mm'
   * @returns 'YYYY-MM-DDTHH:mm:ss' | null
   */
  addTimeToDate(date: string, time: string) {
    if (!date) return null;
    const [h, m] = (time || '0:0').split(':');
    return dayjs(date)
      .hour(Number(h))
      .minute(Number(m))
      .second(0)
      .format(BackendLocaleDatePipe.format)
      ;
  }
}
