import { XMLParser } from "fast-xml-parser";
const xmlParser = new XMLParser({
  ignoreAttributes: false,
  attributeNamePrefix: "",
  parseAttributeValue: true
});
const RESERVED_VALUE = 0x40;
export function parseEncryptionDescriptor(buffer) {
  const reservedValue = buffer.readInt16LE(4);
  if (reservedValue !== RESERVED_VALUE) {
    throw new Error(`Unexpected reserved value ${reservedValue}`);
  }
  const xmlBuffer = buffer.slice(8); // leading @
  const xmlString = xmlBuffer.toString("ascii");
  const parsedXML = xmlParser.parse(xmlString);
  const keyData = parsedXML.encryption.keyData;
  const keyEncryptor = parsedXML.encryption.keyEncryptors.keyEncryptor["p:encryptedKey"];
  return {
    keyData: {
      blockSize: keyData.blockSize,
      cipher: {
        algorithm: keyData.cipherAlgorithm,
        chaining: keyData.cipherChaining
      },
      hash: {
        size: keyData.hashSize,
        algorithm: keyEncryptor.hashAlgorithm
      },
      salt: Buffer.from(keyData.saltValue, "base64")
    },
    passwordKeyEncryptor: {
      blockSize: keyEncryptor.blockSize,
      keyBits: keyEncryptor.keyBits,
      spinCount: keyEncryptor.spinCount,
      cipher: {
        algorithm: keyEncryptor.cipherAlgorithm,
        chaining: keyEncryptor.cipherChaining
      },
      hash: {
        size: keyEncryptor.hashSize,
        algorithm: keyEncryptor.hashAlgorithm
      },
      salt: Buffer.from(keyEncryptor.saltValue, "base64"),
      encrypted: {
        keyValue: Buffer.from(keyEncryptor.encryptedKeyValue, "base64"),
        verifierHashInput: Buffer.from(keyEncryptor.encryptedVerifierHashInput, "base64"),
        verifierHashValue: Buffer.from(keyEncryptor.encryptedVerifierHashValue, "base64")
      }
    }
  };
}