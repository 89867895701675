import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { ClrModal } from '@clr/angular';
import { BehaviorSubject, debounceTime, fromEvent, Subscription } from 'rxjs';
import SignaturePad, { PointGroup } from 'signature_pad';

import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';

/** Subscribe to singImage: EventEmitter to get an URL string OR "" for cancel */
@Component({
    selector: 'app-signature-pad-modal',
    templateUrl: './signature-pad-modal.component.html',
    styleUrls: ['./signature-pad-modal.component.scss'],
    imports: [CommonModule, ClarityModule, MrTranslatePipe],
    standalone: true
})
export class SignaturePadModalComponent implements OnDestroy {
  @ViewChild('signature', { static: true })
  private modalSignature: ClrModal
    ;
  @ViewChild('unterschriftCanvas', { static: true })
  private unterschriftCanvas: ElementRef<HTMLCanvasElement>
    ;
  protected signaturePad: SignaturePad;
  protected modalAntwort: BehaviorSubject<string> = new BehaviorSubject("");
  private signaturePadData: PointGroup[]; // Für preserve beim Window Resize
  private modalSub: Subscription;
  private modalOpenSub: Subscription;
  private resizeSub: Subscription;

  @Output() signImage: EventEmitter<string> = new EventEmitter<string>();

  @Input() set modalOpen(isOpen: boolean) {
    if (isOpen) this.sign();
  }

  private readonly ratio: number = window.devicePixelRatio;

  private sign() {
    this.modalSignature.open();
    this.modalOpenSub = this.modalSignature._openChanged.subscribe(
      (open) => !open && this.modalAntwort.next('closed')
    );
    this.signaturePad = new SignaturePad(this.unterschriftCanvas.nativeElement, {
      minWidth: 0.5,
      maxWidth: 2.0, penColor: "rgba(44,45,102,1.00)"
    });
    this.resizeSub = fromEvent(window, 'resize').pipe(debounceTime(200)).subscribe(() => this.onResize());
    setTimeout(() => this.onResize());

    this.modalSub?.unsubscribe();
    this.modalSub = this.modalAntwort.subscribe((antwort) => {
      if (antwort == "signed") {
        const unterschrift: string = this.signaturePad.toDataURL("image/png");
        this.modalSignature.close();
        this.signImage.emit(unterschrift);
      }
      if (antwort == "closed") {
        this.ngOnDestroy();
        this.modalAntwort.next("");
        this.signImage.emit("");
      }
    });
  }


  onResize = () => {
    const canvas = this.unterschriftCanvas.nativeElement;
    canvas.width = canvas.offsetWidth * this.ratio;
    canvas.height = canvas.width * 0.6;
    canvas.getContext("2d").scale(this.ratio, this.ratio);

    if (!this.signaturePadData?.length && !this.signaturePad.isEmpty())
      this.signaturePadData = this.signaturePad.toData();
    this.signaturePad.clear();

    if (this.signaturePadData?.length) {
      this.signaturePad.fromData(this.signaturePadData);
      this.signaturePadData = [];
    }
  }

  ngOnDestroy() {
    this.signaturePad = undefined;
    this.resizeSub?.unsubscribe();
    this.modalSub?.unsubscribe();
    this.modalOpenSub?.unsubscribe();
  }
}
