import { FilterOperator } from "src/app/models/ui/filter";
import { emptyValueSign } from "../../models/ui/datatype";
import { IFilter } from ".";

export class StringFilter implements IFilter {
  private data: any[];

  constructor(data: any[]) {
    this.data = data;
  }

  filter(data1: string, data2: string, operator: FilterOperator){
    switch(operator.value){
      case "startsWith":
        return this.startsWith(data1);
      case "endsWith":
        return this.endsWith(data1);
      case "contains":
        return this.contains(data1);
      case "isEqual":
        return this.isEqual(data1);
      case "isNotEqual":
        return this.isNotEqual(data1);
      case "isEmpty":
        return this.isEmpty();
      case "isNotEmpty":
        return this.isNotEmpty();
      case "none":
        return this.data;
    }
  }

  startsWith(prefix: string): string[] {
    return this.data.filter((item) => item.name.toLowerCase().startsWith(prefix.toLowerCase()));
  }

  endsWith(suffix: string): string[] {
    return this.data.filter((item) => item.name.toLowerCase().endsWith(suffix.toLowerCase()));
  }

  contains(substring: string): string[] {
    return this.data.filter((item) => item.name.toLowerCase().includes(substring.toLowerCase()));
  }

  isEqual(value: string): string[] {
    return this.data.filter((item) => item.name.toLowerCase() === value.toLowerCase());
  }

  isNotEqual(value: string): string[] {
    return this.data.filter((item) => item.name.toLowerCase() !== value.toLowerCase());
  }

  isEmpty(): string[] {
    return this.data.filter((item) => item.name === emptyValueSign);
  }

  isNotEmpty(): string[] {
    return this.data.filter((item) => item.name !== emptyValueSign);
  }
}
