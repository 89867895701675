import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";

import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
// import { BasicWithFormsModule } from "src/app/modules/basicsWithForms.module";

@Component({
  selector: "app-basedatamodal",
  templateUrl: "./basedatamodal.component.html",
  styleUrls: ["./basedatamodal.component.scss"],
  imports: [CommonModule, ClarityModule, MrTranslatePipe, HideIDColumnsPipe, TablePrettyPrintPipe],
  standalone: true
})
export class BasedatamodalComponent implements AfterViewInit {
  @Input() modalOpen: boolean = false;
  @Output() onOK: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('bezfield', { static: false }) bezeichnungElement: ElementRef;
  @ViewChild('dgStammdaten') dgStammdaten: ClrDatagrid;
  @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;

  private ctx: CanvasRenderingContext2D;
  public GLOB_SCALE: number = 1;
  offSetX: number = 0;
  offSetY: number = 0;
  ModalSize: string = "lg";
  is_Weichentyp: boolean = false;
  is_Schienenform: boolean = false;
  is_Bauwerk: boolean = false;
  is_Stammitems: boolean = false;
  boolValue: boolean = false;
  titel: string = "";
  tabelle: string;
  otypid: number;
  typid: string = '0';
  schhmax: number = 0;
  tname: string;
  gattungen: any;
  stammdaten: any;
  stammdatenOriginal: any;
  selid: number = 0;
  selecteditem: any;
  isChanged: boolean = false;
  logo: any;
  base64Image: string;
  bildData: any = undefined;
  sollprofil: any;
  sizeh: number = 200;
  sizew: number = 200;
  isProfile: boolean = false;
  isVignol: boolean = true;
  profiltyp: string = '';

  isSBAGP: boolean = false;
  SBAGPName: any[] = [];
  locked: boolean = true;
  isNewSBAGP: boolean = false;
  selSBAGPName: string = '';
  base64data: any;
  pdffile: any;
  isBWChecked: boolean = false;

  open(inData: any) {
    this.titel = inData.titel;
    if (inData.tabelle.toLowerCase().includes('strvalue')) {
      this.toastr.error(this.mrTranslate.transform(
        'Die Tabelle ist nicht vorhanden'
      ));
      this.close();
      return;
    } else this.tabelle = inData.tabelle?.toUpperCase();
    this.otypid = inData.OTYPID;
    this.tname = inData.tname;
    this.is_Weichentyp = false;
    this.is_Schienenform = false;
    this.is_Stammitems = false;
    this.bildData = undefined;

    this.boolValue = false;
    this.isSBAGP = false;
    this.sizeh = 0.1;
    this.sizew = 0.1;

    if (this.tabelle == "SBAGP") {
      this.isSBAGP = true;
      this.boolValue = true;
      this.ModalSize = undefined;
    }
    if (this.tabelle == "SWTYP") {
      this.ModalSize = 'xl';
      this.is_Weichentyp = true;
      this.boolValue = true;
    }

    if (this.tabelle == "SSCHF") {
      this.ModalSize = 'xl';
      this.is_Schienenform = true;
      this.boolValue = true;
    }

    if (this.tabelle == "SBAUW") {
      this.ModalSize = 'xl';
      this.is_Bauwerk = true;
      this.boolValue = true;
    }

    if (this.tabelle == "SSTAMM") {
      this.ModalSize = 'xl';
      this.is_Stammitems = true;
    }

    this.apiServiceItem('get', '');

    this.modalOpen = true;
  }

  trackById = (index, item) => item.ID;

  tabelleNameGroup = new UntypedFormGroup({
    txt0s: new UntypedFormControl('', [Validators.required]),
    gattung: new UntypedFormControl('', [Validators.required]),
    txt1s: new UntypedFormControl(''),
    typid: new UntypedFormControl(0),
    profiltyp: new UntypedFormControl('Vignol'),
    schhmax: new UntypedFormControl(149),
    checkbauwerk: new UntypedFormControl(false),
    anweisung: new UntypedFormControl(''),
  });

  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    //this.ctx = this.canvasRefL.nativeElement.getContext('2d');
  }

  ngAfterViewInit() {
    if (this.tabelle == 'SSCHF') {
      this.ctx = this.canvas.nativeElement.getContext('2d');
      this.ctx.scale(this.GLOB_SCALE, this.GLOB_SCALE);
    }
  }


  selectionFirmaChanged(value: any) {
    if (value != undefined) {
      this.isProfile = false;
      this.bildData = undefined;
      this.isVignol = true;
      this.selid = value.ID;
      this.logo = undefined;
      this.isBWChecked = false;
      this.pdffile = undefined;

      this.tabelleNameGroup.controls.txt0s.setValue(value.Bezeichnung);
      this.tabelleNameGroup.controls.checkbauwerk.setValue('');
      this.tabelleNameGroup.controls.gattung.setValue(value.GattungID || null);

      this.sizeh = 0;
      this.sizew = 0;

      if (value.TypID != undefined)
        this.tabelleNameGroup.controls.typid.setValue(value.TypID);

      if (value.Profiltyp != undefined) {
        this.tabelleNameGroup.controls.profiltyp.setValue(value.Profiltyp);
        this.isVignol = (value.Profiltyp == 'Vignol');
      }
      if (value.BildBase64 != undefined) {
        if (value.BildBase64 != null && value.BildBase64 != '') {
          this.logo = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + value.BildBase64);
        }
      }

      if (value.SchHMax != undefined)
        this.tabelleNameGroup.controls.schhmax.setValue(value.SchHMax);

      if (value.Pruefung != undefined) {
        this.isBWChecked = value.Pruefung;
        this.tabelleNameGroup.controls.checkbauwerk.setValue(value.Pruefung);
      }

      if (value.AnweisungBase64 != undefined) {
        if (value.AnweisungBase64 != null && value.AnweisungBase64 != '') {
          this.pdffile = this._sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + value.AnweisungBase64); //  'data:application/pdf;base64,' + (this._sanitizer.bypassSecurityTrustResourceUrl(value.AnweisungBase64) as any).changingThisBreaksApplicationSecurity;
        }
      }


      if (this.ctx != undefined)
        this.ctx.clearRect(0, 0, this.sizew, this.sizeh);

      if (value.Profil != undefined) {
        if (value.Profil != null) {
          let separator = ' ';
          if (value.Profil.toString().includes('\r\n'))
            separator = '\r\n';

          this.sizeh = 200;
          this.sizew = 200;
          let str = value.Profil;
          this.changeDetectorRef.detectChanges();
          let textrows = str.toString().split(separator);
          this.sollprofil = textrows.map((el) => el.split(";")).map((elem) => [parseFloat(elem[0]), parseFloat(elem[1])]);
          this.ctx = this.canvas.nativeElement.getContext('2d');
          this.renderSoll();
        }
      }
    }
    else {
      this.isProfile = false;
      this.bildData = undefined;
      this.isVignol = true;
      this.selid = 0;
      this.logo = undefined;
      this.isBWChecked = false;
      this.pdffile = undefined;
      this.tabelleNameGroup.controls.txt0s.setValue('');
      this.tabelleNameGroup.controls.checkbauwerk.setValue('');
      this.sizeh = 0;
      this.sizew = 0;
    }
  }

  onBauwerkCheckboxChange($event) {
    this.isBWChecked = $event.target.checked;
  }

  newitem() {

    if (this.isSBAGP == true) {
      this.isNewSBAGP = true;

      let copystammdaten = [];
      this.stammdaten.rows.forEach(val => copystammdaten.push(Object.assign({}, val)));

      for (let i = 0; i < copystammdaten.length; i++) {
        copystammdaten[i].ID = 0;
        copystammdaten[i].Name = '';
        copystammdaten[i].Anzahl = 0
      }
      this.stammdaten.rows = [];
      copystammdaten.forEach(val => this.stammdaten.rows.push(Object.assign({}, val)));

    }

    if (this.tabelle == "SSCHF") {
      this.isProfile = false;
      this.logo = undefined;
      this.tabelleNameGroup.controls.schhmax.setValue(0);
      this.tabelleNameGroup.controls.profiltyp.setValue('');
      this.bildData = undefined;
      this.sizeh = 0;
      this.sizew = 0;
      this.base64Image = '';
      this.sollprofil = undefined;
      this.selecteditem = {};
    }


    this.selid = 0;
    this.tabelleNameGroup.controls.txt0s.setValue('');
    this.tabelleNameGroup.controls.typid.setValue(0);

    setTimeout(() => {
      this.bezeichnungElement?.nativeElement.focus();
    }, 0);

  }

  saveitem() {

    let vals = this.tabelleNameGroup.value;

    if (this.isSBAGP) {
      if (this.isNewSBAGP)
        this.selSBAGPName = this.tabelleNameGroup.value.txt0s;
      else {
        this.tabelleNameGroup.controls.txt0s.setValue(this.selSBAGPName);
        if (vals.txt1s && vals.txt1s != this.selSBAGPName)
          this.tabelleNameGroup.controls.txt0s.setValue(vals.txt1s);
        vals = this.tabelleNameGroup.value;
      }
    }

    if (vals.txt0s == '') {
      this.toastr.error(this.mrTranslate.transform(this.mrTranslate.transform("Geben Sie eine Bezeichnung ein")));
      return;
    }

    if (this.is_Stammitems && vals.gattung == '') {
      this.toastr.error(this.mrTranslate.transform(this.mrTranslate.transform("Geben Sie eine Gattung ein")));
      return;
    }

    if (this.tabelle != "SBAGP") {
      if (this.stammdatenOriginal.some((item) => item.Bezeichnung.toLowerCase() == vals.txt0s.toLowerCase() && item.ID != this.selid)) {
        this.toastr.error(this.mrTranslate.transform("Eintrag existiert bereits !!"));
        return;
      }
    }

    if (this.tabelle == 'SWTYP') {
      this.typid = vals.typid;
    }

    if (this.tabelle == 'SSCHF') {

      if (vals.schhmax == 0) {
        this.toastr.error(this.mrTranslate.transform(this.mrTranslate.transform("Bitte geben sie die Schienenhöhe / Rillentiefe an")));
        return;
      }
      if (vals.profiltyp == '') {
        this.toastr.error(this.mrTranslate.transform(this.mrTranslate.transform("Bitte wählen sie eine Schienentyp aus")));
        return;
      }

      this.schhmax = vals.schhmax;
      this.profiltyp = vals.profiltyp;
      this.base64Image = this.selecteditem.BildBase64;
    }

    if (this.tabelle == 'SBAUW') {
      this.isBWChecked = vals.checkbauwerk;
    }

    this.apiServiceItem('update', vals.txt0s, vals.gattung);
    this.isChanged = true;

  }

  deletitem() {

    if (this.otypid == 1 || this.otypid == 2) {
      if (this.tabelle == 'SGATT') {
        if (this.selecteditem.intID < 15) {
          this.toastr.error(this.mrTranslate.transform("Eintrag kann nicht gelöscht werden"));
          return;
        }
      }
    }


    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?"))) {
      let vals = this.tabelleNameGroup.value;
      this.apiServiceItem('delete', vals.txt0s);
      this.isChanged = true;
    }
  }

  newRow() {
    if (this.isSBAGP) {
      if (!this.stammdaten?.rows?.length) {
        this.stammdaten = { rows: [] };
        this.isNewSBAGP = true;
      }
      this.stammdaten?.rows.push({
        "Prüfart": this.mrTranslate.transform("neue Prüfart"),
        "Anzahl": 0
      });
      this.locked = false;
    }
  }

  apiServiceItem(status: string, value: string, gattungid?: number) {

    let profil = this.selecteditem?.Profil;
    this.isNewSBAGP = false;
    this.locked = true;
    let SBAGP = [];

    if (status != 'get' && this.isSBAGP) {
      SBAGP = this.stammdaten?.rows ?? [];
    }

    if (this.isBWChecked == undefined)
      this.isBWChecked = false;


    let inData = {
      status: status,
      id: this.selid,
      value: value,
      titel: this.titel,
      tabelle: this.tabelle,
      OTYPID: this.otypid,
      tname: this.tname,
      typid: this.typid,
      gattungid: gattungid || 0,
      schhmax: this.schhmax,
      bauwcheck: this.isBWChecked,
      profiltyp: this.profiltyp,
      profil: profil,
      base64Image: this.base64Image,
      sbagp: SBAGP,
      sbagpold: this.selSBAGPName,
      base64data: this.base64data,

    }

    this.stammdaten = undefined;
    this.SBAGPName = [];

    this.apiService
      .getBaseDataItems(inData)
      .pipe(first())
      .subscribe((val: any) => {

        if (this.isSBAGP) {
          this.SBAGPName = val.namen.rows;

          this.stammdaten = val.items;
          this.stammdatenOriginal = val.items.rows;

          setTimeout(() => {
            if (this.SBAGPName.length != 0)
              //this.selectedName = this.SBAGPName[0].Name;
              this.selectedNamechanged(this.SBAGPName[0].Name);
          }, 50);
        }
        else if (this.is_Stammitems && status == 'get') {
          this.gattungen = val.gattungen;
          this.stammdaten = val.stammdaten;
          this.stammdatenOriginal = val.stammdaten.rows;

          setTimeout(() => {
            if (this.stammdaten.rows.length != 0)
              this.selecteditem = this.stammdaten.rows[0];
          }, 10);
        }
        else {
          this.stammdaten = val;
          this.stammdatenOriginal = val.rows;

          setTimeout(() => {
            if (this.stammdaten.rows.length != 0)
              this.selecteditem = this.stammdaten.rows[0];
          }, 10);
        }


      });
  }

  close() {
    this.modalOpen = false;
  }

  onSubmit() {
    this.onOK.emit('');
  }

  deleteProfil() {
    this.ctx.clearRect(0, 0, this.sizew, this.sizeh);
    this.sollprofil = undefined;
    this.isProfile = false;

    if (this.selecteditem.Profil != undefined) {
      this.selecteditem.Profil = null;
    }
  }

  deletePDF() {
    this.base64data = '';
    this.selecteditem.AnweisungBase64 = null;
    this.pdffile = undefined;
  }

  deleteImage() {
    this.logo = undefined;
    this.base64Image = '';
    this.selecteditem.BildBase64 = null;
  }

  closeImage() {
    this.bildData = undefined;
  }

  showPreviewImage() {
    this.bildData = this._sanitizer.bypassSecurityTrustUrl(this.logo);
  }

  onProfilChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        let str = reader.result;
        this.sizeh = 200;
        this.sizew = 200;

        this.selecteditem.Profil = str;

        let textrows = str.toString().split(/\r?\n/);
        this.ctx = this.canvas.nativeElement.getContext('2d');
        this.sollprofil = textrows.map((el) => el.split(";")).map((elem) => [parseFloat(elem[0]), parseFloat(elem[1])]);
        this.isProfile = true;
        this.renderSoll();
      }
      reader.readAsText(file);
    }
  }


  renderSoll() {
    this.isProfile = true;
    this.ctx.clearRect(0, 0, this.sizew, this.sizeh);
    this.ctx.save();
    this.ctx.scale(1, -1);
    this.ctx.beginPath();
    this.ctx.lineWidth = 1;
    this.sollprofil.map((profile: any) => {
      let x = profile[0] + 100;
      let y = profile[1] - 100;
      this.ctx.lineTo(x, y);
    });

    this.ctx.translate(50, 50);
    this.ctx.strokeStyle = "blue";
    this.ctx.stroke();
    this.ctx.restore();

  }

  onPDFChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        let r = reader.result as string;
        var splitted = r.split(',');
        this.base64data = splitted[1];
        this.selecteditem.AnweisungBase64 = splitted[1];
        this.pdffile = this._sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + this.base64data);
      };

    }
  }

  onLogoChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        let r = reader.result as String;
        var splitted = r.split(',');
        this.base64Image = splitted[1];
        this.logo = r;
        this.selecteditem.BildBase64 = splitted[1];
      }
      reader.readAsDataURL(file);
    }
  }

  triggerLock() {
    this.locked = !this.locked;
  }

  selectedNamechanged(val: string) {
    this.selid = 1;
    this.selSBAGPName = val;
    this.tabelleNameGroup.controls.txt1s.setValue(val);
    this.stammdaten.rows = this.stammdatenOriginal.filter(stamm => stamm.Name == val);
  }
  emit() {
    this.onOK.emit({
      isChanged: this.isChanged,
      stammdaten: !this.isSBAGP ? this.stammdaten : { rows: this.SBAGPName }
    });
  }
}
