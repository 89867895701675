<div class="w-100-p padding-10-lr padding-12-b scroll-y h-60">
  <div *ngIf="alert" class="clr-row nxm clr-justify-content-center clr-align-items-baseline">
    <clr-alert class="clr-col-md-8 clr-col" clrAlertType="warning" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">
          {{ "Zuerst müssen " + (alert | titlecase) + " eingetragen werden" | mrTranslate }}
        </span>
        <div class="alert-actions">
          <a (click)="view.emit(alert)" class="alert-action"> {{ "Gehen zu " + (alert | titlecase) | mrTranslate }} </a>
        </div>
      </clr-alert-item>
    </clr-alert>
  </div>
  <ng-container *ngIf="objekteTable">
    <div class="clr-row nxm height-half" style="height: 60%">
      <div class="clr-col-md-6 h-100">
        <h4 style="margin-top: 12px;">{{ "Objekte" | mrTranslate }}</h4>
        <clr-datagrid #objekteGrid class="datagrid-compact" style="height: calc(100% - 36px);"
          [(clrDgSelected)]="selectedObjekte" [clrDgRowSelection]="true">
          <clr-dg-column *ngFor="let column of objekteTable['columns']" [clrDgField]="column.id">
            <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns}">
              {{ column.id | titlecase | mrUnitText }}
            </ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of objekteTable['rows']" [clrDgItem]="row">
            <clr-dg-cell class="name-cell" *ngFor="let cell of objekteTable['columns']"
              [innerHTML]="row[cell.id] | tablePrettyPrint">
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>{{objekteTable['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
      </div>
      <div class="clr-col-md-6 h-100">
        <h4 style="margin: 12px 0;">{{ "Verknüpfungen" | mrTranslate }}</h4>
        <div class="card item-table mt-0 d-f col clr-justify-content-between0"
          style="height: calc(100% - 48px);">
          <div class="card-block scroll-y h-60 np">
            <form #heightRef clrForm (ngSubmit)="saveIfValid()" #f="ngForm" [formGroup]="zuordnungFormGroup"
              class="width100" clrLabelSize="4">
              <div class="clr-row nxm clr-align-items-start margin--18-t">
                <clr-select-container class="nxm clr-col-xl-6">
                  <label class="clr-required-mark clr-col-4" for="maske">{{ "Maske" | mrTranslate }}</label>
                  <select clrSelect name="maske" formControlName="mID" class="clr-col-8">
                    <option *ngFor="let maske of maskenListe" [value]="maske.ID">
                      {{ maske.Name }}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">
                    {{ "Das Feld muss ausgefüllt werden" | mrTranslate}}
                  </clr-control-error>
                </clr-select-container>
                <clr-select-container class="nxm clr-col-xl-6">
                  <label class="clr-required-mark clr-col-4" for="personal">{{ "Verantwortlich" | mrTranslate }}</label>
                  <select clrSelect name="personal" class="cur-color clr-col-8" formControlName="pID">
                    <option *ngFor="let personal of personalListe" [value]="personal.ID">
                      {{ personal.Bezeichnung }}
                    </option>
                  </select>
                  <clr-control-error *clrIfError="'required'">
                    {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                  </clr-control-error>
                </clr-select-container>
              </div>

              <div class="clr-row nxm clr-align-items-start">
                <clr-input-container class="nxm clr-col-xl-4 clr-col-8">
                  <label class="clr-required-mark clr-col-6 clr-col-md-6" for="intervall">{{ "Intervall" | mrTranslate }}</label>
                  <input clrInput type="number" class="clr-col-md-6 clr-col-6"
                    min="0" name="intervall" formControlName="intervall"/>
                  <clr-control-error *clrIfError="'required'">
                    {{ "Erforderlich" | mrTranslate }}
                  </clr-control-error>
                  <clr-control-error *clrIfError="'min'">
                    {{ "Mindestens 0" | mrTranslate }}
                  </clr-control-error>
                </clr-input-container>
                <clr-select-container class="nxm clr-col-xl-2 clr-col-4" style="padding-left: 0;">
                  <label class="clr-col-12" for="einheit" style="margin-top: -1px;"></label><!-- {{ "in" | mrTranslate }} -->
                  <select class="clr-col-12" clrSelect name="einheit" formControlName="einheit">
                    <option [value]="'Tag'">{{ "Tag" | mrTranslate }}</option>
                    <option [value]="'Monat'">{{ "Monat" | mrTranslate }}</option>
                  </select>
                </clr-select-container>
                <clr-date-container class="nxm clr-col-xl-6">
                  <label class="clr-required-mark clr-col-4" for="intervallbeginn"> {{ "Beginn" | mrTranslate }} </label>
                  <input #dateModel clrDate name="intervallbeginn" autocomplete="off" type="date" class="clr-col-8"
                    [ngModel]="dateControl | localeDate"
                    (ngModelChange)="dateControl = localeDate.tosql($event)"
                    [ngModelOptions]="{updateOn: 'blur', standalone: true}"
                    [disabled]="zuordnungFormGroup.disabled" required/>
                  <clr-control-error *clrIfError="'required'">
                    {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
                  </clr-control-error>
                </clr-date-container>
              </div>
            </form>
            <div style="min-height: 120px; padding: 0 5px 3px"
              [dymanicStyle]="heightRef" addPx="3">
              <clr-datagrid #zuordnungsGrid *ngIf="zuordnungenTable" class="datagrid-compact h-100"
                [(clrDgSingleSelected)]="selectedZuordnung" [clrDgRowSelection]="true">
                <clr-dg-column *ngFor="let column of zuordnungenTable['columns']" [clrDgField]="column.id">
                  <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['mid', 'pid']}">
                    {{ column.id | titlecase | mrUnitText }}
                  </ng-container>
                </clr-dg-column>
                <clr-dg-row *clrDgItems="let row of zuordnungenTable['rows']" [clrDgItem]="row">
                  <clr-dg-cell class="name-cell" *ngFor="let cell of zuordnungenTable['columns']"
                    [innerHTML]="row[cell.id] | tablePrettyPrint">
                  </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>{{zuordnungenTable['rows'].length}} {{'Zeilen' | mrTranslate }}</clr-dg-footer>
              </clr-datagrid>
            </div>
          </div>
          <div class="card-footer">
            <div class="btn-group btn-primary btn-icon margin-0-t clr-flex-shrink-0">
              <button class="btn btn-icon btn-success" [title]="'Neu' | mrTranslate"
                (click)="addItem()" [disabled]="!selectedObjekte.length || insertItem">
                <clr-icon shape="file"></clr-icon>
              </button>
              <button class="btn btn-icon btn-danger" [title]="'Löschen' | mrTranslate"
                (click)="deleteItem()" [disabled]="!selectedZuordnung">
                <clr-icon shape="times"></clr-icon>
              </button>
              <button class="btn btn-icon margin-12-l" [title]="'Speichern' | mrTranslate"
                (click)="f.ngSubmit.emit()" [disabled]="!(insertItem || selectedZuordnung)">
                <clr-icon shape="floppy"></clr-icon>
              </button>
            </div>
            <span class="name-cell">
              {{ (insertItem
                ? "Neue Verknüpfung erstellen"
                : selectedZuordnung
                ? "Ausgewählte Verknüpfung bearbeiten"
                : ""
              ) | mrTranslate }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="clr-row nxm height-half" style="height: 40%;">
      <div class="clr-col-md-6 h-100 padding-24-t">
        <div class="border-table h-100 scroll">
          <table class="table margin-0-t table-compact h-100">
            <thead style="position: sticky; top: -1px; z-index: 3;">
              <tr>
                <th>{{'Check' | mrTranslate }}</th>
                <th>{{'Detail' | mrTranslate }}</th>
                <th class="left">{{'Bezeichnung' | mrTranslate }}</th>
                <th class="left">{{'Username' | mrTranslate }}</th>
                <th class="left">{{'Status' | mrTranslate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of maskenListe">
                <td> <!-- TODO disabled + item.ID übernehmen -->
                  <button class="btn btn-link w-h-auto" [class.disabled]="zuordnungFormGroup.disabled"
                    (click)="useMaskeInForm(item.ID)"
                    [title]="'Das Wert in Form übernehmen' | mrTranslate">
                    <clr-icon shape="check"></clr-icon>
                  </button>
                </td>
                <td>
                  <button class="btn btn-link w-h-auto"
                    (click)="showMaskeDetail(item.ID)"
                    [title]="'Arten in die Maske anzeigen' | mrTranslate">
                    <clr-icon shape="angle-double right"></clr-icon>
                  </button>
                </td>
                <td class="left">{{ item.Name }}</td>
                <td class="left">{{ item.UserName }}</td>
                <td class="left">{{ item.Status | tablePrettyPrint }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="clr-col-md-6 h-100 padding-24-t">
        <div class="border-table h-100 scroll">
          <table class="table margin-0-t table-compact h-100">
            <thead style="position: sticky; top: -1px; z-index: 3;">
              <tr>
                <th class="left">{{'Wartungsarten in Maske' | mrTranslate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of maskenItems" [class.headerRow]="item.Typ == 0">
                <td class="left">{{ item.Bezeichnung }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</div>