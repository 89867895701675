import { CommonModule } from "@angular/common";
import { Component, EventEmitter, HostListener, OnDestroy, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ClarityModule, ClrDatagrid } from "@clr/angular";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { first } from "rxjs/operators";

import { InTableValidatorDirective } from "src/app/directives/validators/in-table-validator.directive";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { MrUnitTextPipe } from "src/app/pipes/mrUnitText.pipe";
import { ObjTypePipe } from "src/app/pipes/objtype.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { LinieDrehungService } from "src/app/services/Shared/linie-drehung.service";
import { WebSocketService } from "src/app/services/Websocket/websocket.service";
import { slideAnimation } from '../../../../content/_components/detailview/slide.animation';
import { HistorieTreeComponent } from "../../detailview/historie-tree/historie-tree.component";
import { BauwerksDialogComponent } from "../bauwerksdialog/bauwerksdialog.component";
import { MangelDialogComponent } from "../mangelerfassungsdialog/mangeldialog.component";
import { SpezifikationsDialogComponent } from "../spezifikationsdialog/spezifikationsdialog.component";
import { StammdatenDialogComponent } from "../stammdatendialog/stammdatendialog.component";

@Component({
  selector: "app-inspektionsdialog",
  templateUrl: "./inspektionsdialog.component.html",
  styleUrls: ["./inspektionsdialog.component.scss"],
  imports: [
    CommonModule, ClarityModule, SpezifikationsDialogComponent,
    StammdatenDialogComponent, BauwerksDialogComponent, MrUnitTextPipe, HideIDColumnsPipe, TablePrettyPrintPipe,
    MangelDialogComponent, InTableValidatorDirective, HistorieTreeComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe, ObjTypePipe
  ],
  animations: [slideAnimation],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})

export class InspektionsDialogComponent implements OnDestroy {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private dataRefresh: EventEmitter<any>;

  @ViewChild(MangelDialogComponent, { static: false })
    private mangelTab: MangelDialogComponent
  ;
  @ViewChild(StammdatenDialogComponent, { static: false })
    private stammTab: StammdatenDialogComponent
  ;
  @ViewChild(SpezifikationsDialogComponent, { static: false })
    private spezTab: SpezifikationsDialogComponent
  ;
  @ViewChild(BauwerksDialogComponent, { static: false })
    private bauTab: BauwerksDialogComponent
  ;
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  ostammid: any;
  otypid: any;
  type: number = 1;
  stammData: any;
  pruefid: any;
  checklistennamen: any;
  forceClose:boolean = false;
  protected listNameModalNeu: string;
  protected messVerlMeldung: string;

  messungen: any;
  skizzeData: SafeUrl;
  showSkizzeModal: boolean = false;


  activeTab: any = {
    stamm: true,
    spez: false,
    mangel: false,
    mess: false,
    hist: false,
  };

  protected inspektiondate: string = BackendLocaleDatePipe.now;

  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private domSanitizer: DomSanitizer,
    private toastr: ToastrService,
    protected drehung: LinieDrehungService,
    protected websocket: WebSocketService,
    protected localeDate: BackendLocaleDatePipe,
  ) {}


  set data(inData: any) {
    this.ostammid = inData.ostamm;
    this.type = inData.type;
    this.otypid = inData.otypid;
    this.stammData = inData.stammData;
    this.drehung.summe = this.stammData;
    this.pruefid = inData.pruefid;
    this.dataRefresh = inData.dataRefresh;

    this.websocket._connection.on('inspClosed', data => {
      this.toastr.info(
        this.mrTranslate.transform("Inspektion '" + data[0] +"' wurde geschlossen von "  + data[1] + ". Stammdokument ist wieder bearbeitbar."),
        this.mrTranslate.transform("Inspektion")
      );
    });

    if (inData.type == 1 || inData.type == 3){
      this.getChecklisten();
      var result = (val) => {
        if (val){
        this.messungen = val["messung"];
        if (val["skizze"]) {
          this.skizzeData = this.domSanitizer.bypassSecurityTrustUrl(
            "data:image/jpeg;charset=utf-8;base64," + val["skizze"]
          );
        } else {
          this.skizzeData = undefined;
        }
      }
    }


      let vals = {
        status: 'inspektion',
        ostammid: this.ostammid,
      }
      this.apiService
      .getMessungAuswertung(vals)
      .pipe()
      .subscribe((val: any) => {
        result(val);
      });
    }
  }

  imageZoom(event) {
    if (event == "skizze") {
      this.showSkizzeModal = true;
    }
  }

  async saveCheckliste(newname: string) {
    const sendObj = {
      status: 'createnew',
      OTYPID: this.otypid,
      listpo: this.mangelTab.pruefarte,
      newname,
    }

    if (
      await firstValueFrom(this.apiService.getChecklistenItems(sendObj))
    ) {
      this.toastr.success(
        this.mrTranslate.transform("Checkliste wurde gespeichert"),
        this.mrTranslate.transform("Checkliste")
      );
      this.checklistennamen.push(newname);
    }
    else
      this.toastr.error(
        this.mrTranslate.transform("Checkliste konnte nicht gespeichert werden"),
        this.mrTranslate.transform("Checkliste")
      );
  }

  async addPartChecklist(name: string) {
    const sendObj = {
      status: 'updatetochecklist',
      typeid: this.type,
      otypid: this.otypid,
      pruefid: this.pruefid,
      ostammid: this.ostammid,
      pruefarte: this.mangelTab.pruefarte,
      checklistname: name,
    };
    const { mangel } =  await firstValueFrom(this.apiService.updateOMAZS(sendObj, -1))
    if (mangel?.rows) {
      this.mangelTab.mangel = mangel.rows;
      this.toastr.success(
        this.mrTranslate.transform("Prüfarte wurden ergänzt"),
        this.mrTranslate.transform("Checkliste")
      );
    }
    else
      this.toastr.error(
        this.mrTranslate.transform("Prüfarte konnten nicht ergänzt werden"),
        this.mrTranslate.transform("Checkliste")
      );
  }

  getChecklisten(){
    let items = {
      status: 'get',
      OTYPID: this.otypid,
    }

    this.apiService
      .getChecklistenItems(items)
      .pipe(first())
      .subscribe((val: any) => {
      if (val){
        this.checklistennamen = val.name.rows.map((row: any) => row.Titel);
      }

    });
  }


  tabChange(tab){
    this.activeTab.stamm =  false ;
    this.activeTab.spez =  false ;
    this.activeTab.mangel =  false ;
    this.activeTab.mess =  false ;
    this.activeTab.hist =  false ;

    switch (tab) {
      case "mangel":
        this.activeTab.mangel =  true ;
        break;
      case "mess":
        this.activeTab.mess =  true ;
        break;
      case "stamm":
        this.activeTab.stamm =  true ;
        break;
      case "spez":
        this.activeTab.spez =  true ;
        break;
      case "bauwerk":
        break;
      case "hist":
        this.activeTab.hist =  true ;
        break;

      default:
        break;
    }
  }
  protected success: { [key:string]: boolean } = {}
  private async validateTabs(): Promise<boolean> {
    this.success.stamm = await this.stammTab.saveIfValid();
    this.success.mangel = await this.mangelTab.checkValid();
    if (ObjTypePipe.is(this.type, 'point')) {
      this.success.spez = await this.spezTab.saveIfValid();
    }
    else {
      const { startmeter, endmeter } = this.drehung.summe = this.stammTab.linieValues;
      this.success.spez = this.spezTab.isVollständig(startmeter, endmeter);
      this.success.bauwerke = this.bauTab.checkValid(startmeter, endmeter);
      this.success.mangel = this.mangelTab.checkLinieValid(startmeter, endmeter)
        && this.success.mangel;
    }
    return Object.values(this.success).every(res => res);
  }

  isValided: boolean = false;
  isSaved: boolean = false;
  async saveitems(){
    this.isValided =  await this.validateTabs();

    if (this.isValided == false) return;
    await this.updatePruefung();
    this.isSaved = true;
    this.dataRefresh?.emit(true);
    this.toastr.success(
      this.mrTranslate.transform("Daten gespeichert"),
      this.mrTranslate.transform("Inspektion")
    );

  }

  protected async check_mess_verletzung() {
    if (!this.messungen?.length) {
      this.toastr.success(this.mrTranslate.transform(
        "Es konnten keine Messungen zur Prüfung gefunden werden..."
      ));
      return;
    }

    const mangel = this.mangelTab.mangel;
    const bezeichnungen = new Set<string>();
    let text = [];
    let innerHTML: string = "";

    this.messungen.forEach((mess: any) => {
      if (!mess.Ausfall) return;
      const mBez = mess.Bezeichnung.toLowerCase();
      const ist = mess.Ist?.replace(',', '.');
      const soll = mess.Soll?.replace(',', '.');
      let richtung: '+' | '-';
      bezeichnungen.add(mBez);

      if (mBez.includes("zp1") || soll == "<=" || +ist > +soll)
        richtung = "+";
      else if (soll == ">=" || +ist < +soll)
        richtung = "-";

      if (!richtung) return;

      const row = mangel.find(
        row => row.lage?.split(',').some(
          (lage: string) => lage.toLowerCase().trim() == mBez
        )
      );
      if (!row) text.push(
        mBez + " --> " + this.mrTranslate.transform("fehlt")
      );
      else if (
        !mBez.startsWith("ü") && !mBez.startsWith("zp")
        && this.isFalscheRichtung(row.mangel, richtung)
      ) text.push(
        mBez + " --> " + this.mrTranslate.transform("falscher Mangeleintrag")
      );

    });

    innerHTML = text.join(' <br> ');
    text = [];

    mangel?.forEach((mngl: any) => {
      if (!mngl.mangel || !this.isMessEintrag(mngl.pruefart)) return;
      const isDokumentiert = mngl.lage?.split(',').some(
        (lage: string) => bezeichnungen.has(lage.toLowerCase().trim())
      );
      if (!isDokumentiert) text.push(
        mngl.lage + " --> " + this.mrTranslate.transform("c")
      );
    });

    if (text.length) innerHTML += " <br><hr> " +
      this.mrTranslate.transform("Fehler in der Dokumentation") + ":<hr> " +
      text.join(" <br> ");

    if (innerHTML) this.messVerlMeldung = innerHTML;
    else this.toastr.success(this.mrTranslate.transform(
      "Alle Messungenverletzungen sind korrekt eingetragen..."
    ));
  }

  private isFalscheRichtung(mangel: string, richtung: '+' | '-') {
    switch (richtung) {
      case "+":
        return !(
          mangel.includes('+')
          || mangel.includes('groß')
          || mangel.includes('erweiter')
        );
      case "-":
        return !(
          mangel.includes('-')
          || mangel.includes('klein')
          || mangel.includes('vereng')
        );
    }
  }

  protected async clear_mess_verletzung() {
    const clearIDs = this.mangelTab.mangel.flatMap(
      row => this.isMessEintrag(row.pruefart) ? row.OMAZSID : []
    );

    const sendObj = {
      status: 'updatetoempty',
      clearIDs,
      typeid: this.type,
      otypid: this.otypid,
      pruefid: this.pruefid,
      ostammid: this.ostammid,
    };

    const { mangel } = await firstValueFrom(
      this.apiService.updateOMAZS(sendObj, -1)
    );
    this.mangelTab.mangel = mangel.rows;
  }

  private isMessEintrag(po: string): boolean {
    return po.includes("Höhenlage") || po.startsWith("ZuPrf") || [
      "Überhöhung",
      "Querhöhe",
      "Spurweite",
      "Spurweiten",
      "Leitweiten",
      "Leitkantenabstand",
      "Rillenweiten",
      "Rillentiefen",
      "Durchfahrrillen",
      "Durchfahrrille",
      "Schienenbreiten",
      "Spurunterschiede",
      "Verwindung",
      "Schienentiefen"
    ].some(po1 => po.includes(po1));
  }


  updatePruefung(){

    let items ={
      status: 'setInspektionsDatum',
      pruefid: this.pruefid,
      ostammid: this.ostammid,
      datum: this.inspektiondate || BackendLocaleDatePipe.now
    }
    return firstValueFrom(this.apiService.setUpdatePruefung(items));

  }

  async deletePruefung(){

    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie die aktuelle Inspektion löschen wollen?"))) {
      let items ={
        status: 'deleteInspektion',
        pruefid: this.pruefid,
        ostammid: this.ostammid,
      }

      this.apiService
        .setUpdatePruefung(items)
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {

            if (res.status == "OK") {
              this.toastr.info(this.mrTranslate.transform("Daten wurden gelöscht"));
              this.closedialog.next(true);
            }
          }
        });


    }
  }


  close() {

    if (this.isSaved == false && this.forceClose == false){
      this.toastr.error(this.mrTranslate.transform("Vor dem Beenden müssen Sie die Inspektion speichern...!"));
      return;
    }

    if (this.isValided == false && this.forceClose == false){
      this.toastr.error(this.mrTranslate.transform("Es gibt noch falsche Einträge...!"));
      return;
    }
    this.closedialog.next(true);
  }

  currentIndex: number = 0;
  currentHistIndex: number = 0;
  maxIndex: number = 0;
  allbild: any[] = [];
  bildData;
  showBildModal = false;

  setCurrentSlideIndex(index) {
    this.currentIndex = index;
}

  isCurrentSlideIndex(index) {
    return this.currentIndex === index;
  }
  setCurrentHistSlideIndex(index) {
    this.currentHistIndex = index;
  }

  isCurrentHistSlideIndex(index) {
    return this.currentHistIndex === index;
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex < this.allbild.length - 1) ? ++this.currentIndex : 0;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex > 0) ? --this.currentIndex : this.allbild.length - 1;
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    if (!this.forceClose) firstValueFrom(
      this.apiService.sendAnlagenMessage(this.ostammid, 'Close')
    );
  }
}
