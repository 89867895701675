import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  active: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  enable(){
    this.active.next(true);
  }

  disable(){
    this.active.next(false);
  }


}
