import { AfterViewInit, Directive, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { combineLatest, debounceTime, Subject, Subscription } from 'rxjs';
import { ClrTabLink, ClrTabs } from '@clr/angular';
import ResizeObserver from 'resize-observer-polyfill';


/**
 * @example <clr-tabs dynamicOverflow class="w-100-p overflow-tab">
 */
@Directive({
  selector: 'clr-tabs.overflow-tab[dynamicOverflow]',
  standalone: true

})
export class TabsInOverflowDirective implements AfterViewInit, OnDestroy {
  private resizer: ResizeObserver;
  private resized$ = new Subject<ResizeObserverEntry[]>();

  private innerWidth$ = new Subject<number>();
  private outerWidth$ = new Subject<number>();

  private sub: Subscription;

  private innerContainer: HTMLUListElement;
  private outerContainer: HTMLElement;

  constructor(
    private el: ElementRef<HTMLElement>,
    private tabs: ClrTabs,
    private zone: NgZone
  ) {}

  ngAfterViewInit() {
    this.outerContainer = this.el.nativeElement;
    this.innerContainer = this.outerContainer.firstElementChild as HTMLUListElement;
    this.catchChanges();
  }

  private catchChanges() {
    this.resizer = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => this.resized$.next(entries)
    );

    this.resizer.observe(this.outerContainer);
    this.resizer.observe(this.innerContainer);

    this.sub = this.resized$.pipe(debounceTime(100)).subscribe(
      (entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          const width = entry.borderBoxSize[0].inlineSize;
          if (entry.target === this.innerContainer)
            this.innerWidth$.next(width);
          else if (entry.target === this.outerContainer)
            this.outerWidth$.next(width);
        }
      }
    );

    this.sub.add(combineLatest([ this.outerWidth$, this.innerWidth$ ]).subscribe(
      ([ outer, inner ]) => {
        if (inner > outer) this.hideInOverflow(outer);
        else if (outer > inner) this.showIfNotOverflow(outer, inner);
      }
    ));
  }

  private hideInOverflow(outer: number) {
    const tabs = this.tabs.tabLinkDirectives;
    for (let i = tabs.length - 1; i >= 0; i--) {
      const tab = tabs[i];
      if (tab.inOverflow) continue;
      const el = tab.el.nativeElement as HTMLElement;
      if (el.offsetLeft + el.offsetWidth + 15 > outer)
        this.toogleTabOverflow(tab);
      else break;
    }
  }

  private showIfNotOverflow(outer: number, inner: number) {
    const tabs = this.tabs.tabLinkDirectives;
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      if (!tab.inOverflow) continue;
      const el = tab.el.nativeElement as HTMLElement;
      if ((inner += el.innerText.length * 9.5 + 15) < outer)
        this.toogleTabOverflow(tab);
      else break;
    }
  }

  private toogleTabOverflow(tab: ClrTabLink) {
    this.zone.run(() => tab.inOverflow = !tab.inOverflow);
  }

  ngOnDestroy() {
    this.resizer.disconnect();
    this.sub.unsubscribe();
  }
}
