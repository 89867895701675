
<div *ngIf="isconfiged; else notconfiged">
  <div class="clr-row nxm" style="align-items: center;">
    <div class="clr-col-auto margin-12-tb">
      <div class="btn-group btn-primary btn-icon">
        <button class="btn btn-icon btn-success" [title]="'Neue Begehung durchführen' | mrTranslate"
          (click)="addBegehung()" [disabled]="obegeid == 0">
          <clr-icon shape="file"></clr-icon>
        </button>
        <button class="btn btn-icon" [title]="'Begehung fortsetzen' | mrTranslate"
          (click)="continueBegehung()" [disabled]="!canContinueBegehung">
          <clr-icon shape="step-forward-2"></clr-icon>
        </button>
        <button class="btn btn-icon" [title]="'Begehung einrichten' | mrTranslate"
        (click)="openBegehungsEinstellungen()">
        <clr-icon shape="cog"></clr-icon>
      </button>
      </div>
    </div>
  </div>
  <div class="clr-row nxm" style="padding-right:10px;">
    <div class="clr-col-md-8" >
      <div class="clr-row nxm" style="padding-right:10px;">
          <h4 style="margin-top: 2px;">{{ "Begehungsart" | mrTranslate }}</h4>
      </div>
      <div class="limit-height">
        <clr-datagrid *ngIf="begehungTable?.rows?.length > 0" #objekteGrid class="datagrid-compact"
          [(clrDgSingleSelected)]="selectedBegehung" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectionGridChanged($event)">
            <clr-dg-column *ngFor="let column of begehungTable['columns']" [clrDgField]="column.id">
              <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['_tag','obegeid','obegpid', 'OBEGEID', 'OBEGPID']}">
                {{ column.id | titlecase | mrUnitText }}
              </ng-container>
            </clr-dg-column>
            <clr-dg-row *clrDgItems="let row of begehungTable['rows']" [clrDgItem]="row">
              <clr-dg-cell class="name-cell" *ngFor="let cell of begehungTable['columns']"
                [innerHTML]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>{{begehungTable['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>

    <div class="clr-col-md-4" >
      <div class="limit-height">
        <div class="clr-row nxm" style="padding-right:10px;">
          <h4 style="margin-top: 2px;">{{ "Historie" | mrTranslate }}</h4>
        </div>

        <clr-datagrid *ngIf="begehungHistoryTable?.columns?.length > 0" #objekteGrid class="datagrid-compact"
          [(clrDgSingleSelected)]="selectedHistoryBegehung" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectionHistoryChanged($event)">
            <clr-dg-column *ngFor="let column of begehungHistoryTable['columns']" [clrDgField]="column.id">
              <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['_tag','obegeid','obegpid', 'OBEGEID', 'OBEGPID']}">
                {{ column.id | titlecase | mrUnitText }}
              </ng-container>
            </clr-dg-column>
            <clr-dg-row *clrDgItems="let row of begehungHistoryTable['rows']" [clrDgItem]="row">
              <clr-dg-cell class="name-cell" *ngFor="let cell of begehungHistoryTable['columns']"
                [innerHTML]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row>
            <clr-dg-footer>{{begehungHistoryTable['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
        </clr-datagrid>
      </div>
    </div>
  </div>

  <ng-container *ngIf="selectedZuordnungTable?.rows?.length > 0">
    <div class="clr-row nxm" style="padding-right:10px;">
      <div class="clr-col-md-4">
        <h4 style="margin-top: 5px;">{{ "Objekte" | mrTranslate }}</h4>
      </div>
    </div>
    <div class="clr-row nxm" style="padding-right:10px;">
      <div class="clr-col-md-12" >

        <clr-datagrid #objekteDatagrid class="datagrid-compact" style="height: calc(100% - 60px);">

          <ng-container *ngIf="obegpid != 0">
            <clr-dg-column>{{ 'Abgeschlossen' | titlecase | mrTranslate }}</clr-dg-column>
            <clr-dg-column>{{ 'Datum' | titlecase | mrTranslate }}</clr-dg-column>
          </ng-container>
          <clr-dg-column *ngFor="let column of selectedZuordnungTable['columns']" [clrDgField]="column.id" [style.min-width.px]="(column.id.length * 7.5) + 26">
              <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['sp', 'lf','st','rep']}">
                {{ column.id | titlecase | mrTranslate }}
              </ng-container>
            </clr-dg-column>

            <clr-dg-row *clrDgItems="let row of selectedZuordnungTable['rows']" [clrDgItem]="row">

              <ng-container *ngIf="obegpid != 0">

                <clr-dg-cell [innerHTML]="getGridValue(row,'closed') | dotDotDot:30 | tablePrettyPrint"
                  [title]="getGridValue(row,'closed') | tablePrettyPrint">
                </clr-dg-cell>

                <clr-dg-cell [innerHTML]="getGridValue(row,'date') | dotDotDot:30 | tablePrettyPrint"
                  [title]="getGridValue(row,'date') | tablePrettyPrint">
                </clr-dg-cell>

              </ng-container>

              <ng-container *ngFor="let cell of selectedZuordnungTable['columns']">
                <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
                  <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                    [title]="row[cell.id] | tablePrettyPrint">
                  </clr-dg-cell>
                </ng-container>
                <ng-template #iconcell>
                  <clr-dg-cell style="color:#000">
                    <ng-container [ngSwitch]="cell.id.toLowerCase()">
                      <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                        [attr.shape]="row['typ_icon'] ? 'objekttyp'+row['typ_icon'] : 'unknown-status'" size="20">
                      </clr-icon>
                    </ng-container>
                  </clr-dg-cell>
                </ng-template>
              </ng-container>
            </clr-dg-row>
            <clr-dg-footer>
              <clr-dg-column-toggle></clr-dg-column-toggle>
              <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="12">
                <clr-dg-page-size [clrPageSizeOptions]="[12,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                </clr-dg-page-size>
                {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
              </clr-dg-pagination>
            </clr-dg-footer>
        </clr-datagrid>

      </div>
    </div>
  </ng-container>
</div>
<ng-template #notconfiged>
  <br/>
  <div class="clr-row nxm" style="margin-top: 20">
    <button class="btn btn-sm" style="display: block; margin: 0 auto;" (click)="openBegehungsEinstellungen()"><clr-icon shape="cog"></clr-icon> {{ "Begehung einrichten" | mrTranslate }}</button>
  </div>
</ng-template>
