<clr-modal [(clrModalOpen)]="modalOpen" (clrModalOpenChange)="!$event && emit()" [clrModalSize]="boolValue == true ? ModalSize :'lg'" [clrModalClosable]="true">

  <h3 class="modal-title">{{titel}}</h3>
  <div class="modal-body">

    <div *ngIf="bildData == undefined; else vollbild">
      <form clrForm class="clr-form" [formGroup]="tabelleNameGroup">
        <div class="btn-group btn-primary btn-icon">
          <button class="btn btn-success" (click)="newitem()">
            <clr-icon shape="file"></clr-icon>
            <span class="clr-icon-title">Add</span>
          </button>
          <button class="btn" (click)="saveitem()">
            <clr-icon shape="floppy"></clr-icon>
            <span class="clr-icon-title">Save</span>
          </button>
          <button class="btn btn-danger" [disabled]="selid == 0" (click)="deletitem()">
            <clr-icon shape="times"></clr-icon>
            <span class="clr-icon-title">Delete</span>
          </button>

          <ng-container *ngIf="isSBAGP == true">
            <button class="btn" (click)="triggerLock()">
              <clr-icon [attr.shape]="locked ? 'lock' : 'unlock'"></clr-icon>
            </button>
          </ng-container>

        </div>

        <ng-container *ngIf="isSBAGP == false;else showSBAGP">
          <br/>
          <!-- <br/> -->
          <div class="clr-row nxm">
            <clr-input-container class="clr-col">
              <input type="text" id="basic" placeholder="Enter value here" #bezfield
                clrInput formControlName="txt0s"/>
              <clr-control-error *clrIfError="'required'">
                {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
              </clr-control-error>
            </clr-input-container>
            <clr-select-container class="clr-col-auto" *ngIf="is_Stammitems">
              <label for="gattung" style="display: block;">{{ "gattung" | titlecase | mrTranslate }}</label>
              <select clrSelect formControlName="gattung" name="gattung">
                <option *ngFor="let obj of gattungen" [value]="obj.ID">
                  {{obj.Bezeichnung}}
                </option>
              </select>
              <clr-control-error *clrIfError="'required'">
                {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
              </clr-control-error>
            </clr-select-container>
          </div>

          <div class="limit-height" *ngIf="boolValue == false">
            <clr-datagrid #dgStammdaten *ngIf="stammdaten?.columns; else elseTemplate" style="padding-top: 0px" class="datagrid-compact w-100-p" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selecteditem" (clrDgSingleSelectedChange)="selectionFirmaChanged($event)">
              <clr-dg-column *ngFor=" let column of stammdaten['columns']" [clrDgField]="column.id"
                [hidden]="column.id | hideIDColumns:['TypID','Profiltyp','SchHMax','BildBase64','Profil', 'intid']">{{column.id | mrTranslate}}</clr-dg-column>
                <clr-dg-row *clrDgItems="let row of stammdaten['rows']; trackBy: trackById" [clrDgItem]="row">
                  <clr-dg-cell class="name-cell" *ngFor="let cell of stammdaten['columns']"
                    [hidden]="cell.id | hideIDColumns:['TypID','profiltyp', 'schhmax','bildbase64','profil', 'intid']" [innerHTML]="row[cell.id] | tablePrettyPrint">
                  </clr-dg-cell>
                </clr-dg-row>
              <clr-dg-footer>
                <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{stammdaten['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
              </clr-dg-footer>
            </clr-datagrid>
            <ng-template #elseTemplate> <div style="height: 400px;"></div> </ng-template>
          </div>

          <div *ngIf="boolValue == true">
              <div class="clr-row">
                <div class="clr-col-9">
                  <div class="limit-height">
                    <clr-datagrid #dgStammdaten *ngIf="stammdaten?.columns; else elseTemplate" style="padding-top: 0px" class="datagrid-compact" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selecteditem" (clrDgSingleSelectedChange)="selectionFirmaChanged($event)">
                      <clr-dg-column *ngFor=" let column of stammdaten['columns']" [clrDgField]="column.id"
                        [hidden]="column.id | hideIDColumns:['TypID','profiltyp', 'schhmax','bildbase64','profil','anweisungbase64']">{{column.id | mrTranslate}}</clr-dg-column>
                      <clr-dg-row *clrDgItems="let row of stammdaten['rows']; trackBy: trackById" [clrDgItem]="row">
                        <clr-dg-cell class="name-cell" *ngFor="let cell of stammdaten['columns']"
                          [hidden]="cell.id | hideIDColumns:['TypID','profiltyp', 'schhmax','bildbase64','profil','anweisungbase64']" [innerHTML]="row[cell.id] | tablePrettyPrint">
                        </clr-dg-cell>
                      </clr-dg-row>
                      <clr-dg-footer>
                        <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{stammdaten['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                    <ng-template #elseTemplate> <div style="height: 400px;"></div> </ng-template>
                  </div>
                </div>
                <div class="clr-col-3">

                  <div *ngIf="is_Weichentyp == true && selecteditem != undefinded" [formGroup]="tabelleNameGroup">
                    <h5>{{ 'Sperrtyp' | mrTranslate }}</h5>
                    <hr>
                    <div class="clr-row">
                      <div class="clr-col">
                        <clr-radio-wrapper>
                          <input type="radio" clrRadio [value]="1" formControlName="typid"/>
                        </clr-radio-wrapper>
                      </div>
                      <div class="clr-col">
                        <clr-icon shape="sperrtyp1" size="64"></clr-icon>
                      </div>
                    </div>
                    <hr>
                    <div class="clr-row">
                      <div class="clr-col">
                        <clr-radio-wrapper>
                          <input type="radio" clrRadio [value]="2" formControlName="typid"/>
                        </clr-radio-wrapper>
                      </div>
                      <div class="clr-col">
                        <clr-icon shape="sperrtyp2" size="64"></clr-icon>
                        <clr-icon shape="sperrtyp3" size="64"></clr-icon>
                      </div>
                    </div>
                    <hr>
                    <div class="clr-row">
                      <div class="clr-col">
                        <clr-radio-wrapper>
                          <input type="radio" clrRadio [value]="3" formControlName="typid"/>
                        </clr-radio-wrapper>
                      </div>
                      <div class="clr-col">
                        <clr-icon shape="sperrtyp4" size="64"></clr-icon>
                      </div>
                    </div>
                    <hr>

                    <div class="clr-row">
                      <div class="clr-col">
                        <clr-radio-wrapper>
                          <input type="radio" clrRadio [value]="0" formControlName="typid"/>
                        </clr-radio-wrapper>
                      </div>
                      <div class="clr-col">
                        <clr-icon shape="error-standard" size="32"></clr-icon>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="is_Bauwerk == true && selecteditem != undefinded">
                    <br/>
                    <div class="clr-row">
                      <div class="clr-col">
                        <div>{{ 'Bauwerk prüfen' | mrTranslate }}</div>
                      </div>
                      <div class="clr-col">
                        <input type="checkbox" clrCheckbox [value]="0"  formControlName="checkbauwerk" (change)="onBauwerkCheckboxChange($event)" />
                      </div>
                    </div>
                    <ng-container *ngIf="isBWChecked">
                      <ng-container *ngIf="!pdffile;else mitPDF">
                        <div class="clr-row" style="margin-top: 5px;" >
                          <div class="clr-col">
                            <div>{{'Prüfanweisung' | mrTranslate}}</div>
                          </div>
                          <div class="clr-col">
                            <clr-icon class="icon" shape="pdffile" (click)="pdfInput.click()" class="cursor-pointer" size="24"></clr-icon>
                          </div>
                        </div>
                        <input #pdfInput type="file" id="fileUpload" name="fileUpload" accept=".pdf" style="display:none;" (change)="onPDFChanged($event)"/>
                      </ng-container>
                      <ng-template #mitPDF>
                        <div class="clr-row" style="margin-top: 5px;">
                          <div class="clr-col">
                            <div>{{'Prüfanweisung' | mrTranslate}}</div>
                          </div>
                          <div class="clr-col">
                            <!-- <clr-icon class="icon" shape="search" (click)="deletePDF()" class="cursor-pointer" size="22"></clr-icon> -->
                            <div class="inline-block float-right">
                              <clr-icon size="22" shape="times" (click)="deletePDF()" class="cursor-pointer"></clr-icon>
                            </div>
                          </div>
                        </div>
                        <div class="clr-row" style="margin-top: 10px;">
                          <iframe frameborder="0" [attr.src]="pdffile" width="" height=""></iframe>
                        </div>
                      </ng-template>
                    </ng-container>
                  </div>


                  <div *ngIf="is_Schienenform == true && selecteditem != undefinded">
                    <br/>
                    <div class="clr-row">
                      <div class="clr-col">
                        <div>{{ 'Vignolschiene' | mrTranslate }}</div>
                      </div>
                      <div class="clr-col">
                        <input type="radio" clrRadio [value]="'Vignol'"  formControlName="profiltyp" />
                      </div>
                    </div>
                    <div class="clr-row" style="margin-top: 5px;">
                      <div class="clr-col">
                        <div>{{ 'Rillenschiene' | mrTranslate }}</div>
                      </div>
                      <div class="clr-col">
                        <input type="radio" clrRadio [value]="'Rille'" formControlName="profiltyp" />
                      </div>
                    </div>
                    <div class="clr-row" style="margin-top: 5px;">
                      <div class="clr-col-6"  *ngIf="isVignol== true">
                        <div>{{ 'Schienenhöhe' | mrTranslate }}</div>
                      </div>
                      <div class="clr-col-6"  *ngIf="isVignol == false">
                        <div>{{ 'Rillentiefe' | mrTranslate }}</div>
                      </div>
                      <div class="clr-col-4">
                        <input type="number" id="basic" class="clr-input" formControlName="schhmax" />
                      </div>
                      <div class="clr-col-2">
                        mm
                      </div>
                    </div>
                    <div class="clr-row" style="margin-top: 5px;" *ngIf="!logo; else mitbild">
                      <div class="clr-col">
                        <div>{{'Bild' | mrTranslate}}</div>
                      </div>
                      <div class="clr-col">
                        <clr-icon class="icon" shape="plus-circle" (click)="fInput.click()" class="cursor-pointer" size="22"></clr-icon>
                      </div>
                    </div>

                    <ng-template #mitbild>
                      <div class="clr-row" style="margin-top: 10px;">
                        <img class="image" [src]="logo" (click)="showPreviewImage()">
                        <clr-icon class="icon" shape="times" (click)="deleteImage()" style="margin-left: -20px;" class="cursor-pointer" size="22"></clr-icon>
                      </div>
                    </ng-template>

                    <div class="clr-row" style="margin-top: 5px;">
                      <div class="clr-col">
                        <div>{{'Profil' | mrTranslate}}</div>
                      </div>
                      <div class="clr-col">
                        <clr-icon *ngIf="!isProfile" class="icon" shape="plus-circle" (click)="pInput.click()" class="cursor-pointer" size="22"></clr-icon>
                      </div>
                    </div>

                    <div class="clr-row" style="margin-top: 5px; margin-left: 30px;">
                      <canvas #canvas [height]="sizeh" [width]="sizew" class="profileL" style="border:1px solid #8f9ba3;"></canvas>
                      <clr-icon *ngIf="isProfile" class="icon" shape="times" (click)="deleteProfil()" style="margin-left: -30px;" class="cursor-pointer" size="22"></clr-icon>
                    </div>


                    <input #pInput type="file" id="fileUpload" name="fileUpload" accept=".txt" style="display:none;" (change)="onProfilChanged($event)"/>
                    <input #fInput type="file" id="fileUpload" name="fileUpload" accept=".png" style="display:none;" (change)="onLogoChanged($event)"/>

                  </div>
                </div>
              </div>
            </div>

        </ng-container>
        <ng-template #showSBAGP>
          <form clrForm class="clr-form clr-form-horizontal" [formGroup]="tabelleNameGroup">
            <ng-container *ngIf="!isNewSBAGP && stammdaten?.rows?.length; else isnew">
              <div class="clr-row">
                <div class="clr-col">
                  <div style="margin-top: 25px;">Typ</div>
                </div>
                <div class="clr-col">
                  <ng-container *ngIf="locked == true;else _unlocked">
                    <clr-select-container>
                      <select clrSelect #sel (change)="selectedNamechanged(sel.value)">
                        <option *ngFor="let item of SBAGPName" [value]="item.Name">
                          {{ item.Name }}
                        </option>
                      </select>
                    </clr-select-container>
                  </ng-container>
                  <ng-template #_unlocked>
                    <br>
                    <input type="text" id="basic" placeholder="Enter value here" #bezfield class="clr-input" formControlName="txt1s"/>
                  </ng-template>
                </div>
              </div>
            </ng-container>
            <ng-template #isnew>
              <br>
              <input type="text" id="basic" placeholder="Enter value here" #bezfield class="clr-input" formControlName="txt0s"/>
              <br>
            </ng-template>
            <br>
            <hr>
            <ng-container *ngFor="let row of stammdaten?.rows">
              <div class="clr-row">
                <div class="clr-col">
                  <ng-container *ngIf="locked == true;else unlocked">
                    <div style="margin-top: 25px;">{{row['Prüfart']}}</div>
                  </ng-container>
                  <ng-template #unlocked>
                    <div class="clr-control-container">
                      <input clrInput #bezfield type="text" [(ngModel)]="row['Prüfart']" [ngModelOptions]="{ standalone: true }" [readonly]="locked"/>
                    </div>
                  </ng-template>
                </div>
                <div class="clr-col">
                  <div class="clr-control-container">
                    <input clrInput #bezfield type="number" [(ngModel)]="row['Anzahl']" [ngModelOptions]="{ standalone: true }"/>
                  </div>
                </div>
              </div>
            </ng-container>
            <button type="button" class="btn btn-outline margin-10-t" (click)="newRow()"> {{"Neue Prüfart" | mrTranslate}} </button>
          </form>
        </ng-template>
      </form>
    </div>
    <ng-template #vollbild>
      <div class="clr-row previewThumbnailsContainer" [style.justify-content]="'space-evenly'">
        <div class="uploadImagePreviewContainer">
          <a class="deletePreview" href="javascript:void(0);"
          (click)="closeImage()">
            <clr-icon shape="times" size="22"></clr-icon>
          </a>
          <img [src]="logo" alt="" class="uploadImagePreview">
        </div>
      </div>
    </ng-template>
  </div>

  <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="emit()">{{ 'Beenden' | mrTranslate }}</button>
  </div>
</clr-modal>
