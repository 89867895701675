<clr-modal #logModal (clrModalOpenChange)="closeMangelLog.emit(null)"
  [clrModalOpen]="true" [clrModalSize]="'lg'" [clrModalStaticBackdrop]="false">
  <h3 class="modal-title">{{'Mangel-Log' | mrTranslate}}</h3>
  <div class="modal-body">
    <clr-datagrid *ngIf="log$ | async as logTable" class="datagrid-compact">
      <clr-dg-column *ngFor="let column of logTable.columns" [clrDgField]="column.id">
        <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns}">
          {{ column.id | titlecase | mrUnitText }}</ng-container>
      </clr-dg-column>

      <clr-dg-row *clrDgItems="let row of logTable.rows">
        <clr-dg-cell class="name-cell" *ngFor="let cell of logTable.columns" 
          [innerHTML]="row[cell.id] | tablePrettyPrint "></clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>
        {{logTable.rows?.length}} {{'Zeilen' | mrTranslate}}
      </clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="logModal.close()">
      {{'Schließen' | mrTranslate}}</button>
  </div>
</clr-modal>