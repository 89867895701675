import { Attribute, Directive, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, skip, startWith, Subject, Subscription } from 'rxjs';


/**
 * @description für Anzeige/Ausblendung abhängig von element;
 * @hides host, wenn mit `*` benutzt;
 * @returns true/false durch template variable (query = directive) q.matches
 * @example *el-media-query="{min: 1000, max: 1200, el: elref}
 * @example [el-media-query]="{max: 600, el: elref}" #q="query" [clrInMenu]="q.matches"
 */
@Directive({
  selector: '[el-media-query]',
  exportAs: 'query',
  standalone: true

})
export class ElementMediaQueryDirective implements OnInit, OnDestroy {
  @Input({ alias: 'el-media-query', required: true })
    private mode: { min?: number; max?: number, el: HTMLElement }
  ;

  private resizer: ResizeObserver;
  private resized$ = new Subject<ResizeObserverEntry[]>();
  private sub: Subscription;

  public matches: boolean = false;

  private readonly box_size: 'blockSize' | 'inlineSize';
  constructor(
    @Optional() private readonly viewContainerRef: ViewContainerRef,
    @Optional() private readonly templateRef: TemplateRef<unknown>,
    @Attribute('media_height') private readonly dimension: string,
    @Attribute('debounce_time') private readonly timeout: number,
  ) {
    this.dimension = dimension !== null ? 'height' : 'width';
    this.box_size = this.dimension == 'height' ? 'blockSize' : 'inlineSize';
    this.timeout = Number(timeout ?? 200);
  }

  ngOnInit() {
    if (!this.mode || !(this.mode.min || this.mode.max) || !this.mode.el) return;

    this.resizer = new ResizeObserver(
      (entries: ResizeObserverEntry[]) => this.resized$.next(entries)
    );

    this.resizer.observe(this.mode.el);

    this.sub = this.resized$.pipe(
      debounceTime(this.timeout),
      map(entries => {
        const size = entries[0].borderBoxSize[0][this.box_size];
        return (
          !this.mode.max || size <= this.mode.max
        ) && (
          !this.mode.min || size >= this.mode.min
        );
      }),
      startWith(false), distinctUntilChanged(), skip(1),
    ).subscribe(matches => this.updateView(matches));

  }

  private updateView = (matches: boolean) => {
    this.matches = matches;
    if (matches && this.templateRef) {
      const ref = this.viewContainerRef?.createEmbeddedView(this.templateRef);
      ref?.markForCheck();
    } else this.viewContainerRef?.clear();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}