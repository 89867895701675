import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableStateService {
  private tables: { [id: string]: any } = {};
  private resetAllSubject: Subject<void> = new Subject<void>();
  private tableResetSubjects: { [id: string]: Subject<void> } = {};

  constructor() {}

  getByTableName(tableName: string): any {
    if (this.tables[tableName]) {
      return this.tables[tableName];
    }
    return null;
  }

  upsertTableByName(tableName: string, filterData: any): void {
    this.tables[tableName] = filterData;
  }

  resetAll(): void {
    this.tables = {};
    this.resetAllSubject.next();
  }

  reset(tableName: string): void {
    this.tables[tableName] = {};
    this.getFormResetSubject(tableName).next();
  }

  getResetAllObservable(): Observable<void> {
    return this.resetAllSubject.asObservable();
  }

  getFormResetObservable(tableName: string): Observable<void> {
    return this.getFormResetSubject(tableName).asObservable();
  }

  private getFormResetSubject(tableName: string): Subject<void> {
    if (!this.tableResetSubjects[tableName]) {
      this.tableResetSubjects[tableName] = new Subject<void>();
    }
    return this.tableResetSubjects[tableName];
  }
}
