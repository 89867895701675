import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: "root"
})
export class EncryptionService {
  key: string = String.fromCharCode(76,113,51,81,55,77,83,99,52,54,98,66,87,68,90,114,49,78,108,89,81,47,73,116,122,77,49,98,82,105,79,113,69,106,70,80,79,52,66,55,83,111,68,104,71,81,43,48,50,76,68,80,108,69,83,113,83,71,74,122,100,73,118,73);
  constructor() {}

  encryptionAES (msg) {
    // Encrypt
    const ciphertext = CryptoJS.AES.encrypt(msg, this.key);
    return ciphertext.toString();
  }

  decryptionAES (msg) {
    // Decrypt
    const bytes  = CryptoJS.AES.decrypt(msg, this.key);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
}
