// auth.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

// Define interfaces for user DTOs
interface UserDTO {
  Email: string;
  Password: string;
  Forename: string;
  Lastname: string;
  Username: string;
}

interface OktaUserDTO {
  Email: string;
  Token: string;
  Forename: string;
  Lastname: string;
  Username: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthNewService {
  private apiUrl = 'https://localhost:44365/api/Auth'; // Change this to your backend API URL
  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  register(user: UserDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/register`, user);
  }

  login(credentials: UserDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/login`, credentials).pipe(
      tap((response: any) => this.handleLoginResponse(response))
    );
  }

  oktaLogin(claims: OktaUserDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Okta/Login`, claims).pipe(
      tap((response: any) => this.handleLoginResponse(response))
    );
  }

  public handleLoginResponse(response: any): void {
    localStorage.setItem('token', response.token);
    localStorage.setItem('expiresAt', response.expiresAt);
    localStorage.setItem('refresh', response.refresh);
    localStorage.setItem('rights', JSON.stringify(response.rights));
    localStorage.setItem('db', response.db);
    localStorage.setItem('image', response.image);
    this.loggedIn.next(true);
  }

  logout(): void {
    // Implement your logout logic here
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('refresh');
    localStorage.removeItem('rights');
    localStorage.removeItem('db');
    localStorage.removeItem('image');
    this.loggedIn.next(false);
  }

  isAuthenticated(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  postSecuredData(user: UserDTO): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/secured`, user);

  }
}
