import { Component, OnInit, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { APIService } from '../../../../services/APIService/api.service';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from 'src/app/services/Spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { DndModule } from 'ngx-drag-drop';

import { environment } from "src/environments/environment";
import * as Plotly from 'plotly.js-basic-dist-min';

import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlAttribution from 'ol/control/Attribution';
import OlXYZ from 'ol/source/XYZ';
import OlVectorLayer from 'ol/layer/Vector';
import OlVectorSource from 'ol/source/Vector';
import OlTileLayer from 'ol/layer/Tile';
import OlGeoJSON from 'ol/format/GeoJSON';
import OlStyle from 'ol/style/Style';
import OlStroke from 'ol/style/Stroke';
import { defaults } from 'ol/control';
import { fromLonLat, transform } from 'ol/proj';
import lineSliceAlong from '@turf/line-slice-along';
import { getLength as geoDesLength } from "ol/sphere";
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import OlFill from 'ol/style/Fill';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import { TileLayerObj } from 'src/app/content/GISMap/ol-exports';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlotlyPlotComponent } from '../../plotly-plot/plotly-plot.component';

@Component({
  selector: 'app-messungsdialog',
  templateUrl: './messungsdialog.component.html',
  styleUrls: ['./messungsdialog.component.scss'],
  imports: [CommonModule, ClarityModule, DndModule, MrTranslatePipe, FormsModule, ReactiveFormsModule,PlotlyPlotComponent],
  standalone: true
})
export class MessungsdialogComponent implements OnInit {
  ostammid: number;
  tblPruefids: any;
  selection: any;
  selpruefids: string = "";
  selDates: string[] = [];
  curIndex = 0;
  selIsOpen = false;
  map: OlMap;
  geolayer:any;
  Object = Object;
  placeHolderHeight = 50;
  draganddropactive = false;
  isDetailViewVisible = false;
  curGraph: any;
  layers: any[] = [];
  positionFeature = new Feature();
  highlightfeature: any;
  darkMap:boolean = false;

  min:number;
  max:number;

  graph: any = {
    layout: {
      autosize: true,
      hovermode: 'closest',
      showlegend: false,
      legend: { "orientation": "h" },
      height: 250,
      margin: {
        l: 35,
        r: 20,
        b: 25,
        t: 20,
        pad: 0
      },
      plot_bgcolor: 'transparent',
      paper_bgcolor: 'transparent'
    },
    config: {
      responsive: true,
      displaylogo: false,
      locale: 'de',
      modeBarButtonsToRemove: ['sendDataToCloud', 'lasso2d', 'zoomOut2d', 'zoomIn2d', 'autoScale2d', 'toggleSpikelines', 'hoverCompareCartesian', 'hoverClosestCartesian']
    },
    m: [],
    show: {},
    y: [],
    layouts: {
      Radius: {
        autosize: true,
        hovermode: 'closest',
        showlegend: false,
        legend: { "orientation": "h" },
        height: 250,
        margin: {
          l: 35,
          r: 20,
          b: 25,
          t: 20,
          pad: 0
        },
        plot_bgcolor: 'transparent',
        paper_bgcolor: 'transparent',
        yaxis: {
          range: [-10000, 10000]
        }
      }
    },
    data: [
      {
        name: 'Spur',
        key: 'Spur',
        yData: []
      },
      {
        name: 'Bogen',
        key: 'Bogen',
        yData: []
      },
      {
        name: 'Überhöhung',
        key: 'Ueberhoehung',
        yData: []
      },
      {
        name: 'Tiefpunkt',
        key: 'Tiefpunkt',
        yData: []
      },
      {
        name: 'Verwindung',
        key: 'Verwindung1',
        yData: []
      },
      {
        name: 'Verwindung2',
        key: 'Verwindung2',
        yData: []
      },
      {
        name: 'Rillen',
        key: 'Rille',
        yData: null
      },
      {
        name: 'Rille links',
        key: 'Rille_L',
        yData: []
      },
      {
        name: 'Rille rechts',
        key: 'Rille_R',
        yData: []
      },
      {
        name: '∆H links',
        key: 'DH_L',
        yData: []
      },
      {
        name: '∆S1 links',
        key: 'DS1_L',
        yData: []
      },
      {
        name: '∆S2 links',
        key: 'DS2_L',
        yData: []
      },
      {
        name: '∆H rechts',
        key: 'DH_R',
        yData: []
      },
      {
        name: '∆S1 rechts',
        key: 'DS1_R',
        yData: []
      },
      {
        name: '∆S2 rechts',
        key: 'DS2_R',
        yData: []
      }

    ]
  };

  tileLayers = TileLayerObj;

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);

  set data(dataIn: any) {
    this.ostammid = dataIn.id;
    this.apiservice.getPruefid(this.ostammid).pipe(first()).subscribe((res: any) => {
      this.tblPruefids = res;
      this.selpruefids = "-1";
      this.selDates.push("");
      this.getMessungen(this.ostammid);
    });
  }
  selOpenChanged(event: any) {
    this.selIsOpen = event;
    if (event) return;
    this.selpruefids = "";
    this.graph.m = [];
    this.graph.show = {};
    this.graph.y = [];
    this.selDates = [];
    this.graph.data.forEach(element => {
      element.yData = [];
    });
    this.spinner.enable();
    if (!this.selection || this.selection.length == 0) {
      this.selpruefids = "-1";
      this.selDates.push("");
    } else {
      this.selection.forEach(element => {
        this.selpruefids += element.id + ",";
        this.selDates.push(element.Datum);
      });
      this.selpruefids = this.selpruefids.slice(0, -1);
    }

    this.getMessungen(this.ostammid);
  }

  selChanged(event: any) {
    if (this.selIsOpen || !event.model) return;
    this.selpruefids = "";
    this.graph.m = [];
    this.graph.show = {};
    this.graph.y = [];
    this.selDates = [];
    this.graph.data.forEach(element => {
      element.yData = [];
    });
    this.spinner.enable();
    if (!this.selection || this.selection.length == 0) {
      this.selpruefids = "-1";
      this.selDates.push("");
    } else {
      event.model.forEach(element => {
        this.selpruefids += element.id + ",";
        this.selDates.push(element.Datum);
      });
      this.selpruefids = this.selpruefids.slice(0, -1);
    }

    this.getMessungen(this.ostammid);
  }

  getMessungen(stammid: number) {
    this.apiservice.getMessungen(this.selpruefids, stammid).pipe(first()).subscribe((res: any) => {
      let settings = res.settings;
      let measurements = res.measurements;
      if (!measurements || (measurements.length == 1 && measurements[0].length == 0)) {
        this.spinner.disable();
        this.toastr.warning(this.mrTranslate.transform("Keine Daten vorhanden"));
        this.close();
        return;
      }

      this.min = measurements[0][0]['m'];
      this.max = measurements[0][measurements[0].length-1]['m'];
      for (this.curIndex = 0; this.curIndex < measurements.length; this.curIndex++) {
        const measurement = measurements[this.curIndex];

        measurement.forEach(val => {
          Object.keys(val).forEach((valName: any) => {
            let showName = valName;
            let ind = this.getGraphIndex(valName);
            if (val[valName] != null && val[valName] != 0) {
              if (valName == "m") return;

              showName = valName;
              if (valName.indexOf('SR') != -1) showName = "Spur";
              ind = this.getGraphIndex(showName);
              if (ind != undefined) {
                let correctName = valName.replace("_M", '-').replace("_P", '+');
                let searchAddition = valName.includes("SR") ? "" : " " + this.selDates[this.curIndex]
                let found = false;
                for (var i = 0; i < this.graph.data[ind].yData.length; i++) {
                  if (this.graph.data[ind].yData[i].name == correctName + searchAddition) {
                    found = true;
                    this.graph.data[ind].yData[i].x.push(val['m']);
                    this.graph.data[ind].yData[i].y.push(val[valName]);
                    break;
                  }
                }
                if (!found) {
                  let newPlot: any = {
                    name: correctName + searchAddition,
                    visible: valName.indexOf(valName) != -1 ? true : "legendonly",
                    type: "scatter",
                    marker: {
                      size: Number.MIN_VALUE
                    },
                    mode: "line",
                    x: [val['m']],
                    y: [val[valName]]
                  };
                  this.graph.data[ind].yData.push(newPlot);
                }
              }

            }

            if (showName != 'm' && ind !== undefined) {
              if (showName.toLowerCase().indexOf('ds') == -1 && showName.toLowerCase().indexOf('dh') == -1) {
                this.graph.data[ind].show = true
              }

              if (!this.graph.data[ind].layout && settings[showName].ymin !== undefined && settings[showName].ymax !== undefined) {
                let layout = JSON.parse(JSON.stringify(this.graph.layout));
                //if (showName == 'Spur') {
                layout.showlegend = true;
                //}
                layout.yaxis = {
                  range: [settings[showName].ymin, settings[showName].ymax]
                };
                this.graph.data[ind].layout = layout;
              }
            }



          });
        });
      }
      this.spinner.disable();
    });
  }

  set combineRille(newVal: Boolean) {

    let hex2rgb = c => `rgb(${c.substr(1).match(/../g).map(x => +`0x${x}`)})`;
    let rgb2Hex = c => '#' + c.match(/\d+/g).map(x => (+x).toString(16).padStart(2, '0')).join``;
    let invRgb = c => `rgb(${c.replace('rgb(', '').replace(')', '').split(',').map(x => 255 - x).join(',')})`;
    let indRille = this.getGraphIndex('Rille');
    let indRille_L = this.getGraphIndex('Rille_L');
    let indRille_R = this.getGraphIndex('Rille_R');
    if (newVal) {
      let leftrille = this.graph.data[indRille_L].yData[0];
      let newCol = rgb2Hex(invRgb(hex2rgb(leftrille.line.color)));
      leftrille.line.color = newCol;
      this.graph.data[indRille].yData = [leftrille, this.graph.data[indRille_R].yData[0]];
      this.graph.data[indRille].show = true;
      this.graph.data[indRille_L].yData = null;
      this.graph.data[indRille_R].yData = null;
    } else {
      let leftrille = this.graph.data[indRille].yData[0];
      let newCol = rgb2Hex(invRgb(hex2rgb(leftrille.line.color)));
      leftrille.line.color = newCol;
      this.graph.data[indRille_L].yData = [leftrille];
      this.graph.data[indRille_R].yData = [this.graph.data[indRille].yData[1]];
      this.graph.data[indRille_L].show = true;
      this.graph.data[indRille_R].show = true;
      this.graph.data[indRille].yData = null;
    }
    if (!this.graph.data[indRille].layout) {
      let layout = JSON.parse(JSON.stringify(this.graph.data[indRille_L].layout));
      layout.showlegend = true;
      this.graph.data[indRille].layout = layout;
    }
    this._combineRille = newVal;
  }

  get combineRille() {
    return this._combineRille;
  }

  _combineRille: Boolean = false;

  dndsettings = (str: string) => {
    return {
      content: str,
      effectAllowed: "move",
      disable: false,
      handle: false,
    }
  }

  constructor(
    private apiservice: APIService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    private changeDet: ChangeDetectorRef,
    private mrTranslate: MrTranslatePipe
  ) {
    this.graph.m = [];
    this.graph.y = [];
  }

  lastData: any;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    if(this.lastData != undefined){
      //let cdata = JSON.parse(JSON.stringify(this.lastData));

      if (event.key == 'ArrowLeft' && this.lastData.points[0].x > this.min+1){
        this.lastData.points[0].x -= 1;
      }

      if (event.key == 'ArrowRight' && this.lastData.points[0].x < this.max-1){
        this.lastData.points[0].x += 1;
      }

      var dataPoint = { mode: 'line', name: "Selektierte Position", type: "lines", x: [this.lastData.points[0].x, this.lastData.points[0].x], y: [this.curGraph.layout.yaxis.range[0], this.curGraph.layout.yaxis.range[1]], visible: true }
      if (this.curGraph.yData[this.curGraph.yData.length - 1].name == "Selektierte Position") {
        this.curGraph.yData[this.curGraph.yData.length - 1] = dataPoint;
      } else {
        this.curGraph.yData.push(dataPoint);
      }

      Plotly.react('plotly_Sub', this.curGraph.yData, this.curGraph.layout);

      if (this.max>this.min){
        this.getFeatPos((this.lastData.points[0].x - this.min).toString())
      }
      else{
        this.getFeatPos((this.lastData.points[0].x - this.max).toString())
      }



    }
  }

  loadDetailView(graphData: any) {
    this.curGraph = JSON.parse(JSON.stringify(graphData)); //Deep Copy
    this.isDetailViewVisible = true;

    this.apiservice.getMessungGeo(this.ostammid).pipe().subscribe((res: any) => {
      setTimeout(() => {
        this.loadMap(res);
        this.curGraph.layout.height = 500;
        Plotly.newPlot('plotly_Sub', this.curGraph.yData, this.curGraph.layout);
        var myPlot: any = document.getElementById("plotly_Sub");
        myPlot.on('plotly_click', data => {
          this.lastData = data;
          var dataPoint = { mode: 'line', name: "Selektierte Position", type: "lines", x: [data.points[0].x, data.points[0].x], y: [this.curGraph.layout.yaxis.range[0], this.curGraph.layout.yaxis.range[1]], visible: true }
          if (this.curGraph.yData[this.curGraph.yData.length - 1].name == "Selektierte Position") {
            this.curGraph.yData[this.curGraph.yData.length - 1] = dataPoint;
          } else {
            this.curGraph.yData.push(dataPoint);
          }

          Plotly.react('plotly_Sub', this.curGraph.yData, this.curGraph.layout);

          if (this.max>this.min){
            this.getFeatPos((data.points[0].x - this.min).toString())
          }
          else{
            this.getFeatPos((data.points[0].x - this.max).toString())
          }

        });

      });
    });

  }

  getGraphIndex(key: string): number {
    for (var i = 0; i < this.graph.data.length; i++) {
      if (this.graph.data[i].key.includes(key)) {
        return i;
      }
    }
    return undefined;
  }

  applyYaxis(name: string) {
    let ind = this.getGraphIndex(name);
    this.graph.data[ind].show = false;
    this.changeDet.detectChanges();
    this.graph.data[ind].show = true;
  }

  onDrop(event) {
    let oldIndex = this.getGraphIndex(event.data.key);
    let newIndex = event.index;
    if (oldIndex != newIndex) {
      let save = JSON.parse(JSON.stringify(this.graph.data[newIndex]));
      this.graph.data[newIndex] = JSON.parse(JSON.stringify(this.graph.data[oldIndex]));
      this.graph.data[oldIndex] = save;
    }
    this.changeDet.detectChanges();
  }

  onDragStart(event) {
    this.placeHolderHeight = event.srcElement.clientHeight;
  }


  public close() {
    this.graph.m = [];
    this.graph.y = [];
    this.closedialog.next(true);
  }

  ngOnInit() {

  }

  ngOn

  replaceUnderscore(str: string) {
    return str.replace('_', ' ');
  }

  tileSwitch(tileLayerCat: string, tileLayerKey: string) {
    Object.keys(this.tileLayers).forEach((catname) => {
      Object.keys(this.tileLayers[catname]).forEach((layername) => {
        this.tileLayers[catname][layername].setVisible(false);
      });
    });
    this.tileLayers[tileLayerCat][tileLayerKey].setVisible(true);
    this.darkMap = tileLayerKey == "cartoDbDark" || tileLayerKey == "cartoDbDarkNoLabels" ? true: false;
    const newStyle = this.getLayerStyles();
    this.geolayer.setStyle(newStyle);


  }

  loadMap(res: any) {
    if (res && !this.geolayer) {
      this.layers = [];
      Object.keys(this.tileLayers).forEach((catname) => {
        Object.keys(this.tileLayers[catname]).forEach((layername) => {
          this.tileLayers[catname][layername].setVisible(false);
          this.layers.push(this.tileLayers[catname][layername]);
        });
      });
      var karte = JSON.parse(localStorage.getItem("karte"));
      this.tileLayers[karte.p][karte.m].setVisible(true);
      this.geolayer = new OlVectorLayer({
        source: new OlVectorSource({
          format: new OlGeoJSON(),
          loader: (extent, resolution, projection) => {
            var xhr = new XMLHttpRequest();
            let token = localStorage.getItem("jwt");
            xhr.open("GET", environment.apiUrl + "/karte/topojson", true);
            xhr.setRequestHeader("Authorization", "Bearer " + token);
            var onError = () => {
              this.geolayer.getSource().removeLoadedExtent(extent);
            };
            xhr.onerror = onError;
            xhr.onload = () => {
              if (xhr.status == 200) {
                let src = this.geolayer.getSource();
                setTimeout(() => {
                  let features = src.getFormat().readFeatures(xhr.responseText, {
                    featureProjection: "EPSG:3857",
                  });
                  src.addFeatures(features);
                });
              } else {
                onError();
              }
            };
            xhr.send();

            let featurewrap: any = {
              type: "Feature",
              geometry: res,
            };
            this.highlightfeature = this.geolayer.getSource().getFormat().readFeature(featurewrap, { featureProjection: "EPSG:3857" })
            this.geolayer.getSource().addFeature(this.highlightfeature);
            this.geolayer.getSource().addFeature(this.positionFeature);
          }
        }),
        style: this.getLayerStyles()
      });
      this.layers.push(this.geolayer);
    }
    if (!this.map) {
      this.map = new OlMap({
        target: 'previewMap',
        layers: this.layers,
        view: new OlView({
          center: fromLonLat([6.747443, 49.981018]),
          maxZoom: 23,
          zoom: 15
        }),
        controls: defaults({ attribution: false, rotate: false, zoom: false })
      });
    }

    if (res) {
      this.geolayer.getSource().on('change', () => {
        if(!this.positionFeature.getGeometry()) {
          this.map.getView().fit(this.geolayer.getSource().getExtent(), { duration: 100, maxZoom: 21 });
        } else {
          this.map.getView().fit(this.positionFeature.getGeometry().getExtent(), { duration: 100, maxZoom: 19 });
        }

      });
    }
  }
  getFeatPos(pos: string) {
    var src = this.geolayer.getSource();
    var coordPoint = this.highlightfeature.getGeometry().getCoordinateAt(parseFloat(pos) / geoDesLength(this.highlightfeature.getGeometry()));
    this.geolayer.setStyle(this.getLayerStyles());

    this.positionFeature.setGeometry(new Point(coordPoint));
    this.positionFeature.setStyle(new OlStyle({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: 'green',
        }),
        stroke: new OlStroke({
          color: 'green',
          width: 2,
        }),
      }),
      zIndex: 3
    }));
  }
  getLayerStyles() {
    return (feature, resolution) => {
      let prop = feature.getProperties();
      if (
        prop.geometry.getType() == "LineString" ||
        prop.geometry.getType() == "MultiLineString"
      ) {
        if (JSON.stringify(feature.getGeometry().getCoordinates()) === JSON.stringify(this.highlightfeature.getGeometry().getCoordinates())) {
          return new OlStyle({
            stroke: new OlStroke({
              color: 'red',
              width: 4
            }),
            zIndex: 2
          })
        } else {
          return new OlStyle({
            stroke: new OlStroke({
              color: this.darkMap ? 'grey' : 'black',
              width: 4
            }),
            zIndex: 1
          })
        }
      }
    }
  }

  closeModal() {
    this.isDetailViewVisible = false;
    if (this.curGraph.yData[this.curGraph.yData.length - 1].name == "Selektierte Position") {
      this.curGraph.yData[this.curGraph.yData.length - 1] = undefined;
    }
  }

}

