import { emptyValueSign } from '../../models/ui/datatype';
import { FilterOperator } from 'src/app/models/ui/filter';
import { IFilter } from '.';

export class BooleanFilter implements IFilter {
  private data: any[];

  constructor(data: any[]) {
    this.data = data;
  }

  filter(data1: any, data2: any, operator: FilterOperator): any[] {
    switch (operator.value) {
      case 'isEqual':
        return this.isEqual(data1);
      case 'isNotEqual':
        return this.isNotEqual(data1);
      case 'isEmpty':
        return this.isEmpty();
      case 'isNotEmpty':
        return this.isNotEmpty();
      case 'none':
        return this.data;
    }
  }

  isEqual(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => this.normalizeValue(item.name) === v);
  }

  isNotEqual(value: any): any[] {
    const v = this.normalizeValue(value);
    return this.data.filter((item) => this.normalizeValue(item.name) !== v);
  }

  isEmpty(): string[] {
    return this.data.filter((item) => item.name === emptyValueSign);
  }

  isNotEmpty(): string[] {
    return this.data.filter((item) => item.name !== emptyValueSign);
  }

  normalizeValue(data: any): boolean {
    if (typeof data === 'boolean') {
      return data;
    }

    if (typeof data === 'number') {
      return data !== 0;
    }

    if (typeof data === 'string') {
      const lowerCaseData = data.toLowerCase().trim();
      return lowerCaseData === 'true' || lowerCaseData === '1';
    }

    return false;
  }
}
