function randomBetween(min, max) {
  if (min < 0) {
      return Math.round(min + Math.random() * (Math.abs(min)+max));
  }else {
      return Math.round(min + Math.random() * (Math.abs(max-min)));
  }
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  console.log(byteArrays);

  return new File(byteArrays, "pot", { type: contentType });
}

import { Component,OnInit,Inject,ChangeDetectorRef,ViewChild,ElementRef } from "@angular/core";
import { APIService } from "../../../../services/APIService/api.service";
import { first } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import * as _ from "underscore";
import shajs from "sha.js";
import { ToastrService } from "ngx-toastr";
import { ClrDatagrid } from "@clr/angular";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { AuthService } from "src/app/services/Auth/auth.service";
import '@cds/core/icon/register.js';
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";

@Component({
    selector: "app-benutzerverwaltungsdialog",
    templateUrl: "./benutzerverwaltungsdialog.component.html",
    styleUrls: ["./benutzerverwaltungsdialog.component.scss"],
    imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, HideIDColumnsPipe, TablePrettyPrintPipe],
    standalone: true
})

export class BenutzerverwaltungsdialogComponent implements OnInit {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild(ClrDatagrid) grid: ClrDatagrid;
  @ViewChild("leftObjectsDatagrid") leftObjectsDatagrid;
  @ViewChild("addedObjectsDatagrid") addedObjectsDatagrid;

  @ViewChild("leftAuftragObjectsDatagrid") leftAuftragObjectsDatagrid;
  @ViewChild("addedAuftragObjectsDatagrid") addedAuftragObjectsDatagrid;

  @ViewChild('_username') _username: ElementRef;
  @ViewChild('_alias') _alias: ElementRef;
  @ViewChild('_pw') _pw: ElementRef;
  @ViewChild('_rechte') _rechte: ElementRef;
  @ViewChild('_vname') _vname: ElementRef;
  @ViewChild('_name') _name: ElementRef;
  @ViewChild('_email') _email: ElementRef;
  @ViewChild('_mobil') _mobil: ElementRef;
  @ViewChild('_optkein') _optkein: ElementRef;
  @ViewChild('_optalv') _optalv: ElementRef;
  @ViewChild('_imageInput') _imageInput: ElementRef;
  @ViewChild('_optebl') _optebl: ElementRef;
  @ViewChild('_verantwortung') _verantwortung: ElementRef;

  @ViewChild('_username_new') _username_new: ElementRef;
  @ViewChild('_alias_new') _alias_new: ElementRef;
  @ViewChild('_pw_new') _pw_new: ElementRef;
  @ViewChild('_rechte_new') _rechte_new: ElementRef;
  @ViewChild('_vname_new') _vname_new: ElementRef;
  @ViewChild('_name_new') _name_new: ElementRef;
  @ViewChild('_email_new') _email_new: ElementRef;
  @ViewChild('_mobil_new') _mobil_new: ElementRef;
  @ViewChild('_optkein_new') _optkein_new: ElementRef;
  @ViewChild('_optalv_new') _optalv_new: ElementRef;
  @ViewChild('_imageInput_new') _imageInput_new: ElementRef;
  @ViewChild('_optebl_new') _optebl_new: ElementRef;
  @ViewChild('_verantwortung_new') _verantwortung_new: ElementRef;


  @ViewChild('_group_name') _group_name: ElementRef;
  @ViewChild('_group_rechte') _group_rechte: ElementRef;

  @ViewChild('_group_name_new') _group_name_new: ElementRef;
  @ViewChild('_group_rechte_new') _group_rechte_new: ElementRef;


  @ViewChild('_aufgroup_name') _aufgroup_name: ElementRef;
  @ViewChild('_aufgroup_kost') _aufgroup_kost: ElementRef;

  @ViewChild('_aufgroup_name_new') _aufgroup_name_new: ElementRef;
  @ViewChild('_aufgroup_kost_new') _aufgroup_kost_new: ElementRef;


  users: any;
  usersOriginal: any;

  user_rights: any = [];
  rights: any;

  canEdit: boolean = false;
  locked: boolean = true;
  Object = Object;

  token: any;
  trial = false;
    // groups and group settings

    groups: any[] = [];
    groupsOriginal: any[] = [];
    groupsLocked: boolean = true;
    groupObjects: any;
  _selectedUser: any = undefined;

  selectedUserRights: any = undefined;
  selectedUserRightsOriginal: any = undefined;

  selectedUserGroups: any = [];
  selectedUserGroupsOriginal: any = [];
  auftragGroupUser: any = undefined;
  selectedAuftragGroupUser: any = undefined;
  _selectedAuftragGroupNameForRights: any = undefined;
  _selectedAuftragGroupID: any = undefined;
  selectedUserAuftragGroups: any = [];
  selectedUserAuftragGroupsOriginal: any = [];
  auftragGroupsOriginal: any[] = [];
  auftragGroupsLocked: boolean = true;
  _selectedGroupNameForRights: any = undefined;
  _selectedGroupID: any = undefined;
  groupUser: any = undefined;
  selectedGroupUser: any = undefined;

  curuserid: any;
  curgroupid: any;
  curaufgroupid: any;
  isUserEditVisible: boolean = false;
  isUserAddVisible: boolean = false;
  isGroupEditVisible: boolean = false;
  isGroupAddVisible: boolean = false;
  isAufGroupEditVisible: boolean = false;
  isAufGroupAddVisible: boolean = false;
  sotyp: any;

  bild: string;
  file: string = '';
  userrechte: any;
  edituser: any;
  base64Image: String;

  optPWkBuch: boolean = true;
  optPWgBuch: boolean = true;
  optPWZahl: boolean = true;
  optPWSonder: boolean = true;

  optPWMinL: number = 6;
  optAzkBuch: number = 1;
  optAzgBuch: number = 1;
  optAzZahl: number = 1;
  optAzSonder: number = 1;


  set selectedUser(newVal: any) {
    if (
      newVal &&
      newVal.id &&
      newVal.id != "INSERT" &&
      (!this._selectedUser || !(this._selectedUser.id == "INSERT"))
    ) {
      this.apiservice
        .getUser(newVal.id)
        .pipe(first())
        .subscribe((res: any) => {
          this.selectedUserRights = undefined;
          this.selectedUserGroups = [];
          this.selectedUserAuftragGroups = [];
          if (res) {
            this.selectedUserRights = res.rights;
            this.selectedUserGroups = res.groups;
            this.selectedUserAuftragGroups = res.auftraggroups;
            this.selectedUserRightsOriginal = JSON.parse(
              JSON.stringify(res.rights)
            );
            this.selectedUserGroupsOriginal = JSON.parse(
              JSON.stringify(res.groups)
            );
            this.selectedUserAuftragGroupsOriginal = JSON.parse(
              JSON.stringify(res.auftraggroups)
            );
          }
          this.triggerLock();
          setTimeout(() => {
            this.triggerLock();
          });
        });
    }

    this._selectedUser = newVal;
  }

  get selectedUser(): any {
    return this._selectedUser;
  }

  groupObjectsDifference: any = {
    toAdd: [],
    toRemove: [],
  };

  groupRights = {
    1: "lesen",
    2: "lesen und schreiben",
  };


  set selectedGroupID(newVal: any) {
    if (newVal && newVal != "INSERT") {
      this.apiservice
        .getUserFromGroup(newVal)
        .pipe(first())
        .subscribe((res: any) => {
          if (res && res.rows) {
            this.groupUser = res;
          } else if (this.groupUser && (!res || !res.rows || !res)) {
            this.groupUser.rows = [];
          } else if (!this.groupUser) {
            this.groupUser = {
              columns: [],
              rows: [],
            };
          }
        });
    }

    if (newVal == 1) this.groupsLocked = true;
    if (newVal !== undefined) this._selectedGroupID = newVal;
  }

  get selectedGroupID() {
    return this._selectedGroupID;
  }


  set selectedGroupNameForRights(newVal: any) {
    this.apiservice
      .getGroupObjects(parseInt(newVal))
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          this.groupObjects = {};

          setTimeout(() => {
            this.groupObjects = res;

            const addedLen = Object.keys(this.groupObjects.addedObjects).length;
            const leftLen = Object.keys(this.groupObjects.leftObjects).length;

            if (addedLen == 0 && leftLen != 0) {
              this.groupObjects.addedObjects.columns = JSON.parse(
                JSON.stringify(this.groupObjects.leftObjects.columns)
              );
              this.groupObjects.addedObjects.rows = [];
            } else if (addedLen != 0 && leftLen == 0) {
              this.groupObjects.leftObjects.columns = JSON.parse(
                JSON.stringify(this.groupObjects.addedObjects.columns)
              );
              this.groupObjects.leftObjects.rows = [];
            }

            this.groupObjects.addedObjects.toRemove = [];
            this.groupObjects.leftObjects.toAdd = [];

            this.changeDet.detectChanges();
            this.grid.resize();
            if (
              this.addedObjectsDatagrid &&
              this.addedObjectsDatagrid.organizer
            )
              this.addedObjectsDatagrid.organizer.resize();
            if (this.leftObjectsDatagrid && this.leftObjectsDatagrid.organizer)
              this.leftObjectsDatagrid.organizer.resize();
          }, 500);
        }
      });
    this._selectedGroupNameForRights = newVal;
  }

  get selectedGroupNameForRights(): any {
    return this._selectedGroupNameForRights;
  }

  auftragsGroups: any = {
    columns: [],
    rows: [],
  };

  auftragGroupObjects: any = {
    addedObjects: [],
    leftObjects: [],
  };

  auftragGroupObjectsDifference: any = {
    toAdd: [],
    toRemove: [],
  };

  set selectedAuftragGroupID(newVal: any) {
    if (newVal && newVal != "INSERT") {
      this.apiservice
        .getUserFromAuftragGroup(newVal)
        .pipe(first())
        .subscribe((res: any) => {
          if (res && res.rows) {
            this.auftragGroupUser = res;
          } else if (this.auftragGroupUser && (!res || !res.rows || !res)) {
            this.auftragGroupUser.rows = [];
          } else if (!this.auftragGroupUser) {
            this.auftragGroupUser = {
              columns: [],
              rows: [],
            };
          }
        });
    }

    if (newVal == 1) this.auftragGroupsLocked = true;
    if (newVal !== undefined) this._selectedAuftragGroupID = newVal;
  }

  get selectedAuftragGroupID() {
    return this._selectedAuftragGroupID;
  }

  set selectedAuftragGroupNameForRights(newVal: any) {
    this.apiservice
      .getAuftragGroupObjects(parseInt(newVal))
      .pipe(first())
      .subscribe((res: any) => {
        if (res) {
          this.auftragGroupObjects = {};

          setTimeout(() => {
            this.auftragGroupObjects = res;

            const addedLen =
              Object.keys(this.auftragGroupObjects.addedObjects?.rows)
                ?.length || 0;
            const leftLen =
              Object.keys(this.auftragGroupObjects.leftObjects?.rows)?.length ||
              0;

            if (addedLen == 0 && leftLen != 0) {
              this.auftragGroupObjects.addedObjects.columns = JSON.parse(
                JSON.stringify(this.auftragGroupObjects.leftObjects.columns)
              );
              this.auftragGroupObjects.addedObjects.rows = [];
            } else if (addedLen != 0 && leftLen == 0) {
              this.auftragGroupObjects.leftObjects.columns = JSON.parse(
                JSON.stringify(this.auftragGroupObjects.addedObjects.columns)
              );
              this.auftragGroupObjects.leftObjects.rows = [];
            }

            this.auftragGroupObjects.addedObjects.toRemove = [];
            this.auftragGroupObjects.leftObjects.toAdd = [];

            console.log(this.auftragGroupObjects);

            this.auftragGroupObjects = { ...this.auftragGroupObjects };

            this.changeDet.detectChanges();
            this.grid?.resize();
            if (this.addedAuftragObjectsDatagrid?.organizer)
              this.addedAuftragObjectsDatagrid.organizer.resize();
            if (this.leftAuftragObjectsDatagrid?.organizer)
              this.leftAuftragObjectsDatagrid.organizer.resize();
          }, 500);
        }
      });
    this._selectedAuftragGroupNameForRights = newVal;
  }

  get selectedAuftragGroupNameForRights(): any {
    return this._selectedAuftragGroupNameForRights;
  }

  activeTabs = {
    user: true,
    groups: false,
    groupsObj: false,
    auftraggroups: false,
    auftraggroupsObj: false,
    security: false,
  };

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private changeDet: ChangeDetectorRef,
    private mrTranslate: MrTranslatePipe,
    private authService: AuthService,

  ) {

    this.trial = this.authService.getToken().trial;
    this.token=this.authService.getToken().email;
    this.loadUsers();
    this.loadGroups();
    this.loadAuftragsGroups();
    this.loadSOTYP();
    this.loadPWini();
  }
  private loadPWini() {
    this.apiservice
      .getPWini()
      .pipe(first())
      .subscribe((val: any) => {

        this.optPWkBuch = val.optPWkBuch;
        this.optPWgBuch = val.optPWgBuch;
        this.optPWZahl = val.optPWZahl;
        this.optPWSonder = val.optPWSonder;
        this.optPWMinL = val.optPWMinL;
        this.optAzkBuch = val.optAzkBuch;
        this.optAzgBuch = val.optAzgBuch;
        this.optAzZahl = val.optAzZahl;
        this.optAzSonder = val.optAzSonder;
      });
  }

  private loadSOTYP() {
    this.apiservice
      .getSOTYP()
      .pipe(first())
      .subscribe(val => {
        this.sotyp = val;
      });
  }

  private loadGroups() {
    this.apiservice
      .getGroups()
      .pipe(first())
      .subscribe((val: any) => {
        this.groups = val;
        this.groupsOriginal = JSON.parse(JSON.stringify(val));
      });
  }

  private loadAuftragsGroups() {
    this.apiservice
      .getAuftragsGroups()
      .pipe(first())
      .subscribe((val: any) => {
        this.auftragsGroups = val;
      });
  }

  private loadUsers() {
    this.apiservice
      .getUsers()
      .pipe(first())
      .subscribe((users: any) => {
        this.users = users;
        this.usersOriginal = users["rows"];
      });
  }

  setPWSecurity(){
    let toSend = {
      optPWMinL: this.optPWMinL,
      optPWkBuch: this.optPWkBuch ? 1 : 0,
      optPWgBuch: this.optPWgBuch ? 1 : 0,
      optPWZahl: this.optPWZahl ? 1 : 0,
      optPWSonder: this.optPWSonder ? 1 : 0,
      optAzkBuch: this.optAzkBuch,
      optAzgBuch: this.optAzgBuch,
      optAzZahl: this.optAzZahl,
      optAzSonder: this.optAzSonder,
    };

    this.apiservice
    .setPWini(toSend)
    .pipe(first())
    .subscribe((res: any) => {

      if(res.status = "OK"){
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
      }
      else{
        this.toastr.error(this.mrTranslate.transform("Beim Speichern ist ein Fehler aufgetreten"));
      }
    });
  }

  addAuftragGroup() {
    let rowIndex = this.auftragsGroups.map((e) => e.id).indexOf("INSERT");
    if (rowIndex == -1) {
      let blank = JSON.parse(JSON.stringify(this.auftragsGroups[0]));
      for (let key in blank) {
        blank[key] = "";
      }
      blank.id = "INSERT";
      this.auftragsGroups.push(blank);

      this.auftragGroupsLocked = false;
      setTimeout(() => {
        this.selectedAuftragGroupID = "INSERT";
      }, 100);
    }
  }

  deleteAuftragGroup(id: number) {
    const text = this.mrTranslate.transform(
      "Sind Sie sicher, dass Sie diese Auftragsgruppe löschen möchten?"
    );
    if (confirm(text)) {
      this.apiservice
        .deleteAuftragGroup(id)
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.toastr.success(
              this.mrTranslate.transform("Auftragsgruppe wurde gelöscht.")
            );
          }
          this.auftragsGroups = res;
          this.selectedAuftragGroupID = undefined;
          this.auftragGroupsLocked = true;
        });
    }
  }

  public tabChange(key: string) {
    this.locked = true;
    this.groupsLocked = true;
    this.selectedGroupID = undefined;
    this.groupUser = undefined;
    this.selectedUser = undefined;
    for (const act of Object.keys(this.activeTabs)) {
      if (act != key) {
        this.activeTabs[act] = false;
      } else {
        this.activeTabs[act] = true;
      }
    }
  }

  public close() {
    this.closedialog.next(true);
  }

  saveGroupObjects() {
    let toSend: any = {};
    toSend.toAdd = this.groupObjectsDifference.toAdd.map((obj) => {
      return {
        OSTAMMID: obj.OSTAMMID,
        OTYPID: obj.TID,
      };
    });
    toSend.toRemove = this.groupObjectsDifference.toRemove.map((obj) => {
      return {
        OSTAMMID: obj.OSTAMMID,
        OTYPID: obj.TID,
      };
    });
    toSend.groupid = parseInt(this.selectedGroupNameForRights);
    this.apiservice
      .setGroupObjects(toSend)
      .pipe(first())
      .subscribe((res: any) => {
        if (res && res.status && res.status == "OK") {
          this.groupObjectsDifference = {
            toAdd: [],
            toRemove: [],
          };
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        } else {
          this.toastr.warning(
            this.mrTranslate.transform(
              "Beim Speichern der Gruppenberechtigung ist ein Fehler aufgetreten"
            ),
            this.mrTranslate.transform(res)
          );
        }
      });
  }

  saveAuftragGroupObjects() {
    let toSend: any = {};
    toSend.toAdd = this.auftragGroupObjectsDifference.toAdd.map(
      (auftr) => auftr.ID
    );
    toSend.toRemove = this.auftragGroupObjectsDifference.toRemove.map(
      (auftr) => auftr.ID
    );
    toSend.groupid = parseInt(this.selectedAuftragGroupNameForRights);
    this.apiservice
      .setAuftragGroupObjects(toSend)
      .pipe(first())
      .subscribe((res: any) => {
        if (res && res.status && res.status == "OK") {
          this.auftragGroupObjectsDifference = {
            toAdd: [],
            toRemove: [],
          };
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        } else {
          this.toastr.warning(
            this.mrTranslate.transform(
              "Beim Speichern der Gruppenberechtigung ist ein Fehler aufgetreten"
            ),
            this.mrTranslate.transform(res)
          );
        }
      });
  }

  executeToAdd() {
    if (this.groupObjects.addedObjects.toRemove.length == 0) {
      this.groupObjects.addedObjects.rows =
        this.groupObjects.addedObjects.rows.concat(
          this.groupObjects.leftObjects.toAdd
        );
      for (const toAddObj of this.groupObjects.leftObjects.toAdd) {
        this.groupObjects.leftObjects.rows.splice(
          this.groupObjects.leftObjects.rows.indexOf(toAddObj),
          1
        );
        if (this.groupObjectsDifference.toRemove.indexOf(toAddObj) != -1) {
          this.groupObjectsDifference.toRemove.splice(
            this.groupObjectsDifference.toRemove.indexOf(toAddObj),
            1
          );
        } else {
          if (this.groupObjectsDifference.toAdd.indexOf(toAddObj) == -1) {
            this.groupObjectsDifference.toAdd.push(toAddObj);
          }
        }
      }
      this.groupObjects.leftObjects.toAdd = [];
    }
  }

  executeAuftraggroupToAdd() {
    if (this.auftragGroupObjects.addedObjects.toRemove.length == 0) {
      this.auftragGroupObjects.addedObjects.rows =
        this.auftragGroupObjects.addedObjects.rows.concat(
          this.auftragGroupObjects.leftObjects.toAdd
        );
      for (const toAddObj of this.auftragGroupObjects.leftObjects.toAdd) {
        this.auftragGroupObjects.leftObjects.rows.splice(
          this.auftragGroupObjects.leftObjects.rows.indexOf(toAddObj),
          1
        );
        if (
          this.auftragGroupObjectsDifference.toRemove.indexOf(toAddObj) != -1
        ) {
          this.auftragGroupObjectsDifference.toRemove.splice(
            this.auftragGroupObjectsDifference.toRemove.indexOf(toAddObj),
            1
          );
        } else {
          if (
            this.auftragGroupObjectsDifference.toAdd.indexOf(toAddObj) == -1
          ) {
            this.auftragGroupObjectsDifference.toAdd.push(toAddObj);
          }
        }
      }
      this.auftragGroupObjects.leftObjects.toAdd = [];
    }
  }

  executeToRemove() {
    if (this.groupObjects.leftObjects.toAdd.length == 0) {
      this.groupObjects.leftObjects.rows =
        this.groupObjects.leftObjects.rows.concat(
          this.groupObjects.addedObjects.toRemove
        );
      for (const toRemObj of this.groupObjects.addedObjects.toRemove) {
        this.groupObjects.addedObjects.rows.splice(
          this.groupObjects.addedObjects.rows.indexOf(toRemObj),
          1
        );
        if (this.groupObjectsDifference.toAdd.indexOf(toRemObj) != -1) {
          this.groupObjectsDifference.toAdd.splice(
            this.groupObjectsDifference.toRemove.indexOf(toRemObj),
            1
          );
        } else {
          if (this.groupObjectsDifference.toRemove.indexOf(toRemObj) == -1) {
            this.groupObjectsDifference.toRemove.push(toRemObj);
          }
        }
      }
      this.groupObjects.addedObjects.toRemove = [];
    }
  }

  executeAuftraggroupToRemove() {
    if (this.auftragGroupObjects.leftObjects.toAdd.length == 0) {
      this.auftragGroupObjects.leftObjects.rows =
        this.auftragGroupObjects.leftObjects.rows.concat(
          this.auftragGroupObjects.addedObjects.toRemove
        );
      for (const toRemObj of this.auftragGroupObjects.addedObjects.toRemove) {
        this.auftragGroupObjects.addedObjects.rows.splice(
          this.auftragGroupObjects.addedObjects.rows.indexOf(toRemObj),
          1
        );
        if (this.auftragGroupObjectsDifference.toAdd.indexOf(toRemObj) != -1) {
          this.auftragGroupObjectsDifference.toAdd.splice(
            this.auftragGroupObjectsDifference.toRemove.indexOf(toRemObj),
            1
          );
        } else {
          if (
            this.auftragGroupObjectsDifference.toRemove.indexOf(toRemObj) == -1
          ) {
            this.auftragGroupObjectsDifference.toRemove.push(toRemObj);
          }
        }
      }
      this.auftragGroupObjects.addedObjects.toRemove = [];
    }
  }

  hasRight(userrights: string, right_id: number) {
    let rights = userrights.split(",");
    for (let right of rights) {
      if (parseInt(right) == right_id) {
        return true;
      }
    }
    return false;
  }

  removeUserFromGroup(userid: number) {
    let toSend = {
      id: userid,
      rights: {},
      groups: {
        toAdd: [],
        toRemove: [this.selectedGroupID],
      },
      auftraggroups: {},
    };

    if (userid && this.selectedGroupID) {
      this.apiservice
        .editUser(toSend)
        .pipe(first())
        .subscribe((res: any) => {
          this.refreshUsers(res);
          let save = this.selectedGroupID;
          this.selectedGroupID = undefined;
          setTimeout(() => {
            this.selectedGroupID = save;
            this.groupsLocked = true;
          });
        });
    }
  }

  removeUserFromAuftragGroup(userid: number) {
    const toSend = {
      id: userid,
      auftraggroups: {
        toRemove: [this.selectedAuftragGroupID],
      },
    };

    if (userid && this.selectedAuftragGroupID) {
      this.apiservice
        .editUser(toSend)
        .pipe(first())
        .subscribe((res: any) => {
          this.refreshUsers(res);
          let save = this.selectedAuftragGroupID;
          this.selectedAuftragGroupID = undefined;
          setTimeout(() => {
            this.selectedAuftragGroupID = save;
            this.auftragGroupsLocked = true;
          });
        });
    }
  }

  sendAuftragGroups() {
    let toSend: any = {};

    let rowIndex = this.auftragGroupsOriginal
      .map((e) => e.id)
      .indexOf(this.selectedAuftragGroupID);
    let curRowIndex = this.auftragsGroups
      .map((e) => e.id)
      .indexOf(this.selectedAuftragGroupID);

    if (
      JSON.stringify(this.auftragGroupsOriginal[rowIndex]) !=
      JSON.stringify(this.auftragsGroups[curRowIndex])
    ) {
      toSend = this.auftragsGroups[curRowIndex];
      if (!toSend || !toSend.Bezeichnung || toSend.Bezeichnung == "") {
        toSend = undefined;
        return this.toastr.warning(
          this.mrTranslate.transform("Bitte eine Bezeichnung angeben.")
        );
      }
    }

    if (toSend) {
      this.apiservice
        .setAuftragGroups(toSend)
        .pipe(first())
        .subscribe((res: any) => {
          if (res && !res.errors && res.length > 0) {
            this.auftragsGroups = res;
            this.auftragGroupsOriginal = JSON.parse(JSON.stringify(res));
            this.toastr.success(
              this.mrTranslate.transform(`Daten gespeichert`)
            );
            this.auftragGroupsLocked = true;
            let saveId = this.selectedAuftragGroupID;
            this._selectedAuftragGroupID = undefined;
            setTimeout(() => {
              this.selectedAuftragGroupID = saveId;
              this.grid.resize();
              this.changeDet.detectChanges();
            }, 250);
          } else {
            this.toastr.warning(
              res.errors
                .map((err) => this.mrTranslate.transform(err))
                .join("\n"),
              this.mrTranslate.transform(`Ein Fehler ist aufgetreten.`)
            );
          }
        });
    }
  }

  sendGroups() {
    let toSend: any = {};

    let rowIndex = this.groupsOriginal
      .map((e) => e.id)
      .indexOf(this.selectedGroupID);
    let curRowIndex = this.groups
      .map((e) => e.id)
      .indexOf(this.selectedGroupID);

    if (
      JSON.stringify(this.groupsOriginal[rowIndex]) !=
      JSON.stringify(this.groups[curRowIndex])
    ) {
      toSend = this.groups[curRowIndex];
      if (
        !toSend ||
        !toSend.Rechte ||
        !toSend.Bezeichnung ||
        toSend.Bezeichnung == ""
      ) {
        toSend = undefined;
        return this.toastr.warning(
          this.mrTranslate.transform(
            "Bitte eine Bezeichnung angeben und ein Recht auswählen."
          )
        );
      }
      toSend.Rechte = parseInt(toSend.Rechte);
    }

    if (toSend) {
      this.apiservice
        .setGroups(toSend)
        .pipe(first())
        .subscribe((res: any) => {
          if (res && !res.errors && res.length > 0) {
            this.groups = res;
            this.groupsOriginal = JSON.parse(JSON.stringify(res));
            this.toastr.success(
              this.mrTranslate.transform(`Daten gespeichert`)
            );
            this.groupsLocked = true;
            let saveId = this.selectedGroupID;
            this._selectedGroupID = undefined;
            setTimeout(() => {
              this.selectedGroupID = saveId;
              this.grid.resize();
              this.changeDet.detectChanges();
            }, 250);
          } else {
            this.toastr.warning(
              res.errors
                .map((err) => this.mrTranslate.transform(err))
                .join("\n"),
              this.mrTranslate.transform(`Ein Fehler ist aufgetreten.`)
            );
          }
        });
    }
  }

  send() {
    if (this.selectedUser) {

      if(this.selectedUserRights.status != 6){

        this.selectedUserRights.view.forEach((item: any) => {
          if (item.key == "AllowBEVER") {
            item.value = false;
          }
        });
      }

      let toSend: any = {
        id: this.selectedUser.id,
        rights: this.selectedUserRights,
        groups: this.selectedUserGroups,
        auftraggroups: this.selectedUserAuftragGroups,
      };

      /*
          if (this.selectedUser.id != "INSERT") {
            let rowIndex = this.usersOriginal
              .map((e) => e.id)
              .indexOf(this.selectedUser.id);

            if (
              JSON.stringify(this.usersOriginal[rowIndex]) !=
              JSON.stringify(this.selectedUser)
            ) {
              toSend.user = this.selectedUser;
            }
            var rightdifference = {};
            Object.keys(this.selectedUserRights).forEach((right: string) => {
              if (right != "type") {
                const currentRights = this.selectedUserRights[right];
                const originalRights = this.selectedUserRightsOriginal[right];
                var filteredRights = _.filter(currentRights, function (obj) {
                  return !_.findWhere(originalRights, obj);
                });
                if (filteredRights.length > 0) {
                  rightdifference[right] = filteredRights;
                }
              } else {
                this.selectedUserRights[right].forEach(
                  (typeright: any, index: number) => {
                    const currentRights = typeright.rights;
                    const originalRights =
                      this.selectedUserRightsOriginal[right][index].rights;
                    var filteredRights = _.filter(currentRights, function (obj) {
                      return !_.findWhere(originalRights, obj);
                    });

                    if (filteredRights.length > 0) {
                      let ins = typeright;
                      ins.rights = filteredRights;
                      if (!rightdifference[right]) {
                        rightdifference[right] = [];
                      }
                      rightdifference[right].push(ins);
                    }
                  }
                );
              }
            });

            toSend.rights = rightdifference;

            const currentGroups = this.selectedUserGroups;
            const originalGroups = this.selectedUserGroupsOriginal;
            toSend.groups = {
              toAdd: currentGroups.filter((x) => !originalGroups.includes(x)),
              toRemove: originalGroups.filter((x) => !currentGroups.includes(x)),
            };

            const currentAuftragGroups = this.selectedUserAuftragGroups;
            const originalAuftragGroups = this.selectedUserAuftragGroupsOriginal;
            toSend.auftraggroups = {
              toAdd: currentAuftragGroups.filter(
                (x) => !originalAuftragGroups.includes(x)
              ),
              toRemove: originalAuftragGroups.filter(
                (x) => !currentAuftragGroups.includes(x)
              ),
            };
          } else {
            toSend.user = this.selectedUser;
          }

          var test = true;
          if (toSend.user) {
            if (
              (toSend.user.id == "INSERT" && !toSend.user.password) ||
              !toSend.user.UserMail
            ) {
              test = false;
            } else if (toSend.user.password) {
              toSend.user.password = shajs("sha256")
                .update(toSend.user.password)
                .digest("hex");
            }
          }
      */

      this.apiservice
        .editUser(toSend)
        .pipe(first())
        .subscribe((val: any) => {
          this.refreshUsers(val);
        });
    }
  }

  refreshUsers(val: any) {
    if (val && val.user && !val.error) {
      this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));

      if (val.id) {
        this.selectedUser.id = val.id;
        this.users.rows[this.users.rows.map((e) => e.id).indexOf(val.id)].id =
          val.id;
        this.usersOriginal = JSON.parse(JSON.stringify(this.users["rows"]));
      }
      this.selectedUserRights = val.user.rights;
      this.selectedUserGroups = val.user.groups;
      this.selectedUserRightsOriginal = JSON.parse(
        JSON.stringify(val.user.rights)
      );
      this.selectedUserGroupsOriginal = JSON.parse(
        JSON.stringify(val.user.groups)
      );
      this.locked = true;
    } else {
      this.toastr.error(
        val?.error ? this.mrTranslate.transform(val.error) : "",
        this.mrTranslate.transform("Benutzer konnten nicht gespeichert werden")
      );
      this.loadUsers();
    }

    this.locked = true;
  }

  deleteGroup(id: number) {
    if (confirm("Sind Sie sicher, dass Sie diese Gruppe löschen möchten?")) {
      this.apiservice
        .deleteGroup(id)
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.toastr.success(
              this.mrTranslate.transform("Gruppe wurde gelöscht.")
            );
          }
          this.groups = res;
          this.selectedGroupID = undefined;
          this.groupsLocked = true;
        });
    }
  }


  deleteUser(id: number) {
    if (
      confirm(
        this.mrTranslate.transform(
          "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?"
        )
      )
    ) {
      this.apiservice
        .deleteUser(id)
        .pipe(first())
        .subscribe((val) => {
          if (val) {
            this.toastr.success(
              this.mrTranslate.transform("Benutzer wurde gelöscht.")
            );
          }
          this.users = val;
          this.selectedUser = undefined;
          this.locked = true;
        });
    }
  }

  add() {

    var imax;
    this.apiservice
    .getmaxUser()
    .pipe(first())
    .subscribe((val) => {
      if (val) {

        imax=val;

        if ( val > this.users.rows.length + 1 ){
          let blank = JSON.parse(JSON.stringify(this.users["rows"][0]));
          for (let key in blank) {
            blank[key] = "";
          }

          blank["id"] = -1;
          this.edituser = blank;

          this.curuserid = -1;
          this.isUserAddVisible = true;

          this._username_new.nativeElement.value = '';
          this._pw_new.nativeElement.value = '';
          this._alias_new.nativeElement.value = '';
          this._rechte_new.nativeElement.value = 1;
          this._vname_new.nativeElement.value = '';
          this._name_new.nativeElement.value = '';
          this._email_new.nativeElement.value = '';
          this._mobil_new.nativeElement.value = '';
          this.base64Image='';

          setTimeout(() => {


            document.forms[0]["_username_new"].text='';
            document.forms[0]["_pw_new"].text='';

            document.forms[0]["_image_new"].src = '/assets/icons/user-solid.svg';
            document.forms[0]["vertical-radio4"].checked=true;
            document.forms[0]["vertical-radio5"].checked=false;
            document.forms[0]["vertical-radio6"].checked=false;

          }, 300);
        }
        else
        {
          this.toastr.error(this.mrTranslate.transform("Die maximale Anzahl an Benutzern ist erreicht"));
        }
      }
    });
  }

  addGroup() {
    let rowIndex = this.groups.map((e) => e.id).indexOf("INSERT");
    if (rowIndex == -1) {
      let blank = JSON.parse(JSON.stringify(this.groups[0]));
      for (let key in blank) {
        blank[key] = "";
      }
      blank.id = "INSERT";
      this.groups.push(blank);

      this.groupsLocked = false;
      setTimeout(() => {
        this.selectedGroupID = "INSERT";
      }, 100);
    }
  }

  triggerLock() {
    this.locked = !this.locked;
  }

  ngOnInit() {}

  addAufGruppe(){

    if (this._aufgroup_name_new.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Bezeichnung ein"));
      return;
    }

    if(this.auftragsGroups.some((usr) => usr.Bezeichnung.toLowerCase() == this._aufgroup_name_new.nativeElement.value.toLowerCase())){
      this.toastr.error(this.mrTranslate.transform("Gruppe existiert bereits"));
      return;
    }

    let toSend: any = {};
    toSend = this.auftragsGroups[0];
    toSend.id="INSERT";
    toSend.Bezeichnung =this._aufgroup_name_new.nativeElement.value;
    toSend.Kostenstelle = this._aufgroup_kost_new.nativeElement.value;

    if (toSend) {
      this.apiservice
        .setAuftragGroups(toSend)
        .pipe(first())
        .subscribe((res: any) => {
          if (res && !res.errors && res.length > 0) {
            this.auftragsGroups = res;
            this.auftragGroupsOriginal = JSON.parse(JSON.stringify(res));
            this.toastr.success(this.mrTranslate.transform(`Daten gespeichert`));
            this.auftragGroupsLocked = true;
            let saveId = this.selectedAuftragGroupID;
            this._selectedAuftragGroupID = undefined;

            this.isAufGroupAddVisible=false;
            this.curaufgroupid=0;
            this._aufgroup_name_new.nativeElement.value = '';
            this._aufgroup_kost_new.nativeElement.value = '';


            setTimeout(() => {
              this.selectedAuftragGroupID = saveId;
              this.grid.resize();
              this.changeDet.detectChanges();
            }, 250);
          } else {
            this.toastr.warning(
              res.errors
                .map((err) => this.mrTranslate.transform(err))
                .join("\n"),
              this.mrTranslate.transform(`Ein Fehler ist aufgetreten.`)
            );
          }
        });
    }

  }

  editAufGruppe(){

    if (this._aufgroup_name.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Bezeichnung ein"));
      return;
    }

    if(this.auftragsGroups.some((usr) => usr.Bezeichnung.toLowerCase() == this._aufgroup_name.nativeElement.value.toLowerCase() && usr.id != this.curaufgroupid)){
      this.toastr.error(this.mrTranslate.transform("Gruppe existiert bereits"));
      return;
    }

    let toSend: any = {};
    toSend = this.auftragsGroups[0];
    toSend.id= this.curaufgroupid;
    toSend.Bezeichnung =this._aufgroup_name.nativeElement.value;
    toSend.Kostenstelle = this._aufgroup_kost.nativeElement.value;

    if (toSend) {
      this.apiservice
        .setAuftragGroups(toSend)
        .pipe(first())
        .subscribe((res: any) => {
          if (res && !res.errors && res.length > 0) {
            this.auftragsGroups = res;
            this.auftragGroupsOriginal = JSON.parse(JSON.stringify(res));
            this.toastr.success(this.mrTranslate.transform(`Daten gespeichert`));
            this.auftragGroupsLocked = true;
            let saveId = this.selectedAuftragGroupID;
            this._selectedAuftragGroupID = undefined;

            this.isAufGroupEditVisible=false;
            this.curaufgroupid=0;
            this._aufgroup_name_new.nativeElement.value = '';
            this._aufgroup_kost_new.nativeElement.value = '';


            setTimeout(() => {
              this.selectedAuftragGroupID = saveId;
              this.grid.resize();
              this.changeDet.detectChanges();
            }, 250);
          } else {
            this.toastr.warning(
              res.errors
                .map((err) => this.mrTranslate.transform(err))
                .join("\n"),
              this.mrTranslate.transform(`Ein Fehler ist aufgetreten.`)
            );
          }
        });

    }
  }

  deleteAufGruppe(id: any){
    const text = this.mrTranslate.transform(
      "Sind Sie sicher, dass Sie diese Auftragsgruppe löschen möchten?"
    );
    if (confirm(text)) {
      this.apiservice
        .deleteAuftragGroup(id)
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.toastr.success(
              this.mrTranslate.transform("Auftragsgruppe erfolgreich gelöscht.")
            );
          }
          this.auftragsGroups = res;
          this.selectedAuftragGroupID = undefined;
          this.auftragGroupsLocked = true;

          this.loadAuftragsGroups();

        });
    }
  }


  editShowAufGruppe(id: any){

    let item = this.auftragsGroups.find(
      (el) => el.id == id
    )

    this.curaufgroupid=id;
    this._aufgroup_name.nativeElement.value  = item.Bezeichnung;
    this._aufgroup_kost.nativeElement.value = item.Kostenstelle;
    this.isAufGroupEditVisible=true;
  }

  addGruppe(){

    if (this._group_name_new.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Bezeichnung ein"));
      return;
    }
    if (this._group_rechte_new.nativeElement.value == null || this._rechte.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Wählen Sie Rechte für die Gruppe aus"));
      return;
    }

    if(this.groups.some((usr) => usr.Bezeichnung.toLowerCase() == this._group_name_new.nativeElement.value.toLowerCase())){
      this.toastr.error(this.mrTranslate.transform("Gruppe existiert bereits"));
      return;
    }

    let toSend: any = {};
    toSend = this.groups[0];
    toSend.id="INSERT";
    toSend.Bezeichnung =this._group_name_new.nativeElement.value;
    toSend.Rechte = parseInt(this._group_rechte_new.nativeElement.value );

    this.apiservice
    .setGroups(toSend)
    .pipe(first())
    .subscribe((res: any) => {
      if (res && !res.errors && res.length > 0) {
        this.groups = res;
        this.groupsOriginal = JSON.parse(JSON.stringify(res));
        this.toastr.success(this.mrTranslate.transform(`Daten gespeichert`));
        this.isGroupAddVisible=false;
        this.groupsLocked = true;
        let saveId = this.selectedGroupID;
        this._selectedGroupID = undefined;

        this._group_name_new.nativeElement.value = '';
        this._group_rechte_new.nativeElement.value = 1;

        setTimeout(() => {
          this.selectedGroupID = saveId;
          this.grid.resize();
          this.changeDet.detectChanges();
        }, 250);
      } else {
        this.toastr.warning(
          res.errors
            .map((err) => this.mrTranslate.transform(err))
            .join("\n"),
          this.mrTranslate.transform(`Ein Fehler ist aufgetreten.`)
        );
      }
    });
  }

  editGruppe(){

    if (this._group_name.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Bezeichnung ein"));
      return;
    }
    if (this._group_rechte.nativeElement.value == null || this._group_rechte.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Wählen Sie Rechte für die Gruppe aus"));
      return;
    }

    if(this.groups.some((usr) => usr.Bezeichnung.toLowerCase() == this._group_name.nativeElement.value.toLowerCase() && usr.id != this.curgroupid)){
      this.toastr.error(this.mrTranslate.transform("Gruppe existiert bereits"));
      return;
    }

    let toSend: any = {};
    toSend = this.groups[0];
    toSend.id= this.curgroupid;
    toSend.Bezeichnung =this._group_name.nativeElement.value;
    toSend.Rechte = parseInt(this._group_rechte.nativeElement.value );

    this.apiservice
    .setGroups(toSend)
    .pipe(first())
    .subscribe((res: any) => {
      if (res && !res.errors && res.length > 0) {
        this.groups = res;
        this.groupsOriginal = JSON.parse(JSON.stringify(res));
        this.toastr.success(this.mrTranslate.transform(`Daten gespeichert`));

        this.groupsLocked = true;
        let saveId = this.selectedGroupID;
        this._selectedGroupID = undefined;

        this.isGroupEditVisible=false;
        this.curgroupid = 0;
        this._group_name.nativeElement.value = '';
        this._group_rechte.nativeElement.value = 1;

        setTimeout(() => {
          this.selectedGroupID = saveId;
          this.grid.resize();
          this.changeDet.detectChanges();
        }, 250);
      } else {
        this.toastr.warning(
          res.errors
            .map((err) => this.mrTranslate.transform(err))
            .join("\n"),
          this.mrTranslate.transform(`Ein Fehler ist aufgetreten.`)
        );
      }
    });

  }

  deleteGruppe(id: any){
    if (confirm("Sind Sie sicher, dass Sie diese Gruppe löschen möchten?")) {
      this.apiservice
        .deleteGroup(id)
        .pipe(first())
        .subscribe((res: any) => {
          if (res) {
            this.toastr.success(
              this.mrTranslate.transform("Gruppe erfolgreich gelöscht.")
            );
          }
          this.groups = res;
          this.selectedGroupID = undefined;
          this.groupsLocked = true;
        });
    }
  }

  editShowGruppe(id: any){

    let item = this.groups.find(
      (el) => el.id == id
    )
    this.curgroupid = item.id;
    this._group_name.nativeElement.value = item.Bezeichnung;
    this._group_rechte.nativeElement.value = item.Rechte;

    this.isGroupEditVisible=true;

  }

  editUser(row: any){
    this.edituser=row;
    this.curuserid = row.id;
    this.isUserEditVisible = true;
    let r = atob(row.Status);

    this.userrechte = r.substring(4, 5);
    this._username.nativeElement.value = row.UserName;
    this._pw.nativeElement.value = '********************';
    this._alias.nativeElement.value = row.Alias;
    this._rechte.nativeElement.value = this.userrechte;
    this._vname.nativeElement.value = row.Vorname;
    this._name.nativeElement.value = row.Nachname;
    this._email.nativeElement.value = row.UserMail;
    this._mobil.nativeElement.value = row.mobil
    this.base64Image='';

    setTimeout(() => {

      document.forms[0]["_image"].src = '/assets/icons/user-solid.svg';
      document.forms[0]["vertical-radio1"].checked=false;
      document.forms[0]["vertical-radio2"].checked=false;
      document.forms[0]["vertical-radio3"].checked=false;

      if ((row["Eisenbahnbetriebsleiter (EBL)"]== 0 || row["Eisenbahnbetriebsleiter (EBL)"]== null) && (row["Anlagenverantwortlicher (ALV)"] == 0 || row["Anlagenverantwortlicher (ALV)"] == null)){
        document.forms[0]["vertical-radio1"].checked=true;
      }

      if (row["Eisenbahnbetriebsleiter (EBL)"]== 0 && row["Anlagenverantwortlicher (ALV)"] == 1){
        document.forms[0]["vertical-radio2"].checked=true;
      }

      if (row["Eisenbahnbetriebsleiter (EBL)"]== 1 && row["Anlagenverantwortlicher (ALV)"] == 0){
        document.forms[0]["vertical-radio3"].checked=true;
      }

      if (row.UserImageBase64 != ''){
        this.base64Image = row.UserImageBase64;

        var enc = atob(row.UserImageBase64);

        var image = new File([enc], "random.jpg", {
          type: "image/jpeg"
        });
        var file = b64toBlob(row.UserImageBase64, "image/jpeg", 512 );
        var fileb = new File(["akkaka"], "ranom", {
          type: "image/jpeg"
        });
        console.log(file.size);
        console.log(fileb.size);
        this.file = URL.createObjectURL(file);

        console.log(this.file);

        document.forms[0]["_image"].src = this.file ;
      }

    }, 300);

  }

  onFileClear(part: any){
    this.file = '';
    this.base64Image ='';

    if (part === 'new')
      document.forms[0]["_image_new"].src = '/assets/icons/user-solid.svg';
    else
      document.forms[0]["_image"].src = '/assets/icons/user-solid.svg';

    this.resetInput();
  }

  onFileOpen(part: any) {

    let input = document.createElement('input');
    input.type = 'file';
    input.accept= '.jpg';
    input.onchange = _ => {
      // you can use this method to get file and perform respective operations
      let files =   Array.from(input.files);

      if (files.length > 0) {
        const reader = new FileReader();

        reader.onloadend =() => {
          let r = reader.result as String;
          var splitted = r.split(',');
          this.base64Image = splitted[1];
        }

        reader.readAsDataURL(files[0]);

        const _file = URL.createObjectURL(files[0]);
        this.file = _file;

       if (part === 'new')
        document.forms[0]["_image_new"].src = _file;
       else
        document.forms[0]["_image"].src = _file;
      }



    };
    input.click();
  }

  resetInput(){
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;
    if(input){
      input.value = "";
    }
  }

  setVerantwortung_new(val:any){
    document.forms[0]["vertical-radio4"].checked=false;
    document.forms[0]["vertical-radio5"].checked=false;
    document.forms[0]["vertical-radio6"].checked=false;

    if (val==='optkein'){
      document.forms[0]["vertical-radio4"].checked=true;
    }
    if (val==='optalv'){
      document.forms[0]["vertical-radio5"].checked=true;
    }
    if (val==='optebl'){
      document.forms[0]["vertical-radio6"].checked=true;
    }

  }

  setVerantwortung(val:any){
    document.forms[0]["vertical-radio1"].checked=false;
    document.forms[0]["vertical-radio2"].checked=false;
    document.forms[0]["vertical-radio3"].checked=false;


    if (val==='optkein'){
      document.forms[0]["vertical-radio1"].checked=true;
    }
    if (val==='optalv'){
      document.forms[0]["vertical-radio2"].checked=true;
    }
    if (val==='optebl'){
      document.forms[0]["vertical-radio3"].checked=true;
    }
  }

  addUser(event){

    if (this._username_new.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Benutzernamen ein"));
      return;
    }

    if (this._email_new.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine EMail Adresse ein"));
      return;
    }

    if (this._rechte_new.nativeElement.value == null || this._rechte.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Wählen Sie Rechte für den User aus"));
      return;
    }

    let validPW = this.validatePassword(this._pw_new.nativeElement.value.toString());
    if (validPW != '' ){
      this.toastr.error(this.mrTranslate.transform(validPW));
      return;
    }

    if (this._mobil_new.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Mobilnummer ein"));
      return;
    }

    if (this._mobil_new.nativeElement.value.startsWith('00')){
      this._mobil_new.nativeElement.value = this._mobil_new.nativeElement.value.replace("00","+");
    }

    if (!this._mobil_new.nativeElement.value.startsWith('+')){
      this.toastr.error(this.mrTranslate.transform("Geben Sie bei der Mobilnummer die Länderkennung (+49..) ein"));
      return;
    }

    if(this.users.rows.some((usr) => usr.UserMail.toLowerCase() == this._email_new.nativeElement.value.toLowerCase())){
      this.toastr.error(this.mrTranslate.transform("EMail Adresse wird bereits verwendet"));
      return;
    }

    if(this.users.rows.some((usr) => usr.UserName.toLowerCase() == this._username_new.nativeElement.value.toLowerCase())){
      this.toastr.error(this.mrTranslate.transform("Benutzername wird bereits verwendet"));
      return;
    }

    if(this.users.rows.some((usr) => usr.mobil && usr.mobil.toLowerCase() == this._mobil_new.nativeElement.value.toLowerCase())){
      this.toastr.error(this.mrTranslate.transform("Mobilnummer wird bereits verwendet"));
      return;
    }


    let t1 = randomBetween(1000,9999).toString();
    let t2 = this._rechte_new.nativeElement.value;
    let t3 = randomBetween(10000,99999).toString();
    let tStatus = t1 + t2 + t3;

    this.edituser.Status = btoa(tStatus);
    this.edituser.UserName = this._username_new.nativeElement.value;
    this.edituser.Alias = this._alias_new.nativeElement.value;
    this.edituser.Vorname = this._vname_new.nativeElement.value;
    this.edituser.Nachname = this._name_new.nativeElement.value;
    this.edituser.UserMail = this._email_new.nativeElement.value;
    this.edituser.mobil = this._mobil_new.nativeElement.value;
    this.edituser.USER_EBL=0;
    this.edituser.USER_ALV=0;

    if (document.forms[0]["vertical-radio5"].checked){
      this.edituser.USER_ALV=1;
    }

    if (document.forms[0]["vertical-radio6"].checked){
      this.edituser.USER_EBL=1;
    }

    this.edituser.pwhash = shajs("sha256").update(this._pw_new.nativeElement.value).digest("hex");

    if (this.base64Image != undefined){
      this.edituser.UserImageBase64 = this.base64Image;
    }

    this.apiservice
    .updateUser(this.edituser)
    .pipe(first())
    .subscribe((val: any) => {

      if (val. message.toLowerCase() === 'ok'){
        this.loadUsers();
        this.isUserAddVisible = false;
        this.toastr.success(this.mrTranslate.transform("Benutzer wurde erfolgreich angelegt"));
      }
      else{
        this.toastr.error(this.mrTranslate.transform("Leider ist ein Fehler aufgetreten."));
      }
    });
  }

  updateUser(event){

    let domain = this.token.substring(this.token.indexOf('@') + 1, 100);

    if (this._username.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie einen Benutzernamen ein"));
      return;
    }

    if (this._email.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine EMail Adresse ein"));
      return;
    }

    // if (!this._email.nativeElement.value.includes(domain) && domain != "rsv.gmbh"){
    //   this.toastr.error(this.mrTranslate.transform("Error: EMail Domain ist ungültig."));
    //   return;
    // }

    if (this._rechte.nativeElement.value == null || this._rechte.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Wählen Sie Rechte für den User aus"));
      return;
    }


    if (this._mobil.nativeElement.value == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Mobilnummer ein"));
      return;
    }

    if (this._mobil.nativeElement.value.startsWith('00')){
      this._mobil.nativeElement.value = this._mobil.nativeElement.value.replace("00","+");
    }

    if (!this._mobil.nativeElement.value.startsWith('+')){
      this.toastr.error(this.mrTranslate.transform("Geben Sie bei der Mobilnummer die Länderkennung (+49..) ein"));
      return;
    }

    if (this._pw.nativeElement.value != '********************'){

      let validPW = this.validatePassword(this._pw.nativeElement.value.toString());
      if (validPW != '' ){
        this.toastr.error(this.mrTranslate.transform(validPW));
        return;
      }

    }

    let t1 = randomBetween(1000,9999).toString();
    let t2 = this._rechte.nativeElement.value;
    let t3 = randomBetween(10000,99999).toString();
    let tStatus = t1 + t2 + t3;

    this.edituser.Status = btoa(tStatus);
    this.edituser.UserName = this._username.nativeElement.value;
    this.edituser.Alias = this._alias.nativeElement.value;
    this.edituser.Vorname = this._vname.nativeElement.value;
    this.edituser.Nachname = this._name.nativeElement.value;
    this.edituser.UserMail = this._email.nativeElement.value;
    this.edituser.mobil = this._mobil.nativeElement.value;
    this.edituser.USER_EBL=0;
    this.edituser.USER_ALV=0;

    if (document.forms[0]["vertical-radio2"].checked){
      this.edituser.USER_ALV=1;
    }

    if (document.forms[0]["vertical-radio3"].checked){
      this.edituser.USER_EBL=1;
    }

    if (this._pw.nativeElement.value != '********************')
      this.edituser.pwhash = shajs("sha256").update(this._pw.nativeElement.value).digest("hex");
    else
      this.edituser.pwhash = null;

    if (this.base64Image != undefined){
      this.edituser.UserImageBase64 = this.base64Image;
    }

    this.apiservice
    .updateUser(this.edituser)
    .pipe(first())
    .subscribe((val: any) => {

      if (val. message.toLowerCase() === 'ok'){
        this.loadUsers();
        this.isUserEditVisible = false;
        this.toastr.success(this.mrTranslate.transform("Benutzer wurde erfolgreich geändert"));
      }
      else{
        this.toastr.error(this.mrTranslate.transform("Leider ist ein Fehler aufgetreten."));
      }

    });
  }

  validatePassword(pw: string){

    if (pw == undefined) {
      return "Das Passwort muss mindestens (" + this.optPWMinL.toString() + ") Zeichen enthalten.";
    }

    if (pw == "") {
      return "Das Passwort muss mindestens (" + this.optPWMinL.toString() + ") Zeichen enthalten.";
    }

    let hasNumber = new RegExp("[0-9]");
    let hasUpperChar = new RegExp("[A-Z]");
    let hasLowerChar = new RegExp("[a-z]");
    let hasSymbols = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);

    let countUpper: number = 0;
    let countLower: number = 0;
    let countNumber: number = 0;
    let countSymbols: number = 0;


    if (pw.length < this.optPWMinL) {
      return "Das Passwort muss mindestens (" + this.optPWMinL.toString() + ") Zeichen enthalten.";
    }

    if (this.optPWkBuch){
      if( ! pw.match(/[a-z]/) ){
        return "Das Passwort muss mindestens (" + this.optAzkBuch.toString() + ") Kleinbuchstaben enthalten.";
      }
      countLower = (pw.match(/[a-z]/g) || []).length;
      if (this.optAzkBuch > countLower){
        return "Das Passwort muss mindestens (" + this.optAzkBuch.toString() + ") Kleinbuchstaben enthalten.";
      }
    }

    if (this.optPWgBuch){
      if( ! pw.match(/[A-Z]/) ){
        return "Das Passwort muss mindestens (" + this.optAzgBuch.toString() + ") Grossbuchstaben enthalten.";
      }
      countUpper = (pw.match(/[A-Z]/g) || []).length;
      if (this.optAzgBuch > countUpper){
        return "Das Passwort muss mindestens (" + this.optAzgBuch.toString() + ") Grossbuchstaben enthalten.";
      }
    }

    if (this.optPWZahl){
      if( ! pw.match(/[0-9]/) ){
        return "Das Passwort muss mindestens (" + this.optAzZahl.toString() + ") Zahlen enthalten.";
      }
      countNumber = (pw.match(/[0-9]/g) || []).length;
      if (this.optAzZahl > countNumber){
        return "Das Passwort muss mindestens (" + this.optAzZahl.toString() + ") Zahlen enthalten.";
      }
    }

    if (this.optPWSonder){
      if( ! pw.match(hasSymbols)){
        return "Das Passwort muss mindestens (" + this.optAzSonder.toString() + ") unterschiedliche Sonderzeichen enthalten.";
      }
      countSymbols = pw.match(/[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g).length;
      if (this.optAzSonder > countSymbols){
        return "Das Passwort muss mindestens (" + this.optAzSonder.toString() + ") unterschiedliche Sonderzeichen enthalten.";
      }
    }

    return '';

  }


}
