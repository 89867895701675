import { Pipe, PipeTransform } from '@angular/core';
import { ZählerName, ZählerWert } from './zaehler.types';

@Pipe({
  name: 'hideSRaktiv',
  standalone: true
})
export class HideSrAktivPipe implements PipeTransform {

  transform(wertColName: keyof ZählerWert, options: ZählerName): boolean | string {
    if (!options.SR100 && wertColName.toLowerCase().includes('sr100'))
      return true;
    if (!options.SRG && wertColName.toLowerCase().includes('srg'))
      return true;
    else
      return wertColName;
  }
}