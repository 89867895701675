import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ClrDatagrid, ClrForm } from '@clr/angular';
import { NgForm } from '@angular/forms';
import { first, Subject } from 'rxjs';
import { APIService } from 'src/app/services/APIService/api.service';
import { ToastrService } from 'ngx-toastr';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';
import { ItemsMode, WartungsItem } from '../../wartung.types';
import { HilfeBeschreibungModalComponent } from 'src/app/content/_components/_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component';
import { Table } from 'src/app/models/ui/table';
import { BasicStoreService } from 'src/app/services/BasicStore/basic-store.service';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';

@Component({
    selector: 'app-wartungsitem',
    templateUrl: './wartungsitem.component.html',
    styleUrls: ['./wartungsitem.component.scss'],
    imports: [
        CommonModule, ClarityModule,
        AutofocusDirective,
        HilfeBeschreibungModalComponent,
        MrTranslatePipe,
        FormsModule,
        ReactiveFormsModule,
        HideIDColumnsPipe,
        TablePrettyPrintPipe
    ],
    standalone: true
})
export class WartungsItemComponent implements OnInit {
  @ViewChild(ClrForm, { static: true })
    private clrForm: ClrForm
  ;
  @ViewChild(NgForm, { static: true })
    protected ngForm: NgForm
  ;
  @ViewChild(HilfeBeschreibungModalComponent, { static: false })
    protected hilfeModal: HilfeBeschreibungModalComponent
  ;
  @ViewChild('wItemGrid', { read: ClrDatagrid, static: false })
    private grid: ClrDatagrid<WartungsItem>
  ;
  @ViewChild('gridContainer', { read: ElementRef, static: true })
    private gridContainer: ElementRef<HTMLElement>
  ;

  @Output() deletedItem = new EventEmitter<number>();
  @Output() editedItem = new EventEmitter<{id: number, name: string}>();
  @Input() protected mode: ItemsMode;
  @Input() protected withHelper: boolean;
  @Input() refresh: Subject<void>;

  @Input() public set selectedID(value: number) {
    if (value) {
      this.grid.singleSelected = this.table?.rows?.find(
        row => row.ID == value
      );
    } else {
      this.grid?.selection.clearSelection();
    }
  }
  //#region objekttypen
  @Input() protected set otypid(value: number) {
    this._otypid = value;
    if (this.mode) this.loadTable();
    if (this.table) this.table.rows = [];
    this.isLoading = true;
  }
  protected get otypid(): number {
    return this._otypid;
  }
  private _otypid: number;
  //#endregion
  //#region item-Table
  protected _table: Table<WartungsItem>;
  protected get table(): Table<WartungsItem> {
    return this._table
  }
  protected set table(value: Table<WartungsItem>) {
    if (this._table?.columns?.length)
      this._table.rows = value.rows;
    else this._table = value;
    this.grid?.selection.clearSelection();
    this.insertItem = false;
    this.isLoading = false;
  }

  public selID: number;
  public get selName(): string {
    return this.selectedItem.Bezeichnung;
  }

  private _selectedItem: WartungsItem;
  protected get selectedItem(): WartungsItem {
    return this._selectedItem;
  }
  protected set selectedItem(value: WartungsItem) {
    this._selectedItem = value;
    this.selID = value?.ID;
    if (value) {
      this.insertItem = false;
      setTimeout(() => {
        this.gridContainer.nativeElement
          .querySelector("#i-" + value.ID)
          ?.scrollIntoView({
            behavior: 'auto',
            block: 'center'
          });
      });
    }
    value ||= { Bezeichnung: '', Typ: 1 };
    this.ngForm?.resetForm(value);
  }
  //#endregion
  protected insertItem: boolean = false;
  protected isLoading: boolean = true;
  //#region load()
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private store: BasicStoreService
  ) {}

  ngOnInit() {
    this.loadTable();
  }

  private loadTable() {

      this.apiService
        .getWartungsItem(this.mode, this.otypid)
        .pipe(first())
        .subscribe(({ success, error, table }: any) => {
          if (success) {
            this.table = table;
          } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
        });
  }
  //#endregion
  //#region item()
  protected addItem() {
    this.insertItem = true;
    this.grid?.selection.clearSelection();
  }

  protected saveIfValid() {
    this.clrForm.markAsTouched();
    if (this.ngForm.valid) this.saveItem();
  }

  private saveItem() {
    const sendObj = { ID: this.selID, ...this.ngForm.value };
    this.apiService
      .setWartungsItem(this.mode, this.otypid, sendObj)
      .pipe(first())
      .subscribe(({ success, error, neuRow }: any) => {
        if (success) {
          this.refresh?.next();
          this.store.deleteComponentStoreAsync('wartung');
          this.toastr.success(this.mrTranslate.transform('Daten gespeichert'));
          const neuItem: WartungsItem = { ...this.selectedItem, ...sendObj, ...neuRow};
          if (this.insertItem)
            this.table.rows.unshift(neuItem);
          else {
            this.table.rows.splice(
              this.table.rows.indexOf(this.selectedItem),
              1, neuItem
            );
            if (this.selectedItem.Bezeichnung != neuItem.Bezeichnung)
              this.editedItem.emit({ id: this.selID, name: neuItem.Bezeichnung });
          }
          this.grid.singleSelected = neuItem;
        } else {
          this.toastr.error(
            error, this.mrTranslate.transform('Etwas ist schief gelaufen')
          );
        }
      });
  }

  protected deleteItem() {
    const text = this.mrTranslate.transform('Eintrag wirklich löschen?');
    if (confirm(text)) {
      this.apiService
        .deleteWartungsItem(this.mode, this.selID)
        .pipe(first())
        .subscribe(({ success, error }: any) => {
          if (success) {
            this.refresh?.next();
            this.store.deleteComponentStoreAsync('wartung');
            this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));
            this.table.rows.splice(this.table.rows.indexOf(this.selectedItem), 1);
            this.deletedItem.emit(this.selID);
          } else {
            this.toastr.error(
              error, this.mrTranslate.transform('Fehler beim Löschen')
            );
          }
        });
    }
  }
  //#endregion
  //#region helper()
  protected openHelper() {
    this.hilfeModal.item = { ...this.selectedItem };
  }

  protected async saveHelper(sendObj: any) {
    this.apiService
      .setWartungsHelper(this.selID, sendObj)
      .pipe(first())
      .subscribe(({success, error, neuRow}: any) => {
        if (success) {
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
          this.hilfeModal.item = null;
          const neuItem: WartungsItem = { ...this.selectedItem, ...sendObj, ...neuRow };

          this.table.rows.splice(
            this.table.rows.indexOf(this.selectedItem),
            1, neuItem
          );
          this.grid.singleSelected = neuItem;
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }
  //#endregion
}



