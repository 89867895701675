<div class="overlay-inner-wrapper">
  <div id="addressverwaltungsWrapper">
    <div class="clr-row">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h3 class="mt-0">{{'Adressverwaltung' | mrTranslate}}</h3>
        </div>
        <div class="inline-block float-right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
    </div>
    <div class="clr-row padding-10-t">
      <div class="clr-col-10">
        <button class="btn btn-sm" [disabled]="trial" (click)="newShowFirma()">+ {{'Firma hinzufügen'|mrTranslate}}</button>
      </div>
    </div>

    <div class="clr-row padding-10-t">
      <div class="clr-col-8" *ngIf="firmen?.columns?.length">
        <h3 class="margin-11-tb">{{ "Servicepartner" | mrTranslate }}</h3>
        <clr-datagrid style="padding-top: 0px" class="datagrid-compact" [(clrDgSingleSelected)]="selectedfirma" (clrDgSingleSelectedChange)="selectionFirmaChanged($event)">
          <clr-dg-column *ngFor=" let column of firmen['columns']" [clrDgField]="column.id"
            [hidden]="column.id | hideIDColumns:['delstatus','gesperrt']">{{column.id | mrTranslate}}</clr-dg-column>
          <clr-dg-row *clrDgItems="let row of firmen['rows']" [clrDgItem]="row" [clrDgSelected]="selectedfirma">
            <clr-dg-action-overflow>
              <button class="action-item" (click)="editShowFirma(row)">{{'Bearbeiten' | mrTranslate}}</button>
              <button class="action-item" (click)="deleteFirma(row.ID)">{{'Löschen' | mrTranslate}}</button>
            </clr-dg-action-overflow>

            <clr-dg-cell *ngFor="let cell of firmen['columns']" [hidden]="cell.id | hideIDColumns:['delstatus','gesperrt']">
              <input clrInput type="text" [(ngModel)]="row[cell.id]" [readonly]="true" />
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="15">{{firmen['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>

      </div>
      <div class="clr-col-4">
        <h3 class="margin-11-tb">{{ "Gruppen" | mrTranslate }}</h3>
        <clr-datagrid #grid style="padding-top: 0px" class="datagrid-compact" [(clrDgSelected)]="selectedUserGroups" [appClrCheckboxDisplay]="selectedfirma !== undefined" (clrDgSelectedChange)="selectionGroupChanged()">
          <clr-dg-column>{{"Bezeichnung" | mrTranslate}}</clr-dg-column>
          <clr-dg-row [clrDgSelectable]="selectedfirma !== undefined" *clrDgItems="let grp of groups" [clrDgItem]="grp">
            <clr-dg-cell>{{ grp.Bezeichnung }} </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="15">{{groups.length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>

    </div>

  </div>
</div>


<clr-modal [(clrModalOpen)]="editfirmaShow" [clrModalClosable]="true" [clrModalSize]="'lg'">
  <h3 class="modal-title">{{modaltitel}}</h3>
  <div class="modal-body">

    <form clrForm clrLayout="horizontal" >
      <clr-input-container>
        <label>{{'Firma' | mrTranslate}}</label>
        <input clrInput name="Firma" #_firma/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Abteilung' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Abteilung" #_abteilung/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Strasse' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Strasse" #_strasse/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'PLZ' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="PLZ" #_plz/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Ort' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Ort" #_ort/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Postfach' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Postfach" #_postfach/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Land' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Land" #_land/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Telefon Zentrale' | mrTranslate}}</label>
        <input  class="clr-input-short" clrInput name="Telefon" #_tel/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Ansprechpartner' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Ansprechpartner" #_ap/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Ansprechpartner Telefon' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Ansprechpartner Telefon" #_ap_tel/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Ansprechpartner Handy' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Ansprechpartner Handy" #_ap_mob/>
      </clr-input-container>
      <clr-input-container>
        <label>{{'Ansprechpartner E-Mail' | mrTranslate}}</label>
        <input class="clr-input-short" clrInput name="Ansprechpartner EMail" #_ap_mail/>
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"  (click)="updateFirma()">OK</button>
    <button type="button" class="btn" (click)="editfirmaShow = false">Abbrechen</button>
  </div>
</clr-modal>
