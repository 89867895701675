import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule, ClrLoadingState } from '@clr/angular';
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { BasicStoreService } from 'src/app/services/BasicStore/basic-store.service';
import { VerwaltungsView, WartungsItem, WartungsMaske, WartungsMaskeItem } from '../wartung.types';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';

@Component({
  selector: 'app-wartungsmasken',
  templateUrl: './wartungsmasken.component.html',
  styleUrls: ['./wartungsmasken.component.scss'],
  imports: [ CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, TablePrettyPrintPipe ],
  standalone: true
})
export class WartungsMaskenComponent {
  @Output() view = new EventEmitter<VerwaltungsView>();

  @Input() refresh: Subject<void>;

  //#region objekt
  @Input() protected set otypid(value: number) {
    this._otypid = value;
    this.allArten = this.usedArten = this.notUsedArten = this.maskenListe = this.selectedMaske = null;

    this.apiService
      .getWartungsItem('art', this.otypid)
      .subscribe(({success, error, table: arten}: any) => {
        if (success) {
          this.allArten = arten.rows;
          if (this.viewValid = !!this.allArten.length) this.loadMaskenList();
        }
        else
          this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }
  protected get otypid(): number {
    return this._otypid;
  }
  private _otypid: number;
  //#endregion
  //#region masken
  protected maskenListe: WartungsMaske[];
  protected maskenAddStatus: 'new' | 'change' | 'copy';
  protected maskenNameNeu: string;
  protected isOpenModalMaskenName = false;

  private _selectedMaske: number;
  protected get selectedMaske(): number {
    return this._selectedMaske;
  }
  protected set selectedMaske(value: number) {
    this._selectedMaske = value;
    this.initStates();
    if (value) this.loadMaskeItems();
  }
  //#endregion
  //#region arten
  protected allArten: WartungsItem[];
  protected notUsedArten: WartungsItem[];
  protected selectedLeft: WartungsItem[] = [];
  protected moveRightState: ClrLoadingState;
    //#region rightTable
  protected usedArten: WartungsMaskeItem[];
  private _selectedRight: WartungsMaskeItem[] = [];
  protected get selectedRight(): WartungsMaskeItem[] {
    return this._selectedRight;
  }
  protected set selectedRight(value: WartungsMaskeItem[]) {
    this._selectedRight = value;
    this.checkEnableUpDown();
  }
  protected moveLeftState: ClrLoadingState;

  protected moveUpDownState: ClrLoadingState;
  protected enableMove: { up: boolean, down: boolean } = { up: false, down: false };
  private countToMove = new BehaviorSubject<number>(0);
    //#endregion
  //#endregion
  protected viewValid = true;

  //#region load()
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private store: BasicStoreService
  ) {}

  private loadMaskenList() {
    this.apiService
      .getWartungsItem('masken-names', this.otypid)
      .subscribe(({ success, error, table: masken }: any) => {
        if (success) {
          this.maskenListe = masken.rows;
          this.selectedMaske = this.maskenListe[0]?.ID;
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }

  private loadMaskeItems() {

    this.apiService
      .getWartungsMaskeList(this.selectedMaske, this.otypid)
      .subscribe(({ success, error, list: arten }: any) => {
        if (success) {
          this.usedArten = arten;
          this.extractUsed();
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
      });
  }
  //#endregion
  //#region Maske-Items()
  protected moveToRight() {
    if (!this.selectedLeft.length) return;
    this.moveRightState = ClrLoadingState.LOADING;

    let maxSort = Math.max(...this.usedArten.map(art => art.mSort), 1_000_000);
    const arten = this.selectedLeft.map(art => ({
      ID: art.ID,
      Bezeichnung: art.Bezeichnung,
      Typ: art.Typ,
      mSort: ++maxSort,
    }));

    this.apiService
      .setWartungsMaskeList(this.selectedMaske, this.otypid, { arten })
      .subscribe(({ success, error, IDs }: any) => {
        if (success) {
          arten.forEach((art, i) => {
            this.usedArten.push({ mID: IDs[i], ...art });
          });
          this.extractUsed();
          this.moveRightState = ClrLoadingState.SUCCESS;
        } else {
          this.moveRightState = ClrLoadingState.ERROR;
          this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
        }
      })
  }

  protected async moveToLeft() {
    if (!this.selectedRight.length) return;
    this.moveLeftState = ClrLoadingState.LOADING;
    try {
      var result = await Promise.all(
        this.selectedRight.map(art => firstValueFrom(
          this.apiService.deleteWartungsItem('masken-items', art.mID)
        ))
      );

      this.loadMaskeItems();
      if (result.every(({success}: any) => !!success)) {
        this.moveLeftState = ClrLoadingState.SUCCESS;
      } else throw new Error(
        this.mrTranslate.transform("Nicht alle Einträge konnten gelöscht werden!") +
        this.mrTranslate.transform("Bitte versuchen Sie nochmal")
      );

    } catch (error) {
      this.moveLeftState = ClrLoadingState.ERROR;
      this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    }
  }

  protected moveUpDown(direction: 'up' | 'down') {
    this.checkEnableUpDown();
    if (!this.enableMove[direction]) return;

    const currentCount = this.countToMove.getValue();
    const changeCount = direction == 'up' ? -1 : 1;

    const selected = this.selectedRight[0];
    const swapWith = this.usedArten.at(this.usedArten.indexOf(selected) + changeCount);

    const tmpSort = swapWith.mSort;

    swapWith.mSort = selected.mSort;
    selected.mSort = tmpSort;
    this.usedArten.sort((a, b) => a.mSort - b.mSort);
    this.countToMove.next(currentCount - changeCount);

    if (!currentCount) setTimeout(() => {
      const currentCount = this.countToMove.getValue();
      if (currentCount == 0) return;

      this.moveUpDownState = ClrLoadingState.LOADING;
      const selectedIndex = this.usedArten.indexOf(selected);
      const arten = currentCount > 0
        ? this.usedArten.slice(selectedIndex, selectedIndex + currentCount + 1)
        : this.usedArten.slice(selectedIndex + currentCount, selectedIndex + 1);


      this.apiService
        .setWartungsMaskeList(this.selectedMaske, this.otypid, { arten, edit: true })
        .subscribe(({ success, error }: any) => {
          this.countToMove.next(0);
          if (success) {
            this.moveUpDownState = ClrLoadingState.SUCCESS;
          } else {
            this.moveUpDownState = ClrLoadingState.ERROR;
            this.loadMaskeItems();
            this.toastr.error(error,
              this.mrTranslate.transform('Etwas ist schief gelaufen') + "!  " +
              this.mrTranslate.transform("Bitte versuchen Sie nochmal")
            );
          }
          });

    }, 1000)
    ;
  }
  //#endregion
  //#region Masken-Names()
  protected addMaske(mode: typeof this.maskenAddStatus) {
    this.maskenAddStatus = mode;
    this.isOpenModalMaskenName = true;
    this.maskenNameNeu = mode != 'new'
      ? this.maskenListe?.find(m => m.ID == this.selectedMaske)?.Name
      : null;
  }

  protected saveMaske() {
    const sendObj = {
      ID: this.maskenAddStatus == 'change'
        ? this.selectedMaske
        : null,
      Bezeichnung: this.maskenNameNeu
    };

    this.apiService
      .setWartungsItem('masken-names', this.otypid, sendObj)
      .subscribe(async ({ success, error, neuRow }: any) => {
        if (success) {
          const { success, error = null } = this.maskenAddStatus == 'copy'
            ? await firstValueFrom(
              this.apiService.setWartungsMaskeList(neuRow.ID, this.otypid, { arten: this.usedArten })
            )
            : { success: true };

          if (success) {
            this.toastr.success(this.mrTranslate.transform('Daten gespeichert'));
            if (this.maskenAddStatus == 'change') {
              this.refresh?.next();
              this.store.deleteComponentStoreAsync('wartung');
              this.maskenListe.find(m => m.ID == this.selectedMaske).Name = this.maskenNameNeu;
            } else {
              this.maskenListe.push({ Name: this.maskenNameNeu, OTYPID: this.otypid, ...neuRow });
              this.selectedMaske = neuRow.ID;
            }
            this.isOpenModalMaskenName = false;
          } else {
            this.toastr.error(
              error, this.mrTranslate.transform('Etwas ist schief gelaufen')
            );
          }
        } else {
          this.toastr.error(
            error, this.mrTranslate.transform('Etwas ist schief gelaufen')
          );
        }
      });
  }

  protected async deleteMaske() {
    const text = this.mrTranslate.transform('Eintrag wirklich löschen?');
    if (confirm(text)) {

      const result = await Promise.all([
        firstValueFrom(this.apiService.deleteWartungsItem('masken-names', this.selectedMaske)),
        firstValueFrom(this.apiService.deleteWartungsMaske(this.selectedMaske))
      ]);

      if (result.every(({success}: any) => !!success)) {
        this.refresh?.next();
        this.store.deleteComponentStoreAsync('wartung');
        this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));
        this.maskenListe = this.maskenListe.filter(maske => maske.ID != this.selectedMaske);
        this.selectedMaske = null;
      } else this.toastr.error(this.mrTranslate.transform('Fehler beim Löschen'));
    }
  }
  //#endregion
  //#region helpers()
  private initStates() {
    this.selectedRight = this.selectedLeft = [];
    this.moveLeftState = this.moveRightState = this.moveUpDownState = ClrLoadingState.DEFAULT;
    this.countToMove.next(0);
  }

  private checkEnableUpDown() {
    if (this.selectedRight.length == 1)
      this.enableMove = {
        up: this.usedArten.indexOf(this.selectedRight[0]) != 0,
        down: this.usedArten.indexOf(this.selectedRight[0]) != this.usedArten.length - 1,
      };
    else this.enableMove = { up: false, down: false };
  }

  private extractUsed() {
    this.notUsedArten = _.differenceBy(this.allArten, this.usedArten, 'ID');
  }
  //#endregion
}
