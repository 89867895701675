<div class="clr-row nxm h-100" style="overflow:hidden;">
  <div id="overlaySwitcher"
    [ngClass]="{'visible': showOverlaySwitcher, 'foreground': (filter_modal)}">
    <div class="clr-row" style="width: 100%; justify-content: flex-end;">
      <button class="btn btn-sm btn-outline" (click)="reloadTopoJson()">
        <clr-icon shape="refresh"></clr-icon>
      </button>
    </div>
    <div *ngIf="showOverlaySwitcher" style="margin-top: -45px;">
      <clr-dropdown>
        <button type="button" class="btn btn-icon dropdown-toggle" clrDropdownTrigger>
          <clr-icon shape="world" size="26"></clr-icon>
          <clr-icon shape="caret down"></clr-icon>
        </button>

        <clr-dropdown-menu *clrIfOpen>
          <a (click)="tileSwitch('sonstige','leer')" clrDropdownItem>{{'leer' | mrTranslate}}</a>

          <clr-dropdown>
            <button clrDropdownTrigger>Bing</button>
            <clr-dropdown-menu *clrIfOpen>
                <clr-dropdown>
                  <a (click)="tileSwitch('bing','bingRoadOnDemand')" clrDropdownItem>BingMaps</a>
                  <a (click)="tileSwitch('bing','bingAerial')" clrDropdownItem>Sattelit</a>
                  <a (click)="tileSwitch('bing','bingAerialWithLabels')" clrDropdownItem>Hybrid</a>
                </clr-dropdown>
            </clr-dropdown-menu>
          </clr-dropdown>

          <clr-dropdown>
            <button clrDropdownTrigger>Carto DB</button>
            <clr-dropdown-menu *clrIfOpen>
                <clr-dropdown>
                  <a (click)="tileSwitch('cartoDB','cartoDbDark')" clrDropdownItem>Dark</a>
                  <a (click)="tileSwitch('cartoDB','cartoDbDarkNoLabels')" clrDropdownItem>Dark no Label</a>
                  <a (click)="tileSwitch('cartoDB','cartoDbLight')" clrDropdownItem>Light</a>
                  <a (click)="tileSwitch('cartoDB','cartoDbLightNoLabels')" clrDropdownItem>Light no Label</a>
                </clr-dropdown>
            </clr-dropdown-menu>
          </clr-dropdown>

          <clr-dropdown>
            <button clrDropdownTrigger>ESRI</button>
            <clr-dropdown-menu *clrIfOpen>
                <clr-dropdown>
                  <a (click)="tileSwitch('sonstige','esri')" clrDropdownItem>Hybrid</a>
                </clr-dropdown>
            </clr-dropdown-menu>
          </clr-dropdown>

          <clr-dropdown>
            <button clrDropdownTrigger>Geoportale</button>
            <clr-dropdown-menu *clrIfOpen>

              <clr-dropdown>
                <button clrDropdownTrigger>Belgien</button>
                <clr-dropdown-menu *clrIfOpen>
                  <clr-dropdown>
                    <a (click)="tileSwitch('sonstige','belgiumOrtho')" clrDropdownItem>Ortho</a>
                    <a (click)="tileSwitch('sonstige','belgiumCarto')" clrDropdownItem>Carto.be</a>
                  </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Deutschland</button>
                <clr-dropdown-menu *clrIfOpen>
                  <clr-dropdown>
                    <a (click)="tileSwitch('sonstige','bb_house')" clrDropdownItem>BB ALKIS Gebäude</a>
                    <a (click)="tileSwitch('sonstige','bb_lands')" clrDropdownItem>BB ALKIS Flurstücke</a>
                    <a (click)="tileSwitch('sonstige','bb_ortho')" clrDropdownItem>BB Ortho</a>
                    <a (click)="tileSwitch('sonstige','bw_ortho')" clrDropdownItem>BW Ortho</a>
                    <a (click)="tileSwitch('sonstige','by_ortho')" clrDropdownItem>BY Ortho</a>
                    <a (click)="tileSwitch('sonstige','hb_ortho')" clrDropdownItem>HB Ortho</a>
                    <a (click)="tileSwitch('sonstige','he_ortho')" clrDropdownItem>HE Ortho</a>
                    <a (click)="tileSwitch('sonstige','hh_ortho')" clrDropdownItem>HH Ortho</a>
                    <a (click)="tileSwitch('sonstige','mv_house')" clrDropdownItem>MV ALKIS Gebäude</a>
                    <a (click)="tileSwitch('sonstige','mv_lands')" clrDropdownItem>MV ALKIS Flurstücke</a>
                    <a (click)="tileSwitch('sonstige','mv_ortho')" clrDropdownItem>MV Ortho</a>


                    <a (click)="tileSwitch('sonstige','ni_ortho')" clrDropdownItem>NI Ortho</a>
                    <a (click)="tileSwitch('sonstige','nrw_house')" clrDropdownItem>NR ALKIS Gebäude</a>
                    <a (click)="tileSwitch('sonstige','nrw_lands')" clrDropdownItem>NR ALKIS Flurstücke</a>
                    <a (click)="tileSwitch('sonstige','nrwOrtho')" clrDropdownItem>NR Ortho</a>
                    <a (click)="tileSwitch('sonstige','sa_ortho')" clrDropdownItem>SA Ortho</a>
                    <a (click)="tileSwitch('sonstige','sn_house')" clrDropdownItem>SN ALKIS Gebäude</a>
                    <a (click)="tileSwitch('sonstige','sn_lands')" clrDropdownItem>SN ALKIS Flurstücke</a>
                    <a (click)="tileSwitch('sonstige','sn_ortho')" clrDropdownItem>SN Ortho</a>
                    <a (click)="tileSwitch('sonstige','th_ortho')" clrDropdownItem>TH Ortho</a>

                    <a (click)="tileSwitch('sonstige','de_farbe')" clrDropdownItem>BASEMAP.DE</a>
                    <a (click)="tileSwitch('sonstige','de_grau')" clrDropdownItem>BASEMAP.DE grau</a>
                  </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Luxemburg</button>
                <clr-dropdown-menu *clrIfOpen>
                  <clr-dropdown>
                    <a (click)="tileSwitch('sonstige','luxBaseMap')" clrDropdownItem>Basemap</a>
                    <a (click)="tileSwitch('sonstige','luxOrtho')" clrDropdownItem>Ortho</a>
                  </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Österreich</button>
                <clr-dropdown-menu *clrIfOpen>
                  <clr-dropdown>
                    <a (click)="tileSwitch('sonstige','austriaOrtho')" clrDropdownItem>Österreich Ortho</a>
                    <!-- <a clrDropdownItem>Voralberg Ortho</a> -->
                  </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

              <clr-dropdown>
                <button clrDropdownTrigger>Schweiz</button>
                <clr-dropdown-menu *clrIfOpen>
                  <clr-dropdown>
                    <a (click)="tileSwitch('sonstige','ch_base')" clrDropdownItem>Swisstopo Pixelkarte farbe</a>
                    <a (click)="tileSwitch('sonstige','ch_base_grau')" clrDropdownItem>Swisstopo Pixelkarte grau</a>
                    <a (click)="tileSwitch('sonstige','ch_ortho')" clrDropdownItem>Swisstopo Ortho</a>
                  </clr-dropdown>
                </clr-dropdown-menu>
              </clr-dropdown>

            </clr-dropdown-menu>
          </clr-dropdown>

          <clr-dropdown>
            <button clrDropdownTrigger>Google</button>
            <clr-dropdown-menu *clrIfOpen>
                <clr-dropdown>
                  <a (click)="tileSwitch('google','googleMaps')" clrDropdownItem>GoogleMaps</a>
                  <a (click)="tileSwitch('google','googleSatellite')" clrDropdownItem>Satelit</a>
                  <a (click)="tileSwitch('google','googleHybrid')" clrDropdownItem>Hybrid</a>
                  <a (click)="tileSwitch('google','googleTerrain')" clrDropdownItem>Terrain</a>
                </clr-dropdown>
            </clr-dropdown-menu>
          </clr-dropdown>

          <!-- <clr-dropdown>
            <button clrDropdownTrigger>Mapbox</button>
            <clr-dropdown-menu *clrIfOpen>
                <clr-dropdown>
                  <a (click)="tileSwitch('mapbox','mapboxDark')" clrDropdownItem>Dark</a>
                  <a (click)="tileSwitch('mapbox','mapboxLight')" clrDropdownItem>Light</a>
                  <a (click)="tileSwitch('mapbox','mapboxStreet')" clrDropdownItem>Street</a>
                  <a (click)="tileSwitch('mapbox','mapboxSatellite')" clrDropdownItem>Sattelit</a>
                  <a (click)="tileSwitch('mapbox','mapboxSatelliteStreet')" clrDropdownItem>Hybrid</a>
                </clr-dropdown>
            </clr-dropdown-menu>
          </clr-dropdown> -->

          <clr-dropdown>
            <button clrDropdownTrigger>Openstreetmap</button>
            <clr-dropdown-menu *clrIfOpen>
                <clr-dropdown>
                  <a (click)="tileSwitch('sonstige','osmde')" clrDropdownItem>Openstreetmap</a>
                  <!-- <a clrDropdownItem>OpenTopomap</a>
                  <a clrDropdownItem>ÖPNV Karte</a>
                  <a clrDropdownItem>OpenTransportmap</a> -->
                </clr-dropdown>
            </clr-dropdown-menu>
          </clr-dropdown>

          <a  (click)="tileSwitch('sonstige','wikimedia')" clrDropdownItem>WikiMaps</a>

        </clr-dropdown-menu>
      </clr-dropdown>
    </div>

    <div style="margin-top:10px;" *ngIf="overLayers">
      <strong>{{'Allgemein' | mrTranslate}}</strong>
      <clr-accordion>
        <clr-accordion-panel>
          <clr-accordion-title>{{'Objekte' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <clr-tree id="baseOverlayTree" >
              <clr-tree-node *ngFor="let overlay of  overLayers.base[0].overlays; let j = index;"
                [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('base',0,j,$event)">
                <span class="span-tree-node">
                  <clr-icon [attr.shape]="'objekttyp'+overlay.icon" size="18" style="color: black; margin-right: 0.4em;">
                  </clr-icon>
                        {{ overlay.name }}
                </span>
              </clr-tree-node>
            </clr-tree>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>{{'Kartenset' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <clr-select-container>
              <label>Set</label>
              <select clrSelect name="options" id="selMapSet" [(ngModel)]='curMapSet' (change)='reloadTopoJson()'>
                <option *ngFor="let objlist of tblKartenset.rows" value="{{objlist.ID}}">{{objlist.Dateiname}}</option>
              </select>
            </clr-select-container>
            <br>
            <button class="btn-icon" id="btnSetAdd" (click)="isMapSetNameVisible = true; isnewMapSet = true" title="{{'Neues Kartenset erstellen' | mrTranslate}}"><clr-icon shape="plus" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnSetEdit" (click)="isMapSetNameVisible = true; isnewMapSet = false" title="{{'Name bearbeiten' | mrTranslate}}"><clr-icon shape="edit" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnSetDelete" (click)="deleteMapSet()" title="{{'Kartenset löschen' | mrTranslate}}"><clr-icon shape="delete" size="32"></clr-icon></button>

          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>{{'Farben' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <input type="color" style="display:none;" [(ngModel)]="baseColor" (ngModelChange)="setColorGeneral('baseColor',$event)" #colorBase>
            <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorBase)" [ngStyle]="{'color': baseColor}"></clr-icon>
            <span [attr.title]="Hauptfarbe"> Linienfarbe</span>
            <br>
            <input type="color" style="display:none;" [(ngModel)]="hovercolor" (ngModelChange)="setColorGeneral('hoverColor',$event)" #colorHover>
            <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorHover)" [ngStyle]="{'color': hoverColor}"></clr-icon>
            <span [attr.title]="Hauptfarbe"> Auswahl-Farbe (Objekt verknüpft)</span>
            <br>
            <input type="color" style="display:none;" [(ngModel)]="hoverEmptycolor" (ngModelChange)="setColorGeneral('hoverEmptyColor',$event)" #colorHoverEmpty>
            <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorHoverEmpty)" [ngStyle]="{'color': hoverEmptyColor}"></clr-icon>
            <span [attr.title]="Hauptfarbe"> Auswahl-Farbe (Objekt nicht verknüpft)</span>
            <br>
            <br>
            <button class="btn-icon" (click)="resetColor()" title="{{'zurücksetzen' | mrTranslate}}"><clr-icon shape="undo" size="32"></clr-icon></button>
          </clr-accordion-content>
        </clr-accordion-panel>
        </clr-accordion>
    </div>
    <div style="margin-top:30px;" *ngIf="overLayers">
      <strong>{{'Visualisierung' | mrTranslate}}</strong>
      <clr-accordion>
        <clr-accordion-panel>
          <clr-accordion-title>{{'Zustand' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <!-- ! Container + Template außerhalb von clr-accordion zur Umgehung des Fehlers -->
            <!-- * Hilft auch einen anderen component zu erstellen -->
            <ng-container *ngTemplateOutlet='zustandAccordion'></ng-container>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel *ngIf="overLayers.visualisations['bauform']">
          <clr-accordion-title>{{'Bauform' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <!-- ! Container + Template außerhalb von clr-accordion zur Umgehung des Fehlers -->
            <!-- * Hilft auch einen anderen component zu erstellen -->
            <ng-container *ngTemplateOutlet='bauformAccordion'></ng-container>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>{{'Stammdaten' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <!-- ! Container + Template außerhalb von clr-accordion zur Umgehung des Fehlers -->
            <!-- * Hilft auch einen anderen component zu erstellen -->
            <ng-container *ngTemplateOutlet='stammdatenAccordion'></ng-container>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel *ngIf="overLayers.visualisations['hersteller']">
          <clr-accordion-title>{{'Hersteller' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <!-- ! Container + Template außerhalb von clr-accordion zur Umgehung des Fehlers -->
            <!-- * Hilft auch einen anderen component zu erstellen -->
            <ng-container *ngTemplateOutlet='herstellerAccordion'></ng-container>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel *ngIf="overLayers.visualisations['prueftermin']">
          <clr-accordion-title>{{'Termin' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <!-- ! Container + Template außerhalb von clr-accordion zur Umgehung des Fehlers -->
            <!-- * Hilft auch einen anderen component zu erstellen -->
            <ng-container *ngTemplateOutlet='terminAccordion'></ng-container>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel *ngIf="overLayers.visualisations['projekte']">
          <clr-accordion-title>{{'Projekte' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <!-- ! Container + Template außerhalb von clr-accordion zur Umgehung des Fehlers -->
            <!-- * Hilft auch einen anderen component zu erstellen -->
            <ng-container *ngTemplateOutlet='projekteAccordion'></ng-container>
          </clr-accordion-content>
        </clr-accordion-panel>
      </clr-accordion>
      <ng-template #zustandAccordion>
        <clr-accordion>
          <clr-accordion-panel>
            <clr-accordion-title>{{'Fehlerklassen einfach' | mrTranslate}}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overLayers.base[1].overlays; let j = index;"
                    [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('base',1,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('base',1,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>

                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel>
            <clr-accordion-title>{{'Fehlerklassen segmentiert' | mrTranslate}}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overLayers.base[2].overlays; let j = index;"
                    [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('base',2,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                        (ngModelChange)="setColorFromPicker('base',2,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['stoerungen']; let i = index;">
            <clr-accordion-title>{{ 'Störungen' | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable"
                    (clrSelectedChange)="changeOverlayState('stoerungen',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('stoerungen',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['sperrungen']; let i = index;">
            <clr-accordion-title>{{ overlayCat.category | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable"
                    (clrSelectedChange)="changeOverlayState('sperrungen',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('sperrungen',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['langsamfahrstellen']; let i = index;">
            <clr-accordion-title>{{ overlayCat.category | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable"
                    (clrSelectedChange)="changeOverlayState('langsamfahrstellen',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('langsamfahrstellen',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['auftraege']; let i = index;">
            <clr-accordion-title>{{ 'Aufträge' | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('auftraege',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('auftraege',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel *ngIf="overLayers.visualisations['kaveinfach']">
            <clr-accordion-title>{{ 'KAV' | mrTranslate }} {{ 'Einfach' | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree *ngFor="let overlayCat of overLayers.visualisations['kaveinfach']; let i = index;">
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable"
                    (clrSelectedChange)="changeOverlayState('kaveinfach',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('kaveinfach',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel *ngIf="overLayers.visualisations['kavseg']">
            <clr-accordion-title>{{ 'KAV' | mrTranslate }} {{ 'Segmentiert' | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree *ngFor="let overlayCat of overLayers.visualisations['kavseg']; let i = index;">
                <clr-tree-node>
                  {{overlayCat.category | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('kavseg',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('kavseg',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel>
            <clr-accordion-title>{{ 'Auswertung Gleismessdaten' | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>

              <app-gleismessdatenmodal (mess_filter)="setMessFilter($event)"
                [resultReady]="isGleisMessFilterReady">
              </app-gleismessdatenmodal>
            </clr-accordion-content>
          </clr-accordion-panel>

        </clr-accordion>
      </ng-template>
      <ng-template #bauformAccordion>
        <clr-accordion>
          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['bauform']; let i = index;">
            <clr-accordion-title>{{ overlayCat.category | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('bauform',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('bauform',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>
      </ng-template>
      <ng-template #stammdatenAccordion>
        <clr-accordion>
          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['stamm']; let i = index;">
            <clr-accordion-title>{{ correctStammName(overlayCat.category) | mrTranslate }}</clr-accordion-title>
            <clr-accordion-content [clrIfExpanded]="true">
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('stamm',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('stamm',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['trassierungen']; let i = index;">
            <clr-accordion-title>{{'Trassierungen' | mrTranslate}} {{ overlayCat.category }}</clr-accordion-title>
            <clr-accordion-content [clrIfExpanded]="true">
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable"
                    (clrSelectedChange)="changeOverlayState('trassierungen',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('trassierungen',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>

        </clr-accordion>
      </ng-template>
      <ng-template #herstellerAccordion>
        <clr-accordion>
          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['hersteller']; let i = index;">
            <clr-accordion-title>{{ overlayCat.category | mrTranslate}}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable"
                    (clrSelectedChange)="changeOverlayState('hersteller',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('hersteller',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>
      </ng-template>
      <ng-template #terminAccordion>
        <clr-accordion>
          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['prueftermin']; let i = index;">
            <clr-accordion-title>{{ 'Prüftermin' | mrTranslate}}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable"
                    (clrSelectedChange)="changeOverlayState('prueftermin',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('prueftermin',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ getMonthName(overlay.name) }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>
      </ng-template>
      <ng-template #projekteAccordion>
        <clr-accordion>
          <clr-accordion-panel *ngFor="let overlayCat of overLayers.visualisations['projekte']; let i = index;">
            <clr-accordion-title>{{ overlayCat.category | mrTranslate}}</clr-accordion-title>
            <clr-accordion-content>
              <clr-tree>
                <clr-tree-node>
                  {{'Alle' | mrTranslate}}
                  <clr-tree-node *ngFor="let overlay of overlayCat.overlays; let j = index;"
                    [clrSelected]="overlay.enable" (clrSelectedChange)="changeOverlayState('projekte',i,j,$event)">
                    <span class="span-tree-node">
                      <input type="color" style="display:none;" [(ngModel)]="overlay.color"
                      (ngModelChange)="setColorFromPicker('projekte',i,j,$event)" #colorIn>
                      <clr-icon shape="circle" class="is-solid cursor-pointer" (click)="colorFocusInput(colorIn)"
                        [ngStyle]="{'color': overlay.color}"></clr-icon>
                      <span [attr.title]="overlay.name">{{ overlay.name | dotDotDot:30 }}</span>
                    </span>
                  </clr-tree-node>
                </clr-tree-node>
              </clr-tree>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>
      </ng-template>


      <div style="margin-top:10px;margin-left:4px;" class="clr-form-control singleCheckbox clr-row">
        <clr-checkbox-wrapper>
          <input class="clr-col-md-2" type="checkbox" clrCheckbox value="1" (click)="setObjText($event)" />
          <label class="clr-col-md">{{'Objektbezeichnung anzeigen' | mrTranslate}}</label>
        </clr-checkbox-wrapper>
      </div>

      <div style="margin-top:10px;margin-left:4px;" class="clr-form-control singleCheckbox clr-row">
        <clr-checkbox-wrapper>
          <input class="clr-col-md-2" type="checkbox" clrCheckbox value="1" (click)="setMangelBem($event)" />
          <label class="clr-col-md">{{'Mangelbemerkungen anzeigen' | mrTranslate}}</label>
        </clr-checkbox-wrapper>
      </div>

      <div style="margin-top:10px;margin-left:4px;" class="clr-form-control singleCheckbox clr-row">
        <clr-checkbox-wrapper>
          <input class="clr-col-md-2" type="checkbox" clrCheckbox value="1" (click)="showPosiiton($event)" />
          <label class="clr-col-md">{{'Standort anzeigen' | mrTranslate}}</label>
        </clr-checkbox-wrapper>
      </div>

      <!-- <form [formGroup]="sliderForm">
        <p>Low value: {{ sliderForm.value.sliderControl[0] }}</p>
        <p>High value: {{ sliderForm.value.sliderControl[1] }}</p>
        <ngx-slider [options]="options" formControlName="sliderControl"></ngx-slider>
      </form> -->


      <!-- <strong style="margin-top: 10px; display:inline-block;">{{'Filter' | mrTranslate}}</strong><br />

      <app-objectfiltermodal (filter_modal)="filter_modal = $event" (object_filter)="applyFilter($event)"
        [config]="{mode: 'gismap'}" (filter_invert)="invertFilter($event)"
        (gismap_datefilter_emitter)="setDateFilter($event)">
      </app-objectfiltermodal> -->

      <br>
      <strong>{{'Editor' | mrTranslate}}</strong>
      <clr-accordion>
        <clr-accordion-panel>
          <clr-accordion-title>{{'Allgemein' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <label style="font-size: 12px; font-weight: bolder;"> {{'Bearbeiten' | mrTranslate}} </label>
            <br>
            <button class="btn-icon" id="btnDelete"  [disabled]="!activeElement" (click)="deleteSelected()" title="{{'Objekt löschen' | mrTranslate}}"><clr-icon shape="delete" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnModify" [disabled]="!activeElement" (click)="modifySelected($event)" title="{{'Linie bearbeiten' | mrTranslate}}"><clr-icon shape="cursor-hand-grab" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnUpdate" [disabled]="!activeElement" (click)="updateSelected($event)" title="{{'Objekt einer Anlage zuweisen' | mrTranslate}}"><clr-icon shape="edit" size="32"></clr-icon></button>

            <br>
            <br>
            <label style="font-size: 12px; font-weight: bolder;"> {{'Schneiden/Trennen' | mrTranslate}} </label>
            <br>
            <button class="btn-icon" id="btnCut" [disabled]="!activeElement" (click)="cutSelected($event)" title="{{'Linie abschneiden' | mrTranslate}}"><clr-icon shape="trimLine" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnCutPos" [disabled]="!activeElement" (click)="cutSelectedAtPos($event)" title="{{'Linie abschneiden bei Position' | mrTranslate}}"><clr-icon shape="trimLineAtPos" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnSep" [disabled]="!activeElement" (click)="separateSelected($event)" title="{{'Linie trennen' | mrTranslate}}"><clr-icon shape="breakLine" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnSepPos" [disabled]="!activeElement" (click)="separateSelectedAtPos($event)" title="{{'Linie trennen' | mrTranslate}}"><clr-icon shape="breakLineAtPos" size="32"></clr-icon></button>
            <button class="btn-icon" id="btnMerge" [disabled]="!activeElement" (click)="mergeSelected($event)" title="{{'Linien verbinden' | mrTranslate}}"><clr-icon shape="mergeLine" size="32"></clr-icon></button>

            <br>
            <br>
            <label style="font-size: 12px; font-weight: bolder;"> {{'Sortieren' | mrTranslate}} </label>
            <br>
            <button class="btn-icon" [disabled]="!activeElement" (click)="sortLine($event)" title="{{'Linie sortieren' | mrTranslate}}"><clr-icon shape="sortLine" size="32"></clr-icon></button>
            <button class="btn-icon" [disabled]="!activeElement" (click)="setRotateLine($event)" title="{{'Linie drehen' | mrTranslate}}"><clr-icon shape="rotateLine" size="32"></clr-icon></button>

            <br>
            <br>
            <label style="font-size: 12px; font-weight: bolder;"> {{'Messung' | mrTranslate}} </label>
            <br>
            <button class="btn-icon" (click)="setMeasure($event)" title="{{'Distanz zwischen zwei Punkten ermitteln' | mrTranslate}}"><clr-icon shape="ruler" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="getPos($event)" title="{{'Position ermitteln' | mrTranslate}}"><clr-icon shape="pin" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="startDrawArc(true,$event)" title="{{'Radius messen' | mrTranslate}}"><clr-icon shape="arcCalc" size="32"></clr-icon></button>
            <!-- <button class="btn-icon" (click)="setMoveAll($event)" title="{{'Alles bewegen' | mrTranslate}}"><clr-icon shape="ruler" size="32"></clr-icon></button> -->

          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>{{'Zeichnen' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <button class="btn-icon" (click)="startDraw('LineString', $event)" title="{{'Linie zeichnen' | mrTranslate}}"><clr-icon shape="draw_line" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="startDrawArc(false,$event)" title="{{'Bogen' | mrTranslate}}"><clr-icon shape="draw_arc" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="startDraw('Polygon', $event)" title="{{'Fläche' | mrTranslate}}"><clr-icon shape="draw_area" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="startPlaceSymbol(0,$event)" title="{{'Punktsymbol platzieren' | mrTranslate}}"><clr-icon shape="styp0" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="startDraw('None', $event)" title="{{'Abbrechen' | mrTranslate}}"><clr-icon shape="cursor-arrow" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="undoDraw()" title="{{'Letzte Linie zurücksetzen' | mrTranslate}}"><clr-icon shape="undo" size="32"></clr-icon></button>
            <br>
            <br>
            <button class="btn-icon" (click)="showTOEditor()" title="{{'Weichen-Editor' | mrTranslate}}"><clr-icon shape="weicheneditor" size="32"></clr-icon></button>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>{{'Symbole' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <clr-accordion>
              <button class="btn-icon" id="btnRotate" [disabled]="!activeElement" (click)="rotateSymbol($event)" title="{{'Objekt drehen' | mrTranslate}}"><clr-icon shape="rotateObj" size="32"></clr-icon></button>
              <button class="btn-icon" id="btnMove" [disabled]="!activeElement" (click)="moveSymbol($event)" title="{{'Objekt bewegen' | mrTranslate}}"><clr-icon shape="cursor-move" size="32"></clr-icon></button>
              <clr-accordion-panel>
                <clr-accordion-title>{{'Gleissperren' | mrTranslate}}</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <button class="btn-outline" (click)="startPlaceSymbol(1,$event)" title="{{'Gleissperre links' | mrTranslate}}"><clr-icon shape="styp1" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(2,$event)" title="{{'Gleissperre rechts' | mrTranslate}}"><clr-icon shape="styp2" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(3,$event)" title="{{'Gleissperre links ferngesteuert' | mrTranslate}}"> <clr-icon shape="styp3" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(4,$event)" title="{{'Gleissperre rechts ferngesteuert' | mrTranslate}}"><clr-icon shape="styp4" size="32"></clr-icon></button>
                </clr-accordion-content>
              </clr-accordion-panel>
              <clr-accordion-panel>
                <clr-accordion-title>{{'Isolierstöße' | mrTranslate}}</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <button class="btn-outline" (click)="startPlaceSymbol(5,$event)" title="{{'Isolierstoß' | mrTranslate}}"><clr-icon shape="styp5" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(30,$event)" title="{{'Isolierstoß nach beiden Seiten' | mrTranslate}}"><clr-icon shape="styp30" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(31,$event)" title="{{'Isolierstoß nach rechts wirkend' | mrTranslate}}"><clr-icon shape="styp31" size="32"></clr-icon></button>
                </clr-accordion-content>
              </clr-accordion-panel>
              <clr-accordion-panel>
                <clr-accordion-title>{{'Lichtsignale' | mrTranslate}}</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <button class="btn-outline" (click)="startPlaceSymbol(14,$event)" title="{{'Lichthauptsignal' | mrTranslate}}"><clr-icon shape="styp14" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(15,$event)" title="{{'LHS mit Geschwindigkeitsanzeiger' | mrTranslate}}"><clr-icon shape="styp15" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(16,$event)" title="{{'LHS mit Geschwindigkeitsanzeiger und Voranzeiger' | mrTranslate}}"><clr-icon shape="styp16" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(17,$event)" title="{{'Lichtvorsignal' | mrTranslate}}"><clr-icon shape="styp17" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(18,$event)" title="{{'LVS mit Geschwindigkeitsanzeiger' | mrTranslate}}"><clr-icon shape="styp18" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(19,$event)" title="{{'Lichthauptsignal als Mehrabschnittssignal' | mrTranslate}}"><clr-icon shape="styp19" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(20,$event)" title="{{'LHS-MAS mit Geschwindigkeitsanzeiger' | mrTranslate}}"><clr-icon shape="styp20" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(21,$event)" title="{{'LHS-MAS mit Geschwindigkeitsavoranzeiger' | mrTranslate}}"><clr-icon shape="styp21" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(22,$event)" title="{{'LHS-MAS mit Geschwindigkeitsanzeiger / voranzeiger' | mrTranslate}}"><clr-icon shape="styp22" size="32"></clr-icon></button>
                </clr-accordion-content>
              </clr-accordion-panel>
              <clr-accordion-panel>
                <clr-accordion-title>{{'Radsensoren' | mrTranslate}}</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <button class="btn-outline" (click)="startPlaceSymbol(23,$event)" title="{{'Achszählkontakt' | mrTranslate}}"><clr-icon shape="styp23" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(32,$event)" title="{{'Schaltkontakt' | mrTranslate}}"><clr-icon shape="styp32" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(33,$event)" title="{{'Bü-Schaltkontakt' | mrTranslate}}"><clr-icon shape="styp33" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(34,$event)" title="{{'Magnetschienenkontakt' | mrTranslate}}"><clr-icon shape="styp34" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(35,$event)" title="{{'Fahrzeugsensor' | mrTranslate}}"><clr-icon shape="styp35" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(36,$event)" title="{{'Schaltbarer Gleismagnet' | mrTranslate}}"><clr-icon shape="styp36" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(37,$event)" title="{{'Ständig wirksame Gleismagnete' | mrTranslate}}"><clr-icon shape="styp37" size="32"></clr-icon></button>
                </clr-accordion-content>
              </clr-accordion-panel>
              <clr-accordion-panel>
                <clr-accordion-title>{{'Rangier-/Sperrsignale' | mrTranslate}}</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <button class="btn-outline" (click)="startPlaceSymbol(28,$event)" title="{{'Lichtsperrsignal' | mrTranslate}}"><clr-icon shape="styp28" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(27,$event)" title="{{'Lichtsperrsignal mit niedrigem Mast' | mrTranslate}}"><clr-icon shape="styp27" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(24,$event)" title="{{'Rangerhalttafel' | mrTranslate}}"><clr-icon shape="styp24" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(43,$event)" title="{{'Sh1/Ra12' | mrTranslate}}"><clr-icon shape="styp43" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(44,$event)" title="{{'Wartezeichen' | mrTranslate}}"><clr-icon shape="styp44" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(45,$event)" title="{{'Wartezeichen mit SH1' | mrTranslate}}"><clr-icon shape="styp45" size="32"></clr-icon></button>
                </clr-accordion-content>
              </clr-accordion-panel>
              <clr-accordion-panel>
                <clr-accordion-title>{{'Kabellagepläne' | mrTranslate}}</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <button class="btn-outline" (click)="startPlaceSymbol(38,$event)" title="{{'Kabelschrank' | mrTranslate}}"><clr-icon shape="styp38" size="38"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(39,$event)" title="{{'Schalthaus' | mrTranslate}}"><clr-icon shape="styp39" size="39"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(40,$event)" title="{{'Kabelverteiler' | mrTranslate}}"><clr-icon shape="styp40" size="40"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(41,$event)" title="{{'Kabelschacht' | mrTranslate}}"><clr-icon shape="styp41" size="41"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(42,$event)" title="{{'Weichenantrieb' | mrTranslate}}"><clr-icon shape="styp42" size="42"></clr-icon></button>
                </clr-accordion-content>
              </clr-accordion-panel>
              <clr-accordion-panel>
                <clr-accordion-title>{{'Sonstige' | mrTranslate}}</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                  <button class="btn-outline" (click)="startPlaceSymbol(10,$event)" title="{{'Andreaskreuz' | mrTranslate}}"><clr-icon shape="styp10" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(11,$event)" title="{{'Überwachungssignal' | mrTranslate}}"><clr-icon shape="styp11" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(12,$event)" title="{{'Andreaskreuz mit Lichtzeichen' | mrTranslate}}"><clr-icon shape="styp12" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(13,$event)" title="{{'Beleuchtung' | mrTranslate}}"><clr-icon shape="styp13" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(9,$event)" title="{{'Andreaskreuz (liegend)' | mrTranslate}}"><clr-icon shape="styp9" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(29,$event)" title="{{'Schrankenanlage' | mrTranslate}}"><clr-icon shape="styp29" size="32"></clr-icon></button>
                  <button class="btn-outline" (click)="startPlaceSymbol(5,$event)" title="{{'GLA (Prellbock)' | mrTranslate}}"><clr-icon shape="styp5" size="32"></clr-icon></button>
                </clr-accordion-content>
              </clr-accordion-panel>
            </clr-accordion>
          </clr-accordion-content>
        </clr-accordion-panel>

        <clr-accordion-panel>
          <clr-accordion-title>{{'Import' | mrTranslate}}</clr-accordion-title>
          <clr-accordion-content *clrIfExpanded>
            <button class="btn-icon" (click)="fileInput.click()"><clr-icon shape="importOSM" size="32"></clr-icon></button>
            <button class="btn-icon" (click)="fileInputSHP.click()"><clr-icon shape="importSHP" size="32"></clr-icon></button>
            <input type="file" #fileInput id="fileInput" name="fileInput" style="display: none; max-height: 200px;" (change)="onFileOSMChange($event)" />
            <input type="file" #fileInputSHP id="fileInputSHP" accept=".zip" name="fileInputSHP" style="display: none; max-height: 200px;" (change)="onFileSHPChange($event)" />

            <br>
            <br>
            <clr-select-container>
              <label>Projektionstyp:</label>
              <select clrSelect name="options" #mySelect (change)='EPSGChanged(mySelect.value)'>
                <option value="EPSG:4326">EPSG 4326 WGS 84 World</option>
                <option value="EPSG:2169">EPSG 2169 Luxembourg 1930 / Gauss</option>
                <option value="EPSG:2056">EPSG 2056 Swiss CH1903 + / LV95</option>
                <option value="EPSG:21781">EPSG 21781 Swiss CH1903 / LV03</option>
                <option value="EPSG:25831">EPSG 25831 ETRS89 / UTM zone 31N</option>
                <option value="EPSG:25832">EPSG 25832 ETRS89 / UTM zone 32N</option>
                <option value="EPSG:25833">EPSG 25833 ETRS89 / UTM zone 33N</option>
                <option value="EPSG:31466">EPSG 31466 Gauss-Kruger Zone 2 (Mittelmeridiane 6 Grad)</option>
                <option value="EPSG:31467">EPSG 31467 Gauss-Kruger Zone 3 (Mittelmeridiane 9 Grad)</option>
                <option value="EPSG:31468">EPSG 31468 Gauss-Kruger Zone 4 (Mittelmeridiane 12 Grad)</option>
                <option value="EPSG:31469">EPSG 31469 Gauss-Kruger Zone 5 (Mittelmeridiane 15 Grad)</option>
                <option value="EPSG:31370">EPSG 31370 Belge 1972 / Belgian Lambert 72</option>
              </select>
            </clr-select-container>
          </clr-accordion-content>
        </clr-accordion-panel>
      </clr-accordion>
      <br>
      <button class="btn btn-primary" [disabled]="(tempfeaturesList.length == 0 && !enableSave) || production" (click)="saveFeatures()">{{'Speichern' | mrTranslate}}</button>
      </div>
  </div>
  <div id="overlaySwitcherTriggerWrapper">
    <div id="overlaySwitcherTrigger" class="cursor-pointer" (click)="triggerOverlaySwitcher()">
      <a href="javascript:void(0);" class="cursor-pointer">
        <clr-icon size="32" shape="angle-double left"
        [style.rotate.deg]="showOverlaySwitcher ? 0 : 180"></clr-icon>
      </a>
    </div>
  </div>
  <div id="mapWrap"
    [ngClass]="{'showprogressbar': showprogressbar && progressbarenlarged, 'split50': split50 && !hidedetailview, 'showdetailview': showdetailview && !hidedetailview}">
    <div style="height:100%;" id="map-page">
      <div id="map" class="map">
        <div id="info"></div>
        <div id="position"></div>
      </div>
    </div>
  </div>
  <div #dvwrapper id="wrapperdetailview" *ngIf="showdetailview"
    [ngClass]="{ sidenavenlarged, split50, hidedetailview }">
    <app-detailview [ostammid]="ostammid" [progressbarenlarged]="progressbarenlarged" [split50]="split50"
      [expanded]="sidenavenlarged" [hidden]="hidedetailview" (enlarge)="enlargeSidenav($event)" (enlargePB)="enlargeProgressbar(true)"
      (progressData)="showProgressData($event)" (indexScrub)="handleScrub($event)" (reload)="reloadDetailview()" #detailView></app-detailview>
  </div>
</div>


<div id="progresstrigger" *ngIf="showprogressbar" [ngClass]="{'bottom': !progressbarenlarged}">
  <div class="wrap" (click)="enlargeProgressbar()">
    <clr-icon [ngStyle]="{'transform': progressbarenlarged ? 'rotate(180deg)' : 'rotate(0deg)'}" shape="caret"
      class="expandProgressbarButton cursor-pointer"></clr-icon>
  </div>
</div>
<!--  
! auskommentiert, da progressbarenlarged is nie false
[ngClass]="(!progressbarenlarged && !sidenavenlarged) ? (split50 ? 'shorter' :'short') : '' " -->
<footer *ngIf="showprogressbar && progressbarenlarged" id="wrapperprogressbars"
  class="clr-row nxm clr-align-items-center">
  <app-progressbar [dataIn]="progressbars" [indexScrub]="indexScrub" (enlargeProgressbar)="enlargeProgressbar(true)"
    (openDialogAt)="openOverlay($event)" style="display:inline-block; width:calc(100% - 35px);"></app-progressbar>
</footer>

<!-- MODAL OBJEKTVERKNÜPFUNG -->
<clr-modal [(clrModalOpen)]="isObjLinkVisible" [clrModalSize]="'xl'" [clrModalClosable]="true">
  <h3 class="modal-title">{{'Objekt verknüpfen'|mrTranslate}}</h3>
  <div class="modal-body" *ngIf="tblObjektTypen">
    <form clrForm clrLayout="horizontal" [formGroup]="modalLinkGroup">
      <clr-select-container>
        <label>Objekttyp</label>
        <select clrSelect name="options" formControlName="objTyp" #mySelect (change)='objTypChanged(mySelect.value)'>
          <option *ngFor="let objlist of tblObjektTypen.rows" value="{{objlist.ID}}">{{objlist.Bezeichnung}}</option>
        </select>
      </clr-select-container>
      <!-- <label>STAMMID</label>
      <input type="text" formControlName="StammID" class="clr-input" /> -->
      <div *ngIf="tblAnl">
        <clr-datagrid [(clrDgSingleSelected)]="selTbl" class="datagrid-compact">
          <clr-dg-column *ngFor="let column of tblAnl['columns']" [clrDgField]="column.id" [hidden]="column.id | hideIDColumns: ['bildpfad','lf','st','sp','rep','messreg bezirk']">{{
            column.id | titlecase | mrTranslate
            }}</clr-dg-column>
          <clr-dg-row *clrDgItems="let row of tblAnl['rows']" [clrDgItem]="row">
            <clr-dg-cell *ngFor="let cell of tblAnl['columns']" [innerHTML]="row[cell.id] | tablePrettyPrint" [hidden]="cell.id | hideIDColumns: ['bildpfad','lf','st','sp','rep','messreg bezirk']"></clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="15">
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
              {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>
      </form>
      </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="editFeature()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isObjLinkVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>

<!-- MODAL POSITION -->
<clr-modal [(clrModalOpen)]="isPositionModalVisible" [clrModalSize]="'m'" [clrModalClosable]="true">
  <h3 class="modal-title">{{'Position angeben'|mrTranslate}}</h3>
  <div class="modal-body">
    <label>Geben Sie die Position (m) ein, in der geschnitten werden soll:</label>
    <br>
    <input type="number" min="0" max="{{featLen}}" style="width: 250px;" id="PositionCut" class="clr-input" /> <label id="lblLaenge" style="margin-left: 20px;">{{'Gleislänge:' | mrTranslate}} {{featLen}} m</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="cutLine(-1)">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isPositionModalVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>

<!-- MODAL KARTENSET NAME -->
<clr-modal [(clrModalOpen)]="isMapSetNameVisible" [clrModalSize]="'m'" [clrModalClosable]="true">
  <h3 class="modal-title">{{'Name angeben'|mrTranslate}}</h3>
  <div class="modal-body">
    <label>Tragen Sie den Namen dieses Kartensets ein</label>
    <br>
    <input type="text" style="width: 250px;" id="SetName" class="clr-input" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="createMapSet()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isMapSetNameVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>

<!-- MODAL GEOPOSITION ANZEIGEN -->
<clr-modal [(clrModalOpen)]="isGeoPosModalVisible" [clrModalSize]="'m'" [clrModalClosable]="true">
  <h3 class="modal-title">{{'Geoposition'|mrTranslate}}</h3>
  <div class="modal-body">
    <div *ngIf="!arcShowDlgOnly; else showArcRadius">
      <div *ngIf="measure.SetSecondPoint; else standard">
        <label>{{'Position 1:' | mrTranslate}}</label><br>
        <label style="margin-left:20px"> in m: {{measure.Point1}}</label>
        <br>
        <label style="margin-left:20px"> in °: {{measure.Point1_Deg}}</label>
        <br>
        <label>{{'Position 2:' | mrTranslate}}</label><br>
        <label style="margin-left:20px"> in m: {{measure.Point2}}</label>
        <br>
        <label style="margin-left:20px"> in °: {{measure.Point2_Deg}}</label>
        <br>
        <br>
        <label>{{'Abstand X:' | mrTranslate}}</label> {{measure.PointX}}
        <br>
        <label>{{'Abstand Y:' | mrTranslate}}</label> {{measure.PointY}}
        <br>
        <label>{{'Abstand:' | mrTranslate}}</label> {{measure.Distance}}
      </div>
  </div>
    <ng-template #standard>
      <label>{{'Aktuelle Geopositon:' | mrTranslate}}</label>
      <br>
      <input type="text" style="width: 350px;" value="{{mouseposConv}}" id="GeoPosMove" class="clr-input" />
    </ng-template>
    <ng-template #showArcRadius>
      <label>{{'Radius:' | mrTranslate}}</label>
      <br>
      <input type="text" style="width: 350px;" value="{{dlgRadius}}" id="GeoPosMove" class="clr-input" />
    </ng-template>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="moveToPos()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isGeoPosModalVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div> -->
</clr-modal>


<!-- MODAL WEICHENEDITOR -->
<clr-modal [(clrModalOpen)]="isTurnoutEditorVisible" [clrModalSize]="'xl'" [clrModalClosable]="true">
  <h3 class="modal-title">{{'Weicheneditor'|mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="horizontal" [formGroup]="modalTOEditor">
      <div class="container">
          <label>{{'Weichentyp'|mrTranslate}}</label>
          <select name="options" style="margin-top: 0px;" formControlName="WeichenTyp" #mySelect (change)='getTOData(mySelect.value)'>
            <option *ngFor="let objlist of lstWeichenTypen | keyvalue"   [selected]="objlist.key === 0" value="{{objlist.key}}">{{objlist.value}}</option>
          </select>
        <div class="clr-radio-wrapper">
          <input type="radio" id="rbL" style="left: 28px;" clrRadio [value]="0" formControlName="richtung" (change)='switchDir()' />
          <label class="clr-col-md-6">{{'Links' | mrTranslate }}</label>
        </div>
        <div class="clr-radio-wrapper">
          <input type="radio" id="rbR" style="left: 28px;" clrRadio [value]="1" formControlName="richtung" (change)='switchDir()' />
          <label for="A2" class="clr-col-md-12">{{'Rechts' | mrTranslate }}</label>
        </div>
      </div>
      <div class="container" style="margin-top: 10px;">
          <label style="margin-top: 12px;">{{'Bezeichnung'|mrTranslate}}</label>
          <select name="options" formControlName="Bezeichnung" #mySelect2 (change)='setTOTypes(mySelect2.value)'>
            <option *ngFor="let objlist of lstWeichen" value="{{objlist}}">{{objlist}}</option>
          </select>
        <button class="btn-outline" (click)="TOEditorCreateTO($event)" title="{{'Eigene Weiche erstellen' | mrTranslate}}"><clr-icon shape="add" size="32"></clr-icon></button>
        <button class="btn-outline" (click)="TOEditorDeleteTO($event)" title="{{'Weiche löschen' | mrTranslate}}"><clr-icon shape="delete" size="32"></clr-icon></button>
        <button class="btn-outline" (click)="TOEditorEditTO($event)" title="{{'Weiche Bearbeiten' | mrTranslate}}"><clr-icon shape="edit" size="32"></clr-icon></button>
        <input type="text" id="BezPosition" formControlName="BezPosition" placeholder="Bezeichnung neue Schiene" style ="width: 200px;" class="clr-input" />
      </div>
      <br>
      <div class="container">
        <label>{{'Position'|mrTranslate}}</label>
        <input type="text" formControlName="Position" class="clr-input" />
        <input type="checkbox" formControlName="PositionCut" />
        <label>{{'Gleis an Position schneiden'|mrTranslate}}</label>
        <input type="checkbox" formControlName="PositionMouse" />
        <label>{{'Im gewählten Gleis an Mausposition setzen'|mrTranslate}}</label>
      </div>
      <br>
      <div class="clr-row" style="margin-top:30px">
        <div class="clr-col-12">
          <div style="border-style: solid;">
            <div class="clr-row">
              <div style="min-height: 300px; max-height: 300px;">
                <img [src]=curTOPic alt="" style="max-height: 296px; width: 700px" class="uploadImagePreview" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <input type="text" id="tb1" class="clr-input tbstandard" value="0"/>
      <input type="text" id="tb2" class="clr-input tbstandard" value="0"/>
      <input type="text" id="tb3" class="clr-input tbstandard" value="0"/>
      <input type="text" id="tb4" class="clr-input tbstandard" value="0"/>
      <input type="text" id="tb5" class="clr-input tbstandard" value="0"/>
      <input type="text" id="tb6" class="clr-input tbstandard" value="0"/>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="startPlaceTOSymbol($event)">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isObjLinkVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
