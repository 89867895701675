<div id="barwrapper">
  <div id="barholder" #wrapper>
    <div *ngFor="let bar of bars; let i = index"
      [ngStyle]="{'width': (bar.end-bar.start)/progressbarwidth *100 + '%','margin-left': (bar.start)/progressbarwidth *100 + '%'}"
      style="position:absolute;height:inherit; display: inline-block;">
      <clr-tooltip style="width:100%;height:inherit; display:inline-block;">
        <div clrTooltipTrigger class="bar cursor-pointer" (click)="openProfileAt(bar.start_m)"
          [ngStyle]="{'background-color': bar.color, 'border': '0px solid transparent', '--fk': bar.fk}">
        </div>
        <clr-tooltip-content clrPosition="top-right" clrSize="lg" *clrIfOpen [innerHTML]="bar.metastring">
        </clr-tooltip-content>
      </clr-tooltip>
    </div>
    <div class="bar scrub" *ngIf="indexScrub >= 0"
      [ngStyle]="{'margin-left': (indexScrub*ratio)/progressbarwidth *100 + '%'}"
      style="width:1px; background-color: #000;transition: none;border: 0px solid transparent; transform: scale(1,1.38); display:inline-block;">
    </div>
  </div>
  <div id="barlegend">
    <div *ngFor="let val of barlegend" [ngStyle]="{'left': (100/(barlegend.length-1) * val)+'%'}"
      class="legendstripe translate-left">|
      <br> {{dist / (barlegend.length-1) * val | number:'1.2-2'}}m
    </div>
  </div>
</div>