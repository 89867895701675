<div id="calendarWrap" class="clr-row">
  <div id="calendar" *ngIf="events$ | async as events" class="clr-col h-60"
    [ngStyle]="{'max-width': ((!showdetailview || hidedetailview) ? '100%' : (split50 ? '50%' : '66.66%'))}">
    <div class="w-100p clr-col" id="calendarToolbar">
      <div id="currentDate" class="noMargin" style="justify-self:flex-start;">
        <strong class="caption">
          {{ currentDate | date: 'LLLL':undefined:translateService.currentLang | titlecase }}
        </strong>
      </div>
      <div id="calenderMonthSwitcher">
        <clr-button-group class="noMargin">
          <clr-button class="btn-sm" (click)="switchNextPrev(-1)">
            <clr-icon shape="caret" style="transform: rotate(270deg);"></clr-icon>
          </clr-button>
          <clr-button class="btn-sm" (click)="today()">{{ 'Heute' | mrTranslate }}</clr-button>
          <clr-button class="btn-sm" (click)="switchNextPrev(+1)">
            <clr-icon shape="caret" style="transform: rotate(90deg);"></clr-icon>
          </clr-button>
        </clr-button-group>
      </div>
      <div>
        <span>{{s_view | date: 'MM.YY'}} - {{e_view | date: 'MM.YY'}}</span>
      </div>
    </div>

    <ng-template
      #customCellTemplate
      let-day="day"
      let-openDay="openDay"
      let-locale="locale"
      let-tooltipPlacement="tooltipPlacement"
      let-highlightDay="highlightDay"
      let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked"
      let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDelay="tooltipDelay"
      let-trackByEventId="trackByEventId"
    >
      <div class="cal-cell-top" style="min-height: 0;"
        [attr.aria-label]="{ day: day, locale: locale } | calendarA11y: 'monthCell'">
        <span aria-hidden="true">
          <span class="cal-day-number">{{day.date | calendarDate: 'monthViewDayNumber':locale}}</span>
        </span>
      </div>
      <div class="cal-events" *ngIf="day.events.length > 0">
        <div *ngFor="let event of day.events; trackBy: trackByEventId"
          (mouseenter)="highlightDay.emit({ event: event })"
          (mouseleave)="unhighlightDay.emit({ event: event })"
          [mwlCalendarTooltip]="event.title | calendarEventTitle: 'monthTooltip':event"
          [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event"
          [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
          (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
          [attr.aria-hidden]="{} | calendarA11y: 'hideMonthCellEvents'"
        >
          <ng-container [ngSwitch]="event.title">
            <span *ngSwitchCase="'Wartungen'" href="javascipt:void(0)" class="label label-purple clickable">
              <span class="text">
                <clr-icon shape="wartung" size="15"></clr-icon>
              </span>
              <span class="badge"> {{ event.meta.length }}</span>
            </span>
            <span *ngSwitchCase="'Prüfungen'" href="javascipt:void(0)" class="label label-blue clickable">
              <span class="text">
                <clr-icon shape="check-circle" size="20"></clr-icon>
              </span>
              <span class="badge"> {{ event.meta.length }}</span>
            </span>
            <span *ngSwitchCase="'Begehungen'" href="javascipt:void(0)" class="label label-orange clickable">
              <span class="text">
                <clr-icon shape="clock" size="15"></clr-icon>
              </span>
              <span class="badge"> {{ event.meta.length }}</span>
            </span>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template
      #customOpenDayEventsTemplate
      let-locale="locale"
      let-events="events"
      let-eventClicked="eventClicked"
      let-isOpen="isOpen"
      let-trackByEventId="trackByEventId"
    >
      <div class="cal-open-day-events" *ngIf="isOpen" role="application">
        <clr-tree>
          <clr-tree-node *ngFor="let group of events; trackBy: trackByEventId" [clrExpanded]="group.id == 'open'">
            <ng-container [ngSwitch]="group.title">
              <clr-icon *ngSwitchCase="'Wartungen'" shape="wartung" size="15"></clr-icon>
              <clr-icon *ngSwitchCase="'Prüfungen'" shape="check-circle" size="20"></clr-icon>
              <clr-icon *ngSwitchCase="'Begehungen'" shape="clock" size="15"></clr-icon>
            </ng-container>
            {{ group.title | mrTranslate }}
            <clr-tree-node *ngFor="let event of group.meta">
              <clr-icon [attr.shape]="'objekttyp'+event.meta.icon" size="20" style="color:black"></clr-icon>

              <mwl-calendar-event-title [event]="event" view="month"
                (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })"
                (mwlKeydownEnter)="eventClicked.emit({ event: event, sourceEvent: $event })"
                tabindex="0"
                [attr.aria-label]="{ event: event, locale: locale } | calendarA11y: 'eventDescription'"
              ></mwl-calendar-event-title>
            </clr-tree-node>
          </clr-tree-node>
        </clr-tree>
      </div>
    </ng-template>

    <mwl-calendar-month-view [viewDate]="currentDate" [events]="events" (dayClicked)="dayClicked($event.day)"
      [activeDayIsOpen]="activeDayIsOpen" (eventClicked)="handleEvent($event.event)"
      [ngClass]="{'activeDayIsOpen': activeDayIsOpen}" [cellTemplate]="customCellTemplate"
      [openDayEventsTemplate]="customOpenDayEventsTemplate" [locale]="translateService.currentLang">
    </mwl-calendar-month-view>

  </div>
  <div #dvwrapper id="wrapperdetailview" *ngIf="showdetailview"
    [ngClass]="{ sidenavenlarged, split50, hidedetailview }">
    <app-detailview [ostammid]="_ostammid" [progressbarenlarged]="progressbarenlarged" [expanded]="sidenavenlarged" [hidden]="hidedetailview"
      [split50]="split50" (enlarge)="enlargeSidenav($event)" (enlargePB)="enlargeProgressbar(true)" (reload)="reloadDetailview()"
      (progressData)="showProgressData($event)" (indexScrub)="handleScrub($event)" #detailView></app-detailview>
  </div>
</div>
<div id="progresstrigger" *ngIf="showprogressbar" [ngClass]="{'bottom': !progressbarenlarged}">
  <div class="wrap" (click)="enlargeProgressbar()">
    <clr-icon [ngStyle]="{'transform': progressbarenlarged ? 'rotate(180deg)' : 'rotate(0deg)'}" shape="caret"
      class="expandProgressbarButton cursor-pointer"></clr-icon>
  </div>
</div>
<footer *ngIf="showprogressbar && progressbarenlarged" id="wrapperprogressbars"
  [ngClass]="(!progressbarenlarged && !sidenavenlarged) ? (split50 ? 'shorter' :'short') : '' "
  class="clr-row nxm clr-align-items-center">
  <app-progressbar [dataIn]="progressbars" [indexScrub]="indexScrub" (enlargeProgressbar)="enlargeProgressbar(true)"
    (openDialogAt)="openOverlay($event)" style="display:inline-block; width:calc(100% - 35px);"></app-progressbar>
</footer>