import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  overlay: BehaviorSubject<any>;

  constructor() { 
    this.overlay = new BehaviorSubject<any>(null);
  }

  public setOverlay(overlaydata) {
    this.overlay.next(overlaydata);
  }

  public closeOverlay() {
    this.overlay.next(null);
  }

  public getOverlay() {
    return this.overlay;
  }

}
