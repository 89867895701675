import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'paintfilterlayer',
    pure: false,
    standalone: true

})
export class PaintFilterLayerPipe implements PipeTransform {
    transform(items: any[], paintfilterlayer: (item: any) => boolean): any {
        if (!items || !paintfilterlayer) {
            return items;
        }
        return items.filter(item => paintfilterlayer(item));
    }
}
