import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClarityModule, ClrModal } from '@clr/angular';
import { DAYS_OF_WEEK } from 'angular-calendar';
import dayjs from "dayjs";
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, firstValueFrom, map, of, startWith, switchMap, tap } from 'rxjs';
import { DeselectSingleSelectedDirective } from 'src/app/directives/deselect-single-selected.directive';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { Table } from 'src/app/models/ui/table';
import { CheckByCallbackFunctionPipe } from 'src/app/pipes/check-by-callback.pipe';
import { BackendLocaleDatePipe } from 'src/app/pipes/get-locale-date.pipe';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { BasicStoreService } from 'src/app/services/BasicStore/basic-store.service';
import { OverlayService } from 'src/app/services/Overlay/overlay.service';
import { db } from 'src/app/services/dexieDB';
import { WartungsPlanNode } from '../_components/_overlays/wartungsverwaltung/wartung.types';
import { HistorieTreeComponent } from '../_components/detailview/historie-tree/historie-tree.component';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';

@Component({
  selector: 'app-wartung',
  templateUrl: './wartung.component.html',
  styleUrls: ['./wartung.component.scss'],
  imports: [
    CommonModule, ClarityModule, DeselectSingleSelectedDirective, CheckByCallbackFunctionPipe, TablePrettyPrintPipe,
    HistorieTreeComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe, HideIDColumnsPipe
  ],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})
export class WartungComponent implements OnInit {
  private dataRefresh = new Subject<void>();

  protected wartungen$: Observable<Table<WartungsPlanNode>>;

  private today = dayjs().startOf('day');

  protected getEinheitText = (e: WartungsPlanNode['einheit']) => e == 'd' ? 'Tag' : 'Monat';

  public isRep = localStorage.getItem("repID") != null && localStorage.getItem("repDB") == localStorage.getItem("DB");;

  private _selectedOPlanNode: WartungsPlanNode;
  protected get selectedPlanNode(): WartungsPlanNode {
    return this._selectedOPlanNode;
  }
  protected set selectedPlanNode(value: WartungsPlanNode) {
    this.isHistoriePanelOpened = false;
    this._selectedOPlanNode = value;
  }

  protected selectedPlanNodes: WartungsPlanNode[] = [];
  protected isMulti = false;
  protected wartungsdate = BackendLocaleDatePipe.now;
  protected bemerkung: string;
  protected bemerkungen$: Observable<string[]>;

  protected isHistoriePanelOpened = false;
  protected isLoading = false;


  constructor(
    private apiService: APIService,
    private basicStore: BasicStoreService,
    private overlayService: OverlayService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    protected localeDate: BackendLocaleDatePipe,
  ) {
    this.bemerkungen$ = apiService.getWartungsBemerkungenList();
  }

  ngOnInit() {
    const { wartungen = null } = this.basicStore.getComponentStore('wartung') ?? {};
    this.wartungen$ = this.dataRefresh.pipe(
      startWith(wartungen === null),
      switchMap(reload => reload === false
        ? of(wartungen)
        : this.apiService.getWartungsPlan('table').pipe(
          tap(() => this.isLoading = true),
          map(({columns, rows}) => ({ columns, rows: this.findTermine(rows)})),
          tap(wartungen => this.basicStore.setComponentStore('wartung', { wartungen }, false)),
          tap(() => setTimeout(() => this.isLoading = false)),
        )
      ),
    );
  }

  private findTermine(objekte: WartungsPlanNode[]): WartungsPlanNode[] {
    return objekte.flatMap(o => {
      if (o.intervall <= 0) {
        o.intervall = 12;
        o.einheit = 'M';
      }
      let event_date = dayjs(o.intervallbeginn).startOf('day');

      if (o.letzteDatum) {
        const intervallDays = o.einheit == 'M' ? (o.intervall * 30.5) : o.intervall;
        const deltaIntervall = Math.ceil(intervallDays * 0.05);
        const ldate = dayjs(o.letzteDatum).add(deltaIntervall, 'days');
        do {
          event_date = dayjs(event_date).add(o.intervall, o.einheit ?? 'months')
        } while (event_date <= ldate); // ? rechnen mit  floor(diff(heute - beginn, einheiten) / intervall)
      }

      if (event_date.day() == DAYS_OF_WEEK.SUNDAY)
        event_date = event_date.add(1, 'd');
      else if (event_date.day() == DAYS_OF_WEEK.SATURDAY)
        event_date = event_date.add(2, 'd');

      o.nextDatum = event_date.format(BackendLocaleDatePipe.format);
      o.diffFromNow = event_date.diff(this.today, 'days');
      return o;
    });
  }

  protected openWartung(mode?: 'neu' | 'readonly') {
    this.overlayService.setOverlay({
      overlay: 'wartungsdialog',
      mode,
      objekt: this.selectedPlanNode,
      dataRefresh: this.dataRefresh
    });
  }

  protected openVerwaltung() {
    this.overlayService.setOverlay({
      overlay: 'wartungsVerwaltung',
      dataRefresh: this.dataRefresh
    });
  }

  protected clearMulti(opened: boolean) {
    if (opened) return;
    this.isMulti = false;
    this.selectedPlanNodes = [];
  }

  protected async sendMultiWartung(multiModal: ClrModal) {
    const sendObj = {
      datum: this.wartungsdate || BackendLocaleDatePipe.now,
      bemerkung: this.bemerkung,
      objekte: this.selectedPlanNodes
    };
    if(this.apiService.isRep) {
      var { success, error } :any = await db.SetMultiWartung(sendObj);
    } else {
      var { success, error } = await firstValueFrom(
      this.apiService.requestPOST('wartung/protokoll/multi', sendObj)
    );
    }
    if (success) {
      this.dataRefresh.next();
      multiModal.close();
      this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
    } else {
      console.log(error);
      this.toastr.error(this.mrTranslate.transform('Etwas ist schief gelaufen'));
    }
  }
}
