import {
  Component,
  Input,
  ElementRef,
  OnInit,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTooltipModule, ClrConditionalModule } from '@clr/angular';
import { NgFor, NgStyle, NgIf, DecimalPipe } from '@angular/common';
//import { ProfileViewerComponent } from '../../../blocks/profileviewer/profileviewer.component';

@Component({
    selector: 'app-progressbar',
    templateUrl: './progressbar.component.html',
    styleUrls: ['./progressbar.component.scss'],
    imports: [NgFor, NgStyle, ClrStopEscapePropagationDirective, ClrPopoverHostDirective, ClrTooltipModule, ClrConditionalModule, NgIf, DecimalPipe],
    standalone: true
})
export class ProgressbarComponent implements OnInit {
  progressbarwidth: number;
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  data;
  dist: number;
  bars: any[] = [];
  ratio: number = 1;
  _ostammid: number;

  _once: boolean;
  barlegend: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  @Input('dataIn') set dataIn(dat: any) {
    if (dat) {
      if (JSON.stringify(dat.zeile) != JSON.stringify(this.data)) {
        this.data = dat.zeile;
        this._ostammid = dat.stammid;
        this._once = dat.once;
        if (document.documentElement.clientWidth > 768) {
          this.barlegend = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        } else {
          this.barlegend = [0, 1, 2, 3, 4];
        }
        this.createBars(dat);
      }
    }
  }
  @Input('indexScrub') indexScrub: number;
  @Output() enlargeProgressbar = new EventEmitter<Boolean>();
  @Output() openDialogAt = new EventEmitter<Object>();
  progresses: any[];
  constructor(
  ) {

  }

  ngOnInit() {
  }

  openProfileAt(startmeter) {

  }

  createBars(dat :any) {
    this.bars = [];
    this.progressbarwidth = this.wrapper.nativeElement.offsetWidth;

    if (this.data && this.data.bars && this.data.dist) {

      this.ratio = dat.once ? 1 : this.progressbarwidth / this.data.dist;
      this.dist = this.data.dist;
      this.data.bars.forEach((bar: any) => {
        bar.start_m = bar.start;
        bar.end_m = bar.end;
        bar.start *= this.ratio;
        bar.end = bar.end_m * this.ratio;

        let color;
        let alpha: number = 0.8;
        switch (bar.fk) {
          case 1:
            color = 'rgba(255,0,0,' + alpha + ')';
            break;
          case 2:
            color = 'rgba(255,255,0,' + alpha + ')';
            break;
          case 3:
            color = 'rgba(0,255,0,' + alpha + ')';
            break;
          case 4:
            color = 'rgba(0,0,255,' + alpha + ')';
            break;
          default:
            color = 'rgba(0,0,0,' + alpha + ')';
            break;
        }
        bar.color = color;
        let s_m: number, e_m: number;
        if(dat.once) {
          bar.start_m = bar.start_m /(this.progressbarwidth / this.data.dist);
          bar.end_m = bar.end_m /(this.progressbarwidth / this.data.dist);
        }
        s_m = bar.start_m.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        e_m = bar.end_m.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        bar.metastring = `
          <div class="text-left">
            <strong>Fehlerklasse ${bar.fk}</strong><br/>
            ${s_m}m - ${e_m}m<br/>
            Mangel: ${bar.meta.mangel ? bar.meta.mangel : ''}<br/>
            Lage: ${bar.meta.lage ? bar.meta.lage : ''}<br/>
            Umfang: ${bar.meta.umfang ? bar.meta.umfang : ''}<br/>
            Mengeneinheit: ${bar.meta.mengeneinheit ? bar.meta.mengeneinheit : ''}<br/>
            Instandsetzungshinweis: ${bar.meta.instandsetzung ? bar.meta.instandsetzung : ''}
          </div>`;
        this.bars.push(bar);

      });
      dat.once = true;
    }

  }
}
