//#region Imports
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClarityModule, ClrDatagrid, ClrForm, ClrLoadingState } from '@clr/angular';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, concat, debounceTime, distinctUntilChanged, filter, firstValueFrom, map, merge, of, scan, skip, switchMap, take,
  tap, withLatestFrom,} from 'rxjs';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { WindowMediaQueryDirective } from 'src/app/directives/window-media-query.directive';
import { cached, precedeWith } from 'src/app/misc/operators';
import { Table } from 'src/app/models/ui/table';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { CustomValidators } from 'src/app/shared/classes/custom-validators';
import { FormGroupOfType } from 'src/app/types/shared';
import { SOTYP_Map } from '../../../../../models/api/SOTYP';
import { HilfeBeschreibung, HilfeBeschreibungModalComponent } from '../../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component';
import { PreviewThumbnailsComponent } from '../../../_shared/preview-thumbnails/preview-thumbnails.component';
import { HideSrAktivPipe } from './hide-sr-aktiv.pipe';
import { WerteColNamesPipe } from './werte-col-names.pipe';
import { WerteRowValuesPipe } from './werte-row-values.pipe';
import { ZaehlerEinheitenComponent } from './zaehler-einheiten/zaehler-einheiten.component';
import { ZArt, ZArtLongNames, ZArtOptions, ZType, ZählerMode } from './zaehler.enums';
import { Genauigkeit, TOL_FIELDS, ZählerEinheit, ZählerName, ZählerSkizze, ZählerWert } from './zaehler.types';
//#endregion
enum SortDirection {
  UP = -1,
  DOWN = 1
};
type SortDirectionName = keyof typeof SortDirection;
type SkizzenMap = Map<string, ZählerSkizze>;

const DELAY_TIME = 500;

@Component({
  selector: 'app-zaehlerverwaltung',
  templateUrl: './zaehlerverwaltung.component.html',
  styleUrls: ['./zaehlerverwaltung.component.scss'],
  imports: [
    CommonModule, ClarityModule,
    HilfeBeschreibungModalComponent,
    PreviewThumbnailsComponent,
    NgxSliderModule,
    HideSrAktivPipe,
    WerteColNamesPipe,
    WerteRowValuesPipe,
    AutofocusDirective,
    WindowMediaQueryDirective,
    ZaehlerEinheitenComponent,
    MrTranslatePipe,
    FormsModule,
    ReactiveFormsModule,
    HideIDColumnsPipe,
    TablePrettyPrintPipe
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true
})
export class ZaehlerverwaltungComponent implements AfterViewInit {
  public readonly closedialog = new BehaviorSubject(false);

  protected readonly objekte$: Observable<SOTYP_Map>;
  protected readonly meh$: Observable<ZählerEinheit[]>;
  protected readonly zaehler_names$: Observable<Table<ZählerName>>;
  protected readonly zaehler_werte$: Observable<Table<ZählerWert>>;

  private readonly zNames_Codes$: Observable<ZählerName['Code'][]>;
  protected readonly zNames_Skizzen_Map$: Observable<SkizzenMap>;
  private readonly previewSkizzeSubject$ = new Subject<ZählerSkizze>;

  protected readonly zWerte_Codes$: Observable<ZählerWert['Code'][]>;

  private readonly zWerte_MAX_Sort$: Observable<ZählerWert['I_SORT']>;
  private readonly zWerte_Rows$: Observable<ZählerWert[]>;

  protected otypid: number;
  protected readonly otypidSubject$ = new Subject<void>();

  protected selectedName: ZählerName;
  protected readonly selectedNameSubject$ = new Subject<void>();

  protected copiedWerte: ZählerWert[] = [];
  protected selectedWerte: ZählerWert[] = [];
  private get singleSelectedWert() {
    return this.selectedWerte[0] ;
  }
  private set singleSelectedWert(value: ZählerWert) {
    if (value) this.selectedWerte = [value];
    else this.wertGrid?.selection.clearSelection();
  }
  protected wertIndex: number;
  protected readonly wertSortSubject$ = new Subject<SortDirection>();

  protected readonly mehRefresh$ = new Subject<void>();
  private readonly namesRefresh$ = new Subject<void>();
  private readonly werteRefresh$ = new Subject<void>();

  protected readonly trackByID = (_: number, row: ZählerName | ZählerWert) => row.ID;

  protected mode: ZählerMode;

  @ViewChildren(PreviewThumbnailsComponent)
  private previews: QueryList<PreviewThumbnailsComponent>;

  private get preview_aktiv() {
    return this.previews.find(preview => !preview.disabled);
  }

  private get preview_readonly() {
    return this.previews.find(preview => preview.disabled);
  }

  @ViewChild(HilfeBeschreibungModalComponent, { static: true })
  private hilfeModal: HilfeBeschreibungModalComponent;

  @ViewChild('zNamesContainer', { read: ElementRef, static: false })
  private zNamesContainer: ElementRef<HTMLElement>;
  @ViewChild('zWerteContainer', { read: ElementRef, static: false })
  private zWerteContainer: ElementRef<HTMLElement>;

  @ViewChild('wertForm', { read: ClrForm, static: false })
  private wertClrForm: ClrForm;
  @ViewChild('wertGrid', { read: ClrDatagrid, static: false })
  private wertGrid: ClrDatagrid;

  protected isOpenModalZaehlerName = false;
  protected isOpenModalZaehlerWert = false;
  protected isOpenModalZaehlerEinheit = false;
  protected initializeEinheiten = false;
  protected wertState = ClrLoadingState.DEFAULT;
  protected bildChanged: boolean;
  private initiatedNameForm: boolean;

  protected moveUpDownState: ClrLoadingState;
  protected enableMove: Record<SortDirectionName, boolean> = {
    UP: false,
    DOWN: false
  };

  protected LOADING_STATE = ClrLoadingState;
  protected SORT_DIRECTION = SortDirection;

  public set data(dataIn: any) {
    this.otypid = dataIn.otypid;
    this.mode = dataIn.mode ?? ZählerMode.ZAEHLER;
    // this.dataRefresh = dataIn.dataRefresh;
  }

  protected readonly nameGroupForm = new FormGroupOfType<ZählerName>({
    ID: new FormControl<number>(null),
    Code: new FormControl<string>(null, {
      updateOn: 'blur',
      // ! Set InTableValidator based on otypid & selectedName
      validators: Validators.required
    }),
    Bezeichnung: new FormControl<string>(null),
    SRG: new FormControl<boolean>(false, { nonNullable: true }),
    SR100: new FormControl<boolean>(false, { nonNullable: true }),
    Bild: new FormControl<string>(null)
  });

  protected readonly wertGroupForm = new FormGroupOfType<ZählerWert>({
    ID: new FormControl<number>(null),
    Code: new FormControl<string>(null, {
      updateOn: 'blur',
      // ! Set InTableValidator based on selectedName & selectedWert
      validators: Validators.required
    }),
    Bezeichnung: new FormControl<string>(null),
    Soll: new FormControl<number>(null),

    aktivSR100: new FormControl<boolean>(true, { nonNullable: true }),
    SR100plus: new FormControl<number>(null),
    SR100minus: new FormControl<number>(null),

    aktivSRlimm: new FormControl<boolean>(true, { nonNullable: true }),
    SRlimplus: new FormControl<number>(null),
    SRlimminus: new FormControl<number>(null),

    aktivSRG: new FormControl<boolean>(true, { nonNullable: true }),
    SRGplus: new FormControl<number>(null),
    SRGminus: new FormControl<number>(null),

    ZEinheit: new FormControl<number>(null),
    I_SORT: new FormControl<number>(null),

    SGenau: new FormControl<Genauigkeit>(0, { nonNullable: true }),
    TGenau: new FormControl<Genauigkeit>(0, { nonNullable: true }),

    ZArt: new FormControl<ZArt>(ZArt.Numerisch, { nonNullable: true }),
    isHeader: new FormControl<boolean>(false),
  });

  private defaultEinheit: ZählerEinheit;
  protected readonly ZType_Header = ZType.Header;
  protected readonly ZArt_Options = ZArtOptions;
  protected readonly ZArt_Map = ZArtLongNames;
  protected readonly noSort = () => 0;

  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    private toastr: ToastrService
  ) {
    //#region init readonly Observables
    //#region names Observables
    this.objekte$ = apiService.getSOTYP().pipe(tap(map => {
      if (this.otypid) return;
      // selects first otypid if not provided by parent component in set data
      for (const id in map) {
        this.otypid = +id;
        this.otypidSubject$.next();
        break;
      }
    }));

    this.meh$ = merge(this.otypidSubject$, this.mehRefresh$).pipe(
      switchMap(() =>
        apiService.getZaehlerWerteEinheiten(this.otypid)),
      map((table: Table<ZählerEinheit>) => table.rows),
      //? was wenn noch keine Einheiten erstellt wurden
      tap(meh => this.defaultEinheit = meh?.[0])
    );

    const zNames_API$ = merge(
      this.otypidSubject$.pipe(
        // after otypidChange clear selectedName
        tap(() => this.selectedName = null),
      ),
      this.namesRefresh$
    ).pipe(
      switchMap(() => apiService.getZaehlerNames(this.otypid, this.mode)),
      // shareReplay API result to zaehler_names and zNames_Codes
      cached<Table<ZählerName>>(),
    );

    this.zaehler_names$ = zNames_API$.pipe(
      // first emit {}, then actual table, for grid resize
      precedeWith({} as Table<ZählerName>),
      tap(table => {
        if (table.rows && this.selectedName?.ID) setTimeout(() => {
          this.zNamesContainer.nativeElement
            .querySelector("#i-" + this.selectedName.ID)
            ?.scrollIntoView({
              behavior: 'auto',
              block: 'nearest'
            });
        });
      })
    );

    this.zNames_Codes$ = zNames_API$.pipe(
      // from all names obj get existing names to validate new name
      map((table) => table.rows.map((row) => row.Code)),
    );

    this.zNames_Skizzen_Map$ = concat(
      zNames_API$.pipe(take(1), map(table => [null as ZählerSkizze, table] as const)),
      this.previewSkizzeSubject$.pipe(withLatestFrom(zNames_API$))
    ).pipe(
      map(([neu, table]) => {
        const existingSkizzen = new Map<string, ZählerSkizze>();
        for (const { Bild64, Bild } of table.rows) {
          if (Bild64)
            existingSkizzen.set(Bild, { Bild64, Bild });
        }
        if (neu) existingSkizzen.set(neu.Bild, neu);
        return existingSkizzen;
      }),
      cached<SkizzenMap>(),
    );

    const selectedNameChange$ = this.selectedNameSubject$.pipe(
      // ignore { ID }, that are emitted after edit/new for select in table
      filter(() => !(this.selectedName && !this.selectedName.Code)),
      // after namesRefresh emits same ID, no need to reload Werte
      distinctUntilChanged(undefined, () => this.selectedName?.ID),
      // after selectedNameChange clear selectedWert and bild
      tap(() => {
        this.singleSelectedWert = null;
      })
    );

    selectedNameChange$.pipe(
      // neue/gelöschte/ausgewählte Name
      takeUntilDestroyed()
    ).subscribe(() => this.setBildData(this.preview_readonly, this.selectedName));

    this.namesRefresh$.pipe(
      // selectedNameChange$ wird nicht ausgeführt, wenn Bild in desselben Namen geändert wurde
      filter(() => this.bildChanged),
      takeUntilDestroyed()
    ).subscribe(() => {
      this.preview_readonly.rawPreviewThumbnails = this.preview_aktiv.rawPreviewThumbnails;
    });
    //#endregion

    //#region werte Observables
    const zWerte_API$ = merge(selectedNameChange$, this.werteRefresh$).pipe(
      switchMap(() => this.selectedName?.ID
        ? apiService.getZaehlerWerte(this.selectedName.ID)
        : of({} as Table<ZählerWert>)
      ),
      // shareReplay API result to zaehler_werte and zWerte_Codes
      cached<Table<ZählerWert>>(),
    );

    this.zaehler_werte$ = zWerte_API$.pipe(
      // first emit {}, then actual table, for grid resize
      precedeWith({} as Table<ZählerWert>),
      tap(table => {
        const id = this.singleSelectedWert?.ID;
        if (table.rows && id) setTimeout(() => {
          this.zWerteContainer.nativeElement
            .querySelector("#i-" + id)
            ?.scrollIntoView({
              behavior: 'auto',
              block: 'nearest'
            });
        });
      })
    );

    this.zWerte_Codes$ = zWerte_API$.pipe(
      // from all names obj get existing names to validate new name
      map((table) => table.rows?.map((row) => row.Code)),
    );

    this.zWerte_Rows$ = zWerte_API$.pipe(
      map((table) => table.rows),
    );

    this.zWerte_MAX_Sort$ = zWerte_API$.pipe(
      map(table => table.rows?.reduce(
        (max, {I_SORT}) => I_SORT > max ? I_SORT : max,
        /* Starting max with: */ 0
      ) /* Nächste mögliche I_SORT: */ + 1),
    );
    //#endregion
    //#endregion
    this.catchWerteSortedChanges();
    this.catchFormChanges();
  }

  private catchWerteSortedChanges() {
    // list der Werten, wird für Sortierung benutzt und ist ein Trigger für wertSortChange$
    this.zWerte_Rows$.pipe(
      // 'switchMap' startet wertSortChange$ neu, wenn eine neue Liste kommt
      switchMap(list => this.wertSortSubject$.pipe(

        // sortiert die Liste im Grid in Template
        tap(direction => {
          this.checkEnableUpDown(list);
          if (!this.enableMove[SortDirection[direction]]) return;

          const selected = this.singleSelectedWert;
          const swapWith = list.at(list.indexOf(selected) + direction);

          const tmpSort = swapWith.I_SORT;

          swapWith.I_SORT = selected.I_SORT;
          selected.I_SORT = tmpSort;
          list.sort((a, b) => a.I_SORT - b.I_SORT);
        }),

        // zählt, wie viele Zeilen geändert wurden
        // wird nach jeder Refresh (durch switchMap) neu erstellt mit 0
        scan<SortDirection, number>(
          (countToChange, direction) => countToChange - direction,
            /* starting CountToChange: */ 0
        ),

        // wartet, bis es keine Änderungen eine Sekunde lang gibt
        debounceTime(1000),

        // keine Änderungen zu speichern
        filter(countToChange => countToChange != 0),

        // API-Aufruf zur Speicherung der geänderten Werte
        switchMap(countToChange => {
          this.moveUpDownState = ClrLoadingState.LOADING;
          const selectedIndex = list.indexOf(this.singleSelectedWert);
          const werteToChange = countToChange > 0
            ? list.slice(selectedIndex, selectedIndex + countToChange + 1)
            : list.slice(selectedIndex + countToChange, selectedIndex + 1);

          return this.apiService.setZaehlerSorting(werteToChange);
        })
      )),
      // beendet das Observable, wenn die Komponente zerstört wird
      takeUntilDestroyed()

    ).subscribe(({ success }) => {
      // Ergebnisverarbeitung des API-Aufrufs
      if (success) {
        this.moveUpDownState = ClrLoadingState.SUCCESS;
        setTimeout(() => this.moveUpDownState = ClrLoadingState.DEFAULT, DELAY_TIME * 3);

      } else {
        this.moveUpDownState = ClrLoadingState.ERROR;
        this.toastr.error(
          this.mrTranslate.transform('Etwas ist schief gelaufen') + "!  " +
          this.mrTranslate.transform("Bitte versuchen Sie nochmal")
        );
      }
      this.werteRefresh$.next();
    });
  }

  private catchFormChanges() {
    this.nameGroupForm.get('Bild').valueChanges.pipe(
      filter(() => this.initiatedNameForm),
      withLatestFrom(this.zNames_Skizzen_Map$),
      takeUntilDestroyed(),
    ).subscribe(([name, allSkizzen]) => {
      const skizze = name
        ? allSkizzen.get(name)
        : null;
      this.bildChanged = true;
      this.setBildData(this.preview_aktiv, skizze);
    });

    this.wertGroupForm.get('isHeader').valueChanges.pipe(
      takeUntilDestroyed()
    ).subscribe(isHeader => {
      const artCtrl = this.wertGroupForm.get('ZArt');
      if (!isHeader && !artCtrl.value)
        artCtrl.setValue(ZArt.Numerisch, { emitEvent: false });
      this.toggleRequiredControls(artCtrl.value, isHeader);
    });

    this.wertGroupForm.get('ZArt').valueChanges.pipe(
      takeUntilDestroyed()
    ).subscribe(art => {
      this.toggleRequiredControls(art);
    });
  }

  ngAfterViewInit() {
    if (this.otypid)
      // Emit new value, if otypid was passed to set data from parent component
      // after ViewInit, because subscription to otypidChange$ happens onViewInit
      this.otypidSubject$.next();
  }
  protected addItem(mode: 'name' | 'wert') {
    switch (mode) {
      case 'name':
        this.selectedName = null;
        break;
      case 'wert':
        this.singleSelectedWert = null;
        break;
    }
    this.editItem(mode);
  }

  protected async editItem(mode: 'name' | 'wert') {
    switch (mode) {
      case 'name':
        this.initiatedNameForm = false;
        this.nameGroupForm.reset();
        this.nameGroupForm.get('Code').setValidators([
          Validators.required,
          CustomValidators.InTableValidator(
            await firstValueFrom(this.zNames_Codes$),
            true,
            this.selectedName?.Code
          )
        ]);
        this.nameGroupForm.patchValue(this.selectedName);
        this.isOpenModalZaehlerName = true;
        this.bildChanged = false;
        this.initiatedNameForm = true;
        this.setBildData(this.preview_aktiv, this.selectedName);
        break;
      case 'wert':
        this.wertGroupForm.reset();
        this.wertGroupForm.get('Code').setValidators([
          Validators.required,
          CustomValidators.InTableValidator(
            await firstValueFrom(this.zWerte_Codes$),
            true,
            this.singleSelectedWert?.Code
          )
        ]);
        this.wertGroupForm.patchValue({
          ...this.singleSelectedWert,
          ZEinheit: this.singleSelectedWert?.ZEinheit ?? this.defaultEinheit?.ID,
          isHeader: this.singleSelectedWert?.ZType == ZType.Header,
          I_SORT: this.singleSelectedWert?.I_SORT
            ?? await firstValueFrom(this.zWerte_MAX_Sort$),
        });

        // auf kleinen Bildschirmen sliders laden zu schnell und machen die Form dirty
        // TODO: Alternative? in Angular 17 - @defer block in HTML
        this.wertGroupForm.get('TGenau').valueChanges.pipe(
          take(2),
          skip(1)
        ).subscribe(_ => this.wertGroupForm.markAsPristine());

        this.isOpenModalZaehlerWert = true;
        break;
    }
  }

  protected async saveItem(mode: 'name' | 'wert') {
    switch (mode) {
      case 'name': {
        if (this.nameGroupForm.invalid) return;
        /* wenn neue Name erstellt wurde */
        if (!this.selectedName) this.bildChanged = false;

        const changed = this.nameGroupForm.dirty || this.bildChanged;
        let value: ZählerName = this.nameGroupForm.getRawValue();

        const skizze = value.Bild
          ? (await firstValueFrom(this.zNames_Skizzen_Map$)).get(value.Bild)
          : { Bild: null, Bild64: null };

        value = {...value, ...skizze};

        const { success, error = null, ID = null } = !changed
          ? { success: true }
          : await firstValueFrom(
            this.apiService.setZaehlerName(this.otypid, this.mode, value)
          ) ?? {};

        if (success) {
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
          this.isOpenModalZaehlerName = false;

          if (changed) {
            this.selectedName = { ID } as ZählerName; // same or new ID
            this.namesRefresh$.next();
          }
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
        break;
      }
      case 'wert': {
        const { success, error, ID, pristine } = await this.saveWert() ?? {};
        if (success) {
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
          this.isOpenModalZaehlerWert = false;

          if (!pristine) {
            this.singleSelectedWert = { ID } as ZählerWert; // same or new ID
            this.werteRefresh$.next();
          }
        } else {
          this.wertClrForm.markAsTouched();
          this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
        }
        break;
      }
    }
  }
  protected nextWert(direction: 1 | -1) {
    this.wertState = ClrLoadingState.LOADING;
    this.nextWertAsync(direction);
  }

  private async nextWertAsync(direction: 1 | -1) {
    const { success, error, pristine } = await this.saveWert() ?? {};

    if (success) {
      /** to give a loadingState time to render */
      if (!pristine /* changed */) {
        setTimeout(() =>
          this.wertState = ClrLoadingState.SUCCESS, DELAY_TIME);
        setTimeout(() =>
          this.wertState = ClrLoadingState.DEFAULT, DELAY_TIME * 3);
      } else this.wertState = ClrLoadingState.DEFAULT;

      this.werteRefresh$.next();

      // * get Next - zWerte_API$ has old table, before saving, which is not a problem
      // since previous or next Wert could not have been changed, only current
      // Alternatively: pipe(skip(1)) to skip cached value and wait for a new to emit
      const list = await firstValueFrom(this.zWerte_Rows$/* .pipe(skip(1)) */);
      this.wertIndex += direction;
      this.singleSelectedWert = list.at(this.wertIndex);
      this.editItem('wert');

    } else {
      this.wertState = ClrLoadingState.ERROR;
      this.wertClrForm.markAsTouched();
      this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
    }
  }

  private async saveWert() {
    if (this.wertGroupForm.invalid)
      return { success: false, error: 'Form is invalid' };
    if (this.wertGroupForm.pristine)
      return { success: true, ID: this.singleSelectedWert.ID, pristine: true };

    const value: ZählerWert = this.clearFields(
      this.wertGroupForm.getRawValue()
    );

    return firstValueFrom(this.apiService.setZaehlerWert(this.selectedName.ID, value));
  }

  private clearFields(value: ZählerWert) {
    if (value.isHeader) {
      for (const key in value) {
        if (!['ID', 'Code', 'Bezeichnung', 'I_SORT'].includes(key))
          value[key] = null;
      }
      value.ZType = ZType.Header;
    } else {
      value.ZArt ??= ZArt.JaNein;
      const mode = ZArtOptions[value.ZArt];
      value.ZType = ZType.NotHeader;

      if (mode.soll != 'Num' && mode.minus != 'Num' && mode.plus != 'Num')
        value.SGenau = value.TGenau = value.ZEinheit = null;

      if (mode.soll != 'Num') value.Soll = null;

      if (mode.minus == 'Num' || mode.plus == 'Num') {
        value.aktivSR100 ??= false;
        value.aktivSRlimm ??= false;
        value.aktivSRG ??= false;
        if (!value.aktivSR100) value.SR100minus = value.SR100plus = null;
        if (!value.aktivSRlimm) value.SRlimminus = value.SRlimplus = null;
        if (!value.aktivSRG) value.SRGminus = value.SRGplus = null;
      }

      if (mode.minus != 'Num' || mode.plus != 'Num')
        for (const key in TOL_FIELDS) {
          if (
            key.includes('minus') && mode.minus != 'Num'
            || key.includes('plus') && mode.plus != 'Num'
            || key.includes('aktiv') && mode.minus != 'Num' && mode.plus != 'Num'
          ) value[key] = null;
        }
    }

    delete value.isHeader;
    return value;
  }


  private toggleRequiredControls(art: ZArt, isHeader?: boolean) {
    const sollCtrl = this.wertGroupForm.get('Soll');
    if (!isHeader && ZArtOptions[art]?.soll == 'Num')
      sollCtrl.addValidators(Validators.required);
    else sollCtrl.removeValidators(Validators.required);
    sollCtrl.updateValueAndValidity();

    if (!this.defaultEinheit?.ID) {
      const mehCtrl = this.wertGroupForm.get('ZEinheit');
      if (!isHeader && art != ZArt.JaNein)
        mehCtrl.addValidators(Validators.required);
      else mehCtrl.removeValidators(Validators.required);
      mehCtrl.updateValueAndValidity();
    }
  }

  protected async deleteItem(mode: 'name' | 'wert') {
    const IDs = mode == 'name' ? [this.selectedName.ID] : this.selectedWerte.map(({ID}) => ID);

    const text = 'Sind Sie sicher, dass Sie diese Zeile löschen wollen?';
    if (!confirm(this.mrTranslate.transform(text))) return;

    const result = await Promise.all(IDs.map((ID) =>
      firstValueFrom(this.apiService.deleteZahlerItem(mode, ID))
    ));
    const success = result.every(({success}) => !!success);
    if (success) {
      this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));
      switch (mode) {
        case 'name':
          this.selectedName = null;
          this.bildChanged = false;
          this.namesRefresh$.next();
          break;
        case 'wert':
          this.singleSelectedWert = null;
          this.werteRefresh$.next();
          break;
      }
    } else this.toastr.error(this.mrTranslate.transform('Etwas ist schief gelaufen'));
  }

  protected async copyItem(mode: 'name' | 'wert') {
    const CopyText = this.mrTranslate.transform('Kopie');
    switch (mode) {
      case 'name': {
        const sendObj = {
          Code: this.selectedName.Code,
          CopyText
        };
        const { success, error = null, ID = null } = await firstValueFrom(
            this.apiService.copyZaehlerName(this.selectedName.ID, sendObj)
          ) ?? {};

        if (success) {
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
          this.selectedName = { ID } as ZählerName; // same or new ID
          this.bildChanged = false;
          this.namesRefresh$.next();
        } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
        break;
      }
      case 'wert': {
        const IDs = this.copiedWerte.map(wert => wert.ID);

        const { success, error } = await firstValueFrom(
          this.apiService.copyZaehlerWert(this.selectedName.ID, { IDs, CopyText })
        ) ?? {};

        if (success) {
          this.toastr.success(this.mrTranslate.transform('Erfolgreich gespeichert!'));
          this.selectedWerte = this.copiedWerte = [];
          this.werteRefresh$.next();
        } else {
          this.wertClrForm.markAsTouched();
          this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
        }
        break;
      }
    }
  }

  private setBildData(
    preview: PreviewThumbnailsComponent,
    skizze: ZählerSkizze
  ) {
    preview.clearPreview();
    if (skizze?.Bild64) {
      const bild = {
        name: skizze.Bild || '.png',
        base64: skizze.Bild64
      };
      // const setSaved = this.bildChanged ? false : true;
      preview.unpackFiles([ bild ], !this.bildChanged);
    }
  }

  protected async setBildInForm(name: string) {
    let skizze = null;

    if (name) {
      const bilder = await this.preview_aktiv.packUnsavedFiles();
      skizze = { Bild: name, Bild64: bilder[0].base64 };
    }
    this.bildChanged = true;
    this.previewSkizzeSubject$.next(skizze);
    this.nameGroupForm.get('Bild').setValue(name, { emitEvent: false });
  }

  protected openHelper() {
    this.hilfeModal.item = { ...this.selectedName };
  }

  protected async saveHelper(sendObj: HilfeBeschreibung) {
    const { success, error } = await firstValueFrom(
      this.apiService.setZaehlerHelper(this.selectedName.ID, sendObj)
    );

    if (success) {
      this.toastr.success(
        this.mrTranslate.transform('Erfolgreich gespeichert!')
      );
      this.hilfeModal.item = null;
      this.namesRefresh$.next();
    } else this.toastr.error(error, this.mrTranslate.transform('Etwas ist schief gelaufen'));
  }

  protected close() {
    this.closedialog.next(true);
  }

  protected checkEnableUpDown(list: ZählerWert[]) {
    const selectedIndex = list.indexOf(this.singleSelectedWert);
    if (this.selectedWerte.length == 1)
      this.enableMove = {
        UP: selectedIndex != 0,
        DOWN: selectedIndex != list.length - 1,
      };
    else this.enableMove = { UP: false, DOWN: false };
  }
}