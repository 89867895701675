import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinieDrehungService {

  constructor() { }

  /**
   * @description Initialisieren Summe für Drehung
   * @example
   * this.drehung.summe = { startmeter, endmeter };
   * @example 
   * this.drehung.summe = this.stammTab.linieValues;
   */
  public set summe({startmeter, endmeter}: {startmeter: number, endmeter: number}) {
    this._summe = startmeter + endmeter;
  }
  /**
   * @description Konvertation (Summe - Wert)
   * @see LinieDrehungPipe.transform()
   * @example
   * protected drehung: LinieDrehungService,
   * "value | drehung: drehung.summe" 
   * "setValue(drehungPipe.back($event.target.value, drehung.summe))" 
   */
  public get summe(): number {
    return this._summe;
  }
  private _summe: number;

  /**
   * Für Speichern der Einstellung
   * @example [(ngModel)]="drehung.isOn"
   * @example 
   * protected drehung: LinieDrehungService,
   * *ngIf="drehung.isOn"
   */
  public isOn: boolean = false;
}
