export function isNumber(value) {
  return typeof value === "number" && isFinite(value);
}

export function duplicate(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function isEmptyObj(obj) {
  return (
    obj &&
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function stringToUint(str) {
  var string = btoa(encodeURIComponent(str)),
      charList = string.split(''),
      uintArray = [];
  for (var i = 0; i < charList.length; i++) {
      uintArray.push(charList[i].charCodeAt(0));
  }
  return new Uint8Array(uintArray);
}

export function uintToString(uintArray) {
  var encodedString = String.fromCharCode.apply(null, uintArray),
      decodedString = decodeURIComponent(escape(atob(encodedString)));
  return decodedString;
}

export function getByteArray(file) {
  let fileReader = new FileReader();
  return new Promise(function (resolve, reject) {
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = function (ev) {
      const array = new Uint8Array(<ArrayBuffer>ev.target.result);
      const fileByteArray = [];
      for (let i = 0; i < array.length; i++) {
        fileByteArray.push(array[i]);
      }
      resolve(fileByteArray);  // successful
    }
    fileReader.onerror = reject; // call reject if error
  })
}

export function isEqual(obj1, obj2) {
  var props1 = Object.getOwnPropertyNames(obj1);
  var props2 = Object.getOwnPropertyNames(obj2);
  if (props1.length != props2.length) {
    return false;
  }
  for (var i = 0; i < props1.length; i++) {
    let val1 = obj1[props1[i]];
    let val2 = obj2[props1[i]];
    let isObjects = isObject(val1) && isObject(val2);
    if (isObjects && !isEqual(val1, val2) || !isObjects && val1 !== val2) {
      return false;
    }
  }
  return true;
}
function isObject(object) {
  return object != null && typeof object === 'object';
}


export function arrayCompare(_arr1, _arr2) {
  if (
    !Array.isArray(_arr1)
    || !Array.isArray(_arr2)
    || _arr1.length !== _arr2.length
  ) {
    return false;
  }

  // .concat() to not mutate arguments
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}


const unitAbbreviationMap: { [key: string]: string } = {
  "Millimeter": "mm",
  "Inch": "in",
  "Meter": "m",
  "Miles": "mi",
  "Feet": "ft",
  "Mile Feet": "mileft"
};

const unitConversionMap: { [key: string]: number } = {
  "mm": 1,
  "in": 25.4,
  "m": 1000,
  "mi": 1609344,
  "ft": 304.8,
  "mileft": 1609344 * 5280
};

export const measurementUnitItems: string[] = ["Millimeter", "Inch"];
export const lengthUnitItems: string[] = ["Meter", "Miles", "Feet", "Mile Feet"];

export function addUnitToValue(value: number, unitType: number, unitTypeValue: number): string {
  let unit = getUnit(unitType, unitTypeValue);

  // let unit: string;
  // if (unitType === 0) {
  //   unit = measurementUnitItems[unitTypeValue];
  // } else {
  //   unit = lengthUnitItems[unitTypeValue];
  // }

  return `${value} ${unitAbbreviationMap[unit]}`;
}

export function addUnitAbbreviationToText(text: string, unitType: number, unitTypeValue: number): string {
  let unit = getUnit(unitType, unitTypeValue);

  // if (unitType === 0) {
  //   unit = measurementUnitItems[unitTypeValue];
  // } else {
  //   unit = lengthUnitItems[unitTypeValue];
  // }

  return `${text} [${unitAbbreviationMap[unit]}]`;
}

export function addUnitToText(text: string, unitType: number, unitTypeValue: number): string {
  let unit = getUnit(unitType, unitTypeValue);
  // if (unitType === 0) {
  //   unit = measurementUnitItems[unitTypeValue];
  // } else {
  //   unit = lengthUnitItems[unitTypeValue];
  // }

  return `${text} [${unit}]`;
}
export const lengthUnitColumns = ['leistungslänge', `gleislänge`,'startmeter', 'endmeter', 'schienenhöhe', 'länge'];
export const measurementUnitColumns = ['dämmung', 'radius'];

export const UnitLength = 1;
export const UnitMeasurement = 0;
function getUnit(unitType: number, unitTypeValue: number) {
  let unit: string;
  if (unitType === 0) {
    unit = measurementUnitItems[unitTypeValue] ?? measurementUnitItems[0];
  } else {
    unit = lengthUnitItems[unitTypeValue] ?? lengthUnitItems[0];
  }
  return unit;
}
export function convertValueToUnit(value: number, unitType: number, fromUnitTypeValue: number, toUnitTypeValue: number, addUnitToValue: boolean): number {
  let fromUnit = getUnit(unitType, fromUnitTypeValue);
  let toUnit = getUnit(unitType, toUnitTypeValue);
  const fromConversionFactor = unitConversionMap[unitAbbreviationMap[fromUnit]];
  const toConversionFactor = unitConversionMap[unitAbbreviationMap[toUnit]];

  if (fromConversionFactor && toConversionFactor) {
    const finalValue = (value * fromConversionFactor / toConversionFactor).toFixed(2);
    return addUnitToValue ? this.addUnitToValue(finalValue, fromUnit, toUnit) : Number(finalValue);
  } else {
    throw new Error("Invalid unit provided");
  }
}

