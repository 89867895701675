import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, ViewChild } from "@angular/core";
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrForm } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { APIService } from "../../../../services/APIService/api.service";
import { SonderinspektionmodalComponent } from "../../_modals/sonderinspektionmodal/sonderinspektionmodal.component";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';


@Component({
  selector: 'app-sonderinspektionslistedialog',
  imports: [CommonModule, ClarityModule, PreviewThumbnailsComponent, SonderinspektionmodalComponent, MrTranslatePipe, FormsModule,
    ReactiveFormsModule, BackendLocaleDatePipe, HideIDColumnsPipe, TablePrettyPrintPipe],
  templateUrl: './sonderinspektionslistedialog.component.html',
  styleUrls: ['./sonderinspektionslistedialog.component.scss'],
  providers: [BackendLocaleDatePipe],
  standalone: true
})
export class SonderinspektionslistedialogComponent {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private basicStore: BasicStoreService,
    protected localeDate: BackendLocaleDatePipe,
  ) {
    this.formGroupSI.controls.datum.setValue(BackendLocaleDatePipe.now);
  }

  set data(dataIn: any) {

    this.dataRefresh = dataIn.dataRefresh;
    this.textpreview = this.mrTranslate.transform("Sonderinspektion wird geladen") + '...';

    this.loadSiObjekt();
  }

  @ViewChild(ClrForm, { static: false })
  private clrForm: ClrForm

  @ViewChild(PreviewThumbnailsComponent, { static: false })
  private preview: PreviewThumbnailsComponent

  @ViewChild(PreviewThumbnailsComponent, { static: false })
  private preview1: PreviewThumbnailsComponent


  @ViewChild(NgForm, { static: false })
  private ngForm: NgForm

  @ViewChild(SonderinspektionmodalComponent) modal: SonderinspektionmodalComponent;


  protected formGroupSI = new UntypedFormGroup({
    bemerkung: new UntypedFormControl(''),
    zustand: new UntypedFormControl('', [Validators.required]),
    datum: new UntypedFormControl("", [Validators.required]),
  });

  protected sonderinspformGroup = new UntypedFormGroup({
    bemerkung: new UntypedFormControl(''),
    mangel: new UntypedFormControl(''),
    beginn: new UntypedFormControl('', [Validators.required]),
    aktiv: new UntypedFormControl(true),
    intervall: new UntypedFormControl(10, [Validators.required]),
  });

  protected objekteSonderModal: boolean = false;
  protected ostammid: number = 0;
  protected otypid: number = 0;
  protected omazsid: number = 0;
  protected initdate: any;
  protected mangel: string = '';
  protected osispkID = 0;
  protected sonderPruefunglTable: any;
  protected mangelTable: any;
  protected bilder: any = [];
  protected curbilder: any = [];
  protected items: string[] = ['gut', 'schlecht', 'unverändert']
  protected sonderPruefungen: any;
  protected textpreview: string = "";
  protected iconColumns: string[] = ["typ"];
  protected showsonderinspektion: boolean = false;
  protected beginn: any;
  protected intervall: any;

  trackById = (index, item) => item.ID;


  loadSiObjekt() {
    this.apiservice
      .getSonderinspektionListe()
      .pipe(first())
      .subscribe((val: any) => {
        this.textpreview = this.mrTranslate.transform("Es ist keine aktive Sonderinspektion vorhanden");
        if (val.rows.length != 0) {
          this.sonderPruefungen = val;
        }
      });
  }

  showNewSi() {
    this.formGroupSI.controls.datum.setValue(BackendLocaleDatePipe.now);
    this.formGroupSI.controls.zustand.setValue('');
    this.formGroupSI.controls.bemerkung.setValue('');
    this.objekteSonderModal = true;
  }

  async newSi() {
    this.clrForm.markAsTouched();
    if (this.formGroupSI.valid) {
      let images;
      try {
        images = await this.preview?.packUnsavedFiles();
      } catch (_) {
        this.toastr.error(this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten"));
      }

      let val = this.formGroupSI.value;

      let sonder = {
        status: "addpruefung",
        OSTAMMID: this.ostammid,
        OMAZSID: this.omazsid,
        OSISPKID: this.osispkID,
        OTYPID: this.otypid,
        BILDER: images,
        value: val,
      }

      this.apiservice
        .setSonderinspektion(sonder)
        .pipe(first())
        .subscribe((val: any) => {
          if (val.success) {

            this.sonderPruefunglTable.rows = val.pruefung.rows;
            this.mangelTable = val.mangel;
            this.bilder = val.bilder ?? [];
            this.items = val.zustand;

            this.toastr.success(
              this.mrTranslate.transform('Erfolgreich gespeichert'),
              this.mrTranslate.transform('Sonderinspektion')
            );
          }

          this.objekteSonderModal = false;
        });


    }
  }

  saveSonderinspektion() {
    let val = this.sonderinspformGroup.value;

    if (val.aktiv == false) {

      let sonder = {
        status: "closepruefung",
        OSTAMMID: this.ostammid,
        OMAZSID: this.omazsid,
        OSISPKID: this.osispkID,
        bemerkung: val.bemerkung,
      }

      this.apiservice
        .setSonderinspektion(sonder)
        .pipe(first())
        .subscribe((val: any) => {
          if (val.success) {

            this.dataRefresh?.emit(true);

            this.toastr.success(
              this.mrTranslate.transform('Sonderinspektion wurde beenden'),
              this.mrTranslate.transform('Sonderinspektion')
            );

            this.showsonderinspektion = false;

            this.apiservice
              .getSonderinspektionListe()
              .pipe(first())
              .subscribe((val: any) => {
                this.sonderPruefungen.rows = val.rows;
                if (val.rows.length == 0) {
                  this.sonderPruefungen.rows = undefined;
                }
              });
          }
        });
    }
  }

  exitSi() {
    let inData = {
      Status: 0,
      OSTAMMID: this.ostammid,
      OMAZSID: this.omazsid,
      OSISPKID: this.osispkID,
      Mangel: this.mangel,
      aktive: false,
      beginn: this.beginn,
      intervall: this.intervall
    }
    this.modal.open(inData)

    this.modal.onOK.subscribe(res => {
      if (res == true) {
        this.modal.close();
        this.dataRefresh?.emit(true);
        this.apiservice
          .getSonderinspektionListe()
          .pipe(first())
          .subscribe((val: any) => {
            this.sonderPruefungen.rows = val.rows;
            if (val.rows.length == 0) {
              this.sonderPruefungen.rows = undefined;
            }
          });
      }

    });

  }

  selectionTabelleItemChanged(val: any) {
    if (val != undefined) {
      let id = val.ID;

      this.curbilder = [];

      Object.keys(this.bilder).forEach((typid) => {
        if (typid == id.toString()) {
          this.curbilder = this.bilder[typid];

          setTimeout(() => {
            this.preview1.clearPreview();
            if (this.curbilder.length > 0) {
              this.preview1.unpackFiles(this.curbilder);
            }

          }, 200);

        }
      });
    }
  }

  selectionTabelleTitelChanged(val: any) {
    if (val != undefined) {

      this.curbilder = [];
      this.sonderPruefunglTable = undefined;

      this.osispkID = val.ID;
      this.ostammid = val.OSTAMMID;
      this.otypid = val.OTYPID;
      this.omazsid = val.OMAZSID;
      this.mangel = val.Mangel;
      this.beginn = val.Beginn;
      this.intervall = val.Intervall;



      this.loadSonderinspektionObjekt();

    }
  }

  loadSonderinspektionObjekt() {
    this.apiservice
      .getSonderinspektion(this.ostammid.toString())
      .pipe(first())
      .subscribe((val: any) => {

        if (this.sonderPruefunglTable == undefined) {
          this.sonderPruefunglTable = val.pruefung;
        }
        else {
          this.sonderPruefunglTable.rows = val.pruefung.rows;
        }

        //this.sonderPruefunglTable = val.pruefung;
        this.mangelTable = val.mangel;
        this.bilder = val.bilder ?? [];
        if (val.zustand) {
          this.items = val.zustand;
        }
      });
  }


  public close() {
    this.closedialog.next(true);
  }
}
