<div class="overlay-inner-wrapper">
  <div id="statisticsWrapper">
    <div class="clr-row">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h3 class="mt-0">{{'Statistik' | mrTranslate}}</h3>
        </div>
        <div class="inline-block float-right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
    </div>

    <div class="clr-row">
      <div class="clr-col-4">
        <clr-radio-container clrInline class="margin-0-t">
          <clr-radio-wrapper>
            <input type="radio" clrRadio [(ngModel)]="filterInspektion" [value]="1" />
            <label>{{'alle Inspektionen' | mrTranslate}}</label>
          </clr-radio-wrapper>
          <clr-radio-wrapper>
            <input type="radio" clrRadio [(ngModel)]="filterInspektion" [value]="0" />
            <label>{{'letzte Inspektion' | mrTranslate}}</label>
          </clr-radio-wrapper>
        </clr-radio-container>
        <div class="dateRow">
          <clr-date-container>
            <label for="dateInputStart">{{'Startdatum' | mrTranslate}}</label>
            <input id="dateInputStart" type="date" clrDate [ngModel]="filterDateStart | localeDate"
              (ngModelChange)="filterDateStart = localeDate.tosql($event)"
              [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
          </clr-date-container>
          <clr-date-container>
            <label for="dateInputEnd">{{'Enddatum' | mrTranslate}}</label>
            <input id="dateInputEnd" type="date" clrDate [ngModel]="filterDateEnd | localeDate"
              (ngModelChange)="filterDateEnd = localeDate.tosql($event)"
              [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
          </clr-date-container>
        </div>

        <clr-tree id="statisticSelection">
          <clr-tree-node *ngFor="let type of statisticsModes">
            <clr-icon [attr.shape]="'objekttyp'+type.icon" size="24" style="color: black; margin-right: 0.4em;">
            </clr-icon>
            {{type.bezeichnung | titlecase | mrTranslate}}
            <clr-tree-node *ngFor="let statMode of Object.keys(type.statistics)">
              {{statMode | titlecase | mrTranslate}}
              <clr-tree-node *ngFor="let stat of type.statistics[statMode]"
                (click)="selectStatMode(type, statMode, stat)">
                <ng-container *ngIf="isString(stat); else elseTemplate">
                  {{stat | mrTranslate}}
                </ng-container>
                <ng-template #elseTemplate>
                  <span *ngIf="stat?.name">{{stat.name | titlecase | mrTranslate}}</span>
                </ng-template>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree-node>
        </clr-tree>
      </div>
      <div class="clr-col-8">
        <div id="statistikContent">
          <app-statistics-diagram [filterStartDate]="filterDateStart" [filterEndDate]="filterDateEnd"
            [allInspections]="filterInspektion == 1 ? true : false" [selectedMode]="selectedMode">
          </app-statistics-diagram>
        </div>
      </div>
    </div>

  </div>
</div>