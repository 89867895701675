import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, NgModule } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { DropzoneDirective } from "src/app/directives/dropzone.directive";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";
import JSZip from 'jszip';
// var Buffer = require('buffer').Buffer;
import MDBReader from "mdb-reader";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";

interface messreg {
  filename: string,
  dat: string,
  error: boolean,
  errortext: string;
  mess: {},
}

@Component({
  selector: "app-messregsmodal",
  templateUrl: "./messregmodal.component.html",
  styleUrls: ["./messregmodal.component.scss"],
  imports: [CommonModule, ClarityModule, DropzoneDirective, MrTranslatePipe, FormsModule, ReactiveFormsModule],
  standalone: true
})
export class MessRegModalComponent implements OnInit {
  @Input() modalOpen: boolean = false;
  @Output() onOK: EventEmitter<any> = new EventEmitter<any>();
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() param = "file";
  @Input() accept = ".V40";

  trial = false;
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
  ) { }

  @ViewChild('iis') iis: ElementRef;
  @ViewChild('gross') gross: ElementRef;
  @ViewChild('ouebh') ouebh: ElementRef;

  open(inData: any) {

    this.trial = this.authService.getToken().trial;
    this.optionsD = "0";
    this.optionsLK = "0";
    this.isDarkThemeAktiv = inData.theme == 'dark';

    this.backgroundcolor1 = this.backgroundcolor = this.isDarkThemeAktiv
      ? '#28404D'
      : '#F2F6F8';

    this.apiService
      .getMessTol()
      .pipe(first())
      .subscribe((val: any) => {
        this.toleranzen = val.toleranzen;

        let tolrow = val.toleranzen.rows[0];
        this.messtol.controls.usesr100.setValue(tolrow.USE_100);
        this.messtol.controls.usesrlim.setValue(tolrow.USE_L);
        this.messtol.controls.usesrg.setValue(tolrow.USE_G);
        this.messtol.controls.txtsr0p.setValue(tolrow.TEXT_0_p);
        this.messtol.controls.txtsr0m.setValue(tolrow.TEXT_0_m)
        this.messtol.controls.txtsr100p.setValue(tolrow.TEXT_100_p);
        this.messtol.controls.txtsr100m.setValue(tolrow.TEXT_100_m)
        this.messtol.controls.txtsrlimp.setValue(tolrow.TEXT_L_p);
        this.messtol.controls.txtsrlimm.setValue(tolrow.TEXT_L_m)
        this.messtol.controls.txtsrgp.setValue(tolrow.TEXT_G_p);
        this.messtol.controls.txtsrgm.setValue(tolrow.TEXT_G_m)
        this.messtol.controls.txt0s.setValue(tolrow.TEXT_0_S)
        this.messtol.controls.txt100s.setValue(tolrow.TEXT_100_S)
        this.messtol.controls.txtls.setValue(tolrow.TEXT_L_S)
        this.messtol.controls.txtgs.setValue(tolrow.TEXT_G_S)
        this.messtol.controls.tolsr0.setValue(tolrow.TEXT_0_A)
        this.messtol.controls.tolsr100.setValue(tolrow.TEXT_100_A)
        this.messtol.controls.tolsrlim.setValue(tolrow.TEXT_L_A)
        this.messtol.controls.tolsrg.setValue(tolrow.TEXT_G_A)
        this.messtol.controls.tolnamer.setValue(tolrow.TEXT_Ausfall_0)
        this.messtol.controls.tolnamea.setValue(tolrow.TEXT_Ausfall_I)
        this.messtol.controls.colsro.setValue(tolrow.COLOR_0)
        this.messtol.controls.colsr100.setValue(tolrow.COLOR_100)
        this.messtol.controls.colsrlim.setValue(tolrow.COLOR_L)
        this.messtol.controls.colsrg.setValue(tolrow.COLOR_G)

        let inival = val.ini;
        this.messini.controls.optMessTol.setValue(inival.optMessTol.toString());
        this.messini.controls.optMessTolBez.setValue(inival.optMessTolBez);
      });
    this.modalOpen = true;
  }

  close() {
    this.modalOpen = false;
  }

  onSubmit() {
    this.onOK.emit('');
  }

  token: any;
  toleranzen: any;
  selectedMessgeraet: String = "Messprofil V3.0";
  regelActive: boolean;
  neuActive: boolean;
  _toltexttyp: number = 0;
  show: boolean = false;
  optionsD: any;
  optionsLK: any;
  filelistcount: number = 0;
  uploadFile: File | null;
  isDarkThemeAktiv = false;

  backgroundcolor: string = '#28404D';
  backgroundcolor1: string = '#28404D';
  bordercolor: string = '#8f9ba3';

  fileContent: any;
  isLoading: boolean = false;

  downloadingFile = false;
  hassendet: boolean = false;

  messini: UntypedFormGroup = new UntypedFormGroup({
    optMessTol: new UntypedFormControl(1),
    optMessTolBez: new UntypedFormControl(''),
  });

  messtol: UntypedFormGroup = new UntypedFormGroup({
    usesr100: new UntypedFormControl(true),
    usesrlim: new UntypedFormControl(true),
    usesrg: new UntypedFormControl(true),
    txtsr0p: new UntypedFormControl('SR0 +'),
    txtsr0m: new UntypedFormControl('SR0 -'),
    txtsr100p: new UntypedFormControl('SR100 +'),
    txtsr100m: new UntypedFormControl('SR100 -'),
    txtsrlimp: new UntypedFormControl('SRLim +'),
    txtsrlimm: new UntypedFormControl('SRLim -'),
    txtsrgp: new UntypedFormControl('SRG +'),
    txtsrgm: new UntypedFormControl('SRG -'),
    txt0s: new UntypedFormControl('SR0'),
    txt100s: new UntypedFormControl('SR100'),
    txtls: new UntypedFormControl('SRLim'),
    txtgs: new UntypedFormControl('SRG'),
    tolsr0: new UntypedFormControl('SR0'),
    tolsr100: new UntypedFormControl('SR100'),
    tolsrlim: new UntypedFormControl('SRLim'),
    tolsrg: new UntypedFormControl('SRG'),
    tolnamer: new UntypedFormControl('Toleranzverletzung'),
    tolnamea: new UntypedFormControl('Toleranzverletzung'),
    colsro: new UntypedFormControl("#87CEFA"),
    colsr100: new UntypedFormControl("#90EE90"),
    colsrlim: new UntypedFormControl("#FFFF00"),
    colsrg: new UntypedFormControl("#FF0000"),
  });

  color = '#ff0000';

  colorSelected(newColor) {
    console.log('colorSelected', newColor);
    this.color = newColor;
  }

  protected resetToleranzenBezeichnungen() {
    this.messtol.reset({
      usesr100: true,
      usesrlim: true,
      usesrg: true,
      txtsr0p: 'SR0 +',
      txtsr0m: 'SR0 -',
      txtsr100p: 'SR100 +',
      txtsr100m: 'SR100 -',
      txtsrlimp: 'SRLim +',
      txtsrlimm: 'SRLim -',
      txtsrgp: 'SRG +',
      txtsrgm: 'SRG -',
      txt0s: 'SR0',
      txt100s: 'SR100',
      txtls: 'SRLim',
      txtgs: 'SRG',
      tolsr0: 'SR0',
      tolsr100: 'SR100',
      tolsrlim: 'SRLim',
      tolsrg: 'SRG',
      tolnamer: 'Toleranzverletzung',
      tolnamea: 'Toleranzverletzung',
      colsro: "#87CEFA",
      colsr100: "#90EE90",
      colsrlim: "#FFFF00",
      colsrg: "#FF0000",
    });
  }

  /*
  Felder toleranzen
  ID, USE_100, USE_L, USE_G, TEXT_0_m, TEXT_0_p, TEXT_100_m, TEXT_100_p, TEXT_L_m, TEXT_L_p, TEXT_G_m, TEXT_G_p, TEXT_0_A, TEXT_100_A, TEXT_L_A, TEXT_G_A, TEXT_0_S, TEXT_100_S, TEXT_L_S, TEXT_Ausfall_0, TEXT_Ausfall_I, TEXT_G_S, COLOR_0, COLOR_100, COLOR_L, COLOR_G
  */



  ngOnInit() {


  }

  daten: Array<messreg> = [];

  onDragOver($event) {
    this.backgroundcolor1 = this.isDarkThemeAktiv
      ? '#21333B'
      : '#F0F0F0';
    this.bordercolor = '#48D506';
  }

  onDragLeave($event) {
    this.backgroundcolor1 = this.isDarkThemeAktiv
      ? '#28404D'
      : '#F2F6F8';
    this.bordercolor = '#8f9ba3';
  }

  onDrop(files: FileList) {

    for (let i = 0; i < this.daten.length; i++) {
      for (let index = 0; index < files.length; index++) {
        if (this.daten[i].filename == files[index].name) {
          this.removeFileFromArray(this.daten[i]);
        }
      }
    }

    for (let index = 0; index < files.length; index++) {

      if (files[index].name.endsWith(this.accept)) {

        const file = files[index];

        let mr: messreg = {
          filename: file.name,
          error: false,
          errortext: '',
          dat: '',
          mess: '',
        };

        this.daten.push(mr);
        this.filelistcount = this.daten.length;

        let fileReader: FileReader = new FileReader();
        let $this = this;
        fileReader.onloadend = function (x) {
          var jsZip = typeof (<any>JSZip).default === "function" ? new (<any>JSZip).default() : new JSZip();
          jsZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {

              if (filename.toUpperCase() == "DATA.MDB") {
                zip.files[filename].async('blob').then((fileData) => {
                  try {
                    var fileReader = new FileReader();
                    fileReader.onload = function (event) {

                      const result = event.target.result;
                      let _buffer: Buffer;
                      if (typeof result === 'string') {
                        // If it's a string, specify an encoding (adjust if needed)
                        _buffer = Buffer.from(result, 'utf8');
                      } else if (result instanceof ArrayBuffer) {
                        // Convert the ArrayBuffer to a Uint8Array
                        _buffer = Buffer.from(new Uint8Array(result));
                      } else {
                        throw new Error("Unexpected result type");
                      }
                      const data = {};
                      const reader = new MDBReader(_buffer);
                      for (const tableName of reader.getTableNames()) {
                        if (tableName.toUpperCase() == 'MESSWERTE' || tableName.toUpperCase() == 'MW_ABGELEITET' || tableName.toUpperCase() == 'INSPEKTIONEN') {
                          data[tableName] = reader.getTable(tableName).getData();
                          data[tableName + '_c'] = reader.getTable(tableName).getColumns();
                        }
                      }
                      mr.mess = data;
                    };
                    fileReader.readAsArrayBuffer(fileData);

                  } catch (e) {
                    $this.fileContent = fileData;
                  }
                  $this.isLoading = false;
                });
              }

              if (filename.toUpperCase() == "INFO.DAT") {
                zip.files[filename].async('string').then((fileData) => {
                  try {
                    mr.dat = fileData;
                  } catch (e) {
                  }
                });

              }

            });
          });
        }
        fileReader.readAsText(file);

      }
    }

    this.backgroundcolor1 = this.isDarkThemeAktiv
      ? '#28404D'
      : '#F2F6F8';
    this.bordercolor = '#8f9ba3';
  }

  onOptionsSelected(value: string) {

    if (value == "Messprofil V3.0")
      this.accept = ".V40";

  }

  onChange(v: any, t: any) {

    switch (t) {
      case 'colsro': {
        this.messtol.value.colsro = v;
        break;
      }
      case 'colsr100': {
        this.messtol.value.colsr100 = v;
        break;
      }
      case 'colsrg': {
        this.messtol.value.colsrg = v;
        break;
      }
      case 'tolsr0': {
        this.messtol.value.tolsr0 = v;
        break;
      }
      case 'tolsr100': {
        this.messtol.value.tolsr100 = v;
        break;
      }
      case 'tolsrlim': {
        this.messtol.value.tolsrlim = v;
        break;
      }
      case 'tolsrg': {
        this.messtol.value.tolsrg = v;
        break;
      }
      case 'usesr100': {
        this.messtol.value.usesr100 = v;
        break;
      }
      case 'usesrlim': {
        this.messtol.value.usesrlim = v;
        break;
      }
      case 'usesrg': {
        this.messtol.value.usesrg = v;
        break;
      }
      case 'txt0s': {
        this.messtol.value.txt0s = v;
        break;
      }
      case 'txt100s': {
        this.messtol.value.txt100s = v;
        break;
      }
      case 'txtls': {
        this.messtol.value.txtls = v;
        break;
      }
      case 'txtg': {
        this.messtol.value.txtg = v;
        break;
      }
      default: {
        //statements;
        break;
      }
    }

  }

  // onClick() {
  //   const fileUpload = document.getElementById("fileUpload") as HTMLInputElement;
  //   fileUpload.onchange = () => {
  //     this.onDrop(fileUpload.files);
  //   };
  //   fileUpload.click();
  // }

  onClick() {
    const fileUpload = document.getElementById("fileUpload") as HTMLInputElement;
    // Reset the value so that selecting the same file again will trigger onchange.
    fileUpload.value = "";
    fileUpload.onchange = () => {
      this.onDrop(fileUpload.files);
    };
    fileUpload.click();
  }


  uploadMess(mess: messreg) {

    let m = btoa(JSON.stringify(mess));

    let toSend = {};

    toSend['name'] = mess.filename;
    toSend['base64'] = m;


    let dr = false;
    if (this.optionsD == '1')
      dr = true;

    let lk = false;
    if (this.optionsLK == '1')
      lk = true;

    let tolneu = false;
    if (this.neuActive == true)
      tolneu = true;

    let ouebh = false;
    if (this.ouebh != undefined) {
      if (this.ouebh.nativeElement.checked == true)
        ouebh = true;
    }

    let vals = this.messini.value;
    let options = {
      ToleranzNeu: tolneu,
      lk: lk,
      dr: dr,
      iis: this.iis.nativeElement.checked,
      gross: this.gross.nativeElement.checked,
      ouebh: ouebh,
      optMessTol: parseFloat(vals.optMessTol),
      optMessTolBez: vals.optMessTolBez,
    }

    toSend['options'] = options;

    this.apiService
      .sendNewMessdaten(toSend)
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.success) {
          this.removeFileFromArray(mess);
        }
        else {
          this.setError(mess, "Error: " + res.error)
          this.toastr.error(mess.filename, res.error);
        }
        this.checkspinner();
        this.hassendet = true;
      });
  }

  doexit() {
    this.daten = [];
    this.filelistcount = 0;
    this.modalOpen = false;
    this.onOK.emit(this.hassendet);
    this.modalOpenChange.emit(this.hassendet);
  }


  runUpload() {
    let vals = this.messini.value;
    if (vals.optMessTol == '1') {
      if (vals.optMessTolBez == '') {
        this.toastr.error(this.mrTranslate.transform("Geben Sie eine Toleranzbezeichnung ein"));
        return;
      }
    }
    this.downloadingFile = true;

    this.daten.forEach(mess => {
      this.uploadMess(mess);
    });

  }

  checkspinner() {
    let find = false;
    const result = this.daten.find(({ error }) => error === false);
    if (!result || this.daten.length == 0)
      this.downloadingFile = false;
  }

  private setError(mess: messreg, text: string) {
    const index = this.daten.indexOf(mess);
    if (index > -1) {
      this.daten[index].error = true;
      this.daten[index].errortext = text;
    }
  }

  private removeFileFromArray(mess: messreg) {
    const index = this.daten.indexOf(mess);
    if (index > -1) {
      this.daten.splice(index, 1);
      this.filelistcount = this.daten.length;
    }
  }

  toggle() {
    this.show = !this.show;
  }

  saveSettings() {
    let vals = this.messtol.value;
    let mess = {
      USE_100: vals.usesr100,
      USE_L: vals.usesrlim,
      USE_G: vals.usesrg,
      TEXT_0_p: vals.txtsr0p,
      TEXT_0_m: vals.txtsr0m,
      TEXT_100_p: vals.txtsr100p,
      TEXT_100_m: vals.txtsr100m,
      TEXT_L_p: vals.txtsrlimp,
      TEXT_L_m: vals.txtsrlimm,
      TEXT_G_p: vals.txtsrgp,
      TEXT_G_m: vals.txtsrgm,
      TEXT_0_S: vals.txt0s,
      TEXT_100_S: vals.txt100s,
      TEXT_L_S: vals.txtls,
      TEXT_G_S: vals.txtgs,
      TEXT_0_A: vals.tolsr0,
      TEXT_100_A: vals.tolsr100,
      TEXT_L_A: vals.tolsrlim,
      TEXT_G_A: vals.tolsrg,
      COLOR_0: vals.colsro,
      COLOR_100: vals.colsr100,
      COLOR_L: vals.colsrlim,
      COLOR_G: vals.colsrg,
      TEXT_Ausfall_0: vals.tolnamer,
      TEXT_Ausfall_I: vals.tolnamea,
    }

    this.apiService
      .sendMessTol({
        messtol: mess
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status)
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
      });
  }
}
