import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, ViewChild } from "@angular/core";
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';
import { ClarityModule, ClrForm } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";

import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";
import { APIService } from "../../../../services/APIService/api.service";
import { PreviewThumbnailsComponent } from "../../_shared/preview-thumbnails/preview-thumbnails.component";
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';

@Component({
  selector: 'app-sonderinspektionsdialog',
  imports: [ CommonModule, ClarityModule, PreviewThumbnailsComponent, MrTranslatePipe, FormsModule, BackendLocaleDatePipe, ReactiveFormsModule,
    HideIDColumnsPipe, TablePrettyPrintPipe ],
  templateUrl: './sonderinspektionsdialog.component.html',
  styleUrls: ['./sonderinspektionsdialog.component.scss'],
  providers: [ BackendLocaleDatePipe ],
  standalone: true
})
export class SonderinspektionsdialogComponent {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataRefresh = new EventEmitter();

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private _sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private basicStore: BasicStoreService,
    protected localeDate: BackendLocaleDatePipe,
  ) {
    this.formGroupSI.controls.datum.setValue(BackendLocaleDatePipe.now);
  }

  set data(dataIn: any) {

    this.ostammid  = dataIn.ostammid;
    this.otypid =  dataIn.otypid;

    this.loadSiObjekt();
  }
  @ViewChild(ClrForm, { static: false })
    private clrForm: ClrForm

  @ViewChild(PreviewThumbnailsComponent, {static: false})
  private preview: PreviewThumbnailsComponent

  @ViewChild(PreviewThumbnailsComponent, {static: false})
  private preview1: PreviewThumbnailsComponent

  @ViewChild(NgForm, { static: false })
  private ngForm: NgForm

  protected formGroupSI = new UntypedFormGroup({
    bemerkung: new UntypedFormControl(''),
    zustand: new UntypedFormControl('',[Validators.required]),
    datum: new UntypedFormControl("", [Validators.required]),
  });

  protected objekteSonderModal: boolean = false;
  protected ostammid: number = 0;
  protected otypid: number = 0;
  protected omazsid: number = 0;
  protected initdate: any;
  protected mangel: string = '';
  protected cusSiID = 0;
  protected sonderPruefunglTable: any;
  protected mangelTable: any;
  protected bilder: any = [];
  protected curbilder: any = [];
  protected items: string[] = ['gut', 'schlecht','unverändert']

  trackById = (index, item) => item.ID;

  loadSiObjekt(){
    this.apiservice
      .getSonderinspektion(this.ostammid.toString())
      .pipe(first())
      .subscribe((val: any) => {
        this.cusSiID = val.osispkid;
        this.sonderPruefunglTable = val.pruefung;
        this.mangelTable = val.mangel;
        this.bilder = val.bilder ?? [];
        if (val.zustand){
          this.items = val.zustand;
        }
        if (this.mangelTable.rows){
          let row = this.mangelTable.rows[0];
          this.mangel = row["Mangel"];
          this.omazsid = row["OMAZSID"];
        }
    });
  }

   showNewSi(){
    this.formGroupSI.controls.datum.setValue(BackendLocaleDatePipe.now);
    this.formGroupSI.controls.zustand.setValue('');
    this.formGroupSI.controls.bemerkung.setValue('');
    this.objekteSonderModal = true;
  }

  async newSi(){
    this.clrForm.markAsTouched();
    if (this.formGroupSI.valid){
      let images;
      try {
        images = await this.preview?.packUnsavedFiles();
      } catch (_) {
        this.toastr.error(this.mrTranslate.transform("Beim Speichern von Bilder und Video ist ein Fehler aufgetreten"));
      }

      let val = this.formGroupSI.value;

      let sonder = {
        status: "addpruefung",
        OSTAMMID: this.ostammid,
        OMAZSID: this.omazsid,
        OSISPKID: this.cusSiID,
        OTYPID: this.otypid,
        BILDER: images,
        value: val,
      }

      this.apiservice
      .setSonderinspektion(sonder)
      .pipe(first())
      .subscribe((val: any) => {
        if (val.success){

          this.sonderPruefunglTable.rows = val.pruefung.rows;
          this.mangelTable = val.mangel;
          this.bilder = val.bilder ?? [];
          this.items = val.zustand;

          this.toastr.success(
            this.mrTranslate.transform('Erfolgreich gespeichert'),
            this.mrTranslate.transform('Sonderinspektion')
          );
        }

        this.objekteSonderModal = false;
      });


    }
  }

  exitSi(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diese Sonderinspektion beenden wollen?"))) {

      let sonder = {
        status: "closepruefung",
        OSTAMMID: this.ostammid,
        OMAZSID: this.omazsid,
        OSISPKID: this.cusSiID,
      }

      this.apiservice
      .setSonderinspektion(sonder)
      .pipe(first())
      .subscribe((val: any) => {
        if (val.success){

          this.dataRefresh?.emit(true);

          this.toastr.success(
            this.mrTranslate.transform('Sonderinspektion wurde beenden'),
            this.mrTranslate.transform('Sonderinspektion')
          );

          setTimeout(() => {
            this.closedialog.next(true);
          },2000);

        }
      });
    }

  }

  selectionTabelleTitelChanged(val: any){
    if (val != undefined){
      let id = val.ID;

      this.curbilder = [];

      Object.keys(this.bilder).forEach((typid) => {
        if(typid == id.toString()){
          this.curbilder = this.bilder[typid];

          setTimeout(() => {
            this.preview1.clearPreview();
            if (this.curbilder.length>0) {
              this.preview1.unpackFiles(this.curbilder);
            }

          },200);

        }
      });
    }
  }

  public close() {
    this.closedialog.next(true);
  }
}
