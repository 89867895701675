import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { Observable } from 'rxjs';
import { DynamicStyleDirective } from 'src/app/directives/dynamic-style.directive';
import { Table } from 'src/app/models/ui/table';
import { HideIDColumnsPipe } from 'src/app/pipes/hide-idcolumns.pipe';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { MrUnitTextPipe } from 'src/app/pipes/mrUnitText.pipe';
import { TablePrettyPrintPipe } from 'src/app/pipes/tablePrettyPrint.pipe';
import { APIService } from 'src/app/services/APIService/api.service';

@Component({
  selector: 'app-mangel-log-modal',
  templateUrl: './mangel-log-modal.component.html',
  styleUrls: ['./mangel-log-modal.component.scss'],
  imports: [ CommonModule, ClarityModule, MrTranslatePipe,MrUnitTextPipe, HideIDColumnsPipe, TablePrettyPrintPipe ],
  standalone: true
})
export class MangelLogModalComponent {
  protected log$: Observable<Table>;

  @Input('open') set openedMangelLog(omazsid: number) {
    this.log$ = this.apiService.getMaengelLog(omazsid);
  }

  @Output('openChange') protected closeMangelLog = new EventEmitter<null>();

  constructor(
    private apiService: APIService,
  ) {}

}
