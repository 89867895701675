import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";


import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as XLSX from 'xlsx';
//const { read, write, utils } = XLSX;

import { ClrDatagrid } from "@clr/angular";
import {HubConnectionBuilder, HubConnection, LogLevel, HttpTransportType } from "@microsoft/signalr"
import { DropzoneDirective } from "src/app/directives/dropzone.directive";
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";

@Component({
  selector: "app-messungmodal",
  templateUrl: "./messungmodal.component.html",
  styleUrls: ["./messungmodal.component.scss"],
  imports: [ CommonModule, ClarityModule, DropzoneDirective, MrTranslatePipe, HideIDColumnsPipe, TablePrettyPrintPipe, BackendLocaleDatePipe ],
  providers: [ BackendLocaleDatePipe, MrTranslatePipe ],
  standalone: true
})
export class MessungModalComponent implements OnInit {
  @Input() modalOpen: boolean = false;
  @Output() onOK: EventEmitter<any> = new EventEmitter<any>();

  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() param = "file";
  @Input() accept = ".xls";
  @ViewChild('dgStammdaten') dgStammdaten: ClrDatagrid;

  trial = false;
  private _connection: HubConnection;

  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
    private http: HttpClient,
    protected localeDate: BackendLocaleDatePipe
  ) {}

  setting: UntypedFormGroup = new UntypedFormGroup({
    messpunktabstand: new UntypedFormControl(10),
    verwindungsbasis: new UntypedFormControl(3),
    verwindungseinheit: new UntypedFormControl('promill'),
    bediener: new UntypedFormControl(''),
    rtol: new UntypedFormControl(false),
    showbauw: new UntypedFormControl(false),
    spurauto: new UntypedFormControl(false),
    spurautowert: new UntypedFormControl(0.5),
    uehglatt: new UntypedFormControl(true),
    uehglattopt: new UntypedFormControl(1),
    radglatt: new UntypedFormControl(false),
    usetrass: new UntypedFormControl(false),
    usetrassueh: new UntypedFormControl(false),
    usetrassrad: new UntypedFormControl(false),
    mehrjahr: new UntypedFormControl(false),
    usesrg: new UntypedFormControl(false),
    usesrl: new UntypedFormControl(false),
    usesr1: new UntypedFormControl(false),
  });

  tol: UntypedFormGroup = new UntypedFormGroup({
    auswahl: new UntypedFormControl(true),
    sollwert:new UntypedFormControl(''),
    ymin: new UntypedFormControl(''),
    ymax: new UntypedFormControl(''),
    srmin: new UntypedFormControl(''),
    srmax: new UntypedFormControl(''),
  });

  tolrad: UntypedFormGroup = new UntypedFormGroup({
    messart: new UntypedFormControl(''),
    bed: new UntypedFormControl(''),
    bedmin: new UntypedFormControl(''),
    bedmax: new UntypedFormControl(''),
    soll: new UntypedFormControl(''),
    srgp: new UntypedFormControl(''),
    srgm: new UntypedFormControl(''),
    srlp: new UntypedFormControl(''),
    srlm: new UntypedFormControl(''),
    sr1p: new UntypedFormControl(''),
    sr1m: new UntypedFormControl(''),
  });

  bauw: UntypedFormGroup = new UntypedFormGroup({
    auswahl: new UntypedFormControl(''),
    pos: new UntypedFormControl(''),
    color: new UntypedFormControl(''),
  });

  opt: UntypedFormGroup = new UntypedFormGroup({
    auswahl: new UntypedFormControl(true),
    ymin: new UntypedFormControl(''),
    ymax: new UntypedFormControl(''),
    srmin: new UntypedFormControl(''),
    srmax: new UntypedFormControl(''),
  });

  protected dateField = BackendLocaleDatePipe.now;


  tabselect(val){
    if (val == 'profil')
    setTimeout(() => {
      this.dateField = BackendLocaleDatePipe.now;
    }, 10);
  }

  trackdActive: any;
  profilesActive: any;
  powerActive: any;
  riffelActive: any;
  selecteditem: any;
  selected: any = [];

  n: any;
  toleranzen: any;
  dtStellkraft: any;
  toleranzenall: any;
  optionen: any;
  optionenall: any;

  bauwerke: any;
  tolradius: any = [];

  selectedMessgeraet: String = "0";

  filelistcount: number=0;
  uploadFile: File | null;
  isDarkThemeAktiv = false;

  backgroundcolor1:string = '#28404D';
  bordercolor:string = '#8f9ba3';

  fileContent: any;
  isLoading: boolean = false;

  downloadingFile = false;

  selectbauwerke: boolean = false;
  selecttoleranzen: boolean = false;
  selBWID: number;

  selTolID: number = 0;
  messtyp: number = 0;
  messgeraettyp: number = 0;
  messgeraettyplins: number = 0;
  typlins: string;
  filelist: any[] = [];
  sendfilelist: any[] = [];

  API_URL: string;
  jwt: string;
  spinnertitel: string;
  uploadprocessrun: boolean = false;
  uploadprocessprogress: number = 0;
  stammdatenauswahl: any;
  selectedGleisForObject: any;
  selectedEinheit: any;
  initdate: any;

  haslinspfileleft: boolean = false;
  haslinspfileright: boolean = false;
  haslinsfilesleft: boolean = false;
  haslinsfilesright: boolean = false;
  haslinslwmfileleft: boolean = false;
  haslinslwmfileright: boolean = false;

  hassended: boolean = false;

  onOptionsSelectedBedingung(value: any){

  }

  onOptionsSelectedMessart(value: any){

  }

  selectionChanged(value: any){

  }

  uploadedFiles = [];
  pos: string="";

  initUploadFolder(val) {
    this.pos = val;
    let fileInput = document.getElementById('folderInput');
    if (fileInput)
      fileInput.click();
    else
      console.log('ERROR: cannot find file input');
  }


  initUpload(val, index) {
    this.pos = val;
    this.messgeraettyplins = index;
    this.typlins ="profil";

    if (index==12)
      this.typlins ="riffel";

    let fileInput = document.getElementById('fileInput');
    if (fileInput)
      fileInput.click();
    else
      console.log('ERROR: cannot find file input');
  }

  public onChange(fileList: FileList): void {
    let item: any = fileList[0];

    for (let i = 0; i < this.filelist.length; i++) {
      if (this.filelist[i].name == item.name){
        this.deleteFile(i);
      }
    }

    if(this.verifyAccept(item.name)){
      if (item.size > 0){
        item.filetyp = 1,
        item.filelocation = this.pos,
        item.typ = this.typlins,
        item.subfolder = ''
        item.geraet = this.messgeraettyp,
        item.progress = 0;
        item.error = false;
        item.errortext = '';
        item.isprocessed = false;
        this.filelist.push(item);

        this.checklinsfiles();
      }
    }
    else{
      this.toastr.error(this.mrTranslate.transform("Die Datei hat nicht das passende Format!")+" " + item.name);
    }
  }


  checklinsfiles(){

    this.haslinspfileleft = this.filelist.some(x => x.filetyp == 1 && x.typ == "profil" && x.filelocation == 'left');
    this.haslinspfileright = this.filelist.some(x => x.filetyp == 1 && x.typ == "profil" && x.filelocation == 'right');
    this.haslinsfilesleft = this.filelist.some(x => x.filetyp == 2 && x.typ == "profil" && x.filelocation == 'left');
    this.haslinsfilesright = this.filelist.some(x => x.filetyp == 2 && x.typ == "profil" && x.filelocation == 'right');
    this.haslinslwmfileleft = this.filelist.some(x => x.filetyp == 1 && x.typ == "riffel" && x.filelocation == 'left');
    this.haslinslwmfileright = this.filelist.some(x => x.filetyp == 1 && x.typ == "riffel" && x.filelocation == 'right');

  }


  uploadFiles(files: any) {
    const arrFiles = Array.from(files);

    arrFiles.forEach((file) => {
      const path: string = file['webkitRelativePath'];
      const pathPieces = path.split('/');
      pathPieces.pop();


      let item: any = file;
      for (let i = 0; i < this.filelist.length; i++) {
        if (this.filelist[i].name == item.name && this.filelist[i].filetyp == 2 && this.filelist[i].filelocation == this.pos){
          this.deleteFile(i);
        }
      }

      if(this.verifyAccept(item.name)){
        if (item.size > 0){
          item.subfolder = pathPieces[0];
          item.filetyp = 2,
          item.filelocation = this.pos,
          item.typ = "profil",
          item.geraet = this.messgeraettyp,
          item.progress = 0;
          item.error = false;
          item.errortext = '';
          item.isprocessed = false;
          this.filelist.push(item);
        }
      }
      else{
        this.toastr.error(this.mrTranslate.transform("Die Datei hat nicht das passende Format!")+" " + item.name);
      }

    });
    this.checklinsfiles();
  }

  open(inData: any) {
    this.isDarkThemeAktiv = inData.theme == 'dark';
    this.API_URL = this.apiService.getURL();
    this.jwt = localStorage.getItem("jwt");

    this.backgroundcolor1 = this.isDarkThemeAktiv
     ? '#28404D'
     : '#F2F6F8';

    this.selectedMessgeraet = '0';
    this.trial = this.authService.getToken().trial;

    let vals = {
      status: 'get'
    }

    this.apiService
      .getMessungTol(vals)
      .pipe(first())
      .subscribe((val: any) => {
        this.toleranzen = val.toleranzen;
        this.toleranzenall = val.toleranzen.rows;

        this.optionen = val.optionen;
        this.optionenall = val.optionen.rows[0];

        this.bauwerke =  val.bauwerke;
        this.tolradius =  val.radiustoleranzen;

        this.messtyp = 0;
        this.onOptionsSelected(this.messtyp.toString());

    });

    this.initHubConnectionBuilder();
    this.modalOpen = true;
  }

  loadsettings(typ){
    this.setting.controls.messpunktabstand.setValue(this.optionenall['MesspunktAbstand' + typ ]);
    this.setting.controls.verwindungsbasis.setValue(this.optionenall['VerwindungBasis' + typ ]);
    this.setting.controls.usetrass.setValue(this.optionenall['R_Trass' + typ ]);
    this.setting.controls.rtol.setValue(this.optionenall['R_Tol' + typ ]);
    this.setting.controls.showbauw.setValue(this.optionenall['BauW' + typ ]);
    this.setting.controls.bediener.setValue(this.optionenall['Bediener' + typ ]);
    this.setting.controls.usesrg.setValue(this.optionenall['SRG_Use_' + typ ]);
    this.setting.controls.usesrl.setValue(this.optionenall['SRLim_Use_' + typ ]);
    this.setting.controls.usesr1.setValue(this.optionenall['SR100_Use_' + typ ]);
    this.setting.controls.usetrassrad.setValue(this.optionenall['R_Trass_Ra_' + typ ]);
    this.setting.controls.usetrassueh.setValue(this.optionenall['R_Trass_UH_' + typ ]);
    this.setting.controls.uehglatt.setValue(this.optionenall['Ueh_Glaetten_' + typ ]);
    this.setting.controls.spurauto.setValue(this.optionenall['SpurAuto_' + typ ]);
    this.setting.controls.mehrjahr.setValue(this.optionenall['MehrJahrVergleich_' + typ ]);
    this.setting.controls.spurautowert.setValue(this.optionenall['AutoWertSpur']);
    this.setting.controls.uehglattopt.setValue(this.optionenall['UEH_Frequenz_' + typ ]);
    this.setting.controls.verwindungseinheit.setValue('promill');
  }

  updateSettings(){

    let saveitems = {
      status: 'updatesettings',
      typ: this.messtyp,
      vals: this.setting.value,
    }

    this.apiService
    .getMessungTol(saveitems)
    .pipe(first())
    .subscribe((val: any) => {
      if (val){

      }
    });
  }


  updateitems(vals: any){
    this.apiService
    .getMessungTol(vals)
    .pipe(first())
    .subscribe((val: any) => {
      if (val){
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
      }
    });
  }

  close() {
    this.filelist = [];
    this.toleranzen = undefined;
    this.toleranzenall= undefined;
    this.optionen= undefined;
    this.optionenall= undefined;
    this.bauwerke= undefined;
    this.tolradius= undefined;

    this.sendfilelist = [];
    this.downloadingFile = false;
    this.uploadprocessrun = false;

    if (this._connection != undefined){
      this._connection.stop()
      this._connection = undefined;
    }

    this.modalOpen = false;

    this.onOK.emit(this.hassended);
  }

  onSubmit() {
    this.onOK.emit('');
  }

  ngOnInit() {

  }

  saveItemsBW(id){
    let vals =  this.bauw.value;

    for (let i = 0; i < this.bauwerke.rows.length; i++) {
      if (this.bauwerke.rows[i].id == id){
        this.bauwerke.rows[i]['auswahl'] = vals.auswahl;
        this.bauwerke.rows[i]['reihenfolge'] = vals.pos;
        this.bauwerke.rows[i]['colorhtml'] = vals.color;
        break;
      }
    }

    let saveitems = {
      status: 'updatebauwerke',
      id: id,
      auswahl: vals.auswahl,
      reihenfolge: vals.pos,
      colorhtml: vals.color,
    }

    this.updateitems(saveitems);
  }

  onBWColor(value){
    this.bauw.controls.color.setValue(value);
  }

  onDetailOpenBW(value){
    if (value != null){
      this.selBWID = value.id;
      this.bauw.controls.auswahl.setValue(value.auswahl);
      this.bauw.controls.pos.setValue(value.reihenfolge);
      this.bauw.controls.color.setValue(value.colorhtml);
    }
  }

  saveItemsMessTol(id: number){

    let vals =  this.tolrad.value;


    if (vals.messart == undefined){
      this.toastr.error(this.mrTranslate.transform("Bitte 'Messart' auswählen...!"));
      return;
    }

    if (vals.messart == ''){
      this.toastr.error(this.mrTranslate.transform("Bitte 'Messart' auswählen...!"));
      return;
    }

    if (vals.soll == undefined){
      this.toastr.error(this.mrTranslate.transform("Geben Sie einen Sollwert an..."));
      return;
    }

    if (vals.soll == ''){
      this.toastr.error(this.mrTranslate.transform("Geben Sie einen Sollwert an..."));
      return;
    }

    if (vals.srgp == undefined){
      this.toastr.error(this.mrTranslate.transform("Bitte 'SRG +' eingeben...!"));
      return;
    }

    if (vals.srgp == ''){
      this.toastr.error(this.mrTranslate.transform("Bitte 'SRG +' eingeben...!"));
      return;
    }

    if (vals.srgm == undefined){
      this.toastr.error(this.mrTranslate.transform("Bitte 'SRG -' eingeben...!"));
      return;
    }

    if (vals.srgm == ''){
      this.toastr.error(this.mrTranslate.transform("Bitte 'SRG -' eingeben...!"));
      return;
    }

    let saveitems = {
      status: 'updateradiustoleranz',
      id: id,
      messart: vals.messart,
      bed: vals.bed,
      bedmin: vals.bedmin,
      bedmax: vals.bedmax,
      soll: vals.soll,
      srgp: vals.srgp,
      srgm: vals.srgm,
      srlp: vals.srlp,
      srlm: vals.srlm,
      sr1p: vals.sr1p,
      sr1m: vals.sr1m,
    }

    this.apiService
    .getMessungTol(saveitems)
    .pipe(first())
    .subscribe((val: any) => {
      if (val){

        this.tolradius =  val.radiustoleranzen;
        this.selTolID = 0;
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
      }

    });
  }

  addItemsMessTol(){
    this.selTolID = 0;

    let newrow ={
      ID: 0,
      Messart: this.mrTranslate.transform('Spurweite'),
      Sollwert: 0,
    }
    this.tolradius.rows.push(newrow);
  }

  deleteItemsMessTol(){
    if (confirm(this.mrTranslate.transform("Sind Sie sicher, dass Sie diesen Eintrag löschen wollen?"))) {

      let deleteitems = {
        status: 'deleteradiustoleranz',
        id: this.selTolID,
      }

      this.apiService
      .getMessungTol(deleteitems)
      .pipe(first())
      .subscribe((val: any) => {
        if (val){
          this.tolradius =  val.radiustoleranzen;
          this.selTolID = 0;
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      });
    }
  }


  onDetailOpenMessTol(value){
    if (value != null){
      this.selTolID = value.ID;
      this.tolrad.controls.messart.setValue(value.Messart);
      this.tolrad.controls.bed.setValue(value.Bedingung);
      this.tolrad.controls.bedmin.setValue(value['>=']);
      this.tolrad.controls.bedmax.setValue(value['<']);
      this.tolrad.controls.soll.setValue(value['Sollwert']);
      this.tolrad.controls.srgp.setValue(value['SRG+']);
      this.tolrad.controls.srgm.setValue(value['SRG-']);
      this.tolrad.controls.srlp.setValue(value['SRlim +']);
      this.tolrad.controls.srlm.setValue(value['SRlim -']);
      this.tolrad.controls.sr1p.setValue(value['SR100 +']);
      this.tolrad.controls.sr1m.setValue(value['SR100 -']);
    }
  }

  saveItems(id){
    let vals =  this.tol.value;

    if (vals.sollwert == undefined || vals.sollwert == null){
      this.toastr.error(this.mrTranslate.transform("Geben Sie einen Sollwert an..."));
      return;
    }


    for (let i = 0; i < this.toleranzen.rows.length; i++) {
      if (this.toleranzen.rows[i].id == id){
        this.toleranzen.rows[i]['Auswahl'] = vals.auswahl;
        this.toleranzen.rows[i]['Sollwert'] = vals.sollwert;
        this.toleranzen.rows[i]['Y-Min'] = vals.ymin;
        this.toleranzen.rows[i]['Y-Max'] = vals.ymax;
        this.toleranzen.rows[i]['SRG-'] = vals.srmin;
        this.toleranzen.rows[i]['SRG+'] = vals.srmax;
        break;
      }
    }

    for (let i = 0; i < this.toleranzenall.length; i++) {
      if (this.toleranzenall[i].id == id){
        this.toleranzenall[i]['Auswahl'] = vals.auswahl;
        this.toleranzenall[i]['Sollwert'] = vals.sollwert;
        this.toleranzenall[i]['Y-Min'] = vals.ymin;
        this.toleranzenall[i]['Y-Max'] = vals.ymax;
        this.toleranzenall[i]['SRG-'] = vals.srmin;
        this.toleranzenall[i]['SRG+'] = vals.srmax;
        break;
      }
    }

    let saveitems = {
      status: 'updatediagramm',
      id: id,
      auswahl: vals.auswahl,
      sollwert: vals.sollwert,
      ymin: vals.ymin,
      ymax: vals.ymax,
      srmin: vals.srmin,
      srmax: vals.srmax,
    }

    this.updateitems(saveitems);

  }

  changeBauwerke(event){
    this.selectbauwerke = event.target.checked;
  }

  changeRadiusToleranzen(event){
    this.selecttoleranzen = event.target.checked;
  }

  onOptionsSelected(value:string){
    this.toleranzen.rows = [];
    this.messgeraettyp = parseInt(value);

    switch(this.messgeraettyp) {
      case 0: {
        this.messtyp = 0;
        this.accept = ".xls"
         break;
      }
      case 1:{
          this.accept = ".xls,.xlsx"
          this.messtyp = 1;
           break;
        }
      case 2:{
          this.accept = ".xls,.xlsx"
          this.messtyp = 1;
           break;
        }
      case 3: {
          this.accept = ".csv,.krx"
          this.messtyp = 2;
           break;
        }
      case 4:{
          this.accept = ".dat"
          this.messtyp = 1;
           break;
        }
      case 5:{
          this.accept = ".txt"
          this.messtyp = 1;
           break;
        }
      case 6:{
          this.accept = ".html"
          this.messtyp = 1;
           break;
        }
      case 7:{
        this.accept = ".html"
          this.messtyp = 1;
           break;
        }
      case 8:{
          this.accept = ".str, srh"
          this.messtyp = 1;
           break;
        }
      case 9: {
        this.accept = ".csv"
        this.messtyp = 1;
         break;
      }
      case 10: {
        this.accept = ".emaview"
        this.messtyp = -1;
         break;
      }
      case 11: {
        this.accept = ".csv"
        this.messtyp = -1;

        if (this.stammdatenauswahl == undefined){
          this.getStammdaten();
        }

        setTimeout(() => {
          this.dateField = BackendLocaleDatePipe.now;
        }, 10);

         break;
      }
      case 12: {
        this.accept = ".csv"
        this.messtyp = -1;
         break;
      }
      case 13: {
        this.accept = ".csv"
        this.messtyp = -1;
         break;
      }
    }

    this.loadsettings(this.messtyp.toString());
    this.toleranzen.rows = this.toleranzenall.filter(item=> item.typ == this.messtyp);

  }

  getStammdaten(){
    let vals = {
      status: 'getstamm'
    }

    this.apiService
      .getMessungTol(vals)
      .pipe(first())
      .subscribe((val: any) => {
        this.stammdatenauswahl = val.stammdaten;
    })
  }


  onDetailOpen(value){
    if (value != null){
      this.tol.controls.auswahl.setValue(value.Auswahl);
      this.tol.controls.sollwert.setValue(value['Sollwert']);
      this.tol.controls.ymin.setValue(value['Y-Min']);
      this.tol.controls.ymax.setValue(value['Y-Max']);
      this.tol.controls.srmin.setValue(value['SRG-']);
      this.tol.controls.srmax.setValue(value['SRG+']);
    }
  }


  onDragOver($event) {
    this.backgroundcolor1 = this.isDarkThemeAktiv
      ? '#21333B'
      : '#F0F0F0';
    this.bordercolor = '#48D506';
  }

  onDragLeave($event) {
    this.backgroundcolor1 = this.isDarkThemeAktiv
      ? '#28404D'
      : '#F2F6F8';
    this.bordercolor = '#8f9ba3';
  }

  onDrop(files) {
    this.backgroundcolor1 = this.isDarkThemeAktiv
      ? '#28404D'
      : '#F2F6F8';
    this.bordercolor = '#8f9ba3';
    this.prepareFilesList(files);
  }

  onClick() {
    const fileUpload = document.getElementById("fileUpload") as HTMLInputElement;
    fileUpload.onchange = () => {
      this.onDrop(fileUpload.files);
    };
    fileUpload.click();
  }

  connected: boolean;
  async startHubConnection() {
      try {

          if (this._connection == undefined)
            this.initHubConnectionBuilder();


          await this._connection.start();
          console.log("SignalR Connected.");
          this.connected = true;

      } catch (err) {
          console.log(err);
          setTimeout(this.startHubConnection, 1500);
      }
  };

  initHubConnectionBuilder(){
    this._connection = new HubConnectionBuilder()
    .configureLogging(LogLevel.None)
    .withUrl(this.API_URL + '/progress' , { withCredentials: false, transport: HttpTransportType.LongPolling  })
    .withAutomaticReconnect()
    .build();

    this._connection.on('taskStarted', data => {
      this.sendfilelist = [];
    });

    this._connection.on('taskTextUpdate', data => {
      this.toastr.error(data);

      //this.spinnertitel = data;
      //this.messages.push(data);
    });

    this._connection.on('taskProgressChanged', data => {
      setTimeout(() => {
        this.uploadprocessprogress = data
      }, 1);
      //this.messages.push(data);
    });

    this._connection.on('taskEnded', data => {
      setTimeout(() => {
        this.uploadprocessprogress = 0;
        this.sendfilelist = [];
        this.downloadingFile = false;
        this.uploadprocessrun = false;
        //this._connection.stop()
        //this._connection = undefined;
        //this.connected = false;


        this.toastr.success(this.mrTranslate.transform("Daten Hochladen ist abgeschlossen"));

      }, 500);
    });
  }

  sendfilelisttoserver(){

    this.uploadprocessrun = true;

    if (this.messgeraettyp == 11){

      let vals = {
        status: 'processfiles',
        geraettyp: this.messgeraettyp,
        ostammid: this.selectedGleisForObject,
        einheit: this.selectedEinheit,
        datum: this.dateField,
        list: this.sendfilelist,
      }

      setTimeout(() => {
        this.http
          .post(this.API_URL + "/files/processmessfiles", vals, {
            headers: new HttpHeaders().set(
              "Authorization",
              "Bearer " + this.jwt,
            ),
            responseType: "json",
            reportProgress: true,
            observe: 'events',
          })

          .subscribe( () => {});
      }, 1000);
    }
  else
  {


    let vals = {
      status: 'processfiles',
      geraettyp: this.messgeraettyp,
      list: this.sendfilelist
    }
    setTimeout(() => {
      this.http
        .post(this.API_URL + "/files/processmessfiles", vals, {
          headers: new HttpHeaders().set(
            "Authorization",
            "Bearer " + this.jwt,
          ),
          responseType: "json",
          reportProgress: true,
          observe: 'events',
        })
        //.pipe(first())
        .subscribe((val: any) => {
        if (val){


        }

      });
    }, 2000);
  }
  }

  upload(index: number) {

    this.spinnertitel = this.mrTranslate.transform("Lädt...");

    if (index === this.filelist.length) {
      this.spinnertitel = this.mrTranslate.transform("Server Verarbeitung...");
      this.sendfilelisttoserver();
      return;
    }

    const file = this.filelist[index];
    file.progress = 0;
    const formData = new FormData();
    formData.append('file', file);


    this.http
      .post(this.API_URL + "/files/uploadfiles", formData, {
        headers: new HttpHeaders().set(
          "Authorization",
          "Bearer " + this.jwt,
        ),
        responseType: "json",
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            file.progress = Math.round((100 / event.total) * event.loaded);
          } else if (event.type == HttpEventType.Response) {

            setTimeout(() => {
              file.progress = null;

              let items ={
                subfolder: file.subfolder,
                filetyp: file.filetyp,
                filelocation: file.filelocation,
                typ: file.typ,
                geraet: file.geraet,
                name: file.name,
                isprocessed: file.isprocessed,
              }
              this.sendfilelist.push(items);

              this.removeFileFromArray(file);
              this.upload(0);

            }, 200);
          }
        }),
        catchError((err: any) => {
          file.progress = null;
          file.error = true;
          file.errortext = err.message;
          //alert(err.message);

          this.downloadingFile = false;

          return throwError(err.message);
        })
      )
      .toPromise();
  }


  changedGleis(value){
    this.selectedGleisForObject = value;
  }

  changedEinheit(value){
    this.selectedEinheit = value;
  }

  runUpload() {

    if (this.messgeraettyp == 11){
      if (this.selectedGleisForObject == undefined){
        this.toastr.error(this.mrTranslate.transform("Bitte wählen Sie ein Objekt aus!"));
        return;
      }
      if (this.selectedEinheit == undefined){
        this.toastr.error(this.mrTranslate.transform("Einheit fehlt"));
        return;
      }

      if (!this.dateField){
        this.toastr.error(this.mrTranslate.transform("Wählen Sie ein Datum aus"));
        return;
      }
    }

    this.downloadingFile = true;

    this.startHubConnection();

    if (this.trackdActive)
      this.updateSettings();


    if (this.accept == ".xls" || this.accept == ".xlsx" || this.accept == ".xls,.xlsx"){
      this.convertExcelFileToCSV(0);
    }
    else{
      this.upload(0);
    }

  }

  convertExcelFileToCSV(index: number){

    this.spinnertitel = this.mrTranslate.transform("Exceldatei Verarbeitung...");

    if (index === this.filelist.length) {

      this.spinnertitel = this.mrTranslate.transform("Server Verarbeitung...");
      this.sendfilelisttoserver();
      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      /* read workbook */
      let bstr = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr);
      let cursheet = 0;
      let fsheet = 0;

      if (wb.SheetNames.length > 1)
      {
        cursheet = 1;
        for (let i = 0; i < wb.SheetNames.length; i++){

          const wsname: string = wb.SheetNames[i];
          const ws_: XLSX.WorkSheet = wb.Sheets[wsname];

          for (let m = 2; m <= 7; m++){
            var cell =   'A' + m.toString();
            var desired_cell = ws_[cell];
            var val = (desired_cell ? desired_cell.v : undefined);

            if (val == 'm'){
              fsheet = i;
              break;
            }
          }

          if ( fsheet != 0 )
          {
            cursheet = fsheet;
            break;
          }

        }

      }

      const wsname: string = wb.SheetNames[cursheet];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      if (this.filelist[index].geraet == 1 || this.filelist[index].geraet == 2){
        for (let m = 2; m <= 10; m++){
          var cell =   'E' + m.toString();
          var desired_cell = ws[cell];
          var val = (desired_cell ? desired_cell.v : undefined);
          if (val != undefined){
            if (val.indexOf('\n') != -1) {

              do {
                val = val.replace('\n','');
               } while (val.indexOf('\n') != -1)

               ws[cell].v = val;
               ws[cell].w = val;
               break;

            }
          }
        }
      }


      let csv = XLSX.utils.sheet_to_csv(ws, { FS: '|', strip: false });

      let fileName = this.filelist[index].name + '.csv';
      let fileContent = csv;

      let myBlob = new Blob([fileContent], { type: 'text/plain' });

      if (cursheet == 1)
        this.filelist[index].geraet = 0;

      let item: any = new File([myBlob], fileName);

      if (item.size > 0){
        item.subfolder = '';
        item.filetyp = -1,
        item.filelocation = '',
        item.progress = 0;
        item.error = false;
        item.errortext = '';
        item.typ = this.filelist[index].typ,
        item.geraet = this.filelist[index].geraet,
        item.isprocessed = ( fsheet == 1);
        this.filelist[index] = item;

        const file = this.filelist[index];

        file.progress = 0;
        const formData = new FormData();
        formData.append('file', file);

        this.spinnertitel = this.mrTranslate.transform("Lädt...");

        this.http
          .post(this.API_URL + "/files/uploadfiles", formData, {
            headers: new HttpHeaders().set(
              "Authorization",
              "Bearer " + this.jwt,
            ),
            responseType: "json",
            reportProgress: true,
            observe: 'events',
          })
          .pipe(
            map((event: any) => {
              if (event.type == HttpEventType.UploadProgress) {
                file.progress = Math.round((100 / event.total) * event.loaded);
              } else if (event.type == HttpEventType.Response) {

                setTimeout(() => {
                  file.progress = null;

                  let items ={
                    subfolder: file.subfolder,
                    filetyp: file.filetyp,
                    filelocation: file.filelocation,
                    typ: file.typ,
                    geraet: file.geraet,
                    name: file.name,
                    isprocessed: file.isprocessed,
                  }
                  this.sendfilelist.push(items);

                  this.removeFileFromArray(file);
                  this.convertExcelFileToCSV(0);

                }, 200);
              }
            }),
            catchError((err: any) => {
              file.progress = null;
              file.error = true;
              file.errortext = err.message;
              //alert(err.message);
              return throwError(err.message);
            })
          )
          .toPromise();

      }

    };

    reader.readAsArrayBuffer(this.filelist[index]);
  }

  removeFileFromArray(file) {
    const index = this.filelist.indexOf(file);
    if (index > -1) {
      this.filelist.splice(index, 1);
    }
  }

  setError(index: number, text: string){
    this.filelist[index].error = true;
    this.filelist[index].errortext = text;
  }


  deleteFile(index: number) {
    this.filelist.splice(index, 1);
    this.checklinsfiles();
  }

  prepareFilesList(files: Array<any>) {
    const fileUpload = document.getElementById("fileUpload") as HTMLInputElement;

    for (let i = 0; i < this.filelist.length; i++) {
      for (let index = 0; index < files.length; index++) {
        if (this.filelist[i].name == files[index].name){
          this.deleteFile(i);
        }
      }
    }


    let paratyp = "track";

    if (this.trackdActive)
      paratyp = "track";
    if (this.profilesActive)
      paratyp = "profil";
    if (this.powerActive)
      paratyp = "power";
    if (this.riffelActive)
      paratyp = "riffel";

    for (const item of files) {
      if(this.verifyAccept(item.name)){
        if (item.size > 0){
          item.subfolder = '';
          item.filetyp = -1,
          item.filelocation = '',
          item.typ = paratyp,
          item.geraet = this.messgeraettyp,
          item.progress = 0;
          item.error = false;
          item.errortext = '';
          item.isprocessed = false;
          this.filelist.push(item);
        }
      }
      else{
        this.toastr.error(this.mrTranslate.transform("Die Datei hat nicht das passende Format!")+" " + item.name);
      }
    }
    fileUpload.value = undefined; //Damit OnChange wieder getriggert werden kann
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  verifyAccept(type: string): boolean {
    const allowed = this.accept.split(',').map(x => x.trim());
    let ext = type.split('.').pop();
    for (const item of allowed)
    {
      if ( ext.toLowerCase() == item.toLowerCase().replace(".","")){
        return true;
      }
    }
    return false;
  }
}
