import { HilfeBeschreibung } from "../../../_modals/hilfebeschreibungmodal/hilfebeschreibungmodal.component";
import { ZArt, ZType } from './zaehler.enums';

export type ToleranzenAktivOptions = {
  SR100: boolean;
  SRG: boolean;
};

export type ZählerSkizze = {
  Bild64?: string;
  /** Name */
  Bild?: string;
}

export type ZählerName = {
  ID: number;
  /** @required @unique */
  Code: string;
  Bezeichnung: string;
} & ZählerSkizze & ToleranzenAktivOptions & HilfeBeschreibung;

export const TOL_FIELDS = {
  'aktivSRlimm': false,
  'SRlimplus': 0,
  'SRlimminus': 0,
  'aktivSR100': false,
  'SR100plus': 0,
  'SR100minus': 0,
  'aktivSRG': false,
  'SRGplus': 0,
  'SRGminus': 0,
};

export type Toleranzen = {[key in keyof typeof TOL_FIELDS]: typeof TOL_FIELDS[key] };

export type ZählerWert = {
  ID: number;
  SZAEHNID?: number;
  I_SORT: number;
  /** @required @unique within SZAEHNID*/
  Code: string;
  Soll: number;
  Bezeichnung: string;
  ZArt: ZArt; // ENUM
  ZType?: ZType; // ENUM
  isHeader?: boolean;
  ZEinheit: number; // id from szaeeh
  Einheit?: string;
  SGenau: Genauigkeit;
  TGenau: Genauigkeit;
} & Toleranzen;

export type Genauigkeit = 0 | 1 | 2 | 3; // Zahl der Dezimalwerte

export type ZählerEinheit = {
  ID: number;
  Code: string;
  Bezeichnung: string;
  OTYPID: number;
};

