import { Attribute, Directive, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent, Subscription, throttleTime } from 'rxjs';


/**
 * @description für Anzeige/Ausblendung abhängig von window;
 * @hides Element, wenn mit `*` benutzt;
 * @returns true/false durch template variable ('query' exposes directive) q.matches
 * @example *w-media-query="{min: 1000, max: 1200}"
 * @example [w-media-query]="{max: 600}" #q="query" [clrInMenu]="q.matches"
 */
@Directive({
  selector: '[w-media-query]',
  exportAs: 'query',
  standalone: true

})
export class WindowMediaQueryDirective implements OnInit, OnDestroy {
  @Input({ alias: 'w-media-query', required: true })
    private mode: { min?: number; max?: number }
  ;

  private mediaQueryList: MediaQueryList;
  private sub: Subscription;
  public matches: boolean = false;

  constructor(
    @Optional() private readonly viewContainerRef: ViewContainerRef,
    @Optional() private readonly templateRef: TemplateRef<unknown>,
    @Attribute('or_operator') private readonly operator: string,
    @Attribute('media_height') private readonly dimension: string,
  ) {
    this.operator = operator !== null ? 'or' : 'and';
    this.dimension = dimension !== null ? 'height' : 'width';
  }

  ngOnInit() {
    if (!this.mode) return;

    const mediaQuery = Object.entries(this.mode).map(
      ([key, px]) => `(${key}-${this.dimension}: ${px}px)`
    );

    this.mediaQueryList = matchMedia(mediaQuery.join(this.operator));
    this.updateView(this.mediaQueryList.matches);

    this.sub = fromEvent<MediaQueryListEvent>(this.mediaQueryList, 'change')
    .pipe(throttleTime(400)).subscribe(({matches}) => this.updateView(matches));
  }

  private updateView = (matches: boolean) => {
    this.matches = matches;
    if (matches && this.templateRef) {
      const ref = this.viewContainerRef?.createEmbeddedView(this.templateRef);
      ref?.markForCheck();
    } else this.viewContainerRef?.clear();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
