import { Component, EventEmitter, Input, Output} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import { first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
;
import { BackendLocaleDatePipe } from "src/app/pipes/get-locale-date.pipe";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: "app-messregauswertungmodal",
    templateUrl: "./messregauswertungmodal.component.html",
    styleUrls: ["./messregauswertungmodal.component.scss"],
    imports: [CommonModule, ClarityModule, MrTranslatePipe, FormsModule, ReactiveFormsModule, BackendLocaleDatePipe],
    providers: [BackendLocaleDatePipe, MrTranslatePipe],
    standalone: true
})
export class MessregAuswertungmodal {
  @Input() modalOpen: boolean = false;
  @Output() onOK: EventEmitter<any> = new EventEmitter<any>();

  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() param = "file";
  @Input() accept = ".xls";


  constructor(
    private apiService: APIService,
    private mrTranslate: MrTranslatePipe,
    protected localeDate: BackendLocaleDatePipe
  ) {

  }

  inGroup: UntypedFormGroup = new UntypedFormGroup({
    datevon: new UntypedFormControl(BackendLocaleDatePipe.now),
    datebis: new UntypedFormControl(BackendLocaleDatePipe.now),
    anzeigeTyp: new UntypedFormControl(0),
  });


  public get von() : string {
    return this.inGroup.get('datevon').value;
  }
  public set von(value : string) {
    this.inGroup.get('datevon').setValue(value);
    value && this.getCounts();
  }
  public get bis() : string {
    return this.inGroup.get('datebis').value;
  }
  public set bis(value: string) {
    this.inGroup.get('datebis').setValue(value);
    value && this.getCounts();
  }

  isopened: boolean = false;
  objektlist: any;
  messlist: any;
  countmess: number = 0;
  countobjekte: number = 0;
  opruefid: any = [];

  open() {
    this.isopened = true;
    this.inGroup.controls.datevon.setValue(
      dayjs().startOf('year').format(BackendLocaleDatePipe.format)
    );
    this.inGroup.controls.datebis.setValue(
      BackendLocaleDatePipe.now
    );

    this.getCounts();
    this.modalOpen = true;
  }


  getCounts(){
    const { datevon, datebis, anzeigeTyp } = this.inGroup.value;
    if (!datevon || !datebis) return;
    let vals = {
      status: 'getcount',
      start: datevon,
      ende: datebis,
      typ: anzeigeTyp,
    }

    this.apiService
    .getMessungAuswertung(vals)
    .pipe(first())
    .subscribe((val: any) => {
      if (val){
        this.objektlist= val.objektlist;
        this.countmess= val.countmess;
        this.countobjekte= val.countobjekte;
        this.opruefid = val.objektlist.rows.map((item) => item.OPRUEFID);
      }

    });
  }


  onExport(){

    let localLang = localStorage.getItem("language");
    let val = this.inGroup.value;
    let vals = {
      status: 'getmess',
      start: val.datevon,
      ende: val.datebis,
      typ: val.anzeigeTyp,
      opruefid: this.opruefid,
      lang: localLang,
    }

    this.apiService
    .getMessungAuswertung(vals)
    .pipe(first())
    .subscribe((val: any) => {
      if (val){
        this.messlist = val.messlist.rows;

        const fileName = this.mrTranslate.transform("Sammelauswertung") + '.xlsx';
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.messlist);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, this.mrTranslate.transform("Sammelauswertung"));

        XLSX.writeFile(wb, fileName);


      }

    });

  }


  close() {
   this.modalOpen = false;
  }

  onSubmit() {
    this.onOK.emit('');
  }


}
