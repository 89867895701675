import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { first, firstValueFrom } from 'rxjs';
import { MrTranslatePipe } from 'src/app/pipes/mr-translate.pipe';
import { APIService } from 'src/app/services/APIService/api.service';
import { BasicStoreService } from 'src/app/services/BasicStore/basic-store.service';
import { WartungsItemComponent } from './wartungsitem/wartungsitem.component';
import { ItemsMode, WartungsBaumNode } from '../wartung.types';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-wartungsbaum',
    templateUrl: './wartungsbaum.component.html',
    styleUrls: ['./wartungsbaum.component.scss'],
    imports: [CommonModule, ClarityModule, WartungsItemComponent, MrTranslatePipe, FormsModule],
    standalone: true
})
export class WartungsBaumComponent implements OnInit {
  @ViewChild('art', { read: WartungsItemComponent, static: true})
    private artItem: WartungsItemComponent
  ;
  @ViewChild('arbeit', { read: WartungsItemComponent, static: true})
    private arbeitItem: WartungsItemComponent
  ;
  @ViewChild('treeContainer', { read: ElementRef, static: true})
    private treeContainer: ElementRef<HTMLElement>
  ;

  //#region objekttypen
  private _otypid: number;
  protected get otypid(): number {
    return this._otypid;
  }
  @Input() protected set otypid(value: number) {
    this._otypid = value;
    this.apiService
      .getWartungsbaum(this.otypid)
      .pipe(first())
      .subscribe(({ success, error, wartungen }: any) => {
        if (success) {
          this.wTree.clear();
          setTimeout(() => wartungen.forEach((w: WartungsBaumNode) => this.addTreeNode(w)));
        } else {
          this.toastr.error(
            error, this.mrTranslate.transform('Etwas ist schief gelaufen')
          );
        }
      });
    this.selectedWartung = undefined;
  }
  //#endregion
  //#region wartungen
  protected setInsert: boolean = false;
  protected wTree = new Map<number, WartungsBaumNode[]>();

  private _selectedWartung: WartungsBaumNode;
  protected get selectedWartung(): WartungsBaumNode {
    return this._selectedWartung;
  }
  protected set selectedWartung(value: WartungsBaumNode) {
    this._selectedWartung = value;
    this.setInsert = value && !value.ID;
    this.artItem.selectedID = value?.artID;
    this.arbeitItem.selectedID = value?.arbeitID;
    this.selectedFK = value
      ? value.Priorität ?? this.rangeFK.at(-1)
      : undefined;
  }
  //#endregion
  protected rangeFK: number[] = [];
  protected selectedFK: number;

  //#region load()
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private store: BasicStoreService
  ) {}

  ngOnInit(): void {
    const {
      einstellung: { optMINNumFK = 0, optMAXNumFK = 4 } = {},
    } = this.store.getComponentStore('einstellung');
    for (let fk = optMINNumFK; fk <= optMAXNumFK; fk++) {
      this.rangeFK.push(fk);
    }
  }
  //#endregion
  //#region wartung()
  protected addWartung() {
    this.selectedWartung = {
      artID: this.artItem.selID,
      arbeitID: this.arbeitItem.selID
    };
  }

  protected saveWartung() {
    const artID = this.artItem.selID;
    const arbeitID = this.arbeitItem.selID;

    if (!artID || !arbeitID) {
      this.toastr.error(
        this.mrTranslate.transform('Wartungsart und Wartungsarbeiten müssen ausgewählt sein'),
        this.mrTranslate.transform('Ungültige Eingabe'),
      );
      return;
    }

    if ((this.setInsert || artID != this.selectedWartung.artID || arbeitID != this.selectedWartung.arbeitID)
      && this.hasTreeNode({artID, arbeitID})
    ) {
      this.toastr.error(
        this.mrTranslate.transform('Eintrag bereits existriert'),
        this.mrTranslate.transform('Ungültige Eingabe'),
      );
      return;
    }

    const sendObj: WartungsBaumNode = {
      ID: this.selectedWartung.ID,
      artID,
      arbeitID,
      Priorität: this.selectedFK,
      artName: this.artItem.selName,
      arbeitName: this.arbeitItem.selName,
    };

    this.apiService
      .setWartungsbaumNode(this.otypid, sendObj)
      .pipe(first())
      .subscribe(({ success, error, ID }: any) => {
        if (success) {
          this.toastr.success(this.mrTranslate.transform('Daten gespeichert'));
          const neuItem: WartungsBaumNode = { ...sendObj, ID };
          if (!this.setInsert) this.deleteTreeNode(this.selectedWartung);
          this.addTreeNode(neuItem);
          this.selectedWartung = neuItem;
          setTimeout(() => {
            this.treeContainer.nativeElement
              .querySelector("#w-" + this.selectedWartung.ID)
              ?.scrollIntoView({
                behavior: 'auto',
                block: 'center'
              });
          });
        } else {
          this.toastr.error(
            error, this.mrTranslate.transform('Etwas ist schief gelaufen')
          );
        }
      });
  }

  protected deleteWartung() {
    const text = this.mrTranslate.transform('Wartungsverknüpfung wirklich löschen?');
    if (confirm(text)) {
      this.apiService
        .deleteWartungsItem('wartung', this.selectedWartung.ID)
        .pipe(first())
        .subscribe(({ success, error }: any) => {
          if (success) {
            this.toastr.success(this.mrTranslate.transform('Daten gelöscht'));
            this.deleteTreeNode(this.selectedWartung);
            this.selectedWartung = undefined;
          } else {
            this.toastr.error(
              error, this.mrTranslate.transform('Fehler beim Löschen')
            );
          }
        });
    }
  }
  //#endregion
  //#region tree()
  protected async editTree(mode: ItemsMode, {id, name}: {id: number, name: string}) {
    this.findTreeNode(mode, id).forEach(
      w => w[mode + "Name"] = name
    );
  }

  protected async cleanTree(mode: ItemsMode, id: number) {
    const wartungen = this.findTreeNode(mode, id);
    if (!wartungen.length) return;

    this.selectedWartung = undefined;

    wartungen.forEach(w => {
      firstValueFrom(this.apiService.deleteWartungsItem('wartung', w.ID));
      if (mode == 'arbeit') this.deleteTreeNode(w);
    });

    if (mode == 'art') this.wTree.delete(id);
  }

  private findTreeNode(mode: string, id: number): WartungsBaumNode[] {
    return mode == 'art'
      ? this.wTree.get(id) ?? []
      : Array.from(this.wTree.values()).flatMap(
        wArr => wArr.filter(w => w.arbeitID == id)
      );
  }

  private hasTreeNode({ artID, arbeitID }: WartungsBaumNode): boolean {
    const arr = this.wTree.get(artID);
    return arr?.some(w => w.arbeitID == arbeitID);
  }

  private addTreeNode(w: WartungsBaumNode) {
    const arr = this.wTree.get(w.artID);
    if (arr) arr.unshift(w);
    else this.wTree.set(w.artID, [w]);
  }

  private deleteTreeNode(w: WartungsBaumNode) {
    const arr = this.wTree.get(w.artID);
    if (arr.length > 1)
      arr.splice(arr.indexOf(w), 1);
    else this.wTree.delete(w.artID);
  }
  //#endregion
}
