<clr-modal [(clrModalOpen)]="isMultiRueckModalOpen" [clrModalSize]="'xl'" id="multirueckmeldung">
  <h3 class="modal-title">{{'Rückmeldung' | mrTranslate}}</h3>
  <div class="modal-body grid" id="grid-container">
    <clr-datagrid *ngIf="isReady" class="datagrid-compact">
      <clr-dg-column>
        <clr-date-container class="nm">
          <label for="date">{{'Datum' | mrTranslate}}</label>
          <input name="date" type="date" clrDate [ngModel]="dateforall | localeDate"
            (ngModelChange)="dateforall = localeDate.tosql($event)"
            [ngModelOptions]="{standalone: true, updateOn: 'blur'}"/>
        </clr-date-container>
      </clr-dg-column>
      <clr-dg-column style="min-width: 40ch;">
        <clr-select-container class="nm">
          <label for="instandsetzer">{{'Instandsetzer' | mrTranslate}}</label>
          <select clrSelect *ngIf="firmen" (change)="setAllFirmen($event)" name="instandsetzer" class="w-100-p">
            <option *ngFor="let firm of firmen" [ngValue]="firm.Firma + ', ' + firm.Abteilung + ', ' + firm.Ort">
              {{firm.Firma + ', ' + firm.Abteilung + ', ' + firm.Ort}}</option>
          </select>
        </clr-select-container>
      </clr-dg-column>
      <clr-dg-column *ngFor="let col of rueckmeldungColumns" [clrDgField]="col">
        <ng-container *clrDgHideableColumn="{hidden: col | hideIDColumns}">{{
          col | titlecase | mrUnitText }}
        </ng-container>
      </clr-dg-column>

      <clr-dg-row *clrDgItems="let row of selectedValid" [clrDgItem]="row">
        <clr-dg-cell class="name-cell">
          <clr-date-container>
            <input type="date" clrDate [ngModel]="row.erledigt_am | localeDate"
              (ngModelChange)="row.erledigt_am = localeDate.tosql($event)"
              [ngModelOptions]="{standalone: true, updateOn: 'blur'}"/>
          </clr-date-container>
        </clr-dg-cell>
        <clr-dg-cell class="name-cell">
          <select style="max-width: 100%" *ngIf="firmen" [(ngModel)]="row.auftragnehmer"
            [ngModelOptions]="{standalone: true}">
            <option *ngFor="let firm of firmen" [ngValue]="firm.Firma + ', ' + firm.Abteilung + ', ' + firm.Ort">
              {{firm.Firma + ', ' + firm.Abteilung + ', ' + firm.Ort}}</option>
          </select>
        </clr-dg-cell>
        <clr-dg-cell *ngFor="let cell of rueckmeldungColumns" [innerHTML]="row[cell] | tablePrettyPrint">
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>
        <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="10">
          {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
          {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
        </clr-dg-pagination>
      </clr-dg-footer>
    </clr-datagrid>

    <div class="clr-col-12 padding-1-b margin-20-t">
      <app-preview-thumbnails [drag_drop]="true"></app-preview-thumbnails>
    </div>
  </div>
  <div class="modal-footer">
  <button type="button" [disabled]="trial || selectedValid.length == 0" class="btn btn-primary"
      (click)="sendMultiRueckmeldung()">{{selectedValid.length}} 
      {{"Mängel rückmelden" | mrTranslate}}</button>
  </div>
</clr-modal>