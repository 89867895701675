export const ColumnTypes = {
  Boolean: "boolean",
  Byte: "byte",
  Integer: "integer",
  Long: "long",
  Currency: "currency",
  Float: "float",
  Double: "double",
  DateTime: "datetime",
  Binary: "binary",
  Text: "text",
  OLE: "ole",
  Memo: "memo",
  RepID: "repid",
  Numeric: "numeric",
  Complex: "complex",
  BigInt: "bigint",
  DateTimeExtended: "datetimextended"
};