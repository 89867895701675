import { Column } from "./column";
import { Row } from "./row";
import { Table } from "./table";

function getUniqueValues(table: Table): Record<string, Set<any>> {
  const uniqueValuesByColumn: Record<string, Set<any>> = {};

  for (const column of table.columns) {
    const columnName = column.id;
    const uniqueValues = new Set();

    table.rows.forEach((row) => {
      const val = row[columnName];
      uniqueValues.add(val);
    });

    uniqueValuesByColumn[columnName] = uniqueValues;
  }

  return uniqueValuesByColumn;
}

export { Table, Column, Row, getUniqueValues };
