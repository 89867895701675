import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objtype',
  standalone: true

})
export class ObjTypePipe implements PipeTransform {

  transform(value: number, mode?: 'point' | 'line') {
    return ObjTypePipe.is(value, mode);
  }

  static is(value: number, mode?: 'point' | 'line') {
    let typ: 'point' | 'line';
    switch (value) {
      case 1: case 3:
        typ = 'point';
        break;
      case 2: case 4:
        typ = 'line';
        break;
    }
    return mode ? typ == mode : typ;
  }
}
