<header class="header-2">
  <div class="branding">
    <span class="title">{{'Werksabnahme konfigurieren' | mrTranslate}}</span>
  </div>
  <div class="header-actions">
    <clr-dropdown>
      <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
        <clr-icon shape="cog"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a (click)="addFormulare()" clrDropdownItem>{{'Neue Vorlage erstellen' | mrTranslate}}</a>

        <div *ngIf="selectionvorlageID">
          <a (click)="saveAsFormulare()" clrDropdownItem>{{'Vorlage speichern unter...' | mrTranslate}}</a>
          <a (click)="deleteFormulare()" clrDropdownItem>{{'Vorlage löschen' | mrTranslate}}</a>
        </div>


        <div class="dropdown-divider" role="separator"></div>
        <a (click)="addTabelle()" clrDropdownItem>{{'Neue Tabelle erstellen' | mrTranslate}}</a>

        <div *ngIf="selectedTabelleID">
          <a (click)="renameTabelle()" clrDropdownItem>{{'Tabelle umbenennen' | mrTranslate}}</a>
          <a (click)="saveAsTabelle()" clrDropdownItem>{{'Tabelle speichern unter...' | mrTranslate}}</a>
          <a (click)="deleteTabelle()" clrDropdownItem>{{'Tabelle löschen' | mrTranslate}}</a>
        </div>

        <!-- <div *ngIf="UseKomponenten === true" class="dropdown-divider" role="separator"></div>
        <a *ngIf="UseKomponenten === true" (click)="addKomponenten()" clrDropdownItem>{{'Neue Komponente erstellen' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="editKomponenten()" clrDropdownItem>{{'Komponente bearbeiten' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="delKomponenten()" clrDropdownItem>{{'Komponente löschen' | mrTranslate}}</a> -->
      </clr-dropdown-menu>
    </clr-dropdown>
    <a href="javascript://" class="nav-link nav-icon">
      <clr-icon shape="times" (click)="close()"></clr-icon>
    </a>
  </div>
</header>



<div class="overlay-inner-wrapper" style="height: calc(100% + 60px);">
  <div id="datenTabs">
    <clr-tabs>

      <!-- 1. TAB: FORMULARERSTELLUNG -->
      <clr-tab>
        <button clrTabLink (click)="tabChange()" [title]="'Formularerstellung' | mrTranslate">{{ 'Vorlagen' |
          mrTranslate | titlecase }}</button>
        <clr-tab-content *clrIfActive>
          <div id="formularerstellungsWrapper">

            <div class="clr-row">
              <div class="clr-col-12" *ngIf="formulare">
                <button class="btn btn-sm" *ngIf="canEdit" (click)="triggerLock()">
                  <clr-icon [attr.shape]="locked ? 'lock' : 'unlock'"></clr-icon>
                </button>

                <button class="btn btn-sm" (click)="addForFormulare()" *ngIf="!locked">+ {{ 'Zeile hinzufügen' | mrTranslate }}</button>
                <button class="btn btn-sm" (click)="deleteForFormulare()" *ngIf="!locked && delItems.length > 0">- {{ 'Zeile löschen' | mrTranslate }}</button>
                <!-- <button class="btn btn-sm" (click)="copyFormulare()" *ngIf="selectedVorlageName">{{ 'Vorlage kopieren' | mrTranslate }}</button> -->
                <clr-select-container  style="margin-top: 0px">
                  <select clrSelect [(ngModel)]="selectedVorlageName" (change)="selectVorlageName(selectedVorlageName)">
                    <option *ngFor="let name of vorlageNames" [value]="name.VorlageName">{{ name.VorlageName }}</option>
                  </select>
                </clr-select-container>

                <div *ngIf="selectedformulare" class="inline-block float-right">
                  <clr-icon *ngIf="cansortup" size="24" shape="arrow" (click)="sortUp()"
                    class="cursor-pointer"></clr-icon>
                  <clr-icon *ngIf="cansortdown" size="24" shape="arrow" (click)="sortDown()" class="cursor-pointer"
                    style="transform: rotate(180deg);"></clr-icon>
                </div>

                <div *ngIf="locked; else unlocked">
                  <div class="limit-height">
                    <clr-datagrid #grid style="padding-top: 0px" class="datagrid-compact" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selectedformulare" (clrDgSingleSelectedChange)="selectionFormulareChanged($event)">
                      <clr-dg-column *ngFor=" let column of formulare['columns']" [clrDgField]="column.id"
                        [hidden]="column.id | hideIDColumns:['Sorting','sorting','SchHMax','BildBase64','Profil', 'intid']">{{column.id | mrTranslate}}</clr-dg-column>
                        <clr-dg-row *clrDgItems="let row of formulare['rows']; trackBy: trackById" [clrDgItem]="row">
                          <clr-dg-cell *ngFor="let cell of formulare['columns']; let j = index" [hidden]="cell.id | hideIDColumns:['Sorting','sorting', 'schhmax','bildbase64','profil', 'intid']">
                            <ng-container *ngIf="cell.id != 'Bezeichnung'; else bezcell">
                              <ng-container *ngIf="cell.id != 'Tabelle' else tabcell">
                                <clr-dg-cell [innerHTML]="row[cell.id] | tablePrettyPrint  | mrTranslate"></clr-dg-cell>
                              </ng-container>
                            </ng-container>
                            <ng-template #bezcell>
                              <clr-dg-cell [innerHTML]="getvalue(row) | tablePrettyPrint  | mrTranslate"></clr-dg-cell>
                            </ng-template>
                            <ng-template #tabcell>
                              <clr-dg-cell [innerHTML]="getvalueTab(row) | tablePrettyPrint  | mrTranslate"></clr-dg-cell>
                            </ng-template>
                          </clr-dg-cell>
                        </clr-dg-row>
                      <clr-dg-footer>
                        <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{formulare['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                  </div>
                </div>

                <ng-template #unlocked>
                  <div class="limit-height">
                    <clr-datagrid class="datagrid-compact" *ngIf="formulare" style="padding-top: 0px;" [(clrDgSingleSelected)]="selectedformulare" (clrDgSingleSelectedChange)="selectionFormulareChanged($event)">
                      <clr-dg-column [hidden]="locked">
                        {{ 'Löschen' | mrTranslate }}
                      </clr-dg-column>
                      <clr-dg-column *ngFor="let column of formulare['columns']" [clrDgField]="column.id"
                        [style.min-width.px]="column.id == 'status' ? 100 : (column.id.length * 7.5) + 45">
                        <ng-container
                          *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['sorting', 'vorlageid', 'delstatus', 'status', 'username']}">
                          {{ (column.id | titlecase | mrTranslate)}}
                        </ng-container>
                      </clr-dg-column>
                      <clr-dg-row *clrDgItems="let row of formulare['rows']; trackBy: trackById" [clrDgItem]="row">
                        <clr-dg-cell [hidden]="locked">
                            <input type="checkbox" clrCheckbox (change)="onItemChanged($event, row, trackById)"/>
                        </clr-dg-cell>
                        <ng-container *ngIf="!locked; else lockedCell">
                          <clr-dg-cell>
                            <select clrInput [(ngModel)]="row[firstColumnId]" [disabled]="!canEdit">
                              <option *ngFor="let option of typOptions" [value]="option">{{ option | mrTranslate }}</option>
                            </select>
                          </clr-dg-cell>
                          <clr-dg-cell>
                            <input clrInput type="text" [(ngModel)]="row[secondColumnId]" [readonly]="locked" style="min-width: 400px;" />
                          </clr-dg-cell>
                          <clr-dg-cell>
                            <ng-container *ngIf="row['Typ'] != 'Überschrift' && row['Typ'] != 'Titel'; else emptycell">
                              <select clrInput type="text" [(ngModel)]="row['Tabelle']" [disabled]="!canEdit"
                                (change)="handleAuswahlOptionChange(row, $event.target.value)">
                                <option *ngFor="let option of auswahlOptions" [value]="option">{{ option | mrTranslate }}</option>
                              </select>
                            </ng-container>
                            <ng-template #emptycell>
                              <clr-dg-cell [innerHTML]=""></clr-dg-cell>
                            </ng-template>
                          </clr-dg-cell>
                          <clr-dg-cell>
                            <ng-container *ngIf="row['Typ'] != 'Überschrift' && row['Typ'] != 'Titel'; else emptycell1">
                              <select clrInput type="text" [(ngModel)]="row['Bezeichnung']" [disabled]="!canEdit" class="datagrid-dropdown">
                                <ng-container *ngIf="row['Tabelle'] === 'Stammdaten'">
                                  <option *ngFor="let option of stammdaten.rows" [value]="option.FeldName">{{ option.Bezeichnung | mrTranslate }}</option>
                                </ng-container>
                                <ng-container *ngIf="row['Tabelle'] === 'Spezifikationsdaten'">
                                  <option *ngFor="let option of spezifikationsdaten.columns" [value]="option.value">{{ option.display }}</option>
                                </ng-container>
                                <ng-container *ngIf="row['Tabelle'] === 'Herstellerdaten'">
                                  <option *ngFor="let option of herstellerdaten.columns" [value]="option">{{ option | mrTranslate }}
                                  </option>
                                </ng-container>
                              </select>
                            </ng-container>
                            <ng-template #emptycell1>
                              <clr-dg-cell [innerHTML]=""></clr-dg-cell>
                            </ng-template>
                          </clr-dg-cell>
                        </ng-container>
                        <ng-template #lockedCell>
                          <clr-dg-cell>
                            <select clrInput class="no-interaction" [(ngModel)]="row[firstColumnId]" [readonly]="locked">
                              <option *ngFor="let option of typOptions" [value]="option">{{ option | mrTranslate }}</option>
                            </select>
                          </clr-dg-cell>
                          <clr-dg-cell>
                            <input clrInput type="text" [(ngModel)]="row[secondColumnId]" [readonly]="locked" />
                          </clr-dg-cell>
                          <clr-dg-cell>
                            <select clrInput class="no-interaction" type="text" [(ngModel)]="row['Tabelle']"
                              [readonly]="locked" (change)="handleAuswahlOptionChange(row, $event.target.value)">
                              <option *ngFor="let option of auswahlOptions" [value]="option">{{ option | mrTranslate }}</option>
                            </select>
                          </clr-dg-cell>
                          <clr-dg-cell>
                            <select clrInput class="no-interaction" type="text" [(ngModel)]="row['Bezeichnung']"
                              [readonly]="locked" class="datagrid-dropdown">
                              <ng-container *ngIf="row['Tabelle'] === 'Stammdaten'">
                                <option *ngFor="let option of stammdaten.columns" [value]="option">{{ option | mrTranslate }}</option>
                              </ng-container>
                              <ng-container *ngIf="row['Tabelle'] === 'Spezifikationsdaten'">
                                <option *ngFor="let option of spezifikationsdaten.columns" [value]="option.value">{{
                                  option.display }}</option>
                              </ng-container>
                              <ng-container *ngIf="row['Tabelle'] === 'Herstellerdaten'">
                                <option *ngFor="let option of herstellerdaten.columns" [value]="option">{{ option | mrTranslate }}
                                </option>
                              </ng-container>
                            </select>

                          </clr-dg-cell>
                        </ng-template>
                        <clr-dg-cell *ngFor="let cell of formulare['columns'].slice(6)"
                          [hidden]="cell.id | hideIDColumns:['sorting', 'vorlageid', 'delstatus', 'status', 'username']">
                          <input clrInput type="text" [(ngModel)]="row[cell.id]" [readonly]="locked" />
                        </clr-dg-cell>
                      </clr-dg-row>
                      <clr-dg-footer>
                        <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{formulare['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                      </clr-dg-footer>
                    </clr-datagrid>
                  </div>
                  <button class="btn btn-primary" (click)="sendForFormulare()" id="saveButton" [disabled]="locked">{{'Daten speichern' | mrTranslate }}</button>
                </ng-template>


              </div>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>

      <!-- 2. TAB: TABELLENERSTELLUNG -->
      <clr-tab>
        <button clrTabLink (click)="tabChange()" [title]="'Tabellenerstellung' | mrTranslate">{{ 'Messwerte' |
          mrTranslate | titlecase }}</button>
        <clr-tab-content *clrIfActive>
          <div id="tabellenerstellungsWrapper">

            <!-- <app-newtabellenamemodal (reload)="handleModalReload()" style="margin-right:auto; align-self:flex-end;"
              (modalOpen)="_modalOpen = $event">
            </app-newtabellenamemodal> -->

            <div class="clr-row">
              <div class="clr-col-12" *ngIf="tabellen">

                <button class="btn btn-sm" *ngIf="canEdit" (click)="triggerLock()">
                  <clr-icon [attr.shape]="locked ? 'lock' : 'unlock'"></clr-icon>
                </button>
                <button class="btn btn-sm" (click)="openHelper()" *ngIf="canEdit">{{ 'Hilfe' | mrTranslate }}</button>

                <button class="btn btn-sm" (click)="addForTabellen()" *ngIf="!locked">+ {{ 'Zeile hinzufügen' | mrTranslate }}</button>


                <div class="clr-row">
                  <div class="clr-col-3">
                    <clr-select-container  style="margin-top: 0px">
                      <select clrSelect [(ngModel)]="selectedTabelleID" (change)="selectTabelleID(selectedTabelleID)" style="max-width: 300px;">
                        <option *ngFor="let tabelle of tabellenNames" [value]="tabelle.ID">{{ tabelle.Bezeichnung }}</option>
                      </select>
                    </clr-select-container>
                  </div>
                  <div class="clr-col-3">
                    <label>{{'Bereich' | mrTranslate}}: {{selcode}}</label>
                  </div>
                  <div class="clr-col-6">
                    <form clrForm style="margin-top: -5px; width: 100%;">
                      <clr-input-container>
                        <label class="clr-col-md-1">{{'Bemerkung' | mrTranslate}}</label>
                        <input style="width: 500px;" clrInput [(ngModel)]="bemerkungmessung" name="name" [readonly]="locked" />
                      </clr-input-container>
                    </form>
                  </div>
                </div>

                <div *ngIf="selectedtabellen" class="inline-block float-right">
                  <clr-icon *ngIf="cansortuptabelle" size="24" shape="arrow" (click)="sortUptable()"
                    class="cursor-pointer"></clr-icon>
                  <clr-icon *ngIf="cansortdowntabelle" size="24" shape="arrow" (click)="sortDowntable()"
                    class="cursor-pointer" style="transform: rotate(180deg);"></clr-icon>
                </div>

                <ng-container *ngIf="selmesstyp == 0; else visuell">
                  <div class="limit-height-500">
                    <clr-datagrid *ngIf="tabellenNames.length > 0" style="padding-top: 0px;" class="datagrid-compact"
                      [(clrDgSingleSelected)]="selectedtabellen"
                      (clrDgSingleSelectedChange)="selectionTabellenChanged($event)">
                      <clr-dg-column [clrDgField]="delete" [hidden]="locked">{{ 'Löschen' | mrTranslate }}</clr-dg-column>
                      <clr-dg-column *ngFor="let column of tabellen['columns']" [clrDgField]="column.id"
                        [hidden]="column.id | hideIDColumns: ['i_sort','szaehnid', 'zart']">
                        {{ (column.id | titlecase | mrTranslate)}}
                      </clr-dg-column>
                      <clr-dg-row *clrDgItems="let row of tabellen['rows']" [clrDgItem]="row" [clrDgSelected]="selectedtabellen">
                        <clr-dg-cell [hidden]="locked">
                          <clr-icon shape="times" (click)="deleteForTabellen(row['ID'])"
                            class="cursor-pointer"></clr-icon>
                        </clr-dg-cell>
                        <clr-dg-cell *ngFor="let cell of tabellen['columns']"
                          [hidden]="cell.id | hideIDColumns: ['i_sort','szaehnid', 'zart']">
                          <input clrInput type="text" [(ngModel)]="row[cell.id]" [readonly]="locked" />
                        </clr-dg-cell>
                      </clr-dg-row>
                      <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination
                        [clrDgPageSize]="100">{{pagination.totalItems}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                    </clr-datagrid>
                  </div>
                </ng-container>

              </div>

              <ng-template #visuell>
                <div>
                  <div class="limit-height-500">
                    <clr-datagrid *ngIf="tabellenNames.length > 0" style="padding-top: 0px;" class="datagrid-compact"
                      [(clrDgSingleSelected)]="selectedtabellen"
                      (clrDgSingleSelectedChange)="selectionTabellenChanged($event)">
                      <clr-dg-column [clrDgField]="delete" [hidden]="locked">{{ 'Löschen' | mrTranslate }}</clr-dg-column>
                      <clr-dg-column *ngFor="let column of tabellen['columns']" [clrDgField]="column.id"
                        [hidden]="column.id | hideIDColumns: ['i_sort','szaehnid', 'zart', 'soll', 'tol +', 'tol -']">
                        {{ (column.id | titlecase | mrTranslate)}}
                      </clr-dg-column>
                      <clr-dg-row *clrDgItems="let row of tabellen['rows']" [clrDgItem]="row"
                        [clrDgSelected]="selectedtabellen">
                        <clr-dg-cell [hidden]="locked">
                          <clr-icon shape="times" (click)="deleteForTabellen(row['ID'])"
                            class="cursor-pointer"></clr-icon>
                        </clr-dg-cell>
                        <clr-dg-cell *ngFor="let cell of tabellen['columns']"
                          [hidden]="cell.id | hideIDColumns: ['i_sort','szaehnid', 'zart', 'soll' , 'tol +', 'tol -']">

                            <input clrInput type="text" [(ngModel)]="row[cell.id]" [readonly]="locked" style="width: calc(50vw);" />

                        </clr-dg-cell>
                      </clr-dg-row>
                      <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination
                        [clrDgPageSize]="100">{{pagination.totalItems}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
                    </clr-datagrid>
                  </div>
                </div>
              </ng-template>

              <div class="clr-row">
                <div class="clr-col">
                  <button class="btn btn-primary" (click)="sendForTabellen()" id="saveButton" [disabled]="locked">{{'Daten speichern' | mrTranslate }}</button>
                </div>
              </div>

              <br/>
              <div class="clr-col-12">
                <app-preview-thumbnails #preview [showCamera]="false" [isSkizze]="true" [readonly]="locked" [acceptTypes]="'.png'" [maxAmount]="6"  [drag_move]="true"></app-preview-thumbnails>
              </div>
            </div>
          </div>
        </clr-tab-content>
      </clr-tab>

    </clr-tabs>
  </div>
</div>

<app-hilfe-beschreibung-modal
  (onSave)="saveHelper($event)">
</app-hilfe-beschreibung-modal>

<clr-modal [(clrModalOpen)]="isVorlageAddVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{modaltitel}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical">
      <div class="clr-row">
        <div class="clr-col-12">
          <div class="comboboxcontainer">
            <div class="clr-control-container" style="width: 300px">
              <input id="_aufgroup_name_new" class="clr-input combobox" placeholder="{{'Bezeichnung' | mrTranslate}}" #_vorlage_new />
            </div>
          </div>
          <br>
          <br>
        </div>
      </div>
    </form>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addVorlage()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isVorlageAddVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isZaehlerAddVisible" [clrModalClosable]="true">
  <h3 class="modal-title">{{modaltitel | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm clrLayout="vertical" [formGroup]="optionMessTable">
      <div class="clr-row">
        <div class="clr-col-3">
          <label>{{'Bereich' | mrTranslate}}</label>
        </div>
        <div class="clr-col-9">
          <div class="clr-control-container"  style="width: 300px">
            <input class="clr-input combobox" type="search"
              name="pruefart" #_zaehlercode list="pruefartList" autocomplete="off" />
          </div>
          <datalist id="pruefartList">
            <option *ngFor="let obj of tabellenName" [value]="obj"></option>
          </datalist>

        </div>
      </div>
      <br/>
      <div class="clr-row">
        <div class="clr-col-3">
          <label>{{'Bezeichnung' | mrTranslate}}</label>
        </div>
        <div class="clr-col-9">
          <div class="comboboxcontainer">
            <div class="clr-control-container" style="width: 300px">
              <input id="_aufgroup_name_new" class="clr-input combobox" #_zaehlerbezeichnung />
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div class="clr-row" *ngIf="dorename === 0">
        <div class="clr-col-3">
          <label>{{'Typ' | mrTranslate}}</label>
        </div>
        <div class="clr-col-9">
          <clr-select-container  style="margin-top: 0px">
            <select clrSelect name="options" formControlName="messart">
              <option value="0">{{'messen' | mrTranslate}}</option>
              <option value="1">{{'visuell' | mrTranslate}}</option>
            </select>
          </clr-select-container>
        </div>
      </div>
    </form>
    <br>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="addZaehler()">{{'OK' | mrTranslate}}</button>
    <button type="button" class="btn" (click)="isZaehlerAddVisible = false">{{'Abbrechen' | mrTranslate}}</button>
  </div>
</clr-modal>
