import { Pipe, type PipeTransform } from '@angular/core';
import dayjs from 'dayjs';


export type CompareOperator = 'before' | '<' | 'after' | '>' | 'sameOrBefore' | '<=' | 'sameOrAfter' | '>=' | 'same' | '==';

@Pipe({
  name: 'compareDate',
  standalone: true

})
export class CompareDatePipe implements PipeTransform {
  /**
   * @description nur value: value is `same` as current `day` ?
   * @alias check
   * @param operator - `<` | `<=` | `>` | `>=` | `==` - default 'same'
   * @param unit - `day` - default | `month` | `year` | `hour` | `minute` usw.
   * @param compareWith - default today
   * @example row.status | compareDate:'before':'month'
   * @example row.status | compareDate:'same':'day':row.erledigtAm
   * @example this.compareDate.check(row.status)
   */
  transform(
    value: string,
    operator?: CompareOperator,
    unit?: dayjs.OpUnitType,
    compareWith?: string
  ): boolean {
    const date = dayjs(value);
    const dateToCompareWith = dayjs(compareWith);
    switch (operator) {
      case 'after':
      case '>':
        return date.isAfter(dateToCompareWith, unit || 'days');
      case 'before':
      case '<':
        return date.isBefore(dateToCompareWith, unit || 'days');
      case 'sameOrAfter':
      case '>=':
        return dateToCompareWith.isBefore(date, unit || 'days');
      case 'sameOrBefore':
      case '<=':
        return dateToCompareWith.isAfter(date, unit || 'days');
      case 'same':
      case '==':
      default:
        return date.isSame(dateToCompareWith, unit || 'days');
    }
  }

  public check = this.transform;
}
