<clr-modal [(clrModalOpen)]="isBerichteModalOpen" [clrModalSize]="'xl'">
  <h3 class="modal-title">
    {{ "Berichte verwalten" | mrTranslate }}
    <span *ngIf="!saved" class="label label-warning" >
      <span class="text"> {{ "nicht gespeichert" | mrTranslate }} </span>
    </span>
  </h3>
  <div class="modal-body" style="height: 65vh; height: 65svh;">
    <div class="clr-row nxm h-100">
      <div class="clr-col-lg-6 h-100">
        <h5 style="margin-top: 0;"> {{ "verfügbare Berichte" | mrTranslate }} </h5>
        <div style="height: calc(100% - 24px); overflow: auto;">
          <clr-tree>
            <clr-tree-node *ngFor="let header of berichtsArten" [clrExpanded]="true">
              <span> {{ header.name | mrTranslate }} </span>
              <clr-tree-node class="basealign" *ngFor="let art of header.arten" [(clrExpanded)]="alerts[art.id].expanded">
                <span class="label" [class.label-success]="alerts[art.id]?.count > 0">
                  <span class="text"> {{ "Aktive" | mrTranslate }} </span>
                  <span class="badge" [class.badge-success]="alerts[art.id]?.count > 0"> {{ alerts[art.id]?.count }}</span>
                </span>
          
                <span> {{ art.titel | mrTranslate }} </span>
          
                <clr-tree-node *ngFor="let alert of alerts[art.id]?.items" [ngClass]="{'datagrid-row datagrid-selected': selectedAlert == alert}">
                  <clr-toggle-container class="margin-0-t">
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle [ngModel]="alert.active" (ngModelChange)="toggleActive($event, art.id, alert)" />
                    </clr-toggle-wrapper>
                  </clr-toggle-container>
                  <a href="javascript:void(0);" (click)="selectedAlert = alert; description = art.description" style="color: currentColor;">
                    {{ (sendRepeatTermin[alert?.termin] || "nie") | mrTranslate | titlecase }}
                    {{ " " + ("an" | mrTranslate) + " " }}
                    {{ alert?.users?.nameStr || ("niemanden" | mrTranslate) }}
                  </a>
                  <button type="button" (click)="deleteAlert(art.id, alert)"
                    class="btn btn-sm btn-link btn-icon"
                    style="margin-left: auto; flex: 0 0 45px;" [title]="'Löschen'| mrTranslate">
                    <clr-icon shape="times" size="18" style="color: tomato;" class="nxm"></clr-icon>
                  </button>
          
                </clr-tree-node>
                <clr-tree-node>
                  <a href="javascript:void(0);" (click)="newAlert(art.id); description =  art.description">
                    {{ "Neu erstellen" | mrTranslate }}
                  </a>
                </clr-tree-node>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
      <div class="clr-col-lg-6 mh-100" style="overflow: auto;">
        <h5 style="margin-top: 0;"> {{ "Personen" | mrTranslate }} </h5>
        <clr-datagrid #userDg *ngIf="users.rows?.length" class="datagrid-compact dg-overflow-fix" style="max-height: 30svh;" 
          [(clrDgSelected)]="selectedUsers"
          [appClrCheckboxDisplay]="!!selectedAlert">
          <clr-dg-column
            *ngFor="let column of users['columns']"
            [clrDgField]="column.id"
            [hidden]="column.id | hideIDColumns:['userimagebase64', 'status', 'username', 'alias']">
            {{ column.id | mrTranslate }}
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of users['rows']" [clrDgItem]="row" [clrDgSelectable]="!!selectedAlert">
            <clr-dg-cell
              *ngFor="let cell of users['columns']"
              [hidden]="cell.id | hideIDColumns:['userimagebase64', 'status', 'username', 'alias']"
              class="name-cell"
              [innerHTML]="row[cell.id] | tablePrettyPrint">
            </clr-dg-cell>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-pagination
              class="dg-pagination"
              style="right: 60px"
              #pagination
              [clrDgPageSize]="5"
            >
              {{ pagination.totalItems }} {{ "Zeilen" | mrTranslate }}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
        <div class="clr-row nxm">
          <h5 class="clr-col-12"> {{ "Einstellungen" | mrTranslate }} </h5>
          <div class="clr-col-6">
            <clr-radio-container class="margin-6-t">
              <label class="clr-col-12 clr-col-md-12">
                {{ "Bericht erstellen" | mrTranslate }}
              </label>
              <clr-radio-wrapper *ngFor="let termin of sendRepeatTermin | keyvalue">
                <label>{{ termin.value | mrTranslate }}</label>
                <input [disabled]="!selectedAlert" name="termin" type="radio" clrRadio [value]="termin.key" 
                  [ngModel]="selectedAlert?.termin" 
                  (ngModelChange)="selectedAlert.termin = $event; selectedAlert.isChanged = true; saved = false"
                />
              </clr-radio-wrapper>
            </clr-radio-container>
          </div>
          <div class="clr-col-6">
            <clr-radio-container class="margin-6-t">
              <label class="clr-col-12 clr-col-md-12">
                {{ "Export Format" | mrTranslate }}
              </label>
              <clr-radio-wrapper>
                <label>{{ "*.xlsx" }}</label>
                <input [disabled]="!selectedAlert" name="format" type="radio" clrRadio [value]="0" 
                  [ngModel]="selectedAlert?.format" 
                  (ngModelChange)="selectedAlert.format = $event; selectedAlert.isChanged = true; saved = false"
                />
              </clr-radio-wrapper>
              <clr-radio-wrapper>
                <label>{{ "*.pdf" }}</label>
                <input [disabled]="!selectedAlert" name="format" type="radio" clrRadio [value]="1" 
                  [ngModel]="selectedAlert?.format" 
                  (ngModelChange)="selectedAlert.format = $event; selectedAlert.isChanged = true; saved = false"
                />
              </clr-radio-wrapper>
            </clr-radio-container>
          </div>
          <h5 class="clr-col-12"> {{ "Beschreibung" | mrTranslate }} </h5>
          <span class="clr-col-12"> 
            {{ description ? (description | mrTranslate) 
              : ("Wählen Sie eine Konfiguration des Berichts" | mrTranslate)
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="saveBerichteConfig()">
        {{ "Speichern" | mrTranslate }}
      </button>
      <button type="button" class="btn btn-outline-danger" (click)="isBerichteModalOpen = false">
        {{ (saved ? "Schliessen" : "Abbrechen") | mrTranslate }}
      </button>
  </div>
</clr-modal>