<button class="btn btn-sm" (click)="openTheModal()">{{'Auftrag erstellen' | mrTranslate }} <clr-spinner
    *ngIf="isLoading" [clrInline]="true" [clrSmall]="true"></clr-spinner></button>

<div *ngIf="openModal">
  <clr-wizard id="newAuftragWizard" #wizard [clrWizardOpen]="openModal" (clrWizardOnCancel)="reset(true)"
    (clrWizardOnReset)="reset(true)" (clrWizardCurrentPageChanged)="changePage($event)">
    <clr-wizard-title>
      {{'Auftrag erstellen' | mrTranslate }}
      <clr-checkbox-wrapper style="display:inline-block;">
        <input type="checkbox" clrToggle name="options" [(ngModel)]="objectSelectMode" />
        <label>{{'Mängel über Objekte auswählen' | mrTranslate}}</label>
      </clr-checkbox-wrapper>
    </clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{'Abbrechen' | mrTranslate}}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{'Zurück' | mrTranslate}}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{'Weiter' | mrTranslate}}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{'Auftrag absenden' | mrTranslate}}</clr-wizard-button>

    <clr-wizard-page #pageOne
      [clrWizardPageNextDisabled]="!auftragGroup?.value?.startdate || !auftragGroup?.value?.enddate || !auftragGroup?.value?.auftragnehmer || !auftragGroup?.value?.auftragsgruppe || !auftragGroup?.value?.titel">
      <ng-template clrPageTitle>{{'Auftragsdaten' | mrTranslate}}</ng-template>
      <ng-template clrPageNavTitle>{{'Auftragsdaten' | mrTranslate}}</ng-template>
      <form class="login" id="auftragform" clrForm [formGroup]="auftragGroup" (ngSubmit)="onSubmit()">

        <div class="clr-row clr-justify-content-start" id="auftragsRow0">


          <div class="clr-col-2">
            <label for="titelInput">{{'Titel' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <div class="comboboxcontainer">
                <div class="clr-control-container">
                  <input id="titel" class="clr-input combobox" list="titellist" placeholder="" type="search" name="titel"
                    formControlName="titel"/>
                </div>
                <datalist id="titellist">
                  <option *ngFor="let c of titel" [value]="c">{{ c }}</option>
                </datalist>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-break-row"><br></div>

        <div class="clr-row clr-justify-content-start" id="auftragsRow1">
          <div class="clr-col-2">
            <label for="dateInputStart">{{'Startdatum' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <clr-date-container>
                <input id="dateInputStart" type="date" clrDate
                [ngModel]="auftragGroup.get('startdate').value | localeDate"
                (ngModelChange)="auftragGroup.get('startdate').setValue(localeDate.tosql($event))"
                [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
              </clr-date-container>
            </div>
          </div>
        </div>

        <div class="clr-break-row"><br></div>

        <div class="clr-row clr-justify-content-start" id="auftragsRow2">
          <div class="clr-col-2">
            <label for="dateInputEnd">{{'Enddatum' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <clr-date-container>
                <input id="dateInputEnd" type="date" clrDate
                [ngModel]="auftragGroup.get('enddate').value | localeDate"
                (ngModelChange)="auftragGroup.get('enddate').setValue(localeDate.tosql($event))"
                [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
              </clr-date-container>
            </div>
          </div>
        </div>

        <div class="clr-break-row"><br></div>

        <div class="clr-row clr-justify-content-start" id="auftragsRow3">
          <div class="clr-col-2">
            <label for="auftragnehmer">{{'Auftragnehmner' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <div class="select">
                <clr-select-container id="auftragnehmerWrapper">
                  <select id="auftragnehmer" clrSelect formControlName="auftragnehmer">
                    <option [value]="null" disabled selected>Auftragnehmer auswählen</option>
                    <option *ngFor="let firm of firmen" [value]="firm.ID">{{ firm.mFirma }}</option>
                  </select>
                </clr-select-container>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-break-row"><br></div>

        <div class="clr-row clr-justify-content-start" id="auftragsRow4">
          <div class="clr-col-2">
            <label for="auftragsgruppeInput">{{'Auftragsgruppe' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <div class="select">
                <clr-select-container id="auftragsgruppeWrapper">
                  <select id="auftragsgruppe" clrSelect formControlName="auftragsgruppe">
                    <option [value]="null" disabled selected>Auftragsgruppe auswählen</option>
                    <option *ngFor="let groups of auftragsgroups" [value]="groups.id">{{ groups.mBezeichnung }}</option>
                  </select>
                </clr-select-container>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-break-row"><br></div>

        <div class="clr-row clr-justify-content-start" id="auftragsRow5">
          <div class="clr-col-2">
            <label for="auftragsart">{{'Auftragsart' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <div class="comboboxcontainer">
                <div class="clr-control-container">
                  <input id="auftragsart" class="clr-input combobox" placeholder="" type="search" name="auftragsart"
                    formControlName="auftragsart" list="auftragsartList" />
                </div>
                <datalist id="auftragsartList">
                  <option *ngFor="let c of auftragsart" [value]="c">{{ c }}</option>
                </datalist>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-break-row"><br></div>

        <div class="clr-row clr-justify-content-start" id="auftragsRow6">
          <div class="clr-col-2">
            <label for="auftragsnrInput">{{'Auftragsnummer' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <div class="comboboxcontainer">
                <div class="clr-control-container">
                  <input id="auftragsnr" class="clr-input combobox" placeholder="" type="search" name="auftragsnr"
                    formControlName="auftragsnr"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="clr-break-row"><br></div>

        <div class="clr-row clr-justify-content-start" id="auftragsRow7">
          <div class="clr-col-2">
            <label for="kostenartInput">{{'Kostenart' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <div class="comboboxcontainer">
                <div class="clr-control-container">
                  <input id="kostenart" class="clr-input combobox" placeholder="" type="search" name="kostenart"
                    formControlName="kostenart" list="kostenartList"/>
                </div>
                <datalist id="kostenartList">
                  <option *ngFor="let c of kostenart" [value]="c">{{ c }}</option>
                </datalist>
              </div>
            </div>
          </div>
        </div>



        <div class="clr-break-row"><br></div>



        <div class="clr-row clr-justify-content-start" id="auftragsRow7">
          <div class="clr-col-2">
            <label for="auftragtextarea">{{'Bemerkung' | mrTranslate}}</label>
          </div>
          <div class="clr-col clr-align-items-start">
            <div style="display:flex">
              <clr-textarea-container style="max-width:100%;margin-left: 2.5em;">
                <textarea clrTextarea rows="3" id="auftragtextarea" formControlName="bemerkung" style="width:100%;"></textarea>
              </clr-textarea-container>
            </div>
          </div>
        </div>
      </form>
    </clr-wizard-page>

    <clr-wizard-page #pageTwo *ngIf="objectSelectMode"
      [clrWizardPageNextDisabled]="!selectedObjekte || selectedObjekte?.length == 0">
      <ng-template clrPageTitle>{{'Objekte' | mrTranslate}}</ng-template>
      <ng-template clrPageNavTitle>{{'Objekte' | mrTranslate}}</ng-template>

      <clr-datagrid *ngIf="objekte?.rows?.length > 0" [(clrDgSelected)]="selectedObjekte"
        [clrDgPreserveSelection]="true" class="datagrid-compact newAuftragTable h-100"
        (clrDgRefresh)="refreshDatagrid(objekteDatagrid)" #objekteDatagrid>
        <clr-dg-column *ngFor="let column of objekte['columns']" [clrDgField]="column.id"
          [style.min-width.px]="(column.id.length * 7.5) + 26">
          <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['bezirk','stellwerk', 'rep']}">
            {{ column.id | titlecase | mrTranslate }}
          </ng-container>
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let row of objekte['rows']" [clrDgItem]="row">
          <ng-container *ngFor="let cell of objekte['columns']">
            <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
              <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                [title]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </ng-container>
            <ng-template #iconcell>
              <clr-dg-cell style="color:#000">
                <ng-container [ngSwitch]="cell.id.toLowerCase()">
                  <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                    [attr.shape]="row['typ_icon'] ? 'objekttyp'+row['typ_icon'] : 'unknown-status'" size="28">
                  </clr-icon>
                  <clr-icon *ngSwitchCase="'rep'" class="customIcon"
                    [ngStyle]="{'color': row['Rep'] == 0 ? 'green' : 'red'}"
                    [attr.shape]="row['Rep'] == 0 ? 'shield-check' : 'shield-x'" size="25"></clr-icon>
                  <clr-icon *ngSwitchCase="'sperrstatus'" class="customIcon"
                    [attr.shape]="row['SperrStatus'] ? 'gesperrt' : 'minus'" size="28">
                  </clr-icon>
                  <clr-icon *ngSwitchCase="'lfstatus'" class="customIcon"
                    [attr.shape]="row['lfstatus'] && row['lfstatus'] > 0  ? 'lastelle'+(row['lfstatus']/10).toString().replace('.',',') : 'minus'"
                    size="28">
                  </clr-icon>
                </ng-container>
              </clr-dg-cell>
            </ng-template>
          </ng-container>
        </clr-dg-row>
        <clr-dg-footer>
          <clr-dg-column-toggle></clr-dg-column-toggle>
          <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="100">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
            </clr-dg-page-size>
            {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
            {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
          </clr-dg-pagination>
        </clr-dg-footer>
      </clr-datagrid>
    </clr-wizard-page>

    <clr-wizard-page #pageThree
      [clrWizardPageNextDisabled]="(!createNewMaengel && (!selectedMaengel || selectedMaengel?.length == 0)) || (createNewMaengel && newMaengel?.rows?.length == 0)"
      class="maengel">
      <ng-template clrPageTitle>
        {{createNewMaengel ? ('Neue Mängel erstellen' | mrTranslate) :
        ('Existierende Mängel auswählen' | mrTranslate)}}
        <ng-container *ngIf="objectSelectMode">
          <span style="margin-left: 0.3em;">|</span>
          <clr-checkbox-wrapper style="margin-left:0.6em; display:inline-block;">
            <input type="checkbox" clrToggle name="options" [(ngModel)]="createNewMaengel" />
            <label>{{'Neue Mängel erstellen' | mrTranslate}}</label>
          </clr-checkbox-wrapper>
        </ng-container>
      </ng-template>
      <ng-template clrPageNavTitle>{{'Mängel' | mrTranslate}}</ng-template>

      <div *ngIf="objectSelectMode && createNewMaengel">
        <div class="clr-row">
          <div class="clr-col-6">
            <form clrForm id="newMangelForm" [formGroup]="newMangelGroup">
              <div class="comboboxcontainer clr-form-control clr-row newMangelInput">
                <label class="clr-col-md-4 clr-control-label">{{'Titel' | mrTranslate}}</label>
                <div class="clr-control-container clr-col-md-8">
                  <input class="clr-input combobox" placeholder="Titel" type="search" name="titel"
                    formControlName="titel" list="titelList" autocomplete="off" />
                </div>
              </div>

              <clr-textarea-container class="newMangelInput">
                <label class="clr-col-md-4" for="Beschreibungtextarea">{{'Beschreibung' | mrTranslate}}</label>
                <textarea class="clr-col-md-8" clrTextarea rows="3" id="Beschreibungtextarea"
                  formControlName="beschreibung"></textarea>
              </clr-textarea-container>

              <clr-input-container class="clr-form-control newMangelInput">
                <label class="clr-col-md-4">{{'Umfang' | mrTranslate}}</label>
                <input class="clr-col-md-8" clrInput type="text" formControlName="umfang" />
              </clr-input-container>

              <clr-select-container class="clr-form-control newMangelInput">
                <label class="clr-col-md-4">{{'Mengeneinheit' | mrTranslate}} </label>
                <select class="clr-col-md-8" clrSelect formControlName="mengeneinheit">
                  <option value="0" selected disabled>{{'Auswahl Mengeneinheiten' | mrTranslate}}</option>
                  <option *ngFor="let val of mengeneinheiten" [value]="val.bezeichnung">{{val.bezeichnung |
                    mrTranslate}}</option>
                </select>
              </clr-select-container>


              <clr-textarea-container class="newMangelInput">
                <label class="clr-col-md-4" for="bemerkungtextarea">{{'Bemerkung' | mrTranslate}}</label>
                <textarea class="clr-col-md-8" clrTextarea rows="3" id="bemerkungtextarea"
                  formControlName="bemerkung"></textarea>
              </clr-textarea-container>

              <button class="btn btn-sm btn-primary" (click)="createMangel()">{{'Mangel erstellen' |
                mrTranslate}}</button>
            </form>

          </div>
          <div class="clr-col-6">
            <clr-datagrid class="datagrid-compact maengel newAuftragTable" #newMangelTable>
              <clr-dg-column *ngIf="newMaengel['columns'].length > 0" [clrDgField]="delete" [style.max-width.px]="30">
                {{'Löschen' | mrTranslate}}</clr-dg-column>
              <clr-dg-column *ngFor="let column of newMaengel['columns']" [clrDgField]="column.id">
                <ng-container
                  *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['osperrstatus','osperrid','sperrstatus', 'lfstatus']}">
                  {{ column.id | titlecase | mrTranslate }}
                </ng-container>
              </clr-dg-column>
              <clr-dg-row *clrDgItems="let row of newMaengel['rows']" [clrDgItem]="row">
                <clr-dg-cell>
                  <clr-icon shape="times" (click)="deleteNewMangel(row)" class="cursor-pointer"></clr-icon>
                </clr-dg-cell>
                <clr-dg-cell *ngFor="let cell of newMaengel['columns']"
                  [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                  [title]="row[cell.id] | tablePrettyPrint">
                </clr-dg-cell>
              </clr-dg-row>
              <clr-dg-footer>
                <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage"
                  [clrDgPageSize]="rownr">
                  {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                  {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                </clr-dg-pagination>
              </clr-dg-footer>
            </clr-datagrid>

          </div>
        </div>
      </div>

      <div *ngIf="!createNewMaengel" style="height:100%">

        <!-- ! Maengel gibt's immer, 
        ?-- maengel?.rows wahrscheinlich
        * besser: mnglTable, nur die, die Objekten entsprechen 
        !-->

        <clr-datagrid *ngIf="maengel" [(clrDgSelected)]="selectedMaengel"
          (clrDgRefresh)="refreshDatagrid(maengelDatagrid)" class="datagrid-compact maengel newAuftragTable"
          [clrDgPreserveSelection]="true" #maengelDatagrid>
          <clr-dg-column *ngFor="let column of maengel['columns']" [clrDgField]="column.id"
            [style.min-width.px]="(column.id.length * 7.5) + 26">
            <ng-container
              *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['osperrstatus','osperrid','sperrstatus','lfstatus']}">{{
              column.id | titlecase | mrTranslate }}
            </ng-container>
          </clr-dg-column>
          <clr-dg-row *clrDgItems="let row of maengel['rows']" [clrDgItem]="row">
            <!-- <clr-dg-cell *ngFor="let cell of maengel['columns']"
              [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint" [title]="row[cell.id] | tablePrettyPrint"
              [ngStyle]="{'min-width': cell.id == 'ort' ? '145px' : 'initial'}">
            </clr-dg-cell> -->
            <ng-container *ngFor="let cell of maengel['columns']">
              <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
                <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                  [title]="row[cell.id] | tablePrettyPrint">
                </clr-dg-cell>
              </ng-container>
              <ng-template #iconcell>
                <clr-dg-cell style="color:#000">
                    <clr-icon class="customIcon"
                      [attr.shape]="row['icon'] ? 'objekttyp'+row['icon'] : 'unknown-status'" size="28">
                    </clr-icon>
                </clr-dg-cell>
              </ng-template>
            </ng-container>
          </clr-dg-row>
          <clr-dg-footer>
            <clr-dg-column-toggle></clr-dg-column-toggle>
            <clr-dg-pagination class="dg-pagination" #pagination [(clrDgPage)]="currentPage" [clrDgPageSize]="100"> 
              <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
              </clr-dg-page-size>
              {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
              {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>

    </clr-wizard-page>

    <clr-wizard-page #pageFour [clrWizardPagePreventDefaultNext]="true" [clrWizardPageNextDisabled]="trial"
      (clrWizardPageOnCommit)="onSubmit()">
      <ng-template clrPageTitle>{{'Zusammenfassung' | mrTranslate}}</ng-template>

      <table style="width:100%">
        <tr style="height:22px">
          <td style="width:25%">{{'Titel' | mrTranslate}}:</td>
          <td>{{auftragGroup.value.titel}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Auftragnehmer' | mrTranslate}}:</td>
          <td>{{getFirmenName(auftragGroup.value.auftragnehmer)}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Auftragsgruppe' | mrTranslate}}:</td>
          <td>{{getAuftragsgruppeName(auftragGroup.value.auftragsgruppe)}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Startdatum' | mrTranslate}}:</td>
          <td>{{auftragGroup.value.startdate}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Enddatum' | mrTranslate}}:</td>
          <td>{{auftragGroup.value.enddate}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Auftragsart' | mrTranslate}}:</td>
          <td>{{auftragGroup.value.auftragsart}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Auftragsnummer' | mrTranslate}}:</td>
          <td>{{auftragGroup.value.auftragsnr}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Kostenart' | mrTranslate}}:</td>
          <td>{{auftragGroup.value.kostenart}}</td>
        </tr>
        <tr style="height:22px">
          <td style="width:25%">{{'Bemerkung' | mrTranslate}}:</td>
          <td>{{auftragGroup.value.bemerkung}}</td>
        </tr>
      </table>
      <br>

      <ng-container *ngIf="selectedObjekte?.length > 0">
        <h4 style="margin-top: 0.6em;">{{'Ausgewählte Objekte' | mrTranslate}}: {{selectedObjekte.length}}</h4>
        <table class="table table-compact" style="display:block; overflow-y: auto; margin-top: 0.6em;">
          <thead>
            <tr>
              <ng-container *ngFor="let objCol of objekte['columns']">
                <th class="left" *ngIf="!(objCol.id | hideIDColumns:['bezirk','stellwerk', 'rep','typ'])">
                  {{objCol.id | titlecase | mrTranslate}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let obj of selectedObjekte">
              <ng-container *ngFor="let objCol of objekte['columns']">
                <td class="left" *ngIf="!(objCol.id | hideIDColumns:['bezirk','stellwerk', 'rep','typ'])"
                  [title]="obj[objCol.id] | tablePrettyPrint"
                  [innerHTML]="obj[objCol.id] | dotDotDot:30 | tablePrettyPrint"></td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </ng-container>

      <ng-container *ngIf="newMaengel?.rows?.length > 0">
        <h4 style="margin-top: 0.6em;">{{'Neu angelegte Mängel' | mrTranslate}}: {{newMaengel.length}}</h4>
        <table class="table table-compact" style="overflow-y: auto; margin-top: 0.6em;">
          <thead>
            <tr>
              <ng-container *ngFor="let mnglCol of newMaengel['columns']">
                <th class="left" *ngIf="!(mnglCol.id | hideIDColumns:['osperrstatus','osperrid','sperrstatus', 'lfstatus'])">
                  {{mnglCol.id | titlecase | mrTranslate}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mngl of newMaengel.rows">
              <ng-container *ngFor="let mnglCol of newMaengel['columns']">
                <td class="left" *ngIf="!(mnglCol.id | hideIDColumns:['osperrstatus','osperrid','sperrstatus', 'lfstatus'])"
                  [title]="mngl[mnglCol.id] | tablePrettyPrint"
                  [innerHTML]="mngl[mnglCol.id] | dotDotDot:30 | tablePrettyPrint"></td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </ng-container>

      <ng-container *ngIf="selectedMaengel?.length > 0">
        <h4 style="margin-top: 0.6em;">{{'Ausgewählte Mängel' | mrTranslate}}: {{selectedMaengel.length}}</h4>
        <table class="table table-compact" style="display:block; overflow-y: auto; margin-top: 0.6em;">
          <thead>
            <tr>
              <ng-container *ngFor="let mnglCol of maengel['columns']">
                <th class="left" *ngIf="!(mnglCol.id | hideIDColumns:['osperrstatus','osperrid','sperrstatus', 'lfstatus'])">
                  {{mnglCol.id | titlecase | mrTranslate}}</th>
              </ng-container>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let mngl of getSelectedMaengel()">
              <ng-container *ngFor="let mnglCol of maengel['columns']">
                <td class="left" *ngIf="!(mnglCol.id | hideIDColumns:['osperrstatus','osperrid','sperrstatus', 'lfstatus'])"
                  [title]="mngl[mnglCol.id] | tablePrettyPrint"
                  [innerHTML]="mngl[mnglCol.id] | dotDotDot:30 | tablePrettyPrint"></td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </clr-wizard-page>
  </clr-wizard>

</div>
