import { CommonModule, DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ClarityModule } from "@clr/angular";
import { AngularDeviceInformationService } from "angular-device-information";
import * as Excel from 'exceljs';
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { first } from "rxjs/operators";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from 'src/app/services/APIService/api.service';
import { CameramodalComponent } from "../../_modals/cameramodal/cameramodal.component";

@Component({
  selector: "app-bahnuebergangsdialog",
  templateUrl: "./bahnuebergangsdialog.component.html",
  styleUrls: ["./bahnuebergangsdialog.component.scss"],
  imports: [ CommonModule, ClarityModule,  CameramodalComponent, MrTranslatePipe, FormsModule, ReactiveFormsModule, HideIDColumnsPipe, TablePrettyPrintPipe ],
  standalone: true
})

export class BahnuebergangsdialogComponent implements OnInit {
  currentIndex: number = 0;

  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Output() modalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  os:any;
  previewThumbnails: any[];
  selImage:number;
  pruefid:string;
  selectedBue: any = undefined;
  table:any;
  setInsert:any = false;
  isDirty:any = false;
  title:string = "Bahnübergänge verwalten";
  type:number;
  openModal = false;
  showBildModal: boolean = false;
  bildData: any;
  showSVGModal: boolean = false;
  SelQuadrant: number = 0;
  pList: any;
  stBezirk : string = "";
  stBezeichnung: string = "";
  dataRefresh = new EventEmitter();

  set data(dataIn: any) {
    if (dataIn) {
        //this.selSpez = dataIn.selSpez;
        this.table = dataIn.table;
        this.pruefid = dataIn.opruefid;
        this.type = dataIn.type;

        this.previewThumbnails = Array(8).fill({
          src: "",
          file: "",
          date: "",
          base64: "",
        });
        if(dataIn.dataRefresh) {
          this.dataRefresh = dataIn.dataRefresh;
        }

        this.freshReload();
    }
  }

  formBueValues = new UntypedFormGroup({
    bersa: new UntypedFormControl("", [Validators.required]),
    vstmax: new UntypedFormControl(""),
    vstmin: new UntypedFormControl(""),
    ve: new UntypedFormControl(""),
    d: new UntypedFormControl(""),
    sa: new UntypedFormControl(""),
  });

  constructor(
    private apiservice: APIService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    private deviceInformationService: AngularDeviceInformationService,
  ) {

  }


  initBue() {
    this.formBueValues.controls.bersa.setValue("Kraftfahrzeug")
    this.showSVGModal = true;
    this.SelQuadrant = 0;
    this.loadBUEData(this.SelQuadrant);
  }

  loadBUEData(dir: number) {
    this.apiservice
    .getBUE(parseInt(this.pruefid))
    .pipe(first())
    .subscribe((res: any) => {
      if (res.table?.rows?.length) {
        var data = res.table;
        var col = dir == 0 ? "Quadrant 1 + 2" : "Quadrant 3 + 4";
        this.formBueValues.controls.vstmax.setValue(data.rows[0][col]);
        this.formBueValues.controls.vstmin.setValue(data.rows[1][col]);
        this.formBueValues.controls.ve.setValue(data.rows[2][col]);
        this.formBueValues.controls.d.setValue(data.rows[3][col]);
        this.formBueValues.controls.sa.setValue(data.rows[4][col]);
        this.stBezeichnung = data.rows[0]["BezeichnungStamm"];
        this.stBezirk = data.rows[0]["Bezirk"];
      } else {
        this.formBueValues.reset();
      }
    });
  }
  deleteBILDBue(num: any) {
    const text = this.mrTranslate.transform("Bild wirklich löschen?");
    if (confirm(text)) {
    this.apiservice
      .deleteBUE(parseInt(this.pruefid),num)
      .pipe(first())
      .subscribe((res: any) => {
        this.isDirty = false;
        if (res["status"] === "success") {
          this.previewThumbnails[num] = null;
          this.toastr.success(this.mrTranslate.transform("Daten gelöscht"));
          this.freshReload();
        }else {
          this.toastr.error(this.mrTranslate.transform("Fehler beim Löschen"));
        }
      });
    }
  }


  freshReload() {
    this.table = undefined;
    this.apiservice
    .getBUE(parseInt(this.pruefid))
    .pipe(first())
    .subscribe((res: any) => {
      this.table = res.table;
      if(this.table?.rows?.length) {
        this.stBezeichnung = this.table.rows[0]["BezeichnungStamm"];
        this.stBezirk = this.table.rows[0]["Bezirk"];
      }

      this.pList = res.pList;
      if(this.pList) {
        for (let index = 0; index < this.pList.length; index++) {
          const val = this.pList[index];
          this.previewThumbnails[index] = ({
            src: val.bild,
            file: val.file,
            date: this.datePipe.transform(Date.now(), 'dd.MM.yyyy HH.mm.ss.SSS'),
            base64: null,
          });
        }
      }
    });
  }


  saveBue() {
    let c: any = {}
    c.DoInsert = this.setInsert;
    var test = true;
    if (this.formBueValues.controls.d.value == null || this.formBueValues.controls.d.value.toString().length == 0) {
      this.toastr.warning(this.mrTranslate.transform("Feld zum Wert 'd' muss ausgefüllt werden!"));
      test = false;
    }
    if (this.formBueValues.controls.sa.value == null || this.formBueValues.controls.sa.value.toString().length == 0) {
      this.toastr.warning(this.mrTranslate.transform("Feld zum Wert 'sa' muss ausgefüllt werden!"));
      test = false;
    }

    if (!test) return;
    c.table = this.formBueValues.value;
    delete c.table.bersa;
    c.images = this.previewThumbnails;
    c.direction = this.SelQuadrant;
    //c.otypid = this.otypid;

    this.apiservice
      .setBUE(parseInt(this.pruefid),c)
      .pipe(first())
      .subscribe((res: any) => {
        this.isDirty = false;
        this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
      });
  }
  close() {
    this.closedialog.next(true);
  }

  ngOnInit() {
    this.os = this.deviceInformationService.getDeviceInfo().os
  }

  openCamera(num: number) {
    this.selImage = num;
    this.openModal = true;
  }
  setQuad(num:number) {
    this.saveBue();
    this.SelQuadrant = num;
    this.loadBUEData(this.SelQuadrant);
  }

  showPreviewImage(image: any){
    this.bildData = image.src;
    this.showBildModal = true;
  }

  onModalClose() {
    let c: any = {}
    this.openModal = false;

    let val = localStorage.getItem('foto');

    if (val){

      let r = val.toString() as String;
      var splitted = r.split(',');
      c.curImage = val;
      c.images = this.previewThumbnails;
      c.index = this.selImage;
      this.apiservice
      .setBUEImage(parseInt(this.pruefid),c)
      .pipe(first())
      .subscribe((res: any) => {
        this.isDirty = false;
        this.toastr.success(this.mrTranslate.transform("Bild gespeichert"));
        let img = {
          src: val,
          file: res,
          date: this.datePipe.transform(Date.now(),'dd.MM.yyyy HH.mm.ss.SSS'),
          base64: splitted[1],
        };
        this.previewThumbnails[this.selImage] = img;
      });
      localStorage.removeItem('foto');
    }
  }

  createExcelDoc() {
    var Zuschlag = [];
    var Faktor = [];
    var index: number;
    var breiteL: number;
    var breiteR: number;
    var strassenArt: string
    var veMax: string
    var veMaxGegen: string
    var vStMax: string
    var vStMaxGegen: string
    var vStMin: string
    var vStMinGegen : string
    var ta: number = 0.0;
    var vms: number = 0.0;
    Zuschlag.push(9)
    Zuschlag.push(9)
    Zuschlag.push(10)
    Zuschlag.push(13)
    Zuschlag.push(19)

    Faktor.push(0.09)
    Faktor.push(0.12)
    Faktor.push(0.18)
    Faktor.push(0.36)
    Faktor.push(0.72)

    strassenArt = ""
    breiteL = this.table.rows[3]["Quadrant 1 + 2"] ? this.table.rows[3]["Quadrant 1 + 2"] : 6;
    breiteR = this.table.rows[3]["Quadrant 3 + 4"] ? this.table.rows[3]["Quadrant 3 + 4"] : 6;
    veMax = this.table.rows[2]["Quadrant 1 + 2"] ? this.table.rows[2]["Quadrant 1 + 2"]  : "50";
    veMaxGegen = this.table.rows[2]["Quadrant 3 + 4"] ? this.table.rows[2]["Quadrant 3 + 4"] : "50";
    vStMax = this.table.rows[1]["Quadrant 1 + 2"] ? this.table.rows[1]["Quadrant 1 + 2"]  : "50";
    vStMaxGegen = this.table.rows[1]["Quadrant 3 + 4"] ? this.table.rows[1]["Quadrant 3 + 4"] : "50";
    vStMin = this.table.rows[0]["Quadrant 1 + 2"] ? this.table.rows[0]["Quadrant 1 + 2"]  : "10";
    vStMinGegen = this.table.rows[0]["Quadrant 3 + 4"] ? this.table.rows[0]["Quadrant 3 + 4"] : "10";

    this.apiservice
      .getBUEExcelTemplate()
      .pipe(first())
      .subscribe((res: any) => {
        var file = Buffer.from(res, 'base64');

        const wb = new Excel.Workbook();
        wb.xlsx.load(file).then(() => {
          const ws = wb.worksheets[0];
          ws.getCell('E5').value = this.stBezeichnung;
          ws.getCell('D7').value = this.stBezirk;
          ws.getCell('C16').value = breiteR;
          ws.getCell('C21').value = breiteL;
          ws.getCell('C27').value = breiteL;
          ws.getCell('C32').value = breiteR;
          ws.getCell('E15').value = veMax;
          ws.getCell('E26').value = veMaxGegen;

          // D-Zeile = Vst; F-Zeile = sa
          index = -1;
          for (var i = 16; i <= 36; i++) {
            if (i != 26) // Trenner-Zeile
            {
              var n = 5;
              index += 1;
              if (index > 4)
                index = 0;

              var vstVal = ws.getCell("D" + i.toString()).value.toString();
              if (vstVal == "50/40")
                vstVal = "50 u. 40";

              if (i <= 20 || i >= 32) {
                // if (vstVal == vStMax) {
                //   ws.getCell("D" + i.toString()).fill = {type: 'pattern', pattern:'solid', fgColor:{argb:'FFC0C0C0'}};
                // }
                // if (vstVal == vStMin) {
                //   ws.getCell("D" + i.toString()).fill = {type: 'pattern', pattern:'solid', fgColor:{argb:'FFC0C0C0'}};
                // }
                ta = Zuschlag[index] + breiteR * Faktor[index];
              }
              else {
                // if (vstVal == vStMaxGegen) {
                //   ws.getCell("D" + i.toString()).fill = {type: 'pattern', pattern:'solid', fgColor:{argb:'FFC0C0C0'}};
                // }
                // if (vstVal == vStMinGegen) {
                //   ws.getCell("D" + i.toString()).fill = {type: 'pattern', pattern:'solid', fgColor:{argb:'FFC0C0C0'}};
                // }
                ta = Zuschlag[index] + breiteL * Faktor[index];
              }

              vms = i > 26 ? parseFloat(veMaxGegen) / 3.6: parseFloat(veMax) / 3.6;

              ws.getCell("F" + i.toString()).value = (ta * vms).toFixed(0);
            }
          }
          wb.xlsx.writeBuffer().then((buffer) => {
            this.apiservice.downloadBlobAsFile(buffer, "Bahnübergang_" + this.stBezeichnung + "_" + this.stBezirk + ".xlsx");
          });
        });
      });
  }

}
