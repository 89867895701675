
<clr-modal *ngIf="bild" [(clrModalOpen)]="showBildModal" [clrModalSize]="'lg'" class="customModal"
  [clrModalClosable]="true" [clrModalStaticBackdrop]="false">
  <div class="modal-body">
    <img [src]="bild" />
  </div>
</clr-modal>


<header class="header-2">
  <div class="branding">
      <span class="title">{{'Vorlagen für Spezifikationen verwalten' | mrTranslate}}</span>
  </div>
  <div class="header-actions">
<!--
    <clr-dropdown>
      <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
        <clr-icon shape="cog"></clr-icon>
      </button>
      <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
        <a (click)="addObjekt()" clrDropdownItem>{{'Neues Objekt erstellen' | mrTranslate}}</a>
        <div *ngIf="UseKomponenten === true" class="dropdown-divider" role="separator"></div>
        <a *ngIf="UseKomponenten === true" (click)="addKomponenten()" clrDropdownItem>{{'Neue Komponente erstellen' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="editKomponenten()" clrDropdownItem>{{'Komponente bearbeiten' | mrTranslate}}</a>
        <a *ngIf="selnodeid !== 0" (click)="delKomponenten()" clrDropdownItem>{{'Komponente löschen' | mrTranslate}}</a>
      </clr-dropdown-menu>
    </clr-dropdown>
     -->
    <a href="javascript://" class="nav-link nav-icon">
      <clr-icon  shape="times" (click)="close()"></clr-icon >
    </a>
  </div>
</header>

<div class="overlay-inner-wrapper">
  <div id="objekttpyWrapper">
    <div class="btn-group btn-primary btn-icon">
      <button class="btn btn-success" (click)="newitem()">
        <clr-icon shape="file"></clr-icon>
        <span class="clr-icon-title">Add</span>
      </button>
      <button class="btn btn-danger" [disabled]="selid == 0" (click)="deletitem()">
        <clr-icon shape="times"></clr-icon>
        <span class="clr-icon-title">Delete</span>
      </button>
    </div>
    <form clrForm class="clr-form">
      <div class="limit-height">
        <clr-datagrid *ngIf="stammdaten?.columns" style="padding-top: 0px" class="datagrid-compact" [clrDgRowSelection]="true" [(clrDgSingleSelected)]="selecteditem" (clrDgSingleSelectedChange)="selectionFirmaChanged($event)">
          <clr-dg-column *ngFor=" let column of stammdaten['columns']" [clrDgField]="column.id"
            [hidden]="column.id | hideIDColumns:['TypID','Profiltyp','schhmax','bildbase64','Profil']">{{column.id | mrTranslate}}</clr-dg-column>
            <clr-dg-row *clrDgItems="let row of stammdaten['rows']"  [clrDgItem]="row">

              <ng-container *ngFor="let cell of stammdaten['columns']">
                <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1;else iconcell">
                  <clr-dg-cell [hidden]="cell.id | hideIDColumns:['TypID','Profiltyp','schhmax','bildbase64','Profil']"
                  [innerHTML]="row[cell.id] | tablePrettyPrint"
                  [title]="row[cell.id] | tablePrettyPrint | dotDotDot : 30">
                </clr-dg-cell>
                </ng-container>
                <ng-template #iconcell>
                  <ng-container *ngIf="row[cell.id];else nullcell">
                    <clr-dg-cell style="color: #000">
                      <clr-icon shape="image" size="28" [ngStyle]="{ color: 'green'}">
                      </clr-icon>
                    </clr-dg-cell>
                  </ng-container>
                  <ng-template #nullcell>
                    <clr-dg-cell style="color: #000">
                      <clr-icon shape="blank" size="28" [ngStyle]="{ color: 'green'}">
                      </clr-icon>
                    </clr-dg-cell>
                  </ng-template>

                </ng-template>
              </ng-container>
            </clr-dg-row>
            <!-- <clr-dg-row *clrDgItems="let row of stammdaten['rows']"  [clrDgItem]="row">
              <clr-dg-cell class="name-cell" *ngFor="let cell of stammdaten['columns']"
                [hidden]="cell.id | hideIDColumns:['TypID','profiltyp', 'schhmax','bildbase64','profil']" [innerHTML]="row[cell.id] | tablePrettyPrint">
              </clr-dg-cell>
            </clr-dg-row> -->



            <ng-template [(clrIfDetail)]="detailState" let-detail (clrIfDetailChange)="onDetailOpen($event)">
              <clr-dg-detail>
                <clr-dg-detail-header>{{ detail['Code'] }}</clr-dg-detail-header>
                <clr-dg-detail-body>
                  <form clrForm class="clr-form" clrLayout="horizontal">
                    <div class="clr-row">
                      <div class="clr-col-2">
                        {{'Code' | mrTranslate}}
                      </div>
                      <div class="clr-col-2">
                        <input type="text" id="basic" placeholder="Enter value here" #bez class="clr-input" value="{{detail['Code']}}" #code />
                      </div>
                    </div>
                    <br/>
                    <div class="clr-row">
                      <div class="clr-col-2">
                        {{'Schienenform' | mrTranslate}}
                      </div>
                      <div class="clr-col-2">
                        <div class="clr-select-wrapper">
                          <select class="clr-select" #sel [(ngModel)]="selectedSchienen" [ngModelOptions]="{standalone: true}" (change)="selectedSchienechanged(sel.value)">
                            <option *ngFor="let item of schienen.rows" [value]="item.Bezeichnung">
                              {{ item.Bezeichnung}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="clr-col-1">
                        <clr-icon shape="note" size="22" (click)="openWerteliste('Schienenform', 'SSCHF','')" class="cursor-pointer"></clr-icon>
                      </div>
                    </div>
                    <br/>
                    <div class="clr-row">
                      <div class="clr-col-2">
                        {{'Schwellenart' | mrTranslate}}
                      </div>
                      <div class="clr-col-2">
                        <div class="clr-select-wrapper">
                          <select class="clr-select" [(ngModel)]="selectedSchwellenart" [ngModelOptions]="{standalone: true}" style="margin-top: -25p; width: 100%;">
                            <option *ngFor="let item of schwellenart.rows" [value]="item.Bezeichnung">
                              {{ item.Bezeichnung}}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="clr-col-1">
                        <clr-icon shape="note" size="22" (click)="openWerteliste('Schwellenart', 'SSCHW','')" class="cursor-pointer"></clr-icon>
                      </div>
                    </div>
                    <br/>

                    <div class="clr-row">
                      <div class="clr-col-2">
                        {{'Schienenbefestigung' | mrTranslate}}
                      </div>
                      <div class="clr-col-2">
                        <div class="clr-select-wrapper">
                          <select class="clr-select" [(ngModel)]="selectedSchienenbefestigung" [ngModelOptions]="{standalone: true}" style="margin-top: -25p; width: 100%;">
                            <option *ngFor="let item of schienenbefestigung.rows" [value]="item.Bezeichnung">
                              {{ item.Bezeichnung}}
                            </option>
                          </select>
                        </div>

                      </div>
                      <div class="clr-col-1">
                        <clr-icon shape="note" size="22" (click)="openWerteliste('Schienenbefestigung', 'SBEFT','')" class="cursor-pointer"></clr-icon>
                      </div>
                    </div>
                    <br/>

                    <div class="clr-row">
                      <div class="clr-col-2">
                        {{'Eindeckung' | mrTranslate}}
                      </div>
                      <div class="clr-col-2">
                        <div class="clr-select-wrapper">
                          <select class="clr-select" #ein [(ngModel)]="selectedEindeckung" [ngModelOptions]="{standalone: true}" (change)="selectedSchienechanged(ein.value)" style="margin-top: -25p; width: 100%;">
                            <option *ngFor="let item of eindeckung.rows" [value]="item.Bezeichnung">
                              {{ item.Bezeichnung}}
                            </option>
                          </select>
                        </div>

                      </div>
                      <div class="clr-col-1">
                          <clr-icon shape="note" size="22" (click)="openWerteliste('Eindeckung', 'SEIND','')" class="cursor-pointer"></clr-icon>
                      </div>
                    </div>
                    <br/>

                    <div class="clr-row">
                      <div class="clr-col-2">
                        {{'geschlossen' | mrTranslate}}
                      </div>
                      <div class="clr-col-1">
                        <input type="checkbox" clrCheckbox [checked]="detail['Geschlossen'] == true" #geschlossen/>
                      </div>
                    </div>
                    <br/>

                    <div class="clr-row">
                      <div class="clr-col-2">
                        {{'Preis pro m' | mrTranslate}}
                      </div>
                      <div class="clr-col-2">
                        <input type="number" id="basic" class="clr-input" value="{{detail['EPreis']}}" #epreis />
                      </div>
                    </div>
                    <br/>
                    <div class="clr-row" style="margin-top: 5px;" *ngIf="!bild; else mitbild">
                      <div class="clr-col-2">
                        <div>{{'Bild' | mrTranslate}}</div>
                      </div>
                      <div class="clr-col-2">
                        <clr-icon class="icon" shape="plus-circle" (click)="fInput.click()" class="cursor-pointer" size="22"></clr-icon>
                      </div>
                    </div>

                    <ng-template #mitbild>
                      <div class="clr-row" style="margin-top: 5px;">
                        <div class="clr-col-2">
                          <div>{{'Bild' | mrTranslate}}</div>
                        </div>
                        <div class="clr-col-2">
                          <img class="image" [src]="bild" (click)="openBildemodal()">
                        </div>
                        <div class="clr-col-1">
                          <clr-icon class="icon" shape="times" [ngStyle]="{ color: 'red'}" (click)="deleteImage()" class="cursor-pointer" size="22"></clr-icon>
                        </div>
                      </div>
                    </ng-template>
                    <input #fInput type="file" id="fileUpload" name="fileUpload" accept="image/jpeg" style="display:none;" (change)="onBildChanged($event)"/>

                    <div class="clr-row" style="margin-top: 15px; margin-left: 10px;">
                      <button type="button" class="btn btn-icon btn-primary" aria-label="Speichern" (click)="saveitem()">
                        <clr-icon shape="floppy"></clr-icon>
                      </button>
                    </div>
                  </form>

                </clr-dg-detail-body>
              </clr-dg-detail>
            </ng-template>
          <clr-dg-footer>
            <clr-dg-pagination class="dg-pagination" style="right:60px" #pagination [clrDgPageSize]="100">{{stammdaten['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
      </div>




<!--


      <clr-input-container>
        <label>{{ "Code" | mrTranslate }}</label>
        <input clrInput type="text" name="example" formControlName="code" />
      </clr-input-container>
      <clr-select-container>
        <label>{{ "Schienenform" | mrTranslate }}</label>
        <select clrSelect [(ngModel)]="selectedSchienen">
          <option [value]="undefined" disabled selected>
            {{ "Schienenform auswählen" | mrTranslate }}
          </option>
          <option *ngFor="let item of schienen" [value]="item">
            {{ item}}
          </option>
        </select>
      </clr-select-container>

      <clr-select-container>
        <label>{{ "Schwellenart" | mrTranslate }}</label>
        <select clrSelect [(ngModel)]="selectedSchwellenart">
          <option [value]="undefined" disabled selected>
            {{ "Schwellenart auswählen" | mrTranslate }}
          </option>
          <option *ngFor="let item of schwellenart" [value]="item">
            {{ item}}
          </option>
        </select>
      </clr-select-container>

      <clr-select-container>
        <label>{{ "Schienenbefestigung" | mrTranslate }}</label>
        <select clrSelect [(ngModel)]="selectedSchienenbefestigung">
          <option [value]="undefined" disabled selected>
            {{ "Schienenbefestigung auswählen" | mrTranslate }}
          </option>
          <option *ngFor="let item of schienenbefestigung" [value]="item">
            {{ item}}
          </option>
        </select>
      </clr-select-container>

      <clr-select-container>
        <label>{{ "Eindeckung" | mrTranslate }}</label>
        <select clrSelect [(ngModel)]="selectedEindeckung">
          <option [value]="undefined" disabled selected>
            {{ "Eindeckung auswählen" | mrTranslate }}
          </option>
          <option *ngFor="let item of eindeckung" [value]="item">
            {{ item}}
          </option>
        </select>
      </clr-select-container>
 -->

      <!--
      <div class="clr-row padding-10-t">
        <div class="clr-col-1">
          <div>{{ "Code" | mrTranslate }}</div>
        </div>
        <div class="clr-col">
          <div>{{ "Schienenform" | mrTranslate }}</div>
        </div>
        <div class="clr-col">
          <div>{{ "Schwellenart" | mrTranslate }}</div>
        </div>
        <div class="clr-col">
          <div>{{ "Schienenbefestigung" | mrTranslate }}</div>
        </div>
        <div class="clr-col">
          <div>{{ "Eindeckung" | mrTranslate }}</div>
        </div>
        <div class="clr-col-1">
          <div>{{ "geschlossen" | mrTranslate }}</div>
        </div>
        <div class="clr-col-1">
          <div>{{ "Kosten pro m" | mrTranslate }}</div>
        </div>
        <div class="clr-col-1">
          <div>{{ "Bild" | mrTranslate }}</div>
        </div>
      </div>
      <div class="clr-row padding-10-t">
        <div class="clr-col-1">
          <input type="text" id="basic" placeholder="Enter value here" #bezfield class="clr-input" formControlName="code"/>
        </div>
        <div class="clr-col">
          <clr-select-container style="display: inline-block ; margin-top: -25px;">
            <select clrSelect [(ngModel)]="selectedSchienen">
              <option [value]="undefined" disabled selected>
                {{ "Schienenform auswählen" | mrTranslate }}
              </option>
              <option *ngFor="let item of schienen" [value]="item">
                {{ item}}
              </option>
            </select>
          </clr-select-container>
        </div>
        <div class="clr-col">
          <clr-select-container style="display: inline-block ; margin-top: -25px;">
            <select clrSelect [(ngModel)]="selectedSchwellenart">
              <option [value]="undefined" disabled selected>
                {{ "Schwellenart auswählen" | mrTranslate }}
              </option>
              <option *ngFor="let item of schwellenart" [value]="item">
                {{ item}}
              </option>
            </select>
          </clr-select-container>
        </div>
        <div class="clr-col">
          <clr-select-container style="display: inline-block ; margin-top: -25px;">
            <select clrSelect [(ngModel)]="selectedSchienenbefestigung">
              <option [value]="undefined" disabled selected>
                {{ "Schienenbefestigung auswählen" | mrTranslate }}
              </option>
              <option *ngFor="let item of schienenbefestigung" [value]="item">
                {{ item}}
              </option>
            </select>
          </clr-select-container>
        </div>
        <div class="clr-col">
          <clr-select-container style="display: inline-block; margin-top: -25px;">
            <select clrSelect [(ngModel)]="selectedEindeckung">
              <option [value]="undefined" disabled selected>
                {{ "Eindeckung auswählen" | mrTranslate }}
              </option>
              <option *ngFor="let item of eindeckung" [value]="item">
                {{ item}}
              </option>
            </select>
          </clr-select-container>
        </div>
        <div class="clr-col-1">
          <input type="checkbox" clrCheckbox [value]="0"  formControlName="geschlossen" (change)="onGeschlossenChange($event)" />
        </div>
        <div class="clr-col-1">
          <input type="number" id="basic" placeholder="Enter value here" #bezfield class="clr-input" formControlName="kosten"/>
        </div>
        <div class="clr-col-1">
          <div>{{ "Bild" | mrTranslate }}</div>
        </div>
      </div>
 -->


    </form>
    <div class="content-container">
      <div id="content-area" class="content-area">
        <app-basedatamodal></app-basedatamodal>
      </div>
    </div>
  </div>
</div>



