import { DataType, isBooleanType, isDateType, isNumberType, isTextType } from "../../models/ui/datatype";
import { BooleanFilter } from "./booleanfilter";
import { DateFilter } from "./datefilter";
import { NumberFilter } from "./numberfilter";
import { StringFilter } from "./stringfilter";
import { FilterItem, FilterOperator } from "src/app/models/ui/filter";

export class FilterFactory {
  createFilter(data: FilterItem[], dataType: DataType | string): IFilter {
    if (isNumberType(dataType)) {
      return new NumberFilter(data);
    } else if (isTextType(dataType)) {
      return new StringFilter(data);
    } else if (isDateType(dataType)) {
      return new DateFilter(data);
    } else if (isBooleanType(dataType)) {
      return new BooleanFilter(data);
    } else {
      throw new Error(`Cannot create filter - Invalid data type ${dataType}`);
    }
  }
}



export interface IFilter {
  filter(data1: string, data2: string, operator: FilterOperator): any[];
}
