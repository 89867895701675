import { Component, ViewChild, ElementRef } from "@angular/core";
import { APIService } from "../../../../services/APIService/api.service";
import { first } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import * as _ from "underscore";
import { AuthService } from "src/app/services/Auth/auth.service";
import { ToastrService } from "ngx-toastr";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { ClrCheckboxDisplayDirective } from "src/app/directives/clrCheckboxDisplay.directive";
import { CommonModule } from "@angular/common";
import { ClarityModule } from "@clr/angular";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HideIDColumnsPipe } from "src/app/pipes/hide-idcolumns.pipe";

@Component({
    selector: "app-addressverwaltungsdialog",
    templateUrl: "./addressverwaltungsdialog.component.html",
    styleUrls: ["./addressverwaltungsdialog.component.scss"],
    imports: [CommonModule, ClarityModule, ClrCheckboxDisplayDirective, MrTranslatePipe, FormsModule, ReactiveFormsModule, HideIDColumnsPipe],
    standalone: true
})
export class AddressverwaltungsdialogComponent {
  public closedialog: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild('_firma') _firma: ElementRef;
  @ViewChild('_abteilung') _abteilung: ElementRef;
  @ViewChild('_strasse') _strasse: ElementRef;
  @ViewChild('_plz') _plz: ElementRef;
  @ViewChild('_ort') _ort: ElementRef;
  @ViewChild('_postfach') _postfach: ElementRef;
  @ViewChild('_land') _land: ElementRef;
  @ViewChild('_tel') _tel: ElementRef;
  @ViewChild('_ap') _ap: ElementRef;
  @ViewChild('_ap_tel') _ap_tel: ElementRef;
  @ViewChild('_ap_mob') _ap_mob: ElementRef;
  @ViewChild('_ap_mail') _ap_mail: ElementRef;
  @ViewChild('grid') grid: ElementRef;
  firmen: any;
  groups: any[] = [];
  selgroups: any[] = [];
  canEdit: boolean = true;
  locked: boolean = true;
  selectedfirma: any = undefined;
  editfirmaShow: boolean = false;
  modaltitel: string = '';

  //@ViewChild(ClrDatagrid) grid: ClrDatagrid;

  trial = false;
  curFirmaid: number;
  editFirma: any;
  selectedUserGroups: any[] = [];

  constructor(
    //@Inject(MAT_DIALOG_DATA) public data,
    private apiservice: APIService,
    private authservice: AuthService,
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
  ) {
    this.trial = this.authservice.getToken().trial;
    this.reload();
  }

  reload() {
    this.apiservice
      .getFirmenAll()
      .pipe(first())
      .subscribe((val) => {
        this.firmen = val;
      });

    this.apiservice
      .getGroups()
      .pipe(first())
      .subscribe((val: any) => {
        this.groups = val;
      });
  }
  selectionFirmaChanged(value: any) {
    console.log(value.ID);

    this.apiservice
      .getFirmenUserGruppen(value.ID)
      .pipe(first())
      .subscribe((val: any) => {
        this.selectedUserGroups = [];
        if (val?.length) {
          this.selectedUserGroups = this.groups.filter((grp) => val.includes(grp.id));
        }
      });
  }

  selectionGroupChanged() {
    let f = this.selectedfirma.ID;
    let a = this.selectedUserGroups.map((grp) => grp.id);
    let toSend: any = {
      id: f,
    };

    toSend.groups = {
      toAdd: a
    };

    this.apiservice
      .setFirmenUserGruppen(toSend)
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
      });

  }

  public close() {
    this.closedialog.next(true);
  }

  updateFirma() {

    if (this._firma.nativeElement.value == '') {
      this.toastr.error(this.mrTranslate.transform("Geben Sie eine Firma ein"));
      return;
    }
    if (this._abteilung.nativeElement.value == '') {
      this.toastr.error(this.mrTranslate.transform("Bitte tragen Sie einen Abteilung ein !!"));
      return;
    }

    if (this._plz.nativeElement.value == '') {
      this.toastr.error(this.mrTranslate.transform("Bitte tragen Sie einen Postleitzahl ein !!"));
      return;
    }

    if (this._ort.nativeElement.value == '') {
      this.toastr.error(this.mrTranslate.transform("Bitte tragen Sie einen Ort ein !!"));
      return;
    }

    if (this.firmen.rows.some((firma) => (firma.ID != this.curFirmaid || this.curFirmaid == -1) && firma.Firma.toLowerCase() == this._firma.nativeElement.value.toLowerCase() && firma.Abteilung.toLowerCase() == this._abteilung.nativeElement.value.toLowerCase() && firma.Ort.toLowerCase() == this._ort.nativeElement.value.toLowerCase())) {
      this.toastr.error(this.mrTranslate.transform("Firma") + ' ' + this.mrTranslate.transform("ist bereits vorhanden...!"));
      return;
    }

    if (this.curFirmaid == -1) {
      let blank: any = {};
      blank["ID"] = "INSERT";
      blank["Abteilung"] = undefined;
      blank["Ansprechpartner Handy"] = undefined;
      blank["Ansprechpartner Mail"] = undefined;
      blank["Ansprechpartner Name"] = undefined;
      blank["Ansprechpartner Telefon"] = undefined;
      blank["Faxzentrale"] = null;
      blank["Firma"] = undefined;
      blank["Gesperrt"] = null;
      blank["Land"] = undefined;
      blank["Ort"] = undefined;
      blank["PLZ"] = undefined;
      blank["Postfach"] = undefined;
      blank["Straße"] = undefined;
      blank["Telefonzentrale"] = undefined;

      this.editFirma = blank;
    }

    this.editFirma.Firma = this._firma.nativeElement.value;
    this.editFirma.Abteilung = this._abteilung.nativeElement.value;
    this.editFirma.Straße = this._strasse.nativeElement.value;
    this.editFirma.PLZ = this._plz.nativeElement.value;
    this.editFirma.Ort = this._ort.nativeElement.value;
    this.editFirma.Land = this._land.nativeElement.value;
    this.editFirma.Postfach = this._postfach.nativeElement.value;
    this.editFirma.Telefonzentrale = this._tel.nativeElement.value;
    this.editFirma['Ansprechpartner Name'] = this._ap.nativeElement.value;
    this.editFirma['Ansprechpartner Telefon'] = this._ap_tel.nativeElement.value;
    this.editFirma['Ansprechpartner Handy'] = this._ap_mob.nativeElement.value;
    this.editFirma['Ansprechpartner Mail'] = this._ap_mail.nativeElement.value;

    this.apiservice
      .editFirmen(this.editFirma)
      .pipe(first())
      .subscribe((val) => {
        if (val) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          this.editfirmaShow = false;
          this.firmen = val;
          this.reload();
        }
        else {
          this.toastr.error(this.mrTranslate.transform("Leider ist ein Fehler aufgetreten."));
        }
      });


  }

  newShowFirma() {
    this.curFirmaid = -1;

    this._firma.nativeElement.value = '';
    this._abteilung.nativeElement.value = '';
    this._strasse.nativeElement.value = '';
    this._plz.nativeElement.value = '';
    this._ort.nativeElement.value = '';
    this._postfach.nativeElement.value = '';
    this._land.nativeElement.value = '';
    this._tel.nativeElement.value = '';
    this._ap.nativeElement.value = '';
    this._ap_tel.nativeElement.value = '';
    this._ap_mob.nativeElement.value = '';
    this._ap_mail.nativeElement.value = '';

    this.modaltitel = 'Neue Firma anlegen';
    this.editfirmaShow = true;
  }

  editShowFirma(row: any) {
    this.curFirmaid = row.ID;
    this.editFirma = row;

    this._firma.nativeElement.value = row.Firma;
    this._abteilung.nativeElement.value = row.Abteilung;
    this._strasse.nativeElement.value = row.Straße;
    this._plz.nativeElement.value = row.PLZ;
    this._ort.nativeElement.value = row.Ort;
    this._land.nativeElement.value = row.Land;
    this._postfach.nativeElement.value = row.Postfach;
    this._tel.nativeElement.value = row.Telefonzentrale;
    this._ap.nativeElement.value = row['Ansprechpartner Name'];
    this._ap_tel.nativeElement.value = row['Ansprechpartner Telefon'];
    this._ap_mob.nativeElement.value = row['Ansprechpartner Handy'];
    this._ap_mail.nativeElement.value = row['Ansprechpartner Mail'];

    this.modaltitel = 'Firma bearbeiten';
    this.editfirmaShow = true;
  }

  deleteFirma(id: number) {
    if (confirm("Sind Sie sicher, dass Sie diese Adresse löschen wollen?")) {
      this.apiservice
        .deleteFirma(id)
        .pipe(first())
        .subscribe((val) => {
          if (val) {
            this.toastr.success(this.mrTranslate.transform("Adresse gelöscht"));
            this.firmen = val;
          }
        });
    }
  }
}
