<clr-modal [clrModalSize]="'xl'" [(clrModalOpen)]="isMultiAuftragModalOpen">
  <h3 class="modal-title">{{'Auftrag erstellen' | mrTranslate}}</h3>
  <div class="modal-body">
    <form clrForm #form="ngForm">
      <clr-date-container>
        <label class="clr-col-md-2">{{'Startdatum' | mrTranslate}}</label>
        <input class="clr-col-md-10" type="date" required name="startdate" clrDate
          [ngModel]="multiauftrag.startdate | localeDate"
          (ngModelChange)="multiauftrag.startdate = localeDate.tosql($event)"
          [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
        <clr-control-error *clrIfError="'required'">
          {{ "Das Datum muss eingegeben werden" | mrTranslate }}
        </clr-control-error>
      </clr-date-container>
      <clr-date-container>
        <label class="clr-col-md-2">{{'Enddatum' | mrTranslate}}</label>
        <input class="clr-col-md-10" type="date" required name="enddate" clrDate
          [ngModel]="multiauftrag.enddate | localeDate"
          (ngModelChange)="multiauftrag.enddate = localeDate.tosql($event)"
          [ngModelOptions]="{standalone: true, updateOn: 'blur'}" />
        <clr-control-error *clrIfError="'required'">
          {{ "Das Datum muss eingegeben werden" | mrTranslate }}
        </clr-control-error>  
      </clr-date-container>
      <clr-select-container clrInline>
        <label class="clr-col-md-2">{{'Auftragnehmer' | mrTranslate}}</label>
        <select class="clr-col-md-10" required name="select" clrSelect [(ngModel)]="multiauftrag.auftragnehmer">
          <option *ngFor="let firm of firmen" [value]="firm.ID">
            {{firm.Firma + ', ' + firm.Abteilung + ', ' + firm.Ort}}</option>
        </select>
        <clr-control-error *clrIfError="'required'">
          {{ "Das Feld muss ausgefüllt werden" | mrTranslate }}
        </clr-control-error>
      </clr-select-container>
      <div class="comboboxcontainer clr-form-control clr-row">
        <label class="clr-col-md-2 clr-control-label">{{'Auftragsart' | mrTranslate}}</label>
        <div class="clr-control-container clr-col-md-10">
          <input class="clr-input combobox" placeholder="" type="search" name="auftragsart"
            [(ngModel)]="multiauftrag.auftragsart" list="auftragsArtList" style="width: auto;" />
        </div>
        <datalist id="auftragsArtList">
          <option value="SAP">
        </datalist>
      </div>
      <clr-input-container clrInline>
        <label class="clr-col-md-2">{{'Auftragsnummer' | mrTranslate}}</label>
        <input class="clr-col-md-10" clrInput placeholder="" name="auftragsnummer"
          [(ngModel)]="multiauftrag.auftragsnr" />
      </clr-input-container>
      <clr-textarea-container>
        <label class="clr-col-md-2">{{'Bemerkung' | mrTranslate}}</label>
        <textarea class="clr-col-md-10" clrTextarea [(ngModel)]="multiauftrag.bemerkung" name="bemerkung">
        </textarea>
      </clr-textarea-container>

      <div style="margin-top: 16px;">
        <clr-accordion class="nopadding">
          <clr-accordion-panel>
            <clr-accordion-title>
              {{ "Mängel" | mrTranslate }}
            </clr-accordion-title>
            <clr-accordion-content *clrIfExpanded>
              <table class="table table-compact margin-0-t">
                <thead>
                  <tr>
                    <th class="left">{{'Ort' | mrTranslate}}</th>
                    <th class="left">{{'Bezirk' | mrTranslate}}</th>
                    <th class="left">{{'Stellwerk' | mrTranslate}}</th>
                    <th class="left">{{'Prüfdatum' | mrTranslate}}</th>
                    <th class="left">{{'Prüfart' | mrTranslate}}</th>
                    <th class="left">{{'Mangel' | mrTranslate}}</th>
                    <th class="left">{{'Lage' | mrTranslate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of selectedValid">
                    <td class="left">{{row.Ort}}</td>
                    <td class="left">{{row.Bezirk}}</td>
                    <td class="left">{{row.stellwerk}}</td>
                    <td class="left">{{row.pruefdatum}}</td>
                    <td class="left">{{row.pruefart}}</td>
                    <td class="left">{{row.mangel}}</td>
                    <td class="left">{{row.lage}}</td>
                  </tr>
                </tbody>
              </table>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="trial || !selectedValid?.length || !form.valid" class="btn btn-primary margin--12-l"
      (click)="sendMultiAuftrag()">{{'Auftrag mit' |
      mrTranslate}} 
      {{selectedValid.length}} 
      {{'Mängeln anlegen' | mrTranslate}}</button>
  </div>
</clr-modal>