<div class="overlay-inner-wrapper">
  <div id="measurementWrapper">
    <div class="clr-row">
      <div class="clr-col-12">
        <div class="text-left inline-block">
          <h3 class="mt-0" style="display: inline-block;">{{'Längswelligkeitsmessungen' | mrTranslate}}</h3>
        </div>
        <div class="inline-block float-right">
          <clr-icon size="20" shape="times" (click)="close()" class="cursor-pointer"></clr-icon>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let gr of graph.data; let ind = index;">
      <div class="card" *ngIf="gr.yData && gr.yData.length > 0" [dndDraggable]="gr" [dndEffectAllowed]="'move'"
        [dndDisableIf]="!draganddropactive" (dndStart)="onDragStart($event)">
        <div class="card-header" [ngClass]="{'cursor-grab': draganddropactive}">
          <div class="clr-row">
            <div class="clr-col-6">
              <clr-icon (click)="gr.show = !gr.show" [attr.shape]="gr.show ? 'caret up' : 'caret down'"
                style="cursor: pointer; margin-left:-15px;  margin-right: 8px;" size="25">
              </clr-icon> {{ gr.name | mrTranslate }}
            </div>
            <div class="clr-col-6" style="text-align: right;">
              <clr-signpost>
                <clr-icon shape="cog" class="is-solid" clrSignpostTrigger>
                </clr-icon>
                <clr-signpost-content [clrPosition]="'left-bottom'" *clrIfOpen>
                  <form clrForm>
                    <clr-input-container style="margin-top:0px;">
                      <label>{{'Y-Max' | mrTranslate}}</label>
                      <input clrInput placeholder="Y-Max" name="ymax" [(ngModel)]="gr.layout.yaxis.range[1]" />
                    </clr-input-container>
                    <clr-input-container>
                      <label>{{'Y-Min' | mrTranslate}}</label>
                      <input clrInput placeholder="Y-Min" name="ymin" [(ngModel)]="gr.layout.yaxis.range[0]" />
                    </clr-input-container>
                    <button class="btn btn-primary btn-sm" style="margin-top:1.2rem"
                      (click)="applyYaxis(gr.key)">{{'übernehmen' | mrTranslate}}</button>
                  </form>
                </clr-signpost-content>
              </clr-signpost>
            </div>
          </div>
        </div>
        <div *ngIf="gr.show" class="card-block">
          <div style="width:100%;">
            <div class="clr-col-12">
              <div [ngStyle]="{'height.px': gr.layout.height}">
                <app-plotly-plot [className]="" [data]="gr.yData" [layout]="gr.layout ? gr.layout : graph.layout"
                  [config]="graph.config">
                </app-plotly-plot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
