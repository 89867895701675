import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ClarityModule } from "@clr/angular";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";

import { DotDotDotPipe } from "src/app/pipes/dot-dot-dot.pipe";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { TablePrettyPrintPipe } from "src/app/pipes/tablePrettyPrint.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { BasicStoreService } from "src/app/services/BasicStore/basic-store.service";

@Component({
    selector: "app-einstellungsmodal",
    templateUrl: "./einstellungsmodal.component.html",
    styleUrls: ["./einstellungsmodal.component.scss"],
    imports: [CommonModule, ClarityModule, MrTranslatePipe, TablePrettyPrintPipe, DotDotDotPipe],
    providers: [MrTranslatePipe],
    standalone: true
})
export class EinstellungsmodalComponent implements OnInit {
  @Input() modalOpen: boolean = false;
  @Output() modalOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  contentmode = "allgemein";
  kundenbezeichnung: any;
  iniitems: any;
  ini: any;
  toSend: any;
  logo: any;
  base64Image: string;
  kunde: any = {
    k: "",
    o: "",
  };

  apiKeys: any = {
    bing: "",
    mapbox: "",
  };
  apiKeysOriginal: any = {};

  measurementUnitItems: string[] = ["Millimeter", "Inch"];
  lengthUnitItems: string[] = ["Meter", "Miles", "Feet", "Mile Feet"];
  optAuthentType: number = 0;

  @ViewChild('bez') bez: ElementRef;
  @ViewChild('show') show: ElementRef;
  @ViewChild('must') must: ElementRef;

  optionGroup: UntypedFormGroup = new UntypedFormGroup({
    kunde: new UntypedFormControl(''),
    ort: new UntypedFormControl(''),
    OpLogoDok: new UntypedFormControl(true),
    OpLogoPosition: new UntypedFormControl(true),
    OpMaxLenMangel: new UntypedFormControl(255),
    OpMaxLenInsthinweis: new UntypedFormControl(255),
    optZeitAuftragNummer: new UntypedFormControl(10),
    optChecked_EBL: new UntypedFormControl(false),
    optMeldung_EBL: new UntypedFormControl(false),
    optMeldungTage_EBL: new UntypedFormControl(0),
    optAuftragSpezExport: new UntypedFormControl(0),
    optAuthentType: new UntypedFormControl(0),
    ADAppid: new UntypedFormControl(''),
    ADTenant: new UntypedFormControl(''),
    optMINNumFK: new UntypedFormControl(1),
    optMAXNumFK: new UntypedFormControl(4),
    optFk1_IstGleich_BG: new UntypedFormControl(true),
    optDownChangeFK: new UntypedFormControl(true),
    optUnitLength: new UntypedFormControl(''),
    optUnitWidth: new UntypedFormControl(''),
    optUseFAT: new UntypedFormControl(false),
    tfa: new UntypedFormControl(false),
  });


  kavGroup: UntypedFormGroup = new UntypedFormGroup({
    gleisOffenerOberbauSchiene: new UntypedFormControl(0),
    gleisOffenerOberbauSchwellen: new UntypedFormControl(0),
    gleisOffenerOberbauBefestigung: new UntypedFormControl(0),
    gleisOffenerOberbauBettung: new UntypedFormControl(0),
    gleisOffenerOberbauUnterbau: new UntypedFormControl(0),
    gleisGeschlossenerOberbauSchiene: new UntypedFormControl(0),
    gleisGeschlossenerOberbauSchwellen: new UntypedFormControl(0),
    gleisGeschlossenerOberbauBefestigung: new UntypedFormControl(0),
    gleisGeschlossenerOberbauBettung: new UntypedFormControl(0),
    gleisGeschlossenerOberbauUnterbau: new UntypedFormControl(0),
    gleisGeschlossenerOberbauEindeckung: new UntypedFormControl(0),
    weichenSchiene: new UntypedFormControl(0),
    weichenSchwellen: new UntypedFormControl(0),
    weichenBefestigung: new UntypedFormControl(0),
    weichenBettung: new UntypedFormControl(0),
    weichenUnterbau: new UntypedFormControl(0),
    weichenZungenvorrichtung: new UntypedFormControl(0),
    weichenHerzstück: new UntypedFormControl(0),
    weichenEindeckung: new UntypedFormControl(0),
    kavKat1Color: new UntypedFormControl("#FF0000"),
    kavKat2Color: new UntypedFormControl("#FFFFFF"),
    kavKat3Color: new UntypedFormControl("#FFFFFF"),
    anzeigeKAV: new UntypedFormControl(false),
    anzeigeKAVDok: new UntypedFormControl(false),
    anzeigeTyp: new UntypedFormControl(0),
  });
  kavSettings: any = {};

  trial = false;
  constructor(
    private apiService: APIService,
    private toastr: ToastrService,
    private authService: AuthService,
    private mrTranslate: MrTranslatePipe,
    private basicStore: BasicStoreService
  ) { }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.loadApiKeys();
      this.loadKAVSettings();
      this.loadKundenbezeichnung();
      this.loadINI();
      this.loadKunde();
      this.trial = this.authService.getToken().trial;

      if (this.authService.twofa == 2) {
        this.optionGroup.controls.tfa.setValue(true);
      }

    }
  }

  replaceKundenfeld(s: string) {
    let t = this.mrTranslate.transform("Kundenfeld");
    return s.replace('KNField', t + ' ');
  }

  getSum(mode: string) {
    switch (mode) {
      case "gleisOffenerOberbau":
        return +(
          this.kavGroup.value.gleisOffenerOberbauSchiene +
          this.kavGroup.value.gleisOffenerOberbauSchwellen +
          this.kavGroup.value.gleisOffenerOberbauBefestigung +
          this.kavGroup.value.gleisOffenerOberbauBettung +
          this.kavGroup.value.gleisOffenerOberbauUnterbau
        ).toFixed(2);
      case "gleisGeschlossenerOberbau":
        return +(
          this.kavGroup.value.gleisGeschlossenerOberbauSchiene +
          this.kavGroup.value.gleisGeschlossenerOberbauSchwellen +
          this.kavGroup.value.gleisGeschlossenerOberbauBefestigung +
          this.kavGroup.value.gleisGeschlossenerOberbauBettung +
          this.kavGroup.value.gleisGeschlossenerOberbauUnterbau +
          this.kavGroup.value.gleisGeschlossenerOberbauEindeckung
        ).toFixed(2);
      case "weichen":
        return +(
          this.kavGroup.value.weichenSchiene +
          this.kavGroup.value.weichenSchwellen +
          this.kavGroup.value.weichenBefestigung +
          this.kavGroup.value.weichenBettung +
          this.kavGroup.value.weichenUnterbau +
          this.kavGroup.value.weichenZungenvorrichtung +
          this.kavGroup.value.weichenHerzstück +
          this.kavGroup.value.weichenEindeckung
        ).toFixed(2);
      default:
        return 0;
    }
  }

  loadKAVSettings() {
    this.apiService
      .getSettingsKav()
      .pipe(first())
      .subscribe((res: any) => {
        if (res) this.kavSettings = res;
        if (res && res.kav) {
          this.kavGroup.controls.gleisOffenerOberbauSchiene.setValue(
            parseFloat(res.kav.KAV1)
          );
          this.kavGroup.controls.gleisOffenerOberbauSchwellen.setValue(
            parseFloat(res.kav.KAV2)
          );
          this.kavGroup.controls.gleisOffenerOberbauBefestigung.setValue(
            parseFloat(res.kav.KAV5)
          );
          this.kavGroup.controls.gleisOffenerOberbauBettung.setValue(
            parseFloat(res.kav.KAV3)
          );
          this.kavGroup.controls.gleisOffenerOberbauUnterbau.setValue(
            parseFloat(res.kav.KAV4)
          );
          this.kavGroup.controls.gleisGeschlossenerOberbauSchiene.setValue(
            parseFloat(res.kav.KAV11)
          );
          this.kavGroup.controls.gleisGeschlossenerOberbauSchwellen.setValue(
            parseFloat(res.kav.KAV12)
          );
          this.kavGroup.controls.gleisGeschlossenerOberbauBefestigung.setValue(
            parseFloat(res.kav.KAV15)
          );
          this.kavGroup.controls.gleisGeschlossenerOberbauBettung.setValue(
            parseFloat(res.kav.KAV13)
          );
          this.kavGroup.controls.gleisGeschlossenerOberbauUnterbau.setValue(
            parseFloat(res.kav.KAV14)
          );
          this.kavGroup.controls.gleisGeschlossenerOberbauEindeckung.setValue(
            parseFloat(res.kav.KAV16)
          );
          this.kavGroup.controls.weichenSchiene.setValue(
            parseFloat(res.kav.KAVW1)
          );
          this.kavGroup.controls.weichenSchwellen.setValue(
            parseFloat(res.kav.KAVW3)
          );
          this.kavGroup.controls.weichenBefestigung.setValue(
            parseFloat(res.kav.KAVW5)
          );
          this.kavGroup.controls.weichenBettung.setValue(
            parseFloat(res.kav.KAVW4)
          );
          this.kavGroup.controls.weichenUnterbau.setValue(
            parseFloat(res.kav.KAVW2)
          );
          this.kavGroup.controls.weichenZungenvorrichtung.setValue(
            parseFloat(res.kav.KAVW7)
          );
          this.kavGroup.controls.weichenHerzstück.setValue(
            parseFloat(res.kav.KAVW8)
          );
          this.kavGroup.controls.weichenEindeckung.setValue(
            parseFloat(res.kav.KAVW6)
          );
          this.kavGroup.controls.kavKat1Color.setValue(
            "#" + res.kav.optKAVKat1ColS.toString(16)
          );
          this.kavGroup.controls.kavKat2Color.setValue(
            "#" + res.kav.optKAVKat2ColS.toString(16)
          );
          this.kavGroup.controls.kavKat3Color.setValue(
            "#" + res.kav.optKAVKat3ColS.toString(16)
          );
          this.kavGroup.controls.anzeigeKAV.setValue(res.kav.KAV17);
          this.kavGroup.controls.anzeigeKAVDok.setValue(res.kav.KAV18);
          this.kavGroup.controls.anzeigeTyp.setValue(
            res.kav.optKAV_Anzeige_Typ
          );
        }
      });
  }

  saveKAVSettings() {
    let vals = this.kavGroup.value;
    let kav = {
      KAV1: vals.gleisOffenerOberbauSchiene,
      KAV2: vals.gleisOffenerOberbauSchwellen,
      KAV5: vals.gleisOffenerOberbauBefestigung,
      KAV3: vals.gleisOffenerOberbauBettung,
      KAV4: vals.gleisOffenerOberbauUnterbau,
      KAV11: vals.gleisGeschlossenerOberbauSchiene,
      KAV12: vals.gleisGeschlossenerOberbauSchwellen,
      KAV15: vals.gleisGeschlossenerOberbauBefestigung,
      KAV13: vals.gleisGeschlossenerOberbauBettung,
      KAV14: vals.gleisGeschlossenerOberbauUnterbau,
      KAV16: vals.gleisGeschlossenerOberbauEindeckung,
      KAV17: vals.anzeigeKAV,
      KAV18: vals.anzeigeKAVDok,
      KAVW1: vals.weichenSchiene,
      KAVW3: vals.weichenSchwellen,
      KAVW5: vals.weichenBefestigung,
      KAVW4: vals.weichenBettung,
      KAVW2: vals.weichenUnterbau,
      KAVW7: vals.weichenZungenvorrichtung,
      KAVW8: vals.weichenHerzstück,
      KAVW6: vals.weichenEindeckung,
      optKAVKat1ColS: parseInt(vals.kavKat1Color.slice(1), 16),
      optKAVKat2ColS: parseInt(vals.kavKat2Color.slice(1), 16),
      optKAVKat3ColS: parseInt(vals.kavKat3Color.slice(1), 16),
      optKAV_Anzeige_Typ: vals.anzeigeTyp,
    };

    let schwellen = this.kavSettings.schwellen
      .map((schwelle) => {
        return `${schwelle.Schwellenart}\t${schwelle["max. Liegedauer"]}\r`;
      })
      .join("\n");
    let schienenhoehe = this.kavSettings.schienenhoehe;
    let anlagenklassen = this.kavSettings.anlagenklasse;

    this.apiService
      .sendKAVsettings({
        kav: kav,
        schwellen: schwellen,
        schienenhoehe: schienenhoehe,
        anlagenklassen: anlagenklassen,
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status)
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
      });
  }

  loadApiKeys() {
    this.apiService
      .getSettingsApiKeys()
      .pipe(first())
      .subscribe((res) => {
        this.apiKeys = res;
        this.apiKeysOriginal = JSON.parse(JSON.stringify(res));
      });
  }

  loadKunde() {
    this.apiService
      .getSettingsKunde()
      .pipe(first())
      .subscribe((res) => {
        this.kunde = res;
        this.optionGroup.controls.kunde.setValue(this.kunde.k);
        this.optionGroup.controls.ort.setValue(this.kunde.o);
      });
  }

  loadKundenbezeichnung() {
    this.apiService
      .getKundenBezeichnung()
      .pipe(first())
      .subscribe((res) => {
        this.kundenbezeichnung = res;
      });
  }

  onChangeLogoDok(event) {
    this.iniitems.isOpLogoDok = event.target.checked;
  }

  onAnmeldChanged(event, opj) {
    let t = event.target.checked;
    this.optAuthentType = 0;
    if (opj != 'mrpro') {
      if (event.target.checked) {
        this.optAuthentType = 2;
      }
    }
  }

  changeStatus(event) {
    let t = event.target.checked;
    let n = 0;


    if (t) {
      n = 2;
    }

    let iniVal = {
      status: "updatetwofa",
      googletwofa: n,
    }

    this.apiService
      .sendINIsettings({
        ini: iniVal,
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
        }
        else {
          this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
        }
      });




  }




  saveINI() {

    let vals = this.optionGroup.value;

    this.optAuthentType = vals.optAuthentType;

    if (vals.optAuthentType == 2) {
      if (vals.ADAppid == '') {
        this.toastr.error(this.mrTranslate.transform("Geben Sie eine App ID ein"));
        return;
      }
      if (vals.ADTenant == '') {
        this.toastr.error(this.mrTranslate.transform("Geben Sie eine Tenant ein"));
        return;
      }
    }

    if (vals.optUseFAT == false) {
      vals.optUseFAT = 0;
      localStorage.setItem('optUseFAT', "0");
    }
    else {
      localStorage.setItem('optUseFAT', "1");
    }

    let iniVal = {
      BetreiberlogoBase64: '',
      OpLogoDok: vals.OpLogoDok,
      OpLogoPosition: parseFloat(vals.OpLogoPosition),
      OpMaxLenMangel: vals.OpMaxLenMangel,
      OpMaxLenInsthinweis: vals.OpMaxLenInsthinweis,
      optChecked_EBL: vals.optChecked_EBL,
      optMeldung_EBL: vals.optMeldung_EBL,
      optMeldungTage_EBL: vals.optMeldungTage_EBL,
      optAuftragSpezExport: vals.optAuftragSpezExport,
      ADAppid: vals.ADAppid,
      ADTenant: vals.ADTenant,
      optMINNumFK: vals.optMINNumFK,
      optMAXNumFK: vals.optMAXNumFK,
      optFk1_IstGleich_BG: vals.optFk1_IstGleich_BG,
      optDownChangeFK: vals.optDownChangeFK,
      optZeitAuftragNummer: vals.optZeitAuftragNummer,
      optAuthentType: vals.optAuthentType,
      optUnitLength: Number(vals.optUnitLength),
      optUnitWidth: Number(vals.optUnitWidth),
      optUseFAT: Number(vals.optUseFAT)
    };

    if (this.base64Image) {
      iniVal.BetreiberlogoBase64 = this.base64Image;
    }

    if (vals.ADTenant) {
      iniVal.ADTenant = vals.ADTenant;
    }

    if (vals.ADAppid) {
      iniVal.ADAppid = vals.ADAppid;
    }

    let kunde = {
      name: vals.kunde,
      ort: vals.ort,
    }

    this.apiService
      .sendINIsettings({
        ini: iniVal,
        kunde: kunde
      })
      .pipe(first())
      .subscribe((res: any) => {
        if (res?.status) {
          this.toastr.success(this.mrTranslate.transform("Daten gespeichert"));
          this.basicStore.setComponentStore('einstellung', {
            einstellung: iniVal
          });
        }
        else {
          this.toastr.warning(res.join("<br>"), this.mrTranslate.transform("Ein Fehler ist aufgetreten"));
        }
      });

  }


  loadINI() {
    this.apiService
      .getINIOptions()
      .pipe(first())
      .subscribe((res) => {
        this.ini = res;
        this.iniitems = this.ini?.rows[0];
        if (!this.iniitems) return;
        this.optionGroup.controls.OpLogoDok.setValue(this.iniitems.OpLogoDok);

        if (this.iniitems.OpLogoPosition == 1)
          this.optionGroup.controls.OpLogoPosition.setValue('1');
        else
          this.optionGroup.controls.OpLogoPosition.setValue('0');

        this.optionGroup.controls.OpMaxLenMangel.setValue(parseFloat(this.iniitems.OpMaxLenMangel));
        this.optionGroup.controls.OpMaxLenInsthinweis.setValue(parseFloat(this.iniitems.OpMaxLenInsthinweis));
        this.optionGroup.controls.optZeitAuftragNummer.setValue(parseFloat(this.iniitems.optZeitAuftragNummer));
        this.optionGroup.controls.optChecked_EBL.setValue(this.iniitems.optChecked_EBL);
        this.optionGroup.controls.optMeldung_EBL.setValue(this.iniitems.optMeldung_EBL);
        this.optionGroup.controls.optMeldungTage_EBL.setValue(parseFloat(this.iniitems.optMeldungTage_EBL));
        this.optionGroup.controls.optAuftragSpezExport.setValue(this.iniitems.optAuftragSpezExport);
        this.optionGroup.controls.ADAppid.setValue(this.iniitems.ADAppid);
        this.optionGroup.controls.ADTenant.setValue(this.iniitems.ADTenant);
        this.optionGroup.controls.optMINNumFK.setValue(parseFloat(this.iniitems.optMINNumFK));
        this.optionGroup.controls.optMAXNumFK.setValue(parseFloat(this.iniitems.optMAXNumFK));
        this.optionGroup.controls.optFk1_IstGleich_BG.setValue(this.iniitems.optFk1_IstGleich_BG);
        this.optionGroup.controls.optDownChangeFK.setValue(this.iniitems.optDownChangeFK);
        this.optionGroup.controls.optAuthentType.setValue(this.iniitems.optAuthentType);
        this.optionGroup.controls.optUnitLength.setValue(this.iniitems.optUnitLength);
        this.optionGroup.controls.optUnitWidth.setValue(this.iniitems.optUnitWidth);

        localStorage.setItem('optUseFAT', this.iniitems.optUseFAT?.toString());

        this.optAuthentType = this.iniitems.optAuthentType;


        if (this.iniitems.optUseFAT.toString() == '0') {
          this.optionGroup.controls.optUseFAT.setValue(false);
        }
        else {
          this.optionGroup.controls.optUseFAT.setValue(true);
        }

        if (this.iniitems.BetreiberlogoBase64) {
          this.logo = 'data:image/png;base64,' + this.iniitems.BetreiberlogoBase64;
          this.base64Image = this.iniitems.BetreiberlogoBase64;
        }
      });
  }

  onLogoChanged(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        let r = reader.result as String;
        const splitted = r.split(',');
        this.base64Image = splitted[1];
        this.logo = r;
      }
      reader.readAsDataURL(file);
    }
  }


  colorFocusInput(input: HTMLInputElement) {
    input.focus();
    input.click();
  }

  saveBezeichnung(id: number) {
    let b = this.bez.nativeElement.value;
    let s = this.show.nativeElement.checked;
    let m = this.must.nativeElement.checked;

    if (id < 13) {
      if (this.bez.nativeElement.value == '') {
        this.toastr.error(this.mrTranslate.transform("Bezeichnung darf nicht leer sein"));
        return
      }
    }
    else {
      if (this.bez.nativeElement.value == '') {
        s = false;
        m = false;
      }
    }

    if (b != '') {
      for (let i = 0; i < this.kundenbezeichnung.rows.length; i++) {
        let bez = this.kundenbezeichnung.rows[i];
        if (bez.ID !== id) {
          if (bez.KBezeichnung.toLowerCase() === b.toLowerCase()) {
            this.toastr.error(this.mrTranslate.transform("Bezeichnung wird bereits verwendet"));
            return;
          }
        }
      }
    }

    let toSend = {};

    toSend['ID'] = id;
    toSend['KBezeichnung'] = b;
    toSend['KShow'] = s;
    toSend['KPflicht'] = m;

    this.apiService
      .setSettingsBezeichnung(toSend)
      .pipe(first())
      .subscribe((res: any) => {
        if (res.status && res.status == "OK") {
          this.loadKundenbezeichnung();

          this.toastr.success(this.mrTranslate.transform("Bezeichnung erfolgreich gespeichert."));
        } else {
          this.toastr.warning(
            res.join("<br>"),
            this.mrTranslate.transform("Ein Fehler ist aufgetreten")
          );
        }
      });


  }

  saveApiKeys() {
    let send = {};

    Object.keys(this.apiKeys).forEach((key) => {
      if (this.apiKeysOriginal[key] != this.apiKeys[key]) {
        send[key] = this.apiKeys[key];
      }
    });

    this.apiService
      .setSettingsApiKeys(send)
      .pipe(first())
      .subscribe((res: any) => {
        if (res.length == 0) {
          this.apiKeysOriginal = JSON.parse(JSON.stringify(this.apiKeys));
          this.toastr.success(
            this.mrTranslate.transform("Einstellungen erfolgreich gespeichert.")
          );
        } else {
          this.toastr.warning(
            res.join("<br>"),
            this.mrTranslate.transform("Ein Fehler ist aufgetreten")
          );
        }
      });
  }
}
