<div class="w-100-p padding-10-lr padding-12-b scroll-y h-60">
  <div class="clr-row nxm clr-justify-content-center clr-align-items-end">
    <ng-container *ngIf="maskenListe">
      <ng-container *ngIf="maskenListe.length; else empty">
        <clr-select-container class="clr-col-auto clr-control-inline clr-align-items-end">
          <label for="maske">{{ "Maske" | mrTranslate }}</label>
          <select clrSelect name="maske"
            [(ngModel)]="selectedMaske" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let maske of maskenListe" [value]="maske.ID">
              {{ maske.Name }}
            </option>
          </select>
        </clr-select-container>
        <clr-dropdown>
          <button type="button" class="btn-icon" clrDropdownTrigger aria-label="toggle settings menu">
            <clr-icon shape="cog" size="22"></clr-icon>
          </button>
          <clr-dropdown-menu *clrIfOpen clrPosition="bottom-left">
            <a (click)="addMaske('new')" clrDropdownItem>{{'Neue Maske erstellen' | mrTranslate}}</a>
            <div *ngIf="selectedMaske">
              <a (click)="addMaske('change')" clrDropdownItem>{{'Maskennamen ändern' | mrTranslate}}</a>
              <a (click)="addMaske('copy')" clrDropdownItem>{{'Maske speichern unter...' | mrTranslate}}</a>
              <a (click)="deleteMaske()" clrDropdownItem>{{'Maske löschen' | mrTranslate}}</a>
            </div>
          </clr-dropdown-menu>
        </clr-dropdown>
      </ng-container>

      <ng-template #empty>
        <button type="button" class="btn btn-primary" (click)="addMaske('new')">
          {{'Neue Maske erstellen' | mrTranslate}}
        </button>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!viewValid">
      <clr-alert clrAlertType="warning" [clrAlertClosable]="false" class="clr-col-md-8 clr-col">
        <clr-alert-item>
          <span class="alert-text">
            {{ "Zuerst müssen Wartungarten eingetragen werden" | mrTranslate }}
          </span>
          <div class="alert-actions">
            <a (click)="view.emit('baum')" class="alert-action"> {{ "Gehen zu Wartungsbaum" | mrTranslate }} </a>
          </div>
        </clr-alert-item>
      </clr-alert>
    </ng-container>
  </div>
  <div *ngIf="selectedMaske" class="clr-row nxm h-60">
    <div class="clr-col-md-5 clr-col-10 h-100">
      <clr-datagrid #leftGrid class="datagrid-compact h-100 w-100-p wrapColumn"
        [(clrDgSelected)]="selectedLeft" [clrDgRowSelection]="true">
        <clr-dg-column [clrDgField]="'Bezeichnung'">{{ "Bezeichnung" | mrTranslate }}
        </clr-dg-column>
        <clr-dg-row *clrDgItems="let row of notUsedArten" [clrDgItem]="row" [class.headerRow]="row.Typ == 0">
          <clr-dg-cell class="ellipsis" [innerHTML]="row['Bezeichnung'] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>{{notUsedArten?.length ?? 0}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
      </clr-datagrid>
    </div>
    <div class="clr-col-md-1 clr-col-2 move-buttons">
      <button class="btn btn-link btn-state btn-state-custom-size add" [class.disabled]="!selectedLeft.length"
        (click)="moveToRight()" [clrLoading]="moveRightState">
        <clr-icon shape="circle-arrow right"></clr-icon>
      </button>
      <button class="btn btn-link btn-state btn-state-custom-size delete" [class.disabled]="!selectedRight.length"
        (click)="moveToLeft()" [clrLoading]="moveLeftState">
        <clr-icon shape="circle-arrow left"></clr-icon>
      </button>
    </div>
    <div class="clr-col-md-5 clr-col-10 h-100">
      <clr-datagrid #rightGrid class="datagrid-compact h-100 w-100-p wrapColumn"
        [(clrDgSelected)]="selectedRight" [clrDgRowSelection]="true">
        <clr-dg-column>{{ "Bezeichnung" | mrTranslate }}</clr-dg-column>
        <clr-dg-row *clrDgItems="let row of usedArten" [clrDgItem]="row" [class.headerRow]="row.Typ == 0">
          <clr-dg-cell class="ellipsis" [innerHTML]="row['Bezeichnung'] | tablePrettyPrint">
          </clr-dg-cell>
        </clr-dg-row>
        <clr-dg-footer>{{usedArten?.length ?? 0}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
      </clr-datagrid>
    </div>
    <div class="clr-col-md-1 clr-col-2 move-buttons">
      <button class="btn btn-link btn-state btn-state-custom-size" [class.disabled]="!enableMove.up"
        (click)="moveUpDown('up')" [clrLoading]="moveUpDownState">
        <clr-icon shape="circle-arrow up"></clr-icon>
      </button>
      <button class="btn btn-link btn-state btn-state-custom-size" [class.disabled]="!enableMove.down"
        (click)="moveUpDown('down')" [clrLoading]="moveUpDownState">
        <clr-icon shape="circle-arrow down"></clr-icon>
      </button>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="isOpenModalMaskenName" [clrModalClosable]="true">
  <h3 class="modal-title">{{ ( maskenAddStatus == 'new' 
    ? "Eine neue Maske erstellen"
    : "Die Maske unter anderem Namen speichern"
  ) | mrTranslate }}</h3>
  <div class="modal-body clr-row nxm">
    <form clrForm (ngSubmit)="f.valid && saveMaske()" class="clr-col-11 width100" #f="ngForm" clrLayout="horizontal">
      <clr-input-container>
        <label for="Name">{{ "Name" | mrTranslate }}</label>
        <input clrInput type="text" name="Name" [autofocus]="isOpenModalMaskenName"
          [(ngModel)]="maskenNameNeu" required/>
        <clr-control-error *clrIfError="'required'">
          {{"Das Feld muss ausgefüllt werden" | mrTranslate}}
        </clr-control-error>
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
      [disabled]="f.invalid" (click)="f.ngSubmit.emit()">
      {{'OK' | mrTranslate}}
    </button>
    <button type="button" class="btn" (click)="isOpenModalMaskenName = false">
      {{'Abbrechen' | mrTranslate}}
    </button>
  </div>
</clr-modal>