import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { MrTranslatePipe } from "src/app/pipes/mr-translate.pipe";
import { APIService } from "src/app/services/APIService/api.service";
import { AuthService } from "src/app/services/Auth/auth.service";
import { MrTranslatePipe as MrTranslatePipe_1 } from "../../../../../../pipes/mr-translate.pipe";
import { NgStyle, NgSwitch, NgSwitchCase, TitleCasePipe } from "@angular/common";
import { ClrModalModule, ClrCommonFormsModule, ClrInputModule, ClrTextareaModule } from "@clr/angular";

@Component({
    selector: "app-kosten-edit-modal",
    templateUrl: "./kosten-edit-modal.component.html",
    styleUrls: ["./kosten-edit-modal.component.scss"],
    imports: [
        ClrModalModule,
        NgStyle,
        ClrCommonFormsModule,
        NgSwitch,
        NgSwitchCase,
        FormsModule,
        ReactiveFormsModule,
        ClrInputModule,
        ClrTextareaModule,
        TitleCasePipe,
        MrTranslatePipe_1,
    ],
    standalone: true
})
export class KostenEditModalComponent implements OnInit {
  @Input() set open(newVal: boolean) {
    if (newVal && this.data) {
      const { bild, ...rest } = this.data;
      if (bild) this.bild = `data:image/png;base64,${bild}`;
      else this.bild = null;

      this.id = rest.id;
      delete rest.id;
      switch (this.mode) {
        case "personal":
          this.personalForm.setValue(rest);
          break;
        case "material":
          this.materialForm.setValue(rest);
          break;
        case "maschine":
          this.maschineForm.setValue(rest);
          break;
      }
    }
    this._open = newVal;
  }

  id: number;

  get open() {
    return this._open;
  }
  _open: boolean;

  @Input() mode: "personal" | "material" | "maschine";
  @Input() data:
    | PersonalEditDataWithImage
    | MaterialEditDataWithImage
    | MaschineEditDataWithImage;

  @Output() close: EventEmitter<any> = new EventEmitter();

  bild: string;

  personalForm = new UntypedFormGroup({
    personalnummer: new UntypedFormControl(""),
    kuerzel: new UntypedFormControl(""),
    vorname: new UntypedFormControl("", [Validators.required]),
    nachname: new UntypedFormControl("", [Validators.required]),
    email: new UntypedFormControl(""),
    qualifikation: new UntypedFormControl(""),
    wochenarbeitszeit: new UntypedFormControl(null, [Validators.required]),
    verrechnungssatz: new UntypedFormControl(null, [Validators.required]),
  });

  materialForm = new UntypedFormGroup({
    materialnummer: new UntypedFormControl(""),
    titel: new UntypedFormControl("", [Validators.required]),
    beschreibung: new UntypedFormControl(""),
    einheit: new UntypedFormControl("", [Validators.required]),
    einzelpreis: new UntypedFormControl(null, [Validators.required]),
  });

  @Input() mengeneinheiten: any[] = [];

  maschineForm = new UntypedFormGroup({
    maschinennummer: new UntypedFormControl(""),
    titel: new UntypedFormControl("", [Validators.required]),
    beschreibung: new UntypedFormControl(""),
    verrechnungssatz: new UntypedFormControl(null, [Validators.required]),
  });

  trial = false;

  constructor(
    private toastr: ToastrService,
    private mrTranslate: MrTranslatePipe,
    private apiservice: APIService,
    private authService: AuthService
  ) {
    this.trial = this.authService.getToken().trial;
  }

  ngOnInit(): void {}

  save() {
    const form = this.getForm();
    if (!form.valid) {
      const text = this.mrTranslate.transform(
        "Bitte alle Pflichtfelder ausfüllen"
      ) + '(*)';
      return this.toastr.warning(text);
    }
    let img = this.bild;
    if (img) img = img.split(",")[1];
    const data = {
      ...form.value,
      bild: img || null,
    };
    let apiCall;
    let dataID = -99;
    if(this.data != null) {
      dataID = this.data.id;
    }
    switch (this.mode) {
      case "personal":
        apiCall = this.apiservice.updateKostenPersonal(dataID, data);
        break;
      case "material":
        apiCall = this.apiservice.updateKostenMaterial(dataID, data);
        break;
      case "maschine":
        apiCall = this.apiservice.updateKostenMaschinen(dataID, data);
        break;
    }
    apiCall.pipe(first()).subscribe((res) => {
      if (res && res.status === "success") {
        const text = this.mrTranslate.transform(
          this.mode[0].toUpperCase() +
            this.mode.slice(1) +
            " erfolgreich gespeichert"
        );
        this.toastr.success(text);
        this.close.emit(true);
      } else {
        const text = this.mrTranslate.transform("Fehler beim Speichern");
        this.toastr.error(text);
      }
    });
  }

  onImageSelect(image) {
    if (image.files.length >= 1) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.bild = e.target.result as string;
      };
      fileReader.readAsDataURL(image.files[0]);
    }
  }
  private getForm() {
    switch (this.mode) {
      case "personal":
        return this.personalForm;
      case "material":
        return this.materialForm;
      case "maschine":
        return this.maschineForm;
    }
  }
}

export interface PersonalEditData {
  personalnummer: string;
  kuerzel: string;
  vorname: string;
  nachname: string;
  email: string;
  qualifikation: string;
  wochenarbeitszeit: number;
  verrechnungssatz: number;
}
// extend personalEditData with bild attribute with type string
export interface PersonalEditDataWithImage extends PersonalEditData {
  id: number;
  bild: string;
}

export interface MaterialEditData {
  materialnummer: string;
  titel: string;
  beschreibung: string;
  einheit: string;
  einzelpreis: number;
}

export interface MaterialEditDataWithImage extends MaterialEditData {
  id: number;
  bild: string;
}

export interface MaschineEditData {
  maschinennummer: string;
  titel: string;
  beschreibung: string;
  verrechnungssatz: number;
}

export interface MaschineEditDataWithImage extends MaschineEditData {
  id: number;
  bild: string;
}
