<div class="overlay-inner-wrapper overlay-fullscreen">
  <div id="inspektionWrapper">
    <header class="header-2">
      <div class="branding">
          <span class="title">{{'Begehung durchführen: ' | mrTranslate}}{{ ' ' + titel }}</span>
      </div>
      <div class="header-actions">

        <form clrForm [formGroup]="formGroup" style="width: 250px;">
          <clr-date-container>
            <label>{{'Datum' | mrTranslate}}</label>
            <input id="dateInput" type="date" clrDate style="color: white;"
              [ngModel]="formGroup.get('inspektiondate').value | localeDate"
              (ngModelChange)="formGroup.get('inspektiondate').setValue(localeDate.tosql($event))"
              [ngModelOptions]="{updateOn: 'blur', standalone: true}"
              [disabled]="formGroup.disabled" required />
            <clr-control-error *clrIfError="'required'">
            </clr-control-error>
          </clr-date-container>
        </form>

        <form clrForm [formGroup]="formGroup">
          <clr-input-container>
            <label>{{'Bemerkung' | mrTranslate}}</label>
            <input clrInput name="bemerkung" formControlName="bemerkung" style="width: 400px;"/>
          </clr-input-container>
        </form>


        <a href="javascript://" class="nav-link nav-icon" style="margin-right: 120px ;">
          <clr-icon  shape="window-close" (click)="close()"></clr-icon >
        </a>
      </div>
    </header>

    <div class="clr-row nxm" style="align-items: center;">
      <div class="clr-col-auto margin-12-tb">
        <div class="btn-group btn-primary btn-icon">
          <button class="btn btn-icon" [title]="'Begehung speichern' | mrTranslate"
            (click)="saveBegehung()">
            <clr-icon shape="floppy"></clr-icon>
          </button>
          <button class="btn btn-icon btn-success" [title]="'Begehung für diese Objekt ist abgeschlossen' | mrTranslate"
            (click)="finishedBegehungObjekt()" [disabled]="ostammid == 0 || obegpid == 0">
            <clr-icon shape="check"></clr-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="clr-row nxm" style="padding-right:10px;" *ngIf="objekttabelle?.rows?.length > 0">
      <div class="clr-col-md-6" >
        <div class="clr-row nxm" style="padding-right:10px;">
            <h4 style="margin-top: 2px;">{{ "Objekte" | mrTranslate }}</h4>
        </div>
        <div class="limit-height">
          <clr-datagrid *ngIf="objekttabelle?.rows?.length > 0" #objekteGrid class="datagrid-compact"
            [(clrDgSingleSelected)]="selectedObjekt" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectionGridChanged($event)">

              <clr-dg-column *ngFor="let column of objekttabelle['columns']" [clrDgField]="column.id"
                [style.min-width.px]="(column.id.length * 7.5) + 26">
                <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['sp', 'lf','st','rep']}">
                  {{ column.id | titlecase | mrTranslate }}
                </ng-container>
              </clr-dg-column>
              <clr-dg-row *clrDgItems="let row of objekttabelle['rows']" [clrDgItem]="row" > <!-- [clrDgSelectable]="obegpid != 0"> -->
                <ng-container *ngFor="let cell of objekttabelle['columns']">
                  <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
                    <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                      [title]="row[cell.id] | tablePrettyPrint">
                    </clr-dg-cell>
                  </ng-container>
                  <ng-template #iconcell>
                    <clr-dg-cell style="color:#000">
                      <ng-container [ngSwitch]="cell.id.toLowerCase()">
                        <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                          [attr.shape]="row['typ_icon'] ? 'objekttyp'+row['typ_icon'] : 'unknown-status'" size="20">
                        </clr-icon>
                      </ng-container>
                    </clr-dg-cell>
                  </ng-template>
                </ng-container>
              </clr-dg-row>


              <clr-dg-footer>
                <clr-dg-column-toggle></clr-dg-column-toggle>
                <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="100">
                  <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                  </clr-dg-page-size>
                  {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                  {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                </clr-dg-pagination>
              </clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>

      <div class="clr-col-md-6" >
        <div class="clr-row nxm" style="padding-right:10px;">


          <div class="clr-col-md-11">
            <h4 style="margin-top: 2px;">{{ "Mängel" | mrTranslate }}</h4>
          </div>

          <div class="clr-col-md-1">
            <div class="btn-group btn-primary btn-icon">
              <button class="btn btn-icon" [title]="'Neuen Mangel hinzufügen' | mrTranslate"
                (click)="addMangel()" [disabled]="lpid == 0" style="margin-top: -10px;">
                <clr-icon shape="plus-circle"></clr-icon>
              </button>
            </div>
          </div>



        </div>
        <div class="limit-height">
          <clr-datagrid style="margin-top: -3px;" *ngIf="selectedMaengel?.columns?.length > 0" #objekteGrid class="datagrid-compact">
              <clr-dg-column *ngFor="let column of selectedMaengel['columns']" [clrDgField]="column.id">
                <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['icon','sperrstatus','lfstatus', 'anlagenobjekte', 'pruefdatum']}">
                  {{ column.id | titlecase | mrUnitText }}
                </ng-container>
              </clr-dg-column>
              <clr-dg-row *clrDgItems="let row of selectedMaengel['rows']" [clrDgItem]="row">
                <clr-dg-cell class="name-cell" *ngFor="let cell of selectedMaengel['columns']"
                  [innerHTML]="row[cell.id] | tablePrettyPrint">
                </clr-dg-cell>
              </clr-dg-row>
              <clr-dg-footer>{{selectedMaengel['rows'].length}} {{'Zeilen' | mrTranslate}}</clr-dg-footer>
          </clr-datagrid>
        </div>
      </div>
    </div>


    <ng-container *ngIf="completedobjekttabelle?.rows?.length > 0">
      <div class="clr-row nxm" style="padding-right:10px;">
        <div class="clr-col-md-11">
          <h4 style="margin-top: 15px;">{{ "Begehung abgeschlossen" | mrTranslate }}</h4>
        </div>
        <div class="clr-col-md-1">

          <div class="btn-group btn-primary btn-icon" style="margin-top: 10px;">
            <button class="btn btn-icon btn-danger" [title]="'Objekt wieder aktivieren' | mrTranslate"
              (click)="reaktivBegehungObjekt()" [disabled]="ostammidFinished == 0">
              <clr-icon shape="times"></clr-icon>
            </button>
          </div>



        </div>
      </div>
      <div class="clr-row nxm" style="padding-right:10px;">
        <div class="clr-col-md-12" >

          <clr-datagrid #objekteDatagrid class="datagrid-compact dg-overflow-fix"
            [(clrDgSingleSelected)]="selectedFinishedObjekt" [clrDgRowSelection]="true" (clrDgSingleSelectedChange)="selectionFinishedObjektChanged($event)">
              <clr-dg-column *ngFor="let column of completedobjekttabelle['columns']" [clrDgField]="column.id"
                [style.min-width.px]="(column.id.length * 7.5) + 26">
                <ng-container *clrDgHideableColumn="{hidden: column.id | hideIDColumns:['sp', 'lf','st','rep']}">
                  {{ column.id | titlecase | mrTranslate }}
                </ng-container>
              </clr-dg-column>
              <clr-dg-row *clrDgItems="let row of completedobjekttabelle['rows']" [clrDgItem]="row">
                <ng-container *ngFor="let cell of completedobjekttabelle['columns']">
                  <ng-container *ngIf="iconColumns.indexOf(cell.id.toLowerCase()) == -1; else iconcell">
                    <clr-dg-cell [innerHTML]="row[cell.id] | dotDotDot:30 | tablePrettyPrint"
                      [title]="row[cell.id] | tablePrettyPrint">
                    </clr-dg-cell>
                  </ng-container>
                  <ng-template #iconcell>
                    <clr-dg-cell style="color:#000">
                      <ng-container [ngSwitch]="cell.id.toLowerCase()">
                        <clr-icon *ngSwitchCase="'typ'" class="customIcon"
                          [attr.shape]="row['typ_icon'] ? 'objekttyp'+row['typ_icon'] : 'unknown-status'" size="20">
                        </clr-icon>
                      </ng-container>
                    </clr-dg-cell>
                  </ng-template>
                </ng-container>
              </clr-dg-row>
              <clr-dg-footer>
                <clr-dg-column-toggle></clr-dg-column-toggle>
                <clr-dg-pagination class="dg-pagination" #pagination [clrDgPageSize]="12">
                  <clr-dg-page-size [clrPageSizeOptions]="[12,20,50,100]">{{'Zeilen pro Seite' | mrTranslate}}
                  </clr-dg-page-size>
                  {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
                  {{'von' | mrTranslate}} {{pagination.totalItems}} {{'Zeilen' | mrTranslate}}
                </clr-dg-pagination>
              </clr-dg-footer>
          </clr-datagrid>

        </div>
      </div>
    </ng-container>

  </div>
</div>
